// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-image {
    display: flex;
    color: var(--text-ui-secondary);
    justify-content: space-between;
    margin: 6px 15px !important;
    margin-bottom: 0px !important;
}
.select-image:hover {
    cursor: pointer;
    color: var(--text-ui-primary);
}
.select-image label {
    font-weight: normal;
}
.select-image-content-area {
    display: flex;
    margin-bottom: 0 !important;
}
.select-image-content-area i {
    margin-bottom: 0 !important;
    padding-top: 4px;
}
.select-image-content-area ul li {
    display: flex;
    justify-content: space-between;
}
.select-image-content-area ul li:hover {
    color: var(--text-ui-inverted);
}
.select-image-content-area img {
    height: 20px;
    width: 20px;
}
.selector-ln-sel {
    padding-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/select-language/select-language.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,8BAA8B;IAC9B,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,eAAe;IACf,6BAA6B;AACjC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,2BAA2B;AAC/B;AACA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".select-image {\n    display: flex;\n    color: var(--text-ui-secondary);\n    justify-content: space-between;\n    margin: 6px 15px !important;\n    margin-bottom: 0px !important;\n}\n.select-image:hover {\n    cursor: pointer;\n    color: var(--text-ui-primary);\n}\n.select-image label {\n    font-weight: normal;\n}\n.select-image-content-area {\n    display: flex;\n    margin-bottom: 0 !important;\n}\n.select-image-content-area i {\n    margin-bottom: 0 !important;\n    padding-top: 4px;\n}\n.select-image-content-area ul li {\n    display: flex;\n    justify-content: space-between;\n}\n.select-image-content-area ul li:hover {\n    color: var(--text-ui-inverted);\n}\n.select-image-content-area img {\n    height: 20px;\n    width: 20px;\n}\n.selector-ln-sel {\n    padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
