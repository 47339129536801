export class Logbook {
    cod_logbooks: number;
    cod_profile: number;
    cod_function: number;
    qty_users: number;
    str_name: string;
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;
    is_clocking: number;
    cod_update_user: number;
    cod_area: number;
    str_area_description: string;
    routines: any;
    cod_user: number;
    bol_is_collab: boolean;
    cod_subareas: any;
    cod_locations: any;

    constructor() {
        this.cod_logbooks = 0;
        this.cod_profile = 0;
        this.cod_function = 0;
        this.str_name = '';
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
        this.is_clocking = 0;
        this.cod_update_user = 0;
        this.cod_area = 0;
        this.str_area_description = '';
        this.routines = [];
        this.cod_user = 0;
        this.bol_is_collab = null;
        this.cod_subareas = [];
        this.cod_locations = [];
    }
}