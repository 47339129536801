// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    position: fixed;
}
    
#inactive-button-left-menu {
    margin: 20px 0 0 20px;
}

#menu-superior {
    display: flex;    
    justify-content: flex-end;    
}
`, "",{"version":3,"sources":["webpack://./src/app/main/header/header.component.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":["header {\n    top: 0;\n    left: 0;\n    height: 60px;\n    width: 100%;\n    z-index: 99;\n    overflow: hidden;\n    position: fixed;\n}\n    \n#inactive-button-left-menu {\n    margin: 20px 0 0 20px;\n}\n\n#menu-superior {\n    display: flex;    \n    justify-content: flex-end;    \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
