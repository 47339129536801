import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-meetings-dashboard',
  templateUrl: './meetings-dashboard.component.html',
  styleUrls: ['./meetings-dashboard.component.css']
})
export class MeetingsDashboardComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
