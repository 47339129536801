// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    display: inline-block;
    width: 100%;
}

:host label textarea, input[type=text], input[type=number], input[type=date] {
    width: 100%;
}

:host .invalid {
    /* border: 1px solid red; */
}`, "",{"version":3,"sources":["webpack://./src/app/components/forms-input/forms-input.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[":host {\n    display: inline-block;\n    width: 100%;\n}\n\n:host label textarea, input[type=text], input[type=number], input[type=date] {\n    width: 100%;\n}\n\n:host .invalid {\n    /* border: 1px solid red; */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
