import {Injectable} from '@angular/core';
import {MainService} from './main.service';

@Injectable()
export class EquipmentService {

    constructor(private _mainService: MainService) {
    }

    // getEquipmentsBySubArea(param): Observable<any> {
    //     let path = '/equipments-by-sub-areas/' + param.cod_subareas;
    //     return this._mainService.get(path);
    // }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }
}
