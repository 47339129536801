// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebyside {
    float: left;
    width: 50%;
    padding: 8px;
    text-align: center;
}

.progress-flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    align-content: flex-start;
}

.progress-flex .title {
    flex: 100%;
}

.seg-width {
    min-width: 25%;
}

.progress-1 .title, .progress-2 .title {
    text-align: left;
}

.progress-1 {
    color: var(--ui-progress);
}

.progress-2 {
    color: #5cb85c;
}

.progress {
    width: 100%;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
}

.progress-bar {
    background-color: var(--ui-progress);
}

.col-md-3 {
    padding: 0px;
}

.wi-10 {
    width: 10%;
}

.wi-20 {
    width: 20%;
}

.wi-30 {
    width: 30%;
}

.wi-40 {
    width: 40%;
}

.wi-50 {
    width: 50%;
}

.wi-60 {
    width: 60%;
}

.wi-70 {
    width: 70%;
}

.wi-80 {
    width: 80%;
}

.wi-90 {
    width: 90%;
}

.wi-100 {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/form-percentage-evolution/form-percentage-evolution.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oBAAoB;IACpB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":[".sidebyside {\n    float: left;\n    width: 50%;\n    padding: 8px;\n    text-align: center;\n}\n\n.progress-flex{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    align-items: stretch;\n    justify-content: center;\n    align-content: flex-start;\n}\n\n.progress-flex .title {\n    flex: 100%;\n}\n\n.seg-width {\n    min-width: 25%;\n}\n\n.progress-1 .title, .progress-2 .title {\n    text-align: left;\n}\n\n.progress-1 {\n    color: var(--ui-progress);\n}\n\n.progress-2 {\n    color: #5cb85c;\n}\n\n.progress {\n    width: 100%;\n    height: 10px;\n    margin-left: 1px;\n    margin-right: 1px;\n}\n\n.progress-bar {\n    background-color: var(--ui-progress);\n}\n\n.col-md-3 {\n    padding: 0px;\n}\n\n.wi-10 {\n    width: 10%;\n}\n\n.wi-20 {\n    width: 20%;\n}\n\n.wi-30 {\n    width: 30%;\n}\n\n.wi-40 {\n    width: 40%;\n}\n\n.wi-50 {\n    width: 50%;\n}\n\n.wi-60 {\n    width: 60%;\n}\n\n.wi-70 {\n    width: 70%;\n}\n\n.wi-80 {\n    width: 80%;\n}\n\n.wi-90 {\n    width: 90%;\n}\n\n.wi-100 {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
