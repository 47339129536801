import { Injectable } from "@angular/core";
import { CockpitService } from "./cockpit.service";
import { FiveWhyService } from "app/five-why/services/five-why.service";
import {
  switchMap,
  distinctUntilChanged,
  map,
  skip,
  shareReplay,
} from "rxjs/operators";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { Observable } from "rxjs";
import { Shift, Day, ISO8601 } from "../model/shift";

@Injectable()
export class CockpitShiftService {
  constructor(
    private readonly cockpitService: CockpitService,
    private readonly fivewhyService: FiveWhyService
  ) {
    this.navigateToCurrentShift();
  }

  public currentlyVisibleIndexes$ = new BehaviorSubject([0, 0]);

  public currentlyVisibleShifts$: Observable<[Shift, Shift]> =
    this.currentlyVisibleIndexes$.pipe(
      switchMap(([left, right]) =>
        this.futureAndPastShiftsFor7DaysRange$.pipe(
          map(
            (shiftConfig) =>
              shiftConfig.slice(left, right + 1) as [Shift, Shift]
          )
        )
      )
    );

  public navigate(direction: "next" | "previous"): void {
    const currentIndexes = this.currentlyVisibleIndexes$.value;
    const [left, right] = currentIndexes;
    const nextIndexes =
      direction === "next" ? [left + 1, right + 1] : [left - 1, right - 1];

    this.currentlyVisibleIndexes$.next(nextIndexes);
  }

  public navigateToCurrentShift(): void {
    combineLatest([this.futureAndPastShiftsFor7DaysRange$]).subscribe(
      ([shiftConfig]) => {
        const currentShiftIndex = shiftConfig.findIndex(
          (shift) => shift.cronology === "current"
        );
        const left = currentShiftIndex;
        const right = currentShiftIndex + 1;
        this.currentlyVisibleIndexes$.next([left, right]);
      }
    );
  }

  public shiftConfiguration$ = combineLatest([
    this.cockpitService.subscribeToEventBus("FILTER_LOADED"),
    this.cockpitService.cockpitFilters.pipe(skip(3)),
  ]).pipe(
    shareReplay(1),
    distinctUntilChanged(
      ([_, prev], [__, curr]) =>
        prev.locations.toString() === curr.locations.toString() &&
        prev.areas.toString() === curr.areas.toString()
    ),
    switchMap(([_, filters]) => {
      const { areas, locations, userId } = filters;
      if (!areas[0] || !locations[0] || !userId) {
        return of(generateDays());
      }

      return this.fivewhyService.getShift({
        cod_locations: locations[0],
        cod_areas: areas[0],
        cod_user: userId,
      });
    })
  );

  public futureAndPastShiftsFor7DaysRange$: Observable<Shift[]> =
    this.shiftConfiguration$.pipe(
      map(map7DaysFutureAndPast, distinctUntilChanged())
    );
}

export function mapAllShiftsForDate(date: Date, config: Day[]) {
  const weekDay = date.getDay() + 1;
  const day = config.find((d) => d.weekday === weekDay);
  if (!day) return [];
  const shifts = day.shifts.map((shift) => {
    const dayToConcat = date.toISOString().split("T")[0];
    // concat date and shift start/end time, then compare
    const shiftStart = moment.utc(`${dayToConcat}T${shift.start}Z`);
    const shiftEnd = moment.utc(`${dayToConcat}T${shift.end}Z`);
    if (shiftEnd.isBefore(shiftStart)) shiftEnd.add(1, "days");

    return {
      ...shift,
      start: shiftStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ") as ISO8601,
      end: shiftEnd.format("YYYY-MM-DDTHH:mm:ss.SSSZ") as ISO8601,
    } as Shift;
  });

  return shifts;
}

function map7DaysFutureAndPast(shiftConfig: Day[]) {
  const currentDate = moment();
  const next: Shift[] = [];
  const prev: Shift[] = [];
  generateDays().forEach((_, i) => {
    const nextDay = moment
      .utc(currentDate)
      .add(i + 1, "days")
      .toDate();
    const previousDay = moment
      .utc(currentDate)
      .subtract(i + 1, "days")
      .toDate();

    next.push(...mapAllShiftsForDate(nextDay, shiftConfig));
    prev.push(...mapAllShiftsForDate(previousDay, shiftConfig));
  });

  let shifts = [
    ...next,
    ...mapAllShiftsForDate(currentDate.toDate(), shiftConfig),
    ...prev,
  ].sort((a, b) => moment.utc(a.start).diff(moment.utc(b.start)));

  let index = 0;
  do {
    const shift = shifts[index];
    const { start, end } = shift;
    const shiftStart = moment.utc(start);
    const shiftEnd = moment.utc(end);
    if (currentDate.isBetween(shiftStart, shiftEnd)) break;
    index++;
  } while (true);

  shifts = shifts.map((shift, i) => {
    if (i - 1 === index) return { ...shift, cronology: "next" };
    if (i + 1 === index) return { ...shift, cronology: "previous" };
    if (i === index) return { ...shift, cronology: "current" };
    if (i < index) return { ...shift, cronology: "past" };
    return { ...shift, cronology: "future" };
  });

  return shifts;
}

export function generateDays() {
  return Array(7)
    .fill(null)
    .map((_, i) => ({ weekday: i + 1, shifts: [] })) as Day[];
}
