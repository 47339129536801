import { CommentType } from "../types/enums";

/** 
 * Merges an incoming array of objects into an existing array based on a unique key, with options for skipping merges and removing items. 
 * 
 * @param {Array} arr - The original array to be merged into. 
 * @param {Array} other - The incoming array with new data. 
 * @param {string} key - The property key used to identify unique objects in both arrays. 
 * @param {Object} [options] - Optional parameters. 
 * @param {Function} [options.skipCondition] - Function to determine whether to skip merging a particular object. Takes the new object and the existing object. 
 * @param {boolean} [options.removeMissing] - Whether to remove items from the original array that are not present in the incoming array. 
 * @returns {Array} - The original array, merged and updated. 
**/
export const unionMerge = (arr: any[], other: any[], key: string, options?: { skipCondition?: (incoming: any, existing: any) => boolean, removeMissing?: boolean }) => {
    const map = new Map();
    const nullItems = [];
    const removeMissing = options?.removeMissing ?? false;
    const skipCondition = options?.skipCondition ?? ((a,b) => false);

    arr.forEach(item => {
        if (item[key] === null || item[key] === undefined) {
            nullItems.push(item);
        } else if (!removeMissing || other.some(otherMsg => otherMsg[key] === item[key])) { // This will remove from current array anything without null key that is not on incoming array, this will need to change if we make pagination
            map.set(item[key], item);
        }
    });

    other.forEach(item => {
        if (item[key] === null || item[key] === undefined) {
            nullItems.push(item);
        } else {
            const existing = map.get(item[key]);
            if (existing) {
                if(!skipCondition(item, existing)){
                    Object.assign(existing, item);
                }
            } else {
                map.set(item[key], item);
            }
        }
    });

    // Remove duplicates and assign from the map
    const mergedArray = Array.from(map.values());

    // Add null items at the end
    mergedArray.push(...nullItems);

    // Replace original array content
    arr.length = 0; // Clear the array
    arr.push(...mergedArray); // Add values back

    return arr;
}

export const formatName = (fullName: string) => {
    let names = fullName.trim().split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase());
    let firstName = names[0];
    let lastName = '';
    if (names.length > 1){
        lastName = names[names.length - 1];
    }
    return `${firstName} ${lastName}`.trim();
}

export const getFileName = (strFilePath: string): string => {
    const file_name = strFilePath?.split('/').pop() ?? '';
    return file_name;
}


export function getFileType(strFilePath: string): string {
    const file_type = strFilePath?.split('.').pop() ?? '';
    return file_type;
}

export const checkCommentType = (url): CommentType => {
    const fileType = getFileType(url).toLowerCase();
    let type = CommentType.TXT;
    switch (fileType) {
        case 'jpg':
        case 'jpeg':
        case 'png':
            type = CommentType.IMG;
            break;
        case 'pdf':
            type = CommentType.PDF;
            break;
        default:
            break;
    }
    return type;
}

  /**
   * Merges duplicated objects in an array based on a unique key.
   *
   * @param {Array} arr - The array to be processed.
   * @param {string} key - The property key used to identify unique objects.
   * @param {boolean} keep_first - Boolean determining which duplicated object will have precedence when merging between the first or the last to appear on the array
   * @returns {Array} - The array with merged duplicated objects.
   */
export const mergeDuplicated = (arr: any[], key: string, keep_first: boolean = false) => {
    const map = new Map();

    arr.forEach((item) => {
        const existing = map.get(item[key]);
        if(keep_first) {
            if (existing) {
                Object.assign(item, existing);
            } 
            map.set(item[key], item);
        } else if (existing) {
            Object.assign(existing, item);
        } else {
            map.set(item[key], item);
        }
    });

    // Clear the original array and push merged values back
    arr.length = 0;
    arr.push(...Array.from(map.values()));

    return arr;
}

export default {
    unionMerge,
    formatName,
    getFileName,
    checkCommentType,
    mergeDuplicated,
}