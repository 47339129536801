export class Periodicity {
    cod_periodicity: string;
    str_description: string;
    showWeekCombo: boolean;
    showMonthCombo: boolean;

    constructor(id = '0', description = '', showWeekCombo = false, showMonthCombo = false) {
        this.cod_periodicity = id;
        this.str_description = description;
        this.showWeekCombo = showWeekCombo;
        this.showMonthCombo = showMonthCombo;
    }
}