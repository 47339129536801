import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
// COMPONENTS
import {LogbooksAdminComponent} from './logbooks-admin/logbooks-admin.component';
import {LogbooksDashboardComponent} from './logbooks-dashboard/logbooks-dashboard.component';
import {LogbooksMyTeamComponent} from './logbooks-my-team/logbooks-my-team.component';
import {LogbooksListComponent} from './logbooks-list/logbooks-list.component';
import {LogbookPersistComponent} from './logbook-persist/logbook-persist.component';
import {LogbooksService} from './services/logbooks.service';
import {LogbooksRoutinesListComponent} from './logbooks-routines-list/logbooks-routines-list.component';
import {AreaListComponent} from './area-list/area-list.component';
import {AreaPersistComponent} from './area-persist/area-persist.component';
import {ReportsRoutinesComponent} from './reports-routines/reports-routines.component';
import {TestexcelComponent} from './testexcel/testexcel.component';
import {RoutineShowComponent} from './routine-show/routine-show.component';
import {RoutineAcadiaButtonsComponent} from './routine-acadia-buttons/routine-acadia-buttons.component';
import {RoutineStatusButtonsComponent} from './routine-status-buttons/routine-status-buttons.component';
import {RoutineMessagesComponent} from './routine-messages/routine-messages.component';
import {RoutineCommentsListComponent} from './routine-comments-list/routine-comments-list.component';
import {RoutineCommentsListCollabComponent} from './routine-comments-list-collab/routine-comments-list-collab.component';
import {RoutinesPersistComponent} from './routines-persist/routines-persist.component';
import {ButtonEditComponent} from '../components/button-edit/button-edit.component';
import {RoutinesListComponent} from './routines-list/routines-list.component';
import {ButtonDeleteComponent} from '../components/button-delete/button-delete.component';
import {ButtonInsertComponent} from '../components/button-insert/button-insert.component';
import {ButtonBackComponent} from '../components/button-back/button-back.component';
import {AssignLogbookComponent} from './assign-logbook/assign-logbook.component';

import {AreaService} from './services/area.service';
import {LogbookRoutineService} from './services/logbook-routine.service';
import {MainService} from './services/main.service';
import {ValidationsService} from './services/validations/validations.service';
import {PeriodicityTypesService} from './services/periodicity-types.service';
import {RoutinesService} from './services/routines.service';
import {RoutineDataService} from './services/routine-data.service';
import {LogbooksRoutineCommentsService} from './services/logbooks-routine-comments.service';
import {RoutineStatusService} from './services/routine-status.service';
import {UploadExcelService} from '../components/import-excel/services/upload-excel.service';
import {PillarsService} from './services/pillars.service';
// i18n
import {TranslateModule} from '@ngx-translate/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsDynamicModule} from '../forms/forms-dynamic/forms-dynamic.module';
import {ComponentsModule} from '../components/components.module';
import {AuthGuard} from '../guards/auth-guard';
import {SubareaService} from './services/subarea.service';
import {EquipmentService} from './services/equipment.service';
import {ReportsService} from './services/reports.service';
import {LogbooksMyTeamSelectorComponent} from './logbooks-my-team/selector/logbooks-my-team-selector.component';
import {RoutinesConsultComponent} from './routines-consult/routines-consult.component';
import {AreaConsultComponent} from './area-consult/area-consult.component';
import {PeriodicityConsultComponent} from './periodicity-consult/periodicity-consult.component';
import {InputCommentComponent} from './routine-comments-list/input-comment/input-comment.component';
import {SubAreaListComponent} from './subarea-list/subarea-list.component';
import {SubAreaPersistComponent} from './subarea-persist/subarea-persist.component';
import {EquipmentsListComponent} from './equipments-list/equipments-list.component';
import {EquipmentsPersistComponent} from './equipments-persist/equipments-persist.component';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
// import {AuthInterceptorService} from '../main/access/services/auth-interceptor.service';
import { ReportsRoutineOnlineComponent } from './reports-routines-online/reports-routines-online.component';
import { ReportsRoutineCollaborativeOnlineComponent } from './reports-routines-online/collaborative/reports-routines-collaborative-online.component';
import { ReportsRoutineFunctionalOnlineComponent } from './reports-routines-online/functional/reports-routines-functional-online.component';
import { OnlineReportsService } from './services/online-reports';

import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';


const routes: Routes = [
    {
        path: 'logbooks-admin',
        component: LogbooksAdminComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbooks-dashboard',
        component: LogbooksDashboardComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbooks-my-team',
        component: LogbooksMyTeamComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbooks',
        component: LogbooksListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbook/:id',
        component: LogbookPersistComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'areas',
        component: AreaListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'area/:id',
        component: AreaPersistComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'subareas',
        component: SubAreaListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'subarea/:id',
        component: SubAreaPersistComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbooks-routines',
        component: RoutinesListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'logbooks-routines/:id',
        component: RoutinesPersistComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'assign-logbook',
        component: AssignLogbookComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'excel',
        component: TestexcelComponent
    },
    {
        path: 'reports-routines',
        component: ReportsRoutinesComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    }
];

@NgModule({
    declarations: [
        LogbooksAdminComponent,
        LogbooksDashboardComponent,
        LogbooksMyTeamComponent,
        LogbookPersistComponent,
        LogbooksListComponent,
        AreaListComponent,
        AreaPersistComponent,
        LogbooksRoutinesListComponent,
        RoutineShowComponent,
        RoutineAcadiaButtonsComponent,
        RoutineStatusButtonsComponent,
        RoutineMessagesComponent,
        RoutineCommentsListComponent,
        RoutineCommentsListCollabComponent,
        RoutinesListComponent,
        RoutinesPersistComponent,
        ButtonEditComponent,
        ButtonDeleteComponent,
        ButtonInsertComponent,
        ButtonBackComponent,
        AssignLogbookComponent,
        TestexcelComponent,
        ReportsRoutinesComponent,
        LogbooksMyTeamSelectorComponent,
        RoutinesConsultComponent,
        AreaConsultComponent,
        PeriodicityConsultComponent,
        InputCommentComponent,
        SubAreaListComponent,
        SubAreaPersistComponent,
        EquipmentsListComponent,
        EquipmentsPersistComponent,
        ReportsRoutineOnlineComponent,
        ReportsRoutineCollaborativeOnlineComponent,
        ReportsRoutineFunctionalOnlineComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        HttpClientModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        BrowserModule,
        FormsDynamicModule,
        ComponentsModule,
        DragDropModule
    ],
    exports: [
        LogbookPersistComponent,
        LogbooksListComponent,
        AreaListComponent,
        AreaPersistComponent,
        LogbooksRoutinesListComponent,
        RoutineShowComponent,
        RoutineAcadiaButtonsComponent,
        RoutineStatusButtonsComponent,
        RoutineMessagesComponent,
        RoutineCommentsListComponent,
        RoutineCommentsListCollabComponent,
        LogbooksMyTeamComponent,
        ButtonDeleteComponent,
        ButtonEditComponent,
        ButtonBackComponent,
        RoutinesListComponent,
        ReportsRoutinesComponent,
        RoutinesConsultComponent,
        AreaConsultComponent,
        PeriodicityConsultComponent,
        InputCommentComponent,
        SubAreaListComponent,
        EquipmentsListComponent,
        DragDropModule,
        ReportsRoutineOnlineComponent,
        ReportsRoutineCollaborativeOnlineComponent,
        ReportsRoutineFunctionalOnlineComponent
    ],
    providers: [
        LogbooksService,
        AreaService,
        LogbookRoutineService,
        MainService,
        ValidationsService,
        PeriodicityTypesService,
        RoutinesService,
        RoutineDataService,
        LogbooksRoutineCommentsService,
        RoutineStatusService,
        UploadExcelService,
        PillarsService,
        SubareaService,
        EquipmentService,
        ReportsService,
        OnlineReportsService,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
    ],
    bootstrap: []
})
export class LogbooksModule {
}
