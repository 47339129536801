import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';

@Injectable()
export class UsersProfilesService {

  constructor(private _mainService: MainService) {
  }

  getProfiles(term, cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/profiles/'+term + '/' + cod_user + '/' + dsc_page_privilege);
  }
  getAllApprovers(cod_user): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/approvers/' + cod_user);
  }
  verifyExistentRequest(cod_user, cod_user_request): Observable<any> {
    return this._mainService.get('/users-profiles/approval-requests/verify/' + cod_user+ '/' + cod_user_request);
  }
  getAllRequestsApproval(params): Observable<any> {// A_U, get
    return this._mainService.post('/users-profiles/approval-requests', params);
  }
  updateStatusApprovalRequest(params): Observable<any> {// A_U, get
    return this._mainService.post('/users-profiles/approval-requests/status-change', params);
  }
  getSingleProfile(cod_user, cod_profile): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/profile/' + cod_profile + '/' + cod_user);
  }

  getPrivilegesScreen(cod_user): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/privileges-screen/'+cod_user);
  }

  getPrivilegesReport(cod_user): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/privileges-report/'+cod_user);
  }

  getWorlds(cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    return this._mainService.get('/users-profiles/worlds/' + cod_user + '/' + dsc_page_privilege);
  }

  getRegionals(worlds, cod_user, dsc_page_privilege): Observable<any> {
    const path = '/users-profiles/regionals/' + JSON.stringify(worlds) + '/' + cod_user + '/' + dsc_page_privilege;
    return this._mainService.get(path);
  }

  getLocations(regionals, cod_user, dsc_page_privilege): Observable<any> {
    const path = '/users-profiles/locations/' + JSON.stringify(regionals) + '/' + cod_user + '/' + dsc_page_privilege;
    return this._mainService.get(path);
  }

  postProfile(params): Observable<any> {// A_U, ok
    const path = '/users-profiles';
    return this._mainService.post(path, params);
  }

  putProfile(params): Observable<any> {// A_U, ok
    const path = '/users-profiles';
    return this._mainService.put(path, params);
  }

  deleteProfile(params): Observable<any> {// A_U, ok
    const path = '/users-profiles/' + params.cod_profiles + '/' + params.cod_user;
    return this._mainService.delete(path);
  }

  getUserProfileMeetingsTypes(params): Observable<any>{
    const path = '/user-profiles/meetings-types-search';
    return this._mainService.post(path, params);
  }

  getArrayContent(data: any) {
    return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }

  postRenewUserPrivileges(params): Observable<any> {
    const path = '/users-profiles/renew-user-privileges';
    return this._mainService.post(path, params);
  }

}
