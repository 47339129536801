import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerComponent),
    multi: true
};

@Component({
    selector: 'component-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class DatepickerComponent implements ControlValueAccessor {
    @Output()
    selectionChanged: EventEmitter<any> = new EventEmitter<any>();

    @Input() dateType: string = 'date'; //HTML5 input type: date, month, week,

    //The internal data model
    innerValue: any;

    //get accessor
    get model(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    @Input('model')
    set model(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    @Input() maxDate: Date;
    @Input() minDate: Date;

    @Input() disabled: boolean = false;

    componentId: string;

    constructor() {
        this.componentId = 'dp_' + Date.now();
    }

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    onDateChange($event: string) {
      this.selectionChanged.emit($event);
    };

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    /**
     * Remove focus and set focus again to positioning on day field
     */
    changeDatefieldFocus($event) {
        document.getElementById(this.componentId).blur();
        document.getElementById(this.componentId).focus();
    }
}
