import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {LogbooksRoutineCommentsService} from '../services/logbooks-routine-comments.service';
import {ValidationsService} from '../services/validations/validations.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {UploadService} from '../../main/services/upload.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'logbook-comments-list',
    templateUrl: './routine-comments-list.component.html',
    styleUrls: ['./routine-comments-list.component.css']
})
export class RoutineCommentsListComponent implements OnChanges {

    @ViewChild('scrollCommentList') _scrollCommentList: ElementRef;

    @Input('cod_routine') codRoutine: number;
    @Input('cod_logbook_routines_periodicity_users') codLogbookRoutinesPeriodicityUsers: number;
    @Input('can_post_comment') canPostComment: boolean = true;
    @Input('can_list_comments') canListComments: boolean = true;
    @Input() isCollab: boolean = false;
    @Input() codOwner: number;
    @Output() afterPost: EventEmitter<any> = new EventEmitter();
    @Output() onNeedLrpu: EventEmitter<Function> = new EventEmitter();

    user_logged_id: any;
    commentList: any;
    showListComments: boolean = false;
    str_text: string = '';
    formEnable: boolean = true;
    isLoading: boolean = false;
    user: any;
    error_message_upload: string;
    bol_error_upload: boolean = false;
    showError: boolean = false;
    errorMessage: string = '';
    editingComment: boolean = false;
    bol_loading: boolean = false;
    timeToHideMessage: number;
    private timeToHideMessageConstant: number;

    constructor(private _routineCommentService: LogbooksRoutineCommentsService, public validationService: ValidationsService,
                private _authService: AuthService, private _uploadService: UploadService, private _translateService: TranslateService,
                private cdRef: ChangeDetectorRef) {
        this.showError = false;
        this.errorMessage = '';
        this.user = this._authService.getAuthenticatedUser();
        this.editingComment = false;
        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 3000;

        this.user_logged_id = this._authService.getAuthenticatedUser().id;
    }

    public getCommentList() {
        if (this.codRoutine <= 0 || !this.canListComments || this.codLogbookRoutinesPeriodicityUsers <= 0) {
            this.commentList = [];
        }
        else {
            this.isLoading = true;

            if (this.isCollab) {

                let objParam = {
                    'cod_routine': this.codRoutine,
                    'cod_logbook_routines_periodicity_users_collaborative': this.codLogbookRoutinesPeriodicityUsers,
                    'text': null,
                    'file_path_url': environment().uploadGetFilesApiUrl + '/',
                    'cod_user': this.user_logged_id,
                };
                this._routineCommentService.listRoutineCommentCollab(objParam).subscribe(data => {
                        this.commentList = this._routineCommentService.getArrayContent(data);
                        this.showListComments = true;
                        this.isLoading = false;
                    }, err => {
                        this.isLoading = false;
                        console.log(err);
                        let e = JSON.parse(err._body);
                        this._showErrorMessage(e.content);
                        this._authService.errCheck(err);
                    }
                );

            } else {

                let objParam = {
                    'cod_routine': this.codRoutine,
                    'cod_logbook_routines_periodicity_users': this.codLogbookRoutinesPeriodicityUsers,
                    'term': '',
                    'file_path_url': environment().uploadGetFilesApiUrl + '/',
                    'cod_user': this.user_logged_id,
                };
                this._routineCommentService.getCommentsList(objParam).subscribe(data => {
                        this.commentList = this._routineCommentService.getArrayContent(data);
                        this.showListComments = true;
                        this.isLoading = false;
                    }, err => {
                        this.isLoading = false;
                        console.log(err);
                        let e = JSON.parse(err._body);
                        this._showErrorMessage(e.content);
                        this._authService.errCheck(err);
                    }
                );

            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getCommentList();
    }

    private _postComment(params: any) : void {
        if (this.codLogbookRoutinesPeriodicityUsers > 0) {
            this._sendComment(params);
        } else {
            this.onNeedLrpu.emit(cod_lrpu => {
                params.cod_logbook_routines_periodicity_users = cod_lrpu;
                this._sendComment(params);
            });
        }
    }

    private _sendComment(objParam: any) {
        this.formEnable = false;
        objParam.cod_uuid = null;
        this.bol_loading = true;

        this._routineCommentService.postComment(objParam).subscribe(res => {
                this.formEnable = true;
                this.validationService.boolSubmit = false;
                if (res.type == 'error') {
                    this._showErrorMessage(res.content.message);
                }
                else {
                    this.str_text = '';
                    this.getCommentList();
                    this.afterPost.emit(null);
                }
                this.bol_loading=false;
            }, err => {
                this.formEnable = true;
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
                this.bol_loading=false;    
            }
        );
    }

    saveComment($event) {

        let keyCode = $event.which || $event.keyCode;
        
        if (keyCode == 13 || keyCode == 1) {
            this.validationService.boolSubmit = true;

            if (!this.str_text) {
                return false;
            }

            let objParam = {
                'cod_routine': this.codRoutine,
                'cod_logbook_routines_periodicity_users': this.codLogbookRoutinesPeriodicityUsers,
                'cod_user': this.user_logged_id,
                'str_text': this.str_text,
                'str_image': ''
            };

            this._postComment(objParam);
        }
    }

    fileChangeEvent(e) {
        this.bol_error_upload = false;

        let params = {
            'event': e,
            'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
        };
        this._uploadService.uploadFileBase64(params).subscribe(response => {
                if (response.type == 'success') {
                    // const $this = this;
                    // $this._obj_message_form.str_name_image = response.content.str_name;

                    let objParam = {
                        'cod_routine': this.codRoutine,
                        'cod_logbook_routines_periodicity_users': this.codLogbookRoutinesPeriodicityUsers,
                        'cod_user': this.user_logged_id,
                        'str_text': '',
                        'str_image': response.content.str_name
                    };
                    this._postComment(objParam);

                } else {
                    this.bol_error_upload = true;
                    this.timeToHideMessage = this.timeToHideMessageConstant;
                    this.error_message_upload = response.content;
                    this.isLoading = false;
                }

            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this.isLoading = false;
                this._authService.errCheck(err);
            }
        );
    }

    downloadFile(fileUrl) {

        this._uploadService.downloadFile(fileUrl).subscribe(res => {
                let blob = new Blob([res]);

                let a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = this.getFileName(fileUrl);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    getFileName(strFilePath: string) {
        let arr_temp = strFilePath.split('/');
        return arr_temp[arr_temp.length - 1];
    }

    getFileType(strFilePath: string) {
        let arr_temp = strFilePath.split('.');
        return arr_temp[arr_temp.length - 1];
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    deleteComment(comment) {

        let params = {
            'file_name': comment.str_name_image.substring(comment.str_name_image.lastIndexOf('/') + 1)
        };

        if (this.isCollab)  {

            let objParam = {
                'cod_routines_comments_collaborative': comment.cod_routines_comments_collaborative,
                'cod_user': this.user_logged_id
            };
            this._routineCommentService.deleteRoutineCommentCollab(objParam).subscribe(res => {
                    if (res.type == 'error') {
                        this._showErrorMessage(res.content.message);
                    }
                    else {
                        
                        this._uploadService.deleteFile(params).subscribe(response3 => {
                            //doensnt matter error on deleting
                        },err => {
                            console.log(err);
                            this._authService.errCheck(err);
                        });
                        
                        this.getCommentList();
                    }
                }, err => {
                    console.log(err);
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );

        } else  {

            let objParam = {
                'cod_routines_comments': comment.cod_routines_comments,
                'cod_user': this.user_logged_id
            };
            this._routineCommentService.deleteComment(objParam).subscribe(res => {
                    if (res.type == 'error') {
                        this._showErrorMessage(res.content.message);
                    }
                    else {
                        
                        this._uploadService.deleteFile(params).subscribe(response3 => {
                            //doensnt matter error on deleting
                        },err => {
                            console.log(err);
                            this._authService.errCheck(err);
                        });
                        
                        this.getCommentList();
                    }
                }, err => {
                    console.log(err);
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );

        }
    }

    hideMessage() {
        this.bol_error_upload = false;
        this.timeToHideMessage = 0;
    }

}
