// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-disabled {
   pointer-events: none;
   cursor: default;
   background: #7692e2;
}`, "",{"version":3,"sources":["webpack://./src/app/components/button-insert/button-insert.component.css"],"names":[],"mappings":"AAAA;GACG,oBAAoB;GACpB,eAAe;GACf,mBAAmB;AACtB","sourcesContent":[".button-disabled {\n   pointer-events: none;\n   cursor: default;\n   background: #7692e2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
