import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {MainService} from './main.service';

@Injectable()
export class TeamService {
  
    static selectedTask = new EventEmitter<any>();

    constructor(private _mainService:MainService) {

    }

    getUsersTeam(cod_manager, str_path, cod_user): Observable<any> {
        const path = '/team/users-team/' + cod_manager + '/' + encodeURIComponent(str_path) + '/' + cod_user;
        return this._mainService.get(path);
    }

}