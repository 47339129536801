import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { AuthService } from '../../main/access/services/auth.service';
import { UserPreference } from '../../user-preferences/models/user-preference.model';
import Shepherd from 'shepherd.js';

declare var require: any;

@Injectable()
export class HelpTutorialService {
    _userPreferencesService: UserPreferencesService;

    constructor(
        private _translateService: TranslateService,
        private injector: Injector,
        private _authService: AuthService
    ) {}

    startTutorial() {
        this._userPreferencesService = this.injector.get(UserPreferencesService);
        const user = this._authService.getAuthenticatedUser();
        this._userPreferencesService.getHideTutorial(user.id).subscribe({
            next: (data) => {
                if (data.code === 666) {
                    alert(data.content.message);
                }
                if (!data.content.results || data.content.results.str_pref_value !== 'true') {
                    let step = parseInt(data.content.results.str_pref_value);

                    this._translateService.get('HELP_TUTORIAL').subscribe((translations) => {
                        if (step !== step) {
                            setTimeout((_) => this.startTour(translations));
                        } else {
                            setTimeout((_) => this.startTour(translations, step));
                        }
                    });
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    startTour(translations, startStep = null) {
        const span = document.createElement('span');
        const checkbox = document.createElement('input');

        span.className = 'shepherd-checkbox';
        span.textContent = translations['DONT_SHOW_AGAIN'];
        checkbox.setAttribute('type', 'checkbox');
        checkbox.id = 'shepherd-checkbox';
        checkbox.checked = false;

        span?.insertBefore(checkbox, span.firstChild);

        const tour = new Shepherd.Tour({
            confirmCancel: false,
            confirmCancelMessage: null,
            keyboardNavigation: true,
            useModalOverlay: true,
            exitOnEsc: false,
            defaultStepOptions: {
                scrollTo: false,
                cancelIcon: {
                    enabled: true,
                    label: translations['SKIP'],
                },
                when: {
                    show() {
                        const currentStep = Shepherd.activeTour?.getCurrentStep();
                        const currentStepElement = currentStep?.getElement();
                        const header = currentStepElement?.querySelector('.shepherd-header');
                        const footer = currentStepElement?.querySelector('.shepherd-footer');
                        const progress = document.createElement('span');
                        progress.className = 'shepherd-progress';
                        progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1}/${
                            Shepherd.activeTour?.steps.length
                        }`;

                        header?.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
                        footer?.insertBefore(span, currentStepElement.querySelector('.shepherd-button:first-child'));
                    },
                },
            },
        });

        let steps = [
            {
                id: 'tour-step-1',
                text: translations['STEP_1'],
                attachTo: {
                    element: '#sidebar-left-nav', //'#main-menu',
                    on: 'right',
                },
                //classes: '',
                buttons: [
                    {
                        text: translations['NEXT'],
                        action: tour.next,
                    },
                ],
            },
            {
                id: 'tour-step-2',
                text: translations['STEP_2'],
                attachTo: {
                    element: '.user-pref', //'#user-preferences-menu',//'#menu-superior',
                    on: 'bottom-end',
                },
                //classes: '',
                buttons: [
                    {
                        text: translations['PREVIOUS'],
                        secondary: true,
                        action: tour.back,
                    },
                    {
                        text: translations['NEXT'],
                        action: tour.next,
                    },
                ],
            },
            {
                id: 'tour-step-3',
                text: translations['STEP_3'], 
                attachTo: {
                    element: '.snow-button', //'#user-preferences-menu',//'#menu-superior',
                    on: 'bottom-end',
                },
                //classes: '',
                buttons: [
                    {
                        text: translations['PREVIOUS'],
                        secondary: true,
                        action: tour.back,
                    },
                    {
                        text: translations['DONE'],
                        action: tour.next,
                    },
                ],
            },
        ]
        if(startStep && 0 < startStep && startStep < steps.length){
            steps = steps.slice(startStep);
            steps[0].buttons = steps[0].buttons.filter(b => b.action != tour.back)
        } 
        tour.addSteps(steps);

        tour.on('complete', () => {
            document.body.style.overflow = null;
            this.handleNextTime(checkbox.checked);
        });
        tour.on('cancel', () => {
            document.body.style.overflow = null;
            this.handleNextTime(checkbox.checked);
        });
        document.body.style.overflow = 'hidden';
        tour.start();
    }

    handleNextTime(dontShowAgain) {
        if (dontShowAgain) {
            const userPreference = new UserPreference();
            userPreference.cod_user = this._authService.getAuthenticatedUser().id;
            userPreference.str_pref_value = dontShowAgain.toString();
            this._userPreferencesService.postHideTutorial(userPreference).subscribe(
                (data) => {
                    if (data.code === 666) {
                        alert(data.content.message);
                    }
                },
                (err) => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }
}
