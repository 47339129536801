import { Whys } from "./whys.model";
import * as moment from "moment";

export class FiveWhy {
    cod_user: number;
    cod_area: number;
    str_name: string;
    cod_shift: number;
    cod_crew: number;
    cod_status: number;
    cod_subarea: number;
    cod_machine: number;
    cod_trigger: number;
    cod_location: number;
    cod_usu_ins: number;
    cod_sdca: any;
    dat_reported: string;
    cod_category: number;
    cod_equipment: number;
    bol_canceled: boolean;
    bol_ab_reported: boolean;
    str_cancel_comment: string;
    str_name_usu_canceled: string;
    dat_alt: any;
    dat_closed: any;
    dat_completed: any;
    dat_ins: string;
    str_name_usu_ins: string;
    dat_canceled: string;
    cod_usu_canceled: number;


    whys: Whys[];
    sdca_matrix:[];
    arr_ab_report_files: any[];
    
    bol_closed: boolean;
    str_created_by: any;
    dat_creation: string;
    cod_fivewhys: number;
    str_status_name: string;
    str_machine_name: string;
    str_trigger_name: string;
    str_category_name: string;
    str_fivewhys_name: string;
    str_area_description: any;
    str_equipment_name: string;
    str_problem_details: string;
    str_subarea_description: any;
    bol_root_cause_found: boolean;
    str_location_description: any;
    str_corrective_actions: string;
    bol_repeating_problem: boolean;
    bol_reaction_plan_followed: boolean;
    machine_dat_del: any;
    trigger_dat_del: any;
    bol_reopen: boolean;
    bol_root_cause_changed:boolean;
    bol_ab_report_changed: boolean;
    bol_abort_changed: boolean;
    current_user_date: any;


    constructor() {
        this.cod_fivewhys = 0;
        this.cod_area = 0;
        this.str_name = '';
        this.cod_shift = 0;
        this.cod_status = 1;
        this.cod_subarea = 0;
        this.cod_machine = 0;
        this.cod_trigger = 0;
        this.cod_location = 0;
        this.cod_sdca = [];
        this.dat_creation = '';
        this.str_created_by = [] 
        this.str_status_name = ''; 
        this.bol_canceled = false;
        this.str_machine_name = '';
        this.str_trigger_name = '';
        this.str_fivewhys_name = '';
        this.str_category_name = '';
        this.str_equipment_name = '';
        this.str_problem_details = '';
        this.bol_ab_reported = false;
        this.arr_ab_report_files = [];
        this.str_area_description = [];
        this.str_corrective_actions = '';
        this.str_subarea_description = [];
        this.str_location_description = [];
        this.bol_repeating_problem = false;
        this.bol_reaction_plan_followed = false;
        this.dat_reported = moment(new Date()).format(
            "YYYY-MM-DD"
        );
        this.machine_dat_del = '';
        this.trigger_dat_del = '';
        this.bol_reopen = false;
        this.current_user_date ='';
    }
}
