import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainService } from './main.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../main/access/services/auth.service';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class KpisService extends MainService {

    constructor(_http: HttpClient, _authService: AuthService, _userPreferenceService: UserPreferencesService) {
        super(_http, _authService, _userPreferenceService);
        this._api_url = environment().problemSolvingApiUrl;
    }

    /**
     * Display list of all equipments types.
     * @param param: JSON object filled with search "term"
     */
    getKpisList(param: any): Observable<any> {
        const path = `/kpis:paginated?_page=${param.page}&_size=${param.size}&_term=${param.term}&_order=name asc`;
        return this.get(path);
    }

    getArrayContent(data: any): Object[] {
        return data.content.data;
    }
}
