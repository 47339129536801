import { Injectable } from '@angular/core';
import { MainService } from '../../forms/forms-dynamic/services/main.service';
import { Observable } from 'rxjs';


@Injectable()
export class MyFormsService {
    constructor(
        private _mainService: MainService
    ) { }

    getMyForms(params) {
        const path = `/list-my-forms`;
        return this._mainService.post(path, params);
    }

    getLocationForms(params) {
        const path = '/get-all-location-by-privileges';
        return this._mainService.post(path, params)
    }

    getWorldForms(params) {
        const path = '/get-all-world-by-privileges';
        return this._mainService.post(path, params)
    }

    getUserForms(params) {
        const path = '/get-all-users-by-privileges';
        return this._mainService.post(path, params)
    }    

    getFormsName(params) {
        const path = '/get-all-forms-name-by-privileges';       
        return this._mainService.post(path, params)
    }

    getFormsByLocation(params) {
        const path = '/get-forms-by-location';
        return this._mainService.post(path, params)
    }

    getFormDetailsCollab(params) {
        const path = '/get-details-form-collab';
        return this._mainService.post(path, params)
    }

    getFormDetails(params) {
        const path = '/get-details-form';
        return this._mainService.post(path, params)
    }

    sendEmailSubmitForms(params) {
        const path = '/submit-notification';
        return this._mainService.post(path, params)
    }

    getNameByUserLanguage(userLanguage, form, columnName = 'str_name') {
        return (form[`${columnName}_${userLanguage}`])
                ? form[`${columnName}_${userLanguage}`]
                : form[`${columnName}_en`];
    }

    getQuestionTextByUserLanguage(userLanguage, question, columnName = 'str_text') {
        return (question[`${columnName}_${userLanguage}`])
                ? question[`${columnName}_${userLanguage}`]
                : question[`${columnName}_en`];
    }

    getChoiceTextByUserLanguage(userLanguage, choice, columnName = 'str_text') {
        return (choice[`${columnName}_${userLanguage}`])
                ? choice[`${columnName}_${userLanguage}`]
                : choice[`${columnName}_en`];
    }
    
    postFeedback(params){
        const path = '/persist-feedback';
        return this._mainService.post(path, params);
    }

    postCollabFeedback(params){
        const path = '/persist-collab-feedback';
        return this._mainService.post(path, params);
    }

    getFeedback(params){
        const path = '/get-feedback';
        return this._mainService.post(path, params);
    }

    getCollabFeedback(params){
        const path = '/get-collab-feedback';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }
}
