// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

.is-integration {
    color: #FF5555;
    font-size: 0.9em;
}

.is-integration::before {
    content: '*';
}

`, "",{"version":3,"sources":["webpack://./src/app/logbooks/equipments-list/equipments-list.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n.is-integration {\n    color: #FF5555;\n    font-size: 0.9em;\n}\n\n.is-integration::before {\n    content: '*';\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
