import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KpisService {

  constructor(private readonly _mainService: MainService) { }

  getKpis(params = null){
    const path = `/kpis`;
    return this._mainService.getProblemSolving(path, params).pipe(shareReplay(1)); // Unless last was error, will just replay the cached response
  }

  postKpis(params) {
    const path = `/meeting-kpis`;
    return this._mainService.post(path, params);
  }

}
