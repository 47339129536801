import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'component-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnChanges {
    // message to be displayed
    @Input() message: String = 'Oops, algo deu errado!';

    // level of the alert (success, warning, or error)
    @Input() level: String = 'warning';

    @Input() timeToHide: number = 0;
    @Output() hidenEmitter = new EventEmitter<any>();

    showing: boolean = true;

    // tells what's the alert level
    getLevel() {
        switch (this.level) {
            case 'success':
                return 'success';

            case 'info':
                return 'info';

            case 'error':
            case 'danger':
                return 'error';
            case 'infoGray':
                return 'infoGray';

            default:
                return 'warning';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.timeToHide > 0) {
            let $this = this;
            $this.showing = true;
            window.setTimeout(function () {
                $this.showing = false;
                $this.timeToHide = 0;
                $this.hidenEmitter.emit($this.showing);
            }, this.timeToHide);
        }
    }
}
