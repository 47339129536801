import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'component-select-delimiter',
  templateUrl: './select-delimiter.component.html',
  styleUrls: ['./select-delimiter.component.css']
})
export class SelectDelimiterComponent implements OnInit {

  toggled = false;
  @Input() items: Array<any>;
  @Input() currentValue: string;
  @Input() changeDelimiter: (event) => void;

  constructor() { }

  ngOnInit() {
  }

  toggleOpen() {
    this.toggled = !this.toggled;
  }
}
