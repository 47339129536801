// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-bg{
    color:var(--text-secondary);
}

.primary-bg i{
    margin-left: 5px;
}

.line{
    margin-bottom:10px;
}

.glyphicon-menu-right{
    line-height: 55px;
    margin-left: 10px;
}

.late{
    font-size:12px;
    margin-top: 19px;
    width: 130px;
    text-align: center;
}

.late .late-highlight{
    font-size: 1.12em;
    padding-right: 6px;
}

.glyph-warning{
    height: 17px;
    width: 17px;
    margin: 20px;
}
.meet-name {
    padding: 0 10px 0 10px;
}
.pd_zone{
    padding-left: 21px;
}

.color-text{
    color: #666666 !important; 
}

.result-line div:not(:last-child) {
    line-height: 15px;
    margin-top: 15px;
}

.no-results {
    line-height: 56px;
    margin-bottom: 10px;
    padding: 0 10px 0 10px;
}

.break-all{
    word-break: break-all;
}

.no-margins{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/meetings-location/meetings-location.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".primary-bg{\n    color:var(--text-secondary);\n}\n\n.primary-bg i{\n    margin-left: 5px;\n}\n\n.line{\n    margin-bottom:10px;\n}\n\n.glyphicon-menu-right{\n    line-height: 55px;\n    margin-left: 10px;\n}\n\n.late{\n    font-size:12px;\n    margin-top: 19px;\n    width: 130px;\n    text-align: center;\n}\n\n.late .late-highlight{\n    font-size: 1.12em;\n    padding-right: 6px;\n}\n\n.glyph-warning{\n    height: 17px;\n    width: 17px;\n    margin: 20px;\n}\n.meet-name {\n    padding: 0 10px 0 10px;\n}\n.pd_zone{\n    padding-left: 21px;\n}\n\n.color-text{\n    color: #666666 !important; \n}\n\n.result-line div:not(:last-child) {\n    line-height: 15px;\n    margin-top: 15px;\n}\n\n.no-results {\n    line-height: 56px;\n    margin-bottom: 10px;\n    padding: 0 10px 0 10px;\n}\n\n.break-all{\n    word-break: break-all;\n}\n\n.no-margins{\n    margin-top: 0px;\n    margin-bottom: 0px;\n    margin-left: 0px;\n    margin-right: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
