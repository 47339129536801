import {Injectable} from '@angular/core';
import {CsvMainService} from './csv-main.service';

@Injectable()
export class DownloadCsvService {

  constructor(private _mainService: CsvMainService) {
  }

  postDownloadReports(searchParams) {
    const path = '/downloadReport';
    return this._mainService.postReport(path, searchParams);
  }  
}
