import { Whys } from "../model/whys.model";
import { AuthService } from "app/main/access/services/auth.service";
import { AddWhysHelperService } from "../services/add-whys-helper-service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-add-whys",
  templateUrl: "./add-whys.component.html",
  styleUrls: ["./add-whys.component.css"],
})
export class AddWhysComponent implements OnInit {
  @Input("existingWhys") existingWhys: Whys[] = [];
  @Input("disabled") disabled: boolean = false;
  @Input("rootCauseFounded") rootCauseFounded: boolean;
  @Input("disableWhyComments") disableWhyComments: boolean = false;
 
  @Output("rootCauseChanged") rootCauseFoundChanged = new EventEmitter<boolean>();

  whys: Whys[] = [];
  codUserLogged: string;
  constructor(
    private _authService: AuthService,
    private _addWhysHelperService: AddWhysHelperService
  ) {
    this.codUserLogged = this._authService.getAuthenticatedUser().id;
  }

  ngOnInit() {
    if (this.existingWhys.length > 0) {
      this.whys = JSON.parse(JSON.stringify(this.existingWhys));
      this.validateIfCauseFound();
    }

    this._addWhysHelperService.sendWhys(this.whys);
  }

  addWhy() {
    this.whys.push(new Whys());

    this.whys.forEach(why => {
        why.bol_root_cause_found = false;
    });

    this.validateIfCauseFound();
  }

  onWhyChange(event) {
    this.validateIfCauseFound();
    this._addWhysHelperService.sendWhys(this.whys);
  }

  validateIfCauseFound() {
    const validationRootCause = this.whys.some((why) => why.bol_root_cause_found);
    if (this.rootCauseFounded != validationRootCause) {
      this.rootCauseFoundChanged.emit(validationRootCause);

      this.rootCauseFounded = validationRootCause;
    }
  }

  whyDeleted(index) {
    this.whys.splice(index, 1);

    this.validateIfCauseFound();
    this._addWhysHelperService.sendWhys(this.whys);
  }
}
