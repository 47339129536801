import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationsService} from '../services/validations/validations.service';
import {AuthService} from '../../main/access/services/auth.service';
import {Routine} from '../models/routine.model';
import {RoutinesService} from '../services/routines.service';
import {FormsDynamicAdminService} from '../../forms/forms-dynamic/services/forms-dynamic.service';
import {TranslateService} from '@ngx-translate/core';
import {PillarsService} from '../services/pillars.service';
import {NotificationService} from "./../../main/services/notification.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import {UsersService} from '../../admin/services/users.service';


@Component({
    selector: 'app-routine-persist',
    templateUrl: './routines-persist.component.html',
    styleUrls: ['./routines-persist.component.css']
})
export class RoutinesPersistComponent implements AfterViewInit, OnChanges {

    @Input('codRoutine') id;
    @Output() backEvent = new EventEmitter<Object>();
    @ViewChild('routinePersistForm', { static: true }) routineFormContainer: ElementRef;

    routine: Routine;
    formList: Object[];
    pillarList: any = [];
    isLoading: boolean = false;
    cod_user: number = this._authService.getAuthenticatedUser().id;
    dsc_form_privilege: string = 'ADMIN_FORMS_LISTING';
    showError: boolean = false;
    errorMessage: string = '';
    str_link: any;
    str_link_desc: any;
    arr_links: any;
    arr_links_desc: any;
    arr_cod_links_del: any;
    linkIdx: any = -1;
    link_timeout: any;

    selectedWorlds = [];
    editMode: Boolean;
    isLoadingWorld: boolean;
    regionals = [];
    worldList = [];

    constructor(private _routineService: RoutinesService,
            private _route: ActivatedRoute,
            private _router: Router,
            public _validationService: ValidationsService,
            private _authService: AuthService,
            private _formService: FormsDynamicAdminService,
            private _translateService: TranslateService,
            private _pillarsService: PillarsService,
            private _usersService: UsersService,
            private _notify: NotificationService,
            private sanitizer: DomSanitizer
    ) {
        this.routine = new Routine(0);
        this.formList = new Array(0);
        // this.routine.forms = new Array(0);
        // this.routine.pillars = new Array(0);
        this.showError = false;
        this.errorMessage = '';
        this.arr_links = [];
        this.arr_links_desc = [];
        this.str_link = '';
        this.str_link_desc = '';
        this.arr_cod_links_del = [];
        this.selectedWorlds = [];
    }

    ngAfterViewInit() {
        this.get();
        this.searchPillars();
        this.loadWorld();
    }

    get() {
        this.isLoading = true;
        if (this.id <= 0) {
            this.routine = new Routine(0);
            this.arr_links = [];
            this.arr_links_desc = [];
            this.arr_cod_links_del = [];
            this.isLoading = false;
            this.selectedWorlds = [];
        } else {
            const objParam = {
                'cod_routine': this.id,
                'term': '',
                'limit': null,
                'offset': null,
                'dsc_page_privilege': 'NOT_PRIVILEGE',
                'cod_user': this.cod_user
            };
            this.selectedWorlds = [];
            this._routineService.getRoutineList(objParam).subscribe(data => {

                    this.routine = data.content.results;
                    this.selectedWorlds = [{cod_locations_world: this.routine.cod_world}];
                    this.loadForms();

                    // change file extension
                    this.routine.hyperlinks.map(it => {
                        it['str_click_link'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it['str_link']));
                    });

                    this.arr_links = [];
                    this.arr_links_desc = [];
                    this.arr_cod_links_del = [];
                    this.isLoading = false;
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    getAcadiaDocument() {
        return this.routine.str_acadia_document ? this.routine.str_acadia_document.trim() : null;
    }

    public saveRoutine() {
        const str_acadia_document: string = this.getAcadiaDocument();

        this.addLink();
        this._validationService.boolSubmit = true;
        this._validationService.boolIsLoading = true;

        if (!this.routine.str_name) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_STR_NAME');
            return false;
        }

        if (!this.routine.str_description) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_STR_DESCRIPTION');
            return false;
        }

        if (this.selectedWorlds[0] == undefined || !this.selectedWorlds[0].cod_locations_world) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_WOLRD');
            return false;
        }

        if (str_acadia_document && this.routine.forms.length > 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.MESSAGE_ROUTINE_FORM_ACADIA');
            return false;
        }

        let params: any;

        if (this.routine.hyperlinks.length > 0) {
            for (let i = 0; i < this.routine.hyperlinks.length; i++) {
                if (this.routine.hyperlinks[i]['cod_routines_hyperlinks'] == null) {
                    // console.log(1, this.routine.hyperlinks[i]);
                    this.arr_links.push(this.routine.hyperlinks[i]['str_link']);
                    this.arr_links_desc.push(this.routine.hyperlinks[i]['str_link_desc']);
                }
            }
        }

        if (this.id <= 0) {
            params = {
                'str_name': this.routine.str_name,
                'str_description': this.routine.str_description,
                'pillars': this.routine.pillars,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'forms': this.routine.forms,
                'arr_links': this.arr_links,
                'arr_links_desc': this.arr_links_desc,
                'arr_cod_links_del': this.arr_cod_links_del,
                'cod_world': this.selectedWorlds[0].cod_locations_world,
                'str_acadia_document': str_acadia_document,
                'bol_sop_reaction_plan_training_document': this.routine.bol_sop_reaction_plan_training_document
            };

            this._routineService.postRoutine(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        alert(res.content.message);
                    } else {
                        this._notify.success({text: 'LOGBOOK.ROUTINE_INSERTED', translate: true});
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    const e = JSON.parse(err._body);
                    alert(e.content);
                    this._authService.errCheck(err);
                }
            );
        } else {
            params = {
                'str_name': this.routine.str_name,
                'str_description': this.routine.str_description,
                'cod_routine': this.routine.cod_routines,
                'pillars': this.routine.pillars,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'forms': this.routine.forms,
                'arr_links': this.arr_links,
                'arr_links_desc': this.arr_links_desc,
                'arr_cod_links_del': this.arr_cod_links_del,
                'cod_world': this.selectedWorlds[0].cod_locations_world,
                'str_acadia_document': str_acadia_document,
                'bol_sop_reaction_plan_training_document': this.routine.bol_sop_reaction_plan_training_document
            };
            this._routineService.putRoutine(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        alert(res.content.message);
                    } else {
                        this._notify.success({text: 'LOGBOOK.ROUTINE_EDITED', translate: true});
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    const e = JSON.parse(err._body);
                    alert(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.get();
        let $this = this;
        window.setTimeout(function () {
            $this.routineFormContainer.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
        }, 250);
    }

    selectedFormChanged($event, i) {
        this.routine.forms = $event.selectedOptions;
    }

    loadForms() {
        this._formService.list('', this.cod_user, this.dsc_form_privilege, this.selectedWorlds.map(i => i.cod_locations_world)).subscribe(data => {
                this.formList = this._formService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    backSelected(success: boolean = false) {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (success) {
            this.id = -1;
        } else {
            this.id = 0;
        }
        this.routine = new Routine(0);
        this.arr_links = [];
        this.arr_links_desc = [];
        this.arr_cod_links_del = [];
        this.backEvent.emit(this.id);
    }

    selectPillars($event) {
        this.routine.pillars = $event.selectedOptions;
    }

    searchPillars() {
        const objParam = {
            'cod_pillars': 0,
            'term': '',
            'cod_user': this._authService.getAuthenticatedUser().id
        };
        this._pillarsService.getPillarList(objParam).subscribe(data => {
                this.pillarList = this._pillarsService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._notify.warning({text: err, translate: true});
        /*this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });*/
    }

    addLink() {
        if (!!this.str_link) {
            const pattern = /^https?:\/\/.+/; // check if starts with https://
            const pattern2 = /^file:\/\/\/?.+/; // check if starts with file:///
            const pattern3 = /^\\\\.+/; // check if starts with \\
            const pattern4 = /^[a-zA-Z]:(\/|\\).+/; // check if starts with C:/
            this.str_link = this.str_link.replace(/^[^\x00-\x7F]/gmu, "");            
            // console.log(pattern.test(this.str_link), pattern2.test(this.str_link));

            if (!pattern.test(this.str_link)) { // if doesn't start with https://

                if (!pattern2.test(this.str_link)) { // if doesn't start with file:///
                    if (pattern3.test(this.str_link) || pattern4.test(this.str_link)) { // if starts with either \\ or C:/
                        this.str_link = 'file:///' + this.str_link; // then is a file:/// link
                    } else { // else is a normal https:// link
                        this.str_link = 'https://' + this.str_link;
                    }

                }// if already starts with file:/// do nothing
            }// if already starts with https:// do nothing

            this.routine.hyperlinks.push({
                cod_routines_hyperlinks: null,
                str_link: this.str_link,
                str_link_desc: this.str_link_desc
            });
        }
        this.str_link = '';
        this.str_link_desc = '';

        this.routine.hyperlinks.map(it => {
            it['str_click_link'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it['str_link']));
        });

        // console.log(this.arr_cod_links);
    }

    removeLink(index) {
        if (this.routine.hyperlinks[index]['cod_routines_hyperlinks'] != null) {
            this.arr_cod_links_del.push(this.routine.hyperlinks[index]['cod_routines_hyperlinks']);
        }
        this.routine.hyperlinks.splice(index, 1);
    }

    copyLink(str_hyperlink, i, copy = false) {
        const pattern = /^https?:\/\/.+/;
        // let pattern2 = /^(file:\/\/\/?)?\\\\.+/;

        if (!pattern.test(str_hyperlink) || copy === true) {
            this.copyToClipboard(str_hyperlink);
            // alert('Hyperlink copied to clipboard: \n'+ str_hyperlink);
            this.linkIdx = i;
            clearTimeout(this.link_timeout);
            this.link_timeout = setTimeout(() => {
                this.linkIdx = -1;
            }, 3000);
        }
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    selectWorld($event) {
        this.selectedWorlds = $event.selectedOptions;
        this.loadForms();
    }

    loadWorld() {
        this.isLoadingWorld = true;
        this.worldList = [];
        this._usersService.getWorlds( this.cod_user, 'ADMIN_LOGBOOKS_ROUTINES').subscribe(data => {
                this.isLoadingWorld = false;
                this.worldList = this._usersService.getArrayContent(data);
            },
            err => {
                this.isLoadingWorld = false;
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    downloadExtension(file) {
        //console.log(file);
        const extension = file.split('.').pop();
        let newFile = file;

        // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
        switch (extension) {
          case 'xls':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'xlsx':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'xlsm':
            newFile = `ms-excel:ofe|u|${file}`;
            break;             
          case 'csv':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'doc':
            newFile = `ms-word:ofe|u|${file}`;
            break;
          case 'docx':
            newFile = `ms-word:ofe|u|${file}`;
            break;
          case 'ppt':
            newFile = `ms-powerpoint:ofe|u|${file}`;
            break;
          case 'pptx':
            newFile = `ms-powerpoint:ofe|u|${file}`;
            break;
          default:
            break;
        }
        // REPLACING BACKWARD SLASH TO FORWARD SLASH TO FIX WRONG LOCAL URL
        newFile = newFile.replace(/\\/g, '/');
        return newFile;
    }
    
    validateAcadiaDocument($event: any):void {
        let fieldValue:string = $event.target.value;

        if (fieldValue !== undefined){
            const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
            const p = new RegExp(a.split('').join('|'), 'g');

            fieldValue = fieldValue.trim()
                .replace(p, '')// Replace special chars
                .replace(/&/g, '');// Replace & with 'and'
                //.replace(/[\s\W-]+/g, '');// Replace spaces, non-word characters and dashes with

            this.routine.str_acadia_document = fieldValue;
            $event.target.value = fieldValue;

            if (!this.getAcadiaDocument())
                this.routine.bol_sop_reaction_plan_training_document = false;
        }
    }
}
