import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {InfoComponent} from './help-content/help-content.component';
import {FormsDynamicModule} from '../../forms/forms-dynamic/forms-dynamic.module';
import {LogbooksModule} from '../../logbooks/logbooks.module';
import {AuthGuard} from '../../guards/auth-guard';
import {ComponentsModule} from '../../components/components.module';
import {MainService} from '../../user-preferences/services/main.service';
import {UsersService} from '../../admin/services/users.service';
import {UsersProfilesService} from '../../admin/services/users-profiles.service';
import {InfoListComponent} from './help-list/help-list.component';
import {InfoTopicsComponent} from './help-topics/help-topics.component';
import {InfoSelectItemComponent} from './help-select-item/help-select-item.component';
import {InfoHostDirective} from './help-host.directive';
import {InfoService} from '../services/help.service';
import {RoutinesConsultComponent} from '../../logbooks/routines-consult/routines-consult.component';
import {AreaConsultComponent} from '../../logbooks/area-consult/area-consult.component';
import {PeriodicityConsultComponent} from "../../logbooks/periodicity-consult/periodicity-consult.component";
import {CategoriesConsultComponent} from "../../five-why/categories-consult/categories-consult.component";
import {EquipmentsConsultComponent} from "../../five-why/equipments-consult/equipments-consult.component";
import {MeetingTopic1Component} from 'app/main/help/help-meetings/meeting-topic-1/meeting-topic-1.component';
import {MeetingTopic2Component} from 'app/main/help/help-meetings/meeting-topic-2/meeting-topic-2.component';
import {MeetingTopic3Component} from 'app/main/help/help-meetings/meeting-topic-3/meeting-topic-3.component';
import {InfoMeetingsModule} from './help-meetings/help-meetings.module';
import {MeetingTopic4Component} from './help-meetings/meeting-topic-4/meeting-topic-4.component';
import {MeetingTopic5Component} from './help-meetings/meeting-topic-5/meeting-topic-5.component';
import {MeetingTopic7Component} from './help-meetings/meeting-topic-7/meeting-topic-7.component';
import {MeetingTopic9Component} from './help-meetings/meeting-topic-9/meeting-topic-9.component';
import {InfoMyTeamModule} from './help-my-team/help-my-team.module';
import {MyTeamTopic1Component} from './help-my-team/my-team-topic-1/my-team-topic-1.component';
import {MyTeamTopic2Component} from './help-my-team/my-team-topic-2/my-team-topic-2.component';
import {InfoTaskModule} from './help-tasks/help-task.module';
import {TaskTopic1Component} from './help-tasks/task-topic-1/task-topic-1.component';
import {TaskTopic2Component} from './help-tasks/task-topic-2/task-topic-2.component';
import {ReportTopic1Component} from './help-reports/report-topic-1/report-topic-1.component';
import {ReportTopic2Component} from './help-reports/report-topic-2/report-topic-2.component';
import {InfoReportModule} from './help-reports/help-reports.module';
import {ReportTopic3Component} from './help-reports/report-topic-3/report-topic-3.component';
import {InfoMobileModule} from './help-mobile/help-mobile.module';
import {MobileTopic1Component} from './help-mobile/mobile-topic-1/mobile-topic-1.component';
import {InfoLogbookModule} from './help-logbook/help-logbook.module';
import {LogbookTopic1Component} from './help-logbook/logbook-topic-1/logbook-topic-1.component';
import {LogbookTopic2Component} from './help-logbook/logbook-topic-2/logbook-topic-2.component';
import {LogbookTopic3Component} from './help-logbook/logbook-topic-3/logbook-topic-3.component';
import {LogbookTopic4Component} from './help-logbook/logbook-topic-4/logbook-topic-4.component';
import {LogbookTopic5Component} from './help-logbook/logbook-topic-5/logbook-topic-5.component';
import {InfoGeneralModule} from './help-general/help-general.module';
import {GeneralTopic3Component} from './help-general/general-topic-3/general-topic-3.component';
import {MeetingTopic10Component} from "./help-meetings/meeting-topic-10/meeting-topic-10.component";
import {MeetingTopic11Component} from "./help-meetings/meeting-topic-11/meeting-topic-11.component";
import {MeetingTopic12Component} from "./help-meetings/meeting-topic-12/meeting-topic-12.component";
import {MeetingTopic6Component} from './help-meetings/meeting-topic-6/meeting-topic-6.component';
import {MeetingTopic8Component} from './help-meetings/meeting-topic-8/meeting-topic-8.component';
import {MeetingTopic13Component} from './help-meetings/meeting-topic-13/meeting-topic-13.component';
import {MeetingTopic14Component} from './help-meetings/meeting-topic-14/meeting-topic-14.component';
import { MsalGuard } from '@azure/msal-angular';
import { HelpReuseStrategy } from './help-reuse-strategy';
// routes
const routes: Routes = [
    {
        path: 'info',
        component: InfoComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
        // data: {
        //     reuseComponent: false
        // }
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule,
        LogbooksModule,
        InfoMeetingsModule,
        InfoMyTeamModule,
        InfoTaskModule,
        InfoReportModule,
        InfoMobileModule,
        InfoLogbookModule,
        InfoGeneralModule
    ],
    exports: [InfoComponent],
    declarations: [InfoComponent, InfoListComponent, InfoTopicsComponent, InfoSelectItemComponent, InfoHostDirective],
    providers: [
        MainService,
        UsersService,
        UsersProfilesService,
        InfoService,
        { provide: RouteReuseStrategy, useClass: HelpReuseStrategy }
    ]
})
export class InfoModule {
}
