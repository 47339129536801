import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListMaintenancePlanService} from './services/list-maintenance-plan.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-maintenance-plan',
    templateUrl: './list-maintenance-plan.component.html',
    styleUrls: ['./list-maintenance-plan.component.css']
})

export class ListMaintenancePlanComponent implements OnInit {

    maintenancePlanList: any = [];
    obj_ta_mp  ;
    bol_show_typeahead: Boolean = true;
    str_user_tmp = '';
    cod_component_mp: number;

    @Input() question;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _listMaintenancePlanService: ListMaintenancePlanService, private _authService:AuthService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.maintenancePlanList = [{ cod_answer_option: this.question.cod_answer_option, str_sap_code: this.question.str_answer_text || this.question.answer  }];    
            this.bol_show_typeahead = false;
        }
        this.loadMaintenancePlan();
    }

    loadMaintenancePlan() {
        this.obj_ta_mp = {
            customTemplate: '<div><span style="cursor:pointer">{{item.str_sap_code}}</span></div>',
            type: 'dynamic',
            valueProperty: 'cod_maintenance_plan_id',
            searchProperty: 'str_sap_code',
            isMultiselect: false,
            onSelect: (obj_selected: any) => {
                if (this.maintenancePlanList.length === 0) {
                    this.maintenancePlanList.push({
                        cod_maintenance_plan_id: obj_selected.cod_maintenance_plan_id,
                        str_sap_code: obj_selected.str_sap_code,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_sap_code;
                        this.question.cod_answer_option = obj_selected.cod_maintenance_plan_id;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchMaintenancePlan(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchMaintenancePlan(cb, value) {
        this._listMaintenancePlanService.getMaintenancePlanList({ 'term': value, 'cod_user': this._authService.getAuthenticatedUser().id }).subscribe((data: any) => {
            cb(this._listMaintenancePlanService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delMaintenancePlan() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;    
        this.onChange.emit(this.question);

        this.maintenancePlanList = [];
        this.bol_show_typeahead = true;
        this.cod_component_mp = null;
    }
       
}
