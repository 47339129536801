import { Component, OnInit, SimpleChanges } from '@angular/core';

import {UsersService} from '../../admin/services/users.service';
import {AuthService} from '../../main/access/services/auth.service';
import {LogbooksService} from '../../logbooks/services/logbooks.service'
import {MeetingsTypesService} from '../services/meetings-types.service';
import {MeetingsService} from '../services/meetings.service';

import {Meeting} from '../models/meetings.model';
import { Router } from '@angular/router';

import { SelectPagination } from "app/logbooks/models/select_pagination.model";
import { TranslateService } from '@ngx-translate/core';
import { getPositionOfLineAndCharacter } from 'typescript';
import PaginationHelper from 'app/helpers/pagination-helper';


@Component({
  selector: 'app-meetings-location',
  templateUrl: './meetings-location.component.html',
  styleUrls: ['./meetings-location.component.css']
})
export class MeetingsLocationComponent implements OnInit {
  obj_meeting: Meeting;
  paramsSelected: any = [];
  loadingConfig: any = [];
  cod_user: any;
  logbook: any;
  params: any = [];
  regionalList: any=[];
  locationsList: any=[];
  worldList: any=[];
  isLoading: boolean = false;
  cod_meeting_type: any;
  str_download_link: any;
  bol_download: boolean;
  arr_meetings_types:any=[]; 
  arr_meetings: any = [];
  bol_loading_list:boolean;
  pageIndex: number = 1;
  totalPages: number[] = [];
  maxPage: number;
  resultsPerPage: SelectPagination[] = [];
  resultsPerPageSelected: number;
  resultsPerPageSelectOption: SelectPagination[] = [];
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;

  obj_filter: any=[];
  offset: any;
  limit: any;
  get_filter: any;
  tmpPage: any;
  result_page_selected: any;
  location_meeting_privilege: string = null;

  constructor(
    private _userService: UsersService,
    private _authService: AuthService,
    private _logbookService: LogbooksService,
    private _meetingsTypesService: MeetingsTypesService,
    private _meetingsService: MeetingsService,
    private _router: Router,
    private _translateService:TranslateService
  ) { 
    this.cod_user = this._authService.getAuthenticatedUser().id;
    this.obj_meeting = new Meeting();
    this.bol_loading_list=true;
    if (this._authService.getUserPrivilege('MEETING_EDIT_LOCATION_MEETINGS')) {
      this.location_meeting_privilege = 'MEETING_EDIT_LOCATION_MEETINGS';
    } else if (this._authService.getUserPrivilege('MEETING_LIST_LOCATION_MEETINGS')) {
      this.location_meeting_privilege = 'MEETING_LIST_LOCATION_MEETINGS';
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadingConfig['worlds'] = false;
    this._loadRowsByPage();
    this.getFilter();
}

ngOnChanges(changes: SimpleChanges): void {
  this.getFilter();
}

getFilter(){
  this.params = {
    'worlds' : this.worldList,
    'regionals': this.regionalList,
    'locations': this.locationsList,
    'types': this.arr_meetings_types
  }
  this._getAll(this.params);
}

search() { 
  this.tmpPage = this.pageIndex;
  if (this.tmpPage > 0) {
    this.tmpPage = this.tmpPage - 1;
  }

  this.offset = this.tmpPage * this.resultsPerPageSelected;
  this.limit = this.resultsPerPageSelected;  
 
  this.bol_loading_list = true;

  this.obj_filter = {
      value_offiset: this.resultsPerPageSelected,
      value_limit: this.resultsPerPageSelected,
      value_tmpPage: this.tmpPage
    };
  localStorage.setItem('filter', JSON.stringify(this.obj_filter));

  const location_params = {
    cod_user: this._authService.getAuthenticatedUser().id,
    cod_zone:  this.paramsSelected['worlds_selected'].map(w => w.cod_locations_world).toString(),
    cod_regional: this.paramsSelected['regionals_selected'].map(r => r.cod_locations_regional).toString(),
    cod_location: this.paramsSelected['locations_selected'].map(l => l.cod_location).toString(),
    cod_meetings_types: this.paramsSelected['type_selected']?.map(t => t.cod_meetings_types).toString(),
    limit: this.limit,
    offset: this.offset,
    term: this.obj_meeting.str_name
  }
  this._meetingsService.getLocationMeetings(location_params).subscribe(data => {
    this.arr_meetings = data.content.results || []; 
    this.bol_loading_list = false; 

    this.maxPage = Math.ceil(
      (this.arr_meetings.length > 0 ? this.arr_meetings[0].full_count : 0) / this.limit
    );

    this.totalPages = PaginationHelper.pagination(
      this.pageIndex,
      this.maxPage
    );                               
},
err => {
    this._authService.errCheck(err);
    console.log(err);
}
);
}


  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  private _loadMeetingTypes(fn) {
      if (this.paramsSelected['worlds_selected']?.map(w => w.cod_locations_world).toString() != '') {
          this._setLoading('meetings_types', true);
          let term = '';
          let path = '';
          let userId = this._authService.getAuthenticatedUser().id;
          let privilege = this.location_meeting_privilege || 'MEETING_LIST_LOCATION_MEETINGS';
          let worlds = this.paramsSelected['worlds_selected'].map(w => w.cod_locations_world).toString()
          this._meetingsTypesService.getMeetingsTypesSearch(term, path, userId, privilege, worlds).subscribe(data => {
              this.arr_meetings_types = this._meetingsTypesService.getArrayContent(data);
              this._setLoading('meetings_types', false);
              if (typeof fn != typeof undefined && fn) {
                fn();
            }
              // this._smartClearMeetingTypeList();
          }, err => {
              this._authService.errCheck(err);
              console.log(err);
          });
      } else {
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
          this._clearMeetingTypeList();
      }

  }

  private _loadWorlds(fn) {
     this._clearWorldList();
        this._setLoading('worlds', true);
        this._userService.getWorlds(this._authService.getAuthenticatedUser().id, 'MEETING_LIST_LOCATION_MEETINGS').subscribe(data => {
            this.worldList = this._userService.getArrayContent(data);
            this._setLoading('worlds', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
            this._clearMeetingTypeList();
            this._clearRegionalList();
            this._clearLocationsList();
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
  }

  private _loadRegionals(fn) {
    this._clearRegionalList();
    if ( this.paramsSelected['worlds_selected']?.toString() != '') {
        this._setLoading('regionals', true);
        let params = this.paramsSelected['worlds_selected'].map(w => w.cod_locations_world).toString().split(',');
        this._userService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'MEETING_LIST_LOCATION_MEETINGS').subscribe(data => {
            this.regionalList = this._userService.getArrayContent(data);
            this._setLoading('regionals', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
            this._clearLocationsList();
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    } else {
      if (typeof fn != typeof undefined && fn) {
        fn();
      }
    }
}

private _loadLocations(fn) {
  this._clearLocationsList();
  if (this.paramsSelected['regionals_selected'].toString() != '') {
      this._setLoading('locations', true);
      let params = this.paramsSelected['regionals_selected'].map(r => r.cod_locations_regional).toString().split(',');
      this._userService.getLocations(params, this._authService.getAuthenticatedUser().id, 'MEETING_LIST_LOCATION_MEETINGS').subscribe(data => {
          this.locationsList = this._userService.getArrayContent(data);
          this._setLoading('locations', false);
          if (typeof fn != typeof undefined && fn) {
              fn();
          }
      }, err => {
          this._authService.errCheck(err);
          console.log(err);
      });
  } else {
    if (typeof fn != typeof undefined && fn) {
      fn();
    }
  }

}

selectWorlds($event) {
  this.paramsSelected['worlds_selected'] = $event.selectedOptions;
  this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
  this._loadRegionals(null);
  this._loadMeetingTypes(null);
  this.saveFilterLocal();
  this.search();
}

selectRegionals($event) {
  this.paramsSelected['regionals_selected'] = $event.selectedOptions;
  this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
  this._loadLocations(null);
  this.saveFilterLocal();
  this.search();
}

selectLocations($event) {
  this.paramsSelected['locations_selected'] = $event.selectedOptions;
  this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
  this.saveFilterLocal();
  this.search();
} 

selectTypes($event) {
  this.paramsSelected['type_selected'] = $event.selectedOptions;
  this.paramsSelected['type'] = this._implodeValues($event.selectedOptions, 'cod_meetings_types');
  if( this.paramsSelected['type_selected'] == undefined){
    this.paramsSelected['type_selected'] = ''
  }
  this.saveFilterLocal();
  this.search();
} 

  private _getAll(params) {
    const $this = this;
        $this._loadWorlds(() => {
          if(!JSON.parse(localStorage.getItem('localFilterWorlds'))){
            $this.paramsSelected['worlds_selected'] = []
          }else{
            $this.paramsSelected['worlds'] = this._implodeValues(params.selectedOptions, 'cod_locations_world');   
            $this.paramsSelected['worlds_selected'] = JSON.parse(localStorage.getItem('localFilterWorlds'));
          }
            $this._loadRegionals(() => {
              if(!JSON.parse(localStorage.getItem('localFilterRegionals'))){
                $this.paramsSelected['regionals_selected'] = []
              }else{
                this.paramsSelected['regionals'] = this._implodeValues(params.selectedOptions, 'cod_locations_regional');
                $this.paramsSelected['regionals_selected'] = JSON.parse(localStorage.getItem('localFilterRegionals'));
              }
                $this._loadLocations(() => {
                  if(!JSON.parse(localStorage.getItem('localFilterLocations'))){
                    $this.paramsSelected['locations_selected'] = []
                  }else{
                    this.paramsSelected['locations'] = this._implodeValues(params.selectedOptions, 'cod_location');
                    $this.paramsSelected['locations_selected'] = JSON.parse(localStorage.getItem('localFilterLocations'));
                  }
                     $this._loadMeetingTypes(() => {
                       if(!JSON.parse(localStorage.getItem('localFilterType'))){
                        $this.paramsSelected['type_selected'] = []
                       }else{
                        this.paramsSelected['type'] = this._implodeValues(params.selectedOptions, 'cod_meetings_types');
                        $this.paramsSelected['type_selected'] = JSON.parse(localStorage.getItem('localFilterType'));
                       }
                      this.search();
                     });
                 });               
              });
          });
  }

  private _clearWorldList() {
    this.paramsSelected['worlds'] = '';
    this.paramsSelected['worlds_selected'] = [];
    this._clearRegionalList();
  }

  private _clearRegionalList() {
    this.paramsSelected['regionals'] = '';
    this.paramsSelected['regionals_selected'] = [];
    this.regionalList = [];
    this._clearLocationsList();
}

private _clearLocationsList() {
  this.paramsSelected['locations'] = '';
  this.paramsSelected['locations_selected'] = [];
  this.locationsList = [];
}

private _clearMeetingTypeList() {
    this.paramsSelected['meetings_types_selected'] = [];
    this.paramsSelected['meetings_types'] = '';
    this.arr_meetings_types = [];
}

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  showDownload(event, i) {
    const selected = event.selectedOptions; 
    if(selected.length > 0) {
      this.cod_meeting_type = selected[0].cod_meetings_types;
      this.str_download_link = selected[0].str_pdf_path;
      this.bol_download = true;
    }
  }

  openMeeting(obj_meeting) {
    const path = '/meetings-container/' + obj_meeting.cod_meetings;
    this._router.navigate([path]);
  }

  previousPage() {
    let previous = this.pageIndex - 1;
    this.changePage(previous);
  }

  nextPage() {
    let next = this.pageIndex + 1;
    this.changePage(next);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search();
  }

  selectionChanged($event) {
    this.resultsPerPageSelectOption = $event.selectedOptions;
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  private _loadRowsByPage() {
    this.totalPages = [];
    this.loadingConfig['pagination'] = true;
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str10perpage = "10 " + translate;
        this._translateService
          .get("GENERAL.PAGINATION_SELECT")
          .subscribe((translate) => {
            this.str50perpage = "50 " + translate;
            this._translateService
              .get("GENERAL.PAGINATION_SELECT")
              .subscribe((translate) => {
                this.str100perpage = "100 " + translate;
                this.resultsPerPage = [
                  new SelectPagination(10, this.str10perpage),
                  new SelectPagination(50, this.str50perpage),
                  new SelectPagination(100, this.str100perpage),
                ];
                this.get_filter = JSON.parse(localStorage.getItem('filter'));          
                if(!this.get_filter){
                  this.resultsPerPageSelected = this.resultsPerPage[0].qty;
                  this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
                } else {
                  this.resultsPerPageSelected = this.get_filter.value_offiset;
                  this.resultsPerPageSelectOption = this.resultsPerPage.filter(x => x.qty == this.resultsPerPageSelected);
                  this.tmpPage = this.get_filter.value_tmpPage;
                  this.pageIndex = this.tmpPage + 1
                }
                this.loadingConfig['pagination'] = false;
              });
          });
      });
  }

  openMeetingNewTab(cod_meeting) {
      return '/meetings-container/' + cod_meeting;
  }

  saveFilterLocal(){
    localStorage.setItem('localFilterWorlds', JSON.stringify(this.paramsSelected['worlds_selected'] || ''));
    localStorage.setItem('localFilterRegionals', JSON.stringify(this.paramsSelected['regionals_selected'] || ''));
    localStorage.setItem('localFilterLocations', JSON.stringify(this.paramsSelected['locations_selected'] || ''));
    localStorage.setItem('localFilterType', JSON.stringify(this.paramsSelected['type_selected'] || ''));
  }

}
