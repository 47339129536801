import { Injectable } from '@angular/core';
import {MainService} from '../services/main.service';
import {Observable} from 'rxjs';

@Injectable()
export class RelayService {

    constructor(private _mainService: MainService) { }

    listRelay(params) {
        const path = '/list-relay';
        return this._mainService.get(path, params);
    }

    getRelay(params) {
        const path = '/get-relay';
        return this._mainService.get(path, params);
    }

    getSingleRelay(params) {
        const path = '/get-single-relay';
        return this._mainService.get(path, params);
    }
    
    saveRelay(params) {
        const path = '/save-relay';
        return this._mainService.post(path, params);
    }

    deleteRelay(params) {
        const path = `/relay/${params.cod_user}/${params.cod_area}/${params.cod_location}`;
        return this._mainService.delete(path);
    }

}
