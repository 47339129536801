// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div {
    padding: 3px 0;
}

:host span {
    color: #9f9f9f !important;
}

:host div i {
    margin-left: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/forms-input-label/forms-input-label.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[":host div {\n    padding: 3px 0;\n}\n\n:host span {\n    color: #9f9f9f !important;\n}\n\n:host div i {\n    margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
