// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-link { 
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-around;
    padding: 5px;
    position: relative;
    width: 250px;
    z-index: 2;
}

.modal-link input {
    outline: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-link input:focus {
    border-color: #5178e7;
}

.modal-link ::ng-deep .question-div {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-around;
}

.modal-link ::ng-deep .box-choice { 
    height: 25px;
    min-width: 90px !important;
}

.bottom-modal-link { 
    display: flex;
    justify-content: space-evenly;
}

.modal-link .glyphicon-ok {
     color: #3fc048;
}

.modal-link .glyphicon-remove {
    color: #e44140;
}

::ng-deep .question-div label { 
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hyper-link-modal/hyper-link-modal.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;KACK,cAAc;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".modal-link { \n    background: white;\n    border: 1px solid black;\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n    height: 150px;\n    justify-content: space-around;\n    padding: 5px;\n    position: relative;\n    width: 250px;\n    z-index: 2;\n}\n\n.modal-link input {\n    outline: 0;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.modal-link input:focus {\n    border-color: #5178e7;\n}\n\n.modal-link ::ng-deep .question-div {\n    align-items: center;\n    display: flex;\n    font-size: 12px;\n    justify-content: space-around;\n}\n\n.modal-link ::ng-deep .box-choice { \n    height: 25px;\n    min-width: 90px !important;\n}\n\n.bottom-modal-link { \n    display: flex;\n    justify-content: space-evenly;\n}\n\n.modal-link .glyphicon-ok {\n     color: #3fc048;\n}\n\n.modal-link .glyphicon-remove {\n    color: #e44140;\n}\n\n::ng-deep .question-div label { \n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
