import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RelayService } from '../services/relay.service';
import { AuthService } from '../../main/access/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationsService } from '../services/validations.service';
import { Relay } from '../model/relay.model';
import { UsersService } from '../../admin/services/users.service';
import { FiveWhyService } from '../services/five-why.service';  
import { NotificationService } from '../../main/services/notification.service';

@Component({
  selector: 'app-relay-persist',
  templateUrl: './relay-persist.component.html',
  styleUrls: ['./relay-persist.component.css']
})
export class RelayPersistComponent implements OnInit {

  @Input('codArea') codArea;
  @Input('codLocation') codLocation;
  @Output() backEvent = new EventEmitter<Object>();
  relay: Relay;
  isLoading: boolean = false;
  isEdition: boolean = false;
  alreadyExists: boolean = false;
  showAlreadyExists: boolean = false;
  paramsSelected: any = [];
  worldsList: any = [];
  regionalsList: any = [];
  locationsList: any = [];
  areaList: any = [];
  loadingConfig: any = [];
  showError: boolean = false;
  errorMessage: string;
  alreadyExistsMessage: string;
  fivewhysStatus: any[];

  constructor(
    private _relayService: RelayService, 
    private _authService: AuthService, 
    private _translateService: TranslateService, 
    public _validationService: ValidationsService, 
    private _usersGroupPrivilegesService: UsersService, 
    private _fiveWhyService: FiveWhyService,
    private _notify: NotificationService
  ) {
    this.showError = false;
    this.errorMessage = '';
    this._loadWorlds(null);
    
    this._getFivewhysStatus();

    this._translateService.get('RELAY.EXISTING').subscribe(translate => {
      this.alreadyExistsMessage = translate;
    });

  }

  ngOnInit() {
    this.getSingleRelay();
    this.loadingConfig['worlds'] = false;
    this.loadingConfig['regionals'] = false;
    this.loadingConfig['locations'] = false;
    this.loadingConfig['areas'] = false;
  }

  private _getFivewhysStatus() {
    this._fiveWhyService.listSteps(
      {cod_user: this._authService.getAuthenticatedUser().id}
    ).subscribe(data => {
      this.fivewhysStatus = data.content.results;
      this._resetStatus();
    });
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _resetRelay() {
    this.relay = new Relay();
    this._resetStatus();
  }

  private _resetStatus() {
    if (typeof this.relay == 'undefined') return;
    if (typeof this.relay.fivewhys_blocked_status !== 'undefined' && this.relay.fivewhys_blocked_status.length > 0) return;
    if (typeof this.fivewhysStatus != 'undefined') {
      this.relay.fivewhys_blocked_status = this.fivewhysStatus.map(s => {
        return { cod_fivewhy_steps: s.cod_fivewhys_steps, is_enable: true };
      });
    }
  }

  toggleStatus(ev: any, index: number): void {
    this.relay.fivewhys_blocked_status = this.relay.fivewhys_blocked_status.map(s => {
      if (s.cod_fivewhy_steps == index) s.is_enable = !s.is_enable;
      return s;
    });
  }

  getStatusName(cod_fivewhys_steps: number): string {
    return this.fivewhysStatus?.find(s => s.cod_fivewhys_steps == cod_fivewhys_steps)?.str_name || '';
  }

  getSingleRelay() {
    this.isLoading = true;
    if (!(this.codArea > 0 && this.codLocation > 0)) {
      this._resetRelay();
      this.isLoading = false;
    } else {
      this.isEdition = true;
      const params = {
        'cod_user': this._authService.getAuthenticatedUser().id,
        'cod_area': this.codArea,
        'cod_location': this.codLocation,
      }

      this._relayService.getSingleRelay(params).subscribe(data => {
        if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
          this.relay = data.content.results[0];
          this.isLoading = false;
          this._getAll(this.relay);
        }
        else {
          this.isLoading = false;
          this._showErrorMessage(data.content.message);
          this.backSelected(false);
        }
      }, err => {
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      });
    }
  }

  public getParamsToSave() {
    this.relay.cod_user = this._authService.getAuthenticatedUser().id;
    this.relay.cod_location = this.paramsSelected['locations'];
    this.relay.cod_area = this.paramsSelected['areas'];
  }

  private _handleSaveValidation(message: string): void {
    this._validationService.boolIsLoading = false;
    this._translateService.get(message).subscribe(translate => {
      this.errorMessage = translate;
      this._notify.warning(translate);
    });
  }

  public save() {
    if (this.alreadyExists) {
      this.showAlreadyExists = true;
    } else {
      this.saveRelay();
    }
  }

  public saveRelay() {
    this.showAlreadyExists = false;
    this.getParamsToSave();
    this._validationService.boolSubmit = true;
    this._validationService.boolIsLoading = true;

    if (this.paramsSelected['areas'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_AREA');
      return false;
    }

    if (this.paramsSelected['locations'].length <= 0) {
      this._handleSaveValidation('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED');
      return false;
    }

    let params: any;
    params = {
      'cod_user': this.relay.cod_user,
      'cod_location': this.relay.cod_location,
      'cod_area': this.relay.cod_area,
      'cod_fivewhy_status_enable': this.relay.fivewhys_blocked_status.filter(s => s.is_enable).map(y => y.cod_fivewhy_steps),
      'cod_fivewhy_status_disable': this.relay.fivewhys_blocked_status.filter(s => !s.is_enable).map(y => y.cod_fivewhy_steps),
    };

    this._relayService.saveRelay(params).subscribe(res => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (res.type !== 'success') {
          this._showErrorMessage(res.content.message);
        }
        else {
          this._notify.success({text: 'RELAY.SAVED', translate: true});
          this.backSelected(true);
        }
        this._resetRelay();
    }, err => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
    });
  }

  private _verifyExistingRelay() {
    const params = {
      'cod_user': this._authService.getAuthenticatedUser().id,
      'cod_area': this.paramsSelected["areas"],
      'cod_location': this.paramsSelected['locations'],
    }

    this._relayService.getSingleRelay(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888) {
        this.alreadyExists = (data.content.length > 0);
        if (this.alreadyExists) this._notify.warning(this.alreadyExistsMessage);
      }
      else {
        this.isLoading = false;
        this._showErrorMessage(data.content.message);
        this.backSelected(false);
      }
    }, err => {
      this._authService.errCheck(err);
      this._showErrorMessage(err);
    });
  }

  private _getAll(params) {
    const $this = this;
      $this.paramsSelected['worlds_selected'] = [{ cod_locations_world: params.cod_locations_world }];
      $this.paramsSelected['worlds'] = params.cod_locations_world;
      $this._loadRegionals(() => {
        $this.paramsSelected['regionals_selected'] = [{ cod_locations_regional: params.cod_locations_regional }];
        $this.paramsSelected['regionals'] = params.cod_locations_regional;
        $this._loadLocations(() => {
          $this.paramsSelected['locations_selected'] = [{ cod_location: params.cod_location }];
          $this.paramsSelected['locations'] = params.cod_location;
          $this._loadAreas(() => {
            $this.paramsSelected["areas_selected"] = [{ cod_area: params.cod_area }];
            $this.paramsSelected["areas"] = params.cod_area;
        });
      });
    });
  }

  private _loadWorlds(fn) {
    this._clearWorldList();
    this._setLoading('worlds',true);
    this._usersGroupPrivilegesService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_LIST_RELAY').subscribe(data => {
      this.worldsList = this._usersGroupPrivilegesService.getArrayContent(data);
      this._setLoading('worlds',false);
      if (typeof fn != typeof undefined && fn) {
        fn();
      }
    }, err => {
      this._authService.errCheck(err);
      console.log(err);
    });
  }

  private _loadRegionals(fn) {

    if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
      this._setLoading('regionals',true);
      let params = this.paramsSelected['worlds'].toString().split(',');
      this._usersGroupPrivilegesService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LIST_RELAY').subscribe(data => {
        this.regionalsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('regionals',false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearRegionalList();
      },
        err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      );
    } else {
      this._clearRegionalList();
    }

  }

  private _loadLocations(fn) {

    if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
      this._setLoading('locations',true);
      let params = this.paramsSelected['regionals'].toString().split(',');
      this._usersGroupPrivilegesService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LIST_RELAY').subscribe(data => {
        this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('locations',false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearLocationsList();
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearLocationsList();
    }

  }

  private _loadAreas(fn) {
    if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
      this._setLoading('areas',true);
      let params = {
        arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
        cod_user: this._authService.getAuthenticatedUser().id,
        dsc_page_privilege: 'ADMIN_LIST_RELAY'
      };
      this._fiveWhyService.getAllowedAreas(params).subscribe(data => {
        this.areaList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('areas',false);
        this._smartClearAreaList();
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearAreaList();
    }

  }

  private _clearWorldList() {
    this.paramsSelected['worlds'] = '';
    this.paramsSelected['worlds_selected'] = [];
    this._clearRegionalList();
  }

  private _clearRegionalList() {
    this.paramsSelected['regionals'] = '';
    this.paramsSelected['regionals_selected'] = [];
    this.regionalsList = [];
    this._clearLocationsList();
  }

  private _smartClearRegionalList() {

    if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
        this.regionalsList.map((regional) => {
          if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
        });
      });
      this.paramsSelected['regionals_selected'] = arr;
      this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
    }
    if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
      this.paramsSelected['regionals_selected'] = [];
      this.paramsSelected['regionals'] = '';
      this._clearLocationsList();
    } else {
      this._loadLocations(null);
    }

  }

  private _clearLocationsList() {
    this.paramsSelected['locations'] = '';
    this.paramsSelected['locations_selected'] = [];
    this.locationsList = [];
    this._clearAreaList();
  }

  private _smartClearLocationsList() {

    if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
        this.locationsList.map((location) => {
          if (location.cod_location == selected.cod_location) arr.push(location);
        });
      });
      this.paramsSelected['locations_selected'] = arr;
      this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
    }
    if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
      this.paramsSelected['locations_selected'] = [];
      this.paramsSelected['locations'] = '';
      this._clearAreaList();
    } else {
      this._loadAreas(null);
    }

  }

  private _clearAreaList() {
    this.paramsSelected["areas"] = '';
    this.paramsSelected["areas_selected"] = [];
    this.areaList = [];
  }

  private _smartClearAreaList() {

    if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
        this.areaList.map((area) => {
          if (area.cod_area == selected.cod_area) arr.push(area);
        });
      });
      this.paramsSelected['areas_selected'] = arr;
      this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
    }
    if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
      this.paramsSelected['areas_selected'] = [];
      this.paramsSelected['areas'] = '';
    }

  }

  selectWorlds($event) {
    this.paramsSelected['worlds_selected'] = $event.selectedOptions;
    this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
    this._loadRegionals(null);
  }

  selectRegionals($event) {
    this.paramsSelected['regionals_selected'] = $event.selectedOptions;
    this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
    this._loadLocations(null);
  }

  selectLocations($event) {
    this.paramsSelected['locations_selected'] = $event.selectedOptions;
    this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
    this._loadAreas(null);
  }

  selectArea($event) {
    this.paramsSelected['areas_selected'] = $event.selectedOptions;
    this.paramsSelected["areas"] = this._implodeValues($event.selectedOptions, "cod_area");
    this._verifyExistingRelay();
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  backSelected(success: boolean = false) {
    this._validationService.boolSubmit = false;
    this._validationService.boolIsLoading = false;
    if (success) {
      this.codArea = -1;
      this.codLocation = -1;
    } else {
      this.codArea = 0;
      this.codLocation = 0;
    }
    this._resetRelay();
    this.backEvent.emit({ codArea: this.codArea, codLocation: this.codLocation });
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

}
