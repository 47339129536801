import {Component, OnInit} from '@angular/core';
import {MyNotesService} from '../services/my-notes.service';
import {AuthService} from '../../main/access/services/auth.service';
import {Notes} from '../models/notes.model';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from "./../../main/services/notification.service";

@Component({
  selector: 'app-my-notes',
  templateUrl: './my-notes.component.html',
  styleUrls: ['./my-notes.component.css'],
  providers: [ MyNotesService ]
})
export class MyNotesComponent implements OnInit {
  authenticatedUser: any;
  arr_list_notes = [];
  bol_loading = false;
  bol_add=false;
  bol_success = false;
  bol_error_submit = false;
  error_message_submit='';
  noteModel: Notes;
  errorMessage = '';

  constructor(private _myNotesService: MyNotesService
             ,private _authService: AuthService
             ,private _notify: NotificationService
             ,private _translateService: TranslateService) {
    this.authenticatedUser = this._authService.getAuthenticatedUser();
  }

  ngOnInit() {
    this.noteModel = new Notes();
    this.listNotes();
  }

  listNotes(){
    this.bol_loading = true;
    this._myNotesService.getNotes(this.authenticatedUser.id).subscribe(data => {
      this.arr_list_notes = this._myNotesService.getArrayContent(data);
      this.bol_loading = false;
    },
    err => { this._authService.errCheck(err); console.log(err); } 
    );
  }

  addNote(note){
    if (note) {
      this.bol_success = false;
      this.bol_error_submit = false;
      this.bol_add = true;
      this.noteModel = new Notes();
      this.noteModel.dat_note_datapicker = this.convertDate(note.dat_note_datapicker); 
      this.noteModel.cod_notes = note.cod_notes;
      this.noteModel.str_description = note.str_description;
      this.noteModel.cod_user = note.cod_users;
      this.noteModel.bol_done = note.bol_done;   
    } else {
      this.noteModel = new Notes();
      this.noteModel.cod_user = this.authenticatedUser.id;
      this.bol_add=!this.bol_add;
      this.bol_success = false;
      this.bol_error_submit = false;
    }
  }

  private _showWarning(message: string): void {
    this._notify.warning({ text: message, translate: true });
  }

  verifyDescription() {
    if (this.noteModel.str_description.length == 255) {
      this._showWarning('MEETING_NOTES.VALIDATION.DESCRIPTION_TOO_LONG');
    }
  }

  submit() {
    this.bol_success = false;
    this.bol_error_submit = false;

    if (this.noteModel.str_description.length == 0) {
      this._showWarning('MEETING_NOTES.VALIDATION.DESCRIPTION_REQUIRED');
      return false;
    }

    if (this.noteModel.str_description.length > 255) {
      this._showWarning('MEETING_NOTES.VALIDATION.DESCRIPTION_TOO_LONG');
      return false;
    }

    if (!this.noteModel.dat_note_datapicker) {
      this._showWarning('MEETING_NOTES.VALIDATION.DATE_REQUIRED');
      return false;
    }    
 
    if(this.noteModel.cod_notes === 0) {
      this.bol_loading = true;
      this.noteModel.cod_uuid = null;
      this.noteModel.dat_notes_db = this.noteModel.dat_note_datapicker;
      this._myNotesService.postNote(this.noteModel).subscribe(data => {
        if (data.code === 666) {
          this.bol_error_submit = true;
          this.bol_loading = false;
          switch (data.content.details[0].path[0]) {
            case 'str_description':
              this.errorMessage = 'MEETING_NOTES.DESCRIPTION';
              break;
            case 'dat_notes':
              this.errorMessage = 'MEETING_NOTES.DATA';
              break;
            default:
              return this.error_message_submit = data.content.details[0].message;
          }
          this._translateService.get(this.errorMessage).subscribe(translate => {
            this.error_message_submit = translate;
            this._translateService.get('MEETING_NOTES.FIELD_ERROR').subscribe(translate => {
              return this.error_message_submit += ' ' + translate;
            });
          });
          return;
        }
        this.bol_success = true;
        this.bol_loading = false;
        let $this=this;
        setTimeout(function(){
          $this.bol_add=false;
        },1000);

        this.listNotes();
      },
      err => { this._authService.errCheck(err); console.log(err); } 
      );
    } else {
      this.concludeNote(this.noteModel, false);
    }

  }

  concludeNote(note, isDone) {
    this.bol_success = false;
    this.bol_error_submit = false;
    this.noteModel.dat_notes_db = this.noteModel.dat_note_datapicker;
    if (isDone) note.bol_done=1;
    note.cod_user = this.authenticatedUser.id;
    this._myNotesService.putNote(note).subscribe(data => {
      if (data.code === 666) {
        this.bol_error_submit = true;
        this.error_message_submit = data.content[0].message;
        return;
      }
      this.bol_success = true;
      let $this=this;
      setTimeout(function(){
        $this.bol_add=false;
      },1000);
      this.listNotes();
    },
    err => { this._authService.errCheck(err); console.log(err); } 
    );
  }

  convertDate(date) {
    console.log(date);
    var day  = date.split("/")[0];
    var month  = date.split("/")[1];
    var year  = date.split("/")[2];
  
    return ( year + '-' + ("0"+month).slice(-2) + '-' + ("0"+day).slice(-2));

  }

  closeEdit() {
    this.bol_add = false;
  }

}
