import {Component, Input} from '@angular/core';

@Component({
    selector: 'logbook-button-save',
    templateUrl: './button-save.component.html',
    styleUrls: ['./button-save.component.css'],
})
export class ButtonSaveComponent {
    @Input() disabled: boolean;
    @Input() isLoading: boolean;
    @Input() showIcon: boolean = true;
    @Input() showText: boolean = true;
    @Input() text: string;
}
