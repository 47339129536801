import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from '../models/select_pagination.model';
import {EquipmentService} from '../services/equipment.service';

@Component({
    selector: 'app-equipments-list',
    templateUrl: './equipments-list.component.html',
    styleUrls: ['./equipments-list.component.css']
})
export class EquipmentsListComponent implements AfterViewInit, OnDestroy {

    list: Object;
    paramsSubscription: Subscription = Subscription.EMPTY;
    searchTerm: string;
    searchTimer: any;
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    isLoading: boolean = true;
    codEquipment: number = 0;
    createRecord: boolean = false;
    codUser: number;
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    deleteEquipmentId: number = 0;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    editSuccessMessage: boolean = false;
    isLoadingFile: boolean = false;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;
    procedureImportingEquipment: string = 'LOGBOOKS_sp_imp_equipments';
    fieldsExcelSheet: string = 'cod_equipments,str_name,str_description,cod_locations,str_description_from';

    constructor(public _equipmentService: EquipmentService, private _authService: AuthService, private _translateService: TranslateService) {
        this.searchTerm = '';
        this.showError = false;
        this.errorMessage = '';
        this.list = {
            equipments: []
        };


        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                this.str50perpage = '50 ' + translate;
                this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                    this.str100perpage = '100 ' + translate;
                    this.resultsPerPage = [
                        new SelectPagination(10, this.str10perpage),
                        new SelectPagination(50, this.str50perpage),
                        new SelectPagination(100, this.str100perpage),
                    ];
                });
            });
        });


        this.pageIndex = 1;
        this.totalPages = [];

        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;

        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;
        this.isLoadingFile = false;
    }

    ngAfterViewInit() {
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        } catch (err) {
            //safe!
            console.log(err);
        }
    }

    public edit(id, reload) {
        if (id == -1) {
            id = 0;
            this.editSuccessMessage = true;
        } else {
            this.editSuccessMessage = false;
        }
        this.codEquipment = id;
        this.createRecord = !reload;
        if (reload) {
            this._search();
        }
    }

    public toggleDelete(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteEquipmentId = id;
    }

    public confirmDelete() {
        let objParam = {
            'cod_equipments': this.deleteEquipmentId,
            'cod_user': this.codUser
        };
        this._equipmentService.delete(objParam).subscribe(data => {
                this.search();
            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
        this.awaitConfirmDelete = false;
        this.deleteEquipmentId = 0;
    }

    private _search() {
        this.isLoading = true;
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        let objParam = {
            'cod_equipments': 0,
            'term': this.searchTerm,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected),
            'dsc_page_privilege': 'ADMIN_EQUIPMENT_LISTING',
            'cod_user': this.codUser,
            'cod_user_auth': this._authService.getAuthenticatedUser().id
        };
        this._equipmentService.getList(objParam).subscribe(data => {
                this.list = {
                    equipments: this._equipmentService.getArrayContent(data)
                };

                let str_cod_equipments = '';
                let str_nom_equipments = '';
                let str_dsc_equipments = '';
                let str_cod_locations = '';
                let str_dsc_locations = '';

                this._translateService.get('LOGBOOK.COD_EQUIPMENT').subscribe(translate => {
                    str_cod_equipments = translate;
                    this._translateService.get('LOGBOOK.STR_EQUIPMENT').subscribe(translate => {
                        str_nom_equipments = translate;
                        this._translateService.get('LOGBOOK.DSC_EQUIPMENT').subscribe(translate => {
                            str_dsc_equipments = translate;
                            this._translateService.get('LOGBOOK.COD_LOCATION').subscribe(translate => {
                                str_cod_locations = translate;
                                this._translateService.get('LOGBOOK.STR_LOCATION').subscribe(translate => {
                                    str_dsc_locations = translate;
                                    this.downloadCsvSetup = {
                                        cod_user: this.codUser,
                                        api_url: this._reportsApiUrl,
                                        str_proc_exec: 'reports_ex_equipments',
                                        arr_proc_params_search: [
                                            this.searchTerm
                                        ],
                                        arr_headers: [
                                            str_cod_equipments,
                                            str_nom_equipments,
                                            str_dsc_equipments,
                                            str_cod_locations,
                                            str_dsc_locations,
                                        ],
                                        name_of_file_to_download: 'equipments_',
                                        str_column_dynamic: ''
                                    };
                                });
                            });
                        });
                    });
                });


                this.isLoading = false;

                this.maxPage = Math.ceil(data.content.count / this.resultsPerPageSelected);
                this.totalPages = this.pagination(this.pageIndex, this.maxPage);

            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
                this.isLoading = false;
            }
        );
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.pageIndex = page;
        this.isLoading = true;
        this.search();
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    messageImported() {
        this.isLoadingFile = false;
        this._showErrorMessage('LOGBOOK.MESSAGE_IMPORTED_SUCCESS');
        this.search();
    }

    startLoading($event) {
        this.isLoadingFile = true;
    }

    messageError($event) {
        this.isLoadingFile = false;
        console.log($event);
        let errorMessage = $event.split('!'); //Error uploading file!Error importing routines - STR_DESCRIPTION
        let errorCodeTmp = errorMessage[1].split('-'); //Error importing routines - STR_DESCRIPTION
        //let errorMsgNotice = errorCodeTmp[0]; //Error importing routines
        let errorCode = errorCodeTmp;
        if (typeof errorCodeTmp[1] !== typeof undefined) {
            errorCode = errorCodeTmp[1].trim(); //STR_DESCRIPTION
        }

        let errorMessageFinal = '';
        if (errorCode == 'STR_NAME') {
            this._translateService.get('LOGBOOK.REQUIRED_STR_NAME').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }

        if (errorCode == 'STR_DESCRIPTION') {
            this._translateService.get('LOGBOOK.REQUIRED_STR_DESCRIPTION').subscribe(translate => {
                errorMessageFinal = translate + ` 
                ` + errorCodeTmp[2].trim();
            });
        }

        this._translateService.get('LOGBOOK.MESSAGE_IMPORTED_ERROR').subscribe(translate => {
            if (errorMessageFinal == '') {
                errorMessageFinal = translate + ` - 
                ` + errorMessage;
            } else {
                errorMessageFinal = translate + ` - 
                ` + errorMessageFinal;
            }

            this._showErrorMessage(errorMessageFinal);
        });
    }
}
