import {Component, ElementRef, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    host: {
        '(document:click)': 'clickOutside($event)',
    },
    selector: 'typeahead2',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.css'],
})
export class Typeahead2Component implements OnChanges{

    obj_envirorment: any;
    arr_options: any;
    obj_selected_local: any;
    int_selected: number;
    str_description: string;
    bol_loading: boolean;
    str_search_property: string;
    str_img_property: string;
    debounceFunction: any;

    @ViewChild('container', { static: true })
    container: ElementRef;

    //typeahead options setup
    @Input() obj_setup: any;

    //selected option select
    @Input() obj_selected: any;
    @Input() disabled: any = false;

    public constructor() {
        this.arr_options = [];
        this.int_selected = 0;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!!changes.obj_setup && this.obj_setup) {
            this.arr_options = [];
            this.int_selected = 0;

            //CHECK IF PROPERTIES ARE FILLED
            this.str_search_property = (this.obj_setup.searchProperty?this.obj_setup.searchProperty:'str_name');
            this.str_img_property = (this.obj_setup.imageProperty?this.obj_setup.imageProperty:'str_img_path');
        }

        if (!!changes.obj_selected && this.str_search_property && this.obj_selected) {
            this.str_description = this.obj_selected[this.obj_setup.searchProperty];
        }

    }

    search($event) {

        //NAVIGATE TROUGHT OPTIONS
        let keyCode = $event.which || $event.keyCode;
        if (keyCode == 40) { //Up
            this.int_selected = this.int_selected + 1;
            if (this.int_selected >= this.arr_options.length) {
                this.int_selected = (this.arr_options.length - 1);
            }
            let obj_active_item = <HTMLElement>document.getElementsByClassName("display-opt-item-active")[0];
            if(obj_active_item){
                (<HTMLElement>obj_active_item.parentNode).scrollTop = obj_active_item.offsetTop+50;
            }
            return;
        }
        if(keyCode == 38){ //Down
            this.int_selected = this.int_selected - 1;
            if (this.int_selected < 0) {
                this.int_selected = 0;
            }
            let obj_active_item = <HTMLElement>document.getElementsByClassName("display-opt-item-active")[0];
            if(obj_active_item){
                (<HTMLElement>obj_active_item.parentNode).scrollTop = obj_active_item.offsetTop-50;
            }
            return;
        }

        //SELECT OPTION
        if (keyCode == 13) { //Enter
            if (this.arr_options[this.int_selected]) {
                this.select(this.int_selected, this.arr_options[this.int_selected]);
            }
            return;
        }

        //PREVENTS FROM SENDING MONY REQUESTS DOING DEBOUNCE
        clearTimeout(this.debounceFunction);
        this.debounceFunction = setTimeout(() => {
            this.bol_loading = true;

            if(this.obj_setup.type=='static'){

                //SEARCH EVERY PARAMTER IN STATIC DATA
                let arr_search_list = this.str_description.split(' ');
                if (this.obj_setup.staticData && this.str_search_property) {
                    this.arr_options = this.obj_setup.staticData.filter((obj_item) => {
                        let bol_val_found = false;
                        for (let i = 0; i < arr_search_list.length; i++) {
                            if (obj_item[this.str_search_property] && obj_item[this.str_search_property].toString().toLowerCase().indexOf(arr_search_list[i].toString().toLowerCase()) != -1) {
                                bol_val_found = true;
                            }
                        }
                        return bol_val_found;
                    });
                    this.bol_loading = false;
                }else{
                    console.warn('The parameter "searchProperty" is not set in the "typeahead" setup object, value: ',this.obj_setup.searchProperty);
                    this.bol_loading = false;
                }

            }else{
                if(this.obj_setup['asynchDataCall']){
                    //CALLBACK TO SEARCH THE OPTIONS
                    this.obj_setup['asynchDataCall'](this.str_description, (arr_list: Array<any>) => {
                        this.arr_options = arr_list;
                        this.bol_loading = false;
                    });
                }else{
                    console.warn('The parameter "asynchDataCall" is not set in the "typeahead" setup object, value: ',this.obj_setup['asynchDataCall']);
                    this.bol_loading = false;
                }
            
            }

        },700);
    }

    select(index, obj_opt) {

        this.obj_selected_local=obj_opt;

        if(this.obj_setup['onSelect']){

            this.obj_setup['onSelect'](this.obj_selected_local);
        }else{
            console.warn('The parameter "onSelect" is not set in the "typeahead" setup object, value: ',this.obj_setup['onSelect']);
        }

        this.clean();
    }

    blur() {
        let $this = this;
        window.setTimeout(function () {
            $this.arr_options = [];
            if ($this.obj_setup['onBlur']) {
                $this.obj_setup['onBlur']($this.obj_selected_local);
                $this.clean();
            }
        }, 500);
    }

    clickOutside(event) {
        // ignore icon clicks
        if (event.target.tagName == 'I') {
            return;
        }

        // check whether the click happened outside of this instance of typeahead
        if (!this.container.nativeElement.contains(event.target)) {
            //cleans tray
            this.clean();
        }
    }

    clean(){
        //CLEANS THE OPTIONs AND SELECTION IF IT HAS NOT SELECTED PROPERTY
        if(!this.obj_selected){
            this.str_description='';
        }
        this.arr_options=[];
        this.int_selected=0;
    }

}
