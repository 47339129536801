import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LogbooksService} from '../services/logbooks.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-assign-logbook',
    templateUrl: './assign-logbook.component.html',
    styleUrls: ['./assign-logbook.component.css']
})
export class AssignLogbookComponent implements AfterViewInit, OnDestroy, OnInit {
    id: any;
    list: any;
    codUser: Object;
    searchTerm: string;
    searchTimer: any;
    taSetupRoutine: any = {};
    paramsSubscription: Subscription = Subscription.EMPTY;
    placeholderTypeAheadRoutine = '';
    eventSearch: any;
    isLoading = true;

    constructor(private _logbookService: LogbooksService, private _translateService: TranslateService, private _authService: AuthService) {
        this.searchTerm = '';
        this.list = {
            logbooks: []
        };
        this.codUser = null;
        this.searchTimer = null;
    }

    ngAfterViewInit() {
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription !== Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        } catch (err) {
            console.log(err);
        }
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length !== 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        const $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        const objParam = {'cod_team': 0, 'term': this.searchTerm, 'cod_user': this._authService.getAuthenticatedUser().id};
        this._logbookService.getMyTeamList(objParam).subscribe(data => {
            if (data.content.length === 1) {
                this.list = {
                    logbooks: [data.content.results]
                };
            } else {
                this.list = {
                    logbooks: data.content.results
                };
            }
            this.isLoading = false;
        }, err => {
            const e = JSON.parse(err._body);
            console.log(err);
            this._authService.errCheck(err);
        }    
        );
    }

    ngOnInit(): void {
        this._translateService.get('LOGBOOK.NAME').subscribe(translate => {
            this.placeholderTypeAheadRoutine = translate;
        });

        this.taSetupRoutine = {
            type: 'dynamic',
            placeHolder: this.placeholderTypeAheadRoutine,
            textPrperty: '',
            valueProperty: 'str_name',
            searchProperty: '',
            hasImage: false,
            onSelect: (logbook: any) => {
                this.changeUserLogbook(logbook.cod_logbook);
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchLogbooks(cb, value);
            },
            onBlur: (item: any) => {
                if (!this.taSetupRoutine.getValue) {
                    this.changeUserLogbook(null);
                }
            }
        };
    }

    changeUserLogbook(codVariation) {
        if (!this.codUser || !this._authService.getAuthenticatedUser().id) {
            return;
        }

        const params = {
            'cod_user': this.codUser,
            'cod_logbook': (codVariation > 0 ? codVariation : 'null'),
            'cod_user_auth': this._authService.getAuthenticatedUser().id
        };

        this._logbookService.setLogbookToUser(params).subscribe((res: any) => {
            if (res.type === 'error') {
                alert(res.content.message);
            }
        }, err => {
            const e = JSON.parse(err._body);
            alert(e.content);
            this._authService.errCheck(err);
        }
        );
    }

    updateLogbookUser(codUser, codVariation) {
        this.codUser = codUser;
    }

    searchLogbooks(cb, term) {
        try {
            const $this = this;
            if ($this.eventSearch) {
                clearTimeout(this.eventSearch);
            }

            $this.eventSearch = setTimeout(function () {
                const params = {
                    'term': term,
                    'cod_user': this._authService.getAuthenticatedUser().id,
                    'dsc_page_privilege': 'ADMIN_LOGBOOKS_LISTING'
                };
                $this._logbookService.getLogbooksList(params).subscribe((result: any) => {
                    let resultsArr;
                    if (result.content.length === 1) {
                        resultsArr = [result.content.results];
                    } else {
                        resultsArr = result.content.results;
                    }
                    cb(resultsArr);
                },
                err => { this._authService.errCheck(err); console.log(err); } 
                );
            }, 500);
        } catch (e) {
            console.error(e);
        }
    }
}
