import { Injectable } from "@angular/core";
import { AuthMainService } from "./auth-main.service";
import { Observable } from "rxjs";
import { DeparmentListResponse } from "./types/areas.service.types";

// areas were renamed to departments
@Injectable()
export class AreasService {
    constructor(private readonly _authMainService: AuthMainService) {
    }

    getDepartments({ cod_user, cod_location } : { cod_user: number, cod_location: number }): Observable<DeparmentListResponse> {
        const queryParams = cod_location ? `?cod_location=${cod_location}` : '';
        const path = `/${cod_user}/departments` + queryParams;
        return this._authMainService.getV2(path);
    }
}