import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { SelectPagination } from 'app/logbooks/models/select_pagination.model';
import { AuthService } from 'app/main/access/services/auth.service';
import { MyFormsService } from 'app/my-forms/services/my-forms.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import PaginationHelper from 'app/helpers/pagination-helper';

@Component({
  selector: 'app-my-form',
  templateUrl: './my-form.component.html',
  styleUrls: ['./my-form.component.css']
})
export class MyFormComponent implements OnInit {
  
  isLoading = false;
  initial_date: string = moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD');
  final_date: string = moment(new Date()).format('YYYY-MM-DD');
  searchTerm: string = '';

  pageIndex: number = 1;
  totalPages: number[] = [];
  maxPage: number;


  searchTimer: any;
  cod_user: number = this._authService.getAuthenticatedUser().id;
  hasError: boolean;

  listMyForms: any = [];
  count_data: 0;

  resultsPerPage: SelectPagination[] = [];
  resultsPerPageSelected: number;
  resultsPerPageSelectOption: SelectPagination[] = [];
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  no_records: boolean = false;

  constructor(
    private router: Router,
    private _authService: AuthService, 
    private _myFormsService: MyFormsService,
    private _translateService: TranslateService,
    private _userPreferencesService: UserPreferencesService
  ) { }

  ngOnInit(): void {
    if(localStorage.getItem('myFormsLastInitialDate')) this.initial_date = localStorage.getItem('myFormsLastInitialDate')
    if(localStorage.getItem('myFormsLastFinalDate')) this.final_date = localStorage.getItem('myFormsLastFinalDate')
    this._translateService.onLangChange.subscribe(event => {
      this.listMyForms.forEach(form => form.str_form_name = this._myFormsService.getNameByUserLanguage(event.lang, form, 'str_form_name'));
    });
    this._loadRowsByPage();
  }

  search(reset: boolean) {
    if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
      return;
    }

    clearTimeout(this.searchTimer);
    const $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.loadMyForms();
    }, environment().debounceTime);
  }

  previousPage() {
    let previous = this.pageIndex - 1;
    this.changePage(previous)
}

  nextPage() {
    let next = this.pageIndex + 1;
    this.changePage(next)
  }

  changePage(page) {
    this.pageIndex = page;
    this.isLoading = true;
    this.search(false);
  }

  private loadMyForms() {
    this.no_records = false;
    this.listMyForms = [];
    this.isLoading = true;
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
        tmpPage = tmpPage - 1;
    }
    let offset = tmpPage * this.resultsPerPageSelected;
    let limit = this.resultsPerPageSelected;
    let params = {
        'term': this.searchTerm,
        'cod_user': this.cod_user,
        'dat_ini': this.initial_date,
        'dat_end': this.final_date,
        'limit': limit,
        'offset': offset,
    };

    this._myFormsService.getMyForms(params).subscribe(res => {

      if (res.code === 888 && res.content.length > 0) {
        this.listMyForms = Array.isArray(res.content.results) ? res.content.results : [res.content.results];
        this.listMyForms.forEach(form => form.str_form_name = this._myFormsService.getNameByUserLanguage(this._userPreferencesService.getLocalLanguage(), form, 'str_form_name'));
        this.maxPage = Math.ceil(this.listMyForms[0].full_count / this.resultsPerPageSelected);
        this.totalPages = PaginationHelper.pagination(this.pageIndex, this.maxPage);
      } else { 
        this.maxPage = 1;
        this.totalPages = [1];
        this.no_records = true;
      }

       
      this.isLoading = false;
    }, err => { 
        this.maxPage = 1;
        this.totalPages = [1];
        this.no_records = true;
        this._authService.errCheck(err);
        this.hasError = true;
        this.isLoading = false; 
      }
    );
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  openDetails(lrpuCode, bool_collab) {
    localStorage.setItem('myFormsLastInitialDate', this.initial_date);
    localStorage.setItem('myFormsLastFinalDate', this.final_date);
    if (bool_collab) {
      this.router.navigate([`/my-forms/form-collab/${lrpuCode}`]);
    } else {
      this.router.navigate([`/my-forms/form/${lrpuCode}`]);
    }
  }

  private _loadRowsByPage() {
    this.pageIndex = 1;
    this.totalPages = [];

    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
        this.str10perpage = '10 ' + translate;
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str50perpage = '50 ' + translate;
            this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                this.str100perpage = '100 ' + translate;
                this.resultsPerPage = [
                    new SelectPagination(10, this.str10perpage),
                    new SelectPagination(50, this.str50perpage),
                    new SelectPagination(100, this.str100perpage),
                ];
                this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
                this.resultsPerPageSelected = this.resultsPerPage[0].qty;
                this.loadMyForms();
            });
        });
    });
  }
}
