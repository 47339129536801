// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-excel-container {
    display: inline;
}

.import-excel-container .btn-file {
    background: #e9eaea;
}

.import-excel-container div {
    display: inline-block;
}

.import-excel-container .action-button {
    height: 34px;
    line-height: 34px;
    margin-top: -3px;
}

.import-excel-container .action-button span {
    line-height: inherit;
    font-family: Lato-Regular;
    font-size: 15px;
}

.import-excel-container .action-button:hover,
.import-excel-container .action-button:focus {
    color: #FFF;
}

.import-excel-container .action-button.btn-success {
    background-color: var(--ui-ok);
    color: var(--text-ok);
}

.action-button {
    border: 0px;
}

.display-none{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/import-excel/import-excel.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".import-excel-container {\n    display: inline;\n}\n\n.import-excel-container .btn-file {\n    background: #e9eaea;\n}\n\n.import-excel-container div {\n    display: inline-block;\n}\n\n.import-excel-container .action-button {\n    height: 34px;\n    line-height: 34px;\n    margin-top: -3px;\n}\n\n.import-excel-container .action-button span {\n    line-height: inherit;\n    font-family: Lato-Regular;\n    font-size: 15px;\n}\n\n.import-excel-container .action-button:hover,\n.import-excel-container .action-button:focus {\n    color: #FFF;\n}\n\n.import-excel-container .action-button.btn-success {\n    background-color: var(--ui-ok);\n    color: var(--text-ok);\n}\n\n.action-button {\n    border: 0px;\n}\n\n.display-none{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
