import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params} from '@angular/router';
import * as _ from 'lodash';

type ParamsSelectedType = { [key: string]: string | any };
type SessionFilterPrefillAllowedValuesType = string[][];

@Injectable({
    providedIn: 'root'
})
export class SessionFilterService {

    constructor() {
    }

    preffiledConditions(sessionFilterPrefillAllowedValues: SessionFilterPrefillAllowedValuesType, fields: string[]): boolean {
        return _.flatten(sessionFilterPrefillAllowedValues).some((f) => fields.includes(f));
    }

    preffiledDeepLinksValues(params: ParamsSelectedType, values: SessionFilterPrefillAllowedValuesType): string[][] {
        const keys = Object.keys(params);
        return values.filter((val: string[]) => keys.find((key) => val.includes(key)));
    }

    setSessionFilter(routeParams: Params, paramsSelected: ParamsSelectedType, sessionFilterPrefillAllowedValues: SessionFilterPrefillAllowedValuesType, fields: string[], keySelection: string): { sessionFilterPrefillAllowedValues: SessionFilterPrefillAllowedValuesType, paramsSelected: ParamsSelectedType } {
        if (!sessionFilterPrefillAllowedValues?.length) {
            return {sessionFilterPrefillAllowedValues, paramsSelected};
        }
        const sessionFilterhasValue = fields.some((field: string) => sessionFilterPrefillAllowedValues.some((sessionFilter) => sessionFilter?.includes(field)));
        if (!sessionFilterhasValue) {
            return {sessionFilterPrefillAllowedValues, paramsSelected};
        }
        paramsSelected[fields[0]] = routeParams[fields[0]];
        paramsSelected[fields[1]] = [{[keySelection]: routeParams[fields[0]]}];
        if (paramsSelected[fields[0]].includes(',')) {
            paramsSelected[fields[1]] = [];
            const ids = paramsSelected[fields[0]].split(',');
            ids.forEach((id) => {
                paramsSelected[fields[1]].push({[keySelection]: id});
            });
        }
        sessionFilterPrefillAllowedValues.forEach((sessionFilter, index) => {
            if (fields.includes(sessionFilter[0]) || fields.includes(sessionFilter[1])) {
                sessionFilterPrefillAllowedValues[index] = null;
            }
        });
        return {sessionFilterPrefillAllowedValues: sessionFilterPrefillAllowedValues.filter(n => !!n), paramsSelected};
    }
}
