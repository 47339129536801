// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

.isDisable{
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.mr-2 {
    margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/machine-list/machine-list.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n.isDisable{\n    pointer-events: none;\n    cursor: default;\n    opacity: 0.6;\n}\n\n.mr-2 {\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
