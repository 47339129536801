import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItem, GroupItem, OrderItem } from './filter.model';
import { MeetingsService } from '../../meetings/services/meetings.service';
import { AuthService } from '../../main/access/services/auth.service';
import { ActionsService } from '../../meetings/services/actions.service';

@Component({
    selector: 'filter-component',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.css'],
})

export class FilterComponent {
    
    @Input() arr_filter: Array<FilterItem> = [];
    @Input() arr_order: Array<OrderItem> = [];
    @Input() arr_group: Array<GroupItem> = [];
    @Input() defaultAgrupament: string;
    @Input() arr_actions_group: Array<any> = [];

    @Input() arr_users: Array<any> = [];
    @Input() arr_actions_status: Array<any> = [];
    @Input() arr_meeting_departments: Array<any> = [];
    @Input() arr_meeting_columns: Array<any> = [];
    @Input() arr_meeting_column_answer: Array<any> = [];
    @Input() arr_meeting_indicators: Array<any> = [];

    @Output() onFilter: EventEmitter<any> = new EventEmitter();
    @Output() onOrder: EventEmitter<any> = new EventEmitter();
    @Output() onGroup: EventEmitter<any> = new EventEmitter();

    arr_filters: Array<any> = [];
    arr_orders: Array<string> = [];
    str_agrupament: string = "";
    bol_isDisabled_filter_column_answer: boolean = true;

    constructor(
        private _meetingsService: MeetingsService, 
        private _actionsService: ActionsService,
        private _authService: AuthService
    ) {
        this.str_agrupament = this.defaultAgrupament;
        this.arr_filters = [];
    }

    /* -------- FILTER -------- */
    filter() {        
        this.onFilter.emit(this.arr_filters);        
    }

    
    selectedIndicatorChangedFilter(event, i) {
        this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_indicator : null;
        this.filter(); 
    }

    changeFilterValue(value, i, secondValue = false) {
        if (secondValue) {
            this.arr_filters[i].value2 = value;            
        } else {
            this.arr_filters[i].value = value;
        }
        this.filter();
    }

    removeFilter(i) {
        this.arr_filters.splice(i, 1);
        this.filter();
    }

    selectedStatusChangedFilter(ev, i) {
        const filtersArr = ev.selectedOptions.map(item => {
            return item.cod_actions_status;
        });

        const filters = filtersArr.toString();

        this.arr_filters[i].value = (filters) ? filters : null;
        this.filter();
    }
    
    selectedUsersChangedFilter(event, i) {
        this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].str_name : null;
        this.filter();
    }

    // Department Filter (multiple choice)
    selectedDepartmentChangedFilter(event, i) {     
        const data = event.selectedOptions.map(c => c.cod_areas);

        this.arr_filters[i].value = data ? data : null;        
        this.filter(); 
       }


    setFilter(str_filter, str_value1, str_value2) {
        let $iCount = 0;
        let $iIndex = null;
        this.arr_filters.forEach(filterType => {
            if (filterType.type == str_filter && str_value1 != '') {
                $iIndex = $iCount;
            }
            $iCount++;
        });
        if ($iIndex != null) {
            this.arr_filters[$iIndex] = {type: str_filter, value: str_value1, value2: str_value2};
        }
        else if (!this.arr_filters.filter(function (obj) {
            return obj.type == str_filter;
        }).length) {
            this.arr_filters.push({type: str_filter, value: str_value1, value2: str_value2});
        }
        this.filter();
    }

    selectedColumnChangedFilter(event, i) {
        if (event.selectedOptions[0] && event.selectedOptions[0].cod_columns) {
            this.arr_filters[i].value = {str: event.selectedOptions[0].str_name_column, cod: event.selectedOptions[0].cod_columns};
        }

        if (event.selectedOptions[0] && event.selectedOptions[0].cod_columns_options) {
            this.arr_filters[i].value2 = {str: event.selectedOptions[0].str_name, cod: event.selectedOptions[0].cod_columns_options};
        } else {
            this.arr_filters[i].value2 = {str: '', cod: 0};
        }

        this.filter();
    }

    updateInputAnswer(event, i) {
        this.arr_filters[i].value = {str: '', cod: 0};
        this.arr_filters[i].value2 = {str: '', cod: 0};
        if (event.selectedOptions.length > 0) {
            this._meetingsService.getColumnOptions(event.selectedOptions[0].cod_columns, this._authService.getAuthenticatedUser().id).subscribe(
                data => {
                    if (data.code === 888 && data.content.code === 888) {
                        this.arr_meeting_column_answer = data.content.results;
                        if (this.arr_meeting_column_answer.length == 0) {
                            this.arr_meeting_column_answer = this._actionsService.getColumnNotOptions();
                        }
                        this.bol_isDisabled_filter_column_answer = (this.arr_meeting_column_answer.length > 0) ? false : true;
                    }
                }, err => {
                    console.log(err);
                }
            );
        } else {
            this.arr_meeting_column_answer = [];
            this.bol_isDisabled_filter_column_answer = true;
        }
        this.selectedColumnChangedFilter(event, i);
    }

    /* -------- ORDER -------- */
    order() {
        this.onOrder.emit(this.arr_orders);
    }

    setOrder(order: string) {
        if (this.arr_orders.indexOf(order) < 0)
        this.arr_orders.push(order);
        this.order();
    }
    
    removeOrder(index) {
        this.arr_orders.splice(index, 1);
        this.order();
    }

    /* -------- AGRUPAMENT -------- */
    group() {
        this.onGroup.emit(this.str_agrupament);
    }

    setAgrupament(group: string){
        this.str_agrupament = group;
        this.group();
    }
    
}
