import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/main/access/services/auth.service';
import { NotificationService } from 'app/main/services/notification.service';
import { SelectPagination } from 'app/logbooks/models/select_pagination.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {FiveWhyService} from '../../../five-why/services/five-why.service';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import { ShiftConfigItem } from 'app/cockpit/model/shift';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.css'],
})
export class ShiftListComponent implements OnInit, OnDestroy {
  maxPage: number;
  codLocation: number;
  totalPages: unknown[] = [];
  shiftTotalPages = 0;
  shiftConfigs: ShiftConfigItem[] = [];
  editingShiftConfig: ShiftConfigItem;
  privilege: boolean;
  showError: boolean;
  errorMessage: string;
  isLoading = false;
  editingShift = false;
  pageIndex = 1;
  searchForm: UntypedFormGroup;
  resultsPerPageSelectOption: SelectPagination[] = [];
  conponentShutdown$ = new Subject<void>();
  timezone: string;

  constructor(
    private _authService: AuthService,
    private _notify: NotificationService,
    private _translateService: TranslateService,
    private _fiveWhyService: FiveWhyService,
    private _userPreferencesService: UserPreferencesService,
  ) {
    this.privilege = this._authService.getUserPrivilege('ADMIN_LIST_CREW');
    this.initResultsPerPageSelectOption();
    this.initForm();
  }

  initForm() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl(''),
      limit: new UntypedFormControl(this.resultsPerPageSelectOption[0].qty),
      page: new UntypedFormControl(0),
    });
    this.searchForm.patchValue({ page: 0 }, { emitEvent: false });
    this.searchForm.valueChanges
      .pipe(
        debounceTime(environment().debounceTime),
        takeUntil(this.conponentShutdown$)
      )
      .subscribe(() => {
        this.search();
      });
  }

  initResultsPerPageSelectOption() {
    this._translateService
      .get('GENERAL.PAGINATION_SELECT')
      .pipe(take(1))
      .subscribe((translate) => {
        this.resultsPerPageSelectOption = [
          { qty: 10, str_display: `${translate} 10` },
          { qty: 50, str_display: `${translate} 50` },
          { qty: 100, str_display: `${translate} 100` },
        ];
      });
  }

  ngOnInit() {
    this.search();
    this.getTimezone();
  }

  ngOnDestroy() {
    this.conponentShutdown$.next();
    this.conponentShutdown$.complete();
  }

  getTimezone() {
    this._userPreferencesService.getUserPreferences(this._authService.getAuthenticatedUser().id).subscribe(data => {
      let userData = this._userPreferencesService.getArrayContent(data);
      userData.forEach(element => {
          if(element.str_pref_key == "timezone" ) {
            this.timezone = this._userPreferencesService.toGMTString(element.str_pref_value);
          }
      });
    });
  }

  search() {
    this.isLoading = true;
    const { searchTerm, limit, page } = this.searchForm.value;
    const payload = {
      term: searchTerm,
      limit,
      offset: page * limit,
      cod_user: this._authService.getAuthenticatedUser().id,
      dsc_page_privilege: 'ADMIN_LIST_CREW',
    };
    this._fiveWhyService.listShifts(payload)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.shiftConfigs = response.content.results;
          this.shiftTotalPages = response.content.results[0].total_count;
          this.maxPage = Math.ceil(
              (response.content.results.length > 0 ? response.content.results[0].total_count : 0) / limit
          );
          if (this.maxPage * limit > this.shiftTotalPages) {
            --this.maxPage;
          }
          this.totalPages = this.pagination(page, this.maxPage);
        },
        error: (error) => {
          this.isLoading = false;
          this._showErrorMessage(error);
        }
      })
  }

  changePage(page) {
    this.searchForm.setValue({page});
    this.search();
  }

  inputChanged($event: { target: { value: string } }) {
    const { value } = $event.target;
    this.updateForm({ searchTerm: value });
  }

  selectionChanged($event: { selectedOptions: SelectPagination[] }) {
    const { selectedOptions } = $event;
    if (selectedOptions.length <= 0) { return; }
    this.updateForm({ limit: selectedOptions[0].qty });
  }

  pageChanged(page: number) {
    this.updateForm({ page });
  }

  private updateForm(payload: {
    searchTerm?: string;
    limit?: number;
    page?: number;
  }) {
    this.searchForm.patchValue(payload);
    this.searchForm.updateValueAndValidity();
    this.searchForm.markAsDirty();
  }

  pagination(currentPage: number, nrOfPages: number) {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l = 0;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) { range.push(i); }
    }
    range.push(nrOfPages);

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err: string) {
    this._translateService
      .get(err)
      .pipe(take(1))
      .subscribe((translate) => {
        this._notify.error(translate);
      });
  }

  editShift(shift: ShiftConfigItem) {
    this.editingShiftConfig = shift;
    this.editingShiftConfig.timezone = this.timezone;
    this.editingShift = true;
  }

  closeEditShift(shiftConfig?: ShiftConfigItem) {
    this.editingShift = false;
    if (shiftConfig) {
      this.search();
    }
  }
}
