import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "../components/components.module";
import { CockpitService } from "./services/cockpit.service";
import { TranslateModule } from "@ngx-translate/core";
import { MainService } from "./services/main.service";
import { CommonModule } from "@angular/common";
import { FiveWhyModule } from "app/five-why/five-why.module";
import PowerBiTokenService from "./services/PowerBiToken.service";
import { PowerBiClientService } from "./services/PowerBiClient.service";
import GenerateParamsService from "./services/generateParams.service";
import CockpitFilterService from "./services/cockpit.filter.service";
import { CockpitCustomFilters } from "./model/cockpit-custom-filters";
import { LogbookRoutineService } from "app/logbooks/services/logbook-routine.service";
import { CockpitShiftService } from "./services/cockpit.shift.service";


const routes: Routes = [];

@NgModule({
  declarations: [
  ],
  exports: [],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    FiveWhyModule,
  ],
  providers: [
    MainService,
    CockpitService,
    PowerBiTokenService,
    PowerBiClientService,
    GenerateParamsService,
    CockpitFilterService,
    CockpitCustomFilters,
    LogbookRoutineService,
    CockpitShiftService
  ],
})
export class CockpitModule {}
