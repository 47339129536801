import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MeetingsIndicatorsService } from '../services/meetings-indicators.service';
import { MeetingsTypesService } from '../services/meetings-types.service';
import { PeriodicityTypesService } from '../../logbooks/services/periodicity-types.service';
import { UsersService } from '../../admin/services/users.service';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { AuthService } from '../../main/access/services/auth.service';
import { Type } from '../models/meetings-types.model';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectPagination } from "../../logbooks/models/select_pagination.model";
import { NotificationService } from './../../main/services/notification.service';
import { firstValueFrom } from 'rxjs';
import { Location } from '@angular/common';

@Component({
    selector: 'app-meetings-types',
    templateUrl: './meetings-types-list.component.html',
    styleUrls: ['./meetings-types-list.component.css']
})
export class MeetingsTypesComponent implements OnInit, OnDestroy {
    @Input() cod_meeting_type: number = null
    @Output() closeEdit = new EventEmitter<any>();

    meetingsTypes = [];
    meetingsIndicators = [];
    periodicities = [];
    meetingsIndicatorsSelected = [];
    meetingsWorldSelected = [];
    meetingsTypesIndicatorsSelected = [];
    codPeriodicitySelected = [];
    worlds = [];
    agendaCategories: Array<any> = [];
    str_filter: string;
    str_term = '';
    str_file_path = environment().uploadGetFilesApiUrl + '/';
    cod_file = '';
    searchTimer: any;
    model: Type;
    editMode: boolean;
    bol_success: boolean;
    filesToUpload: Array<File> = [];
    error_message_upload: string;
    // bol_error_upload: boolean = false;
    error_message_submit: string;
    bol_error_submit: boolean = false;
    bol_confirm_delete: any = [];
    meetingToDelete: any;
    errorMessage: string;
    showError: boolean;
    arr_extended_tab_selected: any = [];
    arr_extended_tabs: any = [];
    loading_file: boolean = false;
    bol_loading_save: boolean = false;
    bol_loading_list: boolean = false;
    bol_show_formulary: boolean = false;
    bol_tor_agenda: boolean = false;
    bol_edit_tor_agenda: boolean = false;
    arr_obj_agenda: any;

    int_agenda_index: number = -1;
    obj_edit_agenda: any = {};

    str_objective: any;
    str_owner: any;
    str_participants: any;
    str_location: any;
    str_time: any;
    str_frequency: any;
    str_ground_rules: any;
    str_input: any;
    str_output: any;
    str_kpis: any;
    str_hyperlink: any;
    str_hyperlink_desc: any;
    // arr_hyperlink_agenda_del: any;
    arr_obj_edit_agenda: any;
    arr_obj_del_agenda: any;
    linkIdx: any;
    linkAgendaIdx: any;
    link_timeout: any;
    bol_error_submit_agenda: boolean = false;
    error_message_submit_agenda: any = false;

    bol_unsaved_changes: boolean = false;
    privMeetingTypes: any;
    is_loading: boolean = false;

    showSuccessMessage: boolean;
    showErrorMessage: boolean;
    emitSuccessMessage: boolean;
    emitErrorMessage: boolean;

    maxPage: number;
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    selectedCodMeetingTypeCategory: Array<number> = [];

    str10perpage: string;
    str50perpage: string;
    str100perpage: string;

    isEditLink: boolean = false;
    idx_link: number;
    obj_edit_hyperlink: any;
    error_message_submit_link: boolean = false;
    bol_error_submit_link: any;
    standardMeetingTypes: any = [];
    standardMeetingTypeSelected: any = [];
    isLoadingStandardMeetingTypes: boolean;

    isDisableButton: boolean = true;

    isReload: boolean = false;

    
    durationsList: any[] = [ // Placeholder durations in case translations fail or take too long
        {
            int_duration: 15,
            str_description: '15m'
        },
        {
            int_duration: 30,
            str_description: '30m'
        },
        {
            int_duration: 45,
            str_description: '45m'
        },
        {
            int_duration: 60,
            str_description: '1h'
        }
    ];
    selectedDuration: any = [];

    constructor(
        private readonly indicatorsService: MeetingsIndicatorsService,
        private readonly typesService: MeetingsTypesService,
        private readonly _periodicityTypesService: PeriodicityTypesService,
        private readonly _usersService: UsersService,
        public _authService: AuthService,
        private readonly sanitizer: DomSanitizer,
        private readonly _translateService: TranslateService,
        private readonly _notify: NotificationService,
        private readonly _userPrefsService: UserPreferencesService,
        private readonly _location: Location,
    ) {
        this.model = new Type();
        this.editMode = false;
        this.bol_success = false;
        this.str_filter = '';
        this.filesToUpload = [];
        this.searchTimer = null;
        this.meetingToDelete = null;
        this.bol_confirm_delete = false;
        this.str_objective = '';
        this.str_owner = '';
        this.str_participants = '';
        this.str_location = '';
        this.str_time = '';
        this.str_frequency = '';
        this.str_ground_rules = '';
        this.str_input = '';
        this.str_output = '';
        this.str_kpis = '';
        this.str_hyperlink = '';
        this.str_hyperlink_desc = '';
        // this.arr_hyperlink_agenda_del = [];

        this.arr_obj_agenda = [/*{
            cod_agenda: -1,
            cod_order: 1, 
            str_owner: '',
            isDel: null,
            str_description: '',
            arr_hyperlinks: []
        }*/];
        this.arr_obj_edit_agenda = [];//has cod_agenda of edited agendas
        this.arr_obj_del_agenda = [];//has obj of deleted agendas

        this.pageIndex = 1;
        this.totalPages = [];

        this.str10perpage = '10';
        this.str50perpage = '50';
        this.str100perpage = '100';
        this.resultsPerPage = [
            new SelectPagination(10, this.str10perpage),
            new SelectPagination(50, this.str50perpage),
            new SelectPagination(100, this.str100perpage),
        ];
        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;

        this.getDurationsArray();
    }

    translatePaginationSelect(){
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this.str50perpage = '50 ' + translate;
            this.str100perpage = '100 ' + translate;
            this.resultsPerPage = [
                new SelectPagination(10, this.str10perpage),
                new SelectPagination(50, this.str50perpage),
                new SelectPagination(100, this.str100perpage),
            ];
            this.resultsPerPageSelectOption = [this.resultsPerPage.find(item => item.qty === this.resultsPerPageSelected) ?? this.resultsPerPage[0]];
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.arr_obj_agenda, event.previousIndex, event.currentIndex);
        this.orderAgendaNumber();
    }

    orderAgendaNumber() {
        this.arr_obj_agenda = this.arr_obj_agenda.map((val, i) => {
            val.cod_order = i + 1;
            if (val.cod_agenda != 0) {
                this.arr_obj_edit_agenda.push(val.cod_agenda);
                this.isDisableButton = false;
                this.bol_unsaved_changes = true;
            }
            return val;
        });
    }

    ngAfterViewInit() {
        // this.search();
    }

    ngOnDestroy(): void {
        this.cod_meeting_type = null;
        this.closeEdit.emit();
    }

    ngOnInit() {
        this.privMeetingTypes = this._authService.getUserPrivilege('MEETING_SELECT_WORLDS');
        this.listExtendedTabs();
        this.loadMeetingsStandardTypes();
        this.listMeetingsTypes();
        //this.listIndicators();
        this.listWorlds();
        this.listPeriodicities();
        this.listAgendaCategories();

        this._translateService.onLangChange.subscribe(() => {
            this.translatePaginationSelect();
            this.listPeriodicities();
            this.listAgendaCategories();
            this.loadMeetingsStandardTypes();
            this.meetingsTypes.forEach(mt => {
                this.getStandardTypeDescription(mt)
                this.getTranslatedPeriodicityDescription(mt)
            });
            this.getDurationsArray();
        });

        this.getMeetingType();
    }

    getMeetingType() {
        if(!this.cod_meeting_type) return;

        this.typesService.getMeetingType(this.cod_meeting_type, this._authService.getAuthenticatedUser().id).subscribe({
            next: (result: any) => {
                if (result.code == 888 && result.content.code == 888 && result.content.length > 0) {
                    let meeting_type = result.content.results[0];
                    if(!meeting_type.bol_enable_edit_remove || !this._authService.getUserPrivilege('MEETING_SELECT_WORLDS')){
                        this._notify.error({text: 'USERS.PRIVILEGE_LEVEL', translate: true});
                        this.cod_meeting_type = null;
                        this.closeEdit.emit();
                    } else{
                        this.loadMeetingType(meeting_type);
                    }
                } else {
                    this._notify.error({ text: 'GENERAL.FAIL_TO_FETCH', translate: true });
                    this.cod_meeting_type = null;
                    this.closeEdit.emit();
                }
                
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
                this._notify.error({ text: 'GENERAL.FAIL_TO_FETCH', translate: true });
                this.cod_meeting_type = null;
                this.closeEdit.emit();
            }
        })
    }

    getDurationsArray() {
        this.typesService.getDurationsArray(this._translateService).subscribe((results) => {
            this.durationsList = results;
        });
    }

    loadMeetingsStandardTypes() {
        this.isLoadingStandardMeetingTypes = true;
        this.typesService.getMeetingsStandardTypes(this._authService.getAuthenticatedUser().id).subscribe(data => {
            this.standardMeetingTypes = data.content.results;
            this.isLoadingStandardMeetingTypes = false;
        })
    }

    getStandardTypeDescription(meetingType: Type): void {
        if (meetingType.bol_standard) {
            this._translateService.get('MEETING_TYPES_LIST.STANDARD_DESCRIPTION').subscribe(translate => {
                const standardType = this.standardMeetingTypes.find(standardType => standardType.cod_meeting_standard_types === meetingType.cod_meeting_standard_type);
                meetingType.str_standard_description = translate + ' - ' +standardType.str_description;
            });
        } else {
            this._translateService.get('MEETING_TYPES_LIST.NOT_STANDARD_DESCRIPTION').subscribe(translate => {
                meetingType.str_standard_description = translate;
            });
        }
    }

    getTranslatedPeriodicityDescription(meetingType: Type): void{
        /*
            ID - PERIODICITY        - TRANSLATION KEY
            1  - Daily              - "GENERAL.PERIODICITY_DAILY"
            4  - Weekly             - "GENERAL.PERIODICITY_WEEKLY"
            5  - Monthly            - "GENERAL.PERIODICITY_MONTHLY"
            7  - Every two months   - "GENERAL.PERIODICITY_BIMONTHLY"
            8  - Every three months - "GENERAL.PERIODICITY_EVERY_3_MONTHS"
            9  - Every six months   - "GENERAL.PERIODICITY_SEMIANNUAL"
            10 - Annual             - "GENERAL.PERIODICITY_YEARLY"
            11 - Every two weeks    - "GENERAL.PERIODICITY_BI_WEEKLY"
            12 - Every four months  - "GENERAL.PERIODICITY_EVERY_4_MONTHS"
            13 - As Needed          - "GENERAL.PERIODICITY_AS_NEEDED"
         */
        let periodicitiesKeys = {
            1: "GENERAL.PERIODICITY_DAILY",
            4: "GENERAL.PERIODICITY_WEEKLY",
            5: "GENERAL.PERIODICITY_MONTHLY",
            7: "GENERAL.PERIODICITY_BIMONTHLY",
            8: "GENERAL.PERIODICITY_EVERY_3_MONTHS",
            9: "GENERAL.PERIODICITY_SEMIANNUAL",
            10: "GENERAL.PERIODICITY_YEARLY",
            11: "GENERAL.PERIODICITY_BI_WEEKLY",
            12: "GENERAL.PERIODICITY_EVERY_4_MONTHS",
            13: "GENERAL.PERIODICITY_AS_NEEDED"
        }
        let pKey = periodicitiesKeys[meetingType.cod_periodicity];
        if(pKey){
            this._translateService.get(pKey).subscribe(translate => {
                meetingType.str_periodicity_translation = translate;
            });
        } else {
            meetingType.str_periodicity_translation = meetingType.str_description;
        }
        //Alternative solution: get from periodicities list, the same used on the periodicity selector on new meeting type
        /*
        meetingType.str_periodicity_translation = this.periodicities.find(p => 
            p.cod_periodicity == meetingType.cod_periodicity
        )?.str_description ?? meetingType.str_description;
        */
        
    }

    changeStandard() {
        if (!this.model.bol_standard) {
            this.standardMeetingTypeSelected = [];
            this.model.cod_meeting_standard_type = null;
        }

        this.bol_unsaved_changes = true;
        this.isDisableButton = false;
    }

    selectStandardMeetingType($event) {
        this.standardMeetingTypeSelected = $event.selectedOptions;
        this.model.cod_meeting_standard_type = this.standardMeetingTypeSelected[0].cod_meeting_standard_types;
        this.bol_unsaved_changes = true;
        this.isDisableButton = false;
    }

    listAgendaCategories(): void {
        this.typesService
            .getAgendaCategories(this._authService.getAuthenticatedUser().id, this._userPrefsService.getLocalLanguage())
            .subscribe({
                next: (data) => {
                    if (data.type === 'success') {
                        this.agendaCategories = data.content.results;
                    } else {
                        this._showErrorMessage(data.content);
                    }
                },
                error: (err) => {
                    this._authService.errCheck(err);
                    console.log(err);
                },
            });
    }

    onAgendaCategoryChanged($event): void {
        const category = $event.selectedOptions[0];
        if (category)
            this.obj_edit_agenda.cod_meeting_type_category = category.cod_meeting_type_category;
    }

    getAgendaCategoryDescription(cod_meeting_type_category: number): string {
        const category = this.agendaCategories.find(c => c.cod_meeting_type_category == cod_meeting_type_category);
        return category ? category.str_description : null;
    }

    selectIndicators(event) {
        this.isDisableButton = false;
        this.bol_unsaved_changes = true;
        this.meetingsIndicatorsSelected = event.selectedOptions;
    }

    selectDuration(event) {
        this.isDisableButton = false;
        this.bol_unsaved_changes = true; 
        this.selectedDuration = event.selectedOptions;
        if (typeof event.selectedOptions[0] != typeof undefined) {
            this.model.int_duration = this.selectedDuration[0].int_duration;
        }
        else {
            this.model.int_duration = null;
        }
    }

    selectWorlds(event) {
        this.isDisableButton = false;
        this.bol_unsaved_changes = true;
        this.meetingsWorldSelected = event.selectedOptions;
        this.meetingsIndicators = [];
        this.listIndicators();
    }

    selectExtendedTabs(event) {
        this.model.str_extended_tabs = event.selectedOptions[0].str_name;
    }

    selectPeriodicity(event) {
        this.isDisableButton = false;
        this.bol_unsaved_changes = true;
        if (typeof event.selectedOptions[0] != typeof undefined) {
            this.model.cod_periodicity = event.selectedOptions[0].cod_periodicity;
            this.model.str_frequency = event.selectedOptions[0].str_description;
        }
        else {
            this.model.cod_periodicity = null;
        }

    }

    private _handleSubmitValidation(message: string): void {
        this._translateService.get(message).subscribe(translate => {
            this.bol_error_submit = true;
            this.isDisableButton = false;
            this.error_message_submit = translate;
            this._notify.warning(translate);
        });
    }

    submit() {
        // this.bol_error_upload = false;
        this.model.cod_usu_ins = this._authService.getAuthenticatedUser().id;
        this.loading_file = false;
        this.bol_error_submit = false;
        this.isDisableButton = true;
        this.error_message_submit = '';

        if (this.model.str_name.length <= 0 || !this.model.str_name) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.ERROR_NAME_IS_REQUIRED');
            return;
        }
        if (!this.model.cod_periodicity || this.model.cod_periodicity <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.PERIODICITY_REQUIRED_MSG');
            return;
        }

        if (this.meetingsWorldSelected.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.WORLD_REQUIRED_MSG');
            return;
        }


        const params = this.model;
        params['indicators'] = this.meetingsIndicatorsSelected;
        params['worlds'] = this.meetingsWorldSelected;
        params['cod_user'] = this._authService.getAuthenticatedUser().id;
        params['arr_agendas'] = this.arr_obj_agenda;

        let agendaParam = {
            cod_user: this._authService.getAuthenticatedUser().id,
            cod_types: this.model.cod_meetings_types ? this.model.cod_meetings_types : 0,
            arr_agendas: []
        };

        for (const element of this.arr_obj_del_agenda) {
            agendaParam.arr_agendas.push(element);
        }
        for (const element of this.arr_obj_agenda) {
            if (element.cod_agenda == 0 || this.arr_obj_edit_agenda.indexOf(element.cod_agenda) != -1) {
                agendaParam.arr_agendas.push(element);
            }
        }

        if (this.model.bol_standard && !this.model.cod_meeting_standard_type) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.STANDARD_TYPE_REQUIRED');
            return;
        }
        if (!this.model.str_objective || this.model.str_objective.length <= 0) {

            this._handleSubmitValidation('MEETING_TYPES_LIST.OBJECTIVE_IS_REQUIRED');
            return;
        }
        if (!this.model.str_owner || this.model.str_owner.length <= 0) {

            this._handleSubmitValidation('MEETING_TYPES_LIST.OWNER_IS_REQUIRED');
            return;
        }
        if (!this.model.str_participants || this.model.str_participants.length <= 0) {

            this._handleSubmitValidation('MEETING_TYPES_LIST.PARTICIPANTS_IS_REQUIRED');
            return;
        }
        if (!this.model.str_location || this.model.str_location.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED');
            return;
        }
        if (!this.model.int_duration || this.model.int_duration <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.DURATION_IS_REQUIRED');
            return;
        }
        if (!this.model.str_frequency || this.model.str_frequency.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.FREQUENCY_IS_REQUIRED');
            return;
        }
        if (!this.model.str_ground_rules || this.model.str_ground_rules.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.RULES_IS_REQUIRED');
            return;
        }
        if (!this.model.str_input || this.model.str_input.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.INPUT_IS_REQUIRED');
            return;
        }
        if (!this.model.str_output || this.model.str_output.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.OUTPUT_IS_REQUIRED');
            return;
        }
        if (!this.model.str_kpis || this.model.str_kpis.length <= 0) {
            this._handleSubmitValidation('MEETING_TYPES_LIST.KPIS_IS_REQUIRED');
            return;
        }
        if (!this.arr_obj_agenda || this.arr_obj_agenda.length <= 0) {

            this._handleSubmitValidation('MEETING_TYPES_LIST.AGENDA_IS_REQUIRED');
            return;
        }

        if (this.model.cod_meetings_types === undefined) {

            this.bol_loading_save = true;

            this.typesService.postNewMeetingsType(params).subscribe({ //TODO test
                next: (res) => {
                    if (res.type !== 'success') {
                        this.bol_error_submit = true;
                        this.emitSuccessMessage = false;
                        this.emitErrorMessage = true;
                        this.error_message_submit = res.content[0].message ? res.content[0].message : 'Error';
                        return;
                    } else {
                        const $this = this;
                        this.arr_obj_del_agenda = [];
                        this.bol_success = true;
                        setTimeout(function () {
                            $this.bol_success = false;
                        }, 1500);
                        this.emitSuccessMessage = true;
                        this.emitErrorMessage = false;
                        this._notify.success({ text: 'MEETING_TYPES_LIST.SAVE_MEETING_SUCCESS', translate: true });
                    }
                    if (!this.bol_error_submit) {
                        this.editMode = false;
                        this.bol_loading_save = false;
                        //    this.listMeetingsTypes();
                        this.bol_unsaved_changes = false;
                        this.isDisableButton = false;
                        this.bol_show_formulary = false;
                        this.search();
                    }
                },
                error: (err) => {
                    this.emitSuccessMessage = false;
                    this.emitErrorMessage = true;
                    this.bol_loading_save = false;
                    this._authService.errCheck(err);
                    console.log(err);
                },
            });
        } else {
            this.typesService.updateMeetingsType(params).subscribe({
                next: (res) => {
                    if (res.type !== 'success') {
                        this.bol_error_submit = true;
                        this.emitSuccessMessage = false;
                        this.emitErrorMessage = true;
                        this.error_message_submit = res.content[0].message ? res.content[0].message : 'Error';
                        return;
                    } else {
                        this.typesService.saveMeetingTypeAgenda(agendaParam).subscribe((res) => {
                            if (res.type == 'success') {
                                this.isReload = true;

                                //this.typesService.meetingsTypeChangesTor(changesParam).subscribe(res => {
                                // if (res.type == 'success') {
                                this.isDisableButton = true;
                                this.bol_unsaved_changes = false;
                                this.obj_edit_agenda = [];
                                this.bol_success = true;
                                this.editMode = false;
                                this.bol_loading_save = false;
                                // this.listMeetingsTypes();
                                const $this = this;
                                setTimeout(function () {
                                    $this.bol_success = false;
                                }, 1500);
                                this.emitSuccessMessage = true;
                                this.emitErrorMessage = false;
                                this._notify.success({
                                    text: 'MEETING_TYPES_LIST.EDITED_MEETING_SUCCESS',
                                    translate: true,
                                });
                            } else {
                                this.bol_error_submit = true;
                                this.emitSuccessMessage = false;
                                this.emitErrorMessage = true;
                                this.error_message_submit = res.content.message ? res.content.message : 'Error';
                                return;
                            }
                            if (!this.bol_error_submit) {
                                this.reloadAgenda();
                                return;
                            }
                        });
                    }
                },
                error: (err) => {
                    this.emitSuccessMessage = false;
                    this.emitErrorMessage = true;
                    this.bol_loading_save = false;
                    this._authService.errCheck(err);
                    console.log(err);
                },
            });
        }
    }

    public listMeetingsTypes() {

        this.bol_loading_list = true;
        let tmpPage = this.pageIndex;

        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }


        this.typesService
            .getMeetingsTypes(
                this.resultsPerPageSelected,
                tmpPage * this.resultsPerPageSelected,
                this.str_filter,
                this.str_file_path,
                this._authService.getAuthenticatedUser().id,
                'ADMIN_MEETING_LIST_TYPE'
            )
            .subscribe({
                next: (meetingsTypes) => {
                    const arr_meeting_types = this.typesService.getArrayContent(meetingsTypes);
                    this.meetingsTypes = arr_meeting_types;
                    this.meetingsTypes.forEach((meetingType: Type) => {
                        this.getStandardTypeDescription(meetingType);
                        this.getTranslatedPeriodicityDescription(meetingType);
                    });
                    // change file extension
                    this.arr_obj_agenda.map((item) => {
                        item.arr_hyperlinks.map((it) => {
                            it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(
                                this.downloadExtension(it.str_link)
                            );
                        });
                    });
                    this.showSuccessMessage = this.emitSuccessMessage;
                    this.showErrorMessage = this.emitErrorMessage;
                    this.emitSuccessMessage = null;
                    this.emitErrorMessage = null;
                    this.maxPage = Math.ceil(
                        (this.meetingsTypes.length > 0 ? this.meetingsTypes[0].full_count : 0) /
                            this.resultsPerPageSelected
                    );
                    this.totalPages = this._pagination(this.pageIndex, this.maxPage);
                    this.bol_loading_list = false;
                },
                error: (err) => {
                    this._authService.errCheck(err);
                    console.log(err);
                },
            });
    }

    reloadAgenda() {
        this.isReload = true;
        this.arr_obj_edit_agenda = [];
        this.arr_obj_agenda = [];
        const params = {
            cod_user: this._authService.getAuthenticatedUser().id,
            cod_types: this.model.cod_meetings_types
        };
        this.typesService.listMeetingTypeAgenda(params).subscribe({
            next: (res) => {
                if (res.type == 'success') {
                    this.arr_obj_agenda = this.typesService.getArrayContent(res);
                    this.isReload = false;
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    listPeriodicities() {
        this._periodicityTypesService
            .getPeriodicitiesList({ term: '', cod_user: this._authService.getAuthenticatedUser().id })
            .subscribe({
                next: (meetingPeriodicities) => {
                    this.periodicities = meetingPeriodicities.content.results;
                },
                error: (err) => {
                    this._authService.errCheck(err);
                    console.log(err);
                },
            });
    }

    listIndicators() {
        const params = {
            cod_user: this._authService.getAuthenticatedUser().id,
            cod_world: JSON.parse("[" + this._implodeValues(this.meetingsWorldSelected, 'cod_locations_world') + "]")
        };
        this.is_loading = true;
        this.indicatorsService.getIndicatorByZone(params).subscribe({
            next: (meetingsIndicators) => {
                this.meetingsIndicators = this.indicatorsService.getArrayContent(meetingsIndicators);
                this.is_loading = false;
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    listExtendedTabs() {
        this.arr_extended_tabs = [
            { cod_tab: '', str_name: 'NENHUM' },
            { cod_tab: 'FAROL', str_name: 'FAROL' },
            { cod_tab: 'TOR', str_name: 'TOR' },
        ];
    }

    listAgenda(cod_meetings_types) {
        const params = {
            cod_user: this._authService.getAuthenticatedUser().id,
            cod_types: cod_meetings_types
        };
        this.typesService.listMeetingTypeAgenda(params).subscribe({
            next: (meetingsAgenda) => {
                this.arr_obj_agenda = this.typesService.getArrayContent(meetingsAgenda);

                this.arr_obj_agenda.map((item) => {
                    item.arr_hyperlinks.map((it) => {
                        it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(
                            this.downloadExtension(it.str_link)
                        );
                    });
                });
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    newMeetingType() {
        if (!this._authService.getUserPrivilege('MEETING_SELECT_WORLDS')) {
            return;
        }
        this.bol_success = false;
        this.model = new Type();
        this.model.cod_usu_ins = this._authService.getAuthenticatedUser().id;
        this.model.bol_enable_edit_remove = true;
        this.meetingsTypesIndicatorsSelected = [];
        this.meetingsWorldSelected = [];
        this.codPeriodicitySelected = [];
        this.arr_extended_tab_selected = [];
        this.arr_obj_agenda = [];
        this.arr_obj_del_agenda = [];
        this.bol_show_formulary = true;
        this.standardMeetingTypeSelected = [];
        this.model.cod_meeting_standard_type = null;
        this.selectedDuration = [];
    }

    setMeetingToDelete(meetingType) {
        if (!this._authService.getUserPrivilege('MEETING_SELECT_WORLDS')) {
            return;
        }
        if (!meetingType.bol_enable_edit_remove)
            return;
        this.meetingToDelete = meetingType;
        this.bol_confirm_delete = true;
    }

    deleteMeetingType() {
        this.meetingToDelete.cod_usu_del = this._authService.getAuthenticatedUser().id;
        this.typesService.deleteMeetingsType(this.meetingToDelete).subscribe({
            next: (res) => {
                this.editMode = false;
                this.bol_confirm_delete = false;
                this.emitSuccessMessage = true;
                this.emitErrorMessage = false;
                this.listMeetingsTypes();
                this._notify.success({ text: 'MEETING_TYPES_LIST.DELETED_SUCCESSFULLY', translate: true });
            },
            error: (err) => {
                this.bol_confirm_delete = false;
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    async loadMeetingType(meetingsType: Type) {
        if (!this._authService.getUserPrivilege('MEETING_SELECT_WORLDS')) {
            return;
        }
        this._location.replaceState(`/admin/meeting-type/${meetingsType.cod_meetings_types}`);
        window.scrollTo(0, 0);
        this.bol_success = false;
        this.bol_loading_save = true;
        this.editMode = true;
        this.model = new Type;
        this.model.cod_meetings_types = meetingsType.cod_meetings_types;
        this.model.str_name = meetingsType.str_name;
        this.model.str_description = meetingsType.str_description;
        this.model.cod_periodicity = meetingsType.cod_periodicity;
        this.model.str_pdf_path = meetingsType.str_pdf_path;
        this.model.cod_usu_ins = this._authService.getAuthenticatedUser().id;
        this.model.str_objective = meetingsType.str_objective;
        this.model.str_owner = meetingsType.str_owner;
        this.model.str_participants = meetingsType.str_participants;
        this.model.str_location = meetingsType.str_location;
        this.model.str_time = meetingsType.str_time;
        this.model.int_duration = meetingsType.int_duration;
        this.selectedDuration = [{int_duration: meetingsType.int_duration}];
        this.model.str_frequency = meetingsType.str_frequency;
        this.model.str_ground_rules = meetingsType.str_ground_rules;
        this.model.str_input = meetingsType.str_input;
        this.model.str_output = meetingsType.str_output;
        this.model.str_kpis = meetingsType.str_kpis;
        this.model.bol_standard = meetingsType.bol_standard;
        this.model.cod_meeting_standard_type = meetingsType.cod_meeting_standard_type;
        this.model.bol_enable_edit_remove = meetingsType.bol_enable_edit_remove;

        const standardMeetingType = this.standardMeetingTypes.find(standardType => standardType.cod_meeting_standard_types === this.model.cod_meeting_standard_type);
        if (standardMeetingType)
            this.standardMeetingTypeSelected = [standardMeetingType];
        this.bol_show_formulary = true;

        const obj_extented_tab = _.find(this.arr_extended_tabs, aba => aba.str_name === meetingsType.str_extended_tabs);
        if (obj_extented_tab) {
            this.arr_extended_tab_selected = [obj_extented_tab];
            this.model.str_extended_tabs = obj_extented_tab.cod_tab;
        } else {
            this.arr_extended_tab_selected = [];
            this.model.str_extended_tabs = null;
        }
        this.model.cod_file = meetingsType.cod_file;
        try {
            let result =
                await firstValueFrom(this.typesService.getMeetingsTypesIndicators(this.model.cod_meetings_types, this.model.cod_usu_ins));
            this.meetingsTypesIndicatorsSelected
                = Array.isArray(result.content.results) ? result.content.results : [result.content.results];
            result = await firstValueFrom(this.typesService.getMeetingsTypesLocations(this.model.cod_meetings_types, this.model.cod_usu_ins));
            this.meetingsWorldSelected = Array.isArray(result.content.results) ? result.content.results : [result.content.results];
            this.listIndicators();
            this.codPeriodicitySelected = [{ cod_periodicity: meetingsType.cod_periodicity }];
            this.listAgenda(this.model.cod_meetings_types);

        } catch (error) {
            this._authService.errCheck(error);
            console.log(error);
        }
        this.bol_loading_save = false;
    }

    public _search() {
        if (this.str_filter.length < environment().minCharStartSearch && this.str_filter.length !== 0) {
            return;
        }
        this.pageIndex = 1;
        this.search();
    }

    public search() {
        clearTimeout(this.searchTimer);
        const $this = this;
        this.searchTimer = setTimeout(function () {
            $this.listMeetingsTypes();
        }, environment().debounceTime);
    }

    listWorlds() {
        this._usersService.getWorlds(this._authService.getAuthenticatedUser().id, 'MEETING_SELECT_WORLDS').subscribe({
            next: (data) => {
                this.worlds = this._usersService.getArrayContent(data);
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }

    cancel(typesForm: NgForm) {
        this.emitSuccessMessage = false;
        this.emitErrorMessage = false;
        this.meetingsIndicators = [];
        this.editMode = false;
        this.bol_error_submit = false;
        this.bol_show_formulary = false;
        this.bol_unsaved_changes = false;
        this.isDisableButton = true;
        typesForm.reset();

        this.listMeetingsTypes();
        // this.arr_extended_tab_selected = [];
        const $this = this;
        setTimeout(function () {
            $this.bol_success = false;
        }, 2000);

        this.cod_meeting_type = null;
        this.closeEdit.emit();

    }

    closeConfirmMessage() {
        this.bol_confirm_delete = false;
    }

    private _showErrorMessage(err) {
        this.errorMessage = err;
        this.showError = true;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    newAgenda() {
        this.bol_error_submit_agenda = false;
        this.bol_tor_agenda = true;
        this.bol_edit_tor_agenda = false;
        this.selectedCodMeetingTypeCategory = [];

        this.obj_edit_agenda = {
            cod_agenda: null,
            cod_order: null,
            str_owner: '',
            isDel: null,
            cod_meeting_type_category: null,
            str_date: '',
            str_description: '',
            arr_hyperlinks: [/*{
                    cod_agenda_hyperlinks: 0,
                    str_hyperlink: '',
                    str_hyperlink_desc: '',
                    isDel: null
                }*/
            ]
        }
    }

    private _handleAgendaValidation(message: string): void {
        this._translateService.get(message).subscribe(translate => {
            this.bol_error_submit_agenda = true;
            this.error_message_submit_agenda = translate;
            this._notify.warning(translate);
        });
    }

    addAgenda() {
        let idx;

        this.bol_error_submit_agenda = false;

        if (!this.obj_edit_agenda.cod_meeting_type_category) {
            this._handleAgendaValidation('MEETING_TYPES_LIST.CATEGORY_REQUIRED');
            return;
        }

        if (this.obj_edit_agenda.str_date.length <= 0 || !this.obj_edit_agenda.str_date) {
            this._handleAgendaValidation('MEETING_TYPES_LIST.DATE_REQUIRED');
            return;
        }

        if (this.obj_edit_agenda.str_description.length <= 0 || !this.obj_edit_agenda.str_description) {
            this._handleAgendaValidation('MEETING_TYPES_LIST.DESCRIPTION_REQUIRED');
            return;
        }

        if (this.obj_edit_agenda.str_owner.length <= 0 || !this.obj_edit_agenda.str_owner) {
            this._handleAgendaValidation('MEETING_TYPES_LIST.OWNER_REQUIRED');
            return;
        }

        if (!this.addLink()) {
            return
        }

        this.bol_unsaved_changes = true;
        this.isDisableButton = false;
        if (this.bol_edit_tor_agenda) {
            this.arr_obj_agenda[this.int_agenda_index].cod_meeting_type_category = this.obj_edit_agenda.cod_meeting_type_category;
            this.arr_obj_agenda[this.int_agenda_index].str_date = this.obj_edit_agenda.str_date;
            this.arr_obj_agenda[this.int_agenda_index].str_description = this.obj_edit_agenda.str_description;
            this.arr_obj_agenda[this.int_agenda_index].str_owner = this.obj_edit_agenda.str_owner;
            this.arr_obj_agenda[this.int_agenda_index].arr_hyperlinks = this.obj_edit_agenda.arr_hyperlinks;

            //add no array edit agenda
            this.arr_obj_edit_agenda.push(this.obj_edit_agenda.cod_agenda);
            idx = this.int_agenda_index;
        }
        else {

            this.arr_obj_agenda.push({
                cod_agenda: 0,
                cod_order: this.arr_obj_agenda.length + 1,
                cod_meeting_type_category: this.obj_edit_agenda.cod_meeting_type_category,
                str_date: this.obj_edit_agenda.str_date,
                str_description: this.obj_edit_agenda.str_description,
                str_owner: this.obj_edit_agenda.str_owner,
                isDel: null,
                arr_hyperlinks: this.obj_edit_agenda.arr_hyperlinks
            });
            idx = this.arr_obj_agenda.length - 1;
        }
        this.bol_edit_tor_agenda = false;
        this.bol_tor_agenda = false;

        this.arr_obj_agenda.map(item => {
            item.arr_hyperlinks.map(it => {
                it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it.str_link));
            });
        });



    }

    addLink() {
        if (!!this.str_hyperlink) {

            let wrongPattern = /^\.\.\/|^\.\.\\/;
            let pattern = /^https?:\/\/.+/;
            let pattern2 = /^file:\/\/\/?.+/;
            let pattern3 = /^\\\\.+/;
            let pattern4 = /^[a-zA-Z]:(\/|\\).+/;

            if (wrongPattern.test(this.str_hyperlink)) {
                this._handleAgendaValidation('MEETING_TYPES_LIST.INVALID_HYPERLINK');
                return false;
            }

            this.str_hyperlink = this.str_hyperlink.replace(/^[^\x00-\x7F]/gmu, "");
            if (!pattern.test(this.str_hyperlink)) {
                if (!pattern2.test(this.str_hyperlink)) {
                    if (pattern3.test(this.str_hyperlink) || pattern4.test(this.str_hyperlink)) {
                        this.str_hyperlink = 'file:///' + this.str_hyperlink;
                    }
                    else {
                        this.str_hyperlink = 'https://' + this.str_hyperlink;
                    }
                }
            }
            this.obj_edit_agenda.arr_hyperlinks.push({
                cod_agenda_hyperlinks: 0,
                str_link: this.str_hyperlink,
                str_link_desc: this.str_hyperlink_desc,
                isDel: null,
                cod_order: 0
            });
        }
        this.str_hyperlink = '';
        this.str_hyperlink_desc = '';

        return true;
    }

    cancelAddAgenda() {
        this.bol_tor_agenda = false;
        this.bol_edit_tor_agenda = false;
        this.selectedCodMeetingTypeCategory = [];
        this.bol_unsaved_changes = false;
        this.isDisableButton = false;
        this.obj_edit_agenda = {
            cod_agenda: null,
            cod_order: null,
            str_owner: '',
            isDel: null,
            cod_meeting_type_category: null,
            str_date: '',
            str_description: '',
            arr_hyperlinks: []
        }
    }

    cancelLink() {
        this.bol_error_submit_link = false;
        this.isEditLink = false;
    }

    editAgenda(agenda, idx) {
        if (!this.model.bol_enable_edit_remove)
            return;

        this.bol_error_submit_agenda = false;
        this.bol_tor_agenda = true;
        this.bol_edit_tor_agenda = true;
        this.selectedCodMeetingTypeCategory = [];

        this.obj_edit_agenda = {
            cod_agenda: agenda.cod_agenda,
            cod_order: agenda.cod_order,
            cod_meeting_type_category: agenda.cod_meeting_type_category,
            str_date: agenda.str_date,
            str_description: agenda.str_description,
            str_owner: agenda.str_owner,
            isDel: agenda.isDel,
            arr_hyperlinks: []//agenda.arr_hyperlinks
        };
        for (let i = 0; i < agenda.arr_hyperlinks.length; i++) {
            this.obj_edit_agenda.arr_hyperlinks.push({
                cod_agenda_hyperlinks: agenda.arr_hyperlinks[i].cod_agenda_hyperlinks,
                str_link: agenda.arr_hyperlinks[i].str_link,
                str_link_desc: agenda.arr_hyperlinks[i].str_link_desc,
                isDel: agenda.arr_hyperlinks[i].isDel,
                cod_order: i + 1,
            });

        }

        //agenda;
        this.int_agenda_index = idx;
        if (agenda.cod_meeting_type_category)
            this.selectedCodMeetingTypeCategory = [this.agendaCategories.find(c => c.cod_meeting_type_category == agenda.cod_meeting_type_category)];
    }

    changeLink() {
        if (!this.obj_edit_hyperlink.str_link || this.obj_edit_hyperlink.str_link.length <= 0) {
            this._handleAgendaValidation('MEETING_TYPES_LIST.HYPERLINK_IS_REQUIRED');
            return;
        }

        if (!!this.obj_edit_hyperlink.str_link) {
            const wrongPattern = /^\.\.\/|^\.\.\\/;
            const pattern = /^https?:\/\/.+/;
            const pattern2 = /^file:\/\/\/?.+/;
            const pattern3 = /^\\\\.+/;
            const pattern4 = /^[a-zA-Z]:(\/|\\).+/;

            if (wrongPattern.test(this.obj_edit_hyperlink.str_link)) {
                this._handleAgendaValidation('MEETING_TYPES_LIST.INVALID_HYPERLINK');
                return false;
            }

            this.obj_edit_hyperlink.str_link = this.obj_edit_hyperlink.str_link.replace(/^[^\x00-\x7F]/gmu, "");
            if (!pattern.test(this.obj_edit_hyperlink.str_link)) {
                if (!pattern2.test(this.obj_edit_hyperlink.str_link)) {
                    if (pattern3.test(this.obj_edit_hyperlink.str_link) || pattern4.test(this.obj_edit_hyperlink.str_link)) {
                        this.obj_edit_hyperlink.str_link = 'file:///' + this.obj_edit_hyperlink.str_link;
                    } else {
                        this.obj_edit_hyperlink.str_link = 'https://' + this.obj_edit_hyperlink.str_link;
                    }
                }
            }

            this.obj_edit_agenda.arr_hyperlinks[this.idx_link] = this.obj_edit_hyperlink;
            this.isEditLink = false;
            this.bol_error_submit_link = false;

        }

    }

    removeLink(index) {
        if (!this.model.bol_enable_edit_remove)
            return;

        if (this.obj_edit_agenda.arr_hyperlinks[index].cod_agenda_hyperlinks == 0) {
            this.obj_edit_agenda.arr_hyperlinks.splice(index, 1);
        } else {
            if (this.obj_edit_agenda.arr_hyperlinks[index].iscustom == 0) {
                this.obj_edit_agenda.arr_hyperlinks.splice(index, 1);
            }
            else {
                this.obj_edit_agenda.arr_hyperlinks[index].isDel = 1;
                this.obj_edit_agenda.arr_hyperlinks.push(this.obj_edit_agenda.arr_hyperlinks.splice(index, 1)[0]);
            }
        }
        this.orderLinkNumber();
    }


    dropLink(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.obj_edit_agenda.arr_hyperlinks, event.previousIndex, event.currentIndex);
        this.orderLinkNumber();
    }

    orderLinkNumber() {
        this.obj_edit_agenda.arr_hyperlinks = this.obj_edit_agenda.arr_hyperlinks.map((val, i) => {
            val.cod_order = i + 1;
            if (val.cod_agenda_hyperlinks != 0) {
                this.obj_edit_agenda.arr_hyperlinks.push(val.cod_agenda_hyperlinks);
            }
            return val;
        });
    }

    editLink(link, index) {
        if (!this.model.bol_enable_edit_remove)
            return;

        this.isEditLink = true;
        this.idx_link = index;
        this.obj_edit_hyperlink = { ...link };
    }


    deleteAgenda(idx) {
        if (!this.model.bol_enable_edit_remove)
            return;

        if (this.arr_obj_agenda[idx].cod_agenda == 0) {
            this.arr_obj_agenda.splice(idx, 1);
        } else {
            this.arr_obj_agenda[idx].isDel = 1;
            this.arr_obj_del_agenda.push(this.arr_obj_agenda.splice(idx, 1)[0]);
            this.bol_unsaved_changes = true;
            this.isDisableButton = false;

        }
        this.orderAgendaNumber();
    }

    copyLink(str_hyperlink, i, j = 0, copy = false) {
        const pattern = /^https?:\/\/.+/;
        // let pattern2 = /^(file:\/\/\/?)?\\\\.+/;
        if (!pattern.test(str_hyperlink) || copy === true) {
            this.copyToClipboard(str_hyperlink);
            // alert('Hyperlink copied to clipboard: \n'+ str_hyperlink);
            this.linkIdx = i;
            this.linkAgendaIdx = j;
            clearTimeout(this.link_timeout);
            this.link_timeout = setTimeout(() => {
                this.linkIdx = -1;
                this.linkAgendaIdx = 0;
            }, 3000);
        }
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }


    downloadExtension(file) {
        const extension = file.split('.').pop();
        let newFile = file;

        // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
        switch (extension) {
            case 'xls':
                newFile = `ms-excel:ofe|u|${file}`;
                break;
            case 'xlsx':
                newFile = `ms-excel:ofe|u|${file}`;
                break;
            case 'xlsm':
                newFile = `ms-excel:ofe|u|${file}`;
                break;
            case 'csv':
                newFile = `ms-excel:ofe|u|${file}`;
                break;
            case 'doc':
                newFile = `ms-word:ofe|u|${file}`;
                break;
            case 'docx':
                newFile = `ms-word:ofe|u|${file}`;
                break;
            case 'ppt':
                newFile = `ms-powerpoint:ofe|u|${file}`;
                break;
            case 'pptx':
                newFile = `ms-powerpoint:ofe|u|${file}`;
                break;
            default:
                break;
        }
        // REPLACING BACKWARD SLASH TO FORWARD SLASH TO FIX WRONG LOCAL URL
        newFile = newFile.replace(/\\/g, '/');
        return newFile;
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    hideMessage() {
        this.showSuccessMessage = false;
        this.showErrorMessage = false;
    }

    changePage(page) {
        this.pageIndex = page;
        this.search();
    }

    private _pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    selectionChanged($event) {
        if ($event.selectedOptions[0]) {
            this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
            this.changePage(1);
        }
    }

}
