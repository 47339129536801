import {Component, OnInit} from '@angular/core';
import {AuthService} from "../access/services/auth.service";

@Component({
    selector: 'app-reports-online',
    templateUrl: './reports-online.component.html',
    styleUrls: ['./reports-online.component.css']
})
export class ReportsOnlineComponent implements OnInit {
    isLoading: boolean = true;
    actualTab: string;

    constructor(private _authService: AuthService) {
        this.actualTab = 'reports-routines-online';
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    changeTab(tabName) {
        this.actualTab = tabName;
    }

    canShowTab(tabName) {
        let canShow = this._authService.getUserPrivilege(tabName);
        if (canShow && this.actualTab == '') {
            switch(tabName) {
            }
        }

        return canShow;
    }

}