import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from "./services/auth.service";
import { AppComponent } from 'app/app.component';
import { HelpTutorialService } from '../services/help-tutorial.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { AuthStatus } from './models/auth-event.model';
import { PromptValue } from '@azure/msal-common';

declare type Params = {
  [key: string]: any;
};
declare type RedirectData = {
  path: string;
  query: Params;
}

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit, OnDestroy {
  displaySignInSso: Boolean = false;
  displayAuth: Boolean = true;
  url: string;
  showError: boolean;
  errorMessage: string;
  showAuth: Boolean = false;
  isChrome: boolean;
  msalLogin: Boolean = false;
  ongoingAutoLogin: Boolean = false;
  redirectData: RedirectData = null;
  errorInfo: {};
  isHmi: Boolean = false;

  private subscription: Subscription;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private router: Router,
    private _authService: AuthService,
    private appComponent: AppComponent,
    private _helpTutorialService: HelpTutorialService,
    private activatedRoute: ActivatedRoute
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        switch (this.url) {
          case '/access?action=auth':
            this.displayAuth = true;
            this.showAuth = true;
            this.displaySignInSso = false;
            break;
          case '/access':
            if (this._authService.isAuthenticated()) {
              this.router.navigate(['/home']);
            }
            else {
              this.displayAuth = true;
              this.displaySignInSso = false;
            }
            break;
          case '/hmi':                        
            if (this._authService.isAuthenticated()) {
              this.router.navigate(['/home']);
            }
            else {
              this.displayAuth = false;
              this.displaySignInSso = true;
            }
            break;
          case '/access?action=logout':
            this._authService.deleteAuthenticatedUser();
            this.router.navigate(['/access']);
            break;
          default:
            if (this._authService.isAuthenticated() && (
              this.url == '/access?action=auth' ||
              this.url == '/access?action=sso' ||
              this.url == 'access')
            ) {
              this.router.navigate(['/']);
            } else {
              this.displayAuth = true;
              this.displaySignInSso = false;
            }
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    let isIOS = navigator.userAgent.match('CriOS');
    let isEdge = navigator.userAgent.match('Edg');
    
    if (!this.isChrome && !isIOS && !isEdge) {
      alert('Caution!!! To have the best experience with IAL on your computer, use Google Chrome browser!');
    }
    // IAL auth endpoint will be dealed with at AppComponent, here we subscribe to its result for whatever can't be done there
    this.subscription = this._authService.getAuthEventSubject().subscribe(
      (event) => {
        switch (event.type) {
          case AuthStatus.SUCCESS:
            //do login
            this._helpTutorialService.startTutorial();
            this.displayAuth = true;
            this.displaySignInSso = false;
            this._authService.removeHmiLogin();
            this.router.navigate(['/home']);
            break;
          case AuthStatus.USER_NOT_FOUND:
            let claims = this.msalService.instance.getActiveAccount().idTokenClaims;
            this.errorInfo = {
              globalId: claims?.preferred_username,
              email: claims?.email,
              psmtp: claims?.verified_primary_email
            }
            
            this.displayAuth = true;
            this.displaySignInSso = false;
            this.router.navigate(['/access']);
            this._authService.deleteAuthenticatedUser();
            this.showError = true;
            break;
          case AuthStatus.ONGOING:
            this.displayAuth = false;
            this.displaySignInSso = true;
            break;
          case AuthStatus.FAILURE:
            this.displayAuth = true;
            this.displaySignInSso = false;
            this._authService.deleteAuthenticatedUser();

            const msg = 'Error in SSO sign in';
            this._showErrorMessage(msg);
            break;
          default: // defaulting to AuthStatus.NONE
            break;
        }
      }
    );

    //LOGIN WITH HMI FORCING SELECTION OF ACCOUNT
    if (this.url.includes('/hmi') && !this._authService.isAuthenticated() && !this._authService.getHmiLogin()) {
      this._authService.setHmiLogin();
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest, 
        prompt: PromptValue.SELECT_ACCOUNT
      } as RedirectRequest);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  signInSsoMsal() {
    if (this.url.includes('/access') && !this._authService.isAuthenticated()) {
      this.displayAuth = false;
      this.displaySignInSso = true;
      if(this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  cancel_sso() {
    this.redirectData = null;
    this.ongoingAutoLogin = false;
    this._authService.deleteAuthenticatedUser();
    this.router.navigate(['/access']);
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err) {
    this.errorMessage = err;
    this.showError = true;
  }

  

}
