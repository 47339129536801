import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'app/guards/auth-guard';
import { UnsavedChangesGuard } from 'app/guards/unsaved-changes-guard';
import { Component, NgModule } from '@angular/core';
import { MainService } from './services/main.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MachineService } from './services/machine.service';
import { RelayService } from './services/relay.service';
import { FiveWhyService } from './services/five-why.service';
import { LogbooksModule } from '../logbooks/logbooks.module';
import { TriggersService } from './services/triggers.service';
import { FivewhyComponent } from './fivewhy/fivewhy.component';
import { AddWhysComponent } from './add-whys/add-whys.component';
import { ComponentsModule } from '../components/components.module';
import { ValidationsService } from './services/validations.service';
import { AddWhysHelperService } from './services/add-whys-helper-service';
import { StatusFlowComponent } from './status-flow/status-flow.component';
import { NewFiveWhyComponent } from './new-five-why/new-five-why.component';
import { ListFivewhyComponent } from './list-fivewhy/list-fivewhy.component';
import { SingleWhyComponent } from './add-whys/single-why/single-why.component';
import { FivewhyRatingComponent } from './fivewhy-rating/fivewhy-rating.component';
import { ReportsFivewhyComponent } from './reports-fivewhy/reports-fivewhy.component';
import { TriggerPersistComponent } from './trigger-persist/trigger-persist.component';
import { MachinePersistComponent } from './machine-persist/machine-persist.component';
import { MachineListComponent } from '../five-why/machine-list/machine-list.component';
import { RelayListComponent } from '../five-why/relay-list/relay-list.component';
import { RelayPersistComponent } from '../five-why/relay-persist/relay-persist.component';
import { TriggerListComponent } from '../five-why/trigger-list/trigger-list.component';
import { FiveWhyActionsComponent } from './fivewhy-actions/fivewhy-actions.component';
import { ListLocationsService } from 'app/components/list-locations/services/list-locations.service';
import { WorkflowIconsListComponent } from './list-fivewhy/workflow-icons-list/workflow-icons-list.component';
import { EscaletedAbnormalityReportChartComponent } from './charts/escaleted-abnormality-report-chart/escaleted-abnormality-report-chart.component';
import { SdcaMatrixChartComponent } from './charts/sdca-matrix-chart/sdca-matrix-chart/sdca-matrix-chart.component';
import { CompletionTimeChartComponent } from './charts/completion-time-chart/completion-time-chart.component';
import { RepeatingProblemChartComponent } from './charts/repeating-problem-chart/repeating-problem-chart.component';
import { ChartsService } from './services/charts-service';
import * as echarts from 'echarts';
import { CrewService } from './services/crew.service';
import { FivewhyParticipantsComponent } from './fivewhy-participants/fivewhy-participants.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
// import {AuthInterceptorService} from '../main/access/services/auth-interceptor.service';
import { AbortFivewhyModalComponent } from './abort-fivewhy-modal/abort-fivewhy-modal.component';
import { CategoriesConsultComponent } from './categories-consult/categories-consult.component';
import { EquipmentsConsultComponent } from '../five-why/equipments-consult/equipments-consult.component';
import { KpisConsultComponent } from './kpis-consult/kpis-consult.component';
import { CategoryTypesService } from './services/category-types.service';
import { EquipmentTypesService } from './services/equipment-types.service';
import { KpisService } from './services/kpis.service';
import { AfkWarningModalComponent } from './afk-warning-modal/afk-warning-modal.component';
import { DebounceModule } from 'app/directives/debounce/debounce.module';
// import { AuthInterceptorService } from '../main/access/services/auth-interceptor.service';
import { FivewhyTasksListComponent } from './fivewhy-tasks-list/fivewhy-tasks-list.component';
import { ActionStatusComponent } from './charts/action-status/action-status.component';

import { MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { AnalyticInfoComponent } from './charts/analytic-info/analytic-info.component';

//ECHARTS
export function chartModule(): any {
  return import('echarts');
}

const routes: Routes = [
  {
      path: 'problem-terminator/details',
      component: NewFiveWhyComponent,
      canActivate: [
        AuthGuard, MsalGuard
      ],
      // canDeactivate: [
      //     UnsavedChangesGuard
      // ]
  },
  {
    path: 'problem-terminator/details/:cod_fivewhy',
    component: NewFiveWhyComponent,
    canActivate: [
      AuthGuard, MsalGuard
    ],
    // canDeactivate: [
    //     UnsavedChangesGuard
    // ]
},
];

@NgModule({
  declarations: [ 
    AddWhysComponent,
    FivewhyComponent,
    SingleWhyComponent,
    RelayListComponent,
    StatusFlowComponent,
    NewFiveWhyComponent,
    TriggerListComponent, 
    ListFivewhyComponent,
    MachineListComponent, 
    RelayPersistComponent,
    FivewhyRatingComponent, 
    MachinePersistComponent, 
    TriggerPersistComponent,
    FiveWhyActionsComponent,
    ReportsFivewhyComponent, 
    SdcaMatrixChartComponent, 
    AfkWarningModalComponent, 
    CategoriesConsultComponent,
    AbortFivewhyModalComponent,
    EquipmentsConsultComponent,
    WorkflowIconsListComponent, 
    CompletionTimeChartComponent, 
    FivewhyParticipantsComponent,
    RepeatingProblemChartComponent,
    EscaletedAbnormalityReportChartComponent,
    FivewhyTasksListComponent,
    ActionStatusComponent,
    AnalyticInfoComponent,
    KpisConsultComponent
  ],
  exports: [
    MachineListComponent, 
    RelayListComponent,
    TriggerListComponent,
    ReportsFivewhyComponent,
    FivewhyTasksListComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    LogbooksModule,
    TranslateModule,
    ComponentsModule,
    DebounceModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxEchartsModule.forRoot({
      echarts: chartModule,
    }),
  ],
  providers: [
    MainService,
    ChartsService,
    FiveWhyService,
    MachineService,
    RelayService,
    TriggersService,
    ValidationsService,
    ValidationsService,
    ListLocationsService,
    AddWhysHelperService,
    ChartsService,
    CrewService,
    CategoryTypesService,
    EquipmentTypesService,
    KpisService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
  ]
})
export class FiveWhyModule { }
