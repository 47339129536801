export class User {
    cod_id: Number;
    str_name: String;
    str_legal_name: String;
    str_preferred_name: String;
    str_username: String;
    str_email: String;
    // cod_jobs: Number;
    cod_worlds: Number;
    cod_regionals: Number;
    cod_locations: Number;
    cod_logbooks: Number;
    str_password: String;
    str_partner: String;
    cod_areas: any;
    cod_functional_world: Number;
    str_type: String;
    cod_user: Number;
    cod_profiles: Number;
    cod_current_profiles: Number;
    str_current_profile:String;
    str_ambev_id_manager:String;
    str_job_name:String;
    bool_profile: boolean;
    bool_privilege: boolean;

    constructor() {
        this.cod_id = 0;
        this.str_name = '';
        this.str_legal_name = '';
        this.str_preferred_name = '';
        this.str_username = '';
        this.str_email = '';
        // this.cod_jobs = 0;
        this.cod_worlds = null;
        this.cod_regionals = null;
        this.cod_locations = null;
        this.cod_logbooks = null;
        // this.str_password = '';
        this.str_partner = '';
        this.cod_areas = [];
        this.cod_functional_world = null;
        this.str_type = '';
        this.cod_user = null;
        this.cod_profiles = 0;
        this.str_current_profile='';
        this.str_ambev_id_manager='';
        this.str_job_name='';
        this.bool_profile = true;
        this.bool_privilege = true;
    }
}
