import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-logbook-topic-1',
  templateUrl: './logbook-topic-1.component.html',
  styleUrls: ['./logbook-topic-1.component.css']
})
export class LogbookTopic1Component implements OnInit {

  public str_language='en';

  constructor(public userPreferencesService:UserPreferencesService) { 
    
  }

  ngOnInit() {
  }

}
