import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';

@Injectable()
export class UploadMainService {
    private _api_url;
    private obj_user;

    // Set headers to handle the CORS
    private _headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService, private _userPreferenceService: UserPreferencesService) {
        this._api_url = environment().uploadFilesApiUrl;

        this.obj_user = this._authService.getAuthenticatedUser();
        // Set headers to handle the CORS
        this._headers = new HttpHeaders(
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
                'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
                'Access-Control-Allow-Credentials': 'true',
                'userlang': this._userPreferenceService.getLocalLanguage(),
                'X-Content-Type-Options': 'nosniff',               
                'X-Frame-Options': 'SAMEORIGIN',
                'Referrer-Policy': 'origin-when-cross-origin',
                'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
            
            }
        );
    }

    private _getHeaders(method: string) {
        this._headers = this._headers.set('Access-Control-Allow-Methods', method.toUpperCase());
        this._headers = this._headers.set('userlang', this._userPreferenceService.getLocalLanguage());
        return this._headers;
    }

    private getApiUrl() {
        return this._api_url;
    }

    /**
     * POST request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    post(path: string, params: Object): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('POST')};
        return this._http.post(url, params, opt);
    }

    /**
     * POST request
     * @param url: Path of endpoint
     */
    getBlob(url: string): Observable<any> {

        return this._http.get(url, {
            headers: this._getHeaders('GET'),
            responseType: 'blob'
        });
    }
}
