import { Component, Input, Output, EventEmitter } from '@angular/core';
import {environment} from '../../../environments/environment';
import {DownloadCsvService} from './services/download-csv.service';
import {AuthService} from '../../main/access/services/auth.service';
import {UploadService} from '../../main/services/upload.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from '../../main/services/notification.service';

@Component({
    selector: 'button-download-csv',
    templateUrl: './button-download-csv.component.html',
    styleUrls: ['./button-download-csv.component.css'],
})

export class ButtonDownloadComponent {
    @Input('downloadCsvSetup') downloadCsvSetup: DownloadCsvSetup;
    @Input('searchTerm') searchTerm: string;
    @Input('buttonText') buttonText: string = '';
    @Input('disabled') bol_disabled: boolean;
    @Input('isValid') isValid: boolean = true;
    @Input('validateMessage') validateMessage: string = "";
    @Input() generateOnClientSide: boolean;

    @Output() readonly onGenerateCsv = new EventEmitter<void>();

    bol_loading:boolean;
    bol_err:boolean;
    obj_user:any;
    error_download_message: string;

    constructor(
        private readonly _downloadCsvService: DownloadCsvService, 
        private readonly _uploadService: UploadService, 
        private readonly _authService:AuthService,
        private readonly sanitizer: DomSanitizer,
        private readonly _notify: NotificationService
        ) {
        this.bol_loading=false;
        this.bol_err=false;
        //GETTING AUTHENTICATED USER
        this.obj_user = _authService.getAuthenticatedUser();
    }

    downloadList() {

        if (!this.isValid) {
            this._notify.warning(this.validateMessage);
            return;
        }

        this.bol_err=false;
        this.bol_loading=true;
        this._downloadCsvService.postDownloadReports(this.downloadCsvSetup).subscribe({
            next: (res) => {
                try {
                    let str_file_name = res.content.str_file_name;
                    let uploadFile = (
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            environment().uploadGetFilesApiUrl +
                                '/reports/' +
                                this.obj_user.id +
                                '/' +
                                res.content.str_file_name
                        ) as any
                    ).changingThisBreaksApplicationSecurity;

                    //SEND REQUEST TO GET FILE STREAM, WITH CREDENTIALS
                    this._uploadService.downloadFile(uploadFile).subscribe({
                        next: (res) => {
                            //DOWNLOAD BLOB FILE
                            let obj_blob = new Blob([res]);

                            let a = document.createElement('a');
                            a.href = URL.createObjectURL(obj_blob);
                            a.download = str_file_name;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);

                            this.bol_loading = false;
                        },
                        error: (err) => {
                            this._authService.errCheck(err);
                            console.log(err);
                            this.bol_loading = false;
                        }
                    });
                } catch (err) {
                    console.log(err);
                    this.bol_loading = false;
                    this.bol_err = true;
                    this.error_download_message = 'Error downloading file';
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
                this.bol_loading = false;
                this.bol_err = true;
                this.error_download_message = 'Error creating file';
            },
        });
    }

}

export interface DownloadCsvSetup {
    cod_user: string;
    api_url: string;
    str_proc_exec: string;
    arr_proc_params_search: Array<any>;
    arr_headers: Array<any>;
    name_of_file_to_download: string;
}
