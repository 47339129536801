// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
    padding-bottom:10px;
    margin-right: 0px;
    margin-left: 0px;
}
.action-button {
    margin-left: 4px;
    margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/meetings-create-action/meetings-create-action.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".row {\n    padding-bottom:10px;\n    margin-right: 0px;\n    margin-left: 0px;\n}\n.action-button {\n    margin-left: 4px;\n    margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
