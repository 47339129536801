import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Question } from '../../forms/forms-dynamic/models/question.model';

@Component({
    selector: 'forms-input',
    templateUrl: './forms-input.component.html',
    styleUrls: ['./forms-input.component.css']
})

export class FormsInputComponent<T> implements OnInit {

    @Input() data: T = null;
    @Output() dataChange: EventEmitter<T> = new EventEmitter<T>();

    @Input() question: Question = null;
    @Input() isDisabled: boolean = false;

    @Output() onChange: EventEmitter<{
        data: T,
        question: Question,
        valid: boolean,
    }> = new EventEmitter();

    @Output() onValidation: EventEmitter<{
        question_description: string, 
        isValid: boolean
    }> = new EventEmitter();

    type: string = 'text';
    previousData: T = null;
    isValid: boolean = true;

    constructor() { }

    ngOnInit() : void {
        this.previousData = this.data;
        this.validate();
        this.onValidation.emit({
            question_description: this.question.question_description,
            isValid: this.isValid
        });
    }

    public save() : void {          
        setTimeout(() => {
            this.previousData = this.data;
            this.validate();      

            this.onChange.emit({
                data: this.data,
                question: this.question,
                valid: this.isValid
            });
        });
    }

    protected validate() : void {                
        this.isValid = this.question.bol_optional ? true : this.validationRule();
    }

    protected validationRule() : boolean {        
        return this.data != null;
    }

}
