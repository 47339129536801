export class Indicator {
    arr_locations_world: any;
    arr_conditionals: any;
    cod_indicators: Number;
    str_name: string;
    cod_usu_ins: Number;
    cod_usu_del: Number;
    cod_unit: Number;
    cod_indicators_externals: Number;
    arr_units: any;
    arr_externals: any;
    arr_indicators: any;
    str_name_de: string;
    str_name_es: string;
    str_name_fr: string;
    str_name_nl: string;
    str_name_pt: string;
    str_name_ru: string;
    str_name_ua: string;
    str_name_en: string;
    str_name_zh: string;

    constructor() {
        this.str_name = '';
        this.cod_indicators = 0;
        this.arr_locations_world = [];
        this.arr_units = [];
        this.arr_externals = [];
        this.arr_conditionals = [];
        this.arr_indicators = [];
        this.str_name_de = '';
        this.str_name_es = '';
        this.str_name_fr = '';
        this.str_name_nl = '';
        this.str_name_pt = '';
        this.str_name_ru = '';
        this.str_name_ua = '';
        this.str_name_en = '';
        this.str_name_zh = '';
    }
}
