import { Component } from '@angular/core';
import { FormsInputComponent } from '../forms-input/forms-input.component';

@Component({
    selector: 'selection-input',
    templateUrl: './selection-input.component.html',
    styleUrls: ['./../forms-input/forms-input.component.css', './selection-input.component.css']
})

export class SelectionInputComponent<T> extends FormsInputComponent<T> {

    constructor() {
        super();
    }

    public saveSelection(cod_form_question_choice) : void {     
        if (this.question.bol_optional) {
            if (this.question.cod_form_question_choice && this.question.cod_form_question_choice == cod_form_question_choice){                
                this.question.cod_form_question_choice = undefined;
            }else {                
                this.question.cod_form_question_choice = cod_form_question_choice;
            }

            super.save();        
            return;    
        }

        this.question.cod_form_question_choice = cod_form_question_choice;    
        super.save();
    }

    protected validationRule() : boolean {        
        return this.question.bol_optional ? true : Boolean(this.question.cod_form_question_choice || this.question.cod_answer_question_choice);
    }    

}
