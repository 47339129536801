import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {MeetingTopic1Component} from 'app/main/help/help-meetings/meeting-topic-1/meeting-topic-1.component';
import {MeetingTopic2Component} from './meeting-topic-2/meeting-topic-2.component';
import {MeetingTopic3Component} from './meeting-topic-3/meeting-topic-3.component';
import {MeetingTopic4Component} from './meeting-topic-4/meeting-topic-4.component';
import {MeetingTopic5Component} from './meeting-topic-5/meeting-topic-5.component';
import {MeetingTopic6Component} from './meeting-topic-6/meeting-topic-6.component';
import {MeetingTopic7Component} from './meeting-topic-7/meeting-topic-7.component';
import {MeetingTopic8Component} from './meeting-topic-8/meeting-topic-8.component';
import {MeetingTopic9Component} from './meeting-topic-9/meeting-topic-9.component';
import {MeetingTopic10Component} from "./meeting-topic-10/meeting-topic-10.component";
import {MeetingTopic11Component} from "./meeting-topic-11/meeting-topic-11.component";
import {MeetingTopic12Component} from "./meeting-topic-12/meeting-topic-12.component";
import {MeetingTopic13Component} from "./meeting-topic-13/meeting-topic-13.component";
import {MeetingTopic14Component} from "./meeting-topic-14/meeting-topic-14.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [MeetingTopic1Component, MeetingTopic2Component, MeetingTopic3Component, MeetingTopic4Component,
        MeetingTopic5Component, MeetingTopic6Component, MeetingTopic7Component, MeetingTopic8Component, MeetingTopic9Component,
        MeetingTopic10Component, MeetingTopic11Component, MeetingTopic12Component, MeetingTopic13Component, MeetingTopic14Component],
    declarations: [MeetingTopic1Component, MeetingTopic2Component, MeetingTopic3Component,
        MeetingTopic4Component, MeetingTopic5Component, MeetingTopic6Component, MeetingTopic7Component, MeetingTopic8Component,
        MeetingTopic9Component, MeetingTopic10Component, MeetingTopic11Component, MeetingTopic12Component, MeetingTopic13Component, MeetingTopic14Component],
    providers: []
})
export class InfoMeetingsModule {
}
