export class Type {
    cod_meetings_types: number;
    str_name: string;
    str_description: string;
    cod_periodicity: number;
    str_periodicity: string;
    str_periodicity_translation: string;
    cod_file: string;
    dat_ins: Date;
    dat_del: Date;
    cod_usu_ins: number;
    cod_usu_alt: number;
    dat_alt: Date;
    cod_usu_del: number;
    str_pdf_path: string;
    str_extended_tabs: string;
    str_objective: any;
    str_owner: any;
    str_participants: any;
    str_location: any;
    str_time: any;
    int_duration: number;
    str_frequency: any;
    str_ground_rules: any;
    str_input: any;
    str_output: any;
    str_kpis: any;
    bol_standard: boolean;
    cod_meeting_standard_type: number;
    // str_privilege_level_list: String;
    // str_privilege_level_create_edit_remove: String;
    bol_enable_edit_remove: boolean;
    str_standard_description: string;

    constructor() {
        this.str_name = '';
        this.str_description = '';
        this.cod_file = '';
        this.str_pdf_path = '';
        this.bol_standard = false;
    }
}
