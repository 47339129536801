import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-meeting-topic-7',
  templateUrl: './meeting-topic-7.component.html',
  styleUrls: ['./meeting-topic-7.component.css']
})
export class MeetingTopic7Component implements OnInit {

  constructor(public userPreferencesService:UserPreferencesService) { }

  ngOnInit() {
  }

}
