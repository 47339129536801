import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {MainService} from './main.service';

@Injectable()
export class RoutineStatusService {

    constructor(private _mainService: MainService) {
    }

    getListAll(params): Observable<any> {
        let str_path = '/routine-status-all/' + params.cod_user + '/' + params.cod_routine + '/' + params.cod_form + '/' + params.cod_routine_user + '/' + params.date;
        return this._mainService.get(str_path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getRoutinesStatusByUserPreference(cod_user): Observable<any> {
        let str_path = `/routines-status-by-user-preferences/${cod_user}`;
        return this._mainService.get(str_path);
    }

    getRoutinesStatusByLanguage(cod_user, str_language): Observable<any> {
        let str_path = `/routines-status-by-language/${cod_user}/${str_language}`;
        return this._mainService.get(str_path);
    }
}