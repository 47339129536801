import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'component-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent {
    // message to be displayed
    @Input() message: String = 'Pressione OK para continuar.';

    // callback when confirm button is pressed
    @Output() confirm = new EventEmitter<any>();

    // callback when cancel button is pressed
    @Output() cancel = new EventEmitter<any>();

    // Label for cancel Button
    @Input() cancelLabel: string;

    // Label for confirm Button
    @Input() confirmLabel: string;

    doConfirm() {
        this.confirm.emit();
    }

    doCancel() {
        this.cancel.emit();
    }
}
