import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';
import { Meeting } from '../models/meetings.model';
import { GenericRequestType, GenericResponseType } from './types/generic-response-types';
import { AddMeetingParticipantResponse, MeetingColumn, MeetingDetailsResponse, MeetingParticipant, MeetingParticipantSearch, MeetingPresence, PostMeetingPresenceResponse, RemoveMeetingParticipantResponse, TagType, UpdateMeetingOwnerResponse } from './types/response-types';
import { UpdateMeetingDetailsParams } from './types/request-types';

@Injectable()
export class MeetingsService {

    private autofill_sources: string = null;

    constructor(private readonly _mainService: MainService) { }

    /**
     * Display list of all logbook areas.
     * @param param: JSON object filled with search "term" or "id".
     */
    getMeetingsByNameList(str_name, cod_user, dsc_page_privilege, str_status, offset, limit): Observable<any> {
        const path = '/meetings-list?str_name=' + str_name + '&cod_user=' + cod_user + '&dsc_page_privilege=' + dsc_page_privilege + '&str_status=' + str_status + '&offset=' + offset + '&limit=' + limit;
        return this._mainService.get(path);
    }

    getMeetingsByUsersList(params): Observable<any> {
        const path = '/meetings-list-by-users';
        return this._mainService.post(path, params);
    }

    getMeetingsParticipants(cod_meetings, file_path, cod_user): Observable<any> {
        const path = '/meetings-participants/' + cod_meetings + '/' +  encodeURIComponent(file_path) + '/' + cod_user;
        return this._mainService.get(path);
    }
    verifyIfUserIsParticipantInMeeting(cod_meetings, cod_user): Observable<any> {
        const path = '/meetings-participants-permission/' + cod_meetings + '/' + cod_user;
        return this._mainService.get(path);
    }

    getMeetingsActionsAverage(cod_meeting, cod_user): Observable<any> {
        const path = '/meetings-actions-average/' + cod_meeting + '/'+ cod_user;
        return this._mainService.get(path);
    }

    getMeetingsToday(cod_user, dsc_page_privilege): Observable<any> {
        const path = '/meetings-list-today/' + cod_user + '/' + dsc_page_privilege;
        return this._mainService.get(path);
    }


    getMeetingsTodayHomePanel(cod_user): Observable<any> {
        const path = '/meetings-list-today-home-panel/' + cod_user;
        return this._mainService.get(path);
    }

    getMeetingsUpcomingHomePanel(cod_user): Observable<any> {
        const path = '/meetings-list-upcoming-home-panel/' + cod_user;
        return this._mainService.get(path);      
    }
  

    getMeetingDetails(cod_meeting, cod_user, dsc_page_privilege): Observable<any> {
        const path = '/meetings-details/' + cod_meeting + '/' + cod_user + '/' + dsc_page_privilege;
        return this._mainService.get(path);
    }

    getMeetingHeaderDetails(cod_meeting, cod_user): Observable<any> {
        const path = `/v2/${cod_meeting}/${cod_user}/header-details`
        return this._mainService.get(path);
    }

    getMeetingsActionsAverageTime(cod_meeting, cod_user): Observable<any> {
        const path = `/v2/${cod_meeting}/${cod_user}/actions-average-time`;
        return this._mainService.get(path);
    }

    getCountMeetingActionsStatus(cod_meeting, cod_user): Observable<any> {
        const path = `/v2/${cod_meeting}/actions-status-metrics`;
        return this._mainService.get(path);
    }

    getUsersList(str_term, str_path, cod_user, dsc_page_privilege, cod_location = null): Observable<any> {
        const path = '/meetings-users-list/' + str_term + '/' + encodeURIComponent(str_path) + '/' + cod_user + '/' + dsc_page_privilege + '/' + cod_location;
        return this._mainService.get(path);
    }

    getMeetingColumns(cod_meeting, cod_user) {
        const path = '/meetings-columns/' + cod_meeting + '/' + cod_user;
        return this._mainService.get(path);
    }

    postMeetingColumns(params){
        const path = '/meetings-column';
        return this._mainService.post(path, params);
    }

    deleteMeetingColumn(params) {        
        const path = '/meetings-column/' + params.cod_columns + '/' + params.cod_user+ '/' + params.cod_meeting;
        return this._mainService.delete(path);
    }

    deleteMeetingColumnOption(params) {        
        const path = '/meetings-column-option/' + params.cod_columns_options + '/' + params.cod_user+ '/' + params.cod_meeting;
        return this._mainService.delete(path);
    }

    getColumnsList(str_term, cod_user) {
        const path = '/meetings-columns-list/' + str_term + '/' + cod_user;
        return this._mainService.get(path);
    }

    getColumnOptions(cod_column, cod_user) {
        const path = '/meetings-column-options/' + cod_column + '/' + cod_user;
        return this._mainService.get(path);
    }

    postMeetingsReports(searchParams) {
        const path = '/createReports';
        return this._mainService.postReport(path, searchParams);
    }

    getAdminMeeting(cod_meeting, cod_user): Observable<any> {
        const path = '/meetingAdmin/' + cod_meeting + '/' + cod_user;
        return this._mainService.get(path);
    }

    postMeetingDetails(params) {
        const path = '/meetings-details';
        return this._mainService.post(path, params);
    }

    putMeetingLeaveDelete(params) {
        const path = '/meeting-leave-delete';
        return this._mainService.put(path, params);
    }

    getMeetingsByMeetingTypes(params): Observable<any> {
        const path = '/meetings-by-meeting-types';
        return this._mainService.post(path, params);
    }

    getDistinctMeetingsByMeetingTypes(params): Observable<any> {
        const path = '/distinct-meetings-by-meeting-types';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }

    postMeeting(createParams) {
        const path = '/meeting';
        return this._mainService.post(path, createParams);
    }

    getMeetingsIndicators(cod_meeting, cod_user) {
        const path = '/meetingsIndicators/' + cod_meeting + '/'+cod_user;
        return this._mainService.get(path);
    }

    getMeetingsDepartments(cod_meeting, cod_user?): Observable<any> {
        const path = `/meetingsDepartments/${cod_meeting}/${cod_user}`;
        return this._mainService.get(path);
    }

    getMeetingIndicatorsByAction(cod_action, cod_user) {
        const path = '/meetingIndicatorsByAction/' + cod_action + '/'+cod_user;
        return this._mainService.get(path);
    }

    getActionCreationUser(params) {
        const path = '/action-creation-users-by-meeting';
        return this._mainService.post(path, params);
    }

    getActionExecutionUser(params) {
        const path = '/action-execution-users-by-meeting';
        return this._mainService.post(path, params);
    }

    getDownloadPDF(path) {
        return this._mainService.getDownloadPDF(path);
    }

    getMeetingsLines(cod_locations, cod_user): Observable<any> {
        const path = `/meetings-lines-by-name/?cod_locations=${cod_locations}&cod_user=${cod_user}`;
        return this._mainService.get(path);
    }
    postMeetingParticipant(params) {
        const path = '/meetings-participant';
        return this._mainService.post(path, params);
    }

    postMeetingOwner(params) {
        const path = '/meeting-owner';
        return this._mainService.post(path, params);
    }

    deleteMeetingParticipant(params) {        
        const path = '/meetings-participant/' + params.cod_user + '/' + params.cod_usu_del + '/' + params.cod_meeting;
        return this._mainService.delete(path);
    }

    getMeetingMessages(params){
        const path = '/meetings-chat-list/'+params.cod_meeting+'/'+encodeURIComponent(params.str_path)+'/'+params.cod_user;
        return this._mainService.get(path);
    }

    postMeetingMessage(params){
        const path = '/meetings-chat';
        return this._mainService.post(path, params);
    }

    putMeetingMessage(params){
        const path = '/meetings-chat';
        return this._mainService.put(path,params);
    }

    deleteMeetingMessage(params){
        const path = '/delete-meetings-chat/' + params.cod_chat_messages +'/'+ params.cod_user;
        return this._mainService.delete(path);
    }

    getMeetingComments(cod_meeting, cod_user): Observable<any> {
        const path = `/v2/${cod_meeting}/${cod_user}/meeting-comments-list`;
        return this._mainService.get(path);
    }

    postMeetingComment(cod_meeting, cod_user, params): Observable<any>{
        const path = `/v2/${cod_meeting}/${cod_user}/meeting-comment`;
        return this._mainService.post(path, params);
    }

    putMeetingComment(cod_meeting, cod_user, params): Observable<any>{
        const path = `/v2/${cod_meeting}/${cod_user}/meeting-comment`;
        return this._mainService.put(path, params);
    }

    deleteMeetingComment(cod_meeting, cod_user, cod_comment): Observable<any>{
        const path = `/v2/${cod_meeting}/${cod_user}/meeting-comment/${cod_comment}`;
        return this._mainService.delete(path);
    }

    updateDeletedMeetingType(params): Observable<any>{
        const path = '/meetings-update-deleted-meeting-type';
        return this._mainService.post(path, params);
    }   
    getLocationByMeetingCreator(params){
        const path = '/meetings-list-location-by-creator';
        return this._mainService.post(path, params);
    }
    
    getMeetingsIndicatorsByArray(params) {
        const path = '/meetingsIndicatorsByArray';
        return this._mainService.post(path,params);
    }

    getTakeAttendance(params){
        const path = '/get-take-attendance/'+ params.cod_user + '/' + params.cod_meeting;
        return this._mainService.get(path);
    }

    postMeetingPresenceDate(params){
        const path = '/meetings-presence-date';
        return this._mainService.post(path, params);
    }

    postMeetingPresenceUpdate(params){
        const path = '/meetings-update-presence-date';
        return this._mainService.post(path, params);
    }

    postMeetingPresenceUser(params){
        const path = '/meetings-take-attendance';
        return this._mainService.post(path, params);
    }

    postDownloadAttendanceReport(params){
        const path = '/attendance-report';
        return this._mainService.postReport(path, params);
    }

    getLocationMeetings(params){
        const path = '/location-meetings-list';
        return this._mainService.post(path, params);
    }

    getAreasByMeetingLocation(params): Observable<any>{ 
        let path = '/get-areas-by-meeting-location';
        return this._mainService.post(path, params);
    }

    orderMeetings(
      arr_orders: Array<string>,
      arr_meetings: Array<Meeting>,
      reOrder = false,
    ): Array<Meeting> {
      let result = arr_meetings;
      for(let order of arr_orders) {
        switch(order) {
          case "name":
            result.sort((a, b) => {
              return reOrder ? b.str_name.localeCompare(a.str_name) : a.str_name.localeCompare(b.str_name);
            });
            break;
          case "role":
            result.sort((a, b) => {
              const isOwner = Number(b.bol_is_owner) - Number(a.bol_is_owner);
              const isParticipant = Number(a.bol_is_owner) - Number(b.bol_is_owner);
              return reOrder ? isParticipant : isOwner;
            });
            break;
          case "actions":
            result.sort((a, b) => {
              const totalDesc = b.int_pendentes - a.int_pendentes;
              const totalAsc = a.int_pendentes - b.int_pendentes;
              return reOrder ? totalAsc : totalDesc;
            });
            break;
          case "frequency":
            result.sort((a, b) => {
              const totalDesc = b.periodicity_order - a.periodicity_order;
              const totalAsc = a.periodicity_order - b.periodicity_order;
              return reOrder ? totalAsc : totalDesc;
            });
            break;
          default: break;
        };
      };

      return result;
    };

    getMeetingActionSourcesAutofill(): string {
        if(this.autofill_sources === null){
            this.setMeetingActionSourcesAutofill();
        }
        return this.autofill_sources;
    }

    setMeetingActionSourcesAutofill(){
        this.autofill_sources = JSON.parse(localStorage.getItem('autofill-action-sources'))?.autofill ?? null;
        localStorage.removeItem('autofill-action-sources');
    }

    clearMeetingActionSourcesAutofill(){
        this.autofill_sources = null;
    }

    getMeetingParticipants(cod_meeting: number, cod_user: number): Observable<GenericRequestType<MeetingParticipant>> {
        const path = `/v2/${cod_meeting}/${cod_user}/participants`;
        return this._mainService.get(path);
    }

    getMeetingsParticipantsExclusive(arr_meetings: number[], cod_user: number): Observable<any> {
        const path = `/v2/${cod_user}/participants-exclusive`;
        let query = {};
        if(arr_meetings != null && arr_meetings.length > 0) query[`arr_meetings`] = arr_meetings.toString();
        return this._mainService.get(path, query);
    }

    getPossibleMeetingParticipantsByTerm(cod_user: number, str_term: string): Observable<GenericRequestType<MeetingParticipantSearch>> {
        const path = `/v2/${cod_user}/participants/${str_term}`;
        return this._mainService.get(path);
    }

    postAddMeetingParticipant(cod_meeting: number, cod_user: number, new_participant_id: number): Observable<GenericRequestType<AddMeetingParticipantResponse>> {
        const path = `/v2/${cod_meeting}/${cod_user}/participants`;
        return this._mainService.post(path, { new_participant_id });
    }

    removeMeetingParticipant(cod_meeting: number, cod_user: number, cod_participant: number): Observable<GenericRequestType<RemoveMeetingParticipantResponse>> {
        const path = `/v2/${cod_meeting}/${cod_user}/participants/${cod_participant}`;
        return this._mainService.delete(path);
    }

    updateMeetingOwner(cod_meeting: number, cod_user: number, new_owner_id: number, is_owner: 0 | 1): Observable<GenericRequestType<UpdateMeetingOwnerResponse>> {
        const path = `/v2/${cod_meeting}/${cod_user}/meeting-owner`;
        return this._mainService.put(path, {
            cod_user_updated: new_owner_id, 
            is_owner: is_owner
        });
    }

    getMeetingDatesAndPresences(cod_meeting: number, cod_user: number): Observable<GenericRequestType<MeetingPresence[]>> {
        const path = `/v2/${cod_user}/meeting-presences/${cod_meeting}`;
        return this._mainService.get(path);
    }

    postMeetingPresence(cod_meeting: number, cod_user: number, date_presence: string): Observable<GenericRequestType<PostMeetingPresenceResponse>> {
        const path = `/v2/${cod_user}/meeting-presences/${cod_meeting}`;
        return this._mainService.post(path, { date_presence });
    }

    updateMeetingPresenceDate(cod_user: number, cod_meetings_presence: number, date_presence: string): Observable<GenericRequestType<any>> {
        const path = `/v2/${cod_user}/meeting-presences/${cod_meetings_presence}`;
        const params = {
            date_presence
        }
        return this._mainService.put(path, params);
    }

    updateMeetingParticipantPresence(cod_user: number, cod_meetings_presence: number, isPresent: boolean, cod_meetings_participants): Observable<GenericRequestType<any>> {
        const path = `/v2/${cod_user}/meeting-presences/${cod_meetings_presence}/participants/${cod_meetings_participants}`;
        const params = {
            is_present: isPresent
        }
        return this._mainService.put(path, params);
    }

    deleteMeetingPresenceDate(cod_user: number, cod_meetings_presence: number): Observable<GenericRequestType<any>> {
        const path = `/v2/${cod_user}/meeting-presences/${cod_meetings_presence}`;
        return this._mainService.delete(path);
    }

    getEditMeetingsDetails(cod_meeting: number, cod_user: number): Observable<MeetingDetailsResponse> {
        const path = `/v2/${cod_user}/${cod_meeting}/details`;
        return this._mainService.get(path);
    }
    
    getMeetingDetailColumns(cod_meeting: number, cod_user: number): Observable<GenericResponseType<MeetingColumn>> {
        const path = `/v2/${cod_user}/${cod_meeting}/columns`;
        return this._mainService.get(path);
    }

    getMeetingLocationOptions(cod_meeting: number, cod_user: number): Observable<GenericResponseType<{ cod_locations: number, str_name: string }>> {
        const path = `/v2/${cod_user}/${cod_meeting}/locations`;
        return this._mainService.get(path);
    }

    getMeetingDepartmentOptions(cod_user: number, cod_location: number, str_term: string = ''): Observable<GenericResponseType<{ cod_department: number, department_name: string }>> {
        const params = str_term ? `?str_term=${str_term}` : '';
        const path = `/v2/${cod_user}/${cod_location}/meeting-details/department-options` + params;
        return this._mainService.get(path);
    }

    getMeetingTypeOptions(cod_user: number, cod_location: number, cod_meeting: number): Observable<GenericResponseType<{ cod_meetings_types: number, str_name: string }>> {
        const queryParams = cod_location ? `?cod_location=${cod_location}` : '';
        const path = `/v2/${cod_user}/${cod_meeting}/types` + queryParams;
        return this._mainService.get(path);
    }

    getMeetingTypeFrequency(cod_user: number, cod_meeting_type: number): Observable<GenericResponseType<{ cod_frequency: number, frequency_name: string }>> {
        const path = `/v2/${cod_user}/meeting-type/${cod_meeting_type}/frequency`;
        return this._mainService.get(path);
    }

    getMeetingTagsOptions(cod_user: number, cod_location: number): Observable<GenericResponseType<TagType>> {
        const queryParams = cod_location ? `?cod_location=${cod_location}` : '';
        const path = `/v2/${cod_user}/tags` + queryParams;
        return this._mainService.get(path);
    }

    searchMeetingColumnOptions(cod_user: number, search_term: string): Observable<GenericResponseType<MeetingColumn>> {
        const queryParams = search_term ? `?str_term=${search_term}` : '';
        const path = `/v2/${cod_user}/columns` + queryParams;

        return this._mainService.get(path);
    }

    addNewMeetingColumn(cod_user: number, cod_meeting: number, column_description: string, cod_column_origin: number, column_position: number): Observable<GenericResponseType<{ cod_meeting_column: number }>> {

        const path = `/v2/${cod_user}/${cod_meeting}/column`;
        const params = {
            column_description,
            cod_column_origin,
            column_position
        }

        return this._mainService.post(path, params);
    }

    removeMeetingColumn(cod_user: number, cod_meeting: number, cod_column: number): Observable<GenericResponseType<{ meetings_sp_de_column: number }>> {
        const path = `/v2/${cod_user}/${cod_meeting}/column/${cod_column}`;
        return this._mainService.delete(path);
    }

    postMeetingColumnOption(cod_user: number, cod_meeting: number, cod_column: number, str_name_option: string, option_position: number): Observable<GenericResponseType<{ cod_columns_options: number }>> {
        const path = `/v2/${cod_user}/${cod_meeting}/column/${cod_column}/option`;
        const params = {
            str_name_option,
            option_position
        }

        return this._mainService.post(path, params);
    }

    deleteColumnOption(cod_user: number, cod_meeting: number, cod_column: number, cod_column_option: number): Observable<GenericResponseType<{ meetings_sp_de_column_option: number }>> {
        const path = `/v2/${cod_user}/${cod_meeting}/column/${cod_column}/option/${cod_column_option}`;
        return this._mainService.delete(path);
    }

    updateMeetingColumnOption(cod_column_option: number, new_option_name: string, option_position: number, cod_user: number, cod_meeting: number, cod_column: number): Observable<GenericResponseType<{ cod_columns_options: number }>> {
        const path = `/v2/${cod_user}/${cod_meeting}/column/${cod_column}/option/${cod_column_option}`;
        const params = {
            str_name_option: new_option_name,
            option_position
        }

        return this._mainService.put(path, params);
    }

    updateMeetingTags(cod_user: number, cod_meeting: number, arr_tags_ids: number[]): Observable<GenericResponseType<any>> {
        const path = `/v2/${cod_user}/${cod_meeting}/tags`;
        return this._mainService.put(path, { arr_tags_ids });
    }

    updateMeetingDetails({ cod_user, meetingDetails }: UpdateMeetingDetailsParams): Observable<GenericResponseType<any>> {
        const params = {
            meeting_name: meetingDetails.meeting_name,
            cod_meeting_type: meetingDetails.cod_meeting_type,
            cod_day_of_week: meetingDetails.cod_day_of_week,
            cod_location: meetingDetails.cod_location,
            cod_area: meetingDetails.cod_department,
            only_participants: meetingDetails.only_participants
        };
        
        const path = `/v2/${cod_user}/${meetingDetails.cod_meetings}/details`;
        return this._mainService.put(path, params);
    };

    deleteMeeting({ cod_user, cod_meeting }: { cod_user: number, cod_meeting: number }): Observable<GenericResponseType<any>> {
        const path = `/v2/${cod_user}/${cod_meeting}/details`;
        return this._mainService.delete(path);
    }

    restoreMeeting({ cod_user, cod_meeting }: { cod_user: number, cod_meeting: number }) {
        const path = `/v2/${cod_user}/${cod_meeting}/restore`;
        return this._mainService.put(path, {});
    }
}