// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .content-editable {
    border: 1px solid #ccc;
    border-radius: 4px;    
    padding: 6px 12px;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #555;
    height: 34px;
    line-height: 1.42857143;
    overflow: hidden;
}

:host .content-editable a {
    cursor: pointer !important;
}

:host .text-area {
    min-height: 120px;
    max-height: 120px;
    overflow: scroll;
    height: auto;
}

:host .disabled {
    background-color: #eee;
    cursor: not-allowed;
}

:host .content-editable:focus {
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

:host .content-editable:empty:before { /* :not(:focus) */
    content:attr(data-placeholder);
    color:grey;
    font-style:italic;
}

:host .has-error {
    border: 1px solid red;
}

:host .action-details {
    max-width: calc(100vw / 3 - 65px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/content-editable/content-editable.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,eAAe;IACf,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA,uCAAuC,iBAAiB;IACpD,8BAA8B;IAC9B,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[":host .content-editable {\n    border: 1px solid #ccc;\n    border-radius: 4px;    \n    padding: 6px 12px;\n    width: 100%;\n    display: block;\n    font-size: 14px;\n    color: #555;\n    height: 34px;\n    line-height: 1.42857143;\n    overflow: hidden;\n}\n\n:host .content-editable a {\n    cursor: pointer !important;\n}\n\n:host .text-area {\n    min-height: 120px;\n    max-height: 120px;\n    overflow: scroll;\n    height: auto;\n}\n\n:host .disabled {\n    background-color: #eee;\n    cursor: not-allowed;\n}\n\n:host .content-editable:focus {\n    outline: none !important;\n    border-color: #719ECE;\n    box-shadow: 0 0 10px #719ECE;\n}\n\n:host .content-editable:empty:before { /* :not(:focus) */\n    content:attr(data-placeholder);\n    color:grey;\n    font-style:italic;\n}\n\n:host .has-error {\n    border: 1px solid red;\n}\n\n:host .action-details {\n    max-width: calc(100vw / 3 - 65px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
