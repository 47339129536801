import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mobile-topic-1',
  templateUrl: './mobile-topic-1.component.html',
  styleUrls: ['./mobile-topic-1.component.css']
})
export class MobileTopic1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
