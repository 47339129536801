import {Component, OnInit} from '@angular/core';
import {UserPreference} from '../../models/user-preference.model';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {UserPreferencesComponent} from '../../user-preferences/user-preferences.component';
import {AuthService} from '../../../main/access/services/auth.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-change-background',
    templateUrl: './change-background.component.html',
    styleUrls: ['./change-background.component.css']
})
export class ChangeBackgroundComponent implements OnInit {
    public backgroundImages: any[];
    public userPreference: UserPreference;
    public selectedPath: String;

    constructor(
        private _userPreferencesService: UserPreferencesService,
        // private _headerComponent: HeaderComponent,
        private _userPreferencesComponent: UserPreferencesComponent,
        private _authService: AuthService,
        private sanitizer: DomSanitizer) {
        this.userPreference = new UserPreference();
        this.userPreference.cod_user = this._authService.getAuthenticatedUser().id;
    }

    ngOnInit() {
        this._userPreferencesService.getBackgroundImages().subscribe(data => {
            this.backgroundImages = data;
        },
        err => { this._authService.errCheck(err); console.log(err); } 
        );
    }

    getBackground(image) {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }

    saveBackground() {
        this._userPreferencesService.postBackground(this.userPreference).subscribe(res => {
            if (res.cod <= 0) {
                alert(res.str);
            }
            this._userPreferencesComponent.loadUserPreferences(true);
            this._userPreferencesComponent.closeModal('modal-change-background');
        },
        err => { this._authService.errCheck(err); console.log(err); } 
        );
    }

    closeBackground() {
        this._userPreferencesComponent.closeModal('modal-change-background');
    }

    setBackgroundImage(imagePath: string) {
        this.selectedPath = imagePath;
        this.userPreference.str_pref_value = imagePath;
    }
}
