export class ListChecklistParams {
    arr_cod_areas: Array<string | number>;
    arr_cod_subareas: Array<string | number>;
    cod_user: number;
    str_filter_by: string;
    dsc_page_privilege: string;

    constructor(
        cod_areas: number[] = [],
        cod_subareas: number[] = [],
        user: number = null,
        filter_by: string = null,
        page_privilege: string = ''
    ) {
        this.arr_cod_areas = cod_areas;
        this.arr_cod_subareas = cod_subareas;
        this.cod_user = user;
        this.str_filter_by = filter_by;
        this.dsc_page_privilege = page_privilege;
    }

    valid() {
        if (!this.cod_user) return false;
        if (!this.str_filter_by) return false;
        if (!this.dsc_page_privilege) return false;
        if (!this.arr_cod_areas) return false;
        if (this.arr_cod_areas.length === 0) return false;
        if (this.arr_cod_areas.some((e: any) => ["", null].includes(e))) return false;
        if (!this.arr_cod_subareas) return false;
        if (this.arr_cod_subareas.length === 0) return false;
        if (this.arr_cod_subareas.some((e: any) => ["", null].includes(e))) return false;
        return true;
    }
}
