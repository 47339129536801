// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discarts-bts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}

:host ::ng-deep modal .modal-title {
    color: #a9a9a9 !important;
    padding: 1em 3em 1em 3em;
}

.answer-button {
  width: 150px;
  margin: 0 0.25em;
  padding: 0.5em;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;
  outline: none;
}

.--yes {
  background-color: #00c156;
}

.--no {
  background-color: #fe484f;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-discart-changes/confirm-discart-changes.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,wBAAwB;AAC5B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".discarts-bts {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 1rem;\n    gap: 1rem;\n}\n\n:host ::ng-deep modal .modal-title {\n    color: #a9a9a9 !important;\n    padding: 1em 3em 1em 3em;\n}\n\n.answer-button {\n  width: 150px;\n  margin: 0 0.25em;\n  padding: 0.5em;\n  color: #ffffff;\n  border-radius: 3px;\n  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;\n  outline: none;\n}\n\n.--yes {\n  background-color: #00c156;\n}\n\n.--no {\n  background-color: #fe484f;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
