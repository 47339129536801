// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-pie-percent {
    margin: 0 auto;
    display:block;
}

.chart-percentage {
    position: absolute;
}


.center {
    text-align: center;
}

.center p {
    /* line-height: 1.5; */
    display: inline-block;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/components/chart-pie-percent/chart-pie-percent.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".chart-pie-percent {\n    margin: 0 auto;\n    display:block;\n}\n\n.chart-percentage {\n    position: absolute;\n}\n\n\n.center {\n    text-align: center;\n}\n\n.center p {\n    /* line-height: 1.5; */\n    display: inline-block;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
