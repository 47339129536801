// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    text-align: left;
}

:host .links-container {
    margin: 15px 0;
}

.link-border {
    border-top: 1px solid #e6e6e6;
    overflow-wrap: break-word;
    padding: 0.5em 0;
    margin: 0;
}

:host .label {
    font-size: 0.8125em;
    color: #666666;
    display: block;
    margin-bottom: 4px;
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hyperlinks-list/hyperlinks-list.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[":host {\n    text-align: left;\n}\n\n:host .links-container {\n    margin: 15px 0;\n}\n\n.link-border {\n    border-top: 1px solid #e6e6e6;\n    overflow-wrap: break-word;\n    padding: 0.5em 0;\n    margin: 0;\n}\n\n:host .label {\n    font-size: 0.8125em;\n    color: #666666;\n    display: block;\n    margin-bottom: 4px;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
