import { Question } from '../models/question.model'

export class Category {
    cod_forms_category?: number;
    cod_form: number;
    int_order: number;
    str_name: string;
    str_name_de?: string;
    str_name_es?: string;
    str_name_fr?: string;
    str_name_nl?: string;
    str_name_pt?: string;
    str_name_ru?: string;
    str_name_ua?: string;
    str_name_zh?: string;
    str_name_ko?: string;
    str_name_vi?: string;
    expanded?: boolean;
    questions?: Array<Question>;
    arr_questions?: Array<Question>;
  };
  