import { Component, Input } from '@angular/core';
import { OnlineReportsService } from 'app/logbooks/services/online-reports';
import { AuthService } from 'app/main/access/services/auth.service';
import * as moment from 'moment';
import { forkJoin, Subscription, timer } from "rxjs";

@Component({
    selector: 'app-reports-routines-functional-online',
    templateUrl: './reports-routines-functional-online.component.html',
    styleUrls: ['./reports-routines-functional-online.component.css']
})
export class ReportsRoutineFunctionalOnlineComponent {
    
    @Input("initial_date") initial_date: string;
    @Input("final_date") final_date: string;
    @Input("selectedLogbooks") selectedLogbooks: any;
    @Input("selectedUsers") selectedUsers: any;
    @Input("selectedSubareas") selectedSubareas: any;
    @Input("selectedLocations") selectedLocations: any;

    codUser = null;
    arr_checklists = [];
    qtds = [];
    date_format: string = localStorage.getItem('userPreferencesDateFormat');
    isLoadingChecklists: boolean = false;
    isLoadingQtds: boolean = false;
    problemWithCompleteness: boolean = false;
    drillLevel = 0;
    partialLoading = false;

    constructor(
        private _authService: AuthService,
        private _onlineReportsService: OnlineReportsService,
    ) {
        this.codUser = this._authService.getAuthenticatedUser().id;
    }

    collapseAll(): void {
        this.drillLevel = 0;
        this.arr_checklists?.forEach(c => {
            if(c.childExpanded == true){
                c.childExpanded = false;
                c?.arr_users?.forEach(u => {
                    if(u.childExpanded == true){
                        u.childExpanded = false;
                        u?.arr_days?.forEach(d => {
                            if(d.childExpanded == true){
                                d.childExpanded = false;
                            }
                        });
                    }
                });
            }
        });
    }

    drillUpAll() {       
        if(this.arr_checklists.length == 0){
            return;
        }
        
        switch(this.drillLevel) {
            case 1:
            default:
                this.collapseAll();
                break;
            case 2:
                this.drillLevel -= 1;
                this.arr_checklists?.forEach(c => {
                    if(c.childExpanded == true){
                        c?.arr_users?.forEach(u => {
                            if(u.childExpanded == true){
                                u.childExpanded = false;
                                u?.arr_days?.forEach(d => {
                                    if(d.childExpanded == true){
                                        d.childExpanded = false;
                                    }
                                });
                            }
                        });
                    }
                });
                break;
            case 3:
                this.drillLevel -= 1;
                this.arr_checklists?.forEach(c => {
                    if(c.childExpanded == true){
                        c?.arr_users?.forEach(u => {
                            if(u.childExpanded == true){
                                u?.arr_days?.forEach(d => {
                                    if(d.childExpanded == true){
                                        d.childExpanded = false;
                                    }
                                });
                            }
                        });
                    }
                });
                break;
        }
    }

    async drillDownAll() {
        if(this.arr_checklists.length == 0 || this.drillLevel == 3){
            return;
        }

        this.drillLevel += 1;

        switch(this.drillLevel) {
            case 1:
                this.arr_checklists?.forEach(c => {
                    c.childExpanded = true;
                });
                break;
            case 2:
                this.arr_checklists?.forEach(c => {
                    if(c.childExpanded == true){
                        c?.arr_users?.forEach(s => {
                            s.childExpanded = true;
                        });
                    }
                });
                break;
            case 3:
                if(Array.isArray(this.arr_checklists) && this.arr_checklists.length > 0){
                    for(let i = 0; i < this.arr_checklists.length; i++){
                        let c = this.arr_checklists[i];
                        if(Array.isArray(c.arr_users) && c.arr_users.length > 0 && c.childExpanded == true){
                            for(let j = 0; j < c.arr_users.length; j++){
                                let s = c.arr_users[j];
                                this.partialLoading = true;
                                if(Array.isArray(s.arr_days) && s.arr_days.length > 0 && s.childExpanded == true){
                                    s.partialLoading = true;
                                    for(let k = 0; k < s.arr_days.length; k++){
                                        let d = s.arr_days[k];                                        
                                        if(typeof d.arr_routines == typeof undefined){
                                            if(s.childExpanded == true){
                                                d.isLoading = true;
                                                const formattedDate = moment(d.str_date, this.date_format).format('YYYY-MM-DD');
                                                const param = this.getRoutineParams(formattedDate, c.cod_logbooks, s.cod_id);
                                                const routines = await this.getRoutines(param);
                                                d.arr_routines = routines?.content?.results || [];
                                                d.isLoading = false;
                                                d.childExpanded = true;    
                                            }
                                        }
                                        else{
                                            d.childExpanded = true;
                                        }                                                                               
                                    }
                                    s.partialLoading = false;                               
                                }
                                this.partialLoading = false
                            }
                        }        
                    }
                }
                break;
            default:
                break;
        }
    }

    async onShow(object, params, type) {
        object.childExpanded = !object.childExpanded;        
        let isOpen = false;
        switch(type) {
            case 'checklist':
                this.arr_checklists?.find(c => c?.childExpanded == true) ? this.drillLevel = 1 : this.drillLevel = 0;
                break;
            case 'user':
                this.arr_checklists?.forEach(c => {
                    if(c.childExpanded == true){
                        c?.arr_users?.forEach(s => {
                            s.childExpanded == true && (isOpen = true);
                        });
                    }
                });
                isOpen? this.drillLevel = 2 : this.drillLevel = 1;
                break;
            case 'day':
                this.arr_checklists?.forEach(c => {
                    if(c.childExpanded == true){
                        c?.arr_users?.forEach(u => {
                            if(u.childExpanded == true){
                                u?.arr_days?.forEach(d => {
                                    d.childExpanded == true && (isOpen = true);
                                });
                            }
                        });
                    }
                });
                isOpen? this.drillLevel = 3 : this.drillLevel = 2;
                
                
                if (!object.childExpanded || object.arr_routines) return;

                object.isLoading = true;

                const formattedDate = moment(params.day, this.date_format).format('YYYY-MM-DD');

                const param = this.getRoutineParams(formattedDate, params.checklistId, params.user);
                const routines = await this.getRoutines(param);

                object.arr_routines = routines?.content?.results || [];

                object.isLoading = false;
                break;
            default:
                break;
        }
    }

    searchReports() {
        this.drillLevel = 0;

        this.isLoadingChecklists = true;
        this.isLoadingQtds = true;
        this.problemWithCompleteness = false;

        const params = {
            cod_location: this.selectedLocations,
            cod_subarea: !!this.selectedSubareas ? this.selectedSubareas.split(",") : null,
            cod_users: !!this.selectedUsers ? this.selectedUsers.split(",") : null,
            dat_ini: this.initial_date,
            dat_end: this.final_date,
            cod_user: this.codUser,
            cod_logbooks: !!this.selectedLogbooks ? this.selectedLogbooks.split(",") : null
        };
      
        setTimeout(() => {
            if (this.isLoadingQtds) {
                this.problemWithCompleteness = true;
            }
        }, 15000);

        this._onlineReportsService.getRoutineReportsFunctional(params).subscribe(results => {
            this.isLoadingChecklists = false;
            this.arr_checklists = results.content.results;
            this.calculateAllPercents();
        });

        this._onlineReportsService.getQtdRoutinesFunctional(params).subscribe(results2 => {
            this.isLoadingQtds = false;
            this.qtds = results2.content.results;
            this.calculateAllPercents();
        });
    }

    calculateAllPercents() {
        if (this.isLoadingChecklists || this.isLoadingQtds) return;

        let iLogbook,
            iUser,
            iDay = -1;

        let currentLogbook, 
            currentUser, 
            sumDoneLogbook, 
            sumTotalLogbook, 
            sumDoneUser, 
            sumTotalUser = 0;

        for (let i = 0; i < this.qtds.length; i++) {
            let {
                cod_logbooks,
                cod_user,
                str_date,
                qtd_done,
                qtd_total
            } = this.qtds[i];

            qtd_done = Number(qtd_done);
            qtd_total = Number(qtd_total);

            if (currentLogbook != cod_logbooks) {
                if (iLogbook >= 0) {
                    this.arr_checklists[iLogbook].completeness = this.calcPercent(sumDoneLogbook, sumTotalLogbook);
                    if (iUser >= 0) {
                        this.arr_checklists[iLogbook].arr_users[iUser].completeness = this.calcPercent(sumDoneUser, sumTotalUser);
                        iUser = -1;
                    }
                }

                sumDoneLogbook = 0;
                sumTotalLogbook = 0;

                currentUser = 0;
                currentLogbook = cod_logbooks;
                iLogbook = this.arr_checklists.findIndex(c => c.cod_logbooks == cod_logbooks);
            }

            if (iLogbook == -1) continue;

            if (currentUser != cod_user) {
                if (iUser >= 0) {
                    this.arr_checklists[iLogbook].arr_users[iUser].completeness = this.calcPercent(sumDoneUser, sumTotalUser);
                }

                sumDoneUser = 0;
                sumTotalUser = 0;

                currentUser = cod_user;
                iUser = this.arr_checklists[iLogbook].arr_users.findIndex(s => s.cod_id == cod_user);
            }

            if (iUser == -1) continue;

            iDay = this.arr_checklists[iLogbook]
                .arr_users[iUser]
                .arr_days.findIndex(d => d.str_date == str_date);

            if (iDay == -1) continue;

            this.arr_checklists[iLogbook]
                .arr_users[iUser]
                .arr_days[iDay]
                .completeness = this.calcPercent(qtd_done, qtd_total);

            sumDoneLogbook += qtd_done;
            sumTotalLogbook += qtd_total;
            sumDoneUser += qtd_done;
            sumTotalUser += qtd_total;

        }

        if (iLogbook >= 0) {
            this.arr_checklists[iLogbook].completeness = this.calcPercent(sumDoneLogbook, sumTotalLogbook);
            if (iUser >= 0) {
                this.arr_checklists[iLogbook].arr_users[iUser].completeness = this.calcPercent(sumDoneUser, sumTotalUser);
            }
        }
    }

    calcPercent(x: number, y: number): string {
        if (y == 0) return 'N/A';
        const percent = x / y * 100;
        return isNaN(percent) ? '' : `${percent.toFixed(2)} %`;
    }

    completenessCheck(completeness){
        return completeness? completeness : 'N/A';
    }

    getRoutineParams(date, cod_logbooks, cod_user) {
        return {
            cod_location: this.selectedLocations,
            cod_subarea: !!this.selectedSubareas ? this.selectedSubareas.split(",") : null,
            cod_users: cod_user,
            date: date,
            cod_user: this.codUser,
            cod_logbooks: cod_logbooks
        }; 
    }

    async getRoutines(params) {
        try{
            return await this._onlineReportsService.getRoutinesFunctional(params).toPromise();
        }
        catch(err){
            return err;
        }
    }

    formatDate(date: string): string {
        return date.replace(/(\-\d\d\d\d)|(\/\d\d\d\d)/, '');
    }

}