export class Action {
    cod_user: number;
    dat_due: string;
    cod_indicator?: number;
    cod_area?: number;
    str_description: string;
    cod_usu_ins: number;
    str_sources: string;
    cod_form: number;
    cod_meeting?: number;
    meetings?: any;
    cod_logbook_routines_periodicity_users?: number;
    cod_logbook_routines_periodicity_users_collab?: number;
    cod_uuid: number;
    val_quality: number;

    constructor () {
        this.cod_user = null;
        this.dat_due = null;
        this.str_description = null;
        this.cod_usu_ins = null;
        this.str_sources = null;
        this.cod_form = null;
        this.cod_uuid = null;
        this.val_quality = null;
    };
}