import {Component, OnInit} from '@angular/core';
import {AuthService} from "../access/services/auth.service";
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UploadService} from '../../main/services/upload.service';
import {environment} from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-reports',
    templateUrl: './reports-download.component.html',
    styleUrls: ['./reports-download.component.css']
})
export class ReportsDownloadComponent implements OnInit {
    bol_loading: boolean = true;
    str_message: string;
    str_file: string;
    cod_user: number;

    constructor(
        private router: Router, 
        private _activatedRoute:ActivatedRoute, 
        private _uploadService:UploadService, 
        private _translateService:TranslateService, 
        private _authService: AuthService,
        private sanitizer: DomSanitizer,) {
        //http://localhost:4200/reports-download/625386/DTO_2018_PT_9-9-2019_Acheral2019-9-9-10_38_45.csv
    }

    ngOnInit() {
        //GET FILE AND USER FOR REQUEST REPORT
        this._activatedRoute.params.subscribe((arr_params) => {
            this.cod_user = arr_params['cod_user'];
            this.str_file = arr_params['str_file'];
        });
    }

    ngAfterViewInit() {
        //CHECK IF USER IS LOGGED
        if (this._authService.isAuthenticated()) {
            
            //SEND REQUEST TO GET FILE STREAM, WITH CREDENTIALS

            var downloadFile = (this.sanitizer.bypassSecurityTrustResourceUrl(environment().uploadGetFilesApiUrl+'/reports/'+this.cod_user+'/'+this.str_file) as any).changingThisBreaksApplicationSecurity;

            this._uploadService.downloadFile(downloadFile).subscribe(res => {
                //DOWNLOAD BLOB FILE
                let obj_blob = new Blob([res]);

                var a = document.createElement('a');
                a.href = URL.createObjectURL(obj_blob);
                a.download = this.str_file;
                document.body.appendChild(a);
                //only stop loading after
                a.onclick = e => {
                    window.addEventListener('focus', e=>{
                      URL.revokeObjectURL(a.href);
                      this.bol_loading = false;
                      this.router.navigate(['/']);
                    }, {once: true});
                };
                a.click();
                document.body.removeChild(a);

            }, err => {
                this._authService.errCheck(err);
                console.log(err);
                this.bol_loading = false;
            });
            
        } else {
            this.router.navigate(['/access']);
        }
    }

}