import { Injectable } from '@angular/core';
import {MainService} from '../services/main.service';

@Injectable()
export class CrewService {

    constructor(private _mainService: MainService) { }

    listCrew(params) {
        const path = '/list-crew';
        return this._mainService.get(path, params);
    }

    getLocationCrew(params) {
        const path = '/get-location-crews';
        return this._mainService.get(path, params);
    }

    saveCrews(params) {
        const path = '/save-crews';
        return this._mainService.post(path, params);
    }
}
