import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { NotificationService } from '../../../../main/services/notification.service'

@Component({
  selector: 'forms-dynamic-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})

export class FormsDynamicEditCategoryComponent implements OnChanges, OnInit {

  @Input() cod_forms_category: number = 0;
  @Input() str_text: string = '';

  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onEditTranslations = new EventEmitter();

  isLoading = false;
  isEditing = false;
  constructor(private _notify: NotificationService) { }

  ngOnChanges(changes: SimpleChanges):void {
    this.isEditing = (this.cod_forms_category > 0);
  }

  ngOnInit(){
  }

  validateQuestion() {  
    if(!this.str_text || !this.str_text) {
        return 'DYNAMICFORMS.CATEGORY.VALIDATIONS.NAME';
    }

    return true;
  }

  editTranslations() {
    this.onEditTranslations.emit();
  }

  save() {
    const validation = this.validateQuestion();
    if(validation != true) {
      this._notify.warning({ text: validation, translate: true });
      return;
    }

    this.onSave.emit({
      cod_form_question: this.cod_forms_category,
      str_text: this.str_text,
    });
  }

  cancel() {
    this.onCancel.emit();
  }

  delete() {
    this.onDelete.emit({
      cod_form_question: this.cod_forms_category,
    });
  }
}
