import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-fivewhy',
  templateUrl: './fivewhy.component.html',
  styleUrls: ['./fivewhy.component.css']
})
export class FivewhyComponent {
  isLoading: boolean = true;

  constructor(public _authService: AuthService, private router: Router) {}

  onFiveWhyDetailsOpen(fivewhyId) {
    let idToGet = ''
    if(fivewhyId > 0) {
      idToGet = fivewhyId;
    }
    this.router.navigate([`/problem-terminator/details/${idToGet}`]);
  }
}
