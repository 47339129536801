import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { StatusFivewhyColor } from "../enums/status.enum";
import { MainService } from "./main.service";
import {Observable} from 'rxjs';
import { map } from "rxjs/operators";
import { Day } from "../../../app/cockpit/model/shift";

@Injectable()
export class FiveWhyService {
  constructor(private _mainService: MainService) {}

  getFiveWhys(params) {
    const path = "/get-fivewhys";
    return this._mainService.post(path, params);
  }
  getFiveWhysMyTasks(params) {
    const path = "/get-fivewhys-my-tasks";
    return this._mainService.post(path, params);
  }

  listSteps(params) {
    const path = "/get-fivewhy-steps";
    return this._mainService.post(path, params);
  }

  getAllLocationsByPrivileges(params) {
    const path = "/get-all-location-by-privileges";
    return this._mainService.post(path, params);
  }

  getUserLastFilters(params) {
    const path = "/get-user-last-filters";
    return this._mainService.post(path, params);
  }

  getArrayContent(data: any) {
    return data.content.length >= 0 && !Array.isArray(data.content.results)
      ? [data.content.results]
      : data.content.results;
  }

  listHashtags(params) {
    const path = "/fivewhy-list-hashtags";
    return this._mainService.post(path, params);
  }

  persistFivewhy(params) {
    const path = "/persist-fivewhy";
    return this._mainService.post(path, params);
  }

  getSingleFivewhy(params) {
    const path = "/get-single-fivewhy";
    return this._mainService.post(path, params);
  }

  updateFivewhyStatus(params) {
    const path = "/update-fivewhy-status";
    return this._mainService.post(path, params);
  }

  saveUserPreferencesOnCreation(params) {
    const path = "/save-user-last-options-on-creation";
    return this._mainService.post(path, params);
  }

  getUserLastOptions(params) {
    const path = "/get-user-last-options";
    return this._mainService.post(path, params);
  }

  getWorkflow(params) {
    const path = "/get-graph-workflow";
    return this._mainService.post(path, params);
  }

  getSdcaMatrix(params) {
    const path = "/get-sdca-matrix";
    return this._mainService.post(path, params);
  }

  getFiveWhyAction(params) {
    const path = "/get-fivewhy-actions";
    return this._mainService.post(path, params);
  }

  saveFivewhyAction(params) {
    const path = "/insert-fivewhy-actions";
    return this._mainService.post(path, params);
  }

  sendEmailNotification(params) { 
    const path = "/send-notification-email";
    return this._mainService.post(path, params);
  }

  saveFivewhyRating(params) {
    const path = "/insert-fivewhy-rating";
    return this._mainService.post(path, params);
  }

  deleteFiveWhyRating(params) {
    const path = "/delete-fivewhy-rating";
    return this._mainService.post(path, params);
  }

  listFiveWhyRating(params) {
    const path = "/list-fivewhy-rating";
    return this._mainService.post(path, params);
  }
  postFivewhyReports(searchParams) {
    const path = '/createFivewhyReport';
    return this._mainService.post(path, searchParams);
  }
  
  getAllowedAreas(params) {
    const path = '/get-allowed-areas';
    return this._mainService.post(path, params);
  }

  getFilteredAllowedAreas(params) {
    const path = '/get-filtered-allowed-areas';
    return this._mainService.post(path, params);
  }

  getAllowedSubareas(params) {
    const path = '/get-allowed-subareas';
    return this._mainService.post(path, params);
  }

  getFilteredAllowedSubareas(params) {
    const path = '/get-filtered-allowed-subareas';
    return this._mainService.post(path, params);
  }

  listFivewhyParticipant(params) {
    const path = '/list-fivehwy-participants';
    return this._mainService.post(path, params);
  }

  insertFivewhyParticipant(params) {
    const path = '/insert-fivehwy-participants';
    return this._mainService.post(path, params);
  }

  deleteFivewhyParticipant(params) {
    const path = '/delete-fivehwhy-participants';
    return this._mainService.post(path, params);
  }

  insertCommentWhy(params) {
    const path = '/insert-comment-why';
    return this._mainService.post(path, params);
  }

  updateReopenFivewhy(params) {
    const path = '/reopen-fivewhy';
    return this._mainService.post(path, params);
  }

  getStatusById(statusList, cod_fivewhy_status) {
    return statusList?.find(s => s.cod_fivewhys_status == cod_fivewhy_status);
  }

  getUsersList(str_term, str_path, cod_user, dsc_page_privilege, cod_location = null): Observable<any> {
    const path = '/fivewhy-users-list/' + str_term + '/' + encodeURIComponent(str_path) + '/' + cod_user + '/' + dsc_page_privilege + '/' + cod_location;
    return this._mainService.get(path);
  }

  updateCloseFivewhy(params /* cod_user, cod_fivewhys, bol_closed */) {
    const path = '/close-fivewhy';
    return this._mainService.post(path, params);
  }

  getStatusColor(canceled, fivewhyCurrentStatus, statusList, cod_status): String {
    let statusColor = '';
    const status = this.getStatusById(statusList, cod_status);

    if(typeof status !== typeof undefined) {
      if ((!status.is_enable && status.dat_progress == null) || (cod_status < fivewhyCurrentStatus && status.dat_progress == null) || (canceled && cod_status <= fivewhyCurrentStatus )) {
        return StatusFivewhyColor.Disable; 
      }
    }
    
    const abEscalated = statusList.some(status => status.bol_report);
    
    if(abEscalated && !canceled && status.dat_progress && !status.bol_canceled) {
      return statusColor = StatusFivewhyColor.Blue;
    } else if(status.dat_progress == null) {
      return StatusFivewhyColor.Grey;
    } else {
      const lastStatus = this.getStatusById(statusList, 6)
                          ? this.getStatusById(statusList, 6)
                          : status;
      const timeElapsed = lastStatus.dat_completed
        ? moment(lastStatus.dat_completed).diff(moment(lastStatus.dat_ins), 'hours')
        : moment(new Date()).diff(moment(lastStatus.dat_ins), 'hours');

      if(timeElapsed <= 72) {
        statusColor = StatusFivewhyColor.Green;
      } else if(timeElapsed > 72 && timeElapsed <= 120) {
        statusColor = StatusFivewhyColor.Yellow;
      } else {
        statusColor = StatusFivewhyColor.Red;
      }
    }

    if(status.dat_progress && !status.bol_canceled) {
      return statusColor;
    }

    if (fivewhyCurrentStatus > status.cod_fivewhys_status) {
      return StatusFivewhyColor.Disable;
    }

    return StatusFivewhyColor.Grey;
  }

  getLocationsCrewsByArray(params) {
    const path = '/get-crews-by-locations';
    return this._mainService.post(path, params);
  }

  searchParticipantList(params) {
    const path = '/search-participants-list';
    return this._mainService.post(path, params);
  }
  
  listShifts(params) {
    const path = '/list-shifts';
    return this._mainService.get(path, params);
  }

  getShift(params) {
    const path = '/get-shift';
    return this._mainService.get(path, params).pipe(
      map((res) => {
        return res?.content?.results[0]?.get_fivewhy_shift_scale_up as Day[];
     
      })
    )
  }

  saveShift(params) {
    const path = '/save-shift';
    return this._mainService.post(path, params);
  }

  getAnalyticInfo(params) {
    const path = '/analytic-info?cod_user=' + params.cod_user 
                  + '&arr_cod_locations=' + params.arr_cod_locations
                  + '&arr_cod_areas=' + params.arr_cod_areas 
                  + '&arr_cod_subareas=' + params.arr_cod_subareas
                  + '&arr_cod_machines=' + params.arr_cod_machines
                  + '&arr_cod_equipments=' + params.arr_cod_equipments
                  + '&arr_cod_category=' + params.arr_cod_category
                  + '&dat_from='+ params.dat_from
                  +'&dat_to=' + params.dat_to;
    return this._mainService.get(path);
  }
}
