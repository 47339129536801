import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MeetingsService } from '../../meetings/services/meetings.service';
import { AuthService } from '../../main/access/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'list-users-ta',
    templateUrl: './list-users-ta.component.html',
    styleUrls: ['./list-users-ta.component.css']
})

export class ListUserTaComponent implements OnInit {

    UsersTaList: any = [];
    obj_ta_eq_users;
    str_user_tmp = '';
    warningDeleteMessage: Boolean = false;
    bol_show_typeahead_user: Boolean = true;

    @Input() question: any;
    @Input() disabledDelete: any;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    // @Input() cod_subarea;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _meetingsService: MeetingsService, private _authService:AuthService) { 
        
    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.UsersTaList = [{ cod_answer_option: this.question.cod_answer_option, str_description: this.question.str_answer_text || this.question.answer }];    
            this.bol_show_typeahead_user = false; 
        }
        this.loadUsersTa();
    }

    loadUsersTa() {
        this.obj_ta_eq_users = {
            type: 'dynamic',
            valueProperty: 'cod_user',
            searchProperty: 'str_name',
            hasImage: true,
            imageProperty: 'str_img_path',
            onSelect: (obj_selected: any) => {
                if (this.UsersTaList.length === 0) {
                    this.UsersTaList.push({
                        cod_user: obj_selected.cod_user,
                        str_name: obj_selected.str_name,
                        str_img_path: obj_selected.str_img_path,
                    });
                    if(this.question !== null) {
                        this.question.answer = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_user;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead_user = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchUsersTa(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchUsersTa(cb, str_term) {
        this._meetingsService.getUsersList(
            str_term, 
            environment().uploadGetFilesApiUrl + '/',
            this._authService.getAuthenticatedUser().id, 
            'NOT_PRIVILEGE'
        ).subscribe((result: any) => {
            cb(result.content.results);
        }, err => { this._authService.errCheck(err);
            console.log(err);
        });
    }

    delUserTa() {
        this.warningDeleteMessage = false;

        this.question.answer = '';
        this.question.cod_answer_option = null;
        this.onChange.emit(this.question);

        this.bol_show_typeahead_user = true;
        this.UsersTaList = [];
    }

}
