import {Injectable} from '@angular/core';
import {ListAreasMainService} from './list-areas-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListAreasService {
  
  constructor(private _mainService: ListAreasMainService) {
  }
  
  getAreasList(param): Observable<any> {
    let path = '/areas?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege;
    if (param.cod_areas > 0) {
      path = '/area/' + param.cod_areas;
    }
    
    return this._mainService.get(path);
  }
  
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
