import {AfterViewInit, Component} from '@angular/core';
import {LogbooksService} from '../services/logbooks.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from "../models/select_pagination.model";
import {NotificationService} from "./../../main/services/notification.service";

@Component({
    selector: 'app-logbooks-list',
    templateUrl: './logbooks-list.component.html',
    styleUrls: ['./logbooks-list.component.css']
})
export class LogbooksListComponent implements AfterViewInit {

    id: any;
    logbooksList: any[];
    selectedRecords: Object[];
    searchTerm: string;
    searchTimer: any;
    isLoading: boolean = true;
    codLogbooks: number = 0;
    createRecord: boolean = false;
    procedureImportingLogbooks: string = 'logbooks_sp_imp_logbooks_collab';
    fieldsExcelSheet: string = 'cod_logbooks,str_name,cod_area,str_area_description,str_code_subareas,str_type,cod_periodicity,str_periodicity_description,cod_routine,str_routine_name,str_routine_description,int_order,str_collab,str_shift_1,str_shift_2,str_shift_3';
    downloadCsvExample: any = {};
    codUser: number;
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    deleteLogbookId: number = 0;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    editSuccessMessage: boolean = false;
    isLoadingFile: boolean = false;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;
    logbook: any;
    listAux: any;
    bol_change_copy_name = false;
    copyName: string = '';
    copy_cod_logbook: number = null;
    successCopyMessage = '';
    privArea: boolean;
    warningTranslations: string;
    str_title_button: string;

    showSuccessMessage: boolean;
    showErrorMessage: boolean;
    emitSuccessMessage: boolean;
    emitErrorMessage: boolean;

    constructor(
        private _logbookService: LogbooksService, 
        private _authService: AuthService, 
        private _translateService: TranslateService,
        private _notify: NotificationService
    ) {
        this.searchTerm = '';
        this.showError = false;
        this.errorMessage = '';
        this.logbooksList = [];
        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;

        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
        });
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str50perpage = '50 ' + translate;
        });
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str100perpage = '100 ' + translate;
        }); 
        
        this._translateService.get('LOGBOOK.LABEL_IMPORT_LOGBOOK').subscribe(translate => {
            this.str_title_button = translate;
        });

        this.pageIndex = 1;
        this.totalPages = [];
        this.resultsPerPage = [
            new SelectPagination(10, this.str10perpage),
            new SelectPagination(50, this.str50perpage),
            new SelectPagination(100, this.str100perpage),
        ];
        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];        
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;

        this.selectedRecords = [];
        this.isLoadingFile = false;
    }

    ngOnInit(){
        this.privArea = !this._authService.getUserPrivilege('ADMIN_LOGBOOKS_SELECT_AREA');
    }
    
    ngAfterViewInit() {
        this._search();
    }

    public editLogbook(id, reload) {
        if (id == -1) {
            id = 0;
            this.editSuccessMessage = true;
            this.emitSuccessMessage = true;
            this.emitErrorMessage = false;
        } else {
            this.editSuccessMessage = false;
            this.emitSuccessMessage = false;
            this.emitErrorMessage = false;
        }
        this.codLogbooks = id;
        this.createRecord = !reload;
        if (reload) {
            this._search();
        }
    }

    public toggleDeleteLogbook(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteLogbookId = id;
    }

    public confirmDeleteLogbook() {
        let objParam = {
            'cod_logbook': this.deleteLogbookId,
            'cod_user': this._authService.getAuthenticatedUser().id
        };
        this._logbookService.deleteLogbook(objParam).subscribe(data => {
                this.emitSuccessMessage = true;
                this.emitErrorMessage = false;
                this._notify.success({ text: 'LOGBOOK.DELETED', translate: true });
                this.search(false);
            }, err => {
                let e = JSON.parse(err._body);
                console.log(e.content.message)
                let message = e.content.message;

                const re = /LINKED_USERS/gi;
                if (message.search(re) != -1) {
                    message = 'LOGBOOK.LINKED_USERS';
                }
                this._showErrorMessage(message);
                this._authService.errCheck(err);
            }
        );
        this.awaitConfirmDelete = false;
        this.deleteLogbookId = 0;
    }

    public search(resetPage: boolean) {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            if (resetPage) {
                $this.pageIndex = 1;
            }
            $this._search();
            $this.selectedRecords = [];
        }, environment().debounceTime);
    }

    private _search() {
        this.isLoading = true;
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }

        let objParam = {
            'cod_logbooks': 0,
            'term': this.searchTerm,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected),
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'ADMIN_LOGBOOKS_LISTING'
        };

        this._logbookService.getChecklistAllList(objParam).subscribe(data => {
            this.logbooksList = this._logbookService.getArrayContent(data);
            this._updateDownlodParams();
            let totalCount = this.logbooksList[0] ? this.logbooksList[0].full_count : 0;
            this.maxPage = Math.ceil(totalCount / this.resultsPerPageSelected);
            this.totalPages = this.pagination(this.pageIndex, this.maxPage);

            this.isLoading = false;
            this.showSuccessMessage = this.emitSuccessMessage;
            this.showErrorMessage = this.emitErrorMessage;
            this.emitSuccessMessage = null;
            this.emitErrorMessage = null;

            /* if (this.showSuccessMessage) {
                this._notify.success({text: 'GENERAL.SAVE_ROW', translate: true});
            } */

            if (this.showErrorMessage) {
                this._notify.error(this.errorMessage);
            }

        }, err => {
            this.isLoading = false;
            let e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err);
        });
    }

    private _updateDownlodParams() {

        let str_cod_diario_bordo = '';
        let str_nom_diario_bordo = '';
        let str_cod_area = '';
        let str_dsc_area = '';
        let str_subarea = '';
        let str_func_reg_ponto = '';//remover
        let str_checklist_type = 'Type';
        let str_cod_freq = '';
        let str_dsc_freq = '';
        let str_cod_rotina = '';
        let str_nom_rotina = '';
        let str_dsc_rotina = '';
        let int_order = '';
        let str_shift_collab = 'Collaborative';
        let str_shift_1 = 'Shift 1';
        let str_shift_2 = 'Shift 2';
        let str_shift_3 = 'Shift 3';

        this._translateService.get('LOGBOOK.COD_LOGBOOK').subscribe(translate => {
            str_cod_diario_bordo = translate;
            this._translateService.get('LOGBOOK.LOGBOOK_NAME').subscribe(translate => {
                str_nom_diario_bordo = translate;
                this._translateService.get('MEETINGS_REPORTS.COD_AREA').subscribe(translate => {
                    str_cod_area = translate;
                    this._translateService.get('LOGBOOK.DSC_AREA').subscribe(translate => {
                        str_dsc_area = translate;
                        this._translateService.get('LOGBOOK.SUBAREA').subscribe(translate => {
                            str_subarea = translate;
                            this._translateService.get('LOGBOOK.IS_CLOCKING').subscribe(translate => {//remover
                                str_func_reg_ponto = translate;
                                this._translateService.get('LOGBOOK.COD_PERIODICITY').subscribe(translate => {
                                    str_cod_freq = translate;
                                    this._translateService.get('LOGBOOK.DSC_FREQUENCY').subscribe(translate => {
                                        str_dsc_freq = translate;
                                        this._translateService.get('LOGBOOK.COD_ROUTINE').subscribe(translate => {
                                            str_cod_rotina = translate;
                                            this._translateService.get('LOGBOOK.STR_ROUTINE').subscribe(translate => {
                                                str_nom_rotina = translate;
                                                this._translateService.get('LOGBOOK.DSC_ROUTINE').subscribe(translate => {
                                                    str_dsc_rotina = translate;
                                                    this._translateService.get('LOGBOOK.ORDER').subscribe(translate => {
                                                        int_order = translate;
                                                        this.downloadCsvExample = {
                                                            cod_user: this._authService.getAuthenticatedUser().id,
                                                            api_url: this._reportsApiUrl,
                                                            str_proc_exec: 'generate_ex_blank_files',
                                                            arr_proc_params_search: [null],
                                                            arr_headers: [
                                                                str_nom_diario_bordo,
                                                                str_cod_area,
                                                                str_dsc_area,
                                                                str_subarea,
                                                                str_checklist_type,
                                                                str_cod_freq,
                                                                str_dsc_freq,
                                                                str_cod_rotina,
                                                                str_nom_rotina,
                                                                str_dsc_rotina,
                                                                int_order,
                                                                str_shift_collab,
                                                                str_shift_1,
                                                                str_shift_2,
                                                                str_shift_3
                                                              ],

                                                            arr_headers_2: [
                                                                'InteractionLog - Mouts Checklist Example',
                                                                '0',
                                                                'InteractionLog - Mouts Department Example',
                                                                '0',
                                                                'Functional',
                                                                '0',
                                                                'As Needed',
                                                                '00000',
                                                                'InteractionLog - Mouts Routine Example',
                                                                'It is a example to tamplate',
                                                                '1',
                                                                'Collaborative',
                                                                'Shift 1 - Mouts',
                                                                'Shift 2 - Mouts',
                                                                'Shift 3 - Mouts'
                                                            ],

                                                            name_of_file_to_download: 'template_import_logbooks_',
                                                            str_column_dynamic: ''
                                                        };

                                                        this.downloadCsvSetup = {
                                                            cod_user: this.codUser,
                                                            api_url: this._reportsApiUrl,
                                                            str_proc_exec: 'reports_ex_logbooks',
                                                            arr_proc_params_search: [
                                                                //this.searchTerm, //=> replaced search term by selected itens
                                                                '',
                                                                this._implodeValues(this.selectedRecords)
                                                            ],
                                                            arr_headers: [
                                                                //str_cod_diario_bordo,
                                                                str_nom_diario_bordo,
                                                                str_cod_area,
                                                                str_dsc_area,
                                                                str_subarea,
                                                                //str_func_reg_ponto,//remover, adicionar coluna type no lugar
                                                                str_checklist_type,
                                                                str_cod_freq,
                                                                str_dsc_freq,
                                                                str_cod_rotina,
                                                                str_nom_rotina,
                                                                str_dsc_rotina,
                                                                int_order,
                                                                str_shift_collab,
                                                                str_shift_1,
                                                                str_shift_2,
                                                                str_shift_3
                                                            ],
                                                            name_of_file_to_download: 'logbooks_',
                                                            str_column_dynamic: ''
                                                        };
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });

    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.selectedRecords = [];
        this.pageIndex = page;
        this.search(false);
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    private _implodeValues(objValues) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element;
            });
        }
        return ids;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
        this.bol_change_copy_name = false;
    }

    closeSuccessCopyMessage() {
        this.showError = false;
        this.errorMessage = '';
        this.successCopyMessage = '';
        this.bol_change_copy_name = false;
        this._search();
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => this._notify.error(translate));
    }

    private _showSuccessCopyMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this._notify.success(translate);
        });
    }

    async messageImported(response: any) {
        this.isLoadingFile = false;
        response = JSON.parse(response);
        if (response?.errors) {
            this.importError(JSON.stringify(response))
        } else {
            this._showSuccessCopyMessage('LOGBOOK.MESSAGE_IMPORTED_SUCCESS');
        }
        this.search(false);
    }
    findTerm(msg, part) {
        return msg.includes(part)
    }

    startLoading($event) {
        this.isLoadingFile = true;
    }
    _getImportErrorMessage(error: string): string {
        const self = this
        if(self.findTerm(error, 'COD_AREA')) return 'LOGBOOK.REQUIRED_CODE_AREA';
        if(self.findTerm(error, 'COD_SUBAREA')) return 'LOGBOOK.REQUIRED_SUBAREA';
        if(self.findTerm(error, 'COD_PERIODICITY')) return 'LOGBOOK.PERIODICITY_CODE_REQUIRED_MSG';
        if(self.findTerm(error, 'COD_ROUTINE')) return 'LOGBOOK.ROUTINE_CODE_REQUIRED_MSG';
        if(self.findTerm(error, 'INT_ORDER')) return 'LOGBOOK.REQUIRED_ORDER';
        if(self.findTerm(error, 'STR_NAME')) return 'LOGBOOK.LOGBOOK_NAME_REQUIRED_MSG';
        if(self.findTerm(error, 'STR_AREA')) return 'LOGBOOK.REQUIRED_AREA';
        if(self.findTerm(error, 'STR_PERIODICITY')) return 'LOGBOOK.PERIODICITY_REQUIRED_MSG';
        if(self.findTerm(error, 'STR_ROUTINE_NAME')) return 'LOGBOOK.ROUTINE_REQUIRED_MSG';
        if(self.findTerm(error, 'STR_ROUTINE_DESCRIPTION')) return 'LOGBOOK.ROUTINE_DESCRIPTION_REQUIRED_MSG';
        if(self.findTerm(error, 'STR_TYPE')) return 'LOGBOOK.REQUIRED_TYPE';
        return 'LOGBOOK.MESSAGE_IMPORTED_ERROR';
    }
    importError($event) {
        this.isLoadingFile = false;
        $event = JSON.stringify($event)
        if (!$event.includes('Row length does not match headers')) {
            const errorText = this._getImportErrorMessage($event);
            if (errorText != null)
                this._notify.error({ text: errorText, translate: true });
        } else {
            this._notify.error({ text: 'LOGBOOK.MESSAGE_IMPORTED_ERROR', translate: true });
        }
    }

    messageError($event) {
        this.isLoadingFile = false;

        let errorCode = null;
        let errorLine = null;

        if(!$event.includes('Row length does not match headers') ) {
            let errorMessage = $event.split('!'); //Error uploading file!COD_AREA-1
            let errorCodeTmp = errorMessage[1].split('-'); //COD_AREA-1
            errorCode = errorCodeTmp[0]; //COD_AREA
            errorLine = errorCodeTmp[1]; //1
        }    

        let errorMessageFinal = '';
        if (errorCode == 'COD_AREA') {
            this._translateService.get('LOGBOOK.REQUIRED_AREA').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }

        if (errorCode == 'COD_PERIODICITY') {
            this._translateService.get('LOGBOOK.PERIODICITY_REQUIRED_MSG').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }
        if (errorCode == 'COD_ROUTINE') {
            this._translateService.get('LOGBOOK.ROUTINE_REQUIRED_MSG').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }

        if (errorCode == 'STR_NAME') {
            this._translateService.get('LOGBOOK.LOGBOOK_NAME_REQUIRED_MSG').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }

        this._translateService.get('LOGBOOK.MESSAGE_IMPORTED_ERROR').subscribe(translate => {
            errorMessageFinal = translate + ' ' + errorMessageFinal;
        });

        this._showErrorMessage(errorMessageFinal);
    }

    toggleObjSelection($event, codLogbook) {
        let index;
        if ($event != 0) {
            index = this.selectedRecords.indexOf($event);
        }
        else {
            index = this.selectedRecords.indexOf(codLogbook);
        }

        if (index == -1) {
            this.selectedRecords.push($event);
        }
        else {
            this.selectedRecords.splice(index, 1);
        }

        this._updateDownlodParams();
    }

    toggleAll(event) {
        if (event) {
            let qty = this.logbooksList.length;
            for (let i = 0; i < qty; i++) {
                this.selectedRecords.push(this.logbooksList[i].cod_logbooks);
            }
        }
        else {
            this.selectedRecords = [];
        }
        this._updateDownlodParams();
    }

    isSelected(codLogbook) {
        return this.selectedRecords.indexOf(codLogbook) >= 0;
    }

    public copyLogbook() {
        if (this.selectedRecords.length > 1){
            this._showErrorMessage('LOGBOOK.SELECT_ONE_MESSAGE');
            return;
        }
        else{
            if (this.selectedRecords.length != 1){
                this._showErrorMessage('LOGBOOK.NO_LOGBOOK_SELECTED');
                return;
            }
        }

        this.getCopiedChecklist(this.selectedRecords[0]);
        this.bol_change_copy_name = true;
    }



    getCopiedChecklist(id) {
        let objParam = {'cod_logbooks': id, 'cod_user': this._authService.getAuthenticatedUser().id};
        this._logbookService.getSingleChecklist(objParam).subscribe(data => {
                this.copy_cod_logbook = id;
                this.logbook = data.content.results;
                this.copyName = `${this.logbook.str_name}_copy`;

                this.listAux = {
                    'worlds': data.content.results.arr_worlds,
                    'regionals': data.content.results.arr_regionals,
                    'locations': data.content.results.arr_location,
                    'subareas': JSON.parse(JSON.stringify(data.content.results.arr_subareas).split('"cod_subarea":').join('"cod_subareas":')),
                    'areas' : [{cod_area: data.content.results.cod_area}],
                    'type': [{bol_is_collab: data.content.results.bol_type_collab ? 1 : 0}]
                };
                // this._searchAll(this.listAux);
                this.isLoading = false;
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    saveCopy() {

        this.listAux.subareas = this.listAux.subareas.map(item => {
            return item.cod_subareas;
        });

        this.listAux.locations = this.listAux.locations.map(item => {
            return item.cod_location;
        });

        this.logbook.str_name = this.copyName;
        this.logbook.cod_inherit_logbook = this.copy_cod_logbook;	
        this.copy_cod_logbook = null;

        this.logbook.cod_profile = null;
        this.logbook.cod_function = null;
        this.logbook.cod_user = this._authService.getAuthenticatedUser().id;
        this.logbook.cod_subareas = this.listAux.subareas;
        this.logbook.bol_is_collab = this.logbook.bol_type_collab;
        this.logbook.cod_update_user = this._authService.getAuthenticatedUser().id;
        this.logbook.cod_locations = this.listAux.locations;
        this.logbook.cod_worlds = this.logbook.arr_worlds;
        this.logbook.cod_regionals = this.logbook.arr_regionals;

        const logbook_params = this._logbookService.getLogbookParams(this.logbook);

        this._logbookService.postLogbook(logbook_params).subscribe(res => {
            if (res.type == 'error') {
                this._showErrorMessage(res.content.message);
            } else {
                this._showSuccessCopyMessage('LOGBOOK.SUCCESS_COPY_MESSAGE');
                this.bol_change_copy_name = false;
                this.copyName = '';
            }
        }, err => {
            const e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err);
        }
    );
    }

    checkPrivilege(privilege){
        if(this._authService.getUserPrivilege(privilege)){
          return true;
        }
        return false;
    }

    hideMessage() {
        this.showSuccessMessage = false;
        this.showErrorMessage = false;
    }

}
