// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Component__Confirm__Backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.Component__Confirm__Container {
  width: 650px;
  margin: 0 auto;
  margin-top: 30vh;
  padding: 0.5em;
  background-color: #ffffff;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  color: #a9a9a9;
  text-align: center;
}

.Component__Confirm__Message {
  padding-top: 1em;
}

.Component__Confirm__Actions {
  padding: 1em 0;
  padding-top: 2em;
}

.Component__Confirm__Button {
  width: 150px;
  margin: 0 0.25em;
  padding: 0.5em;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;
  outline: none;
}

.Component__Confirm__Button.--Ok {
  background-color: #00c156;
}

.Component__Confirm__Button.--Cancel {
  background-color: #fe484f;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/confirm/confirm.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,oCAAoC;EACpC,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".Component__Confirm__Backdrop {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1000;\n}\n\n.Component__Confirm__Container {\n  width: 650px;\n  margin: 0 auto;\n  margin-top: 30vh;\n  padding: 0.5em;\n  background-color: #ffffff;\n  border: 2px solid #e8e8e8;\n  border-radius: 10px;\n  color: #a9a9a9;\n  text-align: center;\n}\n\n.Component__Confirm__Message {\n  padding-top: 1em;\n}\n\n.Component__Confirm__Actions {\n  padding: 1em 0;\n  padding-top: 2em;\n}\n\n.Component__Confirm__Button {\n  width: 150px;\n  margin: 0 0.25em;\n  padding: 0.5em;\n  color: #ffffff;\n  border-radius: 3px;\n  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;\n  outline: none;\n}\n\n.Component__Confirm__Button.--Ok {\n  background-color: #00c156;\n}\n\n.Component__Confirm__Button.--Cancel {\n  background-color: #fe484f;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
