import { Injectable } from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ProductsKpisService {

  constructor(private _mainService: MainService) {
  }

  getProductsKpisByYear(cod_meeting, int_year, cod_user): Observable<any> {
    const path = `/products_kpis/${cod_meeting}/${int_year}/${cod_user}`;
    return this._mainService.get(path);
  }
  getYears(cod_meeting, cod_unit, cod_line, cod_user): Observable<any> {
    const path = `/products_kpis/years/${cod_meeting}?cod_unit=${cod_unit}&cod_line=${cod_line}&cod_user=${cod_user}`;
    return this._mainService.get(path);
  }

  putKpiStatus(cod_kpi, bol_active, cod_user): Observable<any> {
    const path = `/products_kpis/${cod_kpi}/status`;
    return this._mainService.put(path, {bol_active, cod_user});
  }

  saveKpi(kpi: any): Observable<any> {
    const path = `/products_kpis/`;
    return this._mainService.post(path, {...kpi});
  }

  putKpi(cod_kpi, str_column_name, str_value, cod_user): Observable<any> {
    const path = `/products_kpis/${cod_kpi}/`;
    return this._mainService.put(path, {str_column_name, str_value, cod_user});
  }

  putKpiValue(cod_kpi, cod_indicator, int_year, int_month, str_type, num_value, cod_user): Observable<any> {
    const path = `/products_kpis/${cod_kpi}/${cod_indicator}/value`;
    return this._mainService.put(path, {int_year, int_month, str_type, num_value, cod_user});
  }

}
