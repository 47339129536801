import {Component, Input, SimpleChanges, OnChanges, OnInit} from '@angular/core';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';

@Component({
    standalone: true,
    selector: 'convert-date',
    templateUrl: './convert-date.component.html',
    styleUrls: ['./convert-date.component.css'],
})
export class ConvertDate implements OnChanges {

    @Input() date;
    @Input() type: string;
    public converted_date: string;
    public localDate: string;

    constructor(private readonly _userPreferencesService:UserPreferencesService) {}

    async ngOnChanges(changes: SimpleChanges) {
        
        await this.getDateFormat();
        await this.translateDate();
        await this.convertDate();
    }

    convertDate() {
        if (!this.date || typeof this.date.getMonth !== 'function') {
            if(this.date.length > 11 && this.date.length < 27){ 
                let dp = this.date.split('-');
                dp[0] = '+' + dp[0].padStart(6,'0');
                this.date = dp.join('-');
                this.date = this.date.split(' ').join('T'); //Adding T for compatibility with other browsers (Chrome Ipad) and changed from replaceAll to make it compatible with older chrome browsers                
            }
            this.date = new Date(this.date);
        }


        switch (this.localDate) {
            case 'dd/mm/yyyy':
                this.converted_date = (('0' + (this.date.getDate())).slice(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + this.date.getFullYear());
                break;
            case 'mm/dd/yyyy':
                this.converted_date = (('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + ('0' + (this.date.getDate())).slice(-2) + '/' + this.date.getFullYear());
                break;
            case 'dd/mm/yyyy hh:mm':
                this.converted_date = (('0' + (this.date.getDate())).slice(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + this.date.getFullYear() + ' ' +  ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2));
                break;
            case 'mm/dd/yyyy hh:mm':
                this.converted_date = (('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + ('0' + (this.date.getDate())).slice(-2) + '/' + this.date.getFullYear() + ' ' +  ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2));
                break;
            case 'dd/mm/yyyy hh:mm:ss':
                this.converted_date = (('0' + (this.date.getDate())).slice(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + this.date.getFullYear() + ' ' +  ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2) + ':' + ('0' + this.date.getSeconds()).slice(-2));
                break;
            case 'mm/dd/yyyy hh:mm:ss':
                this.converted_date = (('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + ('0' + (this.date.getDate())).slice(-2) + '/' + this.date.getFullYear() + ' ' +  ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2) + ':' + ('0' + this.date.getSeconds()).slice(-2));
                break;
            case 'hh:mm':
                this.converted_date = (( ('0' + (this.date.getHours())).slice(-2) + ':' + ('0' + (this.date.getMinutes())).slice(-2) ));
                break;
            case 'yyyy-mm-dd':
                this.converted_date = (('0' + (this.date.getDate())).slice(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + this.date.getFullYear());
                break;
            default:
                this.converted_date = (('0' + (this.date.getDate())).slice(-2) + '/' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '/' + this.date.getFullYear());
                break;
        }        
    }

    async getDateFormat() {
        this.localDate = this._userPreferencesService.getLocalDateFormat();
    }

    async translateDate() {
        // check date only

        if(this.localDate){        
            if (this.type === 'dd/mm/yyyy' || this.type === 'mm/dd/yyyy') {
                this.localDate = this.localDate.toLowerCase();
            }

            // check date and hour
            if (this.type === 'dd/mm/yyyy hh:mm' || this.type === 'mm/dd/yyyy hh:mm') {
                this.localDate = `${this.localDate.toLowerCase()} hh:mm`;
            }

            // check data, hour and seconds
            if (this.type === 'dd/mm/yyyy hh:mm:ss' || this.type === 'mm/dd/yyyy hh:mm:ss') {
                this.localDate = `${this.localDate.toLowerCase()} hh:mm:ss`;
            }
        }
        
        // only hour
        if (this.type === 'hh:mm') {
            this.localDate = 'hh:mm';
        }
        

    }

}
