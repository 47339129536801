import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../main/access/services/auth.service';

@Injectable()
export class UploadExcelService {

    // Set headers to handle the CORS
    private headers: HttpHeaders ;

    constructor(private _http: HttpClient, private _authService: AuthService) {

        const obj_user = _authService.getAuthenticatedUser();
        // Set headers to handle the CORS
        this.headers = new HttpHeaders(
        {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
            'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
            'Access-Control-Allow-Credentials': 'true',
            'X-Content-Type-Options': 'nosniff',            
            'X-Frame-Options': 'SAMEORIGIN',
            'Referrer-Policy': 'origin-when-cross-origin',
            'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
        
        }
    );
    }

    private _getHeaders(method: string) {
        this.headers = this.headers.set('Access-Control-Allow-Methods', method.toUpperCase());
        return this.headers;
    }

    /**
     * Upload request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    upload(path: string, params: Object): Observable<any> {
        const url = environment().uploadFilesApiUrl + path;
        const opt = {headers: this._getHeaders('POST')};
        return this._http.post(url, params, opt);
    }

    /**
     * POST request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    post(path: string, params: Object): Observable<any> {
        const url = environment().logbooksApiUrl + path;
        const opt = {headers: this._getHeaders('POST')};
        return this._http.post(url, params, opt);
    }
}
