import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy } from "@angular/core";
import { AuthService } from "app/main/access/services/auth.service";
import { MyFormsService } from "app/my-forms/services/my-forms.service";
import { NotificationService } from "app/main/services/notification.service";
import { UserPreferencesService } from "app/user-preferences/services/user-preferences.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-forms-details",
  templateUrl: "./forms-details.component.html",
  styleUrls: ["./forms-details.component.css"],
})
export class FormsDetailsComponent implements OnDestroy {
  lrpuId: number;
  arr_questions = [];
  formHeader: any = {
    str_username: "",
    str_form_name: "",
    str_routine_name: "",
    str_date_submitted: "",
    cod_routines: 0
  };
  bol_text: boolean = false;
  bol_send: boolean = false;

  cod_user = this._authService.getAuthenticatedUser().id;
  
  isCollab: boolean;
  isLoading: boolean;
  usedBackButton: boolean = false;

  str_feedback: string;

  errorMessage: string;
  showError: boolean;

  arr_feedback: any = [];

  bol_edit_feedback: boolean = false;

  cod_forms_feedback: number;
  cod_forms_collaborative_feedback: number;

  showDropdown = null;
  isEditing = null;

  str_new_feedback: string;

  bol_del: boolean = false;

  name = this._authService.getAuthenticatedUser().name

  showFormsBody: boolean = false;
  loader: string = "../../../../assets/images/animated/spinner-loader.svg"
  isLoadingFeedback:boolean = false;
  cod_owner: number;
  headers = []
  hasChangesPDF: boolean = false;
  downloadParams = {
    type: 'formPdf',
    colors: [],
    headers: [],
    cod_lrpu: 0,
    cod_user: 0,
    name: 'forms_'
  }
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _myFormsService: MyFormsService,
    private _notificationService: NotificationService,
    private _userPreferencesService: UserPreferencesService,
    private _translateService: TranslateService,
    private _notify: NotificationService,
  ) {
    this.lrpuId = +this._route.snapshot.paramMap.get("lrpuId");
    this.isCollab = this._route.toString().includes('collab');
    this._translateService.get("LOGBOOK.FORM").subscribe((translation) => {
      this.headers.push({
        label: 'form',
        value: translation
      })
      this._translateService.get("LOGBOOK.USER_NAME").subscribe((translation) => {
        this.headers.push({
          label: 'user_name',
          value: translation
        })
        this._translateService.get("LOGBOOK.ROUTINE").subscribe((translation) => {
          this.headers.push({
            label: 'routine',
            value: translation
          })
          this._translateService.get("GENERAL.SUBMITTED").subscribe((translation) => {
            this.headers.push({
              label: 'submitted',
              value: translation
            })
            this._translateService.get("DYNAMICFORMS.SHOW.COMPLETE_PERCENT").subscribe((translation) => {
              this.headers.push({
                label: 'complete_percentage',
                value: translation
              })
              this._translateService.get("DYNAMICFORMS.SHOW.NON_COMPLIANCE_PERCENT").subscribe((translation) => {
                this.headers.push({
                  label: 'compliance_percentage',
                  value: translation
                })
                this._translateService.get("MEETING.FILTER_COLUMN_PLACEHOLDER_ANSWER").subscribe((translation) => {
                  this.headers.push({
                    label: 'answer',
                    value: translation
                  })
                  this._translateService.get("DYNAMICFORMS.QUESTION").subscribe((translation) => {
                    this.headers.push({
                      label: 'question',
                      value: translation
                    })
                    this._translateService.get("MEETINGS_CONTAINER.ACTIONS").subscribe((translation) => {
                      this.headers.push({
                        label: 'actions',
                        value: translation
                      })
                      this._translateService.get("LOGBOOK.DESCRIPTION").subscribe((translation) => {
                        this.headers.push({
                          label: 'description',
                          value: translation
                        })
                        this._translateService.get("MEETING.ORDER_LABEL.owner").subscribe((translation) => {
                          this.headers.push({
                            label: 'owner',
                            value: translation
                          })
                          this._translateService.get("MEETING.ORDER_LABEL.date").subscribe((translation) => {
                            this.headers.push({
                              label: 'due_date',
                              value: translation
                            })
                            this._translateService.get("LOGBOOK.STATUS").subscribe((translation) => {
                              this.headers.push({
                                label: 'status',
                                value: translation
                              })
                              this._translateService.get("LOGBOOK.COMMENTS").subscribe((translation) => {
                                this.headers.push({
                                  label: 'comments',
                                  value: translation
                                })
                                this._translateService.get("DYNAMICFORMS.SHOW.COMMENT").subscribe((translation) => {
                                  this.headers.push({
                                    label: 'comment',
                                    value: translation
                                  })
                                  this._translateService.get("SALES_REPORT.FIELDS.NOTA").subscribe((translation) => {
                                    this.headers.push({
                                      label: 'feedback',
                                      value: translation
                                    })
                                    this._translateService.get("LOGBOOK.ROUTINE_ANSWERS").subscribe((translation) => {
                                      this.headers.push({
                                        label: 'answers',
                                        value: translation
                                      })
                                      this._translateService.get("GENERAL.SUBMITTED_ON").subscribe((translation) => {
                                        this.headers.push({
                                          label: 'submitted_on',
                                          value: translation
                                        })
                                        this._translateService.get("GENERAL.SUBMITTED_BY").subscribe((translation) => {
                                          this.headers.push({
                                            label: 'submitted_by',
                                            value: translation
                                          })
                                          this._translateService.get("GENERAL.NO_RESULTS").subscribe((translation) => {
                                            this.headers.push({
                                              label: 'no_results',
                                              value: translation
                                            })
                                            this.downloadParams.cod_lrpu = this.lrpuId;
                                            this.downloadParams.name += this.lrpuId + "_";
                                            this.downloadParams.cod_user = this.cod_user;
                                            this.downloadParams.type = this.isCollab ? 'formCollabPdf' : 'formPdf';
                                            this.downloadParams.headers = this.headers;
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

    this.getDetailsHeader();
  }

  ngOnDestroy(): void {
    if(!this.usedBackButton) {
      localStorage.setItem('shouldGetBackToLocationForms', 'false');
    }
  }

  ngOnInit() {
    this.getFeedbacks();
    this._translateService.onLangChange.subscribe(event => {
      this.formHeader.str_form_name = this._myFormsService.getNameByUserLanguage(event.lang, this.formHeader, 'str_form_name');
    }); 
    
  }

  questionsChanged(arrQuestions) {
    this.arr_questions = arrQuestions;
  }

  closeDetails() {
    this.usedBackButton = true;
    this._router.navigate(["/my-forms"]);
  }

  private getDetailsHeader() {
    this.isLoading = true;

    const getHeaderDetailsAPI = this.isCollab 
      ? this._myFormsService.getFormDetailsCollab({ cod_user: this.cod_user, cod_lrpu: this.lrpuId, dsc_privilege: 'FORMS_LOCATION_TAB'}) 
      : this._myFormsService.getFormDetails({ cod_user: this.cod_user, cod_lrpu: this.lrpuId, dsc_privilege: 'FORMS_LOCATION_TAB'});
    
    getHeaderDetailsAPI.subscribe(
      (data) => {
        if (data.code == 888) {
          this.formHeader = Array.isArray(data.content.results) ? data.content.results[0] : data.content.results;
          this.formHeader.str_form_name = this._myFormsService.getNameByUserLanguage(this._userPreferencesService.getLocalLanguage(), this.formHeader, 'str_form_name');
          this.isLoading = false;
          this.showFormsBody = true;
          this.cod_owner = data.content.results.cod_user;
        } else { 
          this._notificationService.error({
            text:  `${data.content == 'NO_PERMISSION' ? 'GENERAL.NO_PERMISSION' : 'ERROR.GENERIC_ERROR' }`.toString(),
            translate: true,
          });
          this.closeDetails();
        }
      },
      (err) => {
        this._notificationService.error({
          text: 'ERROR.GENERIC_ERROR',
          translate: true,
        });
        this.isLoading = false;
        this.closeDetails();
      }
    );
  
  }


  newFeedback(){
    if(this.bol_text == true){
      this.bol_text= false;
    } else{
      this.bol_text = true;
    }
  }

  sendFeedback(){
    this.showDropdown = false;
    if(this.cod_user == this.formHeader.cod_user){
      this.confirmSendFeedback(true);                
      return;
    } 
    this.bol_send = true;
  }

  revertFeedback() {
    this.str_feedback = "";
    this.isEditing = null;
    this.bol_edit_feedback = false;
    this.getFeedbacks();
  }

  editFeedback(str_new_feedback,cod_forms_feedback){
    this.bol_edit_feedback = true;
    this.str_new_feedback = str_new_feedback;
    this.cod_forms_feedback = cod_forms_feedback;
    this.cod_forms_collaborative_feedback = cod_forms_feedback;
  }

  editMessage(index) {
    this.isEditing = (this.isEditing === null) ? index : null;
    this.showDropdown = null;
    this.bol_edit_feedback = true;
}

confirmDeletion(i){
  let arr_feedback_tmp = this.arr_feedback[i];
  this.bol_del = true;
  this.showDropdown = null;
  this.str_new_feedback = arr_feedback_tmp.str_feedback;
  this.cod_forms_feedback = arr_feedback_tmp.cod_forms_feedback;
  this.cod_forms_collaborative_feedback = arr_feedback_tmp.cod_forms_collaborative_feedback;
}

  confirmSendFeedback(bolSend: boolean){        
    if(this.isCollab){

      let edit_collab_feedback;
      let cod_user_email;
      let new_str_feedback;
      let is_del;

      if(this.bol_send == true || bolSend){

        this.bol_edit_feedback = false;
        this.bol_del = false;
        edit_collab_feedback = 0;
        new_str_feedback = this.str_feedback;
        is_del = false;
        if(this.cod_user == this.formHeader.cod_user){
          cod_user_email = null;
        } else {
          cod_user_email = this.formHeader.cod_user;
        }
        if(new_str_feedback == ''){
          new_str_feedback = null
        }
      }else if(this.bol_edit_feedback == true){
        this.bol_send = false;
        this.bol_del = false;
        edit_collab_feedback = this.cod_forms_collaborative_feedback;
        cod_user_email = null;
        new_str_feedback = this.str_new_feedback;
        is_del = false;
        if(new_str_feedback == ''){
          new_str_feedback = null
        }
      }else if(this.bol_del == true){
        this.bol_edit_feedback = false;
        this.bol_send = false;
        edit_collab_feedback = this.cod_forms_collaborative_feedback;
        new_str_feedback = this.str_new_feedback;
        is_del = this.bol_del
      }

      let objParams = {
        cod_forms_collaborative_feedback: edit_collab_feedback,
        cod_user: this.cod_user,
        cod_lrpuc: this.lrpuId,
        str_feedback: new_str_feedback,
        is_deleted: is_del,
        cod_user_mail: cod_user_email
      };

      if(objParams.str_feedback == null || objParams.str_feedback == ''){
          this._notify.warning({text: 'FORM_MODULE.REQUIRED_FEEDBACK', translate: true});
          this.bol_send = false;
      }else {
        this._myFormsService.postCollabFeedback(objParams).subscribe(result =>{          
          this.str_feedback = "";
          this.isEditing = null;
        if(result.type == 'success'){
          if(this.bol_send == true || bolSend){
            this._notify.success({ text: 'FORM_MODULE.ADD_FEEDBACK_SUCCESS', translate: true });
            this.bol_send = false;
          }
          if(this.bol_edit_feedback == true){
            this._notify.success({ text: 'FORM_MODULE.UP_FEEDBACK_SUCCESS', translate: true });
            this.bol_edit_feedback = false;
          }
          if(this.bol_del == true){
            this._notify.success({ text: 'FORM_MODULE.DEL_FEEDBACK_SUCCESS', translate: true });
            this.bol_del = false;
          }
          this.str_feedback = "";
          this.bol_edit_feedback = false;
          this.bol_send = false;
          this.bol_del = false;
          this.showDropdown = false;
          this.getFeedbacks();
        }else{
          this._notificationService.error({
            text: 'ERROR.GENERIC_ERROR',
            translate: true,
          });
            this.bol_edit_feedback = false;
            this.bol_send = false;
            this.bol_del = false;
            this.showDropdown = false;
          this.getFeedbacks();
        }

      },err => {
        this._notificationService.error({
          text: 'ERROR.GENERIC_ERROR',
          translate: true,
        });
            this.bol_edit_feedback = false;
            this.bol_send = false;
            this.bol_del = false;
            this.showDropdown = false;
        this.getFeedbacks();
      });
    }

    } else {

      let edit_feedback;
      let cod_user_email;
      let new_str_feedback;
      let is_del;

      if(this.bol_send == true || bolSend){
        this.bol_edit_feedback = false;
        edit_feedback = 0;
        new_str_feedback = this.str_feedback;
        is_del = false;

        if(this.cod_user == this.formHeader.cod_user){
          cod_user_email = null;
        } else {
          cod_user_email = this.formHeader.cod_user;
        }

        if(new_str_feedback == ''){
          new_str_feedback = null
        }
      }else if(this.bol_edit_feedback == true){
        this.bol_send = false;
        edit_feedback = this.cod_forms_feedback;
        cod_user_email = null;
        new_str_feedback = this.str_new_feedback;
        is_del = false;
        if(new_str_feedback == ''){
          new_str_feedback = null
        }
      } else if(this.bol_del == true){
        this.bol_edit_feedback = false;
        this.bol_send = false;
        edit_feedback = this.cod_forms_feedback;
        is_del = this.bol_del;
        new_str_feedback = this.str_new_feedback;
      }

      let objParams = {
        cod_forms_feedback: edit_feedback,
        cod_user: this.cod_user,
        cod_lrpu: this.lrpuId,
        str_feedback: new_str_feedback,
        is_deleted: is_del,
        cod_user_mail: cod_user_email
      };

      if(objParams.str_feedback == null || objParams.str_feedback == ''){
        this._notify.warning({text: 'FORM_MODULE.REQUIRED_FEEDBACK', translate: true});
        this.bol_send = false;
      }else{
        this._myFormsService.postFeedback(objParams).subscribe(result =>{
          this.str_feedback = "";
          this.isEditing = null;
          if(result.type == 'success'){
            if(this.bol_send == true || bolSend){                          
              this._notify.success({ text: 'FORM_MODULE.ADD_FEEDBACK_SUCCESS', translate: true });
              this.bol_send = false;
            }
            if(this.bol_edit_feedback == true){
              this._notify.success({ text: 'FORM_MODULE.UP_FEEDBACK_SUCCESS', translate: true });
              this.bol_edit_feedback = false;
            }
            if(this.bol_del == true){
              this._notify.success({ text: 'FORM_MODULE.DEL_FEEDBACK_SUCCESS', translate: true });
              this.bol_del = false;
            }
            this.str_feedback = "";
            this.bol_edit_feedback = false;
            this.bol_send = false;
            this.bol_del = false;
            this.showDropdown = false;
            this.getFeedbacks();
          }else{
            this._notificationService.error({
              text: 'ERROR.GENERIC_ERROR',
              translate: true,
            });
            this.bol_edit_feedback = false;
            this.bol_send = false;
            this.bol_del = false;
            this.showDropdown = false;
            this.getFeedbacks();
          }
  
        },err => {
          this._notificationService.error({
            text: 'ERROR.GENERIC_ERROR',
            translate: true,
          });
            this.bol_edit_feedback = false;
            this.bol_send = false;
            this.bol_del = false;
            this.showDropdown = false;
        });
        this.getFeedbacks();
      }
    }
       
  }



  getFeedbacks(){
    if (this.isCollab) {
      this._myFormsService
      .getCollabFeedback({ cod_user: this.cod_user, cod_lrpuc: this.lrpuId })
      .subscribe(
        (data) => {
          if (data.code == 888) {
            this.arr_feedback =  Array.isArray(data.content.results) ? data.content.results : Array(data.content.results);
            this.isLoading = false;
          } else { 
            this._notificationService.error({
              text: 'ERROR.GENERIC_ERROR',
              translate: true,
            });
          }
        },
        (err) => {
          this._notificationService.error({
            text: 'ERROR.GENERIC_ERROR',
            translate: true,
          });
          this.isLoading = false;
        }
      );
    }else{
      this._myFormsService
      .getFeedback({ cod_user: this.cod_user, cod_lrpu: this.lrpuId })
      .subscribe(
        (data) => {
          if (data.code == 888) {
            this.arr_feedback =  Array.isArray(data.content.results) ? data.content.results : Array(data.content.results);
            this.isLoading = false;
          } else { 
            this._notificationService.error({
              text: 'ERROR.GENERIC_ERROR',
              translate: true,
            });
          }
        },
        (err) => {
          this._notificationService.error({
            text: 'ERROR.GENERIC_ERROR',
            translate: true,
          });
          this.isLoading = false;
        }
        
      );

    }
  }

  showMessageOptions(index) {
    this.showDropdown = (this.showDropdown === null) ? index : null;
}

}
