import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../../admin/services/users.service';
import { AuthService } from '../../main/access/services/auth.service';
import { ValidationsService } from '../services/validations.service';
import { Trigger } from '../model/trigger.model';
import { TriggersService } from '../services/triggers.service';
import { SubareaService } from '../../logbooks/services/subarea.service';
import { NotificationService } from '../../main/services/notification.service';

@Component({
  selector: 'app-trigger-persist',
  templateUrl: './trigger-persist.component.html',
  styleUrls: ['./trigger-persist.component.css']
})
export class TriggerPersistComponent implements OnInit {

  @Input('codTrigger') id;
  @Output() backEvent = new EventEmitter<Object>();

  trigger: Trigger;
  isLoading: boolean = false;
  paramsSelected: any = [];
  worldsList: any = [];
  regionalsList: any = [];
  locationsList: any = [];
  showError: boolean = false;
  errorMessage: string;
  showEditTranslations: boolean = false;
  editTrigger: Trigger;
  areasList: any = [];
  subareasList: any = [];
  loadingConfig: any = [];
  categoryList: any = [];
  cod_user: any;
  emitEditSuccessMessage: boolean;

  constructor(private readonly _authService: AuthService,
    private readonly _translateService: TranslateService,
    private readonly _validationService: ValidationsService,
    private readonly _usersGroupPrivilegesService: UsersService,
    private readonly _triggerService: TriggersService,
    private readonly _subAreasService: SubareaService,
    private readonly _notify: NotificationService
  ) {
    this.showError = false;
    this.errorMessage = '';
    const user = this._authService.getAuthenticatedUser();
    this.cod_user = user.id;
    this._loadWorlds(null);
    this._loadCategory(null);
  }

  ngOnInit() {
    this.loadingConfig['worlds'] = false;
    this.loadingConfig['regionals'] = false;
    this.loadingConfig['locations'] = false;
    this.loadingConfig['areas'] = false;
    this.loadingConfig['subareas'] = false;
    this.loadingConfig['category'] = false;
    this.getSingleTrigger();

    this._translateService.onLangChange.subscribe(() => this._loadCategory(null));
  }

  getSingleTrigger() {
    this.isLoading = true;
    if (this.id == 0) {
      this.trigger = new Trigger();
      this.isLoading = false;
    }
    else {
      let params = {
        'cod_user': this.cod_user,
        'cod_trigger': this.id
      }

      this._triggerService.getSingleTrigger(params).subscribe(data => {
        if (data.code === 888 && data.content.code === 888 && data.content.length>0) {
          this.trigger = data.content.results[0];
          this.isLoading = false;
          this._getAll(this.trigger);
        }
        else {
          this.isLoading = false; 
          this._showErrorMessage(data.content.message);
          this.backSelected(false);
        }
      },err =>{
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      });
    }
  }

  private _handleSaveValidation(message: string): void {
    this._translateService.get(message).subscribe(translate => {
      this._notify.warning(translate);
    });
  }

  isValid(trigger) {
    if (trigger.str_name === undefined || trigger.str_name === "") {
      this._handleSaveValidation('LOGBOOK.REQUIRED_STR_NAME');
      return false;
    }
    if (trigger.arr_locations.length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_LOCATION');
      return false;
    }
    if (trigger.arr_areas.length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_AREA');
      return false;
    }
    if (trigger.arr_subareas.length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_SUBAREA');
      return false;
    }
    if (trigger.cod_category <= 0 || trigger.cod_category === undefined || trigger.cod_category === "") {
      this._handleSaveValidation('5WHY.REQUIRED_CATEGORY');
      return false;
    }
    return true;
  }

  saveTrigger() {
    this.trigger.arr_locations = JSON.parse("[" + this.paramsSelected['locations'] + "]");
    this.trigger.arr_areas = JSON.parse("[" + this.paramsSelected['areas'] + "]");
    this.trigger.arr_subareas = JSON.parse("[" + this.paramsSelected['subareas'] + "]");
    this.trigger.cod_category = this.paramsSelected['category'];
    this._validationService.boolIsLoading = true;
    let params = JSON.parse(JSON.stringify(this.trigger));
    params.cod_user = this.cod_user;

    if (!this.isValid(params)) {
      this._validationService.boolIsLoading = false;
      return;
    }

    if (this.id == 0) {
      this._triggerService.insertTrigger(params).subscribe(data => {
        if (data.code === 888 && data.content.code === 888) {
          this._validationService.boolIsLoading = false;
          this._notify.success({text: '5WHY.TRIGGER_INSERTED', translate: true});
          this.backSelected(true);
        }
        else {
          this._validationService.boolIsLoading = false;
          this._showErrorMessage(data.content.message);
        }
      }, err => {
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      });
    }
    else {
      this._triggerService.updateTrigger(params).subscribe(data => {
        if (data.code === 888 && data.content.code === 888) {
          this._validationService.boolIsLoading = false;
          this._notify.success({text: '5WHY.TRIGGER_EDITED', translate: true});
          this.backSelected(true);
        }
        else {
          this._validationService.boolIsLoading = false;
          this._showErrorMessage(data.content.message);
        }
      }, err => {
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      });
    }
  }

  private _getAll(params) {
    const $this = this;

    $this.paramsSelected['worlds_selected'] = params.arr_worlds;
    $this.paramsSelected['worlds'] = this._implodeValues(params.arr_worlds, 'cod_locations_world');
    $this._loadRegionals(() => {
      $this.paramsSelected['regionals_selected'] = params.arr_regionals;
      $this.paramsSelected['regionals'] = this._implodeValues(params.arr_regionals, 'cod_locations_regional');
      $this._loadLocations(() => {
        $this.paramsSelected['locations_selected'] = params.arr_locations;
        $this.paramsSelected['locations'] = this._implodeValues(params.arr_locations, 'cod_location');
        $this._loadAreas(() => {
          $this.paramsSelected['areas_selected'] = params.arr_areas;
          $this.paramsSelected['areas'] = this._implodeValues(params.arr_areas, 'cod_area');
          $this._loadSubAreas(() => {
            $this.paramsSelected['subareas_selected'] = params.arr_subareas;
            $this.paramsSelected['subareas'] = this._implodeValues(params.arr_subareas, 'cod_subareas');
            $this._loadCategory(() => {
              $this.paramsSelected['category_selected'] = [{ cod_category: params.cod_category }];
              $this.paramsSelected['category'] = params.cod_category;
            });
          });
        });
      });
    });

  }

  private _loadWorlds(fn) {
    this._clearWorldList();
    this._setLoading('worlds', true);
    this._usersGroupPrivilegesService.getWorlds(this.cod_user, 'ADMIN_CHANGE_TRIGGER').subscribe(data => {
      this.worldsList = this._usersGroupPrivilegesService.getArrayContent(data);
      this._setLoading('worlds', false);
      if (typeof fn != typeof undefined && fn) {
        fn();
      }
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
  }

  private _loadRegionals(fn) {
    this._clearRegionalList();

    if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
      let params = this.paramsSelected['worlds'].toString().split(',');
      this._setLoading('regionals', true);
      this._usersGroupPrivilegesService.getRegionals(params, this.cod_user, 'ADMIN_CHANGE_TRIGGER').subscribe(data => {
        this.regionalsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('regionals', false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      },
        err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      );
    }
  }

  private _loadLocations(fn) {

    if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
      let params = this.paramsSelected['regionals'].toString().split(',');
      this._setLoading('locations', true);
      this._usersGroupPrivilegesService.getLocations(params, this.cod_user, 'ADMIN_CHANGE_TRIGGER').subscribe(data => {
        this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('locations', false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearLocationsList();
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearLocationsList();
    }

  }

  private _loadAreas(fn) {
    
    if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
      this._setLoading('areas', true);
      let params = {
        arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
        cod_user: this.cod_user,
        dsc_page_privilege: 'ADMIN_CHANGE_TRIGGER'
      };

      this._usersGroupPrivilegesService.getAreasByLocation(params).subscribe(data => {
        this.areasList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('areas', false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearAreasList();
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearAreasList();
    }

  }

  private _loadSubAreas(fn) {

    if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '') {
      this._setLoading('subareas', true);
      let params = {
        arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
        arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
        cod_user: this._authService.getAuthenticatedUser().id
      };
      this._subAreasService.searchSubareaByAreaLoction(params).subscribe(data => {
        this.subareasList = this._subAreasService.getArrayContent(data);
        this._setLoading('subareas', false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearSubareasList();  
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearSubareasList();
    }

  }

  private _loadCategory(fn) {
    this._clearCategoryList();
    this._setLoading('category', true);
    this._triggerService.listCategories({ cod_user: this.cod_user }).subscribe(data => {
      this.categoryList = this._triggerService.getArrayContent(data);
      this._setLoading('category', false);
      if (typeof fn != typeof undefined && fn) {
        fn();
      }
    }, err => {
      this._authService.errCheck(err);
      console.log(err);
    });
  }

  private _clearWorldList() {
    this.paramsSelected['worlds'] = '';
    this.paramsSelected['worlds_selected'] = [];
    this._clearRegionalList();
  }

  private _clearRegionalList() {
    this.paramsSelected['regionals'] = '';
    this.paramsSelected['regionals_selected'] = [];
    this.regionalsList = [];
    this._clearLocationsList();
  }

  private _clearLocationsList() {
    this.paramsSelected['locations'] = '';
    this.paramsSelected['locations_selected'] = [];
    this.locationsList = [];
    this._clearAreasList();
  }

  private _smartClearLocationsList() {

    if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
        this.locationsList.map((location) => {
          if (location.cod_location == selected.cod_location) arr.push(location);
        });
      });
      this.paramsSelected['locations_selected'] = arr;
      this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
    }
    if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
      this.paramsSelected['locations_selected'] = [];
      this.paramsSelected['locations'] = '';
      this._clearAreasList();
    } else {
      this._loadAreas(null);
    }

  }

  private _clearAreasList() {
    this.paramsSelected['areas'] = '';
    this.paramsSelected['areas_selected'] = [];
    this.areasList = [];
    this._clearSubareasList();
  }

  private _smartClearAreasList() {

    if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
        this.areasList.map((area) => {
          if (area.cod_area == selected.cod_area) arr.push(area);
        });
      });
      this.paramsSelected['areas_selected'] = arr;
      this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
    }
    if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
      this.paramsSelected['areas_selected'] = [];
      this.paramsSelected['areas'] = '';
      this._clearSubareasList();
    } else {
      this._loadSubAreas(null);
    }

  }

  private _clearSubareasList() {
    this.paramsSelected['subareas'] = '';
    this.paramsSelected['subareas_selected'] = [];
    this.subareasList = [];
  }

  private _smartClearSubareasList() {

    if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
        this.subareasList.map((subarea) => {
          if (subarea.cod_subareas == selected.cod_subareas) arr.push(subarea);
        });
      });
      this.paramsSelected['subareas_selected'] = arr;
      this.paramsSelected['subareas'] = this._implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
    }
    if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
      this.paramsSelected['subareas_selected'] = [];
      this.paramsSelected['subareas'] = '';
    }
    
  }

  private _clearCategoryList() {
    this.paramsSelected['category'] = '';
    this.paramsSelected['category_selected'] = [];
    this.categoryList = [];
  }


  selectWorlds($event) {
    this.paramsSelected['worlds_selected'] = $event.selectedOptions;
    this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
    this._loadRegionals(null);
  }

  selectRegionals($event) {
    this.paramsSelected['regionals_selected'] = $event.selectedOptions;
    this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
    this._loadLocations(null);
  }

  selectLocations($event) {
    this.paramsSelected['locations_selected'] = $event.selectedOptions;
    this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
    this._loadAreas(null);
  }

  selectAreas($event) {
    this.paramsSelected['areas_selected'] = $event.selectedOptions;
    this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
    this._loadSubAreas(null);
  }

  selectSubareas($event) {
    this.paramsSelected['subareas_selected'] = $event.selectedOptions;
    this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
  }

  selectCategory($event) {
    this.paramsSelected['category_selected'] = $event.selectedOptions;
    this.paramsSelected['category'] = this._implodeValues($event.selectedOptions, 'cod_category');
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  cancelEdition() {
    this.trigger = JSON.parse(JSON.stringify(this.editTrigger));
    this.showEditTranslations = false;
  }

  editTranslations() {
    this.editTrigger = JSON.parse(JSON.stringify(this.trigger));
    this.showEditTranslations = true;
  }

  saveTranslations() {
    this.showEditTranslations = false;
  }

  backSelected(success: boolean = false) {
    this._validationService.boolSubmit = false;
    this._validationService.boolIsLoading = false;
    if (success) {
      this.id = -1;
    } else {
      this.id = 0;
    }
    this.trigger = new Trigger();
    this.backEvent.emit(this.id);
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  public activeTrigger(cod_trigger) {
    let objParam = {
      'cod_trigger': cod_trigger,
      'cod_user': this._authService.getAuthenticatedUser().id
    };
    this._triggerService.deleteTrigger(objParam).subscribe(data => {
      this.emitEditSuccessMessage = true;
      this._notify.success({text: '5WHY.TRIGGER_ACTIVATED', translate: true});
      this.getSingleTrigger();
    }, err => {
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    }
    );
  }

}

