export class Whys {
    cod_why: number;
    cod_fivewhys: number;
    cod_user_comment: number;
    str_evidence: string;
    str_why_description: string;
    str_comment: string;
    str_name_user_comment: string;
    bol_root_cause_found: boolean;
    bol_good_rating: boolean;
    
    cod_usu_ins: string;
    dat_ins: string;
    dat_alt: string;
    dat_del: string;
    dat_comment: string;
    
    //virtual
    str_name_user_last_update: string;
    dat_last_alt: string;
    attachments: any;

    constructor() {
        this.cod_why = 0;
        this.cod_user_comment = 0;
        this.str_evidence = '';
        this.str_why_description = '';
        this.str_comment =  '';
        this.str_name_user_comment = '';
        this.bol_root_cause_found = false;
        this.bol_good_rating = false;
        this.dat_ins = '';
        this.dat_alt = '';
        this.dat_del = '';
        this.dat_comment = '';
        this.attachments = [];
    }
}
