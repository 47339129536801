import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class EquipmentService {

    constructor(private _mainService: MainService) {
    }

    // getEquipmentsBySubArea(param): Observable<any> {
    //     let path = '/equipments-by-sub-areas/' + param.cod_subareas;
    //     return this._mainService.get(path);
    // }

    getList(params): Observable<any> {
        let path;
        if (params.cod_equipments > 0) {
            path = '/equipment/' + params.cod_equipments + '/' + params.cod_user;
            return this._mainService.get(path);
        } else {
            path = '/equipments';
            return this._mainService.post(path, params);
        }
    }

    post(params): Observable<any> {
        let str_path = '/equipment';
        return this._mainService.post(str_path, params);
    }

    put(params): Observable<any> {
        let str_path = '/equipment';
        return this._mainService.put(str_path, params);
    }

    delete(params) {
        let str_path = '/equipment/' + params.cod_equipments + '/' + params.cod_user;
        return this._mainService.delete(str_path);
    }

    getEquipmentsBySubAreaAndLocations(param): Observable<any> {
        let path = '/equipments-by-sub-areas-locations/' + param.cod_subareas + '/' + param.cod_locations + '/' + param.cod_user;
        return this._mainService.get(path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }
}
