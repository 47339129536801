import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActionsService} from '../../meetings/services/actions.service';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'chart-meeting',
  templateUrl: './chart-meeting.component.html',
  styleUrls: ['./chart-meeting.component.css']
})
export class ChartMeetingComponent implements OnInit, OnChanges {
  @Input() date;
  @Input() cod_meeting;
  @Input() ind_reload_chart;

  chart_options: any;
  chart: any;
  chart_series = [];
  chart_data = [];
  options: Object;
  bol_loading: boolean;
  translation;

  constructor(private _actionsService: ActionsService, private _authService: AuthService, private _translateService: TranslateService) {
    this.bol_loading = true;

    this._translateService.get('MEETINGS_CONTAINER').subscribe(result => {
        this.translation = result;

        this.chart_series = [{
          enableMouseTracking: false,
          type: 'area',
          name: 'Não iniciada',
          marker: {
            enabled: true,
            symbol: 'ball',
            lineColor: null
          },
          color: '#949494',
          data: []
        },
        {
          enableMouseTracking: false,
          type: 'area',
          name: 'Em andamento',
          marker: {
            enabled: true,
            symbol: 'ball',
            lineColor: null
          },
          color: '#fdb849',
          data: []
        },
        {
          enableMouseTracking: false,
          type: 'area',
          name: 'Atrasada',
          marker: {
            enabled: true,
            symbol: 'ball',
            lineColor: null
          },
          color: '#fc4e53',
          data: []
        }
      ];

      this.chart_options = {
        title: {
          text: ``,
        },
        grid: {
          height: 70,
          left: '25%',
          right: '0%',
        },
        xAxis: {
          type: 'category',
          data: [],
          minInterval: 1,
        },
        yAxis: {
          offset: 0,
          alignWithLabel: true,
          name: this.translation['LABELACTIONS'],
          nameLocation: 'middle',
          nameGap: 30,
          minInterval: 1,
        },
      };

    });
  }

  updateInfoChart() {
    if(this.cod_meeting) {
      this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
      this._actionsService.getCountActionsMeetingEvolution(this.date, this.cod_meeting, this._authService.getAuthenticatedUser().id).subscribe(
        data => {
          if (data.code === 888 && data.content.code === 888 ) {
            let dataParam = [];
            if(data.content.results.v_array_return){
              data.content.results.v_array_return.forEach(element => {
                if(element.cod_actions_status == 1 || element.cod_actions_status == 2 || element.cod_actions_status == 8){
                  let color =
                    ((element.cod_actions_status == 1) ? '#949494' :
                      ((element.cod_actions_status == 2) ? '#fdb849' :
                        ((element.cod_actions_status == 8) ? '#fc4e53' : undefined)));
                  let counter = parseInt(element.counter) < 0 ? 0 : parseInt(element.counter);
                  dataParam.push({
                    name: element.date, 
                    y: counter, 
                    color: color, 
                    marker: 'square', 
                    cod_actions_status: element.cod_actions_status
                  });
                }
              });
            }
            this.updateSeries(dataParam);
          } else {
          }
        },
        err => { this._authService.errCheck(err); console.log(err); }
        
      );
    }
  }

  updateSeries(data) {
    if(data){
      this.chart_data = [];
      this.chart_data[0] = [];
      this.chart_data[1] = [];
      this.chart_data[2] = [];
      let iCount = 0;
      let date = '';
      data.forEach(element => {
        
        element.y = element.y < 0 ? 0 : element.y
        
        if (element.cod_actions_status == 1) {
          this.chart_data[0].push(element)
        }
        else if (element.cod_actions_status == 2) {
          this.chart_data[1].push(element)
        }
        else if (element.cod_actions_status == 8) {
          this.chart_data[2].push(element)
        }
      });
      this.chart_series[0].data = this.chart_data[0].reverse();
      this.chart_series[1].data = this.chart_data[1].reverse();
      this.chart_series[2].data = this.chart_data[2].reverse();
    }

    const series_data = this.chart_series.map(i => {
      return {
        color: i.color,
        data: i.data.map(j => j.y),
        type: "line",
      }
    });

    const xAxis_data = this.chart_data.reduce((p, i) => {
      return [...new Set([...p, ...i.map(x => x.name)])];
    }, []);

    if (this.chart) {

      this.chart.setOption({
        xAxis: {
          data: xAxis_data,
        },
        series: series_data,
      });
      
    }
    
    this.bol_loading = false;
  }

  ngOnInit(){
    this.updateInfoChart();
  }

  ngOnChanges(changes: SimpleChanges):void {
    if (this.ind_reload_chart !== undefined){
      this.updateInfoChart();
    }
    
  }

  saveInstance(chartInstance) {
    this.chart = chartInstance;
    this.updateInfoChart();
  }
}