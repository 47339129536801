import {EventEmitter, Injectable} from '@angular/core';
import { UpdateIndividualCollaborativeRoutinesRequest } from '../../cockpit/model/update-individual-collaborative-routines-request.model';
import {Observable} from 'rxjs';

import {MainService} from './main.service';

@Injectable()
export class RoutinesService {
    static selectedRoutine = new EventEmitter<any>();
    private taskSelected: any = null;

    static STATUS_NOT_DONE:number = 1;
    static STATUS_DONE:number = 2;
    static STATUS_PENDING:number = 3;
    static STATUS_AVAIABLE:number = 4;
    static STATUS_NOT_AVAIABLE:number = 5;

    constructor(private _mainService: MainService) {

    }

    setTaskSelected(taskSelected): void {
        this.taskSelected = taskSelected;
    }

    getTaskSelected() {
        return this.taskSelected;
    }

    getStatusButtonClass2(buttonStatus: number, routine_status: number, isAsNeeded: boolean): string {
        switch(buttonStatus) {
            case RoutinesService.STATUS_NOT_DONE:
                return 'btn-status-not-done';           
            case RoutinesService.STATUS_DONE:
                if (routine_status == RoutinesService.STATUS_DONE && !isAsNeeded)
                    return 'btn-status-realized';          
                else
                    return '';
            case RoutinesService.STATUS_PENDING:
                return 'btn-status-checked';
            case RoutinesService.STATUS_AVAIABLE:
            case RoutinesService.STATUS_NOT_AVAIABLE:
        }

        return '';
    }

    getStatusButtonClass(buttonStatus, routine): string {
        switch(parseInt(buttonStatus)) {
            case RoutinesService.STATUS_NOT_DONE:
                return 'switch-field-label-not-done';
            case RoutinesService.STATUS_DONE:
                if (routine.cod_routine_status == RoutinesService.STATUS_DONE)
                    return 'switch-field-label-realized';
                else
                    return '';
            case RoutinesService.STATUS_PENDING:
                return 'switch-field-label-checked';
            case RoutinesService.STATUS_AVAIABLE:
            case RoutinesService.STATUS_NOT_AVAIABLE:
        }

        return '';
    }

    getFuncionalRoutinesList(params) {
        const path = '/get-functional-routines-list';
        return this._mainService.post(path, params);
    }

    getCollaborativeRoutinesList(params) {
        const path = '/get-collaborative-routines-list';
        return this._mainService.post(path, params);
    }
    
    getRoutineList(params): Observable<any> {
        let path;
        if (params.cod_routine > 0) {
            path = '/routine/' + params.cod_routine + '/' + params.cod_user;
            return this._mainService.get(path);
        }
        else {
            path = '/routines';
            return this._mainService.post(path, params);
        }
    }

    getRoutinesGroupByPeriodicity(params) {
        let path = '/get-routines-group-by-periodicity';
        return this._mainService.post(path, params);
    }

    getCountRoutines(cod_user, date): Observable<any> {
        let str_path = '/routine-cont-by-periodicity/' + cod_user + '/' + date;
        return this._mainService.get(str_path);
    }

    getCountRoutinesWeek(cod_user): Observable<any> {
        let str_path = '/routines-count-week/' + cod_user;
        return this._mainService.get(str_path);
    }

    getRoutines(cod_user, cod_periodicity, date): Observable<any> {
        let str_path = '/routine-by-periodicity/' + cod_user + '/' + cod_periodicity + '/' + date;
        return this._mainService.get(str_path);
    }

    getByLogbook(params): Observable<any> {
        let str_path = '/logbook-routines';
        return this._mainService.post(str_path, params);
    }

    getByLogbookGrouped(params): Observable<any> {
        let str_path = '/logbook-routines-grouped';
        return this._mainService.post(str_path, params);
    }

    postRoutine(params): Observable<any> {
        let str_path = '/routine';
        return this._mainService.post(str_path, params);
    }

    putRoutine(params): Observable<any> {
        let str_path = '/routine';
        return this._mainService.put(str_path, params);
    }

    deleteRoutine(params) {
        let str_path = '/routine/' + params.cod_routine + '/' + params.cod_user;
        return this._mainService.delete(str_path);
    }

    getAttachments(params) {
        let str_path = '/routine/' + params.cod_logbook_routines_periodicity_users + '/attachments/' + params.cod_user;
        return this._mainService.get(str_path);
    }

    getLogbookRoutineDetail(params) {
        let str_path = '/routine/' + params.cod_logbook_routines_periodicity_users + '/detail/' + params.cod_user;
        return this._mainService.get(str_path);
    }

    getCollabAttachments(params) {
        let str_path = '/routine-collab/' + params.cod_logbook_routines_periodicity_users_collaborative + '/attachments/' + params.cod_user;
        return this._mainService.get(str_path);
    }

    getLogbookRoutineCollabDetail(params) {
        let str_path = '/routine-collab/' + params.cod_logbook_routines_periodicity_users_collaborative + '/detail/' + params.cod_user;
        return this._mainService.get(str_path);
    }
    
    postMeetingsReports(searchParams) {
        const path = '/createReports';
        return this._mainService.postReport(path, searchParams);
    }

    updateFuncionalRoutineForm(params): Observable<any> {
        const path = "/update-routine-periodicity-functional-form";
        return this._mainService.post(path, params);
    }

    updateCollaborativeRoutineForm(params): Observable<any> {
        const path = "/update-routine-periodicity-collaborative-form";
        return this._mainService.post(path, params);
    }

    listRoutinesCollab(params): Observable<any>{
        let path = '/list-routines-collab-app';
        return this._mainService.post(path, params);
    }

    countRoutinesCollab(params): Observable<any>{
        let path = '/count-routines-collab';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getPillars(params) {
        const path = '/pillars';
        return this._mainService.post(path, params);
    }

    getOrInsertRoutinePeriodicityCollab(params): Observable<any>{
        const path = '/get-insert-routines-periodicity-collab';
        return this._mainService.post(path, params);
    }

    listAllRoutineStatusCollaborative(params): Observable<any>{
        const path = '/list-all-routine-status-collab';
        return this._mainService.post(path, params);
    }

    updateRoutineStatusCollaborative(params): Observable<any>{
        const path = '/update-routine-status-collab';
        return this._mainService.post(path, params);
    }

    getRoutinesHistoryCollaborative(params): Observable<any> {
        let path = '/routine-history-collab';
        return this._mainService.post(path, params);
    }
    
    getFormsAnswersByTokenCollaborative(params): Observable<any> {
        let path = '/list-answers-collab-by-token'
        return this._mainService.post(path, params);
    }

    getFormsActionsByTokenCollaborative(params): Observable<any> {
        let path = '/list-forms-actions-collab-by-token'
        return this._mainService.post(path, params);
    }
    
    getFormCollab(params): Observable<any>{
        const path = '/find-form-collaborative';
        return this._mainService.post(path, params);
    }

    getFormByPeriodicityCollab(cod_periodicity: number, cod_user: number): Observable<any> {
        let path = '/find-form-collaborative-periodicity';
        return this._mainService.post(path, {
           'cod_logbook_routines_periodicity_users_collaborative' : cod_periodicity,
           'cod_user' : cod_user
        });
    }

    answerFormsCollab(params): Observable<any>{
        const path = '/answer-form-collab';
        return this._mainService.post(path, params);
    }

    listRoutineCommentCollab(params): Observable<any>{
        const path = '/list-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    insertRoutineCommentCollab(params): Observable<any>{
        const path = '/insert-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    updateRoutineCommentCollab(params): Observable<any>{
        const path = '/update-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    deleteRoutineCommentCollab(params): Observable<any>{
        const path = '/delete-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    updateIndividualCollaborativeRoutines(params: UpdateIndividualCollaborativeRoutinesRequest): Observable<any> {
        const path = '/update-individual-collaborative-routines';
        return this._mainService.post(path, params);
    }

    filterRoutines(arr_filters, arr_routines) {

        let arr_return = arr_routines.map(obj => ({...obj}));

        arr_filters.forEach(filter => {

            switch (filter.type) {

                case 'description': 
                    arr_return = arr_return.map(periodicity => {
                        periodicity.routines = periodicity.routines.filter(routine => 
                            (routine.str_description ? routine.str_description.toLowerCase().includes(filter.value ? filter.value.toLowerCase() : '') : false)
                            || (routine.str_name ? routine.str_name.toLowerCase().includes(filter.value ? filter.value.toLowerCase() : '') : false)
                        );
                        return periodicity;
                    });
                break;

                case 'date':
                    if (filter.value !== '' && filter.value2 != '') {
                        arr_return = arr_return.map(periodicity => {
                            periodicity.routines = periodicity.routines.filter(routine => {
                                const date2 = new Date(filter.value2);
                                date2.setDate(date2.getDate() + 1);
                                return ((new Date(routine.date_limit) > new Date(filter.value)) && (new Date(routine.date_limit) < date2));
                            });
                            return periodicity;                            
                        });
                    }
                break;

            }

        });

        arr_return = arr_return.map(periodicity => {    
            periodicity.total_routines = periodicity.routines.length;
            periodicity.total_executed = periodicity.routines.filter(routine => routine.cod_routine_status == 2).length;  
            return periodicity;               
        });

        return arr_return;
    }

}