import { EChartsOption } from "echarts";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-repeating-problem-chart",
  templateUrl: "./repeating-problem-chart.component.html",
  styleUrls: ["./repeating-problem-chart.component.css"],
})
export class RepeatingProblemChartComponent implements OnInit {
  chartOptions: any;

  @Input("data") data: any;
  @Input("isLoading") isLoading: boolean;

  hoursTranslate: string;
  weeksTranslate: string;
  echartsInstance: any;
  chartTitleTranslation: string;

  constructor(private _translateService: TranslateService) {
    this._translateService.get("GENERAL.DAYS").subscribe((translation) => {
      this.hoursTranslate = translation;
    });
    this._translateService.get("GENERAL.WEEK").subscribe((translation) => {
      this.weeksTranslate = translation;
    });
    this._translateService
      .get("5WHY.REPEATING_PROBLEM_CHART_TITLE")
      .subscribe((translation) => {
        this.chartTitleTranslation = translation;
      });
  }

  ngOnInit(): void {
    this.chartOptions = {
      title: {
        text: `${this.chartTitleTranslation}`,
        x: "center",
        top: 20,
        textStyle: {
          fontSize: 15
        }
      },
      xAxis: {
        type: "category",
        show: false,
        data: this.data.map(
          (week) => `${this.weeksTranslate} ${week.str_week}`
        ),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          margin: 2,
          fontSize: 10
        }
      },
      tooltip: {
        trigger: "item",
        formatter: `{b} - {c}`,
      },
      dataZoom: [
        {
          type: "inside",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],
      series: [
        {
          data: this.data.map((week) =>
            week.str_total_repeating_problems == null
              ? 0
              : week.str_total_repeating_problems
          ),
          type: "line",
        },
      ],
      animation: true,
    };
  }

  onChartInit(instance) {
    this.echartsInstance = instance;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        series: [
          {
            data: this.data.map((week) =>
              week.str_total_repeating_problems == null
                ? 0
                : week.str_total_repeating_problems
            ),
            type: "line",
          },
        ],
        xAxis: {
          type: "category",
          show: false,
          data: this.data.map(
            (week) => `${this.weeksTranslate} ${week.str_week}`
          ),
        }
      });
    }
  }
}