// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-email { 
    margin-top: 200px;
}

.modal-dialog { 
    width: 750px;
}
.modal-header  {
    text-align: center;
}

.modal-body {
    text-align: center;
}

.default-button.--Ok {
    background-color: var(--ui-ok);
}
  
.default-button.--Cancel {
    background-color: var(--ui-danger);
}

.button-send { 
    background-color: var(--ui-neutral);
}

.send-div { 
    padding-top: 20px;
}

.default-button {
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;
    border-radius: 3px;
    color: #ffffff;
    margin: 0 0.25em;
    outline: none;
    padding: 0.5em;
    width: 120px;
}

.reason { 
    height: 90px;
    resize: none;
}


.disabled { 
    opacity: 0.5;
  }`, "",{"version":3,"sources":["webpack://./src/app/five-why/abort-fivewhy-modal/abort-fivewhy-modal.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2CAA2C;IAC3C,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,YAAY;EACd","sourcesContent":[".modal-email { \n    margin-top: 200px;\n}\n\n.modal-dialog { \n    width: 750px;\n}\n.modal-header  {\n    text-align: center;\n}\n\n.modal-body {\n    text-align: center;\n}\n\n.default-button.--Ok {\n    background-color: var(--ui-ok);\n}\n  \n.default-button.--Cancel {\n    background-color: var(--ui-danger);\n}\n\n.button-send { \n    background-color: var(--ui-neutral);\n}\n\n.send-div { \n    padding-top: 20px;\n}\n\n.default-button {\n    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;\n    border-radius: 3px;\n    color: #ffffff;\n    margin: 0 0.25em;\n    outline: none;\n    padding: 0.5em;\n    width: 120px;\n}\n\n.reason { \n    height: 90px;\n    resize: none;\n}\n\n\n.disabled { \n    opacity: 0.5;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
