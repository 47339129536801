import {Injectable} from '@angular/core';

import {MainService} from './main.service';

export enum ReportType {
    ROUTINE_ONLINE = "ROUTINE_ONLINE",
    ROUTINE_EMAIL = "ROUTINE_EMAIL",
}

@Injectable()
export class ReportsService {

    constructor(private _mainService: MainService) {

    }
    postProfileApprovalReport(searchParams) {
        const path = '/profile-approval';
        return this._mainService.postReport(path, searchParams);
    }
    postRoutineReports(searchParams) {
        const path = '/createReports';
        return this._mainService.postReport(path, searchParams);
    }
    postLogReports(searchParams) {
        const path = '/logFiltersReports';
        return this._mainService.postReport(path, searchParams);
    }
    getReportFilter(cod_user: number, str_report: ReportType) {
        const path = '/get-filter';
        return this._mainService.postReport(path, {
            cod_user,
            str_report,
        });
    }
    saveReportFilter(params) {
        const path = '/save-filter';
        return this._mainService.postReport(path, params);
    }

    isSameParameters(param1: any, param2: any){

        if(param1.str_proc_exec != param2.str_proc_exec){
            return false;
        }
        if(param1.arr_proc_params_search.length != param2.arr_proc_params_search.length){
            return false;
        }

        for (const property in param1.arr_proc_params_search[0]) {
            if(param1.arr_proc_params_search[0][property] != param2.arr_proc_params_search[0][property]){
                return false;
            }
        }
        
        return true;//if equals
    }
}