import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ListMachinesTaService } from './services/list-machines-ta.service';
import { AuthService } from '../../main/access/services/auth.service';

@Component({
    selector: 'list-machines-ta',
    templateUrl: './list-machines-ta.component.html',
    styleUrls: ['./list-machines-ta.component.css']
})

export class ListMachinesTaComponent implements OnInit {

    machinesTaList: any = [];
    obj_ta_eq;
    bol_show_typeahead: Boolean = true;
    str_user_tmp = '';
    cod_compoment_ta: number;
    obj_user = this._authService.getAuthenticatedUser();

    @Input() question;
    @Input() isMultiple: boolean;
    @Input() disabled: boolean;
    @Input() equipment: number;
    @Input() area: number;
    @Input() subarea: number;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _listMachinesService: ListMachinesTaService, private _authService: AuthService) {

    }

    ngOnInit() {
        if (this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.machinesTaList = [{ cod_answer_option: this.question.cod_answer_option, str_sap_name: this.question.answer }];
            this.bol_show_typeahead = false;
        }
        this.loadMachinesTa();
    }

    loadMachinesTa() {
        this.obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_id',
            searchProperty: 'str_sap_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.machinesTaList.length === 0) {
                    this.machinesTaList.push({
                        cod_id: obj_selected.cod_id,
                        str_sap_name: obj_selected.str_sap_name,
                    });
                    if (this.question !== null) {
                        this.question.cod_answer_option = obj_selected.cod_id;
                        this.question.str_answer_text = obj_selected.str_sap_name;
                        this.onChange.emit(this.question);
                        this.bol_show_typeahead = false;
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchMachinesTa(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchMachinesTa(cb, value) {
        if (this.equipment !== null || this.equipment !== undefined) {

            let objParam = {
                'cod_user': this._authService.getAuthenticatedUser().id,
                'cod_equipments': this.equipment,
                'cod_areas': this.area,
                'cod_subareas': this.subarea,
                'str_filter': value
            };
            
            this._listMachinesService.getMachines(objParam).subscribe(data => {
                cb(this._listMachinesService.getArrayContent(data));
            },
                err => { this._authService.errCheck(err); console.log(err); }
            );
        }
    }

    delMachinesTa() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;
        this.onChange.emit(this.question);

        this.machinesTaList = [];
        this.bol_show_typeahead = true;
        this.cod_compoment_ta = null;
    }

}
