import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LogbooksService} from '../services/logbooks.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-logbooks-my-team',
    templateUrl: './logbooks-my-team.component.html',
    styleUrls: ['./logbooks-my-team.component.css']
})
export class LogbooksMyTeamComponent implements AfterViewInit, OnDestroy, OnInit {

    id: any;
    list: any;
    searchTerm: string;
    searchTimer: any;
    paramsSubscription: Subscription = Subscription.EMPTY;
    isLoading: boolean = true;
    showError: boolean = false;
    errorMessage: string = '';
    loogbooksList: any = [];

    constructor(private _logbookService: LogbooksService, private _translateService: TranslateService, private _authService: AuthService) {
        this.searchTerm = '';
        this.list = {
            logbooks: []
        };
        this.searchTimer = null;
        this.showError = false;
        this.errorMessage = '';
    }

    ngAfterViewInit() {
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        }
        catch (err) {
            //safe!
            console.log(err);
        }
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        let objParam = {
            'cod_logbooks': 0,
            'term': this.searchTerm,
            'limit': 1000,
            'offset': 0,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'ADMIN_LOGBOOKS_LISTING',
            'file_path_url': environment().uploadGetFilesApiUrl + '/',
        };
        this._logbookService.getMyTeamList(objParam).subscribe(data => {
                this.list = {
                    logbooks: this._logbookService.getArrayContent(data)
                };
                this.isLoading = false;
            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.message);
                this._authService.errCheck(err);
            }
        );
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    searchLogbooksMultiSel() {
        this.isLoading = true;
        let params = {
            'term': '',
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'MYTEAM_SELECT_LOGBOOKS',
            'limit': 1000,
            'offset': 0
        };
        this._logbookService.getLogbooksList(params).subscribe((result: any) => {
                //PUT THE DEFAULT NO LOGBOOK OPTION
                this._translateService.get('GENERAL.WITHOUT_LOGBOOK').subscribe(translate => {
                    this.loogbooksList=[];
                    this.loogbooksList = this._logbookService.getArrayContent(result);
                    this.loogbooksList.unshift({"cod_logbooks":"null","str_name":translate});
                    this.isLoading = false;
                });
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    ngOnInit(): void {
        this.searchLogbooksMultiSel();
    }
}
