import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {InfoService} from '../../services/help.service';

@Component({
  selector: 'app-info-topics',
  templateUrl: './help-topics.component.html',
  styleUrls: ['./help-topics.component.css']
})
export class InfoTopicsComponent implements OnInit, OnDestroy {

  topics: object;
  keys: Array<string> = [];
  subscription: Subscription;
  topicSubscription: Subscription;
  active: string;

  constructor(private _infoService: InfoService, private _translateService: TranslateService) { }

  ngOnInit() {
    this.subscription = this._infoService.currentItem$
        .subscribe(key => {
          this.loadTopics(key);
          this._translateService.onLangChange.subscribe(() => this.loadTopics(key));
        });
        this.topicSubscription = this._infoService.currentTopic$
        .subscribe(key => this.active = key.toString());
  }

  clickTopic(topic) {
    this.active = topic;
    this._infoService.changeSelectedTopic(topic);
  }

  loadTopics(key) {
    this._translateService.get(`HELP.${key}`).subscribe(result => {
      this.topics = result;
      this.keys = this.topics ? Object.keys(this.topics) : [];
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.topicSubscription.unsubscribe();
}

}
