import {Component, EventEmitter, Input} from '@angular/core';

@Component({
    selector: 'logbook-button-delete',
    templateUrl: './button-delete.component.html',
    styleUrls: ['./button-delete.component.css'],
})
export class ButtonDeleteComponent {
    @Input() showIcon: boolean = true;
    @Input() showText: boolean = true;
    @Input() text: string;
    @Input() disabled: boolean = false;
    @Input() click: EventEmitter<any> = new EventEmitter<any>();

    onClick($event) {
        if (this.disabled) {
            $event.stopPropagation();
            return false;
        }
    }
}

