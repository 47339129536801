export class Area {
    cod_areas: number;
    str_description: string;
    subareas: any;
    arr_worlds: any;
    arr_regional: any;
    arr_location: any;
    str_description_pt: string;
    str_description_es: string;
    str_description_fr: string;
    str_description_nl: string;
    str_description_de: string;
    str_description_ru: string;
    str_description_uk: string;
    str_description_zh: string;
    str_description_ko: string;
    str_description_vi: string;
    cod_acadia_id: string;

    constructor() {
        this.cod_areas = 0;
        this.str_description = '';
        this.subareas = [];
        this.arr_worlds = [];
        this.arr_regional = [];
        this.arr_location = [];
        this.str_description_pt = null;
        this.str_description_es = null;
        this.str_description_fr = null;
        this.str_description_nl = null;
        this.str_description_de = null;
        this.str_description_ru = null;
        this.str_description_uk = null;
        this.str_description_zh = null;
        this.str_description_ko = null;
        this.str_description_vi = null;
        this.cod_acadia_id = '';
    }
}