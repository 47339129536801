import { Component, OnInit } from '@angular/core';
import { RelayService } from '../services/relay.service';
import { FiveWhyService } from './../services/five-why.service';
import { AuthService } from '../../main/access/services/auth.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SelectPagination } from "../../logbooks/models/select_pagination.model";
import { NotificationService } from '../../main/services/notification.service';

@Component({
  selector: 'app-relay-list',
  templateUrl: './relay-list.component.html',
  styleUrls: ['./relay-list.component.css']
})
export class RelayListComponent implements OnInit {

  pageIndex: any;
  relayList: any = [];
  selectedRecords: Object[];
  fivewhysStatus: [];
  privilegeRelay: any;
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  totalPages: any[];
  resultsPerPage: any[];
  resultsPerPageSelectOption: any[];
  resultsPerPageSelected: any;
  isLoading: any = false;
  searchTerm: string;
  searchTimer: any;
  showError: boolean;
  errorMessage: string;
  // bol_change_copy_name: boolean = false;
  // successCopyMessage: string;
  maxPage: number;
  // editSuccessMessage: boolean;
  codArea: any;
  codLocation: any;
  createRecord: boolean = false;
  deleteRelay: any = null;
  awaitConfirmDelete: boolean;
  // emitEditSuccessMessage: boolean;
  cod_user: number;
  isLoadingSteps: boolean;

  constructor(
    private _relayService: RelayService, 
    private _fiveWhyService: FiveWhyService,
    private _authService: AuthService, 
    private _translateService: TranslateService,
    private _notify: NotificationService
  ) {
    this.searchTerm = '';
    this.privilegeRelay = this._authService.getUserPrivilege('ADMIN_LIST_RELAY');
    this.searchTimer = null;
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str10perpage = '10 ' + translate;
    });
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str50perpage = '50 ' + translate;
    });
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str100perpage = '100 ' + translate;
    });



    this.pageIndex = 1;
    this.totalPages = [];
    this.resultsPerPage = [
      new SelectPagination(10, this.str10perpage),
      new SelectPagination(50, this.str50perpage),
      new SelectPagination(100, this.str100perpage),
    ];
    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
    this.resultsPerPageSelected = this.resultsPerPage[0].qty;
    this.selectedRecords = [];
    this.cod_user = this._authService.getAuthenticatedUser().id;

    this._getFivewhysStatus();

  }

  ngOnInit() {
    this.search(true);
    this.privilegeRelay = this.checkPrivilege('ADMIN_LIST_RELAY');

    this._translateService.onLangChange.subscribe(() => {
      this.isLoadingSteps = true;
      this._getFivewhysStatus();
    });
  }

  private _getFivewhysStatus() {
    this._fiveWhyService.listSteps(
      {cod_user: this._authService.getAuthenticatedUser().id}
    ).subscribe(data => {
      this.fivewhysStatus = data.content.results
      this.isLoadingSteps = false;
    });
  }

  editRelay(cod_area, cod_location, reload) {
    if (cod_area == -1) cod_area = 0;
    if (cod_location == -1) cod_location = 0;

    this.codArea = cod_area;
    this.codLocation = cod_location;
    this.createRecord = !reload;
    if (reload) {
      this.search(true);
    }
  }

  search(reset: boolean) {
    if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
      return;
    }
    this.isLoading = true;
    clearTimeout(this.searchTimer);
    let $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.searchRelay();
    }, environment().debounceTime);
  }

  searchRelay() {
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    this.isLoading = true;
    const objParam = {
      'term': this.searchTerm,
      'limit': this.resultsPerPageSelected,
      'offset': (tmpPage * this.resultsPerPageSelected),
      'cod_user': this.cod_user,
      'dsc_page_privilege': 'ADMIN_LIST_RELAY'
    };

    this._relayService.listRelay(objParam).subscribe(data => {
      this.relayList = data.content.results;
      if (data.content.length > 0) {
        this.maxPage = Math.ceil(data.content.results[0].full_count / this.resultsPerPageSelected);
        this.totalPages = this.pagination(this.pageIndex, this.maxPage);
      }

      this.isLoading = false;
    }, err => {
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    });
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search(false);
  }

  pagination(currentPage, nrOfPages) {
    let delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this._notify.error(translate);
    });
  }

  checkPrivilege(privilege) {
    return this._authService.getUserPrivilege(privilege);
  }

  public toggleDeleteRelay(cod_area: number = null, cod_location: number = null) {
    this.awaitConfirmDelete = !this.awaitConfirmDelete;
    if (cod_area == null || cod_location == null) {
      this.deleteRelay = null;
    } else {
      this.deleteRelay = {
        cod_area: cod_area,
        cod_location: cod_location,
      };
    }
  }

  public confirmDeleteRelay() {
    let objParam = {
      'cod_area': this.deleteRelay.cod_area,
      'cod_location': this.deleteRelay.cod_location,
      'cod_user': this.cod_user
    };

    this._relayService.deleteRelay(objParam).subscribe(data => {
      this._notify.success({text: 'RELAY.DELETED', translate: true});
      this.search(false);
    }, err => {
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    }
    );
    this.awaitConfirmDelete = false;
    this.deleteRelay = null;
  }
}
