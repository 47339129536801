// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

.isDisable{
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.text-justify {
    text-align: justify;
    white-space: pre-line;

}

div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow-y: auto;
    max-height: 90px;
    max-width: 300px;
}

.mb-10 {
    margin-bottom: 10px;
}

.inline-block {
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/trigger-list/trigger-list.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;;AAEzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n.isDisable{\n    pointer-events: none;\n    cursor: default;\n    opacity: 0.6;\n}\n\n.text-justify {\n    text-align: justify;\n    white-space: pre-line;\n\n}\n\ndiv.scrollable {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: block;\n    overflow-y: auto;\n    max-height: 90px;\n    max-width: 300px;\n}\n\n.mb-10 {\n    margin-bottom: 10px;\n}\n\n.inline-block {\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
