import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PreviousRouteService {
    private currentUrl: string;

    private previousUrl: string;

    private previousUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public previousUrlObservable: Observable<string> = this.previousUrlSubject.asObservable(); // an observable, just in case

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.previousUrlSubject.next(this.currentUrl);
                this.currentUrl = event.url;
            }
        });
    }

    public getPreviousUrl(): string {
        return this.previousUrl ?? null;
    }
}
