import { Injectable } from '@angular/core';
import {MainService} from '../services/main.service';
import {Observable} from 'rxjs';

@Injectable()
export class MachineService {

  constructor(private _mainService: MainService) { }

  listMachineAdmin(params){
    const path = '/list-machines-admin';
    return this._mainService.post(path, params);
  }

  getSingleMachine(params){
    const path = '/get-single-machine';
    return this._mainService.post(path, params);
  }

  getListMachines(params){
    const path = '/get-machines';
    return this._mainService.post(path, params);
  }

  insertMachine(params) {
    const path = '/insert-machine';
    return this._mainService.post(path, params);
  }

  updateMachine(params) {
    const path = '/update-machine';
    return this._mainService.post(path, params);
  }

  deleteMachine(params) {
    const path = `/delete-machine/${params.cod_machine}/${params.cod_user}`;
    return this._mainService.delete(path)
  }

  //Equipments

  getListEquipments(params) {
      const path = '/get-equipments-by-subareas';
      return this._mainService.post(path, params);
  }

  getListEquipmentsWithMachines(params) {
      const path = '/get-equipments-by-subareas-with-machines';
      return this._mainService.post(path, params);
  }
}
