import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class AreaService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all logbook areas.
     * @param param: JSON object filled with search "term" or "id"
     */
    getAreasList(param): Observable<any> {
        let path = '/areas?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege;
        if (param.cod_areas > 0) {
            path = '/area/' + param.cod_areas+ '/' + param.cod_user;
        }

        return this._mainService.get(path);
    }

    getListAreasByPagination(param): Observable<any> {
        let path = '/get-list-areas-pagination'
        return this._mainService.post(path, param);
    }

    getAreaById(param): Observable<any> {
        let path = '/get-area-by-id'
        return this._mainService.post(path, param);
    }

    postNewArea(param): Observable<any> {
        let path = '/post-new-area'
        return this._mainService.post(path, param);
    }

    postUpdateArea(param): Observable<any> {
        let path = '/post-update-area'
        return this._mainService.post(path, param);
    }

    getSubAreasByArea(params): Observable<any> {
        const path = '/sub-area-by-area/' + params.cod_areas + '/' + params.cod_user;
        return this._mainService.get(path);
    }

    getSubAreasByAreaPrivilege(params): Observable<any> { // Not used
        const path = '/sub-area-by-area-privileges';
        return this._mainService.post(path, params);
    }

    getAreaByMeetingLocation(params): Observable<any>{
        const path = '/get-area-by-meeting-location';
        return this._mainService.post(path, params);
    }
    
    /**
     * Post area object to database
     * @param params: JSON object of area
     */
    postArea(params): Observable<any> {
        let path = '/area';
        return this._mainService.post(path, params);
    }

    /**
     * Put area object to database
     * @param params: JSON object of area
     */
    putArea(params): Observable<any> {
        let path = '/area';
        return this._mainService.put(path, params);
    }

    /**
     * Delete area object from database
     * @param param: JSON object filled with "id" of area
     */
    deleteArea(params): Observable<any> {
        let path = '/area/' + params.cod_areas + '/' + params.cod_user;
        return this._mainService.delete(path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getAreaByWorlds(params): Observable<any> {
        let path = '/get-area-by-world';
        return this._mainService.post(path, params);
    }

    getAreaByLocations(params): Observable<any> {
        let path = '/get-area-by-location';
        return this._mainService.post(path, params);
    }
}
