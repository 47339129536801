import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-my-forms-main',
  templateUrl: './my-forms-main.component.html',
  styleUrls: ['./my-forms-main.component.css']
})
export class MyFormsMainComponent {
  
  isLoading: boolean = true;
  actualTab: string = 'my-forms';
  actualSubTab: string;

  constructor(public _authService: AuthService) {
    if(localStorage.getItem('shouldGetBackToLocationForms') == 'true') {
      this.actualTab = 'forms-location';
      localStorage.setItem('shouldGetBackToLocationForms', 'false');
    }
  }
  

  changeTab(tabName) {
    this.actualTab = tabName;
  }

  canShowTab(tabName) {
    let canShow = this._authService.getUserPrivilege(tabName);
    return canShow;
}

}
