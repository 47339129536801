import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-meetings-admin',
  templateUrl: './meetings-admin.component.html',
  styleUrls: ['./meetings-admin.component.css']
})
export class MeetingsAdminComponent implements OnInit {

  constructor() { 

  }

  ngOnInit() {
  }

}
