import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';
import * as moment from 'moment';
import { timer } from 'rxjs';

@Component({
  selector: 'app-afk-warning-modal',
  templateUrl: './afk-warning-modal.component.html',
  styleUrls: ['./afk-warning-modal.component.css']
})
export class AfkWarningModalComponent implements OnInit {

  cod_user: number;
  maxTimeInSeconds: number = 60;
  timerLabel: string = '1:00';

  @Output() onConfirmNotAfk = new EventEmitter();
  @Output() onTimeExpire = new EventEmitter();

  constructor(
    private _authService: AuthService
  ) { 
    this.cod_user = this._authService.getAuthenticatedUser().id;

  }

  ngOnInit() {
    const thisTimer = timer(1000,1000).subscribe(() => {
      if(this.maxTimeInSeconds == 0) {
        thisTimer.unsubscribe();
        this.onTimeExpire.emit();

      } else { 
        this.maxTimeInSeconds += -1;
        this.timerLabel = moment({}).minutes(0).seconds(this.maxTimeInSeconds).format('m:ss');
      }
    })
  }

  confirmNotAwayFromKeyboard() {
    this.onConfirmNotAfk.emit();
  }
}
