import {Component, Input} from '@angular/core';

@Component({
    selector: 'logbook-button-back',
    templateUrl: './button-back.component.html',
    styleUrls: ['./button-back.component.css'],
})
export class ButtonBackComponent {
    @Input() routerLink: string;
    @Input() disabled: boolean;
    @Input() showIcon: boolean = true;
    @Input() showText: boolean = true;
    @Input() text: string;

}