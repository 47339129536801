import {Component, OnInit} from '@angular/core';
import {MeetingsIndicatorsService} from '../services/meetings-indicators.service';
import {AuthService} from '../../main/access/services/auth.service';
import {Indicator} from '../models/meetings-indicators.model';
import {UsersService} from '../../admin/services/users.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';
import {NotificationService} from './../../main/services/notification.service';
import { Translation } from 'app/components/translations-input/models/translation.model';
@Component({
    selector: 'app-meetings-indicators',
    templateUrl: './meetings-indicators-list.component.html',
    styleUrls: ['./meetings-indicators-list.component.css']
})
export class MeetingsIndicatorsComponent implements OnInit {
    meetingsIndicators = [];
    str_filter: string;
    model: Indicator;
    editMode: Boolean;
    obj_user: any;
    arr_worlds = [];
    arr_units = [];
    arr_externals = [];
    bol_success: Boolean = false;
    bol_success_del: Boolean = false;
    bol_error_submit: Boolean = false;
    error_message_submit: string;
    show_loading: Boolean = false;
    bol_loading_list = false;
    timeToHideMessage: number;
    private timeToHideMessageConstant: number;
    meetingsIndicatorsSelection = [];
    currentLang = '';
    bol_confirm_delete: any = [];
    indicatorToDelete: any;
    showEditTranslations: boolean = false; 
   
    constructor(private indicatorsService: MeetingsIndicatorsService, private authService: AuthService, private _usersService: UsersService,
                private _translateService: TranslateService, private _userPreferenceService: UserPreferencesService, private _notify: NotificationService) {
        this.model = new Indicator();
        this.str_filter = '';
        this.editMode = false;
        this.obj_user = this.authService.getAuthenticatedUser();
        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 3000;
        this.listWorlds();
        this.meetingsIndicators = [];
        this.indicatorsService.getUnits('', this.authService.getAuthenticatedUser().id).subscribe(data => {
                this.arr_units = this.indicatorsService.getArrayContent(data);
            },
            err => {
                console.log(err);
            }
        );
        // this.indicatorsService.getExternals().subscribe(data => {
        //         this.arr_externals = this.indicatorsService.getArrayContent(data);
        //     },
        //     err => {
        //         console.log(err);
        //     }
        // );

        this.currentLang = this._userPreferenceService.getLocalLanguage();
        this.bol_confirm_delete = false;
        this.indicatorToDelete = null;
    }

    ngOnInit() {
        this.listMeetingsIndicators();
    }

    reset() {
        // this.bol_error_submit = false;
        // this.error_message_submit = '';
        this.model = new Indicator();
        this.model.cod_usu_ins = this.obj_user.id;
        this.listWorlds();
        const $this = this;
        window.setTimeout(function () {
            $this.listMeetingsIndicators();
        }, 300);
    }

    handleSubmitValidation(message: string): void {
        this._translateService.get(message).subscribe(translate => {
            this.bol_error_submit = true;
            this.error_message_submit = translate;
            this._notify.warning(translate);
        });
    }

    submit() {
        if (!this.checkConditionals()) {
            this.handleSubmitValidation('INDICATORS.INVALID_CONDICIONAL');
            return false;
        }
        if (!this.model.str_name.trim()) {
            this.handleSubmitValidation('INDICATORS.NAME_IS_REQUIRED');
            return;
        }
        if (!this.model.arr_locations_world || this.model.arr_locations_world.length <= 0) {
            this.handleSubmitValidation('INDICATORS.WORLD_IS_REQUIRED');
            return;
        }
        this.bol_error_submit = false;
        this.error_message_submit = '';
        this.show_loading = true;
        this.model.cod_usu_ins = this.obj_user.id;
        if (this.model.cod_indicators === 0) {
            this.indicatorsService.postNewMeetingsIndicator({
                ...this.model,
                arr_indicators: this.model.arr_indicators.map(indicator => indicator.cod_indicators)
            })
                .subscribe(res => {
                        if (res.type !== 'success') {
                            this.bol_error_submit = true;
                            this.error_message_submit = res.content[0].message;
                            this._notify.warning(res.content[0].message);
                            return;
                        }
                        this.bol_success = true;
                        this._notify.success({text: 'INDICATORS.CREATED_SUCCESSFULLY', translate: true});
                        this.editMode = false;
                        this.reset();
                        this.timeToHideMessage = this.timeToHideMessageConstant;
                        this.show_loading = false;
                    },
                    err => {
                        this.authService.errCheck(err);
                        console.log(err);
                    }
                );
        } else {
            const indicators = this.model.arr_indicators.map(indicator => indicator.cod_indicators);
            _.remove(indicators, indicator => indicator === this.model.cod_indicators);
            this.indicatorsService.updateMeetingsIndicator({
                ...this.model,
                arr_indicators: indicators
            })
                .subscribe(res => {
                        if (res.type !== 'success') {
                            this.bol_error_submit = true;
                            this.error_message_submit = res.content.message;
                            this._notify.warning(res.content.message);
                            return;
                        }
                        this.bol_success = true;
                        this._notify.success({text: 'INDICATORS.EDITED_SUCCESSFULLY', translate: true});
                        this.editMode = false;
                        this.reset();
                        this.timeToHideMessage = this.timeToHideMessageConstant;
                        this.show_loading = false;
                    },
                    err => {
                        this.authService.errCheck(err);
                        console.log(err);
                    }
                );
        }
    }

    listMeetingsIndicators() {
        this.bol_loading_list = true;

        const params = {
            str_term: (this.str_filter === '' ? '--' : this.str_filter),
            cod_user: this.authService.getAuthenticatedUser().id,
            dsc_page_privilege: 'ADMIN_MEETING_LIST_INDICATORS'
        };

        this.meetingsIndicators = [];
        this.indicatorsService.getMeetingsIndicators(params).subscribe(data => {
                this.meetingsIndicators = Array.isArray(data.content.results) ? data.content.results : [data.content.results];
                this.meetingsIndicatorsSelection = this.meetingsIndicatorsSelection.length === 0
                    ? Object.assign([], this.meetingsIndicators)
                    : this.meetingsIndicatorsSelection;
                // this.bol_success = false;
                this.bol_loading_list = false;
            },
            err => {
                this.authService.errCheck(err);
                console.log(err);
            }
        );

    }

    newIndicator() {
        this.model = new Indicator();
        this.model.cod_usu_ins = this.obj_user.id;
    }

    selectWorlds(event) {
        this.model.arr_locations_world = event.selectedOptions;
    }

    selectUnit(event) {
        if (!event.selectedOptions || !event.selectedOptions.length) {
            this.model.cod_unit = null;
            return;
        }
        this.model.cod_unit = event.selectedOptions[0].cod_unit;
    }

    selectExternal(event) {
        if (!event.selectedOptions || !event.selectedOptions.length) {
            this.model.cod_indicators_externals = null;
            return;
        }
        this.model.cod_indicators_externals = event.selectedOptions[0].cod_indicators_externals;
    }

    selectIndicators(event) {
        if (!event.selectedOptions || !event.selectedOptions.length) {
            this.model.arr_indicators = [];
            return;
        }
        this.model.arr_indicators = event.selectedOptions;
    }

    listWorlds() {
        this._usersService.getWorlds(this.authService.getAuthenticatedUser().id, 'ADMIN_MEETING_LIST_INDICATORS').subscribe(data => {
                this.arr_worlds = this._usersService.getArrayContent(data);
            },
            err => {
                this.authService.errCheck(err);
                console.log(err);
            }
        );
    }

    setDeleteIndicator(meetingsIndicator: Indicator) {
        this.indicatorToDelete = meetingsIndicator;
        this.bol_confirm_delete = true;
    }

    closeConfirmMessage() {
        this.bol_confirm_delete = false;
    }

    deleteMeetingIndicator() {
        this.indicatorToDelete.cod_usu_del = this.obj_user.id;
        this.indicatorsService.deleteMeetingsIndicator(this.indicatorToDelete).subscribe(res => {

                this.editMode = false;
                this.bol_confirm_delete = false;
                this._notify.success({text: 'INDICATORS.DELETED_SUCCESSFULLY', translate: true});
                this.reset();
            },
            err => {
                this.bol_confirm_delete = false;
                this.authService.errCheck(err);
                console.log(err);
            }
        );
    }

    loadMeetingIndicator(meetingsIndicator: Indicator) {
        const elem = document.getElementById('nameindicator');
        const x = window.scrollX, y = window.scrollY;
        window.scrollTo(x, y);
        elem.focus();

        this.editMode = true;
        this.model = new Indicator;
        this.model.cod_indicators = meetingsIndicator.cod_indicators;
        const obj_unit = _.find(this.arr_units, uni => uni.cod_unit === meetingsIndicator.cod_unit);
        if (obj_unit !== undefined) {
            this.model.arr_units[0] = obj_unit;
            this.model.cod_unit = obj_unit.cod_unit;
        }
        this.model.arr_locations_world = meetingsIndicator.arr_locations_world;
        this.model.arr_conditionals = meetingsIndicator.arr_conditionals ? meetingsIndicator.arr_conditionals : [];
        this.model.str_name = meetingsIndicator.str_name_en;
        this.model.str_name_de = meetingsIndicator.str_name_de;
        this.model.str_name_es = meetingsIndicator.str_name_es;
        this.model.str_name_fr = meetingsIndicator.str_name_fr;
        this.model.str_name_nl = meetingsIndicator.str_name_nl;
        this.model.str_name_pt = meetingsIndicator.str_name_pt;
        this.model.str_name_ru = meetingsIndicator.str_name_ru;
        this.model.str_name_ua = meetingsIndicator.str_name_ua;
        this.model.cod_usu_ins = this.obj_user.id;
        //this.model.arr_externals = [{cod_indicators_externals: meetingsIndicator.cod_indicators_externals}];
        this.model.arr_externals = [];
        this.model.cod_indicators_externals = meetingsIndicator.cod_indicators_externals;
        this.bol_error_submit = false;
        this.error_message_submit = '';
    }

    hideMessage() {
        this.bol_success = false;
        this.bol_success_del = false;
        this.timeToHideMessage = 0;
    }

    private checkConditionals() {
        if (this.model.arr_conditionals.length <= 0) {
            return true;
        }

        for (let i = 0; i < this.model.arr_conditionals.length; i++) {
            if (this.model.arr_conditionals[i].str_operator === '' || this.model.arr_conditionals[i].str_color === '') {
                return false;
            }
        }

        return true;
    }

    getConditionals(conditionals) {
        this.model.arr_conditionals = conditionals;
    }

    
    editTranslations() {
        this.showEditTranslations = true;
    }

    cancelTranslation(): void {
        this.showEditTranslations = false;
    }

    saveTranslation(translations: Translation = null) {
        this.model.str_name_de = translations.str_de;
        this.model.str_name_es = translations.str_es;
        this.model.str_name_fr = translations.str_fr;
        this.model.str_name_nl = translations.str_nl;
        this.model.str_name_pt = translations.str_pt;
        this.model.str_name_ru = translations.str_ru;
        this.model.str_name_ua = translations.str_ua;
        this.model.str_name_zh = translations.str_zh;
        this.showEditTranslations = false;
    }
}
