import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class PeriodicityTypesService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all periodicities types.
     * @param param: JSON object filled with search "term" or "id"
     */
    getPeriodicitiesList(param): Observable<any> {// A_M, muitos
        let path = '/periodicities?term=' + param.term + '&cod_user=' + param.cod_user;
        if (param.cod_periodicity > 0) {
            path = '/periodicity/' + param.cod_periodicity;
        }

        return this._mainService.get(path);
    }

    /**
     * Post periodicity type object to database
     * @param params: JSON object of periodicity type
     */
    postPeriodicity(params): Observable<any> {
        throw new Error('Not implemented');
        //let path = '/periodicity-type';
        //return this._mainService.post(path, params);
    }

    /**
     * Put periodicity type object to database
     * @param params: JSON object of periodicity type
     */
    putPeriodicity(params): Observable<any> {
        throw new Error('Not implemented');
        //let path = '/periodicity-type';
        //return this._mainService.put(path, params);
    }

    /**
     * Delete periodicity type object from database
     * @param param: JSON object filled with "id" of periodicity type
     */
    deletePeriodicity(params): Observable<any> {
        throw new Error('Not implemented');
        //let path = '/periodicity-type/' + params.id;
        //return this._mainService.delete(path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }
}
