import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {environment} from 'environments/environment';

import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AcadiaIntegrationService {
  constructor(private _mainService: MainService) {}

  createTaskList(params): Observable<any> {
    let path = "/create-acadia-task-list";
    return this._mainService.post(path, params);
  }

  checkTaskListStatus(params): Observable<any> {
    let path = "/check-acadia-task-list";
    return this._mainService.post(path, params);
  }

  openAcadiaTaskList(task_list_url) {
    if (task_list_url) {
      const url = `${environment().acadiaApiUrl}${task_list_url}`;

      window.open(url);
    }
  }
}
