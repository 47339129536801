import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-report-topic-1',
  templateUrl: './report-topic-1.component.html',
  styleUrls: ['./report-topic-1.component.css']
})
export class ReportTopic1Component implements OnInit {

  constructor(public userPreferencesService:UserPreferencesService) { }

  ngOnInit() {
  }

}
