import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {UserPreferencesComponent} from './user-preferences/user-preferences.component';
import {ChangeBackgroundComponent} from './modals/change-background/change-background.component';
import {SelectedTimeZoneComponent} from './modals/selected-time-zone/selected-time-zone.component';
import {ChangePictureComponent} from './modals/change-picture/change-picture.component';
import {MainService} from './services/main.service';
import {UploadMainService} from './services/upload-main.service';
import {UserPreferencesService} from './services/user-preferences.service';
// i18n
import {TranslateModule} from '@ngx-translate/core';

import {ComponentsModule} from '../components/components.module';
// ROUTE GUARD
import {AuthGuard} from '../guards/auth-guard';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
// import {AuthInterceptorService} from '../main/access/services/auth-interceptor.service';

import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';


// ROUTES FROM MODULE
const routes: Routes = [
  {
      path: 'user-preferences',
      component: UserPreferencesComponent,
      canActivate: [
        AuthGuard, MsalGuard
      ]
  }
]

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forChild(routes),
    CommonModule,
    ComponentsModule
  ],
  declarations: [
    UserPreferencesComponent,
    ChangeBackgroundComponent,
    ChangePictureComponent,
    SelectedTimeZoneComponent],
  exports: [
    UserPreferencesComponent
  ],
  providers: [
    MainService,
    UploadMainService,
    UserPreferencesService/*,
    //NEW SSO MSAL
    //TODO uncomment this, or comment others???
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }*/
  ]
})
export class UserPreferencesModule { }
