import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListPillarsService} from './services/list-pillars.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-pillars',
    templateUrl: './list-pillars.component.html',
    styleUrls: ['./list-pillars.component.css']
})

export class ListPillarsComponent implements OnInit {

    pillarsList: any = [];
    obj_ta_eq  ;
    bol_show_typeahead: Boolean = true;
    str_user_tmp = '';
    cod_component_pillars: number;

    @Input() question;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListPillarsService: ListPillarsService, private _authService:AuthService) { 

    }
    
    ngOnInit() {    
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.pillarsList = [{ cod_answer_option: this.question.cod_answer_option, str_name: this.question.str_answer_text || this.question.answer  }];    
            this.bol_show_typeahead = false;
        }
        this.loadPillars();
    }

    loadPillars() {
        this.obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_pillars',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.pillarsList.length === 0) {
                    this.pillarsList.push({
                        cod_pillars: obj_selected.cod_pillars,
                        str_name: obj_selected.str_name,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_pillars;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchPillars(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchPillars(cb, value) {
        this._ListPillarsService.getPillarsList({ 'term': value, 'cod_user': this._authService.getAuthenticatedUser().id }).subscribe((data: any) => {
            cb(this._ListPillarsService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delPillars() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;
        this.onChange.emit(this.question);

        this.pillarsList = [];
        this.bol_show_typeahead = true;
        this.cod_component_pillars = null;
    }
       
}
