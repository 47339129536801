import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PillarsSafetyComponent} from './pillars-safety/pillars-safety.component';
import {PillarsManagementComponent} from './pillars-management/pillars-management.component';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
// import {AuthInterceptorService} from '../main/access/services/auth-interceptor.service';

import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';


const routes: Routes = [
    { 
        path: 'pillars-safety',
        component: PillarsSafetyComponent
    },
    { 
        path: 'pillars-management',
        component: PillarsManagementComponent
    },
];

@NgModule({
    declarations: [
        PillarsSafetyComponent,
        PillarsManagementComponent
    ],
    imports: [RouterModule.forChild(routes)],
    providers: [
        //TODO uncomment this, or comment others???
        // { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true } //NEW SSO MSAL
    ]
})
export class PillarsModule { }