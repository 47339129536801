import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TranslateObserverService {
    
    private observer: MutationObserver = null;

    private translatedSource = new BehaviorSubject<boolean>(false);
    public translated = this.translatedSource.asObservable();

    constructor() {
        this.createTranslateObserver();
    }

    private createTranslateObserver() : void {
        const targetNode = document.getElementsByTagName('html')[0];

        const config = { attributes: true, childList: false, subtree: false };
        
        const callback = (mutationsList, observer) => {
            for(const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    this.changeTranslated(mutation.target.className.includes('translated-ltr'));
                }
            }
        };
        
        this.observer = new MutationObserver(callback);
        
        this.observer.observe(targetNode, config);
    }

    public changeTranslated(translated: boolean) : void {
        this.translatedSource.next(translated);
    }

}