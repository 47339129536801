import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmailModalService } from '../../../components/email-confirmation-modal/email-confirmation-modal.service';
import { FiveWhyService } from '../../../five-why/services/five-why.service';
import { Routine } from '../../../logbooks/models/routine.model';
import { RoutinesService } from '../../../logbooks/services/routines.service';
import { NotificationService } from "../../../main/services/notification.service";
import { MyFormsService } from '../../../my-forms/services/my-forms.service';
import { RoutinesServiceStore } from '../../../stores/routines-forms.store.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormsDynamicAdminService } from '../../../forms/forms-dynamic/services/forms-dynamic.service';
import { RoutineDataService } from '../../../logbooks/services/routine-data.service';
import { AuthService } from '../../../main/access/services/auth.service';
import { ActionsService } from '../../../meetings/services/actions.service';
import FormsHelper from '../helpers/form-helper';
import { Action } from '../models/action.model';
import { Question, QuestionType } from '../models/question.model';
import * as moment from 'moment';


@Component({
    selector: 'answer-form',
    templateUrl: './answer-form.component.html',
    styleUrls: ['./answer-form.component.css']
})

export class AnswerFormComponent implements OnInit, OnChanges {
    
    @Output() onChangeStatus: EventEmitter<number> = new EventEmitter();
    @Output() onUndoInitializationForm: EventEmitter<number> = new EventEmitter();
    @Output() onNeedLrpu: EventEmitter<Function> = new EventEmitter();
    @Output() canChangeForm: EventEmitter<boolean> = new EventEmitter();
    @Input() hasManyForms: boolean = false;
    @Input() selectedDate: BehaviorSubject<string>;

    routine: Routine = null;
    form: any = null;
    isLoading: boolean = false;
    isLoadingForm: boolean = false;
    isAvailable: boolean = false;
    notInitiated: boolean = true;
    isVisible:  boolean[] = [];
    isCollab: boolean = false;
    isAsNeeded: boolean = false;
    formDisable: boolean = false;
    locationSelected: number = null;
    areaSelected: number = null;
    subareaSelected: number = null;
    equipmentSelected: number = null;
    machineSelected: number = null;
    formSelected: number = null;
    user_id: number = null;
    savingLoading: Map<number, boolean> = new Map();
    isPartialLoading: boolean = false;
    questionValid: Map<string, boolean> = new Map();
    failedPartials: Map<number, any> = new Map();
    actions: Map<number, Array<Action>> = new Map();
    allQuestions: Array<Question> = [];
    translations: any = {};
    upload_api_url: string = environment().uploadGetFilesApiUrl + '/';
    showErrorValidation: boolean = false;
    errorMessageValidation: string = "";
    errorActionMessage: string = "";
    errorDueDateMessage: string = "";
    privilegeEmail: boolean = false;
    isSubmiting: boolean = false;
    modalSubscriptions: Array<any> = [];
    shouldSendEmail: boolean = false;
    errorCreatingFac: boolean = false;
    hasFac: boolean = false;
    formLoaded: number = null;
    str_hashtag: string = null;
    isSubmitDisabled: boolean = false;
    sendEmailObject = {
        cod_user: '', 
        cod_form: '',
        cod_users_to_send_email: '',
        cod_lrpu: 0,
        isCollab: false
    };
    compliance_categories = [];
    arr_actions: Array<any> = [];   
    _formSelectedSubscription: Subscription;
    user: any;


    constructor(
        private readonly _authService: AuthService,
        private readonly _dataService: RoutineDataService,
        private readonly _formService: FormsDynamicAdminService,
        private readonly _translateService: TranslateService,
        private readonly _actionsService: ActionsService,
        private readonly _modalEmailService: EmailModalService,
        private readonly _myFormsService: MyFormsService,
        private readonly _fivewhyService: FiveWhyService,
        private readonly _notify: NotificationService,
        private readonly _routineStore: RoutinesServiceStore,
    ) {
        this.user = this._authService.getAuthenticatedUser();
        this.user_id = this.user.id;
        this._translateService.get('DYNAMICFORMS.SHOW.CREATE_ACTION.VALIDATE').subscribe(translate => this.errorActionMessage = translate);
        this._translateService.get('MEETING.VALIDATIONS.MAX_DUE_DATE').subscribe(translate => this.errorDueDateMessage = translate);
        this.privilegeEmail = this._authService.getUserPrivilege('FORMS_SUBMIT_EMAIL_NOTIFICATION');
        this.loadInitEmailSubscriptions();
        this.loadDataFromService();
    }

    ngOnInit() : void {
        this.refresh();
        this.loadDataFromService();
        
        // FORM SELECTED SUBSCRIPTION
        this._formSelectedSubscription = this._dataService.formSelected
        .subscribe((selected) => {
            this.formSelected = selected;
            if (this.routine?.cod_form == null) {
                this.getFormWithoutAnswers(this.formSelected);
            };
        });
    }

    ngOnChanges(changes: SimpleChanges) : void { 

        if (this.routine?.cod_form == null && this.formSelected != null) {
            this.getFormWithoutAnswers(this.formSelected);                        
        }
    }    

    ngOnDestroy() {
      this.unsubscribeEmailModal();
      
      // CANCEL THE SUBSCRIPTION WHEN COMPONENT UNMOUNT (MEMORY LEAK)
      this._formSelectedSubscription.unsubscribe();
    }

    private refresh() : void {
        setTimeout(() => {
            this.errorCreatingFac = false;
            this.hasFac = false;
            this.disableRules();
            this.canChangeForm.emit(false);
            this.cleanValidations();
    
            if (this.routine.cod_form) {
                this.selectForm(this.routine.cod_form);
                this.getForm();
                return;
            }
    
            if (this.hasMultipleForms() 
                && this.routine.cod_routine_status != RoutinesService.STATUS_DONE
            ) {
                this.canChangeForm.emit(true);
                this.selectForm(null);
                this.form = null;
            } else {
                this.selectForm(this.routine.form_questions[0]?.cod_form);
                this.getFormWithoutAnswers(this.routine.form_questions[0]?.cod_form);
            }
        });
    }

    private disableRules() : void {
                const { cod_routine_status } = this.routine;
        const isAsNeeded = this.routine.cod_periodicity == '13';
        const allowedStatus = [RoutinesService.STATUS_PENDING, RoutinesService.STATUS_AVAIABLE];
        this.notInitiated = !this.hasFac && (this.routine.cod_form == null || this.errorCreatingFac);
        this.formDisable = this.notInitiated || !(isAsNeeded || allowedStatus.includes(cod_routine_status)); 
        
    }

    private loadDataFromService(): void {
        this._dataService.routine.subscribe(x => {
            this.formLoaded = null;
            if (this.routine?.cod_routines != x.cod_routines
                || this.routine?.cod_periodicity != x.cod_periodicity
                || this.routine?.cod_logbook_routines_periodicity_users != x.cod_logbook_routines_periodicity_users
                || this.routine?.cod_logbook_routines_periodicity_users_collaborative_done != x.cod_logbook_routines_periodicity_users_collaborative_done
                || this.routine?.bol_type_collab != x.bol_type_collab
            ) {
                this.routine = x;
                this.refresh();
            } else {
                this.routine = x;
            }

            this.checkRoutineIsAvailable();
        });
   
        this._dataService.formSelected.subscribe(x => {
            this.formSelected = x;
            if (this.routine?.cod_form == null) {
                this.getFormWithoutAnswers(this.formSelected);
            }
        });

        this._dataService.isCollab.subscribe((isCollab) => this.isCollab = isCollab);
        this._dataService.isAsNeeded.subscribe((isAsNeeded) => this.isAsNeeded = isAsNeeded);
    }

    private checkRoutineIsAvailable(){
        this.isAvailable = (
            this.routine.cod_routine_status == RoutinesService.STATUS_PENDING
                || this.routine.cod_routine_status == RoutinesService.STATUS_AVAIABLE);               
    }

    private selectForm(cod_form: number) : void {
        this._dataService.changeFormSelected(cod_form);
    }

    private hasMultipleForms() {
        return (this.routine.form_questions.length > 1);
    }

    private calculateCompliance() : string {
        return FormsHelper.calculateComplianceForm(this.allQuestions.filter( x => !x.bol_optional || (x.bol_optional && (x.str_answer_text || x.answer))));
    }

    private calculateCompleteness(): number {
        return Number(FormsHelper.validateAnswers(true, this.allQuestions.filter(x=>!x.bol_optional)));
    }

    /************ INITIALIZE ************/

    public callInitializeForm(previous_answers: boolean = false) : void {
        this.isLoading = true;

        if (this.routine.cod_logbook_routines_periodicity_users > 0) {
            this.initializeForm(previous_answers);
        } else {
            this.onNeedLrpu.emit(cod_lrpu => {
                this.initializeForm(previous_answers, cod_lrpu);
            });
        }        
    }

    private promiseInitializeForm(previous_answers: boolean, cod_lrpu: number) : Observable<any> 
    {
        return this.isCollab                                        
            ? this._formService.initializeCollabForm(this.user_id, cod_lrpu, this.formSelected, previous_answers)
            : this._formService.initializeForm(this.user_id, cod_lrpu, this.formSelected, previous_answers);
    }

    private initializeForm(previous_answers: boolean, cod_lrpu: number = null) : void {
        if (this.formSelected == null) {
            this.isLoading = false;
            return;
        }
        this.promiseInitializeForm(
            previous_answers,
            cod_lrpu || this.routine.cod_logbook_routines_periodicity_users
        ).subscribe({
            next: (data) => {
                if (this.formSelected != this.routine.cod_form) {
                    this._dataService.changeRoutine({
                        cod_form: this.formSelected,
                    });
                }

                /** Need to verify for the as needed routine don`t refresh */
                const status =
                    this.routine.cod_routine_status == RoutinesService.STATUS_DONE
                        ? RoutinesService.STATUS_PENDING
                        : this.routine.cod_routine_status;
                this.routine.cod_routine_status = status;
                this.onChangeStatus.emit(status);

                this.getForm();
            },
            error: (err) => {
                this._authService.errCheck(err);
            },
            complete: () => (this.isLoading = false),
        });
    }
    private undoInitializationForm(cod_lrpu: number = null) : void {
        this.promiseUndoInitializationForm(cod_lrpu || this.routine.cod_logbook_routines_periodicity_users).subscribe({
            next: (data) => {
                this.getForm();
                this.onUndoInitializationForm.emit();
            },
            error: (err) => {
                this._authService.errCheck(err);
            },
            complete: () => (this.isLoading = false),
        });
    }
    public callUndoInitializationForm() : void {
        this.isLoading = true;
        if (this.routine.cod_logbook_routines_periodicity_users > 0) {
            this.undoInitializationForm();
        } else {
            this.onNeedLrpu.emit(cod_lrpu => {
                this.undoInitializationForm(cod_lrpu);
            });
        }
    }
    private promiseUndoInitializationForm(cod_lrpu: number) : Observable<any> 
    {
        return this.isCollab
            ? this._formService.undoInitializationCollabForm(this.user_id, cod_lrpu)
            : this._formService.undoInitializationForm(this.user_id, cod_lrpu);
    }
    /************ SAVE PARTIAL ************/

    private promiseSavePartial($event) : Observable<any> 
    {
        let promise = null;
        let params = {
            'cod_user': this.user_id, 
            'int_type': $event.question.int_type, 
            'answer': $event.data,
            'cod_answer': $event.question?.cod_answer || null, 
            'compliance': this.calculateCompliance(),
            'completeness': this.calculateCompleteness(),
        };

        if (this.isCollab) {
            params['cod_logbook_routines_periodicity_users_collab'] = this.routine.cod_logbook_routines_periodicity_users;
            params['cod_form_answer_question_collab'] = $event.question.cod_form_answer_question_collaborative;
            promise = this._formService.savePartialCollab(params);
        } else {
            params['cod_logbook_routines_periodicity_users'] = this.routine.cod_logbook_routines_periodicity_users; 
            params['cod_form_answer_question'] = $event.question.cod_form_answer_question;
            promise = this._formService.savePartial(params);           
        }
        return promise;
    }

    public savePartial($event) : void {
        this.savingLoading.set($event.question.cod_form_question, true);
        this.isPartialLoading = true;
        this.questionValid.set($event.question.question_description, $event.valid);

        this.form.categories = this.reWriteFormCategories(this.form.categories);
        this.allQuestions = this.getAllQuestions();
        
        this.form.categories.forEach((category, indexC, arrayC) => {          
            category.arr_questions.forEach((q, indexQ, arrayQ) => {
                const is_q = this.isCollab 
                    ? q.cod_form_answer_question_collaborative == $event.question.cod_form_answer_question_collaborative 
                    : q.cod_form_answer_question == $event.question.cod_form_answer_question;
                    
                if(is_q) {
                    this.form.categories[indexC].arr_questions[indexQ].answer = $event.data;
                }
            });
            this.getPercentageCategories();
        });
        
        this.blockSubmitForIncompleteForms();

        this.createAction($event.question);

        this.promiseSavePartial($event)
            .pipe(
                shareReplay(),
                finalize(() => {
                    this.savingLoading.set($event.question.cod_form_question, false);
                    this.isPartialLoading = Array.from(this.savingLoading.values()).some(value => value);
                })
            )
            .subscribe({
                next: (result) => {
                    if (result.code != 888 || result.type != 'success') {
                        this.failedPartials.set($event.question.cod_form_question, {
                            question_description: $event.question.question_description,
                            params: $event
                        });
                        this._notify.error({text: 'ERROR.GENERIC_ERROR', translate: true});
                        this.blockSubmitForIncompleteForms();
                    }
                },
                error: (error) => {
                    this.failedPartials.set($event.question.cod_form_question, {
                        question_description: $event.question.question_description,
                        params: $event
                    });
                    this._notify.error({text: 'ERROR.GENERIC_ERROR', translate: true});
                    this.blockSubmitForIncompleteForms();
                    this._authService.errCheck(error);
                },
            });
    }

    public retrySavePartial(cod_form_question: number): void {
        const params = this.failedPartials.get(cod_form_question)?.params;
        if (params) {
            this.failedPartials.delete(cod_form_question);
            this.savePartial(params);
        }
    }

    public savePartialLS(dados: any) : void {

        if (dados.int_type == QuestionType.LS_LOCATIONS) {
            if(dados.cod_answer_option == null){
                this.areaSelected = dados.cod_answer_option;
                this.subareaSelected = dados.cod_answer_option;
                this.equipmentSelected = dados.cod_answer_option;
                this.machineSelected = dados.cod_answer_option;
            } else {
                this.locationSelected = dados.cod_answer_option;
            }
        }
        if (dados.int_type == QuestionType.LS_AREA) {
            this.areaSelected = dados.cod_answer_option;
            if(dados.cod_answer_option == null){
                this.subareaSelected = dados.cod_answer_option;
                this.equipmentSelected = dados.cod_answer_option;
                this.machineSelected = dados.cod_answer_option;
            }
        }
        if (dados.int_type == QuestionType.LS_SUBAREA) {
            this.subareaSelected = dados.cod_answer_option;
            if(dados.cod_answer_option == null){
                this.equipmentSelected = dados.cod_answer_option;
                this.machineSelected = dados.cod_answer_option;
            }
        }
        if (dados.int_type == QuestionType.LS_EQUIPMENTS) {
            this.equipmentSelected = dados.cod_answer_option;
            if(dados.cod_answer_option == null){
                this.machineSelected = dados.cod_answer_option;
            }
        }
        if (dados.int_type == QuestionType.LS_MACHINES) {
            this.machineSelected = dados.cod_answer_option;
        }

        //when deleting area must delete also subarea
        let areaToDelete = null;
        if(!this.LSValid(dados)){
            let c = null;
            let q = null;
            let t = null;
            this.form.categories.forEach((category, indexC, arrayC) => {          
                category.arr_questions.forEach((question, indexQ, arrayQ) => {
                    if(dados.cod_form_question == question.cod_form_question &&
                        dados.int_order == question.int_order &&
                        dados.int_type == question.int_type){
                            c = indexC;
                            q = indexQ;
                            t = question.int_type;
                    }
                });
            });
            if(c != null && q != null && t != null && 
                this.form.categories[c].arr_questions[q+1] != null && t == QuestionType.LS_AREA){              
                    for(let i = q+1; i < this.form.categories[c].arr_questions.length; i++){
                        if(this.form.categories[c].arr_questions[i].int_type == QuestionType.LS_SUBAREA){
                            let dadosSubarea = this.form.categories[c].arr_questions[i];
                            areaToDelete = {
                                data: "",
                                valid: false,
                                question: {
                                    cod_form_question: dadosSubarea.cod_form_question,
                                    cod_form_answer_question: dadosSubarea.cod_form_answer_question,
                                    cod_form_answer_question_collaborative: dadosSubarea.cod_form_answer_question_collaborative,
                                    int_type: dadosSubarea.int_type,
                                    cod_answer: null,
                                    question_description: dadosSubarea.question_description,
                                }
                            };
                        }
                    }
                    
            }
        }

        this.savePartial({
            data: dados.str_answer_text || dados.answer,
            valid: this.LSValid(dados),
            question: {
                cod_form_question: dados.cod_form_question,
                cod_form_answer_question: dados.cod_form_answer_question,
                cod_form_answer_question_collaborative: dados.cod_form_answer_question_collaborative,
                int_type: dados.int_type,
                cod_answer: dados.cod_answer_option,
                question_description: dados.question_description,
            }
        });

        if(areaToDelete){
            this.savePartial(areaToDelete);
            this.refresh();
        }
    }

    /************ GET FORM ************/

    private getFormWithoutAnswers(cod_form: number) : void {
        if (cod_form == null) return;
        if (this.formLoaded != null && cod_form == this.formLoaded) return;

        this.formLoaded = cod_form;        
        
        this.isLoadingForm = true;
        this._formService.getFormWithoutAnswers(this.user_id, cod_form, this.myTaskMainDate).subscribe({
            next: (data) => {
                this.form = data.content.results;
                this.initCategoriesOpen();
            },
            error: (err) => {
                this._authService.errCheck(err);
            },
            complete: () => (this.isLoadingForm = false),
        });        
    }

    private promiseGetForm() : Observable<any> 
    {
        let promise = null;
        let params = {
            'cod_user': this.user_id, 
            'cod_form': this.routine.cod_form,
            'upload_url': this.upload_api_url
        };

        if (this.isCollab) {
            params['cod_logbook_routines_periodicity_users_collab'] = this.routine.cod_logbook_routines_periodicity_users;
            promise = this._formService.getCollabForm(params);
        } else {
                        params['cod_logbook_routines_periodicity_users'] = this.routine.cod_logbook_routines_periodicity_users; 
            promise = this._formService.getForm(params);           
        }
        return promise;
    }

    private getForm() : void {
        if (this.routine.cod_form == null) return;
        if (this.isLoadingForm) return;

        this.isLoadingForm = true;

        this.promiseGetForm().subscribe({
            next: (data) => {
                this.form = data.content.results;
                this.form.categories = this.reWriteFormCategories(this.form.categories);

                this.str_hashtag = this.form.str_hashtag;
                let firstArray = this.form.categories.find((c) => c.arr_questions && c.arr_questions.length > 0);
                this.errorCreatingFac = this.isCollab
                    ? firstArray?.arr_questions[0]?.cod_form_answer_question_collaborative == null
                    : firstArray?.arr_questions[0]?.cod_form_answer_question == null;

                this.hasFac = !this.errorCreatingFac;
                this.disableRules();

                this.initCategoriesOpen();
                this.allQuestions = this.getAllQuestions();
                this.createLoadingsAndValidation();
                this.prepareQuestions();
                this.initActions();
                this.getPercentageCategories();
                this.initActions();
                setTimeout(() => {
                    this.blockSubmitForIncompleteForms();
                });
            },
            error: (err) => {
                this._authService.errCheck(err);
            },
            complete: () => (this.isLoadingForm = false),
        });
    }

    private getAllQuestions() : Array<Question> {
        let q = [];
        this.form.categories?.forEach(c => q = [...q, ...c.arr_questions]);
        return q;
    }

    private prepareQuestions() : void {
        this.prepareMultiSelect();
        this.prepareSingleSelect();
        this.prepareLsLocation();
        this.prepareLsArea();
        this.prepareLsSubArea();
        this.prepareLsEquipment();
        this.prepareLsMachine();
    }

    private prepareSingleSelect() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.YES_NO || question.int_type == QuestionType.SINGLE_SELECTION)
            .forEach(question => 
                question.cod_form_question_choice = question.answer ? Number(question.answer) : null
            )
        );
    }

    private prepareMultiSelect() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.MULTI_SELECTION)
            .forEach(question => {
                question.selected_options = [];
                if (question.answer) {
                    question.answer.split(',').map(a => {
                        let c = question.arr_choices.find(choice => choice.cod_form_question_choice == a);
                        if (c != null) question.selected_options.push(c);
                    });
                }

                question.bol_show_actions = question.selected_options.filter(option => option.bol_creates_action).length > 0;
          })
        );
    }

    private prepareLsLocation() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.LS_LOCATIONS)
            .forEach(q => {
                this.locationSelected = q.cod_answer_option 
            }));
    }

    private prepareLsArea() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.LS_AREA)
            .forEach(q => {
                this.areaSelected = q.cod_answer_option 
            }));
    }

    private prepareLsSubArea() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.LS_SUBAREA)
            .forEach(q => {
                this.subareaSelected = q.cod_answer_option 
            }));
    }

    private prepareLsEquipment() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.LS_EQUIPMENTS)
            .forEach(q => {
                this.equipmentSelected = q.cod_answer_option 
            }));
    }

    private prepareLsMachine() : void {
        this.form.categories.map(c => c.arr_questions
            .filter(question => question.int_type == QuestionType.LS_MACHINES)
            .forEach(q => {
                this.machineSelected = q.cod_answer_option 
            }));
    }
  
    /************ EMAIL ************/

    loadInitEmailSubscriptions() {
        const searchUserSubscription = this._modalEmailService.searchUser.subscribe((data: any) => {
            if (data) {
                const privilege = this.privilegeEmail ? 'FORMS_SUBMIT_EMAIL_NOTIFICATION' : 'NOT_PRIVILEGE';
                const cod_user_location = this.privilegeEmail ? null : this._authService.getAuthenticatedUser().cod_location;
  
                this._fivewhyService
                    .getUsersList(
                        data.str_term,
                        environment().uploadGetFilesApiUrl + '/',
                        this._authService.getAuthenticatedUser().id,
                        privilege,
                        cod_user_location
                    )
                    .subscribe({
                        next: (result: any) => {
                            data.callback(result.content.results);
                        },
                        error: (err) => {
                            this._authService.errCheck(err);
                            console.log(err);
                        },
                    });
            }
        });
  
  
        const modalAction = this._modalEmailService.modalAction.subscribe((data: any) => {
            if (data) {
                if (!data.skipEmail) {
                    this.sendEmailObject = {
                        cod_user: this._authService.getAuthenticatedUser().id,
                        cod_form: this.form.cod_form,
                        cod_users_to_send_email: data.userList.map(user => user.cod_user).toString(),
                        cod_lrpu: this.routine.cod_logbook_routines_periodicity_users,
                        isCollab: this.isCollab, 
                    };
                    this.shouldSendEmail = true;
                }
                this.submit();
                this.unsubscribeEmailModal();
            }
        });
  
        this.modalSubscriptions.push(searchUserSubscription);
        this.modalSubscriptions.push(modalAction);
    }

    unsubscribeEmailModal() {
      this._modalEmailService.resetModal();
  
      this.modalSubscriptions.forEach((subscription: any) => {
        subscription.unsubscribe();
      })
    }

    /************ VALIDATIONS ************/

    private cleanValidations() : void {
        this.errorMessageValidation = null;
        this.showErrorValidation = false;
    }

    private createLoadingsAndValidation(): void {
        this.savingLoading = new Map();
        this.isPartialLoading = false;
        this.questionValid = new Map();
        this.failedPartials = new Map();
        this.form.categories?.map(c => {
            c.arr_questions.map(q => {                
                this.savingLoading.set(q.cod_form_question, false); 
                if (!q.bol_optional) {
                    this.questionValid.set(
                        q.question_description, 
                        //ADD WEIRD VALIDATION BECAUSE RANGE OF LIVESEARCH CHANGES AFTER MACHINES LIVESEARCH
                        ((q.int_type >= QuestionType.LS_USER && q.int_type <= QuestionType.LS_INDICATORS) || q.int_type == QuestionType.LS_MACHINES)
                            ? this.LSValid(q) 
                            : true
                    );
                }
            });
        });
    }

    public setValidation($event) : void {
        this.questionValid.set($event.question_description, $event.isValid);
    }

    private isValid() : string {
        let inputsInvalid = [];
        let inputsInvalidMap = new Set<string>();
        this.questionValid.forEach((v, k) => {
            if (!v) inputsInvalidMap.add(k);
        });
        this.failedPartials.forEach((v, k) => {
            inputsInvalidMap.add(v.question_description); 
        });
        inputsInvalid = Array.from(inputsInvalidMap);
        return inputsInvalid.length == 0 ? null : `<br>${inputsInvalid.join('<br>')}`;
    }

    private isActionsValid() : { valid: boolean, isDateDueError: boolean } {
        let valid = true;
        let isDateDueError = false;
        this.actions.forEach(al => {
            al.forEach(a => {
                if (!a.cod_user || 
                    !a.dat_due || 
                    !a.str_description ||
                     a.str_description.trim() == '' ||
                    !a.meetings || 
                     a.meetings.trim() == ''
                ) {
                    valid = false;
                } else if (moment(a.dat_due) > moment().add(5, 'year')) {
                    valid = false;
                    isDateDueError = true;
                }
            });
        });

        return { valid, isDateDueError };
    }

    private LSValid(question) : boolean {
        return question.bol_optional 
            ? true
            : Boolean(question.cod_answer_option);
    }

    /************ SUBMIT ************/    
    public send() {
        this.showErrorValidation = false;
        this.isSubmiting = true;

        const q_valid: string = this.isValid();
        if (q_valid) {
            this.errorMessageValidation = q_valid;
            this.showErrorValidation = true;            
            setTimeout(()=>{
                this.showErrorValidation = false;                   
            }, 5000);
            this.isSubmiting = false;
            return;
        }
        const actionValid = this.isActionsValid();
        if (!actionValid.valid) {
            this.errorMessageValidation = actionValid.isDateDueError ? this.errorDueDateMessage : this.errorActionMessage;
            this.showErrorValidation = true;
            setTimeout(()=>{
                this.showErrorValidation = false;                
            }, 5000);
            this.isSubmiting = false;
            return;
        }
  
        if (this.privilegeEmail) {
            this._modalEmailService.openModal();
            this.isSubmiting = false;
        } else {
            this.submit();
        }
    }

    public submit() : void {
        
        this.isLoading = true;
        this.isSubmiting = true;

        let actionsToSave = this.prepareActionsToSave();

        if(actionsToSave.length > 0){
            this._actionsService.postNewAction(actionsToSave).subscribe({
                next: (data) => this.onChangeStatus.emit(RoutinesService.STATUS_DONE),
                error: (err) => this._authService.errCheck(err),
                complete: () => {
                    this.isLoading = false;
                    this.isSubmiting = false;
                },
            });
        }else{
            this.onChangeStatus.emit(RoutinesService.STATUS_DONE);
            this.isLoading = false;
            this.isSubmiting = false;
        }
        if(this.shouldSendEmail) {
            this._myFormsService.sendEmailSubmitForms(this.sendEmailObject).subscribe(() => {
              this.shouldSendEmail = false;
            });
        }
    }

    /************ ACTIONS ************/
    private prepareActionsToSave() : Array<Action> {
        this.arr_actions = [];
        this.actions.forEach((value, key) => {
            let actions = this.actions.get(key);
            if(Array.isArray(actions) && actions.length > 0){
                actions.forEach(action => {
                    this.arr_actions.push(action);
                });
            }
        });

        return this.arr_actions;
    }

    private initActions() : void {
        this.actions = new Map();
        this.form.categories.map(
            c => c.arr_questions.map(
                q => this.createAction(q)
            )
        );
    }

    private createAction(question: Question) : void {
        if (this.questionMustHaveAction(question) && !this.questionHasAction(question.cod_form_question)) {
            this.addAction(question.cod_form_question);
        } else if(!this.questionMustHaveAction(question) && this.questionHasAction(question.cod_form_question)){
            this.actions.set(question.cod_form_question, []);
        }
    }

    private questionHasAction(cod_form_question: number) : boolean {
        return this.actions.get(cod_form_question)?.length > 0;
    }

    /** Verifies that has to render an action based on the question */
    public renderAction(question: Question) : boolean {
        if (this.questionMustHaveAction(question)) {
            return this.questionHasAction(question.cod_form_question);
        }
        return false;
    }

    private questionMustHaveAction(question: Question) : boolean {
        switch (question?.int_type) {
            case 1: return this.renderActionText(question);
            case 2:
            case 3: return this.renderActionSelection(question);
            case 4: return this.renderActionMultiSelection(question);
            default: return false;
        }
    }

    private renderActionText(question: Question) : boolean {
        return question.bol_creates_action && question.answer?.length > 0;
    }

    private renderActionSelection(question: Question) : boolean {
        return (question.arr_choices?.find(
            c => c.cod_form_question_choice == question.cod_form_question_choice && c.bol_creates_action)
        ) != null;
    }

    private renderActionMultiSelection(question: Question) : boolean {
        return (question.selected_options?.find(
            c => c.bol_creates_action)
        ) != null;
    }

    public addAction(cod_form_question: number) : void {
        let actions = this.actions.get(cod_form_question);
        
        if (actions == null) {
            actions = [];
        }
        actions.push(new Action());

        this.actions.set(cod_form_question, actions);
    }

    public onChangeAction(dados) : void {
        let actions = this.actions.get(dados.question);

        if (dados.deleted == true) {
            actions.splice(dados.index, 1);
        } else {
            let action = actions[dados.index];
            action.cod_user = dados.userId;
            action.dat_due = dados.dueDate;
            action.str_description = dados.actionDescription;
            action.cod_usu_ins = this.user_id;
            action.str_sources = this.translations.str_name;
            action.cod_form = this.routine.cod_form;
            action.meetings = dados.meetings;

            if (this.isCollab) {
                action.cod_logbook_routines_periodicity_users_collab = this.routine.cod_logbook_routines_periodicity_users;                
            } else {
                action.cod_logbook_routines_periodicity_users = this.routine.cod_logbook_routines_periodicity_users;
            }

            actions[dados.index] = action;
        }

        this.actions.set(dados.question, actions);
    }

    private initCategoriesOpen(): void {
        this.isVisible = [];
        this.form?.categories?.forEach(c => this.isVisible[c.cod_forms_category] = true);
    }

    public onClick(key: number): void {
        this.isVisible[key] = !this.isVisible[key];
    }

    public categoryTrackBy(index, item) {
        return `${index}-${item.cod_forms_category}`
    }

    public questionsTrackBy(index, item) {
        return `${index}-${item.cod_form_question}`
    }

    private getPercentageCategories(): void {
        this.form.categories.forEach((category) => {             
            this.compliance_categories[category.cod_forms_category] = FormsHelper.calculateComplianceForm(category.arr_questions);    
        });
    }

    private blockSubmitForIncompleteForms(): void {
        this.isSubmitDisabled = !!this.isValid();
    }

    private reWriteFormCategories(form_categories) {
        return form_categories?.map((c) => {
            let cod_regional = null;
            let cod_world = null;
            let cod_location = null;
            c.arr_questions = c.arr_questions.map(q => {
                if (q.int_type == QuestionType.LS_WORLDS) {
                    if (q.cod_answer_option == null) {
                        cod_world = null;
                    } else {
                        cod_world = q.cod_answer_option;
                    }
                }

                if (q.int_type == QuestionType.LS_REGIONAL) {
                    if (q.cod_answer_option == null) {
                        cod_regional = null;
                    } else {
                        cod_regional = q.cod_answer_option;
                    }
                }

                if (!c.arr_questions.some(d =>d.int_type == QuestionType.LS_WORLDS)) {
                    cod_world = this.user.cod_world;
                }

                if (!c.arr_questions.some(d =>d.int_type == QuestionType.LS_REGIONAL)) {
                    cod_regional = this.user.cod_regional;
                }

                if (!c.arr_questions.some(d =>d.int_type == QuestionType.LS_LOCATIONS)) {
                    cod_location = this.user.cod_location;
                }

                return {
                    ...q,
                    cod_world: cod_world,
                    cod_regional: cod_regional, 
                    cod_location: cod_location
                }
            }); 

            return {
                ...c  
            }
        });

    }

    get myTaskMainDate(): string{
        return this._routineStore.getMyTaskMainDate();
    }

}