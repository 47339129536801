import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LogbooksService} from '../services/logbooks.service';
import {AreaService} from '../services/area.service';
import {SubareaService} from '../services/subarea.service';
import {Logbook} from '../models/logbook.model';
import {LogbookRoutineService} from '../services/logbook-routine.service';
import {Periodicity} from '../models/periodicity.model';
import {WeekDay} from '../models/weekday.model';
import {MonthWeek} from '../models/monthweek.model';
import {TranslateService} from '@ngx-translate/core';
import {ValidationsService} from '../services/validations/validations.service';
import {PeriodicityTypesService} from '../services/periodicity-types.service';
import {Routine} from '../models/routine.model';
import {FormsDynamicAdminService} from '../../forms/forms-dynamic/services/forms-dynamic.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import { UsersService } from '../../admin/services/users.service';
import { NotificationService } from "./../../main/services/notification.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription, timer } from "rxjs";
import { SocketService } from "app/main/services/socket.service";

@Component({
    selector: 'app-logbook-persist',
    templateUrl: './logbook-persist.component.html',
    styleUrls: ['./logbook-persist.component.css']
})
export class LogbookPersistComponent implements OnInit, OnChanges {

    @ViewChild('routineForm', { static: true }) routineFormContainer: ElementRef;
    @ViewChild('logbookFormContainer', { static: true }) logbookFormContainer: ElementRef;
    @Input('codLogbooks') id;
    @Output() backEvent = new EventEmitter<Object>();

    logbook: Logbook;
    listAreas = [];
    selectedArea = [];
    routine: any;
    selectedWeek = [];
    selectedPeriodicity = [];
    selectedDay = [];
    selectedForm = [];
    selectedFormName = '';
    showEditRoutine: boolean = false;
    periodicityList = [];
    weekDayList: WeekDay[];
    monthWeekList: MonthWeek[];
    formList = [];
    showDayOfWeek: boolean;
    showWeekOfMonth: boolean;
    taSetupRoutine: any = {};
    searchTerm: string;
    _selectedItem: any;
    eventSearch: any;
    //Index of routine element on list - used to scroll page
    indexRoutineElm: any;
    placeholderTypeAheadRoutine: string = '';
    isLoading: boolean = false;
    awaitConfirmDelete: boolean = false;
    deleteRoutineId: number = 0;
    showError: boolean = false;
    errorMessage: string = '';
    showSuccessRoutine: boolean = false;
    cod_user: number = this._authService.getAuthenticatedUser().id;
    dsc_form_privilege: string = 'ADMIN_FORMS_LISTING';
    paramsSelected: any;
    worldList: any[];
    regionalList: any[];
    locationsList: any[];
    areaList: any[];
    subareaList: any[];
    listAux: any = [];
    typeChecklist: any;
    loadingConfig: any;
    bol_show_shift: boolean = false;
    options: any = [];
    selectedType: boolean;
    arr_edit_routines: any = [];
    bol_unsaved_changes: boolean = false;
    isEdit: boolean = false;
    cannotChangeType: boolean = false;
    arr_del_routines: any = [];
    arr_copies: any = [];
    bol_show_copies_hint: boolean = false;
    bol_cannot_change_type_hint: boolean = false;

    subscriptionSocket: Subscription;
    copyUpdatedTranslation: string = '';
    user_name: string;
    updatedNotified: boolean = false;
    mainCheckListCod: number = 0;

    constructor(private _logbookService: LogbooksService, private _route: ActivatedRoute, private _router: Router, private _logbookAreaService: AreaService,
                private _logbookRoutineService: LogbookRoutineService, private _translateService: TranslateService, public _validationService: ValidationsService,
                private _periodicityTypeServive: PeriodicityTypesService, private _formService: FormsDynamicAdminService, private _authService: AuthService,
                private _usersGroupPrivilegesService: UsersService, private _logbookSubAreaService: SubareaService, private _notify: NotificationService, private _socketService: SocketService) {
        this.routine = new Routine(0);
        this.showError = false;
        this.errorMessage = '';
        this.id = 0;
        this.logbook = new Logbook();
        this.selectedArea = [];
        this.selectedWeek = [];
        this.selectedPeriodicity = [];
        this.selectedDay = [];
        this.selectedForm = [];
        this.paramsSelected = [];
        this.worldList = [];
        this.selectedType = true;

        this.weekDayList = [
            //Todo: rever
            new WeekDay('0', 'GENERAL.MONDAY', this._translateService),
            new WeekDay('1', 'GENERAL.TUESDAY', this._translateService),
            new WeekDay('2', 'GENERAL.WEDNESDAY', this._translateService),
            new WeekDay('3', 'GENERAL.THURSDAY', this._translateService),
            new WeekDay('4', 'GENERAL.FRIDAY', this._translateService),
            new WeekDay('5', 'GENERAL.SATURDAY', this._translateService),
            new WeekDay('6', 'GENERAL.SUNDAY', this._translateService)
        ];

        this.monthWeekList = [
            //Todo: rever
            new MonthWeek('0', 'GENERAL.FIRST_MONTH_WEEK', this._translateService),
            new MonthWeek('1', 'GENERAL.SECOND_MONTH_WEEK', this._translateService),
            new MonthWeek('2', 'GENERAL.THIRD_MONTH_WEEK', this._translateService),
            new MonthWeek('3', 'GENERAL.FOURTH_MONTH_WEEK', this._translateService),
        ];

        this.typeChecklist = [];
        this._translateService.get('LOGBOOK.FUNCTIONAL').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 0, str_description: translate});
        });
        this._translateService.get('LOGBOOK.COLLAB').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 1, str_description: translate});
        });
        
        this.loadingConfig = [];
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
        this.loadingConfig['areas'] = false;

        this.showDayOfWeek = false;
        this.showWeekOfMonth = false;

        this._formService.list('', this.cod_user, this.dsc_form_privilege, [this._authService.getAuthenticatedUser().cod_world]).subscribe(data => {
                this.formList = this._formService.getArrayContent(data);
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );

        this.searchTerm = '';
        this._validationService.boolSubmit = false;

        this.user_name = this._authService.getAuthenticatedUser().name;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.logbook.routines, event.previousIndex, event.currentIndex);
        this.orderRoutines();
    }

    orderRoutines() {
        this.logbook.routines = this.logbook.routines.map((val, i) => {
            val.int_order = i + 1;
            if (val.cod_routine != 0) {
                this.arr_edit_routines.push(val.int_order);
                this.bol_unsaved_changes = true;
            }
            return val;
        });
    }

    ngOnInit() {
        this._clearType();
        this._searchWorlds(null);
        this.loadPeriodicitiesList();

        this._translateService.onLangChange.subscribe((params) => {
            this.loadPeriodicitiesList()
        });

        this._translateService.get('LOGBOOK.COPY_UPDATED').subscribe((translate) => {
            this.copyUpdatedTranslation = translate;
        });
        
        this._logbookRoutineService.getShifts({cod_user: this._authService.getAuthenticatedUser().id}).subscribe(data => {
            if(data.type == 'success' && data.content.type == 'success'){
                this.options = data.content.results;            
            }
        }, err => {
            let e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        });

        //Get translation for routine typeahead component
        this._translateService.get('LOGBOOK.NAME').subscribe(translate => {
            this.placeholderTypeAheadRoutine = translate;
        });

        let $this = this;
        this.taSetupRoutine = {
            type: 'dynamic',
            placeHolder: this.placeholderTypeAheadRoutine,
            valueProperty: 'cod_routine',
            searchProperty: 'str_autocomplete_text',
            hasImage: false,
            onSelect: (selectedItem: any) => {
                this.routine.str_name = Object.assign([], selectedItem.str_name).join('');
                this.routine.str_description = Object.assign([], selectedItem.str_description).join('');
                this.routine.cod_routine = selectedItem.cod_routine;
                this.routine.cod_form = selectedItem.cod_form; //changed business rule
                if (selectedItem.int_order != undefined)
                    this.routine.int_order = this._nextRoutineOrder();
                this._selectedItem = selectedItem;
                this._selectedItem.cod_routine = selectedItem.cod_routine;
                this.selectedFormName = this._selectedItem.str_form_name;
            },
            asynchDataCall: (value: string, cb: any) => {
                if (value.length < environment().minCharStartLiveSearch && value.length != 0) {
                    return;
                }

                $this.searchActivities(cb, value);
            },
            onBlur: (item: any) => {
                //Validate if selected routine exists
                if (typeof this._selectedItem.cod_routine == typeof undefined || this._selectedItem.cod_routine <= 0) {
                    this.routine = new Routine(this.logbook.cod_logbooks);
                }
            }
        };
        this.get();
    }

    loadPeriodicitiesList() {
        const objParam = {'term': '', 'cod_user': this._authService.getAuthenticatedUser().id};
        this._periodicityTypeServive.getPeriodicitiesList(objParam).subscribe(data => {
            this.periodicityList = [];
            const qty = data.content.length;
            for (let i = 0; i < qty; i++) {
                this.periodicityList.push(new Periodicity(data.content.results[i].cod_periodicity, data.content.results[i].str_description, (data.content.results[i].showweekcombo > 0), (data.content.results[i].showmonthcombo > 0)));
            }
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }
    
    private _updateCannotChangeType(): void {
        this.cannotChangeType = (this._validationService.isLoading(true) || this.logbook.routines.length > 0 || this.logbook.qty_users > 0) ? true : false;
    }

    verifyCanChange(): void {
        if (this.logbook.routines.length > 0 || this.cannotChangeType){
            if(this.logbook.qty_users > 0){
                this._notify.warning({text: 'LOGBOOK.CANNOT_CHANGE_TYPE', translate: true});
            }
            else
                this.bol_cannot_change_type_hint = true;
        }
    }

    private _nextRoutineOrder() {
        return this.logbook.routines.length+1;
    }

    searchActivities(cb, term) {
        try {
            let params = {term: term, 'cod_user': this._authService.getAuthenticatedUser().id, str_privilege: 'ADMIN_LOGBOOKS_ROUTINES'};
            //this.routine.str_name = term;
            this._logbookRoutineService.getRoutinesList(params).subscribe((result: any) => {
                    let resultsArr;
                    if (result.content.length == 1) {
                        resultsArr = [result.content.results];
                    }
                    else {
                        resultsArr = result.content.results;
                    }

                    // if (resultsArr.length <= 0) {
                    //     this._selectedItem = {};
                    // }
                    cb(resultsArr);
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
        catch (e) {
            console.error(e);
        }
    }

    get() {
        this.selectedArea = [];
        this.selectedWeek = [];
        this.selectedPeriodicity = [];
        this.selectedDay = [];
        this.selectedForm = [];
        this.isLoading = true;
        this.bol_show_shift = false;
        this._clearWorldList();
        this._searchWorlds(null);
        if (this.id <= 0) {
            this.logbook = new Logbook();
            this.isLoading = false;
            this.selectedType = true;
        }
        else {
            let objParam = {'cod_logbooks': this.id, 'cod_user': this._authService.getAuthenticatedUser().id};
            this._logbookService.getSingleChecklist(objParam).subscribe(data => {
                this.logbook = data.content.results;
                if (this._logbookService.hasDuplicatedRoutines(this.logbook.routines, null, null)) {
                    this._showErrorMessage('LOGBOOK.HAS_DUPLICATED_ROUTINE_2');
                }
                this.listAux = {
                        'worlds': data.content.results.arr_worlds,
                        'regionals': data.content.results.arr_regionals,
                        'locations': data.content.results.arr_location,
                        'subareas': JSON.parse(JSON.stringify(data.content.results.arr_subareas).split('"cod_subarea":').join('"cod_subareas":')),
                        'areas' : [{cod_area: data.content.results.cod_area}],
                        'type': [{bol_is_collab: data.content.results.bol_type_collab ? 1 : 0}]
                    };

                    this.getShiftColumn();
                    this.bol_show_shift = data.content.results.bol_type_collab;
                    this._searchAll(this.listAux);
                    this._updateCannotChangeType();
                    this._searchCopies();
                    this.isLoading = false;
                    this.selectedType = false;
                    
                    this.mainCheckListCod = this.logbook.cod_logbooks;

                    //this.initializeSocket();
                    
                    //this.initializeSocketEventDisconnect();
                    
                    //this._socketService.joinChecklistCopies(this.mainCheckListCod, { name: this.user_name, id: this.cod_user });

                }, err => {
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                    this.selectedType = true;
                }
            );
        }
    }

    public saveLogbook() {
        this.showEditRoutine = false;
        this._validationService.boolSubmit = true;
        this._validationService.boolIsLoading = true;

        if (!this.logbook.str_name) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_NAME');
            return false;
        }        
        
        if (this.paramsSelected['worlds'].length <= 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_WOLRD');
            return false;
        }

        if (this.paramsSelected['regionals'].length <= 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_REGIONAL');
            return false;
        }

        if (this.paramsSelected['locations'].length <=0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_LOCATION');
            return false;
        }

        if (this.paramsSelected['areas'].length <= 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_AREA');
            return false;
        }

        if (this.paramsSelected['subareas'].length <= 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_SUBAREA');
            return false;
        }

        if (this.paramsSelected['type'].length <= 0) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_TYPE');
            return false;
        }

        if (this._logbookService.hasDuplicatedRoutines(this.logbook.routines, null, !this.isEdit)) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.HAS_DUPLICATED_ROUTINE');
            return false;
        }

        //todo: retirar!
        this.logbook.cod_profile = null;
        this.logbook.cod_function = null;
        this.logbook.cod_user = this._authService.getAuthenticatedUser().id;
        this.logbook.cod_subareas = JSON.parse("[" + this.paramsSelected['subareas'] + "]");
        this.logbook.cod_area = parseInt(this.paramsSelected['areas']);
        this.logbook.bol_is_collab = this.paramsSelected['type'] == 1 ? true : false;
        this.logbook.cod_update_user - this._authService.getAuthenticatedUser().id;
        this.logbook.cod_locations = JSON.parse("[" + this.paramsSelected['locations'] + "]");

        const logbook_params = this._logbookService.getLogbookParams(this.logbook);
        
        if (this.id <= 0) {
            this._logbookService.postLogbook(logbook_params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    this.bol_unsaved_changes = false;
                    if (res.type == 'error') {
                        this._showErrorMessage(res.content.message);
                    }
                    else {
                        this._notify.success({text: 'LOGBOOK.INSERTED', translate: true});
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
        else {
            this._logbookService.putLogbook(logbook_params).subscribe(res => {
                this._validationService.boolSubmit = false;
                this._validationService.boolIsLoading = false;
                this.bol_unsaved_changes = false;
                if (res.type == 'error') {
                    this._showErrorMessage(res.content.message);
                }
                else {
                    this._notify.success({text: 'LOGBOOK.EDITED', translate: true});

                    this.mainCheckListCod = this.logbook.cod_logbooks;

                    let params = {
                        'cod_logbooks': this.mainCheckListCod,
                        'cod_user': this.cod_user,
                        'dsc_page_privilege': 'NOT_PRIVILEGE',
                        'str_path': environment().uploadGetFilesApiUrl + '/'
                    };
                    this._logbookService.getUsersByLogbook(params).subscribe(data => {
                        let userList = this._logbookService.getArrayContent(data);
                        if(Array.isArray(userList) && userList.length > 0){
                            userList.forEach(item => {
                                if(item.cod_id != this.cod_user){
                                    this._socketService.joinChecklistCopies(this.mainCheckListCod, { name: item.str_name, id: item.cod_id });
                                }
                            });
                        }
                    }, err => {
                        console.log(err);
                    }); 

                    if (Array.isArray(this.arr_copies) && this.arr_copies.length > 0) {

                        this._logbookService.updateLogbooksCopiedFrom(this.logbook, this.arr_copies, this.arr_del_routines);

                        this._notify.success({text: 'LOGBOOK.COPIED', translate: true});

                        let copiesArray = this.arr_copies;

                        copiesArray.forEach(element => {
                            let params = {
                                'cod_logbooks': element.cod_logbooks,
                                'cod_user': this.cod_user,
                                'dsc_page_privilege': 'NOT_PRIVILEGE',
                                'str_path': environment().uploadGetFilesApiUrl + '/'
                            };
                            this._logbookService.getUsersByLogbook(params).subscribe(data => {
                                let userList = this._logbookService.getArrayContent(data);
                                if(Array.isArray(userList) && userList.length > 0){
                                    userList.forEach(item => {
                                        if(item.cod_id != this.cod_user){
                                            this._socketService.joinChecklistCopies(element.cod_logbooks, { name: item.str_name, id: item.cod_id });
                                        }
                                    });
                                }
                            }, err => {
                                console.log(err);
                            });
                        });
                    
                        //wait some seconds before unsubscribe
                        timer(15000).subscribe(() => {
                            
                            this._socketService.unsubscribeChecklistCopies(this.mainCheckListCod, { name: this.user_name, id: this.cod_user });
                        
                            copiesArray.forEach(element => {
                                this._socketService.unsubscribeChecklistCopies(element.cod_logbooks, { name: this.user_name, id: this.cod_user });
                            });

                            /*
                            if (!this.subscriptionSocket.closed) {
                                this.subscriptionSocket.unsubscribe();
                            }
                            */

                            copiesArray = [];
                        });
                    }

                        this.arr_del_routines = [];
                        this.arr_copies = [];
                        this.bol_cannot_change_type_hint = false;
                        this.cannotChangeType = false;

                        this.backSelected(true);
                    }
                }, 
                err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
    }

    public mandatory($event, elmIndex){
        if($event != 'selected'){
            this.logbook.routines[elmIndex].bol_mandatory = false;
        }
        else{
            this.logbook.routines[elmIndex].bol_mandatory = true;
        }
    }

    public editRoutine(act, elmIndex) {
        if (this.selectedType == false){
            this.showSuccessRoutine = false;
            this.selectedWeek = [];
            this.selectedPeriodicity = [];
            this.selectedDay = [];
            this.selectedForm = [];
            if (act == 0) {
                this._clearShift();
                this.isEdit=false;
                this.routine = null;
                this.routine = new Routine(this.id);
                this.routine.int_order = this._nextRoutineOrder();
                this.routine.previous_periodicity = 0;
                this.selectedFormName = '';
            } else {
                this.routine = act;
                this.isEdit = true;
                this.selectedWeek = [{id: this.routine.val_week_of_month}];
                this.selectedPeriodicity = [{cod_periodicity: this.routine.cod_periodicity}];
                this.selectedDay = [{id: this.routine.val_day_of_week}];
                this.selectedForm = [{cod_form: this.routine.cod_form}];
                this.paramsSelected['shift_selected'] = [];
                this.routine.previous_periodicity = this.routine.cod_periodicity;
                if(this.routine.arr_cod_shifts){
                    this.routine.arr_cod_shifts.map(shift => {
                        this.paramsSelected['shift_selected'].push({cod_shift: shift});
                    });            
                    this.paramsSelected['shift'] = this.routine.arr_cod_shifts;
                }
                if (typeof this.routine.str_form_name !== typeof undefined && this.routine.str_form_name) {
                    this.selectedFormName = this.routine.str_form_name;
                }
                else {
                    this.selectedFormName = '';
                }
            }
            this._selectedItem = Object.assign({}, this.routine);
            this._selectedItem.cod_routines = this._selectedItem.cod_routine;
            this.showEditRoutine = true;
            this.updateElements();
            this.indexRoutineElm = elmIndex;
        }
    }

    public getShiftColumn() {
        if(this.logbook.routines != undefined){
                        
            this.logbook.routines.forEach((item) => {    
                if(item.arr_cod_shifts != undefined){
                    item.concat_shifts = this.options.filter( (option) => item.arr_cod_shifts.find(option2 => option.cod_shift === option2)).map((object) => { return object["str_name"]; }).join(", ");
                }                                            
            });
        }
    }


    public toggleDeleteRoutine(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteRoutineId = id;
    }

    public confirmDeleteRoutine() {
        this.arr_del_routines.push(this.logbook.routines[this.deleteRoutineId])
        this.logbook.routines.splice(this.deleteRoutineId, 1);
        this.orderRoutines();
        this.deleteRoutineId = 0;
        this.awaitConfirmDelete = false;
        this._updateCannotChangeType();
    }

    public saveRoutine() {
        this.routine.cod_profile = null;
        this._validationService.boolSubmitChild = true;

        if (this.routine.cod_form == 0) {
            this.routine.cod_form = null;
        }

        if (this._selectedItem.str_name != this.routine.str_name) {
            this.routine.cod_routine = 0;
        }

        if (!this.routine.cod_routine || this.routine.cod_routine <= 0) {
            this._showErrorMessage('LOGBOOK.ROUTINE_SELECTED_INVALID');
            return false;
        }

        if (!this.routine.cod_periodicity) {
            this._showErrorMessage('LOGBOOK.MESSAGE_ERROR_PERIODICITY_REQUIRED');
            return false;
        }

        if(this.bol_show_shift && this.paramsSelected['shift'].length <= 0){
            this._validationService.boolSubmitChild = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_SHIFT');
            return false;
        }
        if (this._logbookService.hasDuplicatedRoutines(this.logbook.routines, this.routine, !this.isEdit)) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.HAS_DUPLICATED_ROUTINE');
            return false;
        }
        //this.routine.int_order = this.logbook.routines.length+1;
        this.routine.str_form_name = this.selectedFormName;
        if (this.bol_show_shift)
            this.routine.arr_cod_shifts = JSON.parse("[" + this.paramsSelected['shift'] + "]");
        //Check if is including a new routine, or editing a routine that has not yet been saved to the database
        //if (this.routine.id == 0 && this.logbook.routines.indexOf(this.routine) == -1) {
        if (this.logbook.routines.indexOf(this.routine) == -1) {
            this.logbook.routines.push(this.routine);
        }
        this.orderRoutines();
        this.showSuccessRoutine = true;
        this.showEditRoutine = false;
        this._validationService.boolSubmitChild = false;
        this.getShiftColumn();
        this._updateCannotChangeType();
    }

    public cancelRoutine() {
        this.showSuccessRoutine = false;
        this._clearShift();
        if(this.routine.previous_periodicity != 0){
            this.routine.cod_periodicity = this.routine.previous_periodicity;
        }
        this.routine = new Routine(this.id);
        this.showEditRoutine = false;
        this._updateCannotChangeType();
    }

    updateElements() {
        let item = null;
        let periodicityId = this.routine.cod_periodicity;
        if (periodicityId != '0' && this.periodicityList) {
            item = this.periodicityList.filter(function (item) {
                return item.cod_periodicity == periodicityId;
            })[0];
        }

        if (item) {
            this.showDayOfWeek = item.showWeekCombo;
            this.showWeekOfMonth = item.showMonthCombo;
        }
        else {
            this.showDayOfWeek = false;
            this.showWeekOfMonth = false;
        }
    }

    getPeriodicityObj(periodicityId): string {
        if (!this.periodicityList) {
            return '';
        }

        let item = this.periodicityList.filter(function (item) {
            return parseInt(item.cod_periodicity) == periodicityId;
        })[0];

        if (item) {
            return item.str_description;
        }
        else {
            return '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.get();
    }

    backSelected(success: boolean = false) {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        this.bol_unsaved_changes = false;
        if (success) {
            this.id = -1;
        } else {
            this.id = 0;
        }
        this.logbook = new Logbook();
        this.arr_copies = [];
        this.arr_del_routines = [];
        this.cannotChangeType = false;
        this.bol_cannot_change_type_hint = false;
        this.updatedNotified = false;

        this._socketService.unsubscribeChecklistCopies(this.mainCheckListCod, { name: this.user_name, id: this.cod_user });
                                
        this._clearType();
        this._clearShift();
        this._clearWorldList();
        this.selectedType = true;
        this.showSuccessRoutine = false;
        this.backEvent.emit(this.id);
    }

    selectPeriodicity($event) {
        this.routine.cod_periodicity = $event.selectedOptions[0].cod_periodicity;
        this.updateElements();
    }

    selectDayOfWeek($event) {
        this.routine.val_day_of_week = $event.selectedOptions[0].id;
    }

    selectWeekOfMonth($event) {
        this.routine.val_week_of_month = $event.selectedOptions[0].id;
    }

    selectForm($event) {
        this.routine.cod_form = $event.selectedOptions[0].cod_form;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._notify.warning({ text: err, translate: true});
        /*this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            //this.showError = true;
            this._notify.warning(translate);
        });*/
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocationsList();
    }

    private _clearLocationsList() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearAreasList();
    }
    
    private _smartClearLocationsList() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearAreasList();
        } else {
            this._searchAreas(null);
        }

    }

    private _clearAreasList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this.areaList = [];
        this._clearSubAreasList();
    }

    private _smartClearAreasList() {

        if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
                this.areaList.map((area) => {
                    if (area.cod_area == selected.cod_area) arr.push(area);
                });
            });
            this.paramsSelected['areas_selected'] = arr;
            this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
        }
        if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
            this.paramsSelected['areas_selected'] = [];
            this.paramsSelected['areas'] = '';
            this._clearSubAreasList();
        } else {
            this._searchSubAreas(null);
        }

    }

    private _clearSubAreasList() {
        this.paramsSelected['subareas'] = '';
        this.paramsSelected['subareas_selected'] = [];
        this.subareaList = [];
    }

    private _smartClearSubAreasList() {

        if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
                this.subareaList.map((subarea) => {
                    if (subarea.cod_subareas == selected.cod_subareas) arr.push(subarea);
                });
            });
            this.paramsSelected['subareas_selected'] = arr;
            this.paramsSelected['subareas'] = this._implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
        }
        if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
            this.paramsSelected['subareas_selected'] = [];
            this.paramsSelected['subareas'] = '';
        }

    }

    private _clearType() {
        this.paramsSelected['type'] = '';
        this.paramsSelected['type_selected'] = [];
    }

    private _clearShift() {
        this.paramsSelected['shift'] = '';
        this.paramsSelected['shift_selected'] = [];
    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._usersGroupPrivilegesService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_SELECT_AREA').subscribe(data => {
            this.worldList = this._usersGroupPrivilegesService.getArrayContent(data);
            this._setLoading('worlds', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    private _searchLocations(fn) {

        if (this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._usersGroupPrivilegesService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_SELECT_AREA').subscribe(data => {
                this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('locations', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearLocationsList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearLocationsList();
        }

    }

    private _searchRegionals(fn) {
        this._clearRegionalList();

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._usersGroupPrivilegesService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_SELECT_AREA').subscribe(data => {
                this.regionalList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('regionals', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _searchAreas(fn){
        
        if(typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != ''){
            this._setLoading('areas', true);
            let params = {
                arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
                cod_user: this._authService.getAuthenticatedUser().id,
                dsc_page_privilege: 'ADMIN_LOGBOOKS_SELECT_AREA'
            };
            this._usersGroupPrivilegesService.getAreasByLocation(params).subscribe(data => {
                this.areaList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('areas', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearAreasList()
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearAreasList();
        }

    }

    private _searchSubAreas(fn){

        if(typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != ''){
            this._setLoading('subareas', true);
            let params = {
                arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
                cod_user: this._authService.getAuthenticatedUser().id
            };            
            this._logbookSubAreaService.searchSubareaByAreaLoction(params).subscribe(data => {
                this.subareaList = this._logbookAreaService.getArrayContent(data);
                this._setLoading('subareas', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearSubAreasList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearSubAreasList();
        }
    }

    private _searchCopies(){
        this._logbookService.getCopiedCheckLists({'cod_logbooks': this.logbook.cod_logbooks}).subscribe(data => {
            this.arr_copies = (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
        });
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    selectWorlds($event) {
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
        this._searchAreas(null);
    }

    selectAreas($event){
        this.paramsSelected['areas_selected'] = $event.selectedOptions;
        this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');       
        this._searchSubAreas(null);
    }

    selectSubAreas($event){
        this.paramsSelected['subareas_selected'] = $event.selectedOptions;
        this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
    }
    
    selectType($event){
        this.paramsSelected['type'] = this._implodeValues($event.selectedOptions, 'bol_is_collab');
        if(this.paramsSelected['type'] == 1){// 1 - collab, 0 - functional
            this.bol_show_shift = true;
            this.selectedType = false;
        } 
        else{
            this.bol_show_shift = false; 
            this.selectedType = false;
        } 
        if(this.paramsSelected['type'] == '') {   
            this.selectedType = true;
        }
    }
    
    selectShift($event){
        this.paramsSelected['shift'] = this._implodeValues($event.selectedOptions, 'cod_shift');
    }

    private _searchAll(listAux) {
        const $this = this; 
        $this.paramsSelected['type_selected'] =listAux.type;
        $this.paramsSelected['type'] = this._implodeValues(listAux.type, 'bol_is_collab');     
        $this._searchWorlds(() => {
            $this.paramsSelected['worlds_selected'] = listAux.worlds;
            $this.paramsSelected['worlds'] = this._implodeValues(listAux.worlds, 'cod_locations_world');
            $this._searchRegionals(() => {
                $this.paramsSelected['regionals_selected'] = listAux.regionals;
                $this.paramsSelected['regionals'] = this._implodeValues(listAux.regionals, 'cod_locations_regional');
                $this._searchLocations(() => {
                    $this.paramsSelected['locations_selected'] = listAux.locations;
                    $this.paramsSelected['locations'] = this._implodeValues(listAux.locations, 'cod_location');
                    $this._searchAreas(() =>{
                        $this.paramsSelected['areas_selected'] =listAux.areas;
                        $this.paramsSelected['areas'] = this._implodeValues(listAux.areas, 'cod_area');
                        $this._searchSubAreas(() =>{
                            $this.paramsSelected['subareas_selected'] =listAux.subareas;
                            $this.paramsSelected['subareas'] = this._implodeValues(listAux.subareas, 'cod_subareas');
                        });
                    });
                    /*
                    $this._searchEquipments(() => {
                        $this.paramsSelected['equipments_selected'] = listAux.equipments;
                        $this.paramsSelected['equipments'] = this._implodeValues(listAux.equipments, 'cod_equipments');
                    });*/
                });
            });
        });
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    /*initializeSocket() {
        this.subscriptionSocket = this._socketService.subscribeChecklistCopies().subscribe((socket_msg: any) => {
            if(!this.updatedNotified){
                console.log('socket_msg: ' + JSON.stringify(socket_msg));
                if(Array.isArray(socket_msg) && socket_msg.length > 0){
                    for(let i = 0; i < socket_msg.length && !this.updatedNotified; i++){
                        const msg = socket_msg[i];
                        
                        if(msg.cod_user != this.cod_user && msg.cod_logbooks == this.mainCheckListCod) {
                            console.log('notified');
                            this._notify.info(this.copyUpdatedTranslation, "", { timeOut: 10000 });
                            this.updatedNotified = true;
                        }
                    }
                }
            }
        });
    }*/
}