import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {AuthGuard} from '../../guards/auth-guard';
import {ComponentsModule} from '../../components/components.module';

import {AnswerFormComponent} from './answer-form/answer-form.component';
import {FormsDynamicAdminComponent} from './forms-dynamic-admin/forms-dynamic-admin.component';
import {FormsDynamicEditComponent} from './forms-dynamic-edit/forms-dynamic-edit.component';
import {FormsDynamicEditQuestionComponent} from './forms-dynamic-edit/edit-question/edit-question.component';
import {FormsDynamicEditCategoryComponent} from './forms-dynamic-edit/edit-category/edit-category.component';
import {FormsCategoryComponent} from './forms-dynamic-edit/forms-category/forms-category.component';
import {FormsDynamicCreateActionComponent} from './forms-dynamic-show/create-action/create-action.component';
import {FormsDynamicReportsComponent} from './forms-dynamic-reports/forms-dynamic-reports.component';

import {MainService} from './services/main.service';
import {FormsDynamicAdminService} from './services/forms-dynamic.service';
import {FormsDynamicFiltersService} from './services/forms-filters.service';
import {FormsDynamicReportsService} from './services/forms-reports.service';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
    {
        path: 'forms-dynamic-admin',
        component: FormsDynamicAdminComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
    },
    {
        path: 'forms-dynamic-new',
        component: FormsDynamicEditComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
    },
    {
        path: 'forms-dynamic-edit/:cod_form',
        component: FormsDynamicEditComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
    },
    {
        path: 'forms-dynamic-reports',
        component: FormsDynamicReportsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
    },
];

@NgModule({
    declarations: [
        AnswerFormComponent,
        FormsDynamicAdminComponent,
        FormsDynamicEditComponent,
        FormsDynamicEditQuestionComponent,
        FormsDynamicEditCategoryComponent,
        FormsCategoryComponent,
        FormsDynamicCreateActionComponent,
        FormsDynamicReportsComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        TranslateModule,
        ComponentsModule,
        DragDropModule,
    ],
    exports: [
        AnswerFormComponent,
        FormsDynamicAdminComponent, 
        FormsDynamicReportsComponent, 
        FormsDynamicEditComponent
    ],
    providers: [
        MainService, 
        FormsDynamicAdminService, 
        FormsDynamicFiltersService, 
        FormsDynamicReportsService
    ],
})
export class FormsDynamicModule {
}
