import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {MeetingsService} from '../../meetings/services/meetings.service';
import { UserPreferencesService } from "app/user-preferences/services/user-preferences.service";
import {TranslateService} from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'chart-time-working',
  templateUrl: './chart-time-working.component.html',
  styleUrls: ['./chart-time-working.component.css']
})
export class ChartTimeWorkingComponent implements OnChanges {
  @Input() cod_meeting;
  @Input() chartOptions;

  chart;
  options: Object;
  data;
  average:number = 0;
  bol_loading:boolean;
  bol_show_hint = false;
  addPlural: boolean;
  overlayMessage = '';

  constructor(private _authService: AuthService, private _meetingsService:MeetingsService, private _userPreferencesService: UserPreferencesService, private _translateService: TranslateService) {
    this.bol_loading = true
    this.chartOptions = {
      width: 135,
      height: 135,
      fontSize: 45
    }
  }

  updateInfoChart() {
    this._meetingsService.getMeetingsActionsAverage(this.cod_meeting, this._authService.getAuthenticatedUser().id).subscribe(
      data => {
        if (data.code === 888 && data.content.code === 888 ) {
          this.updateSeriesData(data.content.results.average);
        } else {
        }
      },
      err => { this._authService.errCheck(err); console.log(err); }
      
    );
  }

  updateSeriesData(average): void {
    this.average = Math.round(average);

    let dataParam = [
      {name:'', y:average, color:'#3fc048'}
    ];

    if(this.chart){
      this.chart.series[0].setData(dataParam);
    }
    this.bol_loading = false;
  }

  ngOnInit(){
    // this.updateInfoChart()
    const $this = this
    $this._translateService.get('MEETING.OVERLAYMESSAGE').subscribe(translate => {
        $this.overlayMessage = translate;
    });

    this._translateService.onLangChange.subscribe(() => this.setPlural());
  }

  ngOnChanges(changes: SimpleChanges):void {
    this.updateInfoChart()
  }

  saveInstance(chartInstance) {
    this.chart = chartInstance;
  }

  setPlural(){
    let userLang = localStorage.getItem("userPreferencesLanguage") || "en";
    let localUserLang = this._userPreferencesService.getLocalLanguage();
    
    if(localUserLang != userLang) userLang = localUserLang;

    this.addPlural = true;
    if(userLang == 'ru' || userLang == 'uk'){
      this.addPlural = false;
    }
  }
}