import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from '../../admin/services/users.service';
import { AuthService } from '../../main/access/services/auth.service';
import { Area } from '../models/area.model';
import { AreaTranslation } from '../models/area_translation.model';
import { AreaService } from '../services/area.service';
import { ValidationsService } from '../services/validations/validations.service';
import { NotificationService } from './../../main/services/notification.service';

@Component({
    selector: 'app-area-persist',
    templateUrl: './area-persist.component.html',
    styleUrls: ['./area-persist.component.css']
})
export class AreaPersistComponent implements OnChanges, OnInit {

    @Input('codAreas') id;
    @Output() backEvent = new EventEmitter<Object>();
    @ViewChild('areaPersistForm', { static: true }) areaFormContainer: ElementRef;

    area: Area;
    isLoading: boolean = false;
    subareasList: any = [];
    worldsList: any = [];
    regionalsList: any = [];
    locationsList: any = [];
    showError: boolean = false;
    errorMessage: string = '';
    loadingConfig: any = [];
    paramsSelected: any = [];

    showEditTranslations: boolean = false;

    sapAndAcadiaIdPrivilege: boolean;

    translation: AreaTranslation;

    constructor(private _areaService: AreaService, 
                public _validationService: ValidationsService,
                private _authService: AuthService, 
                private _usersGroupPrivilegesService: UsersService,
                private _translateService: TranslateService,
                private _notify: NotificationService,
                private _sanitizer: DomSanitizer) {
        this.loadingConfig = [];
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;                
        this.showError = false;
        this.errorMessage = '';
        this._resetArea();
        this.paramsSelected = [];
        this._loadWorlds(null);
    }

    ngOnInit(): void {
        this.sapAndAcadiaIdPrivilege = this._authService.getUserPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
    }

    private _resetArea() {
        this.area = new Area();
        this.translation = new AreaTranslation();
    }

    get() {
        this.isLoading = true;
        if (this.id <= 0) {
            this._resetArea();
            this.isLoading = false;
            this._loadWorlds(null);
        }
        else {
            let objParam = {'cod_areas': this.id, 'term': '', 'cod_user': this._authService.getAuthenticatedUser().id, 'dsc_page_privilege': 'NOT_PRIVILEGE'};
            this._areaService.getAreaById(objParam).subscribe(data => {
                    this.area = data.content.results;
                    this._resetTranslation();

                    this.isLoading = false;
                    let params = {
                        wolds_selected: this.area.arr_worlds,
                        regionals_selected: this.area.arr_regional,
                        locations_selected: this.area.arr_location
                    };
                    this._getAll(params);
                }, err => {
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this.isLoading = false;
                    this._authService.errCheck(err);
                }
            );
        }
    }

    private _resetTranslation() {
        this.translation.cod_areas = this.area.cod_areas;
        this.translation.str_description_pt = this._defaultValue(this.area.str_description_pt, null);
        this.translation.str_description_es = this._defaultValue(this.area.str_description_es, null);
        this.translation.str_description_fr = this._defaultValue(this.area.str_description_fr, null);
        this.translation.str_description_nl = this._defaultValue(this.area.str_description_nl, null);
        this.translation.str_description_de = this._defaultValue(this.area.str_description_de, null);
        this.translation.str_description_ru = this._defaultValue(this.area.str_description_ru, null);
        this.translation.str_description_uk = this._defaultValue(this.area.str_description_uk, null);
        this.translation.str_description_zh = this._defaultValue(this.area.str_description_zh, null);
        this.translation.str_description_ko = this._defaultValue(this.area.str_description_ko, null);
        this.translation.str_description_vi = this._defaultValue(this.area.str_description_vi, null);
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    private _loadWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._usersGroupPrivilegesService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
            this.worldsList = this._usersGroupPrivilegesService.getArrayContent(data);
            this._setLoading('worlds', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        }, err => {
            this._authService.errCheck(err);
            console.log(err);
        });

    }

    private _loadRegionals(fn) {
        //console.log('_loadRegionals');

        this._clearRegionalList();

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._setLoading('regionals', true);
            this._usersGroupPrivilegesService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
                this.regionalsList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('regionals', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        }

    }

    private _loadLocations(fn) {
        //console.log('_loadLocations');

        if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._setLoading('locations', true);
            this._usersGroupPrivilegesService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
                this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('locations', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearLocationsList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearLocationsList();
        }

    }

    selectSubareas($event) {
        this.area.subareas = $event.selectedOptions;
    }

    private _getAll(params){
        const $this = this;
        $this._loadWorlds(() => {
            //console.log('callback load world');
            
            $this.paramsSelected['worlds_selected'] = params.wolds_selected;
            $this.paramsSelected['worlds'] = this._implodeValues(params.wolds_selected, 'cod_locations_world');
            //console.log($this.paramsSelected['worlds_selected'], $this.paramsSelected['worlds']);
            $this._loadRegionals(() => {
                //console.log('callback load regionals');

                $this.paramsSelected['regionals_selected'] = params.regionals_selected;
                $this.paramsSelected['regionals'] = this._implodeValues(params.regionals_selected, 'cod_locations_regional');
                //console.log($this.paramsSelected['regionals_selected'], $this.paramsSelected['regionals']);
                $this._loadLocations(() => {
                    //console.log('callback load locations');

                    $this.paramsSelected['locations_selected'] = params.locations_selected;
                    $this.paramsSelected['locations'] = this._implodeValues(params.locations_selected, 'cod_location');
                    //console.log($this.paramsSelected['locations_selected'], $this.paramsSelected['locations']);
                });
            });
        });

    }


    private _clearWorldList() {
        //console.log('_clearWorldList');
        
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected']  = [];
        //this.worldsList = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
        //console.log('_clearRegionalList');

        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalsList = [];
        this._clearLocationsList();
    }

    private _clearLocationsList() {
        //console.log('_clearLocationsList');

        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
    }

    private _smartClearLocationsList() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
        }
          
    }

    selectWorlds(event) {
        //console.log('selectWorlds');
        
        this.paramsSelected['worlds_selected'] = event.selectedOptions;
        this.paramsSelected['worlds'] = this._implodeValues(event.selectedOptions, 'cod_locations_world');
        this._loadRegionals(null);
    }
    selectRegionals(event: { selectedOptions: any; }) {
        //console.log('selectRegionals');

        this.paramsSelected['regionals_selected'] = event.selectedOptions; 
        this.paramsSelected['regionals'] = this._implodeValues(event.selectedOptions, 'cod_locations_regional');
        this._loadLocations(null);
    }
    selectLocations($event) {
        //console.log('selectLocations');
        
        var eventSanitizer = (this._sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.paramsSelected['locations_selected'] = eventSanitizer;
        this.paramsSelected['locations'] = this._implodeValues(eventSanitizer, 'cod_location');
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    private _handleSaveValidation(message: string): void {
        this._validationService.boolIsLoading = false;
        this._translateService.get(message).subscribe(translate => {
            this._notify.warning(translate);
        });
    }

    public saveArea() {
        this._validationService.boolSubmit = true;
        this._validationService.boolIsLoading = true;

        if (!this.area.str_description) {
            this._handleSaveValidation('LOGBOOK.REQUIRED_NAME');
            return false;
        }

        if (this.paramsSelected['worlds'].length <=0){
            this._handleSaveValidation('LOGBOOK.WORLD_REQUIRED_MSG');
            return false;
        }
        if (this.paramsSelected['regionals'].length <=0) {
            this._handleSaveValidation('LOGBOOK.REQUIRED_REGIONAL');
            return false;
        }
        if (this.paramsSelected['locations'].length <=0) {
            this._handleSaveValidation('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED');
            return false;
        }

        let params: any;

        if (this.id <= 0) {
            //INSERT NEW AREA
            params = {
                'str_description': this.area.str_description,
                'worlds': this.paramsSelected['worlds'],
                'regionals': JSON.parse("[" + this.paramsSelected['regionals'] + "]"),
                'locations': JSON.parse("[" + this.paramsSelected['locations'] + "]"),
                'cod_user': this._authService.getAuthenticatedUser().id,
                'str_description_pt': this.area.str_description_pt,
                'str_description_es': this.area.str_description_es,
                'str_description_fr': this.area.str_description_fr,
                'str_description_nl': this.area.str_description_nl,
                'str_description_de': this.area.str_description_de,
                'str_description_ru': this.area.str_description_ru,
                'str_description_uk': this.area.str_description_uk,
                'str_description_zh': this.area.str_description_zh,
                'str_description_ko': this.area.str_description_ko,
                'str_description_vi': this.area.str_description_vi,
                'cod_acadia_id': this.area.cod_acadia_id,
            };
            this._areaService.postNewArea(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        this._showErrorMessage(res.content.message);
                    }
                    else {
                        this._notify.success({text: 'LOGBOOK.AREA_INSERTED', translate: true});
                        this.backSelected(true);
                    }
                    this._resetArea();
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
        else {
            params = {
                'str_description': this.area.str_description,
                'worlds': this.paramsSelected['worlds'],
                'regionals': JSON.parse("[" + this.paramsSelected['regionals'] + "]"),
                'locations': JSON.parse("[" + this.paramsSelected['locations'] + "]"),
                'cod_areas': this.id,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'str_description_pt': this.area.str_description_pt,
                'str_description_es': this.area.str_description_es,
                'str_description_fr': this.area.str_description_fr,
                'str_description_nl': this.area.str_description_nl,
                'str_description_de': this.area.str_description_de,
                'str_description_ru': this.area.str_description_ru,
                'str_description_uk': this.area.str_description_uk,
                'str_description_zh': this.area.str_description_zh,
                'str_description_ko': this.area.str_description_ko,
                'str_description_vi': this.area.str_description_vi,
                'cod_acadia_id': this.area.cod_acadia_id,
            };
            this._areaService.postUpdateArea(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        this._showErrorMessage(res.content.message);
                    }
                    else {
                        this._notify.success({text: 'LOGBOOK.AREA_EDITED', translate: true});
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );

        }
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.get();
    }

    backSelected(success: boolean = false) {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (success) {
            this.id = -1;
        } else {
            this.id = 0;
        }
        this.area = new Area();
        this._clearWorldList();
        this.backEvent.emit(this.id);
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    editTranslations() {
        this.showEditTranslations = true;
    }

    cancelEdition() {
        this.cancelTranslations();
    }

    saveTranslations() {
        this.area.str_description_pt = this._defaultValue(this.translation.str_description_pt, null);
        this.area.str_description_es = this._defaultValue(this.translation.str_description_es, null);
        this.area.str_description_fr = this._defaultValue(this.translation.str_description_fr, null);
        this.area.str_description_nl = this._defaultValue(this.translation.str_description_nl, null);
        this.area.str_description_de = this._defaultValue(this.translation.str_description_de, null);
        this.area.str_description_ru = this._defaultValue(this.translation.str_description_ru, null);
        this.area.str_description_uk = this._defaultValue(this.translation.str_description_uk, null);
        this.area.str_description_zh = this._defaultValue(this.translation.str_description_zh, null);
        this.area.str_description_ko = this._defaultValue(this.translation.str_description_ko, null);
        this.area.str_description_vi = this._defaultValue(this.translation.str_description_vi, null);
        this.showEditTranslations = false;
    }

    cancelTranslations() {
        this._resetTranslation();
        this.showEditTranslations = false;
    }

    private _defaultValue(object: any, defaultValue: any) {
        if (object == undefined || (object === ''))
            return defaultValue;
        else
            return object;
    }
}
