import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NpsService } from 'brewtech-nps-angular';
import { NpsPersistenceService } from '../nps-persistence.service';
import { catchError, shareReplay, take, tap } from 'rxjs';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-nps-modal',
  templateUrl: './nps-modal.component.html',
  styleUrls: ['./nps-modal.component.css']
})
export class NpsModalComponent implements OnInit {

  @Input() visible = false;
  @Output() finish = new EventEmitter();

  score: number;
  comment: string;
  categories$ = this.npsService.getCategories().pipe(shareReplay());
  question: string;
  categoryId: string;
  userInfo: any

  constructor(
    private readonly npsService: NpsService,
    private readonly npsPersistence: NpsPersistenceService,
    private _authService: AuthService
  ) {
    this.userInfo = this._authService.getAuthenticatedUser();
  }

  ngOnInit() {
    if (!this.npsPersistence.canOpenNps()){
      this.finish.emit();
      return;
    }

    this.npsService
      .getSurvey()
      .pipe(
        tap((question) => {
          this.question = question;
          if (question) {
            this.visible = true;
            return;
          }
          this.finish.emit();
        }),
        take(1),
        catchError(err => {
          console.error('Fail to load NPS data.', err);
          this.finish.emit();
          return err;          
        })
      )
      .subscribe()
  }

  submit() {
    console.log({
      categoryId: this.categoryId,
      score: this.score,
      comment: this.comment,
    });
    
    this.npsService
      .answerSurvey({
        categoryId: this.categoryId,
        score: this.score,
        comment: this.comment,
        unitName: this.userInfo.name_world + ";" + this.userInfo.name_regional + ";" + this.userInfo.name_location
        //area: ??
      })
      .pipe(
        take(1),
        tap((_) => {
          this.closeModal();
        })
      )
      .subscribe();
  }

  canSubmit(): boolean {
    if (!this.score) {
      return false;
    }
    return !(this.score < 9 && !this.categoryId);
  }

  postponeNps() {
    console.log({
      categoryId: this.categoryId,
      score: this.score,
      comment: this.comment,
    });
    this.npsPersistence.postponeNps();
    this.closeModal();
  }

  closeModal() {
    this.visible = false;
    this.finish.emit();
  }

}
