import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListAreasTaService} from './services/list-areas-ta.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-areas-ta',
    templateUrl: './list-areas-ta.component.html',
    styleUrls: ['./list-areas-ta.component.css']
})

export class ListAreasTaComponent implements OnInit {

    AreasTaList: any = [];
    obj_ta_eq_area;
    bol_show_typeahead_area: Boolean = true;
    str_user_tmp = '';
    cod_component_area: number;
    warningDeleteMessage: Boolean = false;

    @Input() question: any;
    @Input() disabledDelete: any;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Input() privilege: string = 'NOT_PRIVILEGE';
    @Input() location: number;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _listAreasTaService: ListAreasTaService, private _authService:AuthService) { 
        
    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.AreasTaList = [{ cod_answer_option: this.question.cod_answer_option, str_description: this.question.str_answer_text || this.question.answer }];    
            this.bol_show_typeahead_area = false; 
        }
        this.loadAreasTa();
    }

    loadAreasTa() {
        this.obj_ta_eq_area = {
            type: 'dynamic',
            valueProperty: 'cod_areas',
            searchProperty: 'str_description',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.AreasTaList.length === 0) {
                    this.AreasTaList.push({
                        cod_areas: obj_selected.cod_areas,
                        str_description: obj_selected.str_description,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_description;
                        this.question.cod_answer_option = obj_selected.cod_areas;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }                    
                    this.bol_show_typeahead_area = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchAreasTa(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchAreasTa(cb, value) {
        let objParam = {
            'term': value,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'cod_location': (typeof this.location == typeof undefined?null:this.location)
        };

        this._listAreasTaService.getAreasByUserLocation(objParam).subscribe((data: any) => {
            cb(this._listAreasTaService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delAreasTa() {
        // if (this.cod_subarea == null) {
            this.warningDeleteMessage = false;

            this.question.str_answer_text = '';
            this.question.cod_answer_option = null;
            this.onChange.emit(this.question);

            this.AreasTaList = [];
            this.bol_show_typeahead_area = true;
            this.cod_component_area = null;

        // } else {
        //     this.warningDeleteMessage = true;
        // }
    }
}
