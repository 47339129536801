import { Injectable } from "@angular/core";
import { ListAreasParams } from "app/components/list-areas-select/models/list-areas-params.model";
import { ListChecklistParams } from "app/components/list-checklist-select/models/list-checklist-params.model";
import { ListEquipmentParams } from "app/components/list-equipment-select/models/list-equipment-params.model";
import { ListMachinesParams } from "app/components/list-machine-select/models/list-machines-params";
import { ListSubAreasParams } from "app/components/list-sub-areas-select/models/list-sub-areas-params.model";
import CockpitFilter from "../model/cockpit-filter.model";
import { MainService } from "./main.service";

type Params =
  | ListAreasParams
  | ListChecklistParams
  | ListEquipmentParams
  | ListMachinesParams
  | ListSubAreasParams;
@Injectable()
export default class GenerateParamsService {
  keys = [
    "arr_cod_locations",
    "arr_cod_areas",
    "arr_cod_subareas",
    "arr_cod_equipments",
  ];
  formKeys = [
    "locations",
    "areas",
    "subareas",
    "equipments",
    "machines",
    "checklists",
  ];

  constructor(private readonly _mainService: MainService) {}

  generateParamsForAreas(payload: CockpitFilter): ListAreasParams {
    const params = new ListAreasParams();
    params.arr_cod_locations = this._mainService.mapStringToArray(
      payload.locations
    );
    params.dsc_page_privilege = this.getPrivilegeForView(payload.view);
    return this.addOtherParams<ListAreasParams>(params, payload);
  }

  generateParamsForSubAreas(payload: CockpitFilter): ListSubAreasParams {
    let params = new ListSubAreasParams();
    params = this.insertParams<ListSubAreasParams>(
      params,
      payload,
      1,
      "mapStringToArray"
    );
    return this.addOtherParams<ListSubAreasParams>(params, payload);
  }

  generateParamsForEquipments(payload: CockpitFilter): ListEquipmentParams {
    let params = new ListEquipmentParams();
    params = this.insertParams<ListEquipmentParams>(params, payload, 2);
    return this.addOtherParams<ListEquipmentParams>(params, payload);
  }

  generateParamsForMachines(payload: CockpitFilter): ListMachinesParams {
    let params = new ListMachinesParams();
    params = this.insertParams<ListMachinesParams>(params, payload, 3);
    return this.addOtherParams<ListMachinesParams>(params, payload);
  }

  generateParamsForChecklists(payload: CockpitFilter): ListChecklistParams {
    const params = new ListChecklistParams();
    params.arr_cod_areas = this._mainService.mapStringToArray(payload.areas);
    params.arr_cod_subareas = payload.subareas;
    return this.addOtherParams<ListChecklistParams>(params, payload);
  }

  insertParams<T>(
    params: Params,
    payload: CockpitFilter,
    lastIndex: number,
    method: "mapArrayToString" | "mapStringToArray" = "mapArrayToString"
  ): T {
    const sliced = this.keys.slice(0, lastIndex + 1);
    sliced.forEach((key, index) => {
      const formKey = this.formKeys[index];
      const value = payload[formKey];
      params[key] = this._mainService[method](value);
    });
    return params as unknown as T;
  }

  addOtherParams<T>(params: Params, payload: CockpitFilter): T {
    params.dsc_page_privilege = this.getPrivilegeForView(payload.view);
    params.cod_user = Number(payload.userId);
    params.str_filter_by = "MACHINES";
    return params as unknown as T;
  }

  getPrivilegeForView(view: string) {
    return {
      supervisor: "COCKPIT_LINE",
      operator: "COCKPIT_WORKSTATION",
    }[view];
  }
}
