import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LogbooksService} from '../../services/logbooks.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../main/access/services/auth.service';
import {environment} from '../../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-logbooks-my-team-selector',
    templateUrl: './logbooks-my-team-selector.component.html',
    styleUrls: ['./logbooks-my-team-selector.component.css']
})
export class LogbooksMyTeamSelectorComponent implements OnInit, OnChanges {

    @Input() logbookUser: any;
    @Input() logbookList: any;

    id: any;
    list: any;
    searchTerm: string;
    searchTimer: any;
    placeholderTypeAheadRoutine: string = '';
    isLoading: boolean = true;
    userPlaceholderPicture: string = '';
    isEditing: boolean = false;
    selected: any;
    showMessage: boolean = false;
    timeToHide: number = 0;
    showError: boolean = false;
    errorMessage: string = '';

    constructor(private _logbookService: LogbooksService, 
                private _translateService: TranslateService, 
                public _authService: AuthService,
                private sanitizer: DomSanitizer) {
        this.searchTerm = '';
        this.searchTimer = null;
        this.userPlaceholderPicture = '../../../assets/images/icon_admin.png';
        this.isEditing = false;
        this.showMessage = false;
        this.showError = false;
        this.errorMessage = '';
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        let objParam = {
            'cod_logbooks': 0,
            'term': this.searchTerm,
            'limit': 1000,
            'offset': 0,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'ADMIN_LOGBOOKS_LISTING',
            'file_path_url': environment().uploadGetFilesApiUrl + '/',
        };
        this._logbookService.getMyTeamList(objParam).subscribe(data => {
                this.list = {
                    logbooks: this._logbookService.getArrayContent(data)
                };
                //console.log(this.list);
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    private loadLogbooks(cod_locations: any){

        this._logbookService.getLogbooksyLocation({'cod_user': this._authService.getAuthenticatedUser().id, 'cod_location': cod_locations, 'dsc_page_privilege': 'MYTEAM_SELECT_LOGBOOKS'}).subscribe(data => {
            // PUT THE DEFAULT NO LOGBOOK OPTION
            this._translateService.get('GENERAL.WITHOUT_LOGBOOK').subscribe(translate => {
                this.logbookList = this._logbookService.getArrayContent(data);
                this.logbookList.unshift({'cod_logbooks': 'null', 'str_name': translate});
            });
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        }
        );          
    }        

    ngOnInit(): void {
        //Get translation for routine typeahead component
        this._translateService.get('LOGBOOK.SELECT_LOGBOOK').subscribe(translate => {
            this.placeholderTypeAheadRoutine = translate;
        });

        this.selected = [{"cod_logbooks":"null"}];

        if (typeof this.logbookUser.cod_logbook != typeof undefined && this.logbookUser.cod_logbook > 0) {
            this.selected = [{cod_logbooks: this.logbookUser.cod_logbook, str_name: this.logbookUser.str_variationname}];
        }

        if(this.logbookList == undefined){
            this.loadLogbooks(this.logbookUser.cod_location);
        }   
    }

    changeUserLogbook() {
        if (!this.logbookUser.cod_user || !this._authService.getAuthenticatedUser().id) {
            return;
        }

        let codLogbook = this.logbookUser.cod_logbook;
        if (typeof this.selected !== typeof undefined && this.selected.length > 0) {
            codLogbook = (this.selected[0].cod_logbooks > 0 ? this.selected[0].cod_logbooks : null);
            this.logbookUser.str_variationname = this.selected[0].str_name;
        }
        else {
            if (this.selected.length <= 0) {
                codLogbook = null;
            }
        }

        let params = {
            'cod_user': this.logbookUser.cod_user,
            'cod_logbook': codLogbook,
            'cod_user_auth': this._authService.getAuthenticatedUser().id
        };

        this._logbookService.setLogbookToUser(params).subscribe((res: any) => {
                if (res.type == 'error') {
                    this._showErrorMessage(res.content.message);
                }
                else {
                    this._showMessage();
                    this.isEditing = false;
                    if (!codLogbook || codLogbook.toString() == '0') {
                        this.logbookUser.cod_logbook = null;
                        this.logbookUser.str_variationname = '';
                    }
                }
            }, err => {
                let e = JSON.parse(err._body);
                alert(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    updateLogbookUser() {
        if (this.isEditing) {
            this.changeUserLogbook();
        }
        else {
            this.isEditing = true;
        }
    }

    private _showMessage() {
        this.showMessage = true;
        this.timeToHide = 2000;
    }

    hideMessage() {
        this.showMessage = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.logbookUser != typeof undefined &&
            typeof changes.logbookUser.currentValue != typeof undefined &&
            typeof changes.logbookUser.currentValue.cod_logbook != typeof undefined &&
            changes.logbookUser.currentValue.cod_logbook > 0) {
            this.selected = [{cod_logbooks: changes.logbookUser.currentValue.cod_logbook, str_name: changes.logbookUser.currentValue.str_variationname}];
        }
    }


    selectLogbook($event) {
        this.selected = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }
}
