// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

.isDisable{
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.th-status {
    width: 110px;
    text-align: center;
}

.td-status{
    text-align: center;
}

.td-status i{
    font-size: 2rem;
}

.td-actions {
    text-align: right;
}

.glyphicon--spin {
    color: #5599FF;
    animation: spin 1.5s infinite linear;
  }`, "",{"version":3,"sources":["webpack://./src/app/five-why/relay-list/relay-list.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IAEd,oCAAoC;EACtC","sourcesContent":[".action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n.isDisable{\n    pointer-events: none;\n    cursor: default;\n    opacity: 0.6;\n}\n\n.th-status {\n    width: 110px;\n    text-align: center;\n}\n\n.td-status{\n    text-align: center;\n}\n\n.td-status i{\n    font-size: 2rem;\n}\n\n.td-actions {\n    text-align: right;\n}\n\n.glyphicon--spin {\n    color: #5599FF;\n    -webkit-animation: spin 1.5s infinite linear;\n    animation: spin 1.5s infinite linear;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
