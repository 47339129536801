// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.paginate {
  background: var(--ui-secondary);
  border-radius: 4px;
  display: inline-block;
  margin: 2px;
  color: var(--text-ui-primary);
  line-height: 34px;
  padding: 0 15px;
}

button.paginate.active {
  background-color: var(--ui-primary);
  font-weight: bold;
}

button.paginate.disabled {
  background-color: #EDF0F7;
  color: #000;
}

.action-button-container {
  display: inline-block;
}

.action-button-container button {
  display: inline-block;
}

#kpis-list-qty{
  width: 280px;
}

.kpi-code {
  width: 25%;
}
`, "",{"version":3,"sources":["webpack://./src/app/five-why/kpis-consult/kpis-consult.component.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":["button.paginate {\n  background: var(--ui-secondary);\n  border-radius: 4px;\n  display: inline-block;\n  margin: 2px;\n  color: var(--text-ui-primary);\n  line-height: 34px;\n  padding: 0 15px;\n}\n\nbutton.paginate.active {\n  background-color: var(--ui-primary);\n  font-weight: bold;\n}\n\nbutton.paginate.disabled {\n  background-color: #EDF0F7;\n  color: #000;\n}\n\n.action-button-container {\n  display: inline-block;\n}\n\n.action-button-container button {\n  display: inline-block;\n}\n\n#kpis-list-qty{\n  width: 280px;\n}\n\n.kpi-code {\n  width: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
