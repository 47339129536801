import { Component,  Input } from '@angular/core';
import { Subject } from "rxjs";

@Component({
    selector: 'confirm-discart-changes',
    templateUrl: './confirm-discart-changes.component.html',
    styleUrls: ['./confirm-discart-changes.component.css'],
})
export class ConfimDiscartChangesComponent {

    @Input() text: string = null;

    public show: boolean = false;
    public discart: Subject<boolean> = null;

    constructor() { }

    public yes(): void {
        this.send(true);
    }

    public no(): void {
        this.send(false);
    }

    private send(answer: boolean): void {
        this.show = false;
        this.discart.next(answer);
        this.discart.complete();
    }

    public ask(): void {
        this.show = true;
        this.discart = new Subject<boolean>();
    }

}
