import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
    standalone: true,
    name: 'trustHtml'
})
export class TrustHtmlPipe implements PipeTransform  {    
   constructor(readonly sr: DomSanitizer){}  

   transform(html: string) : SafeHtml {
      const sanitizedContent = DOMPurify.sanitize(html);
      return this.sr.bypassSecurityTrustHtml(sanitizedContent); 
   } 
} 
