import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoutinesService } from './../services/routines.service';
import { Routine } from '../models/routine.model';
import { RoutineDataService } from '../services/routine-data.service';

@Component({
    selector: 'routine-messages',
    templateUrl: './routine-messages.component.html',
    styleUrls: ['./routine-messages.component.css']
})
export class RoutineMessagesComponent implements OnInit, OnChanges {

    @Input() isRoutinePastDue: boolean = false;
    @Input() routineStatus: number;

    isAcadia: boolean = false;
    routine: Routine = null;
    isPastDue: boolean = false;
    isFutureDue: boolean = false;
    isStatusAvaiable: boolean;
    isStatusNotAvaiable: boolean;
    isStatusPending: boolean;
    isStatusDone: boolean;
    isStatusNotDone: boolean;

    isAsNeeded: boolean;
    isDaily: boolean;

    constructor(
        private _dataService: RoutineDataService,
        private _translateService: TranslateService
    ) {

    }

    ngOnInit() {
        this._dataService.routine.subscribe(x => this.routine = x);
        this._dataService.isAsNeeded.subscribe(x => this.isAsNeeded = x);
        this._dataService.isDaily.subscribe(x => this.isDaily = x);
        this._dataService.isPastDue.subscribe(x => this.isPastDue = x);
        this._dataService.isFutureDue.subscribe(x => this.isFutureDue = x);
        this._dataService.isAcadia.subscribe(x => this.isAcadia = x);
    }

    ngOnChanges() {
        this.refresh();
    }

    private refresh() : void {
        this.isStatusNotDone = this._isStatusNotDone();
        this.isStatusDone = this._isStatusDone();
        this.isStatusPending = this._isStatusPending();
        this.isStatusAvaiable = this._isStatusAvaiable();
        this.isStatusNotAvaiable = this._isStatusNotAvaiable();
    }

    private _isStatusNotDone(): boolean {
        return (this.routine?.cod_routine_status == RoutinesService.STATUS_NOT_DONE);
    }

    private _isStatusDone(): boolean {
        return (this.routine?.cod_routine_status == RoutinesService.STATUS_DONE);
    }

    private _isStatusPending(): boolean {
        return (this.routine?.cod_routine_status == RoutinesService.STATUS_PENDING);
    }

    private _isStatusAvaiable(): boolean {
        return (this.routine?.cod_routine_status == RoutinesService.STATUS_AVAIABLE);
    }

    private _isStatusNotAvaiable(): boolean {
        return (this.routine?.cod_routine_status == RoutinesService.STATUS_NOT_AVAIABLE);
    }

    public getDifferentDocumentLanguageMessage(): string {
        let message: string = '';

        this._translateService.get('LOGBOOK.ACADIA_DIFFERENT_DOCUMENT_LANGUAGE').subscribe(translate => {
            message = translate.replace('$LANGUAGE', this.routine?.str_acadia_document_language?.toUpperCase() || '');
        });

        return message;
    }

}
