import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListWorldsService} from './services/list-worlds.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-worlds',
    templateUrl: './list-worlds.component.html',
    styleUrls: ['./list-worlds.component.css']
})

export class ListWorldsComponent implements OnInit {

    worldsList: any = [];
    obj_ta_eq_worlds;
    bol_show_typeahead_worlds: Boolean = true;
    str_user_tmp = '';
    cod_component_worlds: number;

    @Input() question;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListWorldsService: ListWorldsService, private _authService:AuthService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.worldsList = [{ cod_answer_option: this.question.cod_answer_option, str_name: this.question.str_answer_text || this.question.answer  }];    
            this.bol_show_typeahead_worlds = false;
        }
        this.loadWorlds();
    }

    loadWorlds() {
        this.obj_ta_eq_worlds = {
            type: 'dynamic',
            valueProperty: 'cod_locations_world',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.worldsList.length === 0) {
                    this.worldsList.push({
                        cod_locations_world: obj_selected.cod_locations_world,
                        str_name: obj_selected.str_name,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_locations_world;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead_worlds = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchWorlds(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchWorlds(cb, value) {
        this._ListWorldsService.getWorldsList({ 'dsc_privilege': 'NOT_PRIVILEGE','cod_user': this._authService.getAuthenticatedUser().id, 'term': value }).subscribe((data: any) => {
            cb(this._ListWorldsService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delWorlds() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;    
        this.onChange.emit(this.question);

        this.worldsList = [];
        this.bol_show_typeahead_worlds = true;
        this.cod_component_worlds = null;
    }

}
