import {Injectable} from '@angular/core';
import { AuthMainService } from "../../services/auth-main.service";
import {Observable} from 'rxjs';

@Injectable()
export class ListMachinesTaService {
  
  constructor(private _mainService: AuthMainService) {
  }
  
  getMachines(param): Observable<any> {
    let path = '/machines-by-equipment'
    return this._mainService.post(path, param);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
