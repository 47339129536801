
export function replaceAll (fullText: string, toBeReplaced: string, replacer: string) {
    return fullText.replace(new RegExp(toBeReplaced, 'g'), replacer)
}

export function replaceAllGeneric (fullText: string, toBeReplaced: string, replacer: string) {
    return fullText.replace(new RegExp(toBeReplaced, 'g'), replacer)
}

export function linkfyText (text: string) : string {
    let linkfyedText = text;

    linkfyedText = linkfyHttpText(linkfyedText);
    linkfyedText = linkfyWWW(linkfyedText);
    // linkfyedText = linkfyFilePath(linkfyedText);
    // linkfyedText = linkfyBarPath(linkfyedText);
    // linkfyedText = linkfyFolderPath(linkfyedText);
    linkfyedText = linkfyEmail(linkfyedText);

    return linkfyedText;
}

export function linkfyHttpText (text: string): string { 
    return text.replace(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim, '<a href="$1" contenteditable="false" target="_blank">$1</a>');
}

export function linkfyFilePath (text: string): string { 
    const patternFile = /file:\/\/\/?[^ ]+\b/gmi; // check if starts with file:///

    let linkfyedText = text;
    if(patternFile.test(text)) {
        linkfyedText.match(patternFile).forEach(toReplace => {
            linkfyedText = linkfyedText.replace(toReplace,  `<a href="${toReplace}" contenteditable="false" target="_blank">${toReplace}</a>`);
        });
    }

    return linkfyedText;
}

export function linkfyWWW (text: string): string { 
    const patternWWW = /(^|[^\/])(www\.[\S]+(\b|$))/gim //check if start with www

    let linkfyedText = text;
    if(patternWWW.test(text)) {
        linkfyedText.match(patternWWW).forEach(toReplace => {
            let url = toReplace
            url = url.trim()
            if(!url.startsWith('http')){
              url = 'http://'+url
            }
            linkfyedText = linkfyedText.replace(toReplace,  `<a href="${url}" contenteditable="false" target="_blank">${toReplace}</a>`);
        });
    }

    return linkfyedText;
}

export function linkfyBarPath (text: string): string { 
    const patternBarPath = /(\s|^)((\\\\)([-A-Z0-9+&@#\/\\%?=~_|!:,.;]*[-A-Z0-9+&@#\/\\%=~_|]))/gmi // check if starts with \\

    let linkfyedText = text;
    if(patternBarPath.test(text)) {
        linkfyedText.match(patternBarPath).forEach(toReplace => {
            linkfyedText = linkfyedText.replace(toReplace,  `<a href="${toReplace}" target="_blank">${toReplace}</a>`);
        });
    }

    return linkfyedText;
}


export function linkfyFolderPath (text: string): string { 
    const patternFolderPath = /((\b[A-Z]{1}):[\/|\\]{1,2})([A-Z]+\/[A-Z]*)*([^\s]+)/gmi; // check if starts with C:/ 

    let linkfyedText = text;
    if(patternFolderPath.test(text)) {
        linkfyedText.match(patternFolderPath).forEach(toReplace => {
            linkfyedText = linkfyedText.replace(toReplace,  `<a href="${toReplace}" target="_blank">${toReplace}</a>`);
        });
    }

    return linkfyedText;
}


export function linkfyEmail (text: string): string {
    const patternEmail = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+) /gim;
    
    let linkfyedText = text;
    if(patternEmail.test(text)) {
        linkfyedText.match(patternEmail).forEach(toReplace => {
            linkfyedText = linkfyedText.replace(toReplace,  `<a href="${toReplace}" target="_blank">${toReplace}</a>`);
        });
    }

    return linkfyedText;

}

export const implodeValues = (objValues, idField) => {
    let ids = "";
    if (objValues) {
      objValues.forEach((element) => {
        if (ids != "") {
          ids += ",";
        }
        ids += element[idField];
      });
    }
    return ids;
}


export const emptyDefaultValue = (value, defaultValue) => {
    if (typeof value !== typeof undefined && value) {
        return value;
    }
    return defaultValue;
}

export const convertChar = (str: string): string => {
    if (str != undefined) {
        const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẝźḧ·/_,:;";
        const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______";
        const p = new RegExp(a.split("").join("|"), "g");
        return str
            .toString()
            .toLowerCase()
            .trim()
            .replace(p, (c) => b.charAt(a.indexOf(c)))
            .replace(/&/g, "_and_")
            .replace(/[\s-]+/g, "_");
    }
    return str;
}
