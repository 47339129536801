import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription, throttleTime } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appScrollNearEnd]'
})
export class ScrollNearEndDirective implements OnInit, OnDestroy {
  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();

  @Input() threshold: number = 120;
  @Input() throttle: number = 150;
  @Input() scrollableContainer: HTMLElement | null = null;

  private subscription: Subscription;

  private window!: Window;

  constructor(private readonly el: ElementRef) { }

  ngOnInit(): void {
    this.window = window;
    if (this.scrollableContainer){
      this.subscription = fromEvent(this.scrollableContainer, 'scroll')
          .pipe(throttleTime(this.throttle, undefined, {leading: true, trailing: true}))
          .subscribe((event) => {
              this.scrollPositionChecker(this.containerScroller());
          });
    } else {
      this.subscription = fromEvent(this.window, 'scroll')
          .pipe(throttleTime(this.throttle, undefined, { leading: true, trailing: true }))
          .subscribe((event) => {
              this.scrollPositionChecker(this.windowScroller());
          });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scrollPositionChecker(scrollToBottom: number) {
    if(scrollToBottom < this.threshold) {
      this.nearEnd.emit();
    }
  }

  windowScroller(): number {
    const heightOfWholePage = this.window.document.documentElement.scrollHeight;
    const heightOfElement = this.el.nativeElement.scrollHeight;
    const currentScrolledY = this.window.scrollY;
    const innerHeight = this.window.innerHeight;
    const spaceOfElementAndPage = heightOfWholePage - heightOfElement;
    const scrollToBottom = heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage;

    return scrollToBottom;
  }

  containerScroller(): number {
    const heightOfContainer = this.scrollableContainer.scrollHeight;
    const heightOfElement = this.el.nativeElement.scrollHeight;
    const currentScrolledY = this.scrollableContainer.scrollTop;
    const innerHeight = this.scrollableContainer.offsetHeight;
    const spaceOfElementAndPage = heightOfContainer - heightOfElement;
    const scrollToBottom = heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage;

    return scrollToBottom;
  }

}
