import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-abort-fivewhy-modal',
  templateUrl: './abort-fivewhy-modal.component.html',
  styleUrls: ['./abort-fivewhy-modal.component.css']
})
export class AbortFivewhyModalComponent implements OnInit {

  cod_user: number;
  str_reason_cancelation: string;

  @Output() onConfirmAbort = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    private _authService: AuthService,
    private _translateService: TranslateService
  ) { 
    this.cod_user = this._authService.getAuthenticatedUser().id;

  }

  ngOnInit() {
  }

  confirmAbort() {
    this.onConfirmAbort.emit(this.str_reason_cancelation);
  }
  
  cancel() {
    this.onCancel.emit();
  }
}
