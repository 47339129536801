// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-item {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

.select-item-icon img {
    width: 15px;
    height: 15px;
    margin: 0 15px;
}

.select-item-text {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.select-item-text .info {
    font-size: 14px;
}
.select-item-text .text-label {
    color: var(--ui-primary);
}
.select-item-control {
    margin-top: 5%;
    color: #666;
}
.select-item-control:hover {
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-select-item/help-select-item.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,cAAc;IACd,WAAW;AACf;AACA;IACI,eAAe;AACnB","sourcesContent":[".select-item {\n    border: 1px solid #e1e1e1;\n    border-radius: 5px;\n    padding: 10px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.select-item-icon img {\n    width: 15px;\n    height: 15px;\n    margin: 0 15px;\n}\n\n.select-item-text {\n    display: flex;\n    flex-direction: column;\n    margin-right: auto;\n}\n\n.select-item-text .info {\n    font-size: 14px;\n}\n.select-item-text .text-label {\n    color: var(--ui-primary);\n}\n.select-item-control {\n    margin-top: 5%;\n    color: #666;\n}\n.select-item-control:hover {\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
