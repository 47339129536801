// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
}

.category-row {
  display: grid;
  padding: 5px 15px;
  grid-template-rows: 50px auto;
  grid-template-columns: auto min-content;
  grid-template-areas: 
    "name actions"
    "edit edit"
  ;
  width: 100%;
  background-color: #f5f5f5;
}

.highlight {
  background-color: #fff;
}

.name {
  grid-area: name;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
}

.actions {
  grid-area: actions;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.category-edition {
  width: 100%;
  grid-area: edit;
}

.name-input {
  float: left;
  position: relative;
  margin-right: -40px;
}

.translate-button{
  float:right;
  position: absolute;
}

.action-button {
  margin-left: 5px;
}

.action-button:hover {
  color: white;
}

.drag-button {
  cursor: grabbing;
}

.expand-button {
  margin-right: 5px;
  margin-left: 0;
}

.width-actions{
  width: 10%;
  text-align: center;
}

.width-title{
  width: 80%;
}

.checkbox{
  height: 20px;
  width: 20px;
  background-color: #FFF;
  border: 1px solid #ccc;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/forms/forms-dynamic/forms-dynamic-edit/forms-category/forms-category.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,6BAA6B;EAC7B,uCAAuC;EACvC;;;EAGA;EACA,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[":host {\n  display: flex;\n  width: 100%;\n}\n\n.category-row {\n  display: grid;\n  padding: 5px 15px;\n  grid-template-rows: 50px auto;\n  grid-template-columns: auto min-content;\n  grid-template-areas: \n    \"name actions\"\n    \"edit edit\"\n  ;\n  width: 100%;\n  background-color: #f5f5f5;\n}\n\n.highlight {\n  background-color: #fff;\n}\n\n.name {\n  grid-area: name;\n  display: flex;\n  align-items: center;\n  /* cursor: pointer; */\n}\n\n.actions {\n  grid-area: actions;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.category-edition {\n  width: 100%;\n  grid-area: edit;\n}\n\n.name-input {\n  float: left;\n  position: relative;\n  margin-right: -40px;\n}\n\n.translate-button{\n  float:right;\n  position: absolute;\n}\n\n.action-button {\n  margin-left: 5px;\n}\n\n.action-button:hover {\n  color: white;\n}\n\n.drag-button {\n  cursor: grabbing;\n}\n\n.expand-button {\n  margin-right: 5px;\n  margin-left: 0;\n}\n\n.width-actions{\n  width: 10%;\n  text-align: center;\n}\n\n.width-title{\n  width: 80%;\n}\n\n.checkbox{\n  height: 20px;\n  width: 20px;\n  background-color: #FFF;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
