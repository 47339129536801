// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-list-align{
    line-height: 60px;
}

.success-error-box {
    padding-left: 12px;
    padding-right: 12px;
}

.display {
    display: flex; 
    height: 35px;
    padding-top: 0px; 
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/meetings-indicators-list/meetings-indicators-list.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".buttons-list-align{\n    line-height: 60px;\n}\n\n.success-error-box {\n    padding-left: 12px;\n    padding-right: 12px;\n}\n\n.display {\n    display: flex; \n    height: 35px;\n    padding-top: 0px; \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
