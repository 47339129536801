// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-list-align{
    line-height: 60px;
    left: 16%;
}


@media only screen and (min-width: 1920px) {
    .buttons-list-align{
        left: 18%;
    }
}


.header-edit {
    padding: 14px 0px;
    margin: 0 0 5px 0;
}

.no-padding-left {
    padding-left: 0px;
}

.checklists_adjustment {
    height: 60px;
    padding-top: 10px;
}

.row {
    display: flex;
}

.checklist-postion {
    left: 7%;
}

.label_checkbox {
    display: flex;
    margin-bottom: 20px;
    width: 50px;
}

.margin-btn {
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/my-notes/my-notes-list-all/my-notes-list-all.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,SAAS;AACb;;;AAGA;IACI;QACI,SAAS;IACb;AACJ;;;AAGA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".buttons-list-align{\n    line-height: 60px;\n    left: 16%;\n}\n\n\n@media only screen and (min-width: 1920px) {\n    .buttons-list-align{\n        left: 18%;\n    }\n}\n\n\n.header-edit {\n    padding: 14px 0px;\n    margin: 0 0 5px 0;\n}\n\n.no-padding-left {\n    padding-left: 0px;\n}\n\n.checklists_adjustment {\n    height: 60px;\n    padding-top: 10px;\n}\n\n.row {\n    display: flex;\n}\n\n.checklist-postion {\n    left: 7%;\n}\n\n.label_checkbox {\n    display: flex;\n    margin-bottom: 20px;\n    width: 50px;\n}\n\n.margin-btn {\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
