import { Component, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SelectPagination } from "app/logbooks/models/select_pagination.model";
import { ValidationsService } from "app/logbooks/services/validations/validations.service";
import { AuthService } from "app/main/access/services/auth.service";
import { MyFormsService } from "app/my-forms/services/my-forms.service";
import {UsersService} from '../../../admin/services/users.service';
import { UserPreferencesService } from "app/user-preferences/services/user-preferences.service";
import { environment } from "environments/environment";
import * as moment from "moment";
import PaginationHelper from "app/helpers/pagination-helper";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { implodeValues } from "app/helpers/general-helper";

const VIEW_PRIVILEGE = "FORMS_LOCATION_TAB";

@Component({
  selector: "app-forms-location",
  templateUrl: "./forms-location.component.html",
  styleUrls: ["./forms-location.component.css"],
})
export class FormsLocationComponent implements OnInit {
  isLoading = false;
  initial_date: string = moment(new Date())
    .subtract(30, "days")
    .format("YYYY-MM-DD");
  final_date: string = moment(new Date()).format("YYYY-MM-DD");
  searchTerm: string = "";

  pageIndex: number = 1;
  totalPages: number[] = [];
  maxPage: number;

  searchTimer: any;
  cod_user: number = this._authService.getAuthenticatedUser().id;
  hasError: boolean;
  listLocationForms: any = [];

  resultsPerPage: SelectPagination[] = [];
  resultsPerPageSelected: number;
  resultsPerPageSelectOption: SelectPagination[] = [];
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;

  paramsSelected: any = [];
  locationsList: any = [];
  worldsList: any = [];
  usersList: any = [];
  regionalsList: any = [];
  strFormsList: any = [];  
  loadingConfig: any = [];
  no_records: boolean = false;
  user_pref: {
    locations: any;
    worlds: string;
    regionals: string;
    str_forms: string;
    users: string;
  };
  user_pref_dates: string;

  constructor(
    private router: Router,
    private _authService: AuthService,
    private _myFormsService: MyFormsService,
    private _translateService: TranslateService,
    public _validationService: ValidationsService,
    private _userPreferencesService: UserPreferencesService,
    private _usersGroupPrivilegesService: UsersService,
    private _sanitizer: DomSanitizer    
  ) {
    this.user_pref = {
      locations: '',
      worlds: '',
      regionals: '',
      str_forms: '',
      users: '',
    };
  }

  ngOnInit(): void {
    this.isLoading = true;
    
    //pega as preferencias
    this._userPreferencesService
      .getUserPreferences(this.cod_user)
      .subscribe((res) => {                
          this.checkPrefValues(res);
          const { cod_world, cod_regional, cod_location } = this._authService.getAuthenticatedUser();                      
          let params = {
              worlds_selected: this.user_pref.worlds?.split(',')?.map((item) => {
                  return {cod_locations_world: item};
                }) || [{'cod_locations_world' : cod_world}],
              regionals_selected: this.user_pref.regionals?.split(',')?.map((item) => {
                  return {cod_locations_regional: item};
                }) || [{'cod_locations_regional' : cod_regional}],
              locations_selected: this.user_pref.locations?.split(',')?.map((item) => {
                  return {cod_location: item};
                }) || [{'cod_location' : cod_location}],
              str_forms_selected: this.user_pref.str_forms?.split(',')?.map((item) => {
                  return {cod_form: item};
                }),
              users_selected: this.user_pref.users?.split(',')?.map((item) => {
                  return {cod_user: item};
                }),
            };

          this._getAll(params);
      });

    

    this._translateService.onLangChange.subscribe((event) => {
      this.listLocationForms.forEach(
        (form) =>
          (form.str_form_name = this._myFormsService.getNameByUserLanguage(
            event.lang,
            form,
            "str_form_name"
          ))
      );
    });

    
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private checkPrefValues(res) {
    res.content.results.map((pref) => {                        
      switch (pref.str_pref_key) {        
        case 'form_worlds_save':
          this.user_pref.worlds = pref.str_pref_value;          
          break;
        
        case 'form_regionals_save':
          this.user_pref.regionals = pref.str_pref_value;          
          break;

        case 'form_locations_save':
          this.user_pref.locations = pref.str_pref_value;          
          break;
        
        case 'form_str_forms_save':
          this.user_pref.str_forms = pref.str_pref_value;          
          break;

        case 'form_users_save':
          this.user_pref.users = pref.str_pref_value;          
          break;
        
        case 'form_dates_save':
          const dates = pref.str_pref_value.split(",");
          this.initial_date = dates[0];
          this.final_date = dates[1];
          break;
             
      }            
    });
  }
  

  search(reset: boolean) {
    //save preferences
    this._userPreferencesService
      .postFormFilterDates(this.cod_user, this.initial_date, this.final_date)
      .subscribe((res) => {
        //send dates
      });

    if (
      this.searchTerm.length < environment().minCharStartSearch &&
      this.searchTerm.length != 0
    ) {
      return;
    }

    clearTimeout(this.searchTimer);
    const $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.loadForms();
    }, environment().debounceTime);
  }

  previousPage() {
    let previous = this.pageIndex - 1;
    this.changePage(previous);
  }

  nextPage() {
    let next = this.pageIndex + 1;
    this.changePage(next);
  }

  changePage(page) {
    this.pageIndex = page;
    this.isLoading = true;
    this.search(false);
  }  

  private _clearList(key) {
    this.paramsSelected[key] = '';
    this.paramsSelected[`${key}_selected`]  = [];
    
    //cascading
    switch (key) {
      case 'worlds':        
        this._clearList('regionals');      
        break;
      case 'regionals':
        this.regionalsList = [];
        this._clearList('locations');    
        break;
      case 'locations':
        this.locationsList = [];
        this._clearList('str_forms');   
        this._clearList('users');
        break;
      case 'str_forms':                    
        this.strFormsList = [];                              
        break;
      case 'users':                    
        this.usersList = [];                              
        break;      
    }
    return;
  }
  
  private loadWorlds(fn) {        
    this._clearList('worlds');
    this._setLoading('worlds', true);

    let params = {
      term: this.searchTerm,
      cod_user: this.cod_user,
      dsc_page_privilege: VIEW_PRIVILEGE,
    };    

    this._myFormsService.getWorldForms(params).subscribe({
      next: (data) => {
        this.worldsList = this._myFormsService.getArrayContent(data);
        this._setLoading('worlds', false);        
        if (typeof fn != typeof undefined && fn) {
          fn();
        }        
      },
      error: (err) => {
        this._authService.errCheck(err);                
        console.log(err);
      }
    });
  }

  private loadRegionals(fn) {
    this._clearList('regionals');

    if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
      this._setLoading('regionals', true);      
      let params = this.paramsSelected['worlds'].toString().split(',');      
      this._usersGroupPrivilegesService.getRegionals(params, this.cod_user, VIEW_PRIVILEGE).subscribe({
        next: (data) => {
          this.regionalsList = this._usersGroupPrivilegesService.getArrayContent(data);
          this._setLoading('regionals', false);          
          if (typeof fn != typeof undefined && fn) {
              fn();
          }                              
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
			});
    }
  }

  private loadLocations(fn) {
    this._clearList('users');

    if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
      let params = this.paramsSelected['regionals'].toString().split(',');
      this._setLoading('locations', true);
      this._usersGroupPrivilegesService.getLocations(params, this.cod_user, VIEW_PRIVILEGE).subscribe({
				next: (data) => {
					this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
					this._setLoading('locations', false);
					if (typeof fn != typeof undefined && fn) {
							fn();
					}
				},
				error: (err) => {
					this._authService.errCheck(err);
					console.log(err);
				}
      });
    } else {
        this._clearList('locations');
    }                                  
  }

  private loadUsers(fn) {            

    if (this.paramsSelected['locations'] != undefined && this.paramsSelected['locations'].toString() != '') {
      this._setLoading('users', true);    
      let params = {      
        cod_user: this.cod_user,
        dsc_page_privilege: VIEW_PRIVILEGE,            
        cod_location: [implodeValues(this.paramsSelected['locations_selected'], 'cod_location').split(',')],
      };    

      this._myFormsService.getUserForms(params).subscribe({
        next: async (data) => {           
          this.usersList = await this._usersGroupPrivilegesService.getArrayContent(data);                  
          this._setLoading('users', false);
          if (typeof fn != typeof undefined && fn) {
            fn();
          }         
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    }
  }  

  private loadStrForms(fn) {    
    this._clearList('str_forms');   

    if (this.paramsSelected['locations'] !== undefined && this.paramsSelected['locations'].toString() !== '') {
      this._setLoading('str_forms', true);        
      let params = {      
        cod_user: this.cod_user,
        dsc_page_privilege: VIEW_PRIVILEGE,      
        dat_ini: this.initial_date,
        dat_end: this.final_date,
        cod_location: [implodeValues(this.paramsSelected['locations_selected'], 'cod_location').split(',')],
      };

      this._myFormsService.getFormsName(params).subscribe({
        next: (data) => {                 
          this.strFormsList = data.content.results;
          this.loadingConfig["str_forms"] = false;
          if (typeof fn != typeof undefined && fn) {
            fn();
          }                          
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);        
        }
      });
    }
  } 

  private _getAll(params){
    const $this = this;
    $this.loadWorlds(() => {        
        $this.paramsSelected['worlds_selected'] = params.worlds_selected;
        $this.paramsSelected['worlds'] = implodeValues(params.worlds_selected, 'cod_locations_world'); 
        
        $this.loadRegionals(() => {
          $this.paramsSelected['regionals_selected'] = params.regionals_selected;
          $this.paramsSelected['regionals'] = implodeValues(params.regionals_selected, 'cod_locations_regional');
          
          $this.loadLocations(() => {
            $this.paramsSelected['locations_selected'] = params.locations_selected;
            $this.paramsSelected['locations'] = implodeValues(params.locations_selected, 'cod_location');

            $this.loadStrForms(()=>{
              if (params.str_forms_selected){                    
                $this.paramsSelected['str_forms_selected'] = params.str_forms_selected;
                $this.paramsSelected['str_forms'] = implodeValues(params.str_forms_selected, 'cod_form');        
              }
              $this.loadUsers(()=>{
                if (params.users_selected){
                  $this.paramsSelected['users_selected'] = params.users_selected;
                  $this.paramsSelected['users'] = implodeValues(params.users_selected, 'cod_user');
                }
                $this._loadRowsByPage();
              }); 
            });
          });
        });
    });
  }

  private loadForms() {        
    this.no_records = false;
    this.listLocationForms = [];
    this.isLoading = true;
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    let offset = tmpPage * this.resultsPerPageSelected;
    let limit = this.resultsPerPageSelected;    
    
    let params = {
      cod_location: this.paramsSelected["locations"].toString(),
      term: this.searchTerm,
      cod_user: this.cod_user,
      dat_ini: this.initial_date,
      dat_end: this.final_date,
      limit: limit,
      offset: offset,
      dsc_page_privilege: VIEW_PRIVILEGE,
      search_users: this.paramsSelected["users"].toString(),
      search_str_forms: this.paramsSelected["str_forms"].toString(),
    };

    this._myFormsService.getFormsByLocation(params).subscribe({
      next: (res) => {
        if (res.code === 888 && res.content.length > 0) {
          this.listLocationForms = Array.isArray(res.content.results)
            ? res.content.results
            : [res.content.results];
          this.listLocationForms.forEach(
            (form) =>
              (form.str_form_name = this._myFormsService.getNameByUserLanguage(
                this._userPreferencesService.getLocalLanguage(),
                form,
                "str_form_name"
              ))
          );
          this.maxPage = Math.ceil(
            this.listLocationForms[0].full_count / this.resultsPerPageSelected
          );
          this.totalPages = PaginationHelper.pagination(
            this.pageIndex,
            this.maxPage
          );
        } else {
          this.maxPage = 1;
          this.totalPages = [1];
          this.no_records = true;
        }

        this.isLoading = false;
      },
      error: (err) => {
        this.maxPage = 1;
        this.totalPages = [1];
        this.no_records = true;
        this._authService.errCheck(err);
        this.hasError = true;
        this.isLoading = false;
      }
    });
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }  

  selectWorlds(event) {        
    this.paramsSelected['worlds_selected'] = event.selectedOptions;
    this.paramsSelected['worlds'] = implodeValues(event.selectedOptions, 'cod_locations_world');
    this.loadRegionals(null);
    this.loadStrForms(null);
    //save user pref str_forms
    this.savePref("worlds");
  }
  
  selectRegionals(event: { selectedOptions: any; }) {    
    this.paramsSelected['regionals_selected'] = event.selectedOptions; 
    this.paramsSelected['regionals'] = implodeValues(event.selectedOptions, 'cod_locations_regional');
    this.loadLocations(null);      
      //save user pref regionals
      this.savePref("regionals");
  }
  
  selectLocations($event) {            
    var eventSanitizer = (this._sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
    this.paramsSelected['locations_selected'] = eventSanitizer;
    this.paramsSelected['locations'] = implodeValues(eventSanitizer, 'cod_location');
    this.loadUsers(null);
    this._loadRowsByPage();
    this.loadStrForms(null);
    //save user pref locations
    this.savePref("locations");
  }

  selectUsers($event) {            
    var eventSanitizer = (this._sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
    this.paramsSelected['users_selected'] = eventSanitizer;
    this.paramsSelected['users'] = implodeValues(eventSanitizer, 'cod_user');    
    this._loadRowsByPage();
    //save user pref users
    this.savePref("users");
  }

  selectStrForms($event) {            
    var eventSanitizer = (this._sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
    this.paramsSelected['str_forms_selected'] = eventSanitizer;
    this.paramsSelected['str_forms'] = implodeValues(eventSanitizer, 'cod_form');    
    this._loadRowsByPage();

    //save user pref str_forms
    this.savePref("str_forms");
  }

  private savePref(key){

    const params = {
      cod_user: this.cod_user,
      value: this.paramsSelected[key],
      str_key_pref: `form_${key}_save`
    }
    this._userPreferencesService.postFormFilters(params).subscribe(res =>{
      //DONE
    });
  }



  private _loadRowsByPage() {
    this.pageIndex = 1;
    this.totalPages = [];

    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str10perpage = "10 " + translate;
        this._translateService
          .get("GENERAL.PAGINATION_SELECT")
          .subscribe((translate) => {
            this.str50perpage = "50 " + translate;
            this._translateService
              .get("GENERAL.PAGINATION_SELECT")
              .subscribe((translate) => {
                this.str100perpage = "100 " + translate;
                this.resultsPerPage = [
                  new SelectPagination(10, this.str10perpage),
                  new SelectPagination(50, this.str50perpage),
                  new SelectPagination(100, this.str100perpage),
                ];
                this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
                this.resultsPerPageSelected = this.resultsPerPage[0].qty;
                this.loadForms();
              });
          });
      });
  }
  
  openDetails(lrpuCode, bol_collaborative) {
    localStorage.setItem("shouldGetBackToLocationForms", "true");
    if (!bol_collaborative) {
      return `/my-forms/form/${lrpuCode}`;
    } else {
      return `/my-forms/form-collab/${lrpuCode}`;
    }
  }
}
