// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  
  .loading-container {
    margin: 0px 0;
    text-align: center;
    color: #777777;
  }
  
  .loading-container > .glyphicon {
    margin: 6px;
    font-size: 17px;
  }
  
  .loading-container.--loading > .glyphicon {
    color: #fff;
    animation: spin 1.5s infinite linear;
  }
  
  .loading-container.--theme-white,
  .loading-container.--theme-white > .glyphicon {
    color: rgba(255, 255, 255, 0.9);
  }
  
  .alert-box {
    float: right;
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin: 0px;
    padding: 0px;
  }

  .Component__Alert__Container__Icon {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/button-download-csv/button-download-csv.component.css"],"names":[],"mappings":"AAWE;IACE;MAEE,uBAAuB;IACzB;IACA;MAEE,yBAAyB;IAC3B;EACF;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;IAEX,oCAAoC;EACtC;;EAEA;;IAEE,+BAA+B;EACjC;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;EACf","sourcesContent":["@-webkit-keyframes spin {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(359deg);\n      transform: rotate(359deg);\n    }\n  }\n  \n  @keyframes spin {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(359deg);\n      transform: rotate(359deg);\n    }\n  }\n  \n  .loading-container {\n    margin: 0px 0;\n    text-align: center;\n    color: #777777;\n  }\n  \n  .loading-container > .glyphicon {\n    margin: 6px;\n    font-size: 17px;\n  }\n  \n  .loading-container.--loading > .glyphicon {\n    color: #fff;\n    -webkit-animation: spin 1.5s infinite linear;\n    animation: spin 1.5s infinite linear;\n  }\n  \n  .loading-container.--theme-white,\n  .loading-container.--theme-white > .glyphicon {\n    color: rgba(255, 255, 255, 0.9);\n  }\n  \n  .alert-box {\n    float: right;\n    position: relative;\n    width: 100%;\n    margin-top: 10px;\n    margin: 0px;\n    padding: 0px;\n  }\n\n  .Component__Alert__Container__Icon {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
