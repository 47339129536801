import { Component, Input, OnInit } from '@angular/core';
import { AnalyticInfo } from 'app/five-why/model/analytic-info.model';


@Component({
  selector: 'app-analytic-info',
  templateUrl: './analytic-info.component.html',
  styleUrls: ['./analytic-info.component.css']
})
export class AnalyticInfoComponent implements OnInit{
    @Input("data") data: AnalyticInfo;
    @Input("isLoading") isLoading: boolean
    
    ngOnInit(){
      this.data.show_5why_completed = (this.data.completed_5why / this.data.total_count)*100;
      this.data.show_5why_progress = (this.data.pending_5why / this.data.total_count)*100;
      this.data.show_root_cause = (this.data.root_cause_count / this.data.total_count)*100;
    }
}
