import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {AreaService} from '../services/area.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from "../models/select_pagination.model";
import {Area} from '../models/area.model';
import {NotificationService} from './../../main/services/notification.service';

@Component({
    selector: 'app-area-list',
    templateUrl: './area-list.component.html',
    styleUrls: ['./area-list.component.css']
})
export class AreaListComponent implements AfterViewInit, OnDestroy {

    areaList: Area[] = [];
    hasError = false;
    list: Object;
    paramsSubscription: Subscription = Subscription.EMPTY;
    searchTerm: string;
    searchTimer: any;
    isLoading: boolean = true;
    codAreas: number = 0;
    createRecord: boolean = false;
    procedureImportingAreas: string = 'LOGBOOKS_sp_imp_areas';
    fieldsExcelSheet: string = 'cod_areas,str_description';
    codUser: number;
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    deleteAreaId: number = 0;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    privAreas: boolean;
    dsc_page_privilege: string = 'ADMIN_AREA_LISTING';
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;

    showSuccessMessage: boolean;
    showErrorMessage: boolean;
    emitSuccessMessage: boolean;
    emitErrorMessage: boolean;

    sapAndAcadiaIdPrivilege: boolean;

    constructor(
        private _areaService: AreaService, 
        public _authService: AuthService, 
        private _translateService: TranslateService,
        private _notify: NotificationService
    ) {
        this.showError = false;
        this.errorMessage = '';
        this.searchTerm = '';
        this.list = {
            areas: []
        };
        this.codUser = this._authService.getAuthenticatedUser().id;

        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
        });
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str50perpage = '50 ' + translate;
        });
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str100perpage = '100 ' + translate;
        });                

        this.pageIndex = 1;
        this.totalPages = [];
        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;
        this.resultsPerPage = [
            new SelectPagination(10, this.str10perpage),
            new SelectPagination(50, this.str50perpage),
            new SelectPagination(100, this.str100perpage),
        ];
        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];        
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;        
    }

    ngOnInit(){
        this.privAreas = this._authService.getUserPrivilege('ADMIN_LOGBOOKS_AREA_SELECT_WORLDS');
        this.sapAndAcadiaIdPrivilege = this._authService.getUserPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
    }
    
    ngAfterViewInit() {
        let objParam = {'cod_areas': 0, 'term': this.searchTerm};
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        }
        catch (err) {
            //safe!
            console.log(err);
        }
    }

    public editArea(id, reload) {
        if (id == -1) {
            id = 0;
            this.emitErrorMessage = false;
            this.emitSuccessMessage = true;
        } else {
            this.emitErrorMessage = true;
            this.emitSuccessMessage = false;
        }
        this.codAreas = id;
        this.createRecord = !reload;
        if (reload) {
            this._search();
        }
    }

    public toggleDeleteArea(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteAreaId = id;
    }

    public confirmDeleteArea() {
        let objParam = {
            'cod_areas': this.deleteAreaId,
            'cod_user': this.codUser
        };
        this._areaService.deleteArea(objParam).subscribe(data => {
                this.search(false);
                this.emitErrorMessage = false;
                this.emitSuccessMessage = true;
                this._notify.success({text: 'LOGBOOK.AREA_DELETED', translate: true});
            }, err => {
                this.emitErrorMessage = true;
                this.emitSuccessMessage = false;
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
        this.awaitConfirmDelete = false;
        this.deleteAreaId = 0;
    }

    public search(resetPage: boolean) {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            if (resetPage) {
                $this.pageIndex = 1;
            }
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        this.isLoading = true;
        let objParam = {
            'term': this.searchTerm, 
            'cod_user': this.codUser, 
            'dsc_page_privilege': this.dsc_page_privilege,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected)
        };

        this._areaService.getListAreasByPagination(objParam).subscribe(data => {
                this.list = {
                    areas: this._areaService.getArrayContent(data)
                }; 
                if (data.content.length > 0) {
                    this.maxPage = Math.ceil((data.content.length == 1 ? data.content.results.full_count:data.content.results[0].full_count) / this.resultsPerPageSelected);
                    this.totalPages = this.pagination(this.pageIndex, this.maxPage);
                }                  
                let arr_headers=[];

                this._translateService.get('REPORTS.COD_AREA').subscribe(translate => {
                    arr_headers.push(translate);
                });
                this._translateService.get('REPORTS.AREA').subscribe(translate => {
                    arr_headers.push(translate);
                });
                this._translateService.get('REPORTS.COD_WORLDS').subscribe(translate => {
                    arr_headers.push(translate);
                });
                this._translateService.get('REPORTS.WORLDS').subscribe(translate => {
                    arr_headers.push(translate);
                });
                this.downloadCsvSetup = {
                    cod_user: this.codUser,
                    api_url: this._reportsApiUrl,
                    str_proc_exec: 'reports_ex_areas',
                    arr_proc_params_search: [
                        this.searchTerm
                    ],
                    arr_headers: arr_headers,
                    name_of_file_to_download: 'areas_',
                    str_column_dynamic: ''
                };
                this.showErrorMessage = this.emitErrorMessage;
                this.showSuccessMessage = this.emitSuccessMessage;
                this.emitErrorMessage = null;
                this.emitSuccessMessage = null;
                this.isLoading = false;
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
                this.isLoading = false;
            }
        );
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
            this._notify.error(translate);
        });
    }

    messageImported() {
        this._showErrorMessage('LOGBOOK.MESSAGE_IMPORTED_SUCCESS');
        this.search(false);
    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.pageIndex = page;
        this.search(false);
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    hideMessage() {
        this.showErrorMessage = false;
        this.showSuccessMessage = false;
    }

}
