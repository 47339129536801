import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class LogbooksRoutineCommentsService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all routine comments.
     * @param param: JSON object filled with search "term" and/or cod_routine
     */
    getCommentsList(param): Observable<any> {
        let path = '';
        if (param.cod_routine_comments > 0) {
            path = '/routine-comment/' + param.cod_routine_comments + '/' + param.cod_user;
            return this._mainService.get(path);
        }
        else {
            path = '/routine-comments';
            return this._mainService.post(path, param);
        }
    }

    listRoutineCommentCollab(params): Observable<any>{
        const path = '/list-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    /**
     * Post comment object to database
     * @param params: JSON object of comment
     */
    postComment(params): Observable<any> {
        let path = '/routine-comment';
        return this._mainService.post(path, params);
    }

    putComment(params): Observable<any> {
        let path = '/routine-comment';
        return this._mainService.put(path, params);
    }

    deleteComment(params): Observable<any> {
        let path = '/routine-comment/' + params.cod_routines_comments + '/' + params.cod_user;
        return this._mainService.delete(path);
    }

    deleteRoutineCommentCollab(params): Observable<any>{
        const path = '/delete-routine-comment-collab';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }
}
