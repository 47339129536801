import * as moment from 'moment';

export class Rating {
    cod_user: number;
    str_name: string;
    cod_fivewhy: number;
    cod_rating: number;
    bol_5why_work_area: boolean;
    bol_info_completed: boolean;
    bol_pple_present: boolean;
    bol_prev_actions_prop: boolean;
    bol_seq_5why_make_sense: boolean;
    bol_true_root_cause_found: boolean;
    bol_whys_sup_evidence: boolean;
    str_comments: string;
    vl_rating: number;
    dat_ins: string;

    constructor() {
        this.cod_user = 0;
        this.str_name = '';
        this.cod_fivewhy = 0;
        this.cod_rating = 0;
        this.bol_5why_work_area = true;
        this.bol_info_completed = true;
        this.bol_pple_present = true;
        this.bol_prev_actions_prop = true;
        this.bol_seq_5why_make_sense = true;
        this.bol_true_root_cause_found = true;
        this.bol_whys_sup_evidence = true;
        this.str_comments = '';
        this.vl_rating = 0;
        this.dat_ins = moment(new Date()).format('YYYY-MM-DD');
    }
}