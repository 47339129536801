import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingsService } from '../services/meetings.service';
import {AuthService} from "../../main/access/services/auth.service";

@Component({
  selector: 'app-meetings-home-panel',
  templateUrl: './meetings-home-panel.component.html',
  styleUrls: ['./meetings-home-panel.component.css']
})
export class MeetingsHomePanelComponent implements OnInit {
  arr_meetings_today_home_panel: any[] = [];
  arr_meetings_upcoming_home_panel: any[] = [];
  bol_loading_list: boolean = true;
  obj_user: any;
  activeTab: string = 'today';
  todayLoaded: boolean = false;
  upcomingLoaded: boolean = false;
  totalSpent: number;

  constructor(
      private readonly _meetingsService: MeetingsService,
      private readonly _router: Router,
      private readonly _authService?: AuthService
  ) {
    this.obj_user = this._authService?.getAuthenticatedUser();
  }

  ngOnInit() {
    this.setActiveTab(this.activeTab);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    const loadMap = {
      'today': { loaded: this.todayLoaded, action: () => this.getMeetingsTodayHomePanel() },
      'upcoming': { loaded: this.upcomingLoaded, action: () => this.getUpcomingMeetingsHomePanel() }
    };

    if (loadMap[tab] && !loadMap[tab].loaded) {
      this.bol_loading_list = true;
      loadMap[tab].action();
    } else {
      this.bol_loading_list = false;
    }
  }

  getMeetingsTodayHomePanel() {
    this.totalSpent = 0;
    const observer = {
      next: (data: any) => {
        this.arr_meetings_today_home_panel = data.content.results;
        this.arr_meetings_today_home_panel.forEach(element => {
          this.totalSpent = this.totalSpent + element.int_duration;
        });
        this.bol_loading_list = false;
        this.todayLoaded = true;
      },
      error: (err: any) => {
        this._authService?.errCheck(err);
        this.bol_loading_list = false;
      }
    };
    this._meetingsService.getMeetingsTodayHomePanel(this.obj_user?.id).subscribe(observer);
  }

  getUpcomingMeetingsHomePanel() {
    const observer = {
      next: (data: any) => {
        this.arr_meetings_upcoming_home_panel = data.content.results;
        this.bol_loading_list = false;
        this.upcomingLoaded = true;
      },
      error: (err: any) => {
        this._authService?.errCheck(err);
        this.bol_loading_list = false;
      }
    };
    this._meetingsService.getMeetingsUpcomingHomePanel(this.obj_user?.id).subscribe(observer);
  }

  openMeeting(meeting) {
    const path = `/meetings-container/${meeting.cod_meetings}`;
    this._router.navigate([path]);
  }

  formatTime(duration){
    let hours = Math.floor(duration/60);
    let minutes = duration%60;

    if(!duration) return ''

    if(hours > 0)
      if (minutes > 0)
        return `${hours}H:${minutes}Min` 
      else 
        return `${hours}H`
    return `${minutes}Min`
  }

}