// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-afk { 
    margin-top: 200px;
}

.modal-dialog { 
    width: 750px;
}
.modal-header  {
    text-align: center;
}

.modal-body {
    text-align: center;
}

.modal-title {
    padding-top: 20px;
    font-size: 20px;
}

.default-button.--Ok {
    background-color: var(--ui-primary);
}

.default-button {
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;
    border-radius: 3px;
    color: var(--text-ui-primary );
    margin: 0 0.25em;
    outline: none;
    padding: 0.5em;
    width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/afk-warning-modal/afk-warning-modal.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,2CAA2C;IAC3C,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,YAAY;AAChB","sourcesContent":[".modal-afk { \n    margin-top: 200px;\n}\n\n.modal-dialog { \n    width: 750px;\n}\n.modal-header  {\n    text-align: center;\n}\n\n.modal-body {\n    text-align: center;\n}\n\n.modal-title {\n    padding-top: 20px;\n    font-size: 20px;\n}\n\n.default-button.--Ok {\n    background-color: var(--ui-primary);\n}\n\n.default-button {\n    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 5px;\n    border-radius: 3px;\n    color: var(--text-ui-primary );\n    margin: 0 0.25em;\n    outline: none;\n    padding: 0.5em;\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
