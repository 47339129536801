import { Injectable } from "@angular/core";
import { AuthMainService } from "app/components/services/auth-main.service";
import { BehaviorSubject } from "rxjs";

export type EmbedPayload = {
    datasets: { id: string }[]
    reports: { id: string }[]
}

export type TokenResponse = {
    token: string
    tokenId: string,
    expiration: string
}

type pbiTokenInfo = {
    token_type: string,
    expires_in: number,
    ext_expires_in: number,
    access_token: string
}

@Injectable()
export default class PowerBiTokenService {
    tokenResponse: BehaviorSubject<TokenResponse> = new BehaviorSubject<TokenResponse>({ token: '', tokenId: '', expiration: '' })
    tokenError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    lastUsedEmbedPayload: EmbedPayload;
    accessTokenCodeChallengeKey = 'accessTokenFromCodeChallenge'
    accessTokenLoginPageKey = 'accessTokenLoginPage'

    constructor(
        private authMainService: AuthMainService
    ){}

    public getPowerBiToken() {
        return this.authMainService.get('/auth/sso/get-powerbi-token')
    }

    public getEmbeddedToken(payload: EmbedPayload): void {

        this.getPowerBiToken().subscribe((tokenData: pbiTokenInfo) => {

            const { access_token } = tokenData
            const headers: HeadersInit = { 
                Authorization: `Bearer ${access_token}`,
                'Content-type': 'application/json'
            }
            const data = new FormData()
            data.append('datasets', payload.datasets.toString())
            data.append('reports', payload.reports.toString())
            fetch('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers
            }).then(res => res.json())
            .then(res => this.tokenResponse.next(res))
            .catch(() => this.tokenError.next(true))
        },
        () => this.tokenError.next(true)
        )   
    }
}