import { Component } from '@angular/core';
import { SelectionInputComponent } from '../selection-input/selection-input.component';

@Component({
    selector: 'yes-no-input',
    templateUrl: './../selection-input/selection-input.component.html',
    styleUrls: [
        './../forms-input/forms-input.component.css', 
        './../selection-input/selection-input.component.css', 
        './yes-no-input.component.css'
    ]
})

export class YesNoInputComponent extends SelectionInputComponent<string> {
    
    constructor() {
        super();
    }

}
