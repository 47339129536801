export class UserPreference {
    cod_user_preference: number;
    cod_user: number;
    str_pref_key: string;
    str_pref_value: string;
    bol_change_preference: number;

    constructor() {
        this.cod_user_preference = 0;
        this.cod_user = 0;
        this.str_pref_key = '';
        this.str_pref_value = '';
        this.bol_change_preference = 0;
    }
}
