import {Injectable} from '@angular/core';
import {ListEquipmentsTaMainService} from './list-equipments-ta-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListEquipmentsTaService {
  
  constructor(private _mainService: ListEquipmentsTaMainService) {
  }
  
  getEquipmentsTaList(param): Observable<any> {
    let path = '/forms/equipments?term=' + param.term + '&location=' + param.location + '&cod_subarea=' + param.subarea + '&cod_user=' + param.cod_user;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
