import {Injectable} from '@angular/core';
import {AnalyticsMainService} from './analytics-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ActionsAnalyticsService {
  
  constructor(private _mainService: AnalyticsMainService) {
    
  }
  
  getLocationsList(): Observable<any> {
    let path = '/get_unidades';
    return this._mainService.get(path);
  }

  postActionQuality(params): Observable<any> {
    const path = '/';
    return this._mainService.post(path, params);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
