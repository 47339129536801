import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Routine, RoutineType } from '../models/routine.model';
@Injectable()
export class RoutineDataService {

    private obj: Routine;

    private routineSource = new BehaviorSubject<Routine>(new Routine(0));
    public routine = this.routineSource.asObservable();

    private routineTypeSource = new BehaviorSubject<RoutineType>(null);
    public routineType = this.routineTypeSource.asObservable();

    private isCollabSource = new BehaviorSubject<boolean>(false);
    public isCollab = this.isCollabSource.asObservable();

    private isFormSource = new BehaviorSubject<boolean>(false);
    public isForm = this.isFormSource.asObservable();

    private isAcadiaSource = new BehaviorSubject<boolean>(false);
    public isAcadia = this.isAcadiaSource.asObservable();

    private isDailySource = new BehaviorSubject<boolean>(false);
    public isDaily = this.isDailySource.asObservable();

    private isAsNeededSource = new BehaviorSubject<boolean>(false);
    public isAsNeeded = this.isAsNeededSource.asObservable();

    private isFutureDueSource = new BehaviorSubject<boolean>(false);
    public isFutureDue = this.isFutureDueSource.asObservable();

    private isPastDueSource = new BehaviorSubject<boolean>(false);
    public isPastDue = this.isPastDueSource.asObservable();

    private formSelectedSource = new BehaviorSubject<number>(null);
    public formSelected = this.formSelectedSource.asObservable();

    constructor() {
        this.routine.subscribe(routine => this.obj = routine);
    }

    public changeRoutine(params: any) : void {
        this.routineSource.next({...this.obj, ...params});
    }

    public changeRoutineType(routineType: RoutineType) : void {
        this.routineTypeSource.next(routineType);
    }

    public changeIsCollab(isCollab: boolean) : void {
        this.isCollabSource.next(isCollab);
    }

    public changeIsForm(isForm: boolean) : void {
        this.isFormSource.next(isForm);
    }

    public changeIsAcadia(isAcadia: boolean) : void {
        this.isAcadiaSource.next(isAcadia);
    }

    public changeIsDaily(isDaily: boolean) : void {
        this.isDailySource.next(isDaily);
    }

    public changeIsAsNeeded(isAsNeeded: boolean) : void {
        this.isAsNeededSource.next(isAsNeeded);
    }

    public changeIsFutureDue(isFutureDue: boolean) : void {
        this.isFutureDueSource.next(isFutureDue);
    }

    public changeIsPastDue(isPastDue: boolean) : void {
        this.isPastDueSource.next(isPastDue);
    }

    public changeFormSelected(formSelected: number) : void {
        this.formSelectedSource.next(formSelected);
    }

}