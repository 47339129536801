import {EventEmitter, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import * as _ from 'lodash';

import {MainService} from './main.service';

import {FilterByStatus} from '../meetings-actions-list/meetings-actions-list.pipes';
import { CreateActionParams, ChangeActionStatus, EditActionParams, ChangeActionDeadline, ActionComment } from './types/actions-service-types';
import { GenericRequestType } from './types/generic-response-types';

@Injectable()
export class ActionsService {

  static readonly selectedTask = new EventEmitter<any>();
  private arr_column_not_options: Array<any>;

  private readonly refreshEvent = new ReplaySubject<any>(1); // emmits X last events upon subscription, X here is 1

  constructor(private readonly _mainService: MainService) {
    this.arr_column_not_options = [];
  }

  getRefreshEventSubject(){
    return this.refreshEvent;
  }

  getColumnNotOptions() {
    return this.arr_column_not_options;
  }

  getActionsDate(cod_user, date): Observable<any> {
    const str_path = '/actions-user-date/' + cod_user + '/' + date;
    return this._mainService.get(str_path);
  }

  getActionsFuture(cod_user): Observable<any> {
    const str_path = '/actions-future/' + cod_user;
    return this._mainService.get(str_path);
  }

  getActionsOverdue(cod_user): Observable<any> {
    const str_path = '/actions-user-overdue/' + cod_user;
    return this._mainService.get(str_path);
  }

  getAllActions(cod_user): Observable<any> {
    const str_path = '/all-actions-user/' + cod_user;
    return this._mainService.get(str_path);
  }

  getMeetingsTeam(cod_manager): Observable<any> {
    // meetings-delayed-actions
    const path = '/actions-team/' + cod_manager;
    return this._mainService.get(path);
  }


  // not being used ????
  getUsersTeam(cod_manager): Observable<any> {
    const path = '/users-team/' + cod_manager;
    return this._mainService.get(path);
  }

  getCountActionsUser(date, cod_user): Observable<any> {
    const str_path = '/actions-count-user/' + date + '/' + cod_user;
    return this._mainService.get(str_path);
  }

  getCountActionsUserWeek(cod_user): Observable<any> {
    const str_path = '/actions-count-user-week/' + cod_user;
    return this._mainService.get(str_path);
  }

  getCountActionsMeetingEvolution(date, cod_meeting, cod_user): Observable<any> {
    const str_path = '/actions-count-meeting-evolution/' + date + '/' + cod_meeting + '/' + cod_user;
    return this._mainService.get(str_path);
  }

  getActionsStatus(cod_user): Observable<any> {
    const str_path = '/action_status/'+cod_user;
    return this._mainService.get(str_path);
  }

  getMeetingList(cod_meeting, bool_filter_finished, img_path, cod_user): Observable<any> {
    const str_path = '/actions-meeting-list/' + cod_meeting + '/' + bool_filter_finished  + '/' + encodeURIComponent(img_path) + '/' + cod_user;   
    return this._mainService.get(str_path);
  }

  // not used ? route not found in backend...
  getAction(cod_action): Observable<any> {
    const str_path = '/action/' + cod_action;
    return this._mainService.get(str_path);
  }

  isArray (value) {
    return (!!value && typeof value === 'object' && value.constructor === Array);
  }

  verifyCountRow(arr) {
    let arrOk;

    if(this.isArray(arr)) {
      arrOk = arr;
    } else {
      arrOk = [arr];
    }

    return arrOk;
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  filterActions(arr_filters, arr_action_main, filter_finished) {

    if (typeof arr_action_main == 'undefined') {
      arr_action_main = [];
    }

    let arr_return = this.verifyCountRow(arr_action_main);
    arr_return = FilterByStatus.prototype.transform(arr_return, filter_finished , null, null);
    

    arr_filters.forEach(filter => {
      switch (filter.type) {
        case 'description':

          arr_return = arr_return.filter( action => (action.str_description ?
            action.str_description.toLowerCase().includes(filter.value ? filter.value.toLowerCase() : '') : false));

          break;

        case 'owner':

          arr_return = arr_return.filter( action => (action.str_owner_name
            ? action.str_owner_name.toLowerCase().includes(filter.value ? filter.value.toLowerCase() : '') : false));

          break;

        case 'date':

          if (filter.value !== '' && filter.value2 != '') {
            arr_return = arr_return.filter( action => {
              const date2 = new Date(filter.value2);
              date2.setDate(date2.getDate() + 1);
              return ( (new Date(action.dat_due) > new Date (filter.value)) && (new Date(action.dat_due) <  date2) );
            });
          }

          break;

        case 'status':

          if (filter.value !== '') {
            arr_return = arr_return.filter( action =>  {
              let filterArr = filter.value.split(',');

              filterArr = filterArr.map(it => {
                return Number(it);
              });

              return (filterArr.includes(action.cod_actions_status));
            });
          }
          break;
          case 'indicator':
            if (filter.value != '') {
              const response = new Array();
              for(let action of arr_return) {
                if(action?.indicators && Array.isArray(action?.indicators)) {
                  action.indicators.forEach((indicator) => {
                    indicator?.cod_indicator == filter.value && response.push(action);
                  });
                } else if(action.cod_indicator == filter.value) {
                  response.push(action);
                };
              };  
              arr_return = response;
            }  
            break;
          case 'department':

            if (filter.value != '') {
                 arr_return = arr_return.filter(action => filter.value.find(el => el == action.cod_area));            
              }    
              break;

        case 'sources':

          if (filter.value != '') {
              arr_return = arr_return.filter(action => (action.str_sources ?
              action.str_sources.toLowerCase().includes(filter.value ? filter.value.toLowerCase() : '') : false));
          }

          break;

        case 'criticity':

          arr_return = arr_return.filter(action => {
            return (action.bol_is_critic == 1) ? 1 : 0;
          });

          break;

        case 'time':

          if (filter.value != '') {
            arr_return = arr_return.filter( action => {
              const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
              const firstDate = new Date(action.dat_due);
              const secondDate = new Date();
              return ( Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay))) > filter.value);
            });
          }

          break;

        case 'column':

          if (filter.value) {
            const arr_not_options = [];
            let str_check_not_options = '';
            arr_return = arr_return.filter( action => {

              let bol_return = false;
              if (action.columns && action.columns.length > 0) {
                action.columns.forEach(column => {

                  // CASE NOT OPTIONS IN COLUMN
                  if ((filter.value2.str == '' || filter.value2.cod == 0)
                    && (column.str_name == filter.value.str || column.cod_columns == filter.value.cod)) {

                    if (str_check_not_options.indexOf(column.str_description_answer) == -1) {
                      // APPEND OPTIONS THAT ARE TYPED IN ANSWERS
                      arr_not_options.push({cod_columns_options: arr_not_options.length + 1, str_name: column.str_description_answer});
                      str_check_not_options += column.str_description_answer;
                    }

                    this.arr_column_not_options = arr_not_options;
                    // set options of typed things with no option
                    bol_return = true;
                    return;
                  } else if (
                    (column.str_name == filter.value.str || column.cod_columns == filter.value.cod)
                    && (column.str_description_answer == filter.value2.str || column.cod_description_answer == filter.value2.cod)) {
                    // CASE HAVE OPTIONS IN COLUMN
                    bol_return = true;
                    return;
                  }
                });
              }
              return bol_return;
            });
          }

          break;

        default:

          break;
      }
    });
    return arr_return;
  }

  duplicate(n) {
    const obj = [];
    // IF THERE IS MULTIPLE HASHTAGS, ENTER THE IF TO SPLIT IT
    if (n['hashtags'] !== null && n['hashtags'].length > 1) {
      // ITERATE HASHTAGS DO SPLIT THE OBJECT
      n['hashtags'].map(item => {
        // CLONE THE ITEM OBJECT TO CHANGE TO A SINGLE HASHTAG TO GROUP
        const nn = { ...n };
        nn.hashtags = [item];
        obj.push(nn);
      });
      return obj;
    } else {
      return [n];
    }
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  groupActions(str_agrupament, arr_action_main) {
    let objReturn = [];

    arr_action_main = this.verifyCountRow(arr_action_main);

    switch (str_agrupament) {
      case 'owner':

        arr_action_main.forEach(function (action) {
          if (action) {
            if (!objReturn[action.cod_owner]) {
              let $index = 0;
              for (let j = 0; j < action.str_owner_name.length; j++) {
                $index += action.str_owner_name.toLowerCase().charCodeAt(j);
              }
              objReturn[action.cod_owner] = {
                description: action.str_owner_name,
                str_img_path: action.str_img_path,
                cod_user: action.cod_owner,
                index: $index,
                values: []
              };
            }
            objReturn[action.cod_owner].values.push(action);
          }
        });

        break;

      case 'hashtag':

        arr_action_main.forEach(function (action) {
          if (action) {
            if(action.hashtags == null){
              action.hashtags = ['#'];
            }
          }
        });

        arr_action_main.sort(function(a, b){
          const c = (a.hashtags[0]) ? a.hashtags[0].toLowerCase() : '';
          const d = (b.hashtags[0]) ? b.hashtags[0].toLowerCase() : '';
          return c > d ? 1 : -1;
        });

        const arr_actions_flatten = _.flatMap(arr_action_main, this.duplicate);

        let i = -1;

        objReturn = _(arr_actions_flatten)
        .groupBy('hashtags')
        .map(function(items, hashtag) {
          i = i + 1;
          return {
            description: hashtag,
            values: items,
            index: i
          };
        })
        .filter(item => {
          return item.description.toString() !== 'null';
        }).value();

        break;

      case 'columns':

        arr_action_main.forEach(function (action) {
          if (action) {
            if (action.columns) {
              action.columns.forEach(column => {
                let $index = 0;
                for (let j = 0; j < column.str_name.length; j++) {
                  $index += column.str_name.toLowerCase().charCodeAt(j);
                }
                if (!objReturn[column.cod_columns]) {
                  objReturn[column.cod_columns] = {
                    description: column.str_name,
                    index: $index,
                    values: []
                  };
                }
                objReturn[column.cod_columns].values.push(action);
              });
            }
          }
        });

        break;

      default:

        arr_action_main.forEach(function (action) {
          if (action) {
            if (!objReturn[action.cod_actions_status]) {
              let $index = 0;
              for (let j = 0; j < action.str_status_description.length; j++) {
                $index += action.str_status_description.toLowerCase().charCodeAt(j);
              }
              objReturn[action.cod_actions_status] = {
                description: action.str_status_description,
                cod_actions_status: action.cod_actions_status,
                cod_order_status: action.cod_order_status,
                index: $index,
                values: []
              };
            }
            objReturn[action.cod_actions_status].values.push(action);
          }
        });
        objReturn.sort(function(a, b) { return a.cod_order_status - b.cod_order_status; });

        break;
    }
    return objReturn.filter(function(n) { return n != undefined; });
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  orderActions(arr_orders, arr_action_main) {
    const arr_return = this.verifyCountRow(arr_action_main);

    if(arr_orders && arr_orders.length == 0){
      arr_orders = ['date'];
    }

    arr_orders.forEach(order => {
      switch (order) {
        case 'date':

          arr_return.forEach(actionsGroup => {
            actionsGroup.values.sort(function(a, b){
              const c = new Date(a.dat_due);
              const d = new Date(b.dat_due);
              return c > d ? 1 : -1;
            });
          });

          break;

        case 'description':

          arr_return.forEach(actionsGroup => {
            actionsGroup.values.sort(function(a, b) {
              const x = (a.str_description) ? a.str_description.toLowerCase() : '';
              const y = (b.str_description) ? b.str_description.toLowerCase() : '';
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
          });

          break;

        case 'criticity':

          arr_return.forEach(actionsGroup => {
            actionsGroup.values.sort(function(a, b) {
              if (a.bol_is_critic > b.bol_is_critic) { return -1; }
              if (a.bol_is_critic < b.bol_is_critic) { return 1; }
              return 0;
            });
          });

          break;

        case 'owner':

          arr_return.forEach(actionsGroup => {
            actionsGroup.values.sort(function(a, b) {
              const x = (a.str_owner_name) ? a.str_owner_name.toLowerCase() : '';
              const y = (b.str_owner_name) ? b.str_owner_name.toLowerCase() : '';
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
          });

          break;

        default:
          break;
      }
    });
    return arr_return;
  }

  postNewAction(params): Observable<any> {
    const path = '/action';
    return this._mainService.post(path, params);
  }

  postAction(params): Observable<any> {
    const path = '/actions';
    return this._mainService.post(path, params);
  }

  postFormAction(params){
    const path = '/save-form-action';
    return this._mainService.post(path, params);
  }

  putCriticAction(params){
    const path = '/update-critic-action';
    return this._mainService.put(path, params);
  }

  postMeetingActionMessage(params) {
    const path = '/saveMeetingActionMessage';
    return this._mainService.post(path, params);
  }

  deleteMeetingActionMessage(params) {
      const path = '/action-comments/' + params.cod_actions_comments + '/' + params.cod_user;
      return this._mainService.delete(path);
  }

  putMeetingActionMessage(params) {
      const path = '/action-comments';
      return this._mainService.put(path, params);
  }

  // getActionsComments(cod_action) {
  //     const path = '/actions-comments/' + cod_action + '/';
  //     return this._mainService.get(path);
  // }

  postChangeStatusActions(params) {
    const path = '/changeStatusActions';
    return this._mainService.post(path, params);
  }

  postActionsDetails(params) {
    const path = '/actions-details';
    return this._mainService.post(path, params);
  }

  getActionsDetails(cod_action, cod_user, str_path): Observable<any> {
    const path = '/action/' + cod_action + '/' + cod_user + '/' + encodeURIComponent(str_path);
    return this._mainService.get(path);
  }


  postActionsColumns(params) {
    const path = '/meetings-columns-actions';
    return this._mainService.post(path, params);
  }

  deleteMeetingActionLink(params) {
    const path = '/action-meeting/' + params.cod_action + '/' + params.cod_meeting + '/' + params.cod_user;
    return this._mainService.delete(path);
  }

  /* V2 ENDPOINTS SECTION */
  
  getActionTagsByMeetings(cod_user: number, arr_cod_meetings: number[]): Observable<any> {
    const meetings_ids = arr_cod_meetings.join(',');
    const path = `/v2/${cod_user}/get-action-tags-by-meetings?arr_cod_meetings=${meetings_ids}`;
    return this._mainService.get(path);
  }

  searchUserMeetings(cod_user: number, str_term: string, cod_user_search?: number): Observable<any> {
    const path = `/v2/${cod_user}/search-user-meetings`;
    let query = {
      str_term
    };
    if (cod_user_search) query['cod_user_search'] = cod_user_search

    return this._mainService.get(path, query);
  }
  getDepartmentsByMeetings(cod_user: number, arr_cod_meetings: number[]): Observable<any> {
    const meetings_ids = arr_cod_meetings.join(',');
    const path = `/v2/${cod_user}/departments-by-meetings?arr_cod_meetings=${meetings_ids}`;
    return this._mainService.get(path);
  }

  postCreateActions(params: CreateActionParams, cod_user: number): Observable<GenericRequestType<any>> {
    const path = `/v2/${cod_user}/create-actions-by-responsibles`;
    return this._mainService.post(path, params);
  }

  getMeetingActionsList(cod_meeting, cod_user, params): Observable<any> {
      const path = `/v2/${cod_user}/${cod_meeting}/actions/list`;
      let query = {};
      if(params.offset != null && params.offset >= 0) query[`_offset`] = params.offset;
      if(params.limit != null && params.limit > 0) query[`_size`] = params.limit;
      if(params.order != null && params.order != '') query[`_order`] = params.order;
      if(params.owner_filter != null && params.owner_filter != '') query[`owner_filter`] = params.owner_filter;
      if(params.desc_filter != null && params.desc_filter != '') query[`desc_filter`] = params.desc_filter;
      if(params.kpi_filter != null && params.kpi_filter.length > 0) query[`kpi_filter`] = params.kpi_filter.toString();
      if(params.status_filter != null && params.status_filter.length > 0) query[`status_filter`] = params.status_filter.toString();
      return this._mainService.get(path, query);
  }

  getActionComments(cod_user, cod_action): Observable<GenericRequestType<ActionComment[]>> {
    const path = `/v2/${cod_user}/action/${cod_action}/comments`
    return this._mainService.get(path);
  }

  getActionDetails(cod_user, cod_action): Observable<any> {
    const path = `/v2/${cod_user}/action/${cod_action}/details`;
    return this._mainService.get(path);
  }

  changeActionStatus(cod_user, cod_action, params: ChangeActionStatus): Observable<GenericRequestType<any>> {
    const path = `/v2/${cod_user}/action/${cod_action}/status`;
    return this._mainService.put(path, params)
  }

  putEditActionDeadline(cod_user, cod_action, params: ChangeActionDeadline): Observable<GenericRequestType<{ put_edit_action_deadline: number }>> {
    const path = `/v2/${cod_user}/action/${cod_action}/deadline`;
    return this._mainService.put(path, params)
  }

  getSingleAction(cod_user, cod_action): Observable<any>{
    const path = `/v2/${cod_user}/action/${cod_action}/list`;
    return this._mainService.get(path);
  }
  
  putEditAction(cod_user, cod_action, params: EditActionParams): Observable<GenericRequestType<{ put_edit_action: number }>> {
    const path = `/v2/${cod_user}/action/${cod_action}/details`;
    return this._mainService.put(path, params);
  }

  getActionsByDate(cod_user: number, cod_meeting: number, pr_type_period: string, pr_initial_date: string, pr_final_date: string): Observable<any> {
    const path = `/v2/${cod_user}/${cod_meeting}/actions-by-date?pr_type_period=${pr_type_period}&pr_initial_date=${pr_initial_date}&pr_final_date=${pr_final_date}`;
    return this._mainService.get(path);
  }

  postActionComment(cod_user, cod_action, params): Observable<GenericRequestType<{ cod_action_comment: number, dat_ins: string }>>{
    const path = `/v2/${cod_user}/action/${cod_action}/comment`;
    return this._mainService.post(path, params);
  }

  putActionComment(cod_user, cod_action_comment, params): Observable<GenericRequestType<any>>{
    const path = `/v2/${cod_user}/comment/${cod_action_comment}`;
    return this._mainService.put(path, params);
  }

  deleteActionComment(cod_user: number, cod_action_comment: number): Observable<GenericRequestType<{ cod_action_comment: number, dat_ins: string }>>{
    const path = `/v2/${cod_user}/action-comment/${cod_action_comment}`;
    return this._mainService.delete(path);
  }
}
