import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/main/access/services/auth.service";
import { UploadService } from "app/main/services/upload.service";
import { environment } from "environments/environment";


@Component({
  selector: "app-attachment-save",
  templateUrl: "./attachment-save.component.html",
  styleUrls: ["./attachment-save.component.css"],
})
export class AttachmentSaveComponent implements OnInit {
  @Input("attachments") attachments: any;
  @Input('disabled') disabled: boolean = false;
  @Input('showAddOption') showAddOption: boolean = false;
  @Input('showAttachments') showAttachments: boolean = false;
  @Input('showDeleteOption') showDeleteOption: boolean = true;

  @Input('editOnlyMyAttachRule') editOnlyMyAttachRule: boolean = false;

  @Output("onImageDelete") onImageDelete = new EventEmitter<any>();
  @Output("onImageAdd") onImageAdd = new EventEmitter<any>();

  pdfIcon: string = "../../../assets/images/icon-pdf.svg";

  str_image_path: string;
  
  showError: boolean = false;
  errorMessage: string;
  user_logged_id: string;
  displayImage: Object;
  expandedModal = false;

  constructor(
    public _authService: AuthService,
    private _uploadService: UploadService,
    private _translateService: TranslateService
  ) {
    this.str_image_path = environment().uploadGetFilesApiUrl;
    this.user_logged_id = _authService.getAuthenticatedUser().id;
   
  }
  ngOnInit(): void {
    this.attachments.forEach(x => {
      const splitText = x.str_url_file.split('.');
      const fileType = splitText[splitText.length - 1];
      
      if(fileType.toUpperCase() == 'PDF'){
        x.isPdf = true;
      }
    })
  }

  expandSelected(str_image){
    this.displayImage = {
      path: this.str_image_path,
      file: str_image
    };
    this.expandedModal = !this.expandedModal;
  }

  downloadSelected(str_image) {
    this._uploadService
      .downloadFile(this.str_image_path + "/" + str_image)
      .subscribe(
        (res) => {
          let blob = new Blob([res], {
            type: "image/jpeg",
          });

          let a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = str_image;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          
        },
        (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      );
  }

  deleteSelected(obj_attachment, attachment_index) {
    this.attachments.splice(attachment_index, 1)
    this.onImageDelete.emit(obj_attachment);
  }

  fileChangeEvent(event) {
    let obj_params = {
      event: event,
      allowedExtensions: [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
      ],
    };

    this._uploadService.uploadFileBase64(obj_params).subscribe(
      (response) => {
        if (response.type == "success") {
          let imgName = response.content.str_name
          const splitText = imgName.split('.');
          const fileType = splitText[splitText.length - 1];          
          const newFileAdded = {dat_ins: new Date(), str_url_file: response.content.str_name, isPdf: fileType.toUpperCase() == 'PDF'};
          this.attachments.push(newFileAdded);
          this.onImageAdd.emit(newFileAdded);
        } else {
          this._showErrorMessage(response.content)
        }
      },
      (err) => {
        let e = JSON.parse(err._body);
        console.log(e);
        this._showErrorMessage(err);
      }
    );
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
  }

  closeZoom(){
    this.expandedModal = !this.expandedModal;
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe((translate) => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }
}
