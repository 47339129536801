import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {TaskTopic1Component} from './task-topic-1/task-topic-1.component';
import {TaskTopic2Component} from './task-topic-2/task-topic-2.component';

@NgModule({
    imports: [
CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [TaskTopic1Component, TaskTopic2Component],
    declarations: [TaskTopic1Component, TaskTopic2Component],
    providers: []
})
export class InfoTaskModule {
}
