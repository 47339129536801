import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-version-warning',
  templateUrl: './version-warning.component.html',
  styleUrls: ['./version-warning.component.css']
})
export class VersionWarningComponent implements OnInit {
  
  @Output() backEvent = new EventEmitter<Object>();
  date = new Date();
  constructor(private _authService:AuthService) {  
  }

  ngOnInit(){
  }

  refresh(){
    this.backEvent.emit(true);
  }

  closeWarning(){
      localStorage.setItem('versionWarningTimer', ''+ this.date.getTime());
      this.backEvent.emit(false);  
  }
}
