// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

.is-integration {
    color: #FF5555;
    font-size: 0.9em;
}

.is-integration::before {
    content: '*';
}

.text-justify {
    text-align: justify;
    white-space: pre-line;
    max-width: 300px;
}

div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow-y: auto;
    max-height: 90px;
}

.column-size{
    max-width: 75px;
}

.text-justify {
    text-align: justify;
    white-space: pre-line;

}

div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow-y: auto;
    max-height: 90px;
    max-width: 300px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-2 {
    margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/subarea-list/subarea-list.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;;AAEzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n.is-integration {\n    color: #FF5555;\n    font-size: 0.9em;\n}\n\n.is-integration::before {\n    content: '*';\n}\n\n.text-justify {\n    text-align: justify;\n    white-space: pre-line;\n    max-width: 300px;\n}\n\ndiv.scrollable {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: block;\n    overflow-y: auto;\n    max-height: 90px;\n}\n\n.column-size{\n    max-width: 75px;\n}\n\n.text-justify {\n    text-align: justify;\n    white-space: pre-line;\n\n}\n\ndiv.scrollable {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: block;\n    overflow-y: auto;\n    max-height: 90px;\n    max-width: 300px;\n}\n\n.mb-10 {\n    margin-bottom: 10px;\n}\n\n.mr-2 {\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
