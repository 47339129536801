import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersService } from 'app/admin/services/users.service';
import { ListEquipmentParams } from './models/list-equipment-params.model';
import { ListEquipmentSelectService } from './services/list-equipment-select.service';

@Component({
  selector: 'app-list-equipment-select',
  templateUrl: './list-equipment-select.component.html',
  styleUrls: ['./list-equipment-select.component.css']
})
export class ListEquipmentSelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() selected = [];
  @Input() params: ListEquipmentParams;
  @Input() isMultiple: boolean = false;

  isLoading: boolean = false;

  equipmentList = [];
  selectedEquipment = [];

  isMultiSelect: boolean = false;

  constructor(
    private _listEquipmentSelectService: ListEquipmentSelectService,
    private _userService: UsersService,
  ) { }

  ngOnInit(): void {
  }

  searchEquipments(params: ListEquipmentParams, cod_selected_equipments: any[] = null, firstOpening: boolean = false) {
    this.isLoading = true;

    this._listEquipmentSelectService.getListEquipments(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.equipmentList = this._userService.getArrayContent(data);
  
        if (cod_selected_equipments && cod_selected_equipments.length > 0) {
          this.selectedEquipment = this.equipmentList.filter(obj => cod_selected_equipments.includes(obj['cod_equipment']));
        }

        if (firstOpening) {
          this.selectedEquipment = [this.equipmentList[0]];
          this.selectionChanged.emit(this.selectedEquipment);
        }
        
      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    }, () => this.isLoading = false
    );
  }

  onSelection($event) {
    this.selectedEquipment = $event?.selectedOptions;

    this.selectionChanged.emit($event?.selectedOptions);
  }

  clearList() {
    this.equipmentList = [];
    this.selectedEquipment = [];
  }
}
