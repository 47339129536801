export class Crew {
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;
    cod_crews: number;
    cod_location: number;
    str_description: string;

    deleted: boolean;
    constructor() {
        this.cod_crews = 0;
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
        this.cod_location = 0;
        this.str_description = ''
    }
}
