import { Component, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Action } from '../../meetings/models/action.model';
import { Router } from '@angular/router';

@Component({
    selector: 'action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.css'],
})
export class ActionListComponent implements OnInit {

    @Output() onAddAction = new EventEmitter<any>();

    @Input() hasAddAction: boolean = false;
    @Input() disableAddAction: boolean = false;
    @Input() meetingColumn: boolean = true;
    @Input() actionList: Array<Action> = [];
    @Input() addActionWidth: number;

    responsive: boolean = false;
    arr_name_meetings: any = [];

    constructor(private _router: Router) { }

    ngOnInit() {
        const inPT = /problem-terminator/.test(this._router.url);
        const width = document.getElementById('action-table').clientWidth;
        this.responsive = !inPT && typeof width != undefined && width < 450;      
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) { // in case window is resized, table will not clip out of screen/container
        const inPT = /problem-terminator/.test(this._router.url);
        const width = document.getElementById('action-table').clientWidth;
        this.responsive = !inPT && typeof width != undefined && width < 450;  
    }

    public addAction() : void {
        this.onAddAction.emit();
    }

    public buildLinkToAction(cod_meeting, cod_action): string {
        return `${environment().meetingsDetail}/${cod_meeting}?action=${cod_action}`;
    }
}
