import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MachineService } from '../services/machine.service';
import { AuthService } from '../../main/access/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationsService } from '../services/validations.service';
import { Machine } from '../model/machine.model';
import { UsersService } from '../../admin/services/users.service';
import { AreaService } from '../../../app/logbooks/services/area.service';
import { FiveWhyService } from '../services/five-why.service';  
import { NotificationService } from '../../main/services/notification.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

type Treshold = {
  str_treshold_id?: number,
  str_cod_machine?: number,
  str_alert_type: string,
  cod_threshold_yellow: number,
  cod_threshold_red: number,
}
@Component({
  selector: 'app-machine-persist',
  templateUrl: './machine-persist.component.html',
  styleUrls: ['./machine-persist.component.css']
})
export class MachinePersistComponent implements OnInit {

  @Input('codMachine') id;
  @Output() backEvent = new EventEmitter<Object>();
  machine: Machine;
  isLoading = false;
  paramsSelected: any = [];
  worldsList: any = [];
  regionalsList: any = [];
  locationsList: any = [];
  equipmentList: any = [];
  areaList: any = [];
  subAreasList: any = [];
  loadingConfig: any = [];
  showError = false;
  errorMessage: string;
  showEditTranslations = false;
  showEditThresholds = false;

  translation_pt = '';
  translation_es = '';
  translation_fr = '';
  translation_nl = '';
  translation_de = '';
  translation_ru = '';
  translation_uk = '';
  translation_zh = '';
  translation_ko = '';
  translation_vi = '';

  minMaxForGroup = {
    hour: { min: 0, max: 60 },
    day: { min: 0, max: 24 },
    week: { min: 0, max: 7 },
    month: { min: 0, max: 31 },
    year: { min: 0, max: 365 },
  }

  emitEditSuccessMessage: boolean;

  sapAndAcadiaIdPrivilege: boolean;
  tresholdPrivilege: boolean;

  thresholdForm: UntypedFormGroup;
  oldFormValues = {};

  constructor(
    private _machineService: MachineService, 
    private _authService: AuthService, 
    private _translateService: TranslateService, 
    public _validationService: ValidationsService, 
    private _usersGroupPrivilegesService: UsersService, 
    private _areaService: AreaService, 
    private _fiveWhyService: FiveWhyService,
    private _notify: NotificationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.showError = false;
    this.errorMessage = '';
    this._loadWorlds(null);
    this._resetMachine();

    this.thresholdForm = this._formBuilder.group({
      hour: this.generateForm('hour'),
      day: this.generateForm('day'),
      week: this.generateForm('week'),
      month: this.generateForm('month'),
      year: this.generateForm('year'),
    });
    this.checkMinMaxForForm();
  }

  checkMinMaxForForm() {
    this.thresholdForm.valueChanges.subscribe(() => {
      ['hour', 'day', 'week', 'month', 'year'].forEach(group => {
        const { min, max } = this.minMaxForGroup[group];
        const yellowControl = this.thresholdForm.get(`${group}.${group}-cod_threshold_yellow`);
        const redControl = this.thresholdForm.get(`${group}.${group}-cod_threshold_red`);
        if (yellowControl.value < min) {
          yellowControl.setValue(min);
        }
        if (yellowControl.value > max) {
          yellowControl.setValue(max);
        }
        if (redControl.value < min) {
          redControl.setValue(min);
        }
        if (redControl.value > max) {
          redControl.setValue(max);
        }
      });
    });
  }

  public generateForm(group: string): UntypedFormGroup {
    const { min, max } = this.minMaxForGroup[group];
    const lessThanRed = this.lessThanRedValidator(group).bind(this);
    const moreThanYellow = this.moreThanYellowValidator(group).bind(this);
    return this._formBuilder.group({
      [`${group}-cod_threshold_yellow`]: [min, [lessThanRed, Validators.required, Validators.min(min), Validators.max(max)]],
      [`${group}-cod_threshold_red`]: [max, [moreThanYellow, Validators.required, Validators.min(min), Validators.max(max)]],
    });
  }

  lessThanRedValidator(groupName: string): ValidatorFn {
    return (control: AbstractControl) => {
      const { value: yellow } = control;
      const redControl = this.thresholdForm?.get(`${groupName}.${groupName}-cod_threshold_red`);
      if (yellow && redControl && yellow >= redControl.value) {
        return { lessThanRed: true };
      } else {
        return null;
      }
    }
  }

  moreThanYellowValidator(groupName: string): ValidatorFn {
    return function (control: AbstractControl) {
      const { value: red } = control;
      const yellowControl = this.thresholdForm?.get(`${groupName}.${groupName}-cod_threshold_yellow`);
      if (red && yellowControl && red <= yellowControl.value) {
        return { moreThanYellow: true };
      } else {
        return null;
      }
    }
  }

  ngOnInit() {
    this.getSingleMachine();
    this.loadingConfig['worlds'] = false;
    this.loadingConfig['regionals'] = false;
    this.loadingConfig['locations'] = false;
    this.loadingConfig['areas'] = false;
    this.loadingConfig['subareas'] = false;
    this.loadingConfig['equipment'] = false;

    this._translateService.onLangChange.subscribe(() => this._loadEquipments(null));

    this.sapAndAcadiaIdPrivilege = this._authService.getUserPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
    this.tresholdPrivilege = this._authService.getUserPrivilege('MACHINE_TRESHOLD_FIELDS_ACTIONS');
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _resetMachine() {
    this.machine = new Machine();
  }

  getSingleMachine() {
    this.isLoading = true;
    if (this.id == 0) {
      this.machine = new Machine();
      this.isLoading = false;
    }
    else {
      const params = {
        'cod_user': this._authService.getAuthenticatedUser().id,
        'cod_machine': this.id
      }

      this._machineService.getSingleMachine(params).subscribe(data => {
        if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
          const { 0: machine } = data.content.results;
          this.machine = machine;
          const { machine_thresholds } = machine;

          this.populateTresholdForm(machine_thresholds);
          this._resetTranslation();
          this.isLoading = false;
          this._getAll(this.machine);
        }
        else {
          this.isLoading = false;
          this._showErrorMessage(data.content.message);
          this.backSelected(false);
        }
      }, err => {
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      });
    }
  }


  public populateTresholdForm(thresholds: Treshold[]) {
    if (!thresholds) return;
    if (!Array.isArray(thresholds)) return;
    if (thresholds.length === 0) return;

    thresholds.forEach(treshold => {
      const { str_alert_type, cod_threshold_yellow, cod_threshold_red } = treshold;
      this.thresholdForm.get(str_alert_type).patchValue({ 
        [`${str_alert_type}-cod_threshold_yellow`]: cod_threshold_yellow,
        [`${str_alert_type}-cod_threshold_red`]: cod_threshold_red,
      });
    });
  }

  public getTresholdControl(patch: `${string}.${string}`): AbstractControl {
    const [grp, ctr] = patch.split('.');
    const control = this.thresholdForm.get(`${grp}.${grp}-${ctr}`);
    return control;
  }

  public get thresholdFormValues(): Treshold[] {
    const { hour, day, week, month, year } = this.thresholdForm.value

    const getForGroup = (group: typeof hour, groupName: string): Treshold => {
      const yellow = group[`${groupName}-cod_threshold_yellow`];
      const red = group[`${groupName}-cod_threshold_red`];

      return {
        str_alert_type: groupName,
        cod_threshold_yellow: yellow,
        cod_threshold_red: red,
      };
    }
    const thresholds = [
      { str_alert_type: 'hour', ...getForGroup(hour, 'hour') },
      { str_alert_type: 'day', ...getForGroup(day, 'day') },
      { str_alert_type: 'week', ...getForGroup(week, 'week') },
      { str_alert_type: 'month', ...getForGroup(month, 'month') },
      { str_alert_type: 'year', ...getForGroup(year, 'year') },
    ];

    return thresholds;
  }

  public getParamsToSave() {
    this.machine.cod_machine = this.id;
    this.machine.cod_user = this._authService.getAuthenticatedUser().id;
    this.machine.cod_equipment = this.paramsSelected['equipment'];
    this.machine.cod_location = this.paramsSelected['locations'];
    this.machine.cod_area = this.paramsSelected['areas'];
    this.machine.cod_subarea = this.paramsSelected["subareas"];
  }

  private _handleSaveValidation(message: string): void {
    this._validationService.boolIsLoading = false;
    this._translateService.get(message).subscribe(translate => {
      this.errorMessage = translate;
      // this.showError = true;
      this._notify.warning(translate);
    });
  }

  // Field type:  Free text format, including: 
  // Special characters allowed (-) and (.) --> BE04313102 / SC.101.00564
  // Capital letters fixed  --> BE (BELGUIM)
  // Lengh of field --> 100 characters 
  validateInput(event: KeyboardEvent) {
    let input = event.target as HTMLInputElement;
    let value = input.value;
    let pattern = /^[a-zA-Z0-9-\s\.]+$/;
    if (!pattern.test(value)) {
        input.value = value.slice(0, -1);
        return false
    }
    return true;
  }

  public saveMachine() {
    this.getParamsToSave();
    this._validationService.boolSubmit = true;
    this._validationService.boolIsLoading = true;

    if (!this.machine.str_name) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_NAME');
      return false;
    }

    if (this.paramsSelected['worlds'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.WORLD_REQUIRED_MSG');
      return false;
    }
    if (this.paramsSelected['regionals'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_REGIONAL');
      return false;
    }

    if (this.paramsSelected['areas'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_AREA');
      return false;
    }

    if (this.paramsSelected['subareas'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_SUBAREA');
      return false;
    }

    if (this.paramsSelected['locations'].length <= 0) {
      this._handleSaveValidation('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED');
      return false;
    }

    if (this.paramsSelected['equipment'].length <= 0) {
      this._handleSaveValidation('LOGBOOK.REQUIRED_EQUIPMENT');
      return false;
    }

    let params: any;

    params = {
      'cod_user': this.machine.cod_user,
      'cod_machine': this.machine.cod_machine,
      'cod_equipment': this.machine.cod_equipment,
      'cod_location': this.machine.cod_location,
      'cod_area': this.machine.cod_area,
      'cod_subarea': this.machine.cod_subarea,
      'cod_sap_functional_location': this.machine.cod_sap_functional_location,
      'str_name': this.machine.str_name,
      'str_name_pt': this.machine.str_name_pt,
      'str_name_es': this.machine.str_name_es,
      'str_name_fr': this.machine.str_name_fr,
      'str_name_nl': this.machine.str_name_nl,
      'str_name_de': this.machine.str_name_de,
      'str_name_ru': this.machine.str_name_ru,
      'str_name_uk': this.machine.str_name_uk,
      'str_name_zh': this.machine.str_name_zh,
      'str_name_ko': this.machine.str_name_ko,
      'str_name_vi': this.machine.str_name_vi,
      'cod_acadia_id': this.machine.cod_acadia_id,
      'machine_thresholds': this.thresholdFormValues,
    };

    if (this.id <= 0) {
      this._machineService.insertMachine(params).subscribe(res => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (res.type !== 'success') {
          this._showErrorMessage(res.content.message);
        }
        else {
          this._notify.success({text: 'MACHINES.INSERTED', translate: true});
          this.backSelected(true);
        }
        this._resetMachine();
      }, err => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
      );
    }
    else {
      this._machineService.updateMachine(params).subscribe(res => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (res.type !== 'success') {
          this._showErrorMessage(res.content.message);
        }
        else {
          this._notify.success({text: 'MACHINES.EDITED', translate: true});
          this.backSelected(true);
        }
      }, err => {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
      );
    }
  }

  private _getAll(params) {
    const $this = this;
      $this.paramsSelected['worlds_selected'] = [{ cod_locations_world: params.cod_locations_world }];
      $this.paramsSelected['worlds'] = params.cod_locations_world;
      $this._loadRegionals(() => {
        $this.paramsSelected['regionals_selected'] = [{ cod_locations_regional: params.cod_locations_regional }];
        $this.paramsSelected['regionals'] = params.cod_locations_regional;
        $this._loadLocations(() => {
          $this.paramsSelected['locations_selected'] = [{ cod_location: params.cod_location }];
          $this.paramsSelected['locations'] = params.cod_location;
          $this._loadAreas(() => {
            $this.paramsSelected["areas_selected"] = [{ cod_area: params.cod_area }];
            $this.paramsSelected["areas"] = params.cod_area;
            $this._loadSubAreas(() => {
              $this.paramsSelected["subareas_selected"] = [{ cod_subareas: params.cod_subarea }];
              $this.paramsSelected["subareas"] = params.cod_subarea;
              $this._loadEquipments(() => {
                $this.paramsSelected['equipment_selected'] = [{ cod_equipment: params.cod_equipment }];
                $this.paramsSelected['equipment'] = params.cod_equipment;
            });
          });
        });
      });
    });
  }

  private _loadWorlds(fn) {
    this._clearWorldList();
    this._setLoading('worlds',true);
    this._usersGroupPrivilegesService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_CHANGE_MACHINE').subscribe(data => {
      this.worldsList = this._usersGroupPrivilegesService.getArrayContent(data);
      this._setLoading('worlds',false);
      if (typeof fn != typeof undefined && fn) {
        fn();
      }
    }, err => {
      this._authService.errCheck(err);
      console.log(err);
    });
  }

  private _loadRegionals(fn) {

    if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
      this._setLoading('regionals',true);
      let params = this.paramsSelected['worlds'].toString().split(',');
      this._usersGroupPrivilegesService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_CHANGE_MACHINE').subscribe(data => {
        this.regionalsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('regionals',false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearRegionalList();
      },
        err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      );
    } else {
      this._clearRegionalList();
    }

  }

  private _loadLocations(fn) {

    if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
      this._setLoading('locations',true);
      let params = this.paramsSelected['regionals'].toString().split(',');
      this._usersGroupPrivilegesService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_CHANGE_MACHINE').subscribe(data => {
        this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('locations',false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
        this._smartClearLocationsList();
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearLocationsList();
    }

  }

  private _loadAreas(fn) {
    
    if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
      this._setLoading('areas',true);
      let params = {
        arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
        cod_user: this._authService.getAuthenticatedUser().id,
        dsc_page_privilege: 'ADMIN_CHANGE_MACHINE'
      };
      this._fiveWhyService.getAllowedAreas(params).subscribe(data => {
        this.areaList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('areas',false);
        this._smartClearAreaList();
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      }, err => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearAreaList();
    }

  }

  private _loadSubAreas(fn) {

    if (this.paramsSelected["areas"] && this.paramsSelected["areas"].toString() != "") {
      this._setLoading('subareas',true);
      let objParam = {
        arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
        arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
        cod_user: this._authService.getAuthenticatedUser().id,
      };
      this._fiveWhyService.getAllowedSubareas(objParam).subscribe((data) => {
        this.subAreasList = this._areaService.getArrayContent(data);
        this._setLoading('subareas',false);
        this._smartClearSubAreaList();
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      }, (err) => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearSubAreaList();
    }

  }

  private _loadEquipments(fn) {
    if (this.paramsSelected["subareas"] && this.paramsSelected["subareas"].toString() != "") {
      let params = {
        cod_user: this._authService.getAuthenticatedUser().id,
        arr_subareas: JSON.parse("[" + this.paramsSelected['subareas'] + "]")
      }
      this._setLoading('equipment',true);
      this._machineService.getListEquipments(params).subscribe((data) => {
        this.equipmentList = this._usersGroupPrivilegesService.getArrayContent(data);
        this._setLoading('equipment',false);
        this._smartClearEquipments();
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      }, (err) => {
        this._authService.errCheck(err);
        console.log(err);
      });
    } else {
      this._clearEquipments();
    }
  
  }

  private _clearWorldList() {
    this.paramsSelected['worlds'] = '';
    this.paramsSelected['worlds_selected'] = [];
    this._clearRegionalList();
  }

  private _clearRegionalList() {
    this.paramsSelected['regionals'] = '';
    this.paramsSelected['regionals_selected'] = [];
    this.regionalsList = [];
    this._clearLocationsList();
  }

  private _smartClearRegionalList() {

    if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
        this.regionalsList.map((regional) => {
          if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
        });
      });
      this.paramsSelected['regionals_selected'] = arr;
      this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
    }
    if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
      this.paramsSelected['regionals_selected'] = [];
      this.paramsSelected['regionals'] = '';
      this._clearLocationsList();
    } else {
      this._loadLocations(null);
    }

  }

  private _clearLocationsList() {
    this.paramsSelected['locations'] = '';
    this.paramsSelected['locations_selected'] = [];
    this.locationsList = [];
    this._clearAreaList();
  }

  private _smartClearLocationsList() {

    if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
        this.locationsList.map((location) => {
          if (location.cod_location == selected.cod_location) arr.push(location);
        });
      });
      this.paramsSelected['locations_selected'] = arr;
      this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
    }
    if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
      this.paramsSelected['locations_selected'] = [];
      this.paramsSelected['locations'] = '';
      this._clearAreaList();
    } else {
      this._loadAreas(null);
    }

  }

  private _clearAreaList() {
    this.paramsSelected["areas"] = '';
    this.paramsSelected["areas_selected"] = [];
    this.areaList = [];
    this._clearSubAreaList();
  }

  private _smartClearAreaList() {

    if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
        this.areaList.map((area) => {
          if (area.cod_area == selected.cod_area) arr.push(area);
        });
      });
      this.paramsSelected['areas_selected'] = arr;
      this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
    }
    if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
      this.paramsSelected['areas_selected'] = [];
      this.paramsSelected['areas'] = '';
      this._clearSubAreaList();
    } else {
      this._loadSubAreas(null);
    }

  }

  private _clearSubAreaList() {
    this.paramsSelected["subareas"] = '';
    this.paramsSelected["subareas_selected"] = [];
    this.subAreasList = [];
    this._clearEquipments();
  }

  private _smartClearSubAreaList() {

    if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
        this.subAreasList.map((subarea) => {
          if (subarea.cod_subareas == selected.cod_subareas) arr.push(subarea);
        });
      });
      this.paramsSelected['subareas_selected'] = arr;
      this.paramsSelected['subareas'] = this._implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
    }
    if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
      this.paramsSelected['subareas_selected'] = [];
      this.paramsSelected['subareas'] = '';
      this._clearEquipments();
    } else {
      this._loadEquipments(null);
    }

  }

  private _clearEquipments() {
    this.paramsSelected["equipment"] = '';
    this.paramsSelected["equipment_selected"] = [];
    this.equipmentList = [];
  }

  private _smartClearEquipments() {

    if (this.paramsSelected['equipment_selected'] && this.paramsSelected['equipment_selected'].length > 0) {
      var arr = [];
      this.paramsSelected['equipment_selected'].map((selected: { cod_equipment: any; }) => {
        this.equipmentList.map((equip) => {
          if (equip.cod_equipment == selected.cod_equipment) arr.push(equip);
        });
      });
      this.paramsSelected['equipment_selected'] = arr;
      this.paramsSelected['equipment'] = this._implodeValues(this.paramsSelected['equipment_selected'], 'cod_equipment');
    }
    if (!this.paramsSelected['equipment_selected'] || this.paramsSelected['equipment_selected'].length == 0) {
      this.paramsSelected['equipment_selected'] = [];
      this.paramsSelected['equipment'] = '';
    }

  }

  selectWorlds($event) {
    this.paramsSelected['worlds_selected'] = $event.selectedOptions;
    this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
    this._loadRegionals(null);
  }

  selectRegionals($event) {
    this.paramsSelected['regionals_selected'] = $event.selectedOptions;
    this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
    this._loadLocations(null);
  }

  selectLocations($event) {
    this.paramsSelected['locations_selected'] = $event.selectedOptions;
    this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
    this._loadAreas(null);
  }

  selectArea($event) {
    this.paramsSelected['areas_selected'] = $event.selectedOptions;
    this.paramsSelected["areas"] = this._implodeValues($event.selectedOptions, "cod_area");
    this._loadSubAreas(null);
  }

  selectSubArea($event) {
    this.paramsSelected['subareas_selected'] = $event.selectedOptions;
    this.paramsSelected["subareas"] = this._implodeValues($event.selectedOptions, "cod_subareas");
    this._loadEquipments(null);
  }

  selectEquipment($event) {
    this.paramsSelected['equipment_selected'] = $event.selectedOptions;
    this.paramsSelected["equipment"] = this._implodeValues($event.selectedOptions, "cod_equipment");
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  cancelEdition() {
    this.showEditTranslations = false;
  }

  cancelEditionThresholds() {
    this.showEditThresholds = false;
    this.thresholdForm.patchValue(this.oldFormValues);
  }

  get formValid() {
    return this.thresholdForm.valid;
  }

  saveTranslations() {
    this.machine.str_name_pt = this._defaultValue(this.translation_pt, null);
    this.machine.str_name_es = this._defaultValue(this.translation_es, null);
    this.machine.str_name_fr = this._defaultValue(this.translation_fr, null);
    this.machine.str_name_nl = this._defaultValue(this.translation_nl, null);
    this.machine.str_name_de = this._defaultValue(this.translation_de, null);
    this.machine.str_name_ru = this._defaultValue(this.translation_ru, null);
    this.machine.str_name_uk = this._defaultValue(this.translation_uk, null);
    this.machine.str_name_zh = this._defaultValue(this.translation_zh, null);
    this.machine.str_name_ko = this._defaultValue(this.translation_ko, null);
    this.machine.str_name_vi = this._defaultValue(this.translation_vi, null);
  
    this.showEditTranslations = false;
  }

  private _resetTranslation() {
    this.translation_pt = this._defaultValue(this.machine.str_name_pt, null);
    this.translation_es = this._defaultValue(this.machine.str_name_es, null);
    this.translation_fr = this._defaultValue(this.machine.str_name_fr, null);
    this.translation_nl = this._defaultValue(this.machine.str_name_nl, null);
    this.translation_de = this._defaultValue(this.machine.str_name_de, null);
    this.translation_ru = this._defaultValue(this.machine.str_name_ru, null);
    this.translation_uk = this._defaultValue(this.machine.str_name_uk, null);
    this.translation_zh = this._defaultValue(this.machine.str_name_zh, null);
    this.translation_ko = this._defaultValue(this.machine.str_name_ko, null);
    this.translation_vi = this._defaultValue(this.machine.str_name_vi, null);
  }

  editTranslations() {
    this.showEditTranslations = true;
  }

  editThresholds() {
    this.showEditThresholds = true;
    this.oldFormValues = this.thresholdForm.value;
  }

  cancelTranslations() {
    this._resetTranslation();
    this.showEditTranslations = false;
  }

  cancelThresholds() {
    this.showEditThresholds = false;
  }

  backSelected(success = false) {
    this._validationService.boolSubmit = false;
    this._validationService.boolIsLoading = false;
    if (success) {
      this.id = -1;
    } else {
      this.id = 0;
    }
    this.machine = new Machine();
    this.backEvent.emit(this.id);
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  private _defaultValue(translation: any, defaultValue: any) {
    if (translation == undefined || (translation === ''))
      return defaultValue;
    else
      return translation;
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  public activeMachine(cod_machine) {
    let objParam = {
      cod_machine: cod_machine,
      cod_user: this._authService.getAuthenticatedUser().id,
    };
    this._machineService.deleteMachine(objParam).subscribe(
      (data) => {
        this.emitEditSuccessMessage = true;
        this._notify.success({ text: "MACHINES.ACTIVATED", translate: true });
        this.getSingleMachine();
      },
      (err) => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    );
  }

}
