// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host { 
    text-align: center;  
}

.row { 
    padding-top: 10px;
}

img {
    width: 25px;
}

@media only screen and (max-width: 1430px) {
    img {
        width: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/list-fivewhy/workflow-icons-list/workflow-icons-list.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[":host { \n    text-align: center;  \n}\n\n.row { \n    padding-top: 10px;\n}\n\nimg {\n    width: 25px;\n}\n\n@media only screen and (max-width: 1430px) {\n    img {\n        width: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
