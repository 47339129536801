import {Component, OnInit} from '@angular/core';
import {MyNotesService} from '../../services/my-notes.service';
import {AuthService} from '../../../main/access/services/auth.service';
import {Notes} from '../../models/notes.model';
import {TranslateService} from '@ngx-translate/core';
import { NotificationService } from 'app/main/services/notification.service';

@Component({
    selector: 'app-my-notes-list-all',
    templateUrl: './my-notes-list-all.component.html',
    styleUrls: ['./my-notes-list-all.component.css'],
    providers: [MyNotesService]
})
export class MyNotesListAllComponent implements OnInit {
    authenticatedUser: any;
    noteModel: Notes;
    notes = [];
    error_message_submit: string;
    bol_error_submit: Boolean = false;
    bol_success: Boolean = false;
    isLoadingNotes: boolean;
    showModal: boolean = false;
    warningMessage: string;
    bol_done: any;
    bol_active: any;

    constructor(private _myNotesService: MyNotesService, private _authService: AuthService, private _translateService: TranslateService, private _notify: NotificationService) {
        this.authenticatedUser = this._authService.getAuthenticatedUser();
        this.newNote();        
    }

    ngOnInit() {
        this.listNotes();
    }

    listNotes() {
        this.isLoadingNotes = true;
        if (!this.bol_done && !this.bol_active || this.bol_done && this.bol_active) {
            this._myNotesService.getNotes(this.authenticatedUser.id).subscribe(data => {
                this.isLoadingNotes = false;
                this.notes = this._myNotesService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this.bol_done =  this.bol_done ? 1 : 0;
            this._myNotesService.getNotesByStatus(this.authenticatedUser.id, this.bol_done).subscribe(data => {
                this.isLoadingNotes = false;
                this.notes = this._myNotesService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        }     
    }

    loadNote(note) {
        this.showModal = true;
        this.bol_success = false;
        this.bol_error_submit = false;
        this.noteModel = new Notes();
        this.noteModel.cod_notes = note.cod_notes;
        this.noteModel.str_description = note.str_description;
        this.noteModel.cod_user = note.cod_users;  
        this.noteModel.dat_note_datapicker = this.convertDate(note.dat_note_datapicker); 
        this.noteModel.bol_done = note.bol_done;      
    }

    newNote() {
        this.noteModel = new Notes();
        this.noteModel.cod_user = this.authenticatedUser.id;
    }

    deleteNote(note) {
        const params = {
            cod_notes: note.cod_notes,
            cod_user: note.cod_users
        };

        this._myNotesService.deleteNote(params).subscribe(data => {
                if (data.code === 666) {
                    alert(data.content[0].message);
                    return;
                }

                this.listNotes();
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    submit() {
        if (!this.noteModel.str_description) {
            this.showWarningMessage('DESCRIPTION_REQUIRED');
            this.bol_success = false;
            return;
        }

        if (!this.noteModel.dat_note_datapicker) {
            this.showWarningMessage('DATE_REQUIRED');
            this.bol_success = false;
            return;
        }

        this.bol_success = false;
        this.bol_error_submit = false; 
        this.noteModel.dat_notes_db = this.noteModel.dat_note_datapicker;
        this._myNotesService.putNote(this.noteModel).subscribe(data => {
            if (data.code === 666) {
                const message = data?.content?.details?.[0]?.message ;

                this.bol_error_submit = true;
                this.error_message_submit = message;
                return;
            }
            this.bol_success = true;
            this.showModal = false;
            this.newNote();
            this.listNotes();
            this._notify.success({text: 'MEETING_NOTES.VALIDATION.SAVED', translate: true});
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    close(){
        this.showModal = false; 
    }

    convertDate(date) {
        var day  = date.split("/")[0];
        var month  = date.split("/")[1];
        var year  = date.split("/")[2];
      
        return year + '-' + ("0"+month).slice(-2) + '-' + ("0"+day).slice(-2);
    }

    showWarningMessage(message) {
        if (message == '') return;
        this.warningMessage = message;
        this._translateService.get(`MEETING_NOTES.VALIDATION.${message}`).subscribe(translate => this._notify.warning(translate));
    }

    selectStatusDone(event) {
        if (event == 'selected') {
            this.bol_done = true;
        } else {
            this.bol_done = false;
        }
        this.listNotes();
    }

    selectStatusActive(event) {
        if (event == 'selected') {
            this.bol_active = true;
        } else {
            this.bol_active = false;
        }
        this.listNotes();
    }

    concludeNote(note, event) {
        note.cod_user = this.authenticatedUser.id;
        this.changeStatus(note, event);
        this._myNotesService.putNote(note).subscribe(data => {
          if (data.code === 666) {
            this.bol_error_submit = true;
            this.error_message_submit = data.content[0].message;
            return;
          }
          this.bol_success = true;
          this.convertDate(note.dat_notes);
        },
        err => { this._authService.errCheck(err); console.log(err); } 
        );
    }

    changeStatus(note, event) {
        note.bol_done = event == 'selected' ? 1 : 0;
    }
}
