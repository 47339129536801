// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.gallery-card {
  padding: 15px;
}

.gallery-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
  box-shadow: 2px 2px 8px #eaeaea;
  padding: 14px 11px;
  height: 250px;
}

.gallery-item img{
  max-width: 100%;
  height: auto;
  max-height: 75%;
  /* border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; */
}

.gallery-item .gallery-item-body {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  .gallery-item .gallery-item-body {
    flex-direction: column;
  }
}

.gallery-item .btn-download {
  text-decoration: none;
}

.gallery-item .btn-download:hover {
  color: #fff;
}

.gallery-item .btn-download span{
  margin-right: 5px;
}

.gallery-item .img-card {
  width: 100%;
  height: 250px;
  background-size: cover;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/attachments-gallery/attachments-gallery.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf;mCACiC;AACnC;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,8BAA8B;AAChC","sourcesContent":[".gallery {\n  display: flex;\n  flex-direction: row;\n  flex-flow: wrap;\n}\n\n.gallery-card {\n  padding: 15px;\n}\n\n.gallery-item {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: .3rem;\n  box-shadow: 2px 2px 8px #eaeaea;\n  padding: 14px 11px;\n  height: 250px;\n}\n\n.gallery-item img{\n  max-width: 100%;\n  height: auto;\n  max-height: 75%;\n  /* border-top-left-radius: .3rem;\n  border-top-right-radius: .3rem; */\n}\n\n.gallery-item .gallery-item-body {\n  width: 100%;\n  padding-top: 1rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n}\n\n@media only screen and (max-width: 1280px) {\n  .gallery-item .gallery-item-body {\n    flex-direction: column;\n  }\n}\n\n.gallery-item .btn-download {\n  text-decoration: none;\n}\n\n.gallery-item .btn-download:hover {\n  color: #fff;\n}\n\n.gallery-item .btn-download span{\n  margin-right: 5px;\n}\n\n.gallery-item .img-card {\n  width: 100%;\n  height: 250px;\n  background-size: cover;\n  border-top-left-radius: .3rem;\n  border-top-right-radius: .3rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
