// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormsDynamic__Show__CreateAction {
  display: block;
}

.choice-option {
  display: block;
  margin-right: 10px;
  color: #5f5f5f;
  font-weight: normal;
}

.action-form {
  background: #e0e0e0;
  padding: 15px;
  margin-top: 4px;
}

.form-group {
  width: 100%;
}

.action-button {
  position: relative;
    bottom: 5px;
    right: 1px;
}

.pull-top {
  padding-top: 10%;
}

.padding-right {
  padding-right: 100%;
}

.width-owner {
  font-size: 80%;
  width: 85%;
}

.text-overflow-owner {
  text-overflow:  ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/forms/forms-dynamic/forms-dynamic-show/create-action/create-action.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;IAChB,WAAW;IACX,UAAU;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".FormsDynamic__Show__CreateAction {\n  display: block;\n}\n\n.choice-option {\n  display: block;\n  margin-right: 10px;\n  color: #5f5f5f;\n  font-weight: normal;\n}\n\n.action-form {\n  background: #e0e0e0;\n  padding: 15px;\n  margin-top: 4px;\n}\n\n.form-group {\n  width: 100%;\n}\n\n.action-button {\n  position: relative;\n    bottom: 5px;\n    right: 1px;\n}\n\n.pull-top {\n  padding-top: 10%;\n}\n\n.padding-right {\n  padding-right: 100%;\n}\n\n.width-owner {\n  font-size: 80%;\n  width: 85%;\n}\n\n.text-overflow-owner {\n  text-overflow:  ellipsis;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
