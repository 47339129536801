import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {SocketService} from '../../main/services/socket.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import { MeetingsService } from '../services/meetings.service';
import {TranslateService} from '@ngx-translate/core';
import { UploadService } from '../../main/services/upload.service';

@Component({
    selector: 'app-meetings-chat',
    templateUrl: './meetings-chat.component.html',
    styleUrls: ['./meetings-chat.component.css']
})
export class MeetingsChatComponent implements OnInit, AfterViewInit {
    obj_connection: any;
    arr_messages: any;
    obj_message_form: any;
    cod_meeting: number;
    obj_user: any;
    isLoadingComments: boolean;
    isEditing = null;
    showEditor = true;
    bol_loading: boolean = false;
    errorMessage: string = '';
    isLoading: boolean = false;
    bol_error_upload: boolean = false;
    error_message_upload: string;
    showError: boolean = false;
    showLinkModal: boolean = false;
    linkImg: string = '../../../assets/images/link-45deg.svg';
    modalHyperlinkObject = {
        description: '',
        url: '',
        sameTab: false
    }
    timeToHideMessage: number;
    showDeleteConfirmation: boolean = false;
    objMessageToBeDeleted: any;
    deletionMessage: string = '';

    private timeToHideMessageConstant: number;


    @Input() canEditMessages: boolean;
    @Input() isParticipant: boolean;
    

    public config = {
        language: this._translateService.currentLang === 'pt' ? 'pt-br' : this._translateService.currentLang,
        toolbar: {
            items: [
                'heading',
                'bold',
                'italic',
                'underline',
                'fontColor',
                'link',
                'highlight',
                'indent',
                'outdent',
                'bulletedList',
                'numberedList'
            ]
        },
    };

    showDropdown = null;

    constructor(private _activatedRoute: ActivatedRoute,
        private _socketService: SocketService,
        private _authService: AuthService,
        private _translateService: TranslateService,
        private _uploadService: UploadService,
        private _meetingsService: MeetingsService
    ) {
        this.obj_user = _authService.getAuthenticatedUser();
        this.cod_meeting = -1;
        this.canEditMessages = false;
        this.isLoadingComments = true;
        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 3000;
        
        this.obj_message_form = {
            str_text: '',
            cod_meeting: this.cod_meeting,
            cod_user: this.obj_user.id
        };
        this.arr_messages = [];

        this._translateService.get('LOGBOOK.CONFIRM_DELETE_COMMENT').subscribe(translate => {
            this.deletionMessage = translate;
        });
    }

    

    ngOnInit() {
        this._translateService.onLangChange.subscribe(() => this.loadLabelsWithTranslations());
    }

    onKeydown(event) {
        if (event.key === "Enter" && !event.altKey && !event.shiftKey && !event.ctrlKey)  {
          this.sendMessage();
        }
      }

    loadLabelsWithTranslations() {
        this.showEditor = false;
        this.config.language = this._translateService.currentLang === 'pt' ? 'pt-br' : this._translateService.currentLang;
        setTimeout(() => {
            this.showEditor = true;
        }, 300);
    }

    ngAfterViewInit() {
        // GETTING MEETING ID FROM PARAMETER TO SEND IN MESSAGE
        this._activatedRoute.params.subscribe(params => {
            this.cod_meeting = params['id'];
            this.obj_message_form = {
                str_text: '',
                cod_meeting: this.cod_meeting,
                cod_user: this.obj_user.id
            };

            this.obj_connection = this._socketService.subscribeToMessages().subscribe(messages => {
                let obj_messages: any;
                obj_messages = messages;

                if (obj_messages.obj_message.cod_meeting == this.cod_meeting) {
                    this.refreshMessages();
                }
            });
            this.refreshMessages();
        });
    }

    checkImage(url) {
        if (url !== null && (this.getFileType(url).toLowerCase() === 'jpg' || this.getFileType(url).toLowerCase() === 'jpeg' ||
            this.getFileType(url).toLowerCase() === 'png')) {
            return true;
        }
        return false;
    }

    checkPdf(url) {
        if (url !== null && this.getFileType(url).toLowerCase() === 'pdf') {
           return true;
        }
        return false;
    }

    sendMessage() {
        if (this.obj_message_form.str_text && this.obj_message_form.str_text != '') {
            this.isLoadingComments = true;

            this.obj_message_form.str_text = this.obj_message_form.str_text.replace('<li><br></li>', '');
            this._meetingsService.postMeetingMessage(this.obj_message_form).subscribe(data => {
                this.obj_message_form.str_text = '';
                this._socketService.refreshMeetingMessage({cod_meeting: this.cod_meeting});
                this.refreshMessages();
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        }
    }

    cancelDeletion() {
        this.showDeleteConfirmation = false;
        this.objMessageToBeDeleted = {};
    }

    confirmDeletion() {
        this.showDeleteConfirmation = false;
        this.deleteMessage();
    }

    openConfirmationDeleteModal(objMessage) {
        this.objMessageToBeDeleted = objMessage;
        this.showDeleteConfirmation = true;
    }

    deleteMessage() {
        const objMessageForm = {
            cod_chat_messages:  this.objMessageToBeDeleted.cod_chat_messages,
            cod_user: this.obj_user.id,
            cod_meeting:  this.objMessageToBeDeleted.cod_meeting
        };

        // empty array of messages because it will be updated after
        this.isLoadingComments = true;
        this.arr_messages = [];

        this._meetingsService.deleteMeetingMessage(objMessageForm).subscribe(data => {
            this._socketService.refreshMeetingMessage({cod_meeting: this.cod_meeting});
            this.refreshMessages();
            this.showDropdown = null;
            this.objMessageToBeDeleted = {};
        },
        err => {
            this._authService.errCheck(err);
            this.objMessageToBeDeleted = {};
            console.log(err);
        });
    }

    refreshMessages() {
        const obj_meeting = {
            cod_meeting: this.cod_meeting,
            str_path: environment().uploadGetFilesApiUrl + '/',
            cod_user: this._authService.getAuthenticatedUser().id
        };
        // REFRESH DATA FROM ALL MEETING MESSAGES
        this._meetingsService.getMeetingMessages(obj_meeting).subscribe(data => {
            if (data.code == 888 && data.content.code == 888) {
                if (Array.isArray(data.content.results)) {
                    this.arr_messages = data.content.results;
                } else {
                    this.arr_messages = [data.content.results];
                }

                this.arr_messages.map(item => {
                    item.is_image = (this.checkImage(item.str_text)) ? true : false ;
                    item.is_pdf = (this.checkPdf(item.str_text)) ? true : false ;
                    item.str_first_letter = this.getFirstLetter(item.str_name);
                    item.str_name = this.capitalWord(item.str_name);
                });

            } else {
                console.log(data);
            }
            this.isLoadingComments = false;
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    getFirstLetter(name) {
        return name.substring(1, 0);
    }

    capitalWord(str) {
        const names = str.split(' ');
        let lastName = '';

        const firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();
        if (names.length > 1) {
            lastName = names[1].charAt(0).toUpperCase() + names[1].slice(1).toLowerCase();
        }

        return `${firstName} ${lastName}`;
    }

    showMessageOptions(index) {
        this.showDropdown = (this.showDropdown === null) ? index : null;
    }

    editMessage(index) {
        this.isEditing = (this.isEditing === null) ? index : null;
        this.showDropdown = null;
    }

    applyStateReturn(state) {
        this.isEditing = state;
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    fileChangeEvent(e) {
        this.bol_error_upload = false;

        const params = {
            'event': e,
            'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
        };

        this._uploadService.uploadFileBase64(params).subscribe(response => {
                if (response.type == 'success') {

                    this.isLoadingComments = true;

                    const obj_message_form_attachment = {
                        str_text: response.content.str_name,
                        cod_meeting: this.cod_meeting,
                        cod_user: this.obj_user.id
                    };

                    this._meetingsService.postMeetingMessage(obj_message_form_attachment).subscribe(data => {
                        this._socketService.refreshMeetingMessage({cod_meeting: this.cod_meeting});
                        this.refreshMessages();
                    },
                    err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    });

                } else {
                    this.bol_error_upload = true;
                    this.timeToHideMessage = this.timeToHideMessageConstant;
                    this.error_message_upload = response.content;
                    this.isLoading = false;
                }

            }, err => {
                // tslint:disable-next-line: no-shadowed-variable
                const e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this.isLoading = false;
                this._authService.errCheck(err);
            }
        );
    }

    getFileType(strFilePath: string) {
        const arr_temp = strFilePath.split('.').pop();
        return arr_temp;
    }

    hideMessage() {
        this.bol_error_upload = false;
        this.timeToHideMessage = 0;
    }

    openLinkModal() {
        this.showLinkModal = true;
    }

    addHyperlink(hyperlinkTag) {
        this.obj_message_form.str_text += ` ${hyperlinkTag} `;
        this.showLinkModal = false;
    }

    closeLinkModal() {
        this.showLinkModal = false;
    }
    
 
}
