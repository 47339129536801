import { Component, OnInit } from '@angular/core';
import { AuthService } from "../access/services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RoutinesService } from '../../logbooks/services/routines.service';
import { MyFormsService } from 'app/my-forms/services/my-forms.service';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import IAttachment from '../../components/attachments-gallery/attachments.model';

@Component({
    selector: 'app-reports-forms-collab-attachments',
    templateUrl: './reports-forms-collab-attachments.component.html',
    styleUrls: ['./reports-forms-collab-attachments.component.css']
})
export class ReportsFormsCollabAttachmentsComponent implements OnInit {
    loading: boolean = true;
    loadingDetail: boolean = true;
    str_message: string;
    cod_logbook_routines_periodicity_users_collaborative: number;
    cod_form: number;
    cod_user: number;
    attachments: Array<IAttachment> = [];             
    
    detail: any = {
        status_description: null,
        date_limit: null,
        str_description: null,
        str_name: null,
        str_form_name: null
    };                             

    constructor(
        private router: Router, 
        private _activatedRoute:ActivatedRoute, 
        private _authService: AuthService,
        private _routineService: RoutinesService,
        private userPreferencesService: UserPreferencesService,
        private _myFormsService: MyFormsService) {
    }

    ngOnInit() {
        //GET FILE AND USER FOR REQUEST REPORT
        this._activatedRoute.params.subscribe((arr_params) => {
            this.cod_logbook_routines_periodicity_users_collaborative = arr_params['cod_logbook_routines_periodicity_users_collaborative'];
            this.cod_form = arr_params['cod_form'];
        });
    }

    ngAfterViewInit() {
        if (this._authService.isAuthenticated() && this._authService.getUserPrivilege('REPORT_ROUTES')) {
            this.cod_user = this._authService.getAuthenticatedUser().id;
            this.getAttachments();
            this.getDetails();
        }
    }

    getDetails() {
        this.loadingDetail = true;

        const params = {
            cod_logbook_routines_periodicity_users_collaborative: this.cod_logbook_routines_periodicity_users_collaborative,
            cod_user: this.cod_user
        };

        this._routineService.getLogbookRoutineCollabDetail(params).subscribe(res => {
            this.loadingDetail = false;
            if (res.type !== 'success') {
                alert(res.content.message);
            } else {

                this.detail.status_description = res.content.results.status_description;
                this.detail.date_limit = res.content.results.date_limit;
                this.detail.str_description = res.content.results.str_description;
                this.detail.str_routine_name = res.content.results.str_name;
     
            }
          }, err => {
            this._authService.errCheck(err);
          }
      );

      const params2 = {
          'cod_form': this.cod_form,
          'cod_logbook_routines_periodicity_users_collaborative': this.cod_logbook_routines_periodicity_users_collaborative,
          'cod_user': this._authService.getAuthenticatedUser().id
      };
      
      this._routineService.getFormCollab(params2).subscribe(form => {
          this.detail.str_form_name = this._myFormsService.getNameByUserLanguage(this.userPreferencesService.getLocalLanguage(), form.content);  
      },
      err => { this._authService.errCheck(err);
        console.log(err);
      });
    }

    getAttachments() {
        this.loading = true;

        const params = {
            cod_logbook_routines_periodicity_users_collaborative: this.cod_logbook_routines_periodicity_users_collaborative,
            cod_user: this.cod_user
        };

        this._routineService.getCollabAttachments(params).subscribe(res => {
            this.attachments = []; 
            
            if (res.type !== 'success') {
                alert(res.content.message);
            } else {

                if (Array.isArray(res.content.results)) {
                    this.attachments = res.content.results.map((a): IAttachment => this._mapAttachment(a));
                } else {
                    this.attachments[0] = this._mapAttachment(res.content.results);
                }

            }
            this.loading = false;
          }, err => {
            this._authService.errCheck(err);
          }
      );
    }

    _mapAttachment(obj): IAttachment {
        return {
            url_image: `${environment().uploadGetFilesApiUrl}/${obj.str_name_image}`,
            name_image: obj.str_name_image,
            dat_ins:  obj.dat_ins
        };
    }

}