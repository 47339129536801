import { Injectable } from "@angular/core";
@Injectable()
export class Auth {
    username: string;
    password: string;
    email: string;
    name: string;
	
    constructor() {
        this.username = '';
        this.email = '';
        this.name = '';
    }
}
