// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.paginate {
    background: var(--ui-secondary);
    border-radius: 4px;
    display: inline-block;
    margin: 2px;
    color: var(--text-ui-primary);
    line-height: 34px;
    padding: 0 15px;
}

button.paginate.active {
    background-color: var(--ui-primary);
    font-weight: bold;
}

button.paginate.disabled {
    background-color: #EDF0F7;
    color: #000;
}

.action-button-container {
    display: inline-block;
}

.action-button-container button {
    display: inline-block;
}

#routines-list-qty{
    width: 280px;
}

.str_checklists_scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow-y: auto;
    max-height: 87px;
}

.text-justify {
    text-align: justify;
    white-space: pre-line;
    max-width: 400px;
    min-width: 350px;
}

.inline-block {
    display: inline-block;
}

.multiline {
    white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/routines-list/routines-list.component.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,6BAA6B;IAC7B,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["button.paginate {\n    background: var(--ui-secondary);\n    border-radius: 4px;\n    display: inline-block;\n    margin: 2px;\n    color: var(--text-ui-primary);\n    line-height: 34px;\n    padding: 0 15px;\n}\n\nbutton.paginate.active {\n    background-color: var(--ui-primary);\n    font-weight: bold;\n}\n\nbutton.paginate.disabled {\n    background-color: #EDF0F7;\n    color: #000;\n}\n\n.action-button-container {\n    display: inline-block;\n}\n\n.action-button-container button {\n    display: inline-block;\n}\n\n#routines-list-qty{\n    width: 280px;\n}\n\n.str_checklists_scrollable {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: block;\n    overflow-y: auto;\n    max-height: 87px;\n}\n\n.text-justify {\n    text-align: justify;\n    white-space: pre-line;\n    max-width: 400px;\n    min-width: 350px;\n}\n\n.inline-block {\n    display: inline-block;\n}\n\n.multiline {\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
