import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from 'app/logbooks/services/reports.service';
import { AuthService } from 'app/main/access/services/auth.service';
import { NotificationService } from 'app/main/services/notification.service';
import { UploadService } from 'app/main/services/upload.service';
import { environment } from 'environments/environment';
import { UsersProfilesService } from '../services/users-profiles.service';

@Component({
  selector: 'app-users-profiles-approval',
  templateUrl: './users-profiles-approval.component.html',
  styleUrls: ['./users-profiles-approval.component.css']
})
export class UsersProfilesApprovalComponent implements OnInit {
  reportsApiUrl = environment().reportsApiUrl;
  private arrHeaders: any[];

  user: any;
  cod_id: any;
  requests = [];
  approvers = [];
  errorMessage: any = {};
  isLoading: boolean = false;
  resultsPerPage: number[];
  resultsPerPageSelected: number;
  maxPage: number;
  totalPages: number[];
  pageIndex: number;
  awaitConfirmAccept = false;
  awaitConfirmReject = false;
  downloadInProgress = false;
  constructor(
    private _usersProfilesService: UsersProfilesService,
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _reportService: ReportsService,
    private _notify: NotificationService,
    private sanitizer: DomSanitizer,
    private _uploadService: UploadService
  ) {
    this.user = this._authService.getAuthenticatedUser();
    this.pageIndex = 1;
    this.totalPages = [];
    this.resultsPerPage = [15, 50, 100];
    this.resultsPerPageSelected = this.resultsPerPage[0];
  }
  ngOnInit(): void {
    this.isLoading = true;

    this._usersProfilesService.getAllApprovers(this.user.id).subscribe(data => {
      this.approvers = data.content.results
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
    this.pageIndex = 1;
    this.searchRequests()

  }
  getDateFormat(date) {
    return date.replace(/\/?\d\d\d\d\/?/, '');
  }
  changePage(page) {
    this.pageIndex = page;
    this.searchRequests()
  }

  searchRequests() {
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    const objParam = {
      'limit': this.resultsPerPageSelected,
      'offset': (tmpPage * this.resultsPerPageSelected),
      'cod_user': this.user.id
    };
    this._usersProfilesService.getAllRequestsApproval(objParam).subscribe(data => {
      if (Array.isArray(data.content.results)) {
        this.requests = data.content.results
      } else {
        this.requests = [data.content.results]
      }
      this.isLoading = false;
      this.maxPage = Math.ceil(data.content.count / this.resultsPerPageSelected); // MUDAR AQ
      this.totalPages = this._pagination(this.pageIndex, this.maxPage);
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );


  }

  private _pagination(currentPage, nrOfPages) {
    let delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  updateRequestStatus(status, cod_id) {
    this.cod_id = cod_id
    if (status == 1) {
      this.awaitConfirmAccept = !this.awaitConfirmAccept;
    } else {
      this.awaitConfirmReject = !this.awaitConfirmReject;
    }
  }
  rejectRequestStatus(status) {
    this.cod_id = null
    if (status == 1) {
      this.awaitConfirmAccept = !this.awaitConfirmAccept;
    } else {
      this.awaitConfirmReject = !this.awaitConfirmReject;
    }
  }
  async confirmUpdateRequestStatus(status) {
    const $this = this
    if (status == 1) {
      this.awaitConfirmAccept = !this.awaitConfirmAccept;
    } else {
      this.awaitConfirmReject = !this.awaitConfirmReject;
    }
    const emailTextAcceptedTo = await this._translateService.get('USERS.EMAIL_TEXT_ACCEPTED_TO').toPromise()
    const emailTextRejectedTo = await this._translateService.get('USERS.EMAIL_TEXT_REJECTED_TO').toPromise()
    const emailTextAcceptedFrom = await this._translateService.get('USERS.EMAIL_TEXT_ACCEPTED_FROM').toPromise()
    const emailTextRejectedFrom = await this._translateService.get('USERS.EMAIL_TEXT_REJECTED_FROM').toPromise()
    const acceptedRequest = await this._translateService.get('USERS.ACCEPTED_REQUEST').toPromise()
    const rejectedRequest = await this._translateService.get('USERS.REJECTED_REQUEST').toPromise()



    const objParam = {
      'cod_status': status,
      'cod_id': this.cod_id,
      'cod_user': this.user.id,
      'emailTextFROM': status == 1 ? emailTextAcceptedFrom : emailTextRejectedFrom,
      'emailTextTO': status == 1 ? emailTextAcceptedTo : emailTextRejectedTo,
    };
    if (!this.cod_id) {
      return
    }
    this._usersProfilesService.updateStatusApprovalRequest(objParam).subscribe(data => {
      this._notify.success(status == 1 ? acceptedRequest : rejectedRequest);
      $this.cod_id = null
      $this.searchRequests()
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );


  }

  generateExcel() {
    let objParam = {
      cod_user: this.user.id
    };
    this.downloadInProgress = true;
    this._reportService.postProfileApprovalReport(objParam).subscribe(data => {
      try {
        let str_file_name = data.content.fileName;
        var uploadFile = (
          this.sanitizer.bypassSecurityTrustResourceUrl(
            environment().uploadGetFilesApiUrl +
            "/reports/" +
            this.user.id +
            "/" +
            data.content.fileName
          ) as any
        ).changingThisBreaksApplicationSecurity;
        this._uploadService.downloadFile(uploadFile).subscribe(
          (res) => {
            let obj_blob = new Blob([res]);
            var a = document.createElement("a");
            a.href = URL.createObjectURL(obj_blob);
            a.download = str_file_name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.downloadInProgress = false;
          },
          (err) => {
            this._authService.errCheck(err);
            console.log(err);
            this.downloadInProgress = false;

          }
        );
      } catch (err) {
        console.log(err);
        this.downloadInProgress = false;

      }
    },
      err => {
        this._authService.errCheck(err);
        this.downloadInProgress = false;

      }
    );
  }
}
