// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
    overflow: hidden;
    padding: 6px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 10px;
}

.image-item {
    float: left;
    margin-right: 10px;
    max-width: 150px;
    border: 1px solid #f5f5f5;
    display: inline;
    height: 100px;
    margin: 2px;
}

.selected {
    border: 3px solid #3CB371;
}

.image-background {
    width: 150px;
    height: 100px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

button {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-preferences/modals/change-background/change-background.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["ul {\n    overflow: hidden;\n    padding: 6px;\n    border-bottom: 1px solid #f5f5f5;\n    margin-bottom: 10px;\n}\n\n.image-item {\n    float: left;\n    margin-right: 10px;\n    max-width: 150px;\n    border: 1px solid #f5f5f5;\n    display: inline;\n    height: 100px;\n    margin: 2px;\n}\n\n.selected {\n    border: 3px solid #3CB371;\n}\n\n.image-background {\n    width: 150px;\n    height: 100px;\n    background-size: cover;\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n}\n\nbutton {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
