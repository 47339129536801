import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime, fromEvent, map } from 'rxjs';

@Directive({
    selector: '[debounce]',
})
export class DebounceDirective implements OnInit {
    @Input() delay: number = 700;
    @Input() event: string = 'keyup';
    @Output() func = new EventEmitter<any>();

    constructor(private model: NgModel, private elementRef: ElementRef) {}

    ngOnInit() {
		const eventStream = fromEvent(this.elementRef.nativeElement, this.event)
			.pipe(
				map(() => this.model.value),
				debounceTime(this.delay)
			);
		
		eventStream.subscribe(input => this.func.emit(input));
	}
}
