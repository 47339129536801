export class Machine {
    cod_user: number;
    cod_machine: number;
    cod_equipment: number;
    cod_location: number;
    cod_area: number;
    cod_subarea: number;
    str_name: string;
    str_name_de: string;
    str_name_es: string;
    str_name_pt: string;
    str_name_fr: string;
    str_name_nl: string;
    str_name_ru: string;
    str_name_uk: string;
    str_name_zh: string;
    str_name_ko: string;
    str_name_vi: string;
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;
    cod_sap_functional_location: string;
    cod_acadia_id: string;


    constructor() {
        this.cod_user = 0;
        this.cod_machine = 0;
        this.cod_equipment = 0;
        this.cod_location = 0;
        this.cod_area = 0;
        this.cod_subarea= 0;
        this.cod_sap_functional_location = '';
        this.cod_acadia_id = '';
        this.str_name = '';
        this.str_name_de = '';
        this.str_name_es = '';
        this.str_name_pt = '';
        this.str_name_fr = '';
        this.str_name_nl = '';
        this.str_name_ru = '';
        this.str_name_uk = '';
        this.str_name_zh = '';
        this.str_name_ko = '';
        this.str_name_vi = '';
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
    }
}
