// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-image {
    display: flex;
    color: var(--text-ui-secondary);
    justify-content: space-between;
    margin: 6px 15px !important;
    margin-bottom: 0px !important;
}
.select-image:hover {
    cursor: pointer;
    color: var(--text-ui-primary);
}
.select-image label {
    font-weight: normal;
}
.select-image-content-area {
    display: flex;
}
.select-image-content-area i {
    margin-top: 2px;
}
.select-image-content-area ul li {
    display: flex;
    justify-content: space-between;
}

.select-image-content-area img {
    height: 20px;
    width: 20px;
}

.value-delmiter-sel {
    font-weight: 900;
    margin-bottom: 0 !important;
    width: 100%;
    text-align: right;
    padding-right: 7px;
}

.value-delmiter-sel-sel:hover {
    color: var(--text-ui-inverted)
}

.value-delimiter-sel-default {
    padding-right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-delimiter/select-delimiter.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,8BAA8B;IAC9B,2BAA2B;IAC3B,6BAA6B;AACjC;AACA;IACI,eAAe;IACf,6BAA6B;AACjC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".select-image {\n    display: flex;\n    color: var(--text-ui-secondary);\n    justify-content: space-between;\n    margin: 6px 15px !important;\n    margin-bottom: 0px !important;\n}\n.select-image:hover {\n    cursor: pointer;\n    color: var(--text-ui-primary);\n}\n.select-image label {\n    font-weight: normal;\n}\n.select-image-content-area {\n    display: flex;\n}\n.select-image-content-area i {\n    margin-top: 2px;\n}\n.select-image-content-area ul li {\n    display: flex;\n    justify-content: space-between;\n}\n\n.select-image-content-area img {\n    height: 20px;\n    width: 20px;\n}\n\n.value-delmiter-sel {\n    font-weight: 900;\n    margin-bottom: 0 !important;\n    width: 100%;\n    text-align: right;\n    padding-right: 7px;\n}\n\n.value-delmiter-sel-sel:hover {\n    color: var(--text-ui-inverted)\n}\n\n.value-delimiter-sel-default {\n    padding-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
