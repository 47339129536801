// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div {
  display: flex;
  flex-direction: column;
}

.choice-option label {
  font-weight: normal;
  margin-left: 3px;
  cursor: pointer;
}

.choice-option input {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/selection-input/selection-input.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[":host div {\n  display: flex;\n  flex-direction: column;\n}\n\n.choice-option label {\n  font-weight: normal;\n  margin-left: 3px;\n  cursor: pointer;\n}\n\n.choice-option input {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
