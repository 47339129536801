import { Environment } from "./environment.interface";


export function environment():Environment {
  const browserWindow = window as any || {};

  const browserWindowEnv = browserWindow.__env;

  if (!browserWindowEnv) {
      throw new Error('Environment wasn\'t set. Make sure env.js is configured');
  }

  return browserWindowEnv;
}
