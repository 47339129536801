import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-logbooks-admin',
    templateUrl: './logbooks-admin.component.html',
    styleUrls: ['./logbooks-admin.component.css']
})
export class LogbooksAdminComponent implements OnInit {

    constructor() {


    }

    ngOnInit() {
    }

}
