import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Translation } from "../translations-input/models/translation.model";

@Component({
    selector: 'translations-input',
    templateUrl: './translations-input.component.html',
    styleUrls: ['./translations-input.component.css'],
})
export class TranslationsInput {

    @Input() show: boolean = false;
    @Input() disabled: boolean = false;
    @Input() de: string = '';
    @Input() es: string = '';
    @Input() fr: string = '';
    @Input() nl: string = '';
    @Input() pt: string = '';
    @Input() ru: string = '';
    @Input() ua: string = '';
    @Input() zh: string = '';
    @Input() ko: string = '';
    @Input() vi: string = '';    

    @Output() onSave = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    constructor() { }

    save() {
        this.onSave.emit(new Translation(
            this.de,
            this.es,
            this.fr,
            this.nl,
            this.pt,
            this.ru,
            this.ua,
            this.zh,
            this.ko,
            this.vi,
        ));
    }

    cancel() {
        this.onCancel.emit();
    }

}
