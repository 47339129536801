import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {LoadingComponent} from './loading/loading.component';
import {MultiSelectComponent} from './multiselect/multiselect.component';
import {AlertComponent} from './alert/alert.component';
import {AttachmentsGallery} from './attachments-gallery/attachments-gallery.component'; 
import {TranslationsInput} from './translations-input/translations-input.component'; 
import {ConfirmComponent} from './confirm/confirm.component';
import {Typeahead2Component} from './typeahead/typeahead.component';
import {ChartActionsComponent} from '../components/chart-actions/chart-actions.component';
import {ChartLogbookComponent} from '../components/chart-logbook/chart-logbook.component';
import {ChartChecklistCollaborativeComponent} from '../components/chart-checklist-collaborative/chart-checklist-collaborative.component';

import {FilterComponent} from './filter/filter.component';

import {TranslateObserverService} from '../services/translate-observer.service';
import {ContentEditableComponent} from '../components/content-editable/content-editable.component';
import {ConfimDiscartChangesComponent} from '../components/confirm-discart-changes/confirm-discart-changes.component';
import {ActionListComponent} from '../components/action-list/action-list.component';
import {ActionCreateComponent} from '../components/action-create/action-create.component';
import {ChartMeetingComponent} from '../components/chart-meeting/chart-meeting.component';
import {GridPieComponent} from '../components/chart-pie-percent/chart-pie-percent.component';
import {ChartTimeWorkingComponent} from '../components/chart-time-working/chart-time-working.component';
import {ImportExcelComponent} from './import-excel/import-excel.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {ImportExcelService} from './import-excel/services/import-excel.service';
import {ButtonDownloadComponent} from './button-download-csv/button-download-csv.component';
import {ButtonSaveComponent} from './button-save/button-save.component';
import {DownloadCsvService} from './button-download-csv/services/download-csv.service';
import {ListAreasComponent} from './list-areas/list-areas.component';
import {ListAreasService} from './list-areas/services/list-areas.service';
import {ListSubAreasComponent} from './list-subareas/list-subareas.component';
import {ListSubAreasService} from './list-subareas/services/list-subareas.service';
import {ListEquipmentsComponent} from './list-equipments/list-equipments.component';
import {ListEquipmentsService} from './list-equipments/services/list-equipments.service';
import {ListMaintenancePlanComponent} from './list-maintenance-plan/list-maintenance-plan.component';
import {ListMaintenancePlanService} from './list-maintenance-plan/services/list-maintenance-plan.service';
import {FormPercentageEvolutionComponent} from './form-percentage-evolution/form-percentage-evolution.component';
import {FormPercentageEvolutionService} from './form-percentage-evolution/services/form-percentage-evolution.service';
import {ShiftComponent} from './shift/shift.component';

import { HyperlinksListComponent } from './hyperlinks-list/hyperlinks-list.component';

import { FormsInputComponent } from './forms-input/forms-input.component';
import { FormsInputLabelComponent } from './forms-input-label/forms-input-label.component';
import { SelectionInputComponent } from './selection-input/selection-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { DateInputComponent } from './date-input/date-input.component';
import { YesNoInputComponent } from './yes-no-input/yes-no-input.component';
import { SingleSelectionInputComponent } from './single-selection-input/single-selection-input.component';
import { MultiSelectionInputComponent } from './multi-selection-input/multi-selection-input.component';
import { ListUserTaComponent } from './list-users-ta/list-users-ta.component';

import {ListAreasTaComponent} from './list-areas-ta/list-areas-ta.component';
import {ListAreasTaService} from './list-areas-ta/services/list-areas-ta.service';

import {ListSubAreasTaComponent} from './list-subareas-ta/list-subareas-ta.component';
import {ListSubAreasTaService} from './list-subareas-ta/services/list-subareas-ta.service';

import {ListEquipmentsTaComponent} from './list-equipments-ta/list-equipments-ta.component';
import {ListEquipmentsTaService} from './list-equipments-ta/services/list-equipments-ta.service';

import {ListMachinesTaComponent} from './list-machines-ta/list-machines-ta.component';
import {ListMachinesTaService} from './list-machines-ta/services/list-machines-ta.service';

import {ListLocationsComponent} from './list-locations/list-locations.component';
import {ListLocationsService} from './list-locations/services/list-locations.service';

import {ListWorldsComponent} from './list-worlds/list-worlds.component';
import {ListWorldsService} from './list-worlds/services/list-worlds.service';

import {AnalyticsMainService} from './analytics/services/analytics-main.service';
import {ActionsAnalyticsService} from './analytics/services/actions-analytics.service';

import {ListRegionalsComponent} from './list-regionals/list-regionals.component';
import {ListRegionalsService} from './list-regionals/services/list-regionals.service';

import {ListPillarsComponent} from './list-pillars/list-pillars.component';
import {ListPillarsService} from './list-pillars/services/list-pillars.service';

import {ListIndicatorsComponent} from './list-indicators/list-indicators.component';
import {ListIndicatorsService} from './list-indicators/services/list-indicators.service';
import {PdfMakerService} from './pdf-maker/services/pdf-maker.service';

import {ClickOutsideDirective} from './click-outside/click-outside.directive';
import {UserImageComponent} from './user-image/user-image.component';
import {UserImageService} from './user-image/services/user-image.service';
import {UserImageMainService} from './user-image/services/user-image-main.service';
import {SelectLanguageComponent} from './select-language/select-language.component';
import {SelectDelimiterComponent} from './select-delimiter/select-delimiter.component';
import {MaskComponent} from './mask/mask.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {LoadingBackdropComponent} from "./loading-backdrop/loading-backdrop.component";
import {RoutineHistoryComponent} from "./routine-history/routine-history.component";
import {RoutineShowAnswersComponent} from "./routine-show-answers/routine-show-answers.component";
import {ConvertDate} from "./convert-date/convert-date.component";

import {AccessControlLogComponent} from './access-control-log/access-control-log.component';
import {AccessControlLogService} from './access-control-log/services/access-control-log.service';

import { ModalComponent } from './modal/modal.component';

import {DisplayWarningComponent} from './display-warning/display-warning.component';
import { QuestionAnswerButtonComponent } from './question-answer-button/question-answer-button.component';
import { AttachmentSaveComponent } from './attachment-save/attachment-save.component';
import { HyperLinkModalComponent } from './hyper-link-modal/hyper-link-modal.component';
import { IalMultiselectComboboxComponent } from './ial-multiselect-combobox/ial-multiselect-combobox.component';
import { TrustHtmlPipe } from './../meetings/meetings-chat/input-comment-chat/safe.pipes';

// FILTER SELECT COMPONENTS
import { ListAreasSelectComponent } from './list-areas-select/list-areas-select.component';
import { ListSubAreasSelectComponent } from './list-sub-areas-select/list-sub-areas-select.component';
import { ListEquipmentSelectComponent } from './list-equipment-select/list-equipment-select.component';
import { ListMachineSelectComponent } from './list-machine-select/list-machine-select.component';
import { ListChecklistSelectComponent } from './list-checklist-select/list-checklist-select.component';
import { ListAreasSelectService } from './list-areas-select/services/list-areas-select.service';
import { ListSubAreasSelectService } from './list-sub-areas-select/services/list-sub-areas-select.service';
import { ListEquipmentSelectService } from './list-equipment-select/services/list-equipment-select.service';
import { ListMachinesSelectService } from './list-machine-select/services/list-machine-select.service';
import { ListChecklistSelectService } from './list-checklist-select/services/list-checklist-select.service';
import { AuthMainService } from './services/auth-main.service';
import { ChecklistMainService } from './list-checklist-select/services/checklist-main.service';

//ECHARTS
import { NgxEchartsModule } from 'ngx-echarts';
import { PdfMakerComponent } from './pdf-maker/pdf-maker.component';
import { EmailConfirmationModalComponent } from './email-confirmation-modal/email-confirmation-modal.component';
import { EmailModalService } from './email-confirmation-modal/email-confirmation-modal.service';
import { AttachmentsZoomComponent } from './attachments-zoom/attachments-zoom.component';
import { TemplateImportExcelComponent } from './template-import-excel/template-import-excel.component';
import { SecureFilePipe } from './secure-pipe/secure-file.pipe';
import { ChoosenNameComponent } from './choosen-name/choosen-name.component';

import { AreasService } from './services/areas.service';

export function chartModule(): any {
  return import('echarts');

}

@NgModule({
    declarations: [
        QuestionAnswerButtonComponent,
        LoadingComponent,
        SelectLanguageComponent,
        SelectDelimiterComponent,
        AlertComponent,
        AttachmentsGallery,
        TranslationsInput,
        FilterComponent,
        HyperlinksListComponent,
        FormsInputComponent,
        FormsInputLabelComponent,
        SelectionInputComponent,
        TextInputComponent,
        NumberInputComponent,
        DateInputComponent,
        YesNoInputComponent,
        SingleSelectionInputComponent,
        MultiSelectionInputComponent,
        ListUserTaComponent,
        ConfirmComponent,
        ChartActionsComponent,
        ChartLogbookComponent,
        ChartChecklistCollaborativeComponent,
        ChartMeetingComponent,
        ActionListComponent,
        ActionCreateComponent,
        GridPieComponent,
        ChartTimeWorkingComponent,
        ImportExcelComponent,
        ButtonDownloadComponent,
        ButtonSaveComponent,
        ListAreasComponent,
        ListSubAreasComponent,
        ListEquipmentsComponent,
        ClickOutsideDirective,
        DatepickerComponent,
        ListMaintenancePlanComponent,
        ListAreasTaComponent,        
        ListSubAreasTaComponent,
        ListEquipmentsTaComponent,
        ListMachinesTaComponent,
        ListLocationsComponent,
        ListWorldsComponent,
        ListRegionalsComponent,
        ListPillarsComponent,
        ListIndicatorsComponent,
        UserImageComponent,
        MaskComponent,
        ShiftComponent,
        CheckboxComponent,
        LoadingBackdropComponent,
        FormPercentageEvolutionComponent,
        RoutineHistoryComponent,
        RoutineShowAnswersComponent,
        Typeahead2Component,
        AccessControlLogComponent,
        ModalComponent,
        DisplayWarningComponent,
        AttachmentSaveComponent,
        HyperLinkModalComponent,
        IalMultiselectComboboxComponent,
        PdfMakerComponent,
        ListAreasSelectComponent,
        ListSubAreasSelectComponent,
        ListEquipmentSelectComponent,
        ListMachineSelectComponent,
        ListChecklistSelectComponent,
        EmailConfirmationModalComponent,
        AttachmentsZoomComponent,
        AttachmentsZoomComponent,
        ContentEditableComponent,
        ConfimDiscartChangesComponent,
        TemplateImportExcelComponent,
        SecureFilePipe,
        ChoosenNameComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        NgxEchartsModule.forRoot({
            echarts: chartModule,
        }),
        TrustHtmlPipe,
        ConvertDate, 
        MultiSelectComponent,
    ],
    exports: [
        QuestionAnswerButtonComponent,
        LoadingComponent,
        SelectLanguageComponent,
        SelectDelimiterComponent,
        MultiSelectComponent,
        AlertComponent,
        AttachmentsGallery,
        TranslationsInput,
        FilterComponent,
        HyperlinksListComponent,
        FormsInputComponent,
        FormsInputLabelComponent,
        SelectionInputComponent,
        TextInputComponent,
        NumberInputComponent,
        DateInputComponent,
        YesNoInputComponent,
        SingleSelectionInputComponent,
        MultiSelectionInputComponent,
        ListUserTaComponent,
        ConfirmComponent,
        Typeahead2Component,
        ChartActionsComponent,
        ChartLogbookComponent,
        ChartChecklistCollaborativeComponent,
        ChartMeetingComponent,
        ActionListComponent,
        ActionCreateComponent,
        GridPieComponent,
        ChartTimeWorkingComponent,
        ImportExcelComponent,
        ButtonDownloadComponent,
        ButtonSaveComponent,
        ListAreasComponent,
        ShiftComponent,
        ListSubAreasComponent,
        ListEquipmentsComponent,
        ClickOutsideDirective,
        DatepickerComponent,
        ListMaintenancePlanComponent,
        ListAreasTaComponent,        
        ListSubAreasTaComponent,
        ListEquipmentsTaComponent,
        ListMachinesTaComponent,
        ListLocationsComponent,
        ListWorldsComponent,
        ListRegionalsComponent,
        ListPillarsComponent,
        ListIndicatorsComponent,
        UserImageComponent,
        MaskComponent,
        CheckboxComponent,
        LoadingBackdropComponent,
        FormPercentageEvolutionComponent,
        RoutineHistoryComponent,
        RoutineShowAnswersComponent,
        ConvertDate,
        AccessControlLogComponent,
        ModalComponent,
        DisplayWarningComponent,
        AttachmentSaveComponent,
        HyperLinkModalComponent,
        IalMultiselectComboboxComponent,
        PdfMakerComponent,
        ListAreasSelectComponent,
        ListSubAreasSelectComponent,
        ListEquipmentSelectComponent,
        ListMachineSelectComponent,
        ListChecklistSelectComponent,
        EmailConfirmationModalComponent,
        AttachmentsZoomComponent,
        TrustHtmlPipe,
        AttachmentsZoomComponent,
        ContentEditableComponent,
        ConfimDiscartChangesComponent,
        TemplateImportExcelComponent,
        SecureFilePipe,
        ChoosenNameComponent
    ],
    providers: [
        TranslateObserverService,
        ImportExcelService,
        DownloadCsvService,
        ListAreasService,
        ListSubAreasService,
        ListEquipmentsService,
        ListMaintenancePlanService,
        ListAreasTaService,        
        ListSubAreasTaService,
        ListEquipmentsTaService,
        ListMachinesTaService,
        ListLocationsService,
        ListWorldsService,
        ListRegionalsService,
        ActionsAnalyticsService,
        AnalyticsMainService,
        ListPillarsService,
        ListIndicatorsService,
        UserImageService,
        UserImageMainService,
        FormPercentageEvolutionService,
        AccessControlLogService,
        PdfMakerService,
        ListAreasSelectService,
        ListSubAreasSelectService,
        ListEquipmentSelectService,
        ListMachinesSelectService,
        ListChecklistSelectService,
        AuthMainService,
        ChecklistMainService,
        EmailModalService,
        AreasService
    ]
})
export class ComponentsModule {
}
