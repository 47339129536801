import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})

export class LeftMenuComponent {
  constructor(private router: Router, public _authService: AuthService) { }

  isActive(currentRoute: string): boolean {
    return this.router.url.indexOf(currentRoute) !=-1 || (this.router.url =='/' && currentRoute == '/home');
  }
}
