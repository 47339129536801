import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';

@Component({
  selector: 'app-template-import-excel',
  templateUrl: './template-import-excel.component.html',
  styleUrls: ['./template-import-excel.component.css']
})
export class TemplateImportExcelComponent implements OnInit {
  @Input() procedureToImport: string;
  @Input() fieldsExcelSheet: string;
  @Input() additionalParamsToExcel: string[];
  @Input() showLabels: boolean;
  @Input() titleButton: string;
  @Input() downloadCsvExample: any;
  @Input() confirmMessage: any;
  @Input() generateCsvTemplateOnClientSide: boolean;

  @Output() onExportSuccess = new EventEmitter<any>();
  @Output() onExportError = new EventEmitter<any>();
  @Output() onStartLoading = new EventEmitter<any>();
  @Output() onGenerateCsv = new EventEmitter<void>();
  
  isShowModal: boolean = false;
  isLoadingFile: boolean = false;
  codUser: number;

  constructor(
    private _authService: AuthService, 
  ) { 
    this.codUser = this._authService.getAuthenticatedUser().id;
  }

  ngOnInit(): void {
  }

  onClick($event) {
      return this.isShowModal = $event ? !this.isShowModal : this.isShowModal;
  }

  startLoading($event) {
    this.onStartLoading.emit($event);
  }

  public messageImported($event) : void {
    this.onExportSuccess.emit($event);
    this.isShowModal = false;
  }
  
  public messageError($event) : void {
    this.onExportError.emit($event);
  }

}
