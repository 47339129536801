import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../main/access/services/auth.service';
import { QuillEditorComponent, QuillViewComponent } from 'ngx-quill';
import { ActionsService } from '../services/actions.service';
import { NotificationService } from '../../main/services/notification.service';
import { CommentType, SyncStatus } from '../services/types/enums';
import { UploadService } from '../../main/services/upload.service';
import { environment } from '../../../environments/environment';
import { ActionComment } from '../services/types/actions-service-types';
import { ConvertDate } from '../../components/convert-date/convert-date.component';
import CH from '../services/helpers/comments-helpers';
import { finalize, Subscription } from 'rxjs';
import { SocketService } from '../../main/services/socket.service';


@Component({
  standalone: true,
  selector: 'app-meetings-actions-comments-drawer',
  imports: [
    ClbDrawerModule, 
    TranslateModule, 
    QuillEditorComponent, 
    QuillViewComponent,
    CommonModule,
    FormsModule,
    ConvertDate,
    ClbModalModule,
  ],
  templateUrl: './meetings-actions-comments-drawer.component.html',
  styleUrls: ['./meetings-actions-comments-drawer.component.css']
})
export class MeetingsActionsCommentsDrawerComponent implements OnInit, OnChanges, OnDestroy {

  @Input('open') isOpen: boolean = false;
  @Input('codAction') cod_action: number;
  @Input('codMeeting') cod_meeting: number;
  @Input('commentsCount') comments_loading_count: number = 3;
  @Input('isMeetingOwner') is_meeting_owner: boolean = false;
  @Input('socketId') socket_id: string;

  @Output() onClose = new EventEmitter<void>();

  readonly CommentType = CommentType;
  readonly SyncStatus = SyncStatus;

  obj_connection: Subscription;
  can_refresh: boolean;

  obj_user: any;
  cod_user: number;
  bol_loading_comments: boolean = true;

  count_retries: number = 0;
  allow_after_retries: number = 2;
  count_comment_changes: number = 0;

  arr_messages: ActionComment[] = [];

  input_focus: boolean = false;

  maxCharCount: number = 255;

  str_comment: any;
  charCount: number = 0;
  invalidCharCount: boolean = false;

  obj_comment_edit: ActionComment;
  str_comment_edit: string;
  editCharCount: number = 0;
  invalidEditCharCount: boolean = false;

  failed_comments: Map<ActionComment, string> = new Map();

  addCommentEditor: any;
  editCommentEditor: any;

  bol_confirm_delete_comment_modal = false;
  commentToDelete: number = null;

  constructor(
    private readonly _authService: AuthService, 
    private readonly _actionsService: ActionsService,
    private readonly _notify: NotificationService,
    private readonly _uploadService: UploadService,
    private readonly _socketService: SocketService
  ) { 
    this.obj_user = _authService.getAuthenticatedUser();
    this.cod_user = this.obj_user.id;
  }

  ngOnInit() {
    try {
      this.obj_connection = this._socketService.subscribeToActionMessage().subscribe({
        next: (messages) => {
          let obj_messages: any;
          obj_messages = messages;
          if (
            obj_messages.obj_message.cod_meeting == this.cod_meeting &&
            obj_messages.obj_message.cod_action == this.cod_action &&
            obj_messages.obj_message.origin != this.socket_id
          ) {
            if (this.isOpen) {
              this.getActionComments(); // If drawer is open, we can try to refresh immediately
            } else {
              this.can_refresh = true; // If drawer is minimized, do not refresh yet, but set flag to refresh when opening it
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err, 'Action Comments SOCKET ERROR', false);
        },
      });
    } catch (err) {
      this.showErrorMessage(err, 'Action Comments SOCKET ERROR 2', false);
    }
  }

  ngOnDestroy(): void {
    if(!this.obj_connection?.closed){
        this.obj_connection?.unsubscribe()
    }
}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen?.currentValue) {
      if(changes.cod_action && changes.cod_action?.currentValue !== changes.cod_action?.previousValue) {
        this.arr_messages = [];
      }
      this.count_comment_changes = 0;
      if(this.arr_messages.length === 0 || this.can_refresh) { // Only refreshes comment list if arr_essages is empty, or if socket received update while drawer is minimized
        this.getActionComments();
      } else {
        this.count_retries += 1; // As a failsafe, if user keeps opening and closing drawer, maybe the socket disconnected, so let it refresh
        this.can_refresh = this.count_retries < this.allow_after_retries ? this.can_refresh : true;
      }
    }
  }

  initAddCommentQuillEditor(event) {
    this.addCommentEditor = event.editor;
  }

  initEditCommentQuillEditor(event) {
    this.editCommentEditor = event.editor
    this.updateEditCharCount({text: this.editCommentEditor?.getText() ?? this.str_comment_edit});
  }

  updateCharCount(event) {
    this.addCommentEditor = event?.editor ?? this.addCommentEditor;
    this.charCount = event?.text?.trim()?.length ?? 0;
    this.invalidCharCount = this.charCount > this.maxCharCount;
  }

  updateEditCharCount(event) {
    this.editCommentEditor = event?.editor ?? this.editCommentEditor;
    this.editCharCount = event?.text?.trim()?.length ?? 0;
    this.invalidEditCharCount = this.editCharCount > this.maxCharCount;
  }

  addComment() {
    this.updateCharCount({text: this.addCommentEditor?.getText() ?? this.str_comment});
    if (this.invalidCharCount || !this.charCount) return;
    if (this.str_comment) {
        this.str_comment = this.str_comment
            .replace(/<li><br><\/li>/g, '') // erase empty list items
            .replace(/(<ul><\/ul>|<ol><\/ol>)/g, '') //erase empty lists caused by step above
            .replace(/(<p><br><\/p>)*$/g, ''); // erase all trailing empty lines at the end
        
        const local_comment: ActionComment = {
          sync_status: SyncStatus.SYNCING, // waiting/syncing
          cod_user: this.cod_user,
          str_name: CH.formatName(this.obj_user.name),
          str_text: this.str_comment,
          type: CommentType.TXT,
          dat_ins: null,
          cod_actions_comments: null,
          cod_action: null,
          str_name_image: null,
          str_img_comment: null,
          dat_alt: null,
          dat_del: null,
          str_img_path: null
        };
        this.arr_messages.push(local_comment);
        this.str_comment = '';

        this.postActionComment(local_comment);
    }
  }

  fileChangeEvent(event) {
    const params = {
        'event': event,
        'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
    };

    this._uploadService.uploadFileBase64(params).subscribe({
        next: response => {
            if (response.type == 'success') {

                const local_comment: ActionComment = {
                  sync_status: SyncStatus.SYNCING, // waiting/syncing
                  cod_user: this.cod_user,
                  str_name: CH.formatName(this.obj_user.name),
                  str_text: null,
                  type: CH.checkCommentType(response.content.str_name),
                  dat_ins: null,
                  cod_actions_comments: null,
                  cod_action: null,
                  str_name_image: response.content.str_name, // To retry in case it fails after upload is already successful,
                  str_img_comment: `${environment().uploadGetFilesApiUrl}/${response.content.str_name}`,
                  dat_alt: null,
                  dat_del: null,
                  str_img_path: null
                };
                this.arr_messages.push(local_comment);
                this.postActionComment(local_comment);
            } else {
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE';
                this.showErrorMessage(response.content, text);
            }
        },
        error: err => {
            const text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE';
            this.showErrorMessage(err, text);
            this._authService.errCheck(err);
        }
    });
  }

  postActionComment(local_comment: ActionComment) {
    const comment_param = {
        str_description: local_comment.str_text,
        str_file_name: local_comment.str_name_image
    };
    this.count_comment_changes += 1;
    this._actionsService.postActionComment(this.cod_user, this.cod_action, comment_param).subscribe({
      next: (result) => {
        this.parseRequestResult(result, (data: { cod_action_comment: number, dat_ins: string }[]) => {
          this._socketService.refreshActionMessage({origin: this.socket_id, cod_meeting: this.cod_meeting, cod_action: this.cod_action});
          this._actionsService.getRefreshEventSubject().next({type: 'UPDATE_ACTION_COMMENTS', cod_meeting: this.cod_meeting, cod_action: this.cod_action});
          this.updateLocalCommentsSuccess(data[0], local_comment);
        }, (err)=>{
          this.count_comment_changes = Math.max(this.count_comment_changes - 1, 0);
          let text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_COMMENT';
          text = local_comment.type === CommentType.TXT ? text : 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE_COMMENT';
          this.showErrorMessage(err, text);
          this.updateLocalCommentsFailure(local_comment);
        });
      },
      error: (err) => {
        this.count_comment_changes = Math.max(this.count_comment_changes - 1, 0);
        let text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_COMMENT';
        text = local_comment.type === CommentType.TXT ? text : 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE_COMMENT';
        this.showErrorMessage(err, text);
        this.updateLocalCommentsFailure(local_comment);
        this._authService.errCheck(err);
      }
    });
  }

  updateLocalCommentsSuccess(data, local_comment: ActionComment) {
    local_comment.sync_status = SyncStatus.SUCCESS;
    local_comment.cod_actions_comments = data.cod_action_comment;
    local_comment.dat_ins = data.dat_ins;
    // Just in case there was a refresh with the new comment that somehow returns before the insert confirmation
    if (this.arr_messages.some(item => item.cod_actions_comments === local_comment.cod_actions_comments)) {
      CH.mergeDuplicated(this.arr_messages, 'cod_actions_comments'); 
    }
  }

  updateLocalCommentsFailure(local_comment: ActionComment) {
    local_comment.sync_status = SyncStatus.FAILURE;
  }

  getActionComments() {
    this.bol_loading_comments = true;
    this.count_retries = 0;

    this._actionsService.getActionComments(this.cod_user, this.cod_action).subscribe({
      next: (result) => {
        this.parseRequestResult(result, (data: ActionComment[]) => {

          const sorter = (a: ActionComment, b: ActionComment) => {
            if (!a.dat_ins) return 1;
            if (!b.dat_ins) return -1;
            return new Date(a.dat_ins).getTime() - new Date(b.dat_ins).getTime();
          };

          const skipCondition = (incoming: ActionComment, existing: ActionComment) => {
            return (
              existing.sync_status !== SyncStatus.SUCCESS &&
              existing.str_text !== incoming.str_text
            );
          };

          const options = {
            skipCondition,
            removeMissing: true,
          };

          CH.unionMerge(this.arr_messages, data, 'cod_actions_comments', options).sort(sorter);

          this.arr_messages.forEach((comment) => {
            comment.type = CH.checkCommentType(comment.str_name_image);
            comment.str_name = CH.formatName(comment.str_name);
            comment.sync_status = comment.sync_status ?? SyncStatus.SUCCESS;
            comment.is_deleted = comment.dat_del !== null;
            if (comment.type !== CommentType.TXT) {
              comment.str_img_comment = `${environment().uploadGetFilesApiUrl}/${comment.str_name_image}`;
            }
          });
        }, 
        (err) => {
          let text = 'Error getting action comments';
          this.showErrorMessage(err, text);
        });
      },
      error: (err) => {
        let text = 'Error getting action comments';
        this.showErrorMessage(err, text);
      },
      complete: () => {
        this.bol_loading_comments = false;
      }
    });
  }

  openEditComment(comment) {
    this.obj_comment_edit = comment;
    this.str_comment_edit = this.obj_comment_edit.str_text;
  }

  cancelEditComment() {
      this.obj_comment_edit = null;
      this.str_comment_edit = null;
  }

  saveEditComment() {
    this.updateEditCharCount({text: this.editCommentEditor?.getText() ?? this.str_comment_edit});
    if(this.invalidEditCharCount || !this.editCharCount) return;

    const comment_edit = this.obj_comment_edit;
    const str_edit_comment = this.str_comment_edit;
    this.obj_comment_edit = null;
    this.str_comment_edit = null;

    this.putActionComment(comment_edit, str_edit_comment);
  }

  putActionComment(comment_edit: ActionComment, str_edit_comment: string){
    let str_original_comment = comment_edit.str_text;
    comment_edit.str_text = str_edit_comment;
    comment_edit.sync_status = SyncStatus.SYNCING;
    
    const cod_comment = comment_edit.cod_actions_comments;
    const comment_param = {
      str_comment: str_edit_comment
    };

    this.count_comment_changes += 1;
    this._actionsService.putActionComment(this.cod_user, cod_comment, comment_param).subscribe({
      next: res => {
        this._socketService.refreshActionMessage({origin: this.socket_id, cod_meeting: this.cod_meeting, cod_action: this.cod_action});
        this._actionsService.getRefreshEventSubject().next({type:'UPDATE_ACTION_COMMENTS', cod_meeting: this.cod_meeting, cod_action: this.cod_action});
        comment_edit.sync_status = SyncStatus.SUCCESS;
        comment_edit.dat_alt = new Date().toISOString();
      }, 
      error: err => {
        this.count_comment_changes = Math.max(this.count_comment_changes - 1, 0);
        comment_edit.sync_status = SyncStatus.FAILURE;
        this.failed_comments.set(comment_edit, str_original_comment);
        const text = 'MEETING.COMMENTS_DRAWER.ERRORS.EDIT_COMMENT';
        this.showErrorMessage(err, text);
        this._authService.errCheck(err);
      } 
    });
  }

  retrySaveComment(obj_comment: ActionComment) {
    obj_comment.sync_status = SyncStatus.SYNCING;
    if(!obj_comment.cod_actions_comments) {
      // retry add comment
      this.postActionComment(obj_comment);
    } else {
      // retry save edit comment
      const str_text = this.failed_comments.get(obj_comment)
      this.failed_comments.delete(obj_comment);
      const str_comment = obj_comment.str_text;
      obj_comment.str_text = str_text;
      this.putActionComment(obj_comment, str_comment);
    }
  }

  cancelFailedComment(obj_comment: ActionComment) {
    let str_text = this.failed_comments.get(obj_comment);
    this.failed_comments.delete(obj_comment);
    obj_comment.str_text = str_text ?? obj_comment.str_text;
    obj_comment.sync_status = SyncStatus.SUCCESS;
    if(!obj_comment.cod_actions_comments){
      this.arr_messages = this.arr_messages.filter(item => obj_comment != item);
    }
  }

  downloadFile(fileUrl: string) {
    this._uploadService.downloadFile(fileUrl).subscribe({
        next: res => {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = CH.getFileName(fileUrl);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        error: err => {
          console.log('Error downloading file', err);
          const text = 'MEETING.COMMENTS_DRAWER.ERRORS.DOWNLOAD_FILE';
          this.showErrorMessage(err, text);
          this._authService.errCheck(err);
        }
    });
  }

  parseRequestResult(data: any, calback, error) {
    if (data.code == 888 && data.content.code == 888) {
        let res = data.content.results
        calback(res);
    } else {
        error(data);
    }
  }
  
  showErrorMessage(err, text, notify = true) {
    console.log({text, err});
    if(notify) this._notify.error({ text, translate: true });
  }

  closeDrawer() {
    this.onClose.emit();
  }

  openConfirmDelete(comment: ActionComment) {
    this.commentToDelete = comment.cod_actions_comments;
    this.bol_confirm_delete_comment_modal = true;
  }

  cancelDelete() {
    this.bol_confirm_delete_comment_modal = false;
    this.commentToDelete = null;
  }

  confirmDelete() {
    const comment = this.arr_messages.find(comment => comment.cod_actions_comments === this.commentToDelete);
    comment.sync_status = SyncStatus.SYNCING;
    comment.is_deleted = true

      this._actionsService.deleteActionComment(this.cod_user, this.commentToDelete)
        .pipe(finalize(() => {
          this.bol_confirm_delete_comment_modal = false;
          this.commentToDelete = null;
          comment.sync_status = SyncStatus.SUCCESS;
        }))
        .subscribe({
          next: () => {
            comment.dat_del = new Date().toISOString();
            comment.is_deleted = true
            this._socketService.refreshActionMessage({origin: this.socket_id, cod_meeting: this.cod_meeting, cod_action: this.cod_action});
            this._actionsService.getRefreshEventSubject().next({type:'UPDATE_ACTION_COMMENTS', cod_meeting: this.cod_meeting, cod_action: this.cod_action});
          },
          error: (err) => {
            let text = 'MEETING.COMMENTS_DRAWER.ERRORS.DELETE_COMMENT';
            this.showErrorMessage(err, text);
            this._authService.errCheck(err);
            comment.is_deleted = false;
          }      
        });
  }
}
