// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-zoom{
    width: 80%;
    margin: auto auto;
}

.attached-file {
    max-height: 55vh;
    overflow: scroll;
    text-align: center;
}

.attached-file-fit img {
    width: 100%;
    max-height: 68vh;
}
.zoom-range{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    box-shadow: none;
}
.zoom-label{
    text-align: center;
}
.attached-file-zoom-2x img{
    width:  calc(100% + 50vw);
    height: auto;
}

.attached-file-zoom-4x img{
    width:  calc(100% + 100vw);
    height: auto;
}

.file-actions{
    text-align: center;
}

.file-actions button {
    margin:10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/attachments-zoom/attachments-zoom.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".modal-zoom{\n    width: 80%;\n    margin: auto auto;\n}\n\n.attached-file {\n    max-height: 55vh;\n    overflow: scroll;\n    text-align: center;\n}\n\n.attached-file-fit img {\n    width: 100%;\n    max-height: 68vh;\n}\n.zoom-range{\n    width: 20%;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 0px;\n    box-shadow: none;\n}\n.zoom-label{\n    text-align: center;\n}\n.attached-file-zoom-2x img{\n    width:  calc(100% + 50vw);\n    height: auto;\n}\n\n.attached-file-zoom-4x img{\n    width:  calc(100% + 100vw);\n    height: auto;\n}\n\n.file-actions{\n    text-align: center;\n}\n\n.file-actions button {\n    margin:10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
