import {Injectable} from '@angular/core';
import {ListLocationsMainService} from './list-locations-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListLocationsService {
  
  constructor(private _mainService: ListLocationsMainService) {
  }
  
  getLocationsList(param): Observable<any> {
    let path = '/forms/locations/'+ param.cod_user +'/' + param.cod_location_regional +'/' + param.term;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
