export class Notes {
    cod_notes: Number;
    str_description: string;
    dat_notes: string;
    cod_user: Number;
    cod_uuid: string;
    bol_done: number;
    dat_note_datapicker: string;
    dat_notes_db: string;

    constructor() {
        this.cod_notes = 0;
        this.str_description = '';
        this.dat_notes = '';
        this.cod_user = 0;
        this.cod_uuid=null;
        this.dat_note_datapicker = '';
        this.dat_notes_db = '';
    }
}