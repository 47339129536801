// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Component__Alert__Container {
  margin-bottom: 0.5em;
  padding: 0;
  color: var(--text-ui-primary);
  border-radius: 3px;
  align-items: center;
  display: flex;
}

.Component__Alert__Container.isSuccess {
  background-color: #00c156;
}

.Component__Alert__Container.isError {
  background-color: #fe484f;
}

.Component__Alert__Container.isWarning {
  background-color: var(--ui-primary);
}

.Component__Alert__Container.isInfo{
  background-color: var(--ui-primary);
}

.Component__Alert__Container.isInfoGray{
  background-color: #B4B4B4;
}

.Component__Alert__Container__Icon {
  display: inline;
  padding: 0 0.3em;
  font-size: 1.5em;
  vertical-align: middle;
}

.Component__Alert__Container__Message {
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/alert/alert.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,OAAO;AACT","sourcesContent":[".Component__Alert__Container {\n  margin-bottom: 0.5em;\n  padding: 0;\n  color: var(--text-ui-primary);\n  border-radius: 3px;\n  align-items: center;\n  display: flex;\n}\n\n.Component__Alert__Container.isSuccess {\n  background-color: #00c156;\n}\n\n.Component__Alert__Container.isError {\n  background-color: #fe484f;\n}\n\n.Component__Alert__Container.isWarning {\n  background-color: var(--ui-primary);\n}\n\n.Component__Alert__Container.isInfo{\n  background-color: var(--ui-primary);\n}\n\n.Component__Alert__Container.isInfoGray{\n  background-color: #B4B4B4;\n}\n\n.Component__Alert__Container__Icon {\n  display: inline;\n  padding: 0 0.3em;\n  font-size: 1.5em;\n  vertical-align: middle;\n}\n\n.Component__Alert__Container__Message {\n  font-size: 14px;\n  display: flex;\n  justify-content: center;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
