import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-task-topic-2',
  templateUrl: './task-topic-2.component.html',
  styleUrls: ['./task-topic-2.component.css']
})
export class TaskTopic2Component implements OnInit {

  constructor(public userPreferencesService:UserPreferencesService) { }

  ngOnInit() {
  }

}
