import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MeetingsService} from '../services/meetings.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {MeetingsTypesService} from '../services/meetings-types.service';
import {UsersService} from '../../admin/services/users.service';
import {AreaService} from '../../logbooks/services/area.service';
import {MeetingsIndicatorsService} from '../services/meetings-indicators.service';
import {EquipmentService} from '../../logbooks/services/equipment.service';
import {ValidationsService} from '../../logbooks/services/validations/validations.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportsService} from 'app/logbooks/services/reports.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-meetings-reports',
    templateUrl: './meetings-reports.component.html',
    styleUrls: ['./meetings-reports.component.css']
})
export class MeetingsReportsComponent implements OnInit, AfterViewInit {
    cod_user = this.authService.getAuthenticatedUser().id;
    mail_to_send = this.authService.getAuthenticatedUser().email;

    cod_type_period = 1;
    initial_date = null;
    final_date = null;
    reportsApiUrl = environment().reportsApiUrl;
    hashtags: string = '';

    worldList: any = [];
    meetingTypesList: any = [];
    regionalList: any = [];
    locationsList: any = [];
    areaList: any = [];
    actionUserCreationList: any = [];
    actionUserExecutionList: any = [];
    meetingList: any = [];
    subAreasList: any = [];
    indicatorsList: any = [];
    equipmentList: any = [];
    paramsSelected: any = [];
    loadingConfig: any = [];
    isOwner: boolean = false;
    isParticipate: boolean = false;
    messageWarning: string = '';
    isWarning: boolean = false;
    isSent: boolean = false;
    timeToHide: number = 0;
    showUsers: boolean;
    arr_areas: any = [];
    //If "All I participate" or "All I own" is checked, then all filter must be disabled and on submit, all of then must be sent as null
    disableAllFilters: boolean;

    stillGenerating: boolean = false;
    lastObjParamToLog: any = [];
    timeLastDetailedReport: any;
    actualObjParam: any = [];

    // Translate report
    private strReportSubject: string;
    private strFileNameDownload: string;
    private arrHeaders: any[];


    constructor(private _meetingsService: MeetingsService, public authService: AuthService, private _meetingTypesService: MeetingsTypesService, private _reportService: ReportsService,
                private _userService: UsersService, private _areaService: AreaService, private _indicatorsService: MeetingsIndicatorsService,
                private _equipmentService: EquipmentService, public validationService: ValidationsService, private _translateService: TranslateService) {
        this.paramsSelected = [];
        this.showUsers = false;
        this.arr_areas = [];
        let today = new Date();
        let strDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + (today.getDate())).slice(-2);
        this.initial_date = strDate;
        this.final_date = strDate;
        this.disableAllFilters = false;
    }

    ngOnInit(): void {
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['areas'] = false;
        this.loadingConfig['subareas'] = false;
        this.loadingConfig['equipments'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
        this.loadingConfig['creation_users'] = false;
        this.loadingConfig['execution_users'] = false;
        this.loadingConfig['meetings_types'] = false;
        this.loadingConfig['meetings'] = false;
        this.loadingConfig['indicators'] = false;

        this._clearWorldList();

        let $this = this;
        $this._searchWorlds(function () {
            $this.paramsSelected['worlds_selected'] = [];
            $this.worldList.map((world) => {
                if ($this.authService.getAuthenticatedUser().cod_world == world.cod_locations_world) {
                    $this.paramsSelected['worlds_selected'].push({ cod_locations_world: world.cod_locations_world });
                }
            });
            $this.paramsSelected['worlds'] = $this._implodeValues($this.paramsSelected['worlds_selected'], 'cod_locations_world');
            $this.paramsSelected['meetings_types_selected'] = [];
            $this.paramsSelected['meetings_types'] = '';
            $this._searchMeetingTypes();
            $this._searchRegionals(function () {
                $this.paramsSelected['regionals_selected'] = [];
                $this.regionalList.map((regional) => {
                    if ($this.authService.getAuthenticatedUser().cod_regional == regional.cod_locations_regional) {
                        $this.paramsSelected['regionals_selected'].push({ cod_locations_regional: regional.cod_locations_regional });
                    }
                });
                $this.paramsSelected['regionals'] = $this._implodeValues($this.paramsSelected['regionals_selected'], 'cod_locations_regional');
                $this._searchLocations(function () {
                    $this.paramsSelected['locations_selected'] = [];
                    $this.locationsList.map((location) => {
                        if ($this.authService.getAuthenticatedUser().cod_location == location.cod_location) {
                            $this.paramsSelected['locations_selected'].push({ cod_location: location.cod_location });
                        }
                    });
                    $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_location');
                    $this._searchAreas(function () {
                        $this.paramsSelected['areas_selected'] = [];
                        $this.areaList.map((area) => {
                            if ($this.authService.getAuthenticatedUser().cod_area == area.cod_area) {
                                $this.paramsSelected['areas_selected'].push({ cod_area: area.cod_area });
                            }
                        });
                        $this.paramsSelected['areas'] = $this._implodeValues($this.paramsSelected['areas_selected'], 'cod_area');
                    });
                });
            });
        });
    }

    ngAfterViewInit(): void {
        this._searchIndicators();
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
        this._searchAreas(null);
        this._searchMeetings();
    }

    selectCreationUsers($event) {
        this.paramsSelected['creation_users_selected'] = $event.selectedOptions;
        this.paramsSelected['creation_users'] = this._implodeValues($event.selectedOptions, 'cod_id');
    }

    selectExecutionUsers($event) {
        this.paramsSelected['execution_users_selected'] = $event.selectedOptions;
        this.paramsSelected['execution_users'] = this._implodeValues($event.selectedOptions, 'cod_id');
    }

    selectWorlds($event) {
        this.paramsSelected['worlds_selected'] = $event.selectedOptions;
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
        this._searchMeetingTypes();
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectAreas($event) {
        this.paramsSelected['areas_selected'] = $event.selectedOptions;
        this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
        // this._searchSubAreas();
    }

    selectMeetings($event) {
        this.paramsSelected['meetings_selected'] = $event.selectedOptions;
        this.paramsSelected['meetings'] = this._implodeValues($event.selectedOptions, 'cod_meetings');
        this.paramsSelected['meetings_name'] = this._implodeValues($event.selectedOptions, 'str_name');
        this._searchCreationUsers();
        this._searchExecutionUsers();
        this.showUsers = this._emptyDefaultValue(this.paramsSelected['meetings'], null);
    }

    selectMeetingTypes($event) {
        this.paramsSelected['meetings_types_selected'] = $event.selectedOptions;
        this.paramsSelected['meetings_types'] = this._implodeValues($event.selectedOptions, 'cod_meetings_types');
        this._searchMeetings();
    }

    selectSubAreas($event) {
        this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
        this._searchEquipments();
    }

    selectIndicators($event) {
        this.paramsSelected['indicators'] = this._implodeValues($event.selectedOptions, 'cod_indicators');
    }

    selectEquipments($event) {
        this.paramsSelected['equipments'] = this._implodeValues($event.selectedOptions, 'cod_subareas_equipments');
    }

    selectHashtags($event) {
        this.paramsSelected['hashtags'] = this._implodeValues($event.selectedOptions, 'cod_hashtags');
    }

    private _areAllSelected(listSelected, listAll) {
        return listSelected.length == listAll.length;
    }

    private _listToValue(listSelected, listAll, valueWhenSelected, valueWhenAllSelected) {
        if (this._areAllSelected(listSelected, listAll)) {
            return valueWhenAllSelected;
        }
        return valueWhenSelected;
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearAreaList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this.areaList = [];
    }

    private _smartClearAreaList() {

        if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
                this.areaList.map((area) => {
                    if (area.cod_area == selected.cod_area) arr.push(area);
                });
            });
            this.paramsSelected['areas_selected'] = arr;
            this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
        }
        if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
            this.paramsSelected['areas_selected'] = [];
            this.paramsSelected['areas'] = '';
        }

    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocationsList();
    }

    private _smartClearRegionalList() {

        if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
                this.regionalList.map((regional) => {
                    if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
                });
            });
            this.paramsSelected['regionals_selected'] = arr;
            this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        }
        if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
            this.paramsSelected['regionals_selected'] = [];
            this.paramsSelected['regionals'] = '';
            this._clearLocationsList();
        } else {
            this._searchLocations(null);
        }

    }

    private _clearLocationsList() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearAreaList();
        this._clearMeetingList();
    }

    private _smartClearLocationsList() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearAreaList();
            this._clearMeetingList();
        } else {
            this._searchAreas(null);
            this._searchMeetings();
        }
        
    }

    private _clearSubAreaList() {
        this.paramsSelected['subareas'] = '';
        this.paramsSelected['subareas_selected'] = [];
        this.paramsSelected['equipments'] = '';
        this.subAreasList = [];
        this.equipmentList = [];
    }

    private _clearMeetingList() {
        this.meetingList = [];
        this.paramsSelected['meetings'] = '';
        this.paramsSelected['meetings_selected'] = [];
        this.paramsSelected['meetings_name'] = '';
        this._clearUserCreation();
    }

    private _clearUserCreation() {
        this.actionUserCreationList = [];
        this.paramsSelected['creation_users'] = '';
        this.paramsSelected['creation_users_selected'] = [];
        this._clearExecutionUsers();

    }

    private _smartClearUserCreation() {

        if (this.paramsSelected['creation_users_selected'] && this.paramsSelected['creation_users_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['creation_users_selected'].map((selected: { cod_id: any; }) => {
                this.actionUserCreationList.map((actionUser) => {
                    if (actionUser.cod_id == selected.cod_id) arr.push(actionUser);
                });
            });
            this.paramsSelected['creation_users_selected'] = arr;
            this.paramsSelected['creation_users'] = this._implodeValues(this.paramsSelected['creation_users_selected'], 'cod_id');
        }
        if (!this.paramsSelected['creation_users_selected'] || this.paramsSelected['creation_users_selected'].length == 0) {
            this.paramsSelected['creation_users_selected'] = [];
            this.paramsSelected['creation_users'] = '';
        }

    }

    private _clearExecutionUsers() {
        this.paramsSelected['execution_users'] = '';
        this.paramsSelected['execution_users_selected'] = [];
        this.actionUserExecutionList = [];
    }

    private _smartClearExecutionUsers() {

        if (this.paramsSelected['execution_users_selected'] && this.paramsSelected['execution_users_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['execution_users_selected'].map((selected: { cod_id: any; }) => {
                this.actionUserExecutionList.map((actionUser) => {
                    if (actionUser.cod_id == selected.cod_id) arr.push(actionUser);
                });
            });
            this.paramsSelected['execution_users_selected'] = arr;
            this.paramsSelected['execution_users'] = this._implodeValues(this.paramsSelected['execution_users_selected'], 'cod_id');
        }
        if (!this.paramsSelected['execution_users_selected'] || this.paramsSelected['execution_users_selected'].length == 0) {
            this.paramsSelected['execution_users_selected'] = [];
            this.paramsSelected['execution_users'] = '';
        }

    }

    private _smartClearMeetingList() {

        if (this.paramsSelected['meetings_selected'] && this.paramsSelected['meetings_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['meetings_selected'].map((selected: { cod_meetings: any; }) => {
                this.meetingList.map((meetings) => {
                    if (meetings.cod_meetings == selected.cod_meetings) arr.push(meetings);
                });
            });
            this.paramsSelected['meetings_selected'] = arr;
            this.paramsSelected['meetings'] = this._implodeValues(this.paramsSelected['meetings_selected'], 'cod_meetings');
        }
        if (!this.paramsSelected['meetings_selected'] || this.paramsSelected['meetings_selected'].length == 0) {
            this.paramsSelected['meetings_selected'] = [];
            this.paramsSelected['meetings'] = '';
            this._clearUserCreation();
            this._clearExecutionUsers();
        } else {
            this._searchCreationUsers();
            this._searchExecutionUsers();
        }

    }

    private _clearMeetingTypeList() {
        this.meetingTypesList = [];
        this.paramsSelected['meetings_types_selected'] = [];
        this.paramsSelected['meetings_types'] = '';
        this._clearMeetingList();
    }

    private _smartClearMeetingTypeList() {

        if (this.paramsSelected['meetings_types_selected'] && this.paramsSelected['meetings_types_selected'].length > 0) {
            let arr = [];
            this.paramsSelected['meetings_types_selected'].map((selected: { cod_meetings_types: any; }) => {
                this.meetingTypesList.map((meetings) => {
                    if (meetings.cod_meetings_types == selected.cod_meetings_types) arr.push(meetings);
                });
            });
            this.paramsSelected['meetings_types_selected'] = arr;
            this.paramsSelected['meetings_types'] = this._implodeValues(this.paramsSelected['meetings_types_selected'], 'cod_meetings_types');
        }
        if (!this.paramsSelected['meetings_types_selected'] || this.paramsSelected['meetings_types_selected'].length == 0) {
            this.paramsSelected['meetings_types_selected'] = [];
            this.paramsSelected['meetings_types'] = '';
            this._clearMeetingList();
        } else {
            this._searchMeetings();
        }

    }

    private _searchAreas(fn) {

        //Search for areas
        if (this.paramsSelected['locations'] && this.paramsSelected['locations'].toString() != '') {
            this._setLoading('areas', true);
            let params = {
                arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
                cod_user: this.authService.getAuthenticatedUser().id,
                dsc_page_privilege: 'REPORT_MEETING'
            };
            this._userService.getAreasByLocation(params).subscribe({
                next: data => {
                    this.areaList = this._userService.getArrayContent(data);
                    this._setLoading('areas', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearAreaList();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearAreaList();
        }
    }

    private _searchSubAreas() {
        this._clearSubAreaList();
        if (this.paramsSelected['areas'] && this.paramsSelected['areas'].toString() != '') {
            this._setLoading('subareas', true);
            //Search for areas
            let objParam = {
                'cod_areas': this.paramsSelected['areas'].toString(),
                'term': '',
                'cod_user': this.authService.getAuthenticatedUser().id
            };
            this._areaService.getSubAreasByArea(objParam).subscribe({
                next: data => {
                    this.subAreasList = this._areaService.getArrayContent(data);
                    this._setLoading('subareas', false);
                    //this._searchEquipments();
                },
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        }
    }

    private _searchEquipments() {
        this.paramsSelected['equipments'] = '';
        this.equipmentList = [];
        if ((this.paramsSelected['subareas'] && this.paramsSelected['subareas'].toString() != '') &&
            (this.paramsSelected['locations'] && this.paramsSelected['locations'].toString() != '')) {
            //Search for equipments
            this._setLoading('equipments', true);
            let objParam = {
                'cod_subareas': this.paramsSelected['subareas'].toString(),
                'cod_locations': this.paramsSelected['locations'].toString(),
                'cod_user': this.authService.getAuthenticatedUser().id
            };
            this._equipmentService.getEquipmentsBySubAreaAndLocations(objParam).subscribe({
                next: data => {
                    this.equipmentList = this._equipmentService.getArrayContent(data);
                    this._setLoading('equipments', false);
                },
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        }
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);

        this._userService.getWorlds(this.authService.getAuthenticatedUser().id, 'REPORT_MEETING').subscribe({
            next: data => {
                this.worldList = this._userService.getArrayContent(data);
                this._setLoading('worlds', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
            error: err => {
                this.authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _searchLocations(fn) {

        if (this.paramsSelected['regionals'] && this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._userService.getLocations(params, this.authService.getAuthenticatedUser().id, 'REPORT_MEETING').subscribe({
                next: data => {
                    this.locationsList = this._userService.getArrayContent(data);
                    this._setLoading('locations', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearLocationsList();
                },
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearLocationsList();
        }

    }

    private _searchRegionals(fn) {

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this.authService.getAuthenticatedUser().id, 'REPORT_MEETING').subscribe({
                next: data => {
                    this.regionalList = this._userService.getArrayContent(data);
                    this._setLoading('regionals', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearRegionalList();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearRegionalList();
        }

    }

    private _searchMeetings() {

        if ((this.paramsSelected['meetings_types'] && this.paramsSelected['meetings_types'].toString() != '') &&
            (this.paramsSelected['locations'] && this.paramsSelected['locations'].toString() != '')) {
            this._setLoading('meetings', true);
            let params = {
                'cod_meetings_types': this.paramsSelected['meetings_types'].toString().split(','),
                'cod_user': this.authService.getAuthenticatedUser().id,
                'dsc_page_privilege': 'REPORT_MEETING',
                'cod_locations': this.paramsSelected['locations'].toString().split(',')
            };
            this._meetingsService.getDistinctMeetingsByMeetingTypes(params).subscribe({
                next: data => {
                    this.meetingList = this._meetingsService.getArrayContent(data);
                    this._setLoading('meetings', false);
                    this._smartClearMeetingList();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearMeetingList();
        }

    }

    private _searchCreationUsers() {

        if (this.paramsSelected['meetings'] && this.paramsSelected['meetings'].toString() != '') {
            this._setLoading('creation_users', true);
            let params = {
                'cod_meetings': this.paramsSelected['meetings'].toString(),
                'str_path': environment().uploadGetFilesApiUrl + '/',
                'cod_user': this.authService.getAuthenticatedUser().id
            };
            this._meetingsService.getActionCreationUser(params).subscribe({
                next: data => {
                    this.actionUserCreationList = this._meetingsService.getArrayContent(data);
                    this._setLoading('creation_users', false);
                    this._smartClearUserCreation();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearUserCreation();
        }

    }

    private _searchExecutionUsers() {

        if (this.paramsSelected['meetings'] && this.paramsSelected['meetings'].toString() != '') {
            this._setLoading('execution_users', true);
            let params = {
                'cod_meetings': this.paramsSelected['meetings'].toString(),
                'str_path': environment().uploadGetFilesApiUrl + '/',
                'cod_user': this.authService.getAuthenticatedUser().id
            };
            this._meetingsService.getActionExecutionUser(params).subscribe({
                next: data => {
                    this.actionUserExecutionList = this._meetingsService.getArrayContent(data);
                    this._setLoading('execution_users', false);
                    this._smartClearExecutionUsers();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearExecutionUsers();
        }

    }

    private _searchMeetingTypes() {

        if (this.paramsSelected['worlds'] && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('meetings_types', true);
            let term = '';
            let path = '';
            let userId = this.authService.getAuthenticatedUser().id;
            let privilege = 'REPORT_MEETING';
            let worlds = this.paramsSelected['worlds'].toString();
            this._meetingTypesService.getMeetingsTypesSearch(term, path, userId, privilege, worlds).subscribe({
                next: data => {
                    this.meetingTypesList = this._meetingTypesService.getArrayContent(data);
                    this._setLoading('meetings_types', false);
                    this._smartClearMeetingTypeList();
                }, 
                error: err => {
                    this.authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearMeetingTypeList();
        }

    }

    private _searchIndicators() {
        const params = {
            str_term: '--',
            cod_user: this.authService.getAuthenticatedUser().id,
            dsc_page_privilege: 'REPORT_MEETING'
        };
        this._indicatorsService.getMeetingsIndicators(params).subscribe({
            next: data => {
                if(data.content.length > 1) {
                    this.indicatorsList = data.content.results;
                } else {
                    this.indicatorsList = [data.content.results];
                }
            },
            error: err => {
                this.authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _showWarning(message) {
        this.isWarning = true;
        this._translateService.get(message).subscribe(translate => {
            this.messageWarning = translate;
        });
    }

    private _getAsArray(strText) {
        if (typeof strText != typeof undefined && strText) {
            return strText.toString().split(',');
        }
        return [];
    }

    private _submit() {
        this.validationService.boolSubmit = true;

        if (this._hasInvalidParams())
            return false

        this.validationService.boolIsLoading = true;
        this.validationService.boolSubmit = false;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;

        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            this._submitReport('reports_sp_ex_meetings_jr3', mailText)
        });
    }

    private _submitQuality() {
        this.validationService.boolSubmit = true;

        if (this._hasInvalidParams())
            return false

        this.validationService.boolIsLoading = true;
        this.validationService.boolSubmit = false;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;

        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            this._submitReport('reports_sp_ex_meetings_quality_jr3', mailText)
        });
    }

    submit() {
        let $this = this;
        this._translateReport(function () {
            $this._submit();
        });
    }

    submitQuality() {
        let $this = this;
        this._translateReportQuality(function () {
            $this._submitQuality();
        });
    }

    private _emptyDefaultValue(value, defaultValue) {
        if ((typeof value !== typeof undefined && value)) {
            return value;
        }
        return defaultValue;
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    hideMessage() {
        this.isSent = false;
    }

    toggleSelectionParticipate($event) {
        this.isParticipate = $event > 0;
        this.disableAllFilters = (this.isParticipate || this.isOwner);
    }

    toggleSelectionOwn($event) {
        this.isOwner = $event > 0;
        this.disableAllFilters = (this.isParticipate || this.isOwner);
    }

    private _isInvalidDateRange() {
        let selectedMeetings = this._getAsArray(this.paramsSelected['meetings'])
        let selectedLocations = this._getAsArray(this.paramsSelected['locations'])
        let isValidMeetingsRange = selectedMeetings.length < 101 && selectedMeetings.length > 0
        let isGreaterThan90Allowed = selectedLocations.length < 101 || isValidMeetingsRange || 
            (selectedLocations.length < 101 && isValidMeetingsRange)
        let limit = this.disableAllFilters || isGreaterThan90Allowed ? 185 : 95
        let reportRangeDiffInDays = Math.round(this._reportRangeDiffInDays())
        return (reportRangeDiffInDays < 0) || (reportRangeDiffInDays >= limit)
    }

    private _reportRangeDiffInDays() {
        let startDate = (new Date(this.initial_date)).getTime()
        let endDate = (new Date(this.final_date)).getTime()
        return (endDate - startDate) / (1000 * 3600 * 24)
    }

    private _hasInvalidParams() {
        //only validates meeting types and locations if the "All I'm Involved" and "All I Own" flags are unchecked
        if (!this.isOwner && !this.isParticipate) {
            if (!this._emptyDefaultValue(this.paramsSelected['meetings_types'], null) ||
                !this._emptyDefaultValue(this.paramsSelected['locations'], null) ||
                !this.initial_date || !this.final_date) {
                this._showWarning('REPORTS.MEETING_VALIDATION_MESSAGE');
                return true;
            }
        }

        if (this.initial_date > this.final_date) {
            this._showWarning('REPORTS.PERIOD_VALIDATION');
            return true;
        }

        if (this._isInvalidDateRange()) {
            this._showWarning('REPORTS.PERIOD_VALIDATION_MAX_DAYS');
            return true;
        }
        return false
    }

    private _submitReport(proc: string, mailText: string) {
        this.stillGenerating = true;

        mailText = mailText.replace('$USER', this.authService.getAuthenticatedUser().name);
        mailText = mailText.replace('$REPORT', this.strReportSubject);

        let listLocations = null;
        let listMeetingTypes = null;
        let listMeetings = null;
        let listMeetingNames = null;
        let listHashtags = null;
        let listIndicators = null;
        let listCreationUsers = null;
        let listExecutionUsers = null;
        let listAreas = null;
        let listSubAreas = null;
        let listEquipments = null;

        if (!this.disableAllFilters) {
            listLocations = this._emptyDefaultValue(this.paramsSelected['locations'], null);
            listHashtags = this._emptyDefaultValue(this.paramsSelected['hashtags'], null);

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['meetings_types']), this.meetingTypesList))) {
                listMeetingTypes = this._emptyDefaultValue(this.paramsSelected['meetings_types'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['meetings']), this.meetingList))) {
                listMeetings = this._emptyDefaultValue(this.paramsSelected['meetings'], null);
            }

            // This is correct! Test meeting codes and send to procedure meeting names
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['meetings']), this.meetingList))) {
                listMeetingNames = this._emptyDefaultValue(this.paramsSelected['meetings_name'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['indicators']), this.indicatorsList))) {
                listIndicators = this._emptyDefaultValue(this.paramsSelected['indicators'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['creation_users']), this.actionUserCreationList))) {
                listCreationUsers = this._emptyDefaultValue(this.paramsSelected['creation_users'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['execution_users']), this.actionUserExecutionList))) {
                listExecutionUsers = this._emptyDefaultValue(this.paramsSelected['execution_users'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['areas']), this.areaList))) {
                listAreas = this._emptyDefaultValue(this.paramsSelected['areas'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['subareas']), this.subAreasList))) {
                listSubAreas = this._emptyDefaultValue(this.paramsSelected['subareas'], null);
            }

            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['equipments']), this.equipmentList))) {
                listEquipments = this._emptyDefaultValue(this.paramsSelected['equipments'], null);
            }
        }

        let objParam = {
            cod_user: this.cod_user,
            api_url: this.reportsApiUrl,
            str_report_subject: this.strReportSubject,
            reportTab: 1,
            str_proc_exec: proc,
            arr_proc_params_search: [
                {
                    'pr_cods_locations': listLocations,
                    'pr_cod_meeting': listMeetings,
                    'pr_cod_meeting_types': listMeetingTypes,
                    'pr_str_meeting_name': listMeetingNames,
                    'pr_str_hashtags': listHashtags,
                    'pr_cod_indicators': listIndicators,
                    'pr_cod_owner_ins_action': listCreationUsers,
                    'pr_cod_owner_exc_action': listExecutionUsers,
                    'pr_ind_im_owner': (this.isOwner ? '1' : '0'),
                    'pr_ind_im_participate': (this.isParticipate ? '1' : '0'),
                    'pr_cod_user': this.cod_user,
                    'pr_type_period': this.cod_type_period,
                    'pr_initial_date': this.initial_date,
                    'pr_final_date': this.final_date,
                    'pr_area': listAreas,
                }
            ],
            arr_headers: this.arrHeaders,
            name_of_file_to_download: this.strFileNameDownload,
            str_column_dynamic: 'str_columns',
            str_worker_link: environment().workerReportPath,
            str_mail_text: mailText
        };
        // can not do subscribe because rabbit mq does not return a response
        let objParamToLog = {
            cod_user: this.cod_user,
            api_url: this.reportsApiUrl,
            str_report_subject: this.strReportSubject,
            reportTab: 1,
            str_proc_exec: proc,
            arr_proc_params_search: [
                {
                    'pr_cods_world': this.paramsSelected['worlds'],
                    'pr_cods_regionals': this.paramsSelected['regionals'],
                    'pr_cods_locations': this.paramsSelected['locations'],
                    'pr_cod_meeting': this.paramsSelected['meetings'],
                    'pr_cod_meeting_types': this.paramsSelected['meetings_types'],
                    'pr_str_meeting_name': this.paramsSelected['meetings_name'],
                    'pr_str_hashtags': this.paramsSelected['hashtags'],
                    'pr_cod_indicators': this.paramsSelected['indicators'],
                    'pr_cod_owner_ins_action': this.paramsSelected['creation_users'],
                    'pr_cod_owner_exc_action': this.paramsSelected['execution_users'],
                    'pr_ind_im_owner': (this.isOwner ? '1' : '0'),
                    'pr_ind_im_participate': (this.isParticipate ? '1' : '0'),
                    'pr_cod_user': this.cod_user,
                    'pr_type_period': this.cod_type_period,
                    'pr_initial_date': this.initial_date,
                    'pr_final_date': this.final_date,
                    'pr_area': this.paramsSelected['areas'],
                }
            ],
            arr_headers: this.arrHeaders,
            name_of_file_to_download: this.strFileNameDownload,
            str_column_dynamic: 'str_columns',
            str_worker_link: environment().workerReportPath,
            str_mail_text: mailText
        };

        this.actualObjParam = objParamToLog;

        if(this._reportService.isSameParameters(this.actualObjParam, this.lastObjParamToLog)){
            let now = new Date().getTime();
            let seconds = 300;
            if(typeof this.timeLastDetailedReport != typeof undefined){
                let milisec_diff = Math.abs(this.timeLastDetailedReport.getTime() - now);
                seconds = Math.floor(milisec_diff / 1000);
            }

            if(seconds < 300){
                this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
                this.validationService.boolIsLoading = false;
                this.stillGenerating = false;
                return;
            }
            else{
                this.timeLastDetailedReport = new Date();
            }
            
            this.validationService.boolIsLoading = false;
        }
        else{
            this.lastObjParamToLog = objParamToLog;            
            this.timeLastDetailedReport = new Date();
        }
        
        this.stillGenerating = true;

        this._reportService.postLogReports(objParamToLog).subscribe(data => {
        },
        err => {
            this.authService.errCheck(err);
        }
        );
        this._meetingsService.postMeetingsReports(objParam).subscribe({
            next: data => {
                this.validationService.boolIsLoading = false;
                this.stillGenerating = false;
                this.timeToHide = 3000;
                this.isSent = true;
            },
            error: err => {
                this.authService.errCheck(err);
                console.log(err);
                this.stillGenerating = false;
            }
        });
        
        this.validationService.boolSubmit = false;
    }

    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReport(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        forkJoin([
            this._translateService.get('MEETINGS_REPORTS.MAIL_SUBJECT'),
            this._translateService.get('MEETINGS_REPORTS.MAIL_FILE_NAME'),
            this._translateService.get('MEETINGS_REPORTS.STR_WORLD'),
            this._translateService.get('MEETINGS_REPORTS.STR_REGIONAL'),
            this._translateService.get('MEETINGS_REPORTS.STR_LOCATION'),
            this._translateService.get('MEETINGS_REPORTS.STR_AREA_MEETING'),
            this._translateService.get('MEETINGS_REPORTS.STR_AREA'),
            this._translateService.get('MEETINGS_REPORTS.STR_MEETING_TYPE'),
            this._translateService.get('MEETINGS_REPORTS.LAST_TIME_TOR_REFRESHED'),
            this._translateService.get('MEETINGS_REPORTS.STR_MEETING'),
            this._translateService.get('MEETINGS_REPORTS.STR_PERIODICITY'),
            this._translateService.get('MEETINGS_REPORTS.WEEKDAY'),
            this._translateService.get('MEETINGS_REPORTS.ACTION_CREATOR_ID'),
            this._translateService.get('MEETINGS_REPORTS.ACTION_CREATOR'),
            this._translateService.get('MEETINGS_REPORTS.DATE_CREATOR'),
            this._translateService.get('MEETINGS_REPORTS.SOURCE'),
            this._translateService.get('MEETINGS_REPORTS.INDICATOR_ICIV'),
            this._translateService.get('MEETINGS_REPORTS.FUNDAMENTAL_CAUSE'),
            this._translateService.get('MEETINGS_REPORTS.HASHTAG'),
            this._translateService.get('MEETINGS_REPORTS.STR_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.RESPONSIBLE_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.ID_RESPONSIBLE_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.DATE_LIMIT'),
            this._translateService.get('MEETINGS_REPORTS.STR_STATUS'),
            this._translateService.get('MEETINGS_REPORTS.COMMENTS'),
            this._translateService.get('MEETINGS_REPORTS.DATE_COMPLETED'),
            this._translateService.get('MEETINGS_REPORTS.DATE_CLOSED'),
            this._translateService.get('MEETINGS_REPORTS.HOUR_COMPLETED'),
        ]).subscribe(results => {
      
            [this.strReportSubject, this.strFileNameDownload, ...this.arrHeaders] = results;
      
            if (fnCallback) {
              fnCallback();
            }
      
          });
    }

    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReportQuality(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];
        forkJoin([
            this._translateService.get('MEETINGS_REPORTS.MAIL_SUBJECT_QUALITY'),
            this._translateService.get('MEETINGS_REPORTS.MAIL_FILE_NAME_QUALITY'),
            this._translateService.get('MEETINGS_REPORTS.STR_WORLD'),
            this._translateService.get('MEETINGS_REPORTS.STR_REGIONAL'),
            this._translateService.get('MEETINGS_REPORTS.STR_LOCATION'),
            // this._translateService.get('MEETINGS_REPORTS.STR_AREA_MEETING'), //proc seems to be broken and this seems to be unused, so did not change to add new column
            this._translateService.get('MEETINGS_REPORTS.STR_AREA'),
            this._translateService.get('MEETINGS_REPORTS.STR_MEETING_TYPE'),
            this._translateService.get('MEETINGS_REPORTS.LAST_TIME_TOR_REFRESHED'),
            this._translateService.get('MEETINGS_REPORTS.STR_MEETING'),
            this._translateService.get('MEETINGS_REPORTS.STR_PERIODICITY'),
            this._translateService.get('MEETINGS_REPORTS.WEEKDAY'),
            this._translateService.get('MEETINGS_REPORTS.ACTION_CREATOR_ID'),
            this._translateService.get('MEETINGS_REPORTS.ACTION_CREATOR'),
            this._translateService.get('MEETINGS_REPORTS.DATE_CREATOR'),
            this._translateService.get('MEETINGS_REPORTS.SOURCE'),
            this._translateService.get('MEETINGS_REPORTS.INDICATOR_ICIV'),
            this._translateService.get('MEETINGS_REPORTS.FUNDAMENTAL_CAUSE'),
            this._translateService.get('MEETINGS_REPORTS.HASHTAG'),
            this._translateService.get('MEETINGS_REPORTS.STR_QUALITY'),
            this._translateService.get('MEETINGS_REPORTS.VAL_QUALITY'),
            this._translateService.get('MEETINGS_REPORTS.STR_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.RESPONSIBLE_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.ID_RESPONSIBLE_ACTION'),
            this._translateService.get('MEETINGS_REPORTS.DATE_LIMIT'),
            this._translateService.get('MEETINGS_REPORTS.STR_STATUS'),
            this._translateService.get('MEETINGS_REPORTS.COMMENTS'),
            this._translateService.get('MEETINGS_REPORTS.DATE_UPDATE'),
            this._translateService.get('MEETINGS_REPORTS.DATE_COMPLETED'),
            this._translateService.get('MEETINGS_REPORTS.HOUR_COMPLETED'),
        ]).subscribe(results => {
      
            [this.strReportSubject, this.strFileNameDownload, ...this.arrHeaders] = results;
      
            if (fnCallback) {
              fnCallback();
            }
      
          });
    }
}