import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterByStatus'
})
export class FilterByStatus implements PipeTransform {
    transform(actions, filter: boolean, status: number, numberList: number) {
        let count = 0;
        if (status > 0) {
            if (numberList) {
                return actions.filter( action => (action.cod_actions_status === status && (count++ < numberList)));
            } else {
                return actions.filter( action => (action.cod_actions_status === status));
            }
        }
        if (numberList) {
            return actions.filter( action => !(filter && (action.cod_actions_status === 4 || action.cod_actions_status === 7)) && (count++ < numberList));
        } else {
            return actions.filter( action => !(filter && (action.cod_actions_status === 4 || action.cod_actions_status === 7)));
        }
    }
}
