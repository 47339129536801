// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-topic-detail {
    padding: 0 20px 20px;
}

.help-topic-detail .help-topic-title {
    color: var(--ui-primary);
    font-size: 1.5em;
    margin-bottom: 10px;
}

.help-topic-detail .help-topic-text {
    color: var(--text-secondary);
    text-indent: 1.5em;
    text-align: justify;
}

ul {
    width: 80%;
    margin-left: 50px;
}

ul li {
    list-style: initial !important;
    display: list-item;
    border: none;
    list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-reports/report-topic-2/report-topic-2.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":[".help-topic-detail {\n    padding: 0 20px 20px;\n}\n\n.help-topic-detail .help-topic-title {\n    color: var(--ui-primary);\n    font-size: 1.5em;\n    margin-bottom: 10px;\n}\n\n.help-topic-detail .help-topic-text {\n    color: var(--text-secondary);\n    text-indent: 1.5em;\n    text-align: justify;\n}\n\nul {\n    width: 80%;\n    margin-left: 50px;\n}\n\nul li {\n    list-style: initial !important;\n    display: list-item;\n    border: none;\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
