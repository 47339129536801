export class ListAreasParams {
    arr_cod_locations: Array<string | number>;
    cod_user: number;
    dsc_page_privilege: string;
    str_filter_by: string;

    constructor(
        cod_locations: any[] = [],
        user: number = null,
        page_privilege: string = '',
        filter_by: string = null,
    ) {
        this.arr_cod_locations = cod_locations;
        this.cod_user = user;
        this.dsc_page_privilege = page_privilege;
        this.str_filter_by = filter_by;
    }

    valid() {
        if (this.arr_cod_locations.length === 0) return false;
        if (this.arr_cod_locations.some((e: any) => ["", null].includes(e))) return false;
        if (this.arr_cod_locations.length === 0) return false;
        if (!this.cod_user) return false;
        if (!this.str_filter_by) return false;
        if (!this.dsc_page_privilege) return false;
        return true;
    }
}
