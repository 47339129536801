import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SubareaService} from '../services/subarea.service';
import {SelectPagination} from "../models/select_pagination.model";
import {NotificationService} from './../../main/services/notification.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubareaPlain } from '../models/sub_area-plain.model';
import { UserPreferencesService } from './../../user-preferences/services/user-preferences.service';

@Component({
    selector: 'app-subarea-list',
    templateUrl: './subarea-list.component.html',
    styleUrls: ['./subarea-list.component.css']
})
export class SubAreaListComponent implements OnInit, OnDestroy {

    selectedRecords: number[] = [];
    hasError = false;
    list: {subareas: SubareaPlain[] } = { subareas: [] };
    paramsSubscription: Subscription = Subscription.EMPTY;
    searchTerm: string;
    searchTimer: any;
    isLoading: boolean = true;
    codSubAreas: number = 0;
    createRecord: boolean = false;
    procedureImportingAreas = 'logbooks_sp_imp_subareas_sap_acadiaid';
    fieldsExcelSheet = 'code,cod_subareas,cod_sap_functional_location,str_name_areas,cod_acadia_id,str_name';
    codUser: number;
    awaitConfirmDelete: boolean = false;
    deleteSubAreaId: number = 0;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    editSuccessMessage: boolean = false;
    dsc_page_privilege: string = 'ADMIN_STRUCTURE_LISTING';
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;

    sapAndAcadiaIdPrivilege: boolean;
    isLoadingFile: boolean;

    importAreasSapAndAcadiaID$: Observable<string>;
    exportAreas$: Observable<string>;

    constructor(
        private _subAreaService: SubareaService, 
        private _authService: AuthService, 
        private _translateService: TranslateService,
        private _notify: NotificationService,
        private _userPreferencesService: UserPreferencesService
    ) {
        this.showError = false;
        this.errorMessage = '';
        this.searchTerm = '';
        this.list = {
            subareas: []
        };

        this.codUser = this._authService.getAuthenticatedUser().id;

        this.exportAreas$ = forkJoin([
            this._translateText('DYNAMICFORMS.REPORTS.EXPORT'),
            this._translateText('ADMIN.SUBAREAS')
        ])
            .pipe(
                map(translations => translations.join(' '))
            );

        this.importAreasSapAndAcadiaID$ = forkJoin([
            this._translateText('LOGBOOK.IMPORT_AREAS'),
            this._translateText('LOGBOOK.SAP_CODE'),
            this._translateText('LOGBOOK.ACADIA_ID')
        ])
            .pipe(
                map(translations => `${translations[0]} ${translations[1]} & ${translations[2]}`)
            );

        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this.str50perpage = '50 ' + translate;
            this.str100perpage = '100 ' + translate;
        });

        this.pageIndex = 1;
        this.totalPages = [];
        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;

        this.resultsPerPage = [
            new SelectPagination(10, this.str10perpage),
            new SelectPagination(50, this.str50perpage),
            new SelectPagination(100, this.str100perpage),
        ];
        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];        
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;  


    }

    ngOnInit() {
        this._search();

        this.sapAndAcadiaIdPrivilege = this._authService.getUserPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
    }

    ngAfterViewInit() {
        let objParam = {'cod_subarea': 0, 'str_term': this.searchTerm};
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        } catch (err) {
            //safe!
            console.log(err);
        }
    }

    async _translateText(translation: string): Promise<string> {
        return await this._translateService.get(translation).toPromise();
    }

    async exportSubareasToCsv(isTemplate: boolean): Promise<void> {
        const headers: string[] = await Promise.all(
            this.sapAndAcadiaIdPrivilege
                ? [
                    this._translateText('GENERAL.RECORD_ID'),
                    this._translateText('LOGBOOK.SAP_CODE'),
                    this._translateText('LOGBOOK.AREA'),
                    this._translateText('LOGBOOK.ACADIA_ID'),
                    this._translateText('LOGBOOK.SUBAREA'),
                ]
                : [
                    this._translateText('GENERAL.RECORD_ID'),
                    this._translateText('LOGBOOK.AREA'),
                    this._translateText('LOGBOOK.SUBAREA'),
                ]
        );

        const templateValues: string[] = this.sapAndAcadiaIdPrivilege 
            ? ['', 'CodeS@mple123', 'Template Area', 'CodeS@mple123', 'Template area']
            : ['', 'Template Area', 'Template area']

        const selectedSubareasValues: (string | number)[][] = isTemplate
            ? [templateValues]
            : this.selectedRecords.map(
                (codSubarea) => {
                    const subarea = this.list.subareas.find(s => s.cod_subareas === codSubarea);

                    const mapResultBasedOnSapAcadiaIdPrivilege = (subarea) => this.sapAndAcadiaIdPrivilege 
                        ? [ subarea.cod_subareas, subarea.cod_sap_functional_location, subarea.str_name_areas, subarea.cod_acadia_id, subarea.str_name ]
                        : [ subarea.cod_subareas, subarea.str_name_areas, subarea.str_name ]
                    
                    return subarea
                        ? mapResultBasedOnSapAcadiaIdPrivilege(subarea)
                        : [];
                }
            );

        const userPreferenceCsvDeliminator = this._userPreferencesService.getLocalCsvDelimiter();

        const csvDeliminator = userPreferenceCsvDeliminator === '(TAB)'
            ? '\t'
            : userPreferenceCsvDeliminator;

        const csvData = [headers, ...selectedSubareasValues].map(e => e.join(csvDeliminator)).join('\n');
        const csvContent = `data:text/csv;charset=utf-8,${csvData}`;
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `areas ${new Date().toLocaleDateString()}.csv`);

        document.body.appendChild(link);
        link.click();

        this._notify.success(`Areas exported successfully`);
    }

    generateCsvTemplate(): void {
        this.exportSubareasToCsv(true);
    }

    isSelected(codSubarea): boolean {
        return this.selectedRecords.indexOf(codSubarea) >= 0;
    }

    toggleAll(event): void {
        if (event) {
            this.selectedRecords = this.list.subareas.map(s => s.cod_subareas);
        } else {
            this.selectedRecords = [];
        }
    }
    
    toggleObjSelection($event, codSubarea): void {
        let index;
        if ($event != 0) {
          index = this.selectedRecords.indexOf($event);
        } else {
          index = this.selectedRecords.indexOf(codSubarea);
        }
    
        if (index == -1) {
          this.selectedRecords.push($event);
        } else {
          this.selectedRecords.splice(index, 1);
        }
    }

    public editSubArea(id, reload): void {
        if (id == -1) {
            id = 0;
            this.editSuccessMessage = true;
        } else {
            this.editSuccessMessage = false;
        }
        this.codSubAreas = id;
        this.createRecord = !reload;
        if (reload) {
            this._search();
        }
    }

    public toggleDeleteSubArea(id = 0): void {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteSubAreaId = id;        
    }

    public confirmDeleteSubArea(): void {
        let objParam = {
            'cod_subarea': this.deleteSubAreaId,
            'cod_user': this.codUser
        };
        this._subAreaService.deleteSubarea(objParam).subscribe(data => {
                this.editSuccessMessage = true;
                this._notify.success({text: 'LOGBOOK.SUBAREA_DELETED', translate: true});
                this.search(false);
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
        this.awaitConfirmDelete = false;
        this.deleteSubAreaId = 0;
    }

    public search(resetPage: boolean): void {
        this.codSubAreas = 0;
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            if (resetPage) {
                $this.pageIndex = 1;
            }
            $this._search();
        }, environment().debounceTime);
    }
    startLoading(): void {
        this.isLoadingFile = true;
    }

    private _search(): void {
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        this.isLoading = true;
        let objParam = {
            'str_term': this.searchTerm,
            'cod_user': this.codUser,
            'dsc_page_privilege': this.dsc_page_privilege,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected)
        };
        
        this._subAreaService.getListSubAreasByPagination(objParam).subscribe(data => {
                this.list = {
                    subareas: this._subAreaService.getArrayContent(data)
                };
                this.isLoading = false;
                if (data.content.length) {
                    this.maxPage = Math.ceil((+data.content?.results[0]?.full_count || 0) / this.resultsPerPageSelected);
                    this.totalPages = this.pagination(this.pageIndex, this.maxPage);
                }

            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
                this.isLoading = false;
            }
        );
    }

    closeErrorMessage(): void {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err): void {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this._notify.error(translate);
            this.showError = true;
        });
    }

    messageImported(response: any): void {
        this.isLoadingFile = false;
        response = JSON.parse(response);
        if (response?.errors) {
            this._showErrorMessage(JSON.stringify(response));
        } else {
            this._notify.success({ text: 'LOGBOOK.MESSAGE_IMPORTED_SUCCESS', translate: true });
        }
        this.search(false);
    }

    selectionChanged($event): void {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page): void {
        this.pageIndex = page;
        this.search(false);
    }

    pagination(currentPage, nrOfPages): number[] {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    hideMessage(): void {
        this.editSuccessMessage = false;
    }
    
}
