import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';

import {ShiftChanges} from './shift-changes.model';

import {TranslateService} from '@ngx-translate/core';
import {LogbookRoutineService} from '../../logbooks/services/logbook-routine.service';
import {AuthService} from '../../main/access/services/auth.service';
import * as _ from 'lodash';

@Component({
    host: {
        '(document:click)': 'onClickOutside($event)',
    },
    selector: 'component-shift',
    templateUrl: './shift.component.html',
    styleUrls: ['./shift.component.css']
})

export class ShiftComponent implements OnChanges {
    @ViewChild('shift', { static: true })
    container: ElementRef;

    // placeholder text of the outer input display
    @Input() placeholder: string;

    // tells whether data is loading
    @Input() isLoading: boolean = false;

    // tells whether data has failed to load
    @Input() hasError: boolean = false;

    // tells it should allow selecting multiple options
    @Input() multiple: boolean = true;

    // tells it should allow selecting multiple options
    @Input() hasImage: boolean = false;

    // shows some options as selected by default
    @Input() selected: any[] = [];

    // tells which property to use to get option's ID
    @Input() keyProperty: string = 'cod_item';

    // tells which property to use to get option's display value
    @Input() displayValueProperty: string = 'str_name';

    // tells which property to use to get option's display value
    @Input() displayImageValueProperty: string = 'str_img_path';

    // notify about changes in selected options
    @Output() selectionChanged = new EventEmitter<ShiftChanges>();

    // notify that this instance of multiselect was blurred
    @Output() blur = new EventEmitter<any>();

    // tells component is disabled
    @Input() isDisabled: boolean = false;

    // tells component to to initialize dirt
    @Input() isDirty: boolean = false;

    // tells component is disabled
    @Input() bShowPlaceholder: boolean = false;

    options: any = [];

    public constructor(private _translateService: TranslateService, private _logbooRoutinekService: LogbookRoutineService, private _authService: AuthService) {
        this._translateService.onLangChange.subscribe(() => this.updateSelectedPlaceholder());
        this._translateService.get('COMPONENT.SELECT_SHIFT').subscribe(translate => {
            this.placeholder = translate;
        });

    }

    isOpen = false;
    selectedOptions = {};
    filterredOptions = [];
    search = '';
    selectedPlaceholder = null;
    collabChecked = false;

    ngOnInit(){
        this._logbooRoutinekService.getShifts({cod_user: this._authService.getAuthenticatedUser().id}).subscribe(data => {
            if(data.type == 'success' && data.content.type == 'success'){
                this.options = data.content.results;
                this.filterOptions(true);
            }
        }, err => {
            let e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        });
    }

    toggleCollab() {
        this.filterOptions(false);
        for (let i of this.filterredOptions) {            
            const key = i[this.keyProperty];   
            this.selectedOptions[key] = false;
            delete this.selectedOptions[key];
        }
        if(!this.collabChecked){
            this.selectedOptions[1] = true;
        }
        this.collabChecked = this.collabChecked ? false: true;
        this.isDirty = true;
        this.updateSelectedPlaceholder();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.options) {
            this.filterOptions(true);
            this.updateSelectedOptions(this.selected);
        }
        if (!!changes.selected) {
            this.updateSelectedOptions(changes.selected.currentValue);
        }
    }

    onClickOutside(event) {
        // ignore icon clicks
        if (event.target.tagName === 'I') {
            return;
        }

        // check whether the click happened outside of this instance of multiselect
        if (!this.container.nativeElement.contains(event.target)) {
            // toggle component's state if it's open
            if (this.isOpen) {
                this.toggleOpen();
            }
        }
    }

    toggleOpen() {
        // won't allow opening if data is loading or has failed to load
        if (this.isLoading || this.hasError) {
            this.isOpen = false;
        } else {
            this.isOpen = !this.isOpen;
        }

        // emit changes on closing if dirty
        if (!this.isOpen && this.isDirty) {
            this.emitChanges();
            this.isDirty = false;
        }

        // emit blur event on closing
        if (!this.isOpen) {
            if (this.blur) {
                this.blur.emit();
            }
        }
    }

    toggleOption(i) {
        if (!this.keyProperty) {
            console.warn('Property `keyProperty` for `component-multiselect` is invalid, please check its value!', {keyProperty: this.keyProperty});
            return;
        }

        let optionId = i[this.keyProperty];
        //if (!optionId) {
        if (typeof optionId === typeof undefined) { //adjustment to recognize values with id = 0
            let keys = Object.keys(i).map(i => `"${i}"`).join(', ');
            console.warn(`Parameter \`keyProperty="${this.keyProperty}"\` supplied for \`component-multiselect\` is not a valid property for provided \`options\`! Valid keys are: ${keys}. #2`);
            return;
        }
        if(this.collabChecked){
            this.collabChecked = false;
        }
        if (!this.selectedOptions[optionId]) {
            if (this.multiple) {
                this.selectedOptions[optionId] = true;
            } else {
                // deselect all previously selected options if it isn't a multiple select
                this.selectedOptions = {
                    [optionId]: true
                };
                if (this.isOpen) {
                    setTimeout(() => {
                        this.toggleOpen();
                    }, 200);
                }
            }
        } else {
            this.selectedOptions[optionId] = false;
            delete this.selectedOptions[optionId];
        }
        this.isDirty = true;
        this.selectedOptions[1] = false;
        this.updateSelectedPlaceholder();
    }

    filterOptions(bLimit: boolean) {
        if (!this.keyProperty) {
            console.warn('Property `keyProperty` for `component-multiselect` is invalid, please check its value!', {keyProperty: this.keyProperty});
        }

        if (!this.displayValueProperty) {
            console.warn('Property `displayValueProperty` for `component-multiselect` is invalid, please check its value!', {displayValueProperty: this.displayValueProperty});
        }

        if (!this.options || !this.keyProperty || !this.displayValueProperty) {
            return;
        }

        const search = this.search.toLowerCase();
        var count = 0;
        try {
            this.filterredOptions = this.options.filter(i => {
                if (i[this.displayValueProperty]) {
                    const value = i[this.displayValueProperty].toLowerCase();
                    if (bLimit) {
                        return (value.indexOf(search) !== -1 && count++ < 5000);
                    } else {
                        return (value.indexOf(search) !== -1);
                    }
                } else {
                    let optionDp = i[this.displayValueProperty];
                    //if (!optionId) {
                    if (typeof optionDp === typeof undefined) { //adjustment to recognize values with id = 0
                        
                    const keys = Object.keys(i).map(i => `"${i}"`).join(', ');
                        console.warn(`Parameter \`displayValueProperty="${this.displayValueProperty}"\` supplied for \`component-multiselect\` is not a valid property for provided \`options\`! Valid keys are: ${keys}. #1`);
                    return false;
                }

                }
            });
        }
        catch (e) {
            console.log(e);
        }
    }

    updateSelectedOptions(selected: any[]) {
        if (typeof selected != typeof undefined && selected != null) {
            this.selectedOptions = selected
                .filter(el => _.some(this.options, someEl => {
                    return someEl[this.keyProperty]==el[this.keyProperty];
                }))
                .map(i => i[this.keyProperty])
                .reduce((obj, item) => {
                    obj[item] = true;
                    return obj;
                }, {});
            }
            this.updateSelectedPlaceholder();
    }

    updateSelectedPlaceholder() {
        let strSelected = '';
        this._translateService.get('USERS.SELECTED').subscribe(translate => {
            strSelected = translate;
        });

        let strOf = '';
        this._translateService.get('USERS.OF').subscribe(translate => {
            strOf = translate;
        });

        let strAll = '';
        this._translateService.get('LOGBOOK.COLLAB').subscribe(translate => {
            strAll = translate;
        });

        const selected = this.getSelectedOptions();
        if(this.collabChecked){
            this.selectedPlaceholder = `${strAll}`;
        }else if (selected.length === 0) {
            this.selectedPlaceholder = null;
        } else if (selected.length <= 5) {
            this.selectedPlaceholder = selected
                .map(i => i[this.displayValueProperty])
                .join(', ');        
        } else {
            this.selectedPlaceholder = `${selected.length} ${strOf} ${this.options.length} ${strSelected}`;
        }
    }

    getSelectedOptions() {
        try {
            const ids = Object.keys(this.selectedOptions).filter(i => !!this.selectedOptions[i]);
            const options = this.options.filter(i => ids.indexOf(i[this.keyProperty]) !== -1 || ids.indexOf(i[this.keyProperty].toString()) !== -1);
            return options;
        }
        catch (e) {
            console.log(e);
            return [];
        }
    }

    emitChanges() {     
        const selectedOptions = this.getSelectedOptions();
        this.selectionChanged.emit({selectedOptions});
        this.search = '';
        this.filterOptions(true);
        this.collabChecked = false;
    }
}
