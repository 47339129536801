import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UserImageService} from './services/user-image.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';

@Component({
    selector: 'component-user-image',
    templateUrl: './user-image.component.html',
    styleUrls: ['./user-image.component.css'],
})

export class UserImageComponent implements OnChanges {

    @Input() userPicture: string;
    @Output() changedImage: EventEmitter<any> = new EventEmitter<any>();
    codUser: number;

    constructor(private _service: UserImageService, private _prefService: UserPreferencesService, private _authService: AuthService) {
        this.userPicture = '';
        this.codUser = this._authService.getAuthenticatedUser().id;
        this.getUserPreferences(null);
    }

    private getUserPreferences(cb: any) {
        this._prefService.getUserPreferences(this.codUser).subscribe(result => {
                result = this._prefService.getArrayContent(result);
                let foundPicture = false;
                if (result.length > 0) {
                    result.forEach(preference => {
                        if (preference.str_pref_key == 'picture' && preference.str_pref_value != '') {
                            foundPicture = true;
                            this.validateUserPicture(preference.str_pref_value);
                            if (cb) {
                                cb(preference);
                            }
                        }
                    });
                }

                if ((cb) && (!foundPicture)) {
                    cb(null);
                }
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    validateUserPicture(userPicture: string): void {
        if (typeof userPicture == typeof undefined || userPicture == '') {
            return;
        }

        let objParam = {
            file_name: userPicture.split('\\').pop().split('/').pop() //get file name from full path
        };
        this._service.getUserImage(objParam).subscribe(data => {
                if (typeof data.content.str_img_name != typeof undefined && data.content.str_img_name != '') {
                    this.userPicture = environment().uploadGetFilesApiUrl + '/' + data.content.str_img_name;
                    if (this.changedImage) {
                        this.changedImage.emit(this.userPicture);
                    }
                }
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.validateUserPicture(this.userPicture);
        if (changes.userPicture.currentValue == '' && changes.userPicture.previousValue && changes.userPicture.previousValue.toString() == 'CD_INIT_VALUE') {
            this.getUserPictureFromLegacy();
        }
    }

    getUserPictureFromLegacy() {
        let $this = this;
        this.getUserPreferences(function (preference) {
            if (preference == null || preference.bol_change_preference <= 0) {
                let objParam = {
                    cod_user: $this._authService.getAuthenticatedUser().ambev_id
                };

                $this._service.getUserImageFromLegacy(objParam).subscribe(data => {
                        let userPreference = {
                            // cod_user_preference = 0
                            cod_user: $this._authService.getAuthenticatedUser().id,
                            str_pref_key: 'picture',
                            str_pref_value: data.content.str_name,
                            bol_change_preference: 0
                        };

                        $this._prefService.postPicture(userPreference).subscribe(res => {
                                if (res.cod <= 0) {
                                    console.log(res.str);
                                }
                                else {
                                    $this.userPicture = environment().uploadGetFilesApiUrl + '/' + data.content.str_name;
                                }
                            },
                            err => {
                                $this._authService.errCheck(err);
                                console.log(err);
                            }
                        );
                    },
                    err => {
                        $this._authService.errCheck(err);
                        console.log(err);
                    }
                );
            }
        });
    }
}
