import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../main/access/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from '../../../main/services/socket.service';
import { MeetingsService } from '../../services/meetings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '../../../main/services/upload.service';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'input-comment-chat',
    templateUrl: './input-comment-chat.component.html',
    styleUrls: ['./input-comment-chat.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class InputCommentChatComponent implements OnChanges {

    showError = false;
    errorMessage = '';
    strNewText: any;
    objUser: any;
    showEditor = true;
    showLinkModal: boolean = false;
    modalHyperlinkObject = {
        description: '',
        url: '',
        sameTab: false
    };
    linkImg: string = '../../../assets/images/link-45deg.svg';


    @Input() comment: any;
    @Input() codAction: any;
    @Input() codMeeting: any;
    @Input() canEditMessages: boolean;
    @Input() isEditing: boolean = true;
    @Output() stateReturn = new EventEmitter<Object>();

    public quillConfig={
        //toolbar: '.toolbar',
         toolbar: {
              theme: 'snow'
            }
      }

    constructor(private _authService: AuthService,
        private _translateService: TranslateService,
        private _socketService: SocketService,
        protected sanitizer: DomSanitizer,
        private _uploadService: UploadService,
        private _meetingsService: MeetingsService) {
        this.objUser = _authService.getAuthenticatedUser();
    }

    // tslint:disable-next-line: use-life-cycle-interface
    ngOnInit() {
        this._translateService.onLangChange.subscribe(() => this.loadLabelsWithTranslations());

        setTimeout(() => {
            this.setEditingComent();
        }, 300);
    }

    loadLabelsWithTranslations() {
        this.showEditor = false;
        setTimeout(() => {
            this.showEditor = true;
        }, 300);
    }

    setEditingComent() {
        this.strNewText = this.comment.str_text;
    }

    revertComment() {
        this.strNewText = this.comment.str_text;
        this.isEditing = false;
        this.showLinkModal = false;
    }

    saveComment() {
        this.showLinkModal = false;
        if (!this.strNewText) {
            return false;
        }

        const objParam = {
            cod_chat_messages: this.comment.cod_chat_messages,
            cod_meeting: this.codMeeting,
            cod_user: this.objUser.id,
            str_text: this.strNewText
        };

        this._putComment(objParam);
    }

    private _putComment(objParam: any) {
        this._meetingsService.putMeetingMessage(objParam).subscribe(res => {
                this.comment.str_text = this.strNewText;
                this._socketService.refreshMeetingMessage({cod_meeting: this.codMeeting});
                this.stateReturn.emit(null);
        }, err => {
            const e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        } );
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.comment != typeof undefined) {
            if (this.comment.is_image) {
                this.comment.str_image = this.sanitizer.
                bypassSecurityTrustResourceUrl(`${environment().uploadGetFilesApiUrl}/${changes.comment.currentValue.str_text}`);
            } else if (this.comment.is_pdf) {
                this.comment.str_image = this.sanitizer.
                bypassSecurityTrustResourceUrl(`${environment().uploadGetFilesApiUrl}/${changes.comment.currentValue.str_text}`);
            } else {
                this.strNewText = changes.comment.currentValue.str_text;
            }
        }
    }

    getFileName(strFilePath: string) {
        const arr_temp = strFilePath.split('/');
        return arr_temp[arr_temp.length - 1];
    }

    downloadFile() {
        const fileUrl = `${environment().uploadGetFilesApiUrl}/${this.strNewText}`;
        this._uploadService.downloadFile(fileUrl).subscribe(res => {
                const blob = new Blob([res]);

                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = this.getFileName(this.strNewText);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }
    openLinkModal() {
        this.showLinkModal = true;
    }

    addHyperlink(hyperlinkTag) {
        this.strNewText += `${hyperlinkTag}`;
        this.showLinkModal = false;
    }

    closeLinkModal() {
        this.showLinkModal = false;
    }

}
