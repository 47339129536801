import { Injectable } from "@angular/core";
import { AuthMainService } from "../../services/auth-main.service";
import { ListEquipmentParams } from "../models/list-equipment-params.model";


@Injectable()
export class ListEquipmentSelectService {
  constructor(private _mainService: AuthMainService) {}

  getListEquipments(params: ListEquipmentParams) {
    const path = '/filter-list-equipments';
    return this._mainService.post(path, params);
}
}
