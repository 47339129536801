import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../main/access/services/auth.service';
import {RoutinesService} from '../services/routines.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from "../models/select_pagination.model";
import {NotificationService} from "./../../main/services/notification.service";

@Component({
    selector: 'app-routines-list',
    templateUrl: './routines-list.component.html',
    styleUrls: ['./routines-list.component.css']
})
export class RoutinesListComponent implements AfterViewInit, OnDestroy {

    list: any;
    paramsSubscription: Subscription = Subscription.EMPTY;
    searchTerm: string;
    searchTimer: any;
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    isLoading: boolean = true;
    codRoutine: number = 0;
    createRecord: boolean = false;
    procedureImportingRoutines: string = 'LOGBOOKS_sp_imp_routines';
    fieldsExcelSheet: string = '';
    codUser: number;
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    deleteRoutineId: number = 0;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    editSuccessMessage: boolean = false;
    isLoadingFile: boolean = false;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;
    str_title_button: string;
    downloadCsvExample: any = {};

    showErrorMessage: boolean;
    emitSuccessMessage: boolean;
    emitErrorMessage: boolean;
    
    dsc_privilege = 'ADMIN_LOGBOOKS_ROUTINES';

    constructor(
        public routineService: RoutinesService, 
        private _authService: AuthService, 
        private _translateService: TranslateService,
        private _notify: NotificationService
    ) {
        this.searchTerm = '';
        this.showError = false;
        this.errorMessage = '';
        this.list = {
            routines: []
        };


        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                this.str50perpage = '50 ' + translate;
                this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                    this.str100perpage = '100 ' + translate;
                    this.resultsPerPage = [
                        new SelectPagination(10, this.str10perpage),
                        new SelectPagination(50, this.str50perpage),
                        new SelectPagination(100, this.str100perpage),
                    ];
                });
            });
        });

        this._translateService.get('GENERAL.UPLOAD_FILE').subscribe(translate => {
            this.str_title_button = translate;
        });

        this.pageIndex = 1;
        this.totalPages = [];

        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;

        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;
        this.isLoadingFile = false;
    }

    ngAfterViewInit() {
        this._search();

        //Get all pillars to put into sheet
        let objParam = {   
            'cod_user': this._authService.getAuthenticatedUser().id
        };
        this.routineService.getPillars(objParam).subscribe(data => {
                this.fieldsExcelSheet = 'cod_routines,str_name,str_description,str_zone';
                let pillars = this.routineService.getArrayContent(data);
                for (let i = 0; i < pillars.length; i++) {
                    this.fieldsExcelSheet += ',"' + pillars[i].str_name.split(' ').join('_') + '"';
                }
            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        }
        catch (err) {
            //safe!
            console.log(err);
        }
    }

    public editRoutine(id, reload) {
        if (id == -1) {
            id = 0;
            this.editSuccessMessage = true;
            this.emitSuccessMessage = true;
            this.emitErrorMessage = false;
        } else {
            this.editSuccessMessage = false;
            this.emitSuccessMessage = false;
            this.emitErrorMessage = false;
        }
        this.codRoutine = id;
        this.createRecord = !reload;
        if (reload) {
            this._search();
        }
    }

    public toggleDeleteRoutine(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteRoutineId = id;
    }

    public confirmDeleteRoutine() {
        let objParam = {
            'cod_routine': this.deleteRoutineId,
            'cod_user': this.codUser
        };
        this.routineService.deleteRoutine(objParam).subscribe(data => {
                this.emitSuccessMessage = true;
                this.emitErrorMessage = false;
                this._notify.success({text: 'LOGBOOK.ROUTINE_DELETED', translate: true});
                this.search();
            }, err => {
                this.emitSuccessMessage = false;
                this.emitErrorMessage = true;
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
        this.awaitConfirmDelete = false;
        this.deleteRoutineId = 0;
    }

    private _search() {
        this.isLoading = true;
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        let objParam = {
            'cod_routine': 0,
            'term': this.searchTerm,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected),
            'dsc_page_privilege': 'ADMIN_LOGBOOKS_ROUTINES',
            'cod_user': this.codUser
        };
        this.routineService.getRoutineList(objParam).subscribe(data => {
                this.list = {
                    routines: this.routineService.getArrayContent(data)
                };

                this.list.routines.map(item => {
                    item.arr_checklists = (item.str_checklists === null || item.str_checklists === "")  ? [] : item.str_checklists.split(',');
                });               

                let str_cod_rotina = '';
                let str_nom_rotina = '';
                let str_dsc_rotina = '';
                let str_zone = '';

                this._translateService.get('LOGBOOK.COD_ROUTINE').subscribe(translate => {
                    str_cod_rotina = translate;
                    this._translateService.get('LOGBOOK.STR_ROUTINE').subscribe(translate1 => {
                        str_nom_rotina = translate1;
                        this._translateService.get('LOGBOOK.DSC_ROUTINE').subscribe(translate2 => {
                            str_dsc_rotina = translate2;
                            this._translateService.get('USERS.WORLD').subscribe(translate3 => {
                                str_zone = translate3;
                                
                                this.downloadCsvExample = {
                                    cod_user: this._authService.getAuthenticatedUser().id,
                                    api_url: this._reportsApiUrl,
                                    str_proc_exec: 'generate_ex_blank_files',
                                    arr_proc_params_search: [null],
                                    arr_headers: [
                                        str_cod_rotina,
                                        str_nom_rotina,
                                        str_dsc_rotina,
                                        str_zone,

                                    ],

                                    //Validar informações corretas mouts (InteractionLog Mouts routines examples)
                                    arr_headers_2: [
                                        '',
                                        'InteractionLog - Mouts routines example',
                                        'This is a example to import routines',
                                        'Middle America',
                                    ],

                                    name_of_file_to_download: 'template_import_logbooks_',
                                    str_column_dynamic: ''
                                };

                                this.downloadCsvSetup = {
                                    cod_user: this.codUser,
                                    api_url: this._reportsApiUrl,
                                    str_proc_exec: 'reports_ex_routines',
                                    arr_proc_params_search: [
                                        this.codUser,
                                        this.dsc_privilege,
                                        this.searchTerm
                                    ],
                                    arr_headers: [
                                        str_cod_rotina,
                                        str_nom_rotina,
                                        str_dsc_rotina,
                                        str_zone,
                                    ],
                                    name_of_file_to_download: 'routines_',
                                    str_column_dynamic: 'str_columns'
                                };
                            });
                        });
                    });
                });

                this.showErrorMessage = this.emitErrorMessage;
                this.emitSuccessMessage = null;
                this.emitErrorMessage = null;

                this.isLoading = false;

                this.maxPage = Math.ceil(data.content.count / this.resultsPerPageSelected);
                this.totalPages = this.pagination(this.pageIndex, this.maxPage);

            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
                this.isLoading = false;
            }
        );
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.pageIndex = page;
        this.isLoading = true;
        this.search();
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._notify.error({text: err, translate: true});
        /*this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });*/
    }

    async messageImported(response: any) {
        this.isLoadingFile = false;
        response = JSON.parse(response);
        if (response?.errors) {
            this.importError(JSON.stringify(response))
        } else {
            this._notify.success({ text: 'LOGBOOK.MESSAGE_IMPORTED_SUCCESS', translate: true });
        }
        this.search();
    }

    startLoading() {
        this.isLoadingFile = true;
    }
    _getImportErrorMessage(error: string): string {
        const self = this
        if(self.findTerm(error, 'COD_ROUTINE')) return 'LOGBOOK.ROUTINE_CODE_REQUIRED_MSG';
        if(self.findTerm(error, 'STR_NAME')) return 'LOGBOOK.REQUIRED_STR_NAME';
        if(self.findTerm(error, 'STR_DESCRIPTION')) return 'LOGBOOK.REQUIRED_STR_DESCRIPTION';
        if(self.findTerm(error, 'STR_ZONE')) return 'LOGBOOK.WORLD_REQUIRED_MSG';
        return 'LOGBOOK.MESSAGE_IMPORTED_ERROR';
    }
    findTerm(msg, part) {
        return msg.includes(part)
    }
    importError($event) {
        this.isLoadingFile = false;
        $event = JSON.stringify($event)
        if ($event && $event.toString().toLowerCase().includes('file') && $event.toString().toLowerCase().includes('type')) {
            this._notify.error($event);
            return
        }
        if (!$event.includes('Row length does not match headers')) {
            const errorText = this._getImportErrorMessage($event);
            if (errorText != null)
                this._notify.error({ text: errorText, translate: true });
        } else {
            this._notify.error({ text: 'LOGBOOK.MESSAGE_IMPORTED_ERROR', translate: true });
        }
    }
    messageError($event) {
        this.isLoadingFile = false;
        let errorMessage = $event.split('!'); //Error uploading file!Error importing routines - STR_DESCRIPTION
        let errorCodeTmp = (errorMessage.length > 1)
                                ? errorMessage[1]
                                : errorMessage[0].split('-'); //Error importing routines - STR_DESCRIPTION
        //let errorMsgNotice = errorCodeTmp[0]; //Error importing routines
        let errorCode = (errorCodeTmp.length > 1)
                            ? errorCodeTmp[1].trim()
                            : errorCodeTmp[0].trim(); //STR_DESCRIPTION

        let errorMessageFinal = '';
        if (errorCode == 'STR_NAME') {
            this._translateService.get('LOGBOOK.REQUIRED_STR_NAME').subscribe(translate => {
                errorMessageFinal = translate;
            });
        }

        if (errorCode == 'STR_DESCRIPTION') {
            this._translateService.get('LOGBOOK.REQUIRED_STR_DESCRIPTION').subscribe(translate => {
                errorMessageFinal =  translate + ` 
                ` + (errorCodeTmp.length > 2)
                        ? errorCodeTmp[2].trim()
                        : (errorCodeTmp.length > 1)
                            ? errorCodeTmp[1].trim()
                            : errorCodeTmp[0].trim();
            });
        }

        this._translateService.get('LOGBOOK.MESSAGE_IMPORTED_ERROR').subscribe(translate => {
            if(errorMessageFinal == ''){
                errorMessageFinal = translate + ` - 
                ` + errorMessage;
            }else{
                errorMessageFinal = translate + ` - 
                ` + errorMessageFinal;
            }

            this._showErrorMessage(errorMessageFinal);
        });
    }

    hideMessage() {
        this.showErrorMessage = false;
    }
    
}
