import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, firstValueFrom} from 'rxjs';


import {UserPreferencesService} from '../services/user-preferences.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {UserPreference} from '../models/user-preference.model';
import {SocketService} from '../../main/services/socket.service';
import {UsersProfilesService} from '../../admin/services/users-profiles.service';
import { NotificationService } from 'app/main/services/notification.service';
// import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.css']
})
export class UserPreferencesComponent implements OnInit {

    cod_user;
    getPreferences;
    obj_connections = new Subscription();
    arr_notifications: any;
    urlBackground: string;
    authenticatedPicture: string;

    toogleBackgroundHiddenClass: string;
    tooglePictureHiddenClass: string;
    toogleTimeZoneHiddenClass: string;
    authenticatedUserName: string;
    user_notification_tab: string;
    user_notification: boolean;
    user_preferences: boolean;
    timezone = [];
    cod_tz: number;
    arr_email_notify: any = [];
    email_periodicity_toggled: boolean = false;
    date_format_toggled: boolean = false;
    date_formats: any = [];
    dateFormatSelected: any = 'MM/DD/YYYY';
    decimal_delimiter: any = [];
    current_date_format: any;

    isChrome: boolean;
    decimal_delimiter_toggled: any = false;

    showReloadWindow: boolean = false;
    hasUserPreference: any = {}
    defaultLang = {
        'en': ['en','en'],
        'pt': ['pt','pt'],
        'es': ['es','es'],
        'nl': ['nl','nl'],
        'uk': ['uk','ua'], //0: cod_id, 1: str_name
        'ua': ['uk','ua'], //0: cod_id, 1: str_name
        'ru': ['ru','ru'],
        'de': ['de','de'],
        'fr': ['fr','fr'],
        'ko': ['ko','ko'],
        'zh': ['zh','zh'],
        'vi': ['vi','vi']
    };
    selectChoosenName: string = '';
    choosenName: { key: string; value: string; name: any; }[];
    displayMessage: string;
    showChangeName: boolean = false;
    auxChoosenName: any;

    constructor(
        private readonly _userPreferencesService: UserPreferencesService,
        private readonly _router: Router,
        private readonly _authService: AuthService,
        private readonly _socketService: SocketService,
        private readonly _notify: NotificationService,
        // private msalService: MsalService,
        public translate: TranslateService,
        private readonly _usersProfilesService: UsersProfilesService) {
        this.cod_user = this._authService.getAuthenticatedUser().id;
        this.authenticatedUserName = '';
        this.urlBackground = 'assets/images/background-images/default.jpg';
        //this.authenticatedPicture = 'assets/images/icon_user_default.png';
        this.authenticatedPicture = '';

        this.toogleBackgroundHiddenClass = 'hidden';
        this.tooglePictureHiddenClass = 'hidden';
        this.toogleTimeZoneHiddenClass = 'hidden';

        this.user_notification_tab = 'actions';
        this.user_notification = false;
        this.user_preferences = false;
        this.date_formats = ['DD/MM/YYYY', 'MM/DD/YYYY'];
        this.decimal_delimiter = [',', '.'];
        this.hasUserPreference = {
            dateFormat: false,
            timezone: false,
            systemLang: false
        }

        this.arr_notifications = [];
        this.loadChoosenName();
        //this.getCddListByUser();
    }

    ngOnInit() {

        this.authenticatedUserName = this._authService.getAuthenticatedUser().name;

        /*this.arr_email_notify.push('Never');
        this.arr_email_notify.push('Daily');
        this.arr_email_notify.push('Weekly');*/
        this.arr_email_notify = ['USERS.EMAIL_NEVER','USERS.EMAIL_DAILY','USERS.EMAIL_WEEKLY'];

        this.loadUserPreferences(false);
        //console.log(this.arr_email_notify);
        
        

        //SUBSCRIBE TO USERS NOTIFICATIONS
        this.obj_connections.add(
            this._socketService.subscribeToNotifications().subscribe(result => {
                let notifications: any;
                notifications = result;

                if(notifications.cod_user == this.cod_user)
                this.loadNotifications();
            })
        );
        this.loadNotifications();

        //this.isChrome = !!window['chrome']&& !!window['chrome']['webstore'];
        this.isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        let isIOS = navigator.userAgent.match('CriOS');
        let isEdge = navigator.userAgent.match('Edg');
    
        if (!this.isChrome && !isIOS && !isEdge) {
            alert('Caution!!! To have the best experience with IAL on your computer, use Google Chrome browser!');
        }

        this.obj_connections.add(
            this._socketService.subscribeUserPrivileges().subscribe(user => {
                if (this.cod_user == user) {
                    this._usersProfilesService.postRenewUserPrivileges({ cod_user: this.cod_user }).subscribe(data => {
                        if (data.code != 666) {
                            this._authService.setAuthenticatedUser(data.content);
                            this.translate.get('USERS.PRIVILEGES_RENEW_MSG').subscribe((translation) => {
                                this.displayMessage = translation;
                                this.showReloadWindow = true;
                            })
                        }
                    });
                }
            })
        );

    }
        
    // async getCddListByUser() {
    //     try {
    //         await this._callsService.getCallsCddByUser(this._authService.getAuthenticatedUser().id).subscribe(data => {
    //             const arr_calls_by_user = this._callsService.getArrayContent(data);
    //             const obj_user = this._authService.getAuthenticatedUser();
    //             obj_user.hasCddList = false;
    //             if (arr_calls_by_user.length > 0) {
    //                 obj_user.hasCddList = true;
    //             }
    //             this._authService.setAuthenticatedUser(obj_user);
    //           });
    //     } catch (e) {
    //         console.log('getCddListByUser', e);
    //     }
    // }

    toogleModal(id: string) {
        switch (id) {
            case 'modal-change-background':
                this.toogleBackgroundHiddenClass = '';
                this.tooglePictureHiddenClass = 'hidden';
                this.toogleTimeZoneHiddenClass = 'hidden';
                break;
            case 'modal-change-picture':
                this.toogleBackgroundHiddenClass = 'hidden';
                this.tooglePictureHiddenClass = '';
                this.toogleTimeZoneHiddenClass = 'hidden';
                break;
            case 'modal-selected-time-zone':
                this.toogleBackgroundHiddenClass = 'hidden';
                this.tooglePictureHiddenClass = 'hidden';
                this.toogleTimeZoneHiddenClass = '';
            break;
        }

        this.toggleUserPreferencesSubMenu();
    }

    closeModal(id: string) {
        switch (id) {
            case 'modal-change-background':
                this.toogleBackgroundHiddenClass = 'hidden';
                break;
            case 'modal-change-picture':
                this.tooglePictureHiddenClass = 'hidden';
                break;
            case 'modal-selected-time-zone':
                this.toogleTimeZoneHiddenClass = 'hidden';
            break;
        }
    }

    changeUserNotificationTab(str_tab) {
        this.user_notification_tab = str_tab;
    }

    closeUserNotifications(event) {
        if (event.srcElement.id != 'user-notifications-menu') {
            this.user_notification = false;
        }
    }

    closeUserPreferences(event) {

        if (event.srcElement.id != 'user-preferences-menu-button') {
            this.user_preferences = false;
        }
    }

    toggleUserPreferencesSubMenu() {

        this.user_notification = false;
        this.user_preferences = !this.user_preferences;
    }

    toggleUserNotificationsSubMenu() {
        this.user_preferences = false;
        
        this.user_notification = !this.user_notification;
        if(this.user_notification){
            this.setActionNotificationVisible();
        }
    }

    logOut() {
        this._authService.logout().subscribe({
            next: () => {
                this.toggleUserPreferencesSubMenu();
                // this.msalService.logoutRedirect();
                // this._authService.deleteAuthenticatedUser();
          }
        });
      };

    /*
    SAML

    logOut() {
        this._authService.logout().subscribe(data => {
            if (data.type === 'error') {
                alert(data.content.message);
                return;
            }

            // this.toggleUserPreferencesSubMenu();
            // this._authService.deleteAuthenticatedUser();
            // this._router.navigate(["/access"]);

            let $this = this;
            let win = window.open(data.content.url, "_blank");            
            setTimeout(function() {
                win.close();
                console.log('Closed');
                $this.toggleUserPreferencesSubMenu();
                $this._authService.deleteAuthenticatedUser();
                window.location.replace('');
              }, 5000);    
        });
    }*/

    loadUserPreferences(forceUpdate = false) {

        this.hasUserPreference = {
            dateFormat: false,
            timezone: false,
            systemLang: false
        }

        // this.cod_user
        if (!this._userPreferencesService.hasLocalPicture() ||
            !this._userPreferencesService.hasLocalLanguage() ||
            !this._userPreferencesService.hasLocalBackground() ||
            !this._userPreferencesService.hasLocalTimeZone() ||
            !this._userPreferencesService.hasLocalDateFormat() ||
            forceUpdate
        ) {
            this._userPreferencesService.getUserPreferences(this.cod_user).subscribe(result => {
                    result = this._userPreferencesService.getArrayContent(result);
                    if (result.length > 0) {

                        // result.content.results.forEach(preference => {
                        result.forEach(preference => {
                            if (preference.str_pref_key == 'language') {
                                this.hasUserPreference.systemLang = true;
                                this._userPreferencesService.setLocalLanguage(preference.str_pref_value);
                                var userPref = this.defaultLang[preference.str_pref_value];
                                this.translate.use(userPref && userPref.length > 0 ? userPref[1] : 'en');
                            }
                            else if (preference.str_pref_key == 'picture') {
                                if (forceUpdate) {

                                    this.authenticatedPicture = environment().uploadGetFilesApiUrl + '/' + preference.str_pref_value;
                                }

                            }
                            else if (preference.str_pref_key == 'background') {
                                this.urlBackground = preference.str_pref_value;
                                this._userPreferencesService.setLocalBackground(this.urlBackground);
                            } else if (preference.str_pref_key == 'csvdelimiter') {
                                this._userPreferencesService.setLocalCsvDelimiter(preference.str_pref_value);
                            }
                            else if (preference.str_pref_key == 'date-format') {
                                this.hasUserPreference.dateFormat = true;
                                this._userPreferencesService.setLocalDateFormat(preference.str_pref_value);
                            }
                            else if(preference.str_pref_key == 'email-notify-periodicity') {
                                this._userPreferencesService.setLocalEmailNotifyPeriodicity(preference.str_pref_value);
                            }
                            else if(preference.str_pref_key == 'decimal-delimiter') {
                                this._userPreferencesService.setLocalDecimalDelimiter(preference.str_pref_value);
                            }
                            else if(preference.str_pref_key == 'timezone') {
                                this.hasUserPreference.timezone = true;
                                this._userPreferencesService.setLocalTimeZone(preference.str_pref_value);
                                this.timezone = [{cod_tz: preference.str_pref_value}];
                            }
                            
                        });

                        if (!this._userPreferencesService.hasLocalPicture()) {
                            this._userPreferencesService.setLocalPicture(this.authenticatedPicture);
                        }
                        if (!this._userPreferencesService.hasDecimalDelimiter()) {
                            this._userPreferencesService.setLocalDecimalDelimiter('.');
                        }
                        if (!this._userPreferencesService.hasLocalLanguage() || !this.hasUserPreference.systemLang) {
                            this._userPreferencesService.setLocalLanguage('en');
                            this.translate.use('en');
                            const userPreference = new UserPreference();
                            userPreference.cod_user = this._authService.getAuthenticatedUser().id;
                            userPreference.str_pref_value = 'en';

                            this._userPreferencesService.postLanguage(userPreference).subscribe(result1 => {
                                if (result1.code === 666) {
                                    console.log('Error seting language', result1);
                                }
                            }, err => {
                                    this._authService.errCheck(err);
                                    console.log(err);
                                }
                            );
                        }
                        this.setDefaultLanguage();
                        if (!this._userPreferencesService.hasLocalDateFormat() || !this.hasUserPreference.dateFormat) {
                            this.changeDateFormat('MM/DD/YYYY');
                        }
                        if (!this._userPreferencesService.hasLocalTimeZone() || !this.hasUserPreference.timezone) {
                            this.changeTimezone(-(new Date().getTimezoneOffset() / 60));
                        }
                        
                        this.dateFormatSelected = this.date_formats[this.getDateFormat()];

                        if (!this._userPreferencesService.hasLocalCsvDelimiter()) {
                            this._userPreferencesService.getCsvDelimiter(this._authService.getAuthenticatedUser().id).subscribe((data) => {
                                if(data && data.content && data.content.results && data.content.results.str_pref_value){
                                    this._userPreferencesService.setLocalCsvDelimiter(data.content.results.str_pref_value);
                                } else {
                                    this._userPreferencesService.setLocalCsvDelimiter(',');
                                }
                            });
                        }
                        if (!this._userPreferencesService.hasLocalBackground()) {
                            this._userPreferencesService.setLocalBackground(this.urlBackground);
                        }
                        if(!this._userPreferencesService.hasLocalEmailNotifyPeriodicity()){
                            this._userPreferencesService.setLocalEmailNotifyPeriodicity('0');
                        }

                    } else {
                        this._userPreferencesService.setLocalBackground(this.urlBackground);
                        this._userPreferencesService.setLocalPicture(this.authenticatedPicture);
                        this._userPreferencesService.setLocalLanguage('en');
                        this._userPreferencesService.setLocalEmailNotifyPeriodicity('0');
                        this._userPreferencesService.setLocalDecimalDelimiter('.');
                        this.changeDateFormat('MM/DD/YYYY');
                        this.changeTimezone(-(new Date().getTimezoneOffset() / 60));
                        this.translate.use('en');
                        this.setDefaultLanguage();

                        // INSERT DEFAULT
                        const userPreference = new UserPreference();
                        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
                        userPreference.str_pref_value = 'en';

                        this._userPreferencesService.postLanguage(userPreference).subscribe(result1 => {
                            if (result1.code === 666) {
                                console.log('Error seting language', result1);
                            }
                        }, err => {
                                this._authService.errCheck(err);
                                console.log(err);
                            }
                        );
                    }
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        } else {
            this.urlBackground = this._userPreferencesService.getLocalBackground().toString();
            if (this._userPreferencesService.getLocalPicture().toString() != '') {
                this.authenticatedPicture = this._userPreferencesService.getLocalPicture().toString();
            }
            const localLanguage = this._userPreferencesService.getLocalLanguage();
            this.dateFormatSelected = this.date_formats[this.getDateFormat()];
            this.timezone = [{cod_tz: this._userPreferencesService.getLocalTimeZone()}];
        }
    }

    setDefaultLanguage() {
        //Set default language at first time because lang flag was "undefined" when open change language menu
        if (typeof this.translate.currentLang == typeof undefined || this.translate.currentLang == '') {
            if (this._userPreferencesService.hasLocalLanguage()) {
                this.translate.currentLang = this._userPreferencesService.getLocalLanguage();
            }
            else {
                this.translate.currentLang = this.translate.getDefaultLang();
            }
            this.translate.setDefaultLang(this.translate.currentLang ? this.translate.currentLang : 'en');
            var userPref = this.defaultLang[this.translate.currentLang];
            this.translate.currentLang = userPref && userPref.length > 0 ? userPref[1] : 'en';
        }
    }

    changeTimezone(timezone) {
        let obj_timezone = {
            cod_user: this.cod_user,
            val_timezone: timezone
        };
        this._userPreferencesService.postUserTimezone(obj_timezone).subscribe(result => {
            if (result.code === 666) {
                console.log('Error seting timezone',result);
            } else {
                this.timezone = [{cod_tz: obj_timezone.val_timezone}];
                this._userPreferencesService.setLocalTimeZone(obj_timezone.val_timezone);
            }
        }, err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    changeLanguage(language) {
        var userPref = this.defaultLang[language];
        const userPreference = new UserPreference();
        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        userPreference.str_pref_value = userPref && userPref.length > 0 ? userPref[0] : 'en' //userPref[0] value to be send to DB

        this._userPreferencesService.postLanguage(userPreference).subscribe(data => {
            if (data.code === 666) {
                alert(data.content.message);
            }

            this._userPreferencesService.deleteLanguage();
            this._userPreferencesService.setLocalLanguage(userPref && userPref.length > 0 ? userPref[0] : 'en');
            this.translate.use(userPref && userPref.length > 0 ? userPref[1] : 'en'); //userPref[1] value to be used by frontend to display the translation correctly
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    changeDelimiter(delimiter) {
        const userPreference = new UserPreference();
        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        userPreference.str_pref_value = delimiter;

        this._userPreferencesService.postCsvDelimiter(userPreference).subscribe(data => {
                if (data.code === 666) {
                    alert(data.content.message);
                }

                this._userPreferencesService.setLocalCsvDelimiter(delimiter);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    getCsvDelimiter() {
        return this._userPreferencesService.getLocalCsvDelimiter();
    }

    loadNotificationMeeting(action_notification) {             
        this._router.navigate(['/meetings-show/' + action_notification.cod_meeting], { queryParams: { action: action_notification.cod_action } });
        this.user_notification = false;
    }

    //setActionNotificationVisible(action_notification) {       
    
    setActionNotificationVisible() {        
        // action_notification.cod_usu_alt = this._authService.getAuthenticatedUser().id;
        // this._socketService.setActionNotificationVisible(action_notification);
        this._userPreferencesService.postActionNotificationVisible({"cod_usu":this._authService.getAuthenticatedUser().id}).subscribe(data => {
            if (data.code === 888) {
                this.loadNotifications();
            }
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });        
    }

    changedImage(image) {
        this.authenticatedPicture = image;
        this._userPreferencesService.setLocalPicture(this.authenticatedPicture);
    }

    loadNotifications(){
        if(this.cod_user != 0 && this.cod_user){
            this._userPreferencesService.getUsersNotifications(this.cod_user, environment().uploadGetFilesApiUrl + '/').subscribe(result => {

                this.arr_notifications = result.content.results;
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        }
    
    }

    changeEmailNotify(emailPeriodicity, i) {
        const userPreference = new UserPreference();
        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        userPreference.str_pref_value = i;
        

        this._userPreferencesService.setEmailNotifyPeriodicity(userPreference).subscribe(data => {
                if (data.code === 666) {
                    alert(data.content.message);
                }

                this._userPreferencesService.setLocalEmailNotifyPeriodicity(i);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    changeDateFormat(item) {
        const userPreference = new UserPreference();
        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        userPreference.str_pref_value = item;

        this._userPreferencesService.setDateFormat(userPreference).subscribe(data => {
            if (data.code === 666) {
                console.log(data.content.message);
            }
            this._userPreferencesService.setLocalDateFormat(item);
            location.reload();
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    changeDecimalDelimiter(item) {
        const userPreference = new UserPreference();
        userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        userPreference.str_pref_value = item;

        this._userPreferencesService.setDecimalDelimiter(userPreference).subscribe(data => {
                if (data.code === 666) {
                    console.log(data.content.message);
                }

                this._userPreferencesService.setLocalDecimalDelimiter(item);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    getEmailNotifyPeriodicity() {
        return this._userPreferencesService.getLocalEmailNotifyPeriodicity();
    }

    getDateFormat() {
        let index;
        this.current_date_format = this._userPreferencesService.getLocalDateFormat();
        this.current_date_format = (!this.current_date_format) ? 'MM/DD/YYYY' : this.current_date_format ;
        this.date_formats.map((item, i) => {
            if (item === this.current_date_format) {
                index = i;
            }
        });
        return index;
    }

    getDecimal(){
        return this._userPreferencesService.getLocalDecimalDelimiter();
    }

    toggleOpenEmailNotify(){
        this.email_periodicity_toggled = !this.email_periodicity_toggled;
    }

    toggleDecimalDelimiter(){
        this.decimal_delimiter_toggled = !this.decimal_delimiter_toggled;
    }

    toggleOpenDateFormat() {
        this.date_format_toggled = !this.date_format_toggled;
    }

    modalConfirmAction(isChangeName) {
        if(isChangeName){ //check if its Reload Warning or ChooseName Modal
            let params = {
                cod_user: this.cod_user,
                str_key: this.auxChoosenName.key
            };
            this._userPreferencesService.changeChoosneName(params).subscribe({
                next: data =>{ //After successful endpoint, update authenticatedUser name and component variables, close modal and clear aux var
                    let upperName: String = this.auxChoosenName.name; 
                    this.selectChoosenName = this.auxChoosenName.key;
                    let authUser = this._authService.getAuthenticatedUser();
                    this.authenticatedUserName, authUser.name  = upperName.toUpperCase();
                    this._authService.setAuthenticatedUser(authUser);
                    this.showChangeName = false;
                    this.auxChoosenName = undefined;
                    window.location.reload(); //page will reload and fetch new changes
                },
                error: err => {
                    console.log(err);                    
                    this.showChangeName = false;
                    this._notify.error('GENERAL.CHANGE_NAME_ERROR_MSG');
                    this.auxChoosenName = undefined;
                }
            })
        }else{
            window.location.reload();
            this.showReloadWindow = false;
        }
    }

    modalCancelAction() {
        this.showReloadWindow, this.showChangeName = false;
        
    }

    changeChoosenName(choosenName){
        this.auxChoosenName = choosenName;
        if(!choosenName?.name){
            console.log("Choosen Name", choosenName);
            this.translate.get('GENERAL.CHOOSEN_NAME_EMPTY').subscribe((translation) =>{
                this._notify.error(translation);
            });
        }
        else{
            this.translate.get('GENERAL.CONFIRM_CHANGE_NAME').subscribe((translation) => {
                this.displayMessage = translation + ' ' + choosenName.name + '?';
                this.showChangeName = true;
            });
        } 
    }

    async loadChoosenName(){
        let legalTranslation = await firstValueFrom(this.translate.get('GENERAL.LEGAL')); 
        let preferredTranslation = await firstValueFrom(this.translate.get('GENERAL.PREFERRED')); 
        this._userPreferencesService.getChoosenName(this.cod_user).subscribe({
            next: (data) => {
                let results = data.content.results;
                this.selectChoosenName = results.str_pref_value;
                
                this.choosenName = [{ key: 'legal', value: legalTranslation, name: results.str_legal_name },{key: 'preferred', value: preferredTranslation, name: results.str_preferred_name}];
            },
            error: (err) => {
                console.log(err);                
                this._notify.error({text: 'GENERAL.FAIL_TO_FETCH', translate: true});
            },

        })
    }

}
