
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../main/access/services/auth.service';
import { FormsModule } from '@angular/forms';
import { MeetingsService } from '../services/meetings.service';
import { catchError, debounceTime, finalize, forkJoin, of, Subject, switchMap } from 'rxjs';
import { NotificationService } from '../../main/services/notification.service';
import { MeetingParticipant, MeetingPresence } from '../services/types/response-types';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { environment } from '../../../environments/environment';
import { UploadService } from '../../main/services/upload.service';

type MeetingPresenceItem = MeetingPresence & {
  isEditable: boolean;
  isSyncing: boolean;
}

type ExportAttendanceParams = {
  cod_user: number;
  arr_proc_params_search: (number | string)[];
  arr_headers: string[];
  name_of_file_to_download: string;
};

@Component({
  selector: 'app-meetings-attendance',
  templateUrl: './meetings-attendance.component.html',
  styleUrls: ['./meetings-attendance.component.css'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ClbModalModule
  ]
})
export class MeetingsAttendanceComponent implements OnInit {
  @Input('open') isOpen: boolean = false;
  @Input('cod_meeting') current_cod_meeting: number;
  @Input('meeting_name') current_meeting_name: string;

  @Output() closeAttendance = new EventEmitter<any>();

  current_user_id: number;
  isCurrentUserOwner: boolean = false;

  now: Date = null;
  minDate: string = null;
  maxDate: string = null;
  todayStr: string = null;
  yesterdayStr: string = null;

  participants: MeetingParticipant[] = [];
  datesAndPresences: MeetingPresenceItem[] = [];
  attendanceHashmap: Record<number, Set<number>> = {};

  isConfirmationModalOpen: boolean = false;
  deleteConfirmationDateCodMeetingsPresences: number = null;

  minExportDate: Date = null;
  maxExportDate: Date = null;
  isExportModalOpen: boolean = false;
  exportDateFrom: string = null;
  exportDateTo: string = null;
  isLoadingDownloadExport: boolean = false;

  private readonly datesAndPresencesRequest$ = new Subject<void>();
  private readonly refreshAttendanceWithDebounceSubject$ = new Subject<void>();
  private readonly successNotificationSubject$ = new Subject<string>();

  isLoadingParticipants: boolean = false;
  isLoadingAttendance: boolean = false;
  skeletonDateItems = Array(15).fill(null);
  skeletonParticipantItems = Array(5).fill(null);

  isSearchOpen: boolean = false;
  searchTerm: string = '';
  filteredParticipants: MeetingParticipant[] = [];

  constructor(
    private readonly _authService: AuthService,
    private readonly _meetingsService: MeetingsService,
    private readonly _notificationService: NotificationService,
    private readonly _translateService: TranslateService,
    private readonly _uploadService: UploadService
  ) {
    this.current_user_id = this._authService.getAuthenticatedUser().id;
  }

  ngOnInit() {
    this.isLoadingParticipants = true;
    this.isLoadingAttendance = true;
    
    this.now = new Date();
    this.todayStr = this.formatLocalDate(this.now);
    this.yesterdayStr = this.formatLocalDate(this.getYesterdayDate());

    this.maxDate = this.now.getFullYear() + '-' + String(this.now.getMonth() + 1).padStart(2, '0') + '-' + String(this.now.getDate()).padStart(2, '0');
    this.minDate = this.now.getFullYear() + '-' + String(this.now.getMonth() + 1).padStart(2, '0') + '-' + String(this.now.getDate() - 1).padStart(2, '0');

    this.datesAndPresencesRequest$
      .pipe(
        switchMap(() => {
          return this._meetingsService.getMeetingDatesAndPresences(this.current_cod_meeting, this.current_user_id).pipe(
            catchError((err) => {
              this._notificationService.error({
                text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_FETCH_MEETING_DATES',
                translate: true
              });
              return of([]);
            }),
            finalize(() => {
              this.buildAttendanceHashmap();
              this.isLoadingAttendance = false;
            })
          );
        })
      ).subscribe((data) => {
        const response = data['content']['results'] as MeetingPresence[];

        const parsedDates: MeetingPresenceItem[] = response.map(date => {

          const isDateBellowMin = new Date(date.presence_date) < new Date(this.minDate);
          const isDateAboveMax = new Date(date.presence_date) > new Date(this.maxDate);

          const isTodayOrYesterday = date.presence_date === this.todayStr || date.presence_date === this.yesterdayStr;
          const isEditable = isTodayOrYesterday || !isDateBellowMin && !isDateAboveMax;
          
          return { ...date, isEditable, isSyncing: false };
        })
      
        this.datesAndPresences = [ ...parsedDates ];
        this.updateMinMaxDates();
      });

    this.refreshAttendanceWithDebounceSubject$
      .pipe(debounceTime(1500))
      .subscribe(() => {
        this.getAttendanceData();
      });

    this.successNotificationSubject$
      .pipe(debounceTime(1000))
      .subscribe((message) => {
        this._notificationService.success({
          text: message,
          translate: true
        });
      });

    this.getMeetingParticipants();
    this.getAttendanceData();
  };

  ngOnDestroy() {
    this.datesAndPresencesRequest$.unsubscribe();
    this.refreshAttendanceWithDebounceSubject$.unsubscribe();
    this.successNotificationSubject$.unsubscribe();
  };

  getMeetingParticipants() {
    this._meetingsService.getMeetingParticipants(this.current_cod_meeting, this.current_user_id).subscribe({
      next: (data) => {
        const participantsData = data['content']['results'] as MeetingParticipant[];

        this.participants = participantsData;
      },
      error: (err) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_FETCH_PARTICIPANTS',
          translate: true
        });
      },
      complete: () => {
        this.isCurrentUserOwner = this.participants.find(participant => participant.cod_user === this.current_user_id)?.bol_is_owner === 1;
        this.filteredParticipants = [...this.participants];
        this.isLoadingParticipants = false;
      }
    });
  }

  getAttendanceData() {
    this.datesAndPresencesRequest$.next();
  }

  refreshAttendanceWithDebounce() {
    this.refreshAttendanceWithDebounceSubject$.next();
  }

  callSuccessNotification(message: string) {
    this.successNotificationSubject$.next(message);
  };

  buildAttendanceHashmap() {
    this.attendanceHashmap = {};
    this.datesAndPresences.forEach(dateAndParticipants => {
      this.attendanceHashmap[dateAndParticipants.cod_meetings_presences] = new Set(dateAndParticipants.participants);
    });
  }

  addAttendanceDate() {
    if (this.areBothDatesPresent(this.todayStr, this.yesterdayStr)) {
      return this._notificationService.error({
        text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_ALL_DATES_ADDED',
        translate: true
      })
    };

    const presenceDate = this.isDatePresent(this.todayStr) ? this.yesterdayStr : this.todayStr;
    this.addTemporaryDate(presenceDate);

    this._meetingsService.postMeetingPresence(this.current_cod_meeting, this.current_user_id, presenceDate).subscribe({
      next: (response) => {
        const addedDate = this.datesAndPresences.find(d => d.presence_date === presenceDate && d.cod_meetings_presences === -1);

        if (addedDate) {
          const new_cod_meetings_presences = response['content']['results'][0]['cod_meetings_presence'];
          addedDate.cod_meetings_presences = new_cod_meetings_presences;
          addedDate.isSyncing = false;
          addedDate.isEditable = true;
          this.buildAttendanceHashmap();

          const currentUserMeetingParticipantId = this.participants.find(participant => participant.cod_user === this.current_user_id)?.cod_meetings_participants;
          this.updatePresence(new_cod_meetings_presences, this.current_user_id, true, currentUserMeetingParticipantId);
          this.updateMinMaxDates();
        }
        this._notificationService.success({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.SUCCESS_FEEDBACK_MESSAGES.SUCCESS_ADD_ATTENDANCE_DATE',
          translate: true
        });
      },
      error: (err) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_ADD_ATTENDANCE_DATE',
          translate: true
        });

        this.datesAndPresences = this.datesAndPresences.filter(d => d.presence_date !== presenceDate || d.cod_meetings_presences !== -1);
        this.buildAttendanceHashmap();
      }
    });
  }

  private getYesterdayDate(): Date {
    const yesterday = new Date(this.now);
    yesterday.setDate(this.now.getDate() - 1);
    return yesterday;
  }

  private areBothDatesPresent(todayStr: string, yesterdayStr: string): boolean {
    return this.isDatePresent(todayStr) && this.isDatePresent(yesterdayStr);
  }

  isDatePresent(dateStr: string): boolean {
    return this.datesAndPresences.some(d => d.presence_date === dateStr);
  }

  addTemporaryDate(presenceDate: string): void {
    const newMeetingPresence: MeetingPresenceItem = {
      cod_meetings_presences: -1,
      presence_date: presenceDate,
      participants: [],
      isEditable: false,
      isSyncing: true,
    };
  
    const insertPosition = this.isDatePresent(this.formatLocalDate(this.now)) ? 1 : 0;
    this.datesAndPresences.splice(insertPosition, 0, newMeetingPresence);
    this.buildAttendanceHashmap();
  }

  private updateMinMaxDates(): void {
    const isTodayPresent = this.isDatePresent(this.todayStr);
    const isYesterdayPresent = this.isDatePresent(this.yesterdayStr);
  
    if (isTodayPresent) {
      this.maxDate = this.yesterdayStr;
    } else {
      this.maxDate = this.todayStr;
    }
  
    if (isYesterdayPresent) {
      this.minDate = this.todayStr;
    } else {
      this.minDate = this.yesterdayStr;
    }
  }

  onDeleteAttendanceDate(cod_meetings_presences: number, index: number) {
    const dateAndParticipants = this.datesAndPresences[index];

    if (dateAndParticipants.participants.length > 0) {
      return this.openConfirmationModal(cod_meetings_presences);
    }

    this.deleteAttendanceDate(cod_meetings_presences);
  };

  openConfirmationModal(cod_meetings_presences: number) {
    this.isConfirmationModalOpen = true;
    this.deleteConfirmationDateCodMeetingsPresences = cod_meetings_presences;
  }

  onCloseConfirmationModal() {
    this.isConfirmationModalOpen = false;
    this.deleteConfirmationDateCodMeetingsPresences = null;
  }

  onConfirmDeleteDate() {
    this.deleteAttendanceDate(this.deleteConfirmationDateCodMeetingsPresences);
    this.onCloseConfirmationModal();
  }

  deleteAttendanceDate(cod_meetings_presences: number) {
    const removedDateIndex = this.datesAndPresences.findIndex(d => d.cod_meetings_presences === cod_meetings_presences);
    const removedDate = this.datesAndPresences[removedDateIndex];
    this.datesAndPresences.splice(removedDateIndex, 1);
    this.buildAttendanceHashmap();

    if (cod_meetings_presences === -1) return;

    this._meetingsService.deleteMeetingPresenceDate(this.current_user_id, cod_meetings_presences).subscribe({
      next: (_) => {
        this._notificationService.success({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.SUCCESS_FEEDBACK_MESSAGES.SUCCESS_DELETE_ATTENDANCE_DATE',
          translate: true
        });
        this.updateMinMaxDates();
      },
      error: (_) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_DELETE_ATTENDANCE_DATE',
          translate: true
        });

        this.datesAndPresences.splice(removedDateIndex, 0, removedDate);
        this.buildAttendanceHashmap();
        this.updateMinMaxDates();
      }
    });
  };

  onCloseAttendance() {
    this.closeAttendance.emit();
  }

  openSearchParticipant() {
    this.isSearchOpen = true;
  }

  toggleOpenSearchParticipant() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  onCloseSearchParticipant() {
    this.isSearchOpen = false;
    this.searchTerm = '';
    this.filteredParticipants = [...this.participants];
  };

  onConfirmSearchParticipant() {
    this.isSearchOpen = false;
  };

  filterParticipants() {
    if (!this.searchTerm.trim()) {
      this.filteredParticipants = [...this.participants];
      return;
    }

    this.filteredParticipants = this.participants.filter(participant =>
      participant.str_name.toLowerCase().includes(this.searchTerm.trim().toLowerCase())
    );
  }

  onDateChange(newDate: string, cod_meetings_presences: number, index: number): void {
    const previousDate = this.datesAndPresences[index].presence_date;
    if (!this.isDateValid(newDate)) {
      this.datesAndPresences[index].presence_date = previousDate;

      return this._notificationService.error({
        text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_INVALID_DATE',
        translate: true
      });
    };

    this.datesAndPresences[index].presence_date = newDate;
    this.updateAttendanceDate(cod_meetings_presences, newDate, index);
  }

  updateAttendanceDate(cod_meetings_presences: number, newDate: string, index: number): void {
    this._meetingsService.updateMeetingPresenceDate(this.current_user_id, cod_meetings_presences, newDate).subscribe({
      next: (_) => {
        this._notificationService.success({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.SUCCESS_FEEDBACK_MESSAGES.SUCCESS_UPDATE_ATTENDANCE_DATE',
          translate: true
        });
        this.refreshAttendanceWithDebounceSubject$.next();
      },
      error: (_) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_UPDATE_ATTENDANCE_DATE',
          translate: true
        });
      },
      complete: () => {
        this.updateMinMaxDates();
      }
    });
  }

  isDateValid(date: string): boolean {
    const dateAlreadyExists = this.datesAndPresences.some(d => d.presence_date === date);
    const isDateBellowMin = new Date(date) < new Date(this.minDate);
    const isDateAboveMax = new Date(date) > new Date(this.maxDate);

    return !dateAlreadyExists && !isDateBellowMin && !isDateAboveMax;
  }


  isParticipantPresent(codMeetingsPresences: number, participantId: number): boolean {
    const participantSet = this.attendanceHashmap[codMeetingsPresences];
    return participantSet ? participantSet.has(participantId) : false;
  }

  updatePresence(cod_meetings_presences: number, participantId: number, isPresent: boolean, cod_meetings_participants: number): void {
    if (!cod_meetings_presences || !participantId || !cod_meetings_participants) return;
    if (isPresent) {
      this.addHashmapPresence(cod_meetings_presences, participantId);
    } else {
      this.deleteHashmapPresence(cod_meetings_presences, participantId);
    }

    this._meetingsService.updateMeetingParticipantPresence(this.current_user_id, cod_meetings_presences, isPresent, cod_meetings_participants).subscribe({
      next: (_) => {
        this.successNotificationSubject$.next('MY_MEETINGS.ATTENDANCE_SECTION.SUCCESS_FEEDBACK_MESSAGES.SUCCESS_UPDATE_PARTICIPANT_PRESENCE');
      },
      error: (err) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_UPDATE_PARTICIPANT_PRESENCE',
          translate: true
        });
        if (isPresent) {
          this.deleteHashmapPresence(cod_meetings_presences, participantId);
        } else {
          this.addHashmapPresence(cod_meetings_presences, participantId);
        }
      },
      complete: () => {
        this.refreshAttendanceWithDebounceSubject$.next();
      }
    });
  }

  addHashmapPresence(cod_meetings_presences: number, participantId: number): void {
    const participantSet = this.attendanceHashmap[cod_meetings_presences];
    participantSet.add(participantId);
  }

  deleteHashmapPresence(cod_meetings_presences: number, participantId: number): void {
    const participantSet = this.attendanceHashmap[cod_meetings_presences];
    participantSet.delete(participantId);
  }

  openCalendar(event: Event, dateId: number | string): void {
    event.preventDefault();
    const datepicker = document.getElementById(`datepicker-${dateId}`) as HTMLInputElement;

    if (!datepicker) return console.error('Datepicker not found');

    datepicker.focus();
    setTimeout(() => datepicker.showPicker && datepicker.showPicker(), 0);
  }

  trackByCodMeetingsPresences(index: number, item: MeetingPresence): number {
    return item.cod_meetings_presences;
  }

  trackByCodUser(index: number, item: MeetingParticipant): number {
    return item.cod_user;
  }

  formatLocalDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  openExportAttendanceModal() {
    this.isExportModalOpen = true;
  }

  onCloseExportAttendanceModal() {
    this.isExportModalOpen = false;
    this.exportDateFrom = null;
    this.exportDateTo = null;
  }

  onExportDateSelected(event: Event, dateType: 'from' | 'to'): void {
    const inputElement = event.target as HTMLInputElement;
    const selectedDate = inputElement.value;

    if (dateType === 'from') {
      this.calculateMaxExportDate(selectedDate);
    } else {
      this.calculateMinExportDate(selectedDate);
    }
  }

  calculateMaxExportDate(selectedDate: string): void {
    const selectedDateObj = new Date(selectedDate);
    const maxExportDate = new Date(selectedDateObj);
    maxExportDate.setDate(selectedDateObj.getDate() + 180);

    this.maxExportDate = maxExportDate > this.now ? this.now : maxExportDate;
  }

  calculateMinExportDate(selectedDate: string): void {
    const selectedDateObj = new Date(selectedDate);
    const minExportDate = new Date(selectedDateObj);
    minExportDate.setDate(selectedDateObj.getDate() - 180);

    this.minExportDate = minExportDate;
  }

  onConfirmExportAttendance() {
    this.generateExportAttendanceParams();
  };

  generateExportAttendanceParams() {
    let arr_headers = [];

    if (!this.exportDateFrom || !this.exportDateTo) {
      return this._notificationService.error({
        text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_SELECT_DATE_RANGE',
        translate: true
      });
    };

    forkJoin({
      codUser: this._translateService.get("MEETINGS_REPORTS.COD_USER"),
      userName: this._translateService.get("MEETINGS_REPORTS.USER_NAME"),
      userMail: this._translateService.get("MEETINGS_REPORTS.USER_MAIL")
    }).subscribe({
      next: (translations) => {
        arr_headers = [translations.codUser, translations.userName, translations.userMail];

        const diffTime = Math.abs(new Date(this.exportDateTo).getTime() - new Date(this.exportDateFrom).getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 180) {
          this._notificationService.error({
            text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_DATE_RANGE_EXCEEDED',
            translate: true
          });
          return;
        }

        const params: ExportAttendanceParams = {
          cod_user: this.current_user_id,
          arr_proc_params_search: [
            this.current_user_id,
            this.current_cod_meeting,
            this.exportDateFrom,
            this.exportDateTo,
          ],
          arr_headers,
          name_of_file_to_download:
            this.convertChar(this.current_meeting_name) + "_attendance",
        }

        this.downloadAttendance(params);
      }
    })
  }

  downloadAttendance(params: ExportAttendanceParams) {
    this.isLoadingDownloadExport = true;

    this._meetingsService.postDownloadAttendanceReport(params).subscribe({
      next: (res) => {
        try {
          let str_file_name = res.content.str_file_name;
          const uploadFile = `${environment().uploadGetFilesApiUrl}/reports/${this.current_user_id}/${res.content.str_file_name}`;

          this._uploadService.downloadFile(uploadFile).subscribe({
            next: (fileData: Blob) => {
              const downloadBlob = (blob: Blob, fileName: string) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(url);
              };

              downloadBlob(fileData, str_file_name);
            },
            error: (err) => {
              this._notificationService.error({
                text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_DOWNLOAD_FILE',
                translate: true
              });
              this.isLoadingDownloadExport = false;
            },
            complete: () => {
              this.isLoadingDownloadExport = false;
              this.onCloseExportAttendanceModal();
            }
          });
        } catch (err) {
          this._notificationService.error({
            text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_DOWNLOAD_FILE',
            translate: true
          });
          this.isLoadingDownloadExport = false;
        }
      }, error: (err) => {
        this._notificationService.error({
          text: 'MY_MEETINGS.ATTENDANCE_SECTION.ERROR_FEEDBACK_MESSAGES.ERROR_CREATE_FILE',
          translate: true
        });
        this.isLoadingDownloadExport = false;
      }
    });
  }

  convertChar(str: string | undefined): string | undefined {
    if (!str) return str;

    const specialChars = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẝźḧ·/_,:;";
    const replacementChars = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______";
    const regex = new RegExp(`[${specialChars}]`, "g");

    return str
    .toLowerCase()
    .trim()
    .replace(regex, (char) => replacementChars[specialChars.indexOf(char)]) // Replace special chars
    .replace(/&/g, "_and_") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "_"); // Replace spaces, non-word characters, and dashes with '_'
  }
}
