export class SubArea {
    cod_subareas: number;
    str_name_areas: string;
    str_name: string;
    bol_is_integration: number;
    equipments: any;
    locations: any;
    cod_sap_functional_location: string;
    str_description_pt: string;
    str_description_es: string;
    str_description_fr: string;
    str_description_nl: string;
    str_description_de: string;
    str_description_ru: string;
    str_description_uk: string;
    str_description_zh: string;
    str_description_ko: string;
    str_description_vi: string;
    cod_acadia_id: string;
    gly_dream_value: number;
    lef_dream_value: number;

    constructor() {
        this.cod_subareas = 0;
        this.str_name_areas = '';
        this.str_name = '';
        this.bol_is_integration = 0;
        this.equipments = [];
        this.locations = [];
        this.cod_sap_functional_location = '';
        this.str_description_pt = null;
        this.str_description_es = null;
        this.str_description_fr = null;
        this.str_description_nl = null;
        this.str_description_de = null;
        this.str_description_ru = null;
        this.str_description_uk = null;
        this.str_description_zh = null;
        this.str_description_ko = null;
        this.str_description_vi = null;
        this.cod_acadia_id = '';
        this.gly_dream_value = 0;
        this.lef_dream_value = 0;
    }
}
