import { Injectable } from '@angular/core';

const REMIND_ME_LATER = 'NPS_REMIND_LATER_DATE';

@Injectable({
  providedIn: 'root'
})
export class NpsPersistenceService {

  postponeNps() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    localStorage.setItem(REMIND_ME_LATER, date.toUTCString());
  }

  canOpenNps(): boolean {
    const dateString = localStorage.getItem(REMIND_ME_LATER);
    if (!dateString) {
      return true;
    }
    return new Date() >= new Date(dateString);
  }

}
