import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ConfimDiscartChangesComponent } from "app/components/confirm-discart-changes/confirm-discart-changes.component";

export interface CheckChangesOnDeactivate {
    hasChanges: boolean | (() => boolean);
    discartChangesComponent: ConfimDiscartChangesComponent;
}

@Injectable()
export class UnsavedChangesGuard  {

    constructor() { }

    canDeactivate(component: CheckChangesOnDeactivate) : Observable<boolean> | Promise<boolean> | boolean {
        if (typeof component.hasChanges == 'function') {
            if (component.hasChanges()) return this.ask(component);
        } else {
            if (component.hasChanges) return this.ask(component);
        }

        return true;
    }

    private ask(component: CheckChangesOnDeactivate) : Observable<boolean> | Promise<boolean> | boolean {
        component.discartChangesComponent.ask();            
        return component.discartChangesComponent.discart.asObservable();
    }

}
