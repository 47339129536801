// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-whys { 
    width: 100%;
    max-width: 200px;
    margin-top: 15px;
    line-height: normal;
    padding: 8px;
}

.whys:not(:first-child)  {
    margin-top: 20px; 
}

.btn-add-why {
    text-align: end;
    padding-right: 2.2vw;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/add-whys/add-whys.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".add-whys { \n    width: 100%;\n    max-width: 200px;\n    margin-top: 15px;\n    line-height: normal;\n    padding: 8px;\n}\n\n.whys:not(:first-child)  {\n    margin-top: 20px; \n}\n\n.btn-add-why {\n    text-align: end;\n    padding-right: 2.2vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
