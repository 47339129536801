// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-topic-detail {
    padding: 0 20px 20px;
}
.help-topic-detail .help-topic-title {
    color: var(--ui-primary);
    font-size: 1.5em;
    margin-bottom: 10px;
}

.help-topic-detail .help-topic-text {
    color: var(--text-secondary);
}`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-general/general-topic-3/general-topic-3.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".help-topic-detail {\n    padding: 0 20px 20px;\n}\n.help-topic-detail .help-topic-title {\n    color: var(--ui-primary);\n    font-size: 1.5em;\n    margin-bottom: 10px;\n}\n\n.help-topic-detail .help-topic-text {\n    color: var(--text-secondary);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
