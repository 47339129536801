import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListSubAreasTaService} from './services/list-subareas-ta.service';
import {AuthService} from '../../main/access/services/auth.service';
import {AreaService} from 'app/logbooks/services/area.service';

@Component({
    selector: 'list-subareas-ta',
    templateUrl: './list-subareas-ta.component.html',
    styleUrls: ['./list-subareas-ta.component.css']
})

export class ListSubAreasTaComponent implements OnInit {

    subAreasTaList: any = [];
    obj_ta_eq;
    bol_show_typeahead: Boolean = true;
    str_user_tmp = '';
    cod_component_subareas: number;
    warningDeleteMessage: Boolean = false;

    @Input() question;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Input() disabledDelete: any;
    @Input() area: number;
    @Input() location: any;
    @Input() cod_equipment: number;

    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListSubareasTaService: ListSubAreasTaService, private _authService:AuthService, private _areaService:AreaService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.subAreasTaList = [{ cod_answer_option: this.question.cod_answer_option, str_name: this.question.str_answer_text || this.question.answer  }];    
            this.bol_show_typeahead = false;
        }
        this.loadSubAreasTa();
    }

    loadSubAreasTa() {
        this.obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_subareas',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.subAreasTaList.length === 0) {
                    this.subAreasTaList.push({
                        cod_subareas: obj_selected.cod_subareas,
                        str_name: obj_selected.str_name,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_subareas;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchSubAreasTa(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchSubAreasTa(cb, value) {
        let objParam = {
            'cod_area': this.area,
            'str_term': value,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'cod_location': this.location
        };
        this._areaService.getSubAreasByAreaPrivilege(objParam).subscribe(data => {
            cb(this._areaService.getArrayContent(data));
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    delSubAreasTa() {
        if (this.cod_equipment == null) {
            this.warningDeleteMessage = false;

            this.question.str_answer_text = '';
            this.question.cod_answer_option = null;
            this.onChange.emit(this.question);

            this.subAreasTaList = [];
            this.bol_show_typeahead = true;
            this.cod_component_subareas = null;
        } else {
            this.warningDeleteMessage = true;
        }
    }
}
