export class Equipment {
    cod_equipments: number;
    str_name: string;
    str_description: string;
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;
    cod_user: number;
    bol_accepts_hour_meter: boolean;
    str_equipment_number: string;
    int_status: number;
    bol_is_integration: boolean;

    constructor() {
        this.cod_equipments = 0;
        this.str_name = '';
        this.str_description = '';
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
        this.cod_user = 0;
        this.bol_accepts_hour_meter = false;
        this.str_equipment_number = '';
        this.int_status = 0;
        this.bol_is_integration = false;
    }
}
