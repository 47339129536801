import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-meeting-topic-11',
    templateUrl: './meeting-topic-11.component.html',
    styleUrls: ['./meeting-topic-11.component.css']
})
export class MeetingTopic11Component implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
