import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription, Observable} from 'rxjs';
import { mergeMap } from "rxjs/operators";
import {InfoService} from '../../services/help.service';
import {InfoHostDirective} from '../help-host.directive';
import {pick} from 'lodash';
import {environment} from '../../../../environments/environment';
@Component({
    selector: 'app-info',
    templateUrl: './help-content.component.html',
    styleUrls: ['./help-content.component.css'],
})
export class InfoComponent implements OnInit, OnDestroy {
    menuList: object;
    currentItem: object;
    currentTopic: object;
    currentKey: string;
    topicSubscription: Subscription;
    allTopics: any;
    searchTerm: string;
    filteredTopics: Array<any> = [];
    filteredTopicsKeys: Array<string> = [];

    @ViewChild(InfoHostDirective) infoHost: InfoHostDirective;
    version: string;
    constructor(
        private _infoService: InfoService, 
        private changeDetector: ChangeDetectorRef, 
        private router: Router, 
        private _translateService: TranslateService
    ) {

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
                window.scrollTo(0, 0);
                this._infoService.changeSelectedItem('GENERAL');
                this._infoService.changeSelectedTopic('');
            }
        });
    }

    ngOnInit() {
        this.version = environment().version
        this._infoService.changeSelectedItem('GENERAL');
        this.topicSubscription = this._infoService.currentTopic$
        .subscribe(topic => this.loadTopic(topic));
        this._translateService.get('HELP.LIST')
            .pipe(
                mergeMap((list) => {
                    const observables: Array<Observable<any>> = [];
                    const keys = Object.keys(list);
                    for (let i = 0; i < keys.length; i++) {
                        observables.push(this._translateService.get(`HELP.${keys[i]}`));
                    }
                    return forkJoin(observables);
                })
            )
            .subscribe((res) => {
                const result = res.reduce((acc, x: object) => {
                    // tslint:disable-next-line:forin
                    for (const key in x) {
                        acc[key] = x[key];
                    }
                    return acc;
                }, {});
                this.allTopics = result;
            });
    }

    ngOnDestroy() {
        this.topicSubscription.unsubscribe();
    }

    loadTopic(topic) {
        if (!Object.keys(topic).length) {
            this.currentTopic = null;
            return;
        }
        this.currentTopic = topic;
        this.changeDetector.detectChanges();
        const viewContainerRef = this.infoHost.viewContainerRef;
        viewContainerRef.clear();
        viewContainerRef.createComponent(this._infoService.getComponent(this.currentTopic));
    }

    search() {
        if (!this.isSearchActive()) {
            this.clearSearch();
            return;
        }
        this.filteredTopics = this.filterIt(this.allTopics, this.searchTerm);
        this.filteredTopicsKeys = Object.keys(this.filteredTopics);
    }

    isSearchActive() {
        return this.searchTerm && this.searchTerm.trim().length;
    }

    clearSearch() {
        this.searchTerm = '';
        this.filteredTopics = [];
        this.filteredTopicsKeys = [];
    }

    filterIt(object, searchKey) {
        const keysFiltered = Object.keys(object).filter((key) =>
            object[key].toUpperCase().includes(searchKey.toUpperCase())
        );
        return pick(object, keysFiltered);
    }

    clickTopicResult(topic) {
        this.clearSearch();
        const item = topic.includes('MY_TEAM') ? 'MY_TEAM' : topic.split('_')[0];
        this._infoService.changeSelectedItem(item);
        this._infoService.changeSelectedTopic(topic);
    }
}
