// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-action {
	line-height: normal;
	padding: 8px;
}

.action-column {
	padding-right: 2.2vw;
	width: 20%;
}

:host .table-responsive {
	border: 0;
}

:host .table-responsive thead {
	display: none !important;
}

:host .table-responsive tr {
	display: block;
	margin-bottom: 0.625em;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

:host .table-responsive td {
	display: block;
	text-align: right;
}

:host .table-responsive td:last-child {
	border-bottom: 1px solid #ddd;
}

:host .table-responsive td::before {
	content: attr(title);
	float: left;
	font-weight: bold;
	text-transform: uppercase;
}

a {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    max-width: 95%;
    text-overflow: ellipsis;
}

@media (max-width: 1366px) {
	a {
		max-width: 85%;
	}
}`, "",{"version":3,"sources":["webpack://./src/app/components/action-list/action-list.component.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,oBAAoB;CACpB,UAAU;AACX;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,cAAc;CACd,sBAAsB;CACtB,2BAA2B;CAC3B,4BAA4B;AAC7B;;AAEA;CACC,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,oBAAoB;CACpB,WAAW;CACX,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,cAAc;IACd,uBAAuB;AAC3B;;AAEA;CACC;EACC,cAAc;CACf;AACD","sourcesContent":[".add-action {\n\tline-height: normal;\n\tpadding: 8px;\n}\n\n.action-column {\n\tpadding-right: 2.2vw;\n\twidth: 20%;\n}\n\n:host .table-responsive {\n\tborder: 0;\n}\n\n:host .table-responsive thead {\n\tdisplay: none !important;\n}\n\n:host .table-responsive tr {\n\tdisplay: block;\n\tmargin-bottom: 0.625em;\n\tborder-left: 1px solid #ddd;\n\tborder-right: 1px solid #ddd;\n}\n\n:host .table-responsive td {\n\tdisplay: block;\n\ttext-align: right;\n}\n\n:host .table-responsive td:last-child {\n\tborder-bottom: 1px solid #ddd;\n}\n\n:host .table-responsive td::before {\n\tcontent: attr(title);\n\tfloat: left;\n\tfont-weight: bold;\n\ttext-transform: uppercase;\n}\n\na {\n    overflow: hidden;\n    white-space: nowrap;\n    display: block;\n    max-width: 95%;\n    text-overflow: ellipsis;\n}\n\n@media (max-width: 1366px) {\n\ta {\n\t\tmax-width: 85%;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
