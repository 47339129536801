// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dvMultiSelect{
    margin-bottom: 10px
}

.user-day {
    width: 95px;
    display: inline;
}

.user-month {
    width: 150px;
    display: inline;
}

.user-year {
    width: 95px;
    display: inline;
}

.manager-margin{
    margin:10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/users/users.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".dvMultiSelect{\n    margin-bottom: 10px\n}\n\n.user-day {\n    width: 95px;\n    display: inline;\n}\n\n.user-month {\n    width: 150px;\n    display: inline;\n}\n\n.user-year {\n    width: 95px;\n    display: inline;\n}\n\n.manager-margin{\n    margin:10px 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
