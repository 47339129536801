import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { MeetingsService } from '../services/meetings.service';
import { NotificationService } from '../../main/services/notification.service';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ClbTextareaModule } from '@celebration/angular/clb-textarea';
import { ActionsService } from '../services/actions.service';
import { ClbMultiSelectModule } from '@celebration/angular/clb-multi-select';
import { ClbInputTextModule } from '@celebration/angular/clb-input-text';
import { MeetingParticipant } from '../services/types/response-types';
import { KpisService } from '../services/kpis.service';
import { LiveSearchSelectComponent } from '../../components/live-search-select/live-search-select.component';
import { CreateActionParams } from '../services/types/actions-service-types';
import { MultiSelectComponent } from '../../components/multiselect/multiselect.component';
import { SocketService } from '../../main/services/socket.service';

type SelectedOptionType = {
  name: string;
  value: number | string;
}

type KpiItem = {
  uuid: string;
  name: string;
  code: string;
}

export type NewActionForm = {
  description: string;
  deadline: string;
  owners: SelectedOptionType[];
  kpis: SelectedOptionType[];
  tags: SelectedOptionType[];
  department: SelectedOptionType[];
  action_origin: string;
  root_cause: string;
  meetings_ids: number[];
}

@Component({
  selector: 'app-meetings-add-action-drawer',
  templateUrl: './meetings-add-action-drawer.component.html',
  styleUrls: ['./meetings-add-action-drawer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ClbDrawerModule,
    ClbModalModule,
    ClbTextareaModule,
    ClbMultiSelectModule,
    ClbInputTextModule,
    MultiSelectComponent,
    LiveSearchSelectComponent
  ]
})
export class MeetingsAddActionDrawerComponent {
  @Input('open') isOpen: boolean = false;
  @Input('cod_meeting') current_meeting_id: number;
  @Input() meeting_name: string;
  @Input('socketId') socket_id: string;

  @Output() onClose = new EventEmitter<boolean>();

  obj_user: any;
  current_user_id: number;

  isConfirmationModalOpen: boolean = false;
  confirmationModalDescription: string = '';
  currentModalAction: 'createAction' | 'cancelCreateAction' | null = null;

  actionForm: FormGroup;

  ownerOptions = [];
  kpiOptions = [];
  tagOptions = [];
  departmentOptions = [];
  meetingsOptions = [];

  selectedOwners:number[] = [];

  meetingsMap = new Map<number, string>();

  today: string = null;

  loadingOptionsState = {
    owners: false,
    kpis: false,
    tags: false,
    departments: false,
    meetings: false
  }

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _meetingsService: MeetingsService,
    private readonly _notificationService: NotificationService,
    private readonly _actionsService: ActionsService,
    private readonly _kpisService: KpisService,
    private readonly _socketService: SocketService
  ) {
    this.obj_user = this._authService.getAuthenticatedUser();
    this.current_user_id = this.obj_user.id;

    this.actionForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      deadline: new FormControl('', [Validators.required]),
      owners: new FormControl([], [Validators.required]),
      kpis: new FormControl([]),
      tags: new FormControl([]),
      department: new FormControl([]),
      action_origin: new FormControl(''),
      root_cause: new FormControl(''),
      meetings_ids: new FormControl([this.current_meeting_id], [Validators.required])
    });

    this.meetingsMap.set(this.current_meeting_id, this.meeting_name);

    this.today = this.getTodaysDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen?.currentValue) {
      this.getOptions();
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.actionForm.reset({
      description: '',
      deadline: '',
      owners: [],
      kpis: [],
      tags: [],
      department: [],
      action_origin: '',
      root_cause: '',
      meetings_ids: [this.current_meeting_id]
    });
  }

  getOptions() {
    this.getActionOwnerOptions();
    this.getActionKpis();
    this.getActionTags([ this.current_meeting_id ]);
    this.getDepartments([ this.current_meeting_id ]);
    this.getMeetings();
  }

  setLoadingState(field: string, loading: boolean) {
    this.loadingOptionsState[field] = loading;
  }

  getActionOwnerOptions() {
    this.setLoadingState('owners', true);
    this._meetingsService.getMeetingParticipants(this.current_meeting_id, this.current_user_id).subscribe({
      next: (response) => {
        const responseResults = response['content']['results']  as MeetingParticipant[];
        this.ownerOptions = responseResults.map((participant: MeetingParticipant) => {
          return {
            value: participant.cod_user,
            name: participant.str_name
          }
        });
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_ACTION_OWNERS').subscribe((translation) => {
          this._notificationService.error(translation);
        });
        this.setLoadingState('owners', false);
      },
      complete: () => {
        this.setLoadingState('owners', false);
      }
    });
  }

  getActionKpis() {
    this.setLoadingState('kpis', true);
    this._kpisService.getKpis({_order: 'name'}).subscribe({
      next: (response) => {
        const responseData = response?.content?.data as KpiItem[];

        this.kpiOptions = responseData.map((kpi: KpiItem) => {
          return {
            value: kpi.uuid,
            name: kpi.name,
            code: kpi.code
          }
        });
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_KPIS').subscribe((translation) => {
          this._notificationService.error(translation);
        });
        this.setLoadingState('kpis', false);
      },
      complete: () => {
        this.setLoadingState('kpis', false);
      }
    });
  }

  getActionTags(meetingIds: number[]) {
    this.setLoadingState('tags', true);
    this._actionsService.getActionTagsByMeetings(this.current_user_id, meetingIds).subscribe({
      next: (response) => {
        const responseResults = response['content']['results'];
        this.tagOptions = responseResults.map((tag: {cod_tag: number, str_name: string}) => {
          return {
            value: tag.cod_tag,
            name: tag.str_name
          }
        });
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_TAGS').subscribe((translation) => {
          this._notificationService.error(translation);
        });
        this.setLoadingState('tags', false);
      },
      complete: () => {
        this.setLoadingState('tags', false);
      }
    });
  }

  getDepartments(meetingIds: number[]) {
    this.setLoadingState('departments', true);
    this._actionsService.getDepartmentsByMeetings(this.current_user_id, meetingIds).subscribe({
      next: (response) => {
        const responseResults = response['content']['results'];
        this.departmentOptions = responseResults.map((department: {cod_area: number, str_name: string}) => {
          return {
            value: department.cod_area,
            name: department.str_name
          }
        });
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_DEPARTMENTS').subscribe((translation) => {
          this._notificationService.error(translation);
        });
        this.setLoadingState('departments', false);
      },
      complete: () => {
        this.setLoadingState('departments', false);
      }
    });
  }

  getMeetings(term: string = '') {
    this.setLoadingState('meetings', true);
    this._actionsService.searchUserMeetings(this.current_user_id, term).subscribe({
      next: (response) => {
        const responseResults = response['content']['results'];
        this.meetingsOptions = responseResults.map((meeting: {cod_meetings: number, str_name: string}) => {
          this.meetingsMap.set(meeting.cod_meetings, meeting.str_name);
          return {
            cod_meeting: meeting.cod_meetings,
            str_name: meeting.str_name
          }
        });
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_MEETINGS').subscribe((translation) => {
          this._notificationService.error(translation);
        });
        this.setLoadingState('meetings', false);
      },
      complete: () => {
        this.setLoadingState('meetings', false);
      }
    });
  }

  onSearchTermChange(term: string) {
    this.getMeetings(term);
  }

  onSelectMeeting(meeting: { cod_meeting: number, str_name: string }) {
    const meetingsIds = this.actionForm.get('meetings_ids').value;

    if (meetingsIds.includes(meeting.cod_meeting)) {
      this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_MEETING_ALREADY_ADDED').subscribe((translation) => {
        this._notificationService.error(translation);
      });
      return;
    }

    meetingsIds.push(meeting.cod_meeting);
    this.actionForm.get('meetings_ids').setValue(meetingsIds);
    this.meetingsMap.set(meeting.cod_meeting, meeting.str_name);

    const currentMeetingIds = this.actionForm.get('meetings_ids').value;
    this.getActionTags(currentMeetingIds);
    this.getDepartments(currentMeetingIds);
  };

  onRemoveMeeting(meetingId: number) {
    const meetingsIds = this.actionForm.get('meetings_ids').value;
    const updatedMeetings = meetingsIds.filter((id: number) => id !== meetingId);
    this.actionForm.get('meetings_ids').setValue(updatedMeetings);
  }

  onCloseDrawer() {
    const formValues = this.actionForm.value;
    const formFields = Object.keys(formValues);

    const formValuesChanged = formFields.some((field) => {
      if (field === 'meetings_ids') {
        return formValues[field] && formValues[field].length > 1
      };
      return formValues[field] && formValues[field].length > 0
    });

    if (formValuesChanged) {
      this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.CANCEL_CREATION_OF_ACTION').subscribe((translation) => {
        this.openConfirmationModal(translation, 'cancelCreateAction');
      });
    } else {
      this.onClose.emit();
    }
  };

  openConfirmationModal(
    description: string, 
    actionType: 'createAction' | 'cancelCreateAction'
  ) {
    this.confirmationModalDescription = description;
    this.currentModalAction = actionType;
    this.isConfirmationModalOpen = true;
  }

  onCloseConfirmationModal() {
    this.isConfirmationModalOpen = false;
    this.currentModalAction = null;
  }

  onCreateAction() {
    if (this.actionForm.invalid) {
      this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_FILL_ALL_FIELDS').subscribe((translation) => {
        this._notificationService.error(translation);
      });
      return;
    }

    this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.CONFIRM_CREATE_ACTION').subscribe((translation) => {
      this.openConfirmationModal(translation, 'createAction');
    });
  }

  onConfirmAction() {
    if (this.currentModalAction === 'createAction' && this.actionForm.valid) {
      this.createActionRequest();
      return;
    } 
    
    if (this.currentModalAction === 'cancelCreateAction') {
      this.resetFormValues();
      this.onClose.emit(false);
      this.onCloseConfirmationModal();
    }
  }

  createActionRequest() {
    const params: CreateActionParams = this.parseFormFieldsIntoParams(this.actionForm.value);
    this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.INFO_CREATING_ACTION').subscribe((translation) => {
      this._notificationService.info(translation);
    });

    this.onCloseConfirmationModal();

    this._actionsService.postCreateActions(params, this.current_user_id).subscribe({
      next: (response) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_ACTION_CREATED').subscribe((translation) => {
          this._notificationService.success(translation);
        });
        this._actionsService.getRefreshEventSubject().next({type: 'NEW_ACTION', cod_meeting: this.current_meeting_id});
        this._socketService.refreshMeetingActions({origin: this.socket_id, arr_meetings: params.arr_cod_meetings});
      },
      error: (error) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_CREATE_ACTION').subscribe((translation) => {
          this._notificationService.error(translation);
        });
      },
      complete: () => {
        this.onCloseConfirmationModal();
        this.resetFormValues();
      }
    });
  };

  getTodaysDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedToday = `${year}-${month}-${day}`;

    return formattedToday;
  };

  onSelectField(formFieldName: string, event: { selectedOptions: { name: string, value: number | string }[]}) {
    if (formFieldName === 'owners' && event.selectedOptions.length > 10) {

      this.actionForm.get('owners').setValue([]);
      this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_MAX_OWNERS').subscribe((translation) => {
        this._notificationService.error(translation);
      });

      return;
    }
    console.log("event.selectedOptions", event.selectedOptions);
    this.actionForm.get(formFieldName).setValue(event.selectedOptions);
    console.log("this.actionForm.get(formFieldName).value", this.actionForm.get(formFieldName).value);
    console.log("actionForm.get('kpis').value.lenght > 0", this.actionForm.get('kpis').value.lenght > 0)
  }

  parseFormFieldsIntoParams(formValues: NewActionForm): CreateActionParams {
    if (!formValues) return null;

    const params: CreateActionParams = {
      cod_area: formValues.department ? formValues.department[0]?.value as number : undefined,
      arr_tags: formValues.tags.map(tag => tag.value as number),
      str_description: formValues.description,
      dat_deadline: formValues.deadline,
      arr_cod_responsibles: formValues.owners.map(owner => owner.value as number),
      arr_cod_meetings: formValues.meetings_ids,
      arr_uuid_kpis: formValues.kpis.map(kpi => kpi.value as string),
      str_origin: formValues.action_origin,
      str_root_cause: formValues.root_cause
    };

    return params;
  }
}
