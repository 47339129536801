import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NpsService, Period, QuestionFeedback } from 'brewtech-nps-angular';
import { Observable, catchError, combineLatest, defaultIfEmpty, forkJoin, take, tap, timer } from 'rxjs';


@Component({
  selector: 'app-nps-feedback-modal',
  templateUrl: './nps-feedback-modal.component.html',
  styleUrls: ['./nps-feedback-modal.component.css']
})
export class NpsFeedbackModalComponent implements OnInit {

  @Input() visible = false;
  @Output() finish = new EventEmitter();
  positiveFeedback: boolean = null;
  questionFeedback: QuestionFeedback[] = [];
  feedbackTotal: number = 0;
  feedbackDone: number = 0;

  constructor(private readonly npsService: NpsService) { }

  ngOnInit() {
    this.npsService
      .getPeriods()
      .pipe(
        tap(listPeriods => this.getFeedback(listPeriods.filter(period => period.active))),
        take(1),
        catchError(err => {
          console.error('Fail to load NPS data.', err);
          this.finish.emit();
          return err;  
        })
      )
      .subscribe();
  }

  getFeedback(periods: Period[]) {
    if (!periods?.some(period => !!period.id)){
      this.finish.emit();
      return;
    }
    this.questionFeedback = [];
    this.feedbackTotal = 0;
    this.feedbackDone = 0;

    let obsList: Observable<any>[] = []

    periods.forEach(period => {
      if(period.id){
        const obs = this.npsService
          .getSurveyFeedback(period.id)
          .pipe(
            tap({
              next: feedbacks => {
                if (!!feedbacks && feedbacks.length > 0) {
                  this.visible = true;
                  this.questionFeedback.push(feedbacks[0]);
                  this.feedbackTotal++;
                }
              }
            }),
            take(1)
          );
        
        obsList.push(obs);
      }
    });

    if(obsList.length > 0){
      const $this = this;
      forkJoin(obsList).pipe(
        defaultIfEmpty(null),
        tap({
          complete() {
            if($this.questionFeedback.length == 0){
              $this.finish.emit();
            }
          },
        })
      )
      .subscribe()

    } else {
      this.finish.emit();
    }

  }

  answerSurveyFeedback(questionId: string, usefulFeedback: boolean) {
    this.npsService
      .answerSurveyFeedback({ id: questionId, usefulFeedback })
      .pipe(
        take(1),
        tap(_ => {
          if(this.questionFeedback.length === 0){
            this.closeModal();
          }
        })
      )
      .subscribe();

    this.feedbackDone++;
    this.questionFeedback.shift();
    this.positiveFeedback = null;
  }

  closeModal() {
    this.visible = false;
    this.finish.emit();
  }

}
