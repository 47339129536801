import { FiveWhyService } from './../services/five-why.service';
import { TriggersService } from './../services/triggers.service';
import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SelectPagination } from "../../logbooks/models/select_pagination.model";
import { UsersService } from '../../admin/services/users.service';
import { ValidationsService } from '../services/validations.service';
import { SubareaService } from '../../logbooks/services/subarea.service';
import { AreaService } from '../../logbooks/services/area.service';
import { MachineService } from '../services/machine.service';
import * as moment from 'moment';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ChartsService } from '../services/charts-service';
import { SortHeaderFivewhyList } from '../enums/sortHeaderFivewhysList.enum';
import { CrewService } from "../services/crew.service";
import {ActivatedRoute, Params, Router} from '@angular/router';
import { AnalyticInfo } from '../model/analytic-info.model';

@Component({
  selector: 'app-list-fivewhy',
  templateUrl: './list-fivewhy.component.html',
  styleUrls: ['./list-fivewhy.component.css']
})
export class ListFivewhyComponent implements OnInit, AfterContentChecked  {

  @Output() onOpenFiveWhyDetails = new EventEmitter<any>();

  @ViewChild('search_component') search_component: ElementRef;
  @ViewChild('search_button') search_button: ElementRef;

  rootCauseFoundIcon: string = '../../../assets/images/gold-medal.svg';

  codUser: any;
  downloadCsvSetup: any = {};
  _reportsApiUrl = environment().reportsApiUrl;
  initial_date: string;
  final_date: string;
  now: any;

  fiveWhyList: any = [];
  locationsList: any = [];
  areaList: any = [];
  subAreasList: any = [];
  machineList: any = [];
  equipmentList: any = [];
  categoriesList: any = [];
  stepList: any = [];
  statusList: any = [];
  crewList: any = [];
  locationsByProfile: any = [];
  areasByProfile: any = [];
  subAreasByProfile: any = [];
  equipmentsByProfile: any = [];
  machinesByProfile: any = [];
  crewsByProfile: any = [];

  usersFilters: any = {};

  arr_areas: any = [];
  arr_machines: any = [];
  arr_equipments: any = [];
  arr_category: any = [];
  arr_status: any = [];
  arr_location: any = []

  pageIndex: any;
  isLoading: any = false;
  searchTerm: string = '';
  searchTimer: any;
  showError: boolean;
  errorMessage: string;
  privilegeFiveWhy: any;
  maxPage: number;

  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  totalPages: any[];
  resultsPerPage: any[];
  resultsPerPageSelectOption: any[];
  resultsPerPageSelected: any;
  editSuccessMessage: boolean;
  paramsSelected: any = [];
  loadingConfig: any = [];

  screenHeight: number;
  screenWidth: number;

  escaletedAbReportData: any;
  sdcaMatrixData: any;
  completionTimeData: any;
  repeatingProblemChartData: any;
  actionStatusChartData: any;
  showCharts: boolean;

  bol_show_hint: boolean = false;
  arr_opt_hashtag: string[] = [];
  arr_hashtag: string[] = [];
  offset: any;
  limit: any;
  result_page_selected: any;
  get_filter: any;
  obj_filter: any;
  date = new Date();
  firstLoad: boolean = true;
  locationUser: any;

  str_root_cause: string;
  str_escalated: string;
  str_aborted: string;
  str_completed: string;
  str_in_progress: string;

  sortTypes: SortHeaderFivewhyList;
  tableSort = {
    sortHeaderSelected: SortHeaderFivewhyList.Date,
    sortAsc: false
  }

  headerTableCssHelper: string;
  showAnalytics: boolean = false;
  analyticInfoData: AnalyticInfo;

  constructor(
    private _areaService: AreaService,
    private _authService: AuthService,
    private _userService: UsersService,
    private _chartsService: ChartsService,
    private _fiveWhyService: FiveWhyService,
    private _machineService: MachineService,
    private _triggerService: TriggersService,
    private _userPreferenceService: UserPreferencesService,
    private _translateService: TranslateService,
    public validationService: ValidationsService,
    private _logbookSubAreaService: SubareaService,
    private _crewService: CrewService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    ) {
    this._translateService.onLangChange.subscribe(() => {
      this.translationsStatus();
      this.setTranslationPagination();
    });
    this.searchTerm = '';
    this.privilegeFiveWhy = this._authService.getUserPrivilege('5WHY_MENU_VISUALIZE');
    this.searchTimer = null;
    this.setTranslationPagination();

  }
 

  setTranslationPagination() {
    this.loadingConfig['pagination'] = true;
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str10perpage = '10 ' + translate;
      this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
        this.str50perpage = '50 ' + translate;
        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
          this.str100perpage = '100 ' + translate;
          this.pageIndex = 1;
          this.totalPages = [];
          this.resultsPerPage = [];
          this.resultsPerPage = [
            new SelectPagination(10, this.str10perpage),
            new SelectPagination(50, this.str50perpage),
            new SelectPagination(100, this.str100perpage),
          ];

          this.get_filter = JSON.parse(localStorage.getItem('filter'));          
          if(!JSON.parse(localStorage.getItem('filter')) || ((this.now - parseInt(this.get_filter.timestamp)) > 1000*3600*12)){
            this.resultsPerPageSelected = this.resultsPerPage[0].qty;
            this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
          } else {
            this.result_page_selected = JSON.parse(localStorage.getItem('filter'));
            this.resultsPerPageSelected = this.result_page_selected.value_offiset;
            this.resultsPerPageSelectOption = [{
              qty: this.result_page_selected.value_offiset
            }];
          }
          this.getFilterLocalStorage();    
          this.loadingConfig['pagination'] = false;
        });
      });
    });
  }

  ngOnInit() {
    this.privilegeFiveWhy = this.checkPrivilege('5WHY_MENU_VISUALIZE');
    this.codUser = this._authService.getAuthenticatedUser().id;
    const params = this._activatedRoute.snapshot.queryParams;

    if (Object.keys(params).length > 0) {
      if (params?.dat_from) {
        this.initial_date = moment(params?.dat_from).format('YYYY-MM-DD');
      }
      if (params?.dat_to) {
        this.final_date = params?.dat_to;
      }

      this.mapDeepLinkValuesToFilter(params);

      this.searchFilters(true);
      this.searchFiveWhy(false);
    } else {
      this.translationsStatus();
      this._fiveWhyService
        .getUserLastFilters({
          cod_user: this.codUser,
          dsc_page_privilege: '5WHY_MENU_VISUALIZE',
        })
        .subscribe({
          next: (data) => {
            if (data.code === 888 && data.content.code === 888) {
              if (data.content.length > 0) {
                this.usersFilters = data.content.results[0];
                this.refreshSortStrategy()
              }
              this.searchFilters(data.content.length > 0);
              this._translateService.onLangChange.subscribe(() => this.searchFiveWhy(true));
            }
          },
          error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
          },
        });
    }
  }

  refreshSortStrategy() {
    if (this.usersFilters?.str_sort_header) {
      this.tableSort.sortHeaderSelected = this.usersFilters
        .str_sort_header as SortHeaderFivewhyList;
      this.tableSort.sortAsc = this.usersFilters.bol_sort_asc;
    }
  }

  mapDeepLinkValuesToFilter(params: Params) {
    this.paramsSelected.locations = params?.locations;
    this.paramsSelected.areas = params?.areas;
    this.paramsSelected.subareas = params?.subareas;
    this.paramsSelected.equipments = params?.equipments;
    this.paramsSelected.machines = params?.machines;
    this.paramsSelected.status = params?.status;
    this.paramsSelected.crew = params?.crew || "";
    this.paramsSelected.categories = ""
    this.paramsSelected.steps = "";

    this.usersFilters.arr_cod_location = this.paramsSelected.locations;
    this.usersFilters.arr_cod_area = this.paramsSelected.areas;
    this.usersFilters.arr_cod_subareas = this.paramsSelected.subareas;
    this.usersFilters.arr_cod_equipment = this.paramsSelected.equipments;
    this.usersFilters.arr_cod_machine = this.paramsSelected.machines;
    this.usersFilters.arr_cod_category = this.paramsSelected.categories;
    this.usersFilters.arr_cod_steps = this.paramsSelected.steps;
    this.usersFilters.arr_cod_status = this.paramsSelected.status;
    this.usersFilters.arr_cod_crews = this.paramsSelected.crew;

    this.limit = 10
  }

  ngAfterContentChecked() {
    
    const heighHeaderTable = +window.getComputedStyle(document.getElementsByClassName('header-table')[0] , null).height.slice(0,2);
    
    if(heighHeaderTable <= 51) {
      this.headerTableCssHelper = 'oneLineWord';
    } else if(heighHeaderTable <= 62 ) {
      this.headerTableCssHelper = 'twoLinesWord'
    } else if(heighHeaderTable <= 82 ) {
      this.headerTableCssHelper = 'threeLinesWord'
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  getDateFormat(date) {   
    if (date) 
      return date.replace(/\/?\d\d\d\d\/?/, '');
  }

   translationsStatus(){
    this._setLoading('status', true);
    this.isLoading = true;    

    this._translateService.get('5WHY.STR_ROOT_CAUSE').subscribe(translate => {
      this.str_root_cause = translate;
      this._translateService.get('5WHY.STR_ESCALATED').subscribe(translate => {
        this.str_escalated= translate;
        this._translateService.get('5WHY.STR_ABORTED').subscribe(translate => {
          this.str_aborted= translate;
          this._translateService.get('5WHY.STR_COMPLETED').subscribe(translate => {
            this.str_completed= translate;
            this._translateService.get('5WHY.STR_IN_PROGRESS').subscribe(translate => {
              this.str_in_progress= translate;
              this.statusList = [
                {cod_fivewhys_status:1, str_name:this.str_root_cause},
                {cod_fivewhys_status:2, str_name:this.str_escalated},
                {cod_fivewhys_status:3, str_name:this.str_aborted},
                {cod_fivewhys_status:4, str_name:this.str_completed},
                {cod_fivewhys_status:5, str_name:this.str_in_progress}
              ]
              this._setLoading('status', false);
            });
          });
        });
      });
    });
    
  }

  private searchFilters(hasFilters: boolean) {
    this.locationUser = this._authService.getAuthenticatedUser().cod_location;
    if (hasFilters) {
      let $this = this;
      $this.paramsSelected['locations'] = $this.usersFilters.arr_cod_location;
      $this.paramsSelected['areas'] = $this.usersFilters.arr_cod_area;
      $this.paramsSelected['subareas'] = $this.usersFilters.arr_cod_subareas;
      $this.paramsSelected['equipments'] = $this.usersFilters.arr_cod_equipment;
      $this.paramsSelected['machines'] = $this.usersFilters.arr_cod_machine;
      $this.paramsSelected['categories'] = $this.usersFilters.arr_cod_category;
      $this.paramsSelected['steps'] = $this.usersFilters.arr_cod_steps;
      $this.paramsSelected['crew'] = $this.usersFilters.arr_cod_crews;
      $this.paramsSelected['status'] = $this.usersFilters.arr_cod_status;
     
      $this._searchLocations(() => {
        if(!$this.usersFilters.arr_cod_location){
          $this.paramsSelected['locations_selected'] = $this._arrayToJson(JSON.parse("[" + this.locationUser + "]"), 'cod_locations');
          } else {           
            $this.paramsSelected['locations_selected'] = $this._arrayToJson(JSON.parse("[" + $this.usersFilters.arr_cod_location + "]"), 'cod_locations');
          }
        $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_locations');    
        $this.pageIndex = history.state.data?.page ? +history.state.data.page.toString() : 1;
        if($this.tableSort.sortHeaderSelected){
          $this.search(false, true);
        }else{
          $this.search(false);
        }
        $this._getHashtags();
        $this._searchCrews(() => {          
          if (typeof $this.usersFilters.arr_cod_crews != typeof undefined && $this.usersFilters.arr_cod_crews) {
            $this.paramsSelected['crew_selected'] = $this._arrayToJson(JSON.parse('[' + ($this.usersFilters.arr_cod_crews || '') + ']'), 'cod_crews');
          }
          $this.paramsSelected['crew'] = $this._implodeValues($this.paramsSelected['crew_selected'], 'cod_crews');
        }, false);
        $this._searchAreas(() => {           
          $this.paramsSelected['areas_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_area || '') + "]"), 'cod_area');
          $this.paramsSelected['areas'] = $this._implodeValues($this.paramsSelected['areas_selected'], 'cod_area');
          $this._searchSubAreas(() => {
            $this.paramsSelected['subareas_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_subareas || '') + "]"), 'cod_subareas');
            $this.paramsSelected['subareas'] = $this._implodeValues($this.paramsSelected['subareas_selected'], 'cod_subareas');
            $this._searchEquipments(() => {          
              $this.paramsSelected['equipments_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_equipment || '') + "]"), 'cod_equipment');
              $this.paramsSelected['equipments'] = $this._implodeValues($this.paramsSelected['equipments_selected'], 'cod_equipment');
              $this._searchMachines(() => {          
                $this.paramsSelected['machines_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_machine || '') + "]"), 'cod_machine');
                $this.paramsSelected['machines'] = $this._implodeValues($this.paramsSelected['machines_selected'], 'cod_machine');
                this.firstLoad = false;
              }, false);
            }, false);
          }, false);
        }, false);
      }, false);
      $this._searchCategory(() => {
        $this.paramsSelected['categories_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_category || '') + "]"), 'cod_category');
      }, false);

      $this._searchStep(() => {
        $this.paramsSelected['step_selected'] = $this._arrayToJson(JSON.parse("[" + ($this.usersFilters.arr_cod_steps || '') + "]"), 'cod_fivewhys_steps');
        $this.paramsSelected['steps'] = this._implodeValues($this.paramsSelected['step_selected'], 'cod_fivewhys_steps');
      }, false);
      
      $this.paramsSelected['status'] = $this.usersFilters.arr_cod_status;
      $this.paramsSelected['status_selected'] = [];
      let statusArr = $this.paramsSelected['status']?.split(',') || [];
      statusArr = statusArr.length > 0 ? statusArr : [];
      $this.statusList.map((status) => {
        statusArr.map((arr) => {
          if (status.cod_fivewhys_status == arr) {
            $this.paramsSelected['status_selected'].push(status);
          }
        });
      });
      
    }
    else {
      let $this = this;
      $this._searchLocations(() => {
        if(!$this.usersFilters.arr_cod_location){
          $this.paramsSelected['locations_selected'] = $this._arrayToJson(JSON.parse("[" + this.locationUser + "]"), 'cod_locations');
          } else {
          $this.paramsSelected['locations_selected'] = $this._arrayToJson(JSON.parse("[" + $this.usersFilters.arr_cod_location + "]"), 'cod_locations');
        }    
        $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_locations');
        $this._searchCrews(() => {
          $this.paramsSelected['crew'] = $this._implodeValues($this.crewList, 'cod_crews');
          $this.paramsSelected['crew_selected'] = $this.crewList;               
        });
        $this._searchAreas(() => {
          $this.paramsSelected['areas'] = $this._implodeValues($this.areaList, 'cod_area');
          $this.paramsSelected['areas_selected'] = $this.areaList;
          $this._searchSubAreas(() => {
            $this.paramsSelected['subareas'] = $this._implodeValues($this.subAreasList, 'cod_subareas');
            $this.paramsSelected['subareas_selected'] = $this.subAreasList;
            $this._searchEquipments(() => {
              $this.paramsSelected['equipments'] = $this._implodeValues($this.equipmentList, 'cod_equipment');
              $this.paramsSelected['equipments_selected'] = $this.equipmentList;
              $this._searchMachines(() => {
                $this.paramsSelected['machines'] = $this._implodeValues($this.machineList, 'cod_machine');
                $this.paramsSelected['machines_selected'] = $this.machineList;
                this.firstLoad = false;
                $this.pageIndex = history.state.data?.page ? history.state.data.page : 1;
                $this.search(false);
                $this._getHashtags();
              });
            });
          });
        });
      });

      $this._searchCategory(() => {
        $this.paramsSelected['cateogires'] = $this._implodeValues($this.categoriesList, 'cod_category');
        $this.paramsSelected['cateogories_selected'] = $this.categoriesList;
      });


      $this._searchStep(() => {
        $this.paramsSelected['steps'] = $this._implodeValues($this.stepList, 'cod_fivewhys_steps');
        $this.paramsSelected['step_selected'] = $this.stepList;
      });

      $this.paramsSelected['status'] = $this._implodeValues($this.paramsSelected['status_selected'], 'cod_fivewhys_status');
      $this.paramsSelected['status_selected'] = $this.statusList;
    }
  }

  private _clearEquipmentList() {
    this.paramsSelected['equipments'] = '';
    this.paramsSelected['equipments_selected'] = [];
    this.equipmentList = [];
    this._clearMachinesList();
  }

  private _smartClearEquipmentList() {

    if (this.paramsSelected['equipments_selected'] && this.paramsSelected['equipments_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['equipments_selected'].map((selected: { cod_equipment: any; }) => {
        this.equipmentList.map((equip) => {
          if (equip.cod_equipment == selected.cod_equipment) arr.push(equip);
        });
      });
      this.paramsSelected['equipments_selected'] = arr;
      this.paramsSelected['equipments'] = this._implodeValues(this.paramsSelected['equipments_selected'], 'cod_equipment');
    }
    if (!this.paramsSelected['equipments_selected'] || this.paramsSelected['equipments_selected'].length == 0) {
      this.paramsSelected['equipments_selected'] = [];
      this.paramsSelected['equipments'] = '';
      this._clearMachinesList();
    } else if(!this.firstLoad) {
      this._searchMachines(null);
    }

  }

  private _clearLocationList() {
    this.paramsSelected['locations'] = '';
    this.paramsSelected['locations_selected'] = [];
    this.locationsList = [];
    this._clearAreaList();
  }

  private _clearMachinesList() {
    this.paramsSelected['machines'] = '';
    this.paramsSelected['machines_selected'] = [];
    this.machineList = [];
    this.isLoading = false;
  }

  private _smartClearMachinesList() {
    if (this.paramsSelected['machines_selected'] && this.paramsSelected['machines_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['machines_selected'].map((selected: { cod_machine: any; }) => {
        this.machineList.map((machine) => {
          if (machine.cod_machine == selected.cod_machine) arr.push(machine);
        });
      });
      this.paramsSelected['machines_selected'] = arr;
      this.paramsSelected['machines'] = this._implodeValues(this.paramsSelected['machines_selected'], 'cod_machine');
    }
    if (!this.paramsSelected['machines_selected'] || this.paramsSelected['machines_selected'].length == 0) {
      this.paramsSelected['machines_selected'] = [];
      this.paramsSelected['machines'] = '';
    }
  }

  private _clearAreaList() {
    this.paramsSelected['areas'] = '';
    this.paramsSelected['areas_selected'] = [];
    this.areaList = [];
    this._clearSubAreaList();
  }

  private _smartClearAreaList() {

    if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
        this.areaList.map((area) => {
          if (area.cod_area == selected.cod_area) arr.push(area);
        });
      });
      this.paramsSelected['areas_selected'] = arr;
      this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
    } 
    if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
      this.paramsSelected['areas_selected'] = [];
      this.paramsSelected['areas'] = '';
      this._clearSubAreaList();
    } else if(!this.firstLoad) { 
      this._searchSubAreas(null);
    }

  }

  private _clearSubAreaList() {
    this.paramsSelected['subareas'] = '';
    this.paramsSelected['subareas_selected'] = [];
    this.paramsSelected['equipments'] = '';
    this.subAreasList = [];
    this._clearEquipmentList();
  }

  private _smartClearSubAreaList() {

    if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
        this.subAreasList.map((subarea) => {
          if (subarea.cod_subareas == selected.cod_subareas) arr.push(subarea);
        });
      });
      this.paramsSelected['subareas_selected'] = arr;
      this.paramsSelected['subareas'] = this._implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
    }
    if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
      this.paramsSelected['subareas_selected'] = [];
      this.paramsSelected['subareas'] = '';
      this._clearEquipmentList();
      this._clearCategoryList();
    } else if(!this.firstLoad) {
      this._searchEquipments(null);
      this._searchCategory(null);
    }
    
  }

  private _clearCategoryList() {
    this.paramsSelected['categories'] = '';
    this.paramsSelected['categories_selected'] = [];
    this.categoriesList = [];
  }

  private _clearStepList() {
    this.paramsSelected['steps'] = '';
    this.paramsSelected['step_selected'] = [];
    this.stepList = [];
  }

  private _clearCrewList() {
    this.paramsSelected['crew'] = '';
    this.paramsSelected['crew_selected'] = [];
    this.crewList = [];
  }

  selectLocations($event) {
    this.paramsSelected['locations_selected'] = $event.selectedOptions;
    this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_locations');
    this._searchAreas(null);
    this._searchCrews(null);
    this._clearSubAreaList()
    this._clearAreaList()
    this._clearCategoryList()
    this._clearEquipmentList()
    this._clearMachinesList()
    this._clearCrewList()
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);    
    this.changePage(1);
    this.setFilterLocalStorage();
    this.getFilterLocalStorage();
  }

  selectAreas($event) {
    this.paramsSelected['areas_selected'] = $event.selectedOptions;
    this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
    this._searchSubAreas(null);
    this._clearSubAreaList()
    this._clearCategoryList()
    this._clearEquipmentList()
    this._clearMachinesList()
  }

  selectSubAreas($event) {
    this.paramsSelected['subareas_selected'] = $event.selectedOptions;
    this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
    this._searchEquipments(null);
    this._searchCategory(null);
    this._clearCategoryList()
    this._clearEquipmentList()
    this._clearMachinesList()
  }

  selectMachines($event) {
    this.paramsSelected['machines_selected'] = $event.selectedOptions;
    this.paramsSelected['machines'] = $event.stringIds;
  }

  selectEquipments($event) {
    this.paramsSelected['equipments_selected'] = $event.selectedOptions;
    this.paramsSelected['equipments'] = this._implodeValues($event.selectedOptions, 'cod_equipment');
    this._searchMachines(null);
    this._clearMachinesList()
  }

  selectCategories($event) {
    this.paramsSelected['categories'] = this._implodeValues($event.selectedOptions, 'cod_category');
  }

  selectStep($event) {
    this.paramsSelected['step_selected'] = $event.selectedOptions;
    this.paramsSelected['steps'] = this._implodeValues($event.selectedOptions, 'cod_fivewhys_steps');
  }

  selectStatus($event) {
    this.paramsSelected['status_selected'] = $event.selectedOptions;
    this.paramsSelected['status'] = this._implodeValues($event.selectedOptions, 'cod_fivewhys_status');
  }

  selectCrew($event) {
    this.paramsSelected['crew_selected'] = $event.selectedOptions;
    this.paramsSelected["crew"] = this._implodeValues($event.selectedOptions, 'cod_crews');
  }

  setFilterLocalStorage(){
    this.obj_filter = {
      value_initial_date: this.initial_date,
      value_final_date: this.final_date,
      value_offiset: this.resultsPerPageSelected,
      value_limit: this.resultsPerPageSelected,
      timestamp: this.date.getTime()
    };
    localStorage.setItem('filter', JSON.stringify(this.obj_filter));
  }
  
  getFilterLocalStorage(){
    this.now = new Date().getTime()
    this.get_filter = JSON.parse(localStorage.getItem('filter'));
    if (!JSON.parse(localStorage.getItem('filter'))  || ((this.now - parseInt(this.get_filter.timestamp)) > 1000*3600*12)){
      this.offset = this.resultsPerPageSelected;
      this.limit = this.resultsPerPageSelected;
      this.final_date = moment(this.final_date).format('YYYY-MM-DD');
      this.initial_date = moment(this.initial_date).subtract(1, 'month').format('YYYY-MM-DD');
    } else {
      this.offset = this.get_filter.value_offiset;
      this.limit = this.get_filter.value_limit;
      this.final_date = this.get_filter.value_final_date;
      this.initial_date = this.get_filter.value_initial_date;
    }
  }
  
  search(reset: boolean, isSorting: boolean  = false) {
    if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
      return;
    }

    this.isLoading = true;
    let $this = this;
    clearTimeout(this.searchTimer);
    
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.searchFiveWhy(false, isSorting);
    }, environment().debounceTime);
  }

  getCharts() {
    this.showCharts = false;
    const params = {
      cod_user: this.codUser,
      arr_cod_locations: this.paramsSelected['locations']?.toString().split(',').toString(),
      arr_cod_areas: this.paramsSelected['areas']?.toString().split(',').toString(),
      arr_cod_subareas: this.paramsSelected['subareas']?.toString().split(',').toString(),
      arr_cod_equipments: this.paramsSelected['equipments']?.toString().split(',').toString(),
      arr_cod_machines: this.paramsSelected['machines']?.toString().split(',').toString(),
      arr_cod_category: this.paramsSelected['categories']?.toString().split(',').toString(),
      arr_cod_status: this.paramsSelected['status']?.toString().split(',').toString(),
      arr_cod_step: this.paramsSelected['steps']?.toString().split(',').toString(),
      arr_cod_crew: this.paramsSelected['crew']?.toString().split(',').toString(),
      search_term: this.searchTerm,
      dat_from: this.initial_date,
      dat_to: this.final_date
    }

    forkJoin(
      {
        escaleted_ab_report: this._chartsService.getEscAbReportChart(params),
        sdcaMatrixCharts: this._chartsService.getSdcaMatrixChart(params),
        completionTime: this._chartsService.getCompletionTimeChart(params),
        repeatingProblem: this._chartsService.getRepeatingProblemChart(params),
        actionStatusChart: this._chartsService.getActionStatusChart(params),
      }
    ).subscribe({
      next: (data) => {
        this.escaletedAbReportData = data.escaleted_ab_report.content.results[0].fivewhys_sp_chart_escalated_abnormality;
        this.sdcaMatrixData = data.sdcaMatrixCharts.content.results[0].fivewhys_sp_chart_sdca_matrix;
        this.completionTimeData = data.completionTime.content.results[0].fivewhys_sp_chart_completion_time;
        this.repeatingProblemChartData = data.repeatingProblem.content.results[0].fivewhys_sp_chart_repeating_problem;
        this.actionStatusChartData = data.actionStatusChart.content.results;
        this.showCharts = true;       
      
      }, 
      error: err => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    });
  }

  beforeSearchFiveWhy(){
    if(this.search_button?.nativeElement) this.search_button.nativeElement.blur()
    setTimeout(() => {
      this.pageIndex = 1;
      this.searchFiveWhy(true)
    },200);
  }

  searchFiveWhy(resetHashtags = false, isSorting  = false) {

    if(!isSorting) {
      if (resetHashtags) {
        this._getHashtags();
      }
    }
    this.getCharts();
    this.getAnalyticInfo();

    let tmpPage = this.pageIndex || 0;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    

    this.isLoading = true;

    let objParam = {
      'limit': this.limit,
      'offset': tmpPage,
      'cod_user': this._authService.getAuthenticatedUser().id,
      'dsc_page_privilege': '5WHY_MENU_VISUALIZE',
      'cod_location': this.paramsSelected['locations'],
      'cod_equipment': this.paramsSelected['equipments'],
      'cod_machine': this.paramsSelected['machines'],
      'cod_category': this.paramsSelected['categories'],
      'cod_area': this.paramsSelected['areas'],
      'cod_subarea': this.paramsSelected['subareas'],
      'cod_steps': this.paramsSelected['steps'],
      'cod_crews': this.paramsSelected['crew'],
      'cod_status': this.paramsSelected['status'],
      'dat_from': this.initial_date,
      'dat_to': this.final_date,
      'userlang': this._userPreferenceService.getLocalLanguage(),
      'search_term': this.searchTerm,
      'sort_asc': this.tableSort.sortAsc,
      'sort_header': this.tableSort.sortHeaderSelected
    };
    this._fiveWhyService.getFiveWhys(objParam).subscribe({
      next: data => {
        if (typeof data.content.fiveWhysList != typeof undefined && data.content.fiveWhysList) {
          this.fiveWhyList = data.content.fiveWhysList.results;
          this._updateDownlodParams();
          this.maxPage = Math.ceil( (this.fiveWhyList.length > 0 ? this.fiveWhyList[0].full_count: 0) / this.offset);
          this.totalPages = this.pagination(this.pageIndex, this.maxPage);
        }
        this.isLoading = false;
      }, 
      error: err => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    });
  }

  formatRating(rating: string): string {
    const value = parseFloat(rating);
    if (isNaN(value)) return '';
    return value?.toFixed?.(1) + '%' || '';
  }

  convertDate(date) {
    return (date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2));
  }

  getStatusProgressById(fivewhy_status_progress, cod_fivewhy_status) {
    return this._fiveWhyService.getStatusById(fivewhy_status_progress, cod_fivewhy_status);
  }

  getStatusBlocked() {
    return this.fiveWhyList.fivewhys_blocked_status 
  }

  getFlowClass(fivewhy, index) {
    return this._fiveWhyService.getStatusColor(fivewhy.bol_canceled, fivewhy.cod_fivewhys_status, fivewhy.fivewhy_status_progress, index);
  }

  private _searchSubAreas(fn, clearList: boolean =  true) {

    if (typeof this.paramsSelected['areas'] != typeof undefined &&
      typeof this.paramsSelected['locations'] != typeof undefined &&
      this.paramsSelected['areas'].toString() != '' &&
      this.paramsSelected['locations'].toString() != '') {
      this._setLoading('subareas', true);
      let params = {
        arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
        arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
        cod_user: this._authService.getAuthenticatedUser().id
      };
      this._fiveWhyService.getFilteredAllowedSubareas(params).subscribe({
        next: data => {
          this.subAreasList = this._userService.getArrayContent(data);
          fn?.();
          this._setLoading('subareas', false);
          this._smartClearSubAreaList();
        }, error: err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearSubAreaList();
    }

  }

  private _searchAreas(fn, clearList: boolean =  true) {

    if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
      this._setLoading('areas', true);
      let params = {
        arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
        cod_user: this._authService.getAuthenticatedUser().id,
        dsc_page_privilege: '5WHY_MENU_VISUALIZE'
      };
      this._fiveWhyService.getFilteredAllowedAreas(params).subscribe({
        next: data => {
          this.areaList = this._userService.getArrayContent(data);
          fn?.();
          this._setLoading('areas', false);
          this._smartClearAreaList();
        }, error: err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearAreaList();
    }

  }

  private _searchLocations(fn, clearList: boolean =  true) {
    if(clearList) this._clearLocationList();
    let params = {
      term: '',
      cod_user: this._authService.getAuthenticatedUser().id,
      dsc_page_privilege: '5WHY_MENU_VISUALIZE'
    }
    this._setLoading('locations', true);
    this._fiveWhyService.getAllLocationsByPrivileges(params).subscribe({
      next: data => {
        this.locationsList = data.content.results;
        fn?.();
        this._setLoading('locations', false);
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  private _searchMachines(fn, clearList: boolean =  true) {

    if (typeof this.paramsSelected['locations'] != typeof undefined &&
      typeof this.paramsSelected['areas'] != typeof undefined &&
      typeof this.paramsSelected['subareas'] != typeof undefined &&
      typeof this.paramsSelected['equipments'] != typeof undefined &&
      this.paramsSelected['locations'].toString() != '' &&
      this.paramsSelected['areas'].toString() != '' &&
      this.paramsSelected['subareas'].toString() != '' &&
      this.paramsSelected['equipments'].toString() != '') {
      let params = {
        cod_user: this._authService.getAuthenticatedUser().id,
        cod_equipments: this.paramsSelected['equipments'].toString(),
        cod_areas: this.paramsSelected['areas'].toString(),
        cod_subareas: this.paramsSelected['subareas'].toString(),
        cod_locations: this.paramsSelected['locations'].toString(),
        str_filter: 'IS_FILTER'
      }
      this._setLoading('machines', true);
      this._machineService.getListMachines(params).subscribe({
        next: data => {
          this.machineList = this._userService.getArrayContent(data).filter((machine) => {return machine.bool_fivewhys === true});
          this._setLoading('machines', false);
          fn?.();
          this._smartClearMachinesList();
        },
        error: err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearMachinesList();
    }
  }

  private _searchEquipments(fn, clearList: boolean =  true) {

    if (typeof this.paramsSelected['subareas'] != typeof undefined && this.paramsSelected['subareas'].toString() != '') {
      let params = {
        cod_user: this._authService.getAuthenticatedUser().id,
        cod_areas: this.paramsSelected["areas"].toString(),
        cod_subareas: this.paramsSelected["subareas"].toString(),
        cod_locations: this.paramsSelected["locations"].toString(),
        str_filter: 'IS_FILTER'
      }
      this._setLoading('equipments', true);
      this._machineService.getListEquipmentsWithMachines(params).subscribe({
        next: (data) => {
          this.equipmentList = this._userService.getArrayContent(data).filter((equip) => {return equip.bool_fivewhys === true});
          this._setLoading('equipments', false);
          fn?.();
          this._smartClearEquipmentList();
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        },
      });
    } else {
      this._clearEquipmentList();
    }

  }

  private _searchCategory(fn, clearList: boolean =  true) {
    if(clearList) this._clearCategoryList();
    let params = {
      cod_user: this._authService.getAuthenticatedUser().id
    }
    this._triggerService.listCategories(params).subscribe({
      next: data => {
        this.categoriesList = this._userService.getArrayContent(data);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  
  private _searchStep(fn, clearList: boolean =  true) {
    if(clearList) this._clearStepList();
    let params = {
      cod_user: this._authService.getAuthenticatedUser().id
    }
    this._fiveWhyService.listSteps(params).subscribe({
      next: data => {
        this.stepList = this._userService.getArrayContent(data);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      }, 
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }


  private _searchCrews(fn, clearList: boolean =  true) {
    if(clearList) this._clearCrewList();
    let params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.paramsSelected['locations']
    }
    this._fiveWhyService.getLocationsCrewsByArray(params).subscribe({
      next: (data) => {
        this.crewList = this._userService.getArrayContent(data);
        fn?.();
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      },
    });
  }

  private _updateDownlodParams() {

    const observables = [
      '5WHY.REF',
      '5WHY.DATE',
      // 'LOGBOOK.WORLDS',
      // 'LOGBOOK.REGIONALS',
      '5WHY.LOCATION',
      '5WHY.AREA',
      '5WHY.SUBAREA',
      '5WHY.EQUIPMENT',
      '5WHY.MACHINE',
      '5WHY.CATEGORY',
      '5WHY.TRIGGER',
      '5WHY.TITLE',
      '5WHY.STATUS',
      '5WHY.CURRENT_OWNER', // NEW ONE
      '5WHY.ROOT_CAUSE',
      '5WHY.CREATED_BY',
      '5WHY.ROOT_CAUSE_DATE',
      '5WHY.RATING'
    ];
    let arrHeaders = []
    this._translateService.get(observables).subscribe(translations => {
      for(const key of observables){
        arrHeaders.push(translations[key])
      }
      this.downloadCsvSetup = {
        cod_user: this._authService.getAuthenticatedUser().id,
        api_url: this._reportsApiUrl,
        str_proc_exec: 'fivewhys_sp_ex_fivewhys',
        arr_proc_params_search: [
          this._authService.getAuthenticatedUser().id,
          this.paramsSelected['locations'],
          this.paramsSelected['areas'],
          this.paramsSelected['subareas'],
          this.paramsSelected['equipments'],
          this.paramsSelected['machines'],
          this.paramsSelected['categories'],
          this.paramsSelected['status'],
          this.paramsSelected['steps'],
          this.initial_date,
          this.final_date,
          this.searchTerm,
          '5WHY_MENU_VISUALIZE'
          //userlang is added on reportWorker when doing the query -- do not add it here
        ],
        arr_headers: arrHeaders,
        name_of_file_to_download: 'problem_terminator_',
        str_column_dynamic: ''
      };
    });
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  private _arrayToJson(obj, idField = '') {
    let rObj = [];
    if (obj.length > 0) {
      obj.forEach(element => {
        rObj.push(JSON.parse('{ "' + idField + '" : ' + element + '}'));
      });
    }
    return rObj;
  }

  changePage(page) {
    this.pageIndex = page;
    this.search(false);
  }

  pagination(currentPage, nrOfPages) {
    let delta = 2, range = [], rangeWithDots = [], l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }
  openFiveWhyDetails(fivewhyId = null) {
    this.onOpenFiveWhyDetails.emit(fivewhyId);
  }
  
  searchHashtag(): void {
    this.arr_opt_hashtag = [];

    if (this.searchTerm.indexOf('#') != -1) {
      let str_part_hashtag = this.searchTerm.substring(this.searchTerm.lastIndexOf('#') + 1);
      
      for (const element of this.arr_hashtag) {

        if (element.toLowerCase().lastIndexOf(str_part_hashtag.toLowerCase()) != -1) {
          this.arr_opt_hashtag.push(element);
        }

      }

    }

  }

  onSelectedHashtag(str_opt_hashtag: string): void {
    let str_action = this.searchTerm;
    
    this.searchTerm = `${str_action.substring(0, str_action.lastIndexOf('#'))}${str_opt_hashtag}`;
    this.arr_opt_hashtag = [];

    this.search_component.nativeElement.focus();
    this.search(true);
  }

  _getHashtags() {

    let objParam = {
      'cod_user': this._authService.getAuthenticatedUser().id,
      'dsc_page_privilege': '5WHY_MENU_VISUALIZE',
      'cod_location': this.paramsSelected['locations'],
      'cod_equipment': this.paramsSelected['equipments'],
      'cod_machine': this.paramsSelected['machines'],
      'cod_area': this.paramsSelected['areas'],
      'cod_subarea': this.paramsSelected['subareas'],
      'cod_steps': this.paramsSelected['steps'],
      'cod_status': this.paramsSelected['status'],
      'dat_from': this.initial_date,
      'dat_to': this.final_date,
    };

    this._fiveWhyService.listHashtags(objParam).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.arr_hashtag = data.content.results?.map(h => h?.str_hashtag);
      }
    });    
  }

  headerSortBy(columnToSortBy: SortHeaderFivewhyList | string ) {
    let isAlreadySelected = columnToSortBy == this.tableSort.sortHeaderSelected;
    this.tableSort.sortHeaderSelected = columnToSortBy as SortHeaderFivewhyList;
    this.tableSort.sortAsc = isAlreadySelected ? !this.tableSort.sortAsc : false;

    this.searchFiveWhy(false, true);
  }

  getAnalyticInfo(){
    this.showAnalytics = false;
    const params = {
      cod_user: this.codUser,
      arr_cod_locations: this.paramsSelected['locations']?.toString().split(',').toString(),
      arr_cod_areas: this.paramsSelected['areas']?.toString().split(',').toString(),
      arr_cod_subareas: this.paramsSelected['subareas']?.toString().split(',').toString(),
      arr_cod_equipments: this.paramsSelected['equipments']?.toString().split(',').toString(),
      arr_cod_machines: this.paramsSelected['machines']?.toString().split(',').toString(),
      arr_cod_category: this.paramsSelected['categories']?.toString().split(',').toString(),
      arr_cod_status: this.paramsSelected['status']?.toString().split(',').toString(),
      arr_cod_step: this.paramsSelected['steps']?.toString().split(',').toString(),
      arr_cod_crew: this.paramsSelected['crew']?.toString().split(',').toString(),
      search_term: this.searchTerm,
      dat_from: this.initial_date,
      dat_to: this.final_date
    }
    this._fiveWhyService.getAnalyticInfo(params).subscribe({
      next: (data) => {
        if(data.content.length == 1){
          this.analyticInfoData = data.content.results[0];
          this.showAnalytics = this.analyticInfoData.total_count !== 0;
        }
      },
      error: (err) => {
        console.log(err.status, err);
        this.showAnalytics = false;
      }
    });
  }

}
