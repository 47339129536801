export class Routine {
    str_name: string;
    str_description: string;
    cod_form: number;
    cod_periodicity: string;
    val_day_of_week: string;
    val_week_of_month: string;
    cod_profile: number;
    is_segambev: number;
    cod_logbook: number;
    cod_routines: number;
    dat_ins: Object;
    dat_alt?: Object;
    dat_finish?: Object;
    dat_del: Object;
    forms: Object[];
    pillars: Object;
    hyperlinks: Object[];
    form_questions: any[];
    str_form_name: string;
    cod_world: number;
    arr_cod_shifts: any;
    int_order: number;
    bol_mandatory: boolean;
    bol_is_custom: boolean;
    str_acadia_document: string;
    bol_sop_reaction_plan_training_document: boolean;
    cod_logbook_routines_periodicity: number;
    cod_logbook_routines_periodicity_users: number;
    cod_logbook_routines_periodicity_users_done?: number;
    cod_logbook_routines_periodicity_users_collaborative_done?: number;
    cod_routine_status: number;
    cod_acadia: number;
    str_acadia_task_list_url: string;
    bol_acadia_task_list_created: boolean;
    int_acadia_tasks_count: number;
    int_acadia_completion_count: number;
    int_acadia_skipped_count: number;
    int_acadia_completion_percent: number;
    str_acadia_document_language: string;
    bol_acadia_document_language_equals_ial_language: boolean;
    bol_acadia_task_list_finished: boolean;
    date_valid: Date;
    cod_shift?: number;
    bol_type_collab?: boolean;
    date_limit?: Date;
    qtd_dias: number;
    routine_hyperlinks?: any[];
    str_user_name?: string;

    constructor(cod_logbook: number) {
        this.cod_routines = 0;
        this.cod_logbook = cod_logbook;
        this.cod_profile = null;
        this.cod_periodicity = '';
        this.str_name = '';
        this.str_description = '';
        this.cod_form = null;
        this.dat_ins = {};
        this.dat_del = {};
        this.is_segambev = 0;
        this.val_day_of_week = '0';
        this.val_week_of_month = '0';
        this.forms = [];
        this.pillars = [];
        this.hyperlinks = [];
        this.form_questions = [];
        this.str_form_name = '';
        this.cod_world = null;
        this.arr_cod_shifts = [];
        this.int_order = 0;
        this.bol_mandatory = true;
        this.bol_is_custom = false;
        this.str_acadia_document = null;
        this.bol_sop_reaction_plan_training_document = false;
        this.cod_logbook_routines_periodicity = null;
        this.cod_logbook_routines_periodicity_users = null;
        this.cod_routine_status = null;
        this.cod_acadia = null;
        this.str_acadia_task_list_url = null;
        this.bol_acadia_task_list_created = false;
        this.int_acadia_tasks_count = null;
        this.int_acadia_completion_count = null;
        this.int_acadia_skipped_count = null;
        this.int_acadia_completion_percent = null;
        this.str_acadia_document_language = null;
        this.bol_acadia_document_language_equals_ial_language = null;
        this.bol_acadia_task_list_finished = false;
        this.date_valid = null;
        this.qtd_dias = null;
    }
}

export enum RoutineType {
    Acadia,
    Form,
    MarkDone
}