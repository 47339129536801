import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {MyTeamTopic1Component} from './my-team-topic-1/my-team-topic-1.component';
import {MyTeamTopic2Component} from './my-team-topic-2/my-team-topic-2.component';

@NgModule({
    imports: [
CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [MyTeamTopic1Component, MyTeamTopic2Component],
    declarations: [MyTeamTopic1Component, MyTeamTopic2Component],
    providers: []
})
export class InfoMyTeamModule {
}
