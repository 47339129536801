export class Translation {
    str_de: string;
    str_es: string;
    str_fr: string;
    str_nl: string;
    str_pt: string;
    str_ru: string;
    str_ua: string;
    str_zh: string;
    str_ko: string;
    str_vi: string;

    constructor(
        de: string = '',
        es: string = '',
        fr: string = '',
        nl: string = '',
        pt: string = '',
        ru: string = '',
        ua: string = '',
        zh: string = '',
        ko: string = '',
        vi: string = ''
    ) {
        this.str_de = de;
        this.str_es = es;
        this.str_fr = fr;
        this.str_nl = nl;
        this.str_pt = pt;
        this.str_ru = ru;
        this.str_ua = ua;
        this.str_zh = zh;
        this.str_ko = ko;
        this.str_vi = vi;
    }
}