import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {ListEquipmentsService} from './services/list-equipments.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-equipments',
    templateUrl: './list-equipments.component.html',
    styleUrls: ['./list-equipments.component.css']    
})

export class ListEquipmentsComponent implements OnChanges, OnInit {
    
    equipmentList: any = [];        
    
    @Input() isDisabled: Boolean;
    @Input() isMultiple: Boolean;
    @Input() subAreaSelected: any[];
    @Input() selectedValue: any[];
    @Output() selectedValueChange = new EventEmitter<any[]>();
    @Output() blur = new EventEmitter<any>();
    
    constructor(private _listEquipmentsService: ListEquipmentsService, private _authService:AuthService) {        
        this.subAreaSelected = [];
    } 
    
    ngOnInit(){
        this.searchEquipments();        
        if(!this.selectedValue){
            this.selectedValue = [];
        }        
    }
    
    ngOnChanges(changes: SimpleChanges) {      
        const subAreaSelected : SimpleChange = changes.subAreaSelected;
        
        if(subAreaSelected && subAreaSelected.currentValue){     
            
            if(subAreaSelected.currentValue.length > 0){
                this.searchEquipments();
            }
        }
        
    }    
    
    selectEquipments($event) {
        this.selectedValue = $event.selectedOptions;        
        this.selectedValueChange.emit(this.selectedValue);
        this.blur.emit();
    }
    
    
    searchEquipments() {
        if(this.subAreaSelected){                        
            if (this.subAreaSelected.length > 0) {     
                let intLocation = 0;
                //Search for equipments
                
                if(this._authService.getAuthenticatedUser().cod_location > 0){
                    intLocation = this._authService.getAuthenticatedUser().cod_location;                    
                }
                

                let objParam = {
                    'cod_subareas':this.implodeValues(this.subAreaSelected, 'cod_subareas'),   
                    'cod_locations': intLocation,                                             
                    'term': '',
                    'cod_user': this._authService.getAuthenticatedUser().id,
                };
                this._listEquipmentsService.getEquipments(objParam).subscribe(data => {
                    this.equipmentList = this._listEquipmentsService.getArrayContent(data);                
                },
                err => { this._authService.errCheck(err); console.log(err); }
                );
            }
        }
    }    
    
    implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }    
    
}
