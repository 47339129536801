import {TranslateService} from '@ngx-translate/core';

export class WeekDay {
    id: string;
    description: string;

    constructor(id = '', description = '', private _translateService: TranslateService) {
        this.id = id;
        this._translateService.get(description).subscribe(translate => {
            this.description = translate;
        });
    }
}