import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnChanges, Input, HostListener, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FiveWhyService } from "../services/five-why.service";
import { AuthService } from "app/main/access/services/auth.service";
import { environment } from '../../../environments/environment';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import { MeetingsService } from 'app/meetings/services/meetings.service';
import { Meeting } from 'app/meetings/models/meetings.model';
import { ValidationsService } from "../services/validations.service";
import { ActionsService } from 'app/meetings/services/actions.service';

@Component({
  selector: 'app-fivewhy-actions',
  templateUrl: './fivewhy-actions.component.html',
  styleUrls: ['./fivewhy-actions.component.css']
})
export class FiveWhyActionsComponent implements OnInit, OnChanges {

  @Input('showSaveActions') showSaveActions;
  @Input('actionTempObj') actionTempObj;
  @Input('disabled') disabled;
  @ViewChild('str_description_component') str_description_component: ElementRef;
  
  cod_user: any;
  paramsSelected: any = [];
  meetingsActivateList: any = [];

  obj_owner: any;
  obj_ta_meeting: any;
  obj_meeting: Meeting;

  bol_no_owner: boolean = false;
  isVisible: boolean = true;
  showError: boolean;
  showComponentAddAction = false;
  validAction: boolean = false;

  fivewhy_sdca_description: string;
  str_user_tmp: string;
  str_name: string;
  action_status: string;
  errorMessage: string;

  action_description: string;
  action_due_date: string;
  meetings_description: string;

  arr_opt_hashtag: any[] = [];
  arr_hashtag: any[] = [];

  default_hashtags_length: number = 0;

  public str_description: string;
  public str_owner: string;
  public dat_due: string;
  public str_description_sdca: string;

  widthToCopy: string;

  actions: Array<any> = [];

  actionStatus: string;

  constructor(
    private _fivewhyService: FiveWhyService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _userPreferencesService: UserPreferencesService,
    private _meetingsService: MeetingsService,
    public validationService: ValidationsService,
    private _actionsService: ActionsService,
  ) {
    this.cod_user = this._authService.getAuthenticatedUser().id;

    this.str_user_tmp = "";
    this.obj_meeting = new Meeting();
    this.isVisible = false;

    this.obj_owner = {
      cod_user: 0,
      str_name: '',
      str_img_path: ''
    };

    this._translateService.get('MEETING.USER_SEL').subscribe(translate => {
      this.obj_ta_meeting = {
        type: 'dynamic',
        placeHolder: translate,
        valueProperty: 'cod_user',
        searchProperty: 'str_name',
        hasImage: true,
        imageProperty: 'str_img_path',
        onSelect: (obj_selected: any) => {
          this.obj_owner = {
            cod_user: obj_selected.cod_user,
            str_name: obj_selected.str_name,
            str_img_path: obj_selected.str_img_path
          };
          this.searchMeetings();
          this.str_user_tmp = '';
        },
        asynchDataCall: (value: string, cb: any) => {
          this.searchUsers(cb, value);
        },
        onBlur: (item: any) => {
          this.str_user_tmp = '';
        },
      };
    });
  }

  ngOnInit() {
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
    this.cod_user = this._authService.getAuthenticatedUser().id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showSaveActions || changes.actionTempObj) {
      this.buildActions();
    }
  }

  private buildActions() : void {
    this.actions = [...this.showSaveActions.arr_action, ...this.actionTempObj.arr_action];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // work around to maintain same width - get css props from add whys button and copy width every time screen size changes.
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
  }

  private clearMeetings() {
    this.meetingsActivateList = [];
    this.paramsSelected['meetings'] = '';
    this.paramsSelected['meeting_selected'] = [];
  }

  private clearFieldsAction() {
    this.obj_owner = {
      cod_user: 0,
      str_name: '',
      str_img_path: ''
    };
    this.action_description = '';
    this.meetings_description = '',
      this.str_description_sdca = '',
      this.action_due_date = ''

    this.checkDefaultHashtags();
  }

  selectSdca($event) {
    this.paramsSelected['sdcaMatrix'] = this._implodeValues($event.selectedOptions, 'cod_sdca');
    this.fivewhy_sdca_description = this._implodeValues($event.selectedOptions, 'str_description_from');
  }

  selectMeetings($event) {
    this.paramsSelected['meetings'] = this._implodeValues($event.selectedOptions, 'cod_meetings');
    this.meetings_description = this._implodeValues($event.selectedOptions, 'str_name');
    this.isValidAction();
    this.getSelectedMeetingDetails();
  }

  private searchMeetings() {
    let objParam = {
      'cod_user': this.cod_user,
      'cod_user_search': this.obj_owner.cod_user
    };
    this._meetingsService.getMeetingsByUsersList(objParam).subscribe(data => {
      if (data.content.code === 888 && data.code === 888) {
        this.meetingsActivateList = this._meetingsService.getArrayContent(data);
      }
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      });
  }

  searchUsers(cb, str_term) {
    const $this = this;
    this.clearMeetings();
    this._meetingsService.getUsersList(str_term, environment().uploadGetFilesApiUrl + '/', this.cod_user, '5WHY_MENU_VISUALIZE').subscribe((result: any) => {
      cb(result.content.results);
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
  }

  addAction() {
    this.showComponentAddAction = true;
    this.clearFieldsAction();
  }
  
  saveRootCause() {
    this.checkDefaultHashtags();

    this.showComponentAddAction = false;

    const actionStatus = new Promise((resolve, reject) => {
      this._translateService.get('5WHY.ADD_ACTION').subscribe((translation) => {
        this.actionStatus = translation;
        resolve('Success');
      }, (err) => reject(err));
    });

    const selectedDate = new Date(this.action_due_date + 'T00:00:00');
    let datFormat = this._userPreferencesService.getLocalDateFormat();
    datFormat = datFormat ? datFormat : 'MM/DD/YYYY';
    let display_due_date = '';
    if(datFormat == 'DD/MM/YYYY'){
      display_due_date = (('0' + (selectedDate.getDate())).slice(-2) + '/' + ('0' + (selectedDate.getMonth() + 1)).slice(-2) + '/' + selectedDate.getFullYear());
    }
    else{//'MM/DD/YYYY'
      display_due_date = (('0' + (selectedDate.getMonth() + 1)).slice(-2) + '/' + ('0' + (selectedDate.getDate())).slice(-2) + '/' + selectedDate.getFullYear());
    }
    
    this.actionTempObj.arr_action.push({
      str_description: this.action_description,
      str_owner: this.obj_owner.str_name,
      cod_meeting: this.paramsSelected['meetings'],
      str_meeting: this.meetings_description,
      dat_due: display_due_date,
      cod_owner: this.obj_owner.cod_user,
      str_action_status: this.actionStatus
    });

    this.buildActions();
  }

  cancelRootCause() {
    this.clearFieldsAction();
    this.showComponentAddAction = false;
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  removeOwner() {
    this.obj_owner = {
      cod_user: 0,
      str_name: '',
      str_img_path: ''
    };
    this.clearMeetings();
    this.isVisible = true;
    this.validAction = false;
  }

  isValidAction(){
    if(this.obj_owner.cod_user > 0 && this.paramsSelected['meetings'].length > 0){
      this.validAction = true;
    }
    else{
      this.validAction = false;
    }
  }

  savingFivewhy(): boolean {
    if (!this.showComponentAddAction) return true;

    const has_data = (
      this.obj_owner.cod_user > 0
      || this.paramsSelected['meetings']?.length > 0
      || this.action_description.length > 0
      || this.action_due_date.length > 0
    );
    if (!has_data) return true;

    const is_valid = (this.validAction && this.action_description.length > 0 && this.action_due_date.length > 0);
    if (is_valid) {
      this.saveRootCause();
      return true;
    }
    return false;
  }

  searchHashtag(): void {
    this.arr_opt_hashtag = [];

    if (this.action_description.indexOf('#') != -1) {
      let str_part_hashtag = this.action_description.substring(this.action_description.lastIndexOf('#') + 1);
      
      for (let i = 0; i < this.arr_hashtag.length; i++) {

        if (this.arr_hashtag[i].toLowerCase().lastIndexOf(str_part_hashtag.toLowerCase()) != -1) {
          this.arr_opt_hashtag.push(this.arr_hashtag[i]);
        }
      }
    }
  }

  onSelectedHashtag(str_opt_hashtag: string): void {
    let str_action = this.action_description;
    
    this.action_description = `${str_action.substring(0, str_action.lastIndexOf('#'))}${str_opt_hashtag} `;
    this.arr_opt_hashtag = [];

    this.str_description_component.nativeElement.focus();
  }

  // Check and add if not included the default hashtags in the description
  checkDefaultHashtags() {
    if (this.showComponentAddAction) {
      const defaultHashtags = [ '#5WHY' ];

      const hashtagsArray = this.action_description.split(' ').filter(obj => obj.startsWith('#'));

      defaultHashtags.reverse().forEach(hashtag => {
        if (!hashtagsArray.includes(hashtag)) {
          this.action_description = hashtag + ' ' + this.action_description;
        }
      })
    }
  }

  // Get the selected meeting details to extract the hashtags for the option list
  getSelectedMeetingDetails() {
    this._actionsService.getMeetingList(this.paramsSelected['meetings'], false, environment().uploadGetFilesApiUrl + '/', this._authService.getAuthenticatedUser().id).subscribe(result => {
      this.setHashtagOptions(result.content.results);
    });
  }

  setHashtagOptions(actions) {
    this.arr_hashtag = [];
    if (actions) {
      const hashtagOpts = [];
      actions.forEach((action) => {
        const descHashtags = action.str_description.split(' ').filter(word => word.startsWith('#'));
  
        descHashtags.forEach((hash) => {
          if (!hashtagOpts.includes(hash) && !this.arr_hashtag.includes(hash)) { hashtagOpts.push(hash) };
        });
      });
  
      this.arr_hashtag = [ ...this.arr_hashtag, ...hashtagOpts];
    }
  }
}