import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ListSubAreasParams } from './models/list-sub-areas-params.model';

import { UsersService } from 'app/admin/services/users.service';
import { ListSubAreasSelectService } from './services/list-sub-areas-select.service';

@Component({
  selector: 'app-list-sub-areas-select',
  templateUrl: './list-sub-areas-select.component.html',
  styleUrls: ['./list-sub-areas-select.component.css']
})
export class ListSubAreasSelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() selected = [];
  @Input() isMultiple: boolean = false;

  isLoading: boolean = false;

  subAreaList = [];
  selectedSubAreas = [];

  isMultiSelect: boolean = false;

  constructor(
    private _listSubAreasSelectService: ListSubAreasSelectService,
    private _userService: UsersService,
  ) { }

  ngOnInit(): void {
  }

  searchSubAreas(params: ListSubAreasParams, cod_selected_subareas: string[] = null, firstOpening: boolean = false) {
    this.isLoading = true;  

    this._listSubAreasSelectService.getListSubareas(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.subAreaList = this._userService.getArrayContent(data);
  
        if (cod_selected_subareas && cod_selected_subareas.length > 0) {
          this.selectedSubAreas = this.subAreaList.filter(obj => cod_selected_subareas.includes(obj['cod_subareas']));
        };

        if (firstOpening) {
          this.selectedSubAreas = [this.subAreaList[0]];
          this.selectionChanged.emit(this.selectedSubAreas);
        }

      }
    }, error => {
      console.log(error)
      this.isLoading = false;
    },
    () => this.isLoading = false
    );
  }

  onSelection($event) {
    this.selectedSubAreas = $event?.selectedOptions;

    this.selectionChanged.emit($event?.selectedOptions);
  }

  clearList() {
    this.subAreaList = [];
    this.selectedSubAreas = [];
  }
}
