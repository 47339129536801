import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
// SHARED COMPONENTS
import {ComponentsModule} from './components/components.module';
// MODULES
import {UserPreferencesModule} from './user-preferences/user-preferences.module';
import {PillarsModule} from './pillars/pillars.module';
import {MeetingsModule} from './meetings/meetings.module';
import {LogbooksModule} from './logbooks/logbooks.module';
import {FormsDynamicModule} from './forms/forms-dynamic/forms-dynamic.module';
import {AdminModule} from './admin/admin.module';
import {InfoModule} from './main/help/help.module';
// MODULES i18n
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// COMPONENTS "MAIN"
import {AppComponent} from './app.component';
import {NotFoundComponent} from './main/not-found/not-found.component';
import {LeftMenuComponent} from './main/left-menu/left-menu.component';
import {HomeComponent} from './main/home/home.component';
import {TasksListComponent} from './main/tasks-list/tasks-list.component';
import {TeamComponent} from './main/team/team.component';
import {HeaderComponent} from './main/header/header.component';
import {AccessComponent} from './main/access/access.component';
import {AuthComponent} from './main/access/auth/auth.component';
import {ReportsEmailComponent} from './main/reports/reports-email.component';
import {ReportsDownloadComponent} from './main/reports-download/reports-download.component';
import {ReportsRoutineAttachmentsComponent} from './main/reports-routine-attachments/reports-routine-attachments.component';
import {ReportsFormsAttachmentsComponent} from './main/reports-forms-attachments/reports-forms-attachments.component';
import {ReportsFormsCollabAttachmentsComponent} from './main/reports-forms-collab-attachments/reports-forms-collab-attachments.component';
import {ReportsRoutineCollabAttachmentsComponent} from './main/reports-routine-collab-attachments/reports-routine-collab-attachments.component';
import { FivewhyComponent } from './five-why/fivewhy/fivewhy.component';
import { VersionWarningComponent } from './main/version-warning/version-warning.component';

// ROUTE GUARD
import {AuthGuard} from './guards/auth-guard';
import {UnsavedChangesGuard} from './guards/unsaved-changes-guard';
// SERVICES
import {AuthService} from './main/access/services/auth.service';
import {CsvMainService} from './components/button-download-csv/services/csv-main.service';
import {ListAreasMainService} from './components/list-areas/services/list-areas-main.service';
import {ListSubAreasMainService} from './components/list-subareas/services/list-subareas-main.service';
import {ListEquipmentsMainService} from './components/list-equipments/services/list-equipments-main.service';
import {ListMaintenancePlanMainService} from './components/list-maintenance-plan/services/list-maintenance-plan-main.service';
import {UploadMainService} from './main/services/upload-main.service';
import {UploadService} from './main/services/upload.service';
import {ListAreasTaMainService} from './components/list-areas-ta/services/list-areas-ta-main.service';
import {ListSubAreasTaMainService} from './components/list-subareas-ta/services/list-subareas-ta-main.service';
import {ListEquipmentsTaMainService} from './components/list-equipments-ta/services/list-equipments-ta-main.service';
import {ListMachinesTaMainService} from './components/list-machines-ta/services/list-machines-ta-main.service';
import {ListLocationsMainService} from './components/list-locations/services/list-locations-main.service';
import {ListRegionalsMainService} from './components/list-regionals/services/list-regionals-main.service';
import {ListWorldsMainService} from './components/list-worlds/services/list-worlds-main.service';
import {ListPillarsMainService} from './components/list-pillars/services/list-pillars-main.service';
import {HelpTutorialService} from './main/services/help-tutorial.service';
import {ListIndicatorsMainService} from './components/list-indicators/services/list-indicators-main.service';
import {FormPercentageEvolutionMainService} from './components/form-percentage-evolution/services/form-percentage-evolution-main.service';
import { AppCustomRoutineComponent } from './main/custom-routine/app-custom-routine.component';
import {AccessControlLogMainService} from './components/access-control-log/services/access-control-log-main.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FiveWhyModule } from './five-why/five-why.module';
import { MyFormsMainComponent } from './my-forms/my-forms-main/my-forms-main.component';
import { MyFormsModule } from './my-forms/my-forms.module';
import { FormsDetailsComponent } from './my-forms/components/forms-details/forms-details.component';
import { CockpitModule } from './cockpit/cockpit.module';
import { ReportsComponent } from './main/reports-main/reports.component';
import { ReportsOnlineComponent } from './main/reports-online/reports-online.component';
import { RoutinesServiceStore } from './stores/routines-forms.store.service';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication, BrowserUtils, LogLevel } from '@azure/msal-browser';

import {
    BrewtechNpsModule as NpsModule,
    NpsConfig,
    NpsService,
} from 'brewtech-nps-angular';
import { NpsPersistenceService } from './nps/nps-persistence.service';
import { NpsModalComponent } from './nps/nps-modal/nps-modal.component';
import { NpsFeedbackModalComponent } from './nps/nps-feedback-modal/nps-feedback-modal.component';
import { NpsInterceptorService } from './nps/nps-interceptor.service';

import { environment } from 'environments/environment';
import { HelpMenuComponent } from './help-menu/help-menu.component';

// CELEBRATION
import { ClbBreadcrumbModule } from '@celebration/angular/clb-breadcrumb';

registerLocaleData(en);

const npsServiceProvider = {
    provide: NpsService,
    useFactory: (http: HttpClient) => {
      return new NpsService(
        http,
        new NpsConfig(
            environment().npsApiUrl,
        )
      );
    },
    deps: [HttpClient],
  };

const Stores = [
    RoutinesServiceStore,
]

// ROUTES FROM MODULE
const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'tasks',
        component: TasksListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'my-forms',
        component: MyFormsMainComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'my-forms/form/:lrpuId',
        component: FormsDetailsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'my-forms/form-collab/:lrpuId',
        component: FormsDetailsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'tasks/:selectedDate',
        component: TasksListComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'problem-terminator',
        component: FivewhyComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'reports-download/:cod_user/:str_file',
        component: ReportsDownloadComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'attachments/routines/:cod_logbook_routines_periodicity_users',
        component: ReportsRoutineAttachmentsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'attachments/forms/:cod_form/:cod_logbook_routines_periodicity_users',
        component: ReportsFormsAttachmentsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'attachments/forms-collab/:cod_form/:cod_logbook_routines_periodicity_users_collaborative',
        component: ReportsFormsCollabAttachmentsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'attachments/routines-collab/:cod_logbook_routines_periodicity_users_collaborative',
        component: ReportsRoutineCollabAttachmentsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'access',
        component: AccessComponent,
    },
    {
        path: 'hmi',
        component: AccessComponent,
    },
    {
        path: 'team',
        component: TeamComponent
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AuthComponent,
        TeamComponent,
        HeaderComponent,
        AccessComponent,
        ReportsComponent,
        NotFoundComponent,
        LeftMenuComponent,
        TasksListComponent,
        ReportsEmailComponent,
        ReportsOnlineComponent,
        VersionWarningComponent,
        ReportsDownloadComponent,
        AppCustomRoutineComponent,
        ReportsFormsAttachmentsComponent,
        ReportsRoutineAttachmentsComponent,
        ReportsRoutineCollabAttachmentsComponent,
        TeamComponent,
        AppCustomRoutineComponent,
        VersionWarningComponent,
        ReportsFormsCollabAttachmentsComponent,
        ReportsRoutineCollabAttachmentsComponent,
        NpsModalComponent,
        NpsFeedbackModalComponent,
        HelpMenuComponent,
    ],
    imports: [
        NpsModule,
        FormsModule,
        BrowserModule,
        FiveWhyModule,
        MyFormsModule,
        PillarsModule,
        LogbooksModule,
        MeetingsModule,
        HttpClientModule,
        ComponentsModule,
        FormsDynamicModule,
        UserPreferencesModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(routes, {
            useHash: false,
            // Don't perform initial navigation in iframes or popups
            initialNavigation:
                !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AdminModule,
        BrowserAnimationsModule,
        InfoModule,
        CockpitModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment().azureActiveDirectoryClientId,
                    authority: `${environment().msc_url}/${environment().azureActiveDirectoryTenantId}`,
                    redirectUri: environment().azureActiveDirectoryRedirectUrl,
                    postLogoutRedirectUri: '/access',
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE, // true for Internet Explorer 11
                },
                // system: { // uncomment this block to enable MSAL's inner logs
                //     loggerOptions: {
                //         loggerCallback(logLevel: LogLevel, message: string) {
                //             console.log(message);
                //         },
                //         logLevel: LogLevel.Verbose,
                //         piiLoggingEnabled: false
                //     }
                // }
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: [`${environment().azureActiveDirectoryClientId}/.default`],
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map<string, Array<string>>([
                    [`${environment().authApiUrl}/auth/display-warning`, null], //scope null means it is unprotected, and token will not be sent (or should not at least)
                    [`${environment().authApiUrl}/auth/check-frontend-version`, null],
                    [`${environment().envUrl}`, [`${environment().azureActiveDirectoryClientId}/.default`]],
                ]),
            }
        ),
        ClbBreadcrumbModule
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    providers: [
        AuthGuard,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        UnsavedChangesGuard,
        AuthService,
        UploadService,
        CsvMainService,
        UploadMainService,
        HelpTutorialService,
        ListAreasMainService,
        ListWorldsMainService,
        ListPillarsMainService,
        ListAreasTaMainService,
        ListSubAreasMainService,
        ListLocationsMainService,
        ListRegionalsMainService,
        ListSubAreasTaMainService,
        ListEquipmentsMainService,
        ListIndicatorsMainService,
        AccessControlLogMainService,
        ListEquipmentsTaMainService,
        ListMachinesTaMainService,
        ListLocationsMainService,
        ListWorldsMainService,
        ListRegionalsMainService,
        ListPillarsMainService,
        HelpTutorialService,
        ListIndicatorsMainService,
        ListMaintenancePlanMainService,
        FormPercentageEvolutionMainService,
        Stores,
        NpsPersistenceService,
        npsServiceProvider,
        { provide: LOCALE_ID, useValue: 'en' },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NpsInterceptorService, multi: true },
    ],
})
export class AppModule {
    defaultLang = {
        en: ['en', 'en'],
        pt: ['pt', 'pt'],
        es: ['es', 'es'],
        nl: ['nl', 'nl'],
        uk: ['uk', 'ua'], //0: cod_id, 1: str_name
        ua: ['uk', 'ua'], //0: cod_id, 1: str_name
        ru: ['ru', 'ru'],
        de: ['de', 'de'],
        fr: ['fr', 'fr'],
        zh: ['zh', 'zh'], // chinese language
        ko: ['ko', 'ko'], // korean language        
        vi: ['vi', 'vi']  // vietnamese language
    };
    constructor(translateService: TranslateService) {
        translateService.addLangs(['pt', 'en', 'es', 'nl', 'ua', 'ru', 'de', 'fr', 'ko', 'zh', 'vi']);
        translateService.setDefaultLang('en');
        if (localStorage.getItem('userPreferencesLanguage')) {
            const userPref = this.defaultLang[localStorage.getItem('userPreferencesLanguage')];
            translateService.use(userPref && userPref.length > 0 ? userPref[1] : 'en');
        }
    }
}
