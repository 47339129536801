import * as _ from 'lodash';

const calculateComplianceForm = (arr_questions) => {
    const value = getFormsPoints(arr_questions);
    if(!value|| value.points == 0 || value.points == null) {
        return 'N/A'
    } else {
        return ((value.savedPoints / value.points) * 100).toFixed(0) + '%';
    }
}

const getFormsPoints = (arr_questions) => {
    let points = 0;
    let savedPoints = 0;
    
    for (let q of arr_questions) {
        const {
            int_type,
            answer,
            cod_form_question_choice,
            arr_choices,
            cod_answer_question_choice,
            selected_options
        } = q;
    
        switch (int_type) {
            case 2: // yes/no
                const maxPoints = _.maxBy(arr_choices, function(o) { return o.int_points; }); 
                points += (maxPoints) ? maxPoints.int_points : 0 ;
              
                if (cod_form_question_choice || cod_answer_question_choice) {
                    const selected = _.find(arr_choices, ['cod_form_question_choice', cod_form_question_choice]) || _.find(arr_choices, ['cod_form_question_choice', cod_answer_question_choice]);
                    if (selected !== undefined)
                        savedPoints += selected.int_points;
                }

            break;
    
            case 3: // single selection
                const maxSPoints = _.maxBy(arr_choices, function(o) { return o.int_points; }); 
                points += (maxSPoints) ? maxSPoints.int_points : 0 ;

                if (cod_form_question_choice || cod_answer_question_choice) {
                    const selected = _.find(arr_choices, ['cod_form_question_choice', cod_form_question_choice]) || _.find(arr_choices, ['cod_form_question_choice', cod_answer_question_choice]);
                    if (selected !== undefined)
                        savedPoints += selected.int_points;
                }
            break;

            case 4: // multiple selection
                points += _.sumBy(arr_choices, function(o) { return o.int_points; });
                let selected = [];

                if( answer == '' || answer == null) { 
                    selected = arr_choices
                    .filter(i => i.bol_answer_checked);
                } else {
                    if (selected_options[0] === undefined) return;
                    const options = selected_options.map(o => o.cod_form_question_choice)
                    selected = _.filter(arr_choices, (v) => _.includes(options, v.cod_form_question_choice));
                }

            
                const text_answer = arr_choices
                                    .filter(i => i.bol_answer_checked)
                                    .map(i => i.cod_form_question_choice)
                                    .join(',');


                if (text_answer != '' || (answer != '' && answer != null )) {
                    savedPoints += _.sumBy(selected, function(o) { return o.int_points; });
                }

            break;
        }
    
    }
    return { points, savedPoints };
} 

const validateAnswers = (validateErrors, answers) => {
    const errors = [];
    let points = 0;
    let savedPoints = 0;

    for (let q of answers) {
        const {
            str_text,
            cod_form_question,
            int_type,
            arr_choices,
            cod_answer_option,
            cod_answer_question_choice
        } = q;
    
        const cod_form_question_choice = q.cod_form_question_choice || parseInt(q.answer); 
        const str_answer_text = q.str_answer_text || q.answer; 

        switch (int_type) {
            case 1: // text
            if (!str_answer_text || !str_answer_text.length) {
                errors.push(q);
            }
            break;
    
            case 5: // date
            if (isNaN(new Date(str_answer_text).getTime()) || str_answer_text == null) {
                errors.push(q);
            }
            break;
    
            case 2: // yes/no
            const maxPoints = _.maxBy(arr_choices, function(o) { return o.int_points; }); 
            points += (maxPoints) ? maxPoints.int_points : 0 ;

            if (!cod_form_question_choice && !cod_answer_question_choice) {
                errors.push(q);
            } else {
                const selected = _.find(arr_choices, ['cod_form_question_choice', cod_form_question_choice]) || _.find(arr_choices, ['cod_form_question_choice', cod_answer_question_choice]);
                savedPoints += selected.int_points;
            }
            break;
    
            case 3: // single selection
            const maxSPoints = _.maxBy(arr_choices, function(o) { return o.int_points; }); 
            points += (maxSPoints) ? maxSPoints.int_points : 0 ;

            if (!cod_form_question_choice && !cod_answer_question_choice) {
                errors.push(q);
            } else {
                const selected = _.find(arr_choices, ['cod_form_question_choice', cod_form_question_choice]) || _.find(arr_choices, ['cod_form_question_choice', cod_answer_question_choice]);
                savedPoints += selected.int_points;
            }
            break;
    
            case 4: // multiple selection
            points += _.sumBy(arr_choices, function(o) { return o.int_points; });
            let selected = [];

            if(str_answer_text == '' || str_answer_text == null) { 
                selected = arr_choices
                .filter(i => i.bol_answer_checked);
            } else {
                const options = str_answer_text.split(',');
                selected = _.filter(arr_choices, v => _.includes(options, v.cod_form_question_choice.toString()));
            }
            
            const text_answer = arr_choices
                                .filter(i => i.bol_answer_checked)
                                .map(i => i.cod_form_question_choice)
                                .join(',');

            if (text_answer == '' && (str_answer_text == '' || str_answer_text == null )) {
                errors.push(q);
            } else {
                savedPoints += _.sumBy(selected, o => o.int_points);
            }
            break;

            case 16: //number type
            if (!str_answer_text) {
                errors.push(q);
            }
            break;
    
            case 6: // live search users
            case 7: // live search Maintenance Plan 
            case 8: // live search Area
            case 9: // live search Subarea
            case 10: // live search Equipment
            case 11: // live search Pillars
            case 12: // live search World
            case 13: // live search Regional
            case 14: // live search Unity
            case 15: // live search Indicators
            case 17: // live search Machines

    
            if (!cod_answer_option) {
                errors.push(q);
            }
            break;
    
            default:
            break;
        }
    
    }
    
    // check to return errors percentage
    if(validateErrors) { 
        const diff = answers.length - errors.length; 
        const percentage = (diff / answers.length) * 100;
        
        return (errors.length > 0) ? percentage.toFixed(0) : 100;    
    } else {
        // return possible points
        return { points, savedPoints };
    }

} 




const FormsHelper = {
  calculateComplianceForm,
  getFormsPoints,
  validateAnswers
}

export default FormsHelper;