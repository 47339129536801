// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.loading-icon::before {
    content: "\\E031";
}

.loading-icon {
    animation: spin 1.5s infinite linear;
}

:host{
    margin-left: 5px;
}

:host:first-child {
    margin-top: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/components/button-save/button-save.component.css"],"names":[],"mappings":"AAWA;IACI;QAEI,uBAAuB;IAC3B;IACA;QAEI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IAEI,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@-webkit-keyframes spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n    100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n    }\n}\n\n@keyframes spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n    100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n    }\n}\n\n.loading-icon::before {\n    content: \"\\E031\";\n}\n\n.loading-icon {\n    -webkit-animation: spin 1.5s infinite linear;\n    animation: spin 1.5s infinite linear;\n}\n\n:host{\n    margin-left: 5px;\n}\n\n:host:first-child {\n    margin-top: inherit;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
