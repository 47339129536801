import { Injectable } from "@angular/core";
import { AuthMainService } from "../../services/auth-main.service";
import { ListMachinesParams } from "../models/list-machines-params";


@Injectable()
export class ListMachinesSelectService {
  constructor(private _mainService: AuthMainService) {}

  getListMachines(params: ListMachinesParams){
    const path = '/filter-list-machines';
    return this._mainService.post(path, params);
  }
}
