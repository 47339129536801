
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "app/admin/services/users.service";
import { AreaService } from "app/logbooks/services/area.service";
import { AuthService } from "app/main/access/services/auth.service";
import * as moment from "moment";
import { forkJoin, Subscription, timer } from "rxjs";
import { FiveWhy } from "../model/five-why.model";
import { Whys } from "../model/whys.model";
import { FiveWhyService } from "../services/five-why.service";
import { MachineService } from "../services/machine.service";
import { RelayService } from "../services/relay.service";
import { TriggersService } from "../services/triggers.service";
import { ValidationsService } from "../services/validations.service";
import { AddWhysHelperService } from "../services/add-whys-helper-service";
import { ActivatedRoute, Router } from "@angular/router";
import { getTimeBetweenDate } from "app/helpers/date-time-helper";
import { UploadService } from "app/main/services/upload.service";
import { AbReportFiles } from "../model/ab_report_files.model";
import { SocketService } from "app/main/services/socket.service";
import { NotificationService } from "app/main/services/notification.service";
import { CrewService } from "../services/crew.service";

import { Rating } from "../model/rating.model";
import { EmailModalService } from "app/components/email-confirmation-modal/email-confirmation-modal.service";
import { environment } from "environments/environment";
import { ContentEditableComponent } from "app/components/content-editable/content-editable.component";
import { SessionFilterService } from "app/services/session-filter.service";
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';

// import { ConfimDiscartChangesComponent } from "app/components/confirm-discart-changes/confirm-discart-changes.component";
// import { CheckChangesOnDeactivate } from "app/guards/unsaved-changes-guard";


@Component({
  selector: "app-new-five-why",
  templateUrl: "./new-five-why.component.html",
  styleUrls: ["./new-five-why.component.css"],
})
export class NewFiveWhyComponent implements OnInit, OnDestroy/*, CheckChangesOnDeactivate*/ {

  // @HostListener('window:beforeunload', ['$event']) handleUnload($event: any) {
  //   if (this.hasChanges()) {
  //     $event.preventDefault();
  //     $event.returnValue = true;
  //   }
  // }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if(document.getElementsByClassName('status-box-color')[0] && window.getComputedStyle(document.getElementsByClassName('status-box-color')[0] , null)){
      this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('status-box-color')[0] , null).width.replace('px', '');
    }
  }

  @Input("codFivewhy") id;
  @ViewChild('fileInput') fileInput;
  @ViewChild('fiveWhyActionsComponent') fiveWhyActionsComponent;
  @ViewChild('str_name_component') str_name_component: ElementRef;
  @ViewChild('content_editable_name') content_editable_name: ContentEditableComponent;
  // @ViewChild('discartChanges') discartChangesComponent: ConfimDiscartChangesComponent;

  obj_connections = new Subscription();
  connections_created: boolean = false;

  errorMessage: string;
  dateMinLimit: string;
  dateMaxLimit: string;
  isLoading: boolean = false;
  showError: boolean = false;
  haveCrew: boolean = false;
  loadingInProgress: boolean = false;
  shouldAdvanceProblem: boolean = false;
  isLastStatus: boolean = true;
  privilegeFiveWhy: any;
  isNewFivewhy: boolean = true;

  canShowBPMOptions: boolean = false;
  showCancelModalConfirmation: boolean = false;
  canShowButtonReopen: boolean = false;

  codUserToSendEmail: number = 0;

  fivewhyOriginalClone: FiveWhy;
  fivewhy: FiveWhy;
  loadingConfig: any = [];
  paramsSelected: any = [];

  areaList: any = [];
  crewList: any = [];
  fiveWhyList: any = [];
  machineList: any = [];
  subAreasList: any = [];
  triggersList: any = [];
  locationsList: any = [];
  equipmentList: any = [];
  categoriesList: any = [];
  sdcaMatrixList: any = [];

  arr_areas: any = [];
  arr_status: any = [];
  arr_trigger: any = [];
  arr_machines: any = [];
  arr_category: any = [];
  arr_equipments: any = [];
  arr_participants: any = [];

  showSaveActions: any = {
    isCanceled: false,
    isClosed: false,
    arr_action: []
  };

  paramsActions: any = {};
  actionTempObj: any = {
    cod_user: '',
    cod_fivewhy: '',
    str_name_fivewhy: '',
    arr_action: []
  }

  showSaveParticipants: any = {
    arr_participants: [],
    cod_fivewhy: '',
    cod_status: '',
    cod_location: '',
    isNewFivewhy: false
  };

  notesToSendEmail: string;
  cod_user: any;
  user_name: string;
  cod_fivewhys: any;
  fivewhy_date_report: string;
  str_name_fivewhy: any;
  dat_completed: any;
  dateNow: any;
  finalDateReopen: any;

  statusLog = {
    log: [],
    date_ins: "",
    isClosed: false,
    isCanceled: false,
    dat_canceled: "",
    str_name_usu_canceled: "",
    currentStatus: null,
  };

  abReported: boolean;
  abAlreadyReported: boolean;

  existingWhys: Whys[] = [];
  whysList: Whys[] = [];
  whysLoading: boolean = true;

  isUploadingAttachments: boolean = false;

  isFiveWhyCompleted: boolean = false;
  blockFivewhy: boolean = false;
  canShowCancelButton: boolean;
  blockBySocket: boolean = false;
  ellapsedTime: number;
  whyTranslation: string;
  nextOnQueue: boolean = false;
  userEditingOnSocket: string;
  positionInSocketQueue: number;
  subscriptionSocket: Subscription;
  disconnectSocketSubscription: Subscription;

  arr_opt_hashtag: any[] = [];
  arr_hashtag: any[] = [];
  arr_obj_agenda = [];

  abReportAttachmentsClone: any = [];
  showConfirmationBackModal: boolean = false;

  showAbortModal: boolean = false;
  showPopUpKickMessage: boolean = false;
  showActionIncomplete: boolean = false;
  showModalReopen5why: boolean = false;
  pageIndex: any;
  machineDatDel: any;
  triggerDatDel: any;
  deletedWarningMessage: string = '';
  showModalDeleted: boolean = false;
  isLoadingWorkflow: boolean = false;
  nextExec: string = 'save';
  canEditWhyTranslation: string = '';
  arr_rating: Rating[];
  canSkipBackValidation: boolean = true;
  widthToCopy: string;

  blockForStatus:boolean = false;
  blockReopen:boolean = false;

  canceledChanged: boolean = false;
  downloadParams = {
    type: 'problemTerminatorPdf',
    colors: [],
    cod_fivewhy: 0,
    cod_user: 0,
    name: '5WHY_Analysis_'
  }
  hasChangesPDF: boolean = false;
  participantsClone = [];

  functionToSearch: any;

  has_privileges: boolean;
  privileges_user: string;
  cod_user_location: any;

  modalSubscriptions: any[] = [];

  blockingTime: number;
  backup_name_to_hashtag: string = null;

  is_editing_rating: boolean = false;
  is_saving: boolean = false;
  kick_user: boolean = false;
  sessionFilterPrefillAllowedValues: string[][] = [
    ['locations', 'locations_selected'],
    ['areas', 'areas_selected'],
    ['subareas', 'subareas_selected'],
    ['equipment', 'equipment_selected'],
    ['machine', 'machine_selected'],
    ['crew', 'crew_selected'],
  ];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _areaService: AreaService,
    private _userService: UsersService,
    private _notify: NotificationService,
    private _socketService: SocketService,
    private _uploadService: UploadService,
    private _fivewhyService: FiveWhyService,
    private _machinesService: MachineService,
    private _relayService: RelayService,
    private _triggerService: TriggersService,
    private _translateService: TranslateService,
    public validationService: ValidationsService,
    private _addWhysHelperService: AddWhysHelperService,
    private _crewService: CrewService,
    private _modalEmailService: EmailModalService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _sessionFilterService: SessionFilterService,
    private _userPreferencesService: UserPreferencesService
  ) {
    this._route.queryParams.subscribe((params) => {
      if (params['page']) {
        this.pageIndex = params['page']
      }
    })
    this.id = this._route.snapshot.params['cod_fivewhy'];
    this.cod_user = this._authService.getAuthenticatedUser().id;
    this.downloadParams.cod_fivewhy = this.id;
    this.downloadParams.name += this.id + "_";
    this.downloadParams.cod_user = this.cod_user;
    this.user_name = this._authService.getAuthenticatedUser().name;
    this.dateMaxLimit = moment(new Date()).format('YYYY-MM-DD');
    this.dateMinLimit = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
    this._loadSdcaMatrix(null);

    this.fivewhy_date_report = moment(this.fivewhy_date_report).format(
      "YYYY-MM-DD"
    );

    this._addWhysHelperService.getWhys$.subscribe(whys => {
      this.whysList = whys
    });
    this.is_saving = false;
  }

  ngOnDestroy(): void {
    if (!this.isNewFivewhy) {
      this._socketService.unsubscribeProblemTerminator(this.id, { name: this.user_name, id: this.cod_user });
      if (!this.subscriptionSocket.closed) {
        this.subscriptionSocket.unsubscribe();
      }
      if(!this.disconnectSocketSubscription.closed) {
        this.disconnectSocketSubscription.unsubscribe();
      }
    }

    this.unsubscribeEmailModal();
  }

  ngOnInit() {
    setTimeout(() => {
      if(document.getElementsByClassName('status-box-color')[0] && window.getComputedStyle(document.getElementsByClassName('status-box-color')[0] , null)){
        this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('status-box-color')[0] , null).width.replace('px', '');
      }
    }, 500);
    
    this.privilegeFiveWhy = this.checkPrivilege('5WHY_MENU_VISUALIZE');
    this._translateService.get('5WHY.WHY').subscribe((translate) => {
      this.whyTranslation = translate;
    });
    this._translateService.get('5WHY.CAN_EDIT_5WHY').subscribe((translate) => {
      this.canEditWhyTranslation = translate;
    });
    this.sessionFilterPrefillAllowedValues = this._sessionFilterService.preffiledDeepLinksValues(this._activatedRoute.snapshot.queryParams, this.sessionFilterPrefillAllowedValues).filter(n => !!n);
    if (this.id > 0) {
      this.isNewFivewhy = false;
      this.initializeSocket();
      this.initializeSocketEventDisconnect();
    } else {
      this.showSaveParticipants.cod_status = 1;
      this.showSaveParticipants.isNewFivewhy = true;
    }

    this.load();

    this._translateService.onLangChange.subscribe(() => {
      if (this.fivewhy) {
        this.isLoadingWorkflow = true;
        this.reloadWorkflow({
          cod_user: this.cod_user,
          cod_fivewhy: this.id
        });
      }
    });
    const fivewhyDateRoute = this._activatedRoute.snapshot.queryParams?.fivewhy_date_report;
    if (fivewhyDateRoute) {
      this.fivewhy_date_report = moment(fivewhyDateRoute).format(
          "YYYY-MM-DD"
      );
    }
    const problemDetails = this._activatedRoute.snapshot.queryParams?.problem_details;
    if (problemDetails) {
      this.fivewhy.str_problem_details = problemDetails;
    }
  }

  public hasChanges(): boolean {
    return (this.hasChangesInData() || this.is_editing_rating) && !this.is_saving && !this.loadingInProgress && !this.kick_user;
  }

  public onEitingRating(ev: boolean): void {
    this.is_editing_rating = ev;
  }

  private load(): void {
    this.initServiceModal();
    this._loadInformations();
  }

  initServiceModal() {
    this.has_privileges = this._authService.getUserPrivilege("5WHY_SELETED_USERS");
    
    this.privileges_user = this.has_privileges ? '5WHY_SELETED_USERS' : 'NOT_PRIVILEGE';
    this.cod_user_location = this.has_privileges ? null : this._authService.getAuthenticatedUser().cod_location;


    const searchUserSubscription = this._modalEmailService.searchUser.subscribe( data => {
      if(data) {
        this._fivewhyService.getUsersList(data.str_term, environment().uploadGetFilesApiUrl + '/', this.cod_user, this.privileges_user , this.cod_user_location).subscribe({
          next: (result: any) => {
            data.callback(result.content.results);
          },
          error: err => {
            this._authService.errCheck(err);
            console.log(err);
          }
        });
      }
    });

    const modalAction = this._modalEmailService.modalAction.subscribe( (data: any) => {
      if(data) {
        if(!data.skipEmail) {
          this.codUserToSendEmail = data.userList[0].cod_user;
          this.notesToSendEmail = data.notes;
        }
        this.saveFiveWhy();
        this.unsubscribeEmailModal();
      }
    })

    this.modalSubscriptions.push(searchUserSubscription);
    this.modalSubscriptions.push(modalAction);
  }

  unsubscribeEmailModal() {
    this._modalEmailService.resetModal();

    this.modalSubscriptions.forEach( subscription => {
      subscription.unsubscribe();
    })
  }

  initializeSocket() {
    this.subscriptionSocket = this._socketService.subscribeProblemTerminator().subscribe((arrayQueue: any) => {
      this.userEditingOnSocket = arrayQueue[0].str_name;
      if(arrayQueue[0].cod_user == this.cod_user) {
        this.blockBySocket = false;
        this.initializeTimerSocket();
        
      } else { 
        this.blockBySocket = true;
      }

      if (this.nextOnQueue && !this.blockBySocket) {
        this._loadInformations();
        this._notify.info(this.canEditWhyTranslation, "", { timeOut: 10000 });
      }
      this.nextOnQueue = arrayQueue.length > 1 && arrayQueue[1].cod_user == this.cod_user;
      this.positionInSocketQueue = arrayQueue.map(user => user.cod_user).indexOf(this.cod_user);
    });
    // TODO find out why sometimes socket does not join, even if log says it do
    this._socketService.joinProblemTerminator(this.id, { name: this.user_name, id: this.cod_user });
  }

  initializeSocketEventDisconnect() {
    this.disconnectSocketSubscription = this._socketService.subscribeDisconnectEvent().subscribe( () => {
      this._notify.warning({text: '5WHY.SOCKET_DISCONNECT', translate: true}, '', {timeOut: 15000})
      if(this.blockBySocket) {
        this.backPage();
      } else {
        this.saveFiveWhy(true);
      }
    });
  }

  reloadWorkflow(params) {
    this._fivewhyService.getWorkflow(params).subscribe(data => {
      this._getRelay(data.content.results);
      this.isLoadingWorkflow = false;
    });
  }

  initializeTimerSocket() {
    this.showPopUpKickMessage = false;
    timer(1740000).subscribe(() => {
      this.showPopUpKickMessage = true;
    });
  }

  _getRelay(status) {
    status = status.map(s => {
      s.is_enable = true;
      return s;
    });

    this.statusLog.log = status;
    if (this.id < 1) return;

    let params = {
      'cod_user': this._authService.getAuthenticatedUser().id,
      'cod_area': this.fivewhy.cod_area,
      'cod_location': this.fivewhy.cod_location,
    }

    return this._relayService.getSingleRelay(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {

        const fbs = data.content.results[0]?.fivewhys_blocked_status;

        if (fbs != null) {
          fbs.map(i => {
            if (i.is_enable) return;
            status = status.map(s => {
              if (s.cod_fivewhys_status == i.cod_fivewhy_steps) s.is_enable = i.is_enable;
              return s;
            });
          });
        }

      }

      this.downloadParams.colors= [];
      this.statusLog.log.forEach((status, index) => {
        status.color = this._fivewhyService.getStatusColor(this.statusLog.isCanceled, this.statusLog.currentStatus, this.statusLog.log, index + 1);
        this.downloadParams.colors.push({cod_fivewhys_status: index+1, color: status.color})
      });

      this.statusLog.log = status;
    });
  }

  async saveActions() {
    let datFormat = this._userPreferencesService.getLocalDateFormat();
    datFormat = datFormat ? datFormat : 'MM/DD/YYYY';

    if(datFormat == 'DD/MM/YYYY'){ 
      this.actionTempObj.arr_action.forEach(function (action) {             
        action.dat_due = (action.dat_due.substring(3, 5) + '/' + action.dat_due.substring(0, 2) + '/' + action.dat_due.substring(6));
      });
    }

    this.actionTempObj = {
      ...this.actionTempObj,
      cod_user: this.cod_user,
      cod_fivewhy: this.id,
      str_name_fivewhy: this.fivewhy.str_name
    };
    try{
      const data = await this._fivewhyService.saveFivewhyAction(this.actionTempObj).toPromise()
      if (data.code !== 888 || data.content.code !== 888) {
        this._showErrorMessage(data.content.message);
      }
      this.actionTempObj.arr_action = []
    }catch(err){
      this._authService.errCheck(err);
      this._showErrorMessage(err);
      this.actionTempObj.arr_action = []
    }
  }

  async saveParticipants() {
    if (this.showSaveParticipants.arr_participants.length) {
      this.showSaveParticipants.arr_participants[this.showSaveParticipants.cod_status - 1].arr_obj_user.forEach(user => {
        if (user.isNewParticipant) {
          this.arr_participants.push(
            user.cod_user
          )
        }
      });
    }

    let params = {
      'cod_user': this._authService.getAuthenticatedUser().id,
      'cod_fivewhys_status': this.fivewhy.cod_status,
      'cod_fivewhy': this.id,
      'arr_participants': this.arr_participants
    };

    try{
      const res = await this._fivewhyService.insertFivewhyParticipant(params).toPromise()
      if (res.code !== 888 || res.content.code !== 888) {
        this._showErrorMessage(res.content.message);
      }
      this.arr_participants = [];
    }catch(err){
      this._authService.errCheck(err);
      this._showErrorMessage(err);
    }
  }

  afkTimeExpired() {
    this.showPopUpKickMessage = false;
    this.saveFiveWhy(true);
  }

  private _loadInformations() {
    this.isLoading = true;
    this.hasChangesPDF = false;
    if (this.id == 0 || this.id == undefined) {
      this.fivewhy = new FiveWhy();

      this.fivewhy.str_status_name = "Operator - Start";
      this.validate5WhyStatus();
      this.isLoading = false;
      this.whysLoading = false;

      this.paramsSelected = [];

      this.loadingInProgress = true;
      this._fivewhyService.getUserLastOptions({ cod_user: this.cod_user }).subscribe({
        next: ({ content }) => {
          if (content.results.length > 0) {
            const savedPreferOptions = content.results[0];
            this.fivewhy.cod_location = savedPreferOptions.cod_location;
            this.fivewhy = {
              ...this.fivewhy,
              cod_location: savedPreferOptions.cod_location,
              cod_area: savedPreferOptions.cod_area,
              cod_subarea: savedPreferOptions.cod_subarea,
              cod_equipment: savedPreferOptions.cod_equipment,
              cod_machine: savedPreferOptions.cod_machine
            }
            this._getHashtags();
            this._getAll(this.fivewhy);
            this.fivewhy.sdca_matrix = this.paramsSelected["sdcaMatrix"];
            this.fivewhy.cod_crew = +this.paramsSelected["crew"];
            this.fivewhy.whys = []
            this.fivewhyOriginalClone = JSON.parse(JSON.stringify(this.fivewhy));
          } else {
            this._loadLocations(() => this._loadCrews(null));
            this.loadingInProgress = false;
          }
        }, 
        error: (err) => {

        }
      });
    } else {
      const params = {
        cod_user: this.cod_user,
        cod_fivewhy: this.id
      };
      forkJoin(
        {
          fivewhy: this._fivewhyService.getSingleFivewhy(params),
          action: this._fivewhyService.getFiveWhyAction(params),
          participants: this._fivewhyService.listFivewhyParticipant(params),
          workflow: this._fivewhyService.getWorkflow(params)
        }
      ).subscribe({
        next: (data) => {
          if (data.fivewhy.code === 888 && data.fivewhy.content.code === 888 && data.fivewhy.content.length > 0 && data.workflow.code === 888 && data.workflow.content.code === 888 && data.action.code === 888 && data.action.content.code === 888) {
            this.fivewhy = data.fivewhy.content.results[0];
            this._getHashtags();
            this.fivewhy.bol_closed = this.fivewhy.dat_closed ? true : false;
            this.machineDatDel = this.fivewhy.machine_dat_del;
            this.triggerDatDel = this.fivewhy.trigger_dat_del;
            if (this.machineDatDel || this.triggerDatDel) {
              this._translateService.get('5WHY.DELETE_MACHINES_TRIGGERS').subscribe((translate) => {
                this.deletedWarningMessage = translate;
              });
              this.showModalDeleted = true;
            }
            this.fivewhy.bol_root_cause_found = this.fivewhy.whys.some(why => why.bol_root_cause_found);

            this.showSaveParticipants = {
              cod_fivewhy: this.id,
              cod_status: this.fivewhy.cod_status,
              cod_location: this.fivewhy.cod_location,
              arr_participants: data.participants.content.results
            };

            this.abAlreadyReported = this.fivewhy.bol_ab_reported;
            this.canShowBPMOptions = this.fivewhy.cod_status == 5 && !this.fivewhy.bol_canceled;
            this.canShowButtonReopen = this.fivewhy.bol_canceled || this.fivewhy.dat_closed !== null;

            this._getRelay(data.workflow.content.results);

            this.statusLog.date_ins = data.workflow.content.results[0].dat_ins;
            this.statusLog.isClosed = data.workflow.content.results[0].dat_closed != null;
            this.statusLog.isCanceled = this.fivewhy.bol_canceled;
            this.statusLog.dat_canceled = this.fivewhy.dat_canceled;
            this.statusLog.str_name_usu_canceled = this.fivewhy.str_name_usu_canceled;
            this.statusLog.currentStatus = this.fivewhy.cod_status;

            this.showSaveActions = {
              ...this.showSaveActions,
              isCanceled: this.fivewhy.bol_canceled,
              isClosed: this.statusLog.isClosed,
              arr_action: data.action.content.results
            };

            this._createConnections();

            this.abReportAttachmentsClone = JSON.parse(JSON.stringify(this.fivewhy.arr_ab_report_files));
            if (this.abReportAttachmentsClone == undefined) {
              this.abReportAttachmentsClone = [];
            } else {
              this.abReportAttachmentsClone.forEach(attach => {
                attach.str_file = attach.str_ab_report_url_file;
              });
            }


            if(this.statusLog.isClosed){ 
              this.ellapsedTime = getTimeBetweenDate(new Date(this.fivewhy.dat_completed), new Date(data.workflow.content.results[0].dat_ins));
              this.blockingTime = getTimeBetweenDate(new Date(this.fivewhy.current_user_date), new Date(this.fivewhy.dat_completed));
              this.blockReopen = this.blockingTime > 732;
            }

            this.blockFivewhy = this.fivewhy.bol_canceled || this.statusLog.isClosed;
            this.blockForStatus = this.fivewhy.cod_status == 6;          

            this.isLoading = false;
            this.existingWhys = this.fivewhy.whys
            this.validate5WhyStatus();

            this.fivewhy_date_report = this.fivewhy.dat_reported;

            this._getAll(this.fivewhy);
            this.whysLoading = false;
            this.isLoading = false;
            this.fivewhyOriginalClone = JSON.parse(JSON.stringify(this.fivewhy));
            this.participantsClone = JSON.parse(JSON.stringify(data.participants.content.results));
          } else {
            this.isLoading = false;
            this._showErrorMessage(data.fivewhy.content.message);
            setTimeout(() => {
              this.backPage();
            }, 2000);
          }
        },
        error: (err) => {
          this._authService.errCheck(err);
          this._showErrorMessage(err);
        }
      });
    }
  }

  private _createConnections(): void {
    if ((!this.connections_created) && this.showSaveActions.arr_action.length > 0) {
      this.connections_created = true;
      this.showSaveActions.arr_action.map(action =>
        this._socketService.subscribeAction(action.cod_actions)
      );

      this.obj_connections.add(
        this._socketService.subscribeToActionInfo().subscribe({
          next: data => this._updateActionList(data),
          error: err => console.log("err", err)
        })
      );

    }
  }

  private _updateActionList(action): void {
    this._fivewhyService.getFiveWhyAction({
      cod_user: this.cod_user,
      cod_fivewhy: this.id
    }).subscribe((data) => this.showSaveActions.arr_action = data.content.results);
  }

  private validate5WhyStatus() {
    if (this.fivewhy.cod_status == 6 || this.fivewhy.str_status_name == 'Operator - Finish') {
      this.isLastStatus = true;
    }
    else {
      this.isLastStatus = false;
    }
  }

  private _getAll(params: FiveWhy) {
    const $this = this;
    $this.loadingInProgress = true;
    $this.canSkipBackValidation = true;

    $this.haveCrew = params.cod_crew != null;

    $this.paramsSelected["crew_selected"] = [{ cod_crews: params.cod_crew }];
    $this.paramsSelected["crew"] = params.cod_crew ? params.cod_crew.toString() : "";
    $this.paramsSelected["sdcaMatrix_selected"] = params.sdca_matrix;
    $this.paramsSelected["sdcaMatrix"] = this._implodeValues(params.sdca_matrix, 'cod_sdca');
    if (params.cod_fivewhys == 0) {
      $this.loadingInProgress = false;
    }
    $this._loadLocations(() => {
      $this.paramsSelected["locations_selected"] = [{ cod_locations: params.cod_location }];
      $this.paramsSelected["locations"] = params.cod_location ? params.cod_location.toString() : "";
      $this.showSaveParticipants.cod_location = $this.paramsSelected["locations"];
      $this._loadAreas(() => {
        $this.paramsSelected["areas_selected"] = [{ cod_area: params.cod_area }];
        $this.paramsSelected["areas"] = params.cod_area ? params.cod_area.toString() : "";
        $this._loadSubAreas(() => {
          $this.paramsSelected["subareas_selected"] = [{ cod_subareas: params.cod_subarea }];
          $this.paramsSelected["subareas"] = params.cod_subarea ? params.cod_subarea.toString() : "";
            $this._loadEquipments(() => {
              $this.paramsSelected["equipment_selected"] = [{ cod_equipment: params.cod_equipment }];
              $this.paramsSelected["equipment"] = params.cod_equipment ? params.cod_equipment.toString() : "";
              $this._loadMachines(() => {
                $this.paramsSelected["machine_selected"] = [{ cod_machine: params.cod_machine }];
                $this.paramsSelected["machine"] = params.cod_machine ? params.cod_machine.toString() : "";
              $this._loadCategory(() => {
                $this.paramsSelected["category_selected"] = [{ cod_category: params.cod_category }];
                $this.paramsSelected["category"] = params.cod_category ? params.cod_category.toString() : "";
                $this._loadCrews(() => {
                  $this.paramsSelected["crew_selected"] = [{ cod_crews: params.cod_crew }];
                  $this.paramsSelected["crew"] = params.cod_crew ? params.cod_crew.toString() : "";
                  $this._loadTriggers(() => {
                    $this.paramsSelected["trigger_selected"] = [{ cod_trigger: params.cod_trigger }];
                    $this.paramsSelected["trigger"] = params.cod_trigger ? params.cod_trigger.toString() : "";
                    $this.loadingInProgress = false;
                    $this.canSkipBackValidation = false;
                  });
                });
              });
            });
          })
        });
      });
    });
  }

  getParamsToSave(isBackValidation = false, kickUser = false, ignoreChanges = false) {
    if(!isBackValidation) {
      this.fivewhy.cod_user = this.cod_user;
      this.fivewhy.arr_ab_report_files = this.validateAbAttachments(this.fivewhy.arr_ab_report_files);
      this.fivewhy.sdca_matrix = this.paramsSelected["sdcaMatrix"];
    } else if (this.fivewhy.sdca_matrix?.length != 0) {
      this.fivewhy.sdca_matrix = this.paramsSelected["sdcaMatrix"].split(",").map(sdca => { return { cod_sdca: +sdca } });
    }

    this.fivewhy.cod_area = +this.paramsSelected["areas"];
    this.fivewhy.cod_crew = +this.paramsSelected["crew"];
    this.fivewhy.cod_machine = +this.paramsSelected["machine"];
    this.fivewhy.cod_trigger = +this.paramsSelected["trigger"];
    this.fivewhy.cod_subarea = +this.paramsSelected["subareas"];
    this.fivewhy.cod_location = +this.paramsSelected["locations"];
    this.fivewhy.cod_equipment = +this.paramsSelected["equipment"];

    this.fivewhy.dat_reported = moment(this.fivewhy_date_report).format(
      "YYYY-MM-DD"
    );

    this.fivewhy.whys = isBackValidation ? this.whysList : this.getWhysToSave();

    if (this.shouldAdvanceProblem && this.fivewhy.cod_status === 5) {
      this.fivewhy.dat_completed = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
    }
    let params = JSON.parse(JSON.stringify(this.fivewhy));

    if (!isBackValidation && !this.isValid(params, kickUser)) {
      this.validationService.boolIsLoading = false;
      return;
    }
    
    const checkIconsForFlow = this.checkIconToUpdateFlow(params);
 
    if(!ignoreChanges) {
      params.bol_root_cause_changed = checkIconsForFlow.rootCauseFound;
      params.bol_ab_report_changed = checkIconsForFlow.abWasReported;
      params.bol_abort_changed = checkIconsForFlow.wasCanceled;
    }
   
 
    return params;
  }

  private validateAbAttachments(attachments) {
    return attachments.filter(attach => (attach.cod_fivewhys_ab_report_files == undefined || attach.deleted));
  }

  private getWhysToSave() {
    return this.whysList.filter(why => {
      if (why.cod_why === 0) return true;

      why.attachments = this.validateAttachments(why.attachments);

      // Validate if existing why was edited to be saved again.
      return this.existingWhys.some(existingWhy => {
        if (why.cod_why == existingWhy.cod_why) {
          return JSON.stringify(why) != JSON.stringify(existingWhy);
        }
      });
    });
  }

  validateAttachments(attachments) {
    return attachments.filter(attach => (attach.cod_why_attachment == undefined || attach.deleted));
  }

  _saveOrAdvance(): void {
    this.is_saving = true;
    if (this.nextExec == 'save') {
      this.saveFiveWhy();
    } else if (this.nextExec == 'advance') {
      this.onAdvanceProblemClick();
    }
  }

  saveOrAdvance(next: string = 'save'): void {
    this.nextExec = next;
    if (!this.fiveWhyActionsComponent.savingFivewhy()) {
      this.showActionIncomplete = true;
      return;
    }

    this._saveOrAdvance();
  }

  discartActions() {
    this.showActionIncomplete = false;
    this._saveOrAdvance();
  }

  showModalReopenFiveWhy() {
    this.showModalReopen5why = true;
  }

  reOpenFiveWhy() {
    this.showModalReopen5why = false;
    this.dateNow = new Date();

    if (this.fivewhy.bol_canceled) {
      this.finalDateReopen = new Date(this.fivewhy.dat_alt);
    } else {
      this.finalDateReopen = new Date(this.fivewhy.dat_closed);
    }

    var timeDiff = Math.abs(this.finalDateReopen.getTime() - this.dateNow.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays <= 30) {

      this.isLoading = true;

      const params = {
        'cod_user': this._authService.getAuthenticatedUser().id,
        'cod_fivewhy': this.id,
        'bol_canceled': this.fivewhy.bol_canceled,
      }

      this._fivewhyService.updateReopenFivewhy(params).subscribe({
        next: data => {
          if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
            this.isLoading = false;
            this.finalDateReopen = null;
            this.dateNow = null;
            this.canceledChanged = true;
            this.load();
          }
          else {
            this.isLoading = false;
            this._showErrorMessage(data.content.message);
          }
        }, 
        error: err => {
          this._authService.errCheck(err);
          this._showErrorMessage(err);
        }
      });
    } else {
      this._showErrorMessage("5WHY.ERROR_REOPEN_5WHY");
    }
  }

    saveFiveWhy(kickUser: boolean = false) {
        this.validationService.boolIsLoading = true;
        this.loadingInProgress = true;
        const params = this.getParamsToSave(false, kickUser);
        if (params) {
            localStorage.setItem('new-five-why-filters', JSON.stringify({
                machines: params['params'],
                equipments: params['cod_equipment'],
                subareas: params['cod_subarea'],
                area: params['cod_area'],
                locations: params['cod_location'],
            }))
            if (this.fivewhy.cod_fivewhys <= 0) {
                this._fivewhyService.saveUserPreferencesOnCreation(params).subscribe({
                  next: () => {
                      this.callSaveMethod(params, kickUser);
                  }, 
                  error:(err) => {
                      this._authService.errCheck(err);
                      this._showErrorMessage(err);
                  }
                });
            } else {
                this.callSaveMethod(params, kickUser);
            }
        } else {
            if (kickUser) this.backPage();
        }
    }

  onAdvanceProblemClick() {
    this.shouldAdvanceProblem = true;
    const paramsOk = this.getParamsToSave();
    if (paramsOk) {
      this._modalEmailService.openModal();
    }
  }

  rootCauseChanged(rootCauseWasfound) {
    this.fivewhy.bol_root_cause_found = rootCauseWasfound;
  }

  refreshListParticipants(event) {
    const params = {
      cod_user: this.cod_user,
      cod_fivewhy: this.id
    };
    if (event) {
      this._fivewhyService.listFivewhyParticipant(params).subscribe((data) => {
        this.showSaveParticipants.arr_participants = data.content.results;
      })
    }
  }

  callSaveMethod(params, kickUser: boolean = false) {
    this.kick_user = kickUser;
    this._fivewhyService.persistFivewhy(params).subscribe({
      next: async (data) => {
        if (data.code === 888 && data.content.code === 888) {
          this.id = data.content.results[0].fivewhy_sp_in_up;
        
          await this.saveParticipants();
          await this.saveActions();

          if (this.shouldAdvanceProblem) {
            this.shouldAdvanceProblem = false;
            // ERROR
            this._fivewhyService.updateFivewhyStatus({ cod_user: this.cod_user, cod_fivewhy: this.id, cod_user_email_sent: this.codUserToSendEmail })
              .subscribe({
                next: dataStatus => {
                  if (dataStatus.code === 888 && dataStatus.content.code === 888) {
                    this.whysList = [];
                    this.validationService.boolIsLoading = false;
                    this.loadingInProgress = false;
                    
                    if (this.codUserToSendEmail > 0) { 
                      this._fivewhyService.sendEmailNotification({cod_user: this.cod_user, cod_user_email: this.codUserToSendEmail, cod_fivewhy: this.id, notes: this.notesToSendEmail })
                        .subscribe(statusResponse => { 
                          this.codUserToSendEmail = 0;
                          this.notesToSendEmail = '';
                        });
                    } 
          
                    kickUser
                      ? this.backPage() 
                      : this.isNewFivewhy 
                        ?  this._router.navigate([`/problem-terminator/details/${this.id}`])
                        : this.load();
          
                  } else {
                    this.validationService.boolIsLoading = false;
                    this.loadingInProgress = false;
                    this._showErrorMessage(dataStatus.content.message);
                  }
                },
                error: (err) => {
                  this._authService.errCheck(err);
                  this._showErrorMessage(err);
                }
              });
          } else {
            this.validationService.boolIsLoading = false;
            this.loadingInProgress = false;
            kickUser 
              ? this.backPage() 
              : this.isNewFivewhy 
                ?  this._router.navigate([`/problem-terminator/details/${this.id}`])
                : this.load();
          }
        } else {
          this.validationService.boolIsLoading = false;
          this.loadingInProgress = false;
          this._showErrorMessage(data.content.message);
        }
      },
      error: (err) => {
        this._authService.errCheck(err);
        this._showErrorMessage(err);
      }
    });
  }

  private _reloadPage(goBack: boolean = false) {
    goBack 
      ? this.backPage() 
      : this.isNewFivewhy 
        ?  this._router.navigate([`/problem-terminator/details/${this.id}`])
        : this.load();
  }

  checkIconToUpdateFlow(params) {
    let idOfLastRootCause = this.existingWhys.find(why => why.bol_root_cause_found)

    const RootCauseChanged = params.whys.some(why => {
      if (idOfLastRootCause) {
        return (why.bol_root_cause_found && why.cod_why != idOfLastRootCause.cod_why) || !this.fivewhy.bol_root_cause_found 
      } else {
        return why.bol_root_cause_found;
      }
    });

    const wasABreportedChanged = (params.bol_ab_reported && !this.abAlreadyReported) || (!params.bol_ab_reported && this.abAlreadyReported);

    return { rootCauseFound: RootCauseChanged, abWasReported: wasABreportedChanged, wasCanceled: this.canceledChanged };
  }

  isValid(fivewhy: FiveWhy, kickUser = false) {
    if (fivewhy.cod_location <= 0 || fivewhy.cod_location === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.LOCATION_REQUIRED");
      return false;
    }

    if (fivewhy.cod_area <= 0 || fivewhy.cod_area === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.AREA_REQUIRED");
      return false;
    }

    if (fivewhy.cod_subarea <= 0 || fivewhy.cod_subarea === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.SUBAREA_REQUIRED");
      return false;
    }

    if (
      this.paramsSelected["equipment"] <= 0 ||
      this.paramsSelected["equipment"] === undefined
    ) {
      if (!kickUser) this._showErrorMessage("5WHY.EQUIPMENT_REQUIRED");
      return false;
    }

    if (fivewhy.cod_machine <= 0 || fivewhy.cod_machine === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.MACHINE_REQUIRED");
      return false;
    }

    if (!moment(fivewhy.dat_reported).isValid()) {
      if (!kickUser) this._showErrorMessage("5WHY.DATE_REQUIRED");
      return false;
    }

    if (fivewhy.cod_crew <= 0 || fivewhy.cod_crew === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.CREW_REQUIRED");
      return false;
    }

    if (fivewhy.str_name === undefined || fivewhy.str_name.trim() === "") {
      if (!kickUser) this._showErrorMessage("5WHY.TITLE_REQUIRED");
      return false;
    }

    if (
      this.paramsSelected["category"] <= 0 ||
      this.paramsSelected["category"] === undefined
    ) {
      if (!kickUser) this._showErrorMessage("5WHY.CATEGORY_REQUIRED");
      return false;
    }

    if (fivewhy.cod_trigger <= 0 || fivewhy.cod_trigger === undefined) {
      if (!kickUser) this._showErrorMessage("5WHY.TRIGGER_REQUIRED");
      return false;
    }

    if (
      fivewhy.str_problem_details === undefined ||
      fivewhy.str_problem_details.trim() === ""
    ) {
      if (!kickUser) this._showErrorMessage("5WHY.PROBLEM_DETAIL_REQUIRED");
      return false;
    }
    if (
      fivewhy.str_corrective_actions === undefined ||
      fivewhy.str_corrective_actions.trim() === ""
    ) {
      if (!kickUser) this._showErrorMessage("5WHY.CORRECTIVE_ACTIONS_REQUIRED");
      return false;
    }

    const whysNotValid = this.whysList.some((why, index, list) => {
      if (why.str_why_description.trim() === "") {
        if (!kickUser) this._showErrorMessage("5WHY.WHY_DESCRIPTION_REQUIRED", ` (${this.whyTranslation} ${index + 1})`);
        return true;
      }
      if (why.str_evidence.trim() === "") {
        if (!kickUser) this._showErrorMessage("5WHY.WHY_EVIDENCE_REQUIRED", ` (${this.whyTranslation} ${index + 1})`);
        return true;
      }
    });
    if (whysNotValid) {
      return false
    }

    if (fivewhy.bol_root_cause_found && !fivewhy.sdca_matrix) {
      if (!kickUser) this._showErrorMessage("5WHY.SDCA_MATRIX_REQUIRED");
      return false;
    }

    return true;
  }

  backPage() {
    this._router.navigate(['/problem-terminator'], {state: {data: {page: this.pageIndex ? this.pageIndex : '1'}}})
  }

  cancelFivewhy(comment) {
    this.fivewhy.bol_canceled = true;
    this.canceledChanged = true;
    this.showCancelModalConfirmation = false;
    this.fivewhy.str_cancel_comment = comment;
    this.saveFiveWhy()
  }

  showCancelModal() {
    this.showCancelModalConfirmation = true;
  }

  completeFiveWhy() {
    this.fivewhy.bol_closed = true;
    this.fivewhy.bol_reopen = true;
    this.saveFiveWhy()
  }

  private _loadLocations(fn) {
    this._clearLocationList();

    this._setLoading("locations", true);
    this._fivewhyService.getAllLocationsByPrivileges({ cod_user: this.cod_user, dsc_page_privilege: "5WHY_MENU_VISUALIZE" }).subscribe({
      next: (data) => {
        this.locationsList = data.content.results;
        this._setLoading("locations", false);
            if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['locations', 'locations_selected'])) {
              const {
                sessionFilterPrefillAllowedValues,
                paramsSelected
              } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['locations', 'locations_selected'], 'cod_locations');
              this.paramsSelected = paramsSelected;
              this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
              this._loadAreas(undefined);
            } else {
              fn?.();
            }
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  private _loadAreas(fn) {
    this._clearAreaList();
    if (typeof this.paramsSelected["locations"] != typeof undefined && this.paramsSelected["locations"].toString() != "") {
      this._setLoading("areas", true);
      let params = {
        arr_cod_locations: this.paramsSelected["locations"].toString().split(","),
        cod_user: this._authService.getAuthenticatedUser().id,
        dsc_page_privilege: "5WHY_MENU_VISUALIZE",
      };
      this._fivewhyService.getFilteredAllowedAreas(params).subscribe({
        next: (data) => {
          this.areaList = this._userService.getArrayContent(data);
          this._setLoading("areas", false);
              if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['areas', 'areas_selected'])) {
                const {
                  sessionFilterPrefillAllowedValues,
                  paramsSelected
                } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['areas', 'areas_selected'], 'cod_area');
                this.paramsSelected = paramsSelected;
                this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
                this._loadSubAreas(undefined);
              } else {
                fn?.();
              }
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    }
  }

  private _loadSubAreas(fn) {
    this._clearSubAreaList();
    if (this.paramsSelected["areas"] && this.paramsSelected["areas"].toString() != "") {
      this._setLoading("subareas", true);
      let objParam = {
        arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
        arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
        cod_user: this._authService.getAuthenticatedUser().id,
      };
      this._fivewhyService.getFilteredAllowedSubareas(objParam).subscribe({
        next: (data) => {
          this.subAreasList = this._areaService.getArrayContent(data);
          this._setLoading("subareas", false);
              if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['subareas', 'subareas_selected'])) {
                const {
                  sessionFilterPrefillAllowedValues,
                  paramsSelected
                } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['subareas', 'subareas_selected'], 'cod_subareas');
                this.paramsSelected = paramsSelected;
                this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
                this._loadEquipments(undefined);
                this._loadCategory(undefined);
                this._loadTriggers(undefined);
              } else {
                fn?.();
              }
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    }
  }

  private _loadCategory(fn) {
    this._clearCategories();
    this._setLoading("category", true);

    const params = {
      cod_user: this.cod_user,
      cod_areas: this.paramsSelected["areas"]?.toString(),
      cod_subareas: this.paramsSelected["subareas"]?.toString(),
      cod_locations: this.paramsSelected["locations"]?.toString(),
      str_filter: (this.id == 0 || this.id == undefined) ? 'NOT_FILTER' : 'IS_FILTER',
    };

    this._triggerService.listCategoriesWithTriggers(params).subscribe({
      next: (data) => {
        this.categoriesList = this._fivewhyService.getArrayContent(data);
        this._setLoading("category", false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  private _loadEquipments(fn) {
    this._clearEquipments();
    this._setLoading("equipment", true);
    let params = {
      cod_user: this.cod_user,
      cod_areas: this.paramsSelected["areas"].toString(),
      cod_subareas: this.paramsSelected["subareas"].toString(),
      cod_locations: this.paramsSelected["locations"].toString(),
      str_filter: (this.id == 0 || this.id == undefined) ? 'NOT_FILTER' : 'IS_FILTER'
    }

    this._machinesService.getListEquipmentsWithMachines(params).subscribe({
      next: (data) => {
        this.equipmentList = this._fivewhyService.getArrayContent(data);
        this._setLoading("equipment", false);
            if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['equipment', 'equipment_selected'])) {
              const {
                sessionFilterPrefillAllowedValues,
                paramsSelected
              } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['equipment', 'equipment_selected'], 'cod_equipment');
              this.paramsSelected = paramsSelected;
              this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
              this._loadMachines(undefined);
            } else {
              fn?.();
            }
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  private _loadMachines(fn) {
    this._clearMachinesList();
    if ((typeof this.paramsSelected["locations"] != typeof undefined && this.paramsSelected["locations"].toString() != "") &&
       (typeof this.paramsSelected["areas"] != typeof undefined && this.paramsSelected["areas"].toString() != "") &&
       (typeof this.paramsSelected["subareas"] != typeof undefined && this.paramsSelected["subareas"].toString() != "")) {
      this._setLoading("machine", true);
      let params = {
        cod_user: this.cod_user,
        cod_areas: this.paramsSelected["areas"].toString(),
        cod_subareas: this.paramsSelected["subareas"].toString(),
        cod_locations: this.paramsSelected["locations"].toString(),
        cod_equipments: this.paramsSelected["equipment"].toString() ?? 'null',
        str_filter: (this.id == 0 || this.id == undefined) ? 'NOT_FILTER' : 'IS_FILTER'
      }
      this._machinesService.getListMachines(params).subscribe({
        next: (data) => {
          this.machineList = this._fivewhyService.getArrayContent(data);
          this._setLoading("machine", false);
              if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['machine', 'machine_selected'])) {
                const {
                  sessionFilterPrefillAllowedValues,
                  paramsSelected
                } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['machine', 'machine_selected'], 'cod_machine');
                this.paramsSelected = paramsSelected;
                this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
                this._loadCrews(undefined);
              } else {
                fn?.();
              }
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    }
  }

  private _loadTriggers(fn) {
    this._clearTriggersList();
    if ((typeof this.paramsSelected["category"] != typeof undefined && this.paramsSelected["category"].toString() != "") &&
      (typeof this.paramsSelected["locations"] != typeof undefined && this.paramsSelected["locations"].toString() != "") &&
      (typeof this.paramsSelected["areas"] != typeof undefined && this.paramsSelected["areas"].toString() != "") &&
      (typeof this.paramsSelected["subareas"] != typeof undefined && this.paramsSelected["subareas"].toString() != "")) {
      this._setLoading("trigger", true);
      let params = {
        cod_user: this.cod_user,
        cod_areas: this.paramsSelected["areas"].toString(),
        cod_subareas: this.paramsSelected["subareas"].toString(),
        cod_locations: this.paramsSelected["locations"].toString(),
        cod_categories: this.paramsSelected["category"].toString(),
        str_filter: (this.id == 0 || this.id == undefined) ? 'NOT_FILTER' : 'IS_FILTER'
      }

      this._triggerService.getTriggerList(params).subscribe({
        next: (data) => {
          this.triggersList = this._fivewhyService.getArrayContent(data);
          this._setLoading("trigger", false);
          if (typeof fn != typeof undefined && fn) {
            fn();
          }
        },
        error: (err) => {
          this._authService.errCheck(err);
          console.log(err);
        }
      });
    }
  }

  private _loadSdcaMatrix(fn) {
    this._clearSdcaList();
    let params = {
      cod_user: this._authService.getAuthenticatedUser().id
    }
    this._fivewhyService.getSdcaMatrix(params).subscribe({
      next: data => {
        this.sdcaMatrixList = data.content.results;
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  private _loadCrews(fn) {
    this._setLoading("crew", true);
    this._crewService.getLocationCrew({ cod_user: this.cod_user, cod_location: this.fivewhy.cod_location }).subscribe({
      next: (data) => {
        if (data.type == "success" && data.content.type == "success") {
          this.crewList = data.content.results;
          
          if (this.crewList.find(c => c.cod_crews == this.paramsSelected['crew']) == null) {
            this.fivewhy.cod_crew = null;
            this.paramsSelected['crew'] = null;
            this.paramsSelected['crew_selected'] = [];
          }

          this._setLoading("crew", false);
          if (this._sessionFilterService.preffiledConditions(this.sessionFilterPrefillAllowedValues, ['crew', 'crew_selected'])) {
            const {
              sessionFilterPrefillAllowedValues,
              paramsSelected
            } = this._sessionFilterService.setSessionFilter(this._activatedRoute.snapshot.queryParams, this.paramsSelected, this.sessionFilterPrefillAllowedValues, ['crew', 'crew_selected'], 'cod_crews');
            this.paramsSelected = paramsSelected;
            this.sessionFilterPrefillAllowedValues = sessionFilterPrefillAllowedValues;
          } else {
            fn?.();
          }
        }
      },
      error: (err) => {
        let e = JSON.parse(err._body);
        console.log(e.content);
        this._authService.errCheck(err);
      }
    });
  }

  selectLocations($event) {
    this.paramsSelected["locations"] = this._implodeValues($event.selectedOptions, "cod_locations");
    this.fivewhy.cod_location = this.paramsSelected["locations"];
    this._clearSubAreaList()
    this._clearAreaList()
    this._clearCategories()
    this._clearEquipments()
    this._clearMachinesList()
    this._loadAreas(null);
    this._loadCategory(null);
    this._loadTriggers(null);
    this._loadCrews(null);
    this.showSaveParticipants.cod_location = this.paramsSelected["locations"]; 
    this._getHashtags()
    
  }

  selectArea($event) {
    this.paramsSelected["areas"] = this._implodeValues($event.selectedOptions, "cod_area");
    this._clearSubAreaList()
    this._clearCategories()
    this._clearEquipments()
    this._clearMachinesList()
    this._loadSubAreas(null);
    this._loadCategory(null);
    this._loadTriggers(null);
  }

  selectSubArea($event) {
    this.paramsSelected["subareas"] = this._implodeValues($event.selectedOptions, "cod_subareas");
    this._clearCategories()
    this._clearEquipments()
    this._clearMachinesList()
    this._loadCategory(null);
    this._loadTriggers(null);
    this._loadMachines(null);
    this._loadEquipments(null);
  }

  selectCategories($event) {
    this.paramsSelected["category"] = this._implodeValues($event.selectedOptions, "cod_category");
    this._loadTriggers(null);
  }

  selectEquipment($event) {
    this.paramsSelected["equipment"] = this._implodeValues($event.selectedOptions, "cod_equipment");
    this._clearMachinesList()
    this._loadMachines(null);
  }

  selectMachine($event) {
    this.paramsSelected["machine"] = this._implodeValues($event.selectedOptions, "cod_machine");
    const cod_equipment = this._implodeValues($event.selectedOptions, "cod_equipment")
    if((!this.paramsSelected["equipment"] && cod_equipment) || (this.paramsSelected["equipment"]!=cod_equipment)){
      this.paramsSelected["equipment"] = cod_equipment;
      this.paramsSelected["equipment_selected"] = [{ cod_equipment: cod_equipment }];
    }
  }

  selectTrigger($event) {
    this.paramsSelected["trigger"] = this._implodeValues($event.selectedOptions, "cod_trigger");
  }

  selectCrew($event) {
    this.paramsSelected["crew"] = this._implodeValues($event.selectedOptions, "cod_crews");
  }

  selectSdca($event) {
    this.paramsSelected["sdcaMatrix"] = this._implodeValues($event.selectedOptions, "cod_sdca");
  }

  onDateChanged({ target }) {
    if (target.value < this.dateMinLimit) {
      this.fivewhy_date_report = this.dateMinLimit;
    } else if (target.value > this.dateMaxLimit) {
      this.fivewhy_date_report = this.dateMaxLimit;
    }
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
    this.loadingInProgress = false;
    this.validationService.boolIsLoading=false;
  }

  fileChangeEvent(event) {
    this.isUploadingAttachments = true;

    let obj_params = {
      event: event,
      allowedExtensions: [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
      ],
    };

    this._uploadService.uploadFileBase64(obj_params).subscribe({
      next: (response) => {
        if (response.type == "success") {
          let imgName = response.content.str_name
          const splitText = imgName.split('.');
          const fileType = splitText[splitText.length - 1];
          const newFile = { cod_usu_ins: this.cod_user, dat_ins: new Date().toString(), str_url_file: response.content.str_name, isPdf: fileType.toUpperCase() == 'PDF' };
          this.fivewhy.arr_ab_report_files.push(newFile);
          this.abReportAttachmentsClone.push(newFile)
          this.fileInput.nativeElement.value = '';
          this.isUploadingAttachments = false
        } else {
          this.isUploadingAttachments = false;
          this._showErrorMessage(response.content);
        }
      },
      error: (err) => {
        let e = JSON.parse(err._body);
        console.log(e);
        this._showErrorMessage(err);
      }
    });
  }

  onDeleteFile(imageDeleted: AbReportFiles) {
    if (imageDeleted.cod_fivewhys_ab_report_files > 0) {
      this.fivewhy.arr_ab_report_files.forEach((attach) => {
        if (attach.cod_fivewhys_ab_report_files === imageDeleted.cod_fivewhys_ab_report_files)
          attach.deleted = true;
      });
    } else {
      this.fivewhy.arr_ab_report_files = this.fivewhy.arr_ab_report_files.filter(attach => attach.str_url_file !== imageDeleted.str_url_file);
    }
  }

  private _clearLocationList() {
    this.paramsSelected["locations"] = "";
    this.paramsSelected["locations_selected"] = [];
    this.locationsList = [];
    this._clearAreaList();
  }

  private _clearAreaList() {
    this.paramsSelected["areas"] = "";
    this.paramsSelected["areas_selected"] = [];
    this.areaList = [];
    this._clearSubAreaList();
  }

  private _clearSubAreaList() {
    this.paramsSelected["subareas"] = "";
    this.paramsSelected["subareas_selected"] = [];
    this.subAreasList = [];
    this._clearEquipments();
  }

  private _clearCategories() {
    this.paramsSelected["category"] = "";
    this.paramsSelected["category_selected"] = [];
    this.categoriesList = [];
    this._clearTriggersList();
  }

  private _clearEquipments() {
    this.paramsSelected["equipment"] = "";
    this.paramsSelected["equipment_selected"] = [];
    this.equipmentList = [];
    this._clearMachinesList();
  }

  private _clearMachinesList() {
    this.paramsSelected["machine"] = "";
    this.paramsSelected["machine_selected"] = [];
    this.machineList = [];
  }

  private _clearTriggersList() {
    this.paramsSelected["trigger"] = "";
    this.paramsSelected["trigger_selected"] = [];
    this.triggersList = [];
  }

  private _clearSdcaList() {
    this.paramsSelected["sdcaMatrix"] = "";
    this.paramsSelected["sdcaMatrix_selected"] = [];
    this.sdcaMatrixList = [];
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _implodeValues(objValues, idField) {
    let ids = "";
    if (objValues) {
      objValues.forEach((element) => {
        if (ids != "") {
          ids += ",";
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  private _showErrorMessage(err, additionalMessage = "") {
    this._translateService.get(err).subscribe((translate) => {
      this.errorMessage = translate + additionalMessage;
      this.showError = true;
    });
  }

  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }

  validateBackChanges() {
    this.backPage();
  }

  hasChangesInData(): boolean {
    const fivewhy = this.getParamsToSave(true, false, true);
    
    const hasFivewhyChanged = JSON.stringify(fivewhy) != JSON.stringify(this.fivewhyOriginalClone);
    const hasNewActions = this.actionTempObj.arr_action.length > 0;
    const hasParticipantsChanged = JSON.stringify(this.participantsClone) != JSON.stringify(this.showSaveParticipants.arr_participants);

    if (hasFivewhyChanged || hasNewActions || hasParticipantsChanged) {
      return true
    } 
    
    return false;
  }

  validateChangesBeforeDownload($callback = null) {
    this.hasChangesPDF = false; 

    if(this.hasChangesInData()) {
      setTimeout(() => {
        this.hasChangesPDF = true;
      });
    } else {
      $callback();
    }
  }

  public nameInput($event) : void {
    this.searchHashtag($event);
    this.backup_name_to_hashtag = $event;
  }

  private searchHashtag(text: string): void {
    this.arr_opt_hashtag = [];

    if (text.indexOf('#') != -1) {
      let str_part_hashtag = text.substring(text.lastIndexOf('#') + 1);
      for (let i = 0; i < this.arr_hashtag.length; i++) {
        if (this.arr_hashtag[i].toLowerCase().lastIndexOf(str_part_hashtag.toLowerCase()) != -1) {
          this.arr_opt_hashtag.push(this.arr_hashtag[i]);
        }
      }
    }

  }

  public onSelectedHashtag(str_opt_hashtag: string): void {
    let str_name = this.backup_name_to_hashtag;

    this.fivewhy.str_name = `${str_name.substring(0, str_name.lastIndexOf('#'))}${str_opt_hashtag} `;
    this.arr_opt_hashtag = [];

    this.content_editable_name.focus();
  }

  _getHashtags() {
    this._fivewhyService.listHashtags({
      cod_user: this._authService.getAuthenticatedUser().id,
      'dsc_page_privilege': '5WHY_MENU_VISUALIZE',
      'cod_location': this.fivewhy && this.fivewhy.cod_location ? this.fivewhy.cod_location : null
    }).subscribe(data => {
      this.arr_hashtag = []
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.arr_hashtag = data.content.results?.map(h => h?.str_hashtag);
      }
    });
  }

  getUserTime() {
    if (this.fivewhy.str_name_usu_ins && this.fivewhy.dat_ins) {
      return `${this.fivewhy.str_name_usu_ins} - ${this.fivewhy.dat_ins}`;
    }
  }

  getRating($event){
    this.arr_rating = $event
  }

  preFillBrowserUrl(): void {
    this._router.navigate(['/problem-terminator/details'], {queryParams: {
        locations: this.paramsSelected['locations'],
        machine: this.paramsSelected['machine'],
        areas: this.paramsSelected['areas'],
        subareas: this.paramsSelected['subareas'],
        equipment: this.paramsSelected['equipment'],
        crew: this.paramsSelected['crew'],
        fivewhy_date_report: this.fivewhy_date_report
      }});
  }
}
