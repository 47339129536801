export class Trigger {
    cod_trigger: number;
    cod_category: number;
    arr_locations: [];
    arr_areas: [];
    arr_subareas: [];
    str_name: string;
    str_name_de: string;
    str_name_es: string;
    str_name_pt: string;
    str_name_fr: string;
    str_name_nl: string;
    str_name_ru: string;
    str_name_uk: string;
    str_name_zh: string;
    str_name_ko: string;
    str_name_vi: string;
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;

    constructor() {
        this.cod_trigger = 0;
        this.cod_category = 0;
        this.arr_locations = [];
        this.arr_areas = [];
        this.arr_subareas= [];
        this.str_name = '';
        this.str_name_de = '';
        this.str_name_es = '';
        this.str_name_pt = '';
        this.str_name_fr = '';
        this.str_name_nl = '';
        this.str_name_ru = '';
        this.str_name_uk = '';
        this.str_name_zh = '';
        this.str_name_ko = '';
        this.str_name_vi = '';
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
    }
}
