import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthEvent } from '../models/auth-event.model';
import { MsalService } from '@azure/msal-angular';
@Injectable()
export class AuthService extends MainService {

  // private authEvent = new Subject<AuthEvent>();
  private authEvent = new ReplaySubject<AuthEvent>(1); // emmits X last events upon subscription, X here is 1

  constructor(private http: HttpClient, private msalService: MsalService) {
    super('/auth', http);
  }

  getThirdPartyLogin(str_id_doc: string, str_password: string): Observable<any> {
    const path = '/login/' + str_id_doc + '/' + btoa(str_password);
    return this.get(path);
  }

  getAuthUser(str_userid: string, str_sessionid: string): Observable<any> {
    const path = '/user/' + str_userid + '/' + str_sessionid;
    return this.get(path);
  }

  getSamlAuth() {
    const path = '/sso/saml/login/';
    return this.get_nocache(path);
  }

  getSamlAuthForceRelogin(){
    const path = '/sso/saml/re-login/';
    return this.get_nocache(path);
  }

  /**
   * @deprecated
   * /v1/auth/sso/msal/loginInfo
   * @param token 
   * @returns 
   */
  postMsalLoginInfo(token) {
    const path = '/sso/msal/loginInfo';
    return this.post(path, {token});
  }

  /**
   * /v1/auth/sso/getUser
   * @param token idToken
   * @returns will return same obj as calling /v1/auth/sso/msal/loginInfo + socket returns
   */
  getUserAuth(token) {
    const path = '/sso/getUser';
    return this.post(path, {token});
  }

  setAuthenticatedUser(object) {
    localStorage.setItem('authenticatedUser', JSON.stringify(object));
  }

  setUndefinedUser(object) {
    localStorage.setItem('undefinedUser', JSON.stringify(object));
  }

  getAuthenticatedUser() {
    return JSON.parse(localStorage.getItem('authenticatedUser'));
  }

  getAuthenticatedUserNameNoCaps() {
    return JSON.parse(localStorage.getItem('authenticatedUser'))
        .name.split(' ')
        .map((namePart) => namePart[0].toUpperCase() + namePart.slice(1).toLowerCase())
        .join(' ');
  }

  getAuthEventSubject(){
    return this.authEvent;
  }

  getUndefinedUser() {
    return JSON.parse(localStorage.getItem('undefinedUser'));
  }

  getUserPrivilege(str_privilege){
    let obj_user = this.getAuthenticatedUser();

    if (obj_user) {
      for(const element of obj_user.arr_privileges){
        if(element.dsc_privilege_const==str_privilege){
          if(element.arr_locations_types.arr_meetings_types || element.arr_locations_types.arr_locations){
            return true;
          }
        }
      }
    }
    return false;
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('authenticatedUser') ? true : false;
  }

  deleteAuthenticatedUser(doMsalLogout = true) {
    if(doMsalLogout){
      this.msalService.logoutRedirect();
    }
    localStorage.clear();
  }

  
  /**
   * @deprecated
   * /v1/auth/logout/:username/:sessionid/:str_saml_name_id/:sso_session_index
   * @returns 
   */
  logout_old(): Observable<any> {
    const objUser = this.getAuthenticatedUser();
    const path = '/logout/' + objUser.username + '/' + objUser.session_id + '/' + objUser.str_saml_name_id + '/' + objUser.sso_session_index;
    this.deleteAuthenticatedUser();
    return this.delete(path);
  }

  /**
   * /v1/auth/sso/logout
   * 
   * Uses access token on header to get any data it needs to logout
   * @returns 
   */
  logout(): Observable<any> {
    const path = '/sso/logout';
    const request = this.delete(path);
    request.subscribe({
      next: () => {
        this.deleteAuthenticatedUser();
      }
    });
    return request;
  }

  displayWarning(params): Observable<any>{
    const path = '/display-warning';
    return this.post(path, params);
  }

  checkVersion(): Observable<any>{
    const path = '/check-frontend-version';
    return this.get(path);
}

  errCheck(err){
    console.log(err.status, err);
    if(err.status==401 || err.status==403 || err.type=='string.min'){
      this.deleteAuthenticatedUser();
      window.location.replace('');
    }
  }

  getHmiLogin(){
    return localStorage.getItem('hmiLogin')?? false;
  }
  
  setHmiLogin(){
    localStorage.setItem('hmiLogin', 'true');
  }

  removeHmiLogin(){
    localStorage.removeItem('hmiLogin');
  }

}
