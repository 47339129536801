import {Component, OnInit} from '@angular/core';

import {FormsDynamicAdminService} from '../services/forms-dynamic.service';
import {Form} from '../models/form.model';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from "../models/select_pagination.model";
import {NotificationService} from "./../../../main/services/notification.service";
import {Category} from '../models/category.model';
import * as _ from 'lodash';

@Component({
    selector: 'forms-dynamic-admin',
    templateUrl: './forms-dynamic-admin.component.html',
    styleUrls: ['./forms-dynamic-admin.component.css']
})
export class FormsDynamicAdminComponent implements OnInit {
    formsList: Form[] = [];

    isLoading = false;
    hasError = false;
    searchTerm: string;
    searchTimer: any;
    codForm: number = 0;
    createRecord: boolean = false;
    awaitConfirmDelete: boolean = false;
    deleteFormId: number = 0;
    showErrorDelete: boolean = false;
    errorMessageDelete: string = '';
    cod_user: number = this._authService.getAuthenticatedUser().id;
    dsc_page_privilege: string = 'ADMIN_FORMS_LISTING';
    bol_show_copy = false;
    copyName = '';
    selectedRecords = [];
    showError: boolean = false;
    successCopyMessage = '';
    errorMessage: string = '';
    str_link: any;
    str_hashtag: any;
    bol_use_previous_answers: boolean;
    str_link_desc: any;
    arr_links: any;
    arr_links_desc: any;
    arr_cod_links_del: any;
    form_hyperlinks: any;
    categories: Array<Category> = [];
    str_name: string = '';
    str_name_de: string = '';
    str_name_es: string = '';
    str_name_fr: string = '';
    str_name_nl: string = '';
    str_name_pt: string = '';
    str_name_ru: string = '';
    str_name_ua: string = '';
    str_name_zh: string = '';
    str_name_ko: string = '';
    str_name_vi: string = '';    
    cod_zone: number = null;
    formObj = null;
    privFormChange: boolean;
    paramsSelected: any;

    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;

    showSuccessMessage: boolean;
    showErrorMessage: boolean;
    emitSuccessMessage: boolean;
    emitErrorMessage: boolean;

    upload_api_url = environment().uploadGetFilesApiUrl + '/';

    constructor(
        private service: FormsDynamicAdminService, 
        private translateService: TranslateService, 
        public _authService: AuthService,
        private _notify: NotificationService
    ) {
        this.searchTerm = '';
        this.codForm = 0;
        this.resultsPerPage = [];
        this.paramsSelected = [];
    }

    ngOnInit() {
        this._loadRowsByPage();
        this.translateService.get('DYNAMICFORMS.ADMIN.FORM_LOGBOOK_ERROR').subscribe(translate => {
            this.errorMessageDelete = translate;
        });

        this.privFormChange = this._authService.getUserPrivilege('ADMIN_FORMS_CHANGE');
    }

    private _loadRowsByPage() {
        this.pageIndex = 1;
        this.totalPages = [];

        this.translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this.translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                this.str50perpage = '50 ' + translate;
                this.translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                    this.str100perpage = '100 ' + translate;
                    this.resultsPerPage = [
                        new SelectPagination(10, this.str10perpage),
                        new SelectPagination(50, this.str50perpage),
                        new SelectPagination(100, this.str100perpage),
                    ];
                    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
                    this.resultsPerPageSelected = this.resultsPerPage[0].qty;
                    this.loadList();
                });
            });
        });
    }

    loadList() {
        this.reset();
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        let offset = tmpPage * this.resultsPerPageSelected;
        let limit = this.resultsPerPageSelected;
        let params = {
            'term': this.searchTerm,
            'limit': limit,
            'offset': offset,
            'dsc_page_privilege': this.dsc_page_privilege,
            'cod_user': this.cod_user
        };
        this.service.getPagedForms(params).subscribe(res => {
            this.formsList = this.service.getArrayContent(res);
            this.isLoading = false;
            this.maxPage = Math.ceil(res.content.count / this.resultsPerPageSelected);
            this.totalPages = this.pagination(this.pageIndex, this.maxPage);
            this.showErrorMessage = this.emitErrorMessage;
            this.showSuccessMessage = this.emitSuccessMessage;
            this.emitErrorMessage = null;
            this.emitSuccessMessage = null;
        },
        err => { this._authService.errCheck(err);
            this.hasError = true;
            this.isLoading = false; }
        );
    }

    public reset() {
        this.isLoading = true;
        this.hasError = false;
        this.formsList = [];
    }

    public editForm(id, reload) {
        if(!this._authService.getUserPrivilege('ADMIN_FORMS_CHANGE')){
            return;
        }
        this.codForm = id;
        this.createRecord = !reload;
        if (reload) {
            this.loadList();
        }
    }

    public search(resetPage: boolean) {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        const $this = this;
        this.searchTimer = setTimeout(function () {
            if (resetPage) {
                $this.pageIndex = 1;
            }
            $this.loadList();
            $this.selectedRecords = [];
        }, environment().debounceTime);
    }

    public confirmDelete() {
        this.service.getFormInLogbook(this.deleteFormId, this.cod_user).subscribe(res => {
            if (res.content.results.forms_sp_se_form_in_logbook == 0 ) {
                this.service.delete(this.deleteFormId, this._authService.getAuthenticatedUser().id).subscribe(res1 => {
                    this.awaitConfirmDelete = false;
                    this.emitErrorMessage = false;
                    this.translateService.get('DYNAMICFORMS.ADMIN.DELETED')
                    .subscribe(translate => this._notify.success(translate));
                    this.loadList();
                }, err => { 
                    this._authService.errCheck(err); 
                    console.log(err); 
                    this.translateService.get('DYNAMICFORMS.ADMIN.MSG_ERROR')
                    .subscribe(translate => this._notify.error(translate));
                });
            } else {
                this.showErrorDelete = true;
            }
        }, err => { this._authService.errCheck(err); console.log(err); });

    }

    public toggleDeleteForm(id = 0) {
        if(!this._authService.getUserPrivilege('ADMIN_FORMS_CHANGE')){
            return;
        }
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteFormId = id;
    }

    closeErrorMessage() {
        this.showErrorDelete = false;
        this.awaitConfirmDelete = false;
        this.showError = false;        
        this.bol_show_copy = false;
    }

    public copyForm() {
        if (this.selectedRecords.length != 1) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.ONE');
            return;
        }
        this.loadForEditing(this.selectedRecords[0]);
    }

    loadForEditing(id) {
        if (isNaN(id)) {
            return;
        }

        this.service.get(id, 0,this.upload_api_url,this._authService.getAuthenticatedUser().id).subscribe(form => {
            this.formObj = form;
            this.str_name = `${form.content.str_name}_copy`;
            this.str_name_de = form.content.str_name_de;
            this.str_name_es = form.content.str_name_es;
            this.str_name_fr = form.content.str_name_fr;
            this.str_name_nl = form.content.str_name_nl;
            this.str_name_pt = form.content.str_name_pt;
            this.str_name_ru = form.content.str_name_ru;
            this.str_name_ua = form.content.str_name_ua;
            this.str_name_zh = form.content.str_name_zh;
            this.str_name_ko = form.content.str_name_ko;
            this.str_name_vi = form.content.str_name_vi;            
            this.str_hashtag = form.content.str_hashtag;
            this.bol_use_previous_answers = form.content.bol_use_previous_answers;
            this.cod_zone = form.content.cod_zone;
            this.categories = form.content.categories || [];

            this.form_hyperlinks = form.content.hyperlinks;

            this.arr_links = [];
            this.arr_links_desc = [];
            this.arr_cod_links_del = [];

            this.bol_show_copy = true;
        },
        err => { this._authService.errCheck(err);
            console.log(err);
            this.hasError = err;
            console.error('Failed to load dynamic forms details!', err); }
        );
    }

    closeSuccessCopyMessage() {
        this.bol_show_copy = false;
        this.search(false);
    }

    toggleObjSelection($event, codLogbook) {
        let index;
        if ($event != 0) {
            index = this.selectedRecords.indexOf($event);
        } else {
            index = this.selectedRecords.indexOf(codLogbook);
        }

        if (index == -1) {
            this.selectedRecords.push($event);
        } else {
            this.selectedRecords.splice(index, 1);
        }

    }

    private _showErrorMessage(err) {
        this.translateService.get(err).subscribe(translate => {
            this._notify.error(translate);
        });
    }

    isSelected(codLogbook) {
        return this.selectedRecords.indexOf(codLogbook) >= 0;
    }

    saveCopy() {
        const duplicated = this.getDuplicatedName();

        if (duplicated.length > 0) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.DUPLICATED');
            return;
        }

        if (!this.str_name) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.NAME_IS_REQUIRED');
            return;
        }

        let arr_questions = [];
        this.categories.map(c => arr_questions = [...arr_questions, ...c.questions]);

        if (!arr_questions.length) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.QUESTION_AT_LEAST_ONE');
            return;
        }

        const hasSubArea = _.find(arr_questions, { 'int_type': 9 });
        const hasArea = _.find(arr_questions, { 'int_type': 8 });

        if (hasSubArea != undefined && hasArea == undefined) {
            this.showError = true;
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.QUESTION_EQUIPMENT_AREA');
            return;
        }

        const hasEquipment = _.find(arr_questions, { 'int_type': 10 });

        if (hasEquipment != undefined && hasSubArea == undefined) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.QUESTION_EQUIPMENT_SUBAREA');
            return;
        }

        const hasMachine = _.find(arr_questions, { 'int_type': 17 });

        if (hasMachine != undefined && hasEquipment == undefined) {
            this._showErrorMessage('DYNAMICFORMS.EDIT.VALIDATIONS.QUESTION_MACHINE_EQUIPMENT');
            return;
        }
        
        if (this.form_hyperlinks.length > 0) {
            for (let i = 0; i < this.form_hyperlinks.length; i++) {                                    
                this.arr_links.push(this.form_hyperlinks[i]['str_link']);
                this.arr_links_desc.push(this.form_hyperlinks[i]['str_link_desc']);                
            }
        }
        let regionals = []
        if(this.formObj.content.cod_regionals){
            for(let f of this.formObj.content.cod_regionals){
                regionals.push(f.cod_locations_regional)
            }
        }
        let locations = []
        if(this.formObj.content.cod_locations){
            for(let f of this.formObj.content.cod_locations){
                locations.push(f.cod_location)
            }
        }
        this.service.insert(
            this.str_name, 
            this.str_name_de, 
            this.str_name_es, 
            this.str_name_fr, 
            this.str_name_nl, 
            this.str_name_pt, 
            this.str_name_ru, 
            this.str_name_ua,
            this.str_name_zh,
            this.str_name_ko,
            this.str_name_vi,
            this.str_hashtag,
            this.cod_user, 
            this.categories, 
            this.cod_zone,
            regionals,
            locations,
            this.arr_links, 
            this.arr_links_desc, 
            this.arr_cod_links_del,
            this.bol_use_previous_answers
        ).subscribe(res => {
            this.str_link = '';
            this.str_link_desc = '';
            this.search(true);
            this.bol_show_copy = false;
            this._notify.success({ text: 'DYNAMICFORMS.ADMIN.SUCCESS_COPY_MESSAGE', translate: true });
        }, err => { 
            this._authService.errCheck(err); 
            console.log(err); 
            this._showErrorMessage('DYNAMICFORMS.ADMIN.MSG_ERROR');
        });

    }

    getDuplicatedName() {
        const hasDuplicated = this.formsList.filter(item => {
            return item.str_name === this.str_name;
        });
        return hasDuplicated;
    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.selectedRecords = [];
        this.pageIndex = page;
        this.isLoading = true;
        this.search(false);
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    previousPage() {
        let previous = this.pageIndex - 1;
        this.changePage(previous)
    }

    nextPage() {
        let next = this.pageIndex + 1;
        this.changePage(next)
    }

    reqResult(event) {
        this.emitErrorMessage = event.showErrorMessage ? event.showErrorMessage : false;
        this.emitSuccessMessage = event.showSuccessMessage ? event.showSuccessMessage : false;
        var _translateKey = this.emitErrorMessage ? 'DYNAMICFORMS.ADMIN.MSG_ERROR' : (this.emitSuccessMessage ? 'DYNAMICFORMS.ADMIN.MSG_SUCCESS' : '');
        this.translateService.get(_translateKey)
        .subscribe(translate => {
            
            if (this.emitErrorMessage)
                this._notify.error(translate);
            
            if (this.emitSuccessMessage)
                this._notify.success(translate);
            
            this.errorMessage = translate;

        });
    }

    hideMessage() {
        this.showErrorMessage = false;
        this.showSuccessMessage = false;
    }

}
