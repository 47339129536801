import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from '../../main/access/services/auth.service';
import { LogbooksService } from '../../logbooks/services/logbooks.service';
import { TranslateService } from '@ngx-translate/core';
import { Periodicity } from 'app/logbooks/models/periodicity.model';
import { PeriodicityTypesService } from '../../logbooks/services/periodicity-types.service';
import { Routine } from '../../logbooks/models/routine.model';
import { ValidationsService } from '../../logbooks/services/validations/validations.service';
import { LogbookRoutineService } from '../../logbooks/services/logbook-routine.service';
import { environment } from 'environments/environment';
import {FormsDynamicAdminService} from '../../forms/forms-dynamic/services/forms-dynamic.service';
import {PillarsService} from '../../logbooks/services/pillars.service';
import {UsersService} from '../../admin/services/users.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {RoutinesService} from '../../logbooks/services/routines.service';
import { NotificationService } from "./../../main/services/notification.service";
import { SocketService } from "app/main/services/socket.service";
import { Subscription, timer } from "rxjs";

@Component({
  selector: 'app-custom-routine',
  templateUrl: './app-custom-routine.component.html',
  styleUrls: ['./app-custom-routine.component.css']
})
export class AppCustomRoutineComponent implements OnInit {

  @Output() backEvent = new EventEmitter<Object>();
  @Input('codChecklist') cod_checklist;
  @ViewChild('routineForm') routineFormContainer: ElementRef;
  @ViewChild('logbookFormContainer') logbookFormContainer: ElementRef;

  logbook: any;
  errorMessage: any;
  showError: boolean;
  loading_list: boolean = false;
  periodicityList: any;
  showList: any = true;
  showAddRoutine: boolean;
  newRoutine: any;
  selectedFormName: string;
  taSetupRoutine: { type: string; placeHolder: any; valueProperty: string; searchProperty: string; hasImage: boolean; onSelect: (selectedItem: any) => void; asynchDataCall: (value: string, cb: any) => void; onBlur: (item: any) => void; };
  placeholderTypeAheadRoutine: any;
  _selectedItem: any;
  awaitConfirmDelete: boolean = false;
  deleteRoutineId: number = 0;
  selectedPeriodicity: { cod_periodicity: any; }[];
  selectedForm: { cod_form: any; }[];
  has_custom_checklist: any;
  isNew: boolean = false;
  localUser: any;
  is_saving: boolean = false;
  showNewRoutine: boolean= false;
  is_button_save: boolean = true;
  formList: any[] = [];
  isLoadingWorld: boolean;
  worldList: any[];
  cod_user: any;
  str_link: any;
  str_link_desc: string;
  linkIdx: any;
  link_timeout: any;
  pillarList: any;
  routine: any;
  arr_links: any[];
  arr_links_desc: any[];
  arr_cod_links_del: any[];
  selectedWorlds: any[];
  is_savingNewRoutine: boolean = false;
  is_loadingForm: boolean = false;
  arr_del_routines: any = [];
  arr_copies: any = [];

  user_name: string;
  subscriptionSocket: Subscription;
  userEditingOnSocket: string;
  nextOnQueue: boolean = false;
  copyUpdatedTranslation: string = '';
  positionInSocketQueue: number;
  mainCheckListCod: number = 0;

  constructor(private _logbookService: LogbooksService,
    private _routineService: RoutinesService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _periodicityTypeServive: PeriodicityTypesService,
    public _validationService: ValidationsService,
    private _formService: FormsDynamicAdminService,
    private _pillarsService: PillarsService,
    private _usersService: UsersService,
    private sanitizer: DomSanitizer,
    private _logbookRoutineService: LogbookRoutineService,
    private _notify: NotificationService, 
    private _socketService: SocketService
    ) {

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.logbook.routines, event.previousIndex, event.currentIndex);
    this.is_button_save = false;
    this.orderRoutines();
  }

  orderRoutines() {
    this.logbook.routines = this.logbook.routines.map((val, i) => {
      val.int_order = i + 1;
      return val;
    });
  }

  ngOnInit() {
    this.loading_list = false;
    this.is_loadingForm = true;
    this.cod_user = this._authService.getAuthenticatedUser().id;
    this.user_name = this._authService.getAuthenticatedUser().name;
    
    this.initializeSocket();
    
    this._logbookService.getHasCustomChecklist({ 'cod_user': this.cod_user }).subscribe(data => {
      if (data.code === 888 && data.content.code === 678) {
        this.has_custom_checklist = data.content.results.has_custom_checklist;
        let objParam = { 'cod_logbooks': this.cod_checklist, 'cod_user': this.cod_user };
        this._logbookService.getSingleChecklist(objParam).subscribe(data2 => {
          if (data2.code === 888 && data2.content.code === 678) {
            this.logbook = data2.content.results;
            this._searchCopies();
            if (this.has_custom_checklist === false) {
              this.logbook.str_name = this.logbook.str_name + "_custom_copy"
            }
            this.loading_list = true

            if(this.logbook.cod_logbooks != null){
              this.mainCheckListCod = this.logbook.cod_logbooks;
              //this._socketService.joinChecklistCopies(this.logbook.cod_logbooks, { name: this.user_name, id: this.cod_user });
            }
            else{
              this.mainCheckListCod = this.logbook.cod_logbook;
            }           
    
            if (this._logbookService.hasDuplicatedRoutines(this.logbook.routines, null, null)) {
              this._showErrorMessage('LOGBOOK.HAS_DUPLICATED_ROUTINE_2');
            }
          }
        }, err => {
          let e = JSON.parse(err._body);
          this._showErrorMessage(e.content);
          this._authService.errCheck(err);
        });
      }
      else {
        this._showErrorMessage(data);
        console.log(data);

      }
    },
    err => {
      this._authService.errCheck(err);
      this._showErrorMessage(err.content);
      console.log(err);
    });

    let params = { 'term': '', 'cod_user': this.cod_user };
    this._periodicityTypeServive.getPeriodicitiesList(params).subscribe(data => {
      this.periodicityList = [];
      let qty = data.content.length;
      for (let i = 0; i < qty; i++) {
        this.periodicityList.push(new Periodicity(data.content.results[i].cod_periodicity, data.content.results[i].str_description, (data.content.results[i].showweekcombo > 0), (data.content.results[i].showmonthcombo > 0)));
      }
    },
      err => {
        this._authService.errCheck(err);
        this._showErrorMessage(err.content);
        console.log(err);
      }
    );

    let $this = this;
    this.taSetupRoutine = {
      type: 'dynamic',
      placeHolder: this.placeholderTypeAheadRoutine,
      valueProperty: 'cod_routine',
      searchProperty: 'str_autocomplete_text',
      hasImage: false,
      onSelect: (selectedItem: any) => {
        this.newRoutine.str_name = Object.assign([], selectedItem.str_name).join('');
        this.newRoutine.str_description = Object.assign([], selectedItem.str_description).join('');
        this.newRoutine.cod_routine = selectedItem.cod_routine;
        this.newRoutine.cod_form = selectedItem.cod_form; //changed business rule
        this.newRoutine.int_order = selectedItem.int_order;
        this._selectedItem = selectedItem;
        this._selectedItem.cod_routine = selectedItem.cod_routine;
        this.selectedFormName = this._selectedItem.str_form_name;
      },
      asynchDataCall: (value: string, cb: any) => {
        if (value.length < environment().minCharStartLiveSearch && value.length != 0) {
          return;
        }

        $this.searchActivities(cb, value);
      },
      onBlur: (item: any) => {
        //Validate if selected routine exists
        if (typeof this._selectedItem.cod_routine == typeof undefined || this._selectedItem.cod_routine <= 0) {
          this.newRoutine = new Routine(this.cod_checklist);
        }
      }
    };

    this.loadWorld();
    this.searchPillars();

    this._translateService.get('LOGBOOK.COPY_UPDATED').subscribe((translate) => {
      this.copyUpdatedTranslation = translate;
    });
  }

  searchActivities(cb, term) {
    try {
      let params = { term: term, 'cod_user': this.cod_user, str_privilege: 'ADMIN_CREATE_ROUTINE' };
      //this.routine.str_name = term;
      this._logbookRoutineService.getRoutinesList(params).subscribe((result: any) => {
        let resultsArr;
        if (result.content.length == 1) {
          resultsArr = [result.content.results];
        }
        else {
          resultsArr = result.content.results;
        }

        if (resultsArr.length <= 0) {
          this._selectedItem = {};
        }
        cb(resultsArr);
      },
        err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      );
    }
    catch (e) {
      console.error(e);
    }
  }

  show(reload) {
    this.backEvent.emit(reload);
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  getPeriodicityObj(periodicityId): string {
    if (!this.periodicityList) {
      return '';
    }

    let item = this.periodicityList.filter(function (item) {
      return parseInt(item.cod_periodicity) == periodicityId;
    })[0];

    if (item) {
      return item.str_description;
    }
    else {
      return '';
    }
  }

  customRoutine(act) {
    if (act == 0) {
      this.newRoutine = null;
      this.newRoutine = new Routine(this.cod_checklist);
      this.newRoutine.bol_mandatory = false;
      this.newRoutine.bol_is_custom = true;
      this.newRoutine.int_order = this.logbook.routines.length;
      this.selectedFormName = '';
      this.isNew = true;
      this.selectedPeriodicity = [];
      this.newRoutine.previous_periodicity = 0;
    } else {
      this.isNew = false;
      this.newRoutine = act;
      this.selectedPeriodicity = [{ cod_periodicity: this.newRoutine.cod_periodicity }];
      this.newRoutine.previous_periodicity = this.newRoutine.cod_periodicity;
      this.selectedForm = [{ cod_form: this.newRoutine.cod_form }];
      if (typeof this.newRoutine.str_form_name !== typeof undefined && this.newRoutine.str_form_name) {
        this.selectedFormName = this.newRoutine.str_form_name;
      }
      else {
        this.selectedFormName = '';
      }
    }
    this._selectedItem = Object.assign({}, this.newRoutine);
    this._selectedItem.cod_routines = this._selectedItem.cod_routine;
    this.showList = false;
    this.showNewRoutine = false;
    this.showAddRoutine = true;
  }

  cancelRoutine() {
    if(this.newRoutine.previous_periodicity != 0){
      this.newRoutine.cod_periodicity = this.newRoutine.previous_periodicity;
    }
    this.newRoutine = new Routine(0);
    this.showAddRoutine = false;
    this.showList = true;
  }

  selectPeriodicity($event) {
    this.newRoutine.cod_periodicity = $event.selectedOptions[0].cod_periodicity;
    this.updateElements();
  }

  updateElements() {
    let item = null;
    let periodicityId = this.newRoutine.cod_periodicity;
    if (periodicityId != '0' && this.periodicityList) {
      item = this.periodicityList.filter(function (item) {
        return item.cod_periodicity == periodicityId;
      })[0];
    }
  }

  public saveRoutine() {
    this.newRoutine.cod_profile = null;

    if (this.newRoutine.cod_form == 0) {
      this.newRoutine.cod_form = null;
    }

    if (this._selectedItem.str_name != this.newRoutine.str_name) {
      this.newRoutine.cod_routine = 0;
    }

    if (!this.newRoutine.cod_routine || this.newRoutine.cod_routine <= 0) {
      this._showErrorMessage('LOGBOOK.ROUTINE_SELECTED_INVALID');
      return false;
    }

    if (!this.newRoutine.cod_periodicity) {
      this._showErrorMessage('LOGBOOK.MESSAGE_ERROR_PERIODICITY_REQUIRED');
      return false;
    }
    
    if (this._logbookService.hasDuplicatedRoutines(this.logbook.routines, this.newRoutine, this.isNew)) {
      this._validationService.boolIsLoading = false;
      this._showErrorMessage('LOGBOOK.HAS_DUPLICATED_ROUTINE');
      return false;
    }

    this.is_button_save = false;
    this.newRoutine.str_form_name = this.selectedFormName;
    if (this.logbook.routines.indexOf(this.newRoutine) == -1) {
      this.logbook.routines.push(this.newRoutine);
      this.orderRoutines();
    }
    this.showAddRoutine = false;
    this.showList = true;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  public toggleDeleteRoutine(id = 0) {
    this.awaitConfirmDelete = !this.awaitConfirmDelete;
    this.deleteRoutineId = id;
    this.is_button_save = false;
  }

  public confirmDeleteRoutine() {
    this.arr_del_routines.push(this.logbook.routines[this.deleteRoutineId])
    this.logbook.routines.splice(this.deleteRoutineId, 1);
    this.deleteRoutineId = 0;
    this.orderRoutines();
    this.awaitConfirmDelete = false;
  }

  public saveLogbook() {
    if (!this.logbook.str_name) {
      this._showErrorMessage('LOGBOOK.LOGBOOK_NAME_REQUIRED_MSG');
      this.is_saving = false;
      return false;
    }
    this.logbook.cod_user = this.cod_user;
    this.logbook.cod_profile = null;
    this.logbook.cod_function = null;
    this.logbook.cod_subareas = JSON.parse("[" + this._implodeValues(this.logbook.arr_subareas, 'cod_subarea') + "]");
    this.logbook.cod_update_user = this.cod_user;
    this.logbook.cod_locations = JSON.parse("[" + this._implodeValues(this.logbook.arr_location, 'cod_location') + "]");
    this.logbook.is_custom = true;
    this.logbook.bol_is_collab = false;
    this.logbook.cod_inherit_logbook = this.cod_checklist;

    if (this.has_custom_checklist === false) {
      this.logbook.cod_logbooks = 0;

      const logbook_params = this._logbookService.getLogbookParams(this.logbook);

      this._logbookService.postLogbook(logbook_params).subscribe(res => {
        if (res.type == 'error') {
          this.is_saving = false;
          this._showErrorMessage(res.content.message);
        }
        else {
          let params = {
            "cod_user": this.logbook.cod_user,
            "has_custom_checklist": true
          }
          this._logbookService.setHasCustomChecklist(params).subscribe(data => {
            if (data.code === 888 && data.content.code === 678) {
              this.is_saving = false;
              this.localUser = this._authService.getAuthenticatedUser();
              this.localUser.cod_logbook = data.content.results.cod_logbook;
              this._authService.setAuthenticatedUser(this.localUser);
              this.show(true);

            }
            else {
              this.is_saving = false;
              this._showErrorMessage(data.content.message);
            }
          }, err => {
            this.is_saving = false;
            let e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err);
          });
        }
      }, err => {
        this.is_saving = false;
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
      );
    }
    else {
      this._logbookService.putLogbook(this.logbook).subscribe(res => {
        if (res.type == 'error') {
          this._showErrorMessage(res.content.message);
        }
        else {
          this.show(true);

          if (Array.isArray(this.arr_copies) && this.arr_copies.length > 0) {

            this._logbookService.updateLogbooksCopiedFrom(this.logbook, this.arr_copies, this.arr_del_routines);

            this._notify.success({text: 'LOGBOOK.COPIED', translate: true});

            let params = {
              'cod_logbooks': this.mainCheckListCod,
              'cod_user': this.cod_user,
              'dsc_page_privilege': 'NOT_PRIVILEGE',
              'str_path': environment().uploadGetFilesApiUrl + '/'
            };
            this._logbookService.getUsersByLogbook(params).subscribe(data => {
                let userList = this._logbookService.getArrayContent(data);
                if(Array.isArray(userList) && userList.length > 0){
                    userList.forEach(item => {
                        if(item.cod_id != this.cod_user){
                            this._socketService.joinChecklistCopies(this.mainCheckListCod, { name: item.str_name, id: item.cod_id });
                        }
                    });
                }
            }, err => {
                console.log(err);
            });

            this.arr_copies.forEach(element => {
              let params = {
                  'cod_logbooks': element.cod_logbooks,
                  'cod_user': this.cod_user,
                  'dsc_page_privilege': 'NOT_PRIVILEGE',
                  'str_path': environment().uploadGetFilesApiUrl + '/'
              };
              this._logbookService.getUsersByLogbook(params).subscribe(data => {
                  let userList = this._logbookService.getArrayContent(data);
                  if(Array.isArray(userList) && userList.length > 0){
                      userList.forEach(item => {
                          if(item.cod_id != this.cod_user){
                              this._socketService.joinChecklistCopies(element.cod_logbooks, { name: item.str_name, id: item.cod_id });
                          }
                      });
                  }
              }, err => {
                  console.log(err);
              });
            });
          }

          this.arr_del_routines = [];
        }
      }, err => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
      );
    }
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  loadForms() {
    let worlds = this.selectedWorlds.length > 0 
      ? this.selectedWorlds.map(i => i.cod_locations_world) 
      : [this._authService.getAuthenticatedUser().cod_world];

    this._formService.list('', this.cod_user, 'ADMIN_CREATE_ROUTINE', worlds).subscribe(data => {
            this.formList = this._formService.getArrayContent(data);
            this.is_loadingForm = false;
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        }
    );
  }
  
  selectedFormChanged($event, i) {
    this.routine.forms = $event.selectedOptions;
  }

  loadWorld() {
    this.isLoadingWorld = true;
    this.worldList = [];
    this._usersService.getWorlds( this.cod_user, 'ADMIN_CREATE_ROUTINE').subscribe(data => {
            this.isLoadingWorld = false;
            this.worldList = this._usersService.getArrayContent(data);
        },
        err => {
            this.isLoadingWorld = false;
            this._authService.errCheck(err);
            console.log(err);
        }
    );
  }

  selectPillars($event) {
    this.routine.pillars = $event.selectedOptions;
  }

  searchPillars() {
    const objParam = {
        'cod_pillars': 0,
        'term': '',
        'cod_user': this.cod_user
    };
    this._pillarsService.getPillarList(objParam).subscribe(data => {
            this.pillarList = this._pillarsService.getArrayContent(data);
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        }
    );
  }

  addNewRoutine(){
    this.showList = false;
    this.showAddRoutine = false;
    this.showNewRoutine = true;
    this.routine = new Routine(this.cod_checklist);
    this.arr_links = [];
    this.selectedPeriodicity = [];
    this.arr_links_desc = [];
    this.arr_cod_links_del = [];
    this.selectedWorlds = [];
    this.selectedWorlds.push({cod_locations_world: this._authService.getAuthenticatedUser().cod_world});
    this.routine.bol_is_custom = true;
    this.routine.bol_mandatory= false;
    this.routine.int_order = this.logbook.routines.length;
    this.selectedFormName = '';
    this.loadForms();
  }

  cancelNewRoutine(){
    this.routine = new Routine(0);
    this.arr_links = [];
    this.arr_links_desc = [];
    this.arr_cod_links_del = [];
    this.showAddRoutine = false;
    this.showNewRoutine = false;
    this.showList = true;
  }

  addLink() {
    if (!!this.str_link) {
        const pattern = /^https?:\/\/.+/; // check if starts with https://
        const pattern2 = /^file:\/\/\/?.+/; // check if starts with file:///
        const pattern3 = /^\\\\.+/; // check if starts with \\
        const pattern4 = /^[a-zA-Z]:(\/|\\).+/; // check if starts with C:/
        this.str_link = this.str_link.replace(/^[^\x00-\x7F]/gmu, "");            
        // console.log(pattern.test(this.str_link), pattern2.test(this.str_link));

        if (!pattern.test(this.str_link)) { // if doesn't start with https://

            if (!pattern2.test(this.str_link)) { // if doesn't start with file:///
                if (pattern3.test(this.str_link) || pattern4.test(this.str_link)) { // if starts with either \\ or C:/
                    this.str_link = 'file:///' + this.str_link; // then is a file:/// link
                } else { // else is a normal https:// link
                    this.str_link = 'https://' + this.str_link;
                }

            }// if already starts with file:/// do nothing
        }// if already starts with https:// do nothing

        this.routine.hyperlinks.push({
            cod_routines_hyperlinks: null,
            str_link: this.str_link,
            str_link_desc: this.str_link_desc
        });
    }
    this.str_link = '';
    this.str_link_desc = '';

    this.routine.hyperlinks.map(it => {
        it['str_click_link'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it['str_link']));
    });
  }

  removeLink(index) {
      if (this.routine.hyperlinks[index]['cod_routines_hyperlinks'] != null) {
          this.arr_cod_links_del.push(this.routine.hyperlinks[index]['cod_routines_hyperlinks']);
      }
      this.routine.hyperlinks.splice(index, 1);
  }

  copyLink(str_hyperlink, i, copy = false) {
      const pattern = /^https?:\/\/.+/;

      if (!pattern.test(str_hyperlink) || copy === true) {
          this.copyToClipboard(str_hyperlink);
          this.linkIdx = i;
          clearTimeout(this.link_timeout);
          this.link_timeout = setTimeout(() => {
              this.linkIdx = -1;
          }, 3000);
      }
  }

  copyToClipboard(val: string) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }

  downloadExtension(file) {
    const extension = file.split('.').pop();
    let newFile = file;

    // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
    switch (extension) {
      case 'xls':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'xlsx':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'xlsm':
        newFile = `ms-excel:ofe|u|${file}`;
        break;         
      case 'csv':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'doc':
        newFile = `ms-word:ofe|u|${file}`;
        break;
      case 'docx':
        newFile = `ms-word:ofe|u|${file}`;
        break;
      case 'ppt':
        newFile = `ms-powerpoint:ofe|u|${file}`;
        break;
      case 'pptx':
        newFile = `ms-powerpoint:ofe|u|${file}`;
        break;
      default:
        break;
    }
    newFile = newFile.replace(/\\/g, '/');
    return newFile;
  }

  getAcadiaDocument() {
    return this.routine.str_acadia_document ? this.routine.str_acadia_document.trim() : null;
  }

  saveNewRoutine(){
    const str_acadia_document: string = this.getAcadiaDocument();

    this.addLink();
    this.is_savingNewRoutine = true;

    if (!this.routine.str_name) {
        this.is_savingNewRoutine = false;
        this._showErrorMessage('LOGBOOK.REQUIRED_STR_NAME');
        return false;
    }

    if (!this.routine.str_description) {
        this.is_savingNewRoutine= false;
        this._showErrorMessage('LOGBOOK.REQUIRED_STR_DESCRIPTION');
        return false;
    }

    if (!(this.routine.cod_periodicity > 0)) {
      this.is_savingNewRoutine= false;
      this._showErrorMessage('LOGBOOK.MESSAGE_ERROR_PERIODICITY_REQUIRED');
      return false;
    }

    if (this.selectedWorlds[0] == undefined || !this.selectedWorlds[0].cod_locations_world) {
        this.is_savingNewRoutine= false;
        this._showErrorMessage('LOGBOOK.REQUIRED_WOLRD');
        return false;
    }

    if (str_acadia_document && this.routine.forms.length > 0) {
      this.is_savingNewRoutine= false;
      this._showErrorMessage('LOGBOOK.MESSAGE_ROUTINE_FORM_ACADIA');
      return false;
    }

    this.is_button_save = false;
    let params: any;
    if (this.routine.hyperlinks.length > 0) {
        for (let i = 0; i < this.routine.hyperlinks.length; i++) {
            if (this.routine.hyperlinks[i]['cod_routines_hyperlinks'] == null) {
                // console.log(1, this.routine.hyperlinks[i]);
                this.arr_links.push(this.routine.hyperlinks[i]['str_link']);
                this.arr_links_desc.push(this.routine.hyperlinks[i]['str_link_desc']);
            }
        }
    }
    params = {
        'str_name': this.routine.str_name,
        'str_description': this.routine.str_description,
        'pillars': this.routine.pillars,
        'cod_user': this.cod_user,
        'forms': this.routine.forms,
        'arr_links': this.arr_links,
        'arr_links_desc': this.arr_links_desc,
        'arr_cod_links_del': this.arr_cod_links_del,
        'cod_world': this.selectedWorlds[0].cod_locations_world,
        'str_acadia_document': str_acadia_document,
        'bol_sop_reaction_plan_training_document': this.routine.bol_sop_reaction_plan_training_document
    };
    this._routineService.postRoutine(params).subscribe(res => {
          if (res.type !== 'success') {
              this._showErrorMessage(res.content.message);
              alert(res.content.message);
          } else {
              this.is_savingNewRoutine = false;
              this.routine.cod_form = this._implodeValues(this.routine.forms, 'cod_form');
              this.routine.str_form_name = this._implodeValues(this.routine.forms, 'str_name');
              this.routine.cod_routine = res.content.results.logbooks_sp_in_routine;
              if (this.logbook.routines.indexOf(this.routine) == -1) {
                this.logbook.routines.push(this.routine);
                this.orderRoutines();
                this.cancelNewRoutine();
                this.is_button_save = false;
              }
          }
        }, err => {
          this.is_savingNewRoutine= true;
          const e = JSON.parse(err._body);
          alert(e.content);
          this._authService.errCheck(err);
        }
    );
  }

  selectPeriodicityNewRoutine($event) {
    this.routine.cod_periodicity = $event.selectedOptions.length > 0 ? $event.selectedOptions[0].cod_periodicity: 0;
  }

  validateAcadiaDocument($event: any):void {
    let fieldValue:string = $event.target.value;

    if (fieldValue !== undefined){
        const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẝźḧ·/_,:;';
        const p = new RegExp(a.split('').join('|'), 'g');

        fieldValue = fieldValue.trim()
            .replace(p, '')// Replace special chars
            .replace(/&/g, '');// Replace & with 'and'
            //.replace(/[\s\W-]+/g, '');// Replace spaces, non-word characters and dashes with

        this.routine.str_acadia_document = fieldValue;
        $event.target.value = fieldValue;

        if (!this.getAcadiaDocument())
          this.routine.bol_sop_reaction_plan_training_document = false;
    }
  }

  private _searchCopies(){
    this._logbookService.getCopiedCheckLists({'cod_logbooks': this.logbook.cod_logbooks}).subscribe(data => {
        this.arr_copies = (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    });
  }

  initializeSocket() {
    this.subscriptionSocket = this._socketService.subscribeChecklistCopies().subscribe((socket_msg: any) => {
        let updatedNotified = false;        
        if(Array.isArray(socket_msg) && socket_msg.length > 0){
            for(let i = 0; i < socket_msg.length && !updatedNotified; i++){
                const msg = socket_msg[i];
                console.log('version 1');
                if(msg.cod_user != this.cod_user && msg.cod_logbooks == this.mainCheckListCod) {
                    this._notify.info(this.copyUpdatedTranslation, "", { timeOut: 10000 });
                    console.log('item: ' + i);
                    updatedNotified = true;                        
                }
            }
        }
    });
  }
}
