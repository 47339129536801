// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.extra-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.pull-left {
    float: left;
    position: relative;
    margin-right: -9px;
    margin-left: -15px;
}

.pull-right{
    float:right;
    position: absolute;
}

.custom-margin{
    margin-bottom: 10px;
}

.status-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.relay-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.relay-list label{
    margin: 0 15px 0 0;
    font-size: 1.5rem;
}

.relay-item {
    margin: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/relay-persist/relay-persist.component.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\n.extra-padding {\n    padding-left: 20px;\n    padding-right: 20px;\n}\n\n.pull-left {\n    float: left;\n    position: relative;\n    margin-right: -9px;\n    margin-left: -15px;\n}\n\n.pull-right{\n    float:right;\n    position: absolute;\n}\n\n.custom-margin{\n    margin-bottom: 10px;\n}\n\n.status-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.relay-list {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 15px;\n}\n\n.relay-list label{\n    margin: 0 15px 0 0;\n    font-size: 1.5rem;\n}\n\n.relay-item {\n    margin: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
