// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-topics {
    display: flex;
    flex-direction: column;
}
.help-topics li {
    color: var(--text-primary);
    padding: 20px 0;
}

.help-topics li.active {
    border-left: 5px solid var(--ui-primary);
    background-color: var(--bg-primary-highlight);
}

.help-topics li:hover {
    cursor: pointer;
    background-color: var(--bg-primary-highlight);
}
.help-topics li div {
    margin-left: 15px;    
}`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-topics/help-topics.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,wCAAwC;IACxC,6CAA6C;AACjD;;AAEA;IACI,eAAe;IACf,6CAA6C;AACjD;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".help-topics {\n    display: flex;\n    flex-direction: column;\n}\n.help-topics li {\n    color: var(--text-primary);\n    padding: 20px 0;\n}\n\n.help-topics li.active {\n    border-left: 5px solid var(--ui-primary);\n    background-color: var(--bg-primary-highlight);\n}\n\n.help-topics li:hover {\n    cursor: pointer;\n    background-color: var(--bg-primary-highlight);\n}\n.help-topics li div {\n    margin-left: 15px;    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
