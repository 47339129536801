import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImportExcelService} from './services/import-excel.service';
import {UploadService} from '../../main/services/upload.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'app-import-excel',
    templateUrl: './import-excel.component.html',
    styleUrls: ['./import-excel.component.css']
})

export class ImportExcelComponent {
    @Input() proc: string;
    @Input() fields = [];
    @Input() codUser: number;
    @Input() additionalParams: any = [];
    @Input() confirmMessage: string;
    @Output() importedFile = new EventEmitter<any>();
    @Output() onError = new EventEmitter<any>();
    @Output() onBeforeStart = new EventEmitter<any>();

    //Label when file is selected
    @Input('label_file_selected') labelFileNameSelected: string;
    //Label when file is empty
    @Input('label_empty_file') labelEmptyFileName: string;
    //If showLabels is true then the labels of the selected file will be displayed, and the button to confirm the upload of the file.
    // If showLabels is false, then only the button to select the file is displayed, and when this occurs, the upload will be confirmed directly.
    @Input('show_labels') showLabels: boolean;

    filesToUpload: Array<File> = [];
    componentId: string = '';
    fileNameSelected: string = '';
    awaitConfirmUpload: boolean = false;
    eventFileUpload;
    error_message_upload: string;
    bol_error_upload: boolean = false;

    constructor(public importExcelService: ImportExcelService, private _uploadService: UploadService, private _authService: AuthService) {
        this.filesToUpload = [];
        this.componentId = 'importfile_' + Date.now();
        this.labelFileNameSelected = 'GENERAL.FILE_SELECTED';
        this.labelEmptyFileName = 'GENERAL.UPLOAD_FILE';
        this.showLabels = true;
    }

    _confirmUpload() {
        if (this.confirmMessage) {
            // asks for confirmation first
            if (!this.awaitConfirmUpload) {
                this.awaitConfirmUpload = true;
                return;
            }

            // upload confirmed
            this.uploadFile();
            this.awaitConfirmUpload = false;
        }
        else {
            this.uploadFile();
        }
    }

    cancelUpload() {
        this.awaitConfirmUpload = false;
        if (!this.showLabels) {
            this.eventFileUpload.srcElement.value = '';
            // this.eventFileUpload = {};
            this.fileNameSelected = '';
        }
    }

    uploadFile() {
        this.bol_error_upload = false;

        let params = {
            'event': this.eventFileUpload,
            'allowedExtensions': ['text/csv', 'csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        };

        if (this.onBeforeStart) {
            this.onBeforeStart.emit();
        }

        this._uploadService.uploadFileBase64(params).subscribe(response => {
                let fileName = response.content.str_name;
                if (response.type == 'success') {
                    const data = {
                        file: fileName,
                        proc: this.proc,
                        fields: this.fields,
                        cod_user: this.codUser,
                        cod_user_auth: this._authService.getAuthenticatedUser().id,
                        additionalParams: this.additionalParams
                    };
                    this.importExcelService.importExcel(data).subscribe(response2 => {
                        
                        if (response2.type == 'success') {
                            if (this.importedFile) {
                                if (!this.showLabels) {
                                    this.eventFileUpload.srcElement.value = '';
                                    this.fileNameSelected = '';
                                }

                                this.importedFile.emit(response2.content?.results?.excel_sp_in_import_farol);
                            }
                        }else{
                            this.eventFileUpload.srcElement.value = '';
                            this.fileNameSelected = '';
                            if (this.onError) {
                                this.onError.emit(response2.content);
                            }
                            else {
                                this.bol_error_upload = true;
                                this.error_message_upload = response2.content;
                            }
                        }

                        //Delete de file just uploaded
                        let params = {
                            'file_name': fileName
                        };
                        this._uploadService.deleteFile(params).subscribe(response3 => {
                            //doensnt matter error on deleting
                        },err => {
                            console.log(err);
                            this._authService.errCheck(err);
                        });

                    },err => {
                        console.log(err);
                        let e = JSON.parse(err._body);
                        this.eventFileUpload.srcElement.value = '';
                        this.fileNameSelected = '';

                        if (this.onError) {
                            this.onError.emit(e);
                        }
                        else {
                            this.bol_error_upload = true;
                            this.error_message_upload = e.content;
                        }
                        this._authService.errCheck(err);
                    });
                } else {
                    if (this.onError) {
                        this.onError.emit(response.content);
                    }
                    else {
                        this.bol_error_upload = true;
                        this.error_message_upload = response.content;
                    }
                }

            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
                let e = JSON.parse(err._body);
                if (this.onError) {
                    this.onError.emit(e);
                }
                else {
                    this.bol_error_upload = true;
                    this.error_message_upload = e.content;
                }
            }
        );
    }

    fileChangeEvent(fileInput: any) {
        if (typeof fileInput.target.files[0] != typeof undefined) {
            this.eventFileUpload = fileInput;
            this.fileNameSelected = fileInput.target.files[0].name;
            if (!this.showLabels) {
                this._confirmUpload();
            }
        }
    }

    openFileBox() {
        document.getElementById(this.componentId).click();
    }

    closeErrorMessage() {
        this.bol_error_upload = false;
        this.error_message_upload = '';
    }
}
