// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-nav-pills{
    margin-bottom: 10px;
    margin-top: 10px;
}

.active{
    background-color: var(--ui-primary);
}

`, "",{"version":3,"sources":["webpack://./src/app/admin/users-profiles/users-profiles.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".mini-nav-pills{\n    margin-bottom: 10px;\n    margin-top: 10px;\n}\n\n.active{\n    background-color: var(--ui-primary);\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
