// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop{
  z-index: 1000000;
}

.body-layout {
    display: grid;
    gap: 1em;
}

.body-layout .number-wheel {
  width: 300px;
  margin: auto;
}

.modal-title,
.question {
    font-size: 16px;
}

.modal-title {
  font-weight: bolder;
}

.question {
    text-align: center;
}

.button-selected {
  background-color: #4670e4;
  color: white;
  border: 1px solid #4670e4;
}

.white-button:hover {
  border: 1px solid #4670e4;
}

.white-button {
  font-size: 13px !important;
}

.btn-nps {
  background-color: #f8f6f2;
}

.btn-safe {
  border: 1px solid var(--ui-ok);
  background-color: var(--ui-ok);
  color: var(--text-ok);
}

.btn-safe:disabled{
  border: 1px solid #7692e2!important;
  background: #7692e2!important;
  cursor: not-allowed!important;
}

.category-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.category-layout button {
  margin-bottom: 5px;
  margin-right: 5px;
}

.modal-dialog {
	width: 500px;
  overflow-y: scroll;
  padding: 0;
  max-height: 80%;
}

.modal-content {
  border-radius: 10px;
}

.modal-header .close{
	margin: -15px -5px;
}`, "",{"version":3,"sources":["webpack://./src/app/nps/nps-modal/nps-modal.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;;IAEI,eAAe;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;EACnC,6BAA6B;EAC7B,6BAA6B;AAC/B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;CACC,YAAY;EACX,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".backdrop{\n  z-index: 1000000;\n}\n\n.body-layout {\n    display: grid;\n    gap: 1em;\n}\n\n.body-layout .number-wheel {\n  width: 300px;\n  margin: auto;\n}\n\n.modal-title,\n.question {\n    font-size: 16px;\n}\n\n.modal-title {\n  font-weight: bolder;\n}\n\n.question {\n    text-align: center;\n}\n\n.button-selected {\n  background-color: #4670e4;\n  color: white;\n  border: 1px solid #4670e4;\n}\n\n.white-button:hover {\n  border: 1px solid #4670e4;\n}\n\n.white-button {\n  font-size: 13px !important;\n}\n\n.btn-nps {\n  background-color: #f8f6f2;\n}\n\n.btn-safe {\n  border: 1px solid var(--ui-ok);\n  background-color: var(--ui-ok);\n  color: var(--text-ok);\n}\n\n.btn-safe:disabled{\n  border: 1px solid #7692e2!important;\n  background: #7692e2!important;\n  cursor: not-allowed!important;\n}\n\n.category-layout {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n.category-layout button {\n  margin-bottom: 5px;\n  margin-right: 5px;\n}\n\n.modal-dialog {\n\twidth: 500px;\n  overflow-y: scroll;\n  padding: 0;\n  max-height: 80%;\n}\n\n.modal-content {\n  border-radius: 10px;\n}\n\n.modal-header .close{\n\tmargin: -15px -5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
