// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.days {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.persist-root {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}


.buttons {
  display: flex;
  gap: 1rem;
}

.description {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 0; 
  max-width: 750px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/shift/shift-list/shift-persist/shift-persist.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;;AAGA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["section.days {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n\n.persist-root {\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  gap: 4rem;\n}\n\n\n.buttons {\n  display: flex;\n  gap: 1rem;\n}\n\n.description {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 1rem 0; \n  max-width: 750px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
