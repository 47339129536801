// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-container {
    background-color: #fff;
    margin-top: 5px;
    margin-bottom: 5px;
  }

.no-padding-sides {
    padding-left: 15px;
    padding-right: 0;
  }

  
.space-right {
    padding-right: 10px;
  }

.textarea-5why {
  min-height: 120px;
  }
  
.action-button {
  min-width: 130px;
}
.add-action { 
  line-height: normal;
  padding: 8px;
}

.align-button {
  padding-left: 15px; 
  padding-top: 20px;
}

.action-column { 
  padding-right: 2.2vw;
}

.hashtag-item {
  padding: 10px 20px;
}

.display-hashtag-opt{
  display: block;
  left: 15px;
  right: 15px;
  top: calc(100% - 5px);
  cursor: pointer;
}

.text-align{
  text-align: end;
  padding-right: 2.2vw
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/fivewhy-actions/fivewhy-actions.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;;AAGF;IACI,mBAAmB;EACrB;;AAEF;EACE,iBAAiB;EACjB;;AAEF;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf;AACF","sourcesContent":[".background-container {\n    background-color: #fff;\n    margin-top: 5px;\n    margin-bottom: 5px;\n  }\n\n.no-padding-sides {\n    padding-left: 15px;\n    padding-right: 0;\n  }\n\n  \n.space-right {\n    padding-right: 10px;\n  }\n\n.textarea-5why {\n  min-height: 120px;\n  }\n  \n.action-button {\n  min-width: 130px;\n}\n.add-action { \n  line-height: normal;\n  padding: 8px;\n}\n\n.align-button {\n  padding-left: 15px; \n  padding-top: 20px;\n}\n\n.action-column { \n  padding-right: 2.2vw;\n}\n\n.hashtag-item {\n  padding: 10px 20px;\n}\n\n.display-hashtag-opt{\n  display: block;\n  left: 15px;\n  right: 15px;\n  top: calc(100% - 5px);\n  cursor: pointer;\n}\n\n.text-align{\n  text-align: end;\n  padding-right: 2.2vw\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
