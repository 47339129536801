import * as moment from "moment";

export function getTimeBetweenDate (date1: Date, date2: Date, unit: string = 'h') {
    const startDate = moment(date1);
    const endDate = moment(date2);
    
    const duration = moment.duration(startDate.diff(endDate));
    switch(unit) {
        case 'h': 
            return Math.floor(duration.asHours());
        case 'm':
            return Math.floor(duration.asMinutes());
        case 's':
            return Math.floor(duration.asSeconds());
        default:
            return Math.floor(duration.asHours());
    }
}