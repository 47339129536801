// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-list {
    display: flex;
    flex-direction: column;
}
.help-list li {
    color: #666666;
    display: flex;
    border-left: 5px solid #e1e1e1;
    border-bottom: none;
    align-items: center;
}

.help-list li:hover {
    cursor: pointer;
    background-color: var(--bg-primary-highlight);
}

.help-list li.active {
    border-left: 5px solid var(--ui-primary);
    background-color: var(--bg-primary-highlight);
}

.help-list li img{
    width: 15px;
    height: 15px;
    margin: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-list/help-list.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,6CAA6C;AACjD;;AAEA;IACI,wCAAwC;IACxC,6CAA6C;AACjD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".help-list {\n    display: flex;\n    flex-direction: column;\n}\n.help-list li {\n    color: #666666;\n    display: flex;\n    border-left: 5px solid #e1e1e1;\n    border-bottom: none;\n    align-items: center;\n}\n\n.help-list li:hover {\n    cursor: pointer;\n    background-color: var(--bg-primary-highlight);\n}\n\n.help-list li.active {\n    border-left: 5px solid var(--ui-primary);\n    background-color: var(--bg-primary-highlight);\n}\n\n.help-list li img{\n    width: 15px;\n    height: 15px;\n    margin: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
