import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ResponseModel } from '../models/response.mode';
import { SubareaPlain } from '../models/sub_area-plain.model';
import {MainService} from './main.service';

@Injectable()
export class SubareaService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all sub-areas.
     * @param param: JSON object filled with search "term" or "id"
     */
    getList(param): Observable<any> {
        let path = '/sub-areas?term=' + param.term+ '&cod_user=' + param.cod_user_auth;
        if (param.cod_subareas > 0) {
            path = '/sub-area/' + param.cod_subareas + '/' + param.cod_user_auth;
        }

        return this._mainService.get(path);
    }

    getListSubAreasByPagination(params): Observable<ResponseModel<SubareaPlain[]>> {
        let path = '/get-list-subarea-pagination'
        return this._mainService.post<ResponseModel<SubareaPlain[]>>(path, params);
    }

    getEquipmentsList(param): Observable<any> {
        let path = '/equipments-by-sub-areas/' + param.cod_subareas + '/' + param.cod_locations;
        return this._mainService.get(path);
    }

    getEquipmentsByLocationList(param): Observable<any> {
        let path = '/equipments-by-locations/' + param.cod_locations + '/' + param.cod_user ;
        return this._mainService.get(path);
    }

    post(params): Observable<any> {
        let path = '/sub-area';
        return this._mainService.post(path, params);
    }

    put(params): Observable<any> {
        let path = '/sub-area';
        return this._mainService.put(path, params);
    }

    delete(params): Observable<any> {
        let path = '/sub-area/' + params.cod_subareas + '/' + params.cod_user;
        return this._mainService.delete(path);
    }

    searchListSubareas(params): Observable<any>{
        let path = "/structure-list-subarea-location";
        return this._mainService.post(path,params);
    }
    deleteSubarea(params): Observable<any>{
        let path = "/structure-de-subarea";
        return this._mainService.post(path, params);
    }

    insertUpdateSubarea(params) : Observable<any>{
        let path = "/structure-in-up-subarea";
        return this._mainService.post(path, params);
    }

    insertUpdateSubareaLocation(params) : Observable<any>{
        let path = "/structure-in-up-subarea-location";
        return this._mainService.post(path, params);
    }

    deleteSubareaLocation(params) : Observable<any>{
        let path = "/structure-de-subarea-location";
        return this._mainService.post(path, params);
    }

    searchSingleSubarea(params) : Observable<any>{
        let path = "/structure-search-subarea-location";
        return this._mainService.post(path, params);
    }
      
    searchSubareaByAreaLoction(params) : Observable<any>{
        let path = "/structure-search-subarea-by-area-location";
        return this._mainService.post(path, params);
    }

    searchSubareaFilter(params) : Observable<any>{
        let path = "/search-subarea-filter";
        return this._mainService.post(path, params);
    }
      
    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }
}
