import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

export class MainService {
    private readonly _api_url: string;

    // Set headers to handle the CORS
    private headers: HttpHeaders = new HttpHeaders(
        {
            'Access-Control-Allow-Origin': environment().frontend_url,
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
            'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
            'Access-Control-Allow-Credentials': 'true',
            'X-Content-Type-Options': 'nosniff',            
            'X-Frame-Options': 'SAMEORIGIN',
            'Referrer-Policy': 'origin-when-cross-origin',
            'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
        }
    );

    constructor(api_path: string, private readonly _http: HttpClient) {
        this._api_url = environment().authApiUrl + api_path;
    }

    private getApiUrl() {
        return this._api_url;
    }

    private _getHeaders(method: string) {
        this.headers = this.headers.set('Access-Control-Allow-Methods', method.toUpperCase());
        return this.headers;
    }

    /**
     * GET request
     * @param path: Path of endpoint
     */
    protected get(path): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('GET')};
        return this._http.get(url, opt);        
    }

    /**
     * GET request
     * @param path: Path of endpoint
     */
    protected get_nocache(path): Observable<any> {

        let nocache_headers: HttpHeaders = new HttpHeaders(
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Cache-Control, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Cache-Control': 'no-cache',                
                'X-Frame-Options': 'SAMEORIGIN',
                'X-Content-Type-Options': 'nosniff',
                'Referrer-Policy': 'origin-when-cross-origin',
                'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
            }
        );

        const url = this.getApiUrl() + path;
        const opt = {headers: nocache_headers};
        return this._http.get(url, opt);
    }    

    /**
     * POST request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    protected post(path: string, params: Object): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('POST')};
        return this._http.post(url, params, opt);
    }

    /**
     * PUT request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    protected put(path: string, params: Object): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('PUT')};
        return this._http.put(url, params, opt);
    }

    /**
     * DEL request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    protected delete(path: string): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('DELETE')};
        return this._http.delete(url, opt);
    }
}
