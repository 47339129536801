import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-logbooks-dashboard',
    templateUrl: './logbooks-dashboard.component.html',
    styleUrls: ['./logbooks-dashboard.component.css']
})
export class LogbooksDashboardComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {
    }

}
