import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { map } from "rxjs/operators";
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';

type QueryParams = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};

@Injectable()
export class MainService {
  private readonly _api_url;
  private readonly _problem_solving_api_url;
  private readonly _report_api_url;
  private _meeting_id;

  // Set headers to handle the CORS
  private _headers: HttpHeaders ;
  private _headers_pdf: HttpHeaders ;

  constructor(private readonly _http: HttpClient, private readonly _authService:AuthService, private readonly _userPreferenceService: UserPreferencesService){
    this._api_url=environment().meetingsApiUrl;//"http://localhost:1080";
    this._report_api_url=environment().reportsApiUrl;
    this._problem_solving_api_url=environment().problemSolvingApiUrl;

    this._headers = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
        'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
        'Access-Control-Allow-Credentials': 'true',
        'userlang': this._userPreferenceService.getLocalLanguage(),
        'X-Content-Type-Options': 'nosniff',        
        'X-Frame-Options': 'SAMEORIGIN',
        'Referrer-Policy': 'origin-when-cross-origin',
        'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
    
      }
    );

  }

  private _getHeaders(method: string) {
    this._headers = this._headers.set('Access-Control-Allow-Methods', method.toUpperCase());
    this._headers = this._headers.set('userlang', this._userPreferenceService.getLocalLanguage());
    return this._headers;
  }

  getApiUrl(){
    return this._api_url;
  }

  getProblemSolvingApiUrl(){
    return this._problem_solving_api_url;
  }

  getReportApiUrl(){
    return this._report_api_url;
  }

  getMeetingObj(){
    return this._meeting_id;
  }

  setMeetingId(id){
    this._meeting_id=id;
  }

  /**
  * GET request
  * @param path: Path of endpoint
  */
  get(path: string, params?: QueryParams): Observable<any> {
    let url = this.getApiUrl() + path;
    let opt = {
      headers: this._getHeaders('GET'), 
      params: params || undefined
    };
    const result = this._http.get(url, opt);
    result.pipe(
      map((res: Response) => {
        if(res.status==401){
            this._authService.deleteAuthenticatedUser();
            window.location.replace('');
        }
      })
    );
    return result;
  }

  /**
  * GET request
  * @param path: Path of endpoint
  */
  getProblemSolving(path: string, params?: QueryParams): Observable<any> {
    let url = this.getProblemSolvingApiUrl() + path;
    let opt = {
      headers: this._getHeaders('GET'),
      params: params || undefined
    };
    const result = this._http.get(url, opt);
    result.pipe(
      map((res: Response) => {
        if(res.status==401){
            this._authService.deleteAuthenticatedUser();
            window.location.replace('');
        }
      })
    );
    return result;
  }

  /**
  * POST request
  * @param path: Path of endpoint
  * @param params: JSON object relative to endpoint
  */
  post(path: string, params: Object): Observable<any> {
    let url = this.getApiUrl() + path;
    let opt = {headers: this._getHeaders('POST')};
    return this._http.post(url, params, opt);
  }

  /**
  * POST request
  * @param path: Path of endpoint
  * @param params: JSON object relative to endpoint
  */
  postReport(path: string, params: Object): Observable<any> {
    let url = this.getReportApiUrl() + path;
    let opt = {headers: this._getHeaders('POST')};
    return this._http.post(url, params, opt);
  }

  /**
  * PUT request
  * @param path: Path of endpoint
  * @param params: JSON object relative to endpoint
  */
  put(path: string, params: Object): Observable<any> {
    let url = this.getApiUrl() + path;
    let opt = {headers: this._getHeaders('PUT')};
    return this._http.put(url, params, opt);
  }

  /**
  * DELETE request
  * @param path: Path of endpoint
  */
  delete(path: string): Observable<any> {
    let url = this.getApiUrl() + path;
    let opt = {headers: this._getHeaders('DELETE')};
    return this._http.delete(url, opt);
  }

  static getArrayContent(data: any) {
    if (!data || typeof data == typeof undefined) {
      return [];
    }
    return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }

  //Get used to download Files
  getDownloadPDF(path): Observable<any>{
    //CUSTOM HEADER FOR FILE
    this._headers_pdf = new HttpHeaders(
      {
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Headers': "Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept",
        'Access-Control-Allow-Credentials': 'true',
        'Content-Type' : 'application/pdf',
        'userlang': this._userPreferenceService.getLocalLanguage(),
        'X-Content-Type-Options': 'nosniff',        
        'X-Frame-Options': 'SAMEORIGIN',
        'Referrer-Policy': 'origin-when-cross-origin',
        'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
    
      }
    );


    let url = path;
    return this._http
        .get(url, {
            headers: this._headers_pdf,
            responseType: 'blob',
        })
        .pipe(
            map((res: any) => {
                if (res.status == 401) {
                    this._authService.deleteAuthenticatedUser();
                    window.location.replace('');
                }
                return res;
            })
        );
  }


};
