// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-help {
    color: var(--text-primary) !important;
}

.header-label {
    color:#666666;
    margin-top: 10px;
}

.search-result li {
    color: var(--text-primary);
    padding: 20px 0;
}

.search-result li:hover {
    cursor: pointer;
    background-color: var(--bg-primary-highlight);
}

.margin-top{
    margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/help/help-content/help-content.component.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,6CAA6C;AACjD;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".header-help {\n    color: var(--text-primary) !important;\n}\n\n.header-label {\n    color:#666666;\n    margin-top: 10px;\n}\n\n.search-result li {\n    color: var(--text-primary);\n    padding: 20px 0;\n}\n\n.search-result li:hover {\n    cursor: pointer;\n    background-color: var(--bg-primary-highlight);\n}\n\n.margin-top{\n    margin-top: 3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
