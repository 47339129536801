import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {LogbooksService} from '../../logbooks/services/logbooks.service';

@Component({
  selector: 'chart-checklist-collaborative',
  templateUrl: './chart-checklist-collaborative.component.html',
  styleUrls: ['./chart-checklist-collaborative.component.css']
})
export class ChartChecklistCollaborativeComponent implements OnChanges{
  @Input() date;
  @Input() cod_user = this._authService.getAuthenticatedUser().id;
  @Input() cod_manager;
  @Input() chartTitle;
  @Input() updateChart;
  @Input() collab_chart_params;

  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

  public chart_data = {};
  public bol_loading = true;
  public bol_show_hint_collab = false;
  parent_width: any;
  public bol_show_chart: boolean = false;

  constructor(private _authService:AuthService, private _logbookService:LogbooksService) {

  }

  onResize(){
    let w = this.chartContainer.nativeElement.clientWidth;
    this.parent_width = w >= 180 ? 180 : w;
  }

  ngOnInit(){
    this.onResize();
    this.updateInfoChart();
  }

  ngOnChanges(changes: SimpleChanges):void {
    if(changes.updateChart != undefined){
      this.updateInfoChart();
    }
  }

  updateInfoChart() {
    if(!this.collab_chart_params.cod_checklist || !this.collab_chart_params.arr_cod_shifts){
      this.bol_show_chart = false;
      this.bol_loading = false;
    }
    else{
      this.bol_show_chart = true;
      if(this.cod_manager) {
        // TEAM COLLABORATIVE CHART NOT IMPLEMENTED (unneeded?)
        /*
        if(!this.date){
          this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
        }
        else if(this.date.length < 8) {
          this.date += '-01';
        }
        this._logbookService.getTeamCalcLogbook(this.cod_manager, this.date, this.cod_user).subscribe(
          data => {
            if (data.code === 888 && data.content.code === 678 ) {
              let objData = {
                v_count_executed:0,
                v_count_executed_not_point:0,
                v_count_not_calculated:0,
                v_count_not_executed:0,
                v_count_not_point:0,
                v_count_not_scale:0
              }
              if (data.content.results.v_array_return){
                data.content.results.v_array_return.forEach(element => {
                  objData.v_count_executed = objData.v_count_executed + element.v_count_executed;
                  objData.v_count_executed_not_point = objData.v_count_executed_not_point + element.v_count_executed_not_point;
                  objData.v_count_not_calculated = objData.v_count_not_calculated + element.v_count_not_calculated;
                  objData.v_count_not_executed = objData.v_count_not_executed + element.v_count_not_executed;
                  objData.v_count_not_point = objData.v_count_not_point + element.v_count_not_point;
                  objData.v_count_not_scale = objData.v_count_not_scale + element.v_count_not_scale;
                });
              }
  
              this.updateSeriesData(objData);
            } else {
              console.log(data.content);
            }
          },
          err => { this._authService.errCheck(err); console.log(err); }
          
        );
        */
      } else {
        if(!this.date){
          this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
        }
        else if(this.date.length < 8) {
          this.date += '-01';
        }
        let params = {
          //cod_user: this.cod_user,//unused
          date: this.date,
          cod_user_auth: this._authService.getAuthenticatedUser().id,
          cod_checklist: this.collab_chart_params.cod_checklist,
          arr_cod_shifts: this.collab_chart_params.arr_cod_shifts,
          cod_location: this._authService.getAuthenticatedUser().cod_location, 
          cod_subarea: localStorage.getItem('subareaCollab')
        };
        this._logbookService.getUserCalcChecklistCollab(params).subscribe(
          data => {
            if (data.code === 888 && data.content.code === 678 ) {
              this.updateSeriesData(data.content.results.v_array_return);
            } else {
              console.log(data.content);
            }
          },
          err => { this._authService.errCheck(err); console.log(err); }
          
        );
      }

    }
  }

  updateSeriesData(data) {
    if(data){
      this.chart_data = {
        total: (data.v_count_executed + data.v_count_executed_not_point + data.v_count_not_executed),
        value: (data.v_count_executed + data.v_count_executed_not_point)
      };
    }
    this.bol_loading = false;
  }


}