import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeetingsTypesService } from '../services/meetings-types.service';
import { AuthService } from '../../main/access/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';

@Component({
  selector: 'app-meetings-visualize-tor',
  templateUrl: './meetings-visualize-tor.component.html',
  styleUrls: ['./meetings-visualize-tor.component.css']
})

export class MeetingsVisualizeTorComponent implements OnInit {
  obj_tor_details: any;

  cod_meeting: any;

  str_brewery: any = 'Location / Brewery Name';
  str_meeting_name: any = 'Meeting Name';

  obj_tor_temp_details: any = {
    str_objective: '',
    str_owner: '',
    str_participants: '',
    str_location: '',
    str_time: '',
    int_duration: 0,
    str_duration: '',
    str_frequency: '',
    str_ground_rules: '',
    str_input: '',
    str_output: '',
    str_kpis: ''
  };

  bol_loading: boolean = false;
  bol_is_editing: boolean = false;
  bol_saving: boolean = false;
  bol_save_fail: boolean = false;
  bol_save_success: boolean = false;
  bol_can_edit: boolean = false;
  arr_obj_agenda = [];
  arr_abj_agenda_unfiltered = [];
  arr_obj_edit_agenda = [];
  arr_obj_del_agenda = [];
  arr_link_standard = [];
  arr_link_site = [];
  obj_edit_agenda: any;
  linkIdx: any;
  linkAgendaIdx: any;
  link_timeout: any;
  int_agenda_index: any;
  bol_error_submit_agenda: boolean;
  bol_confirm_delete: boolean;
  bol_tor_agenda: boolean;
  bol_edit_tor_agenda: boolean;
  str_hyperlink: string;
  str_hyperlink_desc: string;
  edit_str_hyperlink: string;
  edit_str_hyperlink_desc: string;
  obj_edit_hyperlink: any;
  error_message_submit_agenda: any;
  error_message_submit_link: any;
  bol_unsaved_changes: boolean;
  tmp_arr_agenda: any[];
  bol_error_submit: boolean;
  error_message_submit: any;
  bol_is_refresh: boolean = false;
  bol_rendering_pdf: boolean = false;
  agendaCategories: Array<any> = [];
  selectedCodMeetingTypeCategory: Array<any> = [];
  agendaFilter: Array<any> = [];
  isEditLink: boolean = false;
  torRevisions = [];
  torSelectedRevision = [];
  torLastRev: any;
  bol_error_submit_link: boolean = false;
  idx_link: number;
  hasChanges: any = false;

  downloadParams = {
    type: 'torDetailsPdf',
    cod_meeting: 0,
    cod_user: 0,
    name: 'TOR_ Details_'
  }

  obj_duration: any;
  durationsList: any[] = [ // Placeholder durations in case translations fail or take too long
      {
          int_duration: 15,
          str_description: '15m'
      },
      {
          int_duration: 30,
          str_description: '30m'
      },
      {
          int_duration: 45,
          str_description: '45m'
      },
      {
          int_duration: 60,
          str_description: '1h'
      }
  ];
  selectedDuration: any = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _meetingsTypesService: MeetingsTypesService,
    private _translateService: TranslateService,
    private _userPrefsService: UserPreferencesService,
    private sanitizer: DomSanitizer
  ) {
    this._activatedRoute.params.subscribe({
      next: params => {
        this.cod_meeting = params['id'];
        this.downloadParams.cod_meeting = this.cod_meeting;
        this.downloadParams.cod_user = this._authService.getAuthenticatedUser().id;
        this.downloadParams.name += this.cod_meeting + "_";
      }, 
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  ngOnInit() {
    this.loadMeetingTorDetails();
    this.listAgendaCategories();
    this.getDurationsArray();
  }

  loadMeetingTorDetails() {
    this.bol_loading = true;
    this._meetingsTypesService.getMeetingTorDetails(this.cod_meeting, this._authService.getAuthenticatedUser().id).subscribe({
      next: result => {
        if (result.code == 888 && result.content.code == 888) {
          this.torRevisions = result.content.results.length > 0 ? result.content.results.reverse() : [result.content.results];
          this._translateService.get('MEETING_TYPES_LIST.TOR_RESULT_REV').subscribe(translate => {
            this.torRevisions = this.torRevisions.map(revision => {
              revision.cod_idseqtor = translate + revision.cod_idseqtor + ' - ' + revision.str_usu_dat;
              return revision;
            });
            this.torLastRev = this.torRevisions[0].cod_idseqtor;
            this.torSelectedRevision = [this.torRevisions[0]];
            this.obj_tor_details = this.torRevisions[0];
            this.hasChanges = this.torRevisions[0].bol_has_changes;
            this.listAgenda(this.obj_tor_details.tor_is_custom ? this.obj_tor_details.cod_meetings_custom_type : this.obj_tor_details.cod_meetings_types, this.obj_tor_details.tor_is_custom);
          });
        }
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  listAgenda(cod_meetings_types, tor_is_custom) {
    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_types: cod_meetings_types,
      tor_is_custom: tor_is_custom
    };
    this._meetingsTypesService.listCustomAgenda(params).subscribe({
      next: meetingsAgenda => {
        this.arr_obj_agenda = this._meetingsTypesService.getArrayContent(meetingsAgenda);
        this.arr_abj_agenda_unfiltered = this.arr_obj_agenda;

        // change file extension
        this.arr_obj_agenda.map(item => {
          item.arr_hyperlinks.map(it => {
            it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it.str_link));
          });
        });

        this.resetValues();
        this.bol_can_edit = this.obj_tor_details.bol_is_owner === 1;
        if (this.bol_can_edit) {
          this.bol_is_refresh = this.hasChanges;
        }
        this.bol_loading = false;
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  listAgendaCategories(): void {
    this._meetingsTypesService.getAgendaCategories(this._authService.getAuthenticatedUser().id, this._userPrefsService.getLocalLanguage())
    .subscribe({
      next: data => {
        if (data.type === 'success') {
            this.agendaCategories = data.content.results;
        } else {
            console.log(data.content);
        }
      }, 
      error: err => {
          this._authService.errCheck(err);
          console.log(err);
      }
    });
  }

  getAgendaCategoryDescription(cod_meeting_type_category: number): string {
    const category = this.agendaCategories.find(c => c.cod_meeting_type_category == cod_meeting_type_category);
    return category ? category.str_description : null;
  }

  onAgendaCategoryChanged($event): void {
    const category = $event.selectedOptions[0];
    if (category)
        this.obj_edit_agenda.cod_meeting_type_category = category.cod_meeting_type_category;
  }

  canEditAgenda(agenda, torDetails) {
    return (!torDetails.bol_meeting_type_standard || agenda.iscustom);
  }

  getAgendaFilter(name: string) {
    return this.agendaFilter.find(filter => filter.name == name);
  }

  updateAgendaFilter(name: string, value: string) {
    const filter = this.getAgendaFilter(name);
    if (!filter)
      this.agendaFilter.push({
        name,
        value: value.toLowerCase()
      });
    else
      filter.value = value.toLowerCase();
  }

  hasAgendaFilter(): boolean {
    return (this.agendaFilter.filter(f => f.value && f.value != "").length > 0);
  }

  filterAgenda($event, filterName: string): void {
    this.updateAgendaFilter(filterName, $event.target.value);

    const categoryFilter = this.getAgendaFilter('category');
    const dateFilter = this.getAgendaFilter('date');
    const descriptionFilter = this.getAgendaFilter('description');

    let category: any;
    let searchSplited: any;
    let stringWildCard = '';
    this.arr_obj_agenda = this.arr_abj_agenda_unfiltered;

    if (categoryFilter) {
      if (categoryFilter.value != "") {
        searchSplited = categoryFilter.value.split(' ').filter(elem => elem != '').join('*');
        stringWildCard = '*' + searchSplited + '*';

        if (searchSplited && searchSplited.length > 0) {
          this.arr_obj_agenda = this.arr_obj_agenda.filter((elem) => {
            category = this.agendaCategories.find(category => category.cod_meeting_type_category == elem.cod_meeting_type_category);
            if (category && category.str_description != "" && this.matchRuleShort(category.str_description.replace('\n', ' ').toLowerCase(),stringWildCard)) {
              return true;
            }
          });
        }
      }
    }

    if (dateFilter) {
      if (dateFilter.value != "") {
        searchSplited = dateFilter.value.split(' ').filter(elem => elem != '').join('*');
        stringWildCard = '*' + searchSplited + '*';        if (searchSplited && searchSplited.length > 0) {
          this.arr_obj_agenda = this.arr_obj_agenda.filter((elem) => {
            if (elem.str_date != "" && this.matchRuleShort(elem.str_date.replace('\n', ' ').toLowerCase(),stringWildCard)) {
              return true;
            }
          });
        }
      }
    }

    if (descriptionFilter) {
      if (descriptionFilter.value != "") {
        searchSplited = descriptionFilter.value.split(' ').filter(elem => elem != '').join('*');
        stringWildCard = '*' + searchSplited + '*';
        
        if (searchSplited && searchSplited.length > 0) {
          this.arr_obj_agenda = this.arr_obj_agenda.filter((elem) => {
            if (elem.str_description != "" && this.matchRuleShort(elem.str_description.replace('\n', ' ').toLowerCase(), stringWildCard)) {
              return true;
            }
          });
        }
      }
    }

  }

  matchRuleShort(str, rule) {
    let escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
  }

  hasStandardHyperlinks(agenda): boolean {
    return (this.getStandardHyperlinks(agenda).length > 0);
  }

  hasSiteHyperlinks(agenda): boolean {
    return (this.getSiteHyperlinks(agenda).length > 0);
  }

  getStandardHyperlinks(agenda): any {
    return (agenda.arr_hyperlinks.filter(hyperlink => hyperlink.iscustom == 0));
  }

  getSiteHyperlinks(agenda): any {
    return (agenda.arr_hyperlinks.filter(hyperlink => hyperlink.iscustom == 1));
  }

  showAgendaTableTitle(): boolean {
    return (this.arr_obj_agenda.length != 0 || this.hasAgendaFilter());
  }

  newAgenda() {
    this.bol_error_submit_agenda = false;
    this.bol_tor_agenda = true;
    this.bol_edit_tor_agenda = false;
    this.selectedCodMeetingTypeCategory = [];

    this.obj_edit_agenda = {
      iscustom: 1,
      cod_agenda: null,
      cod_order: null,
      str_owner: '',
      isDel: null,
      str_description: '',
      arr_hyperlinks: [/*{
                cod_agenda_hyperlinks: 0,
                str_hyperlink: '',
                str_hyperlink_desc: '',
                isDel: null
            }*/
      ],
      cod_meeting_type_category: null,
      str_date: ''
    };
  }


  deleteAgenda(idx) {
    this.arr_obj_agenda[idx].isDel = 1;
    this.arr_obj_del_agenda.push(this.arr_obj_agenda[idx]);
    this.arr_obj_agenda.splice(idx, 1);

    this.orderAgendaNumber();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arr_obj_agenda, event.previousIndex, event.currentIndex);
    this.orderAgendaNumber();
  }

  orderAgendaNumber() {
    this.arr_obj_agenda = this.arr_obj_agenda.map((val, i) => {
      val.cod_order = i + 1;
      if (val.cod_agenda != 0) {
        this.arr_obj_edit_agenda.push(val.cod_agenda);
      }
      return val;
    });
  }

  resetValues() {
    this.str_brewery = this.obj_tor_details.str_description_from;
    this.str_meeting_name = this.obj_tor_details.str_meeting_name;

    this.obj_tor_temp_details.str_objective = this.obj_tor_details.str_objective;
    this.obj_tor_temp_details.str_owner = this.obj_tor_details.str_owner;
    this.obj_tor_temp_details.str_participants = this.obj_tor_details.str_participants;
    this.obj_tor_temp_details.str_location = this.obj_tor_details.str_location;
    this.obj_tor_temp_details.str_time = this.obj_tor_details.str_time;
    this.obj_tor_temp_details.int_duration = this.obj_tor_details.int_duration;
    this.selectedDuration = [{ int_duration:  this.obj_tor_details.int_duration }];
    this.obj_duration = this._meetingsTypesService.formatDuration(this.obj_tor_details.int_duration);
    this.obj_tor_temp_details.str_frequency = this.obj_tor_details.str_frequency;
    this.obj_tor_temp_details.str_ground_rules = this.obj_tor_details.str_ground_rules;
    this.obj_tor_temp_details.str_input = this.obj_tor_details.str_input;
    this.obj_tor_temp_details.str_output = this.obj_tor_details.str_output;
    this.obj_tor_temp_details.str_kpis = this.obj_tor_details.str_kpis;

    /* this.obj_tor_temp_details.arr_agenda = ... */
  }

  getDurationsArray() {
    this._meetingsTypesService.getDurationsArray(this._translateService).subscribe((results) => {
        this.durationsList = results;
    });
  }

  selectDuration(event) {
    this.bol_unsaved_changes = true; 
    this.selectedDuration = event.selectedOptions;
    this.obj_tor_temp_details.int_duration = this.selectedDuration[0].int_duration;
  }

  cancelEditTor() {
    this.loadMeetingTorDetails();    
    this.bol_is_editing = false;
    this.bol_error_submit = false;
    this.arr_obj_agenda = Object.assign([], this.tmp_arr_agenda);
    this.arr_obj_del_agenda = [];

    this.arr_obj_agenda.forEach(element => {
      if(element.isDel === 1) {
        element.isDel = null
      }
    });
  }

  saveTorDetails() {
    this.bol_saving = true;
    this.bol_save_fail = false;
    this.bol_save_success = false;

    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_meeting: this.cod_meeting,
      cod_meeting_type: this.obj_tor_details.cod_meetings_types,
      cod_custom_tor: this.obj_tor_details.cod_meetings_custom_type,
      str_objective: this.obj_tor_temp_details.str_objective,
      str_owner: this.obj_tor_temp_details.str_owner,
      str_participants: this.obj_tor_temp_details.str_participants,
      str_location: this.obj_tor_temp_details.str_location,
      // str_time: this.obj_tor_temp_details.str_time,
      int_duration: this.obj_tor_temp_details.int_duration,
      str_frequency: this.obj_tor_temp_details.str_frequency,
      str_ground_rules: this.obj_tor_temp_details.str_ground_rules,
      str_input: this.obj_tor_temp_details.str_input,
      str_output: this.obj_tor_temp_details.str_output,
      str_kpis: this.obj_tor_temp_details.str_kpis,
    };

    const agendaParam = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_types: 0,
      arr_agendas: []
    };

    for (const element of this.arr_obj_del_agenda) {
      agendaParam.arr_agendas.push(element);
    }
    for (const element of this.arr_obj_agenda) {
      if (element.cod_agenda == 0 ||
        this.arr_obj_edit_agenda.indexOf(element.cod_agenda) != -1 || element.iscustom == 0) {
        if (element.iscustom == 0) {
          element.cod_del_agenda = element.cod_agenda;
          element.cod_agenda = 0;
        }
        agendaParam.arr_agendas.push(element);
      }
    }
    if (!this.obj_tor_temp_details.str_objective || this.obj_tor_temp_details.str_objective.length <= 0) {

      this._translateService.get('MEETING_TYPES_LIST.OBJECTIVE_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_owner || this.obj_tor_temp_details.str_owner.length <= 0) {

      this._translateService.get('MEETING_TYPES_LIST.OWNER_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }

    if (!this.obj_tor_temp_details.str_participants || this.obj_tor_temp_details.str_participants.length <= 0) {

      this._translateService.get('MEETING_TYPES_LIST.PARTICIPANTS_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_location || this.obj_tor_temp_details.str_location.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.int_duration || this.obj_tor_temp_details.int_duration <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.DURATION_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_frequency || this.obj_tor_temp_details.str_frequency.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.FREQUENCY_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_ground_rules || this.obj_tor_temp_details.str_ground_rules.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.RULES_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_input || this.obj_tor_temp_details.str_input.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.INPUT_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_output || this.obj_tor_temp_details.str_output.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.OUTPUT_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }
    if (!this.obj_tor_temp_details.str_kpis || this.obj_tor_temp_details.str_kpis.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.KPIS_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }

    if (this.arr_obj_agenda.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.AGENDA_IS_REQUIRED').subscribe(translate => {
        this.bol_error_submit = true;
        this.error_message_submit = translate;
        this.bol_saving = false;
      });
      return;
    }

    this._meetingsTypesService.saveCustomTorDetails(params).subscribe({
      next: result => {
        if (result.code == 888 && result.content.code == 888) {
          agendaParam.cod_types = result.content.results.cod_custom_tor;
          this.obj_tor_details.cod_custom_tor = result.content.results.cod_custom_tor;

          this._meetingsTypesService.saveCustomAgenda(agendaParam).subscribe({
            next: result => {
              if (result.code == 888 && result.content.code == 888) {
                this.bol_is_editing = false;
                this.bol_saving = false;
                this.bol_save_success = true;
                this.bol_error_submit = false;

                this.loadMeetingTorDetails();
                setTimeout(() => {
                  this.bol_save_success = false;
                }, 3000);
              } else {
                this.bol_saving = false;
                this.bol_save_fail = true;
                setTimeout(() => {
                  this.bol_save_fail = false;
                }, 3000);
              }
            },
            error: err => {
              this.bol_saving = false;
              this.bol_save_fail = true;
              setTimeout(() => {
                this.bol_save_fail = false;
              }, 3000);
              this._authService.errCheck(err);
              console.log(err);
            }
          });
        } else {
          this.bol_saving = false;
          this.bol_save_fail = true;
          setTimeout(() => {
            this.bol_save_fail = false;
          }, 3000);
        }
      },
      error: err => {
        this.bol_saving = false;
        this.bol_save_fail = true;
        setTimeout(() => {
          this.bol_save_fail = false;
        }, 3000);
        this._authService.errCheck(err);
        console.log(err);
      }
    });

  }

  toggleEditTor() {
    this.bol_is_editing = !this.bol_is_editing;
    this.tmp_arr_agenda = Object.assign([], this.arr_obj_agenda);
  }

  refreshBaseTor() {
    /* refresh TOR, replace current custom TOR with base TOR */
    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_meeting: this.cod_meeting
    };
    const changesParams = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_meetings: this.cod_meeting
    };
    this.bol_loading = true;
    this.bol_confirm_delete = false;

    this._meetingsTypesService.meetingsTypeHasChanges(changesParams).subscribe({
      next: changesTor => {
        if (changesTor.code === 888 && changesTor.content.code === 888) {
          this._meetingsTypesService.deleteCustomMeetingType(params).subscribe(deleteCustomType => {
            if (deleteCustomType.code === 888 && deleteCustomType.content.code === 888) {
              this.loadMeetingTorDetails();
            } else {
              this.bol_loading = false;
            }
          });
        }
      }, 
      error: err => {
          this.bol_loading = false;
          console.log(err);
      }
    });
  }

  confirmRefreshTor() {
    this.bol_confirm_delete = true;
  }

  closeConfirmMessage() {
    this.bol_confirm_delete = false;
}

  addAgenda() {
    let idx;
    this.bol_unsaved_changes = true;
    this.bol_error_submit_agenda = false;

    if (this.obj_edit_agenda.iscustom) {
      if (!this.obj_edit_agenda.cod_meeting_type_category) {
        this._translateService.get('MEETING_TYPES_LIST.CATEGORY_REQUIRED').subscribe(translate => {
            this.bol_error_submit_agenda = true;
            this.error_message_submit_agenda = translate;
        });
        return;
      }
  
      if (this.obj_edit_agenda.str_date.length <= 0 || !this.obj_edit_agenda.str_date) {
          this._translateService.get('MEETING_TYPES_LIST.DATE_REQUIRED').subscribe(translate => {
              this.bol_error_submit_agenda = true;
              this.error_message_submit_agenda = translate;
          });
          return;
      }
    }

    if (this.obj_edit_agenda.str_description.length <= 0 || !this.obj_edit_agenda.str_description) {
      this._translateService.get('MEETING_TYPES_LIST.DESCRIPTION_REQUIRED').subscribe(translate => {
        this.bol_error_submit_agenda = true;
        this.error_message_submit_agenda = translate;
      });
      return;
    }

    if (this.obj_edit_agenda.str_owner.length <= 0 || !this.obj_edit_agenda.str_owner) {
      this._translateService.get('MEETING_TYPES_LIST.OWNER_REQUIRED').subscribe(translate => {
        this.bol_error_submit_agenda = true;
        this.error_message_submit_agenda = translate;
      });
      return;
    }

    if (!this.addLink()) {
      return
    }

    if (this.bol_edit_tor_agenda) {
      this.arr_obj_agenda[this.int_agenda_index].str_description = this.obj_edit_agenda.str_description;
      this.arr_obj_agenda[this.int_agenda_index].str_owner = this.obj_edit_agenda.str_owner;
      this.arr_obj_agenda[this.int_agenda_index].arr_hyperlinks = this.obj_edit_agenda.arr_hyperlinks;
      this.arr_obj_agenda[this.int_agenda_index].cod_meeting_type_category = this.obj_edit_agenda.cod_meeting_type_category;
      this.arr_obj_agenda[this.int_agenda_index].str_date = this.obj_edit_agenda.str_date;

      // add no array edit agenda
      this.arr_obj_edit_agenda.push(this.obj_edit_agenda.cod_agenda);
      idx = this.int_agenda_index;
    } else {

      this.arr_obj_agenda.push({
        iscustom: 1,
        cod_agenda: 0,
        cod_order: this.arr_obj_agenda.length + 1,
        str_description: this.obj_edit_agenda.str_description,
        str_owner: this.obj_edit_agenda.str_owner,
        isDel: null,
        arr_hyperlinks: this.obj_edit_agenda.arr_hyperlinks,
        cod_meeting_type_category: this.obj_edit_agenda.cod_meeting_type_category,
        str_date: this.obj_edit_agenda.str_date,
        cod_type_agenda: this.obj_edit_agenda.cod_type_agenda
      });
      idx = this.arr_obj_agenda.length - 1;
    }
    this.bol_edit_tor_agenda = false;
    this.bol_tor_agenda = false;
  }

  addLink() {
    if (this.str_hyperlink) {
      const wrongPattern = /^\.\.\/|^\.\.\\/;
      const pattern = /^https?:\/\/.+/;
      const pattern2 = /^file:\/\/\/?.+/;
      const pattern3 = /^\\\\.+/;
      const pattern4 = /^[a-zA-Z]:(\/|\\).+/;

      if (wrongPattern.test(this.str_hyperlink)) {
        this._translateService.get('MEETING_TYPES_LIST.INVALID_HYPERLINK').subscribe(translate => {
            this.bol_error_submit_agenda = true;
            this.error_message_submit_agenda = translate;
        }); 
        return false;
      }

      this.str_hyperlink = this.str_hyperlink.replace(/^[^\x00-\x7F]/gmu, ""); 
      if (!pattern.test(this.str_hyperlink)) {
        if (!pattern2.test(this.str_hyperlink)) {
          if (pattern3.test(this.str_hyperlink) || pattern4.test(this.str_hyperlink)) {
            this.str_hyperlink = 'file:///' + this.str_hyperlink;
          } else {
            this.str_hyperlink = 'https://' + this.str_hyperlink;
          }
        }
      }
      this.obj_edit_agenda.arr_hyperlinks.push({
        iscustom: 1,
        cod_agenda_hyperlinks: 0,
        str_link: this.str_hyperlink,
        str_link_desc: this.str_hyperlink_desc,
        isDel: null,
        str_click_link: null,
        cod_type_agenda: this.obj_edit_agenda.cod_type_agenda
      });
    }
    this.str_hyperlink = '';
    this.str_hyperlink_desc = '';

    this.obj_edit_agenda.arr_hyperlinks.map(it => {
        it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it.str_link));
      });
    return true;
  }

  cancelAddAgenda() {
    this.bol_tor_agenda = false;
    this.bol_edit_tor_agenda = false;
    this.selectedCodMeetingTypeCategory = [];
    this.obj_edit_agenda = {
      cod_agenda: null,
      cod_order: null,
      str_owner: '',
      isDel: null,
      str_description: '',
      arr_hyperlinks: [],
      cod_meeting_type_category: null,
      str_date: ''
    };
  }

  cancelLink() {
    this.bol_error_submit_link = false;
    this.isEditLink = false;
  }

  editAgenda(agenda, idx) {
    this.bol_error_submit_agenda = false;
    this.bol_tor_agenda = true;
    this.bol_edit_tor_agenda = true;
    this.selectedCodMeetingTypeCategory = [];

    this.obj_edit_agenda = {
      iscustom: agenda.iscustom,
      cod_agenda: agenda.cod_agenda,
      cod_order: agenda.cod_order,
      cod_meeting_type_category: agenda.cod_meeting_type_category,
      str_date: agenda.str_date,
      str_description: agenda.str_description,
      str_owner: agenda.str_owner,
      isDel: agenda.isDel,
      cod_type_agenda: agenda.cod_type_agenda,
      arr_hyperlinks: []// agenda.arr_hyperlinks
    };
    for (const element of agenda.arr_hyperlinks) {
      this.obj_edit_agenda.arr_hyperlinks.push({
        iscustom: element.iscustom,
        cod_agenda_hyperlinks: element.cod_agenda_hyperlinks,
        str_link: element.str_link,
        str_link_desc: element.str_link_desc,
        isDel: element.isDel,
        cod_type_agenda: element.cod_type_agenda,
      });

    }
    // agenda;
    this.int_agenda_index = idx;
    if (agenda.cod_meeting_type_category)
      this.selectedCodMeetingTypeCategory = [this.agendaCategories.find(c => c.cod_meeting_type_category == agenda.cod_meeting_type_category)];
  }

  changeLink(){
    if (!this.obj_edit_hyperlink.str_link || this.obj_edit_hyperlink.str_link.length <= 0) {
      this._translateService.get('MEETING_TYPES_LIST.HYPERLINK_IS_REQUIRED').subscribe(translate => {
        this. bol_error_submit_link = true;
        this.error_message_submit_link = translate;
      });
      return;
    }

    if (this.obj_edit_hyperlink.str_link) {
      const wrongPattern = /^\.\.\/|^\.\.\\/;
      const pattern = /^https?:\/\/.+/;
      const pattern2 = /^file:\/\/\/?.+/;
      const pattern3 = /^\\\\.+/;
      const pattern4 = /^[a-zA-Z]:(\/|\\).+/;

      if (wrongPattern.test(this.obj_edit_hyperlink.str_link)) {
        this._translateService.get('MEETING_TYPES_LIST.INVALID_HYPERLINK').subscribe(translate => {
            this.bol_error_submit_agenda = true;
            this.error_message_submit_link = translate;
        }); 
        return false;
      }

      this.obj_edit_hyperlink.str_link = this.obj_edit_hyperlink.str_link.replace(/^[^\x00-\x7F]/gmu, ""); 
      if (!pattern.test(this.obj_edit_hyperlink.str_link)) {
        if (!pattern2.test(this.obj_edit_hyperlink.str_link)) {
          if (pattern3.test(this.obj_edit_hyperlink.str_link) || pattern4.test(this.obj_edit_hyperlink.str_link)) {
            this.obj_edit_hyperlink.str_link = 'file:///' + this.obj_edit_hyperlink.str_link;
          } else {
            this.obj_edit_hyperlink.str_link = 'https://' + this.obj_edit_hyperlink.str_link;
          }
        }
      }

      this.obj_edit_agenda.arr_hyperlinks[this.idx_link] = this.obj_edit_hyperlink;
      this.isEditLink = false;
      this.bol_error_submit_link = false;

    }

  }

removeLink(index) {
  if (this.obj_edit_agenda.arr_hyperlinks[index].cod_agenda_hyperlinks == 0) {
    this.obj_edit_agenda.arr_hyperlinks.splice(index, 1);
  } else {
    if(this.obj_edit_agenda.arr_hyperlinks[index].iscustom == 0){
      this.obj_edit_agenda.arr_hyperlinks.splice(index, 1);
    }
    else{
      this.obj_edit_agenda.arr_hyperlinks[index].isDel = 1;
      this.obj_edit_agenda.arr_hyperlinks.push(this.obj_edit_agenda.arr_hyperlinks.splice(index, 1)[0]);
    }
  }
  this.orderLinkNumber();
}
  

  dropLink(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.obj_edit_agenda.arr_hyperlinks, event.previousIndex, event.currentIndex);
    this.orderLinkNumber();
  }

  orderLinkNumber() {
    this.obj_edit_agenda.arr_hyperlinks = this.obj_edit_agenda.arr_hyperlinks.map((val, i) => {
      val.cod_order = i + 1;
      if (val.cod_agenda_hyperlinks != 0) {
        this.obj_edit_agenda.arr_hyperlinks.push(val.cod_agenda_hyperlinks);
      }
      return val;
    });
  }

  editLink(link, index) {
    this.isEditLink=true;
    this.idx_link = index;
    this.obj_edit_hyperlink = {...link};
   }

  copyLink(str_hyperlink, i, j = 0, copy = false) {
    const pattern = /^https?:\/\/.+/;
    if (!pattern.test(str_hyperlink) || copy === true) {
      this.copyToClipboard(str_hyperlink);
      this.linkIdx = i;
      this.linkAgendaIdx = j;
      clearTimeout(this.link_timeout);
      this.link_timeout = setTimeout(() => {
        this.linkIdx = -1;
        this.linkAgendaIdx = 0;
      }, 3000);
    }
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy'); //TODO execCommand is deprecated, need to change the way we copy
    document.body.removeChild(selBox);
  }

  downloadExtension(file) {
    const extension = file.split('.').pop();
    let newFile = file;

    // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
    switch (extension) {
      case 'xls':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'xlsx':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'xlsm':
        newFile = `ms-excel:ofe|u|${file}`;
        break;         
      case 'csv':
        newFile = `ms-excel:ofe|u|${file}`;
        break;
      case 'doc':
        newFile = `ms-word:ofe|u|${file}`;
        break;
      case 'docx':
        newFile = `ms-word:ofe|u|${file}`;
        break;
      case 'ppt':
        newFile = `ms-powerpoint:ofe|u|${file}`;
        break;
      case 'pptx':
        newFile = `ms-powerpoint:ofe|u|${file}`;
        break;
      default:
        break;
    }

    // REPLACING BACKWARD SLASH TO FORWARD SLASH TO FIX WRONG LOCAL URL
    newFile = newFile.replace(/\\/g, '/');

    return newFile;
  }

  selectTorRevision($event) {
    if ($event.selectedOptions.length > 0) {
      this.obj_tor_details = $event.selectedOptions[0];
    } else {
      this.torSelectedRevision = [this.obj_tor_details];
    }
    this.listAgenda(this.obj_tor_details.tor_is_custom ? this.obj_tor_details.cod_meetings_custom_type : this.obj_tor_details.cod_meetings_types, this.obj_tor_details.tor_is_custom);
  }

  revertTor() {

    this.bol_saving = true;
    this.bol_save_success = false;
    this.bol_error_submit = false;

    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_meeting: this.cod_meeting,
      cod_custom_tor: this.obj_tor_details.cod_meetings_custom_type
    };

    this._meetingsTypesService.revertCustomTorRevision(params).subscribe({
      next: result => {
        if (result.code == 888 && result.content.code == 888) {
          this.bol_saving = false;
          this.bol_save_success = true;
          this.loadMeetingTorDetails();
        } else {
          this._translateService.get('MEETING_TYPES_LIST.SAVE_CUSTOM_TOR_ERROR').subscribe(translate => {
            this.bol_saving = false;
            this.bol_error_submit = true;
            this.error_message_submit = translate;
          });
        }
      }, 
      error: err => {
        this.bol_saving = false;
        this.bol_error_submit = true;
        this.error_message_submit = err;
        this._authService.errCheck(err);
        console.log(err);
      }
    });

  }

}
