// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-div {
  text-align: left;
}

.yesNoButton {
  color: #666666;
  width: 50%;
}
.noButton {
  border-radius: 0 5px 5px 0;
}

.yesButton {
  border-radius: 5px 0 0 5px;
}

.box-choice {
  height: 30px;
  display: flex;
  border: 1px solid;
  border-radius: 5px;
  width: max-content;
  min-width: 110px;
  margin-bottom: 8px;
}

.yesClass {
  background-color: #36ab36;
  color: white;
}

.noClass {
  background-color: #e44140;
  color: white;
}

.blocked {
  cursor: not-allowed !important;
}

.fullSizeContent {
  width: auto;
}

/* label, p {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
} */

label {
  height: 47px;
  display: flex;
  word-break: normal;
  align-items: flex-end;
}

@media (max-width: 1080px) {
  label {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/question-answer-button/question-answer-button.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;;;;GAIG;;AAEH;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".question-div {\n  text-align: left;\n}\n\n.yesNoButton {\n  color: #666666;\n  width: 50%;\n}\n.noButton {\n  border-radius: 0 5px 5px 0;\n}\n\n.yesButton {\n  border-radius: 5px 0 0 5px;\n}\n\n.box-choice {\n  height: 30px;\n  display: flex;\n  border: 1px solid;\n  border-radius: 5px;\n  width: max-content;\n  min-width: 110px;\n  margin-bottom: 8px;\n}\n\n.yesClass {\n  background-color: #36ab36;\n  color: white;\n}\n\n.noClass {\n  background-color: #e44140;\n  color: white;\n}\n\n.blocked {\n  cursor: not-allowed !important;\n}\n\n.fullSizeContent {\n  width: auto;\n}\n\n/* label, p {\n    white-space: nowrap; \n    overflow: hidden;\n    text-overflow: ellipsis;\n} */\n\nlabel {\n  height: 47px;\n  display: flex;\n  word-break: normal;\n  align-items: flex-end;\n}\n\n@media (max-width: 1080px) {\n  label {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
