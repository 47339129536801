import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ListChecklistParams } from "../models/list-checklist-params.model";
import { ChecklistMainService } from "./checklist-main.service";
import { AuthMainService } from "../../services/auth-main.service";


@Injectable()
export class ListChecklistSelectService {
  constructor(private _mainService: AuthMainService) {}

  getListChecklist(params: ListChecklistParams): Observable<any>{
    let path = '/filter-list-checklists';
    return this._mainService.post(path, params);
  }
}
