import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MeetingsService} from '../services/meetings.service';
import {AuthService} from '../../main/access/services/auth.service';
import {FilterByStatus} from '../../meetings/meetings-actions-list/meetings-actions-list.pipes';
import {ActionsService} from '../services/actions.service';
import {TeamService} from '../services/team.service';
import {environment} from '../../../environments/environment';
import {LogbooksService} from '../../logbooks/services/logbooks.service';

@Component({
    selector: 'app-meetings-delayed-actions',
    templateUrl: './meetings-delayed-actions.component.html',
    styleUrls: ['./meetings-delayed-actions.component.css']
})
export class MeetingsDelayedActions implements OnInit {
    // list: Object;
    cod_manager;
    searchTerm: string;
    listUsers: Array<object>;
    isHidden: boolean;
    detailsParam: object;
    searchTimer: any;

    str_agrupament:string = 'owner'; // Usign to define the grouper actions tipe
    arr_actions_group;
    arr_action_main:any;
    bool_filter_finished: boolean = true;
    bool_show_details: boolean = false;
    arr_actions_status:Array<any>;
    arr_users=[];
    arr_meeting_column_answer:Array<any>;
    bol_isDisabled_filter_column_answer: boolean=true;
    arr_filters:Array<any>;
    arr_orders:Array<string>;
    chartDataParam:any;

    arr_userDB:Array<number>;

    @Input('screen-name') screenName;
    constructor(public teamService:TeamService,public actionsService: ActionsService, public meetingsService: MeetingsService, private _logbookService:LogbooksService, private router: Router, private _authService: AuthService) {
        this.cod_manager = this._authService.getAuthenticatedUser().id;
        this.listUsers = [];
        this.arr_filters=[];
        this.arr_orders=[];
        this.isHidden = true;
        this.arr_actions_status=[];
        this.arr_meeting_column_answer=[];
        this.arr_userDB=[];
    }

    ngOnInit() {
        this.actionsService.getActionsStatus(this._authService.getAuthenticatedUser().id).subscribe(
            data => {
                if (data.code === 888 && data.content.code === 888) {

                    this.arr_actions_status = data.content.results;

                    this.teamService.getUsersTeam(this.cod_manager, environment().uploadGetFilesApiUrl + '/', this._authService.getAuthenticatedUser().id).subscribe(
                        data => {
                            if (data.code === 888 && data.content.code === 888) {
                                this.arr_users = data.content.results;
                                this.getPercentualDB();
                            }
                        }, err => { console.log(err); }
                    );

                    this.actionsService.getMeetingsTeam(this.cod_manager).subscribe(data => {
                        if(data && data.code == 888 && data.content && data.content.code == 888){
                            this.arr_action_main= data.content.results;
                            this.updateListActionsOrder();
                        }
                    }, err => { this._authService.errCheck(err); console.log(err); });

                }
            }, err => { console.log(err); }
        );
    }

    /**
     * @author Guilherme Renato Uller
     * This function defines the action according to the object transferred in the stock listing
     * @param task{EventEmitter<Object>}
     */
    onTaskSelected($this) {
        this.isHidden = false;
        this.detailsParam = {'cod_action': $this.action.cod_actions};
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    showCanceled() {
        this.bool_filter_finished = !this.bool_filter_finished;
        this.updateListActionsOrder();
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    showDetails() {
        this.bool_show_details = !this.bool_show_details;
        this.updateListActionsOrder();
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    getPercentualDB() {
        let date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);

        for (let index = 0; index < this.arr_users.length; index++) {
            const user = this.arr_users[index];
            this._logbookService.getUserCalcLogbook(user.cod_user, date, this.cod_manager).subscribe(
                data => {
                    if (data.code === 888 && data.content.code === 678 ) {
                        if(data){
                            let executed = 0;
                            let total = 0;

                            if(data.content.results != null && data.content.results){

                                if(data.content.results.v_array_return != null && data.content.results.v_array_return){
                                    data = data.content.results.v_array_return;
                                    executed = (data.v_count_executed + data.v_count_executed_not_point);
                                    total = (data.v_count_executed + data.v_count_executed_not_point + data.v_count_not_executed);
                                }
                            }

                            if(executed && total){
                                this.arr_userDB[user.cod_user] = Math.round( ((executed * 100) / total) * 100 ) / 100;
                            } else {
                                this.arr_userDB[user.cod_user] = 0;
                            }
                        }
                    } else {
                        console.log(data.content);
                    }
                },
                err => { this._authService.errCheck(err); console.log(err); }
            );
        }
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    setActionsAgrupament(str_agrupament:string){
        this.str_agrupament=str_agrupament;
        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    setActionsFilter(str_filter, str_value1, str_value2){
        let $iCount=0;
        let $iIndex=null;
        this.arr_filters.forEach(filterType => {
            if(filterType.type == str_filter && str_value1 != ''){
            $iIndex = $iCount;
            }
            $iCount++;
        });
        if($iIndex != null){
            this.arr_filters[$iIndex] = {type:str_filter, value:str_value1, value2 :str_value2};
        }
        else if(!this.arr_filters.filter(function( obj ) {return obj.type == str_filter;}).length){
            this.arr_filters.push({type:str_filter, value:str_value1, value2:str_value2});
        }
        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    onFilterSelected($event){
        this.setActionsFilter($event.str_filter, $event.str_value, $event.str_value2)
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    updateListActionsOrder() {
        this.arr_actions_group = this.actionsService.filterActions(this.arr_filters, this.arr_action_main, this.bool_filter_finished);
        this.updateDataChart(this.arr_actions_group)
        this.arr_actions_group = this.actionsService.groupActions(this.str_agrupament, this.arr_actions_group);
        this.arr_actions_group = this.actionsService.orderActions(this.arr_orders, this.arr_actions_group);
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    updateDataChart(arr_action_main) {

        const arr_main = this.actionsService.verifyCountRow(arr_action_main).filter((elem) => {
            return elem.cod_actions_status !== null;
        });

        let arr_return = [];
        this.arr_actions_status.forEach(action_status => {
            let qtdActions = FilterByStatus.prototype.transform(arr_main, null, action_status.cod_actions_status, null).length;
            if(qtdActions > 0) {
                let str_color='';
                switch (action_status.cod_actions_status) {
                    case 1:
                        str_color='#949494';
                        break;
                    case 2:
                        str_color='#fdb849';
                        break;
                    case 3:
                        str_color='#34C141';
                        break;
                    case 4:
                        str_color='#5D5D5D';
                        break;
                    case 5:
                        str_color=null;
                        break;
                    case 6:
                        str_color=null;
                        break;
                    case 7:
                        str_color='#1A94E6';
                        break;
                    case 8:
                        str_color='#fc4e53';
                        break;

                    default:
                        str_color=null;
                        break;
                }
                arr_return.push({
                        name:''
                    , cod_actions_status:action_status.cod_actions_status
                    , str_name:action_status.str_description
                    , y:qtdActions
                    , color:str_color
                    , percent: Math.round( (((qtdActions * 100) / arr_main.length) * 100 ) / 100)
                });
            }
        });
        this.chartDataParam = arr_return;
    }

    selectedUsersChangedFilter(event,i){
        this.arr_filters[i].value=event.selectedOptions[0].str_name;
        this.updateListActionsOrder();
    }

    selectedActionStatusChangedFilter(event,i){
        const filtersArr = event.selectedOptions.map(item => {
            return item.cod_actions_status;
        });
        const filters = filtersArr.toString();
        // this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_actions_status : null;
        this.arr_filters[i].value = (filters) ? filters : null;

        this.updateListActionsOrder();
    }

    selectedIndicatorChangedFilter(event,i){
        this.arr_filters[i].value=event.selectedOptions[0].cod_indicator;
        this.updateListActionsOrder();
    }

    updateInputAnswer(event,i){
        if(event.selectedOptions.length > 0){
            this.meetingsService.getColumnOptions(event.selectedOptions[0].cod_columns, this._authService.getAuthenticatedUser().id).subscribe(
                data => {
                    if (data.code === 888 && data.content.code === 888) {
                        this.arr_meeting_column_answer = data.content.results;
                        this.bol_isDisabled_filter_column_answer = (this.arr_meeting_column_answer.length > 0) ? false : true;
                    }
                }, err => { console.log(err); }
            );
        } else {
            this.arr_meeting_column_answer=[];
            this.bol_isDisabled_filter_column_answer = true;
        }
        this.selectedColumnChangedFilter(event, i)
    }

    selectedColumnChangedFilter(event,i){
        if(event.selectedOptions[0].cod_columns)
            this.arr_filters[i].value=event.selectedOptions[0].cod_columns;

        if(event.selectedOptions[0].cod_columns_options)
            this.arr_filters[i].value2=event.selectedOptions[0].cod_columns_options;

        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    removeActionsFilter(index){
        this.arr_filters.splice(index,1);
        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    setActionsOrder(str_order){
        if(this.arr_orders.indexOf(str_order) < 0)
        this.arr_orders.push(str_order);

        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    removeActionsOrder(index){
        this.arr_orders.splice(index,1);
        this.updateListActionsOrder();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    roundPercent(value){
          return Math.round( (value * 100 ) / 100);
    }

    getImagePath(image: string){
        if (image != ''){
            return environment().uploadGetFilesApiUrl + '/' + image;
        }
        return '';
    }

}