import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { AuthService } from '../../main/access/services/auth.service';
import { ActionComment, ChangeActionDeadline, ChangeActionStatus } from '../services/types/actions-service-types';
import {
  ImportExcelDrawerComponent,
  ImportExcelParams,
} from '../../components/import-excel-drawer/import-excel-drawer.component';
import { NotificationService } from '../../main/services/notification.service';
import { ActionsService } from '../services/actions.service';
import { KpisService } from '../services/kpis.service';
import { ConvertDate } from '../../components/convert-date/convert-date.component';
import { ScrollNearEndDirective } from '../../directives/scroll-near-end/scroll-near-end.directive';
import { FormsModule } from '@angular/forms';
import { debounceTime, finalize, Subject, Subscription } from 'rxjs';
import { MultiSelectComponent } from "../../components/multiselect/multiselect.component";
import * as _ from 'lodash';
import { ClbCheckboxModule } from '@celebration/angular/clb-checkbox';
import { MeetingsEditActionDrawerComponent } from "../meetings-edit-action-drawer/meetings-edit-action-drawer.component";
import * as moment from 'moment';
import { ExportActionsService } from '../services/export-actions.service';
import { MeetingsActionsCommentsDrawerComponent } from "../meetings-actions-comments-drawer/meetings-actions-comments-drawer.component";
import { QuillViewComponent, QuillEditorComponent } from 'ngx-quill';
import { CommentType, SyncStatus } from '../services/types/enums';
import { UploadService } from '../../main/services/upload.service';
import { SocketService } from '../../main/services/socket.service';
import CH from '../services/helpers/comments-helpers';

// Map for action status
enum ActionStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  REQ_TO_CLOSE = 3,
  CANCELED = 4,
  CLOSED = 7,
  DELAYED = 8,
}

type ActionTableColumn = '' | 'owner' | 'description' | 'kpi' | 'deadline' | 'status' | 'export-actions';
type SortState = '' | 'asc' | 'desc';

type FilterState = {
  order: {
    column: ActionTableColumn,
    sort: SortState
  },
  filter: {
    owner: string,
    description: string,
    kpi: string[] | null,
    status: ActionStatus[] | null
  }
};


type ActionListItem = {
    cod_actions: number;
    str_description: string;
    cod_user_owner: number;
    str_owner_name: string;
    str_owner_img_path: string;
    arr_kpis: string[];
    dat_due: string;
    prev_dat_due?: string;
    cod_action_execution_status: number;
    str_execution_status_description: string;
    cod_actions_status: number;
    str_status_description: string;
    count_comments: number;
    bol_open_comments?: boolean;
    arr_comments?: ActionComment[];
};

type EditActionItem = {
  cod_action: number;
  str_description: string;
  cod_owner: number;
  str_name_owner: string;
  dat_due: string;
  cod_action_status: number;
}
type FilterActions = {
  exportActionsStartDate: string;
  exportActionsEndDate: string;
}


@Component({
  standalone: true,
  selector: 'app-meetings-actions',

  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ImportExcelDrawerComponent,
    ClbModalModule,
    ClbCheckboxModule,
    ConvertDate,
    ScrollNearEndDirective,
    MultiSelectComponent,
    MeetingsEditActionDrawerComponent,
    MeetingsActionsCommentsDrawerComponent,
    QuillViewComponent,
    QuillEditorComponent,
],
  templateUrl: './meetings-actions.component.html',
  styleUrls: ['./meetings-actions.component.css']
})

export class MeetingsActionsComponent implements OnInit, OnDestroy {
  @Input('codMeeting') cod_meeting: number;
  @Input('isMeetingOwner') is_meeting_owner: boolean = false;
  @Input('meetingName') meeting_name: string;
  @Input('socketId') socket_id: string;

  @Output() onClickAddAction = new EventEmitter<any>();
  
  readonly ActionStatus = ActionStatus;
  readonly CommentType = CommentType;
  readonly SyncStatus = SyncStatus;

  readonly formatName = CH.formatName; // needed to use on html template

  private readonly actionsListDebounceSubject$ = new Subject<void>();

  obj_connection_comments: Subscription;
  obj_connection_actions: Subscription;

  status_translate_keys = {
    1: 'GENERAL.STATUS_NOT_STARTED',
    2: 'GENERAL.STATUS_IN_PROGRESS',
    3: 'GENERAL.STATUS_REQUEST_TO_CLOSE',
    4: 'GENERAL.STATUS_CANCELED',
    7: 'GENERAL.STATUS_CLOSE',
    8: 'GENERAL.STATUS_DELAYED',
  };

  obj_user: any;
  cod_user: any;

  arr_kpis: any[] = [];
  loading_kpis: boolean = true;

  filterState: FilterState = {
    order: {
      column: '',
      sort: ''
    },
    filter: {
      owner: '',
      description: '',
      kpi: null,
      status: null
    }
  };
  pendingFilter: FilterState = {
    order: {
      column: '',
      sort: ''
    },
    filter: {
      owner: '',
      description: '',
      kpi: null,
      status: null
    }
  };
  openFilter: ActionTableColumn = '';
  selected_kpis: {uuid: string}[] = []
  private readonly default_selected_status: any = {
    1: true,
    2: true,
    3: true,
    4: false,
    7: false,
    8: true
  };
  selected_status: any = {...this.default_selected_status};
  bol_import_action_drawer: boolean = false;

  importActionParams: ImportExcelParams;
  showImportErrors: boolean = false;
  importErrors: string;

  loading_actions: boolean = false;
  arr_actions: ActionListItem[] = [];
  next_offset: number = 0;
  page_size: number = 20;
  loadingSingleAction: { [key: number]: boolean } = {}; 

  bol_edit_action: boolean = false;
  obj_edit_action: EditActionItem = null;
  bol_can_edit_action: boolean;
  bol_action_comments: boolean = false;
  cod_action_to_comment: number = null;
  action_comments_count: number = 0;

  filterActions: FilterActions = {
    exportActionsStartDate: "",
    exportActionsEndDate: "",
  };
  today: string = null;


  obj_comment_edit: ActionComment = null;
  str_comment_edit: string;
  editCharCount: number = 0;
  invalidEditCharCount: boolean = false;

  maxCharCount: number = 255;

  editCommentEditorRef: any;

  failed_comments: Map<ActionComment, string> = new Map();

  obj_comment_to_delete: ActionComment;
  bol_delete_comment_modal: boolean = false;


  constructor(
		private readonly _authService: AuthService,
    private readonly _notify: NotificationService,
    private readonly _translateService: TranslateService,
    private readonly _actionsService: ActionsService,
    private readonly _kpisService: KpisService,
    private readonly _exportActionsService: ExportActionsService,
    private readonly _uploadService: UploadService,
    private readonly _socketService: SocketService
  ) { 
    this.obj_user = _authService.getAuthenticatedUser();
    this.cod_user = this.obj_user.id;
    this.today = this.getTodaysDate(); // Makes sense to not allow edited deadline to be before today
    this.loadKpis();
  }
  
  ngOnInit() {
    this.importActionParams = {
      proc: 'meetings_sp_imp_actions_v2',
      fields: 'cod_action, str_hashtag, str_action, cod_responsible, dat_due, str_comment, cod_department, str_kpis, str_action_origin, str_root_cause',
      additionalParams: [`${this.cod_meeting}`],
      templateTranslationKeys: [
          'CSV_TEMPLATE_HEADERS.HASHTAG', //str_hashtag
          'CSV_TEMPLATE_HEADERS.ACTION_DESCRIPTION', //str_action
          'CSV_TEMPLATE_HEADERS.ID_RESPONSIBLE_ACTION', //cod_responsible
          'CSV_TEMPLATE_HEADERS.DEADLINE', //dat_due
          'CSV_TEMPLATE_HEADERS.COMMENTS', //str_comment
          'CSV_TEMPLATE_HEADERS.COD_AREA', //cod_department
          'CSV_TEMPLATE_HEADERS.KPI', //str_kpis
          'CSV_TEMPLATE_HEADERS.ACTION_ORIGIN', //str_action_origin
          'CSV_TEMPLATE_HEADERS.ROOT_CAUSE', //str_root_cause
      ],
    };
    
    this.actionsListDebounceSubject$
      .pipe(debounceTime(500))
      .subscribe(() => this.refreshActionsList());
    
    this._actionsService.getRefreshEventSubject().subscribe(event => this.onRefreshEvent(event));
    this.subscribeToSocket();
  
    this.loadActionsList();
    this.resetActionsReportFilter();
  }

  onRefreshEvent(event){
    if(event.cod_meeting === this.cod_meeting){
      if (event.type === 'NEW_ACTION') {
        this.refreshActionsList({force: true});
      } else {
        const action = this.arr_actions.find(action => action.cod_actions === event.cod_action);
        if(action){
          if (event.type ==='UPDATE_ACTION' || (event.type === 'UPDATE_ACTION_COMMENTS' && !action.bol_open_comments)) {
            this.loadSingleAction(action.cod_actions);
          } else if (event.type === 'UPDATE_ACTION_COMMENTS') {
            this.loadComments(action);
          }
        }
      } 
    }
  }

  subscribeToSocket() {
    try {
      this.obj_connection_actions = this._socketService.subscribeToActions().subscribe({
        next: (data) => {
          let dataSocket: any;
          dataSocket = data;
          if (
            dataSocket.cod_meeting == this.cod_meeting &&
            dataSocket.origin != this.socket_id
          ){
            const action = this.arr_actions.find(action => action.cod_actions === dataSocket.cod_action);
            if (action) {
              this.loadSingleAction(action.cod_actions);
            } else if(!dataSocket.cod_action){
              this.refreshActionsList({merge: true});
            }
          }
        },
        error: (err) => {
          this.showErrorMessage('Meeting Actions SOCKET ERROR', err, false);
        },
      });
      this.obj_connection_comments = this._socketService.subscribeToActionMessage().subscribe({
        next: (data) => {
          let dataSocket: any;
          dataSocket = data;
          if (
            dataSocket.obj_message.cod_meeting == this.cod_meeting &&
            dataSocket.obj_message.origin != this.socket_id
          ) {

            const cod_action_updated = dataSocket.obj_message.cod_action;
            const action = this.arr_actions.find(action => action.cod_actions === cod_action_updated);
    
            if(action?.bol_open_comments){
              this.loadComments(action);
            } else if (action){
              this.loadSingleAction(action.cod_actions);
            }
          }
        },
        error: (err) => {
          this.showErrorMessage('Action Comments SOCKET ERROR', err, false);
        },
      });
    } catch (err) {
      this.showErrorMessage('Actions SOCKET ERROR', err, false);
    }
  }

  resetActionsReportFilter(): void {
    this.filterActions.exportActionsStartDate = this.filterActions.exportActionsEndDate = moment().format('YYYY-MM-DD');
  }

  ngOnDestroy(): void {
    this.actionsListDebounceSubject$.unsubscribe();
    if(!this.obj_connection_comments?.closed){
        this.obj_connection_comments?.unsubscribe()
    }
    if(!this.obj_connection_actions?.closed){
      this.obj_connection_actions?.unsubscribe()
    }
  }

  refreshActionsList(options?: {force?: boolean, merge?: boolean}) {
    if(!_.isEqual(this.filterState, this.pendingFilter) || options?.force){ 
      this.next_offset = 0;
      this.filterState.order = {...this.pendingFilter.order};
      this.filterState.filter = {...this.pendingFilter.filter};
      this.loadActionsList();
    } else if (options?.merge) {
      this.next_offset = 0;
      this.loadActionsList(false);
    }
  }

  onScrollNearEnd() {
    if(!this.loading_actions && this.arr_actions.length > this.next_offset - this.page_size) {
      this.loadActionsList();
    }
  }

  paramsFromColumnState() {
    let obj_order = this.filterState.order;
    let str_order = `${obj_order.column} ${obj_order.sort}`.trim() || null;
    const params = {
      offset: Math.min(this.next_offset, this.arr_actions.length),
      limit: this.page_size,
      order: str_order,
      owner_filter: this.filterState.filter.owner,
      desc_filter: this.filterState.filter.description,
      kpi_filter: this.filterState.filter.kpi,
      status_filter: this.filterState.filter.status
    }
    return params;
  }

  parseRequestResult(data: any, calback, error) {
    if (data.code == 888 && data.content.code == 888) {
        let res = data.content.results
        calback(res);
    } else {
        error(data.content);
    }
  }

  loadKpis() {
    this.loading_kpis = true;
    this._kpisService
      .getKpis({_order: 'name'})
      .subscribe({
        next: result => {
          if (result.code == 888) {
            this.arr_kpis = result.content.data;
          } else {
            this.showErrorMessage('GENERAL.FAIL_TO_FETCH', result);
          }
          this.loading_kpis = false;
        },
        error: err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
          this._authService.errCheck(err);
        }
      })
  }

  loadActionsList(reset: boolean = true) {
    const params = this.paramsFromColumnState();
    this.loading_actions = true;
    this._actionsService.getMeetingActionsList(this.cod_meeting, this.cod_user, params).subscribe({
      next: response => {
        this.parseRequestResult(response, (data) => {
          this.next_offset = this.arr_actions.length + params.limit;
          this.loading_actions = false;
          if(!params.offset){
            if(reset){
              this.arr_actions = data;
            } else {
              this.arr_actions = CH.mergeDuplicated([...data, ...this.arr_actions], 'cod_actions', true);
            }
          } else {
            this.arr_actions.push(...data); 
          }
        }, err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        });
      },
      error: err => {
        this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        this._authService.errCheck(err);
      }
    });
  }

  loadComments(action: ActionListItem) {
    this._actionsService.getActionComments(this.cod_user, action.cod_actions).subscribe({
      next: response => {
        this.parseRequestResult(response, (data) => {
          let count = 0;
          action.arr_comments = data.map(item => {
            if(!item.dat_del) count += 1;
            item.type = CH.checkCommentType(item.str_name_image);
            item.str_name = CH.formatName(item.str_name);
            item.sync_status = item.sync_status ?? SyncStatus.SUCCESS;
            return item;
          });
          action.count_comments = count;
        }, err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        });
      },
      error: err => {
        this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        this._authService.errCheck(err);
      }
    });
  }

  toggleComments(action: ActionListItem) {
    action.bol_open_comments = !action.bol_open_comments;
    if(action.bol_open_comments){
      this.loadComments(action);
    }
  }

  openCommentsDrawer(cod_action: number, action_comments_count: number) {
    this.cod_action_to_comment = cod_action;
    this.action_comments_count = action_comments_count;
    this.bol_action_comments = true;

    this.cancelEditComment();
  }

  closeCommentsDrawer(){
    this.bol_action_comments = false;
    this.cod_action_to_comment = null;
  }
  
  addAction() {
    this.onClickAddAction.emit();
  }

  editAction(action: ActionListItem) {
    this.obj_edit_action = {
      cod_action: action.cod_actions,
      str_description: action.str_description,
      cod_owner: action.cod_user_owner,
      str_name_owner: action.str_owner_name,
      dat_due: action.dat_due,
      cod_action_status: action.cod_actions_status
    };
    this.bol_can_edit_action = (action.cod_actions_status === ActionStatus.NOT_STARTED || 
                                action.cod_actions_status === ActionStatus.IN_PROGRESS || 
                                action.cod_actions_status === ActionStatus.DELAYED);
    this.bol_edit_action = true;
  }

  closeEditAction(updated: boolean){
    const cod_action = this.obj_edit_action.cod_action;
    this.obj_edit_action = null;
    this.bol_edit_action = false;
    if(updated) {
      this.loadSingleAction(cod_action);
    }
  }

  importAction() {
      this.bol_import_action_drawer = true;
  }

  onDoneImport(response: any) {
      this.bol_import_action_drawer = false;

      response = JSON.parse(response);

      if (response?.errors) {
          const iem = this._translateService.instant('MEETING.IMPORT_ACTIONS_ERROR_1');
          const iem2 = this._translateService.instant('MEETING.IMPORT_ACTIONS_ERROR_2');
          this.showImportErrors = true;
          this.importErrors = `<p>${iem}</p><p>${response.errors.join('</p><p>')}</p><p>${iem2}</p>`;
      } else {
          this._notify.success({ text: 'LOGBOOK.MESSAGE_IMPORTED_SUCCESS', translate: true });
          this.loadActionsList();
      }
  }

  onImportError(error) {
      this.bol_import_action_drawer = false;

      let errorMessageType = '';

      if (error == 'Date format incorret.') {
          this.showErrorMessage('MEETING.INCORRECT_FORMAT_DATE');
      } else if (error == 'Invalid cod_department') {
          this.showErrorMessage('MEETING.INCORRECT_DEPARTMENT');
      } else if (!error.includes('Row length does not match headers')) {
          let errorMessage = error.split('-');
          errorMessageType = errorMessage[0];
          switch (errorMessageType) {
              case 'STR_ACTION':
                  this.showErrorMessage('MEETING.REQUIRED_ACTION');
                  break;
              case 'COD_RESPONSIBLE':
                  this.showErrorMessage('MEETING.REQUIRED_COD_RESPONSIBLE');
                  break;
              case 'COD_PARTICIPANT':
                  this.showErrorMessage('MEETING.REQUIRED_COD_PARTICIPANT');
                  break;
              case 'ROOT_CAUSE':
                  this.showErrorMessage('MEETING.REQUIRED_ROOT_CAUSE');
                  break;
              case 'DAT_DUE':
                  this.showErrorMessage('MEETING.REQUIRED_DAT_DUE');
                  break;
              case 'cod_department':
                  this.showErrorMessage('MEETING.REQUIRED_COD_DEPARTMENT');
                  break;
              default:
                  this.showErrorMessage('ERROR.GENERIC_ERROR', error);
                  break;
          }
      }
  }

  showErrorMessage(errorMessage: string, objError: any = undefined, notify: boolean = true) {
      console.log({errorMessage, objError});
      if (notify) this._notify.error({ text: errorMessage, translate: true });
  }

  showSuccessMessage(successMessage: string) {
      this._notify.success({ text: successMessage, translate: true });
  }

  sortColumn(column: ActionTableColumn) {
    if(this.pendingFilter.order.column !== column) {
      this.pendingFilter.order.sort = '';
    }
    this.pendingFilter.order.column = column;
    switch (this.pendingFilter.order.sort) {
      case 'asc':
        this.pendingFilter.order.sort = ''; //reset
        this.pendingFilter.order.column = '';
        break;
      case 'desc':
        this.pendingFilter.order.sort = 'asc';
        break;
      default:
        this.pendingFilter.order.sort = 'desc';
        break;
    }
    this.actionsListDebounceSubject$.next();
  }

  toggleFilterColumn(column: ActionTableColumn, clearFilter: boolean = false) {
    this.pendingFilter.filter = {...this.filterState.filter}; // When toggling always reset pending filter to actual filter
    if(clearFilter){
      this.clearFilterColumn(column);
    }
    if(this.toggleOpenFilter(column)){
      let elem = document.getElementById(`search-input-${column}`);
      setTimeout(() => { // Autofocus on input field
        if(elem){
          elem.focus();
        }
      }, 200);
    }
  }

  toggleOpenFilter(column: ActionTableColumn): boolean {
    const isOpening = column !== this.openFilter;
    this.openFilter = '';
    if(isOpening){
      this.openFilter = column;
    }
    return isOpening;
  }

  clearFilterColumn(column) {
    if(column === 'kpi'){
      this.selected_kpis = [];
    }
    if(column === 'status') {
      this.selected_status = {...this.default_selected_status};
    }
    else if (column === 'export-actions') {
      this.resetActionsReportFilter();
    }
    this.pendingFilter.filter[column] = null;
    if(!_.isEqual(this.filterState.filter, this.pendingFilter.filter)) { // Only refresh if current != pending
      this.filterState.filter[column] = null;
      this.next_offset = 0;
      this.loadActionsList();
    }
  }

  selectKpis(event){
    const selected = event.selectedOptions;
    this.selected_kpis = selected.map(e => { return {uuid:e.uuid} });
    this.pendingFilter.filter.kpi = selected.map(e => e.uuid);
  }

  selectStatus(status, checked) {
    this.selected_status[status] = checked;
    let arr_status = null;
    if(!_.isEqual(this.selected_status, this.default_selected_status)){
      arr_status = Object.keys(this.selected_status).map(Number).filter(k => this.selected_status[k]);
    } 
    this.pendingFilter.filter.status = arr_status;
    this.actionsListDebounceSubject$.next();
  }
  
  resetFilterStatus() {
    this.selected_status = {...this.default_selected_status};
    this.pendingFilter.filter.status = null;
    if(!_.isEqual(this.filterState.filter, this.pendingFilter.filter)) { // Only refresh if current != pending
      this.filterState.filter.status = null;
      this.next_offset = 0;
      this.loadActionsList();
    }
  }

  onConfirmSearch() {
    this.openFilter = '';
    this.filterState.filter = {...this.pendingFilter.filter};
    this.next_offset = 0;
    this.loadActionsList();
  }

  onExportActions() {
    this._notify.success({ text: 'MY_MEETINGS.ACTIONS_SECTION.DOWNLOAD_STARTED', translate: true });

    this._actionsService.getActionsByDate(
      this.cod_user, 
      this.cod_meeting, 
      '1', 
      this.filterActions.exportActionsStartDate, 
      this.filterActions.exportActionsEndDate
    ).subscribe({
      next: response => {
        if(response.code !== 888) {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', response);   
          return;
        }     

        if(response.content === undefined || response.content == 0){
          this._notify.info({ text: 'MY_MEETINGS.ACTIONS_SECTION.NO_DATA_FOR_FILTERS', translate: true });
          return;
        }
   
        this._exportActionsService.export(response.content, this.meeting_name);
        this.toggleFilterColumn('export-actions', true)
      },
      error: err => {
        this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        this._authService.errCheck(err);
      }
    });
  }


  getNameKpi(kpi_uuid): string {
    const kpiName = this.arr_kpis.find(kpi => kpi.uuid === kpi_uuid)?.name;
    return kpiName ?? '-';
  }

  getRemainingDays(deadline): number {
    const actionDeadline = new Date(deadline).getTime();
    const today = new Date().getTime();

    const timeDiff = actionDeadline - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 0 // to avoid -0 result
    return daysDiff;
  }

  loadSingleAction(cod_action){
    this.loadingSingleAction[cod_action] = true
    this._actionsService.getSingleAction(this.cod_user, cod_action).subscribe({
      next: response => {
        this.parseRequestResult(response, (data) => {
          const pos = this.arr_actions.findIndex(action => action.cod_actions === cod_action);
          this.arr_actions[pos] = data[0];
        }, err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        });
      },
      error: err => {
        this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        this._authService.errCheck(err);
      },
      complete: () => {
        this.loadingSingleAction[cod_action] = false
      }
    });
  }

  changeActionStatus(cod_action, cod_status){
    // Status
    // 2 - Change to In Progress
    // 3 - Change to Request to Close
    // 4 - Change to canceled
    // 7 - Change to Closed
    // 9 - Change back to last status if current status was request to close or (closed or canceled and it was changed in less then 30 days)
    let params: ChangeActionStatus = {
      cod_status: cod_status
    }

    this._actionsService.changeActionStatus(this.cod_user, cod_action, params).subscribe({
      next: response => {
        this.parseRequestResult(response, (data) => {
          this.loadSingleAction(cod_action);
          // We need to change ActionListItem and it's requests (getSingleAction, getMeetingActionsList) 
          // to have an arr_meetings property as well if we want this message to be sent to all meetings for the action
          this._socketService.refreshMeetingActions({origin: this.socket_id, arr_meetings: [this.cod_meeting], cod_action});
        }, err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        });
      },
      error: err => {
        if(err?.error?.content?.code === 'IL203' ){
          this.showErrorMessage('ERROR.IL203', err);
        }
        else{
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        }
        this._authService.errCheck(err);
      }
    });
  }
  changeActionDeadline(cod_action: number, deadline: string) {
    let params: ChangeActionDeadline = {
      dat_deadline: deadline
    };
    this._actionsService.putEditActionDeadline(this.cod_user, cod_action, params).subscribe({
      next: response => {
        this.parseRequestResult(response, (data) => {
          this._notify.success({ 
            text: 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_EDIT_ACTION_DEADLINE', 
            translate: true 
          });
          this.loadSingleAction(cod_action);
          // We need to change ActionListItem and it's requests (getSingleAction, getMeetingActionsList) 
          // to have an arr_meetings property as well if we want this message to be sent to all meetings for the action
          this._socketService.refreshMeetingActions({origin: this.socket_id, arr_meetings: [this.cod_meeting], cod_action});
        }, err => {
          this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        });
      },
      error: err => {
        this.showErrorMessage('GENERAL.FAIL_TO_FETCH', err);
        this._authService.errCheck(err);
      }
    });
  }

  onCancelExportActions() {
    this.resetActionsReportFilter();
  }

  clickDeadlinePicker(picker, action: ActionListItem) {    
    picker.value = this.convertDate(action.dat_due);    
    picker.showPicker();
    action.prev_dat_due = action.dat_due;
  }

  onChangeDateSelectedModel(date: string, action: ActionListItem): void {
    const selectedDate = `${date} 00:00:00`;
    action.dat_due = selectedDate;
  }

  onChangeDateSelected(event, action){
    // double checking, just to be sure
    if (
      action.cod_actions_status === ActionStatus.REQ_TO_CLOSE ||
      action.cod_actions_status === ActionStatus.CANCELED ||
      action.cod_actions_status === ActionStatus.CLOSED
    ) return;

    const date = event.target.value;
    const selectedDate = `${date} 00:00:00`;

    if (this.convertDate(selectedDate) !== this.convertDate(action.prev_dat_due)) {
      action.dat_due = selectedDate;
      action.prev_dat_due = null;
      this.changeActionDeadline(action.cod_actions, selectedDate);
    }
  }

  getTodaysDate() {
    return this.formatDate(new Date());
  };

  convertDate(date: string): string {
    return this.formatDate(new Date(date));
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const strDate = `${year}-${month}-${day}`;

    return strDate;
  }

  downloadFile(fileUrl: string) {
    this._uploadService.downloadFile(fileUrl).subscribe({
        next: res => {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = CH.getFileName(fileUrl);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        error: err => {
          console.log('Error downloading file', err);
          const text = 'MEETING.COMMENTS_DRAWER.ERRORS.DOWNLOAD_FILE';
          this.showErrorMessage(text, err);
          this._authService.errCheck(err);
        }
    });
  }

  openEditComment(comment) {
    this.obj_comment_edit = comment;
    this.str_comment_edit = this.obj_comment_edit.str_text;
  }

  cancelEditComment() {
    this.obj_comment_edit = null;
    this.str_comment_edit = null;
  }

  saveEditComment() {
    this.updateEditCharCount({text: this.editCommentEditorRef?.getText() ?? this.str_comment_edit});
    if(this.invalidEditCharCount || !this.editCharCount) return;

    const comment_edit = this.obj_comment_edit;
    const str_edit_comment = this.str_comment_edit;
    this.obj_comment_edit = null;
    this.str_comment_edit = null;

    this.putActionComment(comment_edit, str_edit_comment);
  }

  initEditCommentQuillEditor(event) {
    this.editCommentEditorRef = event.editor
    this.updateEditCharCount({text: this.editCommentEditorRef?.getText() ?? this.str_comment_edit});
  }

  updateEditCharCount(event) {
    this.editCommentEditorRef = event?.editor ?? this.editCommentEditorRef;
    this.editCharCount = event?.text?.trim()?.length ?? 0;
    this.invalidEditCharCount = this.editCharCount > this.maxCharCount;
  }

  putActionComment(comment_edit: ActionComment, str_edit_comment: string){
    let str_original_comment = comment_edit.str_text;
    comment_edit.str_text = str_edit_comment;
    comment_edit.sync_status = SyncStatus.SYNCING;
    
    const cod_comment = comment_edit.cod_actions_comments;
    const cod_action = comment_edit.cod_action;
    const comment_param = {
      str_comment: str_edit_comment
    };

    this._actionsService.putActionComment(this.cod_user, cod_comment, comment_param).subscribe({
      next: res => {
        comment_edit.sync_status = SyncStatus.SUCCESS;
        comment_edit.dat_alt = new Date().toISOString();
        this.showSuccessMessage('MEETING.COMMENTS_DRAWER.SUCCESS.EDIT_COMMENT');
        this._socketService.refreshActionMessage({origin: this.socket_id, cod_meeting: this.cod_meeting, cod_action});
      }, 
      error: err => {
        comment_edit.sync_status = SyncStatus.FAILURE;
        this.failed_comments.set(comment_edit, str_original_comment);
        const text = 'MEETING.COMMENTS_DRAWER.ERRORS.EDIT_COMMENT';
        this.showErrorMessage(text, err);
        this._authService.errCheck(err);
      } 
    });
  }

  retrySaveComment(obj_comment: ActionComment) {
    obj_comment.sync_status = SyncStatus.SYNCING;

    if (!obj_comment.cod_actions_comments) return;

    const str_text = this.failed_comments.get(obj_comment)
    this.failed_comments.delete(obj_comment);
    const str_comment = obj_comment.str_text;
    obj_comment.str_text = str_text;
    this.putActionComment(obj_comment, str_comment);
  }

  cancelFailedComment(action: ActionListItem, obj_comment: ActionComment) {
    let str_text = this.failed_comments.get(obj_comment);
    this.failed_comments.delete(obj_comment);
    obj_comment.str_text = str_text ?? obj_comment.str_text;
    obj_comment.sync_status = SyncStatus.SUCCESS;

    if(!obj_comment.cod_actions_comments){
      this.arr_actions.find(
        a => a.cod_actions === action.cod_actions
      ).arr_comments = action.arr_comments.filter(
        item => obj_comment != item
      );
    }
  }

  openConfirmDeleteComment(comment: ActionComment) {
    this.obj_comment_to_delete = comment;
    this.bol_delete_comment_modal = true;
  }

  cancelDeleteComment() {
    this.obj_comment_to_delete = null;
    this.bol_delete_comment_modal = false;
  }

  confirmDeleteComment() {
    let comment_to_delete = this.obj_comment_to_delete;
    this.obj_comment_to_delete = null;

    comment_to_delete.is_deleted = true;
    comment_to_delete.sync_status = SyncStatus.SYNCING;
    this.bol_delete_comment_modal = false;

    const cod_comment = comment_to_delete.cod_actions_comments;
    const cod_action = comment_to_delete.cod_action;
    const action = this.arr_actions.find(action => action.cod_actions === cod_action);

    this._actionsService.deleteActionComment(this.cod_user, cod_comment)
      .pipe(finalize(() => {
        comment_to_delete.sync_status = SyncStatus.SUCCESS;
      })).subscribe({
        next: data => {
          comment_to_delete.dat_del = new Date().toISOString();
          if(action) {
            action.count_comments = Math.max(action.count_comments - 1, 0);
            if (action.count_comments === 0) action.bol_open_comments = false;
          }
          this._socketService.refreshActionMessage({origin: this.socket_id, cod_meeting: this.cod_meeting, cod_action});
        },
        error: err => {
          comment_to_delete.is_deleted = false;
          const text = 'MEETING.COMMENTS_DRAWER.ERRORS.DELETE_COMMENT';
          this.showErrorMessage(err, text);
          this._authService.errCheck(err);
        }
      });
  }

}
