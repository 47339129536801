import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {RoutinesService} from '../services/routines.service';
import {AreaService} from '../services/area.service';
import {LogbooksService} from '../services/logbooks.service';
import {PillarsService} from '../services/pillars.service';
import {UsersService} from '../../admin/services/users.service';
import {ReportsService} from '../services/reports.service';
import {environment} from '../../../environments/environment';
import {ValidationsService} from '../services/validations/validations.service';
import {TranslateService} from '@ngx-translate/core';
import {SubareaService} from '../services/subarea.service';
import {UserPreferencesService} from 'app/user-preferences/services/user-preferences.service';

@Component({
    selector: 'app-reports-routines',
    templateUrl: './reports-routines.component.html',
    styleUrls: ['./reports-routines.component.css']
})
export class ReportsRoutinesComponent implements AfterViewInit, OnInit {

    _reportsApiUrl = environment().reportsApiUrl;
    isLoading: boolean = true;
    areaList: any = [];
    logbookList: any = [];
    routineList: any = [];
    worldList: any = [];
    regionalList: any = [];
    locationsList: any = [];
    pillarList: any = [];
    userList: any = [];
    subAreasList: any = [];
    initial_date = null;
    final_date = null;
    messageWarning: string = '';
    isWarning: boolean = false;
    isSent: boolean = false;
    timeToHide: number = 0;
    showUsers: boolean;
    arr_areas: any = [];

    paramsSelected: any = [];
    loadingConfig: any = [];

    stillGeneratingDetailed: boolean = false;
    stillGeneratingConsolidated: boolean = false;
    timeLastDetailedReport: any;
    timeLastConsolidatedReport: any;
    lastObjParamToLogDetailed: any = [];
    lastObjParamToLogConsolidated: any = [];
    actualObjParam: any = [];  

    // Translate report
    private strReportSubject: string;
    private strReportSubjectDetailed: string;
    private strFileNameDownload: string;
    private strFileNameDownloadDetailed: string;
    private arrHeaders: any[];
    private arrHeadersDetailed: any[];
    typeChecklist: any[];
    bol_isCollab: boolean = false;
    bol_show_checklist: boolean = false;

    constructor(private _routineService: RoutinesService, private _authService: AuthService, private _areaService: AreaService,
                private _logbookService: LogbooksService, private _pillarsService: PillarsService, private _userService: UsersService,
                private _reportService: ReportsService, public validationService: ValidationsService, private _translateService: TranslateService,
                private _logbookSubAreaService: SubareaService, private _userPreferenceService: UserPreferencesService) {
        this.paramsSelected = [];
        this.showUsers = false;
        this.arr_areas = [];
        let today = new Date();
        let strDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2);
        this.initial_date = strDate;
        this.final_date = strDate;
    }

    ngOnInit(): void {
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
        this.loadingConfig['pillars'] = false;
        this.loadingConfig['users'] = false;
        this.loadingConfig['areas'] = false;
        this.loadingConfig['subareas'] = false;
        this.loadingConfig['routines'] = false;
        this.loadingConfig['logbooks'] = false;

        this.typeChecklist = [];
        this._translateService.get('LOGBOOK.FUNCTIONAL').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 0, str_description: translate});
        });
        this._translateService.get('LOGBOOK.COLLAB').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 1, str_description: translate});
        });

        this._clearWorldList();
        let $this = this;
        $this.paramsSelected['logbooks_selected'] = [{cod_logbooks: $this._authService.getAuthenticatedUser().cod_logbook}];
        $this.paramsSelected['logbooks'] = $this._authService.getAuthenticatedUser().cod_logbook;

        $this._searchWorlds(function () {
            $this.paramsSelected['worlds_selected'] = [];
            $this.worldList.map((world) => {
                if ($this._authService.getAuthenticatedUser().cod_world == world.cod_locations_world) {
                    $this.paramsSelected['worlds_selected'].push({ cod_locations_world: world.cod_locations_world });
                }
            });
            $this.paramsSelected['worlds'] = $this._implodeValues($this.paramsSelected['worlds_selected'], 'cod_locations_world');
            $this._searchRegionals(function () {
                $this.paramsSelected['regionals_selected'] = [];
                $this.regionalList.map((regional) => {
                    if ($this._authService.getAuthenticatedUser().cod_regional == regional.cod_locations_regional) {
                        $this.paramsSelected['regionals_selected'].push({ cod_locations_regional: regional.cod_locations_regional });
                    }
                });
                $this.paramsSelected['regionals'] = $this._implodeValues($this.paramsSelected['regionals_selected'], 'cod_locations_regional');
                $this._searchLocations(function () {
                    $this.paramsSelected['locations_selected'] = [];
                    $this.locationsList.map((location) => {
                        if ($this._authService.getAuthenticatedUser().cod_location == location.cod_location) {
                            $this.paramsSelected['locations_selected'].push({ cod_location: location.cod_location });
                        }
                    });
                    $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_location');
                    $this._searchAreas(function () {
                        $this.paramsSelected['areas_selected'] = [];
                        $this.areaList.map((area) => {
                            $this._authService.getAuthenticatedUser().cod_area.map(authArea => {
                                if (authArea == area.cod_area) $this.paramsSelected['areas_selected'].push({ cod_area: area.cod_area });
                            });
                        });
                        $this.paramsSelected['areas'] = $this._implodeValues($this.paramsSelected['areas_selected'], 'cod_area');
                    });
                });
            });
        });
    }

    ngAfterViewInit(): void {
        //Reset all list
        this.areaList = [];
        this.logbookList = [];
        this.routineList = [];
        this.worldList = [];
        this.regionalList = [];
        this.locationsList = [];
        this.pillarList = [];

        //Load the lists
        this._searchWorlds(null);
        //this._searchAreas(null);
        this._searchPillars();
    }

    private _areAllSelected(listSelected, listAll) {
        return listSelected.length == listAll.length;
    }

    private _listToValue(listSelected, listAll, valueWhenSelected, valueWhenAllSelected) {
        if (this._areAllSelected(listSelected, listAll)) {
            return valueWhenAllSelected;
        }
        return valueWhenSelected;
    }

    private _clearRoutines() {
        this.routineList = [];
        this.paramsSelected['routines_selected'] = [];
        this.paramsSelected['routines'] = '';
    }

    private _smartClearRoutines() {

        if (this.paramsSelected['routines_selected'] && this.paramsSelected['routines_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['routines_selected'].map((selected: { cod_routine: any; }) => {
                this.routineList.map((routine) => {
                    if (routine.cod_routine == selected.cod_routine) arr.push(routine);
                });
            });
            this.paramsSelected['routines_selected'] = arr;
            this.paramsSelected['routines'] = this._implodeValues(this.paramsSelected['routines_selected'], 'cod_routine');
        }
        if (!this.paramsSelected['routines_selected'] || this.paramsSelected['routines_selected'].length == 0) {
            this.paramsSelected['routines_selected'] = [];
            this.paramsSelected['routines'] = '';
        }

    }

    private _clearUsers() {
        this.userList = [];
        this.paramsSelected['users_selected'] = [];
        this.paramsSelected['users'] = '';
    }

    private _smartClearUsers() {

        if (this.paramsSelected['users_selected'] && this.paramsSelected['users_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['users_selected'].map((selected: { cod_id: any; }) => {
                this.userList.map((users) => {
                    if (users.cod_id == selected.cod_id) arr.push(users);
                });
            });
            this.paramsSelected['users_selected'] = arr;
            this.paramsSelected['users'] = this._implodeValues(this.paramsSelected['users_selected'], 'cod_id');
        }
        if (!this.paramsSelected['users_selected'] || this.paramsSelected['users_selected'].length == 0) {
            this.paramsSelected['users_selected'] = [];
            this.paramsSelected['users'] = '';
        }

    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearAreaList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this.areaList = [];
        this._clearLogbookList();
    }

    private _smartClearAreaList() {

        if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
                this.areaList.map((area) => {
                    if (area.cod_area == selected.cod_area) arr.push(area);
                });
            });
            this.paramsSelected['areas_selected'] = arr;
            this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
        }
        if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
            this.paramsSelected['areas_selected'] = [];
            this.paramsSelected['areas'] = '';
        } else {
            this._searchLogbooks(false);
        }

    }

    private _clearLocations() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearAreaList();
    }

    private _smartClearLocations() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearAreaList();
            this._clearUsers();
        } else {
            this._searchAreas(null);
            this._searchUsers();
        }

    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocations();
    }

    private _smartClearRegionalList() {

        if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
                this.regionalList.map((regional) => {
                    if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
                });
            });
            this.paramsSelected['regionals_selected'] = arr;
            this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        }
        if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
            this.paramsSelected['regionals_selected'] = [];
            this.paramsSelected['regionals'] = '';
            this._clearLocations();
        } else {
            this._searchLocations(null);
        }

    }

    private _clearLogbookList() {
        this.paramsSelected['logbooks'] = '';
        this.paramsSelected['logbooks_selected'] = [];
        this.logbookList = [];
        this._clearRoutines();
        this._clearUsers();
    }

    private _smartClearLogbookList() {

        if (this.paramsSelected['logbooks_selected'] && this.paramsSelected['logbooks_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['logbooks_selected'].map((selected: { cod_logbooks: any; }) => {
                this.logbookList.map((logbook) => {
                    if (logbook.cod_logbooks == selected.cod_logbooks) arr.push(logbook);
                });
            });
            this.paramsSelected['logbooks_selected'] = arr;
            this.paramsSelected['logbooks'] = this._implodeValues(this.paramsSelected['logbooks_selected'], 'cod_logbooks');
        }
        if (!this.paramsSelected['logbooks_selected'] || this.paramsSelected['logbooks_selected'].length == 0) {
            this.paramsSelected['logbooks_selected'] = [];
            this.paramsSelected['logbooks'] = '';
            this._clearRoutines();
            this._clearUsers();
        } else {
            this._searchRoutines();
            this._searchUsers();
        }

    }

    private _clearType() {
        this.paramsSelected['type'] = '';
        this.paramsSelected['type_selected'] = [];
        this.bol_isCollab = false;
        this.bol_show_checklist = false;
        this._clearLogbookList();
    }

    private _searchAreas(fn) {

        if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
            this._setLoading('areas', true);
            //Search for areas
            let params = {
                arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
                cod_user: this._authService.getAuthenticatedUser().id,
                dsc_page_privilege: 'REPORT_ROUTES'
            };
            this._userService.getAreasByLocation(params).subscribe({
                next: data => {
                    this.areaList = this._userService.getArrayContent(data);
                    this._setLoading('areas', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearAreaList();
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearAreaList();
        }

    }

    private _searchLogbooks(fn) {
        
        if(this.bol_isCollab){
            if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '' &&
                typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
                let objParams = {
                    arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                    arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
                    cod_user: this._authService.getAuthenticatedUser().id
                }; 
                this._setLoading('logbooks', true);
                this._logbookSubAreaService.searchSubareaByAreaLoction(objParams).subscribe({
                    next: data => {
                        data.content.results = (data.content.length == 1) ? [data.content.results] : data.content.results; 
                        this.subAreasList = this._implodeValues(data.content.results, 'cod_subareas');
                        let params = {
                            arr_cod_areas: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                            arr_cod_subareas: JSON.parse('[' + this.subAreasList + ']'),
                            cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'), 
                            cod_user: this._authService.getAuthenticatedUser().id,
                            dsc_privilege: 'REPORT_ROUTES'
                        };
                        this._logbookService.getChecklistReportCollab(params).subscribe({
                            next: data => {
                                this.logbookList = this._logbookService.getArrayContent(data);
                                this._setLoading('logbooks', false);
                                this._smartClearLogbookList();
                                this.bol_show_checklist = true;
                            }, 
                            error: err => {
                                this._authService.errCheck(err);
                                console.log(err);
                            }
                        });
                    }, 
                    error: err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    }
                });
            } else {
                this._clearLogbookList();
            }
        }
        else{
            if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '') {
                //Search for logbooks
                let objParam = {
                    'cod_area': this._emptyDefaultValue(this.paramsSelected['areas'], '0'),
                    'cod_location': JSON.parse('[' + this.paramsSelected['locations'] + ']'),
                    'cod_subareas': null, 
                    'term': '',
                    'cod_user': this._authService.getAuthenticatedUser().id,
                    'dsc_page_privilege': 'REPORT_ROUTES'
                };
                this._setLoading('logbooks', true);
                this._logbookService.getLogbookReport(objParam).subscribe({
                    next: data => {
                        this.logbookList = this._logbookService.getArrayContent(data);
                        this.bol_show_checklist = true;
                        this._setLoading('logbooks', false);
                        if (typeof fn != typeof undefined && fn) {
                            fn();
                        }
                        this._smartClearLogbookList();
                        this._searchRoutines();
                    }, 
                    error: err => {
                        console.log(err);
                        this._authService.errCheck(err);
                    }
                });
            } else {
                this._clearLogbookList();
            }
        }

    }

    private _searchRoutines() {

        if (this.paramsSelected['logbooks']) {
            let objParam = {
                'cod_logbook': this._emptyDefaultValue(this.paramsSelected['logbooks'], '0'),
                'term': '',
                'cod_user': this._authService.getAuthenticatedUser().id
            };
            this._setLoading('routines', true);
            this._routineService.getByLogbookGrouped(objParam).subscribe({
                next: data => {
                    this.routineList = this._routineService.getArrayContent(data);
                    this._setLoading('routines', false);
                    this._smartClearRoutines();
                }, 
                error: err => {
                    console.log(err);
                    this._authService.errCheck(err);
                }
            });
        } else {
            this._clearRoutines();
        }

    }

    private _searchPillars() {
        let objParam = {
            'cod_pillars': 0,
            'term': '',
            'cod_user': this._authService.getAuthenticatedUser().id
        };
        this._pillarsService.getPillarList(objParam).subscribe({
            next: data => {
                this.pillarList = this._pillarsService.getArrayContent(data);
            }, 
            error: err => {
                console.log(err);
                this._authService.errCheck(err);
            }
        });
    }

    private _searchUsers() {

        if (typeof this.paramsSelected['logbooks'] != typeof undefined && this.paramsSelected['logbooks'].toString() != '' &&
            typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
            this._setLoading('users', true);
            let params = {
                'cod_logbooks': this.paramsSelected['logbooks'].toString().split(','),
                'cod_location': this._emptyDefaultValue(this.paramsSelected['locations'], '0'),
                'str_path': environment().uploadGetFilesApiUrl + '/',
                'cod_user': this._authService.getAuthenticatedUser().id,
                'dsc_page_privilege': 'REPORT_ROUTES'
            };
            this._logbookService.getUsersByLogbookAndLocations(params).subscribe({
                next: data => {
                    this.userList = this._logbookService.getArrayContent(data);
                    this._setLoading('users', false);
                    this._smartClearUsers();
                }, 
                error: err => {
                    this._setLoading('users', false);
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearUsers();
        }

    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._userService.getWorlds(this._authService.getAuthenticatedUser().id, 'REPORT_ROUTES').subscribe({
            next: data => {
                this.worldList = this._userService.getArrayContent(data);
                this._setLoading('worlds', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _searchLocations(fn) {

        if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._userService.getLocations(params, this._authService.getAuthenticatedUser().id, 'REPORT_ROUTES').subscribe({
                next: data => {
                    this.locationsList = this._userService.getArrayContent(data);
                    this._setLoading('locations', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearLocations();
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearLocations();
        }

    }

    private _searchRegionals(fn) {
        
        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'REPORT_ROUTES').subscribe({
                next: data => {
                    this.regionalList = this._userService.getArrayContent(data);
                    this._setLoading('regionals', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                    this._smartClearRegionalList();
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearRegionalList();
        }

    }

    private _searchSubAreas() {
        if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '') {
            let objParams = {
                arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
                cod_user: this._authService.getAuthenticatedUser().id
            };  
            this._logbookSubAreaService.searchSubareaByAreaLoction(objParams).subscribe({
                next: data => {
                    data.content.results = (data.content.length == 1) ? [data.content.results] : data.content.results; 
                    this.subAreasList = this._implodeValues(data.content.results, 'cod_subareas');
                    //this.subAreasList = this._areaService.getArrayContent(data);
                    //this._searchEquipments();
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        }
    }

    selectLogbooks($event) {
        this.paramsSelected['logbooks_selected'] = $event.selectedOptions;
        this.paramsSelected['logbooks'] = this._implodeValues($event.selectedOptions, 'cod_logbooks');
        if(!this.bol_isCollab){
            this._searchRoutines();
            this._searchUsers();
            this.showUsers = this._emptyDefaultValue(this.paramsSelected['logbooks'], null);
        }
    }

    selectChecklistType($event){
        this.paramsSelected['type_selected'] = $event.selectedOptions;
        this.paramsSelected['type'] = this._implodeValues($event.selectedOptions, 'bol_is_collab');
        if(this.paramsSelected['type'] == 1){// 1 - collab, 0 - functional
            this.bol_isCollab = true;
        } else {
            this.bol_isCollab = false;
            // let $this = this;
            // $this._searchLogbooks(function () {
            //     if ($this.logbookList.length > 0) {
            //         const logbookExistInList = $this.logbookList.filter(logbook => logbook.cod_logbooks == $this._authService.getAuthenticatedUser().cod_logbook)[0];
            //         if (typeof logbookExistInList != typeof undefined && logbookExistInList.cod_logbooks > 0) {
            //             $this.paramsSelected['logbooks_selected'] = [{cod_logbooks: $this._authService.getAuthenticatedUser().cod_logbook}];
            //             $this.paramsSelected['logbooks'] = $this._authService.getAuthenticatedUser().cod_logbook;
            //         }
            //     }
            // });
        }
        this._searchLogbooks(false);
    }

    selectAreas($event) {
        this.paramsSelected['areas_selected'] = $event.selectedOptions;
        this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
        this._searchLogbooks(false);
    }

    selectRoutines($event) {
        this.paramsSelected['routines_selected'] = $event.selectedOptions;
        this.paramsSelected['routines'] = this._implodeValues($event.selectedOptions, 'cod_routine');
    }

    selectWorlds($event) {
        this.paramsSelected['worlds_selected'] = $event.selectedOptions;
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
        //this._searchAreas(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
        this._searchAreas(null);
        this._searchUsers();
    }

    selectPillars($event) {
        this.paramsSelected['pillars'] = this._implodeValues($event.selectedOptions, 'cod_pillars');
    }

    selectUsers($event) {
        this.paramsSelected['users_selected'] = $event.selectedOptions;
        this.paramsSelected['users'] = this._implodeValues($event.selectedOptions, 'cod_id');
    }

    // selectSubAreas($event) {
    //     this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
    // }

    private _emptyDefaultValue(value, defaultValue) {
        if (typeof value !== typeof undefined && value) {
            return value;
        }
        return defaultValue;
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    private daysInMonth(intYear, intMonth) {
        return new Date(parseInt(intYear), parseInt(intMonth), 0).getDate();
    }

    private _getAsArray(strText) {
        if (typeof strText != typeof undefined && strText) {
            return strText.toString().split(',');
        }
        return [];
    }

    private _generateReport(reportStoredProcedure: string, reportEmailSubject: string, fileName: string, headers: any[], dynamicColumn: string, detailed: boolean) {
        
        this.validationService.boolSubmit = true;
        if (!this.initial_date || !this.final_date) {
            this._showWarning('REPORTS.ROUTINE_VALIDATION_MESSAGE');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['worlds'], null)) {
            this._showWarning('USERS.MSG_VALIDATION_WORLD');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['regionals'], null)) {
            this._showWarning('REPORTS.MSG_VALIDATION_REGIONAL');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['locations'], null)) {
            this._showWarning('REPORTS.LOCATION_VALIDATION');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['areas'], null)) {
            this._showWarning('LOGBOOK.REQUIRED_AREA');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['type'], null)) {
            this._showWarning('LOGBOOK.REQUIRED_TYPE');
            return false;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['logbooks'], null)) {
            this._showWarning('USERS.MSG_VALIDATION_LOGBOOK');
            return false;
        }

        if (this.initial_date > this.final_date) {
            this._showWarning('REPORTS.PERIOD_VALIDATION');
            return false;
        }
        
        if (this._isInvalidDateRange()) {
            this._showWarning('REPORTS.PERIOD_VALIDATION_MAX_DAYS');
            return false;
        }

        if(!detailed){
            //Consolidated
            this.stillGeneratingConsolidated = true;
        }
        else{
            //detailed
            this.stillGeneratingDetailed = true;
        }

        this.validationService.boolIsLoading = true;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;

        //mount string formated as inicial date
        let strInitialDate = this.initial_date.toString() + '-01';
        //mount string formated as final date
        let strTempFinalDate = this.final_date.toString().split('-');
        let strFinalDate = strTempFinalDate[0] + '-' + ('0' + (parseInt(strTempFinalDate[1]))).slice(-2) + '-' + ('0' + (this.daysInMonth(strTempFinalDate[0], strTempFinalDate[1]))).slice(-2);

        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            mailText = mailText.replace('$USER', this._authService.getAuthenticatedUser().name);
            mailText = mailText.replace('$REPORT', reportEmailSubject);

            let logbookList = null;
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['logbooks']), this.logbookList))) {
                logbookList = this._emptyDefaultValue(this.paramsSelected['logbooks'], null);
            }
            else{
                if(this.bol_isCollab){
                    logbookList = this.paramsSelected['logbooks'];
                }
            }

            let areaList = null;
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['areas']), this.areaList))) {
                areaList = this._emptyDefaultValue(this.paramsSelected['areas'], null);
            }
            else{
                if(this.bol_isCollab){
                    areaList = this._emptyDefaultValue(this.paramsSelected['areas'], null);
                }
            }

            let pillarList = null;
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['pillars']), this.pillarList))) {
                pillarList = this._emptyDefaultValue(this.paramsSelected['pillars'], null);
            }

            let routineList = null;
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['routines']), this.routineList))) {
                routineList = this._emptyDefaultValue(this.paramsSelected['routines'], null);
            }

            let userList = null;
            if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['users']), this.userList))) {
                userList = this._emptyDefaultValue(this.paramsSelected['users'], null);
            }

            let objParam = {
                cod_user: this._authService.getAuthenticatedUser().id,
                api_url: this._reportsApiUrl,
                reportTab: 2,
                str_report_subject: reportEmailSubject,
                str_proc_exec: reportStoredProcedure,
                arr_proc_params_search: [
                    {
                        'pr_cods_world': this.paramsSelected['worlds'],
                        'pr_cod_regionals': this.paramsSelected['regionals'],
                        'pr_cods_locations': this.paramsSelected['locations'],
                        'pr_cod_turno': null,
                        'pr_cod_logbook': logbookList,
                        'pr_cod_areas': areaList,
                        'pr_cod_subarea': this.subAreasList?.toString() ?? null, //Ensures that this is either imploded ([] -> '') or null to avoid DB errors like: invalid input syntax for integer: "[]"
                        'pr_cod_pilar': null,
                        'pr_cod_routine': routineList,
                        'pr_cod_funcionario': userList,
                        'pr_initial_date': strInitialDate,
                        'pr_final_date': strFinalDate,
                        'pr_delimiter': this._userPreferenceService.getLocalDecimalDelimiter(),
                        'pr_acadia_api_url': environment().acadiaApiUrl,
                        'pr_attachments_url': environment().attachmentsUrl,
                        'pr_cod_user': this._authService.getAuthenticatedUser().id
                    }
                ],
                arr_headers: headers,
                name_of_file_to_download: fileName,
                str_column_dynamic: (dynamicColumn ? dynamicColumn : ''),
                str_worker_link: environment().workerReportPath,
                str_mail_text: mailText
            };

            let objParamToLog = {
                cod_user: this._authService.getAuthenticatedUser().id,
                api_url: this._reportsApiUrl,
                reportTab: 2,
                str_report_subject: reportEmailSubject,
                str_proc_exec: reportStoredProcedure,
                arr_proc_params_search: [
                    {
                        'pr_cods_world': this.paramsSelected['worlds'],
                        'pr_cod_regionals': this.paramsSelected['regionals'],
                        'pr_cods_locations': this.paramsSelected['locations'],
                        'pr_cod_logbook': this.paramsSelected['logbooks'],
                        'pr_cod_areas': this.paramsSelected['areas'],
                        'pr_cod_routine': this.paramsSelected['routines'],
                        'pr_cod_funcionario': this.paramsSelected['users'],
                        'pr_initial_date': strInitialDate,
                        'pr_final_date': strFinalDate
                    }
                ],
                arr_headers: headers,
                name_of_file_to_download: fileName,
                str_column_dynamic: (dynamicColumn ? dynamicColumn : ''),
                str_worker_link: environment().workerReportPath,
                str_mail_text: mailText
            };

            this.actualObjParam = objParamToLog;
            
            let lastObjParamToLog = this.lastObjParamToLogDetailed;
            if(!detailed){
                //Consolidated
                lastObjParamToLog = this.lastObjParamToLogConsolidated;
            }

            if(this._reportService.isSameParameters(this.actualObjParam, lastObjParamToLog)){
                var now = new Date().getTime();
                var milisec_diff = 300000;
                if(!detailed){
                    //Consolidated
                    if(typeof this.timeLastConsolidatedReport != typeof undefined){
                        milisec_diff = Math.abs(this.timeLastConsolidatedReport.getTime() - now);
                    }
                }
                else{
                    //Detailed
                    if(typeof this.timeLastDetailedReport != typeof undefined){
                        milisec_diff = Math.abs(this.timeLastDetailedReport.getTime() - now);
                    }
                }
                var seconds = Math.floor(milisec_diff / 1000);
                
                if(seconds < 300){
                    this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
                    this.validationService.boolIsLoading = false;
                    if(!detailed){
                        //Consolidated
                        this.stillGeneratingConsolidated = false;
                    }
                    else{
                        //detailed
                        this.stillGeneratingDetailed = false;
                    }
                    return;
                }
                else{
                    if(!detailed){
                        //Consolidated
                        this.timeLastConsolidatedReport = new Date();
                    }
                    else{
                        //detailed
                        this.timeLastDetailedReport = new Date();
                    }
                }
                
                this.validationService.boolIsLoading = false;
            }
            else{
                if(!detailed){
                    //Consolidated
                    this.lastObjParamToLogConsolidated = objParamToLog;
                    this.timeLastConsolidatedReport = new Date();
                }
                else{
                    //detailed
                    this.lastObjParamToLogDetailed = objParamToLog;
                    this.timeLastDetailedReport = new Date();
                }
            }

            this._reportService.postLogReports(objParamToLog).subscribe({
                next: data => {
                },
                error: err => {
                    this._authService.errCheck(err);
                }
            });
            
            //can not do subscribe because rabbit mq does not return a response
            this._reportService.postRoutineReports(objParam).subscribe({
                next: data => {
                    //console.log(data);
                    this.validationService.boolIsLoading = false;
                    if(!detailed){
                        //Consolidated
                        this.stillGeneratingConsolidated = false;
                    }
                    else{
                        //detailed
                        this.stillGeneratingDetailed = false;
                    }
                    this.timeToHide = 3000;
                    this.isSent = true;
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                    if(!detailed){
                        //Consolidated
                        this.stillGeneratingConsolidated = false;
                    }
                    else{
                        //detailed
                        this.stillGeneratingDetailed = false;
                    }
                }
            });
            
            this.validationService.boolSubmit = false;
            
            //Rebind event because user can click the 2 buttons simultaneously, then one event would kill the other
            let $this = this;
            window.setTimeout(function () {
                $this.isSent = true;
                $this.timeToHide = 3000;
            }, 5);
        });
    }

    submitDetailed() {
        let $this = this;
        if(this.bol_isCollab){
            this._translateReportDetailedCollab(function () {
                $this._generateReport('reports_sp_se_routines_collaborative', $this.strReportSubjectDetailed, $this.strFileNameDownloadDetailed, $this.arrHeadersDetailed, '', true);
            });
        }else{
            this._translateReportDetailed(function () {
                $this._generateReport('reports_sp_se_routines', $this.strReportSubjectDetailed, $this.strFileNameDownloadDetailed, $this.arrHeadersDetailed, '', true);
            });
        }
    }

    submitConsolidated() {
        let $this = this;
        if(this.bol_isCollab){
            this._translateReportConsolidatedCollab(function () {
                $this._generateReport('reports_sp_se_routines_consolidated_collaborative', $this.strReportSubject, $this.strFileNameDownload, $this.arrHeaders, '', false);
            });
        }else{
            this._translateReportConsolidated(function () {
                $this._generateReport('reports_sp_se_routines_consolidated', $this.strReportSubject, $this.strFileNameDownload, $this.arrHeaders, '', false);
            });
        }
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    private _showWarning(message) {
        this.isWarning = true;
        this._translateService.get(message).subscribe(translate => {
            this.messageWarning = translate;
        });
        setTimeout(() => {
            this.isWarning = false;
        }, 3000);
    }

    hideMessage() {
        this.isSent = false;
    }

    private _isInvalidDateRange() {
        let selectedRoutines = this._getAsArray(this.paramsSelected['routines'])
        let selectedLocations = this._getAsArray(this.paramsSelected['locations'])
        let isValidRoutinesRange = selectedRoutines.length < 101 && selectedRoutines.length > 0
        let isGreaterThan90Allowed = selectedLocations.length < 101 || isValidRoutinesRange || 
            (selectedLocations.length < 101 && isValidRoutinesRange)
        let limit = isGreaterThan90Allowed ? 185 : 95
        let reportRangeDiffInDays = Math.round(this._reportRangeDiffInDays())
        return (reportRangeDiffInDays < 0) || (reportRangeDiffInDays >= limit)
    }

    private _reportRangeDiffInDays() {
        let startDate = (new Date(this.initial_date)).getTime()
        let endDate = (new Date(this.final_date)).getTime()
        return (endDate - startDate) / (1000 * 3600 * 24)
    }

    private _translateReportConsolidatedCollab(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'LOGBOOK.MAIL_SUBJECT',    // strReportSubject
            'LOGBOOK.MAIL_FILE_NAME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'REPORTS.AREA',
            'LOGBOOK.SUBAREA',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.TYPE',
            'LOGBOOK.SHIFT',
            'LOGBOOK.COUNT_ACTIVE',
            'LOGBOOK.TOTAL_FORECAST',
            'LOGBOOK.TOTAL_PERFORMED',
            'REPORTS.STR_TOTAL_PREC_ROUTINE',
            'LOGBOOK.DAILY_FORECAST',
            'LOGBOOK.DAILY_PERFORMED',
            'LOGBOOK.PERC_DAILY',
            'LOGBOOK.WEEKLY_FORECAST',
            'LOGBOOK.WEEKLY_PERFORMED',
            'LOGBOOK.PERC_WEEKLY',
            'LOGBOOK.BIWEEKLY_FORECAST',
            'LOGBOOK.BIWEEKLY_PERFORMED',
            'LOGBOOK.PERC_BIWEEKLY',
            'LOGBOOK.MONTHLY_FORECAST',
            'LOGBOOK.MONTHLY_PERFORMED',
            'LOGBOOK.PERC_MONTHLY',
            'LOGBOOK.BIMONTHLY_FORECAST',
            'LOGBOOK.BIMONTHLY_PERFORMED',
            'LOGBOOK.PERC_BIMONTHLY',
            'LOGBOOK.TREMONTH_FORECAST',
            'LOGBOOK.TREMONTH_PERFORMED',
            'LOGBOOK.PERC_TREMONTH',
            'LOGBOOK.QUARTERLY_FORECAST',
            'LOGBOOK.QUARTERLY_PERFORMED',
            'LOGBOOK.PERC_QUARTERLY',
            'LOGBOOK.SEMESTER_FORECAST',
            'LOGBOOK.SEMESTER_PERFORMED',
            'LOGBOOK.PERC_SEMESTER',
            'LOGBOOK.YEARLY_FORECAST',
            'LOGBOOK.YEARLY_PERFORMED',
            'LOGBOOK.PERC_YEARLY',
            'LOGBOOK.AS_NEEDED_PERFORMED',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'LOGBOOK.MAIL_SUBJECT',    // strReportSubject
            'LOGBOOK.MAIL_FILE_NAME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['LOGBOOK.MAIL_SUBJECT'];
            this.strFileNameDownload = translations['LOGBOOK.MAIL_FILE_NAME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReportConsolidated(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'LOGBOOK.MAIL_SUBJECT',    // strReportSubject
            'LOGBOOK.MAIL_FILE_NAME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'REPORTS.AREA',
            'LOGBOOK.STR_EMPLOYEE',
            'USERS.USERNAME_AMBV',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.TYPE',
            'LOGBOOK.COUNT_ACTIVE',
            'LOGBOOK.TOTAL_FORECAST',
            'LOGBOOK.TOTAL_PERFORMED',
            'REPORTS.STR_TOTAL_PREC_ROUTINE',
            'LOGBOOK.DAILY_FORECAST',
            'LOGBOOK.DAILY_PERFORMED',
            'LOGBOOK.PERC_DAILY',
            'LOGBOOK.WEEKLY_FORECAST',
            'LOGBOOK.WEEKLY_PERFORMED',
            'LOGBOOK.PERC_WEEKLY',
            'LOGBOOK.BIWEEKLY_FORECAST',
            'LOGBOOK.BIWEEKLY_PERFORMED',
            'LOGBOOK.PERC_BIWEEKLY',
            'LOGBOOK.MONTHLY_FORECAST',
            'LOGBOOK.MONTHLY_PERFORMED',
            'LOGBOOK.PERC_MONTHLY',
            'LOGBOOK.BIMONTHLY_FORECAST',
            'LOGBOOK.BIMONTHLY_PERFORMED',
            'LOGBOOK.PERC_BIMONTHLY',
            'LOGBOOK.TREMONTH_FORECAST',
            'LOGBOOK.TREMONTH_PERFORMED',
            'LOGBOOK.PERC_TREMONTH',
            'LOGBOOK.QUARTERLY_FORECAST',
            'LOGBOOK.QUARTERLY_PERFORMED',
            'LOGBOOK.PERC_QUARTERLY',
            'LOGBOOK.SEMESTER_FORECAST',
            'LOGBOOK.SEMESTER_PERFORMED',
            'LOGBOOK.PERC_SEMESTER',
            'LOGBOOK.YEARLY_FORECAST',
            'LOGBOOK.YEARLY_PERFORMED',
            'LOGBOOK.PERC_YEARLY',
            'LOGBOOK.AS_NEEDED_PERFORMED',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'LOGBOOK.MAIL_SUBJECT',    // strReportSubject
            'LOGBOOK.MAIL_FILE_NAME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['LOGBOOK.MAIL_SUBJECT'];
            this.strFileNameDownload = translations['LOGBOOK.MAIL_FILE_NAME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReportDetailed(fnCallback) {
        this.strReportSubjectDetailed = '';
        this.strFileNameDownloadDetailed = '';
        this.arrHeadersDetailed = [];

        const observables = [
            'LOGBOOK.MAIL_SUBJECT_DETAILED',    // strReportSubjectDetailed
            'LOGBOOK.MAIL_FILE_NAME_DETAILED',  // strFileNameDownloadDetailed
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'REPORTS.AREA',
            'LOGBOOK.STR_EMPLOYEE',
            'USERS.USERNAME_AMBV',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.TYPE',
            //'REPORTS.PILLARS',
            'LOGBOOK.STR_ROUTINE',
            'LOGBOOK.DESCRIPTION',
            'REPORTS.ROUTINE_TYPE',
            'LOGBOOK.STR_PERIODICITY',
            'LOGBOOK.DATE_EXPECTED',
            'LOGBOOK.DATE_HELD',
            'LOGBOOK.STATUS',
            'LOGBOOK.TASK_LIST_COMPLETE',
            'LOGBOOK.TASK_LIST_HYPERLINK',
            'LOGBOOK.STR_FORM',
            'LOGBOOK.COMMENTS',
            'LOGBOOK.ATTACHMENT',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'LOGBOOK.MAIL_SUBJECT_DETAILED',    // strReportSubjectDetailed
            'LOGBOOK.MAIL_FILE_NAME_DETAILED',  // strFileNameDownloadDetailed
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubjectDetailed = translations['LOGBOOK.MAIL_SUBJECT_DETAILED'];
            this.strFileNameDownloadDetailed = translations['LOGBOOK.MAIL_FILE_NAME_DETAILED'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeadersDetailed.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

    private _translateReportDetailedCollab(fnCallback) {
        this.strReportSubjectDetailed = '';
        this.strFileNameDownloadDetailed = '';
        this.arrHeadersDetailed = [];

        const observables = [
            'LOGBOOK.MAIL_SUBJECT_DETAILED',    // strReportSubjectDetailed
            'LOGBOOK.MAIL_FILE_NAME_DETAILED',  // strFileNameDownloadDetailed
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'REPORTS.AREA',
            'LOGBOOK.SUBAREA',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.SHIFT',
            'LOGBOOK.TYPE',
            //'REPORTS.PILLARS',
            'LOGBOOK.STR_ROUTINE',
            'LOGBOOK.DESCRIPTION',
            'LOGBOOK.STR_PERIODICITY',
            'LOGBOOK.DATE_EXPECTED',
            'LOGBOOK.DATE_HELD',
            'LOGBOOK.STATUS',
            'LOGBOOK.TASK_LIST_COMPLETE',
            'LOGBOOK.TASK_LIST_HYPERLINK',
            'LOGBOOK.PERFORMED_BY_NAME',
            'LOGBOOK.PERFORMED_BY_ID',
            'LOGBOOK.STR_FORM',
            'LOGBOOK.COMMENTS',
            'LOGBOOK.ATTACHMENT',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'LOGBOOK.MAIL_SUBJECT_DETAILED',    // strReportSubjectDetailed
            'LOGBOOK.MAIL_FILE_NAME_DETAILED',  // strFileNameDownloadDetailed
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubjectDetailed = translations['LOGBOOK.MAIL_SUBJECT_DETAILED'];
            this.strFileNameDownloadDetailed = translations['LOGBOOK.MAIL_FILE_NAME_DETAILED'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeadersDetailed.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

}