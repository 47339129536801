import {Injectable} from '@angular/core';
import {AccessControlLogMainService} from './access-control-log-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class AccessControlLogService {
  
  constructor(private _mainService: AccessControlLogMainService) {
  }
  
  registerAccess(param): Observable<any> {
    let path = '/access-control-log/register';
    return this._mainService.post(path, param);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
