import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadService } from '../../main/services/upload.service';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { forkJoin } from 'rxjs';
import { ImportExcelService } from '../import-excel/services/import-excel.service';
import { AuthService } from '../../main/access/services/auth.service';


export type ImportExcelParams = {
  proc: string;
  fields: string;
  additionalParams: string[];
  templateTranslationKeys: string[];
}

@Component({
    standalone: true,
    selector: 'app-import-excel-drawer',
    imports: [ClbDrawerModule, ClbModalModule, TranslateModule, CommonModule],
    templateUrl: './import-excel-drawer.component.html',
    styleUrls: ['./import-excel-drawer.component.css'],
})
export class ImportExcelDrawerComponent implements OnInit, OnChanges {
    @Input('open') isOpen: boolean = false;

    @Input() importParams: ImportExcelParams = {
        proc: '', 
        fields: '', 
        additionalParams: [], 
        templateTranslationKeys: []
    };

    @Input() str_subtitle: string = 'IMPORT_FILE_DRAWER.SUBTITLE';
    @Input() str_input_label: string = 'IMPORT_FILE_DRAWER.INPUT_LABEL';
    @Input() str_placeholder: string = 'IMPORT_FILE_DRAWER.PLACEHOLDER';

    @Input() str_import_modal_title: string = 'IMPORT_FILE_DRAWER.MODAL.TITLE_IMPORT';
    @Input() str_import_modal_message: string = 'IMPORT_FILE_DRAWER.MODAL.IMPORT_MESSAGE';
    @Input() str_cancel_modal_title: string = 'IMPORT_FILE_DRAWER.MODAL.TITLE_CANCEL';
    @Input() str_cancel_modal_message: string = 'IMPORT_FILE_DRAWER.MODAL.CANCEL_MESSAGE';

    @Output() onClose = new EventEmitter<any>();
    @Output() onDoneImport = new EventEmitter<any>();
    @Output() onImportError = new EventEmitter<any>();

    @ViewChild('fileInput', { static: false }) fileInputElem: ElementRef;

    codUser: number;

    selectedFileName: string = null;
    fileNameStart: string = null;
    fileNameEnd: string = null;
    eventFileUpload: any;

    bol_confirm_import_modal: boolean = false;
    bol_confirm_close_modal: boolean = false;

    bol_import_loading: boolean = false;


    constructor(
        private readonly _translateService: TranslateService,
        private readonly _authService: AuthService,
        private readonly _userPrefService: UserPreferencesService,
        private readonly _uploadService: UploadService,
        private readonly _importService: ImportExcelService
    ) {}
    
    
    ngOnInit() {
        this.codUser = this._authService.getAuthenticatedUser().id;
        // console.log('Init Import Excel Drawer:', this.codUser, this.importParams);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // this.selectedFileName = null;
        // this.eventFileUpload = null;
        this.updateSelectedFile(null, null);
    }

    fileChangeEvent(fileInput) {
        if (typeof fileInput.target.files[0] != typeof undefined) {
            this.updateSelectedFile(fileInput, fileInput.target.files[0].name);
            // this.eventFileUpload = fileInput;
            // this.selectedFileName = fileInput.target.files[0].name;
        }
    }

    updateSelectedFile (fileInput: any, str_file_name: string){
        this.eventFileUpload = fileInput;
        this.selectedFileName = str_file_name;
        if(!this.selectedFileName){
            this.fileNameStart = null;
            this.fileNameEnd = null;
            if(this.fileInputElem) {
                this.fileInputElem.nativeElement.value = null;
            }
        } else {
            this.fileNameStart = str_file_name.slice(0, Math.ceil(str_file_name.length/2));
            this.fileNameEnd = str_file_name.slice(-Math.floor(str_file_name.length/2));
        }
    }

    downloadTemplate() {
        this._getCsvTemplateHeaders((arr_headers) => { 
            const userPreferenceCsvDeliminator = this._userPrefService.getLocalCsvDelimiter() ?? ',';
            const csvDeliminator = userPreferenceCsvDeliminator === '(TAB)' ? '\t' : userPreferenceCsvDeliminator;

            const csvData = arr_headers.join(csvDeliminator);
            const csvContent = `data:text/csv;charset=utf-8,${csvData}`;
            const encodedUri = encodeURI(csvContent);
            const timestamp = this._getTimeStamp();
            const str_file_name = `template_import_actions_${timestamp}`;

            const a = document.createElement('a');
            a.href = encodedUri;
            a.download = str_file_name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    private _getTimeStamp(): string {
        const now = new Date();
        const timestamp =
            now.getFullYear().toString() + '-' +
            (now.getMonth() + 1).toString() + '-' +
            now.getDate().toString() + '-' +
            now.getHours().toString() + '_' +
            now.getMinutes().toString() + '_' +
            now.getSeconds().toString() + '.csv';

        return timestamp;
    }

    private _getCsvTemplateHeaders(callback) {
        const headerTranslations = this.importParams.templateTranslationKeys.map(item => this._translateService.get(item));

        forkJoin(headerTranslations).subscribe((translations) => {
            callback(translations);
        });
    }

    clickImport() {
        this.bol_confirm_import_modal = true;
    }

    cancelImport() {
        this.bol_confirm_import_modal = false;
    }

    confirmImport() {
        this.bol_confirm_import_modal = false;
        this._uploadFile();
    }

    private _uploadFile() {
        let params = {
            'event': this.eventFileUpload,
            'allowedExtensions': ['text/csv', 'csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        };
        this.bol_import_loading = true;
        this._uploadService.uploadFileBase64(params).subscribe({
            next: response => {
                let fileName = response.content.str_name;
                if(response.type == 'success'){
                    const importData = {
                        file: fileName,
                        proc: this.importParams.proc,
                        fields: this.importParams.fields,
                        cod_user_auth: this.codUser,
                        additionalParams: this.importParams.additionalParams
                    };
                    this._importService.importExcelV2(importData).subscribe({
                        next: result => {
                            this.bol_import_loading = false;
                            if(result.type == 'success') {
                                this.onDoneImport.emit(result.content?.results?.excel_sp_in_import_farol);
                            } else {
                                this.onImportError.emit(result.content)
                            }
                            // this.selectedFileName = null;
                            // this.eventFileUpload = null;
                            this.updateSelectedFile(null, null);

                            //Delete de file just uploaded
                            let params = {
                                'file_name': fileName
                            };
                            this._uploadService.deleteFile(params).subscribe({
                                next: _ => {
                                    //doesnt matter error on deleting
                                },
                                error: err => {
                                    console.log(err);
                                    this._authService.errCheck(err);
                                }
                            });
                        },
                        error: err => {
                            this.bol_import_loading = false;
                            console.log(err);
                            let e = err._body ? JSON.parse(err._body) : "";
                            // this.selectedFileName = null;
                            // this.eventFileUpload = null;
                            this.updateSelectedFile(null, null);
    
                            this.onImportError.emit(e);
                            this._authService.errCheck(err);
                        }
                    });
                } else {
                    this.bol_import_loading = false;
                    this.onImportError.emit(response.content);
                }
            },
            error: err => {
                this.bol_import_loading = false;
                console.log(err);
                let e = JSON.parse(err._body);
                this.onImportError.emit(e);
                this._authService.errCheck(err);
            }
        });
    }
    
    closeDrawer() {
        if(this.eventFileUpload){
            this.bol_confirm_close_modal = true;
        } else {
            this.confirmClose();
        }
    }

    cancelClose() {
        this.bol_confirm_close_modal = false;
    }

    confirmClose() {
        this.bol_confirm_import_modal = false;
        this.bol_confirm_close_modal = false;
        this.onClose.emit();
    }
}
