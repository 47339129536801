import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MeetingsService } from '../services/meetings.service';
import { AuthService } from '../../main/access/services/auth.service';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { MeetingParticipantSearch } from '../services/types/response-types';
import { LiveSearchSelectComponent } from '../../components/live-search-select/live-search-select.component';
import { NotificationService } from '../../main/services/notification.service';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';

type MeetingParticipant = {
  cod_meeting: number;
  cod_user: number;
  str_email: string;
  str_name: string;
  str_img_path: string;
  bol_is_owner: 0 | 1;
  sync_status?: -1 | 0 | 1;
};

@Component({
  standalone: true,
  selector: 'app-meetings-participants-drawer',
  imports: [
    TranslateModule,
    CommonModule, 
    ClbDrawerModule, 
    ClbModalModule,
    LiveSearchSelectComponent
  ],
  templateUrl: './meetings-participants-drawer.component.html',
  styleUrls: ['./meetings-participants-drawer.component.css']
})
export class MeetingsParticipantsDrawerComponent {
  @Input('open') isOpen: boolean = false;
  @Input() cod_meeting: number;

  @Output() closeDrawer = new EventEmitter<any>();

  has_edit_location_meetings_privilege: boolean = false;
  has_meeting_select_participants_privilege: boolean = false;
  obj_user: any;
  current_user_id: number;
  current_user_is_owner: boolean = false;
  meetingParticipants: MeetingParticipant[];

  possibleParticipants: MeetingParticipantSearch[] = [];

  isLoading: boolean = true;
  isLoadingPossibleParticipants: boolean = false;

  isConfirmationModalOpen: boolean = false;
  confirmationModalDescription: string = '';

  currentAction:
    | { actionType: 'remove'; participantId: number; participantIndex: number }
    | { actionType: 'toggleOwner'; participantId: number; currentOwnerValue: 0 | 1; participantIndex: number }
    | null = null;

  private readonly participantsRequest$ = new Subject<void>();


  constructor(
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _meetingsService: MeetingsService,
    private readonly _notificationService: NotificationService
  ) {
    this.obj_user = this._authService.getAuthenticatedUser();
    this.current_user_id = this.obj_user.id;

    this.has_edit_location_meetings_privilege = this._authService.getUserPrivilege('MEETING_EDIT_LOCATION_MEETINGS');
    this.has_meeting_select_participants_privilege = this._authService.getUserPrivilege('MEETING_SELECT_PARTICIPANTS');
  }

  ngOnInit() {
    this.participantsRequest$
      .pipe(
          switchMap(() => {
              return this._meetingsService.getMeetingParticipants(this.cod_meeting, this.current_user_id).pipe(
                  catchError((err) => {
                      this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_GET_PARTICIPANTS').subscribe((translation) => {
                          this.showToast('negative', translation);
                      });
                      console.error(err);
                      this.isLoading = false;
                      return of([]);
                  }),
                  finalize(() => {
                      this.isLoading = false;
                  })
              );
          })
      ).subscribe((data) => {
          const sortedParticipants = this.sortParticipants(
            (data['content']['results'] as MeetingParticipant[]).map((participant) => {
              return {
                ...participant,
                sync_status: 1
              };
          }));
          this.meetingParticipants = sortedParticipants;
          this.setCurrentUserIsOwner();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen?.currentValue) {
      this.isLoading = true;
      this.getParticipantsData();
    }
  }

  ngOnDestroy() {
    this.participantsRequest$.unsubscribe();
  }

  getParticipantsData() {
    this.participantsRequest$.next();
  }

  searchPossibleParticipantsByTerm(term: string) {
    if (!term) {
      this.possibleParticipants = [];
      return;
    }
    
    this.isLoadingPossibleParticipants = true;

    this._meetingsService.getPossibleMeetingParticipantsByTerm(this.current_user_id, term).subscribe({
      next: (data) => {
        this.possibleParticipants = data['content']['results'] as MeetingParticipantSearch[];
      },
      error: (err) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_SEARCH_PARTICIPANTS').subscribe((translation) => {
          this.showToast('negative', translation);
        });
        console.error(err);
      },
      complete: () => {
        this.isLoadingPossibleParticipants = false;
      }
    });
  }

  onAddParticipant(newParticipant: MeetingParticipantSearch) {
    const participant: MeetingParticipant = {
      ...newParticipant,
      bol_is_owner: 0,
      cod_meeting: this.cod_meeting,
      str_email: '',
      sync_status: 0
    };

    if (!this.canEditParticipants()) {
      return this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_NO_PERMISSION').subscribe((translation) => {
        this.showToast('negative', translation);
      });
    }

    if (this.meetingParticipants.some((participant) => participant.cod_user === newParticipant.cod_user)) {
      return this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_USER_ALREADY_PARTICIPANT').subscribe((translation) => {
        this.showToast('negative', translation);
      });
    };

    const meetingParticipantsUpdated = this.sortParticipants([...this.meetingParticipants, participant]);

    this.meetingParticipants = meetingParticipantsUpdated;
    this._meetingsService.postAddMeetingParticipant(this.cod_meeting, this.current_user_id, newParticipant.cod_user).subscribe({
      next: (data) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_PARTICIPANT_ADDED').subscribe((translation) => {
          this.showToast('positive', translation);
        });
        this.getParticipantsData();
      },
      error: (err) => {
        this.meetingParticipants = this.meetingParticipants.filter((participant) => participant.cod_user !== newParticipant.cod_user);

        const errorCode = err?.error?.content?.code;
        const translationString = errorCode.includes('IAL') ? `ERROR.${errorCode}` : 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_ADD_PARTICIPANT';

        this._translateService.get(translationString).subscribe((translation) => {
          this.showToast('negative', translation);
        });
        console.error(err);
      }
    });
  }

  onRemoveParticipant(participantId: number, participantIndex: number) {
    if (participantId === this.current_user_id) {
      return this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.LEAVE_MEETING_CONFIRMATION').subscribe((translation) => {
        this.openConfirmationModal(translation, { actionType: 'remove', participantId, participantIndex });
      });
    }

    this.executeRemoveParticipant(participantId, participantIndex);
  }

  onToggleOwner(participantId: number, currentOwnerValue: 0 | 1, participantIndex: number) {
    if (participantId === this.current_user_id) {
      const confirmationMessage = currentOwnerValue === 1 ? 'GIVE_UP_OWNER_CONFIRMATION' : 'BECOME_OWNER_CONFIRMATION';
      return this._translateService.get(`MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.${confirmationMessage}`).subscribe((translation) => {
        this.openConfirmationModal(translation, { actionType: 'toggleOwner', participantId, currentOwnerValue, participantIndex });
      });
    }

    this.meetingParticipants[participantIndex]['sync_status'] = 0;

    this.executeToggleOwner(participantId, currentOwnerValue, participantIndex);
  }

  openConfirmationModal(
    description: string, 
    action: { 
      actionType: 'remove', participantId: number, participantIndex: number
    } | {
      actionType: 'toggleOwner', participantId: number, currentOwnerValue: 0 | 1, participantIndex: number
    }
  ) {
    this.confirmationModalDescription = description;
    this.isConfirmationModalOpen = true;
    this.currentAction = action;
  }

  onCloseConfirmationModal() {
    this.confirmationModalDescription = '';
    this.isConfirmationModalOpen = false;
    this.currentAction = null;
  }

  onConfirmAction() {
    if (this.currentAction) {
      if (this.currentAction) {
        if (this.currentAction.actionType === 'remove') {
          this.executeRemoveParticipant(this.currentAction.participantId, this.currentAction.participantIndex);
        }

        if (this.currentAction.actionType === 'toggleOwner') {
          this.executeToggleOwner(this.currentAction.participantId, this.currentAction.currentOwnerValue, this.currentAction.participantIndex);
        }
      }

      this.onCloseConfirmationModal();
    }
  }

  executeRemoveParticipant(participantId: MeetingParticipant['cod_user'], participantIndex: number) {
    if (this.meetingParticipants[participantIndex].bol_is_owner === 1 && this.countOwners() === 1) {
      return this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_MEETING_ONE_OWNER').subscribe((translation) => {
        this.showToast('negative', translation);
      });
    };
    const removedParticipant = this.meetingParticipants[participantIndex];
    this.meetingParticipants.splice(participantIndex, 1);
    this._meetingsService.removeMeetingParticipant(this.cod_meeting, this.current_user_id, participantId).subscribe({
      next: (data) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_PARTICIPANT_REMOVED').subscribe((translation) => {
          this.showToast('positive', translation);
        });
        this.getParticipantsData();
      },
      error: (err) => {
        this.meetingParticipants.splice(participantIndex, 0, removedParticipant);

        const errorCode = err?.error?.content?.code;
        const translationString = errorCode.includes('IAL') ? `ERROR.${errorCode}` : 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_REMOVE_PARTICIPANT';

        this._translateService.get(translationString).subscribe((translation) => {
          this.showToast('negative', translation);
        });
        console.error(err);
      }
    });
  }

  executeToggleOwner(
    participantId: MeetingParticipant['cod_user'], 
    currentOwnerValue: MeetingParticipant['bol_is_owner'],
    participantIndex: number
  ) {
    const isOwnerNextValue: MeetingParticipant['bol_is_owner'] = currentOwnerValue === 1 ? 0 : 1;

    if (isOwnerNextValue === 0 && this.countOwners() === 1) {
      return this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_MEETING_ONE_OWNER').subscribe((translation) => {
        this.showToast('negative', translation);
      });
    };

    this.meetingParticipants[participantIndex]['bol_is_owner'] = isOwnerNextValue;

    this._meetingsService.updateMeetingOwner(this.cod_meeting, this.current_user_id, participantId, isOwnerNextValue).subscribe({
      next: (data) => {
        this._translateService.get('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_PARTICIPANT_UPDATED').subscribe((translation) => {
          this.showToast('positive', translation);
        });
        this.getParticipantsData();
      },
      error: (err) => {
        this.meetingParticipants[participantIndex]['sync_status'] = 1;
        this.meetingParticipants[participantIndex]['bol_is_owner'] = currentOwnerValue;

        const errorCode = err?.error?.content?.code;
        const translationString = errorCode.includes('IAL') ? `ERROR.${errorCode}` : 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_UPDATE_PARTICIPANT';

        this._translateService.get(translationString).subscribe((translation) => {
          this.showToast('negative', translation);
        });
        console.error(err);
      }
    });
  }

  onCloseDrawer() {
    this.closeDrawer.emit();
  }

  trackByFn(index: number, participant: MeetingParticipant) {
    return participant.cod_user;
  }

  getButtonTitle(
    actionType: 'toggleOwner' | 'removeParticipant',
    isOwner: MeetingParticipant['bol_is_owner'],
    participantId: MeetingParticipant['cod_user']
  ): string {
    const baseKey = 'MEETINGS_LIST.MEETINGS_DRAWERS.BUTTON_TITLES';
    let translationKey = '';
    if (actionType === 'toggleOwner') {
      if (participantId === this.current_user_id) {
        translationKey = `${baseKey}.GIVE_UP_OWNER`;
      } else if (isOwner === 1 && participantId !== this.current_user_id) {
        translationKey = `${baseKey}.REMOVE_OWNER`;
      } else {
        translationKey = `${baseKey}.MAKE_OWNER`;
      }
    } else if (actionType === 'removeParticipant') {
      translationKey = participantId === this.current_user_id
        ? `${baseKey}.LEAVE_MEETING`
        : `${baseKey}.REMOVE_PARTICIPANT`;
    }

    return translationKey;
  }

  canEditParticipants() {
    return (this.current_user_is_owner || this.has_edit_location_meetings_privilege) && this.has_meeting_select_participants_privilege;
  }
  
  showToast(type: 'positive' | 'negative', description: string) {
    if (type === 'positive') {
      this._notificationService.success(description);
    } else {
      this._notificationService.error(description);
    };
  }
  
  sortParticipants(participants: MeetingParticipant[]) {
    return participants.sort((a, b) => {
      return b.bol_is_owner - a.bol_is_owner || a.str_name.trim().localeCompare(b.str_name.trim());
    });
  }

  countOwners() {
    return this.meetingParticipants.filter((participant) => participant.bol_is_owner === 1).length;
  }

  setCurrentUserIsOwner() {
    this.current_user_is_owner = this.meetingParticipants.some((participant) => {
      return participant.cod_user === this.current_user_id && participant.bol_is_owner === 1
    });
  }
}
