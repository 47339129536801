import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-logbook-topic-4',
  templateUrl: './logbook-topic-4.component.html',
  styleUrls: ['./logbook-topic-4.component.css']
})
export class LogbookTopic4Component implements OnInit {

  constructor(public userPreferencesService:UserPreferencesService) { }

  ngOnInit() {
  }

}
