// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-border {
    border-top: 1px solid #e6e6e6;
    overflow-wrap: break-word;
    padding: 0.5em 0;
    margin: 0;
}

.delete-link {
    color: var(--ui-danger);
    cursor: pointer;
    text-align: end;
}

.hyperlink-listing {
    padding-top: 2px;
    padding-bottom: 2px;
}
    
.hyperlink-listing i {
    padding: 4px;
    background: #f5f5f5;
    border-radius: 4px;
}

.sop-box { 
    padding-top: 20px;
}

.limit-size {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pm-0 {
    margin: 0;
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/logbooks/routines-persist/routines-persist.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":[".link-border {\n    border-top: 1px solid #e6e6e6;\n    overflow-wrap: break-word;\n    padding: 0.5em 0;\n    margin: 0;\n}\n\n.delete-link {\n    color: var(--ui-danger);\n    cursor: pointer;\n    text-align: end;\n}\n\n.hyperlink-listing {\n    padding-top: 2px;\n    padding-bottom: 2px;\n}\n    \n.hyperlink-listing i {\n    padding: 4px;\n    background: #f5f5f5;\n    border-radius: 4px;\n}\n\n.sop-box { \n    padding-top: 20px;\n}\n\n.limit-size {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n}\n\n.pm-0 {\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
