// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
    z-index: 1000000;
}

.modal {
    z-index: 1000001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
}

.modal .container {
    position: relative;
    background: white;
    padding: 1rem;
    border-radius: 10px;
}

.modal .min-size {
    width: auto !important;
    height: auto !important;
}

.modal .container .close {
}

.modal .container .content {

}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/modal.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;AACA;;AAEA;;AAEA","sourcesContent":[".backdrop {\n    z-index: 1000000;\n}\n\n.modal {\n    z-index: 1000001;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n}\n\n.modal .container {\n    position: relative;\n    background: white;\n    padding: 1rem;\n    border-radius: 10px;\n}\n\n.modal .min-size {\n    width: auto !important;\n    height: auto !important;\n}\n\n.modal .container .close {\n}\n\n.modal .container .content {\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
