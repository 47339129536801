import { Choice } from './choice.model';

export enum QuestionType {
  TEXT = 1,
  YES_NO = 2,
  SINGLE_SELECTION = 3,
  MULTI_SELECTION = 4,
  DATE = 5,
  LS_USER = 6,
  LS_MAINTENANCE_PLAN = 7,
  LS_AREA = 8,//DEPARTMENT
  LS_SUBAREA = 9,//AREA
  LS_EQUIPMENTS = 10,
  LS_PILLARS = 11,
  LS_WORLDS = 12,
  LS_REGIONAL = 13,
  LS_LOCATIONS = 14,
  LS_INDICATORS = 15,
  NUMBER = 16,
  LS_MACHINES = 17,
}

export class Question {
  cod_form_question: number;
  cod_forms_category: number;
  cod_form_question_choice?: number;
  cod_answer_question_choice?: number;
  int_order: number;
  int_type: QuestionType;
  answer?: string;
  str_answer_text?: string;
  question_description?: string;
  str_text: string;
  str_text_de?: string;
  str_text_es?: string;
  str_text_fr?: string;
  str_text_nl?: string;
  str_text_pt?: string;
  str_text_ru?: string;
  str_text_ua?: string;
  str_text_zh?: string;
  str_text_ko?: string;
  str_text_vi?: string;
  bol_creates_action: boolean;
  bol_optional?: boolean;
  arr_choices?: Array<Choice>;
  selected_options?: Array<Choice>;
}
