import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';

@Component({
    selector: 'component-display-warning',
    templateUrl: './display-warning.component.html',
    styleUrls: ['./display-warning.component.css'],
})
export class DisplayWarningComponent {

    @Input() location;    
    displayMessage: any;
    bol_show_warning: boolean = true;
    date = new Date();
    breakLines: boolean = false;
    showMessage: boolean = false;
    title: string;
    constructor(private _authService:AuthService) { 
        
        
    }
    ngOnInit(){        
        this.bol_show_warning = (this.date.getTime() - JSON.parse(localStorage.getItem('closedWarning')) > 1000*3600*24);
        
        let params = {
            arr_privileges: [0],
        }

        this._authService.displayWarning(params).subscribe( data =>{            
            const { str_description, bol_show_not_logged, str_title } = data.content.results;                        
            this.title = str_title;            
            
            if (this.location === 'not-logged' && bol_show_not_logged){
                this.showMessage = true;                                
            } else if (this.location === 'logged' && !bol_show_not_logged) {                                
                this.showMessage = true;
            } else {                
                this.showMessage = false;
            }
            
            //TO CHECK IF IS A LIST AND SPLIT INTO ARRAY DO SHOW DIFF ON MODAL
            if (!!str_description && str_description.indexOf('*') !== -1) {                                
                const arr_description = str_description.split('*');
                //REMOVE THE FIRST LINE, BECAUSE ALWAYS THE MESSAGE START WITH *
                arr_description.shift();                
                this.displayMessage = arr_description;
                this.breakLines = true;                
                return;        
            }

            this.displayMessage = str_description;
        }, err => {
            const e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        } ); 
        
        
        
    }

    closeWarning(){
        this.bol_show_warning = false;
        localStorage.setItem('closedWarning', ''+ this.date.getTime());
        
    }
}