import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import {ActionsService} from '../../meetings/services/actions.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
  host:{
    '(document:click)': 'onClickOutside($event)',
  },
  selector: 'chart-actions',
  templateUrl: './chart-actions.component.html',
  styleUrls: ['./chart-actions.component.css']
})
export class ChartActionsComponent implements OnChanges, AfterViewInit{
  @ViewChild('legendicon')
  container: ElementRef;

  @Input() date;
  @Input() cod_user = this._authService.getAuthenticatedUser().id;
  @Input() cod_meeting;
  @Input() cod_manager;
  @Input() chart_data_param=[];
  @Input() chart_title;
  @Input() updateChart;
  @Input() has_hint;
  @Input() chart_center;

  chart_options: any;
  chart_actions_instance: any;
  chart_data = [];
  options: Object;
  bol_loading: boolean;
  public bol_show_hint_action = false;

  constructor(private _actionsService:ActionsService, private _authService: AuthService) {
    this.bol_loading = true;

    this.chart_options = {
      title: {
        text: ``,
      },
      tooltip: {
        trigger: "item",
        formatter: `{b}`,
      },
    };

  }

  ngOnInit(){
    this.bol_show_hint_action = !this.has_hint;
    this.updateInfoChart();
  }

  ngOnChanges(changes: SimpleChanges):void {
    if(changes.chart_data_param){
      if(changes.chart_data_param.currentValue != changes.chart_data_param.previousValue){
        this.updateInfoChart();
      }
    }
  }

  updateInfoChart() {
    this.bol_loading = true;
    if(this.cod_meeting || this.cod_manager) {
      const dataParam = this.chart_data_param?.map(i => {
        return {
          name: `${i.percent}%`, 
          value: i.percent,
          color: i.color, 
          itemStyle: {
            color: i.color, 
          },
          cod_actions_status: i.cod_actions_status,
        };
      })
      this.updateSeriesData(dataParam);
    } else  {
      this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
      this._actionsService.getCountActionsUser(this.date, this.cod_user).subscribe(
        data => {
          if (data.code === 888 && data.content.code === 888) {
            let dataParam = [];

            const total = data.content.results.reduce((sum, i) =>  
                (i.cod_actions_status == 1 || i.cod_actions_status == 2 || i.cod_actions_status == 8) 
                ? sum + parseInt(i.counter)
                : sum
            , 0);

            data.content.results.forEach(element => {
              if (element.cod_actions_status == 1 || element.cod_actions_status == 2 || element.cod_actions_status == 8) {
                let color =
                  ((element.cod_actions_status == 1) ? '#949494' :
                    ((element.cod_actions_status == 2) ? '#fdb849' :
                      ((element.cod_actions_status == 8) ? '#fc4e53' : undefined)));

                let percent = Math.round( (((parseInt(element.counter) * 100) / total) * 100 ) / 100);

                dataParam.push({
                    name: `${percent}%`, 
                    value: percent,
                    color: color, 
                    itemStyle: {
                      color: color, 
                    },
                    cod_actions_status: element.cod_actions_status,
                });
              }
            });
            this.updateSeriesData(dataParam);
          } else {
            console.log(data.content);
          }
        },
        err => { this._authService.errCheck(err); console.log(err); }
        
      );
    }
  }

  updateSeriesData(data) {

    if (data) {
      this.chart_data = data;
      this.bol_loading = false;
    }

    if (!this.chart_actions_instance) return;

    setTimeout(() => {

      this.chart_actions_instance.setOption({
        series: [
          {
            data: this.chart_data,
            type: "pie",
            radius: '50%',
            label: {
                show: true,
                position: 'outside',
                fontWeight: 'bold',
                fontSize: 10,
            },
            labelLine: {
                show: true,
                length: 2,
                length2: 2,
            },
          },
        ],
        color: this.chart_data.map(i => i.color),
      }, {
        replaceMerge: ['series'],
        lazyUpdate: true,
      });

    }, 50);

  }

  ngAfterViewInit() {
    // this.updateInfoChart();
  }

  saveInstance(chartInstance) {
    this.chart_actions_instance = chartInstance;
    this.updateInfoChart();
  }

  onClickOutside(event){
    if(this.container){
      if(!this.container.nativeElement.contains(event.target)){
        this.bol_show_hint_action = false;
      }
    }
  }
}