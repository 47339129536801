import {
  GetFivewhysHandoverResultModel,
  GetFivewhysHandoverModel,
} from "./../model/cockpit-get-fivewhys-handover-info.model";
import { Injectable } from "@angular/core";
import { GetHandoverNotesByShiftModel } from "../model/cockpit-get-handover-notes-by-shift.model";
import { SetHandoverNotesEditingModel } from "../model/cockpit-set-handover-notes-editing.model";
import { UpdateHandoverNotesModel } from "../model/cockpit-update-handover-notes.model";
import { MainService } from "./main.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import CockpitFilter from "../model/cockpit-filter.model";
import { catchError, distinctUntilChanged, filter, map } from "rxjs/operators";
import { AuthService } from "app/main/access/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { datadogRum } from "@datadog/browser-rum";

type EventBusEventType = "FILTER_LOADED";

export type GetChartDataResponse = {
  title: string;
  data: echarts.EChartsOption;
  errorMessages: ErrorMessages;
  config: {
    datasetid: string;
    erroronparent: boolean;
    externalreportid: string;
    pagename: string;
    refreshtime: number;
    reportid: number;
    reporttitle: string;
    reporttype: string;
    showreport: boolean;
    styleid: number;
  };
};

export type BackendError = {
  type: string;
  content: string;
  errorMessages?: ErrorMessages;
};

export type LANGUAGES = "de" | "en" | "es" | "fr" | "nl" | "pt" | "ru" | "ua";
export type ERROR_TYPE =
  | "COCKPIT.FUTURE_SHIFT"
  | "COCKPIT.GENERIC_ERROR"
  | "COCKPIT.INVALID_DATE"
  | "COCKPIT.INVALID_FILTERS"
  | "COCKPIT.MISSING_FILTERS"
  | "COCKPIT.NO_DATA_FOR_FILTERS"
  | "COCKPIT.NO_DATA_FOR_SHIFT"
  | "COCKPIT.MALFORMED_RESPONSE";

export type ErrorMessages = {
  [key in LANGUAGES]: ErrorTranslated;
};

export type ErrorTranslated = {
  [key in ERROR_TYPE]: string;
};

@Injectable()
export class CockpitService {
  public cockpitFilters = new BehaviorSubject(new CockpitFilter());

  public shouldOffload: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public eventBus = new Subject<{
    kind: EventBusEventType;
    data: unknown;
  }>();

  public subscribeToEventBus<T>(kind: EventBusEventType): Observable<T> {
    return this.eventBus.pipe(
      filter((event) => event.kind === kind),
      map((event: { kind: EventBusEventType; data: T }) => event.data)
    );
  }

  constructor(
    private _mainService: MainService,
    private _authService: AuthService,
    private _translateService: TranslateService
  ) {
    this._translateService.onLangChange.subscribe((event) => {
      const { lang } = event;
      const updatedFilter = CockpitFilter.fromPartial({
        ...this.cockpitFilters.value,
        language: lang,
      });
      this.cockpitFilters.next(updatedFilter);
    });
  }

  public updateOffload(value: boolean): void {
    this.shouldOffload.next(value);
  }

  public getHandoverNotesByShift(params: GetHandoverNotesByShiftModel) {
    const path = "/get-handover-notes-by-shift";
    return this._mainService.post(path, params).pipe(distinctUntilChanged());
  }

  public setHandoverNotesEditing(params: SetHandoverNotesEditingModel) {
    const path = "/set-handover-notes-editing";
    return this._mainService.post(path, params).pipe(distinctUntilChanged());
  }

  public updateHandoverNotes(params: UpdateHandoverNotesModel) {
    const path = "/update-handover-notes";
    return this._mainService.post(path, params).pipe(distinctUntilChanged());
  }

  public getArrayContent(data: any) {
    if (!data || typeof data == typeof undefined) return [];
    return data.content.length >= 0 && !Array.isArray(data.content.results)
      ? [data.content.results]
      : data.content.results;
  }

  public getRefreshRate(): Observable<{ refreshInMilliseconds: number }> {
    return this._mainService
      .get("/get-cockpit-refresh-time")
      .pipe(distinctUntilChanged());
  }

  public getChartData(
    filters: CockpitFilter,
    reportName: string
  ): Observable<GetChartDataResponse | BackendError> {
    const { id: cod_user } = this._authService.getAuthenticatedUser();
    const path = `/report-data`;
    return this._mainService.post(path, { filters, cod_user, reportName }).pipe(
      distinctUntilChanged(),
      catchError((err) => {
        datadogRum.addError(err);
        return [
          {
            content: "Error loading data",
            type: "error",
          } as BackendError,
        ];
      })
    );
  }

  public async getLabels() {
    const labels = [
      "5WHY_REPORTS.STR_LOCATION",
      "5WHY_REPORTS.STR_AREA",
      "5WHY_REPORTS.STR_SUBAREA",
      "5WHY_REPORTS.STR_EQUIPMENT",
      "5WHY_REPORTS.STR_MACHINE",
      "GENERAL.LOGBOOK",
      "5WHY_REPORTS.STR_DATE",
      "5WHY_REPORTS.STR_SHIFT",
    ];

    const keys = [
      "locations",
      "areas",
      "subareas",
      "equipments",
      "machines",
      "checklists",
      "selectedDate",
      "shift",
    ];

    const result: Record<string, string> = await this._translateService
      .get(labels)
      .toPromise();

    const labelsResult = new Map<string, string>();

    for (let i = 0; i < labels.length; i++) {
      labelsResult.set(keys[i], result[labels[i]]);
    }

    return labelsResult;
  }

  public async getErrorLabels() {
    const keys = [
      "COCKPIT.SHIFT_DIDNT_START_YET",
      "COCKPIT.ERROR_MISSING_FILTERS",
      "ERROR.GENERIC_ERROR",
    ];

    const result: Record<string, string> = await this._translateService
      .get(keys)
      .toPromise();

    const labelsResult = new Map<string, string>();

    for (let i = 0; i < keys.length; i++) {
      labelsResult.set(keys[i], result[keys[i]]);
    }

    return labelsResult;
  }

  public getFivewhysHandoverInfo(
    params: GetFivewhysHandoverModel
  ): Observable<{ results: GetFivewhysHandoverResultModel[] }> {
    const path = "/get-fivewhys-handover-info";
    return this._mainService.post(path, params).pipe(distinctUntilChanged());
  }

  public getFormsHandoverInfo(
    params: any
  ): Observable<{ results: any[] }> {
    const path = "/get-forms-handover-info";
    return this._mainService.post(path, params).pipe(
      map((res) => (res.content ? res.content : res)),
      distinctUntilChanged()
    );
  }

  public listShiftHandoverNotes(params: {
    term: string;
    limit: number;
    offset: number;
    cod_user: number;
    dsc_page_privilege: "ADMIN_LIST_CREW";
  }) {
    const path = "/list-shift-handover-templates";
    return this._mainService.post(path, params);
  }

  public getShiftHandoverNotes(params: {
    cod_location: number;
    cod_area: number;
    cod_subarea: number;
  }) {
    const path = "/get-shift-handover-notes-template";
    return this._mainService.post(path, params);
  }

  public saveShiftHandoverNotes(params: {
    cod_location: number;
    cod_area: number;
    cod_subarea: number;
    template: string;
  }) {
    const path = "/save-shift-handover-notes-template";
    return this._mainService.post(path, params);
  }
}
