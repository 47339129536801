import {Component, OnInit} from '@angular/core';
import { AuthService } from '../access/services/auth.service';
import { ActionsService } from 'app/meetings/services/actions.service';
import { RoutinesService } from 'app/logbooks/services/routines.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  authenticatedUserName: any;
  codUser: number;
  greetingParam = { name: '' }

  pendingChecklists: number = 0
  pendingActions: number = 0

  constructor(private _authService: AuthService, private _actionsService: ActionsService, private _routinesService: RoutinesService) { }

  ngOnInit() {
    const user = this._authService.getAuthenticatedUser()
    this.authenticatedUserName = user.name;
    this.codUser = user.id;
    this.greetingParam.name = this.authenticatedUserName;

    this._routinesService.getCountRoutinesWeek(this.codUser).subscribe({
      next: data => {
        this.pendingChecklists = data?.content?.results?.count_routines ?? 0
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });

    this._actionsService.getCountActionsUserWeek(this.codUser).subscribe({
      next: data => {
        this.pendingActions = data?.content?.results?.count_actions ?? 0
      },
      error: err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });

  }

}
