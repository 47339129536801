import { EChartsOption } from "echarts";
import { TranslateService } from "@ngx-translate/core";
import {Component, Input, OnInit, SimpleChanges, } from "@angular/core";

@Component({
  selector: "app-completion-time-chart",
  templateUrl: "./completion-time-chart.component.html",
  styleUrls: ["./completion-time-chart.component.css"]
})
export class CompletionTimeChartComponent implements OnInit {
  chartOptions: any;

  @Input("data") data: any;
  @Input("isLoading") isLoading: boolean;

  hoursTranslate: string;
  weeksTranslate: string;
  echartsInstance: any;
  chartTitleTranslation: string;

  constructor(private _translateService: TranslateService) {
    this._translateService.get("GENERAL.HOURS").subscribe((translation) => {
      this.hoursTranslate = translation;
    });
    this._translateService.get("GENERAL.WEEK").subscribe((translation) => {
      this.weeksTranslate = translation;
    });
    this._translateService
      .get("5WHY.COMPLETION_TIME_CHART_TITLE")
      .subscribe((translation) => {
        this.chartTitleTranslation = translation;
      });
  }

  ngOnInit(): void {
    this.chartOptions = {
      title: {
        text: `${this.chartTitleTranslation}`,
        x: 'center',
        top: 20,
        textStyle: {
          fontSize: 15
        }
      },
      xAxis: {
        type: "category",
        data: this.data.map((week) => `${this.weeksTranslate} ${week.str_week}`),
        show: false,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          margin: 2,
          fontSize: 10
        }
      },
      dataZoom: [
        {
            type: 'inside',
            show: true,
            xAxisIndex: [0],
            start: 1,
            end: 100
        },
      ],
      tooltip: {
        trigger: "item",
        formatter: `{b} - {c} ${this.hoursTranslate}`,
      },
      series: [
        {
          data: this.data.map((week) =>
            week.str_completion_time_seconds == null
              ? 0
              : Math.round((week.str_completion_time_seconds / 3600))
          ),
          type: "line",
        },
      ],
      animation: true,
    };
  }

  onChartInit(instance) {
    this.echartsInstance = instance;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.echartsInstance) { 
      this.echartsInstance.setOption({
        series: [
          {
            data: this.data.map((week) =>
              week.str_completion_time_seconds == null
                ? 0
                : Math.round((week.str_completion_time_seconds / 3600))
            ),
            type: "line",
          },
        ],
        xAxis: {
          type: "category",
          data: this.data.map((week) => `${this.weeksTranslate} ${week.str_week}`),
          show: false,
        },
      });
    }
  }
}
