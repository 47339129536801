import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'forms-input-label',
    templateUrl: './forms-input-label.component.html',
    styleUrls: ['./forms-input-label.component.css']
})

export class FormsInputLabelComponent {

    @Input() label: string = null;
    @Input() isLoading: boolean = false;
    @Input() isOptional: boolean = false;

    constructor() { }

}
