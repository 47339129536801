import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class EmailModalService {

    private showModalSource = new BehaviorSubject<boolean>(false);
    public showModal = this.showModalSource.asObservable();

    private modalActionSource = new BehaviorSubject<[]>(null);
    public modalAction = this.modalActionSource.asObservable();


    private searchUsersSource = new BehaviorSubject<any>(null);
    public searchUser = this.searchUsersSource.asObservable();

    constructor(){

    }

    closeModal() {
        this.showModalSource.next(false)
    }

    openModal() {
        this.showModalSource.next(true)
    }

    sendEmail(users_list) {
        this.modalActionSource.next(users_list)
    }

    searchForUsers(callback, str_term) {
        this.searchUsersSource.next({callback:callback, str_term: str_term});
    }

    resetModal() {
        this.showModalSource.next(false);
        this.modalActionSource.next(null);
        this.searchUsersSource.next(null);
    }

}