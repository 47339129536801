import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RoutinesService } from './../services/routines.service';
import { RoutineStatus } from '../models/routine_status.model';
import { Routine, RoutineType } from '../models/routine.model';
import { RoutineDataService } from '../services/routine-data.service';

@Component({
    selector: 'routine-status-buttons',
    templateUrl: './routine-status-buttons.component.html',
    styleUrls: ['./routine-status-buttons.component.css']
})
export class RoutineStatusButtonsComponent implements OnInit, OnChanges {

    @Output() onChangeStatus: EventEmitter<number> = new EventEmitter();

    @Input() routinesStatusList: Array<RoutineStatus>;
    @Input() routineStatus: number = null;
    @Input() disabled: boolean = false;
    
    routineType: RoutineType = null;
    isFutureDue: boolean = false;
    isAsNeeded: boolean = false;
    isMarkDone: boolean = false;
    routine: any;
    statusButtons = {
        not_done: false,
        done: false,
        pending: false,
        avaiable: false,
        not_avaiable: false
    };

    constructor(
        private _dataService: RoutineDataService,
        private _routinesService: RoutinesService
    ) {
        this._dataService.isAsNeeded.subscribe(x => this.isAsNeeded = x);
        this._dataService.isFutureDue.subscribe(x => this.isFutureDue = x);
        this._dataService.routineType.subscribe(x => {
            this.routineType = x; 
            this.getStatusList();
            this.isMarkDone = this.routineType == RoutineType.MarkDone;
        });
        this._dataService.routine.subscribe(x => this.routine = x);
    }

    ngOnInit() {
        this.getStatusList();
        this.isMarkDone = this.routineType == RoutineType.MarkDone;  
    }

    ngOnChanges() {
        this.getStatusList();
        this.isMarkDone = this.routineType == RoutineType.MarkDone;
    }

    public isShowStatusButton(cod_status): boolean {
        switch(parseInt(cod_status)) {
            case RoutinesService.STATUS_NOT_DONE:
                return this.statusButtons.not_done;
            case RoutinesService.STATUS_DONE:
                if(!this.isFutureDue){
                    return this.statusButtons.done;
                }  
                break;
            case RoutinesService.STATUS_PENDING:
                return this.statusButtons.pending;
            case RoutinesService.STATUS_AVAIABLE:
                if(!this.isFutureDue){
                    return this.statusButtons.avaiable;
                }    
                break;
            case RoutinesService.STATUS_NOT_AVAIABLE:
                return this.statusButtons.not_avaiable;
        };

        return false;
    }

    public getStatusClass(status) : string {
        return this._routinesService.getStatusButtonClass2(parseInt(status), this.routineStatus, this.isAsNeeded);
    }

    public changeRoutineStatus(status: number) : void {
        const statusRoutine = status == 2 && this.routine?.cod_routine_status == 2 && !this.isAsNeeded && this.routine.qtd_dias < 8 ? status = 3 : status;        
        this.onChangeStatus.emit(statusRoutine);
    }

    private updateStatusButtons(obj) : void {
        this.statusButtons = {
            ...this.statusButtons,
            ...obj
        };
    }

    private getStatusList() {     
        this.statusButtons = {
            not_done: false,
            done: false,
            pending: false,
            avaiable: false,
            not_avaiable: false
        };

        switch(this.routineType) {
            case RoutineType.Form:
                this.statusForm();
                break;
            case RoutineType.Acadia:
                this.statusAcadia();
                break;
            case RoutineType.MarkDone:
                this.statusMarkDone();
                break;
        }
    }

    private statusForm() : void {
        switch(this.routineStatus) {
            case RoutinesService.STATUS_NOT_DONE:
                this.updateStatusButtons({ not_done: true });
                break;
            case RoutinesService.STATUS_DONE:
                this.updateStatusButtons({ done: true });
                break;
            case RoutinesService.STATUS_NOT_AVAIABLE:
                this.updateStatusButtons({ not_avaiable: true });
                break;
        }
    }

    private statusAcadia() : void {
        switch (true) {
            case this.isStatusNotDone():
                this.updateStatusButtons({ not_done: true });
                break;
            case this.isStatusDone():
                this.updateStatusButtons({ done: true });
                break;
            case this.isStatusPending():
                this.updateStatusButtons({ pending: true });
                break;
        }
    }

    private statusMarkDone() : void {
        switch (true) {
            case this.isStatusNotDone():
                this.updateStatusButtons({ not_done: true });
                break;

            case this.isStatusDone():
                this.updateStatusButtons({ done: true });
                break;
            
            case this.isStatusPending():
                this.updateStatusButtons({ done: true, pending: true });
            break;

            case this.isStatusAvaiable():
                this.updateStatusButtons({ done: true, avaiable: true });
                break;

            case this.isStatusNotAvaiable():
                this.updateStatusButtons({ not_avaiable: true });
                break;
        }
    }

    private isStatusNotDone(): boolean {
        return (this.routineStatus == RoutinesService.STATUS_NOT_DONE);
    }

    private isStatusDone(): boolean {
        return (this.routineStatus == RoutinesService.STATUS_DONE);
    }

    private isStatusPending(): boolean {
        return (this.routineStatus == RoutinesService.STATUS_PENDING);
    }

    private isStatusAvaiable(): boolean {
        return (this.routineStatus == RoutinesService.STATUS_AVAIABLE);
    }

    private isStatusNotAvaiable(): boolean {
        return (this.routineStatus == RoutinesService.STATUS_NOT_AVAIABLE);
    }

}
