import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from '../../five-why/services/main.service';

@Injectable()
export class EquipmentTypesService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all equipments types.
     * @param param: JSON object filled with search "term" or "id"
     */
     getEquipmentsList(param): Observable<any> {
        let path = '/equipments?cod_user=' + param.cod_user + '&term=' + param.term;
        return this._mainService.get(path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

}
