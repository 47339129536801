import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { KpisService } from '../services/kpis.service';
import { SelectPagination } from '../../logbooks/models/select_pagination.model';
import PaginationHelper from '../../helpers/pagination-helper';

@Component({
  selector: 'app-kpis-consult',
  templateUrl: './kpis-consult.component.html',
  styleUrls: ['./kpis-consult.component.css'],
})
export class KpisConsultComponent implements AfterViewInit {

  kpis: Object[];
  searchTerm: string;
  searchTimer: any;
  pageIndex: number;
  totalPages: number[];
  resultsPerPage: SelectPagination[];
  resultsPerPageSelected: number;
  resultsPerPageSelectOption: SelectPagination[];
  maxPage: number;
  isLoading: boolean = true;
  codUser: number;
  showError: boolean = false;
  errorMessage: string = '';

  constructor(
    public kpisService: KpisService,
    private readonly _authService: AuthService,
    private readonly _translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef) {
    this.searchTerm = '';
    this.showError = false;
    this.errorMessage = '';
    this.kpis = [];

    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.resultsPerPage = [
        new SelectPagination(10, `10 ${translate}`),
        new SelectPagination(50, `50 ${translate}`),
        new SelectPagination(100, `100 ${translate}`),
      ];
    });

    this.pageIndex = 1;
    this.totalPages = [];

    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
    this.resultsPerPageSelected = this.resultsPerPage[0].qty;

    this.searchTimer = null;
    this.codUser = this._authService.getAuthenticatedUser().id;
  }

  ngAfterViewInit(): void {
    this._search();
    this.cdr.detectChanges();
  }

  private _search(): void {
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }

    let objParam = {
      'term': this.searchTerm,
      'size': this.resultsPerPageSelected,
      'page': tmpPage,
      'cod_user': this.codUser
    };

    this.kpisService.getKpisList(objParam).subscribe({
      next: (data) => {
        this.kpis = this.kpisService.getArrayContent(data);
        this.isLoading = false;
        this.maxPage = data.content.totalPages;
        this.totalPages = PaginationHelper.pagination(this.pageIndex, this.maxPage);
      },
      error: err => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    });
  }

  public search(): void {
    if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
      return;
    }

    clearTimeout(this.searchTimer);
    let $this = this;
    this.searchTimer = setTimeout(function () {
      $this._search();
    }, environment().debounceTime);
  }

  selectionChanged($event): void {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  changePage(page: number): void {
    this.pageIndex = page;
    this.search();
  }

  closeErrorMessage(): void {
    this.showError = false;
    this.errorMessage = '';
  }

  private _showErrorMessage(err): void {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }
}
