import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { AreaService } from '../services/area.service';
import { SubareaService } from '../services/subarea.service';
import { LogbooksService } from '../services/logbooks.service';
import { UsersService } from 'app/admin/services/users.service';
import { AuthService } from 'app/main/access/services/auth.service';
import { LogbookRoutineService } from '../services/logbook-routine.service';
import { emptyDefaultValue, implodeValues } from 'app/helpers/general-helper';
import { ValidationsService } from '../services/validations/validations.service';
import { NotificationService } from 'app/main/services/notification.service';
import { ReportsService, ReportType } from '../services/reports.service';


@Component({
    selector: 'app-reports-routine-online',
    templateUrl: './reports-routines-online.component.html',
    styleUrls: ['./reports-routines-online.component.css']
})
export class ReportsRoutineOnlineComponent implements OnInit, AfterViewInit {
    

    // ViewChild do component de collab e funcional para chmar o search de cada tabela.
    @ViewChild('collabReportElement') collabReportElement: any;
    @ViewChild('functionalReportElement') functionalReportElement: any;
    
    arr_checklists = [];
    @Input('isCollab') isCollab = true;

    //Filter Options List
    userList: any = [];
    areaList: any = [];
    worldList: any = [];
    shiftsList: any = [];
    logbookList: any = [];
    regionalList: any = [];
    subAreasList: any = [];
    locationsList: any = [];
    typeChecklist: any = [];
    //--------------------//

    reportsParams =  {
        logbooks: null,
        shifts: null,
        subareas: null,
        locations: null,
        users: null
    }

    today = new Date();
    initial_date = moment(this.today).format('YYYY-MM-DD');
    final_date = moment(this.today).format('YYYY-MM-DD');


    showUsers: boolean;
    showShifts: boolean;
    bol_isCollab: boolean = false;
    bol_show_checklist: boolean = false;

    paramsSelected: any = [];
    loadingConfig: any = [];

    loggedUserInfos = this._authService.getAuthenticatedUser();

    cod_user: number = null; 
    user_filters: any = null;

    constructor(
        private _authService: AuthService, 
        private _areaService: AreaService, 
        private _logbookService: LogbooksService, 
        private _userService: UsersService, 
        private _translateService: TranslateService, 
        private _logbookSubAreaService: SubareaService, 
        private _logbookRoutinesService: LogbookRoutineService,
        private _notify: NotificationService,
        private _reportService: ReportsService,
        public validationService: ValidationsService, 
    ) { 
        this.cod_user = this._authService.getAuthenticatedUser().id;
    }

    ngOnInit() {
        this.fillChecklistTypeOptions();
        this.getReportFilter();
    }

    ngAfterViewInit(): void {
        this.resetFilters();
        
        //Load filters
        this._searchWorlds(null);
    }

    dateValidation(type) {
        const rangeExceedMonth = moment(this.initial_date).diff(moment(this.final_date), 'months', true);

        if(rangeExceedMonth > 1 || rangeExceedMonth < -1) {
            switch(type){
                case 'end':
                    if(rangeExceedMonth > 1) { 
                        this.initial_date = this.final_date;
                    } else {
                        this.initial_date = moment(this.final_date).subtract('1', 'M').format('YYYY-MM-DD');
                    }
                break;
                case 'init': {
                    if(rangeExceedMonth < -1) {
                        this.final_date = moment(this.initial_date).add('1', 'M').format('YYYY-MM-DD');
                    } else { 
                        this.final_date = this.initial_date;
                    }
                }
            }
        }
    }
   
    searchReports() {

        this.reportsParams.logbooks = implodeValues(this.paramsSelected['logbooks_selected'], 'cod_logbooks');
        this.reportsParams.locations = implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        this.reportsParams.subareas = implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');

        if (this.reportsParams.locations == '') {
            this._notify.warning('Location is required');
            return;
        }
        if (this.reportsParams.subareas == '') {
            this._notify.warning('Area is required');
            return;
        }
        if (implodeValues(this.paramsSelected['type_selected'], 'bol_is_collab') == '') {
            this._notify.warning('Checklist type is required');
            return;
        }
        
        this.saveReportFilter();

        if(this.bol_isCollab) {
            this.reportsParams.shifts = implodeValues(this.paramsSelected['shifts_selected'], 'cod_shift');

            setTimeout(() => this.collabReportElement.searchReports());
            
        } else {
            this.reportsParams.users = implodeValues(this.paramsSelected['users_selected'], 'cod_id');

            setTimeout(() => this.functionalReportElement.searchReports());
        }
    }

    getReportFilter() {
        this._clearWorldList();

        this._reportService.getReportFilter(this.cod_user, ReportType.ROUTINE_ONLINE).subscribe(data => {

            if (data.code === 888 && data.content.code === 888) {
                if (data.content.length > 0) {
                    this.user_filters = data.content.results;
                }
            }

            this.prepareFilters();
        }, (err) => {
            this.prepareFilters();  
        });
    }

    saveReportFilter() {
        const params = {
            cod_user: this.cod_user,
            str_report: ReportType.ROUTINE_ONLINE,
            arr_cod_world: this.paramsSelected['worlds'],
            arr_cod_regional: this.paramsSelected['regionals'],
            arr_cod_location: this.paramsSelected['locations'],
            arr_cod_area: this.paramsSelected['areas'],
            arr_cod_subareas: this.paramsSelected['subareas'],
        };

        this._reportService.saveReportFilter(params).subscribe();        
    }
    
    selectWorlds($event) {
        this.paramsSelected['worlds_selected'] = $event.selectedOptions;
        this.paramsSelected['worlds'] = implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = implodeValues($event.selectedOptions, 'cod_location');
        this._searchAreas(null);
        this._searchUsers();
    }

    selectAreas($event) {
        this.paramsSelected['areas_selected'] = $event.selectedOptions;
        this.paramsSelected['areas'] = implodeValues($event.selectedOptions, 'cod_area');
        this._searchSubAreas(null)
    }

    selectSubAreas($event) {
        this.paramsSelected['subareas_selected'] = $event.selectedOptions;
        this.paramsSelected['subareas'] = implodeValues($event.selectedOptions, 'cod_subareas');

        this._searchLogbooks(false);
    }
    
    selectChecklistType($event) {
        this.paramsSelected['type_selected'] = $event.selectedOptions;
        this.paramsSelected['type'] = implodeValues($event.selectedOptions, 'bol_is_collab');
        if (this.paramsSelected['type'] == 1) {// 1 - collab, 0 - functional
            this.bol_isCollab = true;
        } else {
            this.bol_isCollab = false;
        }
        this._searchLogbooks(false);
    }

    selectLogbooks($event) {
        this.paramsSelected['logbooks_selected'] = $event.selectedOptions;
        this.paramsSelected['logbooks'] = implodeValues($event.selectedOptions, 'cod_logbooks');
        if (!this.bol_isCollab) {
            this._searchUsers();
            this.showUsers = emptyDefaultValue(this.paramsSelected['logbooks'], null);
        } else { 
            this._searchShifts();
            this.showShifts = emptyDefaultValue(this.paramsSelected['logbooks'], null);
        }
    }

    selectShifts($event) {
        this.paramsSelected['shifts_selected'] = $event.selectedOptions;
        this.paramsSelected['shifts'] = implodeValues($event.selectedOptions, 'cod_shifts');
    }

    selectUsers($event) {
        this.paramsSelected['users_selected'] = $event.selectedOptions;
        this.paramsSelected['users'] = implodeValues($event.selectedOptions, 'cod_id');
    }

    /// Private Functions ///

    private fillChecklistTypeOptions() {
        this.typeChecklist = [];
        this._translateService.get('LOGBOOK.FUNCTIONAL').subscribe(translate => {
            this.typeChecklist.push({ bol_is_collab: 0, str_description: translate });
        });
        this._translateService.get('LOGBOOK.COLLAB').subscribe(translate => {
            this.typeChecklist.push({ bol_is_collab: 1, str_description: translate });
        });
    }

    private resetFilters() {
        this.areaList = [];
        this.logbookList = [];
        this.worldList = [];
        this.regionalList = [];
        this.locationsList = [];
    }

    private prepareFilters() {
        let $this = this;

        $this._searchWorlds(function () {
            $this.paramsSelected['worlds_selected'] = [];
            $this.worldList.map((world) => {

                $this.paramsSelected['worlds'] = $this.user_filters?.arr_cod_world || $this.loggedUserInfos.cod_world;

                if ($this.paramsSelected['worlds'] == world.cod_locations_world) {
                    $this.paramsSelected['worlds_selected'].push({ cod_locations_world: world.cod_locations_world });
                }
            });
            $this._searchRegionals(function () {
                // $this.paramsSelected['regionals_selected'] = [];
                $this.paramsSelected['regionals'] = $this.user_filters?.arr_cod_regional || $this.loggedUserInfos.cod_regional;

                $this.regionalList.map((regional) => {
                    if ($this.paramsSelected['regionals'] == regional.cod_locations_regional) {
                        $this.paramsSelected['regionals_selected'].push({ cod_locations_regional: regional.cod_locations_regional });
                    }
                });
                $this._searchLocations(function () {
                    $this.paramsSelected['locations'] = $this.user_filters?.arr_cod_location || $this.loggedUserInfos.cod_location;
                    $this.locationsList.map((location) => {
                        if ($this.paramsSelected['locations'] == location.cod_location) {
                            $this.paramsSelected['locations_selected'].push({ cod_location: location.cod_location });
                        }
                    });
                    $this._searchAreas(function () {
                        $this.paramsSelected['areas'] = $this.user_filters?.arr_cod_area || $this.loggedUserInfos.cod_area;
                        $this.areaList.map((area) => {
                            if ($this.paramsSelected['areas'] == area.cod_area) {
                                $this.paramsSelected['areas_selected'].push({ cod_area: area.cod_area });
                            }
                        });
                       
                        $this._searchSubAreas(function () {
                            $this.paramsSelected['subareas'] = $this.user_filters?.arr_cod_subareas || $this.loggedUserInfos.cod_subarea;
                            const subareas_selected = $this.paramsSelected['subareas']?.split(",") || [];
                            for (let subarea of subareas_selected) {
                                $this.paramsSelected['subareas_selected'].push({ cod_subareas: subarea });
                            }
                        });
                    });
                });
            });
        });
    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._userService.getWorlds(this.cod_user, 'REPORT_ROUTES').subscribe(data => {
            this.worldList = this._userService.getArrayContent(data);
            this._setLoading('worlds', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    private _searchRegionals(fn) {

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this.cod_user, 'REPORT_ROUTES').subscribe(data => {
                this.regionalList = this._userService.getArrayContent(data);
                this._setLoading('regionals', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearRegionalList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearRegionalList();
        }
    }

    private _searchLocations(fn) {

        if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._userService.getLocations(params, this.cod_user, 'REPORT_ROUTES').subscribe(data => {
                this.locationsList = this._userService.getArrayContent(data);
                this._setLoading('locations', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearLocations();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearLocations();
        }
    }

    private _searchAreas(fn) {

        if (typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
            this._setLoading('areas', true);
            //Search for areas
            let params = {
                arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
                cod_user: this.cod_user,
                dsc_page_privilege: 'REPORT_ROUTES'
            };
            this._userService.getAreasByLocation(params).subscribe(data => {
                this.areaList = this._userService.getArrayContent(data);
                this._setLoading('areas', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearAreaList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearAreaList();
        }
    }

    private _searchSubAreas(fn) {
        if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '') {
            let objParams = {
                arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
                cod_user: this.cod_user
            };
            this._logbookSubAreaService.searchSubareaByAreaLoction(objParams).subscribe(data => {
                data.content.results = (data.content.length == 1) ? [data.content.results] : data.content.results;
                // this.subAreasList = implodeValues(data.content.results, 'cod_subareas');
                this.subAreasList = this._areaService.getArrayContent(data);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearSubAreaList();
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        } else { 
            this._clearSubAreaList();
        }
    }

    private _searchUsers() {

        if (typeof this.paramsSelected['logbooks'] != typeof undefined && this.paramsSelected['logbooks'].toString() != '' &&
            typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != '') {
            this._setLoading('users', true);
            let params = {
                'cod_logbooks': this.paramsSelected['logbooks'].toString().split(','),
                'cod_location': emptyDefaultValue(this.paramsSelected['locations'], '0'),
                'str_path': environment().uploadGetFilesApiUrl + '/',
                'cod_user': this.cod_user,
                'dsc_page_privilege': 'REPORT_ROUTES'
            };
            this._logbookService.getUsersByLogbookAndLocations(params).subscribe(data => {
                this.userList = this._logbookService.getArrayContent(data);
                this._setLoading('users', false);
                this._smartClearUsers();
            }, err => {
                this._setLoading('users', false);
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearUsers();
        }
    }

    private _searchShifts() {
        if (typeof this.paramsSelected['logbooks'] != typeof undefined && this.paramsSelected['logbooks'].toString() != '' ) {
            this._setLoading('shifts', true);
            this._logbookRoutinesService.getShifts({cod_user: this.loggedUserInfos.id}).subscribe(data => {
                this.shiftsList = this._logbookService.getArrayContent(data).slice(1);
                this._setLoading('shifts', false);
                this._smartClearShifts();
            }, err => {
                this._setLoading('shifts', false);
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearUsers();
        }
    }

    private _getLogbookPromise() {
        let params: any = {
            cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'), 
            cod_user: this.cod_user,
            dat_start: this.initial_date,
            dat_end: this.final_date,
        };
        let func = null;

        if(this.bol_isCollab) {
            const subareas = 
            !!this.paramsSelected['subareas']
                ? JSON.parse('[' + this.paramsSelected['subareas'] + ']')
                : this.subAreasList.map(subarea => subarea.cod_subareas);

            params = {
                ...params,
                arr_cod_areas: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                arr_cod_subareas: subareas, 
                dsc_privilege: 'REPORT_ROUTES',
            }

            func = this._logbookService.getChecklistReportCollab(params);
        } else {
            params = {
                ...params,
                cod_area: emptyDefaultValue(this.paramsSelected['areas'], '0'),
                cod_subareas: emptyDefaultValue(this.paramsSelected['subareas'], '0'),
                term: '',
                dsc_page_privilege: 'REPORT_ROUTES'
            }
            func = this._logbookService.getLogbookReport(params);
        }

        return func;
    }

    private _searchLogbooks(fn) {

        if( !this.paramsSelected['type_selected'] 
            || typeof this.paramsSelected['areas'] == typeof undefined 
            || this.paramsSelected['areas'].toString() == '' 
            || typeof this.paramsSelected['locations'] == typeof undefined 
            || this.paramsSelected['locations'].toString() == ''
        ) return;

        this._setLoading('logbooks', true);

        this._getLogbookPromise().subscribe(data => {
            this.logbookList = this._logbookService.getArrayContent(data);
            this._setLoading('logbooks', false);
            this._smartClearLogbookList();
            this.bol_show_checklist = true;
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        }, err => {
            console.log(err);
            this._authService.errCheck(err);
        });

    }


    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocations();
    }

    private _clearLocations() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearAreaList();
    }

    private _clearAreaList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this.areaList = [];
        this._clearSubAreaList();
        this._clearLogbookList();
    }

    private _clearSubAreaList() {
        this.paramsSelected['subareas'] = '';
        this.paramsSelected['subareas_selected'] = [];
        this.subAreasList = [];
        this._clearLogbookList();
    }

    private _clearLogbookList() {
        this.paramsSelected['logbooks'] = '';
        this.paramsSelected['logbooks_selected'] = [];
        this.logbookList = [];
        this._clearUsers();
    }

    private _clearUsers() {
        this.userList = [];
        this.paramsSelected['users_selected'] = [];
        this.paramsSelected['users'] = '';
    }

    private _smartClearRegionalList() {

        if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
                this.regionalList.map((regional) => {
                    if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
                });
            });
            this.paramsSelected['regionals_selected'] = arr;
            this.paramsSelected['regionals'] = implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        }
        if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
            this.paramsSelected['regionals_selected'] = [];
            this.paramsSelected['regionals'] = '';
            this._clearLocations();
        } else {
            this._searchLocations(null);
        }
    }

    private _smartClearLocations() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearAreaList();
            this._clearUsers();
        } else {
            this._searchAreas(null);
            this._searchUsers();
        }
    }

    private _smartClearAreaList() {

        if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
                this.areaList.map((area) => {
                    if (area.cod_area == selected.cod_area) arr.push(area);
                });
            });
            this.paramsSelected['areas_selected'] = arr;
            this.paramsSelected['areas'] = implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
        }
        if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
            this.paramsSelected['areas_selected'] = [];
            this.paramsSelected['areas'] = '';
            this._clearSubAreaList();
        } 
        else {
            this._searchSubAreas(null);
        }
    }

    private _smartClearSubAreaList() {
        if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
                if (typeof selected.cod_subareas == 'undefined') return;
                this.subAreasList.map((subarea) => {
                    if (subarea.cod_subareas == selected.cod_subareas) arr.push(subarea);
                });
            });
            this.paramsSelected['subareas_selected'] = arr;
            this.paramsSelected['subareas'] = implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
        }
        if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
            this.paramsSelected['subareas_selected'] = [];
            this.paramsSelected['subareas'] = '';
        } else {
            this._searchLogbooks(false);
        }
    }

    private _smartClearUsers() {

        if (this.paramsSelected['users_selected'] && this.paramsSelected['users_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['users_selected'].map((selected: { cod_id: any; }) => {
                this.userList.map((users) => {
                    if (users.cod_id == selected.cod_id) arr.push(users);
                });
            });
            this.paramsSelected['users_selected'] = arr;
            this.paramsSelected['users'] = implodeValues(this.paramsSelected['users_selected'], 'cod_id');
        }
        if (!this.paramsSelected['users_selected'] || this.paramsSelected['users_selected'].length == 0) {
            this.paramsSelected['users_selected'] = [];
            this.paramsSelected['users'] = '';
        }
    }

    private _smartClearShifts() {
        if (this.paramsSelected['shifts_selected'] && this.paramsSelected['shifts_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['shifts_selected'].map((selected: { cod_shift: any; }) => {
                this.shiftsList.map((shift) => {
                    if (shift.cod_shift == selected.cod_shift) arr.push(shift);
                });
            });
            this.paramsSelected['shifts_selected'] = arr;
            this.paramsSelected['shifts'] = implodeValues(this.paramsSelected['shifts_selected'], 'cod_shift');
        }
        if (!this.paramsSelected['shifts_selected'] || this.paramsSelected['shifts_selected'].length == 0) {
            this.paramsSelected['shifts_selected'] = [];
            this.paramsSelected['shifts'] = '';
        }
    }



    private _smartClearLogbookList() {

        if (this.paramsSelected['logbooks_selected'] && this.paramsSelected['logbooks_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['logbooks_selected'].map((selected: { cod_logbooks: any; }) => {
                this.logbookList.map((logbook) => {
                    if (logbook.cod_logbooks == selected.cod_logbooks) arr.push(logbook);
                });
            });
            this.paramsSelected['logbooks_selected'] = arr;
            this.paramsSelected['logbooks'] = implodeValues(this.paramsSelected['logbooks_selected'], 'cod_logbooks');
        }
        if (!this.paramsSelected['logbooks_selected'] || this.paramsSelected['logbooks_selected'].length == 0) {
            this.paramsSelected['logbooks_selected'] = [];
            this.paramsSelected['logbooks'] = '';
            this._clearUsers();
        } else {
            this._searchUsers();
        }

    }



}