import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListLocationsService} from './services/list-locations.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-locations',
    templateUrl: './list-locations.component.html',
    styleUrls: ['./list-locations.component.css']
})

export class ListLocationsComponent implements OnInit {

    locationsList: any = [];
    _obj_ta_eq;
    bol_show_typeahead_locations: Boolean = true;
    _str_user_tmp = '';
    cod_compoment_locations: number;

    @Input() question;
    @Input() isMultiple: Boolean
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListLocationsService: ListLocationsService, private _authService:AuthService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.locationsList = [{ cod_answer_option: this.question.cod_answer_option, str_description_from: this.question.str_answer_text }];    
            this.bol_show_typeahead_locations = false;
        }
        this.loadLocations();
    }

    loadLocations() {
                this._obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_locations',
            searchProperty: 'str_description_from',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.locationsList.length === 0) {
                    this.locationsList.push({
                        cod_locations: obj_selected.cod_locations,
                        str_description_from: obj_selected.str_description_from,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_description_from;
                        this.question.cod_answer_option = obj_selected.cod_locations;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead_locations = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchLocations(cb, value);
            },
            onBlur: (item: any) => {
                this._str_user_tmp = '';
            }
        };
    }

    searchLocations(cb, value) {
        this._ListLocationsService.getLocationsList({ 
            'cod_user': this._authService.getAuthenticatedUser().id,
            'term': value,
            'cod_location_regional': this.question.cod_regional 
        }).subscribe((data: any) => {
            cb(this._ListLocationsService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delLocations() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;
        this.onChange.emit(this.question);

        this.locationsList = [];
        this.bol_show_typeahead_locations = true;
        this.cod_compoment_locations = null;
    }
       
}
