import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {LogbookTopic1Component} from './logbook-topic-1/logbook-topic-1.component';
import {LogbookTopic2Component} from './logbook-topic-2/logbook-topic-2.component';
import {LogbookTopic3Component} from './logbook-topic-3/logbook-topic-3.component';
import {LogbookTopic4Component} from './logbook-topic-4/logbook-topic-4.component';
import {LogbookTopic5Component} from './logbook-topic-5/logbook-topic-5.component';


@NgModule({
    imports: [
CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [LogbookTopic1Component, LogbookTopic2Component, LogbookTopic3Component, LogbookTopic4Component, LogbookTopic5Component],
    declarations: [LogbookTopic1Component, LogbookTopic2Component, LogbookTopic3Component, LogbookTopic4Component, LogbookTopic5Component],
    providers: []
})
export class InfoLogbookModule {
}
