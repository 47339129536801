import { Component, OnInit, Output, EventEmitter, ViewChild, AfterContentChecked } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

import { RoutinesService } from '../../logbooks/services/routines.service';
import { RoutineStatusService } from '../../logbooks/services/routine-status.service';
import { AuthService } from '../../main/access/services/auth.service';
import { UsersService } from '../../admin/services/users.service';
import { SubareaService } from '../../logbooks/services/subarea.service';
import { ValidationsService } from '../../logbooks/services/validations/validations.service';
import { TranslateService } from '@ngx-translate/core';
import { LogbooksService } from 'app/logbooks/services/logbooks.service';
import { LogbookRoutineService } from '../../logbooks/services/logbook-routine.service';
import * as moment from 'moment';
import { environment } from './../../../environments/environment';
import { NotificationService } from "./../../main/services/notification.service";

import { FilterItem, DefaultFilters } from "./../../components/filter/filter.model";
import { Subscription } from "rxjs";
import { SocketService } from "app/main/services/socket.service";
import { finalize, tap } from 'rxjs/operators';
import { RoutinesServiceStore } from 'app/stores/routines-forms.store.service';

@Component({
    selector: 'app-task-list',
    templateUrl: './tasks-list.component.html',
    styleUrls: ['./tasks-list.component.css']
})
export class TasksListComponent implements OnInit, AfterContentChecked {
    @ViewChild('meetingActionList') meetingActionList;
    @ViewChild('fivewhyTasksList') fivewhyTasksList;

    @Output() onDateChange;
    @Output() taskSelected;
    @Output() selectedId = new EventEmitter<Object>();

    cod_user = this._authService.getAuthenticatedUser().id;
    user_name = this._authService.getAuthenticatedUser().name;
    authenticated_user = this._authService.getAuthenticatedUser();    
    detailsParam: any;
    isHidden: boolean;
    showRoutine: boolean;
    bol_loading: boolean;
    bol_loading_functional: boolean = false;
    bol_loading_collaborative: boolean = false;
    selectedDate: Date;
    routineSelected: number;
    classTabs = {};
    update: number;
    canEditMessages: boolean;
    lastId: number;
    openIndex: any;

    areas = [];
    selectedAreas = [];
    bol_show_add_area: boolean = false;
    bol_saving_add_area: boolean = false;
    bol_add_area_error: boolean = false;
    error_msg_add_area: any;
    loadingConfig: any;
    areaList = [];
    paramsSelected: any = [];
    subareaList: any[];
    logbooks: any[];
    logbookList: any = [];
    loadshift: boolean = false;
    show_collab: boolean = false;
    countRoutinesCollab: any;
    collabParams: any = {};
    shiftList: any;
    loadingCollabCount: boolean;
    bol_logbookIsSelect: boolean = false;
    bol_collab_filter: boolean = false;
    collab_chart_params: any = {};
    selectedRoutineTab: any = [];
    savedFilter: any = {};
    bol_add_custom_routine = false;
    cod_logbook: any;
    routinesGroupedByPeriodicity: any = [];
    filteredRoutines: any = [];
    _setDateTimeout: any = null;
    routinesStatusList: Array<any> = [];

    arr_filters: Array<any> = [];

    subscriptionSocket: Subscription;
    userEditingOnSocket: string;
    nextOnQueue = false;
    copyUpdatedTranslation = '';
    positionInSocketQueue: number;
    updatedNotified = false;
    expand_cod_periodicity = -1;

    filters: Array<FilterItem> = [
        DefaultFilters.description,
        DefaultFilters.date,
        DefaultFilters.criticity,
    ];
    sessionFilterPrefillAllowedValues: string[][] = [
        ['areas', 'areas_selected'],
        ['subareas', 'subareas_selected'],
        ['logbook', 'logbook_selected'],
        ['shift', 'shift_selected'],
    ];
    executeScroll = false;

    constructor(
        private _route: ActivatedRoute,
        public _authService: AuthService,
        private _routinesService: RoutinesService,
        private _usersService: UsersService,
        private _translateService: TranslateService,
        private _logbookService: LogbooksService,
        private _subAreaService: SubareaService,
        private _logbooRoutinekService: LogbookRoutineService,
        public _validationService: ValidationsService,
        private _routineStatusService: RoutineStatusService,
        private _notify: NotificationService,
        private _socketService: SocketService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _routineStore: RoutinesServiceStore
    ) {
        this.bol_loading = true;
        //GETTING MEETING ID FROM PARAMETER TO CONNECT TO THAT SOCKET
        this._route.params.subscribe((params: Params) => {
            const date = this._route.snapshot.queryParams.date;
            if(date){
                this.setDate(date);
            } else{
                this.setDate(params['selectedDate'], false);
            }
        });
        this.routineSelected = 0;
        this.isHidden = true;
        this.showRoutine = false;
        this.canEditMessages = true;
        this.paramsSelected = [];
        this.loadingConfig = [];
        this.loadingConfig['areas'] = false;
        this.loadingConfig['shifts'] = false;
    }
    
    filterRoutines() {
        this.filteredRoutines = this._routinesService.filterRoutines(this.arr_filters, this.routinesGroupedByPeriodicity);
    }

    ngOnInit() {
       
        this.cod_logbook = this._authService.getAuthenticatedUser().cod_logbook;

        this._translateService.get('LOGBOOK.COPY_UPDATED').subscribe((translate) => {
            this.copyUpdatedTranslation = translate;
        });

        this.initializeSocket();
        if(this.cod_logbook != null){
            this._socketService.joinChecklistCopies(this.cod_logbook, { name: this.user_name, id: this.cod_user });
        }
  
        this.loadshift = false;
        this.checkUserAreas();
        this.areaList = [];
        this.shiftList = [];
        this._clearAreasList();
        this.bol_collab_filter = this._authService.getUserPrivilege('COLLAB_CHECKLIST_FILTER');
        this.loadingConfig['areas'] = true;

        this.getRoutineStatus();

        this._translateService.onLangChange.subscribe(event => {
            this._routineStatusService.getRoutinesStatusByLanguage(this._authService.getAuthenticatedUser().id, event.lang).subscribe(data => {
                if (data.type == 'success') {
                    this.routinesStatusList = data.content.results;

                    this.routinesGroupedByPeriodicity.forEach(periodicity => {
                        periodicity.routines.forEach(routine => {
                            const routineStatus = this.routinesStatusList.find(rs => rs.cod_routine_status == routine.cod_routine_status);
                            if (routineStatus)
                                routine.str_status_description = routineStatus.str_status_description;
                        });
                    });
                }
            })
        });

        this.getFunctionalRoutines();

        const params = {
            cod_user: this._authService.getAuthenticatedUser().id,
        }
        this._usersService.listUserAreas(params).subscribe(data => {
            if (data.type == 'success' && data.content.type == 'success') {
                this.areaList = (data.content.length == 1)  ? [data.content.results] : data.content.results;
                this.loadingConfig['areas'] = false;

                const routeParams = this._activatedRoute.snapshot.queryParams
                const hasQueryParams = Object.keys(routeParams).length > 0
                if(this.areaList.length > 0 && (!this.authenticated_user.cod_area || this.authenticated_user.cod_area.length <= 0)){                
                    this.authenticated_user.cod_area = this.areaList.map(({cod_area}) => cod_area);
                    this._authService.setAuthenticatedUser(this.authenticated_user);
                    this.closeAddArea(); 
                }
                if(this.bol_collab_filter || hasQueryParams){
                    this._logbookService.getFilterCollab(params).subscribe(filterData => {
                        if (filterData.type === 'success' && filterData.content.type === 'success') {
                            const date = new Date();
                            if (filterData.content.length > 0 || hasQueryParams) {
                                this.savedFilter = hasQueryParams ? this.mappedRouteParams(routeParams) : filterData.content.results;
                                const $this = this;
                                const filterDate = moment(this.savedFilter.dat_ins, 'YYYY-MM-DD').toDate(); //set from DB string to date
                                const monday = new Date(date.setDate(date.getDate() - date.getDay() + 1)); //get date from monday
                                const mondayNoTZ = moment((monday.getFullYear() + '-' + ('0' + (monday.getMonth() + 1)).slice(-2) + '-' + ('0' + (monday.getDate())).slice(-2)), 'YYYY-MM-DD').toDate(); //set monday with no timezone
                                
                                if (filterDate < mondayNoTZ)  { //if filter saved date is less then monday 00:00
                                    this._logbookService.delFilterCollab(params).subscribe();
                                } 
                                else {
                                    if (this.areaList.findIndex(x1 => x1.cod_area == $this.savedFilter.cod_area) != -1 ){    
                                        const codArea = $this.savedFilter.cod_area                                
                                        $this.paramsSelected['areas'] = codArea;
                                        $this.paramsSelected['areas_selected'] = [{cod_area: codArea}];
                                        $this._searchSubAreas(function () {
                                            if ($this.subareaList.findIndex(x2 => x2.cod_subareas == $this.savedFilter.cod_subareas) != -1 ){   
                                                const codSubarea = $this.savedFilter.cod_subareas
                                                $this.paramsSelected['subareas'] = codSubarea;
                                                $this.paramsSelected['subareas_selected'] = [{cod_subareas: codSubarea}];
                                                localStorage.setItem('subareaCollab', JSON.parse('[' + $this.paramsSelected['subareas'] + ']'));
                                                $this._searchLogbooksCollab(function () {

                                                    if ($this.logbookList.findIndex(x3 => x3.cod_logbooks == $this.savedFilter.cod_logbooks) != -1 ){  
                                                        const codLogbook = $this.savedFilter.cod_logbooks;
                                                        $this.paramsSelected['logbook'] = codLogbook;
                                                        $this.paramsSelected['logbook_selected'] = [{cod_logbooks: codLogbook}];
                                                        $this.bol_logbookIsSelect = true;
                                                        $this._searchShift(function () {
                                                            if ($this.shiftList.findIndex(x4 => x4.cod_shift == $this.savedFilter.cod_shift) != -1 ){  
                                                                const codShift = $this.savedFilter.cod_shift;
                                                                $this.paramsSelected['shift'] = codShift;
                                                                $this.paramsSelected['shift_selected'] = [{cod_shift: codShift}];
                                                                localStorage.setItem('shiftCollab', JSON.parse('[' + $this.paramsSelected['shift'] + ']'));
                                                                $this.show_collab = true;
                                                                $this.getCollaborativeRoutines();
                                                            }
                                                            else{
                                                                $this.clearFilter();
                                                            }
                                                        });
                                                    }
                                                    else{
                                                        $this.clearFilter();                                               
                                                    }
                                                });
                                            }
                                            else{
                                                $this.clearFilter();
                                            }
                                        });
                                    }
                                    else{
                                        $this.clearFilter();
                                    }
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    mappedRouteParams(routeParams: Params) {
        return {
            cod_area: Number(routeParams.areas ?? 0),
            cod_subareas: Number(routeParams.subareas ?? 0),
            cod_logbooks: Number(routeParams.logbook ?? 0),
            cod_shift:  Number(routeParams.shift ?? 0),
            dat_ins: moment().format('YYYY-MM-DD'),
            cod_action: Number(routeParams.action ?? 0),
            cod_routines: Number(routeParams.routines ?? 0),
            cod_periodicity: Number(routeParams.periodicity ?? 0),
            opened_section: routeParams.section ?? '',
        }
    }

    filter(filters) {
        this.bol_loading = true;
        this.arr_filters = filters;
        this.meetingActionList.filterActions();
        this.fivewhyTasksList.filter5Whys();
        this.filterRoutines();
        this.bol_loading = false;
    }

    getRoutineStatus() {
        this._routineStatusService.getRoutinesStatusByUserPreference(this._authService.getAuthenticatedUser().id).subscribe(data => {
            if (data.type == 'success') {
                this.routinesStatusList = data.content.results;
            }
        })
    }

    /**
     * @author Guilherme Renato Uller
     * This function set the date selected
     * @param date{Date}
     */
    setDate(date: string, updateList: boolean = true, source: string = null) {
        this.selectedDate = date ? new Date(date + 'T00:00:00') : new Date();
        if (updateList) {
            this.updateList(source);
            this.taskSelected = null;
        }
    }

    onChangeDateSelected(date: string): void {
      if (this._setDateTimeout) {
        window.clearTimeout(this._setDateTimeout);
      };

      this._setDateTimeout = window.setTimeout(() => {
        const selectedDate = new Date(date + 'T00:00:00');
        const currentDate = new Date(this.myTaskMainDate + 'T00:00:00');
        if (this.convertDate(selectedDate) !== this.convertDate(currentDate)) {
          this.setDate(date);
        };
      }, 500);
    }

    getDate(): string {
        let dateFormatted;
        if (typeof this.selectedDate.getMonth !== 'function') {
            dateFormatted = new Date(this.selectedDate + 'T00:00:00').getFullYear() + '-' + ('0' + (new Date(this.selectedDate + 'T00:00:00').getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date(this.selectedDate + 'T00:00:00').getDate())).slice(-2);
        } else {
            dateFormatted = this.selectedDate.getFullYear() + '-' + ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.selectedDate.getDate())).slice(-2);
        };

        this.unSelectRoutine();
        this._routineStore.setMyTaskMainDate(dateFormatted);
        return dateFormatted;
    }

    private unSelectRoutine() {
        if (this.taskSelected?.routine) {
          this.taskSelected.routine = null;
          this.showRoutine = false; 
        }
    }

    getFunctionalRoutines(source: string = null) {
        this.bol_loading_functional = true;

        const params = {
            cod_user: this.cod_user,
            date: this.getDate(),
            upload_url: environment().uploadGetFilesApiUrl
        };

        this._routinesService.getFuncionalRoutinesList(params).subscribe({
            next: data => {
                if (data.code == 888) {
                    this.routinesGroupedByPeriodicity = [];
                    const periodicities = (data.content.length === 1) ? [data.content.results] : data.content.results;

                    periodicities.forEach(p => {
                        const periodicity = this.getRoutinePeriodicityObject(p.cod_periodicity, "");

                        periodicity.total_routines += parseInt(p.total_routines);
                        periodicity.total_executed += parseInt(p.total_executed);
                        periodicity.routines.unshift(...p.routines);

                        if (p.bol_chart_generate && !periodicity.bol_chart_generate)
                            periodicity.bol_chart_generate = true;
                    });
                };

                if (this.show_collab)
                    this.getCollaborativeRoutines(source);
                else
                    this.sortRoutinesList();

                this.bol_loading_functional = false;
                this.processLoadingEnd(source);
            }, 
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
                this.bol_loading_functional = false;
                this.processLoadingEnd();
            }
        });
    };

    processLoadingEnd(source: string = null) {
        const loadingEnded = (!this.bol_loading_functional && !this.bol_loading_collaborative);
        if (loadingEnded) {
            this.refreshLogbookChart();
            this.bol_loading = false;
            this.executeScroll = (source == 'routineStatusChanged' || source == 'actionStatusChanged');
        }
    }

    ngAfterContentChecked(): void {
        if (this.executeScroll) {
            const taskSelected = this._routinesService.getTaskSelected();
            if (taskSelected) {
                const el = (taskSelected.type == 'routine')
                    ? (<HTMLInputElement> document.getElementById(taskSelected.routine.html_id))
                    : (<HTMLInputElement> document.getElementById(taskSelected.action.html_id));
                if (el) el.scrollIntoView({
                    block: "start",
                    inline: "center"
                });
            }
            this.executeScroll = false;
        }
    }

    getCollaborativeRoutines(source: string = null) {
        this.bol_loading_collaborative = true;

        const params = {
            cod_checklist: this.paramsSelected['logbook'],
            arr_cod_shifts: JSON.parse('[1,' + this.paramsSelected['shift'] + ']'),
            date: this.getDate(),
            cod_user: this.cod_user,
            cod_subarea: localStorage.getItem('subareaCollab'),
            upload_url: environment().uploadGetFilesApiUrl
        };
        this.collab_chart_params = {
            cod_checklist: this.paramsSelected['logbook'],
            arr_cod_shifts: JSON.parse('[' + this.paramsSelected['shift'] + ']'),
            date: this.getDate(),
            cod_subarea: localStorage.getItem('subareaCollab')
        };
        this._routinesService.getCollaborativeRoutinesList(params).subscribe(data => {
            if (data.code == 888) {
                const periodicities = (data.content.length === 1) ? [data.content.results] : data.content.results;

                periodicities.forEach(p => {
                    const periodicity = this.getRoutinePeriodicityObject(p.cod_periodicity, "");

                    periodicity.routines = periodicity.routines.filter(routine => !routine.bol_type_collab);

                    periodicity.total_routines += parseInt(p.total_routines);
                    periodicity.total_executed += parseInt(p.total_executed);
                    periodicity.routines.push(...p.routines);

                    if (p.bol_chart_generate && !periodicity.bol_chart_generate)
                        periodicity.bol_chart_generate = true;
                });
            };
            this.sortRoutinesList();
            this.bol_loading_collaborative = false;
            this.processLoadingEnd(source);
        }, err => {
            this._authService.errCheck(err);
            console.log(err);
            this.bol_loading_collaborative = false;
            this.processLoadingEnd();
        });
    };

    getRoutinePeriodicityObject(cod_periodicity: number, str_description_periodicity: string) {
        let periodicity = this.routinesGroupedByPeriodicity.find(element => element.cod_periodicity == cod_periodicity);

        if (!periodicity) {
            periodicity = {
                cod_periodicity,
                str_description_periodicity,
                total_executed: 0,
                total_routines: 0,
                routines: [],
                bol_chart_generate: false
            };
            this.routinesGroupedByPeriodicity.push(periodicity);
        };

        return periodicity;
    }

    sortRoutinesList() {
        this.routinesGroupedByPeriodicity = this.routinesGroupedByPeriodicity.sort((a,b) => a.cod_periodicity - b.cod_periodicity);
        this.filterRoutines();
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    updateList(source: string = null) {
        this.bol_loading = true;
        this.routinesGroupedByPeriodicity = [];

        this.getFunctionalRoutines(source);
        
    }

    refreshLogbookChart() {
        this.update = Math.random();
    }

    /**
     * @author Guilherme Renato Uller
     * This function defines the action according to the object transferred in the stock listing
     * @param task{EventEmitter<Object>}
     */
    onTaskSelected(task, index = null) {
        this._routinesService.setTaskSelected(task);
        if (task.type === 'action') {
            this.taskSelected = task;
            this.showRoutine = false;
            this.isHidden = false;
            this.detailsParam = { 'cod_action': task.action.cod_actions, 'cod_id': 0 };
            
        } else if (task.type === 'routineSel') {
            if (task.index !== null) this.selectedRoutineTab.push(task.index);

            if (this.taskSelected != undefined && this.taskSelected.type != 'routine' && this.taskSelected.type != 'routineSel' && this.taskSelected.type != 'action' && this.taskSelected.type != '') {
                this.showRoutine = true;
                this.isHidden = true;
                this.taskSelected = task;
            }
        } else {
            this.taskSelected = task;
            if (task.type === 'routine') {
                this.isHidden = true;
                if(task.refresh){
                    this.showRoutine = false;
                } 
                setTimeout(() =>{
                    this.showRoutine = true;
                }, 300)                             
            }
        }

        this.openIndex = task.openIndex !== undefined ? task.openIndex : this.openIndex;
        this.lastId = task.lastId !== undefined ? task.lastId : this.lastId;
    }

    setShowRoutine($event) {
        this.showRoutine = $event;
        this.taskSelected.type = '';
    }

    public closeRoutine() : void {
        this.showRoutine = false;
        this.taskSelected.routine = null; 
        this.taskSelected.type = '';
    }

    /**
     * @author Guilherme Renato Uller
     * This function defines the action as code passed by parameter in the URL
     * @param tab{String}
     */
    setTaskSelected(cod_actions_selected: number) {
        if (cod_actions_selected) {
            this.taskSelected = { cod_actions: cod_actions_selected, action_type: 1 };
        }
    }

    /**
     * Callback from dynamic form after it has been filled an dsent.
     */
    formSent(answerStatus) {
        console.debug('Tasks list form sent', { answerStatus });
        this.updateList();
    }

    /**
     * @author Guilherme Renato Uller
     *
     */
    addDay() {
        const date = this.selectedDate.getTime() + 1 * 86400000;
        this.selectedDate.setTime(date);
        this.setDate(this.selectedDate.getFullYear() + '-' + ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.selectedDate.getDate())).slice(-2))
    }

    /**
     * @author Guilherme Renato Uller
     *
     */
    removeDay() {
        const date = this.selectedDate.getTime() - 1 * 86400000;
        this.selectedDate.setTime(date);
        this.setDate(this.selectedDate.getFullYear() + '-' + ('0' + (this.selectedDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.selectedDate.getDate())).slice(-2))
    }

    changedRoutineCollab($event) {        
        if ($event.dat_ins.length <= 10) {
            $event.dat_ins = $event.dat_ins + 'T00:00:00';
        }

        let newDate = new Date($event.dat_ins);

        let dateStr = newDate.getFullYear() + '-' + ('0' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (newDate.getDate())).slice(-2);

        this.setDate(dateStr, true, 'routineStatusChanged');

        let task = {
            type: 'routine',
            routine: $event
        };
        this.routineSelected = $event.cod_routines;
        this.onTaskSelected(task);
    }

    changedRoutine($event) {
        if ($event.dat_ins.length <= 10) {
            $event.dat_ins = $event.dat_ins + 'T00:00:00';
        }

        const reload = $event.reload === undefined ? true : $event.reload;
        delete $event.reload;

        const newDate = new Date($event.dat_ins);
        const dateStr = newDate.getFullYear() + '-' + ('0' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (newDate.getDate())).slice(-2);

        this.setDate(dateStr, reload, 'routineStatusChanged');

        if (reload) {
            let task = {
                type: 'routine',
                routine: $event
            };
            this.onTaskSelected(task);
        } else {
            const per = this.routinesGroupedByPeriodicity.find(p => p.cod_periodicity == $event.cod_periodicity);
            per.routines = [
                ...per.routines.filter(r => r.int_order != $event.int_order), 
                ...[$event]
            ].sort((a, b) => a.bol_type_collab - b.bol_type_collab || a.int_order - b.int_order);
    
            this.filterRoutines();
            this.refreshLogbookChart();
        }

        this.routineSelected = $event.cod_routines;
    }

    changedAction($event) {
        let task = {
            type: 'action',
            action: $event
        };

        let newDate = this.selectedDate;
        let dateStr = newDate.getFullYear() + '-' + ('0' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('0' + (newDate.getDate())).slice(-2);
        this.setDate(dateStr, true, 'actionStatusChanged');

        this.onTaskSelected(task);
    }

    isSelected(codPeriodicity) {
        if (typeof this.taskSelected !== typeof undefined &&
            typeof this.taskSelected.routine !== typeof undefined &&
            codPeriodicity == this.taskSelected.routine.cod_periodicity) {
            return 'active';
        }
        return 'inactive';
    }

    convertDate(date) {
        return (date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2));
    }

    closeAddArea() {
        let date = new Date();
        sessionStorage.setItem('closedAddMissingArea', '' + date.getTime());
        this.bol_show_add_area = false;
        this.bol_add_area_error = false;
        this.selectedAreas = [];
    }

    addArea() {
        //placeholder for cod area from BD
        let arr_cod_areas = this.selectedAreas.map((area) => {
            return area.cod_areas;
        });
        if (arr_cod_areas.length > 0) {

            let params = {
                arr_cod_areas: arr_cod_areas,
                cod_user: this.cod_user
            };
            this.bol_saving_add_area = true;
            this._usersService.postMissingAreas(params).subscribe(res => {
                if (res.code == 888 && res.content.code == 888) {
                    this.authenticated_user.cod_area = arr_cod_areas;
                    this._authService.setAuthenticatedUser(this.authenticated_user);
                    this.closeAddArea();
                }
                else {
                    this.error_msg_add_area = res.content.message;
                    this.bol_add_area_error = true;
                }
                this.bol_saving_add_area = false;
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                    this.bol_add_area_error = true;
                    this.error_msg_add_area = err.status;
                    this.bol_saving_add_area = false;
                });

        }
        else {
            this.bol_add_area_error = true;
            this._translateService.get('LOGBOOK.REQUIRED_AREA').subscribe(translate => {
                this.error_msg_add_area = translate;
            });
        }


    }

    checkUserAreas() {
        if (!this.authenticated_user.cod_area || this.authenticated_user.cod_area.length <= 0) {
            this._usersService.getAreas(this.cod_user, 'USERS_SELECT_AREAS').subscribe({
                next: data => {
                    this.areas = this._usersService.getArrayContent(data);
                    this.bol_show_add_area = true;
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        }

    }

    selectArea($event) {
        this.selectedAreas = $event.selectedOptions;
    }

    selectAreas($event) {
        this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
        localStorage.setItem('my-task-area', this.paramsSelected['areas']);
        this._searchSubAreas(null);
        if (this.show_collab) {
            this.show_collab = false;
            this.updateList();
        }
    }

    selectSubAreas($event) {
        this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');
        localStorage.setItem('subareaCollab', JSON.parse('[' + this.paramsSelected['subareas'] + ']'));
        this._searchLogbooksCollab(null);
        if (this.show_collab) {
            this.show_collab = false;
            this.updateList();
        }
    }

    selectLogbook($event) {
        this.paramsSelected['logbook'] = this._implodeValues($event.selectedOptions, 'cod_logbooks');
        localStorage.setItem('my-task-checklist', this.paramsSelected['logbook']);
        this._clearShift();
        this._searchShift(null);
        this.bol_logbookIsSelect = true;
        if (this.show_collab) {
            this.show_collab = false;
            this.updateList();
        }
    }

    selectShift($event) {
        this.paramsSelected['shift'] = this._implodeValues($event.selectedOptions, 'cod_shift');
        if (this.show_collab && this.paramsSelected['shift'] === '' ) {
            this.show_collab = false;
            this.collab_chart_params = {};
            this.updateList();
        }else{
            localStorage.setItem('shiftCollab', JSON.parse('[' + this.paramsSelected['shift'] + ']'));
            if(this.taskSelected != undefined){
                this.taskSelected.type = '';
            }
            this.showRoutine = false;
            if(this.bol_logbookIsSelect){
                this.show_collab = true;
                this.updateList();
            }
            const params = {
                cod_area: this.paramsSelected['areas'],
                cod_subareas: this.paramsSelected['subareas'],
                cod_logbooks: this.paramsSelected['logbook'],
                cod_shift: this.paramsSelected['shift'],
                cod_user: this._authService.getAuthenticatedUser().id
            }
            this._logbookService.setFilterCollab(params).subscribe();
        }
    }

    private _clearAreasList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this._clearSubAreasList();
    }
    private _clearSubAreasList() {
        this.paramsSelected['subareas'] = '';
        this.paramsSelected['subareas_selected'] = [];
        this.subareaList = [];
        this._clearLogbooksList();
    }

    private _clearLogbooksList() {
        this.paramsSelected['logbook'] = '';
        this.paramsSelected['logbook_selected'] = [];
        this.logbookList = [];
        this.bol_logbookIsSelect = false;
        this.collab_chart_params = {};
        this._clearShift();
    }

    private _clearShift() {
        this.paramsSelected['shift'] = '';
        this.paramsSelected['shift_selected'] = [];
        this.collab_chart_params = {};
    }

    clearFilter(){
        if(this.taskSelected != undefined){
            this.taskSelected.type = '';
        }
        this._logbookService.delFilterCollab({cod_user: this._authService.getAuthenticatedUser().id}).subscribe();
        this.showRoutine = false;
        this._clearAreasList();
        this._clearShift();
        this.shiftList = [];
        this.show_collab = false;
        this.collabParams = {};
        this.collab_chart_params = {};
        this.updateList();
    }

    private _searchSubAreas(fn) {
        this._clearSubAreasList();
        if (typeof this.paramsSelected['areas'] != typeof undefined && this.paramsSelected['areas'].toString() != '') {
            this._setLoading('subareas', true);
            let params = {
                arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                cod_user: this._authService.getAuthenticatedUser().id
            };
            this._subAreaService.searchSubareaFilter(params).subscribe(data => {
                this.subareaList = this._subAreaService.getArrayContent(data);
                this._setLoading('subareas', false);
                fn?.();
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _searchShift(fn){   
        if (this.paramsSelected['logbook']){      
            
            this._logbooRoutinekService.getLogbookShifts({
                cod_user: this._authService.getAuthenticatedUser().id, 
                cod_logbook: this.paramsSelected['logbook']
            }).pipe(
                tap(() => this.loadingConfig['shifts'] = true),
                finalize(() => this.loadingConfig['shifts'] = false)
            ).subscribe(data => {
                if(data.type == 'success' && data.content.type == 'success'){                
                    this.shiftList = data.content.length === 1? [data.content.results]: data.content.results;
                    fn?.();
                }
            }, err => {
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            });
        }
    }

    private _searchLogbooksCollab(fn) {
        this._clearLogbooksList();
        if (typeof this.paramsSelected['subareas'] != typeof undefined && this.paramsSelected['subareas'].toString() != '') {
            this._setLoading('logbooks', true);
            let params = {
                arr_cod_areas: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
                arr_cod_subareas: JSON.parse('[' + this.paramsSelected['subareas'] + ']'),
                cod_location: [],
                cod_user: this._authService.getAuthenticatedUser().id
            };
            this._logbookService.getChecklistCollabFilter(params).subscribe(data => {
                this.logbookList = this._subAreaService.getArrayContent(data);
                this._setLoading('subareas', false);
                fn?.();
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    private isSelectedRoutine(index) {
        return (this.selectedRoutineTab.includes(index)) ? 'active' : 'inactive' ;
    }

    customRoutine($event){       

        if (this.authenticated_user.cod_logbook > 0) {
            this.bol_add_custom_routine = !this.bol_add_custom_routine;
            if(this.taskSelected != undefined){
                this.taskSelected.type = '';
            }
            this.showRoutine = false;
            if($event === true){
                this.cod_logbook = this._authService.getAuthenticatedUser().cod_logbook;
                this.updateList();
            }
            
        } else {
            this._notify.error({text: 'GENERAL.LOGBOOK_REQUIRED', translate: true});
        }
    }

    initializeSocket() {
        this.subscriptionSocket = this._socketService.subscribeChecklistCopies().subscribe((socket_msg: any) => {
            if (!this.updatedNotified) {
                for(let i = 0; i < socket_msg.length && !this.updatedNotified; i++){
                    const msg = socket_msg[i];
                    if(msg.cod_user != this.cod_user && msg.cod_logbooks == this.cod_logbook) {
                        this._notify.info(this.copyUpdatedTranslation, "", { timeOut: 10000 });
                        this._socketService.unsubscribeChecklistCopies(this.cod_logbook, { name: this.user_name, id: this.cod_user });
                        this.updatedNotified = true;
                    }
                }
            }
        });
    }

    get myTaskMainDate(): string{
        return this._routineStore.getMyTaskMainDate();
    }
}
