import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'component-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css']
})
export class SelectLanguageComponent implements OnInit {

  toggled = false;
  @Input() items: Array<any>;
  @Input() currentValue: string;
  @Input() changeLanguage: (event) => void;

  constructor() { }

  ngOnInit() {
  }

  toggleOpen() {
    this.toggled = !this.toggled;
  }

  getImage(item) {
    return `/assets/images/${item}.jpg`;
  }
}
