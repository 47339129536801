import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";


@Component({
  selector: "app-attachments-zoom",
  templateUrl: "./attachments-zoom.component.html",
  styleUrls: ["./attachments-zoom.component.css"],
})
export class AttachmentsZoomComponent {
  @Input() image: any;
  @Input() str_image_path: string;

  @Output() closeModal = new EventEmitter<any>();
  @Output() downloadImage = new EventEmitter<any>();
  zoomValue = 100
  showModal = true;
  zoomSize = 0;
  zoomButton = 'Zoom';
  fileName: string = '';
  fileExtension = '';

  constructor(
  ) {}

    ngOnInit(){
      this.fileName = this.image.file;
      this.fileName = this.fileName.replace(/%20/g, "_");
      this.fileExtension = this.image.file.split('.').pop();
    }

    download() {
      this.downloadImage.emit(this.image.file);
    }

    close(){
      this.closeModal.emit();
    }

    changeZoom() {
      this.zoomSize =  this.zoomSize + 2;

      switch (this.zoomSize){
        case 2:
          this.zoomButton = 'Zoom';
          break;
        case 4:
          this.zoomButton = 'Fit';
          break;
        default:
          this.zoomSize = 0;
          this.zoomButton = 'Zoom';
          break;
      }
    }
}