import { Component, Input, Output, EventEmitter, OnInit, Renderer2, ElementRef, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-live-search-select',
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './live-search-select.component.html',
  styleUrls: ['./live-search-select.component.css'],
  standalone: true,
})
export class LiveSearchSelectComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() optionLabel: string = 'label';
  @Input() optionValue: string = 'value';
  @Input() isLoadingOptions: boolean = false;
  @Input() options: any[] = [];
  @Input() showSearchIcon: boolean = true;
  @Input() disabled: boolean = false;

  @Output() searchTermChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<any>();

  searchControl = new FormControl();
  dropdownOpen = false;

  emptyResultMessage = 'No results found';

  constructor(
    private readonly renderer: Renderer2, 
    private readonly elRef: ElementRef
  ) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.dropdownOpen = false;
      }
    });
  }

  ngOnInit(): void {
    this.handleFormControlState();

    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.trim() === '') {
        this.dropdownOpen = false;
        this.options = [];
        return;
      }
        this.searchTermChange.emit(searchTerm);
        this.openDropdown();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.handleFormControlState();
    }
  }

  private handleFormControlState(): void {
    if (this.disabled) {
      this.searchControl.disable({ emitEvent: false });
    } else {
      this.searchControl.enable({ emitEvent: false });
    }
  }

  selectOption(option: any): void {
    this.searchControl.setValue('');
    this.dropdownOpen = false;
    this.options = [];
    this.selectionChange.emit(option);
  }

  openDropdown(): void {
    this.dropdownOpen = true;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  onInputBlur(): void {
    setTimeout(() => {
      this.dropdownOpen = false;
      this.options = [];
    }, 200);
  }
}
