import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersService } from 'app/admin/services/users.service';
import { ListChecklistParams } from './models/list-checklist-params.model';
import { ListChecklistSelectService } from './services/list-checklist-select.service';

@Component({
  selector: 'app-list-checklist-select',
  templateUrl: './list-checklist-select.component.html',
  styleUrls: ['./list-checklist-select.component.css']
})
export class ListChecklistSelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() selected = [];
  @Input() isMultiple: boolean = false;

  isLoading: boolean = false;
  checklistList = [];
  selectedChecklist = [];
  isMultiSelect: boolean = false;
  params: ListChecklistParams = new ListChecklistParams();

  constructor(
    private _listChecklistService: ListChecklistSelectService,
    private _userService: UsersService,
  ) { }

  ngOnInit(): void {
  }

  searchChecklists(params: ListChecklistParams, cod_selected_checklists: any[] = [], firstOpening: boolean = false) {
    this.isLoading = true;

    this._listChecklistService.getListChecklist(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.checklistList = this._userService.getArrayContent(data);
  
        if (cod_selected_checklists && cod_selected_checklists.length > 0) {
          this.selectedChecklist = this.checklistList.filter(obj => cod_selected_checklists.includes(obj['cod_logbooks']));
        }

        if (firstOpening) {
          this.selectedChecklist = [this.checklistList[0]];
          this.selectionChanged.emit(this.selectedChecklist);
        }
      }
    }, error => {
      console.log(error)
      this.isLoading = false;
    },
    () => this.isLoading = false
    );
  }

  onSelection($event) {
    this.selectedChecklist = $event?.selectedOptions;

    this.selectionChanged.emit($event?.selectedOptions);
  }

  clearList() {
    this.checklistList = [];
    this.selectedChecklist = [];
  }
}
