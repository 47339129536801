import {AfterViewInit, Component, OnInit} from '@angular/core';

import {FormsDynamicFiltersService} from '../services/forms-filters.service';
import {FormsDynamicReportsService} from '../services/forms-reports.service';
import {ValidationsService} from '../../../logbooks/services/validations/validations.service';
import {UsersService} from '../../../admin/services/users.service';
import {AuthService} from '../../../main/access/services/auth.service';
import {LogbooksService} from '../../../logbooks/services/logbooks.service';
import {RoutinesService} from '../../../logbooks/services/routines.service';
import {PillarsService} from '../../../logbooks/services/pillars.service';
import {AreaService} from '../../../logbooks/services/area.service';
import {environment} from '../../../../environments/environment';
import {MeetingsIndicatorsService} from '../../../meetings/services/meetings-indicators.service';
import {UserPreferencesService} from '../../../user-preferences/services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {SubareaService} from '../../../logbooks/services/subarea.service';
import {ReportsService} from '../../../logbooks/services/reports.service';
import * as moment from 'moment';

enum FORMS_REPORT_VALIDATION {
  MAX_SELECTED_FORMS = 70,
  MAX_PERIOD_DAYS = 90,
};

@Component({
    selector: 'forms-dynamic-reports',
    templateUrl: './forms-dynamic-reports.component.html',
    styleUrls: ['./forms-dynamic-reports.component.css']
})
export class FormsDynamicReportsComponent implements OnInit, AfterViewInit {

    reportsApiUrl = environment().reportsApiUrl;
    paramsSelected: any = [];
    loadingConfig: any = [];
    worldList: any = [];
    areaList: any = [];
    areaCheckedList: any = [];
    regionalList: any = [];
    locationsList: any = [];
    indicatorsList: any = [];
    logbookList: any = [];
    userList: any = [];
    formsList: any = [];
    messageWarning: string = '';
    isWarning: boolean = false;
    isSent: boolean = false;
    timeToHide: number = 0;  
    arr_areas: any = [];
    typeChecklist: any[];
    bol_isCollab: boolean = false;
    bol_show_checklist: boolean = false;
    showUsers: boolean;

    stillGeneratingDetailed: boolean = false;
    stillGeneratingConsolidated: boolean = false;
    timeLastDetailedReport: any;
    timeLastConsolidatedReport: any;
    lastObjParamToLogDetailed: any = [];
    lastObjParamToLogConsolidated: any = [];
    actualObjParam: any = [];
    dateInit: any;
    dateLimit: any;
    perioudCount: number;

    validatePeriodDate: any;

    // Translate report
    private strReportSubject: string;
    private strFileNameDownload: string;
    private arrHeaders: any[];
    subAreasCollab: any;
    areasCollab: any;


    constructor(private filters: FormsDynamicFiltersService, private service: FormsDynamicReportsService,
                private _routineService: RoutinesService, private _authService: AuthService, private _areaService: AreaService,
                private _logbookService: LogbooksService, private _pillarsService: PillarsService, private _userService: UsersService,
                private _reportService: FormsDynamicReportsService, private reportService : ReportsService, private _indicatorsService: MeetingsIndicatorsService,
                public _validationService: ValidationsService, private _translateService: TranslateService,
                private _userPreferenceService: UserPreferencesService, private _logbookSubAreaService: SubareaService) {
        this.paramsSelected = [];
        this.arr_areas = [];        
        let today = new Date();
        let strDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + (today.getDate())).slice(-2);
        this.dat_start = strDate;
        this.dat_finish = strDate;
        this.showUsers = false;
        this.validatePeriod(this.dat_start, this.dat_finish);
    }

    ngOnInit(): void {
        this.indicatorsList = [];
        this.formsList = [];
        this.logbookList = [];
        this.userList = [];
        this.areaCheckedList = [];

        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
        this.loadingConfig['pillars'] = false;
        this.loadingConfig['users'] = false;
        this.loadingConfig['areas'] = false;
        this.loadingConfig['routines'] = false;
        this.loadingConfig['logbooks'] = false;
        this.loadingConfig['forms'] = false;
        this.paramsSelected['type_selected'] = [];
        this.validatePeriod(this.dat_start, this.dat_finish);

        this._loadChecklistTypes();

        this._clearWorldList();
        let $this = this;
        $this._searchWorlds(function () {
            $this.paramsSelected['worlds_selected'] = [];
            $this.worldList.map((world) => {
                if ($this._authService.getAuthenticatedUser().cod_world == world.cod_locations_world) {
                    $this.paramsSelected['worlds_selected'].push({ cod_locations_world: world.cod_locations_world });
                }
            });
            $this.paramsSelected['worlds'] = $this._implodeValues($this.paramsSelected['worlds_selected'], 'cod_locations_world');
            $this._searchRegionals(function () {
                $this.paramsSelected['regionals_selected'] = [];
                $this.regionalList.map((regional) => {
                    if ($this._authService.getAuthenticatedUser().cod_regional == regional.cod_locations_regional) {
                        $this.paramsSelected['regionals_selected'].push({ cod_locations_regional: regional.cod_locations_regional });
                    }
                });
                $this.paramsSelected['regionals'] = $this._implodeValues($this.paramsSelected['regionals_selected'], 'cod_locations_regional');
                $this._searchLocations(function () {
                    $this.paramsSelected['locations_selected'] = [];
                    $this.locationsList.map((location) => {
                        if ($this._authService.getAuthenticatedUser().cod_location == location.cod_location) {
                            $this.paramsSelected['locations_selected'].push({ cod_location: location.cod_location });
                        }
                    });
                    $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_location');
                });
            });
        });
    }

    public onPickPeriodDate() {
      if(this.dat_start && this.dat_finish) {
        const isValidPeriod = this.validatePeriod(this.dat_start, this.dat_finish);
        return !isValidPeriod ? this._showWarning('REPORTS.PERIOD_VALIDATION_MAX_DAYS') : this.isWarning = false;
      };
    };

    private _loadChecklistTypes() {
        this._setLoading('types', true);
        this.typeChecklist = [];
        this._translateService.get('LOGBOOK.FUNCTIONAL').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 0, str_description: translate});
        });
        this._translateService.get('LOGBOOK.COLLAB').subscribe(translate =>{
            this.typeChecklist.push({bol_is_collab: 1, str_description: translate});
        });
        this._setLoading('types', false);
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearAreaList() {
        this.areaList = [];
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this._clearType();
    }

    private _clearForms() {
        this.paramsSelected['forms'] = '';
        this.paramsSelected['forms_selected'] = [];
        this.formsList = [];
    }
  
    private _smartClearForms() {

        if (this.paramsSelected['forms_selected'] && this.paramsSelected['forms_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['forms_selected'].map((selected: { cod_form: any; }) => {
                this.formsList.map((forms) => {
                    if (forms.cod_form == selected.cod_form) arr.push(forms);
                });
            });
            this.paramsSelected['forms_selected'] = arr;
            this.paramsSelected['forms'] = this._implodeValues(this.paramsSelected['forms_selected'], 'cod_form');
        }
        if (!this.paramsSelected['forms_selected'] || this.paramsSelected['forms_selected'].length == 0) {
            this.paramsSelected['forms_selected'] = [];
            this.paramsSelected['forms'] = '';
        }

    }
  
    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocation();
    }

    private _smartClearRegionalList() {

        if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
                this.regionalList.map((regional) => {
                    if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
                });
            });
            this.paramsSelected['regionals_selected'] = arr;
            this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        }
        if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
            this.paramsSelected['regionals_selected'] = [];
            this.paramsSelected['regionals'] = '';
            this._clearLocation();
        } else {
            this._searchLocations(null);
        }

    }

    private _clearLocation() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearLogbookList();
    }
    
    private _smartClearLocation() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearLogbookList();
        } else {
            this._searchLogbooks(false);
        }

    }

    private _clearLogbookList() {
        this.paramsSelected['logbooks'] = '';
        this.paramsSelected['logbooks_selected'] = [];
        this.logbookList = [];
        this._clearForms();
        this._clearUsers();
    }

    private _smartClearLogbookList() {

        if (this.paramsSelected['logbooks_selected'] && this.paramsSelected['logbooks_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['logbooks_selected'].map((selected: { cod_logbooks: any; }) => {
                this.logbookList.map((logbook) => {
                    if (logbook.cod_logbooks == selected.cod_logbooks) arr.push(logbook);
                });
            });
            this.paramsSelected['logbooks_selected'] = arr;
            this.paramsSelected['logbooks'] = this._implodeValues(this.paramsSelected['logbooks_selected'], 'cod_logbooks');
        }
        if (!this.paramsSelected['logbooks_selected'] || this.paramsSelected['logbooks_selected'].length == 0) {
            this.paramsSelected['logbooks_selected'] = [];
            this.paramsSelected['logbooks'] = '';
            this._clearForms();
            this._clearUsers();
        } else {
            this._searchForms();
            this._searchUsers();
        }

    }

    private _clearUsers() {
        this.paramsSelected['users'] = '';
        this.paramsSelected['users_selected'] = [];
        this.userList = [];
    }

    private _smartClearUsers() {

        if (this.paramsSelected['users_selected'] && this.paramsSelected['users_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['users_selected'].map((selected: { cod_id: any; }) => {
                this.userList.map((user) => {
                    if (user.cod_id == selected.cod_id) arr.push(user);
                });
            });
            this.paramsSelected['users_selected'] = arr;
            this.paramsSelected['users'] = this._implodeValues(this.paramsSelected['users_selected'], 'cod_id');
        }
        if (!this.paramsSelected['users_selected'] || this.paramsSelected['users_selected'].length == 0) {
            this.paramsSelected['users_selected'] = [];
            this.paramsSelected['users'] = '';
        }

    }

    private _clearType() {
        this.paramsSelected['type'] = '';
        this.paramsSelected['type_selected'] = [];
        this.bol_isCollab = false;
        this.bol_show_checklist = false;
        this._clearLogbookList();
    }

    ngAfterViewInit(): void {
        this._searchWorlds(null);
        this._searchIndicators();
    }

    private _areAllSelected(listSelected, listAll) {
        return listSelected.length == listAll.length;
    }

    private _listToValue(listSelected, listAll, valueWhenSelected, valueWhenAllSelected) {
        if (this._areAllSelected(listSelected, listAll)) {
            return valueWhenAllSelected;
        }
        return valueWhenSelected;
    }

    private _searchAreas(fn) {
        this._clearAreaList();
        this._setLoading('areas', true);

        let params = {
            arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
            cod_user: this._authService.getAuthenticatedUser().id,
            dsc_page_privilege: 'REPORT_FORMS'
        };
        this._userService.getAreasByLocation(params).subscribe({
            next: data => {
                this.areaCheckedList = this._userService.getArrayContent(data);
                this._setLoading('areas', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _submit() {
        this._generateReport('reports_sp_ex_forms', this.strReportSubject, this.strFileNameDownload, this.arrHeaders);
    }

    private _submitCollab(type:boolean) {
        this._generateReportCollab('reports_sp_ex_forms_collaborative', this.strReportSubject, this.strFileNameDownload, this.arrHeaders, type);
    }

    private _submitCollabResume(type:boolean) {
        this._generateReportCollab('reports_sp_ex_forms_collaborative_consolidated', this.strReportSubject, this.strFileNameDownload, this.arrHeaders, type);
    }

    submit() {
        let $this = this;
        if(this.bol_isCollab){
            this._translateReportCollab(function () {
                $this._submitCollab(false);
            });
        }
        else{
            this._translateReport(function () {
                $this._submit();
            });
        }
    }

    private _submitResume() {
        this._generateReportResume('reports_sp_ex_forms_adhesion_jr', this.strReportSubject, this.strFileNameDownload, this.arrHeaders);
    }

    submitResume() {
        let $this = this;
        if(this.bol_isCollab){
            this._translateReportCollabResume(function () {
                $this._submitCollabResume(true);
            });
        }
        else{
            this._translateReportResume(function () {
                $this._submitResume();
            });
        }
    }

    private _searchLogbooks(fn) {

        if (this.paramsSelected['type_selected'].length > 0) {
            if (this.bol_isCollab) 
                this._searchCollabChecklist(); 
            else 
                this._searchFunctionalChecklist(fn);
        } else {
            this._clearLogbookList();
        }

    }

    private _searchFunctionalChecklist(fn: any) {
        let selectedAreas = this._emptyDefaultValue(this._implodeValues(this.areaCheckedList, 'cod_area'), '0');
        let objParam = {
            'cod_area': selectedAreas,
            'cod_location': JSON.parse('[' + this.paramsSelected['locations'] + ']'), 
            'cod_subareas': null,
            'term': '',
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'REPORT_FORMS'
        };
        this._setLoading('logbooks', true);
        this._logbookService.getLogbookReport(objParam).subscribe({
            next: data => {
                this.logbookList = this._logbookService.getArrayContent(data);
                this.bol_show_checklist = true;
                this._smartClearLogbookList();
                this._setLoading('logbooks', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                //this._searchRoutines();
            }, 
            error: err => {
                console.log(err);
                this._authService.errCheck(err);
            }
        });
    }

    private _searchCollabChecklist() {

        let selectedAreas = this._emptyDefaultValue(this._implodeValues(this.areaCheckedList, 'cod_area'), '0');
        let objParams = {
            arr_cod_area: JSON.parse('[' + selectedAreas + ']'),
            arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
            cod_user: this._authService.getAuthenticatedUser().id
        };
        this._setLoading('logbooks', true);
        this._logbookSubAreaService.searchSubareaByAreaLoction(objParams).subscribe({
            next: data => {
                data.content.results = (data.content.length == 1) ? [data.content.results] : data.content.results;
                this.subAreasCollab = this._implodeValues(data.content.results, 'cod_subareas');
                let params = {
                    arr_cod_areas: JSON.parse('[' + selectedAreas + ']'),
                    arr_cod_subareas: JSON.parse('[' + this.subAreasCollab + ']'),
                    cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'), 
                    cod_user: this._authService.getAuthenticatedUser().id,
                    dsc_privilege: 'REPORT_FORMS'
                };
                this._logbookService.getChecklistReportCollab(params).subscribe({
                    next: data => {
                        this.logbookList = this._logbookService.getArrayContent(data);
                        //this._setLoading('subareas', false);
                        this.bol_show_checklist = true;
                        this._smartClearLogbookList();
                        this._setLoading('logbooks', false);
                    }, error: err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    }
                });
            }, 
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

    }

    private _searchUsers() {

        this.userList = [];
        this.paramsSelected['users'] = '';

        const selectedChecklists = this.paramsSelected['logbooks'];
        const selectedLocations = this.paramsSelected['locations'];
        if ( 
            (typeof selectedChecklists != typeof undefined && selectedChecklists.toString() != '') &&
            (typeof selectedLocations != typeof undefined && selectedLocations.toString() != '')
        ) {
            this._setLoading('users', true);
            let params = {
                'cod_logbooks': selectedChecklists.toString().split(','),
                'cod_location': this._emptyDefaultValue(selectedLocations, '0'),
                'str_path': environment().uploadGetFilesApiUrl + '/',
                'cod_user': this._authService.getAuthenticatedUser().id,
                'dsc_page_privilege': 'REPORT_FORMS'
            };
            this._logbookService.getUsersByLogbookAndLocations(params).subscribe({
                next: data => {
                    this.userList = this._logbookService.getArrayContent(data);
                    this._setLoading('users', false);
                    this._smartClearUsers();
                }, 
                error: err => {
                    this._setLoading('users', false);
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearUsers();
        }

    }

    private _searchIndicators() {
        const params = {
            str_term: '--',
            cod_user: this._authService.getAuthenticatedUser().id,
            dsc_page_privilege: 'REPORT_FORMS'
        };
        this._indicatorsService.getMeetingsIndicators(params).subscribe({
            next: data => {
                if (data.content.length > 1) {
                this.indicatorsList = data.content.results;
                } else {
                    this.indicatorsList = [data.content.results];	
                }
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._userService.getWorlds(this._authService.getAuthenticatedUser().id, 'REPORT_FORMS').subscribe({
            next: data => {
                this.worldList = this._userService.getArrayContent(data);
                this._setLoading('worlds', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private _searchLocations(fn) {
        
        if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._userService.getLocations(params, this._authService.getAuthenticatedUser().id, 'REPORT_FORMS').subscribe({
                next: data => {
                    this.locationsList = this._userService.getArrayContent(data);
                    this._setLoading('locations', false);
                    this._smartClearLocation();
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearLocation();
        }

    }

    private _searchRegionals(fn) {

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'REPORT_FORMS').subscribe({
                next: data => {
                    this.regionalList = this._userService.getArrayContent(data);
                    this._setLoading('regionals', false);
                    this._smartClearRegionalList();
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearRegionalList();
        }

    }

    private _searchForms() {

        if (typeof this.paramsSelected['logbooks'] != typeof undefined && this.paramsSelected['logbooks'].toString() != '') {
            this._setLoading('forms', true);
            let params = {
                'cod_logbooks': this.paramsSelected['logbooks'].toString(),
                'cod_user': this._authService.getAuthenticatedUser().id,
                'cod_zone': this.paramsSelected['worlds']
            };
            this.filters.listFormsByLogbook(params).subscribe({
                next: data => {
                    this.formsList = this.filters.getArrayContent(data);
                    this._setLoading('forms', false);
                    this._smartClearForms();
                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } else {
            this._clearForms();
        }

    }

    selectWorlds($event) {
        this.paramsSelected['worlds_selected'] = $event.selectedOptions;
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
        //this._searchAreas(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
        this._searchLogbooks(false);
        // this._searchAreas(null);
    }

    selectUsers($event) {
        this.paramsSelected['users_selected'] = $event.selectedOptions;
        this.paramsSelected['users'] = this._implodeValues($event.selectedOptions, 'cod_id');
    }

    // selectAreas($event) {
    //     this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
    //     this._searchLogbooks(null);
    // }

    // selectAreasForms($event) {
    //     this.paramsSelected['areas_forms'] = this._implodeValues($event.selectedOptions, 'cod_areas');
    //     this._searchLogbooks(null);
    // }

    selectLogbooks($event) {
        this.paramsSelected['logbooks_selected'] = $event.selectedOptions;
        this.paramsSelected['logbooks'] = this._implodeValues($event.selectedOptions, 'cod_logbooks');
        this._searchForms();
        if (!this.bol_isCollab) this._searchUsers();
    }

    // selectIndicators($event) {
    //     this.paramsSelected['indicators'] = this._implodeValues($event.selectedOptions, 'cod_indicators');
    // }

    selectForms($event) {
      this.paramsSelected['forms_selected'] = $event.selectedOptions;
      this.paramsSelected['forms'] = this._implodeValues($event.selectedOptions, 'cod_form');
      if(this.paramsSelected['forms_selected']?.length > FORMS_REPORT_VALIDATION.MAX_SELECTED_FORMS) {
        this._showWarning('REPORTS.LIMIT_FORMS');
      };
    }

    selectChecklistType($event){
        this.paramsSelected['type_selected'] = $event.selectedOptions;
        this.paramsSelected['type'] = this._implodeValues($event.selectedOptions, 'bol_is_collab');
        this.bol_isCollab = this.paramsSelected['type'] == 1;
        this.showUsers = !this.bol_isCollab;
        if (this.paramsSelected['type_selected'].length > 0) {
            if (this.bol_isCollab) {// 1 - collab, 0 - functional
                this._searchLogbooks(false);
            } else {
                if(this.paramsSelected['type'] != ''){
                    let $this = this;
                    $this._searchLogbooks(function () {
                        if ($this.logbookList.length > 0) {
                            const logbookExistInList = $this.logbookList.filter(logbook => logbook.cod_logbooks == $this._authService.getAuthenticatedUser().cod_logbook)[0];
                            if (typeof logbookExistInList != typeof undefined && logbookExistInList.cod_logbooks > 0) {
                                $this.paramsSelected['logbooks_selected'] = [{cod_logbooks: $this._authService.getAuthenticatedUser().cod_logbook}];
                                $this.paramsSelected['logbooks'] = $this._authService.getAuthenticatedUser().cod_logbook;
                                $this._searchForms();
                                if(!$this.bol_isCollab){
                                    $this._searchUsers();
                                }
                            }
                        }
                    });
                }
            }
        } else {
            this._clearLogbookList();
        }
    }

    private _emptyDefaultValue(value, defaultValue) {
        if (typeof value !== typeof undefined && value) {
            return value;
        }
        return defaultValue;
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

    private _getAsArray(strText) {
        if (typeof strText != typeof undefined && strText) {
            return strText.toString().split(',');
        }
        return [];
    }
    

    private _hasInvalidParams() {
        const selectedForm = this._getAsArray(this.paramsSelected['forms']);
    
        if (!this.dat_start || !this.dat_finish) {
            this._showWarning('REPORTS.FORMS_VALIDATION_MESSAGE');
            return true;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['locations'], null)) {
            this._showWarning('REPORTS.LOCATION_VALIDATION');
            return true;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['type'], null)) {
            this._showWarning('LOGBOOK.REQUIRED_TYPE');
            return true;
        }

        if (!this._emptyDefaultValue(this.paramsSelected['logbooks'], null)) {
            this._showWarning('REPORTS.CHECKLIST_VALIDATION');
            return true;
        }

        if (this.dat_start > this.dat_finish) {
            this._showWarning('REPORTS.PERIOD_VALIDATION');
            return true;
        }

        if (this._isInvalidDateRange()) {
            this._showWarning('REPORTS.PERIOD_VALIDATION_MAX_DAYS');
            return true;
        }

        if (selectedForm.length > FORMS_REPORT_VALIDATION.MAX_SELECTED_FORMS || selectedForm.length < 0) {
            this._showWarning('REPORTS.LIMIT_FORMS');
            return true;
        }

        // If the total number of forms is more than the limit, and 0 are selected, then it's invalid
        if (selectedForm.length === 0 && this.formsList.length > FORMS_REPORT_VALIDATION.MAX_SELECTED_FORMS) {
            this._showWarning('REPORTS.FORMS_VALIDATION');
            return true;
        }

        // If the total number of forms is 0, then it's invalid
        if (!this.formsList || this.formsList.length === 0) {
            this._showWarning('REPORTS.FORMS_VALIDATION');
            return true;
        }

        return false;
    }

    private _generateReport(reportStoredProcedure: string, reportEmailSubject: string, fileName: string, headers: any[]) {
        this._validationService.boolSubmit = true;
        
        if (this._hasInvalidParams())
            return false

        this._validationService.boolIsLoading = true;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;


        let listIndicatorsSelected = [];
        if (typeof this.paramsSelected['indicators'] != typeof undefined && this.paramsSelected['indicators'] &&
            this.paramsSelected['indicators'] != '') {
            listIndicatorsSelected = this.paramsSelected['indicators'].split(',');
        }

        let indicatorsList = this._emptyDefaultValue(this.paramsSelected['indicators'], 'null');
        if (indicatorsList && indicatorsList != 'null' && (this._areAllSelected(listIndicatorsSelected, this.indicatorsList))) {
            indicatorsList = 'null';
        }

        let logbookList = null;
        if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['logbooks']), this.logbookList))) {
            logbookList = this._emptyDefaultValue(this.paramsSelected['logbooks'], 'null');
        }

        let areaCheckedList = null;
        if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['areas_forms']), this.areaCheckedList))) {
            areaCheckedList = this._emptyDefaultValue(this.paramsSelected['areas_forms'], 'null');
        }

        let usersList = null;
        if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['users']), this.userList))) {
            usersList = this._emptyDefaultValue(this.paramsSelected['users'], 'null');
        }

        let formsList = this._emptyDefaultValue(this.paramsSelected['forms'], this._implodeValues(this.formsList, 'cod_form'));


        let locationsList = this._emptyDefaultValue(this.paramsSelected['locations'], 'null');

        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            mailText = mailText.replace('$USER', this._authService.getAuthenticatedUser().name);
            mailText = mailText.replace('$REPORT', reportEmailSubject);

            let objParam = {
                cod_user: this._authService.getAuthenticatedUser().id,
                api_url: this.reportsApiUrl,
                str_report_subject: reportEmailSubject,
                str_proc_exec: reportStoredProcedure,
                arr_proc_params_search: [
                    logbookList,
                    areaCheckedList,
                    indicatorsList,
                    locationsList,
                    usersList,
                    formsList,
                    this.dat_start,
                    this.dat_finish,
                    this._userPreferenceService.getLocalLanguage(),
                    this._userPreferenceService.getLocalDateFormat(),
                    environment().attachmentsUrl,
                ],
                arr_headers: headers,
                isJsonResultSet: false,
                name_of_file_to_download: fileName,
                str_column_dynamic: '',
                str_worker_link: environment().workerReportPath,
                str_mail_text: mailText
            };
            let objParamToLog =  {
                cod_user: this._authService.getAuthenticatedUser().id,
                api_url: this.reportsApiUrl,
                reportTab: 3,
                str_report_subject: reportEmailSubject,
                str_proc_exec: reportStoredProcedure,
                arr_proc_params_search: [
                    {
                        'pr_world_selected': this.paramsSelected['worlds'],
                        'pr_regionals_selected': this.paramsSelected['regionals'],
                        'pr_locations_selected': this.paramsSelected['locations'],
                        'pr_checklist_selected': this.paramsSelected['logbooks'],
                        'pr_forms_selected': this.paramsSelected['forms'],
                        'pr_users_selected': this.paramsSelected['users'],
                        'pr_dat_ini': this.dat_start,
                        'pr_dat_fim': this.dat_finish,
                        'pr_is_collab': this.bol_isCollab,
                        'pr_is_consolidated': false,
                        'pr_cod_user': this._authService.getAuthenticatedUser().id
                    }
                ],
                arr_headers: headers,
                isJsonResultSet: false,
                name_of_file_to_download: fileName,
                str_column_dynamic: 'str_columns',
                str_worker_link: environment().workerReportPath,
                str_mail_text: mailText
            };

            this.actualObjParam = objParamToLog; 
            
            if(this.reportService.isSameParameters(this.actualObjParam, this.lastObjParamToLogDetailed)){
                var now = new Date().getTime();
                var seconds = 300;
                if(typeof this.timeLastDetailedReport != typeof undefined){
                    var milisec_diff = Math.abs(this.timeLastDetailedReport.getTime() - now);
                    seconds = Math.floor(milisec_diff / 1000);
                }
    
                if(seconds < 300){
                    this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
                    this._validationService.boolIsLoading = false;
                    this.stillGeneratingDetailed = false;
                    return;
                }
                else{
                    this.timeLastDetailedReport = new Date();
                }
                
                this._validationService.boolIsLoading = false;
            }
            else{
                this.lastObjParamToLogDetailed = objParamToLog;            
                this.timeLastDetailedReport = new Date();
            }
    
            this.stillGeneratingDetailed = true;

            this.reportService.postLogReports(objParamToLog).subscribe({
                next: data => {
                },
                error: err => {
                    this._authService.errCheck(err);
                }
            });
            //can not do subscribe because rabbit mq does not return a response
            this._reportService.postDynamicReport(objParam).subscribe({
                next: data => {
                    this._validationService.boolIsLoading = false;
                    this.stillGeneratingDetailed = false;
                    this.timeToHide = 3000;
                    this.isSent = true;
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });

            this._validationService.boolSubmit = false;            
        });
    }

    private _generateReportCollab(reportStoredProcedure: string, reportEmailSubject: string, fileName: string, headers: any[], isConsolidated: boolean) {

        if(isConsolidated){
            this.stillGeneratingConsolidated = true;
        }
        else{
            //detailed
            this.stillGeneratingDetailed = true;
        }
        
        this._validationService.boolSubmit = true;
        
        if (this._hasInvalidParams())
            return false

        this._validationService.boolIsLoading = true;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;

        let logbookList = null;
        if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['logbooks']), this.logbookList))) {
            logbookList = this._emptyDefaultValue(this.paramsSelected['logbooks'], 'null');
        }

        let formsList = this._emptyDefaultValue(this.paramsSelected['forms'], this._implodeValues(this.formsList, 'cod_form'));

        let locationsList = this._emptyDefaultValue(this.paramsSelected['locations'], 'null');
        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            mailText = mailText.replace('$USER', this._authService.getAuthenticatedUser().name);
            mailText = mailText.replace('$REPORT', reportEmailSubject);
            this._translateService.get('REPORTS.PLANNED').subscribe(planned => {
                this._translateService.get('REPORTS.PERFORMED').subscribe(performed => {
                    const paramsSearch = isConsolidated
                        ? [
                            logbookList,
                            locationsList,
                            this._implodeValues(this.areaCheckedList, 'cod_area'),
                            this.subAreasCollab,
                            formsList,
                            this.dat_start,
                            this.dat_finish,
                            this._userPreferenceService.getLocalDateFormat(),
                            planned,
                            performed
                        ]
                        : [
                            logbookList,
                            locationsList,
                            this._implodeValues(this.areaCheckedList, 'cod_area'),
                            this.subAreasCollab,
                            formsList,
                            this.dat_start,
                            this.dat_finish,
                            this._userPreferenceService.getLocalLanguage(),
                            this._userPreferenceService.getLocalDateFormat(),
                            environment().attachmentsUrl,
                        ];
                    let objParam = {
                        cod_user: this._authService.getAuthenticatedUser().id,
                        api_url: this.reportsApiUrl,
                        str_report_subject: reportEmailSubject,
                        str_proc_exec: reportStoredProcedure,
                        bol_is_collab: this.bol_isCollab,
                        arr_proc_params_search: paramsSearch,
                        arr_headers: headers,
                        isJsonResultSet: false,
                        name_of_file_to_download: fileName,
                        str_column_dynamic: isConsolidated ? 'str_columns' : '',
                        str_worker_link: environment().workerReportPath,
                        str_mail_text: mailText
                    };
                    let objParamToLog = {
                        cod_user: this._authService.getAuthenticatedUser().id,
                        api_url: this.reportsApiUrl,
                        reportTab: 3,
                        str_report_subject: reportEmailSubject,
                        str_proc_exec: reportStoredProcedure,
                        arr_proc_params_search: [
                            {
                                'pr_world_selected': this.paramsSelected['worlds'],
                                'pr_regionals_selected': this.paramsSelected['regionals'],
                                'pr_locations_selected': this.paramsSelected['locations'],
                                'pr_checklist_selected': this.paramsSelected['logbooks'],
                                'pr_forms_selected': this.paramsSelected['forms'],
                                'pr_users_selected': this.paramsSelected['users'],
                                'pr_dat_ini': this.dat_start,
                                'pr_dat_fim': this.dat_finish,
                                'pr_is_collab': this.bol_isCollab,
                                'pr_is_consolidated': isConsolidated,
                                'pr_cod_user': this._authService.getAuthenticatedUser().id
                            }
                        ],
                        arr_headers: headers,
                        isJsonResultSet: false,
                        name_of_file_to_download: fileName,
                        str_column_dynamic: 'str_columns',
                        str_worker_link: environment().workerReportPath,
                        str_mail_text: mailText
                    };

                    this.actualObjParam = objParamToLog;
                    let lastObjParamToLog = this.lastObjParamToLogDetailed;
                    if(isConsolidated){
                        lastObjParamToLog = this.lastObjParamToLogConsolidated;
                    }

                    if(this.reportService.isSameParameters(this.actualObjParam, lastObjParamToLog)){
                        var now = new Date().getTime();
                        var milisec_diff = 300000;
                        if(isConsolidated){
                            if(typeof this.timeLastConsolidatedReport != typeof undefined){
                                milisec_diff = Math.abs(this.timeLastConsolidatedReport.getTime() - now);
                            }
                        }
                        else{
                            //Detailed
                            if(typeof this.timeLastDetailedReport != typeof undefined){
                                milisec_diff = Math.abs(this.timeLastDetailedReport.getTime() - now);
                            }
                        }
                        var seconds = Math.floor(milisec_diff / 1000);
                        
                        if(seconds < 300){
                            this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
                            this._validationService.boolIsLoading = false;
                            if(isConsolidated){
                                this.stillGeneratingConsolidated = false;
                            }
                            else{
                                //detailed
                                this.stillGeneratingDetailed = false;
                            }
                            return;
                        }
                        else{
                            if(isConsolidated){
                                this.timeLastConsolidatedReport = new Date();
                            }
                            else{
                                //detailed
                                this.timeLastDetailedReport = new Date();
                            }
                        }
                        
                        this._validationService.boolIsLoading = false;
                    }
                    else{
                        if(isConsolidated){
                            this.lastObjParamToLogConsolidated = objParamToLog;
                            this.timeLastConsolidatedReport = new Date();
                        }
                        else{
                            //detailed
                            this.lastObjParamToLogDetailed = objParamToLog;
                            this.timeLastDetailedReport = new Date();
                        }
                    }

                    this.reportService.postLogReports(objParamToLog).subscribe({
                        next: data => {
                        },
                        error: err => {
                            this._authService.errCheck(err);
                        }
                    });
                    //can not do subscribe because rabbit mq does not return a response
                    if (isConsolidated) {
                        this._reportService.postDynamicReportResume(objParam).subscribe({
                            next: data => {
                                //console.log(data);                            
                                this._validationService.boolIsLoading = false;
                                this.stillGeneratingConsolidated = false;
                                this.timeToHide = 3000;
                                this.isSent = true;
                            },
                            error: err => {
                                this._authService.errCheck(err);
                                console.log(err);                                
                                this.stillGeneratingConsolidated = false;
                            }
                        });
                    } else {
                        objParam.arr_proc_params_search.push(true);
                        this._reportService.postDynamicReport(objParam).subscribe({
                            next: data => {
                                //console.log(data);                                                        
                                this._validationService.boolIsLoading = false;
                                this.stillGeneratingDetailed = false;
                                this.timeToHide = 3000;
                                this.isSent = true;
                            },
                            error: err => {
                                this._authService.errCheck(err);
                                console.log(err);                                
                                this.stillGeneratingDetailed = false;
                            }
                        });
                    }

                    this._validationService.boolSubmit = false;
                })
            })
        });
    }

    private _generateReportResume(reportStoredProcedure: string, reportEmailSubject: string, fileName: string, headers: any[]) {
        this._validationService.boolSubmit = true;
        
        if (this._hasInvalidParams())
            return false

        this._validationService.boolIsLoading = true;
        this.isWarning = false;
        this.isSent = false;
        this.timeToHide = 0;

        this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
            mailText = mailText.replace('$USER', this._authService.getAuthenticatedUser().name);
            mailText = mailText.replace('$REPORT', reportEmailSubject);
            this._translateService.get('REPORTS.PLANNED').subscribe(planned => {
                this._translateService.get('REPORTS.PERFORMED').subscribe(performed => {
                    let listIndicatorsSelected = [];
                    if (typeof this.paramsSelected['indicators'] != typeof undefined && this.paramsSelected['indicators'] &&
                        this.paramsSelected['indicators'] != '') {
                        listIndicatorsSelected = this.paramsSelected['indicators'].split(',');
                    }
        
                    let indicatorsList = this._emptyDefaultValue(this.paramsSelected['indicators'], null);
                    if (indicatorsList && indicatorsList && (this._areAllSelected(listIndicatorsSelected, this.indicatorsList))) {
                        indicatorsList = null;
                    }
        
                    let logbookList = null;
                    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['logbooks']), this.logbookList))) {
                        logbookList = this._emptyDefaultValue(this.paramsSelected['logbooks'], null);
                    }
        
                    let areaCheckedList = null;
                    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['areas_forms']), this.areaCheckedList))) {
                        areaCheckedList = this._emptyDefaultValue(this.paramsSelected['areas_forms'], null);
                    }
        
                    let usersList = null;
                    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['users']), this.userList))) {
                        usersList = this._emptyDefaultValue(this.paramsSelected['users'], null);
                    }
        
                    let formsList = null;
                    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['forms']), this.formsList))) {
                        formsList = this._emptyDefaultValue(this.paramsSelected['forms'], null);
                    }
        
                    let locationsList = this._emptyDefaultValue(this.paramsSelected['locations'], null);
                    let objParam = {
                        cod_user: this._authService.getAuthenticatedUser().id,
                        api_url: this.reportsApiUrl,
                        str_report_subject: reportEmailSubject,
                        str_proc_exec: reportStoredProcedure,
                        arr_proc_params_search: [
                            {
                                'pr_cods_locations': locationsList,
                                'pr_cod_indicators': indicatorsList,
                                'pr_area': areaCheckedList,
                                'pr_cod_diario_bordo': logbookList,
                                'pr_cod_formularios': formsList,
                                'pr_cod_users': usersList,
                                'pr_dat_ini': this.dat_start,
                                'pr_dat_fim': this.dat_finish,
                                "pr_planned_label": planned,
                                "pr_performed_label": performed
                            }
                        ],
                        arr_headers: headers,
                        isJsonResultSet: false,
                        name_of_file_to_download: fileName,
                        str_column_dynamic: 'str_columns',
                        str_worker_link: environment().workerReportPath,
                        str_mail_text: mailText
                    };
                    let objParamToLog =  {
                        cod_user: this._authService.getAuthenticatedUser().id,
                        api_url: this.reportsApiUrl,
                        reportTab: 3,
                        str_report_subject: reportEmailSubject,
                        str_proc_exec: reportStoredProcedure,
                        arr_proc_params_search: [
                            {
                                'pr_world_selected': this.paramsSelected['worlds'],
                                'pr_regionals_selected': this.paramsSelected['regionals'],
                                'pr_locations_selected': this.paramsSelected['locations'],
                                'pr_checklist_selected': this.paramsSelected['logbooks'],
                                'pr_forms_selected': this.paramsSelected['forms'],
                                'pr_users_selected': this.paramsSelected['users'],
                                'pr_dat_ini': this.dat_start,
                                'pr_dat_fim': this.dat_finish,
                                'pr_is_collab': this.bol_isCollab,
                                'pr_is_consolidated': true,
                                'pr_cod_user': this._authService.getAuthenticatedUser().id
                            }
                        ],
                        arr_headers: headers,
                        isJsonResultSet: false,
                        name_of_file_to_download: fileName,
                        str_column_dynamic: 'str_columns',
                        str_worker_link: environment().workerReportPath,
                        str_mail_text: mailText
                    };

                    this.actualObjParam = objParamToLog;

                    if(this.reportService.isSameParameters(this.actualObjParam, this.lastObjParamToLogConsolidated)){
                        var seconds = 300;
                        var now = new Date().getTime();
                        if(typeof this.timeLastConsolidatedReport != typeof undefined){
                            var milisec_diff = Math.abs(this.timeLastConsolidatedReport.getTime() - now);
                            seconds = Math.floor(milisec_diff / 1000);
                        }
            
                        if(seconds < 300){
                            this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
                            this._validationService.boolIsLoading = false;
                            this.stillGeneratingConsolidated = false;
                            return;
                        }
                        else{
                            this.timeLastConsolidatedReport = new Date();
                        }
                        
                        this._validationService.boolIsLoading = false;
                    }
                    else{
                        this.lastObjParamToLogConsolidated = this.actualObjParam;            
                        this.timeLastConsolidatedReport = new Date();
                    }
            
                    this.stillGeneratingConsolidated = true;
            
                    this.reportService.postLogReports(objParamToLog).subscribe({
                        next: data => {
                        },
                        error: err => {
                            this._authService.errCheck(err);
                        }
                    });
                    //can not do subscribe because rabbit mq does not return a response
                    this._reportService.postDynamicReportResume(objParam).subscribe({
                        next: data => {
                            //console.log(data);
                            this._validationService.boolIsLoading = false;
                            this.stillGeneratingConsolidated = false;
                            this.timeToHide = 3000;
                            this.isSent = true;
                        },
                        error: err => {
                            this._authService.errCheck(err);
                            console.log(err);
                            this.stillGeneratingConsolidated = false;
                        }
                    });
                    
                    this._validationService.boolSubmit = false;
                })
            })
        });
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    private _showWarning(message, value = null) {
        this.isWarning = true;
        this._translateService.get(message).subscribe(translate => {
            this.messageWarning = translate;
        });
    }

    hideMessage() {
        this.isSent = false;
    }

    private _isInvalidDateRange() {
        let selectedForms = this._getAsArray(this.paramsSelected['forms'])
        let selectedLocations = this._getAsArray(this.paramsSelected['locations'])
        let isValidFormRange = selectedForms.length < 101 && selectedForms.length > 0
        let isGreaterThan90Allowed = selectedLocations.length < 101 || isValidFormRange || 
            (selectedLocations.length < 101 && isValidFormRange)
        let limit = isGreaterThan90Allowed ? 185 : 95
        let reportRangeDiffInDays = Math.round(this._reportRangeDiffInDays())
        return (reportRangeDiffInDays < 0) || (reportRangeDiffInDays >= limit)
    }

    private _reportRangeDiffInDays() {
        let startDate = (new Date(this.dat_start)).getTime()
        let endDate = (new Date(this.dat_finish)).getTime()
        return (endDate - startDate) / (1000 * 3600 * 24)
    }

    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReport(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'DYNAMICFORMS.MAIL_SUBJECT',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'DYNAMICFORMS.AREA',
            'REPORTS.USERS',
            'DYNAMICFORMS.USER_ID',
            'LOGBOOK.TYPE',
            'LOGBOOK.STR_LOGBOOK',
            'DYNAMICFORMS.FORM',
            'DYNAMICFORMS.SHOW.NON_COMPLIANCE_PERCENT',
            'LOGBOOK.DATE_EXPECTED',
            'LOGBOOK.DATE_HELD',
            'DYNAMICFORMS.COMMENTS',
            'LOGBOOK.ATTACHMENT',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'DYNAMICFORMS.MAIL_SUBJECT',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['DYNAMICFORMS.MAIL_SUBJECT'];
            this.strFileNameDownload = translations['DYNAMICFORMS.MAIL_FILE_NAME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

    private _translateReportCollab(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'DYNAMICFORMS.MAIL_SUBJECT',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'LOGBOOK.AREA',
            'GENERAL.SUBAREA',
            'REPORTS.USERS',
            'DYNAMICFORMS.USER_ID',
            'LOGBOOK.TYPE',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.SHIFT',
            'DYNAMICFORMS.FORM',
            'DYNAMICFORMS.SHOW.NON_COMPLIANCE_PERCENT',
            'LOGBOOK.DATE_EXPECTED',
            'LOGBOOK.DATE_HELD',
            'DYNAMICFORMS.COMMENTS',
            'LOGBOOK.ATTACHMENT',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'DYNAMICFORMS.MAIL_SUBJECT',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['DYNAMICFORMS.MAIL_SUBJECT'];
            this.strFileNameDownload = translations['DYNAMICFORMS.MAIL_FILE_NAME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }


    /**
     * This function translate all columns names, sheet and mail subject according language
     * @param fnCallback callback to execute report
     */
    private _translateReportResume(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'DYNAMICFORMS.MAIL_SUBJECT_RESUME',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME_RESUME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'LOGBOOK.AREA',
            'LOGBOOK.STR_EMPLOYEE',
            'DYNAMICFORMS.USER_ID',
            'LOGBOOK.TYPE',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.TOTAL_FORECAST',
            'LOGBOOK.TOTAL_PERFORMED',
            'REPORTS.STR_TOTAL_PREC_FORM',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'DYNAMICFORMS.MAIL_SUBJECT_RESUME',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME_RESUME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['DYNAMICFORMS.MAIL_SUBJECT_RESUME'];
            this.strFileNameDownload = translations['DYNAMICFORMS.MAIL_FILE_NAME_RESUME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    }

    private _translateReportCollabResume(fnCallback) {
        this.strReportSubject = '';
        this.strFileNameDownload = '';
        this.arrHeaders = [];

        const observables = [
            'DYNAMICFORMS.MAIL_SUBJECT_RESUME',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME_RESUME',  // strFileNameDownload
            'REPORTS.WORLDS',
            'REPORTS.REGIONALS',
            'REPORTS.LOCATIONS',
            'LOGBOOK.AREA',
            'GENERAL.SUBAREA',
            'LOGBOOK.TYPE',
            'LOGBOOK.STR_LOGBOOK',
            'LOGBOOK.SHIFT',
            'LOGBOOK.TOTAL_FORECAST',
            'LOGBOOK.TOTAL_PERFORMED',
            'REPORTS.STR_TOTAL_PREC_FORM',
        ];

        // keys to skip on arrHeaders property, as they should be assigned to other properties
        const skip = [ 
            'DYNAMICFORMS.MAIL_SUBJECT_RESUME',    // strReportSubject
            'DYNAMICFORMS.MAIL_FILE_NAME_RESUME',  // strFileNameDownload
        ]

        this._translateService.get(observables).subscribe(translations => {

            this.strReportSubject = translations['DYNAMICFORMS.MAIL_SUBJECT_RESUME'];
            this.strFileNameDownload = translations['DYNAMICFORMS.MAIL_FILE_NAME_RESUME'];

            for(const key of observables){
                if(!skip.includes(key)){
                    this.arrHeaders.push(translations[key])
                }
            }
            if (fnCallback) {
                fnCallback();
            }
        });
    };

    validatePeriod(dateInit, dateEnd): boolean {
        const perioudCount = moment(dateEnd).diff(moment(dateInit), 'days');
        this.perioudCount = perioudCount;
        
        if(moment(dateEnd).isBefore(dateInit)){
            this.validatePeriodDate = false;
            return false;
        } else if(perioudCount > FORMS_REPORT_VALIDATION.MAX_PERIOD_DAYS){
            this.validatePeriodDate = false
            return false;
        };

        this.validatePeriodDate = true;
        return true;
    }

    onPeriodDateChanges() {
        this.validatePeriod(this.dat_start, this.dat_finish);
    };

    set dat_start(value: any) {
        this.dateInit = value;
    }

    set dat_finish(value: any) {
        this.dateLimit = value        
    }

    get dat_start() {
        return this.dateInit;
    }

    get dat_finish() {
        return this.dateLimit;
    }
}