import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { EventEmitter, Input, Output } from '@angular/core';
import { FiveWhyService } from '../services/five-why.service';
import { AuthService } from 'app/main/access/services/auth.service';
import { NotificationService } from 'app/main/services/notification.service';
import { MeetingsService } from 'app/meetings/services/meetings.service';


@Component({
  selector: 'app-fivewhy-participants',
  templateUrl: './fivewhy-participants.component.html',
  styleUrls: ['./fivewhy-participants.component.css']
})
export class FivewhyParticipantsComponent implements OnInit {

  @Input('showSaveParticipants') showSaveParticipants;
  @Input('blockInsertParticipants') blockInsertParticipants;
  @Output("response") newParticipantsAdded = new EventEmitter<boolean>();

  cod_user: any;
  showError: boolean;
  errorMessage: string;
  str_user_tmp: string;
  arr_participants: any;
  obj_ta_participant: any;
  showFormParticipants: boolean;
  widthToCopy: string;

  constructor(
    private _authService: AuthService,
    private _notify: NotificationService,
    private _fiveWhyService: FiveWhyService,
    private _meetingsService: MeetingsService,
    private _translateService: TranslateService
  ) {
    this.cod_user = this._authService.getAuthenticatedUser().id;

    this._translateService.get('MEETING.USER_SEL').subscribe(translate => {
      this.obj_ta_participant = {
        type: 'dynamic',
        placeHolder: translate,
        valueProperty: 'cod_user',
        searchProperty: 'str_name',
        hasImage: true,
        imageProperty: 'str_img_path',
        onSelect: (obj_selected: any) => {
          this.str_user_tmp = '';
        
          if (!this.showSaveParticipants.arr_participants[0]) {
            this.showSaveParticipants.arr_participants.push({
              cod_fivewhys_status: 1,
              arr_obj_user: [],
            });
          }

          let existInArray = this.showSaveParticipants.arr_participants[this.showSaveParticipants.cod_status - 1].arr_obj_user.some((user) => user.cod_user === obj_selected.cod_user);
          let maxParticipants = this.showSaveParticipants.arr_participants[this.showSaveParticipants.cod_status - 1].arr_obj_user.length == 5;

          if (maxParticipants && !existInArray) {
            this._notify.error({ text: '5WHY.MAX_PARTICIPANT_MESSAGE', translate: true });
          } else {
            if (!existInArray) {
              this.showSaveParticipants.arr_participants[this.showSaveParticipants.cod_status - 1].arr_obj_user.push({
                cod_user: obj_selected.cod_user,
                str_name: obj_selected.str_name,
                isNewParticipant: true
              })
            } 
          }
        },
        asynchDataCall: (value: string, cb: any) => {
          this.searchUsers(cb, value);
        },
        onBlur: (item: any) => {
          this.str_user_tmp = '';
        },
      };
    });
  }

  ngOnInit(): void {
    this.cod_user = this._authService.getAuthenticatedUser().id;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // work around to maintain same width - get css props from add whys button and copy width every time screen size changes.
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('top-buttons')[0] , null).width.replace('px', '');
  }

  searchUsers(cb, str_term) {
    const $this = this;
    let params = {
      'str_term': str_term, 
      'str_path': environment().uploadGetFilesApiUrl + '/',
      'cod_user': this.cod_user, 
      'cod_location': this.showSaveParticipants.cod_location
    }

    this._fiveWhyService.searchParticipantList(params).subscribe((result: any) => {
      cb(result.content.results);
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
  }

  removeParticipants(cod_partipant, isNewParticipant, index) {
      this.showSaveParticipants.arr_participants[this.showSaveParticipants.cod_status - 1].arr_obj_user.splice(index, 1);
      if (!isNewParticipant) {    
      let params: any;
      params = {
        'cod_user': this._authService.getAuthenticatedUser().id,
        'cod_fivewhys_status': this.showSaveParticipants.cod_status,
        'cod_fivewhy': this.showSaveParticipants.cod_fivewhy,
        'cod_participants': cod_partipant
      };
      this._fiveWhyService.deleteFivewhyParticipant(params).subscribe(res => {
        if (res.type !== 'success') {
          this._showErrorMessage(res.content.message);
        }
      }, err => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      });
    }
  }

  hideParticipants() {
    if (this.showFormParticipants) {
      this.showFormParticipants = false;
    } else {
      this.showFormParticipants = true;
    }
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

}
