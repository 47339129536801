import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { TranslateObserverService } from '../../services/translate-observer.service';
import { linkfyText } from '../../helpers/general-helper';

@Component({
    selector: 'content-editable',
    templateUrl: './content-editable.component.html',
    styleUrls: ['./content-editable.component.css'],
})
export class ContentEditableComponent implements OnChanges {

    @HostListener('keydown', ['$event'])
    handleKeyDown(event: any) {
        if (
            this.maxlength != null
            && event.target.innerText.length > this.maxlength
            && event.keyCode != 8 
            && event.keyCode != 37 
            && event.keyCode != 38 
            && event.keyCode != 39 
            && event.keyCode != 40
            || (event.keyCode == 13 && !this.isTextArea)
        ) {
            event.preventDefault();
        }
    }

    @ViewChild('content_editable') content_editable: ElementRef;

    @Input() text: string = '';
    @Input() isDisable: boolean = false;
    @Input() isTextArea: boolean = true;
    @Input() linkfy: boolean = true;
    @Input() maxlength: number = null;
    @Input() inputDelay: number = 50;
    @Input() placeholder: string = null;
    @Input() classes: string = '';

    @Output() textChange = new EventEmitter<string>();
    @Output() onInput = new EventEmitter<string>();

    html: string = '';
    translated: boolean = false;
    sendTimeout: any = null;
    childReady: boolean = false;
    findChildInterval: any = null;

    constructor(private _translateObserver: TranslateObserverService, private _sanitizer: DomSanitizer) {
        this._translateObserver.translated.subscribe(x => this.translated = x);
        this.findChildInterval = setInterval(() => {            
            if (this.content_editable) {
                if (this.content_editable.nativeElement) {
                    this.childReady = true;
                    clearInterval(this.findChildInterval);
                    this.content_editable.nativeElement.innerHTML = this.html;
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) : void {
        if (changes.text) {
            this.prepareHtml();
        }
    }

    public blur($event) : void {
        this.textChange.emit($event.target.innerText);
        this.prepareHtml();
    }

    public input($event) : void {
        if (this.onInput == null) return;

        if (this.sendTimeout != null) {
            clearTimeout(this.sendTimeout);
        }
        
        this.sendTimeout = setTimeout(() => {
            this.onInput.emit($event.target.innerText);         
        }, this.inputDelay);
    }

    private prepareHtml() : void {
        const textPurify = DOMPurify.sanitize(this.text);
        this.html = (
            this.linkfy 
                ? linkfyText(textPurify)
                : textPurify
            ).replace(/\n/g, '<br>');

        if (this.childReady) {
            this.content_editable.nativeElement.innerHTML = this.html;
        }
    }

    public getClasses() : string {
        return `
            ${this.classes} 
            ${this.isTextArea ? 'text-area' : ''} 
            ${(this.isDisable || this.translated) ? 'disabled' : ''}
        `;
    }

    public focus(): void {
        setTimeout(() => this.placeCaretAtEnd(this.content_editable.nativeElement));
    }

    private placeCaretAtEnd(el: any): void {
        el.focus();

        const body = <any>document.body;

        if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
            let range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            let sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof body.createTextRange != "undefined") {
            let textRange = body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }

}
