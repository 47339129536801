import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {ActionsService} from '../services/actions.service';
import {AuthService} from '../../main/access/services/auth.service';
import {SocketService} from '../../main/services/socket.service';


import {FilterByStatus} from '../meetings-actions-list/meetings-actions-list.pipes';
import { linkfyText } from '../../helpers/general-helper';


@Component({
  selector: 'app-meetings-actions-list',
  templateUrl: './meetings-actions-list.component.html',
  styleUrls: ['./meetings-actions-list.component.css'],
  animations: [
    trigger('meetingsActionList', [
      state('inactive', style({
        display: 'none'
      })),
      state('active',   style({
        display: ''
      })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})
export class MeetingsActionsListComponent implements OnInit, OnChanges {
  @Input() date = new Date();
  @Input() status = '';
  @Input() title = '';
  @Input() actions_status = '';
  @Input() filter_finished = true;
  @Input() isParticipant: boolean;
  @Input() arr_actions = [];
  @Input() arr_filters = [];
  @Input() str_img_path = '';
  @Input() str_agrupament = '';
  @Input() updateListData;
  @Input() state: string;
  @Input() openIndex;
  @Input() lastId;
  @Input() index;
  @Input() opened = false
  @Output() stateReturn = new EventEmitter<Object>();
  @Output() taskSelected = new EventEmitter<Object>();
  @Output() filterSelected = new EventEmitter<Object>();
  @Output() actions_length = new EventEmitter<number>();  
  
  setOpenedId(id: number) {
    setTimeout(() => {
      this.openedId = Number(id ?? 0);

      let i = -1;
      const action = this.actionsGroup.find((action, index) => {
        if (action.cod_actions == this.openedId) {
          this.lastId = action.cod_actions;
          i = index;
          return action;
        }
      });
      if (action) {
        this.index = i;
        this.openIndex = i;
        this.toggleActionsList('active');
        this.openAction(action.cod_actions, action.dat_action_alt, i);

        setTimeout(() => {
          const element = document.getElementById(`action-${action.cod_actions}`);
          const y = element.getBoundingClientRect().top + window.scrollY;
          window.scroll({ top: y - 300, behavior: 'smooth' })
        }, 10);
      }
    }, 50);
  }


  actions = [];
  actionsGroup = [];
  @Input() openedId = 0;
  cod_user = this._authService.getAuthenticatedUser().id;
  qtdActionsFiltered = 0;
  qtdList = 50;
  emptySpaceRegex = new RegExp("&nbsp;", "g");

  constructor(private _actionsService:ActionsService, private _authService: AuthService, private _socketService:SocketService) {
    this.state = 'inactive';
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateList();
    if (changes.opened && changes.opened.currentValue) {
      this.toggleActionsList('active');
    }
    if (changes.openedId && changes.openedId.currentValue) {
      this.setOpenedId(changes.openedId.currentValue);
    }
  }

  prepareTitle(title){
    if(!title.includes('<a')){
      return linkfyText(title)
    }else{
      return title
    }
  }
  /**
   * @author Guilherme Renato Uller
   *
   * @param action{object}
   */
  openAction(cod_actions, dat_action_alt, index) {
    this.openedId = cod_actions;
    const action = {
      'type' : 'action',
      'action' : {
        cod_actions: cod_actions,
        dat_action_alt: dat_action_alt,
      },
      'openIndex': index,
      'lastId': this.index
    };
    this.taskSelected.emit(action);
  }

  /**
   * @author Guilherme Renato Uller
   *
   * @param action{object}
   */
  onSelectHastag(word, action, i) {
    if (word.includes('#')) {
      const objEvent = {
        str_filter: 'description',
        str_value: word
      };
      this.filterSelected.emit(objEvent);
    } else {
      this.openAction(action.cod_actions, action.dat_action_alt, i);
    }
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  toggleActionsList(state: string = null) {
    this.state = state ?? this.state != 'active' ? 'active' : 'inactive';
    this.stateReturn.emit(this.state);
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  incrementQtdList() {
    this.qtdList = this.qtdList + this.qtdList;
  }

  createActionHtmlId(action): string {
    const htmlId: string = `action-${action.cod_actions}`;
    action.html_id = htmlId;

    return htmlId;
  }

  getActionsClass(cod_action: number, cod_actions_status: number, index: number, str_class_hashtags: any = null): any {
      let classes = [str_class_hashtags || ''];
      if (cod_action == this.openedId && index == this.lastId) {
        switch (cod_actions_status) {
          case 8: classes.push('inner-shadow-red'); break;
          case 7: classes.push('inner-shadow-blue'); break;
          case 3: classes.push('inner-shadow-green'); break;
          case 2: classes.push('inner-shadow-yellow'); break;
          default: classes.push('inner-shadow'); break;
        }
      }
      switch (cod_actions_status) {
        case 8: classes.push('tr-danger'); break;
        case 7: classes.push('tr-finished'); break;
        case 3: classes.push('tr-success'); break;
        case 2: classes.push('tr-in-progress'); break;
        default: break;
      }
      return classes;
  }

  getActionsBorderClass(cod_actions_status: number): string {
    switch (cod_actions_status) {
      case 8: return 'border-left-danger';
      case 7: return 'border-left-finished';
      case 3: return 'border-left-success';
      case 2: return 'border-left-in-progress';
      default: return 'border-left-default'; 
    }
  }

  /**
   * @author Guilherme Renato Uller
   *
   *
   */
  updateList() {
    if (this.arr_actions && this.arr_actions.length >= 1) {
      this.actions = FilterByStatus.prototype.transform(this.arr_actions, this.filter_finished , null, null);
      this.actions_length.emit(this.actions.length);
      this.filterActions();
    } 
    else if (this.status != '') {
      switch (this.status) {
        case 'date':
          this._actionsService.getActionsDate(this.cod_user, this.date).subscribe(
            data => {
              if (data.code === 888 && data.content.code === 888) {
                this.actions = FilterByStatus.prototype.transform(data.content.results, this.filter_finished , null, null);
                this.actions_length.emit(this.actions.length);
              } else {
                this.actions = [];
                this.actions_length.emit(0);
                console.log(data.content);
              }
              this.filterActions();
            },
            err => { this._authService.errCheck(err); console.log(err); }
          );
          break;
        case 'future':
          this._actionsService.getActionsFuture(this.cod_user).subscribe(
            data => {
              if (data.code === 888 && data.content.code === 888) {
                this.actions = FilterByStatus.prototype.transform(data.content.results, this.filter_finished , null, null);
                this.actions_length.emit(this.actions.length);
              } else {
                this.actions = [];
                this.actions_length.emit(0);
                console.log(data.content);
              }
              this.filterActions();
            },
            err => { this._authService.errCheck(err); console.log(err); }
          );
          break;
        case 'overdue':
          this._actionsService.getActionsOverdue(this.cod_user).subscribe(
            data => {
              if (data.code === 888 && data.content.code === 888) {
                this.actions = FilterByStatus.prototype.transform(data.content.results, this.filter_finished , null, null);
                this.actions_length.emit(this.actions.length);
              } else {
                this.actions = [];
                this.actions_length.emit(0);
                console.log(data.content);
              }
              this.filterActions();
            },
            err => { this._authService.errCheck(err); console.log(err); }
          );
          break;
        case 'all':
          this._actionsService.getAllActions(this.cod_user).subscribe(
            data => {
              if (data.code === 888 && data.content.code === 888) {
                this.actions = FilterByStatus.prototype.transform(data.content.results, this.filter_finished , null, null);
                this.actions_length.emit(this.actions.length);
              } else {
                this.actions = [];
                this.actions_length.emit(0);
                console.log(data.content);
              }
              this.filterActions();
            },
            err => { this._authService.errCheck(err); console.log(err); }
          );
          break;
        default:
          console.log('no actions to list');
          this.arr_actions = [];
          this.actions_length.emit(0);
          this.filterActions();
          break;
      }
    } else {
      console.log('no parameters to list actions');
      this.actions = [];
      this.actions_length.emit(0);
    }
  }

  filterActions() {
    if (typeof this.arr_filters == 'undefined' || typeof this.actions == 'undefined') return;

    this.actionsGroup = this._actionsService.filterActions(this.arr_filters, this.actions, this.filter_finished);

  }

  trackByFn(index, item) {
    return index;
  }

  setCritic(action) {
    if (!this.isParticipant) return;
    
    action.bol_is_critic = (action.bol_is_critic == 1 ? 0 : 1);
    action.cod_user = this.cod_user;

    this._actionsService.putCriticAction(action).subscribe(res => {
      if (res.type != 'error') {
          // console.log('salvando');
          this._socketService.refreshMeetingActions({arr_meetings: res.content.results.meetings_sp_up_action_critic});
      }
    }, err => {
        const e = JSON.parse(err._body);
        console.log(e.content);
        this._authService.errCheck(err);
    } );
  }
}
