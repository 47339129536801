import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionInputComponent } from '../selection-input/selection-input.component';

@Component({
    selector: 'multi-selection-input',
    templateUrl: './multi-selection-input.component.html',
    styleUrls: [
        './../forms-input/forms-input.component.css', 
        './../selection-input/selection-input.component.css', 
        './multi-selection-input.component.css'
    ]
})

export class MultiSelectionInputComponent extends SelectionInputComponent<string> {
    
    constructor() {
        super();
    }

    public saveMulti(data: any) : void {
        this.question.selected_options = data.selectedOptions;
        this.data = this.question.selected_options.map(option => option.cod_form_question_choice).join(',');
        super.save();
    }

    protected validationRule() : boolean {
        const text_answer = 
            this.question.arr_choices
                .filter(i => i.bol_answer_checked)
                .map(i => i.cod_form_question_choice)
                .join(',');

        return text_answer != '' || (this.data != '' && this.data != null);
    }

}
