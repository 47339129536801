import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "component-question-answer-button",
  templateUrl: "./question-answer-button.component.html",
  styleUrls: ["./question-answer-button.component.css"],
})
export class QuestionAnswerButtonComponent {
  @Input("label") label: string = "";
  @Input("disabled") disabled: boolean
  @Input("answerValue") answerValue: boolean = false;
  @Output("onAnswerChanged") answerChanged = new EventEmitter<boolean>();
  @Input("customYesLabel") customYesLabel: string;
  @Input("customNoLabel") customNoLabel: string;
  @Input("defaultSize") defaultSize: boolean = true;
 
  constructor() {

  }


  onToggleAnswer(eventValue) {
    this.answerValue = eventValue;
    this.answerChanged.emit(eventValue);
  }
}
