// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    padding-top: 10px;

    /* BREAK HYPERLINKS */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.text-comment {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/meetings-actions-details/input-comment-action/input-comment-action.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,sBAAsB;IAGtB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".message {\n    padding-top: 10px;\n\n    /* BREAK HYPERLINKS */\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n    -ms-word-break: break-all;\n    word-break: break-all;\n    word-break: break-word;\n    -ms-hyphens: auto;\n    -moz-hyphens: auto;\n    -webkit-hyphens: auto;\n    hyphens: auto;\n}\n\n.text-comment {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
