import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';

@Injectable()
export class UsersService {

  constructor(private _mainService: MainService) {
  }

  getAreas(cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    return this._mainService.get('/account/areas' + '/' + cod_user + '/' + dsc_page_privilege);
  }

  postMissingAreas(params){
    return this._mainService.post('/account/set-missing-areas', params);
  }

  getJobs(cod_user): Observable<any> {// A_U, get, admin
    return this._mainService.get('/account/jobs/' + cod_user);
  }

  getTypes(cod_user): Observable<any> {// A_U, get
    return this._mainService.get('/account/types/' + cod_user);
  }


  getProfiles(cod_user): Observable<any> {// A_U, get
    return this._mainService.get('/account/profiles/' + cod_user);
  }

  // getVariations(): Observable<any> {
  //   return this._mainService.get('/account/variations');
  // }

  getLogbooks(params): Observable<any> {// A_U, get
    return this._mainService.get('/account/logbooks' + '/' + params.cod_user + '/' + params.dsc_page_privilege);
  }

  getLogbooksyLocation(params): Observable<any> {// A_U, get
    return this._mainService.get('/account/logbooks' + '/' + params.cod_user + '/' + params.cod_location + '/' + params.dsc_page_privilege);
  }

  getSingleUser(cod_user, cod_id): Observable<any> {// A_U, get
    return this._mainService.get('/account/user-single/' + cod_user + '/' + cod_id);
  }

  getUsersByFilter(params): Observable<any> {// A_U, get
    const path = '/account/users/' + params.limit + '/' + params.offset + '/' + params.str_filter + '/' + params.cod_user + '/' + params.dsc_page_privilege+ '/' + params.str_type;
    return this._mainService.get(path);
  }

  postNewUser(params): Observable<any> {// A_U, ok
    const path = '/account/new-user';
    return this._mainService.post(path, params);
  }

  updateUser(params): Observable<any> {// A_U, ok
    const path = '/account/update-user';
    return this._mainService.put(path, params);
  }

  deleteUser(params): Observable<any> {// A_U, ok
    const path = '/account/' + params.cod_user + '/' + params.cod_user_del;
    return this._mainService.delete(path);
  }

  getWorlds(cod_user, dsc_page_privilege): Observable<any> {// A_M, A_U, get, muitos
    return this._mainService.get('/account/worlds' + '/' + cod_user + '/' + dsc_page_privilege);
  }

  getRegionals(world, cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    const path = '/account/regionals/' + JSON.stringify(world) + '/' + cod_user + '/' + dsc_page_privilege;
    return this._mainService.get(path);
  }

  getLocations(regional, cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    const path = '/account/locations/' + JSON.stringify(regional) + '/' + cod_user + '/' + dsc_page_privilege;
    return this._mainService.get(path);
  }

  getAllAreaTypes(cod_user, dsc_page_privilege): Observable<any> {// A_U, get
    const path = '/account/area-types/' + cod_user + '/' + dsc_page_privilege;
    return this._mainService.get(path);
  }

  setLogLocationChange(params): Observable<any> {
    const path = '/account/set-log-location-change';
    return this._mainService.post(path, params);
  }

  getAresByUser(params): Observable<any>{
    const path = '/account/list-user-areas-array';
    return this._mainService.post(path,params);
  }

  listUserAreas(params): Observable<any>{
    const path = '/account/list-user-areas';
    return this._mainService.post(path,params);
  }

  setAresByUser(params): Observable<any>{
    const path = '/account/set-user-areas';
    return this._mainService.post(path,params);
  }

  delAresByUser(params): Observable<any>{
    const path = '/account/del-user-areas';
    return this._mainService.post(path,params);
  }

  getAreasByLocation(params): Observable<any>{
    const path = '/account/get-areas-by-location';
    return this._mainService.post(path, params);
  }

  getArrayContent(data: any) {
    return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }

}
