export interface FilterItem { 
    name: String, 
    text: String,
    value1?: String,
    value2?: String
};

export interface OrderItem { 
    name: string, 
    text: string,
};

export interface GroupItem { 
    name: String, 
    text: String,
};

export const DefaultFilters: {[key: string]: FilterItem} = {
    description:  { name: "description", text: "MEETING.FILTER_DESCRIPTION" },
    date:         { name: "date", text: "MEETING.FILTER_DATE" },
    criticity:    { name: "criticity", text: "MEETING.FILTER_CRITICITY" },
    owner:        { name: "owner", text: "MEETING.FILTER_OWNER" },
    status:       { name: "status", text: "MEETING.FILTER_STATUS" },
    indicator:    { name: "indicator", text: "MEETING.FILTER_INDICATOR" },
    department:   { name: "department", text: "MEETING.FILTER_DEPARTMENT" }, 
    sources:      { name: "sources", text: "MEETING.FILTER_SOURCE" },
    time:         { name: "time", text: "MEETING.FILTER_TIME" },
    column:       { name: "column", text: "MEETING.FILTER_COLUMN" },
};

export const DefaultOrder: {[key: string]: OrderItem} = {
    name:         { name: "name", text: "MEETING.ORDER_NAME" },
    date:         { name: "date", text: "MEETING.ORDER_DATE" },
    frequency:    { name: "frequency", text: "MEETING.ORDER_FREQUENCY" },
    description:  { name: "description", text: "MEETING.ORDER_DESCRIPTION" },
    criticity:    { name: "criticity", text: "MEETING.ORDER_CRITICITY" },
    owner:        { name: "owner", text: "MEETING.ORDER_OWNER" },
    role:         { name: "role", text: "MEETING.ORDER_ROLE" },
    participant:  { name: "participant", text: "MEETING.ORDER_PARTICIPANT" },
    actions:      { name: "actions", text: "MEETING.ORDER_ACTIONS" },
}

export const DefaultGroup: {[key: string]: GroupItem} = {
    status:       { name: "status", text: "MEETING.GROUP_STATUS" },
    owner:        { name: "owner", text: "MEETING.GROUP_OWNER" },
    hashtag:      { name: "hashtag", text: "MEETING.GROUP_HASHTAG" },
    columns:      { name: "columns", text: "MEETING.GROUP_COLUMN" },
}