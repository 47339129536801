import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UsersService } from 'app/admin/services/users.service';
import { ListAreasService } from '../list-areas/services/list-areas.service';
import { ListAreasParams } from './models/list-areas-params.model';
import { ListAreasSelectService } from './services/list-areas-select.service';

@Component({
  selector: 'app-list-areas-select',
  templateUrl: './list-areas-select.component.html',
  styleUrls: ['./list-areas-select.component.css']
})
export class ListAreasSelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() selected = [];
  @Input() params: ListAreasParams;
  @Input() isMultiple: boolean = false;

  isLoading: boolean = false;

  isMultiSelect: boolean = false;

  areaList = [];
  selectedAreas = [];

  loadingConfig: any = [];

  constructor(
    private _listAreasService: ListAreasSelectService,
    private _userService: UsersService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  searchAreas(params: ListAreasParams, cod_selected_areas: string[] = null, firstOpening: boolean = false) {
    this.isLoading = true;
    
    this._listAreasService.getListAreas(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.areaList = this._userService.getArrayContent(data);
        if (cod_selected_areas && cod_selected_areas.length > 0) {
          this.selectedAreas = this.areaList.filter(obj => cod_selected_areas.map(area => area.toString()).includes(obj['cod_area']));
        };

        if (firstOpening) {
          this.selectedAreas = [this.areaList[0]];
          this.selectionChanged.emit(this.selectedAreas);
        }
      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
  }

  toggleLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setSelectedAreas(areas: any[]) {
    this.selectedAreas = areas;
    this.cdr.detectChanges();
  }

  onSelection($event) {
    this.selectedAreas = $event?.selectedOptions;

    this.selectionChanged.emit($event?.selectedOptions);
  }

  clearList() {
    this.areaList = [];
    this.selectedAreas = [];
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }
}
