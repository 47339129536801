// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-content {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.number {
    line-height: 50px;
    color: #333;
    font-size: 24px;
    margin: 0 auto 10px;
}

.text {
    font-size: 16px;
    color: #333;
}

.analytic-field {
    padding-bottom: 20px;
}

.info-modal {
    padding: 0px 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/charts/analytic-info/analytic-info.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".info-content {\n    text-align: center;\n    background-color: #fff;\n    border-radius: 10px;\n    border: 1px solid #CCCCCC;\n    padding: 20px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.number {\n    line-height: 50px;\n    color: #333;\n    font-size: 24px;\n    margin: 0 auto 10px;\n}\n\n.text {\n    font-size: 16px;\n    color: #333;\n}\n\n.analytic-field {\n    padding-bottom: 20px;\n}\n\n.info-modal {\n    padding: 0px 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
