import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import {Observable, of, BehaviorSubject, takeWhile} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';

@Injectable()
export class SocketService {
    private state = {
        isConnected: false,
        data: null,
    };
    private _obj_socket;
    private _str_socket_url;
    private _obj_user;

    private _auth_socket_subject = new BehaviorSubject<boolean>(false);
    // private _auth_socket = false

    constructor(private _authService: AuthService) {
        this._str_socket_url = environment().socketApiUrl;
    }

    // connectSocket(accessToken: string) {
    // caller MUST have a getAuthToken(cb) function
    connectSocket(getAuthToken) {
        if (!this._obj_socket && !this.isAuthenticated() && this._authService.isAuthenticated()) {
            //this._auth_socket = true;
            this._obj_user = this._authService.getAuthenticatedUser();

            //INITIALIZE THE SOCKET CONNECTION TO EACH USER IN A SPECIFIC CHANNEL FOR NOTIFICATION PURPOUSES
            this._obj_socket = io(this._str_socket_url, {
                transports: ['websocket'],
                auth: (cb) => {
                    // this will get the most up to date token
                    getAuthToken((token) => {
                        // token = { token: "msal_auth_token" }
                        // console.log(token)
                        cb(token);
                    });
                },
                query: {
                    // cod_user: this._obj_user.id, // not needed anymore
                    session_id: this._obj_user.session_id,
                    // str_img_path: environment.uploadGetFilesApiUrl + '/', //not being used anywhere on socket
                    sso: 'false',
                }, 
                //closeOnBeforeunload: false
            });

            this._obj_socket.on('connect_error', (err) => {
                // console.log(err.message); // prints the message associated with the error
                setTimeout(() => {
                    this._obj_socket.connect(); // TODO limit retry attempts
                }, 1000);
            });

            this._obj_socket.on('connect', () => {
                this.state.isConnected = true;
                this._auth_socket_subject.next(true);
            });

            this._obj_socket.on('ping', (data) => {
                this.state.data = data;
                // console.log(this.state);
            });
        }
    }

    isAuthenticated() {
        // return this._auth_socket;
        return this._auth_socket_subject.getValue();
    }

    connectSuccess() {
        // if(this.isAuthenticated()){
        return of(this._obj_socket);
        // }
    }

    subscribeMeeting(cod_meeting) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                // sub.unsubscribe();
                this._obj_socket.emit('meeting subscribe', { cod_meeting: cod_meeting });
            }
        });
        return sub;
    }

    unsubscribeMeeting(cod_meeting) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('meeting unsubscribe', { cod_meeting: cod_meeting });
        }
    }

    refreshMeetingMessage(obj_message) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh meeting message', { obj_message: obj_message });
            }
        });
        return sub;
    }

    saveMeetingActions(arr_actions) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('save meeting actions', { arr_actions: arr_actions });
            }
        });
        return sub;
    }

    setActionNotificationVisible(obj_action_notification) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('set action notification visible', {
                    obj_action_notification: obj_action_notification,
                });
            }
        });
        return sub;
    }

    refreshMeetingColumns(obj_column) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh meeting column', { obj_column: obj_column });
            }
        });
        return sub;
    }

    subscribeToColumns() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('meeting columns', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    refreshMeetingParticipant(obj_participant) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh meeting participant', { obj_participant: obj_participant });
            }
        });
        return sub;
    }

    subscribeToParticipants() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('meeting participants', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeToMessages() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('meeting messages', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeToActions() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('meeting actions', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeToActionsCreated() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('actions created', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeToNotifications() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('user notifications', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeAction(cod_action) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('action subscribe', { cod_action: cod_action });
            }
        });
        return sub;
    }

    unsubscribeAction(cod_action) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('action unsubscribe', { cod_action: cod_action });
        }
    }

    saveAction(obj_action) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('save action', { obj_action: obj_action });
            }
        });
        return sub;
    }

    subscribeToActionInfo() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('action info', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    subscribeToActionMessage() {
        let observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('action messages', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    refreshActionMessage(obj_message) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh action message', { obj_message: obj_message });
            }
        });
        return sub;
    }

    deleteActionMessage(obj_message, str_path) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('delete action message', { obj_message: obj_message, str_path: str_path });
            }
        });
        return sub;
    }

    putActionMessage(obj_message, str_path) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('put action message', { obj_message: obj_message, str_path: str_path });
            }
        });
        return sub;
    }

    refreshMeetingActions(obj_refresh) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh meeting actions', { obj_refresh: obj_refresh });
            }
        });
        return sub;
    }

    refreshUserNotifications(obj_notifications) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh user notifications', { obj_notifications: obj_notifications });
            }
        });
        return sub;
    }

    // CDD

    subscribeCdd(cod_cdd) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('cdd subscribe', { cod_cdd: cod_cdd });
            }
        });
        return sub;
    }

    unsubscribeCdd(cod_cdd) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('cdd unsubscribe', { cod_cdd: cod_cdd });
        }
    }

    subscribeCddParticipants() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('cdd participants', (data) => {
                        observer.next(data);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    refreshCddParticipant(obj_participant) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh cdd participant', { obj_participant: obj_participant });
            }
        });
        return sub;
    }

    joinUserPrivileges(user) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('join user privigeles', user);
            }
        });
        return sub;
    }

    refreshUserPrivileges(user) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('refresh user privigeles', user);
            }
        });
        return sub;
    }

    subscribeUserPrivileges() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('user privigeles', (user) => {
                        observer.next(user);
                    });
                    return () => {
                        this._obj_socket.disconnect();
                    };
                }
            });
        });
        return observable;
    }

    joinProblemTerminator(cod_fivewhy, userObj) {

        // socket was not joining because it was not yet connected and authenticated
        // which can happen on a page reload/refresh, the subject subscription below can be used to solve this
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {

            if (isAuth) {
                this._obj_socket.emit('join problem terminator', cod_fivewhy, userObj);
            }
        });
        return sub; // returning subscription, in case the caller needs to unsubscribe beforehand
    }

    unsubscribeProblemTerminator(cod_fivewhy, userObj) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('problem terminator unsubscribe', cod_fivewhy, userObj);
        }
    }

    subscribeProblemTerminator() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('problem terminator', (cod_fivewhy) => {
                        observer.next(cod_fivewhy);
                    });
                    return () => {
                        this._obj_socket.removeListener('problem terminator');
                    };
                }
            });
        });
        return observable;
    }

    subscribeDisconnectEvent() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('disconnect', () => {
                        observer.next(null);
                    });
                    return () => {
                        this._obj_socket.removeListener('disconnect');
                    };
                }
            });
        });
        return observable;
    }

    subscribeChecklistCopies() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on('join checklist copies', (socket_msg) => {
                        observer.next(socket_msg);
                    });
                    return () => {
                        this._obj_socket.removeListener('join checklist copies');
                    };
                }
            });
        });
        return observable;
    }

    unsubscribeChecklistCopies(cod_logbooks, userObj) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('checklist copies unsubscribe', cod_logbooks, userObj);
        }
    }

    joinChecklistCopies(cod_logbooks, userObj) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('join checklist copies', cod_logbooks, userObj);
            }
        });
        return sub;
    }

    joinHandoverNotes(cod_handover_notes, userObj) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('join handover notes', cod_handover_notes, userObj);
            }
        });
        return sub;
    }

    joinProblemTerminatorQueue(userObj) {
        const observable = new Observable(observer => {
            if (this.isAuthenticated()) {
                this._obj_socket.emit('subscribe problem terminator queue changes', userObj);
                this._obj_socket.on('problem terminator queue changes', (socket_msg) => {
                    observer.next(socket_msg);
                });
                return () => {
                    this._obj_socket.removeListener('queue changes');
                };
            }
        });
        return observable;
    }

    editHandoverNotes(cod_handover_notes, data, userObj) {
        const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
            if (isAuth) {
                this._obj_socket.emit('handover notes edit', {
                    cod_handover_notes,
                    data,
                    userObj,
                });
            }
        });
        return sub;
    }

    subscribeHandoverNotesEdit() {
        const observable = new Observable((observer) => {
            const sub = this._auth_socket_subject.pipe(takeWhile((isAuth) => isAuth === false, true)).subscribe((isAuth) => {
                if (isAuth) {
                    this._obj_socket.on(`handover notes edit`, (socket_msg) => {
                        observer.next(socket_msg);
                    });
                    return () => {
                        this._obj_socket.removeListener(`handover notes edit`);
                    };
                }
            });
        });
        return observable;
    }

    unsubscribeHandoverNotes(cod_handover_notes, userObj) {
        if (this.isAuthenticated()) {
            this._obj_socket.emit('handover notes unsubscribe', cod_handover_notes, userObj);
        }
    }
}
