import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { TranslateModule } from '@ngx-translate/core';
import { QuillEditorComponent, QuillViewComponent } from 'ngx-quill';
import { ConvertDate } from '../../components/convert-date/convert-date.component';
import { AuthService } from '../../main/access/services/auth.service';
import { MeetingsService } from '../services/meetings.service';
import { environment } from '../../../environments/environment';
import { UploadService } from '../../main/services/upload.service';
import { NotificationService } from '../../main/services/notification.service';
import { SocketService } from '../../main/services/socket.service';
import { Subscription } from 'rxjs';
import { CommentType, SyncStatus } from '../services/types/enums';
import CH from '../services/helpers/comments-helpers';


type MeetingComment = {
    cod_meeting: number;
    cod_user: number;
    str_name: string;
    str_text: string;
    dat_ins: string;
    cod_chat_messages: number;
    sync_status?: SyncStatus;
    type?: CommentType;
    str_url?: string;
    is_deleted?: boolean;
};

@Component({
    standalone: true,
    selector: 'app-meetings-comments-drawer',
    imports: [
        ClbDrawerModule, 
        ClbModalModule,
        TranslateModule, 
        QuillEditorComponent, 
        QuillViewComponent,
        CommonModule,
        FormsModule, 
        ConvertDate
    ],
    templateUrl: './meetings-comments-drawer.component.html',
    styleUrls: ['./meetings-comments-drawer.component.css'],
})
export class MeetingsCommentsDrawerComponent implements OnInit, OnChanges, OnDestroy {
    @Input('open') isOpen: boolean = false;
	@Input('codMeeting') cod_meeting: number;

    @Output() onClose = new EventEmitter<any>();

    readonly CommentType = CommentType;
    readonly SyncStatus = SyncStatus;
    
    readonly local_id: string = crypto.randomUUID();

    obj_connection: Subscription;
    can_refresh: boolean;

    obj_user: any;
    cod_user: any;
    bol_loading_comments: boolean = true;
    count_retries: number = 0;
    allow_after_retries: number = 2;
    arr_messages: MeetingComment[] = [];

    input_focus: boolean = false;

    maxCharCount: number = 255;

    str_comment: any;
    charCount: number = 0;
    invalidCharCount: boolean = false;

    obj_comment_edit: MeetingComment;
    str_comment_edit: string;
    editCharCount: number = 0;
    invalidEditCharCount: boolean = false;

    bol_delete_modal: boolean = false;
    obj_comment_to_delete: MeetingComment;

    failed_comments: Map<MeetingComment, string> = new Map();
    
    addCommentEditor: any;
    editCommentEditor: any;


    constructor(
		private readonly _authService: AuthService, 
		private readonly _meetingsService: MeetingsService,
        private readonly _uploadService: UploadService,
        private readonly _notify: NotificationService,
        private readonly _socketService: SocketService
	) {
        this.obj_user = _authService.getAuthenticatedUser();
        this.cod_user = this.obj_user.id;
    }

    ngOnInit() {
        try {
            this.obj_connection = this._socketService.subscribeToMessages().subscribe({
                next: messages => {
                    let obj_messages: any;
                    obj_messages = messages;
                    if (obj_messages.obj_message.cod_meeting == this.cod_meeting && obj_messages.obj_message.origin != this.local_id) {
                        if(this.isOpen) {
                            this.getComments(); // If drawer is open, we can try to refresh immediately
                        } else {
                            this.can_refresh = true; // If drawer is minimized, do not refresh yet, but set flag to refresh when opening it
                        }
                    }
                },
                error: err => {
                    this.showErrorMessage('Meeting Comments SOCKET ERROR', err, false);
                }
            });
        } catch (err) {
            this.showErrorMessage('Meeting Comments SOCKET ERROR 2', err, false);
        }
    }

    ngOnDestroy(): void {
        if(!this.obj_connection?.closed){
            this.obj_connection?.unsubscribe()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isOpen?.currentValue) {
            if(this.arr_messages.length === 0 || this.can_refresh) { // Only refreshes comment list if arr_essages is empty, or if socket received update while drawer is minimized
                this.getComments();
            } else {
                this.count_retries += 1; // As a failsafe, if user keeps opening and closing drawer, maybe the socket disconnected, so let it refresh
                this.can_refresh = this.count_retries < this.allow_after_retries ? this.can_refresh : true;
            }
        }
    }

    parseRequestResult(data: any, calback, error) {
        if (data.code == 888 && data.content.code == 888) {
            let res = data.content.results
            calback(res);
        } else {
            error(data);
        }
    }

    getComments() {
        this.bol_loading_comments = true;
        this.count_retries = 0

        this._meetingsService.getMeetingComments(this.cod_meeting, this.cod_user).subscribe({
            next: (result) => {
                this.parseRequestResult(result, (data) => {

                    /* Using unionMerge instead of simply replacing array for a few reasons:
                        - I need to keep the original object references, in case some edit is ongoing/pending
                        - To avoid fickering on the page when refreshing
                        - To not loose new comments that have not synced yet
                    */
                    const sorter = (a, b): number => {
                        if (!a.dat_ins) return 1;
                        if (!b.dat_ins) return -1;
                        return new Date(a.dat_ins).getTime() - new Date(b.dat_ins).getTime();
                    };
                    const skipCondition = (incoming, existing): boolean => {
                        // Here are any conditions for skiping the merge of a local comment with incoming the same incoming comment
                        return existing.sync_status !== SyncStatus.SUCCESS && existing.str_text !== incoming.str_text;
                    }

                    const options = {
                        skipCondition: skipCondition,
                        removeMissing: true
                    };
                    CH.unionMerge(this.arr_messages, data, 'cod_chat_messages', options).sort(sorter);

                    let _ = this.arr_messages.map(item => {
                        item.type = CH.checkCommentType(item.str_text);
                        item.str_url = item.type != 'text' ? `${environment().uploadGetFilesApiUrl}/${item.str_text}` : undefined;

                        item.str_name = CH.formatName(item.str_name);
                        item.sync_status = item.sync_status ?? SyncStatus.SUCCESS;
                    });

                    this.can_refresh = false;

                }, (err) => {
                    const text = 'MEETING.COMMENTS_DRAWER.ERRORS.COMMENTS_LIST';
                    this.showErrorMessage(err, text);
                });
                this.bol_loading_comments = false;
            },
            error: (err) => {
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.COMMENTS_LIST';
                this.showErrorMessage(err, text);
                this._authService.errCheck(err);
            }
        });
    }

    initAddCommentQuillEditor(event) {
        this.addCommentEditor = event.editor;
    }

    initEditCommentQuillEditor(event) {
        this.editCommentEditor = event.editor
        this.updateEditCharCount({text: this.editCommentEditor?.getText() ?? this.str_comment_edit});
    }

    updateCharCount(event) {
        this.addCommentEditor = event?.editor ?? this.addCommentEditor;
        this.charCount = event?.text?.trim()?.length ?? 0;
        this.invalidCharCount = this.charCount > this.maxCharCount;
    }

    updateEditCharCount(event) {
        this.editCommentEditor = event.editor ?? this.editCommentEditor;
        this.editCharCount = event?.text?.trim()?.length ?? 0;
        this.invalidEditCharCount = this.editCharCount > this.maxCharCount;
    }

    addComment() {
        this.updateCharCount({text: this.addCommentEditor?.getText() ?? this.str_comment});
        if (this.invalidCharCount || !this.charCount) return;
        if (this.str_comment) {
            this.str_comment = this.str_comment
                .replace(/<li><br><\/li>/g, '') // erase empty list items
                .replace(/(<ul><\/ul>|<ol><\/ol>)/g, '') //erase empty lists caused by step above
                .replace(/(<p><br><\/p>)*$/g, ''); // erase all trailing empty lines at the end
            
            const local_comment = {
                sync_status: SyncStatus.SYNCING, // waiting/syncing
                cod_user: this.cod_user,
                str_name: CH.formatName(this.obj_user.name),
                str_text: this.str_comment,
                type: CommentType.TXT,
                cod_meeting: null,
                dat_ins: null,
                cod_chat_messages: null
            };
            this.arr_messages.push(local_comment);
            this.str_comment = '';

            this.postMeetingComment(local_comment);
        }
	}

    fileChangeEvent(event) {
        const params = {
            'event': event,
            'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
        };

        this._uploadService.uploadFileBase64(params).subscribe({
            next: response => {
                if (response.type == 'success') {

                    const local_comment = {
                        sync_status: SyncStatus.SYNCING, // waiting/syncing
                        cod_user: this.cod_user,
                        str_name: CH.formatName(this.obj_user.name),
                        str_url: `${environment().uploadGetFilesApiUrl}/${response.content.str_name}`,
                        str_text: response.content.str_name, // To retry in case it fails after upload is already successful
                        type: CH.checkCommentType(response.content.str_name),
                        cod_meeting: null,
                        dat_ins: null,
                        cod_chat_messages: null
                    };
                    this.arr_messages.push(local_comment);
                    this.postMeetingComment(local_comment);
                } else {
                    const text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE';
                    this.showErrorMessage(response.content, text);
                }
            },
            error: err => {
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE';
                this.showErrorMessage(err, text);
                this._authService.errCheck(err);
            }
        });
    }

    postMeetingComment(local_comment: MeetingComment){
        const comment_param = {
            str_comment: local_comment.str_text
        };
        this._meetingsService.postMeetingComment(this.cod_meeting, this.cod_user, comment_param).subscribe({
            next: (result) => {
                this.parseRequestResult(result, (data) => {
                    this._socketService.refreshMeetingMessage({origin: this.local_id, cod_meeting: this.cod_meeting});
                    this.updateLocalCommentsSuccess(data[0], local_comment);
                }, (err)=>{
                    let text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_COMMENT';
                    text = local_comment.type === CommentType.TXT ? text : 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE_COMMENT';
                    this.showErrorMessage(err, text);
                    this.updateLocalCommentsFailure(local_comment);
                });
            },
            error: (err) => {
                let text = 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_COMMENT';
                text = local_comment.type === CommentType.TXT ? text : 'MEETING.COMMENTS_DRAWER.ERRORS.ADD_FILE_COMMENT';
                this.showErrorMessage(err, text);
                this.updateLocalCommentsFailure(local_comment);
                this._authService.errCheck(err);
            }
        });
    }

    updateLocalCommentsSuccess(data, local_comment) {
        local_comment.sync_status = SyncStatus.SUCCESS; // success
        local_comment.cod_chat_messages = data.cod_meeting_comment;
        local_comment.dat_ins = data.dat_ins;
        // Just in case there was a refresh with the new comment that somehow returns before the insert confirmation
        if(this.arr_messages.some(item => item.cod_chat_messages === local_comment.cod_chat_messages)) {
            CH.mergeDuplicated(this.arr_messages, 'cod_chat_messages'); 
        }
    }

    updateLocalCommentsFailure(local_comment) {
        local_comment.sync_status = SyncStatus.FAILURE; // failure
    }

    openConfirmDelete(index) {
        this.obj_comment_to_delete = this.arr_messages[index];
        this.bol_delete_modal = true;
    }

    cancelDeleteComment() {
        this.obj_comment_to_delete = null;
        this.bol_delete_modal = false;
    }

    confirmDeleteComment() {
        let comment_to_delete = this.obj_comment_to_delete;
        this.obj_comment_to_delete = null;

        comment_to_delete.is_deleted = true;
        comment_to_delete.sync_status = SyncStatus.SYNCING;
        this.bol_delete_modal = false;

        const cod_comment = comment_to_delete.cod_chat_messages;

        this._meetingsService.deleteMeetingComment(this.cod_meeting, this.cod_user, cod_comment).subscribe({
            next: data => {
                this._socketService.refreshMeetingMessage({origin: this.local_id, cod_meeting: this.cod_meeting});
                this.arr_messages = this.arr_messages.filter(item => cod_comment != item.cod_chat_messages);
            },
            error: err => {
                comment_to_delete.sync_status = SyncStatus.FAILURE;
                
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.DELETE_COMMENT';
                this.showErrorMessage(err, text);
                
                setTimeout(() => { // time for the little css animation to run, then back to normal
                    comment_to_delete.is_deleted = false;
                    comment_to_delete.sync_status = SyncStatus.SUCCESS;
                    comment_to_delete = null;
                }, 2200);
                this._authService.errCheck(err);
            }
        });
    }

    openEditComment(comment) {
        this.obj_comment_edit = comment;
        this.str_comment_edit = this.obj_comment_edit.str_text;
    }

    cancelEditComment() {
        this.obj_comment_edit = null;
        this.str_comment_edit = null;
    }

    saveEditComment() {
        this.updateEditCharCount({text: this.editCommentEditor?.getText() ?? this.str_comment_edit});
        if(this.invalidEditCharCount || !this.editCharCount) return;

        const comment_edit = this.obj_comment_edit;
        const str_edit_comment = this.str_comment_edit;
        this.obj_comment_edit = null;
        this.str_comment_edit = null;

        this.putMeetingComment(comment_edit, str_edit_comment);
    }

    putMeetingComment(comment_edit: MeetingComment, str_edit_comment: string){
        let str_original_comment = comment_edit.str_text;
        comment_edit.str_text = str_edit_comment;
        comment_edit.sync_status = SyncStatus.SYNCING;
        
        const comment_param = {
            cod_comment: comment_edit.cod_chat_messages,
            str_comment: str_edit_comment
        };
        

        this._meetingsService.putMeetingComment(this.cod_meeting, this.cod_user, comment_param).subscribe({
            next: res => {
                this._socketService.refreshMeetingMessage({origin: this.local_id, cod_meeting: this.cod_meeting});
                comment_edit.sync_status = SyncStatus.SUCCESS;
            }, 
            error: err => {
                comment_edit.sync_status = SyncStatus.FAILURE;
                this.failed_comments.set(comment_edit, str_original_comment);
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.EDIT_COMMENT';
                this.showErrorMessage(err, text);
                this._authService.errCheck(err);
            } 
        });
    }

    retrySaveComment(obj_comment: MeetingComment) {
        obj_comment.sync_status = SyncStatus.SYNCING;
        if(!obj_comment.cod_chat_messages) {
            // retry add comment
            this.postMeetingComment(obj_comment);
        } else {
            // retry save edit comment
            const str_text = this.failed_comments.get(obj_comment)
            this.failed_comments.delete(obj_comment);
            const str_comment = obj_comment.str_text;
            obj_comment.str_text = str_text;
            this.putMeetingComment(obj_comment, str_comment);
        }
    }

    cancelFailedComment(obj_comment: MeetingComment) {
        let str_text = this.failed_comments.get(obj_comment);
        this.failed_comments.delete(obj_comment);
        obj_comment.str_text = str_text ?? obj_comment.str_text;
        obj_comment.sync_status = SyncStatus.SUCCESS;
        if(!obj_comment.cod_chat_messages){
            this.arr_messages = this.arr_messages.filter(item => obj_comment != item);
        }
    }

    downloadFile(fileUrl) {
        this._uploadService.downloadFile(fileUrl).subscribe({
            next: res => {
                const blob = new Blob([res]);
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = CH.getFileName(fileUrl);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            error: err => {
                const text = 'MEETING.COMMENTS_DRAWER.ERRORS.DOWNLOAD_FILE';
                this.showErrorMessage(err, text);
                this._authService.errCheck(err);
            }
        });
    }

    showErrorMessage(err, text, notify = true) {
        console.log({text, err});
        if(notify) this._notify.error({ text, translate: true });
    }

    closeDrawer() {
        this.onClose.emit();
    }
}
