import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {InfoService} from '../../services/help.service';

@Component({
  selector: 'app-info-select-item',
  templateUrl: './help-select-item.component.html',
  styleUrls: ['./help-select-item.component.css']
})
export class InfoSelectItemComponent implements OnInit, OnDestroy {

  label: string;
  iconPath: string;
  showOptions = false;
  subscription: Subscription;

  constructor(private _infoService: InfoService, private _translateService: TranslateService) { }

  ngOnInit() {
    this.subscription = this._infoService.currentItem$
    .subscribe(key => this.loadLabelAndIcon(key));
  }

  clickShowOptions() {
    this.showOptions = !this.showOptions;
  }

  loadLabelAndIcon(key) {
    this._translateService.get(`HELP.LIST.${key}`).subscribe(result => this.label = result);
    this.iconPath = this._infoService.getImagePath(key);
    this.showOptions = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
