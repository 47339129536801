import { Component, OnInit } from '@angular/core';
import { AuthService } from "../access/services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from '../../main/services/upload.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RoutinesService } from '../../logbooks/services/routines.service';
import IAttachment from '../../components/attachments-gallery/attachments.model';

@Component({
    selector: 'app-reports-routine-attachments',
    templateUrl: './reports-routine-attachments.component.html',
    styleUrls: ['./reports-routine-attachments.component.css']
})
export class ReportsRoutineAttachmentsComponent implements OnInit {
    loading: boolean = true;
    loadingDetail: boolean = true;
    str_message: string;
    cod_logbook_routines_periodicity_users: number;
    cod_user: number;
    attachments: Array<IAttachment> = [];             
    
    detail: Object = {
        status_description: null,
        date_limit: null,
        str_description: null,
        str_name: null
    };                             

    constructor(
        private router: Router, 
        private _activatedRoute:ActivatedRoute, 
        private _uploadService:UploadService, 
        private _translateService:TranslateService, 
        private _authService: AuthService,
        private _routineService: RoutinesService,
        private sanitizer: DomSanitizer,) {
        //http://localhost:4200/reports-download/625386/DTO_2018_PT_9-9-2019_Acheral2019-9-9-10_38_45.csv
    }

    ngOnInit() {
        //GET FILE AND USER FOR REQUEST REPORT
        this._activatedRoute.params.subscribe((arr_params) => {
            this.cod_logbook_routines_periodicity_users = arr_params['cod_logbook_routines_periodicity_users'];
        });
    }

    ngAfterViewInit() {
        if (this._authService.isAuthenticated() && this._authService.getUserPrivilege('REPORT_ROUTES')) {
            this.cod_user = this._authService.getAuthenticatedUser().id;
            this.getAttachments();
            this.getDetails();
        }
    }

    getDetails() {
        this.loadingDetail = true;

        const params = {
            cod_logbook_routines_periodicity_users: this.cod_logbook_routines_periodicity_users,
            cod_user: this.cod_user
        };

        this._routineService.getLogbookRoutineDetail(params).subscribe(res => {
            this.loadingDetail = false;
            if (res.type !== 'success') {
                alert(res.content.message);
            } else {

                this.detail = {
                    status_description: res.content.results.status_description,
                    date_limit: res.content.results.date_limit,
                    str_description: res.content.results.str_description,
                    str_name: res.content.results.str_name
                };
     
            }
          }, err => {
            this._authService.errCheck(err);
          }
      );
    }

    getAttachments() {
        this.loading = true;

        const params = {
            cod_logbook_routines_periodicity_users: this.cod_logbook_routines_periodicity_users,
            cod_user: this.cod_user
        };

        this._routineService.getAttachments(params).subscribe(res => {
            if (res.type !== 'success') {
                alert(res.content.message);
            } else {

                if (Array.isArray(res.content.results)) {
                    this.attachments = res.content.results.map((a): IAttachment => this._mapAttachment(a));
                } else {
                    this.attachments[0] = this._mapAttachment(res.content.results);
                }
                
            }
            this.loading = false;
          }, err => {
            this._authService.errCheck(err);
          }
      );
    }

    _mapAttachment(obj): IAttachment {
        return {
            url_image: `${environment().uploadGetFilesApiUrl}/${obj.str_name_image}`,
            name_image: obj.str_name_image,
            dat_ins:  obj.dat_ins
        };
    }

}