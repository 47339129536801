import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Whys } from "../model/whys.model";

@Injectable()
export class AddWhysHelperService {
    getWhys$: Observable<any>;
    private getWhysSubject = new Subject<Whys[]>();

    constructor() {
        this.getWhys$ = this.getWhysSubject.asObservable();
    }

    sendWhys(data) {
        this.getWhysSubject.next(data);
    }
}