// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-button {
    color: #FF0000;
}

.position-button {
    position: relative;
    float: right;    
    max-width: 200px;
    margin-right: 2vw;
    line-height: normal;
    padding: 8px
}

.status-th{
    min-width: 50px;
    max-width: 50px;
    text-align: center;
    text-transform: none !important;
}

.align-name{
    text-align: center;
}

.ope{
    padding-right: 40px;
 
}

.tech{
    padding-right: 35px;
}

.button-participants{
    width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/app/five-why/fivewhy-participants/fivewhy-participants.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB;AACJ;;AAEA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".remove-button {\n    color: #FF0000;\n}\n\n.position-button {\n    position: relative;\n    float: right;    \n    max-width: 200px;\n    margin-right: 2vw;\n    line-height: normal;\n    padding: 8px\n}\n\n.status-th{\n    min-width: 50px;\n    max-width: 50px;\n    text-align: center;\n    text-transform: none !important;\n}\n\n.align-name{\n    text-align: center;\n}\n\n.ope{\n    padding-right: 40px;\n \n}\n\n.tech{\n    padding-right: 35px;\n}\n\n.button-participants{\n    width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
