import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class LogbooksService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all logbooks.
     * @param param: JSON object filled with search "term" or "id"
     */
    getLogbooksList(param): Observable<any> {
        let path = '/logbooks?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege + '&limit=' + param.limit + '&offset=' + param.offset;
        if (param.cod_logbooks > 0) {
            path = '/logbook/' + param.cod_logbooks + '/' + param.cod_user;
        }

        return this._mainService.get(path);
    }

    getChecklistAllList(param): Observable<any> {
        let path = '/checklistAll?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege + '&limit=' + param.limit + '&offset=' + param.offset;
        return this._mainService.get(path);
    }

    /**
     * Get calc user calc logbook.
     * @param cod_user
     * @param date
     */
    getUserCalcLogbook(cod_user, date, cod_user_auth) {
        let path = '/get-calc-user-logbook/' + cod_user + '/' + date + '/' + cod_user_auth;
        return this._mainService.get(path);
    }

    getUserCalcChecklistCollab(params/*cod_user, date, cod_user_auth*/) {
        let path = '/get-calc-checklist-collab';
        return this._mainService.post(path, params);
    }

    /**
     * Get calc team calc logbook.
     * @param cod_manager
     * @param date
     */
    getTeamCalcLogbook(cod_manager, date, cod_user_auth) {
        let path = '/get-calc-team-logbook/' + cod_manager + '/' + date + '/' + cod_user_auth;
        return this._mainService.get(path);
    }

    /**
     * Post logbook object to database
     * @param params: JSON object of logbook
     */
    postLogbook(params): Observable<any> {
        let path = '/logbook';
        return this._mainService.post(path, params);
    }

    /**
     * Put logbook object to database
     * @param params: JSON object of logbook
     */
    putLogbook(params): Observable<any> {
        let path = '/logbook';
        return this._mainService.put(path, params);
    }

    /**
     * Delete logbook object from database
     * @param param: JSON object filled with "id" of logbook
     */
    deleteLogbook(params): Observable<any> {
        let path = '/logbook/' + params.cod_logbook + '/' + params.cod_user;
        return this._mainService.delete(path);
    }

    getMyTeamList(params): Observable<any> {
        let path = '/logbook-my-team';
        return this._mainService.post(path, params);
    }

    setLogbookToUser(params): Observable<any> {
        let path = '/set-logbook-user';
        return this._mainService.post(path, params);
    }

    setIsClockingLogbookUser(params): Observable<any> {
        let path = '/set-isclocking-logbook-user';
        return this._mainService.post(path, params);
    }

    getLogbooksByArea(params): Observable<any> {
        let path = '/logbooks-by-area';
        return this._mainService.post(path, params);
    }
    getLogbookReport(params): Observable<any> {
        let path = '/get-logbooks-report';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getUsersByLogbook(params): Observable<any> {
        const path = '/get-logbook-users';
        return this._mainService.post(path, params);
    }

    getUsersByLogbookAndLocations(params): Observable<any> {
        let path = '/get-logbook-users-and-locations';
        return this._mainService.post(path, params);
    }

    getChecklistCollab(params): Observable<any>{
        let path = '/get-checklist-area-subarea';
        return this._mainService.post(path, params);
    }
    getChecklistReportCollab(params): Observable<any>{
        let path = '/get-checklist-report';
        return this._mainService.post(path, params);
    }
    
    getChecklistCollabFilter(params): Observable<any>{
        let path = '/get-checklist-filter';
        return this._mainService.post(path, params);
    }

    getSingleChecklist(params): Observable<any>{
        let path = "/get-single-checklist";
        return this._mainService.post(path, params);
    }

    getFormCollab(params): Observable<any>{
        const path = '/find-form-collaborative';
        return this._mainService.post(path, params);
    }

    getFilterCollab(params): Observable<any>{
        const path = '/get-user-checklist-collab-filter';
        return this._mainService.post(path, params);
    }

    setFilterCollab(params): Observable<any>{
        const path = '/set-user-checklist-collab-filter';
        return this._mainService.post(path, params);
    }

    delFilterCollab(params): Observable<any>{
        const path = '/del-user-checklist-collab-filter';
        return this._mainService.post(path, params);
    }

    getHasCustomChecklist(params): Observable<any>{
        const path = '/get-has-custom-checklist';
        return this._mainService.post(path, params);
    }

    setHasCustomChecklist(params): Observable<any>{
        const path = '/set-has-custom-checklist';
        return this._mainService.post(path, params);
    }

    getLogbooksyLocation(params): Observable<any> {// A_U, get
        return this._mainService.get('/logbooks' + '/' + params.cod_user + '/' + params.cod_location + '/' + params.dsc_page_privilege);
    }

    getLogbookRoutinesByUserDate(params) : Observable<any> {
        const path = '/logbook-routines-user-date';
        return this._mainService.post(path, params);
      }
    
    getCopiedCheckLists(params): Observable<any> {
        return this._mainService.get('/logbook-copied-from/' + params.cod_logbooks);
    }

    isRoutineInLogbook(originalList, copiedRoutine){
        if (Array.isArray(originalList) && originalList.length > 0) {
            for( let original of originalList){
                if(original.cod_routine == copiedRoutine.cod_routine && original.cod_periodicity == copiedRoutine.cod_periodicity){
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Update copies of logbook
     * @params params: JSON object of logbook
     * @params arr_del: List of deleted routines logbook
     * obs.: Only update/insert/delete bol_mandatory true routines = Zone standard routines
     */
    updateLogbooksCopiedFrom(params: any, arr_copies: any, arr_del: any){

        if (Array.isArray(arr_copies) && arr_copies.length > 0) {
            arr_copies.forEach(element => {
                let objParam = {'cod_logbooks': element.cod_logbooks, 'cod_user': params.cod_user};
                let copyLogbookRoutines = [];
                this.getSingleChecklist(objParam).subscribe(data => {
                    let copyLogbook = data.content.results;

                    //Delete in copy routines removed from original
                    if(Array.isArray(arr_del) && arr_del.length > 0){
                        arr_del.forEach(deletedRoutine => {
                            let deleted = false;
                            for( let i = copyLogbook.routines.length - 1; i >= 0 && !deleted; i--  ){
                                let copiedRoutine = copyLogbook.routines[i];
                                if(copiedRoutine.bol_mandatory){
                                    if(deletedRoutine.cod_routine == copiedRoutine.cod_routine 
                                        && deletedRoutine.cod_periodicity == copiedRoutine.cod_periodicity){
                                        deleted = true;
                                        copyLogbook.routines.splice(i, 1);
                                    }
                                }
                            };        
                        });
                    }

                    //New rule - Whenever there is a duplicated routine in the copy, 
                    //and it is not of periodicity 13 (as needed), it only keeps the 
                    //routine that comes from the original checklist.
                    if (Array.isArray(params.routines) && params.routines.length > 0) {
                        params.routines.forEach(original_routine => {
                            if (Array.isArray(copyLogbook.routines) && copyLogbook.routines.length > 0) {
                                for( let i = copyLogbook.routines.length - 1; i >= 0; i--  ){
                                    let copiedRoutine = copyLogbook.routines[i];
                                    if(copiedRoutine.bol_mandatory){
                                        if(copiedRoutine.cod_routine == original_routine.cod_routine 
                                            && copiedRoutine.cod_periodicity != original_routine.cod_periodicity
                                            && original_routine.cod_periodicity != 13
                                            && copiedRoutine.cod_periodicity != 13){
                                            copyLogbook.routines.splice(i, 1);
                                        }
                                    }
                                }
                            }
                        })
                    }

                    let new_order = 1;
                    copyLogbook.routines = copyLogbook.routines.map((val) => {
                        val.int_order = new_order;
                        new_order++;
                        return val;
                    });

                    //Edit
                    if(Array.isArray(params.routines) && params.routines.length > 0){
                        // TODO review these copy steps, as the routines have many unused params
                        // Currently the unused params are being striped right before 
                        // making the request, but this uses redundant steps which is not nice
                        params.routines.forEach(originalRoutine => {
                            if(originalRoutine.bol_mandatory){
                                let edit = false;
                                copyLogbook.routines.forEach(copiedRoutine => {
                                    /*If a routine of copy is not in list of routines of original checklist
                                    It is pushed to array copyLogbookRoutines array*/
                                    if(!this.isRoutineInLogbook(params.routines, copiedRoutine)){
                                        if(!this.isRoutineInLogbook(copyLogbookRoutines, copiedRoutine)){
                                            copyLogbookRoutines.push(copiedRoutine);
                                        }
                                    }
                                    else{
                                        //If copiedRoutine exists in originalRoutine array must only added to copyLogbookRoutines array
                                        if(originalRoutine.cod_routine == copiedRoutine.cod_routine && originalRoutine.cod_periodicity == copiedRoutine.cod_periodicity){
                                            copiedRoutine.str_name = originalRoutine.str_name;
                                            copiedRoutine.str_description = originalRoutine.str_description;
                                            copiedRoutine.cod_form = originalRoutine.cod_form;
                                            copiedRoutine.val_day_of_week = originalRoutine.val_day_of_week;
                                            copiedRoutine.val_week_of_month = originalRoutine.val_week_of_month;
                                            copiedRoutine.cod_profile = originalRoutine.cod_profile;
                                            copiedRoutine.int_order = originalRoutine.int_order;
                                            copiedRoutine.str_form_name = originalRoutine.str_form_name;
                                            copiedRoutine.arr_cod_shifts = originalRoutine.arr_cod_shifts;
                                            copiedRoutine.str_autocomplete_text = originalRoutine.str_autocomplete_text;

                                            copiedRoutine.arr_location = originalRoutine.arr_location;
                                            copiedRoutine.arr_regionals = originalRoutine.arr_regionals;
                                            copiedRoutine.arr_worlds = originalRoutine.arr_worlds;
                                            copiedRoutine.arr_subareas = originalRoutine.arr_subareas;

                                            copiedRoutine.cod_routines = copiedRoutine.cod_routine;

                                            if(!this.isRoutineInLogbook(copyLogbookRoutines, copiedRoutine)){
                                                copyLogbookRoutines.push(copiedRoutine);
                                            }

                                            edit = true;
                                        }
                                    }
                                });
                                
                                if(!edit){
                                    originalRoutine.cod_logbooks = copyLogbook.cod_logbooks;
                                    originalRoutine.cod_logbook = copyLogbook.cod_logbooks;
                                    originalRoutine.int_order = copyLogbook.routines.length + 1;
                                    
                                    copyLogbookRoutines.push(originalRoutine);
                                }
                            }
                        });

                        copyLogbook.cod_user = params.cod_user;

                        //logbooks without auxiliary arrays
                        if(typeof copyLogbook.cod_locations == typeof undefined){
                            copyLogbook.cod_locations = [];
                            if(Array.isArray(params.arr_location) && params.arr_location.length > 0){                                
                                params.arr_location.forEach(element => {
                                    copyLogbook.cod_locations.push(element.cod_location);
                                });
                            }
                        }                                

                        if(typeof copyLogbook.cod_subareas == typeof undefined){
                            copyLogbook.cod_subareas = [];
                            if(Array.isArray(params.arr_subareas) && params.arr_subareas.length > 0){
                                params.arr_subareas.forEach(element => {
                                    copyLogbook.cod_subareas.push(element.cod_subarea);
                                });
                            }
                        }
                        
                        copyLogbook.bol_type_collab = params.bol_type_collab;

                        copyLogbook.routines = copyLogbookRoutines;

                        let new_order = 1;
                        copyLogbook.routines = copyLogbook.routines.map((val) => {
                            val.int_order = new_order;
                            new_order++;
                            return val;
                        });

                        const copyLogbookParams = this.getLogbookParams(copyLogbook);
                        
                        this.putLogbook(copyLogbookParams).subscribe(res => {
                            if (res.type == 'error') {
                                
                            }                                    
                        }, err => {
                            let e = JSON.parse(err._body);
                        });
                    }
                }, err => {
                    let e = JSON.parse(err._body);
                });
            });
        }
    }

    hasDuplicatedRoutines(logbook_routines, routine, is_new){
        if(Array.isArray(logbook_routines) && logbook_routines.length > 0){            
            //void AsNeeded routines
            if(routine && routine.cod_routine != null){
                if(is_new){//adding new routine
                    if(routine.cod_periodicity == 13){//'As needed' periodicity
                        let routines = logbook_routines.filter(function (item) {
                            return item.cod_periodicity == 13
                        });
                        let duplicated = routines.filter(function (item){
                            return item.cod_routine == routine.cod_routine
                        })
                        if(Array.isArray(duplicated) && duplicated.length > 0){
                            return true;
                        }
                    }
                    else{// != 13
                        let routines = logbook_routines.filter(function (item) {
                            return item.cod_periodicity != 13
                        });
                        let duplicated = routines.filter(function (item){
                            return item.cod_routine == routine.cod_routine
                        })
                        if(Array.isArray(duplicated) && duplicated.length > 0){
                            return true;
                        }
                    }
                }
                else{//Editing routine
                    if(routine.cod_periodicity == 13){
                        let routines = logbook_routines.filter(function (item) {
                            return item.cod_periodicity == 13
                        });
                        let duplicated = routines.filter(function (item){
                            return item.cod_routine == routine.cod_routine
                        })
                        if(Array.isArray(duplicated) && duplicated.length > 1){
                            return true;
                        }
                    }
                    else{// != 13
                        let routines = logbook_routines.filter(function (item) {
                            return item.cod_periodicity != 13
                        });
                        let duplicated = routines.filter(function (item){
                            return item.cod_routine == routine.cod_routine
                        })
                        if(Array.isArray(duplicated) && duplicated.length > 1){
                            return true;
                        }
                    }
                }
            }
            else{
                if(Array.isArray(logbook_routines) && logbook_routines.length > 0){
                    for(let routine of logbook_routines){
                        if(routine.cod_periodicity == 13){
                            let duplicated = logbook_routines.filter(function (item){
                                return item.cod_routine == routine.cod_routine && item.cod_periodicity == 13
                            })
                            if(Array.isArray(duplicated) && duplicated.length > 1){
                                return true;
                            }
                        }
                        else{// != 13
                            let duplicated = logbook_routines.filter(function (item){
                                return item.cod_routine == routine.cod_routine && item.cod_periodicity != 13
                            })
                            if(Array.isArray(duplicated) && duplicated.length > 1){
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    private asNumber(val: string|number){
        return !val || isNaN(+val)? val : +val;
    }

    getLogbookParams(logbook: any){
        const params = {
            str_name: logbook.str_name,
            cod_logbooks: this.asNumber(logbook.cod_logbooks), 
            cod_area: this.asNumber(logbook.cod_area),
            cod_profile: this.asNumber(logbook.cod_profile),
            cod_function: this.asNumber(logbook.cod_function),
            qty_users: this.asNumber(logbook.qty_users),
            is_clocking: this.asNumber(logbook.is_clocking),
            cod_update_user: this.asNumber(logbook.cod_update_user),
            cod_user: this.asNumber(logbook.cod_user),
            cod_inherit_logbook: this.asNumber(logbook.cod_inherit_logbook),
            bol_is_collab: logbook.bol_is_collab,
            bol_type_collab: logbook.bol_type_collab,
            cod_subareas: logbook.cod_subareas,
            cod_locations: logbook.cod_locations,
            is_custom: logbook.is_custom,
            routines: logbook.routines.map((routine)=>{
                return {
                    cod_routine: this.asNumber(routine.cod_routine),
                    cod_periodicity: this.asNumber(routine.cod_periodicity),
                    int_order: this.asNumber(routine.int_order),
                    bol_mandatory: routine.bol_mandatory,
                    bol_is_custom: routine.bol_is_custom,
                    arr_cod_shifts: routine.arr_cod_shifts
                }
            })
        }

        return params;
    }
}
