export class Meeting {

    str_name: string;
    bol_is_owner: boolean;
    int_pendentes: number;
    str_meeting_type_name: string;
    str_meeting_line_name: string;
    str_location_name: string;
    periodicity_order: number;
    str_periodicity: string;

    constructor() {
        this.str_name = '';
        this.bol_is_owner = false;
        this.int_pendentes = 0;
        this.str_meeting_type_name = '';
        this.str_meeting_line_name = '';
        this.str_location_name = '';
        this.periodicity_order = 0;
        this.str_periodicity = '';
    }
}
