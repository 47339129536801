// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
    background-color: var(--ui-primary) !important;
    color: var(--text-ui-primary) !important;
}

.action-tab {
    background-color: var(--ui-inverted);
    color: var(--text-ui-inverted);
    padding: 15px 45px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/reports-online/reports-online.component.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,wCAAwC;AAC5C;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,kBAAkB;AACtB","sourcesContent":[".active {\n    background-color: var(--ui-primary) !important;\n    color: var(--text-ui-primary) !important;\n}\n\n.action-tab {\n    background-color: var(--ui-inverted);\n    color: var(--text-ui-inverted);\n    padding: 15px 45px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
