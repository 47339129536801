import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthService} from "../../../main/access/services/auth.service";
import {RoutinesService} from '../../services/routines.service';
import {LogbooksRoutineCommentsService} from "../../services/logbooks-routine-comments.service";
import {TranslateService} from "@ngx-translate/core";
import { linkfyText } from 'app/helpers/general-helper';

@Component({
    selector: 'input-comment',
    templateUrl: './input-comment.component.html',
    styleUrls: ['./input-comment.component.css']
})
export class InputCommentComponent implements OnChanges {

    isEditing: boolean;
    showError = false;
    errorMessage = '';
    strNewText: string;

    @Input('isCollab') isCollab: boolean = false;
    @Input() comment: any;
    @Input() canEdit: boolean = true;

    constructor(private _authService: AuthService, private _routineCommentService: LogbooksRoutineCommentsService, 
        private _routinesServce: RoutinesService,
        private _translateService: TranslateService) {
        this.isEditing = false;
        this.strNewText = '';
    }

    setEditingComent($event) {
        if (!this.canEdit) {
            this.isEditing = false;
            return;
        }
        
        this.isEditing = !this.isEditing;
        this.strNewText = this.comment.str_text;

        if (this.isEditing) {
            let $this = this;
            window.setTimeout(function () {
                document.getElementById('commentInputId').focus();
                $this.setAutoHeight();
            }, 10);
        }
    }

    revertComment($event) {
        this.strNewText = this.comment.str_text;
        this.isEditing = false;
    }

    saveComment($event) {
        let keyCode = $event.which || $event.keyCode;

        if (keyCode == 27) {
            this.revertComment(null);
        }

        if (keyCode == 13) {
            $event.preventDefault();

            this.strNewText = this.strNewText.replace(/\n$/, '').trim();

            if (!this.strNewText) {
                return false;
            }
            if(this.isCollab){
                let objParam = {
                    cod_routines_comments_collaborative: this.comment.cod_routines_comments_collaborative,
                    cod_user: this._authService.getAuthenticatedUser().id,
                    str_text: this.strNewText
                };
    
                this._putCommentCollab(objParam);
            }
            else{
                let objParam = {
                    cod_routines_comments: this.comment.cod_routines_comments ,
                    cod_user: this._authService.getAuthenticatedUser().id,
                    str_text: this.strNewText
                };
    
                this._putComment(objParam);
            }
        }
        else {
            this.setAutoHeight();
        }
    }

    private setAutoHeight() {
        let textArea = document.getElementById("commentInputId");
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

    private _putCommentCollab(objParam: any) {
        this._routinesServce.updateRoutineCommentCollab(objParam).subscribe(res => {
                this.isEditing = false;
                this.comment.str_text = this.strNewText;
            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    private _putComment(objParam: any) {
        this._routineCommentService.putComment(objParam).subscribe(res => {
                this.isEditing = false;
                this.comment.str_text = this.strNewText;
            }, err => {
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.comment != typeof undefined) {
            this.strNewText = changes.comment.currentValue.str_text;
        }
    }

    linkfyComment(text: string) {
        return linkfyText(text)
    }
}
