import { Injectable } from '@angular/core';
import { MainService } from '../services/main.service';

@Injectable({
  providedIn: 'root'
})
export class TriggersService {

  constructor(private _mainService: MainService) { }

  listTriggersAdmin(params) {
    const path = '/list-trigger-admin';
    return this._mainService.post(path, params);
  }

  listCategories(params){
    const path = '/get-categories';
    return this._mainService.post(path, params);
  }

  listCategoriesWithTriggers(params){
    const path = '/get-categories-with-triggers';
    return this._mainService.post(path, params);
  }

  getTriggerList(params) {
    const path = '/get-triggers';
    return this._mainService.post(path, params);
  }

  getSingleTrigger(params) {
    const path = '/get-single-trigger';
    return this._mainService.post(path, params);
  }

  insertTrigger(params) {
    const path = '/insert-trigger';
    return this._mainService.post(path, params);
  }

  updateTrigger(params) {
    const path = '/update-trigger';
    return this._mainService.post(path, params);
  }

  deleteTrigger(params) {
    const path = '/delete-trigger';
    return this._mainService.post(path, params);
  }

  getArrayContent(data: any) {
    return MainService.getArrayContent(data);
  }
}
