import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListIndicatorsService} from './services/list-indicators.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-indicators',
    templateUrl: './list-indicators.component.html',
    styleUrls: ['./list-indicators.component.css']
})

export class ListIndicatorsComponent implements OnInit {

    indicatorsList: any = [];
    _obj_ta_eq;
    bol_show_typeahead_indicators: Boolean = true;
    _str_user_tmp = '';
    cod_compoment_indicators: number;

    @Input() question;
    @Input() isMultiple: Boolean
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListIndicatorsService: ListIndicatorsService, private _authService:AuthService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.indicatorsList = [{ cod_answer_option: this.question.cod_answer_option, str_name: this.question.str_answer_text }];    
            this.bol_show_typeahead_indicators = false;
        }
        this.loadIndicators();
    }

    loadIndicators() {
        this._obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_indicators',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.indicatorsList.length === 0) {
                    this.indicatorsList.push({
                        cod_indicators: obj_selected.cod_indicators,
                        str_name: obj_selected.str_name,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_indicators;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead_indicators = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchIndicators(cb, value);
            },
            onBlur: (item: any) => {
                this._str_user_tmp = '';
            }
        };
    }

    searchIndicators(cb, value) {
        this._ListIndicatorsService.getIndicatorsList({ 'dsc_privilege': 'NOT_PRIVILEGE','cod_user': this._authService.getAuthenticatedUser().id, 'term': value }).subscribe((data: any) => {
            cb(this._ListIndicatorsService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delIndicators() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;
        this.onChange.emit(this.question);

        this.indicatorsList = [];
        this.bol_show_typeahead_indicators = true;
        this.cod_compoment_indicators = null;
    }
       
}
