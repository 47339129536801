// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  text-align: center;
}

.status-box-color {
  border: solid 1px #adadaf;
  border-radius: 3px;
  height: 75px;
}

.status-description {
  font-size: 0.7vw;
}

.user-log-box {
  text-align: left;
}

.user-log-description {
  color: var(--text-log-description);
  font-size: 13px;
  font-style: italic;
}

.root-cause-icon,
.ab-report-icon {
  padding-top: 17px;
  width: 40px;
}

.cancel-icon { 
  padding-top: 22px;
  padding-left: 5px;  
  width: 30px;
}

.completed-icon { 
  padding-top: 22px;
  padding-left: 5px;
  width: 40px;
}

.status-green {
  background-color: #36ab36;
}

.status-yellow {
  background-color: #e4e421;
}

.status-red {
  background-color: #ec4b4b;
}

.status-blue {
  background-color: #2f70d2;
}

.status-grey {
  background-color: #eaeaea;
}

.status-canceled-grey { 
  background-color: #969696;
}

.status-disable {
  background-color: #868686;
}

.glyphicon--spin {
  color: #5599FF;
  animation: spin 1.5s infinite linear;
}

.reduce-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15em;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/five-why/status-flow/status-flow.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,kBAAkB;AACpB;;AAEA;;EAEE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EAEd,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".row {\n  text-align: center;\n}\n\n.status-box-color {\n  border: solid 1px #adadaf;\n  border-radius: 3px;\n  height: 75px;\n}\n\n.status-description {\n  font-size: 0.7vw;\n}\n\n.user-log-box {\n  text-align: left;\n}\n\n.user-log-description {\n  color: var(--text-log-description);\n  font-size: 13px;\n  font-style: italic;\n}\n\n.root-cause-icon,\n.ab-report-icon {\n  padding-top: 17px;\n  width: 40px;\n}\n\n.cancel-icon { \n  padding-top: 22px;\n  padding-left: 5px;  \n  width: 30px;\n}\n\n.completed-icon { \n  padding-top: 22px;\n  padding-left: 5px;\n  width: 40px;\n}\n\n.status-green {\n  background-color: #36ab36;\n}\n\n.status-yellow {\n  background-color: #e4e421;\n}\n\n.status-red {\n  background-color: #ec4b4b;\n}\n\n.status-blue {\n  background-color: #2f70d2;\n}\n\n.status-grey {\n  background-color: #eaeaea;\n}\n\n.status-canceled-grey { \n  background-color: #969696;\n}\n\n.status-disable {\n  background-color: #868686;\n}\n\n.glyphicon--spin {\n  color: #5599FF;\n  -webkit-animation: spin 1.5s infinite linear;\n  animation: spin 1.5s infinite linear;\n}\n\n.reduce-text {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  max-width: 15em;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
