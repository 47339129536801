import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {FormPercentageEvolutionService} from './services/form-percentage-evolution.service';
import {AuthService} from '../../main/access/services/auth.service';
import * as _ from 'lodash';
import FormsHelper from '../../forms/forms-dynamic/helpers/form-helper';

@Component({
    selector: 'form-percentage-evolution',
    templateUrl: './form-percentage-evolution.component.html',
    styleUrls: ['./form-percentage-evolution.component.css']
})

export class FormPercentageEvolutionComponent implements OnInit, OnChanges {

    completeness: number = 0;
    nonCompliance: string = '';
    loadEvolution: boolean = false;
    isNotApplicable = false;
    squares = [];

    @Input() answers: any;
    @Input() isHistory: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _formPercentageEvolutionService: FormPercentageEvolutionService, private _authService:AuthService) { 
    }

    ngOnInit() {
        this.loadEvolution = true;
        setTimeout(() => {
            this.loadEvolution = false;
            this.setCompleteness();
            this.setNonCompliance();
        }, 500);
    }

    ngOnChanges(changes: SimpleChanges) {
        const answers: SimpleChange = changes.answers;
        if (answers && answers.currentValue) {
            this.loadEvolution = true;
            setTimeout(() => {
                this.loadEvolution = false;
                this.setCompleteness();
                this.setNonCompliance();
            }, 500);
        }
    }

    setCompleteness() {
        this.completeness = Number(FormsHelper.validateAnswers(true, this.answers.filter(x=>!x.bol_optional)));
    }

    setNonCompliance() {
        const values = Object(FormsHelper.validateAnswers(false, this.answers.filter(x=>!x.bol_optional || (x.bol_optional && (x.str_answer_text || x.answer)))));
        let percentage = (values.savedPoints / values.points) * 100;
        
        if(Number.isNaN(percentage)) {
            percentage = 0;
        }

        const arr = [25,50,75,100];
        
        this.squares = [];
        
        for(let i = 0; i < arr.length; i = i+1){ 
            const val = arr[i];
            if(Math.trunc(percentage) >= val) {
                this.squares.push({ value: 100 });
            } else {
                if((i != 0 && arr[i-1] < Math.trunc(percentage) &&  Math.trunc(percentage) < val ) || (i == 0 && 0 < Math.trunc(percentage) &&  Math.trunc(percentage) < val )) {
                    const porcValue = (percentage / val) * 100;
                    this.squares.push({ value: porcValue.toFixed(0) });
                } else {                    
                    this.squares.push({ value: 0 });
                }
            }
        }

        if(values.points == 0) {
            this.isNotApplicable = true;
        } else { 
            this.isNotApplicable = false;
            this.nonCompliance = percentage.toFixed(0) + ' %';    
        }

    }

}
