// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-picture-container {
    position: static;
}

.change-picture-container .upload-file-name + .btn {
    padding: 25px;
}

.change-picture-container .buttons {
    bottom: 15px;
    position: absolute;
}

.upload-error-message {
    position: relative;
    top: -34px;
}

.upload-error-message span {
    font-size: 13px;
}

.display-none{
    display: none;
}

.img-maxHeight {
    max-height: 80vh;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.btn-save {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-preferences/modals/change-picture/change-picture.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".change-picture-container {\n    position: static;\n}\n\n.change-picture-container .upload-file-name + .btn {\n    padding: 25px;\n}\n\n.change-picture-container .buttons {\n    bottom: 15px;\n    position: absolute;\n}\n\n.upload-error-message {\n    position: relative;\n    top: -34px;\n}\n\n.upload-error-message span {\n    font-size: 13px;\n}\n\n.display-none{\n    display: none;\n}\n\n.img-maxHeight {\n    max-height: 80vh;\n}\n\n.mb-30 {\n    margin-bottom: 30px !important;\n}\n\n.btn-save {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
