// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
    overflow: visible;
    padding-bottom: 10px;
    padding-top: 10px;
}

.btn-save {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-preferences/modals/selected-time-zone/selected-time-zone.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["ul {\n    overflow: visible;\n    padding-bottom: 10px;\n    padding-top: 10px;\n}\n\n.btn-save {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
