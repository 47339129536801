// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.isDisable {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.download-button {
  background: var(--ui-primary);
  border-radius: 3px;
  color: var(--text-ui-primary);
  cursor: pointer;
  font-size: 15px;
  float: right;
  text-align: center;
  padding: 10px;
}

.tableApprovals{
font-size: 0.7125em;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/users-profiles-approval/users-profiles-approval.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,6BAA6B;EAC7B,eAAe;EACf,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;AACA,mBAAmB;AACnB","sourcesContent":[".isDisable {\n  pointer-events: none;\n  cursor: default;\n  opacity: 0.6;\n}\n\n.download-button {\n  background: var(--ui-primary);\n  border-radius: 3px;\n  color: var(--text-ui-primary);\n  cursor: pointer;\n  font-size: 15px;\n  float: right;\n  text-align: center;\n  padding: 10px;\n}\n\n.tableApprovals{\nfont-size: 0.7125em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
