import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RoutinesConsultComponent} from '../../logbooks/routines-consult/routines-consult.component';
import {AreaConsultComponent} from '../../logbooks/area-consult/area-consult.component';
import {MeetingTopic1Component} from '../help/help-meetings/meeting-topic-1/meeting-topic-1.component';
import {MeetingTopic2Component} from '../help/help-meetings/meeting-topic-2/meeting-topic-2.component';
import {MeetingTopic3Component} from '../help/help-meetings/meeting-topic-3/meeting-topic-3.component';
import {MeetingTopic4Component} from '../help/help-meetings/meeting-topic-4/meeting-topic-4.component';
import {MeetingTopic5Component} from '../help/help-meetings/meeting-topic-5/meeting-topic-5.component';
import {MeetingTopic7Component} from '../help/help-meetings/meeting-topic-7/meeting-topic-7.component';
import {MeetingTopic9Component} from '../help/help-meetings/meeting-topic-9/meeting-topic-9.component';
import {MyTeamTopic1Component} from '../help/help-my-team/my-team-topic-1/my-team-topic-1.component';
import {MyTeamTopic2Component} from '../help/help-my-team/my-team-topic-2/my-team-topic-2.component';
import {TaskTopic1Component} from '../help/help-tasks/task-topic-1/task-topic-1.component';
import {TaskTopic2Component} from '../help/help-tasks/task-topic-2/task-topic-2.component';
import {ReportTopic1Component} from '../help/help-reports/report-topic-1/report-topic-1.component';
import {ReportTopic2Component} from '../help/help-reports/report-topic-2/report-topic-2.component';
import {ReportTopic3Component} from '../help/help-reports/report-topic-3/report-topic-3.component';
import {MobileTopic1Component} from '../help/help-mobile/mobile-topic-1/mobile-topic-1.component';
import {LogbookTopic1Component} from '../help/help-logbook/logbook-topic-1/logbook-topic-1.component';
import {LogbookTopic2Component} from '../help/help-logbook/logbook-topic-2/logbook-topic-2.component';
import {LogbookTopic3Component} from '../help/help-logbook/logbook-topic-3/logbook-topic-3.component';
import {LogbookTopic4Component} from '../help/help-logbook/logbook-topic-4/logbook-topic-4.component';
import {LogbookTopic5Component} from '../help/help-logbook/logbook-topic-5/logbook-topic-5.component';
import {GeneralTopic3Component} from '../help/help-general/general-topic-3/general-topic-3.component';
import {TranslateService} from '@ngx-translate/core';
import {PeriodicityConsultComponent} from "../../logbooks/periodicity-consult/periodicity-consult.component";
import {CategoriesConsultComponent} from "../../five-why/categories-consult/categories-consult.component";
import {EquipmentsConsultComponent} from "../../five-why/equipments-consult/equipments-consult.component";
import {MeetingTopic10Component} from "../help/help-meetings/meeting-topic-10/meeting-topic-10.component";
import {MeetingTopic11Component} from "../help/help-meetings/meeting-topic-11/meeting-topic-11.component";
import {MeetingTopic12Component} from "../help/help-meetings/meeting-topic-12/meeting-topic-12.component";
import {MeetingTopic6Component} from '../help/help-meetings/meeting-topic-6/meeting-topic-6.component';
import {MeetingTopic8Component} from '../help/help-meetings/meeting-topic-8/meeting-topic-8.component';
import {MeetingTopic13Component} from '../help/help-meetings/meeting-topic-13/meeting-topic-13.component';
import {MeetingTopic14Component} from '../help/help-meetings/meeting-topic-14/meeting-topic-14.component';
import {KpisConsultComponent} from 'app/five-why/kpis-consult/kpis-consult.component';

@Injectable()
export class InfoService {

    components = {
        'GENERAL_TOPIC_1': RoutinesConsultComponent,
        'GENERAL_TOPIC_2': AreaConsultComponent,
        'MEETING_TOPIC_1': MeetingTopic1Component,
        'MEETING_TOPIC_2': MeetingTopic2Component,
        'MEETING_TOPIC_3': MeetingTopic3Component,
        'MEETING_TOPIC_4': MeetingTopic4Component,
        'MEETING_TOPIC_5': MeetingTopic5Component,
        'MEETING_TOPIC_7': MeetingTopic7Component,
        'MEETING_TOPIC_9': MeetingTopic9Component,
        'MEETING_TOPIC_10': MeetingTopic10Component,
        'MEETING_TOPIC_11': MeetingTopic11Component,
        'MEETING_TOPIC_12': MeetingTopic12Component,
        'MY_TEAM_TOPIC_1': MyTeamTopic1Component,
        'MY_TEAM_TOPIC_2': MyTeamTopic2Component,
        'TASKS_TOPIC_1': TaskTopic1Component,
        'TASKS_TOPIC_2': TaskTopic2Component,
        'REPORT_TOPIC_1': ReportTopic1Component,
        'REPORT_TOPIC_2': ReportTopic2Component,
        'REPORT_TOPIC_3': ReportTopic3Component,
        'MOBILE_TOPIC_1': MobileTopic1Component,
        'LOGBOOK_TOPIC_1': LogbookTopic1Component,
        'LOGBOOK_TOPIC_2': LogbookTopic2Component,
        'LOGBOOK_TOPIC_3': LogbookTopic3Component,
        'LOGBOOK_TOPIC_4': LogbookTopic4Component,
        'LOGBOOK_TOPIC_5': LogbookTopic5Component,
        'GENERAL_TOPIC_3': GeneralTopic3Component,
        'GENERAL_TOPIC_4': PeriodicityConsultComponent,
        'GENERAL_TOPIC_5': CategoriesConsultComponent,
        'GENERAL_TOPIC_6': EquipmentsConsultComponent,
        'GENERAL_TOPIC_7': KpisConsultComponent,
        'MEETING_TOPIC_6': MeetingTopic6Component,
        'MEETING_TOPIC_8': MeetingTopic8Component,
        'MEETING_TOPIC_13': MeetingTopic13Component,
        'MEETING_TOPIC_14': MeetingTopic14Component,
    };

    allTopics = [];

    private currentItemSource = new BehaviorSubject<object>({});
    currentItem$ = this.currentItemSource.asObservable();

    private currentTopicSource = new BehaviorSubject<object>({});
    currentTopic$ = this.currentTopicSource.asObservable();

    constructor(private _translateService: TranslateService) {
    }

    changeSelectedItem(object) {
        this.currentItemSource.next(object);
    }

    changeSelectedTopic(object) {
        this.currentTopicSource.next(object);
    }

    getImagePath(key) {
        const relativePath = '../../../../assets/images/';
        switch (key) {
            case 'GENERAL':
                return `${relativePath}icon_general.png`;
            case 'LOGBOOK':
                return `${relativePath}icon_my_tasks_blue.png`;
            case 'MEETING':
                return `${relativePath}icon_my_meetings_blue.png`;
            case 'TASKS':
                return `${relativePath}icon_my_tasks_blue.png`;
            case 'REPORT':
                return `${relativePath}icon_reports_blue.png`;
            case 'MY_TEAM':
                return `${relativePath}icon_my_team_blue.png`;
            case 'MOBILE':
                return `${relativePath}icon_mobile_blue.png`;
            default:
                return '';
        }
    }

    getComponent(key) {
        return this.components[key];
    }

}
