import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { MeetingsService } from '../services/meetings.service';
import { MeetingsTypesService } from '../services/meetings-types.service';
import { environment } from '../../../environments/environment';
import { UserPreferencesService } from '../../user-preferences/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { KpisService } from '../services/kpis.service';

@Component({
  selector: 'app-my-meetings-main',
  templateUrl: './my-meetings-main.component.html',
  styleUrls: ['./my-meetings-main.component.css']
})
export class MyMeetingsMainComponent implements OnInit {
  obj_user: any;
  arr_meetings_today: any;
  isLoading: boolean = true;
  actualTab: string = 'my-meetings';
  actualSubTab: string;
  bol_create: boolean;
  str_name_location: any;
  cod_area: any; // cod department
  selected_area: any; // department
  cod_meeting_type: any;
  str_download_link: any;
  bol_download: boolean;
  arr_departments: any;
  arr_meetings_types: any;
  obj_meeting_form: any;
  obj_ta_meeting: any;
  str_user_tmp: string;
  bol_no_owner: boolean = false;
  show_create_button: boolean = true;
  bol_error_submit: boolean = false;
  cod_meeting_line: any;
  bol_success: boolean;
  bol_check_active: boolean = false;
  buttonLocation: boolean;
  errors: any = {};

  arr_meetings_kpis: any;
  arr_selected_kpis: any;

  constructor(
      public _authService: AuthService,
      private _meetingsService: MeetingsService,
      private _meetingsTypesService: MeetingsTypesService,
      private _kpisService: KpisService,
      private _userPreferencesService: UserPreferencesService,
      private _translateService: TranslateService,
      private _router: Router
  ) {

    this.arr_meetings_kpis = [];
    this.arr_selected_kpis = [];

    this._meetingsService.setMeetingActionSourcesAutofill();

    this.obj_user = this._authService.getAuthenticatedUser();
    this.buttonLocation = this._authService.getUserPrivilege('MEETING_LIST_LOCATION_MEETINGS') ? true : false;
    this.str_name_location = this.obj_user.name_location;
    if (localStorage.getItem('shouldGetBackToLocationMeetings') == 'true') {
      this.actualTab = 'meetings-location';
      localStorage.setItem('shouldGetBackToLocationMeetings', 'false');
    }
    this.str_user_tmp = "";
    this.obj_meeting_form = {
      str_meeting_name: '',
      str_meeting_type_name: '',
      str_file_path: environment().uploadGetFilesApiUrl + '/',
      arr_users: [{
        cod_user: this.obj_user.id,
        str_name: this.obj_user.name,
        str_img_path: this._userPreferencesService.getLocalPicture(),
        cod_usu_participant: this.obj_user.id,
        bol_is_owner: 1
      }]
    };

    this._translateService.get('MEETING.USER_SEL').subscribe({
      next: (translate) => {
        this.obj_ta_meeting = {
          type: 'dynamic',
          placeHolder: translate,
          valueProperty: 'cod_user',
          searchProperty: 'str_name',
          hasImage: true,
          imageProperty: 'str_img_path',
          onSelect: (obj_selected: any) => {
            const exists = this.checkObjectExists(this.obj_meeting_form.arr_users, obj_selected.cod_user);
            if (!exists) {
              this.obj_meeting_form.arr_users.push({
                cod_user: obj_selected.cod_user,
                str_name: obj_selected.str_name,
                str_img_path: obj_selected.str_img_path,
                bol_is_owner: 0,
                cod_usu_participant: this.obj_user.id
              });
            }
            this.str_user_tmp = '';
          },
          asynchDataCall: (value: string, cb: any) => {
            this.searchUsers(cb, value);
          },
          onBlur: (item: any) => {
            this.str_user_tmp = '';
          },
        };
      },
      error: (err) => {
        const e = JSON.parse(err._body);
        alert(e.content);      }
    });
  }

  ngOnInit() {
    this._meetingsService.getMeetingsToday(this.obj_user.id, 'NOT_PRIVILEGE').subscribe({
      next: (data) => {
        this.arr_meetings_today = data.content.results;
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });

    const params = {
      term: '',
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.obj_user.cod_location
    };

    this.getDepartmentList(params);

    this._meetingsTypesService.getMeetingTypesByMeetingLocation(params).subscribe({
      next: (data) => {
        if (data.content.length > 1) {
          this.arr_meetings_types = data.content.results;
        } else {
          this.arr_meetings_types = [data.content.results];
        }
      },
      error: (err) => {
        const e = JSON.parse(err._body);
        alert(e.content);
      }
    });

    this.loadKpis();

    this._translateService.onLangChange.subscribe(() => this.getDepartmentList(params));
  }

  loadKpis() {
    this._kpisService
      .getKpis({_order: 'name'})
      .subscribe({
        next: result => {
          if (result.code == 888) {
            this.arr_meetings_kpis = result.content.data;
          } else {
            console.log(result);
          }
        },
        error: err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      })
  }

  selectKpis(event) {
    if (event.selectedOptions.length > 0) {
      //this.bol_is_changing_kpis = true
      this.arr_selected_kpis = event.selectedOptions.map(e => e.uuid);
    }
  }

  getKpiCode(id) {
    const kpi = this.arr_meetings_kpis.find(k => k.uuid == id);
    return kpi ? kpi.code : '';
  }

  getKpiName(id) {
    const kpi = this.arr_meetings_kpis.find(k => k.uuid == id);
    return kpi ? kpi.name : '';
  }

  getDepartmentList(params) {
    this._meetingsService.getAreasByMeetingLocation(params).subscribe({
      next: (data: any) => {
        if (data.content.length >= 0 && !Array.isArray(data.content.results)) {
          this.arr_departments = [data.content.results];
        } else {
          this.arr_departments = data.content.results;
        }
      },
      error: (err) => {
        const e = JSON.parse(err._body);// double check if this works
        alert(e.content);
      }
    });
  }

  changeTab(tabName) {
    this.actualTab = tabName;
    if (this.actualTab == 'meetings-location') {
      this.bol_create = false;
      this.clearForm();
    }
  }

  clearForm() {
    this.cod_area = null;
    this.selected_area = null;
    this.cod_meeting_type = null;
    this.bol_check_active = false;
    this.obj_meeting_form = {
      str_meeting_name: '',
      str_meeting_type_name: '',
      str_file_path: environment().uploadGetFilesApiUrl + '/',
      arr_users: [{
        cod_user: this.obj_user.id,
        str_name: this.obj_user.name,
        str_img_path: this._userPreferencesService.getLocalPicture(),
        cod_usu_participant: this.obj_user.id,
        bol_is_owner: 1
      }]
    };
  }

  canShowTab(tabName) {
    let canShow = this._authService.getUserPrivilege(tabName);
    return canShow;
  }

  createMeeting() {
    this.bol_create = true;
  }

  selectedDepartmenthanged(event) {
    this.cod_area = event.selectedOptions[0].cod_areas;
    this.selected_area = event.selectedOptions[0];
  }

  showDownload(event, i) {
    const selected = event.selectedOptions;
    if (selected.length > 0) {
      this.cod_meeting_type = selected[0].cod_meetings_types;
      this.str_download_link = selected[0].str_pdf_path;
      this.bol_download = true;
    }
  }

  checkObjectExists(arr, cod_user) {
    var id = arr.length + 1;
    var found = arr.some(function (el) {
      return el.cod_user === cod_user;
    });
    return found;
  }

  searchUsers(cb, str_term) {
    this._meetingsService.getUsersList(str_term, environment().uploadGetFilesApiUrl + '/', this.obj_user.id, 'MEETING_SELECT_PARTICIPANTS').subscribe({
      next: (result: any) => {
        cb(result.content.results);
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  changeOwner(index, isOwner) {
    if (isOwner === 0) {
      let countOwners = 0;
      this.obj_meeting_form.arr_users.map(user => {
        if (user.bol_is_owner === 1) {
          countOwners++;
        }
      });
      if (countOwners > 1) {
        this.obj_meeting_form.arr_users[index].bol_is_owner = isOwner;
      }
      else {
        this.bol_no_owner = true;
        setTimeout(() => {
          this.bol_no_owner = false;
        }, 5000);
      }
    }
    else {
      this.obj_meeting_form.arr_users[index].bol_is_owner = isOwner;
    }
  }

  delParticipant(int_participant) {
    this.obj_meeting_form.arr_users.splice(int_participant, 1);
  }

  validateFields() {
    this.errors = {};

    if (!this.obj_meeting_form._str_meeting_name) {
      this.errors.str_name = this._translateService.instant('MEETINGS_LIST.MEETING_NAME') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    if (!this.str_name_location) {
      this.errors.str_name_location = this._translateService.instant('MEETINGS_LIST.MEETING_UNITY') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    if (!this.selected_area) {
      this.errors.selected_area = this._translateService.instant('MEETINGS_LIST.MEETING_DEPARTMENT') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    if (!this.cod_meeting_type) {
      this.errors.cod_meeting_type = this._translateService.instant('MEETING.TYPE_SEL') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    if (!this.obj_meeting_form.arr_users.length) {
      this.errors.participants = this._translateService.instant('MEETINGS_LIST.PARTICIPANTS') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    if (!this.arr_selected_kpis.length) {
      this.errors.kpis = this._translateService.instant('MEETINGS_LIST.KPIS') + ' ' + this._translateService.instant('USERS.VALIDATE_FIELDS.IS_REQUIRED');
    }
    return Object.keys(this.errors).length === 0;
  }

  submit() {
    this.show_create_button = false;
    this.bol_error_submit = false;

    if (!this.validateFields()) {
      this.bol_error_submit = true;
      this.show_create_button = true;
      return;
    }

    const params = {
      str_name: this.obj_meeting_form._str_meeting_name,
      cod_area: this.cod_area,
      cod_meeting_type: this.cod_meeting_type,
      cod_meeting_line: this.cod_meeting_line,
      arr_kpis: this.arr_selected_kpis,
      cod_user: this.obj_user.id,
      arr_participants: this.obj_meeting_form.arr_users,
      only_participants: this.bol_check_active
    };

    this._meetingsService.postMeeting(params).subscribe({
      next: (data) => {
        this.bol_success = true;
        const cod_meeting = { cod_meetings: data.content.results.meetings_sp_in_meeting };
        this.openMeeting(cod_meeting);
      },
      error: (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    });
  }

  openMeeting(obj_meeting) {
    const path = '/meetings-container/' + obj_meeting.cod_meetings;
    this._router.navigate([path]);
  }

  selectStatusActive(event) {
    this.bol_check_active = event == 'selected';
  }
}
