import {Injectable} from '@angular/core';
import {ListRegionalsMainService} from './list-regionals-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListRegionalsService {

  constructor(private _mainService: ListRegionalsMainService) {
  }
  
  getRegionalsList(param): Observable<any> {
    let path = '/forms/locations-regionals/'+ param.cod_user +'/' + param.cod_location_world +'/' + param.term;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
