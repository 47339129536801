import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {GeneralTopic3Component} from './general-topic-3/general-topic-3.component';

@NgModule({
    imports: [
CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [GeneralTopic3Component],
    declarations: [GeneralTopic3Component],
    providers: []
})
export class InfoGeneralModule {
}
