import { EChartsOption } from "echarts";
import { TranslateService } from "@ngx-translate/core";
import {Component, Input, OnInit, SimpleChanges, } from "@angular/core";
import { R3TargetBinder } from "@angular/compiler";

@Component({
  selector: 'app-action-status',
  templateUrl: './action-status.component.html',
  styleUrls: ['./action-status.component.css']
})
export class ActionStatusComponent implements OnInit {

  chartOptions: any;

  @Input("data") data: any;
  @Input("isLoading") isLoading: boolean;

  hoursTranslate: string;
  weeksTranslate: string;
  echartsInstance: any;
  chartTitleTranslation: string;
  legendChart: any = [];

  constructor(private _translateService: TranslateService) {
    this._translateService
      .get("MEETING.CHART_ACTIONS")
      .subscribe((translation) => {
        this.chartTitleTranslation = translation;
    });
  }

  ngOnInit(): void {
    
    if (typeof this.data != typeof undefined && this.data) { 
      this.data.map(actionsStatus => {
        actionsStatus.value = actionsStatus.action_count;
        actionsStatus.name = actionsStatus.str_status_description ;
        actionsStatus.label = { formatter:'{d}%'};
        this.legendChart.push(actionsStatus.str_status_description);     

        switch(actionsStatus.cod_action_status) {
          case 1:
            actionsStatus.itemStyle = {color: "#949494"}
            break;
          case 2:
            actionsStatus.itemStyle = {color: "#fdb849"}
            break;
          case 3:
            actionsStatus.itemStyle = {color: "#34c141"}
            break;
          case 7:
            actionsStatus.itemStyle = {color: "#3398DB"}
            break;
          case 8:
            actionsStatus.itemStyle = {color: "#fc4e53"}
            break;
          default:
            break;
        }
      });
    }
    
    this.chartOptions = {
      title: {
        text: `${this.chartTitleTranslation} (%)`,
        x: 'center',
        top: 20,
        textStyle: {
          fontSize: 15
        }
      },
      tooltip: {
        trigger: "item",
        formatter: '{b} : {c} ({d}%)'
      },
      series: [
        {
          type: 'pie',
          radius: '30%',
          data: this.data,
          percentPrecision: 0,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ],
      animation: true,
    };
  }

  onChartInit(instance) {
    this.echartsInstance = instance;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.echartsInstance) { 
      this.echartsInstance.setOption({
        series: [
          {
            data: this.data,
            type: 'pie',
          },
        ]
      });
    }
  }
}
