import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {AuthService} from '../../main/access/services/auth.service';
import {MeetingsService} from '../services/meetings.service';
import {ProductsKpisService} from '../services/products-kpis.service';
import {MeetingsIndicatorsService} from '../services/meetings-indicators.service';

@Component({
  selector: 'app-meetings-container',
  templateUrl: './meetings-container.component.html',
  styleUrls: ['./meetings-container.component.css']
})
export class MeetingsContainerComponent implements OnInit {

  activeTab: string;
  cod_meeting: number;
  meeting: any = {};
  items: Array<any> = [];
  arr_orders: Array<string>;
  str_filter: string;
  countActive = 0;
  countGreen: any = {};
  isLoadingFarol = false;
  year: any = {};
  years: any = [];

  constructor(private _translateService: TranslateService, private _meetingsService: MeetingsService,
    private _activatedRoute: ActivatedRoute, private _authService: AuthService, private _productsKpisService: ProductsKpisService,
    private _meetingsIndicatorsService: MeetingsIndicatorsService) {
      this.year = [this.getCurrentYearObject()];
    }

  ngOnInit() {
    if (this.str_filter === undefined) {
        this.str_filter = moment().format('YYYY-MM-DD');
    }
    // this.loadMeetingDetails(false);
  }

  reloadMeeting($event) {
    if ($event) {
      const newYear = $event.selectedOptions && $event.selectedOptions.length ? $event.selectedOptions[0] : this.getCurrentYearObject();
      if (newYear.int_year !== this.year[0].int_year) {
        this.year = [newYear];
      }
    }
    // this.loadMeetingDetails(true);
  }

  // loadMeetingDetails(keepTab = false) {
  //   const year = this.year[0].int_year;
  //   this._activatedRoute.params.subscribe(params => {
  //     this.cod_meeting = params['id'];
  //     this._meetingsService.getMeetingDetails(this.cod_meeting, this._authService.getAuthenticatedUser().id, 'NOT_PRIVILEGE')
  //     .subscribe((result: any) => {
  //       this.meeting = result.content.results;
  //       if (!keepTab) {
  //         this.activeTab = 'meetings';
  //       }
        // if (this.meeting.str_extended_tabs === 'FAROL') {
        //   this.isLoadingFarol = true;
        //   this.loadYears();
        //   this._productsKpisService.getProductsKpisByYear(this.cod_meeting, year, this._authService.getAuthenticatedUser().id)
        //   .subscribe(data => this.handleData(data.content.results));
        // }
        // if (this.meeting.str_extended_tabs === 'TOR') {
        //     const cod_indicators = this.meeting.arr_indicators.map(ind => ind.cod_indicators);
        //     this._meetingsIndicatorsService.getExternalsByIndicators(cod_indicators, this._authService.getAuthenticatedUser().id).subscribe(resultKpis => {
        //       this.meeting.externalKpis = resultKpis.content.results;
        //     });
        // }
  //     });
  //   },
  //   err => { this._authService.errCheck(err); console.log(err); }
  //   );
  // }

  handleData(data) {
    this.items = data;
    for (const item of this.items) {
      if (!item.arr_data_values) {
        continue;
      }
      item.arr_data_values.forEach(itemValue => {
        const {int_month, monthRealValue, monthGoalValue, str_color} = itemValue;
        item[`monthRealValue${int_month}`] = monthRealValue;
        item[`monthGoalValue${int_month}`] = monthGoalValue;
        item[`monthColor${int_month}`] = str_color;
       }
      );
    }
    this.countActives();
    this.countGreens();
    this.isLoadingFarol = false;
  }

  countActives() {
    this.countActive = this.items.filter(item => item.bol_active).length;
  }

  countGreens() {
    this.countGreen = {};
    const itemMonthValues = this.items.filter(item => item.bol_active).map(item => item.arr_data_values);
    for (const itemValue of itemMonthValues) {
      if (!itemValue) {
        continue;
      }
      for (const monthValue of itemValue) {
        const {int_month, str_color} = monthValue;
        this.countGreen[int_month] = str_color === 'GREEN'
          ? this.countGreen[int_month]
           ? this.countGreen[int_month] + 1
           : 1
          : this.countGreen[int_month] || 0;
      }
    }
  }

  // setActiveTab(tab) {
  //   this.activeTab = tab;
  //   if (this.activeTab === 'meetings' || this.activeTab === 'preparation') {
      // needed to reload TOR KPI's info.
      // this.loadMeetingDetails(true);
  //   }
  // }

  updateKpiState(event, item) {
    const status =  event === 'selected';
    const user = this._authService.getAuthenticatedUser();
    item.bol_active = status;
    this._productsKpisService.putKpiStatus(item.cod_indicators_farol, status, user.id).subscribe(
      _ => {
        this.countActives();
        this.countGreens();
      }
    );
  }

  setActionsOrder(str_order) {
    if (this.arr_orders.indexOf(str_order) < 0) {
    this.arr_orders.push(str_order);
    }
  }

  meetingDateChanged(date) {
    this.str_filter = moment(date).format('YYYY-MM-DD');
  }

  getCurrentYearObject() {
    return {int_year: moment().year(), str_year: moment().format('YYYY')};
  }

  // loadYears() {
  //   const {cod_meetings, cod_unit, cod_meeting_line} =  this.meeting;
  //   this._productsKpisService.getYears(cod_meetings, cod_unit || 0, cod_meeting_line || 0, this._authService.getAuthenticatedUser().id).subscribe(res =>
  //     this.years = res.content.results);
  // }

}
