import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';

@Injectable()
export class MyNotesService {

  constructor(private _mainService: MainService) { }

  getNotes(cod_user): Observable<any> {
    const path = '/notes/' + cod_user;
    return this._mainService.get(path);
  }

  getNotesByStatus(cod_user, bol_done): Observable<any> {
    const path = '/notes/' + cod_user + '/' + bol_done;
    return this._mainService.get(path);
  }

  postNote(params): Observable<any> {
    const path = '/notes';
    return this._mainService.post(path, params);
  }

  putNote(params): Observable<any> {
    const path = '/notes';
    return this._mainService.put(path, params);
  }

  deleteNote(params): Observable<any> {
    const path = '/notes/' + params.cod_notes + '/' + params.cod_user;
    return this._mainService.delete(path);
  }

  getArrayContent(data: any) {
    return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }
}
