import {Component, OnInit} from '@angular/core';
import {AuthService} from "../access/services/auth.service";

@Component({
    selector: 'app-reports-email',
    templateUrl: './reports-email.component.html',
    styleUrls: ['./reports-email.component.css']
})
export class ReportsEmailComponent implements OnInit {
    isLoading: boolean = true;
    actualTab: string;

    constructor(private _authService: AuthService) {
        this.actualTab = '';
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    changeTab(tabName) {
        this.actualTab = tabName;
    }

    canShowTab(tabName) {
        let canShow = this._authService.getUserPrivilege(tabName);
        if (canShow && this.actualTab == '') {
            switch(tabName) {
                case 'REPORT_MEETING':
                    this.actualTab = 'reports-meetings';
                break;
                case 'REPORT_ROUTES':
                    this.actualTab = 'reports-routines';
                break;
                case 'REPORT_FORMS':
                    this.actualTab = 'reports-forms';
                break;
                case '5WHY_REPORT':
                    this.actualTab = 'reports-fivewhy';
                break;
                default: 
                    this.actualTab = '';
            }
        }

        return canShow;
    }

}