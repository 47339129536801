import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Category } from '../../models/category.model';
import { Question } from '../../models/question.model';

@Component({
    selector: 'forms-category',
    templateUrl: './forms-category.component.html',
    styleUrls: ['./forms-category.component.css']
})

export class FormsCategoryComponent implements OnChanges, OnInit {

    @Input() category: Category = null;
    @Input() key: number = null;

    @Output() onSave = new EventEmitter();
    @Output() onCancel = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onEdit = new EventEmitter();
    @Output() onExpand = new EventEmitter();
    @Output() onEditTranslations = new EventEmitter();
    @Output() onEditQuestion = new EventEmitter();
    @Output() onDeleteQuestion = new EventEmitter();
    @Output() onCopyQuestion = new EventEmitter();

    isLoading: boolean = false;
    isEditing: boolean = false;
    showBlockingPopUp: boolean = false;
    orderQuestionBlockType = null;

    constructor(private _translateService: TranslateService) { }

    ngOnChanges(changes: SimpleChanges):void {
        this.isEditing = (this.category.cod_forms_category !== 0);
    }

    ngOnInit(){
    }

    _getQuestions() {
        if (!(this.category.cod_forms_category > 0)) return;
        this.category.questions = [];
    }

    editCategory() {
        this.onEdit.emit(this.key);
    }

    validateCategory() {
        return true;
    }

    editTranslations() {
        this.onEditTranslations.emit(this.key);
    }

    save() {

        if (!this.validateCategory()) {
            return;
        }

        this.onSave.emit(this.category);
    }

    cancel() {
        this.onCancel.emit();
    }

    delete() {
        this.onDelete.emit(this.key);
    }

    editQuestion(question: Question = null): void {
        if (!this.category.expanded) this.expand();
        this.onEditQuestion.emit({ category: this.key, question: question  });
    }
    closeBlockPopUp(){
        this.showBlockingPopUp = false
    }
    drop(event: CdkDragDrop<string[]>) {
        let firstIndexArea = this.category.questions.findIndex(x=>x.int_type==8) //Area
        if(this.category.questions[event.previousIndex].int_type==9 //SubArea = Department
           && firstIndexArea!=-1
           && firstIndexArea>=event.currentIndex){
            this.orderQuestionBlockType = 1;
            this.showBlockingPopUp = true
            return
        }
        if(this.category.questions[event.previousIndex].int_type==10 //Equipment
            && firstIndexArea!=-1
            && firstIndexArea>=event.currentIndex){
             this.orderQuestionBlockType = 2;
             this.showBlockingPopUp = true
             return
        }
        if(this.category.questions[event.previousIndex].int_type==17 //Machine
            && firstIndexArea!=-1
            && firstIndexArea>=event.currentIndex){
             this.orderQuestionBlockType = 3;
             this.showBlockingPopUp = true
             return
        }

        let firstIndexDep = this.category.questions.findIndex(x=>x.int_type==9) //SubArea = Department
        if(this.category.questions[event.previousIndex].int_type==10 //Equipment
            && firstIndexDep!=-1
            && firstIndexDep>=event.currentIndex){
             this.orderQuestionBlockType = 2;
             this.showBlockingPopUp = true
             return
        }
        if(this.category.questions[event.previousIndex].int_type==17 //Machine
            && firstIndexDep!=-1
            && firstIndexDep>=event.currentIndex){
             this.orderQuestionBlockType = 3;
             this.showBlockingPopUp = true
             return
        }

        let firstIndexEquip = this.category.questions.findIndex(x=>x.int_type==10) //Equipment
        if(this.category.questions[event.previousIndex].int_type==17 //Machine
            && firstIndexEquip!=-1
            && firstIndexEquip>=event.currentIndex){
             this.orderQuestionBlockType = 3;
             this.showBlockingPopUp = true
             return
        }

        let indexes = []
        for(let q of this.category.questions){
            if(q.int_type==8){
                indexes.push(this.category.questions.indexOf(q))
            }
            if(q.int_type==9){
                indexes.push(this.category.questions.indexOf(q))
            }
            if(q.int_type==10){
                indexes.push(this.category.questions.indexOf(q))
            }
            if(q.int_type==17){
                indexes.push(this.category.questions.indexOf(q))
            }
        }
        
        moveItemInArray(this.category.questions, event.previousIndex, event.currentIndex);
        this.orderQuestionsNumber();
    }

    orderQuestionsNumber() {
        this.category.questions = this.category.questions.map((val, i) => {
            val.int_order = i + 1;
            return val;
        });
    }

    toggleDeleteQuestion(question: Question): void {
        this.onDeleteQuestion.emit({ category: this.key, question: question });
    }

    expand() {
        this.onExpand.emit(this.key);
    }
    copyQuestion(q){
        this.onCopyQuestion.emit({ category: this.key, question: q  });
    }

}
