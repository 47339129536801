import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {MainService} from './main.service';

@Injectable()
export class PillarsService {

    constructor(private _mainService: MainService) {

    }

    getPillarList(params): Observable<any> {
        let path;
        if (params.cod_pillars > 0) {
            path = '/pillar/' + params.cod_pillars;
            return this._mainService.get(path);
        }
        else {
            path = '/pillars';
            return this._mainService.post(path, params);
        }
    }

    postPillar(params): Observable<any> {
        let str_path = '/pillar';
        return this._mainService.post(str_path, params);
    }

    putPillar(params): Observable<any> {
        let str_path = '/pillar';
        return this._mainService.put(str_path, params);
    }

    deletePillar(params) {
        let str_path = '/pillar/' + params.cod_pillars + '/' + params.cod_user;
        return this._mainService.delete(str_path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

}