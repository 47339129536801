import {Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {RoutinesService} from '../services/routines.service';
import {LogbookRoutineService} from '../services/logbook-routine.service';
import {AcadiaIntegrationService} from '../services/acadia-integration.service';
import {UserPreferencesService} from './../../user-preferences/services/user-preferences.service';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-logbooks-routines-list',
    templateUrl: './logbooks-routines-list.component.html',
    styleUrls: ['./logbooks-routines-list.component.css'],
    animations: [
        trigger('logbooksRoutineList', [
            state('inactive', style({
                display: 'none'
            })),
            state('active', style({
                display: ''
            })),
            transition('inactive => active', animate('100ms ease-in')),
            transition('active => inactive', animate('100ms ease-out'))
        ])
    ]
})
export class LogbooksRoutinesListComponent implements OnInit, OnChanges {
    @Input() routinesPeriodicityCount;
    @Input() date;
    @Input() collabParams;
    @Input() routineSelected;
    @Input() index;
    @Input() routines = [];
    @Input() openIndex;
    @Input() lastId;
    @Input() totalRoutines;
    @Input() routinesExecuted;
    @Optional() @Input() cod_routines: number;
    @Optional() @Input() cod_periodicity: number;
    @Output() taskSelected = new EventEmitter<Object>();
    @Output() afterQuickAction = new EventEmitter<any>();

    @Input() state: string;
    cod_user = this._authService.getAuthenticatedUser().id;
    chartStyle;
    chartData = {total: 1, value: 0};
    openedId: number;
    statusLanguage: any;
    doneTranslation: string;
    errorMessage = '';
    showError = false;
    buttonSize = '100px';

    constructor(
        private _routinesService: RoutinesService, 
        private _logbookRoutineService: LogbookRoutineService,
        private _acadiaIntegrationService: AcadiaIntegrationService,
        private _userService: UserPreferencesService,
        private _authService: AuthService, 
        private _translateService: TranslateService
    ) {
        this.state = 'inactive';
        this.chartStyle = {
            pointFormat: 20,
            color: ['rgb(49,195,75)', 'rgb(197,197,197)'],
            innerSize: 35,
            width: 90,
            height: 90,
            fontSize: 10,
            percentMarginTop: 35,
            percentMarginLeft: 30
        };
    }


    ngOnInit() {
        this._translateService.get('LOGBOOK.STATUS_PENDING').subscribe(translate=> {
            this.statusLanguage = translate;
        });

        this._translateService.get('GENERAL.STATUS_DONE').subscribe(translate=> {
            this.doneTranslation = translate;
        });

        this.setButtonSize(localStorage.getItem('userPreferencesLanguage'));
        this._translateService.onLangChange.subscribe(translate => this.setButtonSize(translate.lang));

    }

    ngOnChanges(changes: SimpleChanges) {
        const { cod_routines, cod_periodicity } = changes;
    
        const taskSelected = this._routinesService.getTaskSelected();
        if (cod_periodicity?.currentValue) this.drillOpen(cod_periodicity.currentValue, cod_routines?.currentValue);
        else if (taskSelected) {
            if (taskSelected.type == 'routine') {
                const routine = taskSelected.routine;

                if (routine?.cod_periodicity == this.routinesPeriodicityCount.cod_periodicity) {
                    const routineSelectedIndex = this.routines.findIndex(r => (r.cod_routines == routine.cod_routines && r.bol_type_collab == routine.bol_type_collab));
                    if (routineSelectedIndex > 0)
                        this.openRoutine(this.routines[routineSelectedIndex], routineSelectedIndex, false);
                }
            }
        }

        this.updateChartData();
    }

    drillOpen(cod_periodicity: number, cod_routines: number) {
        setTimeout(() => {
            const evaluationCode = this.routinesPeriodicityCount?.cod_periodicity
            if (evaluationCode && cod_periodicity && evaluationCode == cod_periodicity) { 
                this.toggleRoutineList('active');
                const routineIndex = this.routines.findIndex(routine => routine.cod_routines == cod_routines);
                if (routineIndex > -1) {
                    this.openRoutine(this.routines[routineIndex], routineIndex);
                    setTimeout(() => {
                        const routineHtmlId = this.createRoutineHtmlId(this.routines[routineIndex]);
                        const routineHtmlElement = document.getElementById(routineHtmlId);
                        const y = routineHtmlElement?.getBoundingClientRect().top + window.scrollY;
                        window.scroll({ top: y - 300, behavior: 'smooth' })
                    }, 50)
                    
                }
            }
        }, 10);
    }

    setButtonSize(lang: string) : void {
        switch (lang) {
            case 'ru':
            case 'ua':
            case 'uk':
                this.buttonSize = '180px';
                break;
            case 'pt':
            case 'es':
                this.buttonSize = '130px';
                break;
            default:
                this.buttonSize = '100px';
                break;
        }
    }

    updateChartData() {
        this.chartData = {
            total: this.totalRoutines,
            value: this.routinesExecuted
        };
    }

    /**
     * @author Guilherme Renato Uller
     *
     *
     */
    toggleRoutineList(state: string = null) {
        const fallBackState = this.state == 'active' ? 'inactive' : 'active';
        const payloadState = typeof state === 'string' ? state : fallBackState; 
        this.state = payloadState ?? fallBackState;
        this.taskSelected.emit({type: 'routineSel', index: (this.state === 'active') ? this.index : null });
    }

    /**
     * @author Guilherme Renato Uller
     *
     * @param routine{object}
     */
    openRoutine(routine: any, index: any, refresh = false) {
        this.openedId = routine.cod_routines;
        this.openIndex = index;
        routine = {
            'type': 'routine',
            'routine': routine,
            'openIndex': index,
            'lastId': this.index,
            'refresh': refresh,
        };
        this.taskSelected.emit(routine);
    }

    createRoutineHtmlId(routine): string {
        const collabRoutine = (routine.bol_type_collab) ? "1" : "0";
        const htmlId: string = "routine-" +
                                routine.cod_periodicity.toString().padStart(2, "0") +
                                routine.cod_routines.toString().padStart(20, "0") +
                                collabRoutine;
        routine.html_id = htmlId;

        return htmlId;
    }

    trackByFn(index, item) {
        return index;
    }

    filterOpenedRoutines() {
        return this.routines.filter(x => !x.boll_executed);
    }

    filterDoneRoutines() {
        return this.routines.filter(x => x.boll_executed);
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    taskList(routine, index): void {

        if (!routine.bol_acadia_task_list_created) {

            let params = {
                'acadia_document': routine.str_acadia_document,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'global_id': this._authService.getAuthenticatedUser().ambev_id,
                'user_language': this._userService.getLocalLanguage(),
                'dat_selected': this.date,
                'cod_logbook': routine.cod_logbook,
                
            };

            if (routine.bol_type_collab) {
                params['source'] = 'collaborative';
                params['cod_logbook_routines_periodicity_users_collaborative'] = routine.cod_logbook_routines_periodicity_users;
                params['cod_shift'] = routine.cod_shift;
            } else {
                params['source'] = 'functional';
                params['cod_logbook_routines_periodicity_users'] = routine.cod_logbook_routines_periodicity_users;
            }
    
            this._acadiaIntegrationService.createTaskList(params).subscribe({
                next: data => {
                    if (data.type === "success") {
                        routine.cod_acadia = data.content.cod_acadia;
                        routine.str_acadia_task_list_url = data.content.str_acadia_task_list_url;
                        routine.bol_acadia_task_list_created = data.content.bol_acadia_task_list_created;
                        routine.int_acadia_tasks_count = data.content.int_acadia_tasks_count;
                        routine.int_acadia_completion_count = data.content.int_acadia_completion_count;
                        routine.int_acadia_skipped_count = data.content.int_acadia_skipped_count;
                        routine.int_acadia_completion_percent = data.content.int_acadia_completion_percent;
                        routine.str_acadia_document_language = data.content.str_acadia_document_language;
                        routine.bol_acadia_document_language_equals_ial_language = data.content.bol_acadia_document_language_equals_ial_language;
                        
                        this._acadiaIntegrationService.openAcadiaTaskList(routine.str_acadia_task_list_url);
                        routine.is_loading = false;
                        this.openRoutine(routine, index, true);
                        this.afterQuickAction.emit();
                    } else {
                        const errorMessage = data.content ? data.content : "Request Error.";
                        this._showErrorMessage(errorMessage);
                        routine.is_loading = false;
                    }
                }, 
                error: err => {
                    routine.is_loading = false;
                    // TODO: This message is set only until ACADIA TEAM changes their return code.
                    this._showErrorMessage('Something went wrong when trying to create new Task List. Please contact the support.');
                    this._authService.errCheck(err);
        
                }
            });

        } else if (!routine.bol_acadia_task_list_finished) {
            this._acadiaIntegrationService.openAcadiaTaskList(routine.str_acadia_task_list_url);
            routine.is_loading = false;
        }

    }

    markDone(routine, index): void {

        let objParam = {
            'cod_routine_status': 2,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dat_selected': this.date,
            'completeness': 100,
        };

        let promise = null;

        if (routine.bol_type_collab) {
            objParam['cod_logbook_routines_periodicity_users_collaborative'] = routine.cod_logbook_routines_periodicity_users;
            objParam['cod_shift'] = localStorage.getItem('shiftCollab');
            objParam['cod_logbooks'] = routine.cod_logbook;

            promise = this._routinesService.updateRoutineStatusCollaborative(objParam);
        } else {
            objParam['cod_logbook_routines_periodicity_users'] = routine.cod_logbook_routines_periodicity_users;

            promise = this._logbookRoutineService.changeRoutineStatus(objParam);
        }

        promise.subscribe(data => {
            routine.cod_routine_status = 2;
            routine.str_status_description = this.doneTranslation;
            const dat = new Date().toISOString();
            if(routine.bol_type_collab){ // temporary done date for display, while it's not refreshed from the server
                routine.dat_finish = dat; 
            } else{
                routine.dat_alt = dat;
            }
            if (!routine.str_user_name) {
                routine.str_user_name = this._authService.getAuthenticatedUserNameNoCaps();
            }
            this.openRoutine(routine, index, true);
            this.afterQuickAction.emit();
        }, err => {
            console.log(err);
            const e = JSON.parse(err._body);
            console.error(e.content);
            this._authService.errCheck(err);
        }, () => routine.is_loading = false);
    }

    quickAction(routine, func, index): void {
        
        routine.is_loading = true;

        if (routine.cod_logbook_routines_periodicity_users == null) {
            
            let params = {
                'cod_user': this._authService.getAuthenticatedUser().id,
                'cod_logbook_routines_periodicity': routine.cod_logbook_routines_periodicity,
                'cod_form': null,
                'date': this.date,
            };

            let promise = null;

            if (routine.bol_type_collab) {
                params['cod_shift'] = localStorage.getItem('shiftCollab');
                params['cod_subarea'] = localStorage.getItem('subareaCollab');

                promise = this._routinesService.getOrInsertRoutinePeriodicityCollab(params);
            } else {
                promise = this._logbookRoutineService.getRoutinePeriodicityUser(params);
            }

            promise.subscribe(data => {
                routine.cod_logbook_routines_periodicity_users = 
                    data.content.results.cod_logbook_routines_periodicity_users_collaborative
                    ? data.content.results.cod_logbook_routines_periodicity_users_collaborative
                    : data.content.results.cod_logbook_routines_periodicity_users;

                this[func](routine, index);
            }, err => {});

        } else {
            this[func](routine, index);
        }
    }

}
