export enum CommentType {
    TXT = 'text',
    IMG = 'img',
    PDF = 'pdf',
}
export enum SyncStatus {
    FAILURE = -1,
    SYNCING = 0,
    SUCCESS = 1,
}
