import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-workflow-icons-list',
  templateUrl: './workflow-icons-list.component.html',
  styleUrls: ['./workflow-icons-list.component.css']
})
export class WorkflowIconsListComponent {

  @Input("fivewhyStatusProgress") fivewhyStatusProgress: any;
  
  rootCauseFoundIcon: string = '../../../../assets/images/gold-medal.svg';
  abReportIcon: string = '../../../../assets/images/report-icon.svg';
  cancelIcon: string = '../../../../assets/images/cancel-icon.svg';
  completedIcon: string = '../../../../assets/images/check-icon.svg';

  constructor() { }

}
