// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-container {
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
}

.th {
  cursor: help;
}
.rating-column {
	padding-right: 2.2vw;
}

.no-padding-sides {
  padding-left: 15px;
  padding-right: 0;
}

.space-right {
  padding-right: 10px;
}

.textarea-5why {
  min-height: 120px;
}

.action-button {
  min-width: 130px;
  line-height: normal;
	padding: 8px;
}
.add-rating {
  width: 100%;
  max-width: 200px;
  margin-top: 15px;
  line-height: normal;
  padding: 8px;
}

.date-fivewhy {
  min-width: 87px;
}
.user-fivewhy {
  min-width: 150px;
}
.rating-fivewhy {
  min-width: 65px;
}

.rbuttons-fivewhy {
  min-width: 94px;
}

@media (max-width: 1080px) {
  .rating-table {
    font-size: 0.8rem;
  }
  .rating-fivewhy {
    min-width: 33px;
  }

  .rbuttons-fivewhy {
    min-width: 35px;
  }
  .date-fivewhy {
    min-width: 60px;
  }
  .user-fivewhy {
    min-width: 80px;
  }
}

.no-padding-left ::ng-deep .question-div {
  padding-left: 0px;
}

.table td {
  word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/app/five-why/fivewhy-rating/fivewhy-rating.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;CACC,oBAAoB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;CACpB,YAAY;AACb;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".background-container {\n  background-color: #fff;\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n\n.th {\n  cursor: help;\n}\n.rating-column {\n\tpadding-right: 2.2vw;\n}\n\n.no-padding-sides {\n  padding-left: 15px;\n  padding-right: 0;\n}\n\n.space-right {\n  padding-right: 10px;\n}\n\n.textarea-5why {\n  min-height: 120px;\n}\n\n.action-button {\n  min-width: 130px;\n  line-height: normal;\n\tpadding: 8px;\n}\n.add-rating {\n  width: 100%;\n  max-width: 200px;\n  margin-top: 15px;\n  line-height: normal;\n  padding: 8px;\n}\n\n.date-fivewhy {\n  min-width: 87px;\n}\n.user-fivewhy {\n  min-width: 150px;\n}\n.rating-fivewhy {\n  min-width: 65px;\n}\n\n.rbuttons-fivewhy {\n  min-width: 94px;\n}\n\n@media (max-width: 1080px) {\n  .rating-table {\n    font-size: 0.8rem;\n  }\n  .rating-fivewhy {\n    min-width: 33px;\n  }\n\n  .rbuttons-fivewhy {\n    min-width: 35px;\n  }\n  .date-fivewhy {\n    min-width: 60px;\n  }\n  .user-fivewhy {\n    min-width: 80px;\n  }\n}\n\n.no-padding-left ::ng-deep .question-div {\n  padding-left: 0px;\n}\n\n.table td {\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
