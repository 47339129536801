import {Component, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import {LogbookRoutineService} from "../../logbooks/services/logbook-routine.service";
import {RoutineStatusService} from "../../logbooks/services/routine-status.service";
import {FormsDynamicAdminService} from "../../forms/forms-dynamic/services/forms-dynamic.service";
import {AuthService} from "../../main/access/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {RoutinesService} from "../../logbooks/services/routines.service";

@Component({
    selector: 'component-routine-history',
    templateUrl: './routine-history.component.html',
    styleUrls: ['./routine-history.component.css'],
})
export class RoutineHistoryComponent implements OnChanges {

    @Input() showRoutine: boolean = false;
    @Input() codRoutine: number;
    @Input() isCollab: boolean = false;
    @Output() changedRoutine = new EventEmitter<any>();
    @Output() showingAnswers = new EventEmitter<boolean>();

    historySubMenu: boolean;
    limitSelected: number;
    listLimitDays: number[];
    isLoadingHistory: boolean = false;
    routineHistoryList: Object = [];
    routineHistoryListCollab: Object = [];
    showAnswers: boolean = false;
    showError: boolean = false;
    showHistoryMenu: boolean = false;
    errorMessage: string = '';
    isLoading: boolean;
    hasActions: boolean;
    codLogbookRoutinePeriodicityUsers: number;

    constructor(private _logbookRoutineService: LogbookRoutineService,
                private _routineStatusService: RoutineStatusService,
                private _formsDynamicService: FormsDynamicAdminService,
                private _authService: AuthService,
                private _translateService: TranslateService,
                private _routinesService: RoutinesService) {
        this.showError = false;
        this.errorMessage = '';
        this.historySubMenu = false;
        this.showHistoryMenu = true;
        this.isLoading = false;
        this.limitSelected = 30;
        this.listLimitDays = [30, 60, 90];
        this.hasActions = false;
    }

    ngOnChanges(changes: SimpleChanges) : void {
        if (changes.codRoutine) {
            this.showAnswers = false;
        }        
    }

    toggleHistorySubMenu() {
        this.historySubMenu = !this.historySubMenu;
        if (this.historySubMenu) {
            this.getRoutineHistory();
        }
    }

    closeHistorySubMenu(event) {
        if (event.srcElement.id != 'history-menu-icon') {
            this.historySubMenu = false;
        }
    }

    changeLimit(limit) {
        this.limitSelected = limit;
        this.getRoutineHistory();
    }

    getRoutineHistory() {
        this.isLoadingHistory = true;
        if(this.isCollab){
            let params = {
                cod_user: this._authService.getAuthenticatedUser().id,
                cod_routine: this.codRoutine, 
                limit: this.limitSelected,
                cod_subarea: localStorage.getItem('subareaCollab')
            };
            this._routinesService.getRoutinesHistoryCollaborative(params).subscribe(data => {
                    this.routineHistoryListCollab = this._routinesService.getArrayContent(data);
                    this.isLoadingHistory = false;
                }, err => {
                    this.isLoadingHistory = false;
                    console.log(err);
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
        else{
            this._logbookRoutineService.getRoutinesHistory(
                this._authService.getAuthenticatedUser().id, 
                this.codRoutine, 
                this.limitSelected
            ).subscribe(data => {
                this.routineHistoryList = this._logbookRoutineService.getArrayContent(data);
                this.isLoadingHistory = false;
            }, err => {
                this.isLoadingHistory = false;
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            });

        }
    }

    changeToHistory(routine) {
        if (this.changedRoutine) {
            this.changedRoutine.emit(routine);
        }
        this.historySubMenu = false;
        this.showAnswers = true;
        this.showRoutine = false;
        this.codLogbookRoutinePeriodicityUsers = this.isCollab 
            ? routine.cod_logbook_routines_periodicity_users_collaborative 
            : routine.cod_logbook_routines_periodicity_users;
        this.emitShowAnswers(true);
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    private emitShowAnswers(showAnswers: boolean) {
        this.showingAnswers.emit(showAnswers);
    }

    isShowingAnswers(showing: boolean) {
        this.showAnswers = showing;
        this.showRoutine = !showing;
        this.emitShowAnswers(showing);
    }
}
