import {Injectable} from '@angular/core';
import {ListEquipmentsMainService} from './list-equipments-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListEquipmentsService {
  
  constructor(private _mainService: ListEquipmentsMainService) {
  }
  
  getEquipments(param): Observable<any> {
    
    let  path = '/equipments-by-sub-areas/' + param.cod_subareas+ '/' + param.cod_locations + '/' + param.cod_user;
    
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
