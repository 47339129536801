import {Injectable} from '@angular/core';
import {FormPercentageEvolutionMainService} from './form-percentage-evolution-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class FormPercentageEvolutionService {
  
  constructor(private _mainService: FormPercentageEvolutionMainService) {
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
