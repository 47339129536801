// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
    background-color: var(--ui-primary) !important;
    color: var(--text-ui-primary) !important;
}

.action-tab {
    background-color: var(--ui-inverted);
    color: var(--text-ui-inverted);
    width: 150px;
    height: 65px;
}

.forms-location-tab { 
    line-height: initial !important;
}

.nav-item {
    border-bottom: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/my-forms/my-forms-main/my-forms-main.component.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,wCAAwC;AAC5C;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".active {\n    background-color: var(--ui-primary) !important;\n    color: var(--text-ui-primary) !important;\n}\n\n.action-tab {\n    background-color: var(--ui-inverted);\n    color: var(--text-ui-inverted);\n    width: 150px;\n    height: 65px;\n}\n\n.forms-location-tab { \n    line-height: initial !important;\n}\n\n.nav-item {\n    border-bottom: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
