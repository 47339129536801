import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../main/access/services/auth.service';
import {UserPreferencesService} from '../../../user-preferences/services/user-preferences.service';

@Injectable()
export class ListAreasMainService {

    // Set headers to handle the CORS
    private _headers: HttpHeaders;
    private _api_url;

    constructor(private _http: HttpClient, private _authService: AuthService, private _userPreferenceService: UserPreferencesService) {

        this._api_url = environment().authApiUrl;
        const obj_user = _authService.getAuthenticatedUser();
        // Set headers to handle the CORS
        this._headers = new HttpHeaders(
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
                'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
                'Access-Control-Allow-Credentials': 'true',
                'userlang': this._userPreferenceService.getLocalLanguage(),
                'X-Content-Type-Options': 'nosniff',            
                'X-Frame-Options': 'SAMEORIGIN',
                'Referrer-Policy': 'origin-when-cross-origin',
                'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
            
            }
        );
    }

    getApiUrl() {
        return this._api_url;
    }

    private _getHeaders(method: string) {
        this._headers = this._headers.set('Access-Control-Allow-Methods', method.toUpperCase());
        this._headers = this._headers.set('userlang', this._userPreferenceService.getLocalLanguage());
        return this._headers;
    }

    /**
     * GET request
     * @param path: Path of endpoint
     */
    get(path): Observable<any> {
        let url = this.getApiUrl() + path;
        let opt = {headers: this._getHeaders('GET')};
        const result = this._http.get(url, opt);
        result.pipe(
            map((res: Response) => {
                if (res.status == 401) {
                    this._authService.deleteAuthenticatedUser();
                    window.location.replace('');
                }
            })
        );
        return result;
    }

    getArrayContent(data: any) {
        if (!data || typeof data == typeof undefined) {
            return [];
        }
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }

}
