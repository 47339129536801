import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { UsersProfilesService } from '../services/users-profiles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../main/services/notification.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
    isLoading = true;
    actualTab: string;
    actualSubTab: string;
    areaTab: string;
    showAreaTab = false;
    showShiftsTab = false;
    isApprover = false;
    user: ReturnType<AuthService['getAuthenticatedUser']>;
    cod_meeting_type: number;
    constructor(
        public _authService: AuthService,
        private readonly _usersProfilesService: UsersProfilesService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _notify: NotificationService
    ) {
		this.cod_meeting_type = null;
        this.checkAreaTab();
        this.user = this._authService.getAuthenticatedUser();
        this.verifyIfIsApprover();
        this.initActualTab();
    }

    ngOnInit(): void {
        if (this._activatedRoute.snapshot.url[0]?.path === 'meeting-type') {
            try {
                let cod_meeting_type = Number(this._activatedRoute.snapshot.paramMap.get('cod_meeting_type'));
    
                if (isNaN(cod_meeting_type)) throw new Error('Meeting Type ID must be a number');
    
                if (this._authService.getUserPrivilege('ADMIN_MEETING_LIST_TYPE')) {
                    this.actualTab = 'admin-meetings';
                    this.actualSubTab = 'admin-meetings-types';
                    this.cod_meeting_type = cod_meeting_type;
                } else {
                    this._notify.error({text: 'USERS.PRIVILEGE_LEVEL', translate: true});
                    if (
                        this._authService.getUserPrivilege('ADMIN_FORMS_LISTING') ||
                        this._authService.getUserPrivilege('ADMIN_LOGBOOKS_LISTING') ||
                        this._authService.getUserPrivilege('ADMIN_LOGBOOKS_ROUTINES') ||
                        this._authService.getUserPrivilege('USERS_PERFIL_LISTING') ||
                        this._authService.getUserPrivilege('USERS_SCREEN_LISTING') ||
                        this._authService.getUserPrivilege('ADMIN_MEETING_LIST_INDICATORS') ||
                        this._authService.getUserPrivilege('ADMIN_MEETING_LIST_TYPE') ||
                        this._authService.getUserPrivilege('ADMIN_AREA_LISTING') ||
                        this._authService.getUserPrivilege('ADMIN_LIST_TRIGGER') ||
                        this._authService.getUserPrivilege('ADMIN_LIST_MACHINE')
                    ) {
                        this._router.navigate(['/admin']);
                    } else {
                        this._router.navigate(['/home']);
                    }
                }
                
            } catch (error) {
                this._notify.error({text: 'GENERAL.FAIL_TO_FETCH', translate: true});
                console.log(error);
                this._router.navigate(['/admin']);
            }
        }
    }

    removeMeetingTypeUrl(){
        this.cod_meeting_type = null;
        this._location.replaceState('/admin');
    }

    initActualTab() {
        this.actualTab = 'admin-forms';
        this.actualSubTab = '';
        switch (true) {
            case this._authService.getUserPrivilege('ADMIN_FORMS_LISTING'):
                this.actualTab = 'admin-forms';
                this.actualSubTab = '';
                break;
            case this._authService.getUserPrivilege('ADMIN_LOGBOOKS_LISTING'):
                this.actualTab = 'admin-logbooks';
                this.actualSubTab = 'admin-logbook-list';
                break;
            case this._authService.getUserPrivilege('ADMIN_LOGBOOKS_ROUTINES'):
                this.actualTab = 'admin-logbooks';
                this.actualSubTab = 'admin-routine';
                break;
            case this._authService.getUserPrivilege('USERS_PERFIL_LISTING'):
                this.actualTab = 'admin-users';
                this.actualSubTab = 'admin-profile-privileges';
                break;
            case this._authService.getUserPrivilege('USERS_SCREEN_LISTING'):
                this.actualTab = 'admin-users';
                this.actualSubTab = 'admin-users-list';
                break;
            case this._authService.getUserPrivilege('ADMIN_MEETING_LIST_INDICATORS'):
                this.actualTab = 'admin-meetings';
                this.actualSubTab = 'admin-meetings-indicators';
                break;
            case this._authService.getUserPrivilege('ADMIN_MEETING_LIST_TYPE'):
                this.actualTab = 'admin-meetings';
                this.actualSubTab = 'admin-meetings-types';
                break;
            case this._authService.getUserPrivilege('GENERAL_SHIFTS_LIST'):
                this.actualTab = 'admin-shifts';
                this.actualSubTab = 'admin-shift-list';
                break;
            case this._authService.getUserPrivilege('ADMIN_AREA_LISTING'):
            case this._authService.getUserPrivilege('ADMIN_STRUCTURE_LISTING'):
            case this._authService.getUserPrivilege('ADMIN_LIST_MACHINE'):
            case this._authService.getUserPrivilege('ADMIN_LIST_TRIGGER'):
            case this._authService.getUserPrivilege('ADMIN_LIST_RELAY'):
            case this._authService.getUserPrivilege('ADMIN_LIST_CREW'):
                this.actualTab = 'admin-structure';
                this.actualSubTab = this.areaTab;
                break;
            default:
                break;
        }
    }

    verifyIfIsApprover() {
        this._usersProfilesService.getAllApprovers(this.user.id).subscribe({
            next: (data) => {
                if (data.content.results?.find((x) => x.cod_user_approver == this.user.id)) {
                    this.isApprover = true;
                } else {
                    this.isApprover = false;
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
    }
    checkAreaTab() {
        if (this._authService.getUserPrivilege('ADMIN_AREA_LISTING')) {
            this.areaTab = 'admin-area';
            this.showAreaTab = true;
        } else if (this._authService.getUserPrivilege('ADMIN_STRUCTURE_LISTING')) {
            this.areaTab = 'admin-subarea';
            this.showAreaTab = true;
        } else if (this._authService.getUserPrivilege('ADMIN_LIST_MACHINE')) {
            this.areaTab = 'admin-machines';
            this.showAreaTab = true;
        } else if (this._authService.getUserPrivilege('ADMIN_LIST_TRIGGER')) {
            this.areaTab = 'admin-trigger';
            this.showAreaTab = true;
        } else if (this._authService.getUserPrivilege('ADMIN_LIST_RELAY')) {
            this.areaTab = 'admin-relay';
            this.showAreaTab = true;
        } else if (this._authService.getUserPrivilege('ADMIN_LIST_CREW')) {
            this.areaTab = 'admin-crew';
            this.showAreaTab = true;
        } else {
            this.showAreaTab = false;
        }

        if (this._authService.getUserPrivilege('GENERAL_SHIFTS_LIST')) {
            this.showShiftsTab = true;
        } else {
            this.showShiftsTab = false;
        }
    }

    changeTab(tabName, subTabName) {
        this.actualTab = tabName;
        this.actualSubTab = subTabName;
    }
}
