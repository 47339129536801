import { Component } from '@angular/core';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.css']
})
export class HelpMenuComponent {

  show: boolean;

  constructor() { 
    this.show = false;
  }

  toggle(){
    this.show = !this.show;
  }

  closeMenu(event){
    if (event.srcElement.id != 'help-menu') {
      
      this.show = false;
    }
  }

}
