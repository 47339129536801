import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sdca-matrix-chart",
  templateUrl: "./sdca-matrix-chart.component.html",
  styleUrls: ["./sdca-matrix-chart.component.css"],
})
export class SdcaMatrixChartComponent implements OnInit {
  @Input("data") data: any;
  @Input("isLoading") isLoading: boolean;

  options_sdca: any;
  screenHeight: number;
  screenWidth: number;
  echartsInstance: any;
  chartTitleTranslation: string;
  hoursTranslate: string;
  weeksTranslate: string;

  constructor(private _translateService: TranslateService) {
    this._translateService
      .get("5WHY.SDCA_MATRIX_CHART")
      .subscribe((translation) => {
        this.chartTitleTranslation = translation;
      });
  }

  ngOnInit(): void {
    this.options_sdca = {
      title: {
        text: `${this.chartTitleTranslation}`,
        x: "center",
        top: 20,
        textStyle: {
          fontSize: 15
        }
      },
      color: ["#3398DB"],
      tooltip: {
        trigger: "item",
        formatter: `{b} - {c}`,
      },
      xAxis: {
        type: "category",
        data: this.data
          .sort((a, b) => b.str_total - a.str_total)
          .map((week) => week.str_week ),
        show: false,
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            margin: 2,
            fontSize: 10,
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],

      series: [
        {
          data: this.data
            .sort((a, b) => b.str_total - a.str_total)
            .map((week) => (week.str_total ? week.str_total : 0)),
          type: "bar",
        },
      ],
      animation: true,
    };
  }

  onChartInit(instance) {
    this.echartsInstance = instance;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        series: [
          {
            data: this.data.map((week) =>
              week.str_total ? week.str_total : 0
            ),
            type: "bar",
          },
        ],
        xAxis: {
          type: "category",
          data: this.data
            .sort((a, b) => b.str_total - a.str_total)
            .map((sdca) => sdca.str_week ),
          show: false,
        },
      });
    }
  }
}
