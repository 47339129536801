import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsInputComponent } from '../forms-input/forms-input.component';

@Component({
    selector: 'number-input',
    templateUrl: './../forms-input/forms-input.component.html',
    styleUrls: ['./../forms-input/forms-input.component.css']
})

export class NumberInputComponent extends FormsInputComponent<number> {
    
    type: string = 'number';

    constructor() {
        super();
    }

    protected validationRule() : boolean {
        return this.data?.toString() != '' && typeof this.data != typeof undefined && this.data != null;
    }

}
