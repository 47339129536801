import CockpitFilter from "./cockpit-filter.model";

export type FilterFNInput = {
  filters: CockpitFilter;
};

export type FilterFNOutput = {
  valid: boolean;
  message: string;
};

export type FilterFN = ({ filters }: FilterFNInput) => FilterFNOutput;
export class CockpitCustomFilters {
  static INVALID_DATE_RANGE({ filters }: FilterFNInput): FilterFNOutput {
    const res = CockpitCustomFilters.generateRes(INVALID_DATE_RANGE);
    const { beginDate, endDate } = filters;

    if (!beginDate || !endDate) res.valid = false;
    if (beginDate > endDate) res.valid = false;

    return res;
  }

  static SHIFT_DIDNT_START_YET({ filters }: FilterFNInput): FilterFNOutput {
    const res = CockpitCustomFilters.generateRes(SHIFT_DIDNT_START_YET);
    const { beginDate, shift } = filters;
    if (!beginDate || !shift) {
      res.valid = false;
      return res;
    }

    return res;
  }

  static validateFilters = ({
    filters,
    validators,
  }: {
    filters: CockpitFilter;
    validators: Array<keyof typeof availableFilters>;
  }): FilterFNOutput[] =>
    validators
      .map((key) => CockpitCustomFilters[key]?.({ filters }))
      .filter((v) => !v.valid);

  static generateRes = (message: string): FilterFNOutput => ({
    valid: true,
    message,
  });
}

const INVALID_DATE_RANGE = "INVALID_DATE_RANGE";
const SHIFT_DIDNT_START_YET = "SHIFT_DIDNT_START_YET";

export const availableFilters = {
  INVALID_DATE_RANGE,
  SHIFT_DIDNT_START_YET,
};
