// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons { 
    padding-top: 22px;
}

.crew-description-div {
    align-items: baseline;
    display: flex;
    flex-direction: row;
}

.crew-description-div .glyphicon-remove { 
    color:#dc3545;
    padding-left: 3px;
}

.disabled {
opacity: 0.5;
}

.save-button { 
    padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/crew/crew-persist/crew-persist.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;AACA,YAAY;AACZ;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".buttons { \n    padding-top: 22px;\n}\n\n.crew-description-div {\n    align-items: baseline;\n    display: flex;\n    flex-direction: row;\n}\n\n.crew-description-div .glyphicon-remove { \n    color:#dc3545;\n    padding-left: 3px;\n}\n\n.disabled {\nopacity: 0.5;\n}\n\n.save-button { \n    padding-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
