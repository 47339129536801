import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';
import { DeparmentListResponse } from './types/departments-service-types';

@Injectable()
export class AreaService {

    constructor(private readonly _mainService: MainService) {
    }

    /**
     * Display list of all logbook areas.
     * @param param: JSON object filled with search "term" or "id"
     */
    getAreasList(param): Observable<any> {
        let path = '/areas?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege;
        if (param.cod_areas > 0) {
            path = '/area/' + param.cod_areas + '/' + param.cod_user;
        }

        return this._mainService.get(path);
    }

    // getSubAreasByArea(params): Observable<any> {
    //     const path = '/sub-area-by-area/' + params.cod_areas;
    //     return this._mainService.get(path);
    // }

    /**
     * Post area object to database
     * @param params: JSON object of area
     */
    postArea(params): Observable<any> {
        let path = '/area';
        return this._mainService.post(path, params);
    }

    /**
     * Put area object to database
     * @param params: JSON object of area
     */
    putArea(params): Observable<any> {
        let path = '/area';
        return this._mainService.put(path, params);
    }

    /**
     * Delete area object from database
     * @param param: JSON object filled with "id" of area
     */
    deleteArea(params): Observable<any> {
        let path = '/area/' + params.cod_areas + '/' + params.cod_user;
        return this._mainService.delete(path);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getDepartments({ cod_user, cod_location } : { cod_user: number, cod_location: number }): Observable<DeparmentListResponse> {
        const queryParams = cod_location ? `?cod_location=${cod_location}` : '';
        const path = `/v2/${cod_user}/departments` + queryParams;
        return this._mainService.get(path);
    }
}
