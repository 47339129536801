import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {AuthGuard} from '../guards/auth-guard';
import {ComponentsModule} from '../components/components.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
// i18n
import {TranslateModule} from '@ngx-translate/core';
//COMPONENTS
import {MeetingsAdminComponent} from './meetings-admin/meetings-admin.component';
import {MeetingsDashboardComponent} from './meetings-dashboard/meetings-dashboard.component';
import {MeetingsListComponent} from './meetings-list/meetings-list.component';
import {MeetingsShowComponent} from './meetings-show/meetings-show.component';
import {MeetingsChatComponent} from './meetings-chat/meetings-chat.component';
import {MeetingsReportsComponent} from './meetings-reports/meetings-reports.component';
import {MeetingsActionsListComponent} from './meetings-actions-list/meetings-actions-list.component';
import {MeetingsDelayedActions} from './meetings-delayed-actions/meetings-delayed-actions.component';
import {MeetingsDetailsComponent} from './meetings-details/meetings-details.component';
import {MeetingsActionsDetailsComponent} from './meetings-actions-details/meetings-actions-details.component';
import {MyNotesComponent} from './my-notes/my-notes.component';
import {MeetingsTypesComponent} from './meetings-types-list/meetings-types-list.component';
import {MeetingsIndicatorsComponent} from './meetings-indicators-list/meetings-indicators-list.component';
import {LogbooksModule} from '../logbooks/logbooks.module';
import { MeetingsContainerComponent } from './meetings-container/meetings-container.component';
import {MeetingsHomePanelComponent} from './meetings-home-panel/meetings-home-panel.component';


//SERVICES
import {MainService} from './services/main.service';
import {SocketService} from '../main/services/socket.service';
import {ActionsService} from './services/actions.service';
import {MeetingsService} from './services/meetings.service';
import {ProductsKpisService} from './services/products-kpis.service';
import {AreaService} from './services/area.service';
import {EquipmentService} from './services/equipment.service';

import {MyNotesListAllComponent} from './my-notes/my-notes-list-all/my-notes-list-all.component';
import {MeetingsTypesService} from './services/meetings-types.service';
import {MeetingsIndicatorsService} from './services/meetings-indicators.service';
import {MeetingsCreateActionComponent} from './meetings-create-action/meetings-create-action.component';
import {FilterByStatus} from './meetings-actions-list/meetings-actions-list.pipes'
import {TeamService} from './services/team.service';
import {InputCommentChatComponent} from "./meetings-chat/input-comment-chat/input-comment-chat.component";
import {InputCommentActionComponent} from "./meetings-actions-details/input-comment-action/input-comment-action.component";
import { MeetingsVisualizeTorComponent } from './meetings-visualize-tor/meetings-visualize-tor.component';

import { DebounceModule } from 'app/directives/debounce/debounce.module';

import { QuillModule } from 'ngx-quill';

import { MyMeetingsMainComponent } from './my-meetings-main/my-meetings-main.component';
import { MeetingsLocationComponent } from './meetings-location/meetings-location.component';
import { MeetingsComponent } from './meetings/meetings.component';

import { MsalGuard } from '@azure/msal-angular';

import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { MeetingsAddActionDrawerComponent } from './meetings-add-action-drawer/meetings-add-action-drawer.component';
import { MeetingsAttendanceComponent } from './meetings-attendance/meetings-attendance.component';
import { ExportActionsService } from './services/export-actions.service';


const routes: Routes = [
    {
        path: 'meetings-admin',
        component: MeetingsAdminComponent,//unused, unimplemented
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-dashboard',
        component: MeetingsDashboardComponent,//unused, unimplemented
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-show/:id',
        component: MeetingsShowComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-container/:id',
        component: MeetingsContainerComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-reports',
        component: MeetingsReportsComponent,//unused route, component used on reports page
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings',
        component: MeetingsListComponent,//most likely unused route, component used on MyMeetingsMainComponent
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-delayed-actions',
        component: MeetingsDelayedActions,//unused route, component used(?) on TeamComponent
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'notes',
        component: MyNotesListAllComponent,
    },
    {
        path: 'meetings-types',
        component: MeetingsTypesComponent,//unused route, component used on admin page
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-indicators',
        component: MeetingsIndicatorsComponent,//unused route, component used on admin page
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'meetings-visualize-tor/:id',
        component: MeetingsVisualizeTorComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'my-meetings',
        component: MyMeetingsMainComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'my-meetings/:id',
        component: MeetingsComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ],
    }
];

@NgModule({
    declarations: [
        MeetingsAdminComponent,
        MeetingsDashboardComponent,
        MeetingsListComponent,
        MeetingsActionsListComponent,
        MeetingsChatComponent,
        MeetingsShowComponent,
        MeetingsReportsComponent,
        MeetingsDelayedActions,
        MeetingsDetailsComponent,
        MyNotesComponent,
        MyNotesListAllComponent,
        MeetingsTypesComponent,
        MeetingsIndicatorsComponent,
        MeetingsActionsDetailsComponent,
        FilterByStatus,
        MeetingsContainerComponent,
        MeetingsCreateActionComponent,
        MeetingsHomePanelComponent,
        InputCommentChatComponent,
        InputCommentActionComponent,
        MeetingsVisualizeTorComponent,
        MyMeetingsMainComponent,
        MeetingsLocationComponent,
    ],
    imports: [
        DebounceModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        TranslateModule,
        ComponentsModule,
        RouterModule.forChild(routes),
        LogbooksModule,
        DragDropModule,
        QuillModule.forRoot(),
        MeetingsComponent,
        ClbDrawerModule,
        MeetingsAddActionDrawerComponent,
        MeetingsAttendanceComponent,
    ],
    exports: [
        MeetingsListComponent,
        MeetingsActionsListComponent,
        MeetingsReportsComponent,
        MeetingsDetailsComponent,
        MeetingsDelayedActions,
        MeetingsTypesComponent,
        MeetingsIndicatorsComponent,
        MeetingsActionsDetailsComponent,
        MeetingsHomePanelComponent,
        MyNotesComponent,
        FilterByStatus,
        InputCommentChatComponent,
        InputCommentActionComponent,
        MeetingsComponent
    ],
    providers: [
        MainService,
        SocketService,
        ActionsService,
        MeetingsService,
        MeetingsTypesService,
        MeetingsIndicatorsService,
        TeamService,
        AreaService,
        EquipmentService,
        ProductsKpisService,
        ExportActionsService
    ]
})
export class MeetingsModule {
}
