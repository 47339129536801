export class Relay {
    cod_user: number;
    cod_location: number;
    cod_locations_regional: number;
    cod_locations_world: number;
    cod_area: number;
    dat_ins: Object;
    dat_alt: Object;
    dat_del: Object;
    fivewhys_blocked_status: Array<{ cod_fivewhy_steps: number, is_enable: boolean }>;

    constructor() {
        this.cod_user = 0;
        this.cod_location = 0;
        this.cod_locations_regional = 0;
        this.cod_locations_world = 0;
        this.cod_area = 0;
        this.dat_ins = {};
        this.dat_alt = {};
        this.dat_del = {};
        this.fivewhys_blocked_status = [];
    }
}
