// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 10px;
}

.alert-container {
  margin: 20px 0;
  text-align: center;
  font-size: 1em;
  color: #777777;
}

.alert-container > .glyphicon {
  margin: 20px;
  font-size: 2.5em;
}

.alert-container.--error > .glyphicon {
  color: #9a4020;
}

.alert-container.--empty > .glyphicon {
  color: #20409a;
}

.FormsDynamicReports__Container {
  padding: 20px;
}

  .FormsDynamicReports__Container .buttons {
    margin-top: 20px;
  }

.glyphicon--wrapper {
  position: relative;
}

.glyphicon--wrapper > .glyphicon {
  position: absolute;
  top: 9px;
  right: 22px;
  font-size: 18px;
  color: #c7c7c7;
}

.glyphicon--wrapper > .form-control {
  padding-left: 28px;
}

.btn-pure-text.disabled {
  cursor: initial;
  opacity: 0.85;
}
`, "",{"version":3,"sources":["webpack://./src/app/forms/forms-dynamic/forms-dynamic-reports/forms-dynamic-reports.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;EAEE;IACE,gBAAgB;EAClB;;AAEF;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;AACf","sourcesContent":[".container {\n  padding: 10px;\n}\n\n.alert-container {\n  margin: 20px 0;\n  text-align: center;\n  font-size: 1em;\n  color: #777777;\n}\n\n.alert-container > .glyphicon {\n  margin: 20px;\n  font-size: 2.5em;\n}\n\n.alert-container.--error > .glyphicon {\n  color: #9a4020;\n}\n\n.alert-container.--empty > .glyphicon {\n  color: #20409a;\n}\n\n.FormsDynamicReports__Container {\n  padding: 20px;\n}\n\n  .FormsDynamicReports__Container .buttons {\n    margin-top: 20px;\n  }\n\n.glyphicon--wrapper {\n  position: relative;\n}\n\n.glyphicon--wrapper > .glyphicon {\n  position: absolute;\n  top: 9px;\n  right: 22px;\n  font-size: 18px;\n  color: #c7c7c7;\n}\n\n.glyphicon--wrapper > .form-control {\n  padding-left: 28px;\n}\n\n.btn-pure-text.disabled {\n  cursor: initial;\n  opacity: 0.85;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
