import {AfterViewInit, Component, Input} from '@angular/core';
import {UserPreference} from '../../models/user-preference.model';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {UserPreferencesComponent} from '../../user-preferences/user-preferences.component';
import {AuthService} from '../../../main/access/services/auth.service';
import {UploadService} from '../../../main/services/upload.service';


@Component({
    selector: 'app-change-picture',
    templateUrl: './change-picture.component.html',
    styleUrls: ['./change-picture.component.css']
})
export class ChangePictureComponent implements AfterViewInit {

    @Input() userPicture: any;

    fileSelected: any;
    fileNameSelected: string;
    oldUserPicture: string;
    filesToUpload: Array<File> = [];
    isLoadingImage: boolean = false;
    isLoading: boolean = false;
    componentId: string = '';
    public userPreference: UserPreference;
    error_message_upload: string;
    bol_error_upload: boolean = false;

    constructor(private _userPreferencesService: UserPreferencesService, private _userPreferencesComponent: UserPreferencesComponent,
                private _authService: AuthService, private _uploadService: UploadService) {
        this.filesToUpload = [];
        this.userPreference = new UserPreference();
        this.userPreference.cod_user = this._authService.getAuthenticatedUser().id;
        this.isLoadingImage = false;
        this.fileNameSelected = '';
        this.componentId = 'importfile_' + Date.now();
    }

    ngAfterViewInit(): void {
        this.fileNameSelected = '';
    }


    uploadFile() {
        this.isLoading = true
        let params = {
            'event': this.fileSelected,
            'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg']
        };

        this._uploadService.uploadFileBase64(params).subscribe(response => {
                if (response.type == 'success') {

                    this.userPreference.str_pref_value = response.content.str_name;
                    this.userPreference.bol_change_preference = 1;

                    this._userPreferencesService.postPicture(this.userPreference).subscribe(res => {
                            if (res.cod <= 0) {
                                alert(res.str);
                            }
                            this.fileNameSelected = '';
                            this.deleteOldImage();
                            this._userPreferencesComponent.loadUserPreferences(true);
                            this._userPreferencesComponent.closeModal('modal-change-picture');
                            this.isLoading = false;
                        },
                        err => {
                            this._authService.errCheck(err);
                            console.log(err);
                            this.isLoading = false;
                        }
                    );

                } else {
                    this.bol_error_upload = true;
                    this.isLoading = false;
                    this.error_message_upload = response.content;
                }

            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    fileChangeEvent(fileInput: any) {
        this.bol_error_upload = false;

        if (typeof fileInput.target.files[0] != typeof undefined) {
            this.oldUserPicture = this.userPicture;
            this.isLoadingImage = true;
            this.userPicture = null;

            let file = (typeof fileInput.event != typeof undefined && fileInput.event.dataTransfer) ? fileInput.event.dataTransfer.files[0] : fileInput.target.files[0];

            let $this = this;
            let reader = new FileReader();
            reader.onload = function (e) {
                //$this.userPicture = e.target.result;
                $this.userPicture = reader.result;
                $this.isLoadingImage = false;
                $this.fileNameSelected = fileInput.target.files[0].name;
            };
            //reader.readAsDataURL(fileInput.target.files[0]);
            reader.readAsDataURL(file);
        }
        this.fileSelected = fileInput;
    }

    public closeBackground() {
        this.fileNameSelected = '';
        this._userPreferencesComponent.closeModal('modal-change-picture');
    }

    deleteOldImage() {
        if (typeof this.userPicture != typeof undefined && this.userPicture !== '') {
            let objParam = {
                'file_name': this.oldUserPicture.split('\\').pop().split('/').pop() //get file name from full path
            };

            this._userPreferencesService.deletePictureFromServer(objParam).subscribe(res => {
                    //console.log(res);
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    openFileBox() {
        document.getElementById(this.componentId).click();
    }
}
