import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NpsInterceptorService implements HttpInterceptor {

    constructor(private _authService: AuthService){}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const obj_user = this._authService.getAuthenticatedUser();
        if (req.url.includes(environment().npsApiUrl)) {
            req = req.clone({
                headers: (req.headers ? req.headers : new HttpHeaders()).set(
                    'Accept-Language',
                    this._authService.getAuthenticatedUser().userlang
                ),
                params: (req.params ? req.params : new HttpParams()).set('cod_user', obj_user.id),
            });
            return next.handle(req);
        }
        return next.handle(req);
    }
}
