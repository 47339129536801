import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";

import { SocketService } from "../../main/services/socket.service";
import { MeetingsService } from "../services/meetings.service";
import { AuthService } from "../../main/access/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { MeetingsTypesService } from "../services/meetings-types.service";
import { MeetingsIndicatorsService } from "../services/meetings-indicators.service";
import { DomSanitizer } from "@angular/platform-browser";
import { UploadService } from "../../main/services/upload.service";

import { environment } from "../../../environments/environment";
import { UserPreferencesService } from "../../user-preferences/services/user-preferences.service";
import { KpisService } from "../services/kpis.service";
import * as _ from 'lodash';

@Component({
  selector: "app-meetings-details",
  templateUrl: "./meetings-details.component.html",
  styleUrls: ["./meetings-details.component.css"],
})
export class MeetingsDetailsComponent implements OnInit {
  @Output() onChangeMeetingDetails: EventEmitter<any> = new EventEmitter();
  @Input() isParticipant: boolean;
  obj_options: any;
  obj_connections = new Subscription();
  arr_users: any;
  arr_columns: any;
  cod_meeting: number;
  cod_meeting_line: any;
  obj_user: any;
  obj_ta_meeting: any;
  obj_ta_columns: any;
  str_user_tmp: any;
  str_column_tmp: any;
  obj_event_search: any;
  arr_weekdays: any;
  obj_form_details: any;
  arr_meetings_types: any;
  cod_meeting_type: number;
  str_download_link: string;
  bol_download: boolean;
  arr_meetings_indicators: any;
  bol_loading_columns: any;
  bol_loading_participants: boolean;
  bol_loading_meetings_form: boolean;
  obj_download: any;
  dat_start: any;
  dat_end: any;
  bol_can_report: boolean;
  bol_report_days: boolean;
  bol_confirm_delete: boolean;
  str_delete_type: string;
  bol_restore_warning: boolean;
  bol_no_admin_left: boolean;
  bol_no_admin_left2: boolean;
  strReportSubject: string;
  strFileNameDownload: string;
  arrHeaders: any;
  privilege: string;

  bol_change_type_confirmation_dialog: boolean = false;
  bol_del_custom_type: boolean = false;
  obj_temp_change_type: any;
  obj_type_selected: any;
  bol_update_deleted_type: boolean;
  arr_locations: any;
  arr_departments: any;

  bol_show_error: boolean;
  error_message_submit: any;
  isChangeType: boolean = false;
  obj_type_change: any;

  bol_loading_attendance: boolean = false;
  bol_err: boolean;
  error_download_message: string;
  attendanceParams: {};
  dat_start_attendance: any;
  dat_end_attendance: any;
  bol_attendance_report: boolean = false;
  bol_is_changing_indicators: boolean = false;
  privilegeSelectType
  privilegeSelectIndicators
  privilegeSelectParticipants
  
  bol_edit_detail: boolean;

  bol_check_active:boolean;

  bol_loading_kpis: boolean;
  arr_meetings_kpis: any[];
  arr_kpis: any[];

  constructor(
    private readonly _indicatorsService: MeetingsIndicatorsService, 
    private readonly _router: Router, 
    private readonly _meetingsTypesService: MeetingsTypesService, 
    private readonly _activatedRoute: ActivatedRoute, 
    private readonly _socketService: SocketService, 
    private readonly _translateService: TranslateService, 
    private readonly _meetingsService: MeetingsService, 
    private readonly _kpisService: KpisService,
    private readonly _authService: AuthService, 
    private readonly sanitizer: DomSanitizer, 
    private readonly _uploadService: UploadService,
    private readonly _userPref: UserPreferencesService
  ) {
    this.bol_edit_detail = this._authService.getUserPrivilege('MEETING_EDIT_LOCATION_MEETINGS');
    this.privilegeSelectType = this._authService.getUserPrivilege('MEETING_SELECT_TYPE')
    this.privilegeSelectIndicators = this._authService.getUserPrivilege('MEETING_SELECT_INDICATORS')
    this.privilegeSelectParticipants = this._authService.getUserPrivilege('MEETING_SELECT_PARTICIPANTS')
    this.privilege = "MEETING_SELECT_AREA";
    this.arr_columns = [];
    this.arr_users = [];
    this.cod_meeting = -1;
    this.str_user_tmp = "";
    this.str_column_tmp = "";
    this.obj_form_details = {
      str_name_meeting: "",
      str_name_location: "",
      str_name_area: "",
      cod_meeting_type: -1,
      str_meeting_line_name: "",
      str_name_periodicity: "",
      cod_week: 1,
      arr_indicators: [],
      only_participants: null
    };
    this.arr_weekdays = [];
    this.obj_user = {};
    this.obj_ta_meeting = {};
    this.obj_ta_columns = {};
    this.arr_meetings_types = [];
    this.bol_download = false;
    this.str_download_link = "";
    this.cod_meeting_type = null;
    this.arr_meetings_indicators = [];
    this.bol_loading_columns = true;
    this.bol_loading_participants = true;
    this.bol_loading_meetings_form = true;
    this.bol_loading_kpis = true;
    this.obj_download = {};
    this.bol_report_days = false;
    this.bol_confirm_delete = false;
    this.str_delete_type = "close";
    this.bol_restore_warning = false;
    this.strReportSubject = "";
    this.strFileNameDownload = "";
    this.arrHeaders = [];
    this.arr_locations = [];
    this.arr_departments = [];
    this.arr_meetings_kpis = [];
    this.arr_kpis = [];

    let today = new Date();
    let strDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    this.dat_start = strDate;
    this.dat_end = strDate;
    this.dat_start_attendance = strDate;
    this.dat_end_attendance = strDate;
    this.bol_can_report = true;

    this.bol_change_type_confirmation_dialog = false;

    //GETTING AUTHENTICATED USER
    this.obj_user = _authService.getAuthenticatedUser();

    //GETTING MEETING ID FROM PARAMETER
    this._activatedRoute.params.subscribe((params) => {
      this.cod_meeting = params["id"];
    });

    this.loadTranslations();
  }

  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }

  loadMeetingTypes() {
    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.obj_form_details.cod_location,
      cod_meeting: this.cod_meeting,
    };
    this._meetingsTypesService.getMeetingTypesByMeetingLocation(params).subscribe({
        next: (data) => {
            if (data.content.length > 1) {
                this.arr_meetings_types = data.content.results;
            } else {
                this.arr_meetings_types = [data.content.results];
            }
            this.bol_loading_meetings_form = false;
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  loadIndicator() {
    //GET ALL MEETINGS INDICATORS
    const params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.obj_form_details.cod_location,
    };
    this._indicatorsService.getIndicatorByMeetingLocation(params).subscribe({
        next: (meetingsIndicators) => {
            if (meetingsIndicators.content.length > 1) {
                this.arr_meetings_indicators = [...meetingsIndicators.content.results];
            } else {
                this.arr_meetings_indicators = [meetingsIndicators.content.results];
            }
            this.bol_loading_meetings_form = false;
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  //<<<<<<<<<<<<<<<START PARTICIPANT SECTION>>>>>>>>>>>>>>>>>

  setMeetingOwner(cod_user, bol_is_owner) {
    this.bol_no_admin_left = false;
    this.bol_no_admin_left2 = false;

    if (bol_is_owner == 0 && this.countMeetingOwner() == 1) {
      this.bol_no_admin_left = true;
    } else if (this.obj_user.bol_is_owner == 1 || this.bol_edit_detail) {
      this.bol_loading_participants = true;

      let obj_is_owner = {
        cod_user: cod_user,
        bol_is_owner: bol_is_owner,
        cod_meeting: this.cod_meeting,
        cod_usu_alt: this.obj_user.id,
      };
      //MODIFY MEETING PARTICIPANT
      this._meetingsService.postMeetingOwner(obj_is_owner).subscribe({
          next: (result: any) => {
              //REFRESH ALL MEETING PARTICIPANTS
              this.refreshParticipants();
              this._socketService.refreshMeetingParticipant({
                  cod_meeting: this.cod_meeting,
              });
          },
          error: (err) => {
              this._authService.errCheck(err);
              console.log(err);
          },
      });
    }
  }

  countMeetingOwner() {
    return this.arr_users.filter((x) => x.bol_is_owner === 1).length;
  }

  delMeetingParticipant(obj_participant) {
    if (obj_participant.cod_user != this.obj_user.id) {
      this.bol_loading_participants = true;

      obj_participant.cod_usu_del = this.obj_user.id;
      obj_participant.cod_user_auth = this.obj_user.id;
      obj_participant.cod_meeting = this.cod_meeting;
      obj_participant.str_type = "leave";

      //MODIFY ACTIONS FROM MEETING PARTICIPANT TO HAVE A OWNER & DELETE MEETING PARTICIPANT
      this._meetingsService.putMeetingLeaveDelete(obj_participant).subscribe({
          next: (result: any) => {
              //REFRESH ALL MEETING PARTICIPANTS
              this.refreshParticipants();
              this._socketService.refreshMeetingParticipant({
                  cod_meeting: this.cod_meeting,
              });
          },
          error: (err) => {
              this._authService.errCheck(err);
              console.log(err);
          },
      });
    }
  }

  searchUsers(cb, str_term) {
    this._meetingsService
        .getUsersList(str_term, environment().uploadGetFilesApiUrl + '/', this.obj_user.id, 'MEETING_SELECT_PARTICIPANTS')
        .subscribe({
            next: (result: any) => {
                cb(result.content.results);
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });

    this.alterReportParameters();
  }

  refreshParticipants() {
    //REFRESH DATA FROM ALL MEETING PARTICIPANTS
    this._meetingsService
        .getMeetingsParticipants(
            this.cod_meeting,
            environment().uploadGetFilesApiUrl + '/',
            this._authService.getAuthenticatedUser().id
        )
        .subscribe({
            next: (data) => {
                if (data.code == 888 && data.content.code == 888) {
                    this.arr_users = data.content.results;

                    for (const element of this.arr_users) {
                        if (this.obj_user.id == element.cod_user) {
                            this.obj_user.bol_is_owner = element.bol_is_owner;
                        }
                    }

                    this.onChangeMeetingDetails.emit('update_users');
                } else {
                    console.log(data);
                }

                this.bol_loading_participants = false;
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
  }

  //<<<<<<<<<<<<<<<<<<END PARTICIPANT SECTION>>>>>>>>>>>>>>>>>

  //<<<<<<<<<<<<<<<<<<START COLUMNS SECTION>>>>>>>>>>>>>>>>>>>>

  saveColumn(obj_column, z) {
    this.bol_loading_columns = true;

    obj_column.int_order = z;
    obj_column.cod_meeting = this.cod_meeting;
    obj_column.cod_user = this.obj_user.id;

    for (let i = 0; i < obj_column.arr_options.length; i++) {
      obj_column.arr_options[i].int_order = i;
    }

    this._meetingsService.postMeetingColumns(obj_column).subscribe({
        next: (result: any) => {
            // console.log(result);
            //REFRESH ALL MEETING COLUMNS
            this._socketService.refreshMeetingColumns({
                cod_meeting: this.cod_meeting,
            });
            this.refreshColumns();
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  addOption(int_question) {
    this.arr_columns[int_question].arr_options.push({
      str_name_option: "",
    });
  }

  delColumn(obj_column) {
    this.bol_loading_columns = true;

    obj_column.cod_user = this.obj_user.id;
    obj_column.cod_meeting = this.cod_meeting;
    this._meetingsService.deleteMeetingColumn(obj_column).subscribe({
        next: (result: any) => {
            //REFRESH ALL MEETING COLUMNS
            this._socketService.refreshMeetingColumns({
                cod_meeting: this.cod_meeting,
            });
            this.refreshColumns();
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  delOption(obj_option) {
    this.bol_loading_columns = true;

    obj_option.cod_user = this.obj_user.id;
    obj_option.cod_meeting = this.cod_meeting;
    this._meetingsService.deleteMeetingColumnOption(obj_option).subscribe({
        next: (result: any) => {
            //REFRESH ALL MEETING COLUMNS
            this._socketService.refreshMeetingColumns({
                cod_meeting: this.cod_meeting,
            });
            this.refreshColumns();
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  searchColumns(cb, str_term) {
    this._meetingsService.getColumnsList(str_term, this.obj_user.id).subscribe({
        next: (result: any) => {
            result.content.results.unshift({
                cod_columns: -1,
                str_name_column: str_term,
                arr_options: [],
            });
            cb(result.content.results);
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  refreshColumns() {
    //REFRESH DATA FROM ALL MEETING COLUMNS
    this._meetingsService.getMeetingColumns(this.cod_meeting, this._authService.getAuthenticatedUser().id).subscribe({
        next: (data) => {
            if (data.code == 888 && data.content.code == 888) {
                this.arr_columns = data.content.results;

                this.onChangeMeetingDetails.emit('update_columns');
            } else {
                console.log(data);
            }

            this.bol_loading_columns = false;
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
        },
    });
  }

  //<<<<<<<<<<<<<<<<<<END COLUMNS SECTION>>>>>>>>>>>>>>>>>>>>

  ngOnInit() {
    this._translateService.onLangChange.subscribe(() => {
      this.loadTranslations();
      this.loadIndicator();
    });
    this.bol_del_custom_type = false;
  }

  ngAfterViewInit() {
    this.obj_connections.add(
      this._socketService.subscribeToParticipants().subscribe((users) => {
        let obj_participant: any;
        obj_participant = users;
        if (this.cod_meeting == obj_participant.obj_participant.cod_meeting) {
          this.refreshParticipants();
        }
      })
    );
    this.refreshParticipants();

    this.obj_connections.add(
      this._socketService.subscribeToColumns().subscribe((columns) => {
        let obj_column: any;
        obj_column = columns;
        if (this.cod_meeting == obj_column.obj_column.cod_meeting) {
          this.refreshColumns();
        }
      })
    );
    this.refreshColumns();
    this.loadKpis();

    this.alterReportParameters();
    this.attendanceReportParams();
  }

  ngOnDestroy() {
    // this.obj_connections.unsubscribe();
  }

  loadLocation() {
    let params = {
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_meetings: this.cod_meeting,
    };
    this._meetingsService
      .getLocationByMeetingCreator(params)
      .subscribe((data) => {
        if (data.content.length > 1) {
          this.arr_locations = data.content.results;
        } else {
          this.arr_locations = [data.content.results];
        }
      });
  }

  loadAreas(){// departments
    let params = {
      term: '',
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.obj_form_details.cod_location
    };
    // as the meeting gets updated when you change locations, probably could use the following endpoint
    // that gets meeting location from BD instead of sending as param, which could be better
    // this._logbookAreaService.getAreaByMeetingLocation(objParam) 
    this._meetingsService
      .getAreasByMeetingLocation(params)
      .subscribe((data: any) => {
        if (data.content.length >= 0 && !Array.isArray(data.content.results)){
          this.arr_departments = [data.content.results]
        } else {
          this.arr_departments = data.content.results
        }
    });
  }


  loadTranslations() {
    //GET TRANSLATED WEEKDAYS
    this._translateService
      .get("GENERAL.WEEK_DAYS_JSON")
      .subscribe((translate) => {
        this.arr_weekdays = JSON.parse(translate);
      });

    //Get translation for meeting typeahead component and initialize it
    this._translateService
      .get("MEETING.ADD_PARTICIPANT")
      .subscribe((translate) => {
        this.obj_ta_meeting = {
          customTemplate:
            '<div><span style="cursor:pointer"><div style="width: 30px; height: 30px; overflow: hidden; border-radius: 50%; margin: 0px 8px 0 0px; border: solid 1px #ccc; float: left !important;"><img alt="obj_img" *ngIf="item.str_img_path!=\'\'" [src]="item.str_img_path" class="img-responsive" />  <img *ngIf="item.str_img_path==\'\'" src="../../../assets/images/icon_user_default.png" class="img-responsive" /></div><label style="padding-top:8px;">{{item.str_name}}</label></span></div>',
          type: "dynamic",
          placeHolder: translate,
          valueProperty: "cod_user",
          searchProperty: "str_name",
          hasImage: false,
          onSelect: (obj_selected: any) => {
            const exists = this.checkObjectExists(
              this.arr_users,
              obj_selected.cod_user
            );

            if (obj_selected && !exists) {
              this.bol_loading_participants = true;
              let obj_participant = {
                cod_user: obj_selected.cod_user,
                str_name: obj_selected.str_name,
                str_img_path: obj_selected.str_img_path,
                bol_is_owner: 0,
                cod_meeting: this.cod_meeting,
                cod_usu_ins: this.obj_user.id,
              };

              this.bol_loading_participants = true;
              //ADD MEETING PARTICIPANT
              this._meetingsService.postMeetingParticipant(obj_participant).subscribe({
                  next: (result: any) => {
                      //REFRESH ALL MEETING PARTICIPANTS
                      this.refreshParticipants();
                      this._socketService.refreshMeetingParticipant({
                          cod_meeting: this.cod_meeting,
                      });
                  },
                  error: (err) => {
                      this._authService.errCheck(err);
                      console.log(err);
                  },
              });
            }
          },
          asynchDataCall: (value: string, cb: any) => {
            this.searchUsers(cb, value);
          },
          onBlur: (item: any) => {
            this.str_user_tmp = "";
          },
        };
      });

    //Get translation for columns typeahead component and initialize it
    this._translateService.get("MEETING.ADD_COLUMN").subscribe((translate) => {
      this.obj_ta_columns = {
        customTemplate:
          '<div style="margin-top: 5px;cursor:pointer;border-radius:5px;padding:5px 10px 5px 10px;" [ngClass]="{active: item.cod_columns==-1}"><span>{{item.str_name_column}}</span></div>',
        type: "dynamic",
        placeHolder: translate,
        textPrperty: "",
        valueProperty: "cod_columns",
        searchProperty: "str_name_column",
        isMultiselect: false,
        onSelect: (obj_selected: any) => {
          if (obj_selected) {
            this.saveColumn(obj_selected, this.arr_columns.length + 1);
            this.str_column_tmp = "";
          }
        },
        asynchDataCall: (value: string, cb: any) => {
          this.searchColumns(cb, value);
        },
        onBlur: (item: any) => {
          this.str_column_tmp = "";
        },
      };
    });

    //Atualiza Tradução Meeting Type

    const self = this;
    setTimeout(function () {
      self.loadLocation();
      self.refreshMeetingDetails();
    }, 1000);
  }

  refreshMeetingDetails() {
    this._meetingsService
        .getMeetingDetails(this.cod_meeting, this._authService.getAuthenticatedUser().id, 'NOT_PRIVILEGE')
        .subscribe({
            next: (result: any) => {
                if (result.code == 888 && result.content.code == 888) {

                    ({arr_kpis: this.arr_kpis, ...this.obj_form_details} = result.content.results);
                    
                    this.loadAreas();
                    this.loadIndicator();
                    this.loadMeetingTypes();
                } else {
                    console.log(result);
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
  }

  //<<<<<<<<<<<<<<<<<<<<<<START KPIS SECTION>>>>>>>>>>>>>>>>>>>>>

  getKpiCode(id) {
    const kpi = this.arr_meetings_kpis.find(k => k.uuid == id);
    return kpi ? kpi.code : '';
  }

  getKpiName(id) {
    const kpi = this.arr_meetings_kpis.find(k => k.uuid == id);
    return kpi ? kpi.name : '';
  }

  loadKpis() {
    this._kpisService
      .getKpis({_order: 'name'})
      .subscribe({
        next: result => {
          if (result.code == 888) {
            this.arr_meetings_kpis = result.content.data;
          } else {
            console.log(result);
          }
          this.bol_loading_kpis = false;
        },
        error: err => {
          this._authService.errCheck(err);
          console.log(err);
        }
      })
  }

  selectKpis(event) {
    //this.bol_is_changing_kpis = true
    const selected = event.selectedOptions;
    const previous_selection = [...this.arr_kpis];
    this.arr_kpis = selected.map(e => { return {uuid:e.uuid} });
    
    if (!_.isEqual(previous_selection, this.arr_kpis)){
      this.saveMeetingKPIs();
    }
  }
  
  //<<<<<<<<<<<<<<<<<<<<<<START FORM SECTION>>>>>>>>>>>>>>>>>>>>>
  saveMeetingKPIs(){
    const arr_selected_kpis = this.arr_kpis.map(kpi => kpi.uuid);
    if (arr_selected_kpis.length > 0) {
      const params = {
        cod_user: this.obj_user.id,
        cod_meeting: this.cod_meeting,
        arr_kpis: arr_selected_kpis
      };
      this._kpisService.postKpis(params).subscribe({
        next: result => {
          this.onChangeMeetingDetails.emit('update_title'); // used to remove the 'Missing KPIs' warning
        },
        error: err => {
          console.log(err);
        }
      })
    }
  }

  saveMeetingsForm() {
    this.bol_show_error = false;
    this.error_message_submit = "";

    if (this.obj_form_details.cod_meeting_type != -1) {
      this.bol_loading_meetings_form = true;

      this.obj_form_details.cod_user = this.obj_user.id;
      this._meetingsService.postMeetingDetails(this.obj_form_details).subscribe({
          next: (result: any) => {
              if (result.code == 666) {
                  if (result.content.details?.[0]?.path[0]) {
                      this.errorMessageErr(result.content.details[0].path[0]);
                      this.bol_show_error = true;
                      this.error_message_submit =
                          this.errorMessageErr(result.content.details[0].path[0]) +
                          ' ' +
                          this.errorMessageErr('is_required');
                      this.bol_loading_meetings_form = false;
                  }
              } else {
                  if (this.bol_update_deleted_type) {
                      let params = {
                          cod_meetings: this.obj_form_details.cod_meetings,
                          cod_user: this._authService.getAuthenticatedUser().id,
                      };
                      this._meetingsService.updateDeletedMeetingType(params).subscribe({
                          next: (results) => {
                              if (results.type == 'success') {
                                  window.location.reload();
                              }
                          },
                          error: (err) => {
                              this._authService.errCheck(err);
                              console.log(err);
                          },
                      });
                  }

                  this.refreshMeetingDetails();

                  if (this.bol_del_custom_type) {
                      let agendaParam = {
                          cod_user: this._authService.getAuthenticatedUser().id,
                          cod_meetings: this.obj_form_details.cod_meetings,
                      };
                      this._meetingsTypesService.deleteCustomAgenda(agendaParam).subscribe({
                          next: (result) => {
                              if (result.code == 888 && result.content.code == 888) {
                                  let deleteParams = {
                                      cod_user: this._authService.getAuthenticatedUser().id,
                                      cod_meeting: this.cod_meeting,
                                  };
                                  this._meetingsTypesService.deleteCustomMeetingType(deleteParams).subscribe({
                                      next: (result) => {
                                          this.bol_del_custom_type = false;
                                      },
                                      error: (err) => {
                                          this._authService.errCheck(err);
                                          console.log(err);
                                      },
                                  });
                              }
                          },
                          error: (err) => {
                              this._authService.errCheck(err);
                              console.log(err);
                          },
                      });
                  }
                  this.onChangeMeetingDetails.emit('update_location');
                  this.onChangeMeetingDetails.emit('update_title');
                  if (this.bol_is_changing_indicators) {
                      this.onChangeMeetingDetails.emit('update_indicators');
                      this.bol_is_changing_indicators = false;
                  }
                  this.bol_loading_meetings_form = false;
              }
          },
          error: (err) => {
              this._authService.errCheck(err);
              console.log(err);
          },
      });
    }
  }

  selectedWeekChanged(event) {
    const selected = event.selectedOptions;
    if (selected.length > 0) {
      this.obj_form_details.cod_week = selected[0].cod_week;
    }
    this.saveMeetingsForm();
  }

  selectedLocationChanged(event) {
    const selected = event.selectedOptions;
    if (selected.length > 0) {
      this.obj_form_details.cod_location = selected[0].cod_locations;
    }
    this.saveMeetingsForm();
  }

  selectedDepartmentChanged(event) {
    const selected = event.selectedOptions;
    if (selected.length > 0) {
      this.obj_form_details.cod_area = selected[0].cod_areas;
    }
    this.saveMeetingsForm();
  }

  selectedMeetingTypeChanged(event) {
    this.isChangeType = true;
    if (this.obj_form_details.bol_meeting_type_del) {
      this.changeMeetingType(event);
      this.bol_update_deleted_type = true;
    } else if (
      this.obj_form_details.cod_meeting_type !=
      event.selectedOptions[0].cod_meetings_types
    ) {
      this.isChangeType = true;
      this.obj_temp_change_type = event;
      this.obj_type_change = event.selectedOptions[0].cod_meetings_types;
      this.obj_type_selected = [{ cod_meetings_types: this.obj_type_change }];
      this.bol_change_type_confirmation_dialog = true;
    }
  }

  cancelChangeType() {
    this.isChangeType = true;
    this.obj_type_selected = [
      { cod_meetings_types: this.obj_form_details.cod_meeting_type },
    ];
    this.bol_change_type_confirmation_dialog = false;
    this.bol_del_custom_type = false;
  }

  changeMeetingType(event) {
    let selected = event.selectedOptions;
    if (selected.length > 0) {
      this.cod_meeting_type = selected[0].cod_meetings_types;
      for (const element of this.arr_meetings_types) {
        if (
          this.cod_meeting_type == element.cod_meetings_types
        ) {
          this.str_download_link = element.str_pdf_path;
        }
      }
      this.bol_download = true;

      this.obj_form_details.cod_meeting_type = this.cod_meeting_type;
    }

    this.bol_del_custom_type = true;

    this.saveMeetingsForm();
    this.bol_change_type_confirmation_dialog = false;
  }

  selectIndicators(event) {
    if (event.selectedOptions.length > 0) {
      this.bol_is_changing_indicators = true
      this.obj_form_details.arr_indicators = event.selectedOptions;
    }
    this.saveMeetingsForm();
  }

  selectedLine(event, i) {
    const selected = event.selectedOptions;
    if (selected.length > 0) {
      this.cod_meeting_line = selected[0].cod_line;
      this.obj_form_details.cod_meeting_line = this.cod_meeting_line;
    }
  }

  alterReportParameters() {
    let $this = this;

    //translate header
    this._translateReport(function () {
      if ($this.dat_end != null && $this.dat_start != null) {
        let dat_diff =
          (new Date($this.dat_end).getTime() -
            new Date($this.dat_start).getTime()) /
          (1000 * 3600 * 24);

        if (Math.round(dat_diff) < 190 && Math.round(dat_diff) >= 0) {
          $this.bol_report_days = false;
          $this.bol_can_report = true;

          let obj_params = {
            pr_cods_locations: "null",
            pr_cod_meeting: "" + $this.cod_meeting,
            pr_cod_meeting_types: "null",
            pr_str_meeting_name: "null",
            pr_str_hashtags: "null",
            pr_cod_indicators: "null",
            pr_cod_owner_ins_action: "null",
            pr_cod_owner_exc_action: "null",
            pr_ind_im_owner: "null",
            pr_ind_im_participate: "null",
            pr_cod_user: "" + $this.obj_user.id,
            pr_type_period: "1",
            pr_initial_date: "" + $this.dat_start.toString(),
            pr_final_date: "" + $this.dat_end.toString(),
          };

          $this.obj_download = {
            cod_user: $this.obj_user.id,
            api_url: environment().reportsApiUrl,
            str_proc_exec: "reports_sp_ex_meetings_jr3",
            arr_proc_params_search: [obj_params],
            arr_headers: $this.arrHeaders,
            name_of_file_to_download: $this.convertChar(
              $this.obj_form_details.str_name_meeting
            ),
            str_column_dynamic: "str_columns",
          };
        } else {
          $this.bol_report_days = true;
          $this.bol_can_report = false;
        }
      } else {
        $this.bol_can_report = false;
      }
    });
  }

  toggleDelete(type) {
    this.bol_confirm_delete = !this.bol_confirm_delete;
    this.str_delete_type = type;
  }

  confirmDelete(type) {
    this.bol_confirm_delete = !this.bol_confirm_delete;
    this.bol_restore_warning = false;
    this.bol_no_admin_left2 = false;
    this.bol_no_admin_left = false;

    let params = {
      cod_meeting: this.cod_meeting,
      cod_user: this.obj_user.id,
      str_type: type,
      cod_user_auth: this.obj_user.id,
    };

    if (type == "leave") {
      let bol_is_owner = this.arr_users.filter(
        (x) => x.bol_is_owner === 1 && x.cod_user == this.obj_user.id
      ).length;

      if (bol_is_owner == 1 && this.countMeetingOwner() == 1) {
        this.bol_no_admin_left2 = true;
      } else {

        this._meetingsService.putMeetingLeaveDelete(params).subscribe({
            next: (result: any) => {
                if (result.code == 888 && result.content.code == 888) {
                    //REFRESH ALL MEETING PARTICIPANTS AND NOTIFICATIONS
                    this._socketService.refreshMeetingParticipant({ cod_meeting: this.cod_meeting });
                    this._socketService.refreshUserNotifications({
                        arr_participants: this.arr_users.map((user) => user.cod_user),
                    });
                    this._router.navigate(['/my-meetings']);
                }
            },
            error: (err) => {
                this._authService.errCheck(err);
                console.log(err);
            },
        });
      }
    } else {
      this._meetingsService.putMeetingLeaveDelete(params).subscribe({
          next: (result: any) => {
              if (result.code == 888 && result.content.code == 888) {
                  if (type == 'restore') {
                      if (result.content.results.meetings_sp_up_meeting_restore == 0) {
                          this.bol_restore_warning = true;
                      } else {
                          this._router.navigate(['/my-meetings']);
                      }
                  } else {
                      this._router.navigate(['/my-meetings']);
                  }
              } else {
                  this._router.navigate(['/my-meetings']);
              }
          },
          error: (err) => {
              this._authService.errCheck(err);
              console.log(err);
          },
      });
    }
  }

  checkObjectExists(arr, cod_user) {
    const found = arr.some(function (el) {
      return el.cod_user === cod_user;
    });
    return found;
  }

  convertChar(str: string) {
    if (str != undefined) {
      const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẝźḧ·/_,:;"; //
      const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______";
      const p = new RegExp(a.split("").join("|"), "g");
      return str
        .toString()
        .toLowerCase()
        .trim()
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/&/g, "_and_") // Replace & with 'and'
        .replace(/[\s\W-]+/g, "_"); // Replace spaces, non-word characters and dashes with
    }
    return str;
  }

  /**
   * This function translate all columns names, sheet and mail subject according language
   * @param fnCallback callback to execute report
   */
  private _translateReport(fnCallback) {
    this.strReportSubject = "";
    this.strFileNameDownload = "";
    this.arrHeaders = [];

    forkJoin([ // easier to see what is going on without the giant chain of subscriptions
      this._translateService.get("MEETINGS_REPORTS.MAIL_SUBJECT"),
      this._translateService.get("MEETINGS_REPORTS.MAIL_FILE_NAME"),
      this._translateService.get("MEETINGS_REPORTS.STR_WORLD"),
      this._translateService.get("MEETINGS_REPORTS.STR_REGIONAL"),
      this._translateService.get("MEETINGS_REPORTS.STR_LOCATION"),
      this._translateService.get("MEETINGS_REPORTS.STR_AREA_MEETING"),
      this._translateService.get("MEETINGS_REPORTS.STR_AREA"),
      this._translateService.get("MEETINGS_REPORTS.STR_MEETING_TYPE"),
      this._translateService.get('MEETINGS_REPORTS.LAST_TIME_TOR_REFRESHED'),
      this._translateService.get("MEETINGS_REPORTS.STR_MEETING"),
      this._translateService.get("MEETINGS_REPORTS.STR_PERIODICITY"),
      this._translateService.get("MEETINGS_REPORTS.WEEKDAY"),
      this._translateService.get("MEETINGS_REPORTS.ACTION_CREATOR_ID"),
      this._translateService.get("MEETINGS_REPORTS.ACTION_CREATOR"),
      this._translateService.get("MEETINGS_REPORTS.DATE_CREATOR"),
      this._translateService.get("MEETINGS_REPORTS.SOURCE"),
      this._translateService.get("MEETINGS_REPORTS.INDICATOR_ICIV"),
      this._translateService.get("MEETINGS_REPORTS.FUNDAMENTAL_CAUSE"),
      this._translateService.get("MEETINGS_REPORTS.HASHTAG"),
      this._translateService.get("MEETINGS_REPORTS.STR_ACTION"),
      this._translateService.get("MEETINGS_REPORTS.RESPONSIBLE_ACTION"),
      this._translateService.get("MEETINGS_REPORTS.ID_RESPONSIBLE_ACTION"),
      this._translateService.get("MEETINGS_REPORTS.DATE_LIMIT"),
      this._translateService.get("MEETINGS_REPORTS.STR_STATUS"),
      this._translateService.get("MEETINGS_REPORTS.COMMENTS"),
      this._translateService.get("MEETINGS_REPORTS.DATE_UPDATE"),
      this._translateService.get("MEETINGS_REPORTS.DATE_COMPLETED"),
      this._translateService.get("MEETINGS_REPORTS.HOUR_COMPLETED"),
    ]).subscribe(results => {
      
      [this.strReportSubject, this.strFileNameDownload, ...this.arrHeaders] = results;

      if (fnCallback) {
        fnCallback();
      }

    });
  }

  errorMessageErr(err) {
    let message;
    let translation;
    switch (err) {
      case "is_required":
        message = "USERS.VALIDATE_FIELDS.IS_REQUIRED";
        break;
      case "str_name_meeting":
        message = "MEETINGS_LIST.MEETING_NAME";
        break;
      default:
        return err;
    }
    this._translateService.get(message).subscribe((translate) => {
      translation = translate;
    });
    return translation;
  }

  attendanceReportParams() {
    let arr_headers = [];
    this.bol_attendance_report = false;

    this._translateService
      .get("MEETINGS_REPORTS.COD_USER")
      .subscribe((translate) => {
        arr_headers.push(translate);
        this._translateService
          .get("MEETINGS_REPORTS.USER_NAME")
          .subscribe((translate) => {
            arr_headers.push(translate);
            this._translateService
            .get("MEETINGS_REPORTS.USER_MAIL")
            .subscribe((translate) => {
              arr_headers.push(translate);
              if (
                this.dat_start_attendance != null &&
                this.dat_end_attendance != null
              ) {
                let dat_diff =
                  (new Date(this.dat_end_attendance).getTime() -
                    new Date(this.dat_start_attendance).getTime()) /
                  (1000 * 3600 * 24);

                if (Math.round(dat_diff) < 180 && Math.round(dat_diff) >= 0) {
                  this.attendanceParams = {
                    cod_user: this._authService.getAuthenticatedUser().id,
                    arr_proc_params_search: [
                      this._authService.getAuthenticatedUser().id,
                      this.cod_meeting,
                      this.dat_start_attendance.toString(),
                      this.dat_end_attendance.toString(),
                    ],
                    arr_headers: arr_headers,
                    name_of_file_to_download:
                      this.convertChar(this.obj_form_details.str_name_meeting) +
                      "_attendance",
                  };
                } else {
                  this.bol_attendance_report = true;
                }
              } else {
                this.bol_attendance_report = true;
              }
            });
          });
      });
  }

  downloadAttendanceReport() {
    this.bol_loading_attendance = true;

    this._meetingsService.postDownloadAttendanceReport(this.attendanceParams).subscribe({
        next: (res) => {
            try {
                let str_file_name = res.content.str_file_name;
                let uploadFile = (
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                      environment().uploadGetFilesApiUrl +
                            '/reports/' +
                            this.obj_user.id +
                            '/' +
                            res.content.str_file_name
                    ) as any
                ).changingThisBreaksApplicationSecurity;

                //SEND REQUEST TO GET FILE STREAM, WITH CREDENTIALS
                this._uploadService.downloadFile(uploadFile).subscribe({
                    next: (res) => {
                        //DOWNLOAD BLOB FILE
                        let obj_blob = new Blob([res]);

                        let a = document.createElement('a');
                        a.href = URL.createObjectURL(obj_blob);
                        a.download = str_file_name;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        this.bol_loading_attendance = false;
                    },
                    error: (err) => {
                        this._authService.errCheck(err);
                        console.log(err);
                        this.bol_loading_attendance = false;
                    },
                });
            } catch (err) {
                console.log(err);
                this.bol_loading_attendance = false;
                this.bol_err = true;
                this.error_download_message = 'Error downloading file';
            }
        },
        error: (err) => {
            this._authService.errCheck(err);
            console.log(err);
            this.bol_loading_attendance = false;
            this.bol_err = true;
            this.error_download_message = 'Error creating file';
        },
    });
  }

  selectStatusActive(event){
    this.obj_form_details.only_participants = event == 'selected';
    this.saveMeetingsForm();
  }

}
