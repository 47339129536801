import {Injectable} from '@angular/core';

@Injectable()
export class ValidationsService {

    public boolSubmit: boolean;
    public boolIsLoading: boolean;
    public boolSubmitChild: boolean;
    public boolIsLoadingChild: boolean;

    constructor() {
        this.boolSubmit = false;
        this.boolSubmitChild = false;
    }

    public requiredInput(field) {
        if ((!field || field == 0) && this.boolSubmit == true) {
            return 'has-error';
        }
    }

    public requiredInputChild(field) {
        if ((!field || field == 0) && this.boolSubmitChild == true) {
            return 'has-error';
        }
    }

    public requiredInputDate(field) {
        if ((!field || field == 0 || typeof field == typeof undefined || field == '') && this.boolSubmit == true) {
            return 'has-error';
        }
    }

    public requiredInputDateChild(field) {
        if ((!field || field == 0 || typeof field == typeof undefined || field == '') && this.boolSubmitChild == true) {
            return 'has-error';
        }
    }

    public notRequiredInput() {
        if (this.boolSubmit) {
            return 'loading-icon';
        }
        return '';
    }

    public notRequiredInputChild() {
        if (this.boolSubmitChild) {
            return 'loading-icon';
        }
        return '';
    }

    public isLoading(returnClass: boolean = false) {
        if (returnClass) {
            if (this.boolIsLoading) {
                return 'loading-icon';
            }
            return '';
        }
        else {
            return this.boolIsLoading;
        }
    }

    public isLoadingChild(returnClass: boolean = false) {
        if (returnClass) {
            if (this.boolIsLoadingChild) {
                return 'loading-icon';
            }
            return '';
        }
        else {
            return this.boolIsLoadingChild;
        }
    }
}
