import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';

import {MultiSelectChanges} from './multiselect-changes.model';

import {TranslateModule, TranslateService} from '@ngx-translate/core';

import * as _ from 'lodash';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    host: {
        '(document:click)': 'onClickOutside($event)',
    },
    selector: 'component-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.css'],
    standalone: true,
    imports: [CommonModule, TranslateModule, FormsModule],
})
export class MultiSelectComponent implements OnChanges {
    @ViewChild('container', { static: true })
    container: ElementRef;

    @ViewChild('inputSearch', { static: true })
    inputSearch: ElementRef;

    // additional keys of object to compare on input search
    @Input() extraKeysToSearch: any[] = [];
    
    // placeholder text of the outer input display
    @Input() placeholder: string;

    // tells whether data is loading
    @Input() isLoading: boolean = false;

    // tells whether data has failed to load
    @Input() hasError: boolean = false;

    // tells it should allow selecting multiple options
    @Input() multiple: boolean = true;

    // tells it should allow selecting multiple options
    @Input() hasImage: boolean = false;

    // options must be an array of any type
    @Input() options: any[] = [];

    // shows some options as selected by default
    @Input() selected: any[] = [];

    // tells which property to use to get option's ID
    @Input() keyProperty: string = 'cod_item';

    // tells which property to use to get option's display value
    @Input() displayValueProperty: string = 'str_name';

    // tells which property to use to get option's display value
    @Input() hasPrefixProperty: boolean = false;

    // tells which property to use to get option's prefix display value
    @Input() displayPrefixProperty: string = 'str_desc';
    
    // prefix display value separator
    @Input() prefixSeparator: string = ' - ';

    // tells which property to use to get option's display value
    @Input() displayImageValueProperty: string = 'str_img_path';

    // notify about changes in selected options
    @Output() selectionChanged = new EventEmitter<MultiSelectChanges>();

    // notify that this instance of multiselect was blurred
    @Output() blur = new EventEmitter<any>();

    // tells component is disabled
    @Input() isDisabled: boolean = false;

    // tells component to to initialize dirt
    @Input() isDirty: boolean = false;

    // tells component is disabled
    @Input() bShowPlaceholder: boolean = false;

    @Input() theme: string = 'default';

    public constructor(private readonly _translateService: TranslateService) {
        this.options = [];
        this._translateService.onLangChange.subscribe(() => this.updateSelectedPlaceholder());
    }

    isOpen = false;
    selectedOptions = {};
    filterredOptions = [];
    search = '';
    selectedPlaceholder = null;
    areAllSelected = false;


    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.options) {
            this.filterOptions(true);
            this.updateSelectedOptions(this.selected);
        }
        if (!!changes.selected) {
            this.updateSelectedOptions(changes.selected.currentValue);
        }
    }

    onClickOutside(event) {
        // ignore icon clicks
        if (event.target.tagName === 'I') {
            return;
        }

        // check whether the click happened outside of this instance of multiselect
        if (!this.container.nativeElement.contains(event.target)) {
            // toggle component's state if it's open
            if (this.isOpen) {
                this.toggleOpen();
            }
        }
    }

    toggleOpen() {

        setTimeout(() => {
            this.inputSearch.nativeElement.focus();
        },200);

        // won't allow opening if data is loading or has failed to load
        if (this.isLoading || this.hasError) {
            this.isOpen = false;
        } else {
            this.isOpen = !this.isOpen;
        }

        // emit changes on closing if dirty
        if (!this.isOpen && this.isDirty) {
            this.emitChanges();
            this.isDirty = false;
        }

        // emit blur event on closing
        if (!this.isOpen) {
            if (this.blur) {
                this.blur.emit();
            }
        }
    }

    toggleOption(i) {
        if (!this.keyProperty) {
            console.warn('Property `keyProperty` for `component-multiselect` is invalid, please check its value!', {keyProperty: this.keyProperty});
            return;
        }

        let optionId = i[this.keyProperty];
        //if (!optionId) {
        if (typeof optionId === typeof undefined) { //adjustment to recognize values with id = 0
            let keys = Object.keys(i).map(i => `"${i}"`).join(', ');
            console.warn(`Parameter \`keyProperty="${this.keyProperty}"\` supplied for \`component-multiselect\` is not a valid property for provided \`options\`! Valid keys are: ${keys}. #2`);
            return;
        }

        if (!this.selectedOptions[optionId]) {
            if (this.multiple) {
                this.selectedOptions[optionId] = true;
            } else {
                // deselect all previously selected options if it isn't a multiple select
                this.selectedOptions = {
                    [optionId]: true
                };
                if (this.isOpen) {
                    setTimeout(() => {
                        this.toggleOpen();
                    }, 200);
                }
            }
        } else {
            this.selectedOptions[optionId] = false;
            delete this.selectedOptions[optionId];
        }
        this.isDirty = true;
        this.updateAllSelected();
        this.updateSelectedPlaceholder();
    }

    toggleAll() {
        this.filterOptions(false);
        const wereAllSelected = this.areAllSelected;
        for (let i of this.filterredOptions) {
            const key = i[this.keyProperty];
            if (wereAllSelected) {
                this.selectedOptions[key] = false;
                delete this.selectedOptions[key];
            } else {
                this.selectedOptions[key] = true;
            }
        }
        this.isDirty = true;
        this.updateAllSelected();
        this.updateSelectedPlaceholder();
    }

    filterOptions(bLimit: boolean) {
        if (!this.keyProperty) {
            console.warn('Property `keyProperty` for `component-multiselect` is invalid, please check its value!', {keyProperty: this.keyProperty});
        }

        if (!this.displayValueProperty) {
            console.warn('Property `displayValueProperty` for `component-multiselect` is invalid, please check its value!', {displayValueProperty: this.displayValueProperty});
        }

        if (!this.options || !this.keyProperty || !this.displayValueProperty) {
            return;
        }

        const search = this.search.toLowerCase();
        let count = 0;
        try {
            this.filterredOptions = this.options.filter(i => {
                if (i[this.displayValueProperty] || ( this.extraKeysToSearch && this.extraKeysToSearch.length>0 && this.extraKeysToSearch.filter(x=>i[x]).length >0)) {
                    const value = i[this.displayValueProperty].toLowerCase();
                    if(value.indexOf(search)!== -1){
                        if (bLimit) {
                            return (value.indexOf(search) !== -1 && count++ < 5000);
                        } else {
                            return (value.indexOf(search) !== -1);
                        }
                    }else{
                        if (bLimit) {
                            return (this.extraKeysToSearch.filter(x=>i[x] && i[x].toString().toLowerCase().indexOf(search)!== -1).length>0 && count++ < 5000);
                        } else {
                            return (this.extraKeysToSearch.filter(x=>i[x] && i[x].toString().toLowerCase().indexOf(search)!== -1).length>0);
                        }
                    }
                    
                } else {
                    let optionDp = i[this.displayValueProperty];
                    //if (!optionId) {
                    if (typeof optionDp === typeof undefined) { //adjustment to recognize values with id = 0
                            
                        const keys = Object.keys(i).map(i => `"${i}"`).join(', ');
                        console.warn(`Parameter \`displayValueProperty="${this.displayValueProperty}"\` supplied for \`component-multiselect\` is not a valid property for provided \`options\`! Valid keys are: ${keys}. #1`);
                        return false;
                    }

                }
            });
            this.updateAllSelected();
        }
        catch (e) {
            console.log(e);
        }
    }

    updateSelectedOptions(selected: any[]) {
        if (typeof selected != typeof undefined && selected != null) {
            this.selectedOptions = selected
                .filter(el => _.some(this.options, someEl => {
                    return someEl[this.keyProperty]==el[this.keyProperty];
                }))
                .map(i => i[this.keyProperty])
                .reduce((obj, item) => {
                    obj[item] = true;
                    return obj;
                }, {});
            }
            this.updateAllSelected();
            this.updateSelectedPlaceholder();
    }

    updateAllSelected() {
        const selectedCount = this.filterredOptions
            .filter(i => !!this.selectedOptions[i[this.keyProperty]])
            .length;
        this.areAllSelected = (selectedCount === this.filterredOptions.length);
    }

    updateSelectedPlaceholder() {

        let strSelected = '';
        this._translateService.get('USERS.SELECTED').subscribe(translate => {
            strSelected = translate;
        });

        let strOf = '';
        this._translateService.get('USERS.OF').subscribe(translate => {
            strOf = translate;
        });

        let strAll = '';
        this._translateService.get('USERS.ALL').subscribe(translate => {
            strAll = translate;
        });

        const selected = this.getSelectedOptions();
        if (selected.length === 0) {
            this.selectedPlaceholder = null;
        } else if (selected.length <= 3) {
            this.selectedPlaceholder = selected
                .map(i => i[this.displayValueProperty])
                .join(', ');
        } else if (selected.length === this.options.length) {
            this.selectedPlaceholder = `${strAll} ${strSelected}`;
        } else {
            this.selectedPlaceholder = `${selected.length} ${strOf} ${this.options.length} ${strSelected}`;
        }
    }

    getSelectedOptions() {
        try {
            const ids = Object.keys(this.selectedOptions).filter(i => !!this.selectedOptions[i]);
            const options = this.options.filter(i => ids.indexOf(i[this.keyProperty]) !== -1 || ids.indexOf(i[this.keyProperty].toString()) !== -1);
            return options;
        }
        catch (e) {
            console.log(e);
            return [];
        }
    }

    emitChanges() {
        const selectedOptions = this.getSelectedOptions();
        this.selectionChanged.emit({selectedOptions});
        this.search = '';
        this.filterOptions(true);
    }
}