import { Component, OnInit } from "@angular/core";
import { MachineService } from "../services/machine.service";
import { AuthService } from "../../main/access/services/auth.service";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { SelectPagination } from "../../logbooks/models/select_pagination.model";
import { NotificationService } from "../../main/services/notification.service";
import { Logbook } from "app/logbooks/models/logbook.model";

@Component({
  selector: "app-machine-list",
  templateUrl: "./machine-list.component.html",
  styleUrls: ["./machine-list.component.css"],
})
export class MachineListComponent implements OnInit {
  pageIndex: any;
  machineList: any = [];
  selectedRecords: Object[];
  privilegeMachine: any;
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  totalPages: any[];
  resultsPerPage: any[];
  resultsPerPageSelectOption: any[];
  resultsPerPageSelected: any;
  isLoading: any = false;
  searchTerm: string;
  searchTimer: any;
  showError: boolean;
  errorMessage: string;
  bol_change_copy_name: boolean = false;
  successCopyMessage: string;
  maxPage: number;
  editSuccessMessage: boolean;
  codMachine: any;
  createRecord: boolean = false;
  deleteMachineId: number = 0;
  awaitConfirmDelete: boolean;
  emitEditSuccessMessage: boolean;
  cod_user: number;
  isLoadingFile: boolean;
  fieldsExcelSheet: string = "code,cod_machine,cod_location,str_location_name,cod_area,str_area_name,cod_subarea,str_subarea_name,cod_equipment,str_equipment,cod_acadia_id,str_name,cod_sap_functional_location,str_name_de,str_name_es,str_name_fr,str_name_nl,str_name_pt,str_name_ru,str_name_ua";
  procedureImportingMachines: string = "machines_sp_imp_machines";
  procedureImportingMachinesSapAndAcadiaId: string = "logbooks_sp_imp_machines_sap_acadiaid";
  bol_check_active:boolean = true;
  bol_check_deleted:boolean = false;
  downloadCsvExample: any = {};
  str_title_button: string;

  downloadCsvSetup: any = {};
  _reportsApiUrl = environment().reportsApiUrl;

  sapAndAcadiaIdPrivilege: boolean;
  
  constructor(
    private _machineService: MachineService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _notify: NotificationService
  ) {
    this.searchTerm = "";
    this.privilegeMachine = this._authService.getUserPrivilege(
      "ADMIN_LIST_MACHINE"
    );
    this.searchTimer = null;
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str10perpage = "10 " + translate;
      });
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str50perpage = "50 " + translate;
      });
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str100perpage = "100 " + translate;
      });

    this._translateService.get('MACHINES.LABEL_IMPORT_MACHINES').subscribe(translate => {
        this.str_title_button = translate;
    });

    this.pageIndex = 1;
    this.totalPages = [];
    this.resultsPerPage = [
      new SelectPagination(10, this.str10perpage),
      new SelectPagination(50, this.str50perpage),
      new SelectPagination(100, this.str100perpage),
    ];
    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
    this.resultsPerPageSelected = this.resultsPerPage[0].qty;
    this.selectedRecords = [];
    this.cod_user = this._authService.getAuthenticatedUser().id;
  }

  ngOnInit() {
    this.search(true);
    this.bol_check_active=true;
    this.privilegeMachine = this.checkPrivilege("ADMIN_CHANGE_MACHINE");
    this.sapAndAcadiaIdPrivilege = this.checkPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
  }

  ngAfterViewInit(){
    this.search(true);
    this.bol_check_active=true;
  }

  editMachine(id, reload) {
    if (id == -1) {
      id = 0;
      this.editSuccessMessage = true;
    } else {
      this.editSuccessMessage = false;
    }
    this.codMachine = id;
    this.createRecord = !reload;
    if (reload) {
      this.search(true);
    }
  }

  copyMachine() {
    this.bol_change_copy_name = true;
  }

  saveCopy() {
    this.bol_change_copy_name = false;
  }

  search(reset: boolean) {
    if (
      this.searchTerm.length < environment().minCharStartSearch &&
      this.searchTerm.length != 0
    ) {
      return;
    }
    this.isLoading = true;
    clearTimeout(this.searchTimer);
    let $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.searchMachine();
    }, environment().debounceTime);
  }

  _getStatus(): string {
    if (this.bol_check_active && this.bol_check_deleted) {
      return 'ALL';
    } else {
      if (this.bol_check_active) {
        return 'ACTIVE';
      }
      if (this.bol_check_deleted) {
        return 'DELETED';
      }
    }
    return 'ACTIVE';
  }

  searchMachine() {
    let str_status='ACTIVE';
    if(this.bol_check_active && this.bol_check_deleted){
      str_status='ALL';
    }else{
      if(this.bol_check_active){
        str_status='ACTIVE';
      }
      if(this.bol_check_deleted){
        str_status='DELETED';
      }
    }

    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    this.isLoading = true;
    let objParam = {
      'term': this.searchTerm,
      'limit': this.resultsPerPageSelected,
      'offset': (tmpPage * this.resultsPerPageSelected),
      'cod_user': this.cod_user,
      'dsc_page_privilege': 'ADMIN_LIST_MACHINE',
      'str_status': str_status
    };

    this._machineService.listMachineAdmin(objParam).subscribe(
      (data) => {
        this.machineList = data.content.results;        
        if (data.content.length > 0) {
          this.maxPage = Math.ceil(
            data.content.results[0].full_count / this.resultsPerPageSelected
          );
          this.totalPages = this.pagination(this.pageIndex, this.maxPage);
        }
        this._buidCsvSetup();

        this.isLoading = false;
      },
      (err) => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    );
  }

  private _implodeValues(objValues) {
      let ids = '';
      if (objValues) {
          objValues.forEach(element => {
              if (ids != '') {
                  ids += ',';
              }
              ids += element;
          });
      }
      return ids;
  }

  async _buidCsvSetup() {
    const arr_headers = [
      await this._translateText('GENERAL.RECORD_ID'),
      await this._translateText('MACHINES.EXPORT.LOCATION_COD'),
      await this._translateText('MACHINES.EXPORT.LOCATION'),
      await this._translateText('MACHINES.EXPORT.DEPARTMENT_COD'),
      await this._translateText('MACHINES.EXPORT.DEPARTMENT'),
      await this._translateText('MACHINES.EXPORT.AREA_COD'),
      await this._translateText('MACHINES.EXPORT.AREA'),
      await this._translateText('MACHINES.EXPORT.EQUIPAMENT'),
      await this._translateText('MACHINES.EXPORT.EQUIPAMENT_NAME'),
      await this._translateText('LOGBOOK.ACADIA_ID'),
      await this._translateText('MACHINES.EXPORT.MACHINE_NAME'),
      await this._translateText('LOGBOOK.SAP_CODE'),
      await this._translateText('MACHINES.EXPORT.NAME_DE'),
      await this._translateText('MACHINES.EXPORT.NAME_ES'),
      await this._translateText('MACHINES.EXPORT.NAME_FR'),
      await this._translateText('MACHINES.EXPORT.NAME_NL'),
      await this._translateText('MACHINES.EXPORT.NAME_PT'),
      await this._translateText('MACHINES.EXPORT.NAME_RU'),
      await this._translateText('MACHINES.EXPORT.NAME_UA'),
    ];

    this.downloadCsvSetup = {
        cod_user: this.cod_user,
        api_url: this._reportsApiUrl,
        str_proc_exec: 'fivewhy_sp_ex_machines',
        arr_proc_params_search: [
            this.searchTerm,
            this._getStatus() || null,
            this._implodeValues(this.selectedRecords),
            this.cod_user
        ],
        arr_headers: arr_headers,
        name_of_file_to_download: 'machines_',
        str_column_dynamic: ''
    };

    this.downloadCsvExample = {
      cod_user: this._authService.getAuthenticatedUser().id,
      api_url: this._reportsApiUrl,
      str_proc_exec: 'fivewhy_sp_ex_machines',
      arr_proc_params_search: [
        null,
        null,
        this._implodeValues([]),
        null
      ],
      arr_headers: arr_headers,
      arr_headers_2: [
        '0',                          // RECORD_ID
        '12345',                      // LOCATION_COD
        'Mouts locations example',    // LOCATION
        '0',                          // DEPARTMENT_COD
        'Mouts department example',   // DEPARTMENT
        '123',                        // AREA_COD
        'Mouts Area example',         // AREA
        '123',                        // EQUIPAMENT
        'Mouts Equipment example',    // EQUIPAMENT_NAME
        '123',                        // ACADIA_ID
        'Mouts Machine example',      // MACHINE_NAME
        '123',                        // SAP_CODE
        'Mouts Machine example DE',   // NAME_DE
        'Mouts Machine example ES',   // NAME_ES
        'Mouts Machine example FR',   // NAME_FR
        'Mouts Machine example NL',   // NAME_NL
        'Mouts Machine example PT',   // NAME_PT
        'Mouts Machine example RU',   // NAME_RU
        'Mouts Machine example UA',   // NAME_UA
      ],
      name_of_file_to_download: 'template_import_machines_',
      str_column_dynamic: ''
    };
  }

  async _translateText(translation: string): Promise<string> {
      return await this._translateService.get(translation).toPromise();
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search(false);
  }

  pagination(currentPage, nrOfPages) {
    let delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  toggleAll(event) {
    if (event) {
      let qty = this.machineList.length;
      for (let i = 0; i < qty; i++) {
        this.selectedRecords.push(this.machineList[i].cod_machine);
      }
    } else {
      this.selectedRecords = [];
    }
    this._buidCsvSetup();
  }

  toggleObjSelection($event, codMachine) {
    let index;
    if ($event != 0) {
      index = this.selectedRecords.indexOf($event);
    } else {
      index = this.selectedRecords.indexOf(codMachine);
    }

    if (index == -1) {
      this.selectedRecords.push($event);
    } else {
      this.selectedRecords.splice(index, 1);
    }
    this._buidCsvSetup();
  }

  isSelected(codMachine) {
    return this.selectedRecords.indexOf(codMachine) >= 0;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
    this.bol_change_copy_name = false;
  }

  closeSuccessCopyMessage() {
    this.showError = false;
    this.errorMessage = "";
    this.successCopyMessage = "";
    this.bol_change_copy_name = false;
    this.searchMachine();
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe((translate) => {
      this.errorMessage = translate;
      this.showError = true;
      this._notify.error(translate);
    });
  }

  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }

  public toggleDeleteMachine(id = 0) {
    this.awaitConfirmDelete = !this.awaitConfirmDelete;
    this.deleteMachineId = id;
  }

  public confirmDeleteMachine() {
    let objParam = {
      cod_machine: this.deleteMachineId,
      cod_user: this.cod_user,
    };

    this._machineService.deleteMachine(objParam).subscribe(
      (data) => {
        this.emitEditSuccessMessage = true;
        this._notify.success({ text: "MACHINES.DELETED", translate: true });
        this.search(false);
      },
      (err) => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    );
    this.awaitConfirmDelete = false;
    this.deleteMachineId = 0;
  }

  async messageImported(response: any) {
    this.isLoadingFile = false;
    response = JSON.parse(response);
    if (response?.errors) {
        this.importError(JSON.stringify(response))
    } else {
        this._notify.success({ text: 'MACHINES.MESSAGE_IMPORTED_SUCCESS', translate: true });
    }

    this.search(false);
}

  startLoading() {
    this.isLoadingFile = true;
  }
  _getImportErrorMessage(error: string): string {
    const self = this
    if(self.findTerm(error, 'COD_AREA')) return 'LOGBOOK.REQUIRED_CODE_AREA';
    if(self.findTerm(error, 'COD_LOCATION')) return 'LOGBOOK.REQUIRED_CODE_LOCATION';
    if(self.findTerm(error, 'COD_SUBAREA')) return 'LOGBOOK.REQUIRED_CODE_SUBAREA';
    if(self.findTerm(error, 'COD_EQUIPMENT')) return 'MACHINES.REQUIRED_EQUIPMENT';
    if(self.findTerm(error, 'STR_LOCATION')) return 'LOGBOOK.REQUIRED_LOCATION';
    if(self.findTerm(error, 'STR_NAME')) return 'MACHINES.REQUIRED_STR_NAME';
    if(self.findTerm(error, 'STR_AREA')) return 'LOGBOOK.REQUIRED_AREA';
    if(self.findTerm(error, 'STR_SUBAREA')) return 'LOGBOOK.REQUIRED_SUBAREA';
    return 'MACHINES.MESSAGE_IMPORTED_ERROR';
}
findTerm(msg, part) {
  return msg.includes(part)
}
importError($event) {
    this.isLoadingFile = false;
    $event = JSON.stringify($event)
    if (!$event.includes('Row length does not match headers')) {
        const errorText = this._getImportErrorMessage($event);
        if (errorText != null)
            this._notify.error({ text: errorText, translate: true });
    } else {
        this._notify.error({ text: 'MACHINES.MESSAGE_IMPORTED_ERROR', translate: true });
    }
}
  messageError($event) {
    this.isLoadingFile = false;

    let errorMessage = "";
    let errorMessageType = "";
    if (!$event.includes("Row length does not match headers")) {
      errorMessage = $event.split("-"); //Error uploading file!COD_AREA-1
      errorMessageType = errorMessage[0]; //COD_AREA
    }

    let errorMessageFinal = "";
    if (errorMessageType == "COD_LOCATION") {
      this._translateService
        .get("MACHINES.REQUIRED_LOCATION")
        .subscribe((translate) => {
          errorMessageFinal = translate;
        });
    }

    if (errorMessageType == "COD_AREA") {
      this._translateService
        .get("MACHINES.REQUIRED_AREA")
        .subscribe((translate) => {
          errorMessageFinal = translate;
        });
    }

    if (errorMessageType == "COD_SUBAREA") {
      this._translateService
        .get("MACHINES.REQUIRED_SUBAREA")
        .subscribe((translate) => {
          errorMessageFinal = translate;
        });
    }

    if (errorMessageType == "COD_EQUIPMENT") {
      this._translateService
        .get("MACHINES.REQUIRED_EQUIPMENT")
        .subscribe((translate) => {
          errorMessageFinal = translate;
        });
    }

    if (errorMessageType == "STR_NAME") {
      this._translateService
        .get("MACHINES.REQUIRED_STR_NAME")
        .subscribe((translate) => {
          errorMessageFinal = translate;
        });
    }
    if (errorMessageType == "AREA_TYPE_LINK") {
      this._translateService
        .get("MACHINES.NOT_FOUND_SUBAREA_MACHINE_TYPE")
        .subscribe((translation) => {
          errorMessageFinal = translation
            .replace("#location", errorMessage[1])
            .replace("#area", errorMessage[2])
            .replace("#subarea", errorMessage[3])
            .replace("#equipment", errorMessage[4]);
        });
    }

    this._translateService
      .get("MACHINES.MESSAGE_IMPORTED_ERROR")
      .subscribe((translate) => {
        errorMessageFinal = translate + " " + errorMessageFinal;
      });

    this._showErrorMessage(errorMessageFinal);
  }

  selectStatusActive(event){
    if(event=='selected'){
      this.bol_check_active=true;
    }else{
      this.bol_check_active=false;
      this.bol_check_deleted=true;
    }
    //console.log(this.bol_check_active);
    this.searchMachine();
  }

  selectStatusDeleted(event){
    if(event=='selected'){
      this.bol_check_deleted=true;
    }else{
      this.bol_check_deleted=false;
      this.bol_check_active=true;
    }
    this.searchMachine();
  }
}
