import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {MeetingsService} from '../services/meetings.service';
import {MeetingsTypesService} from '../services/meetings-types.service';
import {Meeting} from '../models/meetings.model';

import { SelectPagination } from "app/logbooks/models/select_pagination.model";

import {AuthService} from '../../main/access/services/auth.service';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';

import {environment} from '../../../environments/environment';
import PaginationHelper from 'app/helpers/pagination-helper';
import { DefaultOrder, OrderItem } from 'app/components/filter/filter.model';


@Component({
  selector: 'app-meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrls: ['./meetings-list.component.css']
})
export class MeetingsListComponent implements OnInit {
  arr_meetings: any = [];
  obj_meeting: Meeting;
  obj_user: any;
  bol_create:boolean;
  str_download_link:any;
  bol_download:boolean;
  obj_meeting_form:any;
  obj_ta_meeting:any;
  obj_event_search: any;
  str_user_tmp:string;
  arr_meetings_today:any;
  arr_departments:any;
  arr_meetings_types:any;  
  cod_location: any;
  str_name_location: any;
  cod_area: any;// cod department
  cod_meeting_type: any;
  cod_meeting_line:any;
  bol_success: boolean;
  bol_loading_list:boolean;
  bol_check_active:boolean;
  bol_check_deleted:boolean;
  bol_has_cdd_list:boolean;
  errorMessage:string;
  showError:boolean;
  bol_error_submit: boolean = false;
  show_create_button: boolean = true;
  bol_no_owner: boolean = false;
  pageIndex: number = 1;
  totalPages: number[] = [];
  maxPage: number;
  resultsPerPage: SelectPagination[] = [];
  resultsPerPageSelected: number;
  resultsPerPageSelectOption: SelectPagination[] = [];
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  no_records: boolean = false;
  arr_orders: Array<string> = [];
  orders: Array<OrderItem> = [
    DefaultOrder.name,
    DefaultOrder.role,
    DefaultOrder.actions,
    DefaultOrder.frequency,
  ];

  @Output() openCreateMeeting = new EventEmitter<any>();

  constructor(
    private _meetingsService: MeetingsService, 
    private _meetingsTypesService: MeetingsTypesService, 
    private _router: Router, 
    public _authService:AuthService,
    private _userPreferencesService:UserPreferencesService,
    private _translateService:TranslateService
  ) {
    this.bol_check_active=true;
    this.bol_check_deleted=false;
    this.bol_loading_list=true;
    this.bol_has_cdd_list=false;
    this.obj_meeting = new Meeting();
    this.arr_meetings = [];
    this.bol_create = false;
    this.bol_success = false;
    this.str_user_tmp = "";
    this.obj_user = this._authService.getAuthenticatedUser();
    this.cod_location = this.obj_user.cod_location;
    this.str_name_location = this.obj_user.name_location;
    this.obj_meeting_form = {
      str_meeting_name:'',
      str_meeting_type_name:'',
      str_file_path: environment().uploadGetFilesApiUrl + '/',
      arr_users: [{
        cod_user: this.obj_user.id,
        str_name: this.obj_user.name,
        str_img_path: this._userPreferencesService.getLocalPicture(),
        cod_usu_participant: this.obj_user.id,
        bol_is_owner: 1
      }]
    };

    //Get translation for meeting typeahead component and initialize it
    this._translateService.get('MEETING.USER_SEL').subscribe(translate => {
      this.obj_ta_meeting = {        
        type: 'dynamic',
        placeHolder: translate,
        valueProperty: 'cod_user',
        searchProperty: 'str_name',
        hasImage: true,
        imageProperty: 'str_img_path',
        onSelect: (obj_selected: any) => {
            const exists = this.checkObjectExists(this.obj_meeting_form.arr_users, obj_selected.cod_user)
            if(!exists) {
              this.obj_meeting_form.arr_users.push({
                cod_user: obj_selected.cod_user,
                str_name: obj_selected.str_name,
                str_img_path: obj_selected.str_img_path,
                bol_is_owner: 0,
                cod_usu_participant: this.obj_user.id
              });
            } 
            this.str_user_tmp='';
        },
        asynchDataCall: (value: string, cb: any) => {
            this.searchUsers(cb, value);
        },
        onBlur: (item: any) => {
          this.str_user_tmp='';
        },
      };
    });
    
  }

  checkObjectExists(arr, cod_user) {
    var id = arr.length + 1;
    var found = arr.some(function (el) {
      return el.cod_user === cod_user;
    });
    return found;
  }
  

  ngOnInit() {
    this._meetingsService.getMeetingsToday(this.obj_user.id, 'NOT_PRIVILEGE').subscribe(data => {
            this.arr_meetings_today = data.content.results;
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        }
    );
    const params = {
      term: '',
      cod_user: this._authService.getAuthenticatedUser().id,
      cod_location: this.obj_user.cod_location
    }

    this.getDepartmentList(params);
    
    this._meetingsTypesService.getMeetingTypesByMeetingLocation(params).subscribe(data => {
      if(data.content.length > 1) {
        this.arr_meetings_types = data.content.results;
      }else{
        this.arr_meetings_types = [data.content.results];
      }

    }, err => {
        const e = JSON.parse(err._body);
        alert(e.content);
    });

    this._translateService.onLangChange.subscribe(() => this.getDepartmentList(params));

    this._loadRowsByPage();
    this.bol_check_active=true;
  }

  ngAfterViewInit(){
    this.bol_check_active=true;
    this.bol_has_cdd_list=this._authService.getAuthenticatedUser().hasCddList;
  }

  previousPage() {
    let previous = this.pageIndex - 1;
    this.changePage(previous);
  }

  nextPage() {
    let next = this.pageIndex + 1;
    this.changePage(next);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search();
  }

  setOrder(order: OrderItem) {
    const indexOfOrder = this.arr_orders.indexOf(order.name);
    indexOfOrder === -1 && this.arr_orders.push(order.name);
    this.order(indexOfOrder);
  };

  order(indexOfOrder: number) {
    const reOrder = indexOfOrder !== -1;
    const meetings = this._meetingsService.orderMeetings(this.arr_orders, this.arr_meetings, reOrder);

    reOrder && this.arr_orders.splice(indexOfOrder)
    this.arr_meetings = meetings;
  };

  search() {
    this.no_records = false;
    let str_status='ACTIVE';
    if(this.bol_check_active && this.bol_check_deleted){
      str_status='ALL';
    }else{
      if(this.bol_check_active){
        str_status='ACTIVE';
      }
      if(this.bol_check_deleted){
        str_status='DELETED';
      }
    }
    
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }

    let offset = tmpPage * this.resultsPerPageSelected;
    let limit = this.resultsPerPageSelected;  

    this.bol_loading_list = true;
    this._meetingsService.getMeetingsByNameList((this.obj_meeting.str_name == '' ? '--' : this.obj_meeting.str_name), this.obj_user.id, 'NOT_PRIVILEGE', str_status, offset, limit).subscribe(data => {
            this.arr_meetings = data.content.results || [];               
            this.bol_loading_list = false;
            this.maxPage = Math.ceil(
              (this.arr_meetings.length > 0 ? this.arr_meetings[0].full_count : 0) / limit
            );

            this.totalPages = PaginationHelper.pagination(
              this.pageIndex,
              this.maxPage
            );
            this.no_records = true;
        },
        err => {
            this._authService.errCheck(err);
            console.log(err);
        }
    );
  }

  getDepartmentList(params){
    this._meetingsService
      .getAreasByMeetingLocation(params)
      .subscribe((data: any) => {
        if (data.content.length >= 0 && !Array.isArray(data.content.results)){
          this.arr_departments = [data.content.results]
        } else {
          this.arr_departments = data.content.results
        }
    }, err => {
      const e = JSON.parse(err._body);
      alert(e.content);
    });
  }

  selectedDepartmenthanged(event) {
    this.cod_area = event.selectedOptions[0].cod_areas;
  }

  showDownload(event, i) {
    const selected = event.selectedOptions; 
    if(selected.length > 0) {
      this.cod_meeting_type = selected[0].cod_meetings_types;
      this.str_download_link = selected[0].str_pdf_path;
      this.bol_download = true;
    }
  }

  selectedLine(event, i) {
    const selected = event.selectedOptions;
    if(selected.length > 0) {
      this.cod_meeting_line = selected[0].cod_line;
    }
  }

  openMeeting(obj_meeting) {
    const path = '/meetings-container/' + obj_meeting.cod_meetings;
    this._router.navigate([path]);
  }

  delParticipant(int_participant){
    this.obj_meeting_form.arr_users.splice(int_participant,1);
  }

  createMeeting() {
    this.openCreateMeeting.emit();
  }

  validateSubmit() {
    if(this.obj_meeting_form._str_meeting_name == '' || this.obj_meeting_form._str_meeting_name == null) {
      return false;
    }
    if(this.cod_area == null || this.cod_area == undefined){
      return false;
    }
    if(this.cod_meeting_type == null || this.cod_meeting_type == undefined) {
      return false;
    }
    return true;
  }

  submit() {
    this.show_create_button = false;
    if(!this.validateSubmit()) {
      this.bol_error_submit = true;
      this.show_create_button = true;
      return false;
    }
    
    const params = {
      str_name: this.obj_meeting_form._str_meeting_name,
      cod_area: this.cod_area,
      cod_meeting_type: this.cod_meeting_type,
      cod_meeting_line: this.cod_meeting_line,
      cod_user: this.obj_user.id,
      arr_participants: this.obj_meeting_form.arr_users
    };

    this._meetingsService.postMeeting(params).subscribe(data => {
      this.bol_success = true;
      const cod_meeting = { cod_meetings: data.content.results.meetings_sp_in_meeting }; 
      this.openMeeting(cod_meeting);
    },
    err => { this._authService.errCheck(err); console.log(err); } 
    );
  }

    searchUsers(cb, str_term) {

        this._meetingsService.getUsersList(str_term, environment().uploadGetFilesApiUrl + '/', this.obj_user.id, 'MEETING_SELECT_PARTICIPANTS').subscribe((result: any) => {
                cb(result.content.results);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );

  }

  selectStatusActive(event){
    if(event=='selected'){
      this.bol_check_active=true;
    }else{
      this.bol_check_active=false;
      this.bol_check_deleted=true;
    }
    //console.log(this.bol_check_active);
    this.search();
  }

  selectStatusDeleted(event){
    if(event=='selected'){
      this.bol_check_deleted=true;
    }else{
      this.bol_check_deleted=false;
      this.bol_check_active=true;
    }
    this.search();
  }

  downloadList(str_download_link) {
    this._meetingsService.getDownloadPDF(str_download_link).subscribe((res: any) => {
      console.log(res)
      let blob = new Blob([res], {
        type: 'application/pdf'
      });

      let tmp = str_download_link.split('/');
      let pdfName = tmp[tmp.length - 1];

      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = pdfName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
    }, err => {
      let e = JSON.parse(err);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    }
    );
  }

  private _showErrorMessage(err) {
    this.errorMessage = err;
    this.showError = true;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
  }

  changeOwner(index, isOwner){    
    if(isOwner === 0){
      let countOwners = 0;
      this.obj_meeting_form.arr_users.map(user =>{
        if(user.bol_is_owner === 1){
          countOwners++;
        } 
      });      
      if(countOwners > 1){
        this.obj_meeting_form.arr_users[index].bol_is_owner = isOwner;
      }
      else{
        this.bol_no_owner = true;
        setTimeout(() => {
          this.bol_no_owner = false;
        }, 5000);
      }
    }
    else{
      this.obj_meeting_form.arr_users[index].bol_is_owner = isOwner;
    }
  }

  selectionChanged($event) {
    this.resultsPerPageSelectOption = $event.selectedOptions;
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  private _loadRowsByPage() {
    this.pageIndex = 1;
    this.totalPages = [];

    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str10perpage = "10 " + translate;
        this._translateService
          .get("GENERAL.PAGINATION_SELECT")
          .subscribe((translate) => {
            this.str50perpage = "50 " + translate;
            this._translateService
              .get("GENERAL.PAGINATION_SELECT")
              .subscribe((translate) => {
                this.str100perpage = "100 " + translate;
                this.resultsPerPage = [
                  new SelectPagination(10, this.str10perpage),
                  new SelectPagination(50, this.str50perpage),
                  new SelectPagination(100, this.str100perpage),
                ];
                this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
                this.resultsPerPageSelected = this.resultsPerPage[0].qty;
                this.search();
              });
          });
      });
  }

}
