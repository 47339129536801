import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MeetingsService} from '../../../../meetings/services/meetings.service';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../../main/access/services/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Meeting } from 'app/meetings/models/meetings.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'forms-dynamic-create-action',
  templateUrl: './create-action.component.html',
  styleUrls: ['./create-action.component.css']
})
export class FormsDynamicCreateActionComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  @Input() questionChoice;
  @Input() codLogbook;
  @Input() strHashtag;
  @Input() first;
  @Input() index;
  @Input() question;

  obj_user = this._authService.getAuthenticatedUser();

  actionDescription: string = '';
  userId: number = undefined;
  dueDate: Date = undefined;
  str_file_path = environment().uploadGetFilesApiUrl + '/';

  arr_users = {
    isLoading: false,
    hasError: false,
    options: [],
    selected: [],
  };

  arr_meetings = {
    selected: []
  };

  obj_owner: any;
  obj_ta_meeting: any;
  cod_user: any;
  str_user_tmp: string;
  str_name: string;  

  deleted: boolean = false;
  isVisible: boolean = true;
  meetingsActivateList: any = [];
  paramsSelected: any = [];
  meetings_description: string;

  constructor(
    private _meetingsService: MeetingsService,
    private _authService:AuthService,
    private sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) {
    this.cod_user = this._authService.getAuthenticatedUser().id;

    this.str_user_tmp = "";
    this.isVisible = false;

    this.obj_owner = {
      cod_user: 0,
      str_name: ''
    };

    this._translateService.get('MEETING.USER_SEL').subscribe(translate => {
      this.obj_ta_meeting = {
        type: 'dynamic',
        placeHolder: translate,
        valueProperty: 'cod_user',
        searchProperty: 'str_name',
        hasImage: false,
        onSelect: (obj_selected: any) => {
          this.obj_owner = {
            cod_user: obj_selected.cod_user,
            str_name: obj_selected.str_name
          };
          this.userId = this.obj_owner.cod_user;
          this.emitChanges();
          this.loadMeetings();
          this.str_user_tmp = '';
        },
        asynchDataCall: (value: string, cb: any) => {
          this.searchUsers(cb, value);
        },
        onBlur: (item: any) => {
          this.str_user_tmp = '';
        },
      };
    });
    this.actionDescription = '';
  }

  ngOnInit() {
    this.cod_user = this._authService.getAuthenticatedUser().id;
    if (this.strHashtag) {
      this.actionDescription = this.strHashtag;
      this.checkDefaultHashtags();
    }
  }

  readequateMeetings(arrMeetings) {
    const newArrayMeetings = [];
    for(let i = 0;i<arrMeetings.length;i = i+1) {
      newArrayMeetings.push({
        cod_meetings: arrMeetings[i].cod_meetings
      });
    }
    return newArrayMeetings;
  }

  emitChanges() {
    const {questionChoice, actionDescription, userId, dueDate, arr_meetings: { selected: destinationMeetingsIds } } = this;
    const isValid = (!!actionDescription && !!userId && !!dueDate && !!destinationMeetingsIds.length);
    const meetingsNewKeys = this.readequateMeetings(destinationMeetingsIds);
    const obj = {
      actionDescription,
      userId,
      dueDate,
      meetings: meetingsNewKeys,
      isValid,
      questionChoice,
      deleted: ((this.deleted) ? true : false),
      index: this.index,
      question: this.question
    };
    this.onChange.emit(obj);
  }

  onMeetingsSelectionChanged(event) {
    this.paramsSelected['meeting_selected'] = event.selectedOptions;
    this.arr_meetings.selected = this.paramsSelected['meeting_selected'];
    this.emitChanges();  
  }

  onOwnerSelectionChanged(event) {
    this.arr_users.selected = event.selectedOptions;
    this.userId = event.selectedOptions.length > 0 ? event.selectedOptions[0].cod_user : undefined;
    this.emitChanges();
  }

  changeDate($event) {
    this.dueDate = (this.sanitizer.bypassSecurityTrustResourceUrl($event.target.value) as any).changingThisBreaksApplicationSecurity;
    this.emitChanges();
  }

  searchUsers(cb, str_term) {
    const $this = this;
    this.clearMeetings();
    this._meetingsService.getUsersList(str_term, environment().uploadGetFilesApiUrl + '/', this.cod_user, 'NOT_PRIVILEGE').subscribe((result: any) => {
      cb(result.content.results);
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
  }

  loadMeetings() {
    let objParam = {
      'cod_user': this.cod_user,
      'cod_user_search': this.obj_owner.cod_user
    };

    this._meetingsService.getMeetingsByUsersList(objParam).subscribe(data => {
      if (data.content.code === 888 && data.code === 888) {
        this.meetingsActivateList = this._meetingsService.getArrayContent(data);
      }
    },
      err => {
        this._authService.errCheck(err);
        console.log(err);
      });
  }

  selectMeetings($event) {
    this.paramsSelected['meetings'] = this._implodeValues($event.selectedOptions, 'cod_meetings');
    this.meetings_description = this._implodeValues($event.selectedOptions, 'str_name');
  }

  private clearMeetings() {
    this.meetingsActivateList = [];
    this.paramsSelected['meetings'] = '';
    this.paramsSelected['meeting_selected'] = [];
  }

  private _implodeValues(objValues, idField) {
    let ids = '';
    if (objValues) {
      objValues.forEach(element => {
        if (ids != '') {
          ids += ',';
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  delete() {
    this.deleted = true;
    this.emitChanges();
  }

  removeOwner() {
    this.obj_owner = {
      cod_user: 0,
      str_name: '',
      str_img_path: ''
    };
    this.userId = null;
    this.emitChanges();
    this.clearMeetings();
    this.isVisible = true;
  }
  
  private checkDefaultHashtags() {
    const defaultHashtags = [ this.strHashtag ];
    const hashtagsArray = this.actionDescription.split(' ').filter(obj => obj.startsWith(this.strHashtag));
  
    defaultHashtags.reverse().forEach(hashtag => {
      if (!hashtagsArray.includes(hashtag)) {
        this.actionDescription = hashtag + ' ' + this.actionDescription;
      }
    })
  }

  onBlurMethod(){
    if (this.strHashtag)
      this.checkDefaultHashtags();
  }

}