import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClbBreadcrumbModule } from '@celebration/angular/clb-breadcrumb';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MeetingsService } from '../services/meetings.service';
import { AuthService } from '../../main/access/services/auth.service';
import { NotificationService } from '../../main/services/notification.service';
import { SocketService } from '../../main/services/socket.service';
import { CommonModule } from '@angular/common';

// TYPES
import { MeetingHeaderDetails, ActionsStatusCount } from './types'
// COMPONENTS
import { MeetingsCommentsDrawerComponent } from '../meetings-comments-drawer/meetings-comments-drawer.component';
import { MeetingsParticipantsDrawerComponent } from '../meetings-participants-drawer/meetings-participants-drawer.component';
import { MeetingsActionsComponent } from '../meetings-actions/meetings-actions.component';
import { MeetingsAddActionDrawerComponent } from '../meetings-add-action-drawer/meetings-add-action-drawer.component';
import { MeetingsAttendanceComponent } from '../meetings-attendance/meetings-attendance.component';
import { MeetingsEditDetailsDrawerComponent } from '../meetings-edit-details-drawer/meetings-edit-details-drawer.component';


@Component({
    standalone: true,
    selector: 'app-meetings',
    imports: [
        ClbBreadcrumbModule,
        TranslateModule, 
        CommonModule, 
        MeetingsParticipantsDrawerComponent,
        MeetingsCommentsDrawerComponent,
        MeetingsActionsComponent,
        MeetingsAddActionDrawerComponent,
        MeetingsAttendanceComponent,
        MeetingsEditDetailsDrawerComponent
    ],
    templateUrl: './meetings.component.html',
    styleUrls: ['./meetings.component.css'],
})
export class MeetingsComponent implements OnInit, OnDestroy {
    
    readonly socket_id: string = crypto.randomUUID(); // Creating local socket uuid here to pass to components that need it, so they are the same (create action, list actions)

    breadcrumbs = [
        {
            url: '/home',
            label: 'Home',
        },
        {
            url: '/my-meetings',
            label: 'My Meetings',
        },
        {
            url: '/my-meetings/:id',
            label: 'Current Page',
        },
    ];

    cod_meeting: number;

	obj_user: any;
	cod_user: any;
	
    bol_loading_header_details: boolean = true;
    str_meeting_name: string;
    inactive_days: { days: any; };
	obj_meeting_header_details: MeetingHeaderDetails;

    bol_loading_count_actions_status: boolean = true;
    obj_count_actions_status: ActionsStatusCount = null;

    bol_loading_actions_average: boolean = true;
    average_execution_time: number = null;

    bol_comments: boolean = false;
    bol_participants: boolean = false;
    bol_add_action: boolean = false;
    bol_show_attendance: boolean = false;
    bol_edit_meeting_details: boolean = false;
    
    constructor(
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _translateService: TranslateService,
        private readonly _authService: AuthService,
        private readonly _meetingsService: MeetingsService,
        private readonly _notify: NotificationService,
        private readonly _socketService: SocketService
    ) {
        this.obj_user = _authService.getAuthenticatedUser();
        this.cod_user = this.obj_user.id;

        this.cod_meeting = -1;
        // 'Current Page' breadcrumb will be a placeholder until meeting name is loaded
        this._translateService.get(['BREADCRUMBS.HOME', 'BREADCRUMBS.MEETINGS', 'BREADCRUMBS.CURRENT']).subscribe((translated) => {
            for (let i = 0; i < translated.length; i++) {
                if (!translated[i].includes('BREADCRUMBS.')) {
                    this.breadcrumbs[i].label = translated[i];
                }
            }
        });
    }

    ngOnInit() {
        try {
            this.cod_meeting = Number(this._activatedRoute.snapshot.paramMap.get('id'));
            if (isNaN(this.cod_meeting)) throw new Error('Meeting ID must be a number');
            this.verifyPermission().subscribe(result => {
                this.parseRequestResult(result, (data) => {
                    if(data.meetings_sp_se_verify_participant) {

                        this._socketService.subscribeMeeting(this.cod_meeting);
                        this.getMeetingDetails();
                        this.getCountActionsStatus();
                        this.getActionsAverage();
                        this.parseQueryParams();

                    }
                    else {
                        this.showErrorMessage('You don`t have the access to this meeting', 'MEETING.OVERLAYMESSAGE');
                        this._router.navigate(['/my-meetings']);
                    }
                }, (err) => {
                    this.showErrorMessage('Failed to load permission', 'GENERAL.FAIL_TO_FETCH');
                    this._router.navigate(['/my-meetings']);
                });
            });
        } catch (error) {
            this.showErrorMessage(error, 'GENERAL.FAIL_TO_FETCH');
            this._router.navigate(['/my-meetings']);
        }
    }

    ngOnDestroy(): void {
        this._socketService.unsubscribeMeeting(this.cod_meeting);
    }

    
    verifyPermission(){
        return this._meetingsService.verifyIfUserIsParticipantInMeeting(this.cod_meeting, this.cod_user)
    }

    parseQueryParams() {
        const queryParams = this._activatedRoute.snapshot.queryParams;
        this.openSection(queryParams.section);
        this.openAction(queryParams.action);
        this.setActionsFilters(queryParams); //TODO decide what filters and etc and how to pass them here
    }

    openSection(section) {
        if (!section) return;

        switch (section) {
            case 'details':
                this.openDetails();
                break;
            case 'participants':
                this.openParticipants();
                break;
            case 'attendance':
                this.openAttendance();
                break;
            case 'comments':
                this.openComments();
                break;
            default:
                break;
        }
    }

    openAction(cod_action) {
        if(!cod_action) return;
        //TODO open action by ID
    }

    openAddAction() {
        this.bol_participants = false;
        this.bol_comments = false;
        this.bol_add_action = true;
    }

    setActionsFilters(filters) {
        if (!filters) return;
        //TODO set / apply filters
    }

    openDetails() {
        this.bol_participants = false;
        this.bol_comments = false;
        this.bol_add_action = false;
        this.bol_edit_meeting_details = true;
    }

    openParticipants() {
        this.bol_participants = true;
        this.bol_comments = false;
        this.bol_add_action = false;
        this.bol_edit_meeting_details = false;
    }

    openAttendance() {
        this.bol_show_attendance = true;
    }

    toggleAttendance() {
        this.bol_show_attendance = !this.bol_show_attendance;
    }

    openComments() {
        this.bol_participants = false;
        this.bol_comments = true;
        this.bol_add_action = false;
        this.bol_edit_meeting_details = false;
    }

    openTor(){
        window.open('/meetings-visualize-tor/' + this.cod_meeting, '_blank');
    }

    parseRequestResult(data: any, calback, error) {
        if (data.code == 888 && data.content.code == 888 && data.content.length > 0) {
            let res = data.content.results
            calback(res.length === 1 ? res[0] : res);
        } else {
            error(data.content);
        }
    }

    getMeetingDetails() {
        this.bol_loading_header_details = true;
        this._meetingsService
            .getMeetingHeaderDetails(this.cod_meeting, this.cod_user)
            .subscribe({
                next: (result: any) => {
                    this.parseRequestResult(result, (data) => {
                        this.obj_meeting_header_details = data;
						this.str_meeting_name = this.obj_meeting_header_details.str_name_meeting;
                        this.inactive_days = {
                            days: this.obj_meeting_header_details.int_inactive_days
                        }
						if (this.str_meeting_name){
							this.breadcrumbs[2].label = this.str_meeting_name;
						}

                        this.bol_loading_header_details = false;
                    }, (err) => {
                        console.log(err);
                        const text = 'MEETING.ERRORS.HEADER_DETAILS';
                        this.showErrorMessage(err, text);
                    });
                },
                error: (err) => {
                    const text = 'MEETING.ERRORS.HEADER_DETAILS';
                    this.showErrorMessage(err, text);
                    this._authService.errCheck(err);
                },
            });
    }

    getCountActionsStatus() {
        this.bol_loading_count_actions_status = true;
        this._meetingsService
            .getCountMeetingActionsStatus(this.cod_meeting, this.cod_user)
            .subscribe({
                next: (result: any) => {
                    this.parseRequestResult(result, (data) => {
                        this.obj_count_actions_status = this.parseCountActionsStatus(data);
                        this.bol_loading_count_actions_status = false;
                    }, (err) => {
                        console.log(err);
                        const text = 'MEETING.ERRORS.COUNT_ACTIONS_STATUS';
                        this.showErrorMessage(err, text);
                    });
                },
                error: (err) => {
                    const text = 'MEETING.ERRORS.COUNT_ACTIONS_STATUS';
                    this.showErrorMessage(err, text);
                }
            });
    }

    parseCountActionsStatus(arr_data: any[]): ActionsStatusCount {
        let res: ActionsStatusCount = arr_data.reduce((obj, item) => {
            switch (item.cod_actions_status){
                case 1:
                    return {...obj, not_started: item.counter};
                case 2:
                    return {...obj, in_progress: item.counter};
                case 3:
                    return {...obj, req_to_close: item.counter};
                case 8:
                    return {...obj, delayed: item.counter};
                default:
                    return obj;
            }
        }, {});
        return res;
    }

    getActionsAverage() {
        this.bol_loading_actions_average = true;
        this._meetingsService
            .getMeetingsActionsAverageTime(this.cod_meeting, this.cod_user)
            .subscribe({
                next: (result: any) => {
                    this.parseRequestResult(result, (data) => {
                        this.average_execution_time = Math.round(data.average) ?? 0;

                        this.bol_loading_actions_average = false;
                    }, (err) => {
                        const text = 'MEETING.ERRORS.ACTIONS_AVERAGE';
                        this.showErrorMessage(err, text);
                    });
                },
                error: (err) => {
                    const text = 'MEETING.ERRORS.ACTIONS_AVERAGE';
                    this.showErrorMessage(err, text);
                }
            });
    }

    showErrorMessage(err, text) {
        console.log(err);
        this._notify.error({ text, translate: true });
    }

}
