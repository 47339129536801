import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {InfoService} from '../../services/help.service';

@Component({
  selector: 'app-info-list',
  templateUrl: './help-list.component.html',
  styleUrls: ['./help-list.component.css']
})
export class InfoListComponent implements OnInit, OnDestroy {

  list: object;
  keys: Array<string> = [];
  active: string;
  subscription: Subscription;

  constructor(private _translateService: TranslateService, private _helpService: InfoService) { }

  ngOnInit() {
    this.loadTranslations();
    this._translateService.onLangChange.subscribe(() => this.loadTranslations());

    this.subscription = this._helpService.currentItem$
        .subscribe(key => this.active = key.toString());
  }

  loadTranslations() {
    this._translateService.get('HELP.LIST').subscribe(result => {
      this.list = result;
      this.keys = Object.keys(this.list);
    });
  }

  clickItem(key) {
    this.active = key;
    this._helpService.changeSelectedItem(key);
    this._helpService.changeSelectedTopic('');
  }

  getImagePath(key) {
    return this._helpService.getImagePath(key);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
