import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ValidationsService } from '../services/validations/validations.service';
import { AuthService } from '../../main/access/services/auth.service';
import { SubareaService } from '../services/subarea.service';
import { UsersService } from '../../admin/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { SubArea } from '../models/sub_area.model';
import { SubAreaTranslation } from '../models/sub_area_translation.model';
import { NotificationService } from '../../main/services/notification.service';

@Component({
    selector: 'app-subarea-persist',
    templateUrl: './subarea-persist.component.html',
    styleUrls: ['./subarea-persist.component.css']
})
export class SubAreaPersistComponent implements OnChanges, OnInit {

    @Input('codSubAreas') id;
    @Output() backEvent = new EventEmitter<Object>();
    @ViewChild('subAreaPersistForm', { static: true }) subAreaFormContainer: ElementRef;

    subArea: SubArea;
    isLoading: boolean;
    equipments: any = [];
    locations: any = [];
    showError: boolean = false;
    errorMessage: string = '';
    worldList: any = [];
    regionalList: any = [];
    areaTypesList: any = [];
    locationsList: any = [];
    areaList: any = [];
    paramsSelected: any = [];
    loadingConfig: any = [];
    tmp_arr_location: any = [];

    showEditTranslations: boolean = false;
    translation: SubAreaTranslation;

    sapAndAcadiaIdPrivilege: boolean;
    dreamValuePrivilege: boolean;

    constructor(
        private _subAreaService: SubareaService, 
        public _validationService: ValidationsService,
        private _authService: AuthService, 
        private _usersGroupPrivilegesService: UsersService,
        private _translateService: TranslateService,
        private _notify: NotificationService,
    ) {
        this.showError = false;
        this.paramsSelected = [];
        this.errorMessage = '';
        this.loadingConfig = [];
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
        this.loadingConfig['areas'] = false;
        this.loadingConfig['area_types'] = false;
        this.subArea = new SubArea();
        this._resetSubArea();
    }

    ngOnInit(): void {
        this.get();
        this._searchWorlds(null);
        this._searchAreaTypes(null);
        this._clearWorldList();

        this.sapAndAcadiaIdPrivilege = this._authService.getUserPrivilege('SAP_LOCATION_ACADIA_ID_FIELDS_ACTIONS');
        this.dreamValuePrivilege = this._authService.getUserPrivilege('ADMIN_AREA_DREAM_VALUE');
    }

    private _resetSubArea() {
        this.subArea = new SubArea();
        this.translation = new SubAreaTranslation();
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.regionalList = [];
        this._clearLocationsList();
    }

    private _smartClearRegionalList() {

        if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
                this.regionalList.map((regional) => {
                    if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
                });
            });
            this.paramsSelected['regionals_selected'] = arr;
            this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        }
        if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
            this.paramsSelected['regionals_selected'] = [];
            this.paramsSelected['regionals'] = '';
            this._clearLocationsList();
        } else {
            this._searchLocations(null);
        }

    }

    private _clearLocationsList() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
        this._clearAreasList();
        //this._clearEquipmentsList();
    }

    private _smartClearLocationsList() {

        if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
                this.locationsList.map((location) => {
                    if (location.cod_location == selected.cod_location) arr.push(location);
                });
            });
            this.paramsSelected['locations_selected'] = arr;
            this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
        }
        if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
            this.paramsSelected['locations_selected'] = [];
            this.paramsSelected['locations'] = '';
            this._clearAreasList();
        } else {
            this._searchAreas(null);
        }

    }

    private _clearAreasList() {
        this.paramsSelected['areas'] = '';
        this.paramsSelected['areas_selected'] = [];
        this.areaList = [];
    }

    private _smartClearAreasList() {

        if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
            var arr = [];
            this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
                this.areaList.map((area) => {
                    if (area.cod_area == selected.cod_area) arr.push(area);
                });
            });
            this.paramsSelected['areas_selected'] = arr;
            this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
        }
        if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
            this.paramsSelected['areas_selected'] = [];
            this.paramsSelected['areas'] = '';
        }

    }

    private _clearAreasTypes() {
        this.paramsSelected['area_types'] = '';
        this.paramsSelected['area_types_selected'] = [];
        this.areaList = [];
    }

    /*
        private _clearEquipmentsList() {
            this.paramsSelected['equipments'] = '';
            this.paramsSelected['equipments_selected'] = [];
            this.equipmentsList = [];
        }*/

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._usersGroupPrivilegesService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_STRUCTURE_LISTING').subscribe(data => {
            this.worldList = this._usersGroupPrivilegesService.getArrayContent(data);
            this._setLoading('worlds', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    private _searchLocations(fn) {

        if (this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._usersGroupPrivilegesService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_STRUCTURE_LISTING').subscribe(data => {
                this.locationsList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('locations', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearLocationsList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearLocationsList();
        }

    }

    private _searchRegionals(fn) {

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._usersGroupPrivilegesService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_STRUCTURE_LISTING').subscribe(data => {
                this.regionalList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('regionals', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearRegionalList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearRegionalList();
        }

    }

    private _searchAreas(fn){

        if(typeof this.paramsSelected['locations'] != typeof undefined && this.paramsSelected['locations'].toString() != ''){
            this._setLoading('areas', true);
            let params = {
                arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
                cod_user: this._authService.getAuthenticatedUser().id,
                dsc_page_privilege: 'ADMIN_STRUCTURE_LISTING'
            };
            this._usersGroupPrivilegesService.getAreasByLocation(params).subscribe(data => {
                this.areaList = this._usersGroupPrivilegesService.getArrayContent(data);
                this._setLoading('areas', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
                this._smartClearAreasList();
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearAreasList();
        }

    }
    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    selectWorlds($event) {
        this.paramsSelected['worlds_selected'] = $event.selectedOptions;
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations_selected'] = $event.selectedOptions;
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
        this._searchAreas(null);
        //this._searchEquipments(null);
    }

    selectAreas($event){
        this.paramsSelected['areas_selected'] = $event.selectedOptions;
        this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
    }

    selectAreasTypes($event){
        this.paramsSelected['area_types'] = this._implodeValues($event.selectedOptions, 'cod_area_types');
    }

    get() {
        this.isLoading = true;
        if (this.id <= 0) {
            this.subArea = new SubArea();
            this.isLoading = false;
        } else {
            let objParam = {
                'cod_subarea': this.id,
                'cod_user': this._authService.getAuthenticatedUser().id,
            };
            this._subAreaService.searchSingleSubarea(objParam).subscribe(data => {
                this.subArea = data.content.results;
                this._resetTranslation();
                const listAux = {
                    'worlds': data.content.results.arr_worlds,
                    'regionals': data.content.results.arr_regional,
                    'locations': data.content.results.arr_location,
                    'areas' : [{cod_area: data.content.results.cod_area}],
                    'area_types' : [{cod_area_types: data.content.results.cod_area_types}]
                };
                this.tmp_arr_location = data.content.results.arr_location;
                this._searchAll(listAux);

                this.isLoading = false;
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this.isLoading = false;
                this._authService.errCheck(err);
            }
            );
        }
    }

    private _searchAll(listAux) {
        const $this = this;      
        $this._searchWorlds(() => {
            $this.paramsSelected['worlds_selected'] = listAux.worlds;
            $this.paramsSelected['worlds'] = this._implodeValues(listAux.worlds, 'cod_locations_world');
            $this._searchRegionals(() => {
                $this.paramsSelected['regionals_selected'] = listAux.regionals;
                $this.paramsSelected['regionals'] = this._implodeValues(listAux.regionals, 'cod_locations_regional');
                $this._searchLocations(() => {
                    $this.paramsSelected['locations_selected'] = listAux.locations;
                    $this.paramsSelected['locations'] = this._implodeValues(listAux.locations, 'cod_location');
                    $this._searchAreas(() =>{
                        $this.paramsSelected['areas_selected'] =listAux.areas;
                        $this.paramsSelected['areas'] = this._implodeValues(listAux.areas, 'cod_area');
                    });
                    /*
                    $this._searchEquipments(() => {
                        $this.paramsSelected['equipments_selected'] = listAux.equipments;
                        $this.paramsSelected['equipments'] = this._implodeValues(listAux.equipments, 'cod_equipments');
                    });*/
                });
            });
        });
        $this._searchAreaTypes(() => {
            $this.paramsSelected['area_types_selected'] = listAux.area_types;
            $this.paramsSelected['area_types'] = this._implodeValues(listAux.area_types, 'cod_area_types');
        });
    }

    private _searchAreaTypes(fn) {
        this._clearAreasTypes();
        this._setLoading('area_types', true);
        this._usersGroupPrivilegesService.getAllAreaTypes(this._authService.getAuthenticatedUser().id, 'ADMIN_STRUCTURE_LISTING').subscribe(data => {
            this.areaTypesList = data.content.results.length > 1 ? data.content.results : [data.content.results];
            this._setLoading('area_types', false);
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        }, err => {
            this._authService.errCheck(err);
            console.log(err);
        });
    }

    /*
        private _searchEquipments(fn) {
            this._clearEquipmentsList();
    
            if (this.paramsSelected['locations'].toString() != '') {
                this._setLoading('equipments', true);
                let params = {
                    'cod_locations': this.paramsSelected['locations'].toString().split(','),
                    'cod_user': this._authService.getAuthenticatedUser().id
                };
                this._subAreaService.getEquipmentsByLocationList(params).subscribe(data => {
                        this.equipmentsList = this._subAreaService.getArrayContent(data);
                        this._setLoading('equipments', false);
                        if (typeof fn != typeof undefined && fn) {
                            fn();
                        }
                    },
                    err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    }
                );
            }
        }
    
        selectEquipments($event) {
            this.paramsSelected['equipments'] = this._implodeValues($event.selectedOptions, 'cod_equipments');
        }*/

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }


    // Field type:  Free text format, including: 
    // Special characters allowed (-) and (.) --> BE04313102 / SC.101.00564
    // Capital letters fixed  --> BE (BELGUIM)
    // Lengh of field --> 100 characters 
    validateInput(event: KeyboardEvent) {
        let input = event.target as HTMLInputElement;
        let value = input.value;
        let pattern = /^[a-zA-Z0-9-\s\.]+$/;
        if (!pattern.test(value)) {
            input.value = value.slice(0, -1);
            return false
        }
        return true;
    }

    private _handleSaveValidade(message: string): void {
        //this.showError = true;
        this._translateService.get(message).subscribe(translate => {
            //this.errorMessage = translate;
            this._notify.warning(translate);
        });
        this._validationService.boolIsLoading = false;
    }

    private _handleSuccessMessage(isNew: boolean): void {
        if (isNew) this._notify.success({text: 'LOGBOOK.SUBAREA_INSERTED', translate: true});
        else this._notify.success({text: 'LOGBOOK.SUBAREA_EDITED', translate: true});
    }

    public saveSubArea() {
        this._validationService.boolSubmit = true;
        this._validationService.boolIsLoading = true;
        this.showError = false;

        if (this.subArea.str_name == null || this.subArea.str_name == '') {
            this._handleSaveValidade('MEETING_TYPES_LIST.ERROR_NAME_IS_REQUIRED');
            return false;
        }

        if(this.paramsSelected['worlds'].length <=0 || this.paramsSelected['worlds'] == 'null'){
            this._handleSaveValidade('MEETING_TYPES_LIST.WORLD_REQUIRED_MSG');
            return false;
        }

        if(this.paramsSelected['regionals'].length <=0 || this.paramsSelected['regionals'] == 'null'){
            this._handleSaveValidade('USERS.MSG_VALIDATION_REGIONAL');
            return false;
        }

        if(this.paramsSelected['locations'].length <=0 || this.paramsSelected['locations'] == 'null'){
            this._handleSaveValidade('MEETING_TYPES_LIST.LOCATION_IS_REQUIRED');
            return false;
        }
        
        if(this.paramsSelected['areas'].length <= 0 || this.paramsSelected['areas'] == 'null'){
            this._handleSaveValidade('LOGBOOK.REQUIRED_AREA');
            return false;
        }
        
        if(this.paramsSelected['area_types'].length <= 0 || this.paramsSelected['area_types'] == 'null'){
            this._handleSaveValidade('LOGBOOK.REQUIRED_SUBAREA_TYPES');
            return false;
        }

        let params: any;

        if (this.id <= 0) {
            params = {
                'cod_subarea': 0,
                'str_name': this.subArea.str_name,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'cod_area':this.paramsSelected['areas'],
                'cod_area_types':this.paramsSelected['area_types'],
                'cod_sap_functional_location': this.subArea.cod_sap_functional_location,
                'str_description_pt': this.subArea.str_description_pt,
                'str_description_es': this.subArea.str_description_es,
                'str_description_fr': this.subArea.str_description_fr,
                'str_description_nl': this.subArea.str_description_nl,
                'str_description_de': this.subArea.str_description_de,
                'str_description_ru': this.subArea.str_description_ru,
                'str_description_uk': this.subArea.str_description_uk,
                'str_description_zh': this.subArea.str_description_zh,
                'str_description_ko': this.subArea.str_description_ko,
                'str_description_vi': this.subArea.str_description_vi,
                'cod_acadia_id': this.subArea.cod_acadia_id,
                'gly_dream_value': this.subArea.gly_dream_value,
                'lef_dream_value': this.subArea.lef_dream_value
            };
        } else {
            params = {
                'cod_subarea': this.subArea.cod_subareas,
                'str_name': this.subArea.str_name,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'cod_area': this.paramsSelected['areas'],
                'cod_area_types':this.paramsSelected['area_types'],
                'cod_sap_functional_location': this.subArea.cod_sap_functional_location,
                'str_description_pt': this.subArea.str_description_pt,
                'str_description_es': this.subArea.str_description_es,
                'str_description_fr': this.subArea.str_description_fr,
                'str_description_nl': this.subArea.str_description_nl,
                'str_description_de': this.subArea.str_description_de,
                'str_description_ru': this.subArea.str_description_ru,
                'str_description_uk': this.subArea.str_description_uk,
                'str_description_zh': this.subArea.str_description_zh,
                'str_description_ko': this.subArea.str_description_ko,
                'str_description_vi': this.subArea.str_description_vi,
                'cod_acadia_id': this.subArea.cod_acadia_id,
                'gly_dream_value': this.subArea.gly_dream_value,
                'lef_dream_value': this.subArea.lef_dream_value
            };
        }        
        this._subAreaService.insertUpdateSubarea(params).subscribe(res => {
            if (res.type !== 'success') {
                this._showErrorMessage(res.content);
            } else {
                let locationParams = {
                    'cod_subarea': this.id <= 0 ? res.content.results.cod_location : this.subArea.cod_subareas,
                    'cod_user': this._authService.getAuthenticatedUser().id,
                    'arr_cod_location': JSON.parse("[" + this.paramsSelected['locations'] + "]")
                }
                this._subAreaService.insertUpdateSubareaLocation(locationParams).subscribe(result => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (result.type !== 'success') {
                        this._showErrorMessage(result.content);
                    } else {
                        let delParams = {
                            'cod_subarea': this.id <= 0 ? res.content.results.cod_location : this.subArea.cod_subareas,
                            'cod_user': this._authService.getAuthenticatedUser().id,
                            'arr_cod_location': []
                        }
                        this.tmp_arr_location.filter(element => {
                            if(JSON.parse("[" + this.paramsSelected['locations'] + "]").indexOf(element.cod_location) == -1){
                                delParams.arr_cod_location.push(element.cod_location);
                            }
                        });                     
                        if(delParams.arr_cod_location.length > 0){
                            this._subAreaService.deleteSubareaLocation(delParams).subscribe(data => {
                                if (result.type !== 'success') {
                                    this._showErrorMessage(data.content);
                                }
                                else{
                                    this._handleSuccessMessage(params.cod_subarea == 0);
                                    this.backSelected(true);
                                }
                            },  err => {
                                this._validationService.boolSubmit = false;
                                this._validationService.boolIsLoading = false;
                                let e = JSON.parse(err._body);
                                this._showErrorMessage(e.content);
                                this._authService.errCheck(err);
                            })
                        }
                        else{
                            this._handleSuccessMessage(params.cod_subarea == 0);
                            this.backSelected(true);
                        }
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    this._showErrorMessage(e.content);
                    this._authService.errCheck(err);
                });
            }
        }, err => {
            this._validationService.boolSubmit = false;
            this._validationService.boolIsLoading = false;
            let e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.get();
    }

    backSelected(success: boolean = false) {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (success) {
            this.id = -1;
        } else {
            this.id = 0;
        }
        this.subArea = new SubArea();
        this.backEvent.emit(this.id);
    }

    closeErrorMessage() {
        this.showError = false;
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
            this._notify.error(translate);
        });
    }

    editTranslations() {
        this.showEditTranslations = true;
    }

    saveTranslations() {
        this.subArea.str_description_pt = this._defaultValue(this.translation.str_description_pt, null);
        this.subArea.str_description_es = this._defaultValue(this.translation.str_description_es, null);
        this.subArea.str_description_fr = this._defaultValue(this.translation.str_description_fr, null);
        this.subArea.str_description_nl = this._defaultValue(this.translation.str_description_nl, null);
        this.subArea.str_description_de = this._defaultValue(this.translation.str_description_de, null);
        this.subArea.str_description_ru = this._defaultValue(this.translation.str_description_ru, null);
        this.subArea.str_description_uk = this._defaultValue(this.translation.str_description_uk, null);
        this.subArea.str_description_zh = this._defaultValue(this.translation.str_description_zh, null);
        this.subArea.str_description_ko = this._defaultValue(this.translation.str_description_ko, null);
        this.subArea.str_description_vi = this._defaultValue(this.translation.str_description_vi, null);
        this.showEditTranslations = false;
    }

    cancelTranslations() {
        this._resetTranslation();
        this.showEditTranslations = false;
    }

    private _defaultValue(object: any, defaultValue: any) {
        if (object == undefined || (object === ''))
            return defaultValue;
        else
            return object;
    }

    private _resetTranslation() {
        this.translation.str_description_pt = this._defaultValue(this.subArea.str_description_pt, null);
        this.translation.str_description_es = this._defaultValue(this.subArea.str_description_es, null);
        this.translation.str_description_fr = this._defaultValue(this.subArea.str_description_fr, null);
        this.translation.str_description_nl = this._defaultValue(this.subArea.str_description_nl, null);
        this.translation.str_description_de = this._defaultValue(this.subArea.str_description_de, null);
        this.translation.str_description_ru = this._defaultValue(this.subArea.str_description_ru, null);
        this.translation.str_description_uk = this._defaultValue(this.subArea.str_description_uk, null);
        this.translation.str_description_zh = this._defaultValue(this.subArea.str_description_zh, null);
        this.translation.str_description_ko = this._defaultValue(this.subArea.str_description_ko, null);
        this.translation.str_description_vi = this._defaultValue(this.subArea.str_description_vi, null);
    }

}
