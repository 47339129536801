import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {AuthService} from '../../main/access/services/auth.service';
import * as moment from 'moment';
import { FiveWhyService } from '../services/five-why.service';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fivewhy-tasks-list',
  templateUrl: './fivewhy-tasks-list.component.html',
  styleUrls: ['./fivewhy-tasks-list.component.css'],
  animations: [
    trigger('fiveWhyList', [
      state('inactive', style({
        display: 'none'
      })),
      state('active',   style({
        display: ''
      })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})
export class FivewhyTasksListComponent implements OnInit, OnChanges {
  @Input() title = '';
  @Input() arr_filters = [];
  @Input() state: string;
  @Input() opened = false;
  @Output() stateReturn = new EventEmitter<Object>();

  fivewhys = [];
  fivewhysOriginal = [];
  cod_user = this._authService.getAuthenticatedUser().id;
  notInformedTranslation: string;
  datFormat: string;

  constructor(private _fiveWhyService: FiveWhyService, private _router: Router,
    private _userPreferenceService: UserPreferencesService,
    private _authService: AuthService,
    private _translateService: TranslateService) {
    this.state = 'inactive';
    this.datFormat = this._userPreferenceService.getLocalDateFormat();
    this.datFormat = this.datFormat ? this.datFormat : 'MM/DD/YYYY' ;
    this.loadTranslations();
  }

  ngOnInit() { 
    this.searchFiveWhy()

    this._translateService.onLangChange.subscribe(() => {
      this.loadTranslations();
      this.searchFiveWhy();
    });
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.opened && changes.opened.currentValue) {
      this.toggleFiveWhy('active');
    }
  }

  open5Why(cod_5why) {
    this._router.navigate([`/problem-terminator/details/${cod_5why}`])
  }

  loadTranslations() {
    this._translateService.get('5WHY.USER_NOT_INFORMED').subscribe(result => {
      this.notInformedTranslation = result;
    });
  }

  toggleFiveWhy(state: string = null) {
    this.state = state ?? this.state != 'active' ? 'active' : 'inactive';
    this.stateReturn.emit(this.state);
  }

  filter5Whys(){
    if(this.arr_filters.length>0){
      for(let a of this.arr_filters){
        if(a.type && a.type== 'description'){
          this.fivewhys = this.fivewhysOriginal.filter( fivewhy => (fivewhy.str_fivewhys_name ?
            fivewhy.str_fivewhys_name.toLowerCase().includes(a.value ? a.value.toLowerCase() : '') : false));
        }
      }
    }else{
      this.fivewhys = [...this.fivewhysOriginal]
    }
  }

  getRowClass(fivewhy) {
    let colorToReturn = ''
    if(fivewhy.bol_ab_reported){
      colorToReturn = 'tr-finished'
    }else{
      const timeElapsed = fivewhy.dat_completed
        ? moment(fivewhy.dat_completed).diff(moment(fivewhy.dat_ins), 'hours')
        : moment(new Date()).diff(moment(fivewhy.dat_ins), 'hours');
      if(timeElapsed <= 72) {
        colorToReturn = 'tr-success'
      } else if(timeElapsed > 72 && timeElapsed <= 120) {
        colorToReturn = 'tr-in-progress'
      } else {
        colorToReturn = 'tr-danger'
      }
    }
    return colorToReturn;
  }
  getColumnClass(fivewhy) {
    let colorToReturn = 'border-left-default'
    if(fivewhy.bol_ab_reported){
      colorToReturn = 'border-left-finished'
    }else{
      const timeElapsed = fivewhy.dat_completed
        ? moment(fivewhy.dat_completed).diff(moment(fivewhy.dat_ins), 'hours')
        : moment(new Date()).diff(moment(fivewhy.dat_ins), 'hours');
      if(timeElapsed <= 72) {
        colorToReturn = 'border-left-success'
      } else if(timeElapsed > 72 && timeElapsed <= 120) {
        colorToReturn = 'border-left-in-progress'
      } else {
        colorToReturn = 'border-left-danger'
      }
    }
    return colorToReturn;
  }
  formatDate(date){
    return date ? moment(date).format(this.datFormat) : ''
  }
  searchFiveWhy() {
    let objParam = {
      'cod_user': this._authService.getAuthenticatedUser().id,
      'userlang': this._userPreferenceService.getLocalLanguage()
    };
    this._fiveWhyService.getFiveWhysMyTasks(objParam).subscribe(data => {
      if(data.content.fiveWhysList){
        for(let f of data.content.fiveWhysList.results){
          f.cod_user_sent = this.notInformedTranslation;
          if(f.cod_fivewhys_status && f.fivewhy_status_progress && f.fivewhy_status_progress.length>1){
            let lastStatus = f.fivewhy_status_progress[f.fivewhy_status_progress.length-2]
            if(lastStatus!=null && !!lastStatus.str_name) {
              f.cod_user_sent = lastStatus.str_name;
            }
          }
        }
        this.fivewhys = data.content.fiveWhysList.results
        this.fivewhysOriginal = data.content.fiveWhysList.results
      }
      
    }, err => {
      let e = JSON.parse(err._body);
      this._authService.errCheck(err);
    });
  }
}
