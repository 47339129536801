import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {SocketService} from '../../main/services/socket.service';
import {ActionsService} from '../services/actions.service';
import {MeetingsService} from '../services/meetings.service';

@Component({
  selector: 'app-meetings-create-action',
  templateUrl: './meetings-create-action.component.html',
  styleUrls: ['./meetings-create-action.component.css']
})
export class MeetingsCreateActionComponent implements OnInit {

  @Input()
  meetingDetails: any;
  @Input()
  kpi: any;
  @Output()
  arrayChanged: EventEmitter<any> = new EventEmitter<any>();
  error_message_action: any;
  arr_action_form: Array<any>;
  obj_user: any;
  cod_meeting: number;
  arr_filters: Array<any>;
  str_agrupament = 'status'; // Usign to define the grouper actions tipe
  arr_actions_group;
  arr_action_main: any;
  arr_orders: Array<string>;
  bool_filter_finished = true;
  arr_users: any;
  obj_connections = new Subscription();
  error_create_action: boolean;
  bol_success_actions: boolean;
  constructor(private _activatedRoute: ActivatedRoute, private _translateService: TranslateService,
    private _authService: AuthService, private _actionsService: ActionsService, private _socketService: SocketService,
     private _meetingsService: MeetingsService) {
      this.obj_user = _authService.getAuthenticatedUser();
   }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.cod_meeting = params['id'];
      this.arr_users = [];
      this.arr_action_form = [
        {
          arr_meeting: [{cod_meetings: this.cod_meeting}],
          str_description: '',
          str_sources: this.meetingDetails.str_name_meeting_type,
          cod_indicator: this.kpi.cod_indicator,
          arr_users: [],
          dat_due: {},
          cod_usu_ins: this.obj_user.id
        }
      ];
      this._socketService.subscribeMeeting(this.cod_meeting);
    },
    err => { this._authService.errCheck(err); console.log(err); }
    );
    this.refreshParticipants();
  }

  refreshParticipants() {
    // REFRESH DATA FROM ALL MEETING PARTICIPANTS
    this._meetingsService.getMeetingsParticipants(this.cod_meeting, environment().uploadGetFilesApiUrl + '/', this._authService.getAuthenticatedUser().id).subscribe(data => {

        if (data.code === 888 && data.content.code === 888) {
            this.arr_users = data.content.results;
            // console.log(this.arr_users);
            for (let i = 0; i < this.arr_users.length; i++) {
                if (this.obj_user.id === this.arr_users[i].cod_user) {
                    this.obj_user.bol_is_owner = this.arr_users[i].bol_is_owner;
                }
            }

        } else {
            console.log(data);
        }

    },
    err => {
        this._authService.errCheck(err);
        console.log(err);
    });
  }

  selectedUsersChanged(event, i) {
    this.arr_action_form[i].arr_users = event.selectedOptions;
  }

  selectedUsersChangedFilter(event, i) {
    this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].str_name : null;
    this.updateListActionsOrder();
  }

  selectedActionStatusChangedFilter(event, i) {
    this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_actions_status : null;
    this.updateListActionsOrder();
  }

  selectedIndicatorChangedFilter(event, i) {
    this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_indicator : null;
    this.updateListActionsOrder();
  }

  /**
  * @author Guilherme Renato Uller
  *
  *
  */
 updateListActionsOrder() {
    this.arr_actions_group = this._actionsService.filterActions(this.arr_filters, this.arr_action_main, this.bool_filter_finished);
    this.arr_actions_group = this._actionsService.groupActions(this.str_agrupament, this.arr_actions_group);
    this.arr_actions_group = this._actionsService.orderActions(this.arr_orders, this.arr_actions_group);
  }

  addAction() {
    this.arr_action_form.push({
      arr_meeting: [{cod_meetings: this.cod_meeting}],
      str_description: '',
      str_sources: this.meetingDetails.str_name_meeting_type,
      cod_indicator: this.kpi.cod_indicator,
      arr_users: [],
      dat_due: {},
      cod_usu_ins: this.obj_user.id
    });
    this.arrayChanged.emit(this.arr_action_form.length);
    this.error_create_action = false;
    this.bol_success_actions = false;
  }

  validateActions(arr_actions) {
    const errors = [];

    for (let i = 0; i < arr_actions.length; i = i + 1) {
      errors[i] = [];
      const action = arr_actions[i];
      if (action.str_description === '' || action.str_description == null) {
        this._translateService.get('MEETING.VALIDATIONS.DESCRIPTION_IS_REQUIRED').subscribe(translate => {
          errors[i].push(translate);
        });
      }
      if (action.dat_due.length === 'undefined' || action.dat_due.length === undefined) {
        this._translateService.get('MEETING.VALIDATIONS.DATE_IS_REQUIRED').subscribe(translate => {
          errors[i].push(translate);
        });
      }
      if (action.arr_users.length === 0) {
        this._translateService.get('MEETING.VALIDATIONS.RESPONSIBLE_IS_REQUIRED').subscribe(translate => {
          errors[i].push(translate);
        });
      }
    }
    const checkerrros = [];
    for (let i = 0; i < errors.length; i = i + 1) {
      if (errors[i].length > 0) {
        checkerrros.push(1);
      }
    }

    return (checkerrros.length === 0) ? [] : errors;
  }

  saveActions() {
    const validate = this.validateActions(this.arr_action_form);
    if (validate.length > 0) {
        this.bol_success_actions = false;
        this.error_create_action = true;
        this.error_message_action = validate;
    } else {
        this.error_create_action = false;
        this.bol_success_actions = true;
        const $this = this;
        setTimeout(_ => this.bol_success_actions = false
        , 3000);

        this._actionsService.postAction(this.arr_action_form).subscribe(res => {
            if (res.code === 888 && res.content.code === 888) {
                // REFRESH MEETINGS
                this._socketService.refreshMeetingActions({arr_meetings: [this.cod_meeting]});

                const participants = [];
                for (let i = 0; i < this.arr_users.length; i++) {
                    participants[i] = this.arr_users[i].cod_user;
                }
                // REFRESH USERS NOTIFICATIONS
                this._socketService.refreshUserNotifications({arr_participants: participants});
                // CLEAN ARRAY OF ACTIONS
                this.arr_action_form = [];
                this.arrayChanged.emit(this.arr_action_form.length);
            }
        }, err => {
            const e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        } );

    }
  }

  delAction(cod_action) {
    this.error_create_action = false;

    this.arr_action_form.splice(cod_action, 1);
    this.arrayChanged.emit(this.arr_action_form.length);
  }

  searchUserAction(index) {
    // GET DESCRIPTION
    const str_action = this.arr_action_form[index].str_description;

    // OPEN OPTIONS USER
    this.arr_action_form[index].arr_opt_users = [];

    // ONLY IF HAS @
    if (str_action.indexOf('@') !== -1) {
      // GET PART USER NAME
      const str_part_user = str_action.substring(str_action.indexOf('@') + 1);

      for (let i = 0; i < this.arr_users.length; i++) {
        // CHECK IF STRING AFTER @ IS INSIDE NAME OF PARTICIPANTS
        if (this.arr_users[i].str_name.toLowerCase().indexOf(str_part_user.toLowerCase()) !== -1) {
          this.arr_action_form[index].arr_opt_users.push(this.arr_users[i]);
        }
      }

    }

    // DONT SHWO OPTIONS IF NONE WAS FOUND
    if (this.arr_action_form[index].arr_opt_users.length === 0) {
      delete this.arr_action_form[index].arr_opt_users;
    }
  }

}
