import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UploadExcelService} from './upload-excel.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ImportExcelService {

    constructor(private http: HttpClient, private _uploadExcelService: UploadExcelService) {}

    uploadFile(formData): Observable<any> {
        return this._uploadExcelService.upload('/upload', formData);
    }

    importExcel(formData): Observable<any> {
        return this._uploadExcelService.post('/importexcel', formData);
    }

    importExcelV2(formData): Observable<any> {
        return this._uploadExcelService.post('/v2/importexcel', formData);
    }

}
