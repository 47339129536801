import {Injectable} from '@angular/core';
import {ListSubAreasTaMainService} from './list-subareas-ta-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListSubAreasTaService {
  
  constructor(private _mainService: ListSubAreasTaMainService) {
  }
  
  getSubAreasTaList(param): Observable<any> {
    let path = '/sub-areas?term=' + param.term + '&cod_user='+param.cod_user;
    if (param.cod_areas > 0) {
      path = '/sub-area-by-area/' + param.cod_areas + '/'+param.cod_user;
    }
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
