import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'app/main/access/services/auth.service';
import { Rating } from '../model/rating.model';
import { FiveWhyService } from '../services/five-why.service';

@Component({
  selector: 'app-fivewhy-rating',
  templateUrl: './fivewhy-rating.component.html',
  styleUrls: ['./fivewhy-rating.component.css']
})
export class FivewhyRatingComponent implements OnInit {

  @Input('codFivewhy') id;
  @Input('editEnabled') editEnabled;

  rating: Rating;
  showComponentRatingFiveWhy = false;
  defaultSizeRating = false;
  all_required_completed: string = "";
  people_present: string = "";
  out_work_area: string = "";
  all_evidence: string = "";
  seq_make_sense: string = "";
  true_root_cause_found: string = "";
  preventive_actions_proprosed: string = "";
  bol_all_required_completed = false;
  bol_people_present = false;
  bol_out_work_area = false;
  bol_all_evidence = false;
  bol_seq_make_sense = false;
  bol_true_root_cause_found = false;
  bol_preventive_actions_proprosed = false;
  str_comments: string;
  vl_rating: any;
  date_rating: any;
  awaitConfirmDelete: boolean = false;
  deleteRatingId: any;
  editSuccessMessage: boolean = false;
  emitSuccessMessage: boolean;
  emitErrorMessage: boolean;
  errorMessage: string = '';
  showError: boolean = false;
  createRecord: boolean = false;
  arr_rating: Rating[] = [];
  @Output() ratingEmitter = new EventEmitter<any>();
  @Output() onEditing = new EventEmitter<boolean>();

  cod_user: any;
  isLoading: boolean = false;
  showDeleteRatingModalConfirmation: boolean = false;
  widthToCopy: string;

  constructor(private _translateService: TranslateService,private _authService: AuthService,private _fivewhyService: FiveWhyService) 
  {
    this.rating = new Rating();
    this.cod_user = this._authService.getAuthenticatedUser().id;

    this._translateService.get("5WHY.ALL_REQUIRED_COMPLETED").subscribe((translation) => {
      this.all_required_completed = translation;
    });

    this._translateService.get("5WHY.PEOPLE_PRESENT").subscribe((translation) => {
      this.people_present = translation;
    });

    this._translateService.get("5WHY.5WHY_OUT_WORK_AREA").subscribe((translation) => {
      this.out_work_area = translation;
    });

    this._translateService.get("5WHY.ALL_5WHY_EVIDENCE").subscribe((translation) => {
      this.all_evidence = translation;
    });

    this._translateService.get("5WHY.SEQUENCE_MAKE_SENSE").subscribe((translation) => {
      this.seq_make_sense = translation;
    });

    this._translateService.get("5WHY.TRUE_ROOT_CAUSE_FOUND").subscribe((translation) => {
      this.true_root_cause_found = translation;
    });

    this._translateService.get("5WHY.PREVENTIVE-ACTIONS_PROPOSED").subscribe((translation) => {
      this.preventive_actions_proprosed = translation;
    });
  }

  ngOnInit() {
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
    if (this.rating.cod_rating > 0) {
      this.date_rating = this.rating.dat_ins;
    } else {
      this.date_rating = new Date()
    }
    this.searchRating();
    this.calcRating();
  }
  @HostListener ('window:resize', ['$event'])
  onResize(event) {
    // work around to maintain same width - get css props from add whys button and copy width every time screen size changes.
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
  }
  private searchRating() {
    let objParam = {
      'cod_user': this.cod_user,
      'cod_fivewhy': this.id
    };

    this.isLoading = true;
    this._fivewhyService.listFiveWhyRating(objParam).subscribe(data => {
      if (data.code === 888 && data.content.code === 888) {
        this.arr_rating = data.content.results;
        this.ratingEmitter.emit(this.arr_rating);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this._showErrorMessage(data.content.message);
        this.arr_rating = [];
        this.ratingEmitter.emit(this.arr_rating);
      }
    }, err => {
      this.isLoading = false;
      this._authService.errCheck(err);
      this._showErrorMessage(err);
    });
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  addRatingFiveWhy() {
    this.showComponentRatingFiveWhy = true;
    this.rating = new Rating();
    this.onEditing.emit(true);
  }

  saveRatingFiveWhy() {
    this.rating.cod_user = this.cod_user;
    this.rating.str_name = this._authService.getAuthenticatedUser().name;
    this.showComponentRatingFiveWhy = false;
    this.rating.cod_fivewhy = this.id;
    this.calcRating();

    this._fivewhyService.saveFivewhyRating(this.rating).subscribe(data => {
      if (data.code !== 888 || data.content.code !== 888) {
        this._showErrorMessage(data.content.message);
      } else {
        this.searchRating();
        this.onEditing.emit(false);
      }
    }, (err) => {
      this._authService.errCheck(err);
      this._showErrorMessage(err);
    });
  }

  calcRating(){
    let temp_rating = Object.keys(this.rating);
    let countRating = 0;
    temp_rating.forEach(element => {
      if (this.rating[element] && element.startsWith('bol'))
        countRating++;
    });
    this.rating.vl_rating = (countRating / 7) * 100
  }

  cancelRatingFiveWhy() {
    this.showComponentRatingFiveWhy = false;
    this.searchRating();
    this.onEditing.emit(false);
  }

  onAllReqCompletedChanged(event) {
    this.rating.bol_info_completed = event;
  }

  onPeoplePresentChanged(event) {
    this.rating.bol_pple_present = event;
  }

  onOutWorkAreaChanged(event) {
    this.rating.bol_5why_work_area = event;
  }

  onAllEvidenceChanged(event) {
    this.rating.bol_whys_sup_evidence = event;
  }

  onSeqMakeSense(event) {
    this.rating.bol_seq_5why_make_sense = event;
  }

  onRootCauseFoundChanged(event) {
    this.rating.bol_true_root_cause_found = event;
  }

  onPrevActionsPropChanged(event) {
    this.rating.bol_prev_actions_prop = event;
  }

  public editRating(ratings) {
    this.showComponentRatingFiveWhy = true;
    this.rating = ratings;
  }

  public showBtnAdd() {
    if(!this.editEnabled) return false
    return this.arr_rating ? !this.arr_rating.some(element => element.cod_user === this.cod_user) : true;
  }

  public showBtnEditDel(ratings) {
    return ratings.cod_user == this.cod_user && this.editEnabled;
  }

  public showModalDelete(rating) {
    this.showDeleteRatingModalConfirmation = true;
    this.deleteRatingId = rating.cod_rating;
  }

  public deleteRatingFivewhy() {
    this.showDeleteRatingModalConfirmation = false;
    let objParam = {
      'cod_rating': this.deleteRatingId,
      'cod_user': this.cod_user
    };
    this._fivewhyService.deleteFiveWhyRating(objParam).subscribe(data => {
      if (data.code === 888 && data.content.code === 888){
        this.searchRating();
      } else {
        this._showErrorMessage(data.content.message);
      }

      this.emitErrorMessage = false;
      this.emitSuccessMessage = true;
      this.deleteRatingId = 0;
    }, err => {
      this.emitErrorMessage = true;
      this.emitSuccessMessage = false;
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
      this.deleteRatingId = 0;
    });
    this.awaitConfirmDelete = false;
    
  }
}
