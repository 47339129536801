export class ListEquipmentParams {
    cod_user: number;
    arr_cod_areas: string;
    arr_cod_subareas: string;
    arr_cod_locations: string;
    str_filter: string;
    str_filter_by: string;
    dsc_page_privilege: string;

    constructor(
        user: number = null,
        areas: string = '',
        subareas: string = '',
        locations: string = '',
        filter: string = 'IS_FILTER',
        filter_by: string = null,
        page_privilege: string = ''
    ) {
        this.cod_user = user;
        this.arr_cod_areas = areas;
        this.arr_cod_subareas = subareas;
        this.arr_cod_locations = locations;
        this.str_filter = filter;
        this.str_filter_by = filter_by;
        this.dsc_page_privilege = page_privilege;
    }

    valid() {
        if (!this.cod_user) return false;
        if (!this.str_filter_by) return false;
        if (!this.dsc_page_privilege) return false;
        if (!this.arr_cod_areas) return false;
        if (!this.arr_cod_subareas) return false;
        if (!this.arr_cod_locations) return false;
        return true;
    }
}
