import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccessControlLogService} from './services/access-control-log.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'access-control-log',
    templateUrl: './access-control-log.component.html'
})

export class AccessControlLogComponent implements OnInit {

    @Input() dscControl: string;

    constructor(private _AccessControlLogService: AccessControlLogService, private _authService:AuthService) { 

    }

    ngOnInit() {        
        this.registerAcessLog(this.dscControl);
    }

    registerAcessLog(control) {
        let date = new Date();
        let date_ins = date.getFullYear() + '-' + 
                       ('0' + (date.getMonth() + 1)).slice(-2) + '-' + 
                       ('0' + date.getDate()).slice(-2) + ' ' + 
                       ('0' + date.getHours()).slice(-2) + ':' + 
                       ('0' + date.getMinutes()).slice(-2) + ':' + 
                       ('0' + date.getSeconds()).slice(-2);
        this._AccessControlLogService.registerAccess({ 'cod_user': this._authService.getAuthenticatedUser().id,'dsc_control': control, 'dat_ins': date_ins }).subscribe(res => {
            return;
        }, err => {
            const e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        } );        
    }


       
}
