import {Injectable} from '@angular/core';
import {MainService} from './main.service';

import {environment} from '../../../../environments/environment';

import {AuthService} from '../../../main/access/services/auth.service';

import {ListItem} from '../models/list-item.model';

@Injectable()
export class FormsDynamicReportsService {
    private _cod_user = this._authService.getAuthenticatedUser().id;
    private _mail_to_send = this._authService.getAuthenticatedUser().email;
    _reportsApiUrl = environment().reportsApiUrl;

    constructor(
        private _mainService: MainService,
        private _authService: AuthService,
    ) {
    }

    postDynamicReportResume(searchParams) {
        const path = '/createReports';
        return this._mainService.postReport(path, searchParams);
    }

    report(arr_variations: ListItem[], arr_areas: ListItem[], arr_users: ListItem[], arr_forms: ListItem[], dat_start: Date, dat_finish: Date, cod_user: number) {
        const str_cod_logbooks = arr_variations.map(i => i.cod_item).join();
        const str_cod_areas = arr_areas.map(i => i.cod_item).join();
        const str_cod_users = arr_users.map(i => i.cod_item).join();
        const str_cod_forms = arr_forms.map(i => i.cod_item).join();
        return this._mainService.post('/report/:'+cod_user, {
            str_cod_logbooks,
            str_cod_areas,
            str_cod_users,
            str_cod_forms,
            dat_start,
            dat_finish,
        });
    }

    export(arr_variations: ListItem[], arr_areas: ListItem[], arr_users: ListItem[], arr_forms: ListItem[], dat_start: Date, dat_finish: Date) {
        const str_cod_variations = arr_variations.map(i => i.cod_item).join();
        const str_cod_areas = arr_areas.map(i => i.cod_item).join();
        const str_cod_users = arr_users.map(i => i.cod_item).join();
        const str_cod_forms = arr_forms.map(i => i.cod_item).join();
        return this._mainService.postReport('/createFormsReports', {
            cod_user: this._cod_user,
            api_url: this._reportsApiUrl,
            str_report_subject: 'Forms report', // get name from i18n
            arr_filters: {
                str_cod_variations,
                str_cod_areas,
                str_cod_users,
                str_cod_forms,
                dat_start,
                dat_finish,
            },
        });
    }

    //TODO: review
    postDynamicReport(searchParams) {
        const path = '/createFormsReports';
        return this._mainService.postReport(path, searchParams);
    }

}
