import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {FormsDynamicAdminService} from '../services/forms-dynamic.service';
import {UsersService} from '../../../admin/services/users.service';
import {AuthService} from '../../../main/access/services/auth.service';
import {Question} from '../models/question.model';
import {Choice} from '../models/choice.model';
import { Category } from '../models/category.model'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { NotificationService } from "./../../../main/services/notification.service";
import { Translation } from "../../../components/translations-input/models/translation.model";

const REGEX_QUESTION_ID = /^question-([0-9]+)$/;

enum TranslationType {
    FORM,
    CATEGORY,
    QUESTION,
    CHOICE,
}

@Component({
    selector: 'forms-dynamic-edit',
    templateUrl: './forms-dynamic-edit.component.html',
    styleUrls: ['./forms-dynamic-edit.component.css']
})
export class FormsDynamicEditComponent implements OnInit, /*OnDestroy, */OnChanges {

    @Input('codForm') cod_form: number;
    @Output() backEvent = new EventEmitter<Object>();
    @Output() reqResult = new EventEmitter<Object>();

    // cod_form: number = 0;
    str_name: string = '';
    str_name_de: string = '';
    str_name_es: string = '';
    str_name_fr: string = '';
    str_name_nl: string = '';
    str_name_pt: string = '';
    str_name_ru: string = '';
    str_name_ua: string = '';
    str_name_zh: string = '';
    str_name_ko: string = '';
    str_name_vi: string = '';    
    world: number = null;
    arr_questions: Question[] = [];
    obj_user = this._authService.getAuthenticatedUser();
    upload_api_url = environment().uploadGetFilesApiUrl + '/';

    cod_form_question: number = 0;
    cod_forms_category: number = 0;
    int_type: number = 0;
    bol_creates_action: boolean = false;
    bol_optional: boolean = false;
    str_text: string = '';

    arr_choices: Choice[] = [];
    arr_questions_to_verify = []
    isEditing = false;
    isEditingQuestion = false;
    isLoading = false;
    hasError = false;
    // sub: Subscription;
    warningMessage: string;
    awaitConfirmDeleteQuestion: boolean = false;
    deleteFormId: any;
    messageCountChars: boolean = false;
    messageTooMuchHashtags: boolean = false;
    showBlockingPopUp: boolean = false;

    reorderSource = null;
    reorderTarget = null;
    reorderSourceId = 0;
    reorderTargetId = 0;

    loadingWorld: boolean = false;
    loadingRegionals: boolean = false;
    loadingLocations: boolean = false;
    worldList: any = [];
    regionalList: any[];
    locationsList: any = [];
    paramsSelected: any;
    str_link: any;
    str_hashtag: any;
    str_link_desc: any;
    arr_links: any;
    arr_links_desc: any;
    arr_cod_links_del: any;
    form_hyperlinks: any;
    linkIdx: any = -1;
    link_timeout: any;
    int_order: number;
    isSaving: boolean = false;
    listAux: any = [];
    bol_use_previous_answers: boolean = false;
    
    edit_translation: boolean = false;
    translation_type: TranslationType = null;
    translation_data: Translation = new Translation();
    backup_translation_data: Translation = new Translation();

    edited_translation: boolean = false;
    current_category: number = null;
    current_question: number = null;
    current_choice: number = null;

    categories: Array<Category> = [];
    awaitConfirmDeleteCategory: boolean = false;
    deleteCategoryId: number = null;
    isEditingCategory: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: FormsDynamicAdminService,
        private _userService: UsersService,
        private sanitizer: DomSanitizer,
        private _notify: NotificationService,
        private _translateService: TranslateService,
        private _authService: AuthService
    ) {
        this.arr_links = [];
        this.arr_links_desc = [];
        this.arr_cod_links_del = [];
        this.str_link = '';
        this.str_link_desc = '';
        this.form_hyperlinks = [];
        this.paramsSelected = [];
    }

    ngOnInit() {
        this.isEditing = !isNaN(this.cod_form) && this.cod_form > 0;
        this._searchWorlds(undefined);   
    }

    save() {
        if (!this.isSaving) {
            this.showWarningMessage('');
            this.isSaving = true;

            if(this.str_hashtag && this.str_hashtag.indexOf('#')==-1){
                this.str_hashtag = '#'+this.str_hashtag
            }

            if (this.str_hashtag && this.str_hashtag.indexOf('#') > 0) {
                this.showWarningMessage('HASHTAG_VALIDATION_2');
                this.isSaving = false;
                return;
            }
            
            if((this.str_hashtag?.match(/#/g)||[]).length > 1){
                this.showWarningMessage('HASHTAG_VALIDATION_2');
                this.isSaving = false;
                return;
            }

            if (this.str_hashtag && this.str_hashtag.indexOf(' ') >= 0) {
                this.showWarningMessage('HASHTAG_VALIDATION_1');
                this.isSaving = false;
                return;
            }
            
            if (!this.str_name) {
                this.showWarningMessage('NAME_IS_REQUIRED');
                this.isSaving = false;
                return;
            }

            if (this.paramsSelected['worlds'] == undefined || this.paramsSelected['worlds'].length <= 0) {
                this.showWarningMessage('WORLD_IS_REQUIRED');
                this.isSaving = false;
                return;
            }

            if (this.paramsSelected['regionals'] == undefined || this.paramsSelected['regionals'].length <= 0) {
                this.showWarningMessage('REGIONAL_IS_REQUIRED');
                this.isSaving = false;
                return;
            }

            if (this.paramsSelected['locations'] == undefined || this.paramsSelected['locations'].length <= 0) {
                this.showWarningMessage('LOCATION_IS_REQUIRED');
                this.isSaving = false;
                return;
            }

            let arr_questions = [];
            this.categories.map(c => arr_questions = [...arr_questions, ...c.questions]);
            if (arr_questions.length < 1) {
                this.showWarningMessage('QUESTION_AT_LEAST_ONE');
                this.isSaving = false;
                return;
            }
            const categoryWithoutQuestions = this.categories.find(x=>!x.questions || x.questions.length==0)
            if(categoryWithoutQuestions){
                this.showWarningMessage('QUESTION_AT_LEAST_ONE_BY_CATEGORY');
                this.isSaving = false;
                return;
            }
            const hasSubArea = _.find(arr_questions, { 'int_type': 9 });
            const hasArea = _.find(arr_questions, { 'int_type': 8 });

            if (hasSubArea != undefined && hasArea == undefined) {
                this.showWarningMessage('QUESTION_EQUIPMENT_AREA');
                this.isSaving = false;
                return;
            }

            const hasEquipment = _.find(arr_questions, { 'int_type': 10 });

            if (hasEquipment != undefined && hasSubArea == undefined) {
                this.showWarningMessage('QUESTION_EQUIPMENT_SUBAREA');
                this.isSaving = false;
                return;
            }

            const hasMachine = _.find(arr_questions, { 'int_type': 17 });

            if (hasMachine != undefined && hasEquipment == undefined) {
                this.showWarningMessage('QUESTION_MACHINE_EQUIPMENT');
                this.isSaving = false;
                return;
            }
            
            if (this.form_hyperlinks.length > 0) {
                for (let i = 0; i < this.form_hyperlinks.length; i++) {
                    if (this.form_hyperlinks[i]['cod_forms_hyperlinks'] == null) {
                        this.arr_links.push(this.form_hyperlinks[i]['str_link']);
                        this.arr_links_desc.push(this.form_hyperlinks[i]['str_link_desc']);
                    }
                }
            }

            if (this.isEditing) {
                this.service.update(
                    this.cod_form, 
                    this.str_name, 
                    this.str_name_de, 
                    this.str_name_es, 
                    this.str_name_fr, 
                    this.str_name_nl, 
                    this.str_name_pt, 
                    this.str_name_ru, 
                    this.str_name_ua,
                    this.str_name_zh,
                    this.str_name_ko,
                    this.str_name_vi,                    
                    this.str_hashtag,
                    this.obj_user.id,
                    this.categories,
                    this.paramsSelected['worlds'],
                    JSON.parse("[" + this.paramsSelected['regionals'] + "]"),
                    JSON.parse("[" + this.paramsSelected['locations'] + "]"),
                    this.arr_links, 
                    this.arr_links_desc, 
                    this.arr_cod_links_del,
                    Boolean(this.bol_use_previous_answers)
                ).subscribe(res => {
                    this.isSaving = false;
                    this.reqResult.emit({ showSuccessMessage:true });
                    this.str_link = '';
                    this.str_link_desc = '';
                    this.goBackToListing();
                }, err => { 
                    this.reqResult.emit({ showErrorMessage:true });
                });
            } else {
                this.service.insert(    
                    this.str_name, 
                    this.str_name_de, 
                    this.str_name_es, 
                    this.str_name_fr, 
                    this.str_name_nl, 
                    this.str_name_pt, 
                    this.str_name_ru, 
                    this.str_name_ua, 
                    this.str_name_zh,
                    this.str_name_ko,
                    this.str_name_vi,
                    this.str_hashtag,
                    this.obj_user.id, 
                    this.categories, 
                    this.paramsSelected['worlds'],
                    JSON.parse("[" + this.paramsSelected['regionals'] + "]"),
                    JSON.parse("[" + this.paramsSelected['locations'] + "]"),
                    this.arr_links, 
                    this.arr_links_desc, 
                    this.arr_cod_links_del,
                    Boolean(this.bol_use_previous_answers)
                ).subscribe(res => {
                    this.isSaving = false;
                    this.reqResult.emit({ showSuccessMessage:true });
                    this.str_link = '';
                    this.str_link_desc = '';
                    this.goBackToListing();
                }, err => { 
                    this._authService.errCheck(err); 
                    this.reqResult.emit({ showErrorMessage:true });
                    console.log(err); 
                });
            }

        }
    }

    showWarningMessage(message) {
        if (message == '') return;
        this.warningMessage = message;
        this._translateService.get(`DYNAMICFORMS.EDIT.VALIDATIONS.${message}`).subscribe(translate => this._notify.warning(translate));
    }

    // Translations
    _editTranslations(translation_type: TranslationType): void {
        this.translation_type = translation_type;
        this.edit_translation = true;
    }

    editFormTranslations(): void {
        this.translation_data = new Translation(
            this.str_name_de,
            this.str_name_es,
            this.str_name_fr,
            this.str_name_nl,
            this.str_name_pt,
            this.str_name_ru,
            this.str_name_ua,
            this.str_name_zh,
            this.str_name_ko,
            this.str_name_vi,
        );
        this._editTranslations(TranslationType.FORM);
    }

    editCategoryTranslations(): void {
        const category = this.categories[this.current_category];

        if (category == null) {
            this.translation_data = this.backup_translation_data;
        } else {
            this.translation_data = new Translation(
                this.categories[this.current_category]?.str_name_de || '',
                this.categories[this.current_category]?.str_name_es || '',
                this.categories[this.current_category]?.str_name_fr || '',
                this.categories[this.current_category]?.str_name_nl || '',
                this.categories[this.current_category]?.str_name_pt || '',
                this.categories[this.current_category]?.str_name_ru || '',
                this.categories[this.current_category]?.str_name_ua || '',
                this.categories[this.current_category]?.str_name_zh || '',
                this.categories[this.current_category]?.str_name_ko || '',
                this.categories[this.current_category]?.str_name_vi || '',                
            );
        }

        this._editTranslations(TranslationType.CATEGORY);
    }

    _getQuestionIndexFromOrder(int_order: number): number {
        const current_question = 
            this.categories[this.current_category]?.questions.indexOf(
                this.categories[this.current_category]?.questions.find(q => q.int_order == int_order)
            );
        return current_question == -1 ? null : current_question;
    }

    editQuestionTranslations(int_question_order: number): void {
        this.current_question = this._getQuestionIndexFromOrder(int_question_order);
        
        const question_obj = this.categories[this.current_category]?.questions[this.current_question];

        this.translation_data = (question_obj == null) ? this.backup_translation_data : this._getTextTranslationFromObj(question_obj);
        this._editTranslations(TranslationType.QUESTION);
    }

    editChoiceTranslations(param: { int_question_order: number, choice: number }): void {
        this.current_question = this._getQuestionIndexFromOrder(param.int_question_order);
        this.current_choice = param.choice;
        const choice_obj = this.arr_choices[this.current_choice];
        this.translation_data = (choice_obj == null) ? new Translation() : this._getTextTranslationFromObj(choice_obj);
        this._editTranslations(TranslationType.CHOICE);
    }

    editYesNoTranslations(translations: Translation[]) {
        translations.forEach( (translation, index) => {
            this.current_question = this._getQuestionIndexFromOrder(null);
            this.current_choice = index;
            this.translation_type = TranslationType.CHOICE;

            this.saveTranslation(translation);
        });  
    }

    _updateTextTranslationFromObj(obj, translation: Translation): any {
        obj.str_text_de = translation.str_de;
        obj.str_text_es = translation.str_es;
        obj.str_text_fr = translation.str_fr;
        obj.str_text_nl = translation.str_nl;
        obj.str_text_pt = translation.str_pt;
        obj.str_text_ru = translation.str_ru;
        obj.str_text_ua = translation.str_ua;
        obj.str_text_zh = translation.str_zh;
        obj.str_text_ko = translation.str_ko;
        obj.str_text_vi = translation.str_vi;
        return obj;
    }

    _getTextTranslationFromObj(obj): Translation {
        return new Translation(
            obj?.str_text_de || '',
            obj?.str_text_es || '',
            obj?.str_text_fr || '',
            obj?.str_text_nl || '',
            obj?.str_text_pt || '',
            obj?.str_text_ru || '',
            obj?.str_text_ua || '',
            obj?.str_text_zh || '',
            obj?.str_text_ko || '',
            obj?.str_text_vi || '',
        );
    }

    saveTranslation(translations: Translation = null) {
        switch (this.translation_type) {
            case TranslationType.FORM: 
                this.str_name_de = translations.str_de;
                this.str_name_es = translations.str_es;
                this.str_name_fr = translations.str_fr;
                this.str_name_nl = translations.str_nl;
                this.str_name_pt = translations.str_pt;
                this.str_name_ru = translations.str_ru;
                this.str_name_ua = translations.str_ua;
                this.str_name_zh = translations.str_zh;
                this.str_name_ko = translations.str_ko;
                this.str_name_vi = translations.str_vi;                
                this.cancelTranslation();
                break;
            case TranslationType.CATEGORY: 
                if (this.current_category == null) {                    
                    this.backup_translation_data = translations;
                    this.backup_translation_data = new Translation(); 
                    this.edited_translation = true;
                    this.edit_translation = false;                                    
                    this.createCategorySaveTranslation({str_text:this.str_name?this.str_name:""});
                    this.current_category = this.categories.length - 1;                                      
                }
                this.categories[this.current_category].str_name_de = translations.str_de;
                this.categories[this.current_category].str_name_es = translations.str_es;
                this.categories[this.current_category].str_name_fr = translations.str_fr;
                this.categories[this.current_category].str_name_nl = translations.str_nl;
                this.categories[this.current_category].str_name_pt = translations.str_pt;
                this.categories[this.current_category].str_name_ru = translations.str_ru;
                this.categories[this.current_category].str_name_ua = translations.str_ua;
                this.categories[this.current_category].str_name_zh = translations.str_zh;
                this.categories[this.current_category].str_name_ko = translations.str_ko;                
                this.categories[this.current_category].str_name_vi = translations.str_vi;                
                this.backup_translation_data = new Translation();
                this.cancelTranslation();
                break;
            case TranslationType.QUESTION: 
                if (this.current_question == null) {
                    this.edited_translation = true;
                    this.edit_translation = false;
                    this.backup_translation_data = translations;
                    return;
                } 
                this._updateTextTranslationFromObj(
                    this.categories[this.current_category].questions[this.current_question],
                    translations
                );
                this.backup_translation_data = new Translation();
                this.cancelTranslation();
                break;
            case TranslationType.CHOICE: 
                this._updateTextTranslationFromObj(
                    this.arr_choices[this.current_choice],
                    translations
                );
                this.edit_translation = false;
                this.translation_data = new Translation();
                this.translation_type = null;
                break;
        }
    }

    _removeBackupTranslation(): void {
        this.backup_translation_data = new Translation();
    }

    cancelTranslation(): void {
        this.edit_translation = false;
        this.edited_translation = false;
        this.translation_data = new Translation();
        this.translation_type = null;
    }

    // Categories
    editCategory(category_index: number = null): void {
        this.isEditingCategory = true;
        this.current_category = category_index;
        this.str_text = '';
        if (category_index != null) {
            this.cod_forms_category = this.categories[this.current_category].cod_forms_category;
            this.str_text = this.categories[this.current_category].str_name;
        }
    }

    _newCategory(name: string): Category {
        let c = new Category();
        c.questions = [];
        c.str_name = name;
        c.str_name_de = this.str_name_de;
        c.str_name_es = this.str_name_es;
        c.str_name_fr = this.str_name_fr;
        c.str_name_ko = this.str_name_ko;
        c.str_name_nl = this.str_name_nl;
        c.str_name_pt = this.str_name_pt;
        c.str_name_ru = this.str_name_ru;
        c.str_name_ua = this.str_name_ua;
        c.str_name_vi = this.str_name_vi;
        c.str_name_zh = this.str_name_zh;

        c.int_order = this.categories.length + 1;
        return c;        
    }

    createCategorySaveTranslation($event){
        this.categories.push(this._newCategory($event.str_text));
        this.current_category = this.categories.length - 1;
        if (this.edited_translation) {
            this.translation_type = TranslationType.CATEGORY;
            this.saveTranslation(this.backup_translation_data);
        }
    }

    saveCategory($event) {        
        if (this.current_category == null) {
           this.createCategorySaveTranslation($event);
        } else {
            this.categories[this.current_category].str_name = $event.str_text;
        }
        this.isEditingCategory = false;
        this.current_category = null;
        this.str_text = "";
        this.cod_forms_category = null;
    }

    cancelEditingCategory() {
        this.str_text = "";
        this.cod_forms_category = null;
        this.current_category = null;
        this.isEditingCategory = false;
        if (this.edited_translation) this.cancelTranslation();
        this._removeBackupTranslation();
    }

    toggleDeleteCategory(cod_category: number = null): void {
        this.awaitConfirmDeleteCategory = !this.awaitConfirmDeleteCategory;
        this.deleteCategoryId = cod_category;
    }

    deleteCategory() {
        this.categories.splice(this.deleteCategoryId, 1); 
        this.awaitConfirmDeleteCategory = false;
        this.deleteCategoryId = null;
    }

    expandCategory(index: number) {
        this.categories[index].expanded = !this.categories[index].expanded;
    }

    drop(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.arr_questions, event.previousIndex, event.currentIndex);
      this.orderQuestionsNumber();
    }
    dropCategory(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
        this.orderCategoriesNumber();
    }
    orderQuestionsNumber() {
      this.arr_questions = this.arr_questions.map((val, i) => {
        val.int_order = i + 1;
        return val;
      });
    }
    orderCategoriesNumber() {
        this.categories = this.categories.map((val, i) => {
          val.int_order = i + 1;
          return val;
        });
    }

    editQuestion(param: { category: number, question: Question }) {
        const cat = param.category!=null ? this.categories[param.category] : null
        if(cat) this.arr_questions_to_verify = cat.questions
        this.isEditingQuestion = true;
        this.current_category = param.category;
        if (param.question) {
            this.cod_form_question = param.question.cod_form_question;
            this.cod_forms_category = param.question.cod_forms_category;
            this.int_type = param.question.int_type;
            this.str_text = param.question.str_text;
            this.int_order = param.question.int_order;
            this.bol_creates_action = param.question.bol_creates_action;
            this.bol_optional = param.question.bol_optional;
            this.arr_choices = param.question.arr_choices;
        } else {
            this.cod_form_question = null;
            this.cod_forms_category = null;
            this.int_type = 0;
            this.str_text = '';
            this.int_order = null;
            this.bol_creates_action = false;
            this.bol_optional = false;
            this.arr_choices = [];
            this.backup_translation_data = new Translation();
        }
    }

    prepareToCopyQuestion(ev){
        this.current_category = ev.category;
        let newChoices = ev.question?.arr_choices ? [...ev.question.arr_choices] : []
        for(let c of newChoices){
            c.cod_form_question_choice = null
        }
        let objToSend = {
            'cod_form_question': null,
            'str_text': ev.question.str_text,
            'int_type': ev.question.int_type,
            'int_order': null,
            'bol_creates_action': ev.question.bol_creates_action,
            'bol_optional': ev.question.bol_optional,
            'arr_choices': newChoices
        }
        let translations = new Translation()
        translations.str_de = ev.question.str_text_de
        translations.str_es = ev.question.str_text_es
        translations.str_fr = ev.question.str_text_fr
        translations.str_nl = ev.question.str_text_nl
        translations.str_pt = ev.question.str_text_pt
        translations.str_ru = ev.question.str_text_ru
        translations.str_ua = ev.question.str_text_ua
        translations.str_zh = ev.question.str_text_zh
        translations.str_ko = ev.question.str_text_ko
        translations.str_vi = ev.question.str_text_vi
        this.backup_translation_data = translations;
        this.edited_translation = true;
        this.saveQuestion(objToSend)
    }
    onUpdateChoices(ev){
        if(ev?.arr_choices){
            this.arr_choices = ev.arr_choices
        }
    }
    saveQuestion(ev) {
        if (ev.int_order) {
            this.categories[this.current_category].questions = this.categories[this.current_category].questions.map(i => {
                if (i.int_order === ev.int_order) {
                    i.int_type = ev.int_type;
                    i.str_text = ev.str_text;
                    i.bol_creates_action = ev.bol_creates_action;
                    i.bol_optional = ev.bol_optional;
                    i.arr_choices = ev.arr_choices;
                }
                return i;
            });
        } else {
            this.categories[this.current_category].questions.push({
                cod_form_question: null,
                cod_forms_category: this.categories[this.current_category].cod_forms_category,
                int_order: this.getNextQuestionOrder(),
                int_type: ev.int_type,
                str_text: ev.str_text,
                bol_creates_action: ev.bol_creates_action,
                bol_optional: ev.bol_optional,
                arr_choices: ev.arr_choices,
            });
            this.current_question = this.categories[this.current_category].questions.length - 1;
        }
        if (this.edited_translation) {
            this.translation_type = TranslationType.QUESTION;
            this.saveTranslation(this.backup_translation_data);
        }
        this.current_category = null;
        this.current_question = null;
        this.isEditingQuestion = false;
    }

    deleteQuestion() {
        this.categories[this.current_category].questions = 
            this.categories[this.current_category].questions.filter(i => i.int_order !== this.deleteFormId.int_order);
        this.isEditingQuestion = false;
        this.awaitConfirmDeleteQuestion = false;
    }

    toggleDeleteQuestion(obj: { category: number, question: Question } = null) {
        
        if( obj?.question?.int_type==8 && 
            obj.category!=null &&             
            this.categories[obj.category]?.questions?.filter(x=>x.int_type==8).length==1 && 
            this.categories[obj.category].questions.find(x=>x.int_type==9)){
                this.showBlockingPopUp = true;
                return
        }

        this.current_category = (obj?.category > -1) ? obj.category : this.current_category;
        this.awaitConfirmDeleteQuestion = !this.awaitConfirmDeleteQuestion;
        this.deleteFormId = obj?.question || null;
    }

    cancelEditingQuestion() {
        this.isEditingQuestion = false;
        this.cod_form_question = 0;
        this.str_text = '';
        this.bol_creates_action = false;
        this.int_type = 0;
        this._removeBackupTranslation();
    }

    goBackToListing() {        
        this.backSelected();
    }

    loadForEditing() {
        if (isNaN(this.cod_form)) {
            return;
        }
        this.reset();
        this.service.get(this.cod_form, 0, this.upload_api_url, this._authService.getAuthenticatedUser().id,).subscribe(form => {
            this.str_name = form.content.str_name;
            this.str_name_de = form.content.str_name_de;
            this.str_name_es = form.content.str_name_es;
            this.str_name_fr = form.content.str_name_fr;
            this.str_name_nl = form.content.str_name_nl;
            this.str_name_pt = form.content.str_name_pt;
            this.str_name_ru = form.content.str_name_ru;
            this.str_name_ua = form.content.str_name_ua;            
            this.str_name_zh = form.content.str_name_zh;            
            this.str_name_ko = form.content.str_name_ko;            
            this.str_name_vi = form.content.str_name_vi;            
            this.str_hashtag = form.content.str_hashtag;
            this.bol_use_previous_answers = form.content.bol_use_previous_answers;
            this.categories = form.content.categories || [];

            this.listAux = {
                'worlds': form.content.cod_zone,
                'regionals': form.content.cod_regionals,
                'locations': form.content.cod_locations
            }

            this._searchAll(this.listAux);
            
            this.isLoading = false;

            this.form_hyperlinks = form.content.hyperlinks;

            // change file extension
            this.form_hyperlinks.map(it => {
                it.str_click_link = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it.str_link));
            });

            this.arr_links = [];
            this.arr_links_desc = [];
            this.arr_cod_links_del = [];
        },
        err => { this._authService.errCheck(err);
            console.log(err); 
            this.hasError = err;
            this.isLoading = false;
            console.error('Failed to load dynamic forms details!', err); }
        );
    }

    getNextQuestionOrder() {
        const orders = this.categories[this.current_category].questions.map(i => i.int_order).concat(0);
        const max = Math.max.apply(null, orders);
        const next = max + 1;
        return next;
    }

    getCodFormQuestionFromString(str) {
        const match = REGEX_QUESTION_ID.exec(str);
        if (!match) {
            return -1;
        }

        return +match[1];
    }

    findQuestionListItem(i) {
        if (!i?.className) {
            return false;
        }

        const classNames = i.className.toString().split(' ');
        return classNames.includes('questions-list__item');
    }

    reset() {
        this.isLoading = true;
        this.hasError = false;
        this.str_name = null;
        this.arr_questions = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadForEditing();
        this.isEditing = !isNaN(this.cod_form) && this.cod_form > 0;
    }

    backSelected() {
        this.cod_form = 0;
        this.backEvent.emit(this.cod_form);
    }
    closeBlockPopUp(){
        this.showBlockingPopUp = false
      }
    countChars() {
        if(this.str_name?.length == 120) {
            this.messageCountChars = true;
        } 

        setTimeout(() => {
            this.messageCountChars = false;
        }, 4000);
    }

    countHashtags(){
        if(this.str_hashtag?.length == 120) {
            this.messageCountChars = true;
        } 

        if((this.str_hashtag?.match(/#/g)||[]).length > 1){
            this.messageTooMuchHashtags = true;
        }

        setTimeout(() => {
            this.messageTooMuchHashtags = false;
        }, 4000);
    }

    addLink() {
        if (this.str_link) {
            const pattern = /^https?:\/\/.+/; // check if starts with https://
            const pattern2 = /^file:\/\/\/?.+/; // check if starts with file:///
            const pattern3 = /^\\\\.+/; // check if starts with \\
            const pattern4 = /^[a-zA-Z]:(\/|\\).+/; // check if starts with C:/
            this.str_link = this.str_link.replace(/^[^\x00-\x7F]/gmu, ""); 
            if (!pattern.test(this.str_link)) { // if doesn't start with https://

                if (!pattern2.test(this.str_link)) { // if doesn't start with file:///

                    if (pattern3.test(this.str_link) || pattern4.test(this.str_link)) { // if starts with either \\ or C:/
                        this.str_link = 'file:///' + this.str_link; // then is a file:/// link
                    } else { // else is a normal https:// link
                        this.str_link = 'https://' + this.str_link;
                    }

                }// if already starts with file:/// do nothing
            }// if already starts with https:// do nothing

            this.form_hyperlinks.push({
                cod_routines_hyperlinks: null,
                str_link: this.str_link,
                str_link_desc: this.str_link_desc
            });
        }
        this.str_link = '';
        this.str_link_desc = '';
    }

    removeLink(index) {
        if (this.form_hyperlinks[index]['cod_forms_hyperlinks'] != null) {
            this.arr_cod_links_del.push(this.form_hyperlinks[index]['cod_forms_hyperlinks']);
        }
        this.form_hyperlinks.splice(index, 1);
    }

    copyLink(str_hyperlink, i, copy = false) {
        const pattern = /^https?:\/\/.+/;

        if (!pattern.test(str_hyperlink) || copy === true) {
            this.copyToClipboard(str_hyperlink);
            this.linkIdx = i;
            clearTimeout(this.link_timeout);
            this.link_timeout = setTimeout(() => {
                this.linkIdx = -1;
            }, 3000);
        }
    }

    copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    downloadExtension(file) {
        const extension = file.split('.').pop();
        let newFile = file;

        // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
        switch (extension) {
          case 'xls':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'xlsx':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'xlsm':
            newFile = `ms-excel:ofe|u|${file}`;
            break;            
          case 'csv':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'doc':
            newFile = `ms-word:ofe|u|${file}`;
            break;
          case 'docx':
            newFile = `ms-word:ofe|u|${file}`;
            break;
          case 'ppt':
            newFile = `ms-powerpoint:ofe|u|${file}`;
            break;
          case 'pptx':
            newFile = `ms-powerpoint:ofe|u|${file}`;
            break;
          default:
            break;
        }
        // REPLACING BACKWARD SLASH TO FORWARD SLASH TO FIX WRONG LOCAL URL
        newFile = newFile.replace(/\\/g, '/');
        return newFile;
    }

    selectWorlds($event) {
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals_selected'] = $event.selectedOptions;
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

     selectLocations($event) {      
       let eventSanitizer = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
       this.paramsSelected['locations_selected'] = eventSanitizer;
       this.paramsSelected['locations'] = this._implodeValues(eventSanitizer, 'cod_location');
     }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
         this.paramsSelected['regionals'] = '';
         this.paramsSelected['regionals_selected'] = [];
         this.regionalList = [];
         this._clearLocationsList();
    }

    private _clearLocationsList() {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.locationsList = [];
    }


    private _searchWorlds(fn) {
        this._clearWorldList();
        this.loadingWorld = true;
        this._userService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_FORMS_CHANGE').subscribe(data => {
            this.worldList = this._userService.getArrayContent(data);
            this.loadingWorld = false;
            if (typeof fn != typeof undefined && fn) {
                fn();
            }
        },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    private _searchRegionals(fn) {
        this._clearRegionalList();

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this.loadingRegionals = true;
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_FORMS_CHANGE').subscribe(data => {
                this.regionalList = this._userService.getArrayContent(data);
                this.loadingRegionals = false;
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _searchLocations(fn) {
        if (typeof this.paramsSelected['regionals'] != typeof undefined && this.paramsSelected['regionals'].toString() != '') {
            let params = this.paramsSelected['regionals'].toString().split(',');
            this.loadingLocations = true;
            this._userService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_FORMS_CHANGE').subscribe(data => {
                this.locationsList = this._userService.getArrayContent(data);
                this.loadingLocations = false;
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            }, err => {
                this._authService.errCheck(err);
                console.log(err);
            });
        } else {
            this._clearLocationsList();
        }

    }

    private _searchAll(listAux) {
        const $this = this;     
        $this._searchWorlds(() => {
            $this.paramsSelected['worlds_selected'] = [{cod_locations_world: listAux.worlds}];
            $this.paramsSelected['worlds'] = listAux.worlds;
            $this._searchRegionals(() => {
                $this.paramsSelected['regionals_selected'] = listAux.regionals;
                $this.paramsSelected['regionals'] = this._implodeValues(listAux.regionals, 'cod_locations_regional');
                $this._searchLocations(() => {
                    $this.paramsSelected['locations_selected'] = listAux.locations;
                    $this.paramsSelected['locations'] = this._implodeValues(listAux.locations, 'cod_location');
                });
            });
        });
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }

}
