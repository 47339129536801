import {Injectable} from '@angular/core';
import {ListPillarsMainService} from './list-pillars-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListPillarsService {
  
  constructor(private _mainService: ListPillarsMainService) {
  }
  
  getPillarsList(param): Observable<any> {
    let path = '/forms/pillars?term=' + param.term + '&cod_user=' + param.cod_user;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
