import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pillars-management',
  templateUrl: './pillars-management.component.html',
  styleUrls: ['./pillars-management.component.css']
})
export class PillarsManagementComponent implements OnInit {

  constructor() { 


  }

  ngOnInit() {
  }

}
