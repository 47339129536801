import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';
import { UserPreference } from 'app/user-preferences/models/user-preference.model';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import { UserPreferencesComponent } from 'app/user-preferences/user-preferences/user-preferences.component';


@Component({
  selector: 'app-selected-time-zone',
  templateUrl: './selected-time-zone.component.html',
  styleUrls: ['./selected-time-zone.component.css']
})
export class SelectedTimeZoneComponent implements OnInit {

  public userPreference: UserPreference;
  selectedTz = [];
  cod_tz: number;
  codigoTz: any;
  bol_error_submit = true
  listTimeZone = [];

  constructor(
    private _userPreferencesService: UserPreferencesService,
    private _userPreferencesComponent: UserPreferencesComponent,
    private _authService: AuthService) {
      
    this.userPreference = new UserPreference();
    this.userPreference.cod_user = this._authService.getAuthenticatedUser().id;
  }

  saveTimeZone(){
    let obj_timezone = {
      cod_user: this.userPreference.cod_user,
      val_timezone: this.cod_tz
    };
    this._userPreferencesService.postUserTimezone(obj_timezone).subscribe(result => {
      if (result.code === 666) {
        console.log('Error seting timezone',result);
      } else {
        this._userPreferencesService.setLocalTimeZone(this.cod_tz);
      }
      this._userPreferencesComponent.closeModal('modal-selected-time-zone'); 
    },err => {
      this._authService.errCheck(err);
      console.log(err);
    });
  }

  closeTimeZone() {
    this._userPreferencesComponent.closeModal('modal-selected-time-zone');
  }

  timeZoneChanged($event){
    this.selectedTz = $event.selectedOptions;
    this.cod_tz = this.selectedTz[0].cod_tz;
  }

  ngOnInit() {
    var found = false;
    this._userPreferencesService.getTimezonesList(this.userPreference.cod_user).subscribe(data => {
      this.listTimeZone = this._userPreferencesService.getArrayContent(data);
      this._userPreferencesService.getUserPreferences(this.userPreference.cod_user).subscribe(data => {
        this.codigoTz = this._userPreferencesService.getArrayContent(data);
        this.codigoTz.forEach(element => {
          if(element.str_pref_key == "timezone" ){
            this.listTimeZone.map(data => {
              if (data.cod_tz == element.str_pref_value) {
                this.selectedTz = [data];
                found = true;
              }
            });
          }
        });
        if (!found) {
          let obj_timezone = {
            cod_user: this.userPreference.cod_user,
            val_timezone: -(new Date().getTimezoneOffset() / 60)
          };
          this.listTimeZone.map(data => {
            if (data.cod_tz == obj_timezone.val_timezone) {
              this._userPreferencesService.postUserTimezone(obj_timezone).subscribe(result => {
                if (result.code === 666) {
                  console.log('Error seting timezone',result);
                } else {
                  this.selectedTz = [data];
                  this._userPreferencesService.setLocalTimeZone(data.cod_tz);
                }
              }, err => {
                this._authService.errCheck(err);
                console.log(err);
              });
            }
          });
        }
      });
    });
  }

}