import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {forkJoin, map, Observable} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MeetingsTypesService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all logbook areas.
     * @param param: JSON object filled with search "term" or "id"
     */
    // getMeetingsTypes(limit, offset, str_term, str_path, cod_user, dsc_page_privilege, cod_worlds): Observable<any> {// A_M, A_U, get, muitos
    getMeetingsTypes(limit, offset, str_term, str_path, cod_user, dsc_page_privilege): Observable<any> {// A_M, A_U, get, muitos
        const path = `/meetings-types-by-name/?limit=${limit}&offset=${offset}&str_term=${str_term}&str_path=${str_path}&cod_user=${cod_user}&dsc_page_privilege=${dsc_page_privilege}`; //&cod_worlds=${cod_worlds}`;
        return this._mainService.get(path);
    }

    getMeetingType(cod_meeting_type, cod_user): Observable<any> {
        const path = `/v2/${cod_user}/${cod_meeting_type}/meeting-type`
        return this._mainService.get(path);
    }

    // getMeetingsTypeByCode(cod_meetings_types): Observable<any> {
    //     const path = `/meetings-types/${cod_meetings_types}`;
    //     return this._mainService.get(path);
    // }

    postNewMeetingsType(params): Observable<any> {// A_M, só admin, ok
        const path = '/meetings-types';        
        return this._mainService.post(path, params);
    }

    updateMeetingsType(params): Observable<any> {// A_M, só admin, ok
        const path = `/meetings-types/${params.cod_meetings_types}`;
        return this._mainService.put(path, params);
    }

    deleteMeetingsType(params): Observable<any> {// A_M, só admin, ok
        const path = `/meetings-types/${params.cod_meetings_types}/${params.cod_usu_del}`;
        return this._mainService.delete(path);
    }

    getPeriodicities(): Observable<any> {
        const path = '/periodicities';
        return this._mainService.get(path);
    }

    postMeetingsTypesIndicators(params): Observable<any> {
        const path = `/meetings-types-indicators`;
        return this._mainService.post(path, params);
    }

    postMeetingsTypesLocationsWorld(params): Observable<any> {
        const path = `/meetings-types-world`;
        return this._mainService.post(path, params);
    }

    deleteMeetingsTypeWorldIndicator(params): Observable<any> {
        const path = `/meetings-types-world-indicators/${params.cod_meeting_types}/${params.cod_usu_ins}`;
        return this._mainService.delete(path);
    }

    getMeetingsTypesIndicators(cod_meeting_types, cod_user): Observable<any> {// A_M, só admin
        const path = `/meetings-types-indicators/${cod_meeting_types}/${cod_user}`;
        return this._mainService.get(path);
    }

    getMeetingsTypesLocations(cod_meeting_types, cod_user): Observable<any> {// A_M, só admin
        const path = `/meetings-types-world/${cod_meeting_types}/${cod_user}`;
        return this._mainService.get(path);
    }

    getMeetingTorDetails(cod_meeting, cod_user): Observable<any> {
        const path = '/meeting-tor-details/'+ cod_meeting + '/' + cod_user;
        return this._mainService.get(path);
    }

    saveCustomTorDetails(params): Observable<any> {
        const path = '/meeting-tor-details-edit'
        return this._mainService.post(path, params);
    }

    revertCustomTorRevision(params): Observable<any> {
        const path = '/meeting-tor-revert-revision'
        return this._mainService.post(path, params);
    }

    deleteCustomMeetingType(params): Observable<any> {
        const path = '/meeting-tor-details-delete'
        return this._mainService.post(path, params);
    }
    saveMeetingTypeAgenda(params): Observable<any> {
        const path = '/meetings-types-agenda' ;
        return this._mainService.post(path, params);  
    }

    listMeetingTypeAgenda(params): Observable<any>{
        const path = '/meetings-types-list-agenda';      
        return this._mainService.post(path, params);
    }

    saveCustomAgenda(params): Observable<any>{
        const path = '/meetings-custom-types-agenda';
        return this._mainService.post(path, params);
    }

    listCustomAgenda(params): Observable<any>{
        const path = '/meetings-custom-types-list-agenda';
        return this._mainService.post(path, params);
    }

    deleteCustomAgenda(params): Observable<any>{
        const path = '/meetings-delete-custom-agenda';
        return this._mainService.post(path, params);
    }

    meetingsTypeChangesTor(params): Observable<any>{
        const path = '/meetings-custom-types-update-changes-tor';
        return this._mainService.post(path, params);
    }

    meetingsTypeSearchRefresh(params): Observable<any>{
        const path = '/meetings-custom-types-search-refresh-tor';
        return this._mainService.post(path, params);
    }

    meetingsTypeHasChanges(params): Observable<any>{
        const path = '/meetings-types-has-changes';
        return this._mainService.post(path, params);
    }

    meetingsTypeUpdateHasChanges(params): Observable<any>{
        const path = '/meetings-types-update-has-changes';
        return this._mainService.post(path, params);
    }

    getMeetingTypesByMeetingLocation(params): Observable<any> {// A_M, muitos
        const path = '/get-meeting-types-by-meetings-location';
        return this._mainService.post(path, params);
      }

    getArrayContent(data: any) {
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }

    getAgendaCategories(cod_user: BigInteger, str_userlang: string) {
        const path = `/get-meeting-type-agenda-categories/${cod_user}/${str_userlang}`;
        return this._mainService.get(path);
    }

    getMeetingsStandardTypes(cod_user: BigInteger) {
        const path = `/get-meetings-standard-types/${cod_user}`;
        return this._mainService.get(path);
    }

    getMeetingsTypesSearch(str_term, str_path, cod_user, dsc_page_privilege, cod_worlds): Observable<any> {
        const path = `/get-meetings-types/?str_term=${str_term}&str_path=${str_path}&cod_user=${cod_user}&dsc_page_privilege=${dsc_page_privilege}&cod_worlds=${cod_worlds}`;
        return this._mainService.get(path);
    }

    formatDuration(duration: number = 0) {
        let hours = (duration / 60) >> 0
        let minutes = duration % 60 >> 0
        let tag = 'MEETING_TYPES_LIST.DURATION.'
        let placeholder = '';
        if(hours == 0){
            tag += 'MINUTES';
            placeholder = `${minutes}min`;
        } else if(minutes == 0){
            tag += 'HOURS';
            placeholder = `${hours}h (${duration}min)`;
        } else {
            tag += 'HOURS_AND_MINUTES';
            placeholder = `${hours}h${minutes}min (${duration}min)`; // Starts with placeholder durations in case translations fail
        }
        return { duration, placeholder, tag, hours, minutes };
    }

    getDurationsArray(_translateService: TranslateService, shortLen: number = 8, longLen: number = 20) {
        let durations = [...Array.from({ length: shortLen }, (_, index) => (1+index) * 15),...Array.from({ length: longLen }, (_, index) => (shortLen*15) + (1+index) * 30)];//each 15min up to 120, then each 30min, total 12h range
        let durationsData = durations.map(duration => this.formatDuration(duration));

        return forkJoin(
            durationsData.map((data) => // Create array of subscriptions
                _translateService.get(data.tag, { duration: data.duration, hours: data.hours, minutes: data.minutes }).pipe( // Pass interpolation params to each translation
                    map((res) => { // Construct final object
                        let desc = res;
                        if (res.includes('MEETING_TYPES_LIST')) {
                            desc = data.placeholder // Keep placeholder if translation fails
                        }
                        return { int_duration: data.duration, str_description: desc };
                    })
                )
            )
        );
    }

}
