import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-logbook-topic-2',
  templateUrl: './logbook-topic-2.component.html',
  styleUrls: ['./logbook-topic-2.component.css']
})
export class LogbookTopic2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
