// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-box {
    margin-top: 10px;
}

.active {
    background-color: var(--ui-primary) !important;
    color: var(--text-ui-primary) !important;
}

.action-tab {
    background-color: var(--ui-inverted);
    color: var(--text-ui-inverted);
    width: 135px;
    height: 65px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/admin/admin.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,wCAAwC;AAC5C;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".admin-box {\n    margin-top: 10px;\n}\n\n.active {\n    background-color: var(--ui-primary) !important;\n    color: var(--text-ui-primary) !important;\n}\n\n.action-tab {\n    background-color: var(--ui-inverted);\n    color: var(--text-ui-inverted);\n    width: 135px;\n    height: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
