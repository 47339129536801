import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthService } from '../../main/access/services/auth.service';
import { UserPreferencesService } from './../../user-preferences/services/user-preferences.service';
import { AcadiaIntegrationService } from '../services/acadia-integration.service';
import { NotificationService } from 'app/main/services/notification.service';
import { Routine } from '../models/routine.model';
import { RoutineDataService } from '../services/routine-data.service';
import { RoutinesService } from './../services/routines.service';
import * as _ from 'lodash';

@Component({
    selector: 'routine-acadia-buttons',
    templateUrl: './routine-acadia-buttons.component.html',
    styleUrls: ['./routine-acadia-buttons.component.css']
})
export class RoutineAcadiaButtonsComponent implements OnInit, OnChanges {

    @Output() onChangeStatus: EventEmitter<number> = new EventEmitter();
    @Output() onNeedLrpu: EventEmitter<Function> = new EventEmitter();

    @Input() cod_shifts: any = null;
    @Input() dateSelected: any = null;

    isCollab: boolean = false;
    isPastDue: boolean;
    isFutureDue: boolean;
    routine: Routine = null;
    creatingTaskList: boolean = false;
    refreshingTaskList: boolean = false;

    showCreateButton: boolean = false;
    showOpenButton: boolean = false;
    showChart: boolean = false;
    showStatusButton: boolean = false;

    chartStyle: any = {
        pointFormat: 20,
        innerSize: 38,
        width: 95,
        height: 95,
        fontSize: 10,
        percentMarginTop: 15,
        percentMarginLeft: 10
    };
    chartData: any = null;

    constructor(
        private _authService: AuthService,
        private _userService: UserPreferencesService,
        private _notify: NotificationService,
        private _dataService: RoutineDataService,
        private _acadiaIntegrationService: AcadiaIntegrationService
    ) {
        this._dataService.routine.subscribe(x => { 
            this.routine = x; 
            this.refresh(); 
        });
        this._dataService.isCollab.subscribe(x => this.isCollab = x);
        this._dataService.isPastDue.subscribe(x => this.isPastDue = x);
        this._dataService.isFutureDue.subscribe(x => this.isFutureDue = x);        
    }

    ngOnInit() {
        this.refresh();
    }

    ngOnChanges(changes) {
        this.refresh();
    }

    private refresh() : void {
        if (this.routine == null) return;
        this.showCreateButton = (!this.isPastDue && !this.isFutureDue && this.routine.date_valid && !this.routine.bol_acadia_task_list_created);
        this.showOpenButton = (!this.routine.bol_acadia_task_list_finished && !this.isPastDue && !this.isFutureDue && this.routine.date_valid && this.routine.bol_acadia_task_list_created);
        this.showStatusButton = (!this.routine.bol_acadia_task_list_finished && !this.isPastDue && !this.isFutureDue && this.routine.date_valid && this.routine.bol_acadia_task_list_created);
        this.showChart = this.routine.bol_acadia_task_list_created;
        this.buildChartData();
    }

    public callCreateTaskList() : void {
        if (this.creatingTaskList) return;

        this.creatingTaskList = true;

        if (!(this.routine.cod_logbook_routines_periodicity_users > 0)) {
            this.onNeedLrpu.emit(cod_lrpu => this.createTaskList(cod_lrpu));
        } else {
            this.createTaskList();
        }
    }

    private getParams(addionalParams: any = null, cod_lrpu: number = null) : any {
        let params = {
            'acadia_document': this.routine.str_acadia_document,
            'cod_routine': this.routine.cod_routines,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'global_id': this._authService.getAuthenticatedUser().ambev_id,
            'user_language': this._userService.getLocalLanguage(),
            'dat_selected': this.dateSelected,
            'cod_logbook': this.routine.cod_logbook
        };

        if (this.isCollab) {
            params['source'] = 'collaborative';
            params['cod_logbook_routines_periodicity_users_collaborative'] = cod_lrpu || this.routine.cod_logbook_routines_periodicity_users;
            params['cod_shift'] = this.cod_shifts;
        } else {
            params['source'] = 'functional';
            params['cod_logbook_routines_periodicity_users'] = cod_lrpu || this.routine.cod_logbook_routines_periodicity_users;
        }

        return addionalParams ? {...params, ...addionalParams} : params;
    }

    public createTaskList(cod_lrpu: number = null) : void {
        const params = this.getParams({acadia_document: this.routine.str_acadia_document}, cod_lrpu);

        this._acadiaIntegrationService.createTaskList(params).subscribe(data => {
            if (data.type === "success") {

                // UPDATE ROUTINE WITH ACADIA DATA
                this._dataService.changeRoutine(_.pick(data.content, [
                    'cod_acadia', 
                    'str_acadia_task_list_url',
                    'bol_acadia_task_list_created',
                    'int_acadia_tasks_count',
                    'int_acadia_completion_count',
                    'int_acadia_skipped_count',
                    'int_acadia_completion_percent',
                    'str_acadia_document_language',
                    'bol_acadia_document_language_equals_ial_language',
                ]));

                this.changeStatusEmitter(this.routine.cod_routine_status);
                this.openTaskList(data.content.str_acadia_task_list_url);
                this.refresh();
            } else {
                const errorMessage = data.content ? data.content : "Request Error.";
                this._notify.error(errorMessage);
            }
        }, err => {
            const e = JSON.parse(err._body);
            this._notify.error(e.content);
            this._authService.errCheck(err);
        }, () => this.creatingTaskList = false);
    }

    public openTaskList(link: string = null) {
        this._acadiaIntegrationService.openAcadiaTaskList(link || this.routine.str_acadia_task_list_url);
    }

    public checkTaskListStatus(callback = null) : void {
        const params = this.getParams({cod_acadia: this.routine.cod_acadia});

        this._acadiaIntegrationService.checkTaskListStatus(params).subscribe(data => {
            if (data.type == "success") {

                const cod_routine_status = data.content.bol_acadia_task_list_finished 
                    ? data.content.cod_routine_status 
                    : this.routine.cod_routine_status;

                // UPDATE ROUTINE WITH ACADIA DATA
                this._dataService.changeRoutine({
                    ..._.pick(data.content, [
                        'int_acadia_tasks_count',
                        'int_acadia_completion_count',
                        'int_acadia_skipped_count',
                        'int_acadia_completion_percent',
                        'bol_acadia_task_list_finished',
                    ]), 
                    ...{ 'cod_routine_status': cod_routine_status }
                });

                this.changeStatusEmitter(cod_routine_status);
                this.refresh();

            } else {
                this._notify.error(data.content);
            }
        }, err => {
            const e = JSON.parse(err._body);
            this._notify.error(e.content);
            this._authService.errCheck(err);
        }, () => {
            if (callback != null) callback();
        });
    }

    private changeStatusEmitter(status: number) : void {
        this.onChangeStatus.emit(status);
    }

    private buildChartData() : void {
        if (this.routine.int_acadia_completion_count != null) {
            this.chartData = [{
                color: 'rgb(50, 215, 120)',
                name: '',
                y: this.routine.int_acadia_completion_count
            },
            {
                color: 'rgb(230, 255, 40)',
                name: '',
                y: this.routine.int_acadia_skipped_count
            },
            {
                color: 'rgb(155,155,155)',
                name: '',
                y: (this.routine.int_acadia_tasks_count - this.routine.int_acadia_completion_count - this.routine.int_acadia_skipped_count)
            }];
        }
    }

}
