import { HttpResponse } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/main/access/services/auth.service";
import { Observable, of } from "rxjs";
import { debounceTime, map, withLatestFrom } from "rxjs/operators";
import CockpitFilter from "../model/cockpit-filter.model";
import { MainService } from "./main.service";
import { CockpitService } from "./cockpit.service";
import { environment } from "environments/environment";
import * as moment from "moment";
import { CockpitShiftService } from "./cockpit.shift.service";

@Injectable()
export default class CockpitFilterService {
  arrayKeys = [
    "locations",
    "areas",
    "subareas",
    "equipments",
    "machines",
    "checklists",
  ];

  constructor(
    @Optional() private _authService: AuthService,
    private _mainService: MainService,
    private _cockpitService: CockpitService,
    private readonly shiftService: CockpitShiftService,
    private router: Router
  ) {}

  public listenToFilterChanges(): void {
    this._cockpitService.cockpitFilters
      .pipe(debounceTime(environment().debounceTime * 4))
      .subscribe(
        async (res) =>
          await this.triggerAllPersistMethods(CockpitFilter.fromPartial(res))
      );
  }

  public async loadFilter(): Promise<CockpitFilter> {
    try {
      const cod_user = this._authService.getAuthenticatedUser().id;

      const promises = [
        this.getFromUrlParams().toPromise(),
        this.getFromLocalStorage().toPromise(),
      ];

      for (const promise of promises) {
        const res: Partial<CockpitFilter> = await promise;
        if (!res) continue;
        if (!this.hasValidData(res)) continue;
        return CockpitFilter.fromPartial(res);
      }

      const res = await this.getInitialFilterData({
        cod_user,
        deeplink: null,
        getLists: false,
      })
        .toPromise()
        .then((r) => r?.results?.[0]?.cockpit_filters_bootstrap?.values);

      if (!res) return new CockpitFilter();
      if (!this.hasValidData(res)) return new CockpitFilter();
      return CockpitFilter.fromPartial(res);
    } catch (e) {
      console.error(e);
      return new CockpitFilter();
    }
  }

  public getFromLocalStorage(): Observable<Partial<CockpitFilter>> {
    const filter = localStorage.getItem("cockpitFilter");
    return of(JSON.parse(filter || "{}"));
  }

  public getFromUrlParams(): Observable<Partial<CockpitFilter>> {
    const params = new URLSearchParams(window.location.search);

    const value: Partial<CockpitFilter> = {};
    value.view = params.get("view") || "operator";
    this.arrayKeys.forEach(
      (key) => (value[key] = params.get(key) ? params.get(key)?.split(",") : [])
    );

    const shift$ = this.shiftService.futureAndPastShiftsFor7DaysRange$.pipe(
      map((shifts) => shifts.find((shift) => shift.id === params.get("shift")))
    );

    value.machineName = [params.get("machineName")];
    value.selectedDate =
      params.get("selectedDate") || moment().format("YYYY-MM-DD");
    return of(value).pipe(
      withLatestFrom(shift$),
      map(([value, shift]) => {
        return {
          ...value,
          shift,
        };
      })
    );
  }

  public hasValidData(value: Partial<CockpitFilter>): boolean {
    let valid = true;
    this.arrayKeys.slice(0, 2).forEach((key) => {
      if (value[key]?.length && value[key][0]?.toString().length > 0)
        valid = true;
      else valid = false;
    });
    return valid;
  }

  public async triggerAllPersistMethods(
    value: Partial<CockpitFilter>
  ): Promise<void> {
    value.removeInvalid();
    await this.setRemoteFilter(value).toPromise();
    value.removeFields();
    this.saveToLocalStorage(value);
    this.updateUrl(value);
  }

  public setRemoteFilter(
    value: Partial<CockpitFilter>
  ): Observable<HttpResponse<CockpitFilter>> {
    if (!this.hasValidData(value)) return of(null);
    const params = this.mapArraysToString(value);
    return this._mainService
      .post("/set-user-last-options", params)
      .pipe(debounceTime(environment().debounceTime * 4));
  }

  public saveToLocalStorage(value: Partial<CockpitFilter>): void {
    localStorage.setItem("cockpitFilter", JSON.stringify(value));
  }

  public updateUrl(filter: Partial<CockpitFilter>): void {
    const queryParams = filter.toUrlParams();
    this.router.navigate([], { queryParams, replaceUrl: true });
  }

  public mapArraysToString(value: Partial<CockpitFilter>) {
    const payload = { ...value };
    this.arrayKeys.forEach(
      (key) =>
        (payload[key] = Number(
          ["number", "string"].includes(typeof value[key])
            ? value[key]
            : value[key]?.join()
        ))
    );
    return payload;
  }

  public merge(
    obj1: Partial<CockpitFilter>,
    obj2: Partial<CockpitFilter>
  ): CockpitFilter {
    const result = {};

    const isValid = (value) => {
      if (Array.isArray(value)) return value.length > 0;
      return value;
    };

    Object.keys(obj2).forEach((key) => {
      const [value1, value2] = [obj1[key], obj2[key]];
      const [valid1, valid2] = [isValid(value1), isValid(value2)];
      if (valid1 && valid2) {
        result[key] = value1;
      } else if (valid1 && !valid2) {
        result[key] = value1;
      } else if (!valid1 && valid2) {
        result[key] = value2;
      } else {
        result[key] = value2;
      }
    });

    return CockpitFilter.fromPartial(result);
  }

  public getInitialFilterData(value: {
    cod_user: string;
    deeplink: CockpitFilter;
    getLists: boolean;
  }): Observable<{
    results: {
      cockpit_filters_bootstrap: {
        values: { [key: string]: any };
        lists: { [key: string]: any[] };
      };
    }[];
  }> {
    return this._mainService.post("/get-initial-filter-data", value);
  }
}
