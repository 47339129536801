import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import { AreaService } from 'app/logbooks/services/area.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-subareas',
    templateUrl: './list-subareas.component.html',
    styleUrls: ['./list-subareas.component.css']    
})

export class ListSubAreasComponent implements OnChanges, OnInit {
    
    subAreasList: any = [];        
    
    @Input() isDisabled: Boolean;
    @Input() isMultiple: Boolean;
    @Input() areasSelected: any[];
    @Input() selectedValue: any[];
    @Output() selectedValueChange = new EventEmitter<any[]>();
    @Output() blur = new EventEmitter<any>();
    
    constructor(private _areaService: AreaService, private _authService:AuthService) {        
        this.areasSelected = [];
    } 
    
    ngOnInit(){
        this.searchSubAreas();        
        if(!this.selectedValue){
            this.selectedValue = [];
        }        
    }
    
    ngOnChanges(changes: SimpleChanges) {      
        const areasSelected : SimpleChange = changes.areasSelected;
        
        if(areasSelected && areasSelected.currentValue){        
            
            if(areasSelected.currentValue.length > 0){
                this.searchSubAreas();
            }
            
        }
        
    }    
    
    selectSubAreas($event) {
        this.selectedValue = $event.selectedOptions;        
        this.selectedValueChange.emit(this.selectedValue);
        this.blur.emit();
    }
    
    
    searchSubAreas() {
        if(this.areasSelected){
            if (this.areasSelected.length > 0) {
                let objParam = {
                    'cod_area': this.implodeValues(this.areasSelected, 'cod_areas'),
                    'str_term': null,
                    'cod_user': this._authService.getAuthenticatedUser().id,
                    'dsc_page_privilege':'MEETING_SELECT_SUBAREA'
                };
                this._areaService.getSubAreasByAreaPrivilege(objParam).subscribe(data => {
                        this.subAreasList = this._areaService.getArrayContent(data);
                    },
                    err => {
                        this.subAreasList = [];
                        console.log(err);
                    }
                );
            }
        }

    }    
    
    
    implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }     
    
}
