import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListRegionalsService} from './services/list-regionals.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-regionals',
    templateUrl: './list-regionals.component.html',
    styleUrls: ['./list-regionals.component.css']
})

export class ListRegionalsComponent implements OnInit {

    regionalsList: any = [];
    obj_ta_eq  ;
    bol_show_typeahead_regionals: Boolean = true;
    str_user_tmp = '';
    cod_component_pillars: number;

    @Input() question;
    @Input() isMultiple: Boolean;
    @Input() disabled: boolean;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _ListRegionalsService: ListRegionalsService, private _authService:AuthService) { 

    }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.regionalsList = [{ cod_answer_option: this.question.cod_answer_option, str_name: this.question.str_answer_text || this.question.answer  }];    
            this.bol_show_typeahead_regionals = false;
        }
        this.loadRegionals();
    }

    loadRegionals() {
        this.obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_locations_regional',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.regionalsList.length === 0) {
                    this.regionalsList.push({
                        cod_locations_regional: obj_selected.cod_locations_regional,
                        str_name: obj_selected.str_name,
                    });
                    if(this.question !== null) {
                        this.question.str_answer_text = obj_selected.str_name;
                        this.question.cod_answer_option = obj_selected.cod_locations_regional;    
                        this.onChange.emit(this.question);
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                    this.bol_show_typeahead_regionals = false;
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchRegionals(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };
    }

    searchRegionals(cb, value) {
        this._ListRegionalsService.getRegionalsList({
            'cod_user': this._authService.getAuthenticatedUser().id,
            'term': value ,
            'cod_location_world': this.question.cod_world
        }).subscribe((data: any) => {
            cb(this._ListRegionalsService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delRegionals() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;    
        this.onChange.emit(this.question);

        this.regionalsList = [];
        this.cod_component_pillars = null;
        this.bol_show_typeahead_regionals = true;
    }
       
}
