import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

export enum ToastType {
    Error = 'toast-error',
    Info = 'toast-info',
    Success = 'toast-success',
    Warning = 'toast-warning'
}

type TranslateParam = { text: string, translate: boolean };
type TextParam = string | TranslateParam;

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

    private readonly _config: IndividualConfig = {
        easing: 'ease-in',
        easeTime: 300,
        timeOut: 5000,
        extendedTimeOut: 1000,
        disableTimeOut: false,
        enableHtml: false,
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        onActivateTick: false,
        newestOnTop: true,
        toastClass: 'ngx-toastr',
        positionClass: 'toast-top-right',
        titleClass: 'toast-title',
        messageClass: 'toast-message',
    }

    constructor(
        private readonly _toastr: ToastrService,
        private readonly _translate: TranslateService,
    ) { }

    success(message: TextParam, title: TextParam = "", config: any = null): void {
        this.show(message, title, ToastType.Success, config);
    }

    info(message: TextParam, title: TextParam = "", config: any = null): void {
        this.show(message, title, ToastType.Info, config);
    }

    error(message: TextParam, title: TextParam = "", config: any = null): void {
        this.show(message, title, ToastType.Error, config);
    }

    warning(message: TextParam, title: TextParam = "", config: any = null): void {
        this.show(message, title, ToastType.Warning, config);
    }

    async show(message: TextParam, title: TextParam, type: string = ToastType.Success, config: any = null): Promise<void> {
        config = this._mergeConfig(config);

        try {
            message = await this._processText(message);
            title = await this._processText(title);   
            this._toastr.show(message, title, config, type);         
        } catch (error) { }
    }

    async _processText(text: TextParam): Promise<string> {
        if (typeof text == 'string') return text;

        return await this._translateText(text);
    }

    async _translateText(tp: TranslateParam): Promise<string> {
        if (tp.translate) {
            return await firstValueFrom(this._translate.get(tp.text));
        }
        return tp.text;
    }

    _mergeConfig(config: any) {
        return {...this._config, ...config };
    }

}