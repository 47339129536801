// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#reports-routine-filter {
    height: 285px;
}

.period-row {
    padding: 0;
}

.period-row input {
    margin-bottom: 0;
}

.btn-search{
    background: var(--ui-primary);
    border-radius: 4px;
    display: inline-block;
    color: var(--text-ui-primary);
    line-height: 34px;
    padding: 0 12px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 30px;
}

.bt-seach-row {
    height: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.reports-table-container {
    padding: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/reports-routines-online/reports-routines-online.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,eAAe;IACf,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#reports-routine-filter {\n    height: 285px;\n}\n\n.period-row {\n    padding: 0;\n}\n\n.period-row input {\n    margin-bottom: 0;\n}\n\n.btn-search{\n    background: var(--ui-primary);\n    border-radius: 4px;\n    display: inline-block;\n    color: var(--text-ui-primary);\n    line-height: 34px;\n    padding: 0 12px;\n    cursor: pointer;\n    font-size: 15px;\n    box-shadow: rgb(0 0 0 / 15%) 0px 2px 30px;\n}\n\n.bt-seach-row {\n    height: 56px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.reports-table-container {\n    padding: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
