import {Injectable} from '@angular/core';
import {ListAreasTaMainService} from './list-areas-ta-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListAreasTaService {
  
  constructor(private _mainService: ListAreasTaMainService) {
  }
  
  getAreasTaList(param): Observable<any> {

    let path = '/areas?term=' + param.term + '&cod_user=' + param.cod_user + '&dsc_page_privilege=' + param.dsc_page_privilege;
    if (param.cod_areas > 0) {
      path = '/area/' + param.cod_areas;
    }

    if (param.cod_location > 0) {
      path += '/location/' + param.cod_location;
    }
    
    return this._mainService.get(path);
  }

  getAreasByUserLocation(params): Observable<any>{
    let path = '/get-areas-by-user-location';
    return this._mainService.post(path, params);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
