import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "./main/access/services/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { SocketService } from "./main/services/socket.service";
import { filter, takeUntil, repeat, throttleTime, fromEvent, Subject, Observable, Subscription } from "rxjs";
import { environment } from "environments/environment";
import Hotjar from '@hotjar/browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { PromptValue } from '@azure/msal-common';
import { AuthStatus } from "./main/access/models/auth-event.model";
import { datadogRum } from "@datadog/browser-rum";

const siteId = 3368430;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  userAuthenticated: boolean;
  showVersionWarning = false;
  date = new Date();

  mouseMoveSubscription: Subscription;
  mouseScrollSubscription: Subscription;
  timeout: ReturnType<typeof setTimeout>;
  refreshTime: number;
  shouldUserLogout = false;
  disposalSubject: Subject<number> = new Subject();

  private readonly _destroying$ = new Subject<void>();
  isIframe = false;

  canOpenNpsFeedback = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    public router: Router,
    private readonly _authService: AuthService,
    private readonly _socketService: SocketService,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    // private ssoAngularService: SsoAngularService
  ) {
    router.events.subscribe(() => {
      this.loadComponents();
    });
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        const url = _event.urlAfterRedirects;
        const currentUser = datadogRum.getUser() || {};
        datadogRum.setUser({
          ...currentUser,
          id: null,
          access: {
            current_route: url,
            deliver_value: (url.includes('task') || url.includes('meetings') || url.includes('problem-terminator') || url.includes('admin'))
          },
          session: {
            access: {
              access_date: new Date().toISOString(),
            }
          }
        });

        this._authService.checkVersion().subscribe((response) => {
          if (response.code === 888) {
            if (
              environment().version != response.content.version &&
              this.date.getTime() -
                JSON.parse(localStorage.getItem("versionWarningTimer")) >
                1000 * 3600
            ) {
              this.showVersionWarning = true;
            }
          }
        });
      });

    this.mouseMoveSubscription = this.whileHovering().subscribe(() =>
      this.updateTimeToLive()
    );
    this.mouseScrollSubscription = this.whileScrolling().subscribe(() =>
      this.updateTimeToLive()
    );

    this.subscribeToUserLogout();
  }

  ngOnInit(): void {
    this.setTheme();

    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        //   this.msalService.instance.getActiveAccount(),
        //   // JSON.parse(localStorage.getItem(Object.keys(localStorage).find( k => k.includes('idtoken')))).secret,
        //   // this._authService.getAuthenticatedUser()
        // );
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
    });

    const events: EventType[] = [
      EventType.LOGOUT_START,
      EventType.LOGOUT_SUCCESS,
      EventType.LOGOUT_FAILURE,
      EventType.LOGOUT_END
    ];

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => events.includes(msg.eventType)
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if(result.eventType == EventType.LOGOUT_SUCCESS){
          this.router.navigate(['/access']);
        }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  switchAccount() {
    this.msalService.instance.setActiveAccount(null);
    this._authService.deleteAuthenticatedUser(false);
    this.msalService.instance.loginRedirect({
      ...this.msalGuardConfig.authRequest,
      prompt: PromptValue.LOGIN,
    } as RedirectRequest);
  }

  checkAndSetActiveAccount() {
    // If no active account set but there are accounts signed in, sets first account to active account
    let activeAccount = this.msalService.instance.getActiveAccount();
    let accounts = this.msalService.instance.getAllAccounts();

    const groups = activeAccount?.idTokenClaims?.groups as string[]
    if (groups?.find(group => group.includes('APP ROLE-NAZ-Acadia-No Auto Login'))) {

      if(accounts.length > 0){
        // add your code for handling multiple accounts here
        let account = accounts.find(acc => {
          const groups = acc.idTokenClaims.groups as string[]
          if (groups.find(group => !group.includes('APP ROLE-NAZ-Acadia-No Auto Login'))){
            return true
          }
          return false
        })
        this.msalService.instance.setActiveAccount(account || null);
        activeAccount = this.msalService.instance.getActiveAccount();
      }
    }

    if(accounts.length > 0) {
      if (!activeAccount){
        this.switchAccount()
      } else {
        this.msalService.instance.acquireTokenSilent({ // gets tokens from cache if present, requests from MSAL if not
          scopes: [`${environment().azureActiveDirectoryClientId}/.default`]
        }).then(response => {
          //debug // console.log('MSAL TOKEN SILENT', response)
          const idToken = response.idToken
            ?? JSON.parse(localStorage.getItem(Object.keys(localStorage).find( k => k.includes('idtoken'))))?.secret;

          this.getUserAuth(idToken, activeAccount);
        }).catch(err => {
          // alternate/fallback way of getting idToken
          const idToken = JSON.parse(localStorage.getItem(Object.keys(localStorage).find( k => k.includes('idtoken'))))?.secret;
          this.getUserAuth(idToken, activeAccount);
        });
      }
    }
  }

  getUserAuth(idToken, activeAccount): void {
    if(!!idToken && !this._authService.isAuthenticated() && !!activeAccount){

      this._authService.getAuthEventSubject().next({
        type: AuthStatus.ONGOING
      });

      // msal account valid, but no IAL data present, do call IAL endpoint
      this._authService.getUserAuth(idToken).subscribe({
        next: (data) => {
          if (
            data.content?.sso_session_index &&
            data.content?.userSession?.id &&
            !data.content.error
          ) {
            this._authService.setAuthenticatedUser(data.content.userSession);

            if ( environment().env != '__env__') {
              this.setDatadogUser();              
            }

            this.setLanguage(data.content.userSession.userlang);
            this.loadComponents();

            this._authService.getAuthEventSubject().next({
              type: AuthStatus.SUCCESS,
              data: data
            });
          } else {
            this._authService.getAuthEventSubject().next({
              type: AuthStatus.USER_NOT_FOUND,
              data: data,
              error: {
                msg: 'Error or missing user data from response',
                error: data.content.error,
              }
            });
          }
        },
        error: (err) => {
          console.log('GET USER AUTH ERROR', err);
          // could instead send to .error() and change how subscribers deal with it
          this._authService.getAuthEventSubject().next({
            type: AuthStatus.FAILURE,
            error: err
          });
        },
      });
    }
    else{
      this.setDatadogUser();
    }
  };

  setDatadogUser(){
    const now = new Date();
    const loginDate = `${String(now.getUTCMonth() + 1).padStart(2, '0')}/${String(now.getUTCDate()).padStart(2, '0')}/${now.getUTCFullYear()} ${String(now.getUTCHours()).padStart(2, '0')}:${String(now.getUTCMinutes()).padStart(2, '0')}:${String(now.getUTCSeconds()).padStart(2, '0')}`;
    const currentUser = datadogRum.getUser() || {};
    const User = this._authService.getAuthenticatedUser();
    datadogRum.setUser({
      ...currentUser,
      center_sap: User.cod_sharp,
      product: 'InteractionLog',
      id: User.objectId,
      uniqueid: User.objectId,
      user: {
        site: User.name_location,
        zone: User.name_world,
        role: User.role,
        login_date: loginDate,
      }
    });
  }

  setLanguage(userlang: string) {
    localStorage.setItem('userPreferencesLanguage', userlang);
  }

  setTheme() {
    document.body.classList.toggle('light-theme');
  }

  closeWarning(reload) {
    this.showVersionWarning = false;

    if (reload) {
      window.location.reload();
    }
  }

  loadComponents() {
    this.userAuthenticated = this._authService.isAuthenticated();

    //ESTABILISH CONNECTION TO SOCKET AND SUBSCRIBE USER TO OWN CHANNEL
    if(this.msalService.instance.getActiveAccount()) {
      this._socketService.connectSocket(cb => {
        this.getAuthToken(token => {
          cb({token});
        })
      });
    }
  }

  public getAuthToken(next){
    this.msalService.instance.acquireTokenSilent({ // gets tokens from cache if present, requests from MSAL if not
      scopes: [`${environment().azureActiveDirectoryClientId}/.default`]
    }).then(response => {
      if(response.accessToken){
        next(response.accessToken);
      } else{
        // alternate/fallback way of getting accessToken
        const accessToken = JSON.parse(localStorage.getItem(Object.keys(localStorage).find( k => k.includes('accesstoken'))))?.secret;
        next(accessToken);
      }
    }).catch(err => {
      // alternate/fallback way of getting accessToken
      const accessToken = JSON.parse(localStorage.getItem(Object.keys(localStorage).find( k => k.includes('accesstoken'))))?.secret;
      next(accessToken);
    });
  }

  public whileHovering(): Observable<MouseEvent> {
    const enter = fromEvent<MouseEvent>(document, "mousemove");
    const leave = fromEvent<MouseEvent>(document, "mouseleave");

    return enter.pipe(
      throttleTime(environment().debounceTime),
      takeUntil(leave),
      repeat()
    );
  }

  public whileScrolling(): Observable<WheelEvent> {
    const scroll = fromEvent<WheelEvent>(document, "wheel");
    return scroll.pipe(throttleTime(environment().debounceTime), repeat());
  }

  public updateTimeToLive(): void {
    clearTimeout(this.timeout);
    const oneHour = 60 * 60 * 1000;
    this.timeout = setTimeout(
      () => this.setUserLogout(),
      this.refreshTime || oneHour
    );
  }

  public subscribeToUserLogout() {
    this.refreshTime = 4 * 60 * 60 * 1000; //4 hours
    this.updateTimeToLive();
  }

  public setUserLogout(): void {
    this.shouldUserLogout = true;
    this.mouseMoveSubscription.unsubscribe();
    this.mouseScrollSubscription.unsubscribe();
    this.disposalSubject.next(1);
    this.disposalSubject.complete();

    //logout
    this._authService.logout().subscribe({
      next: () => {
        // NOTHING
      },
    });
  }

}
