import {Component, OnInit} from '@angular/core';
import {UsersProfilesService} from '../services/users-profiles.service';
import {AuthService} from '../../main/access/services/auth.service';
import {UsersProfiles} from './models/UsersProfiles.model';
import {MeetingsTypesService} from '../../meetings/services/meetings-types.service';
import {UsersService} from '../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from "./../../main/services/notification.service";
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-users-profiles',
    templateUrl: './users-profiles.component.html',
    styleUrls: ['./users-profiles.component.css']
})
export class UsersProfilesComponent implements OnInit {

    profiles = [];
    worlds = [];
    selectedWorlds = [];
    selectedLevels = [];
    levels=[];
    meetingTypes = [];
    filter = "";
    profile = null;
    actualMiniTab = "privillege-screen";
    bol_loading_list: boolean = false;

    bol_loading_meeting_types: boolean = false;

    privilegesScreen = [];

    privilegesReport = [];

    user: any;
    model: UsersProfiles;

    strMundo:string;
    strRegional:string;
    strUnidade:string;
    bol_loading_meetings_types: boolean = false;
    arr_meetings_types: any = [];
    awaitConfirmDelete: boolean = false;
    errorMessage: any = {};
    screenFound:boolean = false;
    reportFound:boolean = false;
    timeToHideMessage: number;
    private timeToHideMessageConstant: number;

    searchTerm: string;
    searchTimer: any;

    strUserType: string;
    strTypeDescription: string;
    types = [];
    private allUserTypes: any[];

    worldSelected: any[];
    levelSelected: any[];

    constructor(
        private _usersProfilesService: UsersProfilesService, 
        private _translateService: TranslateService, 
        private _authService: AuthService, 
        private _meetingsTypesService: MeetingsTypesService, 
        private _notify: NotificationService,
        private _usersService:UsersService,
        private sanitizer: DomSanitizer,
    ) {
        this.user = this._authService.getAuthenticatedUser();
        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 3000;
        this.searchTerm = '';
    }

    ngOnInit() {
        this.loadPrivilegesScreen();
        this.loadPrivilegesReport();

        this._translateService.onLangChange.subscribe(() => this.loadLevels());


        this.loadWorlds();
        this.loadMeetingTypes();
        this.listProfiles();
        this.loadLevels();

        this.newRecord();

    }

    loadLevels() {
        this._translateService.get('USERS.WORLD').subscribe(translate => {
            this.strMundo = translate;
        });

        this._translateService.get('USERS.REGIONAL').subscribe(translate => {
            this.strRegional = translate;
        });

        this._translateService.get('USERS.LOCATION').subscribe(translate => {
            this.strUnidade = translate;
        });

        this.levels=[
            {
                str_level:"WORLD",
                str_name:this.strMundo
            },
            {
                str_level:"REGIONAL",
                str_name:this.strRegional
            },
            {
                str_level:"UNITY",
                str_name:this.strUnidade
            }
        ];
    }

    changeMiniTab(tabName) {
        this.actualMiniTab = tabName;
    }

    newRecord() {
        this.model = new UsersProfiles();
        this.worldSelected = [];
        this.levelSelected = [];
    }

    addPrivilegeScreen() {

        this.model.arr_profiles_screens.push({
            cod_privileges_screens: -1,
            arr_privileges: [],
            arr_locations_worlds: [],
            arr_permission_level: [this.levels[0]]
        });
    }

    addPrivilegeReport() {

        this.model.arr_profiles_reports.push({
            cod_privileges_reports: -1,
            arr_meetings_types: [],
            arr_reports: [],
            arr_locations_worlds: [],
            arr_permission_level: [this.levels[0]]
        });

    }

    deletePrivilegeScreen(i) {
        this.errorMessage.screen.splice(i, 1);
        this.model.arr_profiles_screens.splice(i, 1);
    }

    deletePrivilegeReport(i) {
        this.errorMessage.report.splice(i, 1);
        this.model.arr_profiles_reports.splice(i, 1);
    }

    loadProfile(usersProfiles: UsersProfiles) {
        
        // Clear variables
        this.errorMessage = {};
        this.errorMessage.err = '';
        this.errorMessage.screen = [];
        this.errorMessage.report = [];
        this.screenFound = false;
        this.reportFound = false;

        
        // get single profile
        this._usersProfilesService.getSingleProfile(this._authService.getAuthenticatedUser().id, usersProfiles.cod_profiles).subscribe(data => {
            this.model = data.content.results;
            this.worldSelected = this.worlds.filter(elem => elem.cod_locations_world == this.model.cod_world);
            this.levelSelected = this.levels.filter(elem => elem.str_level == this.model.str_level);
        },
        err => {
            this.bol_loading_meetings_types = false;
            this._authService.errCheck(err);
            console.log(err);
        });

        this.bol_loading_meetings_types = true;
        this._usersProfilesService.getUserProfileMeetingsTypes({cod_user:this._authService.getAuthenticatedUser().id, cod_profiles: usersProfiles.cod_profiles}).subscribe(data =>{
            if(data.code === 888 && data.content.code===888){
                this.arr_meetings_types = data.content.results.arr_meetings_types;
                this.bol_loading_meetings_types = false;
            }
            else{
                this.bol_loading_meetings_types = false;
            }
        },
        err =>{
            this._authService.errCheck(err);
            console.log(err);            
        });
    }

    listProfiles() {

        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        $this.searchTimer = setTimeout(function () {

            $this._usersProfilesService.getProfiles($this.filter == '' ? '--' : $this.filter, $this._authService.getAuthenticatedUser().id, 'USERS_PERFIL_LISTING').subscribe(data => {
                    $this.bol_loading_list = false;
                    $this.profiles = $this._usersProfilesService.getArrayContent(data);
                },
                err => {
                    $this._authService.errCheck(err);
                    console.log(err);
                }
            );
            $this.bol_loading_list = true;

        }, environment().debounceTime);

    }

    loadPrivilegesScreen() {
        this._usersProfilesService.getPrivilegesScreen(this.user.id).subscribe(data => {
                this.privilegesScreen = this._usersProfilesService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    loadPrivilegesReport() {
        this._usersProfilesService.getPrivilegesReport(this.user.id).subscribe(data => {
                this.privilegesReport = this._usersProfilesService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    loadMeetingTypes() {
        this.bol_loading_meeting_types = true;
        this._meetingsTypesService.getMeetingsTypesSearch('', '', this._authService.getAuthenticatedUser().id, 'ADMIN_MEETING_SELECT_TYPE', null).subscribe(data => {
                this.bol_loading_meeting_types = false;
                this.meetingTypes = this._usersProfilesService.getArrayContent(data);
            },
            err => {
                this.bol_loading_meeting_types = false;
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    setPrivilegesScreen(privileges, i) {
        this.model.arr_profiles_screens[i].arr_privileges = privileges.selectedOptions;
    }

    setPrivilegesReport(privileges, i) {
        this.model.arr_profiles_reports[i].arr_reports = privileges.selectedOptions;
    }

    // setJobs(jobs) {
    //     this.model.arr_jobs = jobs.selectedOptions;
    // }

    setLevelReport(permission, i){
        this.model.arr_profiles_reports[i].arr_permission_level = permission.selectedOptions;
    }

    setLevelScreen(permission, i){
        this.model.arr_profiles_screens[i].arr_permission_level = permission.selectedOptions;
    }

    setMeetingsTypesReport(reports, i) {
        let selectedOptions = reports.selectedOptions;
        this.model.arr_profiles_reports[i].arr_meetings_types = selectedOptions;
    }

    setWorldReport(worlds,i){
        this.model.arr_profiles_reports[i].arr_locations_worlds = worlds.selectedOptions;
    }

    setWorldScreen(worlds,i){
        this.model.arr_profiles_screens[i].arr_locations_worlds = worlds.selectedOptions;
    }

    loadWorlds() {
        this._usersProfilesService.getWorlds(this._authService.getAuthenticatedUser().id, 'USERS_PERFIL_LISTING').subscribe(data => {
                this.worlds = this._usersProfilesService.getArrayContent(data);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }    

    submit() {

        this.model.cod_user = this.user.id;

        // Clear variables
        this.errorMessage = {};
        this.errorMessage.err = '';
        this.errorMessage.screen = [];
        this.errorMessage.report = [];
        this.screenFound = false;
        this.reportFound = false;
        this.errorMessage.privilege_screen = this.errorMessageErr('privilege_screen');
        this.errorMessage.privilege_report = this.errorMessageErr('privilege_report');

        if (this.model.cod_profiles > 0) {

            this._usersProfilesService.putProfile(this.model).subscribe(data => {
                this._handleSaveSuccess(data, false);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });

        } else {

            this._usersProfilesService.postProfile(this.model).subscribe(data => {
                this._handleSaveSuccess(data, true);
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            });

        }

    }

    _handleSaveSuccess(data: any, isNew: boolean = false): void {
        if (data.code === 666) {
            this.showErrors(data);
        } else {
            this.newRecord();
            this.listProfiles();
            this.timeToHideMessage = this.timeToHideMessageConstant;
            if (isNew)
                this._notify.success({text: 'USERS.PROFILE_INSERTED', translate: true});
            else
                this._notify.success({text: 'USERS.PROFILE_EDITED', translate: true});
        }
    }

    hideMessage() {
        this.timeToHideMessage = 0;
    }

    showErrors(data) {
        let field = this.errorMessageErr('field');
        if (data.content.error && data.content.error.details.length > 0) {
            this.errorMessage.err = this.errorMessageErr(data.content.error.details[0].path[0]);
        }
        if (data.content.message == 'str_level_validate' && data.content.message != undefined) {
            this.errorMessage.err = this.errorMessageErr('str_level_validate');
        }

        if (data.content.message == 'str_privilege' && data.content.message != undefined) {
            this.errorMessage.err = this.errorMessageErr('str_privilege');
        }

        for (var i = 0; i < data.content.report.length; i++) {
            var aux = '';
            var req = '';
            for (var j = 0; j < data.content.report[i].length; j++) {
                if (data.content.report[i][j]) {
                    if (j > 0) aux += ', ';
                    aux += this.errorMessageErr(data.content.report[i][j]);
                }
            };
            if (aux) {
                this.reportFound = true;
                req = data.content.report[i].length > 1 ? this.errorMessageErr('are_required') : this.errorMessageErr('is_required');
                this.errorMessage.report.push(field + ' ' + aux + ' ' + req);
            } else {
                this.errorMessage.report.push('');
            }
        }
        for (var i = 0; i < data.content.screen?.length; i++) {
            var aux = '';
            var req = '';
            for (var j = 0; j < data.content.screen[i].length; j++) {
                if (data.content.screen[i][j]) {
                    if (j > 0) aux += ', ';
                    aux += this.errorMessageErr(data.content.screen[i][j]);
                }
            };
            if (aux) {
                this.screenFound = true;
                req = data.content.screen[i].length > 1 ? this.errorMessageErr('are_required') : this.errorMessageErr('is_required');
                this.errorMessage.screen.push(field + ' ' + aux + ' ' + req);
            } else {
                this.errorMessage.screen.push('');
            }
        }

        if (this.errorMessage.err)
            this._notify.warning(this.errorMessage.err);

        if (this.errorMessage.screen) {
            this.errorMessage.screen.forEach(err => {
                this._notify.warning(err);                
            });
        }

    }

    errorMessageErr(err) {
        var message;
        var translation;
        switch (err) {
            case 'field':
                message = 'USERS.VALIDATE_FIELDS.FIELDS';
                break;
            case 'is_required':
                message = 'USERS.VALIDATE_FIELDS.IS_REQUIRED';
                break;
            case 'are_required':
                message = 'USERS.VALIDATE_FIELDS.ARE_REQUIRED';
                break;
            case 'str_name':
                message = 'USERS.VALIDATE_FIELDS.NAME';
                break;
            case 'cod_world':
                message = 'USERS.VALIDATE_FIELDS.ZONE';
                break;
            case 'arr_privileges':
                message = 'USERS.PRIVILEGE_PROFILES';
                break;
            case 'arr_locations_worlds':
                message = 'USERS.WORLDS';
                break;
            case 'arr_permission_level':
                message = 'USERS.LEVEL';
                break;
            case 'arr_reports':
                message = 'USERS.REPORTS';
                break;
            case 'privilege_screen':
                message = 'USERS.VALIDATE_FIELDS.PRIVILEGE_SCREEN';
                break;
            case 'privilege_report':
                message = 'USERS.VALIDATE_FIELDS.PRIVILEGE_REPORT';
                break;
            case 'str_level':
                message = 'USERS.VALIDATE_FIELDS.PROFILE_LEVEL_REQUIRED';
                break;
            case 'str_level_validate':
                message = 'USERS.PROFILE_LEVEL';
                break;
            case 'str_privilege':
                message = 'USERS.PRIVILEGE_LEVEL';
                break;
            default:
                return err;
        }
        this._translateService.get(message).subscribe(translate => {
            translation = translate;
        });
        return translation;
    }

    deleteProfile() {

        this.profile.cod_user = this.user.id;

        this._usersProfilesService.deleteProfile(this.profile).subscribe(data => {
                if (data.code === 666) {
                    alert(data.content[0].message);
                    return;
                }
                this.timeToHideMessage = this.timeToHideMessageConstant;
                this._notify.success({text: 'USERS.PROFILE_DELETED', translate: true});
                this.newRecord();
                this.listProfiles();

            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
        this.awaitConfirmDelete = !this.awaitConfirmDelete;

    }

    public toggleDeleteProfile(profile = null) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.profile = profile;
    }

    selectWorld($event) {
        this.selectedWorlds = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.model.cod_world = this.selectedWorlds[0].cod_locations_world;
    }

    selectLevel($event) {
        this.selectedLevels = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.model.str_level = this.selectedLevels[0]?.str_level;
    }

}
