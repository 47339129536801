import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FiveWhyService } from '../services/five-why.service';



@Component({
  selector: 'app-status-flow',
  templateUrl: './status-flow.component.html',
  styleUrls: ['./status-flow.component.css']
})
export class StatusFlowComponent implements OnInit {
  
  @Input("isLoading") isLoading: boolean;
  @Input("StatusLog") StatusLog: {
    log: any,
    date_ins: string,
    isClosed: boolean,
    dat_canceled: string,
    isCanceled: boolean,
    str_name_usu_canceled: string,
    currentStatus: number
  };

  statusColor: string = '';
  rootCauseFoundIcon: string = '../../../assets/images/gold-medal.svg';
  abReportIcon: string = '../../../assets/images/report-icon.svg';
  cancelIcon: string = '../../../assets/images/cancel-icon.svg';
  completedIcon: string = '../../../assets/images/check-icon.svg';


  constructor(private _fiveWhyService: FiveWhyService) { }


  ngOnInit() {
    // this.StatusLog.log.forEach((status, index) => {
    //   status.color = this._fiveWhyService.getStatusColor(this.StatusLog.isCanceled, this.StatusLog.currentStatus, this.StatusLog.log, index + 1);
    // });

  }
}
