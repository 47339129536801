import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { UploadService } from '../../main/services/upload.service';
import { AuthService } from "../../main/access/services/auth.service";
import IAttachment from "./attachments.model";

@Component({
    selector: 'attachments-gallery',
    templateUrl: './attachments-gallery.component.html',
    styleUrls: ['./attachments-gallery.component.css'],
})
export class AttachmentsGallery {

    @Input() attachments: Array<IAttachment> = [];

    loading: boolean = false;

    constructor(
        private _uploadService:UploadService, 
        private _authService: AuthService,
    ) { }

    downloadImage(attachment: IAttachment) {
        
        this._uploadService.downloadFile(attachment.url_image).subscribe(res => {
            //DOWNLOAD BLOB FILE
            let obj_blob = new Blob([res]);

            var a = document.createElement('a');
            a.href = URL.createObjectURL(obj_blob);
            a.download = attachment.name_image;
            document.body.appendChild(a);
            //only stop loading after
            a.onclick = e => {
                window.addEventListener('focus', e=>{
                    URL.revokeObjectURL(a.href);
                    this.loading = false;
                }, {once: true});
            };
            a.click();
            document.body.removeChild(a);

        }, err => {
            this._authService.errCheck(err);
            console.log(err);
            this.loading = false;
        });
    }
    
}
