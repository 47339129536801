// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    overflow-y: auto;
    max-height: 90px;
    max-width: 300px;
}

.td-actions { 
    text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/shift/shift-list/shift-list.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["div.scrollable {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    display: block;\n    overflow-y: auto;\n    max-height: 90px;\n    max-width: 300px;\n}\n\n.td-actions { \n    text-align: end;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
