import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationsService} from '../services/validations/validations.service';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {EquipmentService} from '../services/equipment.service';
import {Equipment} from '../models/equipment.model';
import {UsersService} from '../../admin/services/users.service';

@Component({
    selector: 'app-equipments-persist',
    templateUrl: './equipments-persist.component.html',
    styleUrls: ['./equipments-persist.component.css']
})
export class EquipmentsPersistComponent implements OnInit, OnChanges {

    @Input('codEquipment') id;
    @Output() backEvent = new EventEmitter<Object>();
    @ViewChild('equipmentPersistForm', { static: true }) equipmentFormContainer: ElementRef;

    equipment: Equipment;
    isLoading: boolean;
    cod_user: number = this._authService.getAuthenticatedUser().id;
    showError: boolean = false;
    errorMessage: string = '';
    worldList: any = [];
    regionalList: any = [];
    locationsList: any = [];
    paramsSelected: any = [];
    loadingConfig: any = [];

    constructor(private _equipmentService: EquipmentService, private _route: ActivatedRoute, private _router: Router,
                public _validationService: ValidationsService, private _authService: AuthService,
                private _translateService: TranslateService, private _userService: UsersService) {
        this.equipment = new Equipment();
        this.showError = false;
        this.errorMessage = '';
        this.paramsSelected = [];
        this.loadingConfig = [];
        this.loadingConfig['worlds'] = false;
        this.loadingConfig['regionals'] = false;
        this.loadingConfig['locations'] = false;
    }

    ngOnInit(): void {
        this.get();
        this._searchWorlds(null);
        this._clearWorldList();
    }

    private _clearWorldList() {
        this.paramsSelected['worlds'] = '';
        this.paramsSelected['worlds_selected'] = [];
        this.worldList = [];
        this._clearRegionalList();
    }

    private _clearRegionalList() {
        this.paramsSelected['regionals'] = '';
        this.paramsSelected['regionals_selected'] = [];
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];
        this.regionalList = [];
        this.locationsList = [];
    }

    private _searchWorlds(fn) {
        this._clearWorldList();
        this._setLoading('worlds', true);
        this._userService.getWorlds(this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
                this.worldList = this._userService.getArrayContent(data);
                this._setLoading('worlds', false);
                if (typeof fn != typeof undefined && fn) {
                    fn();
                }
            },
            err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        );
    }

    private _searchLocations(fn) {
        this.paramsSelected['locations'] = '';
        this.paramsSelected['locations_selected'] = [];

        if (this.paramsSelected['regionals'].toString() != '') {
            this._setLoading('locations', true);
            let params = this.paramsSelected['regionals'].toString().split(',');
            this._userService.getLocations(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
                    this.locationsList = this._userService.getArrayContent(data);
                    this._setLoading('locations', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _searchRegionals(fn) {
        this._clearRegionalList();

        if (typeof this.paramsSelected['worlds'] != typeof undefined && this.paramsSelected['worlds'].toString() != '') {
            this._setLoading('regionals', true);
            let params = this.paramsSelected['worlds'].toString().split(',');
            this._userService.getRegionals(params, this._authService.getAuthenticatedUser().id, 'ADMIN_LOGBOOKS_AREA_SELECT_WORLDS').subscribe(data => {
                    this.regionalList = this._userService.getArrayContent(data);
                    this._setLoading('regionals', false);
                    if (typeof fn != typeof undefined && fn) {
                        fn();
                    }
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _setLoading(field, isLoading) {
        this.loadingConfig[field] = isLoading;
    }

    selectWorlds($event) {
        this.paramsSelected['worlds'] = this._implodeValues($event.selectedOptions, 'cod_locations_world');
        this._searchRegionals(null);
    }

    selectRegionals($event) {
        this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
        this._searchLocations(null);
    }

    selectLocations($event) {
        this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
    }

    private _emptyDefaultValue(value, defaultValue) {
        if (typeof value !== typeof undefined && value) {
            return value;
        }
        return defaultValue;
    }

    private _implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }


    get() {
        this.isLoading = true;
        if (this.id <= 0) {
            this.equipment = new Equipment();
            this.isLoading = false;
        } else {

            let objParam = {
                'cod_equipments': this.id,
                'term': '',
                'limit': null,
                'offset': null,
                'dsc_page_privilege': 'NOT_PRIVILEGE',
                'cod_user': this.cod_user
            };
            this._equipmentService.getList(objParam).subscribe(data => {
                    this.equipment = data.content.results;

                    const listAux = {
                        'worlds': data.content.results.worlds,
                        'regionals': data.content.results.regionals,
                        'locations': data.content.results.locations,
                    };
                    this._searchAll(listAux);

                    this.isLoading = false;
                },
                err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            );
        }
    }

    private _searchAll(listAux) {
        const $this = this;
        $this._searchWorlds(() => {
            $this.paramsSelected['worlds_selected'] = listAux.worlds;
            $this.paramsSelected['worlds'] = this._implodeValues(listAux.worlds, 'cod_locations_world');
            $this._searchRegionals(() => {
                $this.paramsSelected['regionals_selected'] = listAux.regionals;
                $this.paramsSelected['regionals'] = this._implodeValues(listAux.regionals, 'cod_locations_regional');
                $this._searchLocations(() => {
                    $this.paramsSelected['locations_selected'] = listAux.locations;
                    $this.paramsSelected['locations'] = this._implodeValues(listAux.locations, 'cod_location');
                });
            });
        });
    }

    public save() {
        this._validationService.boolSubmit = true;
        this._validationService.boolIsLoading = true;

        if (!this.equipment.str_name) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_STR_NAME');
            return false;
        }

        if (!this.equipment.str_description) {
            this._validationService.boolIsLoading = false;
            this._showErrorMessage('LOGBOOK.REQUIRED_STR_DESCRIPTION');
            return false;
        }


        let params: any;
        if (this.id <= 0) {
            params = {
                'str_name': this.equipment.str_name,
                'str_description': this.equipment.str_description,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'bol_accepts_hour_meter': this.equipment.bol_accepts_hour_meter,
                'bol_is_integration': this.equipment.bol_is_integration,
                'str_equipment_number': this.equipment.str_equipment_number,
                'cod_locations': this.paramsSelected['locations']
            };
            this._equipmentService.post(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        alert(res.content.message);
                    } else {
                        this._clearWorldList();
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    alert(e.content);
                    this._authService.errCheck(err);
                }
            );
        } else {
            params = {
                'cod_equipments': this.equipment.cod_equipments,
                'str_name': this.equipment.str_name,
                'str_description': this.equipment.str_description,
                'cod_user': this._authService.getAuthenticatedUser().id,
                'bol_accepts_hour_meter': this.equipment.bol_accepts_hour_meter,
                'bol_is_integration': this.equipment.bol_is_integration,
                'str_equipment_number': this.equipment.str_equipment_number,
                'cod_locations': this.paramsSelected['locations']
            };
            this._equipmentService.put(params).subscribe(res => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    if (res.type !== 'success') {
                        alert(res.content.message);
                    } else {
                        this._clearWorldList();
                        this.backSelected(true);
                    }
                }, err => {
                    this._validationService.boolSubmit = false;
                    this._validationService.boolIsLoading = false;
                    let e = JSON.parse(err._body);
                    alert(e.content);
                    this._authService.errCheck(err);
                }
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.get();
        let $this = this;
        window.setTimeout(function () {
            $this.equipmentFormContainer.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
        }, 250);
    }

    backSelected(success: boolean = false) {
        this._validationService.boolSubmit = false;
        this._validationService.boolIsLoading = false;
        if (success) {
            this.id = -1;
        } else {
            this.id = 0;
        }
        this.equipment = new Equipment();
        this.backEvent.emit(this.id);
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }
}
