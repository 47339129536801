import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ListMachinesParams } from './models/list-machines-params';
import { UsersService } from 'app/admin/services/users.service';
import { ListMachinesSelectService } from './services/list-machine-select.service';

@Component({
  selector: 'app-list-machine-select',
  templateUrl: './list-machine-select.component.html',
  styleUrls: ['./list-machine-select.component.css']
})
export class ListMachineSelectComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() selected = [];
  @Input() isMultiple: boolean = false;

  isLoading: boolean = false;

  machinesList = [];
  selectedMachines = [];

  params: ListMachinesParams = new ListMachinesParams();

  isMultiSelect: boolean = false;

  constructor(
    private _userService: UsersService,
    private _listMachinesService: ListMachinesSelectService,
  ) { }

  ngOnInit(): void {
  }

  searchMachines(params: ListMachinesParams, cod_selected_machines: any[] = null, firstOpening: boolean = false) {
    this.isLoading = true;

    this._listMachinesService.getListMachines(params).subscribe(data => {
      if (data.code === 888 && data.content.code === 888 && data.content.length > 0) {
        this.machinesList = this._userService.getArrayContent(data);
  
        if (cod_selected_machines && cod_selected_machines.length > 0) {
          this.selectedMachines = this.machinesList.filter(obj => cod_selected_machines.includes(obj['cod_machine']));
        }

        if (firstOpening) {
          this.selectedMachines = [this.machinesList[0]];
          this.selectionChanged.emit(this.selectedMachines);
        }
      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    }, () => this.isLoading = false
    );
  }

  onSelection($event) {
    this.selectedMachines = $event?.selectedOptions;

    this.selectionChanged.emit($event?.selectedOptions);
  }

  clearList() {
    this.machinesList = [];
    this.selectedMachines = [];
  }
}
