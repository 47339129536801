import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthStatus } from 'app/main/access/models/auth-event.model';
import { Observable } from 'rxjs';
import { AuthService } from '../main/access/services/auth.service';
import { PreviousRouteService } from 'app/services/previous-route.service';

@Injectable()
export class AuthGuard  {
    constructor(
        private router: Router,
        private authService: AuthService,
        private previousRouteService: PreviousRouteService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const fromVPO =
            (document.referrer.startsWith('https://vpo-dev.ab-inbev.com') ||
                document.referrer.startsWith('https://vpo-qa.ab-inbev.com') ||
                document.referrer.startsWith('https://vpo.ab-inbev.com')) &&
            this.previousRouteService.getPreviousUrl() === null;
        const fromBPO =
            (document.referrer.startsWith('https://brewingprocessoptimizerdev.azurewebsites.net/') ||
                document.referrer.startsWith('https://brewingprocessoptimizer.azurewebsites.net/')) &&
            this.previousRouteService.getPreviousUrl() === null;

        const autofillData = JSON.parse(localStorage.getItem('autofill-action-sources'));

        if (this.authService.isAuthenticated()) {

            if (fromBPO) {
                localStorage.setItem('autofill-action-sources', JSON.stringify({ redirect: false, autofill: 'BPO' })); // setting flag for 'BPO' auto fill
                if (
                    !route.routeConfig.path.startsWith('meetings-container') &&
                    !route.routeConfig.path.startsWith('my-meetings')
                ) {
                    // if starts with meetings-container then it is already a meeting, so no redirecting
                    this.router.navigate(['/my-meetings']); // redirecting to meetings selection menu
                }
            }
            return true;
        } else if (route.routeConfig.path === '' && !fromBPO && !fromVPO) {
            this.router.navigate(['/access']);
            return false;
        } else {

            if (fromBPO) {
                localStorage.setItem('autofill-action-sources', JSON.stringify({ redirect: true, autofill: 'BPO' })); // setting flag for 'BPO' auto fill
            }

            let res = new Observable<boolean>((sub) => {
                this.authService.getAuthEventSubject().subscribe((event) => {
                    if (event.type === AuthStatus.SUCCESS) {
                        if (fromBPO || (autofillData?.autofill === 'BPO' && autofillData?.redirect)) {
                            localStorage.setItem(
                                'autofill-action-sources',
                                JSON.stringify({ redirect: false, autofill: 'BPO' })
                            );
                            if (
                                !route.routeConfig.path.startsWith('meetings-container') &&
                                !route.routeConfig.path.startsWith('my-meetings')
                            ) {
                                this.router.navigate(['/my-meetings']); // redirecting to meetings selection menu
                            }
                        }
                        sub.next(true);
                    } else if (event.type !== AuthStatus.ONGOING) {
                        localStorage.removeItem('autofill-action-sources'); // cancel BPO autofill on failed auth
                        this.router.navigate(['/access']);
                        sub.next(false);
                    }
                });
            });
            return res;
        }
    }
}