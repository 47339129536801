import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/main/access/services/auth.service';
import { EmailModalService } from './email-confirmation-modal.service';


@Component({
  selector: 'app-email-confirmation-modal',
  templateUrl: './email-confirmation-modal.component.html',
  styleUrls: ['./email-confirmation-modal.component.css']
})
export class EmailConfirmationModalComponent {
  @Output() onCancel = new EventEmitter();

  @Input('modalTitleKey') modalTitleKey: string;
  @Input('maxUsersSelect') maxUsersSelect: number = 1;
  @Input('showNotesInput') showNotesInput: boolean = false;


  codUser: number = 0;

  showLiveSearch: boolean = false;
  obj_user: any;
  arr_emails: any;
  str_user_tmp: string;
  cod_user: any;
  notes: string;
  privileges_user: string;
  cod_user_location: any;
  has_privileges: any;

  showModal: boolean;

  constructor(
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _modalService: EmailModalService
  ) { 

    _modalService.showModal.subscribe(toogleShow => this.showModal = toogleShow);

    this.cod_user = this._authService.getAuthenticatedUser().id;
    this.notes = null
    this.arr_emails = []
   

    this._translateService.get('5WHY.USER_EMAIL_SELECT').subscribe(translate => {
      this.obj_user = {
        type: 'dynamic',
        placeHolder: translate,
        valueProperty: 'cod_user',
        searchProperty: 'str_name',
        hasImage: true,
        imageProperty: 'str_img_path',
        onSelect: (obj_selected: any) => {
          this.arr_emails.push({
            cod_user: obj_selected.cod_user,
            str_name: obj_selected.str_name,
            str_img_path: obj_selected.str_img_path
          });
          this.str_user_tmp = '';
        },
        asynchDataCall: (value: string, callback: any) => {
          this._modalService.searchForUsers(callback, value);
        },
        onBlur: (item: any) => {
          this.str_user_tmp = '';
        },
      };
    });
  }

  resetEmailModal() {
    this.notes = '';
    this.arr_emails = [];
    this.showLiveSearch = false;
  }

  removeOwner(index) {
    this.arr_emails.splice(index, 1);
  }

  cancel() {
    this.showLiveSearch = false;
    this.onCancel.emit();
    this._modalService.closeModal();
  }

  confirm(skipEmail) { 
    this._modalService.sendEmail({userList: this.arr_emails, notes: this.notes, skipEmail: skipEmail})
    this.resetEmailModal();
    this._modalService.closeModal();
  }

  onShowLiveSearch() {
    this.showLiveSearch = true;
  }

  cancelEmailSelect() {
    this.showLiveSearch = false;
  }
}
