// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.filter-button {
    position: relative;
    display: inline-block;
    margin: 5px;
}

.filter-close {
    position: absolute;
    top: -19px;
    right: -17px;
}

.close-button {
    margin:10px;
    color: #666666;
}

.div-filters-buttons {
    padding-top: 15px;
}

.p_filter {
    padding: 5px;
}

.dropdown-content div {
    margin-left: 0;
    margin-right: 0;
}

.input-filter-action {
    width: 150px;
}

.form-filter {
    min-width: 160px;
}

.header-title {
    height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/filter.component.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n\n.filter-button {\n    position: relative;\n    display: inline-block;\n    margin: 5px;\n}\n\n.filter-close {\n    position: absolute;\n    top: -19px;\n    right: -17px;\n}\n\n.close-button {\n    margin:10px;\n    color: #666666;\n}\n\n.div-filters-buttons {\n    padding-top: 15px;\n}\n\n.p_filter {\n    padding: 5px;\n}\n\n.dropdown-content div {\n    margin-left: 0;\n    margin-right: 0;\n}\n\n.input-filter-action {\n    width: 150px;\n}\n\n.form-filter {\n    min-width: 160px;\n}\n\n.header-title {\n    height: 60px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
