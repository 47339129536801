import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MyFormsService } from './services/my-forms.service';
import { LogbooksModule } from 'app/logbooks/logbooks.module';
import { ComponentsModule } from 'app/components/components.module';
import { MyFormComponent } from './components/my-form/my-form.component';
import { MyFormsMainComponent } from './my-forms-main/my-forms-main.component';
import { FormsDetailsComponent } from './components/forms-details/forms-details.component';
import { FormsLocationComponent } from './components/forms-location/forms-location.component';



@NgModule({
  declarations: [
    MyFormsMainComponent,
    MyFormComponent,
    FormsLocationComponent,
    FormsDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    LogbooksModule,
    TranslateModule,
    ComponentsModule,
  ],
  providers: [
    MyFormsService
  ]
})
export class MyFormsModule { }
