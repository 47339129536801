import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-choosen-name',
  templateUrl: './choosen-name.component.html',
  styleUrls: ['./choosen-name.component.css']
})
export class ChoosenNameComponent{

  toggled: boolean = false;
  @Input() items: Array<any>;
  @Input() currentValue: string;
  @Input() changeChoosenName: (event) => void;

  constructor() {
  }
  
  toggleOpen() {
    this.toggled = !this.toggled;
  }
}