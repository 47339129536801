import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-escaleted-abnormality-report-chart",
  templateUrl: "./escaleted-abnormality-report-chart.component.html",
  styleUrls: ["./escaleted-abnormality-report-chart.component.css"],
})
export class EscaletedAbnormalityReportChartComponent implements OnInit {
  @Input("data") data: any;
  @Input("isLoading") isLoading: boolean;

  options: any;
  screenWidth: number;
  echartsInstance: any;
  hoursTranslate: string;
  weeksTranslate: string;
  chartTitleTranslation: string;
  chartTitleShortTranslation: string;

  constructor(private _translateService: TranslateService) {
    this._translateService.get("GENERAL.DAYS").subscribe((translation) => {
      this.hoursTranslate = translation;
    });
    this._translateService.get("GENERAL.WEEK").subscribe((translation) => {
      this.weeksTranslate = translation;
    });
    this._translateService
      .get("5WHY.ESCALETED_ABNORMALITY_REPORT_SHORT")
      .subscribe((translation) => {
        this.chartTitleShortTranslation = translation;
      });
    this._translateService
      .get("5WHY.ESCALETED_ABNORMALITY_REPORT")
      .subscribe((translation) => {
        this.chartTitleTranslation = translation;
      });
      
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {

   

    this.screenWidth = window.innerWidth;

    if (window.innerWidth < 1480 && this.echartsInstance != null) {

      this.echartsInstance.setOption({
        title: {
          text: `${this.chartTitleShortTranslation}`,
        },
      });
    } else {
      this.echartsInstance.setOption({
        title: {
          text: `${this.chartTitleTranslation}`,
        },
      });
    }
  }

  ngOnInit(): void {
    this.options = {
      title: {
        text: `${window.innerWidth < 1480 ? this.chartTitleShortTranslation : this.chartTitleTranslation }`,
        x: "center",
        top: 20,
        textStyle: {
          fontSize: 15
        }
      },
      color: ["#3398DB"],
      tooltip: {
        trigger: "item",
        formatter: `{b} - {c}%`,
      },
      xAxis: {
        type: "category",
        data: this.data.map(
          (week) => `${this.weeksTranslate} ${week.str_week}`
        ),
        show: false,
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: "{value}%",
            margin: 2,
            fontSize: 10
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
          show: true,
          xAxisIndex: [0],
          start: 1,
          end: 100,
        },
      ],

      series: [
        {
          data: this.data.map((week) => week.str_percent),
          type: "bar",
        },
      ],
      animation: true,
    };

    
  }

  onChartInit(instance) {
    this.echartsInstance = instance;
    this.getScreenSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.echartsInstance) {
      this.echartsInstance.setOption({
        series: [
          {
            data: this.data.map((week) => week.str_percent),
            type: "bar",
          },
        ],
        xAxis: {
          type: "category",
          data: this.data.map(
            (week) => `${this.weeksTranslate} ${week.str_week}`
          ),
          show: false,
        },
      });
    }
  }
}
