// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1{
    font-size: 30px;
}

.sub-header {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sub-header h4{
    margin-right: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/main/reports-forms-attachments/reports-forms-attachments.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["h1{\n    font-size: 30px;\n}\n\n.sub-header {\n    padding: 1rem 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n}\n\n.sub-header h4{\n    margin-right: 3rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
