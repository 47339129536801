import { fromEvent, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, every, map, switchMap } from "rxjs/operators";
import { NotificationService } from "app/main/services/notification.service";
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges} from "@angular/core";

@Component({
  host: {
    '(document:click)': 'onClickOutside($event)',
  },
  selector: "app-ial-multiselect-combobox",
  templateUrl: "./ial-multiselect-combobox.component.html",
  styleUrls: ["./ial-multiselect-combobox.component.css"],
})
export class IalMultiselectComboboxComponent implements OnChanges {
  @ViewChild("inputSearch", { static: true })
  inputSearch: ElementRef;
  
  @ViewChild('container', { static: true })
  container: ElementRef;

  @Input() data: any[] = [];
  @Input() placeholder: string;
  @Input() multiple: boolean = true;
  @Input() hasImage: boolean = false;
  @Input() hasError: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() selectedOptions: any[] = [];
  @Input() isDisabled: boolean = false;

  @Input() keyProperty: string = "cod_item";
  @Input() displayValueProperty: string = "str_name";
  @Input() displayImageValueProperty: string = "str_img_path";

  @Output() blur = new EventEmitter<any>();
  @Output() selectionChanged = new EventEmitter<any>();
  
  isOpen = false;
  searchFilter = "";
  hasChanges = false;
  isAllSelected = false;
  enableLoadMore = false;
  isSearchLoading = false;
  dynamicPlaceholder = "";
  loadMoreClicksToMultiple = 1;

  translations = {
    of: "",
    all: "",
    selected: "",
    noDataLabelTranslation: ""
  };

  selectedOptionsOutputHelper = {
    stringIds: "",
    selectedOptionsLength: 0,
    selectedOptionsObjectList: {}
  };

  searchSubscription: any;
  liveSearch: Observable<any>;
  allDataClone$: Observable<any> = of();
  selectAllObservable$: Observable<any>;
  deSelectAllObservable$: Observable<any>;
  checkSelectedOnesOnNewData$: Observable<any>;
  updatePlaceholderObservable$: Observable<any>;
  validateIfAllSelectedObservable$: Observable<any>;

  constructor(
    private _translateService: TranslateService,
    private _notificationService: NotificationService
  ) {
    this._translateService.onLangChange.subscribe(() => {
      this.updatePlaceholderObservable$.subscribe();
      this.updatePlaceholder();
    });
    this.updatePlaceholder(); 
  }

  ngAfterViewInit() {
    this.initializeLiveSearchObservable();
  }

  onClickOutside(event) {
    // ignore icon clicks
    if (event.target.tagName === 'I') {
        return;
    }
    // check whether the click happened outside of this instance of multiselect
    if (!this.container.nativeElement.contains(event.target)) {
        if (this.isOpen) {
            this.toggleOpen();
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.data) {

      this.selectedOptionsOutputHelper = {
        stringIds: "",
        selectedOptionsLength: 0,
        selectedOptionsObjectList: {}
      };
      this.isAllSelected = false;

      this.enableLoadMore = this.data.length > 200 ? true : false;

      // if (!!this.selectedOptions && this.selectedOptions.length == this.data.length ) {
      //   this.isAllSelected = true;
      // }

      this.allDataClone$ = of(this.data);

      this.updateObservablesSource();

      this.checkSelectedOnesOnNewData$.subscribe(() => {
        this.updatePlaceholderObservable$.subscribe();
      });
    }
  }

  toggleOpen() {
    // won't allow opening if data is loading or has failed to load
    if (this.isLoading || this.hasError) {
      this.isOpen = false;
    } else {
        this.isOpen = !this.isOpen;
    }

     // emit changes on closing if dirty
     if (!this.isOpen && this.hasChanges) {
       // Format string with commas to be used by who call the component.
      var formatedStringIds = this.selectedOptionsOutputHelper.stringIds.split('{').join('').split('}').join(',').slice(0, -1);
      // Mount a object to maintain the same structure of the older multiselect.
      const objectToEmit = {stringIds: formatedStringIds, selectedOptions: Object.values(this.selectedOptionsOutputHelper.selectedOptionsObjectList)};

      this.selectionChanged.emit(objectToEmit);
      this.hasChanges = false;
    }

     // emit blur event on closing
     if (!this.isOpen) {
      if (this.blur) {
          this.blur.emit();
      }
      this.inputSearch.nativeElement.value = '';
    }
  }

  toggleOption(optionSelected, event) {
    event.stopPropagation();
    
    this.hasChanges = true;
    
    optionSelected.isChecked = !optionSelected.isChecked;
    this.updateSelectedOptionsOutputHelper(optionSelected.isChecked, optionSelected);

    this.validateIfAllSelectedObservable$.subscribe(
      (validationAllSelected) => {
          this.isAllSelected = validationAllSelected
          this.updatePlaceholderObservable$.subscribe();
      }
    );
  }

  toggleAll(event) {
    event.stopPropagation();
    this.isAllSelected = !this.isAllSelected;

    if (this.isAllSelected) {
      this.selectAllObservable$.subscribe(() => { this.updatePlaceholderObservable$.subscribe(); });
    } else {
      this.deSelectAllObservable$.subscribe(() => { this.updatePlaceholderObservable$.subscribe(); } );
    }

    this.hasChanges = true;
  }

  private updateObservablesSource() {
    this.updateCheckSelectedOnesObservable();
    this.updateDeSelectAllObservable();
    this.updateSelectAllObservable();
    this.updateValidationIfAllSelectedObservable();
    this.updatePlaceholderValidation();
  }

  private updateCheckSelectedOnesObservable() {
    this.checkSelectedOnesOnNewData$ = this.allDataClone$.pipe(
      map((fullData) => {
        return fullData.map((data) => {
          data.isChecked = this.isAllSelected || this.selectedOptions.some( (selectedOpt, i) => selectedOpt[this.keyProperty] == data[this.keyProperty] );

          if (data.isChecked) {
            this.updateSelectedOptionsOutputHelper(data.isChecked, data);
          }

          return data;
        });
      })
    );
  }

  private updateDeSelectAllObservable() {
    this.deSelectAllObservable$ = this.allDataClone$.pipe(
      map((fullData) => {
        if (this.inputSearch.nativeElement.value.length > 0) {
          return this.data.map((data) => {
            if(data.isChecked) {
              data.isChecked = false;
              this.updateSelectedOptionsOutputHelper(data.isChecked, data );
            }
            return data;
          });
        } else {
          return fullData.map((data) => {
            if(data.isChecked) {
              data.isChecked = false;
              this.updateSelectedOptionsOutputHelper(data.isChecked, data );
            }
            return data;
          });
        }
      })
    );
  }

  private updateSelectAllObservable() {
    this.selectAllObservable$ = this.allDataClone$.pipe(
      map((fullData) => {
        if (this.inputSearch.nativeElement.value.length > 0) {
          return this.data.map((data) => {
            if(!data.isChecked) {
              data.isChecked = true;
              this.updateSelectedOptionsOutputHelper(data.isChecked, data );
            }
            return data;
          });
        } else {
          return fullData.map((data) => {
            if(!data.isChecked) {
              data.isChecked = true;
              this.updateSelectedOptionsOutputHelper(data.isChecked, data );
            }
            return data;
          });
        }
      })
    );
  }

  private updateValidationIfAllSelectedObservable() {
    this.validateIfAllSelectedObservable$ = of(this.data).pipe(
      switchMap((x) => x),
      every((data: any) => {
        return data.isChecked;
      })
    );
  }

  private updatePlaceholderValidation() {
    this.updatePlaceholderObservable$ = this.allDataClone$.pipe(
        map((fullData) => {
          if (fullData == null || fullData.length == 0) {
            this.dynamicPlaceholder = this.translations.noDataLabelTranslation;
          } else if (fullData.length <= 3) {
            const selecteds = fullData.filter(i => i.isChecked);
            if (selecteds.length == 0) {
              this.dynamicPlaceholder = this.placeholder;
            } else {
              this.dynamicPlaceholder = selecteds
                .slice(0, 3)
                .map((data) => data[this.displayValueProperty])
                .join(", ");
            }
          } else if( fullData.length == this.selectedOptionsOutputHelper.selectedOptionsLength) { 
            this.dynamicPlaceholder = `${this.translations.all} ${this.translations.selected}`
          } else {
            this.dynamicPlaceholder = `${this.selectedOptionsOutputHelper.selectedOptionsLength} ${this.translations.of} ${fullData.length} ${this.translations.selected}`;
          }
        })
      );
  }

  private initializeLiveSearchObservable() {
    this.liveSearch = fromEvent(this.inputSearch.nativeElement, "keyup").pipe(
      map((e: any) => {
        if (this.enableLoadMore) {
          this.isSearchLoading = true;
        }
        return e.target.value;
      }),
      debounceTime(this.enableLoadMore ? 1000 : 0),
      // if new values are passed, switch to those and discard the current one.
      switchMap((searchValue) => {
        return this.allDataClone$.pipe(
          map((fullData) => {
            this.isAllSelected = true;

            const filteredData = fullData.filter((dataValue) => {
              const displayValueLabel = dataValue[this.displayValueProperty].toLowerCase();
              const shouldShowInFilter = displayValueLabel.indexOf(searchValue.toLowerCase()) !== -1;

              if (shouldShowInFilter && dataValue.isChecked == false) {
                this.isAllSelected = false;
              }

              return shouldShowInFilter;
            });
            return filteredData;
          })
        );
      })
    );

    this.searchSubscription = this.liveSearch.subscribe(
      (resultSearch: any) => {
        this.data = resultSearch;
        this.updateValidationIfAllSelectedObservable();
        this.loadMoreClicksToMultiple = 1;
        this.isSearchLoading = false;
      },
      (err) => {
        console.log("err ->", err);
        this.isSearchLoading = false;
        this._notificationService.error({text:"IAL_MULTISELECT_COMPONENT.SEARCH_ERROR", translate: true})
      }
    );
  }

  private updateSelectedOptionsOutputHelper(isChecked, data) {
    if(isChecked) {
      this.selectedOptionsOutputHelper.selectedOptionsLength++;
      this.selectedOptionsOutputHelper.stringIds += `{${data[this.keyProperty]}}`
      this.selectedOptionsOutputHelper.selectedOptionsObjectList[data[this.keyProperty]] = data;
    } else { 
      this.selectedOptionsOutputHelper.selectedOptionsLength--;
      this.selectedOptionsOutputHelper.stringIds = this.selectedOptionsOutputHelper.stringIds.replace(`{${data[this.keyProperty]}}`, '');
      delete this.selectedOptionsOutputHelper.selectedOptionsObjectList[data[this.keyProperty]];
    }
  }

  private updatePlaceholder() {
    this._translateService.get("MULTISELECT.NO_ITENS").subscribe((result) => {
      this.translations.noDataLabelTranslation = result;
    });

    this._translateService.get('USERS.SELECTED').subscribe(translate => {
        this.translations.selected = translate;
    });

    this._translateService.get('USERS.OF').subscribe(translate => {
        this.translations.of = translate;
    });

    this._translateService.get('USERS.ALL').subscribe(translate => {
        this.translations.all = translate;
    });
  }
}
