import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../main/access/services/auth.service';
import {RoutinesService} from '../services/routines.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {SelectPagination} from "../models/select_pagination.model";

@Component({
    selector: 'app-routines-consult',
    templateUrl: './routines-consult.component.html',
    styleUrls: ['./routines-consult.component.css']
})
export class RoutinesConsultComponent implements AfterViewInit, OnDestroy {

    list: Object;
    paramsSubscription: Subscription = Subscription.EMPTY;
    searchTerm: string;
    searchTimer: any;
    pageIndex: number;
    totalPages: number[];
    resultsPerPage: SelectPagination[];
    resultsPerPageSelected: number;
    resultsPerPageSelectOption: SelectPagination[];
    maxPage: number;
    isLoading: boolean = true;
    codRoutine: number = 0;
    codUser: number;
    messageConfirmation: string = '';
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    _reportsApiUrl = environment().reportsApiUrl;
    showError: boolean = false;
    errorMessage: string = '';
    str10perpage: string;
    str50perpage: string;
    str100perpage: string;

    constructor(public routineService: RoutinesService, private _authService: AuthService, private _translateService: TranslateService) {
        this.searchTerm = '';
        this.showError = false;
        this.errorMessage = '';
        this.list = {
            routines: []
        };

        this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
            this.str10perpage = '10 ' + translate;
            this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                this.str50perpage = '50 ' + translate;                
                this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
                    this.str100perpage = '100 ' + translate;
                    this.resultsPerPage = [
                        new SelectPagination(10, this.str10perpage),
                        new SelectPagination(50, this.str50perpage),
                        new SelectPagination(100, this.str100perpage),
                    ];
                });       
            });
        });


        this.pageIndex = 1;
        this.totalPages = [];

        this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
        this.resultsPerPageSelected = this.resultsPerPage[0].qty;

        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;

        this._translateService.get('LOGBOOK.IMPORT_ROUTINES_CONFIRMATION').subscribe(translate => {
            this.messageConfirmation = translate;
        });
    }

    ngAfterViewInit() {
        this._search();
    }

    ngOnDestroy() {
        try {
            if (this.paramsSubscription != Subscription.EMPTY) {
                this.paramsSubscription.unsubscribe();
            }
        }
        catch (err) {
            //safe!
            console.log(err);
        }
    }

    private _search() {
        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        let objParam = {
            'cod_routine': 0,
            'term': this.searchTerm,
            'limit': this.resultsPerPageSelected,
            'offset': (tmpPage * this.resultsPerPageSelected),
            'dsc_page_privilege':'NOT_PRIVILEGE',
            'cod_user':this.codUser
        };
        this.routineService.getRoutineList(objParam).subscribe(data => {
            this.list = {
                routines: this.routineService.getArrayContent(data)
            };

            let str_cod_rotina = '';
            let str_nom_rotina = '';
            let str_dsc_rotina = '';
    
            this._translateService.get('LOGBOOK.COD_ROUTINE').subscribe(translate => {
                str_cod_rotina = translate;
                this._translateService.get('LOGBOOK.STR_ROUTINE').subscribe(translate => {
                    str_nom_rotina = translate;                    
                    this._translateService.get('LOGBOOK.DSC_ROUTINE').subscribe(translate => {
                        str_dsc_rotina = translate;

                        this.downloadCsvSetup = {
                            cod_user: this.codUser,
                            api_url: this._reportsApiUrl,
                            str_proc_exec: 'reports_ex_routines',
                            arr_proc_params_search: [
                                this.searchTerm
                            ],
                            arr_headers: [
                                str_cod_rotina,
                                str_nom_rotina,
                                str_dsc_rotina
                            ],
                            name_of_file_to_download: 'routines_',
                            str_column_dynamic: ''
                        };                        
                    });            
                });
            });



            this.isLoading = false;

            this.maxPage = Math.ceil(data.content.count / this.resultsPerPageSelected);
            this.totalPages = this.pagination(this.pageIndex, this.maxPage);

        }, err => {
            console.log(err);
            let e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err);
        }
        );
    }

    public search() {
        if (!parseInt(this.searchTerm) && this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    selectionChanged($event) {
        this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
        this.changePage(1);
    }

    changePage(page) {
        this.pageIndex = page;
        this.search();
    }

    pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

}
