export class SubAreaTranslation {
    cod_subareas: number;
    str_description_pt: string;
    str_description_es: string;
    str_description_fr: string;
    str_description_nl: string;
    str_description_de: string;
    str_description_ru: string;
    str_description_uk: string;
    str_description_zh: string;
    str_description_ko: string;
    str_description_vi: string;

    constructor() {
        this.cod_subareas = 0;
        this.str_description_pt = null;
        this.str_description_es = null;
        this.str_description_fr = null;
        this.str_description_nl = null;
        this.str_description_de = null;
        this.str_description_ru = null;
        this.str_description_uk = null;
        this.str_description_zh = null;
        this.str_description_ko = null;
        this.str_description_vi = null;
    }
}
