import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../main/access/services/auth.service';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { CommonModule } from '@angular/common';
import { MeetingsService } from '../services/meetings.service';
import { DayOfWeekType, KpiType, MeetingColumn, MeetingColumnOption, PartialKpiType, PartialTagType, TagType } from '../services/types/response-types';
import { MultiSelectComponent } from '../../components/multiselect/multiselect.component';
import { KpisService } from '../services/kpis.service';
import { LiveSearchSelectComponent } from "../../components/live-search-select/live-search-select.component";
import { debounceTime, Subject } from 'rxjs';
import { NotificationService } from '../../main/services/notification.service';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { AreasService } from '../../components/services/areas.service';
import { DepartmentItem } from '../../components/services/types/areas.service.types';
import { UpdateMeetingDetailsParams } from '../services/types/request-types';
import { ErrorResponseType } from '../services/types/generic-response-types';


type LocationType = {
  location_name: string;
  cod_location: number;
};

type DepartmentType = {
  department_name: string;
  cod_department: number;
};

type MeetingType = {
  type_name: string;
  cod_meeting_type: number;
};

type MeetingDetailsForm = {
  name: FormControl<string>;
  location: FormControl<LocationType>;
  department: FormControl<DepartmentType>;
  type: FormControl<MeetingType>;
  day_of_the_week: FormControl<DayOfWeekType>;
  tags: FormControl<TagType[]>;
  kpis: FormControl<KpiType[]>;
  columns: FormControl<MeetingColumn[]>;
  is_only_participants: FormControl<boolean>;
};

@Component({
  selector: 'app-meetings-edit-details-drawer',
  templateUrl: './meetings-edit-details-drawer.component.html',
  styleUrls: ['./meetings-edit-details-drawer.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ClbDrawerModule,
    MultiSelectComponent,
    LiveSearchSelectComponent,
    ClbModalModule,
]
})
export class MeetingsEditDetailsDrawerComponent {
  @Input('open') isOpen: boolean;
  @Input('cod_meeting') current_meeting_id: number;
  @Input('meeting_name') current_meeting_name: string;
  @Input('is_owner') is_current_user_owner: boolean = false;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() onDetailsChanged = new EventEmitter();

  obj_user: any;
  current_user_id: number;

  meetingForm: FormGroup;

  selectedFrequencyName: string = '';
  selectedFrequencyId: number = null;
  initialSelectedTagsIds: PartialTagType[] = [];
  initialSelectedKpisUuid: PartialKpiType[] = [];

  locationOptions: any[] = [];
  departmentOptions: DepartmentItem[] = [];
  typeOptions: any[] = [];
  dayOfWeekOptions: DayOfWeekType[] = [];
  tagsOptions: TagType[] = [];
  kpisOptions: KpiType[] = [];
  columnSelectOptions: MeetingColumn[] = [];

  hasEditDetailPrivilege: boolean = false;
  hasEditTypePrivilege: boolean = false;
  hasEditTagsPrivilege: boolean = false;

  meetingTypeState: {
    confirmedToChange: boolean;
    initialMeetingType: MeetingType;
    meetingTypeWaitingConfirmation: MeetingType;
    isCurrentMeetingTypeDeleted: boolean;
  } = {
    confirmedToChange: false,
    initialMeetingType: null,
    meetingTypeWaitingConfirmation: null,
    isCurrentMeetingTypeDeleted: false
  }

  loadingOptionsState = {
    location: false,
    department: false,
    type: false,
    typeFrequency: false,
    day_of_the_week: false,
    tags: false,
    kpis: false,
    columns: false,
  }

  syncActionsState = {
    meetingDetails: false,
    addColumn: false,
    deleteColumn: false,
    addColumnOption: false,
    deleteColumnOption: false,
    updatingMeeting: false,
    deletingMeeting: false,
    restoringMeeting: false,
  }

  isMeetingDeleted: boolean = false;

  private readonly inputDebouncedColumnOptionSubject = new Subject<{ 
    option: MeetingColumnOption; 
    columnId: number;
    type: 'insert' | 'update';
  }>();

  currentConfirmationModal: {
    key: string;
    title: string;
    description: string;
    confirmButtonLabel?: string;
    confirmButtonClass?: string;
    confirmAction: () => void;
    cancelAction?: () => void;
  } | null = null;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _meetingsService: MeetingsService,
    private readonly _kpisService: KpisService,
    private readonly _notificationService: NotificationService,
    private readonly _areasService: AreasService
  ) {
    this.obj_user = this._authService.getAuthenticatedUser();
    this.current_user_id = this.obj_user.id;

    this.hasEditDetailPrivilege = this._authService.getUserPrivilege('MEETING_EDIT_LOCATION_MEETINGS');
    this.hasEditTypePrivilege = this._authService.getUserPrivilege('MEETING_SELECT_TYPE');
    this.hasEditTagsPrivilege = this._authService.getUserPrivilege('MEETING_SELECT_INDICATORS');

    this.meetingForm = new FormGroup<MeetingDetailsForm>({
      name: new FormControl('', [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      department: new FormControl(null, [Validators.required]),
      type: new FormControl(null),
      day_of_the_week: new FormControl(null),
      tags: new FormControl([]),
      kpis: new FormControl([]),
      columns: new FormControl([]),
      is_only_participants: new FormControl(false, [Validators.required]),
    });

    this.inputDebouncedColumnOptionSubject
      .pipe(debounceTime(500))
      .subscribe(({ option, columnId, type }) => {
        if (type === 'insert') {
          this.insertNewColumnOption(option, columnId);
        } else {
          this.updateMeetingColumnOption(option, columnId);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isOpen?.currentValue) {
      this.syncActionsState.meetingDetails = true;
      this.resetFormValues();
      
      this.getMeetingDetails();
      this.getMeetingColumns();
    }
  }

  resetFormValues() {
    this.meetingForm.reset({
      name: '',
      location: null,
      department: null,
      type: null,
      day_of_the_week: null,
      tags: [],
      kpis: [],
      columns: [],
      is_only_participants: false,
    });

    this.meetingForm.enable();
    this.disableFormFields();
  }

  disableFormFields() {
    if (this.is_current_user_owner || this.hasEditDetailPrivilege) return;

    this.meetingForm.get('name')?.disable();
    this.meetingForm.get('is_only_participants')?.disable();
  }

  resetFormField(field: string, defaultValue: unknown = null) {
    this.meetingForm.get(field)?.reset({ value: defaultValue });
  }

  getMeetingDetails() {
    this._meetingsService.getEditMeetingsDetails(this.current_meeting_id, this.current_user_id).subscribe({
      next: (response) => {

        const data = response.content.results[0];

        this.isMeetingDeleted = data.dat_del !== null;

        if (this.isMeetingDeleted) {
          this.meetingForm.disable();
        };
        
        let selectedMeetingType = null;

        if (data.cod_meeting_type) {
          selectedMeetingType = {
            type_name: data?.meeting_type_name,
            cod_meeting_type: data?.cod_meeting_type
          }
        }

        this.meetingForm.patchValue({
          name: data.meeting_name,
          location: { location_name: data.location_name, cod_location: data.cod_location },
          department: { department_name: data.department_name, cod_department: data.cod_department },
          type: selectedMeetingType,
          day_of_the_week: { day_of_week_name: '', cod_day_of_week: data.cod_day_of_week },
          is_only_participants: data.only_participants,
        });

        this.meetingTypeState.initialMeetingType = selectedMeetingType ?? null;
        this.meetingTypeState.isCurrentMeetingTypeDeleted = data.is_meeting_type_deleted ?? false;

        this.initialSelectedTagsIds = data.arr_tags.map((tag) => {
          const { cod_tag } = tag;
          return { cod_tag };
        });

        this.initialSelectedKpisUuid = data.arr_kpis.map((kpi) => {
          const { uuid } = kpi;
          return { uuid };
        });
      },
      error: (error) => {
        console.log('error', error);
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_DETAILS');
        this.closeDrawer();
      },
      complete: () => {
        this.syncActionsState.meetingDetails = false;
        this.getMeetingTypeFrequency(this.meetingForm.get('type')?.value.cod_meeting_type);
        this.getMeetingOptions();
      }
    });
  }

  getMeetingColumns() {
    this._meetingsService.getMeetingDetailColumns(this.current_meeting_id, this.current_user_id).subscribe({
      next: (response) => {
        const results: MeetingColumn[] = response.content.results;

        this.meetingForm.get('columns')?.setValue(results);
      },
      error: (err) => {
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_COLUMNS');
      }
    });
  }

  getMeetingOptions() {
    this.getLocationOptions();
    this.getDepartmentOptions();
    this.getMeetingTypeOptions();
    this.getDayOfWeekOptions();
    this.getTagsOptions();
    this.getKpiOptions();
  }

  getLocationOptions() {
    this.loadingOptionsState.location = true;

    this._meetingsService.getMeetingLocationOptions(this.current_meeting_id, this.current_user_id).subscribe({
      next: (response) => {
        this.locationOptions = response.content.results.map((location) => {

          const { str_name, cod_locations } = location;

          return {
            location_name: str_name,
            cod_location: cod_locations
          }
        });
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.location = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_LOCATIONS');
      },
      complete: () => {
        this.loadingOptionsState.location = false;
      }
    });
  }

  getDepartmentOptions() {
    this.loadingOptionsState.department = true;

    const cod_location_value = this.meetingForm.get('location')?.value.cod_location;

    this._areasService.getDepartments({ cod_user: this.current_user_id, cod_location: cod_location_value }).subscribe({
      next: (response) => {
        this.departmentOptions = response.content.results.map((department) => {

          const { cod_department, department_name } = department;

          return {
            cod_department,
            department_name
          }
        });
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.department = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_DEPARTMENTS');
      },
      complete: () => {
        this.loadingOptionsState.department = false;
      }
    });
  }

  getMeetingTypeOptions() {
    this.loadingOptionsState.type = true;

    const cod_location_value = this.meetingForm.get('location')?.value.cod_location;

    this._meetingsService.getMeetingTypeOptions(this.current_user_id, cod_location_value, this.current_meeting_id).subscribe({
      next: (response) => {
        this.typeOptions = response.content.results.map((type) => {
          return {
            type_name: type.str_name,
            cod_meeting_type: type.cod_meetings_types
          }
        });
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.type = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_MEETING_TYPES');
      },
      complete: () => {
        this.loadingOptionsState.type = false;
      }
    });
  }

  getMeetingTypeFrequency(cod_meeting_type: number) {
    this.loadingOptionsState.typeFrequency = true;
    this._meetingsService.getMeetingTypeFrequency(this.current_user_id, cod_meeting_type).subscribe({
      next: (response) => {
        this.selectedFrequencyName = response.content.results[0].frequency_name;
        this.selectedFrequencyId = response.content.results[0].cod_frequency;
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.typeFrequency = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_FREQUENCY');
      },
      complete: () => {
        this.loadingOptionsState.typeFrequency = false;
      }
    });
  }

  getTagsOptions() {
    this.loadingOptionsState.tags = true;
    const cod_location_value = this.meetingForm.get('location')?.value.cod_location;

    this._meetingsService.getMeetingTagsOptions(this.current_user_id, cod_location_value).subscribe({
      next: (response) => {
        this.tagsOptions = response.content.results.map((tag) => {

          const { tag_name, cod_tag } = tag;
          
          return {
            tag_name,
            cod_tag
          }
        });
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.tags = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_TAGS');
      },
      complete: () => {
        this.parseSelectedTagsFieldsToFormValue(this.tagsOptions);
        this.loadingOptionsState.tags = false;
      }
    });
  }

  parseSelectedTagsFieldsToFormValue(tagsFields: TagType[]) {
    if (this.meetingForm.get('tags')?.value.length > 0) return;

    const tags: TagType[] = this.initialSelectedTagsIds.map((tag) => {
      const { cod_tag } = tag;
      return tagsFields.find((t) => t.cod_tag == cod_tag);
    });

    this.meetingForm.get('tags')?.setValue(tags);
  }

  getKpiOptions() {
    this.loadingOptionsState.kpis = true;

    this._kpisService.getKpis({_order: 'name'}).subscribe({
      next: (response) => {
        this.kpisOptions = response.content.data.map((kpi) => {

          const { uuid, code, name } = kpi;
          return {
            uuid,
            code,
            name
          }
        });
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.kpis = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_FETCH_KPIS');
      },
      complete: () => {
        this.parseSelectedKpiFieldsToFormValue(this.kpisOptions);
        this.loadingOptionsState.kpis = false;
      }
    });
  }

  parseSelectedKpiFieldsToFormValue(kpiFields: KpiType[]) {
    if (this.meetingForm.get('kpis')?.value.length > 0) return;

    const kpis: KpiType[] = this.initialSelectedKpisUuid.map((kpi) => {
      const { uuid } = kpi;
      return kpiFields.find((k) => k.uuid == uuid);
    });

    this.meetingForm.get('kpis')?.setValue(kpis);
  };

  getDayOfWeekOptions() {
    this._translateService.get("GENERAL.WEEK_DAYS_JSON").subscribe((translate) => {
      const options: { cod_week: number, str_week: string }[] = JSON.parse(translate);
      this.dayOfWeekOptions = options.map((option) => {
        const { cod_week, str_week } = option;
        return {
          day_of_week_name: str_week,
          cod_day_of_week: cod_week
        };
      });
    });
  };

  onColumnSearch(term: string): void {
    this.loadingOptionsState.columns = true;

    this._meetingsService.searchMeetingColumnOptions(this.current_user_id, term).subscribe({
      next: (response) => {
        const results: MeetingColumn[] = response.content.results;

        if (results.length == 0) {
          results.push({
            cod_meeting_origin: this.current_meeting_id,
            cod_columns: -1,
            str_name_column: term,
            cod_column_origin: null,
            arr_options: []
          });
        }

        this.columnSelectOptions = results;
      },
      error: (error) => {
        console.log('error', error);
        this.loadingOptionsState.columns = false;
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_SEARCH_COLUMNS');
      },
      complete: () => {
        this.loadingOptionsState.columns = false;
      }
    });
  }

  onSelectAddColumn(column: MeetingColumn): void {
    this.syncActionsState.addColumn = true;
    const newColumn = { ...column };
    newColumn['arr_options'] = [];
    this.addTemporaryColumn(newColumn);
    this.addNewColumn(newColumn);
  };

  addNewColumn(column: MeetingColumn) {
    const newColumnPosition = this.getNextColumnPosition();
    this._meetingsService.addNewMeetingColumn(this.current_user_id, this.current_meeting_id, column.str_name_column, column.cod_column_origin, newColumnPosition).subscribe({
      next: (response) => {
        const newId = response.content.results[0]?.cod_meeting_column;
        this.handleSuccessfulColumnAddition(newId);
      },
      error: (error) => {
        this.handleErrorColumnAddition(error);
      }
    });
  };

  getNextColumnPosition(): number {
    const columns = this.meetingForm.get('columns')?.value;

    if (!columns || columns.length === 0) return 1;
    
    const positions = columns.map((col) => col.column_position || 0);

    return Math.max(...positions) + 1;
  };

  addTemporaryColumn(column: MeetingColumn) {
    const temporaryColumn = { ...column }
    temporaryColumn.cod_columns = -1;

    const columns = this.meetingForm.get('columns')?.value;
    columns.push(temporaryColumn);

    this.meetingForm.get('columns')?.setValue(columns);
  };

  handleSuccessfulColumnAddition(newColumnId: number) {
    const columns: MeetingColumn[] = this.meetingForm.get('columns')?.value;
    const newColumn = columns.find((col) => col.cod_columns == -1);

    newColumn.cod_columns = newColumnId;
    this.meetingForm.get('columns')?.setValue(columns);

    this.syncActionsState.addColumn = false;
    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.COLUMN_ADDED');
  };

  handleErrorColumnAddition(error) {
    const columns = this.meetingForm.get('columns')?.value;
    columns.pop();

    this.syncActionsState.addColumn = false;
    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_ADDING_COLUMN');
  }

  onDeleteColumn(column: MeetingColumn, columnIndex: number) {
    this.syncActionsState.deleteColumn = true;

    this.temporaryDeleteColumn(columnIndex);
    this.deleteMeetingColumn(column, columnIndex);
  }

  temporaryDeleteColumn(columnIndex: number) {
    const columns = this.meetingForm.get('columns')?.value;

    columns.splice(columnIndex, 1);
    this.meetingForm.get('columns')?.setValue(columns);
  };

  deleteMeetingColumn(column: MeetingColumn, columnIndex: number) {
    this._meetingsService.removeMeetingColumn(this.current_user_id, this.current_meeting_id, column.cod_columns).subscribe({
      next: (_) => {
        this.handleSuccessfulColumnDeletion();
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorColumnDeletion(error, column, columnIndex);
      }
    });
  };

  handleSuccessfulColumnDeletion() {
    this.syncActionsState.deleteColumn = false;

    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.COLUMN_DELETED');
  };

  handleErrorColumnDeletion(error, column: MeetingColumn, columnIndex: number) {
    const columns = this.meetingForm.get('columns')?.value;
    columns.splice(columnIndex, 0, column);
    this.syncActionsState.deleteColumn = false;

    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_DELETING_COLUMN');
  };

  onAddColumnOption(columnId: number, ) {
    const columns: MeetingColumn[] = this.meetingForm.get('columns')?.value;
    const column = columns.find((col) => col.cod_columns == columnId);

    const newOptionPosition = this.getNextOptionPosition(column.arr_options);

    const initialColumnOption: MeetingColumnOption = {
      cod_columns_options: -1,
      str_name_option: '',
      option_position: newOptionPosition
    };

    column.arr_options.push(initialColumnOption);
  }

  getNextOptionPosition(optionsArray: MeetingColumnOption[]): number {
    if (!optionsArray || optionsArray.length === 0) return 1;

    const positions = optionsArray.map((opt) => opt.option_position || 0);
    return Math.max(...positions) + 1;
  }

  onInputColumnOption(event: any, option: MeetingColumnOption, columnId: number) {
    const optionDescription = event.target.value.trim();

    option.str_name_option = optionDescription;

    const type = option.cod_columns_options == -1 ? 'insert' : 'update';

    this.inputDebouncedColumnOptionSubject.next({ 
      option, 
      columnId, 
      type
    });
  }

  insertNewColumnOption(option: MeetingColumnOption, columnId: number) {
    this._meetingsService.postMeetingColumnOption(
      this.current_user_id,
      this.current_meeting_id,
      columnId,
      option.str_name_option,
      option.option_position
    ).subscribe({
      next: (response) => {
        const newOptionId = response.content.results[0].cod_columns_options;
        this.handleSuccessfulColumnOptionAddition(option, newOptionId, columnId);
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorColumnOptionAddition(error);
      }
    });
  };

  handleSuccessfulColumnOptionAddition(option: MeetingColumnOption, newOptionId: number, columnId: number) {
    const columns: MeetingColumn[] = this.meetingForm.get('columns')?.value;
    const column = columns.find((col) => col.cod_columns == columnId);
    const optionIndex = column.arr_options.findIndex((opt) => {
      return (
        opt.cod_columns_options == -1 && 
        opt.str_name_option == option.str_name_option &&
        opt.option_position == option.option_position
      )
    });

    column.arr_options[optionIndex].cod_columns_options = newOptionId;

    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.COLUMN_OPTION_ADDED');
  }

  handleErrorColumnOptionAddition(error) {
    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_ADDING_COLUMN_OPTION');
  }

  updateMeetingColumnOption(columnOption: MeetingColumnOption, columnId: number) {
    this._meetingsService.updateMeetingColumnOption(
      columnOption.cod_columns_options, 
      columnOption.str_name_option, 
      columnOption.option_position,
      this.current_user_id, 
      this.current_meeting_id, 
      columnId
    ).subscribe({
      next: (response) => {
        const newOptionId = response.content.results[0].cod_columns_options;
        this.handleSuccessfulColumnOptionUpdate(columnOption, newOptionId, columnId);
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorColumnOptionUpdate(error);
      }
    });
  }

  handleSuccessfulColumnOptionUpdate(option: MeetingColumnOption, newOptionId: number, columnId: number) {
    const columns: MeetingColumn[] = this.meetingForm.get('columns')?.value;
    const column = columns.find((col) => col.cod_columns == columnId);
    const optionIndex = column.arr_options.findIndex((opt) => opt.cod_columns_options == option.cod_columns_options);

    column.arr_options[optionIndex].cod_columns_options = newOptionId;

    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.COLUMN_OPTION_UPDATED');
  }

  handleErrorColumnOptionUpdate(error) {
    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_UPDATING_COLUMN_OPTION');
  }

  onRemoveColumnOption(option: MeetingColumnOption, optionIndex: number, columnId: number) {

    this.syncActionsState.deleteColumnOption = true;

    this.temporaryDeleteColumnOption(columnId, optionIndex);
    this.deleteColumnOption(option, optionIndex, columnId);
  }

  temporaryDeleteColumnOption(columnId: number, optionIndex: number) {
    const columns: MeetingColumn[] = this.meetingForm.get('columns')?.value;
    const column = columns.find((col) => col.cod_columns == columnId);

    column.arr_options.splice(optionIndex, 1);

    this.meetingForm.get('columns')?.setValue(columns);
  };

  deleteColumnOption(option: MeetingColumnOption, optionIndex: number, columnId: number) {
    this._meetingsService.deleteColumnOption(this.current_user_id, this.current_meeting_id, columnId, option.cod_columns_options).subscribe({
      next: (_) => {
        this.handleSuccessfulColumnOptionDeletion();
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorColumnOptionDeletion(error, option, optionIndex, columnId);
      }
    });
  };

  handleSuccessfulColumnOptionDeletion() {
    this.syncActionsState.deleteColumnOption = false;

    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.COLUMN_OPTION_DELETED');
  }

  handleErrorColumnOptionDeletion(error, option: MeetingColumnOption, optionIndex: number, columnId: number) {
    const columns = this.meetingForm.get('columns')?.value as MeetingColumn[];
    const column = columns.find((col) => col.cod_columns == columnId);

    column.arr_options.splice(optionIndex, 0, option);

    this.syncActionsState.deleteColumnOption = false;

    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_DELETE_COLUMN_OPTION');
  }

  onSelectField(field: string, event: any) {
    const selectedOptions = event.selectedOptions;
    const formField = this.meetingForm.get(field);
    formField?.setValue(selectedOptions[0]);
    formField?.markAsDirty();
  };

  onSelectTags(event: {selectedOptions: TagType[]}) {
    this.meetingForm.get('tags')?.setValue(event.selectedOptions);

    this.updateMeetingTags(event.selectedOptions);
  };

  updateMeetingTags(tags: TagType[]) {
    const tagsIds = tags.map((tag) => tag.cod_tag);
    this._meetingsService.updateMeetingTags(this.current_user_id, this.current_meeting_id, tagsIds).subscribe({
      next: (_) => {
        this.handleSuccessfulTagUpdate();
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorTagUpdate(error);
      }
    })
  }

  handleSuccessfulTagUpdate() {
    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.TAGS_UPDATED');
  }

  handleErrorTagUpdate(error) {
    const initialTags: TagType[] = this.parseTagsIdsToTags(this.initialSelectedTagsIds);
    this.meetingForm.get('tags')?.setValue(initialTags);
    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_UPDATING_TAGS');
  }

  parseTagsIdsToTags(tagsIds: PartialTagType[]): TagType[] {
    const tags: TagType[] = tagsIds.map((tagId) => {
      const tag = this.tagsOptions.find((t) => t.cod_tag === tagId.cod_tag);
      return tag;
    });

    return tags;
  }

  onSelectKpis(event: {selectedOptions: KpiType[]}) {
    this.meetingForm.get('kpis')?.setValue(event.selectedOptions);

    this.updateMeetingKpis(event.selectedOptions);
  };

  updateMeetingKpis(kpis: KpiType[]) {
    const kpisIds = kpis.map((kpi) => kpi.uuid);
    const params = {
      cod_user: this.obj_user.id,
      cod_meeting: this.current_meeting_id,
      arr_kpis: kpisIds
    }
    this._kpisService.postKpis(params).subscribe({
      next: (response) => {
        this.handleSuccessfulKpiUpdate();
      },
      error: (error) => {
        console.log('error', error);
        this.handleErrorKpiUpdate(error);
      }
    })
  }

  handleSuccessfulKpiUpdate() {
    this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.KPIS_UPDATED');
  }

  handleErrorKpiUpdate(error) {
    const initialKpis: KpiType[] = this.parseKpisUuidToKpis(this.initialSelectedKpisUuid);
    this.meetingForm.get('kpis')?.setValue(initialKpis);
    this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_UPDATING_KPIS');
  }

  parseKpisUuidToKpis(kpisUuid: PartialKpiType[]): KpiType[] {
    const kpis: KpiType[] = kpisUuid.map((kpiUuid) => {
      const kpi = this.kpisOptions.find((k) => k.uuid === kpiUuid.uuid);
      return kpi;
    });

    return kpis;
  }

  onSelectMeetingLocation(event: {selectedOptions: LocationType[]}) {
    const selectedOption = event.selectedOptions[0];
    this.meetingForm.get('location')?.setValue(selectedOption);
    this.meetingForm.get('location')?.markAsDirty();

    this.resetFormField('department');
    this.resetFormField('type');
    this.resetFormField('tags');

    this.getDepartmentOptions();
    this.getMeetingTypeOptions();
    this.getTagsOptions();
  }

  onSelectMeetingType(event: {selectedOptions: MeetingType[]}) {
    const newMeetingType = event.selectedOptions[0];

    if (this.meetingTypeState.confirmedToChange || this.meetingTypeState.isCurrentMeetingTypeDeleted) {
      this.setMeetingTypeValue(newMeetingType);
      return;
    }

    if (!this.meetingTypeState.confirmedToChange) {
      this.meetingTypeState.meetingTypeWaitingConfirmation = newMeetingType;
      this.onOpenChangeMeetingTypeConfirmation();
    }
  }

  setMeetingTypeValue(newMeetingType: MeetingType = null): void {
    this.meetingForm.get('type')?.setValue(newMeetingType);
    this.meetingForm.get('type')?.markAsDirty();

    if (newMeetingType) {
      this.getMeetingTypeFrequency(newMeetingType.cod_meeting_type);
    }
  }

  onConfirmChangeMeetingType() {
    this.meetingTypeState.confirmedToChange = true;

    const newMeetingType = this.meetingTypeState.meetingTypeWaitingConfirmation;
    this.setMeetingTypeValue(newMeetingType);

    this.meetingTypeState.meetingTypeWaitingConfirmation = null;
  };

  cancelMeetingTypeChange() {
    this.meetingTypeState.confirmedToChange = false;
    this.meetingForm.get('type')?.setValue(this.meetingTypeState.initialMeetingType);
    this.meetingTypeState.meetingTypeWaitingConfirmation = null;
  };

  onCloseDrawer() {
    if (this.meetingForm.dirty) {
      this.onOpenCloseDrawerConfirmation();
      return;
    }
    
    this.closeDrawer();
  }

  closeDrawer() {
    this.onClose.emit(false);
  }

  onSaveMeetingsDetails() {
    this.onOpenSaveMeetingDetailsConfirmation();
  }

  onConfirmSaveMeetingsDetails() {
    if (this.meetingForm.invalid) {
      this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.INVALID_DETAILS_FIELDS');
      return;
    }

    this.syncActionsState.updatingMeeting = true;
    const meetingForm = this.meetingForm.value;

    const params: UpdateMeetingDetailsParams = {
      cod_user: this.current_user_id,
      meetingDetails: {
        cod_meetings: this.current_meeting_id,
        meeting_name: meetingForm.name,
        cod_meeting_type: meetingForm.type.cod_meeting_type,
        cod_location: meetingForm.location.cod_location,
        cod_department: meetingForm.department.cod_department,
        cod_day_of_week: meetingForm.day_of_the_week.cod_day_of_week,
        only_participants: meetingForm.is_only_participants
      }
    };
    this.updateMeetingDetails(params);
  }

  updateMeetingDetails(meetingDetailsParams: UpdateMeetingDetailsParams) {

    this._meetingsService.updateMeetingDetails(meetingDetailsParams).subscribe({
      next: (response) => {
        this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.MEETING_DETAILS_UPDATED');
      },
      error: (error) => {
        console.log('error', error);
        this.syncActionsState.updatingMeeting = false;
        this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_UPDATING_MEETING_DETAILS');
      },
      complete: () => {
        this.onDetailsChanged.emit();
        this.closeDrawer();
        this.meetingForm.markAsPristine();
        this.syncActionsState.updatingMeeting = false;
      }
    });
  }

  deleteMeeting() {
    this.syncActionsState.deletingMeeting = true;
    this.closeDrawer();
    const params = {
      cod_user: this.current_user_id,
      cod_meeting: this.current_meeting_id
    }
    this._meetingsService.deleteMeeting(params).subscribe({
      next: (_) => {
        this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.MEETING_DELETED');
      },
      error: (error) => {
        console.log('error', error);
        this.errorHelper(error, 'MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_DELETING_MEETING');
        this.syncActionsState.deletingMeeting = true;
      },
      complete: () => {
        this.syncActionsState.deletingMeeting = false;
      }
    });
  }

  errorHelper(httpError: {error: ErrorResponseType}, defaultTranslation: string) {
    const errorCode = httpError?.error?.content?.code;
    const translationString = typeof errorCode === 'string' && errorCode.includes('IL') 
      ? `ERROR.${errorCode}` 
      : defaultTranslation;

    this.showErrorToastNotification(translationString);
  }

  restoreMeeting() {
    this.closeDrawer();
    const params = {
      cod_user: this.current_user_id,
      cod_meeting: this.current_meeting_id
    }
    this.syncActionsState.restoringMeeting = true;
    this._meetingsService.restoreMeeting(params).subscribe({
      next: (_) => {
        this.showSuccessToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_SUCCESS.MEETING_RESTORED');
      },
      error: (error) => {
        console.log('error', error);
        this.showErrorToastNotification('MY_MEETINGS.EDIT_MEETING_DRAWER.FEEDBACK_ERROR.ERROR_RESTORING_MEETING');
        this.syncActionsState.restoringMeeting = false;
      },
      complete: () => {
        this.syncActionsState.restoringMeeting = false;
      }
    });
  }

  openConfirmationModal(
    key: string, 
    title: string, 
    description: string, 
    confirmAction: () => void,
    cancelAction: () => void = this.onCloseConfirmationModal.bind(this),
    confirmButtonLabel: string = 'MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRM',
    confirmButtonClass: string = 'button-save'
  ) {
    this.currentConfirmationModal = {
      key,
      title,
      description,
      confirmAction,
      cancelAction,
      confirmButtonLabel,
      confirmButtonClass
    };
  };

  onCloseConfirmationModal() {
    this.currentConfirmationModal = null;
  }

  onConfirmModalAction() {
    if (this.currentConfirmationModal?.confirmAction) {
      this.currentConfirmationModal.confirmAction();
    }
    this.onCloseConfirmationModal();
  }

  onCancelConfirmationModalAction() {
    if (this.currentConfirmationModal?.cancelAction) {
      this.currentConfirmationModal.cancelAction();
    }
    this.onCloseConfirmationModal();
  }

  onOpenChangeMeetingTypeConfirmation() {
    this.openConfirmationModal(
      'confirmChangeMeetingType',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.WARNING',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.CHANGE_TYPE',
      () => this.onConfirmChangeMeetingType(),
      () => this.cancelMeetingTypeChange(),
    );
  }

  onOpenDeleteMeetingConfirmation() {
    this.openConfirmationModal(
      'confirmDelete',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.WARNING',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.DELETE_MEETING',
      () => this.deleteMeeting(),
      () => this.onCloseConfirmationModal(),
      'MY_MEETINGS.EDIT_MEETING_DRAWER.BUTTONS.DELETE',
      'button-save button-danger'
    );
  }

  onOpenRestoreMeetingConfirmation() {
    this.openConfirmationModal(
      'confirmRestore',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.RESTORE',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.RESTORE_MEETING',
      () => this.restoreMeeting()
    );
  }

  onOpenCloseDrawerConfirmation() {
    this.openConfirmationModal(
      'confirmClose',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.CLOSE',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.UNSAVED_CHANGES',
      () => this.closeDrawer()
    );
  }

  onOpenSaveMeetingDetailsConfirmation() {
    this.openConfirmationModal(
      'confirmSave',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.SAVE',
      'MY_MEETINGS.EDIT_MEETING_DRAWER.CONFIRMATION.SAVE_CHANGES',
      () => this.onConfirmSaveMeetingsDetails()
    );
  }

  showSuccessToastNotification(message: string) {
    this._notificationService.success({
      text: message,
      translate: true
    })
  }

  showErrorToastNotification(message: string) {
    this._notificationService.error({
      text: message,
      translate: true
    })
  }
}
