import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {LogbooksService} from '../../logbooks/services/logbooks.service';

@Component({
  selector: 'chart-logbook',
  templateUrl: './chart-logbook.component.html',
  styleUrls: ['./chart-logbook.component.css']
})
export class ChartLogbookComponent implements OnChanges{
  @Input() date;
  @Input() cod_user = this._authService.getAuthenticatedUser().id;
  @Input() cod_manager;
  @Input() chartTitle;
  @Input() updateChart;
  
  public chart_data = {};
  public bol_loading = true;
  public bol_show_hint = false;
  parent_width: any;

  constructor(private _authService:AuthService, private _logbookService:LogbooksService) {

  }
  onResize(){
    let w = document.getElementById("chart-logbook-container").clientWidth;
    this.parent_width = w >= 180 ? 180 : w;
  }

  ngOnInit(){
    this.onResize();
    if(this.cod_manager) {
       this.updateInfoChart();
    }
  }

  ngOnChanges(changes: SimpleChanges):void {
    if(changes.updateChart){
      if(changes.updateChart.currentValue){
        this.updateInfoChart();
      }      
    }
   
  }

  updateInfoChart() {
    if(this.cod_manager) {
      if(!this.date){
        this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
      }
      else if(this.date.length < 8) {
        this.date += '-01';
      }
      this._logbookService.getTeamCalcLogbook(this.cod_manager, this.date, this.cod_user).subscribe(
        data => {
          if (data.code === 888 && data.content.code === 678 ) {
            let objData = {
              v_count_executed:0,
              v_count_executed_not_point:0,
              v_count_not_calculated:0,
              v_count_not_executed:0,
              v_count_not_point:0,
              v_count_not_scale:0
            }
            if (data.content.results.v_array_return){
              data.content.results.v_array_return.forEach(element => {
                objData.v_count_executed = objData.v_count_executed + element.v_count_executed;
                objData.v_count_executed_not_point = objData.v_count_executed_not_point + element.v_count_executed_not_point;
                objData.v_count_not_calculated = objData.v_count_not_calculated + element.v_count_not_calculated;
                objData.v_count_not_executed = objData.v_count_not_executed + element.v_count_not_executed;
                objData.v_count_not_point = objData.v_count_not_point + element.v_count_not_point;
                objData.v_count_not_scale = objData.v_count_not_scale + element.v_count_not_scale;
              });
            }

            this.updateSeriesData(objData);
          } else {
            console.log(data.content);
          }
        },
        err => { this._authService.errCheck(err); console.log(err); }
        
      );
    } else {
      if(!this.date){
        this.date = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date().getDate())).slice(-2);
      }
      else if(this.date.length < 8) {
        this.date += '-01';
      }
      this._logbookService.getUserCalcLogbook(this.cod_user, this.date, this._authService.getAuthenticatedUser().id).subscribe(
        data => {
          if (data.code === 888 && data.content.code === 678 ) {
            const objData = (data.content.results.v_array_return) 
                              ? data.content.results.v_array_return
                              : {
                                cod_logbooks: null,
                                v_count_executed:0,
                                v_count_executed_not_point:0,
                                v_count_not_calculated:0,
                                v_count_not_executed:0,
                                v_counts:0
                              };
            
            this.updateSeriesData(objData);
          } else {
            console.log(data.content);
          }
        },
        err => { this._authService.errCheck(err); console.log(err); }
        
      );
    }
  }

  updateSeriesData(data) {
    if(data){
      this.chart_data = {
        total: (data.v_count_executed + data.v_count_executed_not_point + data.v_count_not_executed),
        value: (data.v_count_executed + data.v_count_executed_not_point)
      };
    }
    this.bol_loading = false;
  }

}