// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-box {
    width: 380px; 
    border-radius: 4px;
    background-color: #4771E6;
    color: white;
    padding-left: 10px;
    padding-right: 10px; 
    margin-top: 10px;
    /* display: none; */
}

.mimecast-link {
    text-decoration: underline;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/reports-routines/reports-routines.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB","sourcesContent":[".message-box {\r\n    width: 380px; \r\n    border-radius: 4px;\r\n    background-color: #4771E6;\r\n    color: white;\r\n    padding-left: 10px;\r\n    padding-right: 10px; \r\n    margin-top: 10px;\r\n    /* display: none; */\r\n}\r\n\r\n.mimecast-link {\r\n    text-decoration: underline;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
