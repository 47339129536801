import * as moment from "moment";
import { MachineThreshold } from "./machine-threshold.model";
import { Shift } from "./shift";
const dependantFilters = [
  "beginDate",
  "endDate",
  "ialShiftId",
  "subareaAcadiaId",
  "subareaSapId",
  "machineAcadiaId",
  "machineSapId",
  "machineThresholds",
  "hour",
  "timezone",
];
export default class CockpitFilter {
  view = "operator";
  locations = [];
  areas = [];
  subareas = [];
  equipments = [];
  machines = [];
  checklists = [];
  beginDate: string = null;
  endDate: string = null;
  shift: Shift = null;
  machineName = null;
  selectedDate = null;
  userId = "";
  areaAcadiaId = [];
  subareaAcadiaId = [];
  subareaSapId = [];
  machineAcadiaId = [];
  machineSapId = [];
  language = "en";
  timezone = "0";
  machineThresholds: MachineThreshold[] = [];
  piDashboard: string = null;

  public filterValid(requiredFilters: string[]): string[] {
    if (requiredFilters.length === 0) return [];

    const valueValid = (
      value: string | moment.Moment | number | Array<string | number>
    ): boolean => {
      if (moment.isMoment(value)) return true;
      if (Array.isArray(value) && value.length === 0) return false;
      else if (!value) return false;
      else if (typeof value == "number" && isNaN(value)) return false;
      else if (value.toString().length === 0) return false;
      else return true;
    };

    const filterValid = (filter: string): boolean => {
      if (dependantFilters.includes(filter)) return true;
      const value = this[filter];
      return valueValid(value);
    };

    return requiredFilters.filter((f) => !filterValid(f));
  }

  static fromPartial(filters: Partial<CockpitFilter>): CockpitFilter {
    const filter = new CockpitFilter();
    Object.assign(filter, filters);
    return filter;
  }

  public excludeSensitiveFilters(payload: { cod_user: string }): CockpitFilter {
    this.userId = payload.cod_user;
    return this;
  }

  public removeFields(): CockpitFilter {
    /* Remove fields that are not necessary to save user options */
    delete this.userId;
    delete this.timezone;
    delete this.language;
    delete this.machineName;
    delete this.areaAcadiaId;
    delete this.subareaSapId;
    delete this.subareaAcadiaId;
    delete this.machineSapId;
    delete this.machineAcadiaId;
    delete this.beginDate;
    delete this.endDate;
    delete this.machineThresholds;
    delete this.piDashboard;
    return this;
  }

  public removeUnnecessaryFields(): CockpitFilter {
    /* Remove fields that are not necessary to share the embed report */
    delete this.userId;
    delete this.machineName;
    delete this.areaAcadiaId;
    delete this.subareaSapId;
    delete this.subareaAcadiaId;
    delete this.machineSapId;
    delete this.machineAcadiaId;
    delete this.beginDate;
    delete this.endDate;
    delete this.machineThresholds;
    delete this.piDashboard;
    return this;
  }

  public toUrlParams(): { [key: string]: string } {
    return Object.entries(this)
      .filter(([_, value]) => value)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(",")}`;
        }
        if (key == "shift" && value) { 
          value as Shift;
          return `${key}=${value.id}`;
        }
        return `${key}=${value}`;

      }).reduce((acc, curr) => {
        return {
          ...acc,
          [curr.split("=")[0]]: curr.split("=")[1],
        }
      }, {});
  }

  public removeInvalid() {
    const validFilters = this.filterValid([]);
    Object.keys(this).forEach((key) => {
      if (!this.valueValid(this[key]) && !validFilters.includes(key))
        delete this[key];
    });
  }

  private valueValid(
    value: string | moment.Moment | number | Array<string | number>
  ): boolean {
    if (!value) return false;
    if (moment.isMoment(value)) return true;
    if (Array.isArray(value)) {
      if (value.length === 0) return false;
      if (value.every((v) => v == 0)) return false;
    }
    if (typeof value == "number" && isNaN(value)) return false;
    if (value.toString().length === 0) return false;
    return true;
  }
}
