import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

import {ListItem} from '../models/list-item.model';

@Injectable()
export class FormsDynamicFiltersService {
    constructor(
        private _mainService: MainService
    ) {
    }

    listVariations(cod_user: number): Observable<ListItem[]> {
        return this._mainService.get(`/filters/variations/${cod_user}`);
    }

    listAreas(arr_variations: ListItem[], cod_user: number): Observable<ListItem[]> {
        const str_cod_variations = arr_variations.map(i => i.cod_item).join();
        return this._mainService.get(`/filters/areas/${str_cod_variations}/${cod_user}`);
    }

    listUsers(arr_variations: ListItem[], arr_areas: ListItem[], cod_user: number): Observable<ListItem[]> {
        const str_cod_variations = arr_variations.map(i => i.cod_item).join();
        const str_cod_areas = arr_areas.map(i => i.cod_item).join();
        return this._mainService.get(`/filters/users?str_cod_variations=${str_cod_variations}&str_cod_areas=${str_cod_areas}&cod_user=${cod_user}`);
    }

    listForms(arr_variations: ListItem[],p_cod_user,p_dsc_page_privilege): Observable<ListItem[]> {
        const str_cod_variations = arr_variations.map(i => i.cod_item).join();
        return this._mainService.get('/filters/forms?str_cod_variations='+str_cod_variations+'&cod_user='+p_cod_user+'&dsc_page_privilege='+p_dsc_page_privilege);
    }

    listMeetings(id,): Observable<any> {
        return this._mainService.get('/filters/meetings/' + id);
    }

    listFormsByLogbook(params): Observable<any> {
        return this._mainService.post('/filters/forms-by-logbook', params);
    }

    getArrayContent(data: any) {
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }

    getArrayContentNoResult(data: any) {
        return data.content.length === undefined ? [data.content] : data.content;
    }
}
