// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-margin{
    margin-top: 10px;
}

.old-access-button{
    width: 100%;
    margin-top: 40px;
    line-height: 45px;
    border-radius: 45px;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/main/access/auth/auth.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".input-margin{\n    margin-top: 10px;\n}\n\n.old-access-button{\n    width: 100%;\n    margin-top: 40px;\n    line-height: 45px;\n    border-radius: 45px;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
