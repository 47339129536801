import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsInputComponent } from '../forms-input/forms-input.component';

@Component({
    selector: 'date-input',
    templateUrl: './../forms-input/forms-input.component.html',
    styleUrls: ['./../forms-input/forms-input.component.css']
})

export class DateInputComponent extends FormsInputComponent<string> {
    
    type: string = 'date';

    constructor() {
        super();
    }

    protected validationRule() : boolean {
        return !((isNaN(new Date(this.data).getTime())) || this.data === null || this.data === '' );
    }

}
