import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hyper-link-modal',
  templateUrl: './hyper-link-modal.component.html',
  styleUrls: ['./hyper-link-modal.component.css']
})
export class HyperLinkModalComponent {

  @Output('onHyperlinkChange') onHyperlinkChange = new EventEmitter<string>(); 
  @Output('onHyperlinkCancel') onHyperlinkCancel = new EventEmitter<any>(); 

  @Input('showModal') showModal: boolean = false;
  modalHyperlinkObject = {
    description: '',
    url: '',
    newTab: false
}

  constructor() { }

  addHyperlink() {
    if(this.modalHyperlinkObject.url.length > 0) { 
        const elementAttributes = ` href="http://${this.modalHyperlinkObject.url}" target="${this.modalHyperlinkObject.newTab ? '_blank' : '_self'}"`;
        const aHTMLElement = `<a ${elementAttributes}> ${this.modalHyperlinkObject.description} </a>`;
        this.onHyperlinkChange.emit(aHTMLElement)
    }
    this.cancelHyperLink();
}

cancelHyperLink() {
    this.modalHyperlinkObject.description = ''
    this.modalHyperlinkObject.url = ''
    this.modalHyperlinkObject.newTab = false;
    this.onHyperlinkCancel.emit();
}

  openNewTab(answer) {
    this.modalHyperlinkObject.newTab = answer
}

}
