import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AuthService} from '../../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {SocketService} from '../../../main/services/socket.service';
import {ActionsService} from '../../services/actions.service';
import { linkfyText } from 'app/helpers/general-helper';

@Component({
    selector: 'input-comment-action',
    templateUrl: './input-comment-action.component.html',
    styleUrls: ['./input-comment-action.component.css']
})
export class InputCommentActionComponent implements OnChanges {

    isEditing: boolean;
    showError = false;
    errorMessage = '';
    strNewText: string;
    objUser: any;

    @Input() comment: any;
    @Input() canEditMessages: boolean;
    @Input() action: any;
    @Output() onSaveMessage: EventEmitter<any> = new EventEmitter<any>();

    constructor(private _authService: AuthService, public actionsService: ActionsService, private _translateService: TranslateService, private _socketService: SocketService) {
        this.isEditing = false;
        this.strNewText = '';
        this.objUser = _authService.getAuthenticatedUser();
    }

    setEditingComent($event) {
        this.isEditing = !this.isEditing;
        this.strNewText = this.comment.str_text;

        if (this.isEditing) {
            let $this = this;
            window.setTimeout(function () {
                document.getElementById('commentInputId').focus();
                $this.setAutoHeight();
            }, 10);
        }
    }

    revertComment($event) {
        this.strNewText = this.comment.str_text;
        this.isEditing = false;
    }

    saveComment($event) {
        let keyCode = $event.which || $event.keyCode;

        if (keyCode == 27) {
            this.revertComment(null);
        }

        if (keyCode == 13) {
            $event.preventDefault();

            this.strNewText = this.strNewText.replace(/\n$/, '').trim();

            if (!this.strNewText) {
                return false;
            }

            let objParam = {
                cod_actions_comments: this.comment.cod_actions_comments,
                cod_action: this.action,
                cod_user: this.objUser.id,
                str_text: this.strNewText
            };

            this._putComment(objParam);
        }
        else {
            this.setAutoHeight();
        }
    }

    private setAutoHeight() {
        let textArea = document.getElementById('commentInputId');
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

    private _putComment(objParam: any) {
        this.actionsService.putMeetingActionMessage(objParam).subscribe(res => {
            if (res.type != 'error') {
                this._socketService.saveAction({cod_action: objParam.cod_action});
                if (this.onSaveMessage) {
                    this.onSaveMessage.emit(objParam.cod_action);
                }
            }
        }, err => {
            let e = JSON.parse(err._body);
            console.log(e.content);
            this._authService.errCheck(err);
        });

    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.comment != typeof undefined) {
            this.strNewText = changes.comment.currentValue.str_text;
        }
    }

    linkfyComment(text: string) {
        return linkfyText(text)
    }
}
