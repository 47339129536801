import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
  selector: 'app-my-team-topic-1',
  templateUrl: './my-team-topic-1.component.html',
  styleUrls: ['./my-team-topic-1.component.css']
})
export class MyTeamTopic1Component implements OnInit {

  constructor(public userPreferencesService:UserPreferencesService) { }

  ngOnInit() {
  }

}
