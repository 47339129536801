// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-status {
    background-color: #C0C0C0;
    border: 0px;
    box-shadow: none;
    border-radius: 3px;
    margin-right: 1em;
    color: #FFFFFF;
    font-size: 0.8125em;
    padding: 0 12px;
    line-height: 34px;
}

.btn-status-not-done {
    background-color: #ff0400 !important;
}

.btn-status-realized {
    background-color: #0BAF0B !important;
}

.btn-status-checked {
    background-color: var(--ui-primary) !important;
    color: var(--text-ui-primary);
}

.btn-unmark {
    background: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/routine-status-buttons/routine-status-buttons.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IAEX,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,8CAA8C;IAC9C,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".btn-status {\n    background-color: #C0C0C0;\n    border: 0px;\n    -webkit-box-shadow: none;\n    box-shadow: none;\n    border-radius: 3px;\n    margin-right: 1em;\n    color: #FFFFFF;\n    font-size: 0.8125em;\n    padding: 0 12px;\n    line-height: 34px;\n}\n\n.btn-status-not-done {\n    background-color: #ff0400 !important;\n}\n\n.btn-status-realized {\n    background-color: #0BAF0B !important;\n}\n\n.btn-status-checked {\n    background-color: var(--ui-primary) !important;\n    color: var(--text-ui-primary);\n}\n\n.btn-unmark {\n    background: #ff0000;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
