import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FiveWhyService } from "../../../../five-why/services/five-why.service";
import { AuthService } from "../../../../main/access/services/auth.service";
import { Day, ShiftConfigItem } from "app/cockpit/model/shift";

@Component({
  selector: "app-shift-persist",
  templateUrl: "./shift-persist.component.html",
  styleUrls: ["./shift-persist.component.css"],
})
export class ShiftPersistComponent implements OnInit {
  @Input("shiftConfig") shiftConfig: ShiftConfigItem;
  @Output() backEvent = new EventEmitter();

  loading = true;
  error = false;
  errorMessage?: string;

  config: ShiftConfigItem;

  constructor(
    private _translateService: TranslateService,
    private _fiveWhyService: FiveWhyService,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this._fiveWhyService
      .getShift({
        cod_locations: +this.shiftConfig.cod_locations,
        cod_areas: +this.shiftConfig.cod_areas,
        cod_user: this._authService.getAuthenticatedUser().id,
      })
      .subscribe((data) => {
        if (data?.length) this.shiftConfig.days = data;
      });
    this.loading = false;
  }

  closeErrorMessage() {
    this.errorMessage = null;
  }

  backSelected() {
    this.backEvent.emit();
  }

  saveDay(day: Day) {
    const dayToUpdate = this.shiftConfig.days.findIndex(
      (el) => el.weekday === day.weekday
    );
    this.shiftConfig.days[dayToUpdate] = day;
    this._fiveWhyService
      .saveShift({
        cod_areas: this.shiftConfig.cod_areas,
        cod_locations: this.shiftConfig.cod_locations,
        cod_user: this._authService.getAuthenticatedUser().id,
        days: this.shiftConfig.days,
      })
      .subscribe();
  }
}
