import {AfterViewInit, Component} from '@angular/core';
import {AreaService} from '../services/area.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-area-consult',
    templateUrl: './area-consult.component.html',
    styleUrls: ['./area-consult.component.css']
})
export class AreaConsultComponent implements AfterViewInit {

    list: Object;
    searchTerm: string;
    searchTimer: any;
    isLoading: boolean = true;
    codAreas: number = 0;
    codUser: number;
    messageConfirmation: string = '';
    downloadCsvSetup: any = {};
    awaitConfirmDelete: boolean = false;
    showError: boolean = false;
    errorMessage: string = '';

    constructor(private _areaService: AreaService, private _authService: AuthService, private _translateService: TranslateService) {
        this.showError = false;
        this.errorMessage = '';
        this.searchTerm = '';
        this.list = {
            areas: []
        };
        this.searchTimer = null;
        this.codUser = this._authService.getAuthenticatedUser().id;

        this._translateService.get('LOGBOOK.IMPORT_AREAS_CONFIRMATION').subscribe(translate => {
            this.messageConfirmation = translate;
        });
    }

    ngAfterViewInit() {
        let objParam = {'cod_areas': 0, 'term': this.searchTerm};
        this._search();
    }

    public search() {
        this.codAreas = 0;
        if (!parseInt(this.searchTerm) && this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }

        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        let objParam = {'cod_areas': 0, 'term': this.searchTerm, 'cod_user':this.codUser, 'dsc_page_privilege':'NOT_PRIVILEGE'};
        this._areaService.getAreasList(objParam).subscribe(data => {
            this.list = {
                areas: this._areaService.getArrayContent(data)
            };
            this.isLoading = false;
        }, err => {
            let e = JSON.parse(err._body);
            this._showErrorMessage(e.content);
            this._authService.errCheck(err); 
        });
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        },
        err => { this._authService.errCheck(err); console.log(err); } );
    }
}
