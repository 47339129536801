import {Component, OnInit} from '@angular/core';
import {UserPreferencesService} from "../../../../user-preferences/services/user-preferences.service";

@Component({
    selector: 'app-meeting-topic-12',
    templateUrl: './meeting-topic-12.component.html',
    styleUrls: ['./meeting-topic-12.component.css']
})
export class MeetingTopic12Component implements OnInit {

    constructor(public userPreferencesService:UserPreferencesService) {
    }

    ngOnInit() {
    }

}
