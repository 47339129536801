import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, FormGroup, Validators } from '@angular/forms';
import {
  compareHours,
  nameFields,
  nameFieldsMap,
  ShiftComparison,
  shiftOverlapValidation,
} from './shift-persist-form-member.service';
import { Day, Shift } from '../../../../../../app/cockpit/model/shift';

@Component({
  selector: 'app-shift-persist-form-member',
  templateUrl: './shift-persist-form-member.component.html',
  styleUrls: ['./shift-persist-form-member.component.css'],
})
export class ShiftPersistFormMemberComponent implements OnInit {
  @Input('day') day: Day;
  @Input('simblings') simblings: [Day, Day];
  @Output() backEvent = new EventEmitter<Day>();
  ALLOWED_SHIFT = 3;
  shiftForm: FormGroup;
  expanded = false;
  submitted = false;
  nameFields = nameFields;
  nameFieldsMap = nameFieldsMap;
  ial_id: number[] = [2, 3, 4];

  constructor() {}

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  ngOnInit(): void {
    this.initForm();
    for (const shift of this.day.shifts) {
      this.addShiftForm(shift);
    }
  }

  initForm() {
    this.shiftForm = new FormGroup(
        {
          shifts: new FormArray([]),
        },
        { validators: shiftOverlapValidation }
    );
  }

  get formShift(): FormArray {
    return this.shiftForm.get('shifts') as FormArray;
  }

  private languageMap = {
    de: 'de-DE',
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    nl: 'nl-NL',
    pt: 'pt-PT',
    ru: 'ru-RU',
    ua: 'uk-UA', // Note: 'ua' is not a language code, 'uk' is the language code for Ukrainian
  };

  get weekday() {
    const userLang = localStorage.getItem('userPreferencesLanguage') || 'en';
    const format = Intl.DateTimeFormat(this.languageMap[userLang], { weekday: 'long' }).format;
    const { weekday } = this.day;
    // using the weekday number, we can get the weekday name
    // 1 = Monday, 2 = Tuesday, etc.
    // We can use a month that starts on a monday (1) to get the weekday name
    return format(new Date(2021, 10, weekday)).toUpperCase();
  }

  get shiftsNames() {
    return this.day.shifts.map((s) => s.name_en).join(', ');
  }

  showLang(control: UntypedFormControl) {
    control.setValue(!control.value);
  }

  concatenateNameFields(formGroup: UntypedFormGroup): string {
    const values = nameFields.map(
      (fieldName) => formGroup.controls[fieldName]?.value
    );
    const nonEmptyValues = values.filter((value) => value !== '');
    return nonEmptyValues.join(', ');
  }

  private addShiftForm(shift: Shift | null = null) {
    const formGroup = this.formGet(shift);
    this.formShift.push(formGroup);
  }

  private formGet(shift: Shift) {
    const r = () => {
      return [Validators.required];
    };

    const formId = shift?.id || `D${this.day.weekday}S${this.formShift.length + 1}`;
    return new UntypedFormGroup({
      start: new UntypedFormControl(shift?.start || '00:00', r()),
      end: new UntypedFormControl(shift?.end || '00:00', r()),
      name_en: new UntypedFormControl(shift?.name_en || 'EN Translation', r()),
      name_de: new UntypedFormControl(shift?.name_de || 'DE Translation', r()),
      name_es: new UntypedFormControl(shift?.name_es || 'ES Translation', r()),
      name_fr: new UntypedFormControl(shift?.name_fr || 'FR Translation', r()),
      name_pt: new UntypedFormControl(shift?.name_pt || 'PT Translation', r()),
      name_ua: new UntypedFormControl(shift?.name_ua || 'UA Translation', r()),
      name_ru: new UntypedFormControl(shift?.name_ru || 'RU Translation', r()),
      name_nl: new UntypedFormControl(shift?.name_nl || 'NL Translation', r()),      
      id: new UntypedFormControl(formId, r()),
      showLang: new UntypedFormControl(false),
      ial_id: new UntypedFormControl(shift?.ial_id || '', r()),
    })
  }

  removeShift(index: number) {
    (this.shiftForm.get('shifts') as FormArray).removeAt(index);
  }

  undo() {
    const formArray = this.formShift.controls;
    formArray.forEach((control, i) => {
      control.setValue(this.formGet(this.day.shifts[i])?.value || null)
      control.updateValueAndValidity();
    });
  }

  public submit(): void {
    this.submitted = true;
    if (this.shiftForm.invalid) {
      return;
    }
    this.expanded = false;
    this.backEvent.emit({weekday: this.day.weekday, shifts: this.formShift.value} as Day);
  }

  public nextDayShift(index: number) {
    const shift: Shift = this.formShift.at(index).value;
    const { start, end } = shift;
    return compareHours(start, end) === ShiftComparison.After;
  }
}
