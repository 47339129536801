import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class RoutinesServiceStore {

    private readonly myTaskMainDate = new BehaviorSubject<string>(new Date().toString());
    public myTaskMainDate$ = this.myTaskMainDate.asObservable();

    setMyTaskMainDate(newDate: string): void {
        this.myTaskMainDate.next(newDate);
    }

    getMyTaskMainDate(): string {
        return this.myTaskMainDate.getValue();
    }
}