import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {MobileTopic1Component} from './mobile-topic-1/mobile-topic-1.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [MobileTopic1Component],
    declarations: [MobileTopic1Component],
    providers: []
})
export class InfoMobileModule {
}
