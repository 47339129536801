// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormsDynamic__Show__CreateAction {
	display: block;
}

.choice-option {
	display: block;
	margin-right: 10px;
	color: #5f5f5f;
	font-weight: normal;
}

.action-form {
	background: #e0e0e0;
	padding: 15px;
	margin-top: 4px;
}

.form-group {
	width: 100%;
}

.action-button {
	position: relative;
	bottom: 5px;
	right: 1px;
}

.pull-top {
	padding-top: 10%;
}

.padding-right {
	padding-right: 100%;
}

.width-owner {
	font-size: 80%;
	width: 85%;
}

.text-overflow-owner {
	text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/action-create/action-create.component.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;CACd,kBAAkB;CAClB,cAAc;CACd,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,aAAa;CACb,eAAe;AAChB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,UAAU;AACX;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,UAAU;AACX;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".FormsDynamic__Show__CreateAction {\n\tdisplay: block;\n}\n\n.choice-option {\n\tdisplay: block;\n\tmargin-right: 10px;\n\tcolor: #5f5f5f;\n\tfont-weight: normal;\n}\n\n.action-form {\n\tbackground: #e0e0e0;\n\tpadding: 15px;\n\tmargin-top: 4px;\n}\n\n.form-group {\n\twidth: 100%;\n}\n\n.action-button {\n\tposition: relative;\n\tbottom: 5px;\n\tright: 1px;\n}\n\n.pull-top {\n\tpadding-top: 10%;\n}\n\n.padding-right {\n\tpadding-right: 100%;\n}\n\n.width-owner {\n\tfont-size: 80%;\n\twidth: 85%;\n}\n\n.text-overflow-owner {\n\ttext-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
