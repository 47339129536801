// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-title {
    padding: 10px;
    color: #666;
}

chart{
    display: block;
    width: 95%;
    height: 160px;
}

@media only screen and (min-width: 1900px) {
    .chart-action-status {
        margin: 0 auto;
        display:block;
        width: 380px;
        color: #666;
    }
    
    .width-height-meeting{
        width: 380px; 
        height: 160px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1899px) {
    .chart-action-status {
        margin: 0 auto;
        display:block;
        width: 300px;
        color: #666;
    }
    
    .width-height-meeting{
        width: 300px; 
        height: 150px;
    }
}


@media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .chart-action-status {
        margin: 0 auto;
        display:block;
        width: 280px;
        color: #666;
    }
    
    .width-height-meeting{
        width: 280px; 
        height: 160px;
    }
}

@media only screen and (min-width: 1080px) and (max-width: 1365px) {
    .chart-action-status {
        margin: 0 auto;
        display:block;
        width: 250px;
        color: #666;
    }
    
    .width-height-meeting{
        width: 250px; 
        height: 160px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/components/chart-meeting/chart-meeting.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,cAAc;IACd,UAAU;IACV,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,aAAa;QACb,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,aAAa;QACb,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;;AAGA;IACI;QACI,cAAc;QACd,aAAa;QACb,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,aAAa;QACb,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;AACJ","sourcesContent":[".chart-title {\n    padding: 10px;\n    color: #666;\n}\n\nchart{\n    display: block;\n    width: 95%;\n    height: 160px;\n}\n\n@media only screen and (min-width: 1900px) {\n    .chart-action-status {\n        margin: 0 auto;\n        display:block;\n        width: 380px;\n        color: #666;\n    }\n    \n    .width-height-meeting{\n        width: 380px; \n        height: 160px;\n    }\n}\n\n@media only screen and (min-width: 1441px) and (max-width: 1899px) {\n    .chart-action-status {\n        margin: 0 auto;\n        display:block;\n        width: 300px;\n        color: #666;\n    }\n    \n    .width-height-meeting{\n        width: 300px; \n        height: 150px;\n    }\n}\n\n\n@media only screen and (min-width: 1366px) and (max-width: 1440px) {\n    .chart-action-status {\n        margin: 0 auto;\n        display:block;\n        width: 280px;\n        color: #666;\n    }\n    \n    .width-height-meeting{\n        width: 280px; \n        height: 160px;\n    }\n}\n\n@media only screen and (min-width: 1080px) and (max-width: 1365px) {\n    .chart-action-status {\n        margin: 0 auto;\n        display:block;\n        width: 250px;\n        color: #666;\n    }\n    \n    .width-height-meeting{\n        width: 250px; \n        height: 160px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
