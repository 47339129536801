import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MainService } from "./main.service";
import { UploadMainService } from "./upload-main.service";
import { HttpClient } from "@angular/common/http";
// import { AuthService } from "app/main/access/services/auth.service";

@Injectable()
export class UserPreferencesService {
  constructor(
    private _http: HttpClient,
    private _mainService: MainService,
    private _uploadMainService: UploadMainService
  ) {}

  /**
   *  ALL PREFERENCES METHODS
   */
  getUserPreferences(cod_user): Observable<any> {
    const path = "/get-user-preferences/" + cod_user;
    return this._mainService.get(path);
  }

  /**
   *  PREFERENCE BY AMBEV ID
   */
  getUserPreferencesByAmbevId(ambev_id, pref_key, cod_user): Observable<any> {
    const path =
      "/get-user-preferences-ambev-id/" +
      ambev_id +
      "/" +
      pref_key +
      "/" +
      cod_user;
    return this._mainService.get(path);
  }

  /**
   *  BACKGROUND METHODS
   */
  getBackgroundImages(): Observable<any> {
    return this._http
      .get("../../../assets/images/background-images/list-images.json")
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  hasLocalBackground(): Boolean {
    return localStorage.getItem("userPreferencesBackground") ? true : false;
  }

  setLocalBackground(path: string) {
    localStorage.setItem("userPreferencesBackground", path);
  }

  getLocalBackground(): String {
    return localStorage.getItem("userPreferencesBackground");
  }

  postBackground(params): Observable<any> {
    const path = "/background";
    return this._mainService.post(path, params);
  }

  getBackground(cod_user): Observable<any> {
    const path = "/background/" + cod_user;
    return this._mainService.get(path);
  }

  deleteBackground() {
    localStorage.removeItem("userPreferencesBackground");
  }

  /**
   *  PICTURE METHODS
   */
  hasLocalPicture(): Boolean {
    return localStorage.getItem("userPreferencesPicture") ? true : false;
  }

  setLocalPicture(path: string) {
    localStorage.setItem("userPreferencesPicture", path);
  }

  getLocalPicture(): String {
    return localStorage.getItem("userPreferencesPicture");
  }

  postPicture(params): Observable<any> {
    const path = "/picture";
    return this._mainService.post(path, params);
  }

  getPicture(cod_user): Observable<any> {
    const path = "/picture/" + cod_user;
    return this._mainService.get(path);
  }

  deletePicture() {
    localStorage.removeItem("userPreferencesPicture");
  }

  /*
   * CSC DELIMITER OPTIONS
   */
  hasLocalCsvDelimiter(): Boolean {
    return localStorage.getItem("userPreferencesCsvdelimiter") ? true : false;
  }

  setLocalCsvDelimiter(delimiter: string) {
    localStorage.setItem("userPreferencesCsvDelimiter", delimiter);
  }

  getLocalCsvDelimiter(): string {
    return localStorage.getItem("userPreferencesCsvDelimiter");
  }

  postCsvDelimiter(params): Observable<any> {
    const path = "/language/csv-delimiter";
    return this._mainService.post(path, params);
  }

  getCsvDelimiter(cod_user): Observable<any> {
    const path = "/language/csv-delimiter/" + cod_user;
    return this._mainService.get(path);
  }

  hasLocalDateFormat(): Boolean {
    return localStorage.getItem("userPreferencesDateFormat") ? true : false;
  }

  /**
   *  LANGUAGE METHODS
   */
  hasLocalLanguage(): Boolean {
    return localStorage.getItem("userPreferencesLanguage") ? true : false;
  }

  setLocalLanguage(path: string) {
    localStorage.setItem("userPreferencesLanguage", path);
  }

  getLocalLanguage(): string {
    return localStorage.getItem("userPreferencesLanguage");
  }

  getLocalDecimalDelimiter(): string {
    return localStorage.getItem("decimalDelimiter");
  }

  postLanguage(params): Observable<any> {
    const path = "/language";
    return this._mainService.post(path, params);
  }

  postActionNotificationVisible(params): Observable<any> {
    const path = "/action-notification-visible";
    return this._mainService.post(path, params);
  }

  getLanguage(cod_user): Observable<any> {
    const path = "/language/" + cod_user;
    return this._mainService.get(path);
  }

  getImageUser(path): Observable<any> {
    return this._mainService.getFile(path);
  }

  deleteLanguage() {
    localStorage.removeItem("userPreferencesLanguage");
  }

  /**
   *
   * FORMS SAVE METHODS
   */
  postFormFilters(params): Observable<any> {    
    const path = "/forms-preference-filters";
    return this._mainService.post(path, params);
  }

  postFormFilterDates(cod_user, initDate, finalDate): Observable<any> {
    const params = {
      cod_user,
      dates: {
        initDate,
        finalDate,
      },
    };
    const path = "/forms-preference-dates";
    return this._mainService.post(path, params);
  }

  /**
   *  COMMON METHODS
   */
  uploadFile(formData): Observable<any> {
    return this._uploadMainService.post("/upload", formData);
  }

  deleteUserPreferences() {
    this.deleteBackground();
    this.deleteLanguage();
    this.deletePicture();
  }

  getArrayContent(data: any) {
    return data.content.length >= 0 && !Array.isArray(data.content.results)
      ? [data.content.results]
      : data.content.results;
  }

  logout() {
    localStorage.clear();
  }

  deletePictureFromServer(params) {
    return this._uploadMainService.post("/delete-file", params);
  }

  getFile(url): Observable<any> {
    return this._mainService.getBlob(url);
  }

  postHideTutorial(params): Observable<any> {
    const path = "/hide-tutorial";
    return this._mainService.post(path, params);
  }

  getHideTutorial(cod_user): Observable<any> {
    const path = "/hide-tutorial/" + cod_user;
    return this._mainService.get(path);
  }

  getUsersNotifications(cod_user, str_img_path): Observable<any> {
    const path =
      "/users-notifications/" +
      cod_user +
      "/" +
      encodeURIComponent(str_img_path);
    return this._mainService.get(path);
  }

  postUserTimezone(params): Observable<any> {
    const path = "/user-timezone";
    return this._mainService.post(path, params);
  }

  hasLocalEmailNotifyPeriodicity(): Boolean {
    return localStorage.getItem("userPreferencesEmailNotifyPeriodicity")
      ? true
      : false;
  }

  setEmailNotifyPeriodicity(params): Observable<any> {
    const path = "/set-email-notification-periodicity";
    return this._mainService.post(path, params);
  }

  setDateFormat(params): Observable<any> {
    const path = "/set-date-format";
    return this._mainService.post(path, params);
  }

  setDecimalDelimiter(params): Observable<any> {
    const path = "/set-decimal-delimiter";
    return this._mainService.post(path, params);
  }

  setLocalEmailNotifyPeriodicity(emailPeriodicity: string) {
    localStorage.setItem(
      "userPreferencesEmailNotifyPeriodicity",
      emailPeriodicity
    );
  }

  setLocalDecimalDelimiter(decimalDelimiter: string) {
    localStorage.setItem("decimalDelimiter", decimalDelimiter);
  }

  hasDecimalDelimiter(): Boolean {
    return localStorage.getItem("decimalDelimiter") ? true : false;
  }

  setLocalDateFormat(dateFormat: string) {
    localStorage.setItem("userPreferencesDateFormat", dateFormat);
  }

  getLocalEmailNotifyPeriodicity(): string {
    return localStorage.getItem("userPreferencesEmailNotifyPeriodicity");
  }

  getLocalDateFormat(): string {
    return localStorage.getItem("userPreferencesDateFormat");
  }

  setLocalTimeZone(path: any) {
    localStorage.setItem("userPreferencesTimeZone", path);
  }

  getLocalTimeZone(): string {
    return localStorage.getItem("userPreferencesTimeZone");
  }

  hasLocalTimeZone(): Boolean {
    return localStorage.getItem("userPreferencesTimeZone") ? true : false;
  }

  getTimezonesList(cod_user): Observable<any> {
    const path = "/get-timezones-list/" + cod_user;
    return this._mainService.get(path);
  }

  toGMTString(offset): string {
    var sign = offset < 0 ? '-' : '+';
    var hours = Math.abs(Math.floor(offset));
    var minutes = Math.abs((offset % 1) * 60);
    return 'GMT ' + sign + this.padZero(hours) + ':' + this.padZero(minutes);
  }
  
  padZero(num): string {
    return num < 10 ? '0' + num : num;
  }

  getChoosenName(cod_user): Observable<any> {
    const path = "/get-choosen-name?cod_user=" + cod_user;
    return this._mainService.get(path);
  }

  changeChoosneName(params): Observable<any> {
    const path = '/change-choosen-name';
    return this._mainService.put(path, params);
  }
}