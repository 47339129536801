import {Injectable} from '@angular/core';
import {ListMaintenancePlanMainService} from './list-maintenance-plan-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListMaintenancePlanService {
  
  constructor(private _mainService: ListMaintenancePlanMainService) {
  }
  
  getMaintenancePlanList(param): Observable<any> {
    let path = '/forms/maintenance-plans?term=' + param.term + '&cod_user='+param.cod_user ;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
