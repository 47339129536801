// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-team-user {
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-team-user .row {
    width: 100%;
}

.no-records {
    padding: 10px 20px;
}

#my-team-list-search label {
    color: #FFFFFF;
}

#my-team-list-search .div-filter {
    margin-top: 10px;
}

.div-filter .label-title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 10px;
}

.list-content {
    padding: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/logbooks/logbooks-my-team/logbooks-my-team.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".my-team-user {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.my-team-user .row {\n    width: 100%;\n}\n\n.no-records {\n    padding: 10px 20px;\n}\n\n#my-team-list-search label {\n    color: #FFFFFF;\n}\n\n#my-team-list-search .div-filter {\n    margin-top: 10px;\n}\n\n.div-filter .label-title {\n    font-size: 16px;\n    font-weight: bold;\n    margin: 15px 0 10px;\n}\n\n.list-content {\n    padding: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
