import "./polyfills.ts";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";
import { AppModule } from "./app/";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "environments/environment";

if (environment().production) {
  enableProdMode();
}

if(environment().env != '__env__'){
  datadogRum.init({
      applicationId: environment().DD_APP_ID,
      clientToken: environment().DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: environment().DD_SERVICE,
      env: environment().env,
      version: environment().version, 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
  });
}


platformBrowserDynamic().bootstrapModule(AppModule);