import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AreaService } from 'app/logbooks/services/area.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-areas',
    templateUrl: './list-areas.component.html',
    styleUrls: ['./list-areas.component.css']
})

export class ListAreasComponent implements OnInit {

    areaList: any = [];
    cod_user: number;

    @Input() isDisabled: Boolean;
    @Input() isMultiple: Boolean;
    @Input() selectedValue: any[];
    @Input() privilege: string = 'NOT_PRIVILEGE';
    @Output() selectedValueChange = new EventEmitter<any[]>();
    @Output() blur = new EventEmitter<any>();

    constructor(private _areaService: AreaService, private _authService: AuthService) {
        this.cod_user = this._authService.getAuthenticatedUser().id;
    }

    ngOnInit() {
        this.searchAreas();

        if (!this.selectedValue) {
            this.selectedValue = [];
        }
    }

    selectAreas($event) {
        this.selectedValue = $event.selectedOptions;
        this.selectedValueChange.emit(this.selectedValue);
        this.blur.emit();
    }

    searchAreas() {
        let objParam = {
            'cod_area': 0,
            'term': '',
            'cod_user':this.cod_user,
            'dsc_page_privilege':this.privilege
        };
        this._areaService.getAreasList(objParam).subscribe(data => {
                this.areaList = this._areaService.getArrayContent(data);
            },
            err => {
                console.log(err);
            });
    }

    implodeValues(objValues, idField) {
        let ids = '';
        if (objValues) {
            objValues.forEach(element => {
                if (ids != '') {
                    ids += ',';
                }
                ids += element[idField];
            });
        }
        return ids;
    }
}
