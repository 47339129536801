import { Injectable } from '@angular/core';
import { MainService } from '../services/main.service';

@Injectable()
export class ChartsService {

    constructor(private _mainService: MainService) { }

    getEscAbReportChart(params) {
        const path = '/get-esc-ab-report-chart'
        return this._mainService.post(path, params);
    }

    getSdcaMatrixChart(params) {
        const path = '/get-sdca-matrix-chart'
        return this._mainService.post(path, params);
    }

    getCompletionTimeChart(params) {
        const path = `/get-completion-time-chart`
        return this._mainService.post(path, params);
      }
      
    getRepeatingProblemChart(params) { 
      const path = `/get-repeating-problem-chart`
      return this._mainService.post(path, params);
    }

    getActionStatusChart(params) { 
      const path = `/get-action-status-chart`
      return this._mainService.post(path, params);
    }
}
