import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {UploadMainService} from './upload-main.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { replaceAll } from '../../helpers/general-helper';

@Injectable()
export class UploadService {

    private imageSrc: string = '';
    private fileName: string = '';
    private fileType: string = '';
    private _observer: Observer<any>;
    private returnValue: Observable<any>;
    private _obj_user:any;

    constructor(private _http: HttpClient, 
                private _uploadMainService: UploadMainService,
                private _authService: AuthService,
                private _translateService: TranslateService) {
        this.returnValue = new Observable(observer =>
            this._observer = observer
        );
        this._obj_user = this._authService.getAuthenticatedUser();
    }

    /** 
     *  COMMON METHODS
     */
    uploadFile(formData): Observable<any> {
        return this._uploadMainService.post('/upload', formData);
    }


    /**
     * Post
     * @param params: JSON object
     */

    uploadFileBase64(params) {// A_M, muitos

        if (params.event) {
            
            let file = params.event.dataTransfer ? params.event.dataTransfer.files[0] : params.event.target.files[0];
            let unallowedExt = ['image/jfif'];
            this.fileName = replaceAll(file.name, '#', '');
            this.fileType = (file.type !== '') ? file.type : this.fileName.split('.').pop();
            this.fileName = replaceAll(this.fileName, ' ', '');
            //(params.allowedExtensions.includes(file.type))
            // console.log(params.allowedExtensions.indexOf(file.type));

            if (file.type == null || file.type == '') {
                const ext = file.name?.split('.')?.pop() || '';

                if (ext == 'csv') {
                    file.type = 'text/csv';
                }
            }

            if (
                file.size < 10000000 && 
                params.allowedExtensions.indexOf(this.fileType) > -1 &&
                !(unallowedExt.find((res) => { 
                    return (this.fileName.split('.').pop().indexOf(res.split('/').pop()) > - 1); 
                }))
            ) {
                
                let reader = new FileReader();
                reader.onload = this._handleReaderLoaded.bind(this);
                reader.readAsDataURL(file);

            } else {
                const allowedExtensionsText = params.allowedExtensions.join(', ');
                let messageError = '';
                if(file.size > 10000000) {
                    this._translateService.get('GENERAL.UPLOAD_SIZE_ERROR').subscribe(translate => { messageError = translate; });
                } else {
                    this._translateService.get('GENERAL.UPLOAD_FILE_TYPE').subscribe(translate => { messageError = translate; });
                    messageError = messageError.replace('$allowedExtensionsText', allowedExtensionsText);
                    messageError = messageError.replace('$fileType', this.fileType);
                }

                setTimeout(() => {
                    let msg = {
                        type: 'error',
                        code: 666,
                        title: 'Error!',
                        content: messageError
                    };
                    if(typeof this._observer == 'undefined'){
                        console.log(msg);
                    }else{
                        this._observer.next(msg);
                    }                    
                }, 1000);

            }
        }

        return this.returnValue;

    }

    _handleReaderLoaded(e) {
        let reader = e.target;
        this.imageSrc = reader.result;

        let paramsPost = {
            'files': this.imageSrc,
            'name': this.fileName,
            'type': this.fileType
        };

        this._uploadFileBase64(paramsPost);
    }

    _uploadFileBase64(params) {
        params.cod_user=this._obj_user.id;
        let path = '/uploadbase64';

        this._uploadMainService.post(path, params).subscribe({
            next: result => {
                if(result.type == 'error'){
                    console.log(result.content); 
                }
                this._observer.next(result);
            },
            error: err => { 
                this._authService.errCheck(err); 
                console.log(err); 
                this._observer.error(err)
            } 
        });

    }


    downloadFile(url): Observable<any> {
        return this._uploadMainService.getBlob(url);
    }

    /**
     * Deletes uploaded file by it name
     * @param params Must be an object with the structure: {'file_name': 'file001.jpg'};
     * @returns {Observable<any>}
     */
    deleteFile(params): Observable<any> {
        return this._uploadMainService.post('/delete-file', params);
    }

}
