import { finalize } from 'rxjs/operators';
import {Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, firstValueFrom, forkJoin} from 'rxjs';

import {SocketService} from '../../main/services/socket.service';
import {ActionsService} from '../services/actions.service';
import {environment} from '../../../environments/environment';
import {MeetingsService} from '../services/meetings.service';
import {AuthService} from '../../main/access/services/auth.service';
import {TranslateService} from '../../../../node_modules/@ngx-translate/core';
import {FilterByStatus} from '../meetings-actions-list/meetings-actions-list.pipes';
import { AreaService } from '../../logbooks/services/area.service';

import { FilterItem, OrderItem, GroupItem, DefaultFilters, DefaultOrder, DefaultGroup } from "../../components/filter/filter.model";
import { NotificationService } from '../../main/services/notification.service';
import { ContentEditableComponent } from '../../components/content-editable/content-editable.component';
import * as moment from 'moment';

enum SideScreen {
    Chat,
    Detail,
    Action,
}

@Component({
    selector: 'app-meetings-show',
    templateUrl: './meetings-show.component.html',
    styleUrls: ['./meetings-show.component.css']
})
export class MeetingsShowComponent implements OnInit, OnChanges {
    @ViewChild('filterComponent') filterComponent;
    @ViewChild('actionListComponent') actionListComponent;

    obj_options: any;
    obj_connections = new Subscription();
    arr_users: any;
    cod_meeting: number;
    obj_user: any;
    arr_action_form: Array<any>;
    arr_action_main: any;
    side_screen: number;
    str_agrupament: string = 'status'; // Usign to define the grouper actions type
    arr_actions_group;
    arr_actions_group_states=[];
    action_selected;
    bool_filter_finished: boolean = true;
    bool_init_finished: boolean = false;
    arr_filters: Array<any>;
    arr_orders: Array<string>;
    chartData: Object;
    detailsParam: any;
    bol_loading_list: boolean;
    chartDataParam: any;
    error_create_action: boolean = false;
    bol_success_actions: boolean = false;
    bol_warning_actions: boolean = false;
    is_participant: boolean = false;
    bol_tor_changes: boolean = false;
    bol_is_owner: boolean = false;
    error_message_action: any;
    str_download_link: string;
    arr_meetings_types: any;
    errorMessage: string;
    showError: boolean;
    arr_actions_status: Array<any>;
    arr_meeting_indicators: Array<any> = [];
    department: any = [];
    arr_areas: Array<any>;
    arr_meeting_columns: Array<any>;
    arr_meeting_departments: Array<any>;
    arr_meeting_column_answer: Array<any>;

    @Input() obj_meetings_details: any;
    @ViewChild("str_action_description") str_action_description: ElementRef;
    @ViewChildren("content_editable_descriptions") descriptionsElements: QueryList<ContentEditableComponent>;
    bol_not_custom: boolean = false;
    showOverlay: boolean = false;
    overlayMessage = '';
    checkParticipants : boolean = false;
    ind_reload_chart: number;
    lastId: number;
    openIndex: any;
    debounceFunction: any;
    procedureImportingActions: string = 'meetings_sp_imp_actions';
    isLoadingFile: boolean = false;
    fieldsExcelSheet: string = 'cod_action, str_hashtag, str_action, cod_responsible, dat_due, str_comment, cod_department, str_action_origin, str_root_cause';
    importErrors: string = "";
    showImportErros: boolean = false;
    downloadCsvSetup: any = {};
    downloadCsvExample: any = {};
    _reportsApiUrl = environment().reportsApiUrl;

    bol_show_presence: boolean;
    bol_loading_presences: boolean;
    arr_users_presences: any;
    bol_show_loading_presence: boolean;
    bol_scroll_end: boolean;
    arr_date_presences: any;
    arr_presences_user:any;
    arr_presences_user_date: any;
    dat_new_presence: any;
    showComponentImport: boolean = false;
    additionalParamsToExcel: string[] = [];
    bol_date: boolean = false;
    notOpenedYet: boolean = true;
    bol_can_import = false;
    isLoading: boolean;

    areaList: any = [];
    areasSelected: any[];
    indicatorsSelected: any[];

    edit_presence_time_out = null;
    add_presence_time_out = null;

    today = new Date();
    maxDate = this.today.getFullYear() + '-' + ('0' + (this.today.getMonth() + 1)).slice(-2) + '-' + ('0' + this.today.getDate()).slice(-2);
   
    date = new Date();
    calcDate = this.date.setDate( this.date.getDate() - 7 );
    minDate = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.date.getDate()).slice(-2);

    cod_user
    reportsApiUrl = environment().reportsApiUrl;
    searchTerm: string;

    @ViewChild('presence_container') presence_container: ElementRef;

    executeScroll: boolean = false;

    // FILTERS LIST TO SHOW WHEN FILTER BUTTON HOVER
    filters: Array<FilterItem> = [
        DefaultFilters.description,
        DefaultFilters.date,
        DefaultFilters.criticity,
        DefaultFilters.owner,
        DefaultFilters.status,
        DefaultFilters.indicator,
        DefaultFilters.department,
        DefaultFilters.sources,
        DefaultFilters.time,
        DefaultFilters.column,
    ];

    orders: Array<OrderItem> = [
        DefaultOrder.date,
        DefaultOrder.description,
        DefaultOrder.criticity,
        DefaultOrder.owner,
    ];

    groups: Array<GroupItem> = [ 
        DefaultGroup.status,
        DefaultGroup.owner,
        DefaultGroup.hashtag,
        DefaultGroup.columns,
    ];
    loadingConfig: any = [];
    autofill_sources: string = null;

    constructor(
        private readonly _activatedRoute: ActivatedRoute, 
        private readonly _socketService: SocketService, 
        private readonly _meetingsService: MeetingsService, 
        private readonly _authService: AuthService, 
        private readonly _actionsService: ActionsService, 
        private readonly _translateService: TranslateService, 
        private readonly _areaService: AreaService,
        private readonly _router: Router,
        private readonly _notify: NotificationService
    ) {
        this.obj_user = _authService.getAuthenticatedUser();
        this.cod_user = this.obj_user.id;
        this.side_screen = SideScreen.Chat;
        this.arr_users = [];
        this.arr_filters = [];
        this.arr_orders = [];
        this.cod_meeting = -1;
        this.bol_loading_list = true;
        this.obj_meetings_details = {str_name_meeting: ''};
        this.str_download_link = '';
        this.arr_meetings_types = [];
        this.errorMessage = '';
        this.arr_actions_status = [];
        this.arr_meeting_indicators = [];
        this.arr_meeting_departments = [];
        this.arr_areas = [];
        this.arr_meeting_column_answer = [];

        this.autofill_sources = this._meetingsService.getMeetingActionSourcesAutofill() ?? null;

        //GETTING MEETING ID FROM PARAMETER TO CONNECT TO THAT SOCKET
        this._activatedRoute.params.subscribe({
            next: params => {
                this.cod_meeting = params['id'];
                this.additionalParamsToExcel.push(params['id']);

                this.arr_action_form = [
                    {
                        arr_meeting: [{cod_meetings: this.cod_meeting}],
                        str_description: '',
                        arr_users: [],
                        arr_subareas: [],
                        dat_due: null,
                        cod_usu_ins: this.obj_user.id,
                        cod_areas: null,
                        arr_meeting_indicators: [],
                        cod_subarea: null
                    }
                ];

            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

        this._activatedRoute.queryParams.subscribe(
            params => this.action_selected = (params.action) ? { cod_actions: params.action } : null
        );

    }

    private _openActionListByRoute() {
        this._activatedRoute.queryParams.subscribe(params => {
            if (!params.action) return;

            let activeList = null;
            for (const actions_group of this.arr_actions_group) {
                for (const action of actions_group.values) {
                    if (action.cod_actions == params.action) {
                        activeList = actions_group.description;
                        break;
                    }
                    if (activeList != null) break;
                }
            }

            if (activeList != null)
                this.onOpenList(activeList, null, 0);
        });
    }

    ngOnInit() {
        const $this = this
        $this._translateService.get('MEETING.OVERLAYMESSAGE').subscribe(translate => {
            $this.overlayMessage = translate;
        });

        this._translateService.onLangChange.subscribe(() => this._buidCsvSetup());

        this.verifyPermission().subscribe(data => {
            if(data.code==888 && data.content.code==888 && data.content.results){ 
                if (data.content.results.meetings_sp_se_verify_participant && data.content.results.meetings_sp_se_verify_participant==true){
                    $this.showOverlay = false;
                    $this._socketService.subscribeMeeting($this.cod_meeting);
                    $this.obj_connections.add(
                        $this._socketService.subscribeToParticipants().subscribe(users => {
            
                            let obj_participant:any;
                            obj_participant = users;
                            if($this.cod_meeting==obj_participant.obj_participant.cod_meeting){
                                $this.refreshParticipants();
                            }
                        })
                    );            
                    $this.obj_connections.add(
                        $this._socketService.subscribeToActions().subscribe(meetings => {
            
                            let obj_meeting:any;
                            obj_meeting = meetings;
                            $this.loadActions(obj_meeting.cod_meeting);
                        })
                    );
                    $this.loadActions($this.cod_meeting);
                    $this.getMeetingsDetails(null); 
                    $this.refreshColumns();
                    $this.refreshIndicators();
                    this.refreshDepartments();
                    $this.loadAreas($this.cod_meeting);
                    this._buidCsvSetup();
                    let idToConsult = $this.obj_user.id
                    $this._actionsService.getActionsStatus(idToConsult).subscribe({
                        next: data => {
            
                            if (data.code === 888 && data.content.code === 888) {
                                $this.arr_actions_status = data.content.results;
                                $this.updateListActionsOrder();
                            }
                        }, 
                        error: err => {
                            console.log(err);
                        }
                    });
                        setTimeout(() => {
                            $this.bool_init_finished = true;
                        }, 8000);
                } else {
                    $this.showOverlay = true;
                }
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {  
        if(this.bool_init_finished){ 
            this.refreshParticipants(); 
        }

    }



    togglePresence(){
        if(!this.arr_users_presences){
            this.bol_loading_presences = true;
            this.refreshParticipants()
            
        }
        this.bol_show_presence = !this.bol_show_presence;
        if(!this.bol_loading_presences){
            this.bol_show_loading_presence = false;
        }
        else{
            this.bol_show_loading_presence = true;
        }
    }

    addDatePresence(){
        let new_date = new Date(this.dat_new_presence);
        if (this.add_presence_time_out != null) {
            clearTimeout(this.add_presence_time_out);
        }

        if (new_date > new Date(this.maxDate)) {
            this.dat_new_presence = this.maxDate;
            new_date = new Date(this.dat_new_presence);
        }

        if (this._isDateAvailable(new_date)) {
            let objParams = {
                cod_user: this.obj_user.id,
                cod_meetings: this.cod_meeting,
                dat_presence: this.dat_new_presence,
                bol_date: this.bol_date
            }

            this._meetingsService.postMeetingPresenceDate(objParams).subscribe({
                next: result =>{
                    this.loadPresence();
                    this.dat_new_presence = null;
                    this.bol_scroll_end = true;
                },
                error: err => {
                    console.log('err', err);
                }
            });
        } else {
            this.add_presence_time_out = setTimeout(()=>{
                this.dat_new_presence = null;
            }, 1000);
        }
    }

    private _isDateAvailable(date: Date): boolean {
        if (
            date > new Date(this.maxDate) ||
            date < new Date(this.minDate)
        ) return false;

        const v_d = this.arr_date_presences.find(d => new Date(d.dat_presence)?.getTime() == date.getTime());

        if (v_d != null) return false;

        return true;
    }

    loadPresence(){
        clearTimeout(this.debounceFunction);
        this._updateDownlodParams();
        this.debounceFunction = setTimeout(() => {
            this.bol_loading_presences = true;
            this.arr_date_presences = [];
            const obj_meeting = {
                cod_user: this.obj_user.id,
                cod_meeting: this.cod_meeting
            };
            this._meetingsService.getTakeAttendance(obj_meeting).subscribe({
                next: result => {
                    this.arr_users_presences = Array.isArray(result.content.results) ? result.content.results : [result.content.results];

                    this.is_participant = this.arr_users_presences.find(p => p.cod_user == this.obj_user.id) != null;
                    this.bol_can_import = this._authService.getUserPrivilege('MEETINGS_IMPORT_ACTIONS') && this.is_participant;

                    for(const presence of this.arr_users_presences[0].arr_presences){
                        this.arr_date_presences.push({
                            cod_meetings_presences: presence.cod_meetings_presences,
                            dat_presence: presence.dat_presence
                        });
                    }
                    this.arr_presences_user =  this.arr_users_presences.slice();
                    this.arr_presences_user.forEach(element => {
                        element.arr_presences.forEach(el => {
                            if(el.dat_presence < this.minDate){
                                el.bol_date = true;
                            }
                        });
                    });

                    for(const presence_user of this.arr_presences_user){
                        for(const user of this.arr_users){
                            if(user.cod_user === presence_user.cod_user){
                                presence_user.str_img_path = user.str_img_path;
                                presence_user.str_name = user.str_name;
                            }
                        }
                    }
                    this.bol_loading_presences = false;
                    this.bol_show_loading_presence = false;
                    if(this.bol_show_presence && this.bol_scroll_end){
                        setTimeout(()=>{
                            this.presence_container.nativeElement.scrollLeft = 0;
                            this.bol_scroll_end = false;
                        }, 50);
                    }

                },
                error: err => {
                    console.log('err', err);
                }
            });
        } , 500)
    }

    updateDatePresence(presence, idx){
        let new_date = new Date(presence.dat_presence);
        if(this.edit_presence_time_out != null){
            clearTimeout(this.edit_presence_time_out);
        }

        if (this._isDateAvailable(new_date)) {
            let objParams = {
                cod_user: this.obj_user.id,
                cod_meetings_presences: presence.cod_meetings_presences,
                dat_presence: presence.dat_presence
            }
            this._meetingsService.postMeetingPresenceUpdate(objParams).subscribe({
                next: result =>{
                    this.loadPresence();
                },
                error: err => {
                    console.log('err', err);
                }
            });
        } else {
            this.edit_presence_time_out = setTimeout(()=>{
                presence.dat_presence = this.arr_date_presences[idx].dat_presence;
            }, 1000);
        }
    }

    setUserPresence(event, presence, user, i){
        if(event == 'selected'){
            presence.bol_present = true;
        }
        else{
            presence.bol_present = false;
        }
        let objParams = {
            cod_usu_ins: this.obj_user.id,
            cod_meetings_presences: presence.cod_meetings_presences,
            cod_user: user.cod_user,
            bol_present: presence.bol_present,
            cod_meetings_participants: this.arr_users_presences[i].cod_meetings_participants
        };
        this._meetingsService.postMeetingPresenceUser(objParams).subscribe({
            next: result =>{
                this.loadPresence();
            },
            error: err => {
                console.log('err', err);
            }
        });
    }

    filter(filters) {
        this.arr_filters = filters;                
        this.updateListActionsOrder();
    }

    order(orders) {
        this.arr_orders = orders;
        this.updateListActionsOrder();
    }

    group(group) {
        this.str_agrupament = group;
        this.updateListActionsOrder();
    }

  loadAreas(cod_meeting) {
    let objParam = {
      cod_meeting: [cod_meeting],
      cod_user: this._authService.getAuthenticatedUser().id,
    };
    
    this._areaService.getAreaByMeetingLocation(objParam).subscribe({
      next: res => {
        this.arr_areas = res.content.length == 1
          ? [res.content.results]
          : res.content.results;

        this.isLoading = false;
      },
      error: err => {
        this.isLoading = false;
        console.log(err);
        this._authService.errCheck(err);
      }
    });
  }

    loadActions(cod_meeting){        
        if(cod_meeting == this.cod_meeting){
            this.bol_loading_list = true;            
            // !this.bool_filter_finished Na tela é usado ao contrario, por este motivo estou invertendo o valor antes de mandar para o BD
            this._actionsService.getMeetingList(cod_meeting, !this.bool_filter_finished, environment().uploadGetFilesApiUrl + '/', this.obj_user.id).subscribe({
                next: result => {
                    if (result.code == 888 && result.content.code == 888) {
                        this.bol_loading_list = false;
                        this.arr_action_main = result.content.results;
                        this.updateListActionsOrder();
                        this._openActionListByRoute();
                        this.refreshParticipants();
                    }

                }, 
                error: err => {
                    this.bol_loading_list = false;
                    console.log(err);
                    this._authService.errCheck(err);
                }
            });
        }
    }
    verifyPermission(){
        return this._meetingsService.verifyIfUserIsParticipantInMeeting(this.cod_meeting, this.obj_user.id)
    }
    refreshParticipants(){
        //REFRESH DATA FROM ALL MEETING PARTICIPANTS
        this._meetingsService.getMeetingsParticipants(this.cod_meeting, environment().uploadGetFilesApiUrl + '/', this.obj_user.id).subscribe({
            next: data => {
                if(data.code==888 && data.content.code==888){
                    this.arr_users = data.content.results;
                    for(const user of this.arr_users){
                        if(this.obj_user.id==user.cod_user){
                            this.obj_user.bol_is_owner=user.bol_is_owner;
                        }
                        if(this.obj_user.id == user.cod_user && user.bol_is_owner){
                            this.bol_is_owner = true;
                        }
                    }
                    this.loadPresence();
                    this.checkParticipants = true;
                }

            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    refreshColumns() {
        this._meetingsService.getMeetingColumns(this.cod_meeting, this.obj_user.id).subscribe({
            next: data => {
                if (data.code === 888 && data.content.code === 888) {
                    this.arr_meeting_columns = data.content.results;
                }
            }, 
            error: err => {
                console.log(err);
            }
        });
    }

    async refreshDepartments(){       
         this._meetingsService.getMeetingsDepartments(this.cod_meeting, this.obj_user.id).subscribe({
            next: data => {              
                if (data.code === 888 && data.content.code === 888) {
                    this.arr_meeting_departments = Array.isArray(data.content.results) ? data.content.results : [data.content.results];
                }
            }, 
            error: err => {
                console.error(err);
            }
        });   
    }
    
    refreshIndicators() {
        this.loadingConfig['indicators'] = true; 
        this._meetingsService.getMeetingsIndicators(this.cod_meeting, this.obj_user.id).subscribe({
            next: data => {
                if (data.code === 888 && data.content.code === 888) {
                  this.arr_action_form.forEach(element => {
                    
                    element.arr_meeting_indicators = [];
                  });
                  
                  this.arr_meeting_indicators = data.content.length == 1 ? [data.content.results]: data.content.results;
                  this.loadingConfig['indicators'] = false; 
                }
            }, 
            error: err => {
                console.log(err);
                this.loadingConfig['indicators'] = false; 
            }
        });
    }    

    ngOnDestroy() {
        this._socketService.unsubscribeMeeting(this.cod_meeting);
        this._meetingsService.clearMeetingActionSourcesAutofill()
    }

    showSide(side_screen) {
        this.side_screen = side_screen;
    }

    onActionsDetailsHiddenChange(hiddenActionDetail) {
        if (hiddenActionDetail)
            this.showSide(SideScreen.Chat);
    }

    /**
    * Remove focus and set focus again to positioning on day field
    */
    changeDatefieldFocus($event, index) {
        document.getElementById('date-action-field' + index).blur();
        document.getElementById('date-action-field' + index).focus();
    }

    selectedAreaChanged(event, i) {
        //Search for areas
        if(event?.selectedOptions[0]?.cod_areas && event.selectedOptions[0].cod_areas != null){
            this.arr_action_form[i].cod_area = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_areas : null;
            let objParam = {
                'cod_area': event.selectedOptions[0].cod_areas,
                'str_term': null,
                'cod_user': this.obj_user.id,
                'dsc_page_privilege':'MEETING_SELECT_SUBAREA'
            };
            this._areaService.getSubAreasByAreaPrivilege(objParam).subscribe({
                next: data => {
                    this.arr_action_form[i].arr_subareas = this._areaService.getArrayContent(data);
                },
                error: err => {
                    this.arr_action_form[i].arr_subareas = [];
                    console.log(err);
                }
            });
        }
    }

    selectedSubareaChanged(event, i) {
        this.arr_action_form[i].cod_subarea = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_subareas : null;
    }
    
    selectedUsersChanged(event, i) {
        this.arr_action_form[i].arr_users = event.selectedOptions;
    }

    selectedIndicatorChanged(event, i) {
      this.arr_action_form[i].cod_indicator = event.selectedOptions[0]
      ? event.selectedOptions[0].cod_indicator
      : null;

      this.arr_action_form[i].arr_meeting_indicators = event.selectedOptions; 
    }
    
    selectedUsersChangedFilter(event, i) {
        this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].str_name : null;
        this.updateListActionsOrder();
    }

    selectedDepartmentChangedFilter(event, i) {
        this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_areas : null;
        this.updateListActionsOrder();
    }

    selectedDepartment($event, i) {    
      this.department[i] = $event.selectedOptions; 
  
      const idDepartment = $event.selectedOptions[0]
      ? $event.selectedOptions[0].cod_areas
      : null; 
  
      this.arr_action_form[i].cod_areas = +idDepartment;  
    }

    selectedActionStatusChangedFilter(event, i) {
        const filtersArr = event.selectedOptions.map(item => {
            return item.cod_actions_status;
        });

        const filters = filtersArr.toString();

        this.arr_filters[i].value = (filters) || null;

        this.updateListActionsOrder();
    }

    selectedIndicatorChangedFilter(event, i) {
        this.arr_filters[i].value = (event.selectedOptions[0]) ? event.selectedOptions[0].cod_indicator : null;
        this.updateListActionsOrder();
    }

    addAction() {
        this.arr_action_form.push({
            arr_meeting: [{cod_meetings: this.cod_meeting}],
            str_description: '',
            arr_users: [],
            arr_subareas: [],
            dat_due: null,
            cod_usu_ins: this.obj_user.id,
            cod_areas: null,
            arr_meeting_indicators: [],      
            cod_subarea: null,
            cod_indicator: null
        });
        this.error_create_action = false;
        this.bol_success_actions = false;
        this.bol_warning_actions = false;
        
    }

    delAction(cod_action) {
        this.error_create_action = false;

        this.arr_action_form.splice(cod_action, 1);
    }

    validateActions(arr_actions) {
        const errors = [];
        const dueDateErrors = [];

        for (let i = 0; i < arr_actions.length; i = i + 1) {
            errors[i] = [];
            dueDateErrors[i] = [];

            const action = arr_actions[i];
            if (action.str_description == '' || action.str_description == null) {
                this._translateService.get('MEETING.VALIDATIONS.DESCRIPTION_IS_REQUIRED').subscribe(translate => {
                    errors[i].push(translate);
                });
            }
            if (action.dat_due == '' || action.dat_due == null) {
                this._translateService.get('MEETING.VALIDATIONS.DATE_IS_REQUIRED').subscribe(translate => {
                    errors[i].push(translate);
                });
            }
            if (action.arr_users.length == 0) {
                this._translateService.get('MEETING.VALIDATIONS.RESPONSIBLE_IS_REQUIRED').subscribe(translate => {
                    errors[i].push(translate);
                });
            }
            if (moment(action.dat_due) > moment().add(5, 'year')) {
                this._translateService.get('MEETING.VALIDATIONS.MAX_DUE_DATE').subscribe(translate => {
                    dueDateErrors[i].push(translate);
                });
            }
        }

        return {
            concatErros: errors.some((dueDate) => dueDate.length > 0) ? errors : [],
            dueDateErrors: dueDateErrors.some((dueDate) => dueDate.length > 0) ? dueDateErrors : [],
        }
    }

    saveActions() {
        const {
            concatErros,
            dueDateErrors
        } = this.validateActions(this.arr_action_form);

        if (dueDateErrors.length > 0) {
            this.bol_success_actions = false;
            this.error_create_action = true;
            this.bol_warning_actions = false;
            this.error_message_action = [];
            dueDateErrors.forEach((res) => {
                if (res.length) {
                    this.error_message_action.push(res);
                } else {
                    this.error_message_action.push(null);
                }
            });
        } else if (concatErros.length > 0) {
            this.bol_success_actions = false;
            this.error_create_action = true;
            let meeting = { field: null, fields: null, required_is: null, required_area: null }
            this._translateService.get('MEETING.VALIDATIONS.FIELD').subscribe(res => { meeting.field = res; });
            this._translateService.get('MEETING.VALIDATIONS.FIELDS').subscribe(res => { meeting.fields = res; });
            this._translateService.get('MEETING.VALIDATIONS.REQUIRED_IS').subscribe(res => { meeting.required_is = res; });
            this._translateService.get('MEETING.VALIDATIONS.REQUIRED_AREA').subscribe(res => { meeting.required_area = res; });
            this.error_message_action = [];
            concatErros.forEach((res) => {
                if (res.length) {
                    let aux = res.length === 1 ? meeting.field + ' ' : meeting.fields + ' ';
                    res.map((r,key) => {
                        if (key > 0) { aux += ', '; }
                        aux += r;
                    });
                    aux += res.length === 1 ? ' ' + meeting.required_is : ' ' +  meeting.required_area;
                    this.error_message_action.push(aux);
                } else {
                    this.error_message_action.push(null);
                }
            });
            this.bol_warning_actions = false;
        } else {
            this.error_create_action = false;
            this.bol_success_actions = true;
            let $this = this;
            setTimeout(function () {
                $this.bol_success_actions = false;
            }, 2000);

            let arr_actions=[];
            for(const action_form of this.arr_action_form){
                for(const user of action_form.arr_users){
                    arr_actions.push({ 
                        cod_meeting: this.cod_meeting,
                        cod_user: user.cod_user,
                        dat_due: action_form.dat_due,
                        str_description: action_form.str_description,
                        cod_usu_ins: this.obj_user.id,
                        cod_uuid: null,
                        str_sources: this.autofill_sources ?? null,
                        cod_indicator: action_form.cod_indicator?action_form.cod_indicator:null,
                        val_quality: action_form.val_quality,
                        cod_areas: action_form.cod_areas?action_form.cod_areas:null,
                        arr_meeting_indicators: action_form.arr_meeting_indicators
                    });
                }
            };
            
            this._actionsService.postAction(arr_actions).pipe(
                finalize(() => this.refreshDepartments())).subscribe({
                    next: res => {
                    if (res.code == 888 && res.content.code == 888) {
                        //REFRESH MEETINGS
                        this._socketService.refreshMeetingActions({arr_meetings: [this.cod_meeting]});                   

                        let participants = [];
                        for(let i=0;i<this.arr_users.length;i++){
                            participants[i] = this.arr_users[i].cod_user;
                        }
                        //REFRESH USERS NOTIFICATIONS
                        this._socketService.refreshUserNotifications({arr_participants: participants});

                        //OPEN THE ACTION DETAILS OF THE CREATED ACTION
                        let actionsCreated = res.content.results.meetings_sp_in_actions
                        if (actionsCreated[0]) {
                            let task = {
                                action: {
                                    'type': 'action',
                                    'action': {
                                        cod_actions: actionsCreated[0],
                                        cod_meeting: this.cod_meeting
                                    }
                                }
                            };
                            this.action_selected = task.action;
                            this.side_screen = SideScreen.Action; 
                            this.detailsParam = {'cod_action': task.action.action.cod_actions, 'cod_id': this.cod_meeting};
                        }
                        //RELOAD ACTIONS
                        this.getMeetingsDetails(null); 
                        this.loadActions(this.cod_meeting);
                        this.ind_reload_chart = Math.random();
                    }
                }, 
                error: err => {
                    this._authService.errCheck(err);
                }
            } );  

            //CLEAN ARRAY OF ACTIONS
            this.arr_action_form = [{
                arr_meeting: [{cod_meetings: this.cod_meeting}],
                str_description: '',
                arr_users: [],
                arr_subareas: [],
                dat_due: null,
                cod_usu_ins: this.obj_user.id,
                cod_areas: null,
                arr_meeting_indicators: [],
                cod_subarea: null,
                cod_indicator: null
                
            }];

        }
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    showCanceled() {
        this.bool_filter_finished = !this.bool_filter_finished;
        this.loadActions(this.cod_meeting);
        this.updateListActionsOrder();
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    torDownload() {
        alert('implements torDownload');
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    onFilterSelected($event) {
        this.filterComponent.setFilter($event.str_filter, $event.str_value, $event.str_value2);
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    onOpenList(groupmentDescription, $event, index) {
        if (this.str_agrupament !== 'hashtag') {
            let $index = 0;
            for (let j = 0; j < groupmentDescription.length; j++) {
                $index += groupmentDescription.toLowerCase().charCodeAt(j);
            }

            if (!this.arr_actions_group_states[$index]) {
                this.arr_actions_group_states[$index] = 'active';
            } else {
                this.arr_actions_group_states[$index] = (this.arr_actions_group_states[$index] === 'active') ? 'inactive' : 'active' ;
            }
        } else if (!this.arr_actions_group_states[index]) {
            this.arr_actions_group_states[index] = 'active';
        } else {
            this.arr_actions_group_states[index] = $event;
        }

    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    updateListActionsOrder() {     
   
        this.arr_actions_group = this._actionsService.filterActions(this.arr_filters, this.arr_action_main, this.bool_filter_finished);
        this.updateDataChart(this.arr_actions_group);
        this.arr_actions_group = this._actionsService.groupActions(this.str_agrupament, this.arr_actions_group);
        this.arr_actions_group = this._actionsService.orderActions(this.arr_orders, this.arr_actions_group);
    }

    ngAfterViewChecked() {
        if (typeof this.actionListComponent != 'undefined' && this.notOpenedYet && this.action_selected) {
            this.notOpenedYet = false;
            this.actionListComponent.openAction(this.action_selected.cod_actions, null, null);
        }

        if (this.executeScroll && this.action_selected && !this.bol_loading_list) {
            const el = (<HTMLInputElement> document.getElementById('action-status-group-' + this.action_selected.str_status_description));

            if (el) el.scrollIntoView({
                block: "start",
                inline: "center"
            });

            this.executeScroll = false;
        }
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    updateDataChart(arr_action_main) {
        const arr_main = this._actionsService.verifyCountRow(arr_action_main);
        let arr_return = [];
        this.arr_actions_status.forEach(action_status => {
            let qtdActions = FilterByStatus.prototype.transform(arr_main, null, action_status.cod_actions_status, null).length;
            if (qtdActions > 0) {
                let str_color = '';
                switch (action_status.cod_actions_status) {
                    case 1:
                    str_color = '#949494';
                    break;
                    case 2:
                    str_color = '#fdb849';
                    break;
                    case 3:
                    str_color = '#34C141';
                    break;
                    case 4:
                    str_color = '#5D5D5D';
                    break;
                    case 5:
                    str_color = null;
                    break;
                    case 6:
                    str_color = null;
                    break;
                    case 7:
                    str_color = '#1A94E6';
                    break;
                    case 8:
                    str_color = '#fc4e53';
                    break;

                    default:
                    str_color = null;
                    break;
                }
                arr_return.push({
                    name: '',
                    cod_actions_status: action_status.cod_actions_status,
                    str_name: action_status.str_description,
                    y: qtdActions,
                    color: str_color,
                    percent: Math.round((((qtdActions * 100) / arr_main.length) * 100) / 100)
                });
            }
        });
        this.chartDataParam = arr_return;
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    removeActionsFilter(index) {
        this.arr_filters.splice(index, 1);
        this.updateListActionsOrder();
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    removeActionsOrder(index) {
        this.arr_orders.splice(index, 1);
        this.updateListActionsOrder();
    }

    /**
    * @author Guilherme Renato Uller
    *
    *
    */
    onTaskSelected(task) {
        this.action_selected = task.action;
        this.side_screen = SideScreen.Action;
        this.detailsParam = {'cod_action': task.action.cod_actions, 'cod_id': this.cod_meeting, 'dat_alt': task.action.dat_action_alt};
        this.openIndex = task.openIndex !== undefined ? task.openIndex : this.openIndex;
        this.lastId = task.lastId !== undefined ? task.lastId : this.lastId;     
    }

    searchUserAction(index, ev = '') {
        this.arr_action_form[index].val_quality=null;
        
        if (this.arr_action_form[index].str_description.length > 254) {
            this.bol_warning_actions = true;
        } else {
            this.bol_warning_actions = false;
        }

        //GET DESCRIPTION
        let str_action = ev;

        //OPEN OPTIONS USER AND HASHTAGS
        this.arr_action_form[index].arr_opt_users = [];
        this.arr_action_form[index].arr_opt_hashtag = [];

        //ONLY IF HAS @
        if (str_action.indexOf('@') != -1) {
            //GET PART USER NAME
            let str_part_user = str_action.substring(str_action.indexOf('@') + 1);

            for (const user of this.arr_users) {
                //CHECK IF STRING AFTER @ IS INSIDE NAME OF PARTICIPANTS
                if (user.str_name.toLowerCase().indexOf(str_part_user.toLowerCase()) != -1) {
                    this.arr_action_form[index].arr_opt_users.push(user);
                }
            }

        }

        //ONLY IF HAS #
        if (str_action.indexOf('#') != -1) {

            //GET PART hashtag NAME
            let str_part_hashtag = str_action.substring(str_action.lastIndexOf('#') + 1);

            for (const action of this.arr_action_main) {
                //CHECK IF STRING AFTER @ IS INSIDE NAME OF PARTICIPANTS

                if(action.hashtags!=null){
                    for(const hashtag of action.hashtags){
                        if (hashtag.toLowerCase().lastIndexOf(str_part_hashtag.toLowerCase()) != -1) {
                            this.arr_action_form[index].arr_opt_hashtag.push(hashtag);
                        }
                    }
                }

            }
            //ONLY UNIQUE VALUES
            this.arr_action_form[index].arr_opt_hashtag = Array.from(new Set(this.arr_action_form[index].arr_opt_hashtag));

        }

        //DONT SHOW OPTIONS IF NONE WAS FOUND
        if (this.arr_action_form[index].arr_opt_users.length == 0) {
            delete this.arr_action_form[index].arr_opt_users;
        }
        if (this.arr_action_form[index].arr_opt_hashtag.length == 0) {
            delete this.arr_action_form[index].arr_opt_hashtag;
        }
    }

    private getCurrentEditable(index: number): ContentEditableComponent {
        return this.descriptionsElements.find(
            (item, i, arr) => i == index
        );
    }

    selectUserAction(index, obj_opt_user) {
        const current = this.getCurrentEditable(index);

        //SELECT USER
        let obj_tmp = this.arr_action_form[index].arr_users;
        if(obj_tmp.indexOf(obj_opt_user) == -1){
            obj_tmp.push(obj_opt_user);
        }
        this.arr_action_form[index].arr_users = obj_tmp;

        //GET DESCRIPTION
        let str_action = this.arr_action_form[index].str_description;
        //REMOVES @ PART FROM DESCRIPTION AND CLEAN OPTS
        this.arr_action_form[index].str_description = str_action.substring(0, str_action.indexOf('@'));
        delete this.arr_action_form[index].arr_opt_users;

        //Para o ngOnChanges do angular2 funcionar mapeando arrays é necessário que a referencia do objeto seja alterada
        this.arr_action_form[index].arr_users = Object.assign([], this.arr_action_form[index].arr_users);

        current.focus();

    }

    selectHashtagAction(index, str_opt_hashtag) {
        const current = this.getCurrentEditable(index);

        //GET DESCRIPTION
        let str_action = this.arr_action_form[index].str_description;
        //REMOVES # PART FROM DESCRIPTION AND CLEAN OPTS
        this.arr_action_form[index].str_description = str_action.substring(0, str_action.lastIndexOf('#'))+str_opt_hashtag+' ';
        delete this.arr_action_form[index].arr_opt_hashtag;

        current.focus();

    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this._notify.error(translate)
        } );
    }

    roundPercent(value) {
        return Math.round((value * 100) / 100);
    }

    getMeetingsDetails(callback) {
        this._meetingsService.getMeetingDetails(this.cod_meeting, this.obj_user.id, 'NOT_PRIVILEGE').subscribe({
            next: result => {
                if (result.code == 888 && result.content.code == 888) {
                    this.obj_meetings_details = result.content.results;
                    if (!this.obj_meetings_details.arr_kpis || this.obj_meetings_details.arr_kpis.length == 0) {
                        this.warnMustSelectKPIs();
                    }
                }
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    warnMustSelectKPIs() {
        const message = this._translateService.instant('MEETING.VALIDATIONS.KPIS_REQUIRED_ALERT');
        this._notify.warning(message);
    }

    canEditMessages() {
        let user = this.arr_users.filter(user => user.cod_user == this.obj_user.id && user.bol_is_owner == 1)[0];
        return (typeof user != typeof undefined);
    }

    changedAction($event) {
        this.executeScroll = true;
        this.loadActions(this.cod_meeting);
        this.ind_reload_chart = Math.random();
        this.refreshDepartments();
    }

    updateMeetingsFilters($event) {
        switch ($event) {
            case 'update_users':
                this.refreshParticipants();
                break;
            case 'update_columns':
                this.refreshColumns();
                break;
            case 'update_indicators':
                this.refreshIndicators();
                break;
            case 'update_departments':
                this.refreshDepartments();
                break;
            case 'update_title':
                this.getMeetingsDetails(null);
                break;
            case 'update_location':
                this.loadAreas(this.cod_meeting)
                break;
            default:
                break;
        }
    }    

    viewTor(){
        window.open('/meetings-visualize-tor/' + this.cod_meeting, '_blank');
    }

    closeOverlay() {
        this.showOverlay = false;
        this._router.navigate(['/my-meetings']);
    }

    convertChar(str: string) {
        if (str != undefined) {
          const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
          const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______';
          const p = new RegExp(a.split('').join('|'), 'g');
          return str.toString().toLowerCase().trim()
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
            .replace(/&/g, '_and_') // Replace & with 'and'
            .replace(/[\s\W-]+/g, '_'); // Replace spaces, non-word characters and dashes with
        }
        return str;
      }


    private _updateDownlodParams() {

        let str_participant_id = '';
        let str_name = '';

        let str_type = 'ALL';

        this._translateService.get('MEETINGS_REPORTS.COD_USER').subscribe(translate => {
            str_participant_id = translate;
            this._translateService.get('MEETINGS_REPORTS.USER_NAME').subscribe(translate => {
                str_name = translate;

                this.downloadCsvSetup = {
                    cod_user: this.cod_user,
                    api_url: this.reportsApiUrl,
                    str_proc_exec: 'reports_sp_ex_take_attendance',
                    arr_proc_params_search: [
                        this.obj_user.id,
                        this.searchTerm,
                        'USERS_SCREEN_LISTING',
                        str_type
                    ],
                    arr_headers: [
                        str_participant_id,
                        str_name,
                        // str_date_presence,
                    ],
                    name_of_file_to_download: 'take_attendance',
                    str_column_dynamic: ''
                };
            });
        });
    }
    private hideComponentImport() {
        this.showComponentImport = false;
    }
   
    private showConfirmImport() {
        this.showComponentImport = true;
    }

    async messageImported(response: any) {
        this.isLoadingFile = false;
        response = JSON.parse(response);
        this.hideComponentImport();
        
        if (response?.errors) {

          const iem = await firstValueFrom(this._translateService.get('MEETING.IMPORT_ACTIONS_ERROR_1'));
          const iem2 = await firstValueFrom(this._translateService.get('MEETING.IMPORT_ACTIONS_ERROR_2'));
          this.showImportErros = true;
          this.importErrors = `<p>${iem}</p><p>${response.errors.join('</p><p>')}</p><p>${iem2}</p>`;
        } else {
          this._notify.success({ text: 'LOGBOOK.MESSAGE_IMPORTED_SUCCESS', translate: true });
          this.loadActions(this.cod_meeting);
        }
    }

    messageError($event) {
        this.isLoadingFile = false; 
        let errorMessage = "";
        let errorMessageType = ""; 
        let errorMessageFinal = "";

        this.hideComponentImport();

        if (!$event.includes("Row length does not match headers")) {
            errorMessage = $event.split("-"); 
            errorMessageType = errorMessage[0];
        }

        if (errorMessageType == "STR_ACTION") {
          this._translateService
            .get("MEETING.REQUIRED_ACTION")
            .subscribe((translate) => {
              errorMessageFinal = translate;
              this._showErrorMessage(errorMessageFinal);
            });
        }
    
        if (errorMessageType == "COD_RESPONSIBLE") {
          this._translateService
            .get("MEETING.REQUIRED_COD_RESPONSIBLE")
            .subscribe((translate) => {
              errorMessageFinal = translate;
              this._showErrorMessage(errorMessageFinal);
            });
        }

        if (errorMessageType == "COD_PARTICIPANT") {
            this._translateService
              .get("MEETING.REQUIRED_COD_PARTICIPANT")
              .subscribe((translate) => {
                errorMessageFinal = translate + "-" + errorMessage[1];
                this._showErrorMessage(errorMessageFinal);
              });
          }

        if (errorMessageType == "ROOT_CAUSE") {
            this._translateService
                .get("MEETING.REQUIRED_ROOT_CAUSE")
                .subscribe((translate) => {
                errorMessageFinal = translate;
                this._showErrorMessage(errorMessageFinal);
            });
         }
    
        if (errorMessageType == "DAT_DUE") {
          this._translateService
            .get("MEETING.REQUIRED_DAT_DUE")
            .subscribe((translate) => {
              errorMessageFinal = translate;
              this._showErrorMessage(errorMessageFinal);
            });
        }

        if ($event == 'Date format incorret.') {
            this._translateService
            .get("MEETING.INCORRECT_FORMAT_DATE")
            .subscribe((translate) => {
              errorMessageFinal = translate;
              this._showErrorMessage(errorMessageFinal);
            });
        } 
        
        if ($event == 'Invalid cod_department') {
            this._translateService
            .get("MEETING.INCORRECT_DEPARTMENT")
            .subscribe((translate) => {
              errorMessageFinal = translate;
              this._showErrorMessage(errorMessageFinal);
            });
        }
        
        if (errorMessageType == "cod_department") {
            this._translateService
              .get("MEETING.REQUIRED_COD_DEPARTMENT")
              .subscribe((translate) => {
                errorMessageFinal = translate + "-" + errorMessage[1];
                this._showErrorMessage(errorMessageFinal);
              });
          }
    }

    startLoading($event) {
        this.isLoadingFile = true;
    }

    private _buidCsvSetup() {
        forkJoin([
            this._translateService.get('MEETINGS_REPORTS.HASHTAGS'), //str_hashtag
            this._translateService.get('MEETING.ACTION_DESCRIPTION'), //str_action
            this._translateService.get('MEETINGS_REPORTS.ID_RESPONSIBLE_ACTION'), //cod_responsible
            this._translateService.get('MEETING.ACTION_DATE'), //dat_due
            this._translateService.get('MEETINGS_REPORTS.COMMENTS'), //str_comment
            this._translateService.get('MEETINGS_REPORTS.COD_AREA'), //cod_department
            this._translateService.get('MEETING.ACTION_ORIGIN'), //str_action_origin
            this._translateService.get('MEETING.FUNDAMENTAL_CAUSE') //str_root_cause
        ]).subscribe(translations =>{
            this.downloadCsvExample = {
                cod_user: this._authService.getAuthenticatedUser().id,
                api_url: this._reportsApiUrl,
                str_proc_exec: 'generate_ex_blank_files',
                arr_proc_params_search: [null],
                arr_headers: translations,
                name_of_file_to_download: 'template_import_actions_',
                str_column_dynamic: ''
            };
        });

    }
}
