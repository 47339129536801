import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from '../../../components/components.module';
import {FormsDynamicModule} from '../../../forms/forms-dynamic/forms-dynamic.module';
import {ReportTopic1Component} from './report-topic-1/report-topic-1.component';
import {ReportTopic2Component} from './report-topic-2/report-topic-2.component';
import {ReportTopic3Component} from './report-topic-3/report-topic-3.component';

@NgModule({
    imports: [
CommonModule,
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        ComponentsModule
    ],
    exports: [ReportTopic1Component, ReportTopic2Component, ReportTopic3Component],
    declarations: [ReportTopic1Component, ReportTopic2Component, ReportTopic3Component],
    providers: []
})
export class InfoReportModule {
}
