import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from "./../../main/services/notification.service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'hyperlinks-list',
    templateUrl: './hyperlinks-list.component.html',
    styleUrls: ['./hyperlinks-list.component.css']
})
export class HyperlinksListComponent implements OnInit {

    @Input() hyperlinks: Array<any>;
    @Input() title: string = "";

    constructor(
        private sanitizer: DomSanitizer,
        private _notify: NotificationService
    ) {

    }

    ngOnInit() {
        this.sanitize();
    }

    ngOnChanges() {
        this.sanitize();        
    }

    public copyLink(str_hyperlink: string, i, copy: boolean = false) {    
        if (!/^https?:\/\/.+/.test(str_hyperlink) || copy === true) {
            this.copyToClipboard(str_hyperlink);
            this._notify.success({ text: 'LOGBOOK.COPY_LINK', translate: true });
        }
    }

    private copyToClipboard(val: string) : void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private sanitize() : void {
        this.hyperlinks?.map(it => {
            it.str_click_link = (this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadExtension(it.str_link)) as any).changingThisBreaksApplicationSecurity;
        });
    }

    private downloadExtension(file: string) {
        const extension = file.split('.').pop();
        let newFile = file;

        // GETTING EXTENSION TO SET CORRECT CODE | OFE = OPEN FOR EDITION
        switch (extension) {
          case 'xls':
          case 'xlsx':
          case 'xlsm':           
          case 'csv':
            newFile = `ms-excel:ofe|u|${file}`;
            break;
          case 'doc':
          case 'docx':
            newFile = `ms-word:ofe|u|${file}`;
            break;
          case 'ppt':
          case 'pptx':
            newFile = `ms-powerpoint:ofe|u|${file}`;
            break;
          default:
            break;
        }
        // REPLACING BACKWARD SLASH TO FORWARD SLASH TO FIX WRONG LOCAL URL
        newFile = newFile.replace(/\\/g, '/');
        return newFile;
    }

}
