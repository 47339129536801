import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent {

    @Output() onClose = new EventEmitter<void>();

    @Input() show: boolean = false;
    @Input() closeButton: boolean = true;
    @Input() minSize: boolean = false;
    @Input() title: string = null;

    constructor() {

    }

    public close(): void 
    {
        this.onClose.emit();
    }

}
