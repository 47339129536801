import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {EquipmentTypesService} from "../services/equipment-types.service";

@Component({
    selector: 'app-equipments-consult',
    templateUrl: './equipments-consult.component.html',
    styleUrls: ['./equipments-consult.component.css']
})
export class EquipmentsConsultComponent implements AfterViewInit {

    list: any[];
    searchTerm: string;
    searchTimer: any;
    isLoading: boolean = true;
    showError: boolean = false;
    errorMessage: string = '';

    constructor(
        private _equipmentService: EquipmentTypesService, 
        private _authService: AuthService, 
        private _translateService: TranslateService
    ) {
        this.searchTerm = '';
        this.showError = false;
        this.errorMessage = '';
        this.list = [];
        this.searchTimer = null;
    }

    ngAfterViewInit() {
        this._search();
    }

    private _search() {
        this.isLoading = true;
        let objParam = {
            'term': this.searchTerm,
            'cod_user': this._authService.getAuthenticatedUser().id
        };
        this._equipmentService.getEquipmentsList(objParam).subscribe(data => {
                this.list = this._equipmentService.getArrayContent(data);
                this.isLoading = false;
            }, err => {
                this.isLoading = false;
                console.log(err);
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }
        );
    }

    public search() {
        if (!parseInt(this.searchTerm) && this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }
        clearTimeout(this.searchTimer);
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

}
