import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "app/admin/services/users.service";
import { AreaService } from "app/logbooks/services/area.service";
import { AuthService } from "app/main/access/services/auth.service";
import { ValidationsService } from "app/logbooks/services/validations/validations.service";
import { MachineService } from "../services/machine.service";
import { FiveWhyService } from "../services/five-why.service";
import { UserPreferencesService } from "app/user-preferences/services/user-preferences.service";
import { ReportsService } from "app/logbooks/services/reports.service";

@Component({
  selector: "app-reports-fivewhy",
  templateUrl: "./reports-fivewhy.component.html",
  styleUrls: ["./reports-fivewhy.component.css"],
})
export class ReportsFivewhyComponent implements OnInit {
  cod_user = this.authService.getAuthenticatedUser().id;

  initial_date = null;
  final_date = null;
  reportsApiUrl = environment().reportsApiUrl;

  paramsSelected: any = [];
  loadingConfig: any = [];
  worldList: any = [];
  regionalList: any = [];
  locationsList: any = [];
  areaList: any = [];
  subAreasList: any = [];
  equipmentList: any = [];
  machineList: any = [];

  arr_areas: any = [];

  messageWarning: string = "";
  isWarning: boolean = false;
  isSent: boolean = false;
  timeToHide: number = 0;

  stillGeneratingDetailed: boolean = false;
  stillGeneratingConsolidated: boolean = false;
  timeLastDetailedReport: any;
  timeLastConsolidatedReport: any;
  lastObjParamToLogDetailed: any = [];
  lastObjParamToLogConsolidated: any = [];
  actualObjParam: any = [];

  private strReportSubject: string;
  private strFileNameDownload: string;
  private arrHeaders: any[];
  private arrWhyHeaders: any[];

  constructor(
    public authService: AuthService,
    private _fivewhyService: FiveWhyService,
    private _areaService: AreaService,
    private _reportService: ReportsService,
    private _userService: UsersService,
    private _machineService: MachineService,
    private _translateService: TranslateService,
    public validationService: ValidationsService,
    private _userPreferenceService: UserPreferencesService
  ) {
    this.paramsSelected = [];
    let today = new Date();
    let strDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + (today.getDate())).slice(-2);
    this.initial_date = strDate;
    this.final_date = strDate;
  }

  ngOnInit() {
    this.loadingConfig['worlds'] = false;
    this.loadingConfig['areas'] = false;
    this.loadingConfig['subareas'] = false;
    this.loadingConfig['equipments'] = false;
    this.loadingConfig['regionals'] = false;
    this.loadingConfig['locations'] = false;
    this.loadingConfig['creation_users'] = false;
    this.loadingConfig['execution_users'] = false;
    this.loadingConfig['meetings_types'] = false;
    this.loadingConfig['meetings'] = false;
    this.loadingConfig['indicators'] = false;

    let $this = this;
    $this._searchWorlds(function () {
      $this.paramsSelected['worlds_selected'] = [];
      $this.worldList.map((world) => {
          if ($this.authService.getAuthenticatedUser().cod_world == world.cod_locations_world) {
              $this.paramsSelected['worlds_selected'].push({ cod_locations_world: world.cod_locations_world });
          }
      });
      $this.paramsSelected['worlds'] = $this._implodeValues($this.paramsSelected['worlds_selected'], 'cod_locations_world');
      $this._searchRegionals(function () {
        $this.paramsSelected['regionals_selected'] = [];
        $this.regionalList.map((regional) => {
            if ($this.authService.getAuthenticatedUser().cod_regional == regional.cod_locations_regional) {
                $this.paramsSelected['regionals_selected'].push({ cod_locations_regional: regional.cod_locations_regional });
            }
        });
        $this.paramsSelected['regionals'] = $this._implodeValues($this.paramsSelected['regionals_selected'], 'cod_locations_regional');
        $this._searchLocations(function () {
          $this.paramsSelected['locations_selected'] = [];
          $this.locationsList.map((location) => {
              if ($this.authService.getAuthenticatedUser().cod_location == location.cod_location) {
                  $this.paramsSelected['locations_selected'].push({ cod_location: location.cod_location });
              }
          });
          $this.paramsSelected['locations'] = $this._implodeValues($this.paramsSelected['locations_selected'], 'cod_location');
          $this._searchAreas(function () {
            $this.paramsSelected['areas_selected'] = [];
            $this.areaList.map((area) => {
                $this.authService.getAuthenticatedUser().cod_area.map(authArea => {
                    if (authArea == area.cod_area) {
                      $this.paramsSelected['areas_selected'].push({ cod_area: area.cod_area });
                    }
                });
            });
            $this.paramsSelected['areas'] = $this._implodeValues($this.paramsSelected['areas_selected'], 'cod_area');
            $this._searchSubAreas(() => {
              $this._searchEquipments(() => {
                $this._searchMachines(() => {
                });
              });
            });
          });
        });
      });
    });
    
  }

  selectWorlds($event) {
    this.paramsSelected["worlds_selected"] = $event.selectedOptions;
    this.paramsSelected["worlds"] = this._implodeValues($event.selectedOptions,'cod_locations_world');
    this._searchRegionals(null);
  }

  selectRegionals($event) {
    this.paramsSelected["regionals_selected"] = $event.selectedOptions;
    this.paramsSelected['regionals'] = this._implodeValues($event.selectedOptions, 'cod_locations_regional');
    this._searchLocations(null);
  }

  selectLocations($event) {
    this.paramsSelected["locations_selected"] = $event.selectedOptions;
    this.paramsSelected['locations'] = this._implodeValues($event.selectedOptions, 'cod_location');
    this._searchAreas(null);
  }

  selectAreas($event) {
    this.paramsSelected["areas_selected"] = $event.selectedOptions;
    this.paramsSelected['areas'] = this._implodeValues($event.selectedOptions, 'cod_area');
    this._searchSubAreas(null);
  }

  selectSubAreas($event) {
    this.paramsSelected["subareas_selected"] = $event.selectedOptions;
    this.paramsSelected['subareas'] = this._implodeValues($event.selectedOptions, 'cod_subareas');    
    this._searchEquipments(null);
  }

  selectMachine($event) { 
    this.paramsSelected["machines_selected"] = $event.selectedOptions;
    this.paramsSelected['machines'] = this._implodeValues($event.selectedOptions, 'cod_machine');
  }

  selectEquipments($event) {
    this.paramsSelected["equipments_selected"] = $event.selectedOptions;
    this.paramsSelected['equipments'] = this._implodeValues($event.selectedOptions, 'cod_equipment');
    this._searchMachines(null);
  }

  hideMessage() {
    this.isSent = false;
  }

  submit() {
    let $this = this;
    this._translateReport(function () {
        $this._submit();
    });
  }
  
  submitDetailed() {
    let $this = this;
    this._translateReportDetailed(function () {
        $this._submitDetailed();
    });
  }

  private _submitDetailed() {
    this.validationService.boolSubmit = true;

    if (this._hasInvalidParams())
        return false

    this.validationService.boolIsLoading = true;
    this.validationService.boolSubmit = false;
    this.isWarning = false;
    this.isSent = false;
    this.timeToHide = 0;

    this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
      // CHANGE NAME PROC
        this._submitReport('fivewhy_sp_report_detailed', mailText, 'detailed');
    });
  }

  private _searchWorlds(fn) {
    this._clearWorldList();
    this._setLoading('worlds', true);

    this._userService.getWorlds(this.authService.getAuthenticatedUser().id, '5WHY_REPORT')
      .subscribe({
        next: data => {
          this.worldList = this._userService.getArrayContent(data);
          this._setLoading('worlds', false);
          if (typeof fn != typeof undefined && fn) {
              fn();
          }
        },
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
  }

  private _searchRegionals(fn) {

    if ( typeof this.paramsSelected["worlds"] != typeof undefined && this.paramsSelected["worlds"].toString() != "" ) {
      this._setLoading("regionals", true);
      let params = this.paramsSelected["worlds"].toString().split(",");

      this._userService.getRegionals( params, this.authService.getAuthenticatedUser().id, "5WHY_REPORT" ).subscribe({
        next: (data) => {
          this.regionalList = this._userService.getArrayContent(data);
          this._setLoading("regionals", false);
          this._smartClearRegionalList();
          if (typeof fn != typeof undefined && fn) {
            fn();
          }
        }, 
        error: (err) => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearRegionalList();
    }

  }

  private _submit() {
    this.validationService.boolSubmit = true;

    if (this._hasInvalidParams())
        return false

    this.validationService.boolIsLoading = true;
    this.validationService.boolSubmit = false;
    this.isWarning = false;
    this.isSent = false;
    this.timeToHide = 0;

    this._translateService.get('GENERAL.REPORT_MAIL_TEXT').subscribe(mailText => {
        this._submitReport('fivewhy_sp_report_consolidated', mailText, 'consolidated');
    });
  }
  

  private _hasInvalidParams() {
    if (!this._emptyDefaultValue(this.paramsSelected['locations'], null) ||
        !this.initial_date || !this.final_date) {
        this._showWarning('REPORTS.MEETING_VALIDATION_MESSAGE');
        return true;
    }

    if (this.initial_date > this.final_date) {
        this._showWarning('REPORTS.PERIOD_VALIDATION');
        return true;
    }

    if (this._isInvalidDateRange()) {
        this._showWarning('REPORTS.PERIOD_VALIDATION_MAX_DAYS');
        return true;
    }
    return false
  }

  private _searchLocations(fn) {

    if (this.paramsSelected['regionals'] && this.paramsSelected['regionals'].toString() != '') {
      this._setLoading('locations', true);
      let params = this.paramsSelected['regionals'].toString().split(',');

      this._userService.getLocations(params, this.authService.getAuthenticatedUser().id, '5WHY_REPORT').subscribe({
        next: data => {
          this.locationsList = this._userService.getArrayContent(data);
          this._setLoading('locations', false);
          this._smartClearLocationList();
          if (typeof fn != typeof undefined && fn) {
              fn();
          }
        }, 
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearLocationList();
    }

  }

  private _searchAreas(fn) {
    
    //Search for areas
    if (typeof this.paramsSelected["locations"] != typeof undefined && this.paramsSelected["locations"].toString() != "" ) {
      this._setLoading('areas', true);
      let params = {
          arr_cod_locations: this.paramsSelected['locations'].toString().split(','),
          cod_user: this.authService.getAuthenticatedUser().id,
          dsc_page_privilege: '5WHY_REPORT'
      };
      this._fivewhyService.getAllowedAreas(params).subscribe({
        next: data => {
          this.areaList = this._userService.getArrayContent(data);
          this._setLoading('areas', false);
          this._smartClearAreaList();
          if (typeof fn != typeof undefined && fn) {
              fn();
          }
        }, 
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearAreaList();
    }

  }

  private _searchSubAreas(fn) {

    if (this.paramsSelected['areas'] && this.paramsSelected['areas'].toString() != '') {
      this._setLoading('subareas', true);
      //Search for areas
      let objParam = {
        arr_cod_area: JSON.parse('[' + this.paramsSelected['areas'] + ']'),
        arr_cod_location: JSON.parse('[' + this.paramsSelected['locations'] + ']'),
        cod_user: this.authService.getAuthenticatedUser().id
      };
      this._fivewhyService.getAllowedSubareas(objParam).subscribe({
        next: data => {
          this.subAreasList = this._areaService.getArrayContent(data);
          this._setLoading('subareas', false);
          this._smartClearSubAreaList();
          if (typeof fn != typeof undefined && fn) {
            fn();
          }
        }, 
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearSubAreaList();
    }

  }

  private _searchEquipments(fn) {

    if (this.paramsSelected['subareas'] && this.paramsSelected['subareas'].toString() != '') {
      //Search for equipments
      this._setLoading('equipments', true);
      let objParam = { 
        cod_user: this.authService.getAuthenticatedUser().id,
        arr_subareas: JSON.parse("[" + this.paramsSelected['subareas'] + "]")
      };
      this._machineService.getListEquipments(objParam).subscribe({
        next: data => {
          this.equipmentList = this.getArrayContent(data);
          this._setLoading('equipments', false);
          this._smartClearEquipmentList();
          if (typeof fn != typeof undefined && fn) {
            fn();
          }
        }, 
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearEquipmentList();
    }

  }

  private _searchMachines(fn, clearList: boolean =  true) {

    if (typeof this.paramsSelected['locations'] != typeof undefined &&
      typeof this.paramsSelected['areas'] != typeof undefined &&
      typeof this.paramsSelected['subareas'] != typeof undefined &&
      typeof this.paramsSelected['equipments'] != typeof undefined &&
      this.paramsSelected['locations'].toString() != '' &&
      this.paramsSelected['areas'].toString() != '' &&
      this.paramsSelected['subareas'].toString() != '' &&
      this.paramsSelected['equipments'].toString() != '') {
      this._setLoading('machines', true);
      let params = {
        cod_user: this.authService.getAuthenticatedUser().id,
        cod_equipments: this.paramsSelected['equipments'].toString(),
        cod_areas: this.paramsSelected['areas'].toString(),
        cod_subareas: this.paramsSelected['subareas'].toString(),
        cod_locations: this.paramsSelected['locations'].toString(),
        str_filter: 'IS_FILTER'
      }
      this._machineService.getListMachines(params).subscribe({
        next: data => {
          this.machineList = this._userService.getArrayContent(data);
          this._smartClearMachinesList();
          this._setLoading('machines', false);
          if (typeof fn != typeof undefined && fn) {
            fn();
          }
        }, 
        error: err => {
          this.authService.errCheck(err);
          console.log(err);
        }
      });
    } else {
      this._clearMachinesList();
    }

  }

  private getArrayContent(data: any) {
    return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }

  private _showWarning(message) {
    this.isWarning = true;
    this._translateService.get(message).subscribe(translate => {
        this.messageWarning = translate;
    });
  }

  private _clearWorldList() {
    this.paramsSelected['worlds'] = '';
    this.paramsSelected['worlds_selected'] = [];
    this.worldList = [];
    this._clearRegionalList();

    this._clearMachinesList();
  }

  private _clearRegionalList() {
    this.paramsSelected['regionals'] = '';
    this.paramsSelected['regionals_selected'] = [];
    this.regionalList = [];
    this._clearLocationList();
  }

  private _smartClearRegionalList() {

    if (this.paramsSelected['regionals_selected'] && this.paramsSelected['regionals_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['regionals_selected'].map((selected: { cod_locations_regional: any; }) => {
        this.regionalList.map((regional) => {
          if (regional.cod_locations_regional == selected.cod_locations_regional) arr.push(regional);
        });
      });
      this.paramsSelected['regionals_selected'] = arr;
      this.paramsSelected['regionals'] = this._implodeValues(this.paramsSelected['regionals_selected'], 'cod_locations_regional');
    }
    if (!this.paramsSelected['regionals_selected'] || this.paramsSelected['regionals_selected'].length == 0) {
      this.paramsSelected['regionals_selected'] = [];
      this.paramsSelected['regionals'] = '';
      this._clearLocationList();
    } else {
      this._searchLocations(null);
    }

  }

  private _clearLocationList(){
    this.paramsSelected['locations'] = '';
    this.paramsSelected['locations_selected'] = [];
    this.locationsList = [];
    this._clearAreaList();
  }

  private _smartClearLocationList(){

    if (this.paramsSelected['locations_selected'] && this.paramsSelected['locations_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['locations_selected'].map((selected: { cod_location: any; }) => {
        this.locationsList.map((location) => {
          if (location.cod_location == selected.cod_location) arr.push(location);
        });
      });
      this.paramsSelected['locations_selected'] = arr;
      this.paramsSelected['locations'] = this._implodeValues(this.paramsSelected['locations_selected'], 'cod_location');
    }
    if (!this.paramsSelected['locations_selected'] || this.paramsSelected['locations_selected'].length == 0) {
      this.paramsSelected['locations_selected'] = [];
      this.paramsSelected['locations'] = '';
      this._clearAreaList();
    } else {
      this._searchAreas(null);
    }

  }

  private _clearAreaList() {
    this.paramsSelected['areas'] = '';
    this.paramsSelected['areas_selected'] = [];
    this.areaList = [];
    this._clearSubAreaList();
  }

  private _smartClearAreaList() {

    if (this.paramsSelected['areas_selected'] && this.paramsSelected['areas_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['areas_selected'].map((selected: { cod_area: any; }) => {
        this.areaList.map((area) => {
          if (area.cod_area == selected.cod_area) arr.push(area);
        });
      });
      this.paramsSelected['areas_selected'] = arr;
      this.paramsSelected['areas'] = this._implodeValues(this.paramsSelected['areas_selected'], 'cod_area');
    }
    if (!this.paramsSelected['areas_selected'] || this.paramsSelected['areas_selected'].length == 0) {
      this.paramsSelected['areas_selected'] = [];
      this.paramsSelected['areas'] = '';
      this._clearSubAreaList();
    } else {
      this._searchSubAreas(null);
    }

  }

  private _clearSubAreaList() {
    this.paramsSelected['subareas'] = '';
    this.paramsSelected['subareas_selected'] = [];
    this.subAreasList = [];
    this._clearEquipmentList();
  }

  private _smartClearSubAreaList() {

    if (this.paramsSelected['subareas_selected'] && this.paramsSelected['subareas_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['subareas_selected'].map((selected: { cod_subareas: any; }) => {
        this.subAreasList.map((area) => {
          if (area.cod_subareas == selected.cod_subareas) arr.push(area);
        });
      });
      this.paramsSelected['subareas_selected'] = arr;
      this.paramsSelected['subareas'] = this._implodeValues(this.paramsSelected['subareas_selected'], 'cod_subareas');
    }
    if (!this.paramsSelected['subareas_selected'] || this.paramsSelected['subareas_selected'].length == 0) {
      this.paramsSelected['subareas_selected'] = [];
      this.paramsSelected['subareas'] = '';
      this._clearEquipmentList();
    } else {
      this._searchEquipments(null);
    }

  }

  private _clearEquipmentList() {
    this.paramsSelected['equipments'] = '';
    this.paramsSelected['equipments_selected'] = [];
    this.equipmentList = [];
  }

  private _smartClearEquipmentList() {

    if (this.paramsSelected['equipments_selected'] && this.paramsSelected['equipments_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['equipments_selected'].map((selected: { cod_equipment: any; }) => {
        this.equipmentList.map((equip) => {
          if (equip.cod_equipment == selected.cod_equipment) arr.push(equip);
        });
      });
      this.paramsSelected['equipments_selected'] = arr;
      this.paramsSelected['equipments'] = this._implodeValues(this.paramsSelected['equipments_selected'], 'cod_equipment');
    }
    if (!this.paramsSelected['equipments_selected'] || this.paramsSelected['equipments_selected'].length == 0) {
      this.paramsSelected['equipments_selected'] = [];
      this.paramsSelected['equipments'] = '';
      this._clearMachinesList();
    } else {
      this._searchMachines(null);
    }

  }

  private _clearMachinesList() {
    this.paramsSelected['machines'] = '';
    this.paramsSelected['machines_selected'] = [];
    this.machineList = [];
  }

  private _smartClearMachinesList() {

    if (this.paramsSelected['machines_selected'] && this.paramsSelected['machines_selected'].length > 0) {
      let arr = [];
      this.paramsSelected['machines_selected'].map((selected: { cod_machine: any; }) => {
        this.machineList.map((machine) => {
          if (machine.cod_machine == selected.cod_machine) arr.push(machine);
        });
      });
      this.paramsSelected['machines_selected'] = arr;
      this.paramsSelected['machines'] = this._implodeValues(this.paramsSelected['machines_selected'], 'cod_machine');
    }
    if (!this.paramsSelected['machines_selected'] || this.paramsSelected['machines_selected'].length == 0) {
      this.paramsSelected['machines_selected'] = [];
      this.paramsSelected['machines'] = '';
    }

  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _implodeValues(objValues, idField) {
    let ids = "";
    if (objValues) {
      objValues.forEach((element) => {
        if (ids != "") {
          ids += ",";
        }
        ids += element[idField];
      });
    }
    return ids;
  }
  
  private _getAsArray(strText) {
    if (typeof strText != typeof undefined && strText) {
        return strText.toString().split(',');
    }
    return [];
  }

  private _isInvalidDateRange() {
    let selectedLocations = this._getAsArray(this.paramsSelected['locations'])
    let isGreaterThan90Allowed = selectedLocations.length < 101
    let limit = isGreaterThan90Allowed ? 185 : 95
    let reportRangeDiffInDays = Math.round(this._reportRangeDiffInDays())
    return (reportRangeDiffInDays < 0) || (reportRangeDiffInDays >= limit)
  }

  private _reportRangeDiffInDays() {
    let startDate = (new Date(this.initial_date)).getTime()
    let endDate = (new Date(this.final_date)).getTime()
    return (endDate - startDate) / (1000 * 3600 * 24)
  }

  private _emptyDefaultValue(value, defaultValue) {
    if ((typeof value !== typeof undefined && value)) {
        return value;
    }
    return defaultValue;
  }

  private _areAllSelected(listSelected, listAll) {
    return listSelected.length == listAll.length;
  }

  private _submitReport(proc: string, mailText: string, type: string) {

    if(type == 'consolidated'){
      //Consolidated
      this.stillGeneratingConsolidated = true;
    }
    else{
        //detailed
        this.stillGeneratingDetailed = true;    }
  
    mailText = mailText.replace('$USER', this.authService.getAuthenticatedUser().name);
    mailText = mailText.replace('$REPORT', this.strReportSubject);

    let listLocations = null;
    let listAreas = null;
    let listSubAreas = null;
    let listEquipments = null;
    let listMachines = null;

    listLocations = this._emptyDefaultValue(this.paramsSelected['locations'], null);

    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['areas']), this.areaList))) {
        listAreas = this._emptyDefaultValue(this.paramsSelected['areas'], null);
    }

    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['subareas']), this.subAreasList))) {
        listSubAreas = this._emptyDefaultValue(this.paramsSelected['subareas'], null);
    }

    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['equipments']), this.equipmentList))) {
        listEquipments = this._emptyDefaultValue(this.paramsSelected['equipments'], null);
    }

    if (!(this._areAllSelected(this._getAsArray(this.paramsSelected['machines']), this.machineList))) {
      listMachines = this._emptyDefaultValue(this.paramsSelected['machines'], null);
    }

    let objParam = {
        cod_user: this.cod_user,
        api_url: this.reportsApiUrl,
        str_report_subject: this.strReportSubject,
        str_proc_exec: proc,
        arr_proc_params_search: [
            {
                'pr_cods_locations': listLocations,
                'pr_cod_user': this.cod_user,
                'pr_initial_date': this.initial_date,
                'pr_final_date': this.final_date,
                'pr_area': listAreas,
                'pr_subareas': listSubAreas,
                'pr_machines': listMachines,
                'pr_equipments': listEquipments
            }
        ],
        arr_headers: this.arrHeaders,
        arr_why_headers: this.arrWhyHeaders,
        name_of_file_to_download: this.strFileNameDownload,
        str_type: type,
        str_worker_link: environment().workerReportPath,
        str_mail_text: mailText,
        userlang: this._userPreferenceService.getLocalLanguage()
    };

    let objParamToLog = {
      cod_user: this.cod_user,
      api_url: this.reportsApiUrl,
      reportTab: 4,
      str_report_subject: this.strReportSubject,
      str_proc_exec: proc,
      arr_proc_params_search: [
          {
              'pr_world_selected': this.paramsSelected['worlds'],
              'pr_regionals_selected': this.paramsSelected['regionals'],
              'pr_locations_selected': this.paramsSelected['locations'],
              'pr_cod_user': this.cod_user,
              'pr_dat_ini': this.initial_date,
              'pr_dat_fim': this.final_date,
              'pr_area_selected': this.paramsSelected['areas'],
              'pr_subareas_selected': this.paramsSelected['subareas'],
              'pr_machines_selected': this.paramsSelected['machines'],
              'pr_equipments_selected': this.paramsSelected['equipments'],
              'pr_is_consolidated': type && type=='consolidated' ? true : false,
          }
      ],
      arr_headers: this.arrHeaders,
      arr_why_headers: this.arrWhyHeaders,
      name_of_file_to_download: this.strFileNameDownload,
      str_type: type,
      str_worker_link: environment().workerReportPath,
      str_mail_text: mailText,
      userlang: this._userPreferenceService.getLocalLanguage()
    };

    this.actualObjParam = objParamToLog;
    
    let lastObjParamToLog = this.lastObjParamToLogDetailed;
    if(type == 'consolidated'){
        //Consolidated
        lastObjParamToLog = this.lastObjParamToLogConsolidated;
    }

    if(this._reportService.isSameParameters(this.actualObjParam, lastObjParamToLog)){
        const now = new Date().getTime();
        let milisec_diff = 300000;
        if(type == 'consolidated'){
            //Consolidated
            if(typeof this.timeLastConsolidatedReport != typeof undefined){
              milisec_diff = Math.abs(this.timeLastConsolidatedReport.getTime() - now);
            }
        }
        else{
            //Detailed
            if(typeof this.timeLastDetailedReport != typeof undefined){
              milisec_diff = Math.abs(this.timeLastDetailedReport.getTime() - now);
            }
        }
        const seconds = Math.floor(milisec_diff / 1000);
        
        if(seconds < 300){
            this._showWarning('REPORTS.SAME_FILTERS_MESSAGE');
            this.validationService.boolIsLoading = false;
            if(type == 'consolidated'){
                //Consolidated
                this.stillGeneratingConsolidated = false;
            }
            else{
                //detailed
                this.stillGeneratingDetailed = false;
            }
            return;
        }
        else{
            if(type == 'consolidated'){
                //Consolidated
                this.timeLastConsolidatedReport = new Date();
            }
            else{
                //detailed
                this.timeLastDetailedReport = new Date();
            }
        }
        
        this.validationService.boolIsLoading = false;
    }
    else{
        if(type == 'consolidated'){
            //Consolidated
            this.lastObjParamToLogConsolidated = objParamToLog;
            this.timeLastConsolidatedReport = new Date();
        }
        else{
            //detailed
            this.lastObjParamToLogDetailed = objParamToLog;
            this.timeLastDetailedReport = new Date();
        }
    }

    this._reportService.postLogReports(objParamToLog).subscribe({
      // next: data => {
      // },
      error: err => {
        this.authService.errCheck(err);
      }
    });
    this._fivewhyService.postFivewhyReports(objParam).subscribe({
      next: data => {
        this.validationService.boolIsLoading = false;
        if(type == 'consolidated'){
          //Consolidated
          this.stillGeneratingConsolidated = false;
        }
        else{
          //detailed
          this.stillGeneratingDetailed = false;
        }
        this.timeToHide = 3000;
        this.isSent = true;
      },
      error: err => {
        this.authService.errCheck(err);
        console.log(err);
        
        if(type == 'consolidated'){
          //Consolidated
          this.stillGeneratingConsolidated = false;
        }
        else{
          //detailed
          this.stillGeneratingDetailed = false;
        }
      }
    });

    this.validationService.boolSubmit = false;
  }

  //TODO TRANSLATE REPORT
  private _translateReportDetailed(fnCallback) {
    this.strReportSubject = '';
    this.strFileNameDownload = '';
    this.arrHeaders = [];
    this.arrWhyHeaders = [];

    const observables = [
      '5WHY_REPORTS.MAIL_SUBJECT',    // strReportSubject
      '5WHY_REPORTS.MAIL_FILE_NAME',  // strFileNameDownload
      '5WHY_REPORTS.STR_WORLD',
      '5WHY_REPORTS.STR_REGIONAL',
      '5WHY_REPORTS.STR_LOCATION',
      '5WHY_REPORTS.STR_AREA',
      '5WHY_REPORTS.STR_SUBAREA',
      '5WHY_REPORTS.STR_EQUIPMENT',
      '5WHY_REPORTS.STR_MACHINE',
      '5WHY_REPORTS.STR_DATE',
      '5WHY_REPORTS.STR_CREW',
      '5WHY_REPORTS.STR_CATEGORY',
      '5WHY_REPORTS.STR_TRIGGER',
      '5WHY_REPORTS.STR_REF_COD',
      '5WHY_REPORTS.STR_TITLE',
      '5WHY_REPORTS.STR_REPEATING_PROBLEM',
      '5WHY_REPORTS.STR_PLAN_FOLLOWED',
      '5WHY.STATUS',
      '5WHY.LAST_SENT_TO_REPORT',
      '5WHY_REPORTS.STR_ROOT_CAUSE_FOUND',
      '5WHY_REPORTS.STR_WHO_FOUND_ROOT_CAUSE',
      '5WHY_REPORTS.STR_SDCA_MATRIX',
      '5WHY_REPORTS.STR_COMPLETION_TIME',
      '5WHY_REPORTS.STR_NUMBER_OF_ACTIONS',
      '5WHY_REPORTS.STR_ACTIONS_PROGRESS',
      '5WHY_REPORTS.STR_ESCALATED_AB_REPORT',
      '5WHY_REPORTS.RATING_ADDED_BY',
      '5WHY_REPORTS.ALL_REQUIRED_COMPLETED',
      '5WHY.PEOPLE_PRESENT',
      '5WHY.5WHY_OUT_WORK_AREA',
      '5WHY.ALL_5WHY_EVIDENCE',
      '5WHY.SEQUENCE_MAKE_SENSE',
      '5WHY.TRUE_ROOT_CAUSE_FOUND',
      '5WHY.PREVENTIVE-ACTIONS_PROPOSED',
      '5WHY.COMMENTS_IMPROVEMENTS',
      '5WHY_REPORTS.STR_RATING',
      '5WHY_REPORTS.STR_OPERATOR_START',
      '5WHY_REPORTS.STR_TECHNICIAN',
      '5WHY_REPORTS.STR_MANAGER',
      '5WHY_REPORTS.STR_SME',
      '5WHY_REPORTS.STR_BPM',
      '5WHY_REPORTS.STR_OPERATOR_END',
      '5WHY.PARTICIPANTS',
      '5WHY_REPORTS.CANCEL_COMMENT',
      '5WHY_REPORTS.WHY',             // arrWhyHeaders
      '5WHY_REPORTS.EVIDENCE',        // arrWhyHeaders
      'LOGBOOK.ROUTINE_COMMENTS',     // arrWhyHeaders
    ];

    // keys to skip on arrHeaders property, as they should be assigned to other properties
    const skip = [ 
      '5WHY_REPORTS.MAIL_SUBJECT',    // strReportSubject
      '5WHY_REPORTS.MAIL_FILE_NAME',  // strFileNameDownload
      '5WHY_REPORTS.WHY',             // arrWhyHeaders
      '5WHY_REPORTS.EVIDENCE',        // arrWhyHeaders
      'LOGBOOK.ROUTINE_COMMENTS',     // arrWhyHeaders
    ]
    
    this._translateService.get(observables).subscribe(translations => {

      this.strReportSubject = translations['5WHY_REPORTS.MAIL_SUBJECT'];
      this.strFileNameDownload = translations['5WHY_REPORTS.MAIL_FILE_NAME'];
      this.arrWhyHeaders.push(translations['5WHY_REPORTS.WHY']);
      this.arrWhyHeaders.push(translations['5WHY_REPORTS.EVIDENCE']);
      this.arrWhyHeaders.push(translations['LOGBOOK.ROUTINE_COMMENTS']);

      for(const key of observables){
        if(!skip.includes(key)){
          this.arrHeaders.push(translations[key])
        }
      }
      if (fnCallback) {
        fnCallback();
      }
    });
  }

  //TODO TRANSLATE REPORT CONSOLIDATED
  private _translateReport(fnCallback) {
    this.strReportSubject = '';
    this.strFileNameDownload = '';
    this.arrHeaders = [];

    const observables = [
      '5WHY_REPORTS.MAIL_SUBJECT_CONSOLIDATED',     // strReportSubject
      '5WHY_REPORTS.MAIL_FILE_NAME_CONSOLIDATED',   // strFileNameDownload
      '5WHY_REPORTS.STR_WORLD',
      '5WHY_REPORTS.STR_REGIONAL',
      '5WHY_REPORTS.STR_LOCATION',
      '5WHY_REPORTS.STR_AREA',
      '5WHY_REPORTS.STR_SUBAREA',
      '5WHY_REPORTS.STR_EQUIPMENT',
      '5WHY_REPORTS.STR_MACHINE',
      '5WHY_REPORTS.STR_DATE',
      '5WHY_REPORTS.STR_CREW',
      '5WHY_REPORTS.STR_CATEGORY',
      '5WHY_REPORTS.STR_TRIGGER',
      '5WHY_REPORTS.STR_REF_COD',
      '5WHY_REPORTS.STR_TITLE',
      '5WHY_REPORTS.STR_STEP',
      '5WHY_REPORTS.STR_ROOT_CAUSE_FOUND',
      '5WHY_REPORTS.STR_SDCA_MATRIX',
      '5WHY_REPORTS.STR_COMPLETION_TIME',
      '5WHY_REPORTS.STR_NUMBER_OF_ACTIONS',
      '5WHY_REPORTS.STR_RATING',
      '5WHY_REPORTS.CANCEL_COMMENT',
    ];

    // keys to skip on arrHeaders property, as they should be assigned to other properties
    const skip = [ 
      '5WHY_REPORTS.MAIL_SUBJECT_CONSOLIDATED',    // strReportSubject
      '5WHY_REPORTS.MAIL_FILE_NAME_CONSOLIDATED',  // strFileNameDownload
    ]

    this._translateService.get(observables).subscribe(translations => {

      this.strReportSubject = translations['5WHY_REPORTS.MAIL_SUBJECT_CONSOLIDATED'];
      this.strFileNameDownload = translations['5WHY_REPORTS.MAIL_FILE_NAME_CONSOLIDATED'];

      for(const key of observables){
        if(!skip.includes(key)){
          this.arrHeaders.push(translations[key])
        }
      }
      if (fnCallback) {
        fnCallback();
      }
    });
  }
}
