import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../main/access/services/auth.service';
import {UserPreferencesService} from '../../user-preferences/services/user-preferences.service';

@Injectable()
export class MainService {
    private _socket_url;
    private _api_url;

    // Set headers to handle the CORS
    private _headers: HttpHeaders;

    constructor(
        private _http: HttpClient, 
        private _authService: AuthService, 
        private _userPreferenceService: UserPreferencesService,
        ) {
        this._api_url = environment().cockpitApiUrl;

        const obj_user = _authService.getAuthenticatedUser();
        // Set headers to handle the CORS
        this._headers = new HttpHeaders(
            {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
                'Access-Control-Allow-Headers': 'Content-Security-Policy, X-Frame-Options, X-Content-Type-Options, Referrer-Policy, Feature-Policy, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin',
                'Access-Control-Allow-Credentials': 'true',
                'userlang': this._userPreferenceService.getLocalLanguage(),
                'X-Content-Type-Options': 'nosniff',                
                'X-Frame-Options': 'DENY',
                'Referrer-Policy': 'origin-when-cross-origin',
                'Feature-Policy': 'accelerometer "none"; camera "none"; geolocation "none"; gyroscope "none"; magnetometer "none"; microphone "none"; payment "none"; usb "none";'
            
            }
        );
        
    }

    private _getHeaders(method: string) {
        this._headers = this._headers.set('Access-Control-Allow-Methods', method.toUpperCase());
        this._headers = this._headers.set('userlang', this._userPreferenceService.getLocalLanguage());
        return this._headers;
    }

    getSocketUrl() {
        return this._socket_url;
    }

    getApiUrl() {
        return this._api_url;
    }

    /**
     * GET request
     * @param path: Path of endpoint
     */
    get(path, params = null): Observable<any> {
        const url = this.getApiUrl() + path;

        if (params != null) {
            try {
                params = new HttpParams({fromObject: params});                
            } catch (error) {
                params = null;
            }
        }

        const opt = { headers: this._getHeaders('GET'), params: params };
        const result = this._http.get(url, opt);
        result.pipe(
            map((res: Response) => {
                if (res.status == 401) {
                    this._authService.deleteAuthenticatedUser();
                    window.location.replace('');
                }
            })
        );
        return result;
    }

    /**
     * GET file request
     * @param path: Path
     */
    getFile(path): Observable<any> {
        const url = path;
        return this._http.get(url);
    }

    /**
     * POST request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    post(path: string, params: Object): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('POST')};
        return this._http.post(url, params, opt);
    }

    /**
     * PUT request
     * @param path: Path of endpoint
     * @param params: JSON object relative to endpoint
     */
    put(path: string, params: Object): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('PUT')};
        return this._http.put(url, params, opt);
    }

    /**
     * DELETE request
     * @param path: Path of endpoint
     */
    delete(path: string): Observable<any> {
        const url = this.getApiUrl() + path;
        const opt = {headers: this._getHeaders('DELETE')};
        return this._http.delete(url, opt);
    }

    getBlob(url: string): Observable<any> {
        return this._http.get(url, {responseType: 'blob'});
    }

    static getArrayContent(data: any) {
        if (!data || typeof data == typeof undefined) return [];
        return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
    }

    mapStringToArray(value: number | string | string[]): Array<string | number> {
        return Array.isArray(value) ? value : [value]
    }

    mapArrayToString(value: number | string | string[]): string {
        return Array.isArray(value) ? value.join() : value?.toString()
    }
}
