import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';

@Injectable()
export class LogbookRoutineService {

    constructor(private _mainService: MainService) {
    }

    /**
     * Display list of all routines
     * @param param: JSON object filled with search "term" or "id"
     */
    getRoutinesList(param): Observable<any> {
        let path = '/routines-autocomplete?term=' + encodeURIComponent(param.term) + '&cod_user='+param.cod_user + '&privilege=' + param.str_privilege;
        if (param.cod_routines > 0) {
            path = '/routine/' + param.cod_routines + '/' + param.cod_user + '/' + param.date;
        }

        return this._mainService.get(path);
    }

    /**
     * Post routine object to database
     * @param params: JSON object of routine
     */
    postRoutine(params): Observable<any> {
        let path = '/routine';
        return this._mainService.post(path, params);
    }

    /**
     * Put routine object to database
     * @param params: JSON object of routine
     */
    putRoutine(params): Observable<any> {
        let path = '/routine';
        return this._mainService.put(path, params);
    }

    /**
     * Delete routine object from database
     * @param param: JSON object filled with "id" of routine
     */
    deleteRoutine(params): Observable<any> {
        let path = '/routine/' + params.id;
        return this._mainService.delete(path);
    }

    getRoutinePeriodicityUser(params): Observable<any> {
        let path = '/set-routine-periodicity';
        return this._mainService.post(path, params);
    }

    getRoutinePeriodicity(params): Observable<any> {
        let path = '/get-routine-periodicity/' + params.cod_logbook_routines_periodicity + '/' + params.cod_user + '/' + params.cod_routine_user + '/' + params.date;
        return this._mainService.get(path);
    }

    getFunctionalRoutine(params): Observable<any> {
        const path = `/get-functional-routine/${params.cod_routine}/${params.cod_logbook_routines_periodicity}/${params.cod_user}/${params.cod_routine_user}/${params.date}`;
        return this._mainService.get(path);
    }

    changeRoutineStatus(params): Observable<any> {
        let path = '/change-routine-status';
        return this._mainService.post(path, params);
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getRoutinesHistory(cod_user, cod_routine, limit): Observable<any> {
        let str_path = '/routine-history/' + cod_user + '/' + cod_routine + '/' + limit;
        return this._mainService.get(str_path);
    }

    getShifts(params): Observable<any>{
        let str_path = '/get-all-shifts';
        return this._mainService.post(str_path, params);
    }

    getLogbookShifts(params): Observable<any>{
        let str_path = '/get-logbook-shifts';
        return this._mainService.post(str_path, params);
    }

    getCrews(cod_user: number): Observable<any> {
        const str_path = `/get-all-crews/${cod_user}`;
        return this._mainService.get(str_path);
    }
}
