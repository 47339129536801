import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Whys } from "app/five-why/model/whys.model";
import { AuthService } from "app/main/access/services/auth.service";
import { UploadService } from "app/main/services/upload.service";


@Component({
  selector: "app-single-why",
  templateUrl: "./single-why.component.html",
  styleUrls: ["./single-why.component.css"],
})
export class SingleWhyComponent implements OnInit {
  @Input("why") why: Whys;
  @Input("disabled") disabled: boolean;
  @Input("whyNumber") whyNumber: number;
  @Input("causeFound") causeFound: boolean;
  @Input("showRootCauseOption") showRootCauseOption: boolean;
  @Input("blockDescription") blockDescription: boolean;
  @Input("disableWhyComments") disableWhyComments: boolean = false;

  @Output("onWhyChanged") onWhyChanged = new EventEmitter<{}>();
  @Output("onDeleteWhy") onDeleteWhy = new EventEmitter<number>();

  @ViewChild('fileInput') fileInput; 

  goldMedal: string = "../../../../assets/images/gold-medal.svg";
  trashIcon: string = "../../../../assets/images/icon-trash.svg";
  loader: string = "../../../../assets/images/animated/spinner-loader.svg"

  lastUpdateInformations: string = "";
  lastUpdateInformationsComments: string = "";
  isAttachmentOpen: boolean = false;
  attachmentsClone: any = [];

  showError: boolean = false;
  errorMessage: string;

  isLoadingAttachment: boolean;

  cod_user: string;

  widthToCopy: string;

  constructor(
    private _authService: AuthService,
    private _uploadService: UploadService,
    private _translateService: TranslateService
  ) {
    this.cod_user = _authService.getAuthenticatedUser().id;
  }

  ngOnInit() {
    if(document){
      this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
    }
    
    if (this.why.str_name_user_last_update) {
      this.lastUpdateInformations = `${this.why.str_name_user_last_update} - ${this.why.dat_last_alt}`;
    }

    if (this.why.str_comment) this.lastUpdateInformationsComments = `${this.why.str_name_user_comment} - ${this.why.dat_comment}`;

    this.attachmentsClone = JSON.parse(JSON.stringify(this.why.attachments));

    if (this.attachmentsClone == undefined) {
      this.attachmentsClone = [];
    } else { 
      this.attachmentsClone.forEach(attach => {
        attach.str_file = attach.str_url_file;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // work around to maintain same width - get css props from add whys button and copy width every time screen size changes.
    this.widthToCopy = window.getComputedStyle(document.getElementsByClassName('add-whys')[0] , null).width.replace('px', '');
  }

  deleteWhy() {
    this.onDeleteWhy.emit(this.whyNumber - 1);
  }

  onAnswerChanged(event) {
    this.why.bol_root_cause_found = event;
    this.whyChangeEvent();
  }

  whyChangeEvent() {
    this.onWhyChanged.emit({ whyNumber: this.whyNumber - 1, why: this.why });
  }

  toggleAttachments() {
    this.isAttachmentOpen = !this.isAttachmentOpen;
  }

  fileChangeEvent(event) {
    this.isLoadingAttachment = true;

    let obj_params = {
      event: event,
      allowedExtensions: [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
      ],
    };

    this._uploadService.uploadFileBase64(obj_params).subscribe(
      (response) => {
        if (response.type == "success") {
          let imgName = response.content.str_name
          const splitText = imgName.split('.');
          const fileType = splitText[splitText.length - 1];  
          const newFile = {cod_usu_ins: this.cod_user,  dat_ins: new Date(), str_url_file: response.content.str_name, isPdf: fileType.toUpperCase() == 'PDF' };
          this.why.attachments.push(newFile);
          this.attachmentsClone.push(newFile);
          this.fileInput.nativeElement.value = '';
          this.whyChangeEvent();
          this.isLoadingAttachment = false;
        } else {
          this.isLoadingAttachment = false;
          this._showErrorMessage(response.content);
        }
      },
      (err) => {
        let e = JSON.parse(err._body);
        console.log(e);
        this._showErrorMessage(err);
      }
    );
  }

  onDeleteFile(imageDeleted) {
    if (imageDeleted.cod_why_attachment > 0) {
      this.why.attachments.forEach((attach) => {
        if (attach.cod_why_attachment === imageDeleted.cod_why_attachment) 
            attach.deleted = true;
        });
    } else {
      this.why.attachments = this.why.attachments.filter( attach => attach.str_url_file !== imageDeleted.str_url_file);
    }
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe((translate) => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }
}
