import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UsersComponent} from './users/users.component';
import {AuthGuard} from '../guards/auth-guard';
import {UsersService} from './services/users.service';
import {UsersProfilesService} from './services/users-profiles.service';
import {MainService} from './services/main.service';
import {AdminComponent} from './admin/admin.component';
import {UsersProfilesComponent} from './users-profiles/users-profiles.component';
import {ComponentsModule} from '../components/components.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsDynamicModule} from '../forms/forms-dynamic/forms-dynamic.module';
import {LogbooksModule} from '../logbooks/logbooks.module';
import {MeetingsModule} from '../meetings/meetings.module';
import {FiveWhyModule} from '../five-why/five-why.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { CrewListComponent } from './crew/crew-list/crew-list.component';
import { CrewPersistComponent } from './crew/crew-persist/crew-persist.component';
import { UsersProfilesApprovalComponent } from './users-profiles-approval/users-profiles-approval.component';
import { ShiftPersistComponent } from "./shift/shift-list/shift-persist/shift-persist.component";
import { ShiftListComponent } from "./shift/shift-list/shift-list.component";
import { ShiftPersistFormMemberComponent } from "./shift/shift-list/shift-persist/shift-persist-form-member/shift-persist-form-member.component";

import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MsalRedirectComponent } from '@azure/msal-angular';

// routes
const routes: Routes = [
    {
        path: 'admin',
        children: [
            { path:'', component: AdminComponent },
            { path:'meeting-type/:cod_meeting_type', component: AdminComponent },
        ],
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'admin/users',
        component: UsersComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'admin/users-profiles',
        component: UsersProfilesComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    },
    {
        path: 'admin/users-profiles-approval',
        component: UsersProfilesApprovalComponent,
        canActivate: [
            AuthGuard, MsalGuard
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        TranslateModule,
        FormsDynamicModule,
        LogbooksModule,
        FiveWhyModule,
        MeetingsModule,
        ComponentsModule,   
        ReactiveFormsModule,     
    ],
    exports: [UsersComponent],
    declarations: [
        UsersComponent,
        AdminComponent,
        UsersProfilesComponent,
        UsersProfilesApprovalComponent,
        CrewListComponent,
        CrewPersistComponent,
        ShiftListComponent,
    	ShiftPersistComponent,
    	ShiftPersistFormMemberComponent,
    ],
    providers: [
        MainService,
        UsersService,
        UsersProfilesService,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
    ]
})
export class AdminModule {
}
