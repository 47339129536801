import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PdfMakerService } from './services/pdf-maker.service';
import { NotificationService } from "app/main/services/notification.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pdf-maker',
  templateUrl: './pdf-maker.component.html',
  styleUrls: ['./pdf-maker.component.css']
})
export class PdfMakerComponent implements OnInit {

  bol_rendering_pdf = false;
  error_message = '';
  @Input() pdfMakePath;
  @Input() pdfParams;
  @Input() display = true;  
  @Input() disabled = false;  
  @Input() message;
  @Input() showChangesMessage = false;
  @Input() hintMessage = 'Download';
  @Input() needValidation: boolean = false;

  @Output() onDownloadPfd = new EventEmitter<any>();

  constructor(private pdfMakerService: PdfMakerService, private _notify: NotificationService, private _translateService: TranslateService) { 
    this._translateService.get('GENERAL.ERROR_PDF').subscribe((translate) => {
      this.error_message = translate;
    });
    this._translateService.get('GENERAL.DOWNLOAD').subscribe((translate) => {
      this.hintMessage = translate;
    });
  }

  ngOnInit(): void {
    
  }

  download(){
    this.showChangesMessage = false;
    this.bol_rendering_pdf =  true;
    if(this.pdfMakePath && this.pdfParams){
      this.pdfMakerService[this.pdfMakePath](this.pdfParams).subscribe(data => {
        if(data.code === 888){
          try {
            this.bol_rendering_pdf=false
            let date = new Date();
            let str_name = this.pdfParams.name + date.getFullYear() + '_' + ('0' + (date.getMonth() + 1)).slice(-2)  + '_' + ('0' + (date.getDate())).slice(-2);
  
            let a = document.createElement("a");
            a.href = data.content;
            a.download = str_name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } catch (error) {
            this.bol_rendering_pdf = false;
            this._notify.error(this.error_message);
            console.log(error);
          }
        }else{          
          this.bol_rendering_pdf = false;
          this._notify.error(this.error_message);
        }
      });
    }
  }

  callDownload(){ 
    if(this.needValidation) {
      this.onDownloadPfd.emit(() => {
        this.download()
      });
    } else {
      this.download();
    }
  }

  

  closeConfirmMessage(){
    this.showChangesMessage = false;
  }


}
