import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Crew } from "app/admin/models/crew.model";
import { UsersService } from "app/admin/services/users.service";
import { CrewService } from "app/five-why/services/crew.service";
import { FiveWhyService } from "app/five-why/services/five-why.service";
import { ValidationsService } from "app/five-why/services/validations.service";
import { AuthService } from "app/main/access/services/auth.service";
import { NotificationService } from "app/main/services/notification.service";

@Component({
  selector: "app-crew-persist",
  templateUrl: "./crew-persist.component.html",
  styleUrls: ["./crew-persist.component.css"],
})
export class CrewPersistComponent implements OnInit {
  @Input("codLocation") codLocation;
  @Output() backEvent = new EventEmitter<Object>();
  crews: Crew[] = [];
  locationCrews: {
    cod_location: 0;
    crews: [];
  };
  cod_user: 0;
  isLoading: boolean = false;
  isEdition: boolean = false;
  paramsSelected: any = [];
  locationsList: any = [];
  loadingConfig: any = [];
  showError: boolean = false;
  errorMessage: string;
  crewsClone: Crew[] = [];
  deletedCrews: Crew[] = [];

  constructor(
    private _crewService: CrewService,
    private _authService: AuthService,
    private _fivewhyService: FiveWhyService,
    private _translateService: TranslateService,
    public _validationService: ValidationsService,
    private _usersGroupPrivilegesService: UsersService,
    private _notify: NotificationService
  ) {
    this.showError = false;
    this.errorMessage = "";

    this.cod_user = this._authService.getAuthenticatedUser().id;
  }

  ngOnInit() {
    this.loadInformations();
    this.loadingConfig["locations"] = false;
  }

  loadInformations() {
    if (this.codLocation > 0) {
      this.isLoading = true;
      this.isEdition = true;
      this._crewService
        .getLocationCrew({
          cod_user: this.cod_user,
          cod_location: this.codLocation,
        })
        .subscribe(
          (data) => {
            if (
              data.code === 888 &&
              data.content.code === 888 &&
              data.content.length > 0
            ) {
              this.crews = data.content.results;
              this.crewsClone = JSON.parse(JSON.stringify(this.crews));

              this._loadLocations(() => {
                this.paramsSelected["locations_selected"] = [
                  { cod_locations: this.codLocation },
                ];
                this.paramsSelected["locations"] = this.codLocation;

                this.isLoading = false;
              });
            } else {
              this.isLoading = false;
              this._showErrorMessage(data.content.message);
              this.backSelected(false);
            }
          },
          (err) => {
            this._authService.errCheck(err);
            this._showErrorMessage(err);
          }
        );
    } else {
      this._loadLocations(null);
    }
  }

  private _setLoading(field, isLoading) {
    this.loadingConfig[field] = isLoading;
  }

  private _resetCrews() {
    this.crews = [];
  }

  public getParamsToSave() {
    return {
      cod_user: this.cod_user,
      cod_location: this.paramsSelected["locations"],
      crews: this.validateCrewsToUpIn(),
    };
  }

  public validateCrewsToUpIn() {
    const crewsToUpdate = this.crews.filter((crew) => {
      if (crew.cod_crews == 0) return true;

      return this.crewsClone.some((crewClone) => {
        if (crewClone.cod_crews == crew.cod_crews) {
          return crewClone.str_description != crew.str_description;
        }
      });
    });

    return crewsToUpdate.concat(this.deletedCrews);
  }

  public addCrew() {
    this.crews.push(new Crew());
  }

  public removeCrew(index) {
    const crewToDelete = this.crews.splice(index, 1)[0];
    crewToDelete.cod_crews > 0
      ? this.deletedCrews.push({ ...crewToDelete, deleted: true })
      : "";
  }

  public save() {
      this.saveCrew();
  }

  public saveCrew() {
    if (this.validateCrewInformation()) {
      this._showErrorMessage("CREW.BLANK_DESCRIPTION");
    } else { 
      const params = this.getParamsToSave();
      this._validationService.boolSubmit = true;
      this._validationService.boolIsLoading = true;
  
      this._crewService.saveCrews(params).subscribe(res => {
          this._validationService.boolSubmit = false;
          this._validationService.boolIsLoading = false;
          if (res.type !== 'success') {
            this._showErrorMessage(res.content.message);
          }
          else {
            this._notify.success({text: 'CREW.SAVED', translate: true});
            this.backSelected(true);
          }
          this._resetCrews();
      }, err => {
          this._validationService.boolSubmit = false;
          this._validationService.boolIsLoading = false;
          let e = JSON.parse(err._body);
          this._showErrorMessage(e.content);
          this._authService.errCheck(err);
      });      
    }
  }

  private validateCrewInformation() {
    return this.crews.some((crew) => crew.str_description == "" );
  }

  private _loadLocations(fn) {
    this._setLoading("locations", true);
    let params = {
      term: "",
      cod_user: this._authService.getAuthenticatedUser().id,
      dsc_page_privilege: "ADMIN_LIST_CREW",
    };
    this._fivewhyService.getAllLocationsByPrivileges(params).subscribe(
      (data) => {
        this.locationsList = this._usersGroupPrivilegesService.getArrayContent(
          data
        );
        this._setLoading("locations", false);
        if (typeof fn != typeof undefined && fn) {
          fn();
        }
      },
      (err) => {
        this._authService.errCheck(err);
        console.log(err);
      }
    );
  }

  selectLocations($event) {
    this.paramsSelected["locations_selected"] = $event.selectedOptions;
    this.paramsSelected["locations"] = this._implodeValues(
      $event.selectedOptions,
      "cod_locations"
    );

    const params = {
      cod_user: this.cod_user,
      cod_location: this.paramsSelected["locations"],
    };

    this._setLoading("locations", true);
    this.isLoading = true;
    this._crewService.getLocationCrew(params).subscribe(
      (data) => {
        this.crews = data.content.results;
        this.crewsClone = JSON.parse(JSON.stringify(this.crews));

        this._setLoading("locations", false);
        this.isLoading = false;
      },
      (error) => {
        this._notify.error({text: 'CREW.LOCATION_ERROR', translate: true})
        this._setLoading("locations", false);
      }
    );
  }

  private _implodeValues(objValues, idField) {
    let ids = "";
    if (objValues) {
      objValues.forEach((element) => {
        if (ids != "") {
          ids += ",";
        }
        ids += element[idField];
      });
    }
    return ids;
  }

  backSelected(success: boolean = false) {
    this._validationService.boolSubmit = false;
    this._validationService.boolIsLoading = false;
    if (success) {
      this.codLocation = -1;
    } else {
      this.codLocation = 0;
    }
    this._resetCrews();
    this.backEvent.emit({ codLocation: this.codLocation });
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe((translate) => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }
}
