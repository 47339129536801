import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { convertChar } from '../../helpers/general-helper';
import * as moment from "moment";

type ActionsReportResult = {
    dat_creation: string;
    str_origin: string;
    str_indicators: string;
    str_fundamental_cause: string;
    str_hashtag: string;
    str_action: string;
    str_resp_exec_action: string;
    str_id_resp_exec_action: string;
    dat_limit: string;
    str_status: string;
    str_comments: string;
    dat_request_to_close: string;
    dat_closed: string;
    str_time_conclusion: string;
}

@Injectable({
    providedIn: 'root'
})
export class ExportActionsService {

    constructor(private readonly _translateService: TranslateService) { }

    export(content: ActionsReportResult[], meetingName: string) {
        forkJoin([
            this._translateService.get("MEETINGS_REPORTS.DATE_CREATOR"),
            this._translateService.get("MEETINGS_REPORTS.SOURCE"),
            this._translateService.get("MEETINGS_REPORTS.TAG"),
            this._translateService.get("MEETINGS_REPORTS.FUNDAMENTAL_CAUSE"),
            this._translateService.get("MEETINGS_REPORTS.HASHTAG"),
            this._translateService.get("MEETINGS_REPORTS.STR_ACTION"),
            this._translateService.get("MEETINGS_REPORTS.RESPONSIBLE_ACTION"),
            this._translateService.get("MEETINGS_REPORTS.ID_RESPONSIBLE_ACTION"),
            this._translateService.get("MEETINGS_REPORTS.DATE_LIMIT"),
            this._translateService.get("MEETINGS_REPORTS.STR_STATUS"),
            this._translateService.get("MEETINGS_REPORTS.COMMENTS"),
            this._translateService.get("MEETINGS_REPORTS.DATE_UPDATE"),
            this._translateService.get("MEETINGS_REPORTS.DATE_COMPLETED"),
            this._translateService.get("MEETINGS_REPORTS.HOUR_COMPLETED"),
        ]).subscribe(results => {
            const csvString = this.formatCsvContent(results, content);
            this.generateDownload(csvString, meetingName);
        });
    }

    private generateDownload(csvString: string, meetingName: string) {
        const fileName = `${convertChar(meetingName)}${moment(new Date()).format('YYYY-MM-DD-HH_mm_ss')}.csv`;
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    private formatCsvContent(columns: string[], content: ActionsReportResult[]): string {
        let csvContent: string[] = [];

        csvContent.push(columns.join(','));

        content.forEach((x: ActionsReportResult) => {
            let row: string[] = [];
            row.push(x.dat_creation);
            row.push(x.str_origin);
            row.push(x.str_indicators);
            row.push(x.str_fundamental_cause);
            row.push(x.str_hashtag);
            row.push(x.str_action);
            row.push(x.str_resp_exec_action);
            row.push(x.str_id_resp_exec_action);
            row.push(x.dat_limit);
            row.push(x.str_status);
            row.push(x.str_comments);
            row.push(x.dat_request_to_close);
            row.push(x.dat_closed);
            row.push(x.str_time_conclusion);
            csvContent.push(row.join(','));
        });

        const csvString = csvContent.join('\n');
        return csvString;
    }
}
