import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "chart-pie-percent",
  templateUrl: "./chart-pie-percent.component.html",
  styleUrls: ["./chart-pie-percent.component.css"],
})
export class GridPieComponent {
  @Input() chartData;
  @Input() chartOptions;
  @Input() chartSeries;
  @Input() chartPercent;

  options: Object;
  percentDone: number = 0;
  percentToDo: number = 1;
  percent: number = 0;
  chart: any = null;

  constructor() {

    this.options = {
      title: {
        text: ``,
      },
      tooltip: {
        trigger: "item",
        formatter: `{b}`,
      },
    };

    this.chartOptions = {
      pointFormat: 45,
      color: ["rgb(40,63,152)", "rgb(197,197,197)"],
      innerSize: 115,
      width: 180,
      height: 180,
      fontSize: 34,
      percentMarginTop: 35,
      percentMarginLeft: 30,
    };

    this.setOptionsChart();
  }

  ngOnInit() {
    this.calcValues();
    this.setOptionsChart();
  }

  ngOnChanges(): void {
    this.calcValues();
    this.setOptionsChart();
  }

  calcValues() {
    if (this.chartData) {
      this.percentDone = this.chartData.value;
      this.percentToDo = this.chartData.total - this.chartData.value;
      this.percent = Math.round(
        Math.round(
          ((this.chartData.value * 100) / this.chartData.total) * 100
        ) / 100
      );
    }

    if (this.chartPercent >= 0) this.percent = this.chartPercent;

    if (isNaN(this.percent)) {
      this.percentDone = 0;
      this.percentToDo = 1;
      this.percent = 0;
    }
  }

  setOptionsChart() {

    if (this.chart == null) return;

    setTimeout(() => {

      this.chart.setOption({
        series: [
          {
            radius: ['50%', '60%'],
            data: [
              { value: this.percent, name: '' },
              { value: (100 - this.percent), name: '' },
            ],
            labelLine: {
                show: false
            },
            type: "pie",
            color: ["rgb(40,63,152)", "rgb(197,197,197)"],
          },
        ],
        color: [],
      }, true);

    }, 50);
    
  }

  saveInstance(instance) {
    this.chart = instance;  
    this.setOptionsChart();
  }

}
