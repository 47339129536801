// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  margin: 80px 0;
  text-align: center;
  font-size: 1.2em;
  color: var(--text-secondary);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container svg {
  width: 50px;
  height: 50px;
  color: var(--ui-loading-primary);
  animation: spin 900ms infinite linear;
}

.loading-container.--theme-white {
  color: var(--text-inverted)
}

.loading-container.--theme-white svg{
  color: var(--ui-loading-inverted);
}

.glyphicon--spin {
  color: #5599FF;
  animation: spin 1.5s infinite linear;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,qCAAqC;AACvC;;AAEA;EACE;AACF;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,cAAc;EAEd,oCAAoC;AACtC","sourcesContent":[".loading-container {\n  margin: 80px 0;\n  text-align: center;\n  font-size: 1.2em;\n  color: var(--text-secondary);\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loading-container svg {\n  width: 50px;\n  height: 50px;\n  color: var(--ui-loading-primary);\n  animation: spin 900ms infinite linear;\n}\n\n.loading-container.--theme-white {\n  color: var(--text-inverted)\n}\n\n.loading-container.--theme-white svg{\n  color: var(--ui-loading-inverted);\n}\n\n.glyphicon--spin {\n  color: #5599FF;\n  -webkit-animation: spin 1.5s infinite linear;\n  animation: spin 1.5s infinite linear;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
