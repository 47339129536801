// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-flag {
    overflow: hidden;
    display: inline-block;
    background-color: whitesmoke;
    border: 1px solid #C0C0C0;
    margin-right: 2px;
    border-radius: 40px;
    height: 35px;
    width: 35px;
    text-align: center;
    padding-top: 5px;
}

.language-flag-img {
    height: 15px;
    width: 15px;
}

.language-low-value {
    font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
    padding: 6px 10px;
    overflow: hidden;
    display: inline-block;
    border: 1px solid #C0C0C0;
    border-radius: 3px;
    background-color: whitesmoke;
    margin-left: 2px;
}

.language-low-value-label {
    font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
    padding: 6px 10px;
    overflow: hidden;
    display: inline-block;
    border-radius: 3px;
    background-color: #C0C0C0;
}

.language-container {
    display: block;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    float: left;
}

.translation-description {
    display: block;
    overflow: hidden;
    padding-left: 20px;
}

.translation-description-input {
    padding: 6px 10px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/translations-input/translations-input.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,4BAA4B;IAC5B,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,yDAAyD;IACzD,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,yDAAyD;IACzD,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".language-flag {\n    overflow: hidden;\n    display: inline-block;\n    background-color: whitesmoke;\n    border: 1px solid #C0C0C0;\n    margin-right: 2px;\n    border-radius: 40px;\n    height: 35px;\n    width: 35px;\n    text-align: center;\n    padding-top: 5px;\n}\n\n.language-flag-img {\n    height: 15px;\n    width: 15px;\n}\n\n.language-low-value {\n    font-family: \"Lucida Grande\", Tahoma, Verdana, sans-serif;\n    padding: 6px 10px;\n    overflow: hidden;\n    display: inline-block;\n    border: 1px solid #C0C0C0;\n    border-radius: 3px;\n    background-color: whitesmoke;\n    margin-left: 2px;\n}\n\n.language-low-value-label {\n    font-family: \"Lucida Grande\", Tahoma, Verdana, sans-serif;\n    padding: 6px 10px;\n    overflow: hidden;\n    display: inline-block;\n    border-radius: 3px;\n    background-color: #C0C0C0;\n}\n\n.language-container {\n    display: block;\n    overflow: hidden;\n    padding-left: 10px;\n    padding-right: 10px;\n    float: left;\n}\n\n.translation-description {\n    display: block;\n    overflow: hidden;\n    padding-left: 20px;\n}\n\n.translation-description-input {\n    padding: 6px 10px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
