import {Injectable} from '@angular/core';

import {MainService} from './main.service';

@Injectable()
export class OnlineReportsService {

    constructor(private _mainService: MainService) {

    }
    
    getRoutineReportsCollab(searchParams) {
        const path = '/get-online-checklists-routines-collab';
        return this._mainService.post(path, searchParams);
    }

    getRoutines(searchParams: any) {
        const path = '/get-online-routines-by-date-collab';
        return this._mainService.post(path, searchParams);
    }

    getRoutinesNotDone(searchParams: any) {
        const path = '/get-online-routines-not-done-by-date-collab';
        return this._mainService.post(path, searchParams);
    }

    getQtdRoutinesCollab(searchParams) {
        const path = '/get-online-qtd-routines-collab';
        return this._mainService.post(path, searchParams);
    }
    
    getRoutineReportsFunctional(searchParams) {
        const path = '/get-online-checklists-routines-functional';
        return this._mainService.post(path, searchParams);
    }

    getQtdRoutinesFunctional(searchParams) {
        const path = '/get-online-qtd-routines-functional';
        return this._mainService.post(path, searchParams);
    }

    getRoutinesFunctional(searchParams: any) {
        const path = '/get-online-routines-by-date-functional';
        return this._mainService.post(path, searchParams);
    }

}