import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'component-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
})

export class CheckboxComponent implements OnInit, OnChanges {

    @Input() label: string;
    @Input() value: string;
    @Input() disabled: boolean;
    @Input() selected: boolean;

    @Output()
    selectionChanged: EventEmitter<any> = new EventEmitter<any>();

    isChecked: boolean;

    constructor() {
        this.selected = false;
        this.isChecked = false;
        this.disabled = false;
    }

    toggleObjSelection($event, value) {
        if (!this.disabled) {
            this.isChecked = !this.isChecked;
            if (this.isChecked) {
                this.selectionChanged.emit(value);
            }
            else {
                this.selectionChanged.emit('');
            }
        }
        else {
            return false;
        }
    }

    ngOnInit(): void {
        this.isChecked = false;
        if (this.selected) {
            this.isChecked = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes.selected !== typeof undefined) {
            this.isChecked = (changes.selected.currentValue);
        }
    }
}
