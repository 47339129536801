import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Auth} from '../models/auth.model';
import {AuthService} from '../services/auth.service';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {HelpTutorialService} from '../../services/help-tutorial.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['../access.component.css', './auth.component.css'],
    providers: [
        Auth,
        AuthService
    ]
})
export class AuthComponent implements OnInit {
    public authStep: number;
    public userType: string;
    public auth: Auth;
    showError: boolean = false;
    errorMessage: string = '';

    constructor(private router: Router, private authService: AuthService, private appComponent: AppComponent,
                private translateService: TranslateService, private _helpTutorialService: HelpTutorialService,
                private activatedRoute: ActivatedRoute, private http: HttpClient) {
        this.authStep = 1;
        this.auth = new Auth();
        this.showError = false;
        this.errorMessage = '';
    }

    //get auth session and store into localstorage
    doAutoLogin(userId, sessionId){
      this.authService.getAuthUser(userId, sessionId).subscribe(data => {
          if (data.type === 'error') {
              let msg = '';
              if (data.content.error_msg) {
                  msg = data.content.error_msg;
              }
              else {
                  if (typeof data.content[0] != typeof undefined) {
                      msg = data.content[0].message;
                  }
                  else {
                      msg = data.content.error_message;
                  }
              }
              this._showErrorMessage(msg);
              return;
          }

          this.authService.setAuthenticatedUser(data.content);
          this.setLanguage(data.content.userlang);
          this.appComponent.loadComponents();
          this.router.navigate(['/']);
      });
    }

    ngOnInit() {

      //1 - If has URL params userID & sessionID -> do auto-login
      this.activatedRoute.params.subscribe((params: Params) => {
        let userId = params['userId'];
        let sessionId = params['sessionId'];

        if(userId != null && sessionId != null){
          this.doAutoLogin(userId, sessionId);
        }
      });

    }

    keypress($event, jumpToNext) {
        let keyCode = $event.keyCode || $event.charCode;
        if (keyCode == 13 && !jumpToNext) {
            this.authenticate();
        }
    }

    authenticate() {
        // TODO validate
        if (!this.auth.username) {
            this._showErrorMessage('Usermame!');
            return;
        }

        if (this.authStep === 1) {
            // first step - get the user type

            this.authStep = 2;
            window.setTimeout(function () {
                if (document.getElementById('auth-password')) {
                    document.getElementById('auth-password').focus();
                }
            }, 100);
           
        } 
        // login dev only
        // else if (this.authStep === 2) {
        //     if (!this.auth.password) {
        //         this._showErrorMessage('Password!');
        //         return;
        //     }
        //     // second step - authenticate
        //     let username = this.auth.username.split('/').join('');
        //     let password = this.auth.password.split('/').join('');
        //     this.authService.getThirdPartyLogin(username, password).subscribe(data => {
        //         if (data.type === 'error') {
        //             let msg = '';
        //             if (data.content.error_msg) {
        //                 msg = data.content.error_msg;
        //             }else {
        //                 if(data.content.results.error_message){
        //                     msg = data.content.results.error_message;
        //                 }else{
        //                     msg = 'Error in request';
        //                 }
        //             }
        //             this._showErrorMessage(msg);
        //             return;
        //         }
        //         this.authService.setAuthenticatedUser(data.content);
        //         this.setLanguage(data.content.userlang);
        //         this.appComponent.loadComponents();
        //         this._helpTutorialService.startTutorial();
        //         this.router.navigate(['/']);
        //     });
        // }
     }

    setLanguage(userlang: string) {
        localStorage.setItem('userPreferencesLanguage', userlang);
    }

    closeErrorMessage() {
        this.showError = false;
        this.errorMessage = '';
    }

    private _showErrorMessage(err) {
        this.errorMessage = err;
        this.showError = true;
    }
}
