// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Component__Confirm__Actions {
    margin-right: 20px;
 }

 .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .container {
    width: 650px;
    margin: 0 auto;
    margin-top: 30vh;
    padding: 0.5em;
    background-color: #ffffff;
    border: 2px solid #e8e8e8;
    border-radius: 10px;
    color: #a9a9a9;
    text-align: center;
  }
  
  .confirm_message {
    padding-top: 1em;
  }
  
  .confirm_actions {
    padding: 1em 0;
    padding-top: 2em;
  }
  

.pull-right-btn-close {
    padding-left: 90%;
}

.import-button ::ng-deep .action-button {
  margin-left: 25px;
  width: 200px;
}

.import-excel-container {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/template-import-excel/template-import-excel.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;CACrB;;CAEA;IACG,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,oCAAoC;IACpC,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;;;AAGF;IACI,iBAAiB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".Component__Confirm__Actions {\n    margin-right: 20px;\n }\n\n .backdrop {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n  }\n  \n  .container {\n    width: 650px;\n    margin: 0 auto;\n    margin-top: 30vh;\n    padding: 0.5em;\n    background-color: #ffffff;\n    border: 2px solid #e8e8e8;\n    border-radius: 10px;\n    color: #a9a9a9;\n    text-align: center;\n  }\n  \n  .confirm_message {\n    padding-top: 1em;\n  }\n  \n  .confirm_actions {\n    padding: 1em 0;\n    padding-top: 2em;\n  }\n  \n\n.pull-right-btn-close {\n    padding-left: 90%;\n}\n\n.import-button ::ng-deep .action-button {\n  margin-left: 25px;\n  width: 200px;\n}\n\n.import-excel-container {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
