import {Component, OnInit} from '@angular/core';
import {UsersService} from '../services/users.service';
import {AuthService} from '../../main/access/services/auth.service';
import {User} from './models/user.model';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from "./../../main/services/notification.service";
import {SocketService} from '../../main/services/socket.service';
import { UsersProfilesService } from '../services/users-profiles.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    readonly recordLimit = 40;
    // jobs = [];
    // selectedJobs = [];
    worlds = [];
    selectedWorlds = [];
    selectedFunctWorlds = [];
    regionals = [];
    selectedRegionals = [];
    locations = [];
    selectedLocations = [];
    logbooks = [];
    selectedLogbooks = [];
    managers = [];
    model_managers = '';
    obj_ta_managers = {};
    selectedManagers = [];
    areas = [];
    selectedAreas = [];
    users = [];
    profiles = [];
    profiles_bkp = [];
    selectedProfiles = [];
    types = [];
    selectedTypes = [];
    searchTerm: string;
    str_filter: string;
    model: User;
    editMode: Boolean;
    searchTimer: any;
    list: any;
    isLoading: boolean = true;
    resultsPerPage: number[];
    resultsPerPageSelected: number;
    maxPage: number;
    totalPages: number[];
    pageIndex: number;
    reportsApiUrl = environment().reportsApiUrl;
    cod_user = this._authService.getAuthenticatedUser().id;
    downloadCsvSetup: any = {};
    isCreateVisible: boolean;
    bol_error_submit: Boolean = false;
    error_message_submit: string;
    strUserType: string;
    strTypeDescription: string;
    arrMonths = [];
    arrDays = [];
    arrYears = [];
    daySelected: number;
    monthSelected: number;
    yearSelected: number;
    timeToHideMessage: number;
    private timeToHideMessageConstant: number;
    private allUserTypes: any[];
    isLoadingRegional: boolean;
    isLoadingLocation: boolean;
    awaitConfirmDelete: boolean = false;
    deleteUserId: number = 0;
    bol_check_abi: boolean;
    bol_check_thrd: boolean;
    tmp_user_location: any;
    arr_tmp_areas: any[];
    title_profile_privilege: string;

    constructor(private _usersService: UsersService,
                private _usersProfilesService: UsersProfilesService,
                public _authService: AuthService,
                private _translateService: TranslateService,
                private sanitizer: DomSanitizer,
                private _notify: NotificationService,
                private _socketService: SocketService) {

        this.bol_check_abi = true;
        this.bol_check_thrd = true;
        this.model = new User();
        this.editMode = false;
        this.searchTerm = '';
        this.searchTimer = null;
        this.list = {
            users: []
        };
        this.pageIndex = 1;
        this.totalPages = [];
        this.resultsPerPage = [15, 50, 100];
        this.resultsPerPageSelected = this.resultsPerPage[0];
        this.isCreateVisible = true;
        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 2000;
        this.allUserTypes = [];
        this.isLoadingRegional = false;
        this.isLoadingLocation = false;
    }

    ngOnInit() {
        this.bol_check_abi = true;
        this.bol_check_thrd = true;

        // this._usersService.getJobs(this.cod_user).subscribe(data => {
        //         this.jobs = this._usersService.getArrayContent(data);
        //     },
        //     err => {
        //         this._authService.errCheck(err);
        //         console.log(err);
        //     }
        // );

        this._usersService.getTypes(this.cod_user).subscribe({
            next: data => {
                const types = this._usersService.getArrayContent(data).filter(value => {
                    return value.str_column_value == this.model.str_type;
                });
                if (types.length > 0) {
                    this.types = types[0];
                    this.strUserType = types[0].str_column_value;
                    this.strTypeDescription = types[0].str_value_description;
                } else {
                    this.types = [];
                    this.strUserType = '';
                    this.strTypeDescription = '';
                }
                this.allUserTypes = this._usersService.getArrayContent(data);
                this.getUserType(this.model.str_type);
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

        this._usersService.getProfiles(this.cod_user).subscribe({
            next: data => {
                this.profiles_bkp = this._usersService.getArrayContent(data);
                this.profiles = this.profiles_bkp.filter(profile => 
                    profile.bool_profile && profile.bool_privilege
                );
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

        let privAreas = '';
        if(this.model.cod_id){
            privAreas = 'ADMIN_NEW_USER';
        }else{
            privAreas = this.listPrivilege('USERS_SELECT_AREAS');
        }

        this._usersService.getAreas(this.cod_user, privAreas).subscribe({
            next: data => {
                this.areas = this._usersService.getArrayContent(data);
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

        let privZone = '';
        if(this.model.cod_id){
            privZone = 'ADMIN_NEW_USER';
        }else{
            privZone = this.listPrivilege('USERS_SELECT_WORLDS');
        }

        this._usersService.getWorlds(this.cod_user, privZone).subscribe({
            next: data => {
                this.worlds = this._usersService.getArrayContent(data);
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });

        this._translateService.get('USERS.MANAGER').subscribe(translate => {
            // let str_type='ALL';
            this.obj_ta_managers = {
                type: 'dynamic',
                valueProperty: 'cod_id',
                searchProperty: 'str_name',
                placeHolder: translate,
                hasImage: false,
                onSelect: (obj_selected: any) => {
                    this.selectedManagers.push({
                        cod_user: obj_selected.cod_id,
                        str_name: obj_selected.str_name,
                        str_img_path: obj_selected.str_img_path,
                    });

                    if (this.selectedManagers.length > 0) {
                        this.model.str_ambev_id_manager = obj_selected.str_username;
                    } else {
                        this.model.str_ambev_id_manager = '';
                    }
                },
                asynchDataCall: (value: string, cb: any) => {
                    const objParam = {
                        'limit': -1,
                        'str_filter': ((value == '' || value == undefined || value == null) ? '--' : value),
                        'offset': 0,
                        'cod_user': this._authService.getAuthenticatedUser().id,
                        'dsc_page_privilege': 'USERS_SCREEN_LISTING',
                        'str_type': 'ALL'
                    };
                    this._getUsersFilter(objParam, cb);
                },
                onBlur: (item: any) => {
                    this.model_managers = '';
                }
            };
        });

        this._search();

        // mount days array
        this.arrDays = Array.apply(null, {length: 31}).map(function (value, index) {
            return index + 1;
        });

        const monthArray = new Array();
        monthArray[0] = 'USERS.JAN';
        monthArray[1] = 'USERS.FEB';
        monthArray[2] = 'USERS.MAR';
        monthArray[3] = 'USERS.APR';
        monthArray[4] = 'USERS.MAY';
        monthArray[5] = 'USERS.JUN';
        monthArray[6] = 'USERS.JUL';
        monthArray[7] = 'USERS.AUG';
        monthArray[8] = 'USERS.SEP';
        monthArray[9] = 'USERS.OCT';
        monthArray[10] = 'USERS.NOV';
        monthArray[11] = 'USERS.DEC';

        this.arrMonths = monthArray;

        // mount years array
        const d = new Date();
        const n = d.getFullYear();
        const length = n - 1930;
        this.arrYears = Array.apply(null, {length: length}).map(function (value, index) {
            return index + 1923;
        });
    }

    private _updateDownlodParams() {

        let str_type = 'ALL';

        if (this.bol_check_abi && this.bol_check_thrd) {
            str_type = 'ALL';
        } else {
          if (this.bol_check_abi) {
            str_type = 'ABI';
          }
          if (this.bol_check_thrd) {
            str_type = 'THRD';
          }
        }

        const observables = [
            'USERS.NAME',
            'USERS.LEGAL_NAME',
            'USERS.PREFERRED_NAME',
            'USERS.USERNAME',
            'USERS.LOCATION',
            'USERS.PARTNER',
            'USERS.EMAIL',
            'USERS.JOB',
            'USERS.WORLD',
            'USERS.REGIONAL',
            'USERS.LOGBOOK',
            'USERS.AREA',
            'USERS.PROFILE',
            'USERS.TYPE',
            'USERS.CREATION_USER',
            'USERS.CREATION_DATE'
        ];

        this._translateService.get(observables).subscribe(translations => {

            let arrHeaders = ['ID']
            for(const key of observables){
                arrHeaders.push(translations[key])
            }
            
            this.downloadCsvSetup = {
                cod_user: this.cod_user,
                api_url: this.reportsApiUrl,
                str_proc_exec: 'app_sp_se_find_users_rel',
                arr_proc_params_search: [
                    this._authService.getAuthenticatedUser().id,
                    this.searchTerm,
                    'USERS_SCREEN_LISTING',
                    str_type
                ],
                arr_headers: arrHeaders,
                name_of_file_to_download: 'users',
                str_column_dynamic: ''
            };

        });
    }

    monthDays(month, year) {
        const monthP = parseInt(month) + 1;
        return new Date(year, monthP, 0, 0, 0, 0, 0).getDate();
    }

    async submit() {
        this.bol_error_submit = false;

        this.model.cod_user = this._authService.getAuthenticatedUser().id;
        this.model.str_type = this.strUserType;
        this.model.str_name = this.model.str_name || this.model.str_legal_name;

        if (!this.validateUser()) {
            return;
        }
        let requestMessageToShow = null
        if (this.model.cod_current_profiles != this.model.cod_profiles 
            && this.profiles.find(x => x.cod_profiles == this.model.cod_profiles) 
            && this.profiles.find(x => x.cod_profiles == this.model.cod_profiles).bol_need_approval) {
                if(this.model.cod_id == 0){
                    requestMessageToShow = await this._translateService.get('USERS.CREATED_REQUEST').toPromise()
                }else{
                    const dataReturned = await this._usersProfilesService.verifyExistentRequest(this._authService.getAuthenticatedUser().id, this.model.cod_id).toPromise()
                    if(dataReturned && dataReturned.content.results && dataReturned.content.results.app_sp_se_profiles_existent_request_approval==true){
                        requestMessageToShow = await this._translateService.get('USERS.ALREADY_HAVE_CREATED_REQUEST').toPromise()
                    }else{
                        requestMessageToShow = await this._translateService.get('USERS.CREATED_REQUEST').toPromise()
                    }
                }
        }
        if (this.model.cod_id == 0) {

            this._usersService.postNewUser(this.model).subscribe({
                next: res => {
                    if (res.type != 'success' || res.content.type != 'success') {

                        if (res.content.message == 'str_privilege') {
                            this._translateService.get('USERS.PRIVILEGE_LEVEL').subscribe(translate => {
                                this._notify.error(translate);
                                this.bol_error_submit = true;
                                this.error_message_submit = translate;
                            });
                            return;
                        }

                        if (res.content.message == 'str_level_validate') {
                            this._translateService.get('USERS.PROFILE_LEVEL').subscribe(translate => {
                                this._notify.error(translate);
                                this.bol_error_submit = true;
                                this.error_message_submit = translate;
                            });
                            return;
                        }

                        this.error_message_submit = '';
                        this._translateService.get('USERS.MSG_VALIDATION_GENERIC').subscribe(translate => {
                            this._notify.error(translate);
                            this.bol_error_submit = true;
                            this.error_message_submit = translate;
                        });

                        this._translateService.get('USERS.'+res.content[0].path[0]).subscribe(translate => {
                            this.bol_error_submit = true;
                            this.error_message_submit += translate;
                        });

                        this._translateService.get('USERS.MSG_VALIDATION_GENERIC2').subscribe(translate => {
                            this.bol_error_submit = true;
                            this.error_message_submit += translate;
                        });
                        return;
                    } else {
                        if(this.model.cod_areas == undefined || this.model.cod_areas == null){
                            this.model.cod_areas = [];
                        }
                        const areaParams = {
                            arr_cod_areas: this.model.cod_areas,
                            cod_user: res.content.results.app_sp_in_users,
                            cod_usu_ins: this._authService.getAuthenticatedUser().id
                        }
                        this._usersService.setAresByUser(areaParams).subscribe(data => {
                            if (data.type == 'success' && data.content.type == 'success') {
                                if(requestMessageToShow) this._notify.warning(requestMessageToShow);
                                this._notify.success({text: 'USERS.INSERTED', translate: true});
                                this.timeToHideMessage = this.timeToHideMessageConstant;
                                this.editMode = false;
                                this.cancel();
                                this._search();
                            }
                        });
                    }
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
            
        } else {

            this._usersService.updateUser(this.model).subscribe({
                next: res => {

                    if (res.type !== 'success') {

                        if (res.content.message == 'str_privilege') {
                            this._translateService.get('USERS.PRIVILEGE_LEVEL').subscribe(translate => {
                                this._notify.error(translate);
                                this.bol_error_submit = true;
                                this.error_message_submit = translate;
                            });
                            return;
                        }

                        if (res.content.message == 'str_level_validate') {
                            this._translateService.get('USERS.PROFILE_LEVEL').subscribe(translate => {
                                this._notify.error(translate);
                                this.bol_error_submit = true;
                                this.error_message_submit = translate;
                            });
                            return;
                        }

                        this._translateService.get('USERS.MSG_VALIDATION_GENERIC').subscribe(translate => {
                            this._notify.error(translate);
                            this.bol_error_submit = true;
                            this.error_message_submit = translate;
                        });
                        this._translateService.get('USERS.'+res.content[0].path[0]).subscribe(translate => {
                            this.bol_error_submit = true;
                            this.error_message_submit += translate;
                        });
                        this._translateService.get('USERS.MSG_VALIDATION_GENERIC2').subscribe(translate => {
                            this.bol_error_submit = true;
                            this.error_message_submit += translate;
                        });
                        return;
                    }

                    if (this.tmp_user_location != this.model.cod_locations
                        && (this._authService.getUserPrivilege('USERS_SELECT_LOCATIONS') || this._authService.getUserPrivilege('USERS_SELECT_WORLDS')) && this.strUserType == 'ABI') {
                        
                        const setLogParam = {
                            'cod_user': this.model.cod_id,
                            'str_ambev_id': this.model.str_username,
                            'cod_usu_ins': this._authService.getAuthenticatedUser().id,
                            'cod_location': this.model.cod_locations
                        };

                        this._usersService.setLogLocationChange(setLogParam).subscribe({
                            next: result => {
                                if (result.type !== 'success') {
                                    this._translateService.get(result.content.message).subscribe(translate => {
                                        this.bol_error_submit = true;
                                        this.error_message_submit += translate;
                                    });
                                    return;
                                }
                            }, 
                            error: err => {
                                this._authService.errCheck(err);
                                console.log(err);
                            }
                        });

                    }

                    const delAreaParam = {
                        arr_cod_areas: [],
                        cod_user: this.model.cod_id,
                        cod_usu_ins: this._authService.getAuthenticatedUser().id
                    };

                    if (this.arr_tmp_areas) {
                        this.arr_tmp_areas.filter(area => {
                            if (this.model.cod_areas.indexOf(area) == -1) {
                                delAreaParam.arr_cod_areas.push(area);
                            }
                        });
                    }

                    if(this.model.cod_areas == undefined || this.model.cod_areas == null){
                        this.model.cod_areas = [];
                    }              
                    const areaParams = {
                        arr_cod_areas: this.model.cod_areas,
                        cod_user: this.model.cod_id,
                        cod_usu_ins: this._authService.getAuthenticatedUser().id
                    };

                    this._usersService.setAresByUser(areaParams).subscribe({
                        next: data => {
                            if (data.type !== 'success' || data.content.types) {
                                this._translateService.get(data.content.message).subscribe(translate => {
                                    this.bol_error_submit = true;
                                    this.error_message_submit += translate;
                                });
                                return;
                            } else {
                                this._usersService.delAresByUser(delAreaParam).subscribe({
                                    next: data => {
                                        if (data.type !== 'success' || data.content.types) {
                                            this._translateService.get(data.content.message).subscribe(translate => {
                                                this.bol_error_submit = true;
                                                this.error_message_submit += translate;
                                            });
                                            return;
                                        } else {
                                            if(requestMessageToShow) this._notify.warning(requestMessageToShow);
                                            this._notify.success({text: 'USERS.EDITED', translate: true});
                                            this.timeToHideMessage = this.timeToHideMessageConstant;
                                            this.editMode = false;
                                            this.cancel();
                                            this._search();
                                            this._socketService.joinUserPrivileges(areaParams.cod_user);
                                            this._socketService.refreshUserPrivileges(areaParams.cod_user);
                                        }
                                    }, 
                                    error: err => {
                                        this._authService.errCheck(err);
                                        console.log(err);
                                    }
                                });
                            }
                        }, 
                        error: err => {
                            this._authService.errCheck(err);
                            console.log(err);
                        }
                    });

                }, 
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });

        }
    }

    deleteUser(user) {
        this.editMode = false;
        this.model = new User();

        this.selectedTypes = [];
        this.selectedWorlds = [];
        // this.selectedJobs = [];
        this.selectedProfiles = [];
        this.selectedRegionals = [];
        this.selectedLocations = [];
        this.selectedLogbooks = [];
        this.selectedAreas = [];
        this.selectedFunctWorlds = [];
        this.selectedManagers = [];
        this.daySelected = 0;
        this.monthSelected = -1;
        this.yearSelected = 0;

        this.getUserType('THRD');
    }

    public toggleDeleteUser(id = 0) {
        this.awaitConfirmDelete = !this.awaitConfirmDelete;
        this.deleteUserId = id;
    }

    public confirmDeleteUser() {
        const objParam = {
            'cod_user': this.deleteUserId,
            'cod_user_del': this._authService.getAuthenticatedUser().id
        };
        this._usersService.deleteUser(objParam).subscribe({
            next: data => {
                this._notify.success({text: 'USERS.DELETED_SUCCESSFULLY', translate: true});
                this._search();
            }, 
            error: err => {
                // console.log(err);
                let e = JSON.parse(err._body);
                this._authService.errCheck(err);
            }
        });
        this.awaitConfirmDelete = false;
        this.deleteUserId = 0;
    }

    private showValidationMessage(message) {
        this._notify.warning({text: message, translate: true});
    }

    private validateUser() {
        // Validate if username is filled
        if (!this.model.str_username || this.model.str_username.toString() == '') {
            this.showValidationMessage('USERS.MSG_VALIDATION_EMPTY_USERNAME');
            this._translateService.get('USERS.MSG_VALIDATION_EMPTY_USERNAME').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        if (this.model.str_username.indexOf('\\') > -1) {
            this.showValidationMessage('USERS.MSG_VALIDATION_USERNAME_SLASH');
            this._translateService.get('USERS.MSG_VALIDATION_USERNAME_SLASH').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        if (this.model.str_legal_name.length < 4 && this.strUserType != 'ABI') {
            this.showValidationMessage('USERS.MSG_VALIDATION_LENGTH_NAME');
            this._translateService.get('USERS.MSG_VALIDATION_LENGTH_NAME').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        // Validate if manager is filled, there are users without manager
        /*if (!this.model.str_ambev_id_manager || this.model.str_ambev_id_manager.toString() == '') {
            this._translateService.get('USERS.MSG_VALIDATION_EMPTY_MANAGER').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }*/

        // validate world
        if (this.model.cod_worlds == 0 || !this.model.cod_worlds) {
            this.showValidationMessage('USERS.MSG_VALIDATION_WORLD');
            this._translateService.get('USERS.MSG_VALIDATION_WORLD').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        // validate regional
        if (this.model.cod_regionals == 0 || !this.model.cod_regionals) {
            this.showValidationMessage('USERS.MSG_VALIDATION_REGIONAL');
            this._translateService.get('USERS.MSG_VALIDATION_REGIONAL').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        // validate unity
        if (this.model.cod_locations == 0 || !this.model.cod_locations) {
            this.showValidationMessage('USERS.MSG_VALIDATION_LOCATION');
            this._translateService.get('USERS.MSG_VALIDATION_LOCATION').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        //code user length must be < 16 chars
        if (this.model.str_username.length > 16) {
            this.showValidationMessage('USERS.MSG_VALIDATION_LENGTH_USERNAME');
            this._translateService.get('USERS.MSG_VALIDATION_LENGTH_USERNAME').subscribe(translate => {
                this.bol_error_submit = true;
                this.error_message_submit = translate;
            });
            return false;
        }

        // //User name must only have letters
        // let regexUserName = /^(?=.*\d).+$/igm;
        // let regExUserName = new RegExp(regexUserName);
        // if (regExUserName.test(this.model.str_name.toString())) {
        //     //if entered here is because the entered string contains only numbers
        //     this._translateService.get('USERS.MSG_VALIDATION_USER_NAME').subscribe(translate => {
        //         this.bol_error_submit = true;
        //         this.error_message_submit = translate;
        //     });
        //     return false;
        // }
        return true;
    }

    public search() {
        if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
            return;
        }
        this.pageIndex = 1;
        clearTimeout(this.searchTimer);
        this.isLoading = true;
        let $this = this;
        this.searchTimer = setTimeout(function () {
            $this._search();
        }, environment().debounceTime);
    }

    private _search() {
        this.isLoading = true;
        this._updateDownlodParams();

        let str_type = 'ALL';
        if (this.bol_check_abi && this.bol_check_thrd) {
            str_type = 'ALL';
        } else {
          if (this.bol_check_abi) {
            str_type = 'ABI';
          }
          if (this.bol_check_thrd) {
            str_type = 'THRD';
          }
        }

        let tmpPage = this.pageIndex;
        if (tmpPage > 0) {
            tmpPage = tmpPage - 1;
        }
        const objParam = {
            'limit': this.resultsPerPageSelected,
            'str_filter': ((this.searchTerm == '' || this.searchTerm == undefined || this.searchTerm == null) ? '--' : this.searchTerm),
            'offset': (tmpPage * this.resultsPerPageSelected),
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dsc_page_privilege': 'USERS_SCREEN_LISTING',
            'str_type': str_type
        };

        this._getUsersFilterArr(objParam, (data) => {

            this.list = {
                users: data.results
            };
            this.isLoading = false;

            this.maxPage = Math.ceil(data.count / this.resultsPerPageSelected);
            this.totalPages = this._pagination(this.pageIndex, this.maxPage);
        });
    }

    private _getUsersFilterArr(objParam,cb){
        this._usersService.getUsersByFilter(objParam).subscribe({
            next: data => {
                let new_data = data.content;
                if (data.content.count == 1) {
                    data.content.results = [data.content.results];
                    new_data = data.content;
                }
                cb(new_data);
            }, 
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
                this.isLoading = false;
            }
        });
    }

    private _getUsersFilter(objParam,cb) {
        this._usersService.getUsersByFilter(objParam).subscribe({
            next: data => {
                let new_data = data.content.results;
                if (data.content.count == 1) {
                    new_data = [data.content.results];
                }
                cb(new_data);
            }, 
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
                this.isLoading = false;
            }
        });
    }

    changePage(page) {
        this.pageIndex = page;
        this._search();
    }

    loadUser(user: User) {
        this.profiles = this.profiles_bkp;
        // get single profile
        this._usersService.getSingleUser(this._authService.getAuthenticatedUser().id, user.cod_id).subscribe({
            next: data => {
                user = data.content.results;

                this.bol_error_submit = false;
                this.error_message_submit = '';
                this.timeToHideMessage = 0;
                this.arr_tmp_areas = [];
                this.editMode = true;
                this.model.cod_id = user.cod_id;
                // this.model.cod_jobs = user.cod_jobs;
                this.model.cod_locations = user.cod_locations;
                this.model.cod_user = user.cod_user;
                this.model.str_legal_name = user.str_legal_name;
                this.model.str_preferred_name = user.str_preferred_name;
                this.model.cod_logbooks = user.cod_logbooks;
                this.model.str_email = user.str_email;
                this.model.str_name = user.str_name;
                this.model.str_partner = user.str_partner;
                this.model.str_username = user.str_username;
                this.model.cod_functional_world = user.cod_worlds;
                this.model.cod_regionals = user.cod_regionals;
                this.model.cod_worlds = user.cod_worlds;
                this.model.str_type = user.str_type;
                this.model.cod_current_profiles = user.cod_profiles;
                this.model.cod_profiles = user.cod_profiles;
                this.model.str_current_profile = user.str_current_profile;
                this.model.str_ambev_id_manager = user.str_ambev_id_manager;
                this.model.str_job_name = user.str_job_name;
                this.profiles.map(profile => {
                    if (profile.cod_profiles == this.model.cod_profiles) {
                        this.model.bool_profile = profile.bool_profile;
                        this.model.bool_privilege = profile.bool_privilege;
                    }
                });

                if (this.model.bool_profile && this.model.bool_privilege) {
                    this.profiles = this.profiles_bkp.filter(profile => 
                        profile.bool_profile && profile.bool_privilege
                    );
                } 

                const translationKey = this.model.bool_profile ? 'USERS.SAVE' : 'USERS.PROFILE_LEVEL';
                this._translateService.get(translationKey).subscribe(translate => {
                    this.title_profile_privilege = translate;
                });

                this._usersService.getAresByUser({
                    cod_user: this.model.cod_id,
                    cod_usu_ins: this._authService.getAuthenticatedUser().id
                }).subscribe({
                    next: data => {
                        this.model.cod_areas = data.content.results.cod_area;
                        // if (this.model.str_type != 'ABI') {
                        //     if (user.str_password) {
                        //         const birthdate = user.str_password;
                        //         var matches = birthdate.match(/(.{2})?(.{2})?(.+?)?$/);
                        //         this.daySelected = Number(matches[1]);
                        //         this.monthSelected = Number(matches[2]) - 1;
                        //         this.yearSelected = Number(matches[3]);
                        //     } else {
                        //         this.daySelected = null;
                        //         this.monthSelected = null;
                        //         this.yearSelected = null;
                        //     }
                        // }
                        this._usersService.getTypes(this._authService.getAuthenticatedUser().id).subscribe({
                            next: data => {
                                const types = this._usersService.getArrayContent(data).filter(value => {
                                    return value.str_column_value == this.model.str_type;
                                });
                                this.types = types[0];
                                this.strUserType = types[0].str_column_value;
                                this.strTypeDescription = types[0].str_value_description;
                            },
                            error: err => {
                                this._authService.errCheck(err);
                                console.log(err);
                            }
                        });
                        this.getUserType(this.model.str_type);
                        this.selectedAreas = [];
                        if (this.model.cod_areas) {
                            this.model.cod_areas.map(area =>
                                this.selectedAreas.push({'cod_areas': area})
                            );
                        }
                        this.arr_tmp_areas = this.model.cod_areas;

                        this.loadLogbooks(user.cod_locations);

                        this.selectedTypes = [{'str_column_value': user.str_type}];
                        this.selectedWorlds = [{'cod_locations_world': user.cod_worlds}];
                        // this.selectedJobs = [{'cod_jobs': user.cod_jobs}];
                        this.selectedProfiles = [{'cod_profiles': user.cod_profiles}];
                        this.selectedRegionals = [{'cod_locations_regional': user.cod_regionals}];
                        this.selectedLocations = [{'cod_location': user.cod_locations}];
                        this.selectedLogbooks = [{'cod_logbooks': user.cod_logbooks}];
                        this.tmp_user_location = user.cod_locations;

                        // GET THE MANAGER
                        this.selectedManagers = [];
                        if (user.str_ambev_id_manager != '') {
                            const objParam = {
                                'limit': -1,
                                'str_filter': user.str_ambev_id_manager,
                                'offset': 0,
                                'cod_user': this._authService.getAuthenticatedUser().id,
                                'dsc_page_privilege': 'NOT_PRIVILEGE',
                                'str_type': 'ALL'
                            };
                            this._getUsersFilter(objParam, (data) => {
                                const resultsFiltered = data.filter((manager) => {
                                        return manager.str_username == user.str_ambev_id_manager;
                                });

                                this.selectedManagers = resultsFiltered;
                            });
                        }

                        this.loadRegionals(this.model.cod_worlds);
                        this.loadLocations(this.model.cod_regionals);
                    },
                    error: err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    }
                });
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    private getUserType(type) {
        const types = this.allUserTypes.filter(value => {
            return value.str_column_value == type;
        });

        if (types.length > 0) {
            this.types = types[0];
            this.strUserType = types[0].str_column_value;
            this.strTypeDescription = types[0].str_value_description;
        } else {
            this.types = [];
            let types = this.allUserTypes.filter(value => {
                return value.str_column_value == 'THRD';
            });
            if (types.length > 0) {
                this.strUserType = types[0].str_column_value;
                this.strTypeDescription = types[0].str_value_description;
            }
        }
    }

    // selectType($event) {
    //     this.selectedTypes = $event.selectedOptions;
    //     this.model.str_type = this.selectedTypes[0].str_column_value;
    // }

    selectWorld($event) {
        this.selectedWorlds = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.loadRegionals(this.selectedWorlds[0].cod_locations_world);
        this.model.cod_worlds = this.selectedWorlds[0].cod_locations_world;
        this.model.cod_functional_world = this.selectedWorlds[0].cod_locations_world;
    }

    selectArea($event) {
        this.model.cod_areas = [];
        this.selectedAreas = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.selectedAreas.map(areas => this.model.cod_areas.push(areas.cod_areas));
    }

    selectLogbook($event) {
        this.selectedLogbooks = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        if (this.selectedLogbooks[0].cod_logbooks == 'null') {
            this.model.cod_logbooks = null;
        } else {
            this.model.cod_logbooks = this.selectedLogbooks[0].cod_logbooks;
        }
    }

    selectRegional($event) {
        this.selectedRegionals = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.model.cod_regionals = this.selectedRegionals[0].cod_locations_regional;
        this.loadLocations(this.selectedRegionals[0].cod_locations_regional);
    }

    selectLocation($event) {
        this.selectedLocations = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        this.model.cod_locations = this.selectedLocations[0].cod_location;
        this.loadLogbooks(this.selectedLocations[0].cod_location);
    }

    // selectJob($event) {
    //     this.selectedJobs = $event.selectedOptions;
    //     this.model.cod_jobs = this.selectedJobs[0].cod_jobs;
    // }

    selectProfile($event) {
        this.selectedProfiles = (this.sanitizer.bypassSecurityTrustResourceUrl($event.selectedOptions) as any).changingThisBreaksApplicationSecurity;
        if (this.selectedProfiles.length > 0) {
            this.model.cod_profiles = this.selectedProfiles[0].cod_profiles;
        }
        else {
            this.model.cod_profiles = null;
        }
    }

    cancel() {
        this.editMode = false;
        this.model = new User();

        this.selectedTypes = [];
        this.selectedWorlds = [];
        // this.selectedJobs = [];
        this.selectedProfiles = [];
        this.selectedRegionals = [];
        this.selectedLocations = [];
        this.selectedLogbooks = [];
        this.selectedAreas = [];
        this.selectedFunctWorlds = [];
        this.selectedManagers = [];
        this.daySelected = 0;
        this.monthSelected = -1;
        this.yearSelected = 0;
        this.tmp_user_location = null;
        this.getUserType('THRD');
        this.profiles = this.profiles_bkp.filter(profile => 
            profile.bool_profile && profile.bool_privilege
        );
    }

    loadRegionals(world: any) {
        this.isLoadingRegional = true;
        const params = [world];
        let priv;
        if(this.model.cod_id == 0){
            priv = 'ADMIN_NEW_USER';
        }else{
            priv = this.listPrivilege('USERS_SELECT_WORLDS');
        }
        this._usersService.getRegionals(params, this.cod_user, priv).subscribe({
            next: data => {
                this.isLoadingRegional = false;
                this.regionals = this._usersService.getArrayContent(data);
            },
            error: err => {
                this.isLoadingRegional = false;
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    loadLocations(regional: any) {
        this.isLoadingLocation = true;
        const params = [regional];
        let priv = '';
        if(this.model.cod_id == 0){
            priv = 'ADMIN_NEW_USER';
        }else{
            if(this._authService.getUserPrivilege('USERS_SELECT_LOCATIONS')){
                priv = this.listPrivilege('USERS_SELECT_LOCATIONS');
            }
            else{
                priv = this.listPrivilege('USERS_SELECT_WORLDS');
            }
        }
        this._usersService.getLocations(params, this.cod_user, priv).subscribe({
            next: data => {
                this.isLoadingLocation = false;
                this.locations = this._usersService.getArrayContent(data);
            },
            error: err => {
                this.isLoadingLocation = false;
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    loadLogbooks(cod_locations: any){
        let privChecklist = '';
        if(this.model.cod_id == 0){
            privChecklist = 'ADMIN_NEW_USER';
        }else{
            privChecklist = this.listPrivilege('USERS_SELECT_LOGBOOKS');
        }

        this.logbooks = [];
        this._usersService.getLogbooksyLocation({'cod_user': this.cod_user, 'cod_location': cod_locations, 'dsc_page_privilege': privChecklist}).subscribe({
            next: data => {
                // PUT THE DEFAULT NO LOGBOOK OPTION
                this._translateService.get('GENERAL.WITHOUT_LOGBOOK').subscribe(translate => {
                    this.logbooks = [];
                    this.logbooks = this._usersService.getArrayContent(data);
                    this.logbooks.unshift({'cod_logbooks': 'null', 'str_name': translate});
                });
            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });          
    }

    togleNewUser(state) {
        this.daySelected = 0;
        this.monthSelected = -1;
        this.yearSelected = 0;
        this.isCreateVisible = state;
    }

    delManager() {
        this.selectedManagers = [];
        this.model.str_ambev_id_manager = '';
    }

    private _pagination(currentPage, nrOfPages) {
        let delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        range.push(1);

        if (nrOfPages <= 1) {
            return range;
        }

        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i < nrOfPages && i > 1) {
                range.push(i);
            }
        }
        range.push(nrOfPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }
    
    selectStatusABI(event) {
        if (event == 'selected') {
          this.bol_check_abi = true;
        } else {
          this.bol_check_abi = false;
          this.bol_check_thrd = true;
        }
        this.search();
    }

    selectStatusTHRD(event) {
        if (event == 'selected') {
          this.bol_check_thrd = true;
        } else {
          this.bol_check_thrd = false;
          this.bol_check_abi = true;
        }
        this.search();
    }

    hasPrivilege(strUserType, dsc_privilege){//Return true if not allowed
        //if (strUserType == 'ALLOW' || strUserType != 'ABI' ){ //allow to change if selected user is ABI Employee or is allowed
            if (this._authService.getUserPrivilege(dsc_privilege) ) { 
                return false;
            } else {
                return true;
            }
        //} 
        //return true;
    }

    listPrivilege(dsc_privilege){
        if(this._authService.getUserPrivilege(dsc_privilege)){
            return dsc_privilege;
        }
        else{
            return 'USERS_SCREEN_LISTING'
        }

    }
}
