import {Injectable} from '@angular/core';
import {ListIndicatorsMainService} from './list-indicators-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListIndicatorsService {
  
  constructor(private _mainService: ListIndicatorsMainService) {
  }
  
  getIndicatorsList(param): Observable<any> {
    let path = '/forms/indicators/'+ param.cod_user +'/'+ param.dsc_privilege +'/?term=' + param.term;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
