// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 10px;
}

.FormsDynamicAdmin__Container {
  padding: 20px;
}

.alert-container {
  margin: 80px 0;
  text-align: center;
  font-size: 1.2em;
  color: #777777;
}

.alert-container > .glyphicon {
  margin: 20px;
  font-size: 2.5em;
}

.alert-container.--error > .glyphicon {
  color: #9a4020;
}

.alert-container.--empty > .glyphicon {
  color: #20409a;
}

.list {
  margin: 10px 0;
}

.list__item {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #efefef;
}

.list__item:hover {
  background-color: #efefef;
  cursor: pointer;
}

.buttons {
  display: block;
  margin-top: 50px;
  text-align: right;
}

.isDisable {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.modal-button {
  position: relative;
  padding-right: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/forms/forms-dynamic/forms-dynamic-admin/forms-dynamic-admin.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  padding: 10px;\n}\n\n.FormsDynamicAdmin__Container {\n  padding: 20px;\n}\n\n.alert-container {\n  margin: 80px 0;\n  text-align: center;\n  font-size: 1.2em;\n  color: #777777;\n}\n\n.alert-container > .glyphicon {\n  margin: 20px;\n  font-size: 2.5em;\n}\n\n.alert-container.--error > .glyphicon {\n  color: #9a4020;\n}\n\n.alert-container.--empty > .glyphicon {\n  color: #20409a;\n}\n\n.list {\n  margin: 10px 0;\n}\n\n.list__item {\n  display: block;\n  padding: 10px;\n  border-bottom: 1px solid #efefef;\n}\n\n.list__item:hover {\n  background-color: #efefef;\n  cursor: pointer;\n}\n\n.buttons {\n  display: block;\n  margin-top: 50px;\n  text-align: right;\n}\n\n.isDisable {\n  pointer-events: none;\n  cursor: default;\n  opacity: 0.6;\n}\n\n.modal-button {\n  position: relative;\n  padding-right: 15px;\n}\n\n.mb-10 {\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
