// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner .75s linear infinite;
    opacity: 0.5;
}

.text-break-line {
  white-space: pre-line;
  font-size: 20px;
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/app/components/pdf-maker/pdf-maker.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,gCAAgC;IAChC,+BAA+B;IAC/B,kBAAkB;IAElB,uCAAuC;IACvC,YAAY;AAChB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;AAYA;IACI;MAEE,uBAAuB;IACzB;IACA;MAEE,yBAAyB;IAC3B;AACJ","sourcesContent":[".spinner {\n    display: inline-block;\n    width: 2rem;\n    height: 2rem;\n    vertical-align: text-bottom;\n    border: .25em solid currentColor;\n    border-right-color: transparent;\n    border-radius: 50%;\n    -webkit-animation: spinner .75s linear infinite;\n    animation: spinner .75s linear infinite;\n    opacity: 0.5;\n}\n\n.text-break-line {\n  white-space: pre-line;\n  font-size: 20px;\n}\n\n@-webkit-keyframes spinner {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n}\n@keyframes spinner {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
