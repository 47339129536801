import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LogbookRoutineService } from '../services/logbook-routine.service';
import { AuthService } from '../../main/access/services/auth.service';
import { RoutinesService } from './../services/routines.service';
import { NotificationService } from 'app/main/services/notification.service';
import { TranslateService } from "@ngx-translate/core";

import { Routine, RoutineType } from '../models/routine.model';
import { RoutineStatus } from '../models/routine_status.model';
import { RoutineDataService } from '../services/routine-data.service';
import { RoutinesServiceStore } from '../../stores/routines-forms.store.service';

const date = new Date();
const today = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' +  ('0' + (date.getDate())).slice(-2);

@Component({
    selector: 'routine-show',
    templateUrl: './routine-show.component.html',
    styleUrls: ['./routine-show.component.css']
})

export class RoutineShowComponent implements OnInit, OnChanges {

    @ViewChild('acadiaComponent') acadiaComponent;

    @Output() onClose = new EventEmitter<void>();
    @Output() onChangeStatus: EventEmitter<any> = new EventEmitter();
    @Output() changedRoutine = new EventEmitter<Routine>();

    @Input() cod_shifts: any = null;
    @Input() routine: Routine = null;
    @Input() routinesStatusList: Array<RoutineStatus>;
    @Input() dateSelected: BehaviorSubject<string>;

    isCollab : boolean = false;
    isDaily: boolean = false;
    isAsNeeded: boolean = false;
    isAcadia: boolean = false;
    isForm: boolean = false;
    isLoading: boolean = false;
    showHistoryMenu: boolean = false;
    isRefreshingAcadiaTaskList: boolean = false;
    routineType: RoutineType = null;
    isRoutinePastDue: boolean = false;
    isPastDue: boolean = false;
    isFutureDue: boolean = false;
    canChangeForm: boolean = true;
    showHistoryAnswers: boolean = false;
    formSelected: number = null;
    formSelectedObj: Array<any> = [];
    statusTranslations = {
        not_done: '',
        done: '',
        pending: '',
        available: '',
        not_available: '',
    };
    routineObj: Routine;

    constructor(
        private _logbookRoutineService: LogbookRoutineService,
        private _routineService: RoutinesService,
        private _authService: AuthService,
        private _notify: NotificationService,
        private _dataService: RoutineDataService,
        private _translateService: TranslateService,
        private _routineStore: RoutinesServiceStore
    ) {
        this.showHistoryMenu = false;
        this.loadDataFromService();
        this.getStatusTranslations();
    }

    ngOnInit() : void {
        if (!this.routine.bol_type_collab && !this.routine.str_user_name) {
            this.routine.str_user_name = this._authService.getAuthenticatedUserNameNoCaps();
        }
        this._dataService.changeRoutine(this.routine);
        this.refresh();
    }

    ngOnChanges(changes: SimpleChanges) : void {
        if (changes.routine) {
            this._dataService.changeRoutine(this.routine);
            if (!changes.routinesStatusList) {
                this.refresh();
            }
        }        
    }

    private changeServiceData() : void {
        this._dataService.changeRoutineType(this.getRoutineType());
        this._dataService.changeIsDaily(this._isDaily());
        this._dataService.changeIsAsNeeded(this._isAsNeeded());
        this._dataService.changeIsCollab(this.routineObj.bol_type_collab);
        this.validateDate();
        this.formSelectedObj = [];
    }

    private validateDate() : void {
       
        this._dataService.changeIsPastDue((!this.routineObj.date_valid && Date.parse(this.myTaskMainDate) < Date.parse(today)));
        this._dataService.changeIsFutureDue(!this.routineObj.date_valid && (Date.parse(this.myTaskMainDate) > Date.parse(today)));
    }

    private refresh() : void {
        if (this.routineObj == null) return;

        this.isLoading = true;
        this.showHistoryMenu = false;
        this.showHistoryAnswers = false;

        this.isRoutinePastDue = this.routinePastDue();

        if (this.isForm) {
          this.showHistoryMenu = this.hasFormSelected();
        };

        if(this.isAsNeededDone()) {
          this.resetRoutine();
        };

        this.isLoading = false;
    }

    public onUndoInitializationForm(): void{
        this.formSelectedObj = []
        this._dataService.changeFormSelected(null);
        this.resetRoutine();
        this.changeStatusEmitter(this.routineObj.cod_routine_status);
    }
    private loadDataFromService(): void {
        this._dataService.routine.subscribe(x => { 
            if (this.routineObj?.cod_routines != x.cod_routines
                || this.routineObj?.cod_periodicity != x.cod_periodicity
                || this.routineObj?.cod_logbook_routines_periodicity_users != x.cod_logbook_routines_periodicity_users
                || this.routineObj?.cod_logbook_routines_periodicity_users_collaborative_done != x.cod_logbook_routines_periodicity_users_collaborative_done
                || this.routineObj?.bol_type_collab != x.bol_type_collab
                || this.routineObj?.date_limit != x.date_limit
                || this.routineObj?.date_valid != x.date_valid
            ) {
                this.routineObj = x; 
                this.changeServiceData(); 
            } else {
                this.routineObj = x; 
            }
        });
        
        this._dataService.formSelected.subscribe(x => {
            this.formSelected = x;
            if (this.formSelectedObj.length == 0) {
                this.formSelectedObj = this.routineObj.form_questions?.filter(f => f.cod_form == x);
            }
            if (this.formSelected != null) this.showHistoryMenu = true;
        });

        this._dataService.routineType.subscribe(x => {
            this.routineType = x;
            this._dataService.changeIsForm(this._isForm());
            this._dataService.changeIsAcadia(this._isAcadia());
        }); 
        this._dataService.isCollab.subscribe(x => this.isCollab = x);
        this._dataService.isForm.subscribe(x => this.isForm = x);
        this._dataService.isAcadia.subscribe(x => this.isAcadia = x);
        this._dataService.isDaily.subscribe(x => this.isDaily = x);
        this._dataService.isAsNeeded.subscribe(x => this.isAsNeeded = x);
        this._dataService.isPastDue.subscribe(x => this.isPastDue = x);
        this._dataService.isFutureDue.subscribe(x => this.isFutureDue = x);
    }

    private isAsNeededDone() : boolean {
        return (this.isAsNeeded &&
            this.routineObj.cod_routine_status == RoutinesService.STATUS_DONE /** With status done */
            && (
                this.routineObj.cod_logbook_routines_periodicity_users_done == this.routineObj.cod_logbook_routines_periodicity_users
                || this.routineObj.cod_logbook_routines_periodicity_users_collaborative_done == this.routineObj.cod_logbook_routines_periodicity_users
            ) /** Is the last routine done */
            && (!this.routineObj.str_acadia_document /** Is not acadia */
                || (this.routineObj.bol_acadia_task_list_created && this.routineObj.bol_acadia_task_list_finished) /** Is acadia with task list finished */
            )); 
    }

    private resetRoutine() : void {
        this._dataService.changeRoutine({
            cod_routine_status: RoutinesService.STATUS_AVAIABLE,
            cod_logbook_routines_periodicity_users: null,
            cod_form: null,
            cod_acadia: null,
            str_acadia_task_list_url: null,
            bol_acadia_task_list_created: false,
            int_acadia_tasks_count: null,
            int_acadia_completion_count: null,
            int_acadia_skipped_count: null,
            int_acadia_completion_percent: null,
            str_acadia_document_language: null,
            bol_acadia_document_language_equals_ial_language: false,
            bol_acadia_task_list_finished: false,
        });
    }

    private async getStatusTranslations() : Promise<void> {
        this.statusTranslations.not_done = await firstValueFrom(this._translateService.get('LOGBOOK.ROUTINE_NOT_DONE'));
        this.statusTranslations.done = await firstValueFrom(this._translateService.get('LOGBOOK.ROUTINE_DONE'));
        this.statusTranslations.pending = await firstValueFrom(this._translateService.get('LOGBOOK.ROUTINE_PENDING'));
        this.statusTranslations.available = await firstValueFrom(this._translateService.get('LOGBOOK.ROUTINE_AVAILABLE'));
        this.statusTranslations.not_available = await firstValueFrom(this._translateService.get('LOGBOOK.ROUTINE_NOT_AVAILABLE'));
    }

    public setShowAnswers($event) {
        this.showHistoryAnswers = $event;
    }

    public updateCanChangeForm($event) : void {
        this.canChangeForm = $event;
    }

    private getRoutineType() : RoutineType {
        if (this.routineObj.str_acadia_document != null) return RoutineType.Acadia;
        if (this.routineObj.form_questions.length > 0) return RoutineType.Form;
        return RoutineType.MarkDone;
    }

    private _isForm() : boolean {
        return this.routineType == RoutineType.Form;
    }

    private _isAcadia() : boolean {
        return this.routineType == RoutineType.Acadia;
    }

    private _isAsNeeded(): boolean {
        return this.routineObj.cod_periodicity == '13'; 
    }

    private _isDaily(): boolean {
        return this.routineObj.cod_periodicity == '1';
    }

    private routinePastDue() : boolean {
        const isPast = !this.hasFormSelected() ? false : !this.routineObj.date_valid;
        return this.isForm
            ? isPast
            : !this.routineObj.date_valid && this.routineObj.cod_routine_status == RoutinesService.STATUS_NOT_DONE;
    }

    private hasFormSelected() : boolean {
        return this.formSelected > 0;
    }

    public onChangeFormSelected($event: any) : void {
        this.formSelectedObj = $event.selectedOptions;
        this._dataService.changeFormSelected($event?.selectedOptions[0]?.cod_form || null);
    }

    public close(): void {
        if (this.showHistoryAnswers) {
            this.showHistoryAnswers = false;
        } else {
            this.onClose.emit();
        }
    }

    /************ LRPU ************/

    private getCreateLRPUPromise(form_selected: number) : Observable<any> {
        let params = {
            'cod_user': this._authService.getAuthenticatedUser().id,
            'cod_logbook_routines_periodicity': this.routineObj.cod_logbook_routines_periodicity,
            'cod_form': form_selected,
            'date': this.myTaskMainDate,
        };
        let createLrpuPromise: Observable<any> = null;

        if (this.isCollab) {
            params['cod_subarea'] = localStorage.getItem('subareaCollab');
            params['cod_shift'] = localStorage.getItem('shiftCollab');
            createLrpuPromise = this._routineService.getOrInsertRoutinePeriodicityCollab(params);
        } else {
            createLrpuPromise = this._logbookRoutineService.getRoutinePeriodicityUser(params);
        }

        return createLrpuPromise;
    }

    private createRoutinePeriodicityUsers($callback = null) : void {
        if (this.routineObj.cod_logbook_routines_periodicity_users > 0) {
            if ($callback != null) $callback(this.routineObj.cod_logbook_routines_periodicity_users);
            return;
        }

        const form_selected = this.isForm ? this.formSelected : null;

        this.getCreateLRPUPromise(form_selected).subscribe({
            next: data => {
                const cod_lrpu = this.isCollab 
                    ? data.content.results.cod_logbook_routines_periodicity_users_collaborative
                    : data.content.results.cod_logbook_routines_periodicity_users;

                this._dataService.changeRoutine({
                    cod_logbook_routines_periodicity_users: cod_lrpu,
                    cod_form: form_selected,
                });
                this.refresh();
                this.changeStatusEmitter(this.routineObj.cod_routine_status);

                if ($callback != null) $callback(cod_lrpu);

            }, 
            error: err => {
                console.log(err);
                const e = JSON.parse(err._body);
                this._notify.error(e.content);
                this._authService.errCheck(err);
            }
        });
    }

    /************ STATUS ************/

    public changeStatus($event) : void {
        this.isLoading = true;
        if (this.routineObj.cod_logbook_routines_periodicity_users > 0) {
            this._changeRoutineStatus($event);
        } else {
            this.createRoutinePeriodicityUsers(cod_lrpu => this._changeRoutineStatus($event, cod_lrpu));
        }
    }

    private getCompleteness(status: number): number {
        if (this.routineType == RoutineType.MarkDone) {
            return status == 2 ? 100 : 0;
        }
        return null;
    }

    private getChangeStatusPromise(status: number, cod_lrpu: number) : Observable<any> {
        let params = {
            'cod_routine_status': status,
            'cod_user': this._authService.getAuthenticatedUser().id,
            'dat_selected': this.myTaskMainDate,
            'completeness': this.getCompleteness(status),
        };

        let changeStatusPromise: Observable<any> = null;

        if (this.isCollab) {
            params['cod_logbook_routines_periodicity_users_collaborative'] = cod_lrpu;
            params['cod_shift'] = localStorage.getItem('shiftCollab');
            params['cod_logbooks'] = this.routine.cod_logbook;
            changeStatusPromise = this._routineService.updateRoutineStatusCollaborative(params);
        } else {
            params['cod_logbook_routines_periodicity_users'] = cod_lrpu;
            changeStatusPromise = this._logbookRoutineService.changeRoutineStatus(params);
        }

        return changeStatusPromise;
    }

    private _changeRoutineStatus(status: number, cod_lrpu: number = null) {
        if (status == RoutinesService.STATUS_DONE) {
            this.updateLrpuDone();
        }

        if (status != RoutinesService.STATUS_NOT_DONE && status != RoutinesService.STATUS_DONE && status != RoutinesService.STATUS_PENDING) {
            this.changeStatusEmitter(status);
            this.isLoading = false;
            return;
        };


        const statusForBack = status == RoutinesService.STATUS_PENDING ? RoutinesService.STATUS_NOT_DONE : status;
        this.getChangeStatusPromise(
            statusForBack,
            cod_lrpu || this.routineObj.cod_logbook_routines_periodicity_users
        ).subscribe({
            next: (data) => {
                this.changeStatusEmitter(status);
                this._dataService.changeRoutine({ cod_routine_status: status });
                this.refresh();
            },
            error: (err) => {
                console.log(err);
                const e = JSON.parse(err._body);
                this._notify.error(e.content);
                this._authService.errCheck(err);
            },
            complete: () => (this.isLoading = false),
        });
    }

    private updateLrpuDone() : void {
        const dat = new Date().toISOString(); // temporary done date for display, while it's not refreshed from the server
        // console.log(dat);
        const name = this.routineObj.str_user_name ?? this._authService.getAuthenticatedUserNameNoCaps();
        if (this.isCollab) {
            this._dataService.changeRoutine({
                cod_logbook_routines_periodicity_users_done: this.routineObj.cod_logbook_routines_periodicity_users,
                dat_finish: dat,
                str_user_name: name
            });
        } else {
            this._dataService.changeRoutine({
                cod_logbook_routines_periodicity_users_collaborative_done: this.routineObj.cod_logbook_routines_periodicity_users,
                dat_alt: dat,
                str_user_name: name
            });
        }
    }

    private changeStatusEmitter(cod_routine_status: number): void {   
        // Emmit event if routines is finished

        /** If the routine is as needed and was done today does not update the status */
        if (this.isAsNeeded && (
                this.routineObj.cod_logbook_routines_periodicity_users_done > 0
                || this.routineObj.cod_logbook_routines_periodicity_users_collaborative_done > 0
            )
        ) {

        //    cod_routine_status = RoutinesService.STATUS_DONE;

        } else {
            cod_routine_status = Number(cod_routine_status);
        }

        if(cod_routine_status == RoutinesService.STATUS_DONE){
            const dat = new Date().toISOString(); // temporary done date for display, while it's not refreshed from the server
            const name = this.routineObj.str_user_name ?? this._authService.getAuthenticatedUserNameNoCaps();
            if(this.routineObj.bol_type_collab){
                this.routineObj.str_user_name = name;
                this.routineObj.dat_finish = dat;
            } else{
                this.routineObj.str_user_name = name;
                this.routineObj.dat_alt = dat;
            }
        }

        const objReturn = {
            ...this.routineObj,
            'boll_executed': (cod_routine_status == RoutinesService.STATUS_DONE),
            'boll_executed_in_periodicity': false,
            'cod_routine_status': cod_routine_status,
            'dat_ins': this.myTaskMainDate,
            'str_status_description': this.getStatusDescription(cod_routine_status),
            'cod_shift': localStorage.getItem('shiftCollab'),
            'bol_type_collab': this.isCollab,
            'reload': false
        };
        this.onChangeStatus.emit(objReturn);
    }

    private getStatusDescription(cod_routine_status: number) : string {
        switch (cod_routine_status) {
            case RoutinesService.STATUS_NOT_DONE: return this.statusTranslations.not_done;
            case RoutinesService.STATUS_DONE: return this.statusTranslations.done;
            case RoutinesService.STATUS_PENDING: return this.statusTranslations.pending;
            case RoutinesService.STATUS_AVAIABLE: return this.statusTranslations.available;
            case RoutinesService.STATUS_NOT_AVAIABLE: return this.statusTranslations.not_available;
            default: return '';
        }
    }

    /************ ACADIA ************/

    public showAcadiaStatusButton() : boolean {
        return this.isAcadia && this.acadiaComponent?.showStatusButton || false;
    }

    public checkAcadiaTaskListStatus() : void {
        if (this.isRefreshingAcadiaTaskList) return;

        this.isRefreshingAcadiaTaskList = true;
        this.acadiaComponent.checkTaskListStatus(() => this.isRefreshingAcadiaTaskList = false);
    }

    get myTaskMainDate(): string{
        return this._routineStore.getMyTaskMainDate();
    }

}
