import { Injectable } from "@angular/core";
import { AuthMainService } from "../../services/auth-main.service";
import { ListAreasParams } from "../models/list-areas-params.model";



@Injectable()
export class ListAreasSelectService {
  constructor(
    private _mainService: AuthMainService
    ) {}

  getListAreas(params: ListAreasParams) {
    const path = '/filter-list-areas';
    return this._mainService.post(path, params);
  }
}
