import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClbDrawerModule } from '@celebration/angular/clb-drawer';
import { ClbModalModule } from '@celebration/angular/clb-modal';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../main/access/services/auth.service';
import { MeetingsService } from '../services/meetings.service';
import { NotificationService } from '../../main/services/notification.service';
import { ActionsService } from '../services/actions.service';
import { KpisService } from '../services/kpis.service';
import { MultiSelectComponent } from "../../components/multiselect/multiselect.component";
import { LiveSearchSelectComponent } from "../../components/live-search-select/live-search-select.component";
import { ConvertDate } from '../../components/convert-date/convert-date.component';
import { EditActionParams } from '../services/types/actions-service-types';
import { SocketService } from '../../main/services/socket.service';


type KpiItem = {
  uuid: string;
  name: string;
  code: string;
}

type TagItem = {
  cod_tag: number;
  str_name: string
}

type DepartmentItem = {
  cod_area: number;
  str_name: string;
}

type MeetingItem = {
  cod_meetings: number;
  str_name: string;
}

type MeetingParticipant = {
  cod_user: number;
  str_name: string;
}

type ActionDTO = {
  cod_action: number;
  str_description: string;
  cod_owner: number;
  str_name_owner: string;
  dat_due: string;
  dat_ins: string;
  cod_action_status: number;
  arr_kpis: string[];
  arr_tags: number[];
  cod_area: number;
  str_origin: string;
  str_root_cause: string;
  arr_meetings: number[];
}

type EditActionForm = {
  description: string;
  deadline: string;
  owner: MeetingParticipant[];
  kpis: KpiItem[];
  tags: TagItem[];
  department: DepartmentItem[];
  action_origin: string;
  root_cause: string;
  arr_meetings: number[];
}


@Component({
  standalone: true,
  selector: 'app-meetings-edit-action-drawer',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ClbDrawerModule,
    ClbModalModule,
    MultiSelectComponent,
    LiveSearchSelectComponent,
    ConvertDate
],
  templateUrl: './meetings-edit-action-drawer.component.html',
  styleUrls: ['./meetings-edit-action-drawer.component.css']
})
export class MeetingsEditActionDrawerComponent {

  @Input('open') isOpen: boolean = false;
  @Input('canEdit') bol_can_edit: boolean = false;
  @Input('isMeetingOwner') is_meeting_owner: boolean = false;
  @Input('socketId') socket_id: string;

  @Input() action: ActionDTO;

  @Output() onClose = new EventEmitter<boolean>();

  obj_user: any;
  cod_user: number;

  isConfirmationModalOpen: boolean = false;
  confirmationModalDescription: string = '';
  currentModalAction: 'edit' | 'cancel' | null = null;

  actionForm: FormGroup;

  arr_kpis: KpiItem[] = []
  arr_meetings: MeetingItem[] = [];
  arr_participants: MeetingParticipant[] = [];
  arr_tags: TagItem[] = [];
  arr_departments: DepartmentItem[] = [];

  meetingsMap = new Map<number, string>();

  today: string = null;

  loadingState = {
    action: true,
    participants: false,
    kpis: false,
    tags: false,
    departments: false,
    meetings: false
  };

  constructor(
    private readonly _authService: AuthService,
    private readonly _meetingsService: MeetingsService,
    private readonly _notify: NotificationService,
    private readonly _actionsService: ActionsService,
    private readonly _kpisService: KpisService,
    private readonly _socketService: SocketService
  ) { 

    this.obj_user = this._authService.getAuthenticatedUser();
    this.cod_user = this.obj_user.id;

    this.actionForm = new FormGroup({
      description: new FormControl<string>('', [Validators.required]),
      deadline: new FormControl<string>('', [Validators.required]),
      owner: new FormControl<MeetingParticipant[]>([], [Validators.required]),
      kpis: new FormControl<KpiItem[]>([]),
      tags: new FormControl<TagItem[]>([]),
      department: new FormControl<DepartmentItem[]>([]),
      action_origin: new FormControl<string>(''),
      root_cause: new FormControl<string>(''),
      arr_meetings: new FormControl<number[]>([], [Validators.required])
    });

    this.today = this.getTodaysDate(); // Makes sense to not allow edited deadline to be before today
    this.getKpis();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.action?.currentValue?.cod_action !== changes.action?.previousValue?.cod_action){
      this.resetFormValues();
    }
    if (changes.isOpen?.currentValue && this.action) {
      this.initFormValues();
      this.getMeetings(this.action.cod_owner);
      this.getAction();
    }
  }

  resetFormValues() { 
    this.action = {
      ...this.action,
      dat_ins: null,
      arr_kpis: [],
      arr_tags: [],
      cod_area: null,
      str_origin: null,
      str_root_cause: null,
      arr_meetings: []
    };

    this.actionForm.reset({
      description: '',
      deadline: '',
      owner: [],
      kpis: [],
      tags: [],
      department: [],
      action_origin: '',
      root_cause: '',
      arr_meetings: []
    });
  }

  initFormValues() {
    const owner: MeetingParticipant = {
      cod_user: this.action?.cod_owner,
      str_name: this.action?.str_name_owner
    }
    
    const kpis = this.action?.arr_kpis?.map(e => { return {uuid: e} });
    const tags = this.action?.arr_tags?.map(e => { return {cod_tag: e} });
    const department = {
      cod_area: this.action?.cod_area
    }

    this.actionForm.patchValue({
      description: this.action?.str_description ?? '',
      deadline: this.action?.dat_due ? this.formatDate(new Date(this.action.dat_due)) : '',
      owner: owner.cod_user ? [owner] : [],
      kpis: kpis ?? [],
      tags: tags ?? [],
      department: department?.cod_area ? [department] : [],
      action_origin: this.action?.str_origin ?? '',
      root_cause: this.action?.str_root_cause ?? '',
      arr_meetings: this.action?.arr_meetings ?? []
    });

    this.toggleEditable();
  }

  toggleEditable() {
    if(this.bol_can_edit) {
      this.actionForm.enable();
      if(!this.is_meeting_owner) {
        this.actionForm.get('owner').disable();
      }
    } else {
      this.actionForm.disable();
    }
  }

  getOptions() {
    const arr_meetings = this.actionForm.get('arr_meetings').value;
    if (!arr_meetings || arr_meetings?.length === 0) return;

    this.getMeetingsParticipants(arr_meetings);
    this.getActionTags(arr_meetings);
    this.getDepartments(arr_meetings);
  }

  setLoadingState(field: string, loading: boolean) {
    this.loadingState[field] = loading;
  }

  getTodaysDate() {
    return this.formatDate(new Date());
  };

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const strDate = `${year}-${month}-${day}`;

    return strDate;
  }

  getKpis() {
    this.setLoadingState('kpis', true);
    this._kpisService.getKpis({_order: 'name'}).subscribe({
      next: (response) => {
        if (response.code == 888) {
          this.arr_kpis = response.content.data;
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_KPIS', response);
        }
      },
      error: (error) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_KPIS', error);
        this.setLoadingState('kpis', false);
      },
      complete: () => {
        this.setLoadingState('kpis', false);
      }
    });
  }

  getKpiCode(id) {
    const kpi = this.arr_kpis.find(k => k.uuid == id);
    return kpi ? kpi.code : '';
  }

  getKpiName(id) {
    const kpi = this.arr_kpis.find(k => k.uuid == id);
    return kpi ? kpi.name : '';
  }

  getMeetingsParticipants(arr_meetings: number[]) {
    this.setLoadingState('participants', true);
    this._meetingsService.getMeetingsParticipantsExclusive(arr_meetings, this.cod_user).subscribe({
      next: (response) => {
        if (response.code == 888) {
          const responseResults = response.content.results;
          this.arr_participants = responseResults.map((participant: MeetingParticipant) => {
            return {
              cod_user: participant.cod_user,
              str_name: participant.str_name
            };
          });
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_ACTION_OWNERS', response);
        }
      },
      error: (err) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_ACTION_OWNERS', err);
        this.setLoadingState('participants', false);
      },
      complete: () => {
        this.setLoadingState('participants', false);
      },
    })
  }

  getMeetings(cod_selected_owner: number, term: string = '') {
    this.setLoadingState('meetings', true);
    this._actionsService.searchUserMeetings(this.cod_user, term, cod_selected_owner).subscribe({
      next: (response) => {
        if (response.code == 888) {
          const responseResults = response.content.results;
          this.arr_meetings = responseResults.map((meeting: MeetingItem) => {
            this.meetingsMap.set(meeting.cod_meetings, meeting.str_name);
            return meeting;
          });
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_MEETINGS', response);
        }
      },
      error: (error) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_MEETINGS', error);
        this.setLoadingState('meetings', false);
      },
      complete: () => {
        this.setLoadingState('meetings', false);
      }
    });
  }

  onSelectOwner(event: { selectedOptions: any[] }) {
    this.updateFormValue('owner', event.selectedOptions);
    this.onMeetingSearchTermChange('');
  }
  
  onMeetingSearchTermChange(term: string) {
    const owner = this.actionForm.get('owner').value;
    const cod_owner = owner?.[0]?.cod_user;
    if (!cod_owner) return;
    
    this.getMeetings(cod_owner, term);
  }
  
  onSelectMeeting(meeting: MeetingItem) {
    const meetingsIds = this.actionForm.get('arr_meetings').value;

    if (meetingsIds.includes(meeting.cod_meetings)) {
      this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_MEETING_ALREADY_ADDED');
      return;
    }

    meetingsIds.push(meeting.cod_meetings);
    this.updateFormValue('arr_meetings', meetingsIds);
    this.meetingsMap.set(meeting.cod_meetings, meeting.str_name);

    this.getOptions();
  }

  onRemoveMeeting(meetingId: number) {
    const meetingsIds = this.actionForm.get('arr_meetings').value;
    const updatedMeetings = meetingsIds.filter((id: number) => id !== meetingId);
    this.updateFormValue('arr_meetings', updatedMeetings);
  }

  getActionTags(meetingIds: number[]) {
    this.setLoadingState('tags', true);
    this._actionsService.getActionTagsByMeetings(this.cod_user, meetingIds).subscribe({
      next: (response) => {
        if (response.code == 888) {
          const responseResults = response.content.results;
          this.arr_tags = responseResults.map((tag: TagItem) => {
            return {
              cod_tag: tag.cod_tag,
              str_name: tag.str_name
            }
          });
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_TAGS', response);
        }
      },
      error: (error) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_TAGS', error);
        this.setLoadingState('tags', false);
      },
      complete: () => {
        this.setLoadingState('tags', false);
      }
    });
  }

  getDepartments(meetingIds: number[]) {
    this.setLoadingState('departments', true);
    this._actionsService.getDepartmentsByMeetings(this.cod_user, meetingIds).subscribe({
      next: (response) => {
        if (response.code == 888) {
          const responseResults = response.content.results;
          this.arr_departments = responseResults.map((department: DepartmentItem) => {
            return {
              cod_area: department.cod_area,
              str_name: department.str_name
            }
          });
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_DEPARTMENTS', response);
        }
      },
      error: (error) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_LIST_DEPARTMENTS', error);
        this.setLoadingState('departments', false);
      },
      complete: () => {
        this.setLoadingState('departments', false);
      }
    });
  }

  updateFormValue(key: string, value: any) {
    this.actionForm.get(key).setValue(value);
    this.actionForm.get(key).markAsDirty();
    this.actionForm.get(key).markAsTouched();
  }

  onSelectField(formFieldName: string, event: { selectedOptions: any[] }) {
    this.updateFormValue(formFieldName, event.selectedOptions);
  }

  getAction() {
    this.setLoadingState('action', true);
    this._actionsService.getActionDetails(this.cod_user, this.action.cod_action).subscribe({
      next: (response) => {
        if (response.code == 888) {
          const responseResults = response.content.results[0];
          this.action = {
            cod_action: responseResults.cod_action,
            str_description: responseResults.str_description,
            cod_owner: responseResults.cod_owner,
            str_name_owner: responseResults.str_name_owner,
            cod_action_status: responseResults.cod_action_status, // if status 3, 4, or 7, we just display data, but don't let edit
            dat_due: responseResults.dat_due,
            dat_ins: responseResults.dat_ins,
            arr_kpis: responseResults.arr_kpis,
            arr_tags: responseResults.arr_tags,
            cod_area: responseResults.cod_area,
            str_origin: responseResults.str_sources,
            str_root_cause: responseResults.str_fundamental_cause,
            arr_meetings: responseResults.arr_meetings,
          } as ActionDTO;
          
          this.initFormValues();
          this.getOptions();
        } else {
          this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_GET_ACTION', response);
        }
      },
      error: (error) => {
        this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_GET_ACTION', error);
        this.setLoadingState('action', false);
      },
      complete: () => {
        this.setLoadingState('action', false);
      }
    })
  }

  editActionRequest() {
    if(!this.bol_can_edit) return;
    // getRawValue is used to also get values from disabled fields, as using just value will exclude disabled fields unluess all fields are disabled.
    // The alternative is to either enable all, or disable all before getting value and then calling toggleEditable again after parsing to reset disabled status... Just using getRawValue is way simpler
    const params: EditActionParams = this.parseFormFieldsIntoParams(this.actionForm.getRawValue());
    this.onCloseConfirmationModal();
    this._actionsService.putEditAction(this.cod_user, this.action.cod_action, params).subscribe({
      next: (response) => {
        this._notify.success({ text: 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.SUCCESS_ACTION_EDITED', translate: true });
        this._socketService.refreshMeetingActions({origin: this.socket_id, arr_meetings: params.arr_meetings});
      },
      error: (error) => {
        const errorCode = error?.error?.content?.code;
        const translationString = errorCode?.includes('IL') ? `ERROR.${errorCode}` : 'MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_EDIT_ACTION';

        this.showErrorMessage(translationString, error); 
      },
      complete: () => {
        this.closeDrawer(true);
      }
    });
  }

  parseFormFieldsIntoParams(formValues: EditActionForm): EditActionParams {
    if (!formValues) return null;

    const params: EditActionParams = {
      str_description: formValues.description,
      dat_deadline: formValues.deadline,
      cod_owner: formValues.owner[0].cod_user,
      arr_kpis: formValues.kpis.map(kpi => kpi.uuid),
      arr_tags: formValues.tags.map(tag => tag.cod_tag),
      cod_area: formValues.department ? formValues.department[0]?.cod_area : null,
      str_origin: formValues.action_origin ?? null,
      str_root_cause: formValues.root_cause ?? null,
      arr_meetings: formValues.arr_meetings,
    };

    return params;
  }

  closeDrawer(updated: boolean) {
    this.onCloseConfirmationModal();
    this.resetFormValues();
    this.onClose.emit(updated);
  }
  
  onCloseDrawer() {
    if (this.actionForm.pristine) {
      this.closeDrawer(false);
    } else {
      this.openConfirmationModal('MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.CANCEL_ACTION_EDIT', 'cancel');
    }
  }

  onSaveEditAction() {
    if (this.actionForm.invalid) {
      this.showErrorMessage('MEETINGS_LIST.MEETINGS_DRAWERS.FEEDBACK_MESSAGES.ERROR_FILL_ALL_FIELDS');
      return;
    }
    this.openConfirmationModal('MEETINGS_LIST.MEETINGS_DRAWERS.CONFIRMATION_MESSAGES.CONFIRM_EDIT_ACTION', 'edit');
  }

  onConfirmAction() {
    if (this.currentModalAction === 'edit' && this.actionForm.valid) {
      this.editActionRequest();
    } else if (this.currentModalAction === 'cancel') {
      this.closeDrawer(false);
    }
  }

  openConfirmationModal(
    description: string, 
    actionType: 'edit' | 'cancel'
  ) {
    this.confirmationModalDescription = description;
    this.currentModalAction = actionType;
    this.isConfirmationModalOpen = true;
  }

  onCloseConfirmationModal() {
    this.isConfirmationModalOpen = false;
    this.currentModalAction = null;
  }

  showErrorMessage(errorMessage: string, objError: any = undefined) {
    console.log({errorMessage, objError});
    this._notify.error({ text: errorMessage, translate: true });
  }

}
