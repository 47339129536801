import {Injectable} from '@angular/core';
import {ListWorldsMainService} from './list-worlds-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class ListWorldsService {
  
  constructor(private _mainService: ListWorldsMainService) {
  }
  
  getWorldsList(param): Observable<any> {
    let path = '/forms/locations-worlds/' + param.cod_user +'/'+ param.dsc_privilege +'/' + param.term;
    return this._mainService.get(path);
  }
  
  getArrayContent(data: any) {
    return this._mainService.getArrayContent(data);
  }
}
