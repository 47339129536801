import {Component, Input} from '@angular/core';

@Component({
    selector: 'component-loading-backdrop',
    templateUrl: './loading-backdrop.component.html',
    styleUrls: ['./loading-backdrop.component.css'],
})
export class LoadingBackdropComponent {
    @Input() theme: string = 'default';
}
