import { Component, OnInit } from '@angular/core';
import { TriggersService } from '../services/triggers.service';
import { AuthService } from '../../main/access/services/auth.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SelectPagination } from "../../logbooks/models/select_pagination.model";
import { NotificationService } from '../../main/services/notification.service';

@Component({
  selector: 'app-trigger-list',
  templateUrl: './trigger-list.component.html',
  styleUrls: ['./trigger-list.component.css']
})
export class TriggerListComponent implements OnInit {

  pageIndex: any;
  triggerList: any = [];
  selectedRecords: Object[];
  str10perpage: string;
  str50perpage: string;
  str100perpage: string;
  totalPages: any[];
  resultsPerPage: any[];
  resultsPerPageSelectOption: any[];
  resultsPerPageSelected: any;
  isLoading: any = false;
  searchTerm: string;
  searchTimer: any;
  showError: boolean;
  errorMessage: string;
  bol_change_copy_name: boolean = false;
  successCopyMessage: string;
  maxPage: number;
  editSuccessMessage: boolean;
  emitEditSuccessMessage: boolean;
  createRecord: boolean = false;
  codTrigger: any;
  privilegeListTrigger: boolean;
  privilegeChangeTrigger: boolean;
  awaitConfirmDelete: boolean;
  deleteTriggerId: number;
  procedureImportingTriggers: string = 'fivewhy_sp_imp_triggers';
  fieldsExcelSheet: string = 'cod_trigger,cod_location,str_location_description_from,cod_area,str_area_description,cod_subarea,str_subarea_description,cod_category,str_name,str_name_de,str_name_es,str_name_fr,str_name_nl,str_name_pt,str_name_ru,str_name_ua';
  isLoadingFile: boolean = false;
  bol_check_active:boolean = true;
  bol_check_deleted:boolean = false;
  showImportErros: boolean = false;
  importErrors: string = "";
  warningTranslations: string;
  downloadCsvExample: any = {};
  str_title_button: string;

  downloadCsvSetup: any = {};
  _reportsApiUrl = environment().reportsApiUrl;

  constructor(
    private _triggerService: TriggersService, 
    private _authService: AuthService, 
    private _translateService: TranslateService,
    private _notify: NotificationService
  ) {
    this.searchTerm = '';
    this.privilegeListTrigger = this.checkPrivilege('ADMIN_LIST_TRIGGER');
    this.privilegeChangeTrigger = this.checkPrivilege('ADMIN_CHANGE_TRIGGER');
    this.searchTimer = null;
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str10perpage = '10 ' + translate;
    });
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str50perpage = '50 ' + translate;
    });
    this._translateService.get('GENERAL.PAGINATION_SELECT').subscribe(translate => {
      this.str100perpage = '100 ' + translate;
    });

    this._translateService.get('TRIGGERS.LABEL_IMPORT').subscribe(translate => {
      this.str_title_button = translate;
  });

    this.pageIndex = 1;
    this.totalPages = [];
    this.resultsPerPage = [
      new SelectPagination(10, this.str10perpage),
      new SelectPagination(50, this.str50perpage),
      new SelectPagination(100, this.str100perpage),
    ];
    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
    this.resultsPerPageSelected = this.resultsPerPage[0].qty;
    this.selectedRecords = [];
  }

  ngOnInit() {
    this.search(true);
    this.bol_check_active=true;
  }

  ngAfterViewInit(){
    this.search(true);
    this.bol_check_active=true;
  }

  messageImported(response: any) {
    this.isLoadingFile = false;
    this._notify.success({
      text: "MACHINES.MESSAGE_IMPORTED_SUCCESS",
      translate: true,
    });
    this.search(false);
  }

  _getImportErrorMessage(error: string): string { 
    switch (error) {
      case 'COD_LOCATION': return 'TRIGGERS.REQUIRED_LOCATION';
      case 'COD_AREA': return 'TRIGGERS.REQUIRED_AREA';
      case 'COD_SUBAREA': return 'TRIGGERS.REQUIRED_SUBAREA';
      case 'COD_CATEGORY': return 'TRIGGERS.REQUIRED_CATEGORY';
      case 'STR_NAME': return 'TRIGGERS.REQUIRED_STR_NAME';
      default: return null;
    }
  }

  importError($event) {
    this.isLoadingFile = false; 
    let errorMessage = '';

    if(!$event.includes('Row length does not match headers') ) {
        errorMessage = $event.split('-');
        const errorText = this._getImportErrorMessage(errorMessage?.[1]?.trim());
        if (errorText != null)
          this._notify.error({ text: errorText, translate: true });
    }   

    this._notify.error({ text: 'TRIGGERS.MESSAGE_IMPORTED_ERROR', translate: true });
  }

  startLoading($event) {
    this.isLoadingFile = true;
  }

  editTrigger(id, reload) {
    if (id == -1) {
      id = 0;
      this.emitEditSuccessMessage = true;
    } else {
      this.emitEditSuccessMessage = false;
    }
    this.codTrigger = id;
    this.createRecord = !reload;
    if (reload) {
      this.search(true);
    }
  }

  search(reset: boolean) {
    if (this.searchTerm.length < environment().minCharStartSearch && this.searchTerm.length != 0) {
      return;
    }
    this.isLoading = true;
    clearTimeout(this.searchTimer);
    let $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.searchTrigger();
    }, environment().debounceTime);
  }

  _getStatus(): string {
    if (this.bol_check_active && this.bol_check_deleted) {
      return 'ALL';
    } else {
      if (this.bol_check_active) {
        return 'ACTIVE';
      }
      if (this.bol_check_deleted) {
        return 'DELETED';
      }
    }
    return 'ACTIVE';
  }

  searchTrigger() {
    let str_status='ACTIVE';
    if(this.bol_check_active && this.bol_check_deleted){
      str_status='ALL';
    }else{
      if(this.bol_check_active){
        str_status='ACTIVE';
      }
      if(this.bol_check_deleted){
        str_status='DELETED';
      }
    }
    
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    this.isLoading = true;
    let objParam = {
      'term': this.searchTerm,
      'limit': this.resultsPerPageSelected,
      'offset': (tmpPage * this.resultsPerPageSelected),
      'cod_user': this._authService.getAuthenticatedUser().id,
      'dsc_page_privilege': 'ADMIN_LIST_TRIGGER',
      'str_status': str_status
    };

    this._triggerService.listTriggersAdmin(objParam).subscribe(data => {
      this.triggerList = data.content.results;
      if (data.content.length > 0) {
        this.maxPage = Math.ceil(data.content.results[0].full_count / this.resultsPerPageSelected);
        this.totalPages = this.pagination(this.pageIndex, this.maxPage);
      }
      this.editSuccessMessage = this.emitEditSuccessMessage;
      this.emitEditSuccessMessage = null;
      this._buidCsvSetup();
      this.isLoading = false;
    }, err => {
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    });
  }

  private _implodeValues(objValues) {
      let ids = '';
      if (objValues) {
          objValues.forEach(element => {
              if (ids != '') {
                  ids += ',';
              }
              ids += element;
          });
      }
      return ids;
  }

  async _buidCsvSetup() {
    const arr_headers = [
      await this._translateText('TRIGGERS.EXPORT.LOCATION_COD'),
      await this._translateText('TRIGGERS.EXPORT.LOCATION'),
      await this._translateText('TRIGGERS.EXPORT.DEPARTMENT_COD'),
      await this._translateText('TRIGGERS.EXPORT.DEPARTMENT'),
      await this._translateText('TRIGGERS.EXPORT.AREA_COD'),
      await this._translateText('TRIGGERS.EXPORT.AREA'),
      await this._translateText('TRIGGERS.EXPORT.CATEGORY'),
      await this._translateText('TRIGGERS.EXPORT.TRIGGER_NAME'),
      await this._translateText('TRIGGERS.EXPORT.NAME_DE'), 
      await this._translateText('TRIGGERS.EXPORT.NAME_ES'),
      await this._translateText('TRIGGERS.EXPORT.NAME_FR'),
      await this._translateText('TRIGGERS.EXPORT.NAME_NL'),
      await this._translateText('TRIGGERS.EXPORT.NAME_PT'),
      await this._translateText('TRIGGERS.EXPORT.NAME_RU'),
      await this._translateText('TRIGGERS.EXPORT.NAME_UA'),
    ];

    this.downloadCsvSetup = {
        cod_user: this._authService.getAuthenticatedUser().id,
        api_url: this._reportsApiUrl,
        str_proc_exec: 'fivewhy_sp_ex_triggers',
        arr_proc_params_search: [
            this.searchTerm,
            this._getStatus() || null,
            this._implodeValues(this.selectedRecords),
            this._authService.getAuthenticatedUser().id
        ],
        arr_headers: arr_headers,
        name_of_file_to_download: 'triggers_',
        str_column_dynamic: ''
    };

    this.downloadCsvExample = {
      cod_user: this._authService.getAuthenticatedUser().id,
      api_url: this._reportsApiUrl,
      str_proc_exec: 'fivewhy_sp_ex_triggers',
      arr_proc_params_search: [
        null,
        null,
        this._implodeValues([]),
        null
      ],
      arr_headers: arr_headers,
      arr_headers_2: [
        '"1234, 12345"',
        '"InteractionLog - Mouts location example, InteractionLog - Mouts location example 2"',
        '"1234, 12345"',
        '"InteractionLog - Mouts department example, InteractionLog - Mouts department example 2"',
        '"1234, 12345"',
        '"InteractionLog - Mouts area example, InteractionLog - Mouts area example 2"',
        '0',
        'Mouts Trigger example',
        'Mouts Trigger example DE',
        'Mouts Trigger example ES',
        'Mouts Trigger example FR',
        'Mouts Trigger example NL',
        'Mouts Trigger example PT',
        'Mouts Trigger example RU',
        'Mouts Trigger example UA',
      ],
      name_of_file_to_download: 'template_import_triggers_',
      str_column_dynamic: ''
    };
  }

  async _translateText(translation: string): Promise<string> {
      return await this._translateService.get(translation).toPromise();
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search(false);
  }


  pagination(currentPage, nrOfPages) {
    let delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  toggleAll(event) {
    if (event) {
      let qty = this.triggerList.length;
      for (let i = 0; i < qty; i++) {
        this.selectedRecords.push(this.triggerList[i].cod_trigger);
      }
    }
    else {
      this.selectedRecords = [];
    }
    this._buidCsvSetup();
  }

  toggleObjSelection($event, codMachine) {
    let index;
    if ($event != 0) {
      index = this.selectedRecords.indexOf($event);
    }
    else {
      index = this.selectedRecords.indexOf(codMachine);
    }

    if (index == -1) {
      this.selectedRecords.push($event);
    }
    else {
      this.selectedRecords.splice(index, 1);
    }
    this._buidCsvSetup();
  }

  isSelected(codMachine) {
    return this.selectedRecords.indexOf(codMachine) >= 0;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = '';
    this.bol_change_copy_name = false;
  }

  closeSuccessCopyMessage() {
    this.showError = false;
    this.errorMessage = '';
    this.successCopyMessage = '';
    this.bol_change_copy_name = false;
    this.searchTrigger();
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe(translate => {
      this.errorMessage = translate;
      this.showError = true;
    });
  }

  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }

  public toggleDeleteTrigger(id = 0) {
    this.awaitConfirmDelete = !this.awaitConfirmDelete;
    this.deleteTriggerId = id;
  }

  public confirmDeleteTrigger() {
    let objParam = {
      'cod_trigger': this.deleteTriggerId,
      'cod_user': this._authService.getAuthenticatedUser().id
    };
    this._triggerService.deleteTrigger(objParam).subscribe(data => {
      this.emitEditSuccessMessage = true;
      this._notify.success({text: '5WHY.TRIGGER_DELETED', translate: true});
      this.search(false);
    }, err => {
      let e = JSON.parse(err._body);
      this._showErrorMessage(e.content);
      this._authService.errCheck(err);
    }
    );
    this.awaitConfirmDelete = false;
    this.deleteTriggerId = 0;
  }

  hideMessage() {
    this.editSuccessMessage = false;
  }

  selectStatusActive(event){
    if(event=='selected'){
      this.bol_check_active=true;
    }else{
      this.bol_check_active=false;
      this.bol_check_deleted=true;
    }
    //console.log(this.bol_check_active);
    this.search(true);
  }

  selectStatusDeleted(event){
    if(event=='selected'){
      this.bol_check_deleted=true;
    }else{
      this.bol_check_deleted=false;
      this.bol_check_active=true;
    }
    this.search(true);
  }

}
