import {
  AbstractControl,
  FormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";

export enum ShiftError {
  ShiftOverlap = "shiftOverlap",
  shiftAreEqual = "shiftAreEqual",
}

export const nameFields = [
  "name_en",
  "name_de",
  "name_es",
  'name_fr',
  "name_pt",
  "name_ua",
  "name_ru",
  "name_nl"
];
export const nameFieldsMap = {
  name_en: "en",
  name_de: "de",
  name_es: "es",
  name_fr: "fr",
  name_pt: "pt",
  name_ua: "ua",
  name_ru: "ru",
  name_nl: "nl"
};
export const shiftOverlapValidation: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const shifts = control.get("shifts") as FormArray;
  if (!shifts) {
    return null;
  }
  const shiftsArray: UntypedFormGroup[] = shifts.controls as UntypedFormGroup[];
  const overlaps = [];
  const shiftValues: number[] = [];
  let shiftAreEqual = false;
  shiftsArray.forEach((shift, i) => {
    const start1 = shift.get("start").value;
    const end1 = shift.get("end").value;
    const shiftValue = shift.get("ial_id")?.value;
    if (shiftValues.includes(+shiftValue)) {
      shiftAreEqual = true;
    }
    shiftValues.push(+shiftValue);
    shiftsArray.slice(i + 1).forEach((otherShift, j) => {
      const start2 = otherShift.get("start").value;
      const end2 = otherShift.get("end").value;

      if (
        (start1 >= start2 && start1 < end2) ||
        (end1 > start2 && end1 <= end2)
      ) {
        overlaps.push(i, j + i + 1);
      }
    });
  });
  if (overlaps.length > 0) {
    return { [ShiftError.ShiftOverlap]: true } as const;
  }
  if (shiftAreEqual) {
    return { [ShiftError.shiftAreEqual]: true } as const;
  }
  return null;
};

export function compareHours(time1: string, time2: string) {
  if(!time1 || !time2) return
  const [h1, m1] = time1.split(":").map(Number);
  const [h2, m2] = time2.split(":").map(Number);
  switch (true) {
    case h1 < h2:
      return ShiftComparison.Before;
    case h1 > h2:
      return ShiftComparison.After;
    case m1 < m2:
      return ShiftComparison.Before;
    case m1 > m2:
      return ShiftComparison.After;
    default:
      return ShiftComparison.Equal;
  }
}

export enum ShiftComparison {
  Before = -1,
  Equal = 0,
  After = 1,
}
