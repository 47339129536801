import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MainService} from './main.service';
import {Category} from '../models/category.model';
import {Answer} from '../models/answer.model';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class FormsDynamicAdminService {
    constructor(
        private _mainService: MainService
    ) {
    }

    list(searchTerm, codUser, dscPrivilege, cod_zone = null): Observable<any> {
        let cod_zone_text = cod_zone != null ? cod_zone.join('&cod_zone[]=') : '';
        if (searchTerm != '') {
            return this._mainService.get(`/list/${codUser}/${dscPrivilege}/?term=${searchTerm}&cod_zone[]=${cod_zone_text}`);
        }
        else {
            return this._mainService.get(`/${codUser}/${dscPrivilege}/?cod_zone[]=${cod_zone_text}`);
        }
    }

    getPagedForms(params): Observable<any> {
        return this._mainService.post(`/form/paged`, params);
    }

    get(cod_form:number, cod_periodicity:number, upload_api_url:string, cod_user:number): Observable<any> {
        const path = '/form/' + cod_form + '/' + cod_periodicity + '/' + encodeURIComponent(upload_api_url) + '/' + cod_user;
        return this._mainService.get(path);
    }

    getFormByPeriodicity(cod_periodicity: number, cod_user: number): Observable<any> {
        let path = '/formByPeriodicity';
        return this._mainService.post(path, {
           'cod_periodicity' : cod_periodicity,
           'cod_user' : cod_user
        });
    }

    insert(str_name: string, str_name_de: string, str_name_es: string, str_name_fr: string, str_name_nl: string, str_name_pt: string, str_name_ru: string, str_name_ua: string, str_name_zh: string, str_name_ko: string, str_name_vi: string, str_hashtag: string, cod_user: number, categories: Category[], cod_zone: number, cod_regionals, cod_locations, arr_links, arr_links_desc, arr_cod_links_del, bol_use_previous_answers: boolean): Observable<number> {
        return this._mainService.post('/', {
            str_name,
            str_name_de,
            str_name_es,
            str_name_fr,
            str_name_nl,
            str_name_pt,
            str_name_ru,
            str_name_ua,
            str_name_zh,
            str_name_ko,
            str_name_vi,
            str_hashtag,
            cod_user,
            categories,
            cod_zone,
            cod_regionals,
            cod_locations,
            arr_links, 
            arr_links_desc, 
            arr_cod_links_del, 
            bol_use_previous_answers
        });
    }

    update(cod_form: number, str_name: string, str_name_de: string, str_name_es: string, str_name_fr: string, str_name_nl: string, str_name_pt: string, str_name_ru: string, str_name_ua: string, str_name_zh: string, str_name_ko: string, str_name_vi: string, str_hashtag: string, cod_user: number, categories: Category[], cod_zone: number, cod_regionals, cod_locations, arr_links, arr_links_desc, arr_cod_links_del, bol_use_previous_answers: boolean): Observable<number> {
        return this._mainService.patch(`/${cod_form}`, {
            cod_user,
            str_name,
            str_name_de,
            str_name_es,
            str_name_fr,
            str_name_nl,
            str_name_pt,
            str_name_ru,
            str_name_ua,
            str_name_zh,
            str_name_ko,
            str_name_vi,
            str_hashtag,
            categories, 
            cod_zone,
            cod_regionals, 
            cod_locations,
            arr_links, 
            arr_links_desc, 
            arr_cod_links_del,
            bol_use_previous_answers
        });
    }

    delete(cod_form: number, cod_user: number): Observable<boolean> {
        return this._mainService.delete(`/${cod_form}/${cod_user}`);
    }

    answer(cod_user: number, cod_form: number, cod_logbook_routines_periodicity_users: number, arr_answers: Answer[], source_function: string = '', questions_count: number = -1, compliance: string): Observable<number> {
        
        return this._mainService.post(`/${cod_form}/answer`, {
            cod_user,
            cod_form,
            cod_logbook_routines_periodicity_users,
            source_function,
            source: 'web',
            questions_count,
            arr_answers,
            compliance
        });
    }

    getArrayContent(data: any) {
        return MainService.getArrayContent(data);
    }

    getAnswers(token: string, cod_user: number): Observable<any> {
        return this._mainService.get(`/list_answers_by_token/${token}/${cod_user}/answers`);
    }

    getActions(token: string, cod_user: number): Observable<any> {
        return this._mainService.get(`/list-actions-by-token/${token}/${cod_user}/actions`);
    }

    getFormInLogbook(cod_form: number, cod_user: number): Observable<any> {
        return this._mainService.get(`/form-in-logbook/${cod_form}/${cod_user}/form`);
    }

    getFormWithoutAnswers(cod_user: number, cod_form: number, date: string): Observable<any> {
        let path = '/get-forms-questions';
        
        return this._mainService.post(path, {
          'cod_user' : cod_user,
          'cod_form': cod_form,
          'date': date
        }).pipe(
          distinctUntilChanged((previous, current) => current !== previous),
        );
    }

    initializeForm(cod_user: number, cod_logbook_routines_periodicity_users: number, cod_form: number, bol_previous_answers: boolean = false): Observable<any> {
        let path = '/init-functional-dynamic-form';

        return this._mainService.post(path, {
            'cod_user' : cod_user,
            'cod_logbook_routines_periodicity_users' : cod_logbook_routines_periodicity_users,
            'cod_form': cod_form,
            'bol_previous_answers': bol_previous_answers
        });
    }
    undoInitializationForm(cod_user: number, cod_logbook_routines_periodicity_users: number): Observable<any> {
        let path = '/undo-init-functional-dynamic-form';

        return this._mainService.post(path, {
            'cod_user' : cod_user,
            'cod_logbook_routines_periodicity_users' : cod_logbook_routines_periodicity_users
        });
    }
    undoInitializationCollabForm(cod_user: number, cod_logbook_routines_periodicity_users_collaborative: number): Observable<any> {
        let path = '/undo-init-functional-dynamic-collab-form';

        return this._mainService.post(path, {
            'cod_user' : cod_user,
            'cod_logbook_routines_periodicity_users_collaborative' : cod_logbook_routines_periodicity_users_collaborative,
        });
    }
    initializeCollabForm(cod_user: number, cod_logbook_routines_periodicity_users_collaborative: number, cod_form: number, bol_previous_answers: boolean = false): Observable<any> {
        let path = '/init-collaborative-dynamic-form';

        return this._mainService.post(path, {
            'cod_user' : cod_user,
            'cod_logbook_routines_periodicity_users_collaborative' : cod_logbook_routines_periodicity_users_collaborative,
            'cod_form': cod_form,
            'bol_previous_answers': bol_previous_answers
        });
    }

    getForm(params: any): Observable<any> {
        let path = '/get-dynamic-functional-forms';

        return this._mainService.post(path, params);
    }

    getCollabForm(params: any): Observable<any> {
        let path = '/get-dynamic-collaborative-forms';
        return this._mainService.post(path, params);
    }

    savePartial(params: any): Observable<any> {
        let path = '/save-partial-functional-form';
        return this._mainService.post(path, params);
    }

    savePartialCollab(params: any): Observable<any> {
        let path = '/save-partial-collaborative-form';
        return this._mainService.post(path, params);
    }

}
