import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsInputComponent } from '../forms-input/forms-input.component';

@Component({
    selector: 'text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./../forms-input/forms-input.component.css', './text-input.component.css']
})

export class TextInputComponent extends FormsInputComponent<string> {
    @Input() hasPlaceholder: boolean = true;
    constructor() {
        super();
    }

    protected validationRule() : boolean {
        return this.data && this.data.length > 0;
    }

}
