export class UsersProfiles {
    cod_profiles: number;
    str_name: string;
    str_description: string;
    bol_need_approval: boolean;
    arr_profiles_reports: any[];
    arr_profiles_screens: any[];
    // arr_jobs: any[];
    cod_user: number;
    cod_world: number;
    str_level: string;

    constructor() {
        this.cod_profiles = 0;
        this.str_name = '';
        this.str_description = '';
        this.str_level = '';
        this.arr_profiles_reports = [];
        this.arr_profiles_screens = [];
        // this.arr_jobs = [];
        this.cod_user = 0;
        this.cod_world = null;
    }
}
