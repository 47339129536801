import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {ListEquipmentsTaService} from './services/list-equipments-ta.service';
import {AuthService} from '../../main/access/services/auth.service';

@Component({
    selector: 'list-equipments-ta',
    templateUrl: './list-equipments-ta.component.html',
    styleUrls: ['./list-equipments-ta.component.css']
})

export class ListEquipmentsTaComponent implements OnInit {

    equipmentsTaList: any = [];
    obj_ta_eq  ;
    bol_show_typeahead: Boolean = true;
    str_user_tmp = '';
    cod_compoment_ta: number;
    obj_user = this._authService.getAuthenticatedUser();

    @Input() question;
    @Input() isMultiple: boolean;
    @Input() disabled: boolean;
    @Input() disabledDelete: any;
    @Input() subarea: number;
    @Output() onChange = new EventEmitter<any[]>();

    constructor(private _listEquipmentsTaService: ListEquipmentsTaService, private _authService:AuthService) { }

    ngOnInit() {
        if(this.question.cod_answer_option != null && this.question.cod_answer_option != '') {
            this.equipmentsTaList = [{ cod_answer_option: this.question.cod_answer_option, str_sap_name: this.question.answer }];    
            this.bol_show_typeahead = false;
        }   
        this.loadEquipmentsTa();     
    }

    loadEquipmentsTa() {
        this.obj_ta_eq = {
            type: 'dynamic',
            valueProperty: 'cod_id',
            searchProperty: 'str_sap_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
                if (this.equipmentsTaList.length === 0) {
                    this.equipmentsTaList.push({
                        cod_id: obj_selected.cod_id,
                        str_sap_name: obj_selected.str_sap_name,
                    });
                    if(this.question !== null) {
                        this.question.cod_answer_option = obj_selected.cod_id;    
                        this.question.str_answer_text = obj_selected.str_sap_name;
                        this.onChange.emit(this.question);
                        this.bol_show_typeahead = false;
                    } else {
                        this.onChange.emit(obj_selected);
                    }
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchEquipmentsTa(cb, value);
            },
            onBlur: (item: any) => {
                this.str_user_tmp = '';
            }
        };      
    }

    searchEquipmentsTa(cb, value) {
        const location = this.obj_user.cod_location;
        this._listEquipmentsTaService.getEquipmentsTaList({ 'term': value, 'location': location, 'subarea': this.subarea ,'cod_user': this._authService.getAuthenticatedUser().id }).subscribe((data: any) => {
            cb(this._listEquipmentsTaService.getArrayContent(data));
        },
        err => { this._authService.errCheck(err); console.log(err); }
        );
    }

    delEquipmentsTa() {
        this.question.str_answer_text = '';
        this.question.cod_answer_option = null;    
        this.onChange.emit(this.question);

        this.equipmentsTaList = [];
        this.bol_show_typeahead = true;
        this.cod_compoment_ta = null;
    }
       
}
