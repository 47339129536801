import { Injectable } from "@angular/core";
import { AuthMainService } from "../../services/auth-main.service";
import { ListSubAreasParams } from "../models/list-sub-areas-params.model";


@Injectable()
export class ListSubAreasSelectService {
  constructor(private _mainService: AuthMainService) {}

  getListSubareas(params: ListSubAreasParams) {
    const path = '/filter-list-sub-areas';
    return this._mainService.post(path, params);
  }
}
