import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LogbookRoutineService } from "../../logbooks/services/logbook-routine.service";
import { RoutineStatusService } from "../../logbooks/services/routine-status.service";
import { FormsDynamicAdminService } from "../../forms/forms-dynamic/services/forms-dynamic.service";
import { AuthService } from "../../main/access/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { RoutinesService } from "../../logbooks/services/routines.service";
import { MyFormsService } from 'app/my-forms/services/my-forms.service';
import { UserPreferencesService } from 'app/user-preferences/services/user-preferences.service';
import { ActionsService } from '../../meetings/services/actions.service';
import { NotificationService } from "app/main/services/notification.service";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import FormsHelper from 'app/forms/forms-dynamic/helpers/form-helper';

@Component({
    selector: 'component-routine-show-answers',
    templateUrl: './routine-show-answers.component.html',
    styleUrls: ['./routine-show-answers.component.css'],
})
export class RoutineShowAnswersComponent implements OnChanges {

    @Input() showRoutine: boolean = false;
    @Input() codLogbookPeriodicityUsers: number;
    @Input() isCollab: boolean = false;
    @Input() showPercentageEvolution: boolean = true;
    @Input() formName: string = null;
 
    @Output() changedRoutine = new EventEmitter<any>();
    @Output() questionsArrayChanged = new EventEmitter<any>();
    @Output() showingAnswers = new EventEmitter<boolean>();

    isLoadingAnswers: boolean = false;
    isLoadingActions: boolean = false;
    formAnswersList: any = [];
    showAnswers: boolean = false;
    showError: boolean = false;
    errorMessage: string = '';
    hasActions: boolean;
    actionsList: Array<Object> = [];
    arr_questions = [];
    inMyForms: boolean = false;
    createAction: boolean = false;
    str_hashtag: string = null;
    cod_logbook: number = null;
    cod_form: number = null;
    cod_user: number = null;
    action: any = null;
    categories: any = [];
    isVisibleCategory:  boolean[] = [];
    compliance_categories = [];

    constructor(
        private _logbookRoutineService: LogbookRoutineService,
        private _routineStatusService: RoutineStatusService,
        private _actionsService: ActionsService,
        private _formsDynamicService: FormsDynamicAdminService,
        private _authService: AuthService,
        private _translateService: TranslateService,
        private _routinesService: RoutinesService,
        private _userPreferencesService: UserPreferencesService,
        private _router: Router,
        private _myFormsService: MyFormsService,
        private _notify: NotificationService
    ) {
        this.showError = false;
        this.errorMessage = '';
        this.hasActions = false;
        this.cod_user = this._authService.getAuthenticatedUser().id;
        this.inMyForms = /my-forms/.test(this._router.url);
    }

    ngOnInit() {
        this._translateService.onLangChange.subscribe(event => {
            this.formAnswersList.forEach(question => {
                question.str_text = this._myFormsService.getQuestionTextByUserLanguage(event.lang, question, 'str_text')
                question.choice_text = this._myFormsService.getChoiceTextByUserLanguage(event.lang, question, 'choice_text')
            });
        });
    }

    private getAnswersPromise(token) : Observable<any> {
        return this.isCollab 
            ? this._routinesService.getFormsAnswersByTokenCollaborative({
                str_token: token,
                cod_user: this.cod_user
            })
            : this._formsDynamicService.getAnswers(token, this.cod_user);
    }

    getAnswers(token) {
        this.isLoadingAnswers = true;

        this.getAnswersPromise(token).subscribe(
            data => {
                this.formAnswersList = this._formsDynamicService.getArrayContent(data);                 
                this.formAnswersList[0].arr_categories.forEach(categories => {
                    categories.arr_questions.filter(question => question.int_type == 4).forEach(question => {
                        question.bol_show_actions = false;
                        question.selected_options = question.str_answer_text
                                                      ? question.str_answer_text.split(',').map(answer => question.arr_choices.find(choice => choice.cod_form_question_choice == answer))
                                                      : [];
                        if (question.bol_show_actions)          
                            question.bol_show_actions = question.selected_options.filter(option => option.bol_creates_action).length > 0;
                    });

                    categories.arr_questions.forEach(question => {
                        question.str_text = this._myFormsService.getQuestionTextByUserLanguage(this._userPreferencesService.getLocalLanguage(), question, 'str_text')
                        question.choice_text = this._myFormsService.getChoiceTextByUserLanguage(this._userPreferencesService.getLocalLanguage(), question, 'choice_text')                                        
                        if (question.answer) {
                            this.compliance_categories[categories.cod_forms_category] = FormsHelper.calculateComplianceForm(categories.arr_questions);
                        } else {
                            this.compliance_categories[categories.cod_forms_category] = 'N/A'; 
                        }

                    });                 
                });

                this.categories = this.formAnswersList[0].arr_categories;
                this.showAnswers = true;
                this.getActions(token);
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }, () => this.isLoadingAnswers = false
        );
    }

    private getActionPromise(token) : Observable<any> {
        return this.isCollab
            ? this._routinesService.getFormsActionsByTokenCollaborative({
                str_token: token, 
                cod_user: this.cod_user
            })
            : this._formsDynamicService.getActions(token, this.cod_user);
    }

    getActions(token) {
        this.hasActions = false;
        this.isLoadingActions = true;

        this.getActionPromise(token).subscribe(
            data => {
                this.actionsList = this._formsDynamicService.getArrayContent(data);
                this.hasActions = (this.actionsList.length > 0);
            }, err => {
                let e = JSON.parse(err._body);
                this._showErrorMessage(e.content);
                this._authService.errCheck(err);
            }, () => this.isLoadingActions = false
        );
    }

    private _showErrorMessage(err) {
        this._translateService.get(err).subscribe(translate => {
            this.errorMessage = translate;
            this.showError = true;
        });
    }

    private emitShowingAnswers(showAnswers: boolean) {
        if (this.showingAnswers) {
            this.showingAnswers.emit(showAnswers);
        }
    }

    hide() {
        this.emitShowingAnswers(false);
    }

    hideAnswers() {
        this.showAnswers = false;
        this.emitShowingAnswers(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.arr_questions = [];
        if (typeof changes.codLogbookPeriodicityUsers != typeof undefined) {
            this.getAnswers(changes.codLogbookPeriodicityUsers.currentValue);
        }

        this.formByPeriodicityPromise(changes.codLogbookPeriodicityUsers.currentValue).subscribe(form => {
            this.cod_form = form.content.cod_form;
            this.str_hashtag = form.content.hashtag;
            this.arr_questions = form.content.arr_questions || [];
            this.questionsArrayChanged.emit(this.arr_questions);
        });
    }

    private formByPeriodicityPromise(cod_lrpu: number) : Observable<any> {
        return this.isCollab
            ? this._routinesService.getFormByPeriodicityCollab(cod_lrpu, this.cod_user)
            : this._formsDynamicService.getFormByPeriodicity(cod_lrpu, this.cod_user);
    }

    public addAction() : void {
        this.createAction = true;
    }

    public actionData($event) : void {
        if ($event.deleted) {
            this.action = null;
            this.createAction = false;
        } else {
            this.action = $event;
        }
    }

    private validateAction() : boolean {
        return (
            this.action?.userId && 
            this.action?.dueDate && 
            this.action?.actionDescription &&
            this.action?.actionDescription.trim() != '' &&
            this.action?.meetings &&
            this.action?.meetings.trim() != ''
        )
    }

    public saveAction() : void {
        this.createAction = false;
        if (!this.validateAction()) {
            this._notify.warning({ text: 'DYNAMICFORMS.SHOW.CREATE_ACTION.VALIDATE', translate: true });
            return;
        }

        let actions = [];
        let defaultActionData = {
            'cod_usu_ins': this.cod_user,
            'str_sources': this.formName,
            'cod_form': this.cod_form,
            'cod_uuid': null,
            'val_quality': null
        }

        if (this.isCollab) {
            defaultActionData['cod_logbook_routines_periodicity_users_collab'] = this.codLogbookPeriodicityUsers;
        } else {
            defaultActionData['cod_logbook_routines_periodicity_users'] = this.codLogbookPeriodicityUsers;
        }
        
        actions.push({
            ...defaultActionData, 
            ...{
                'cod_user': this.action.userId,
                'dat_due': this.action.dueDate,
                'str_description': this.action.actionDescription,
                'meetings': this.action.meetings,
            }
        });

        this._actionsService.postNewAction(actions).subscribe(
            data => {
                this.getActions(this.codLogbookPeriodicityUsers);
                this._notify.success({ text: 'GENERAL.STATUS_CREATED', translate: true });
                this.action = null;
            }, err => {
                console.log(err);
            }
        );
    }

    public onClick(key: string): void {
        this.isVisibleCategory[key] = !this.isVisibleCategory[key];
    }
}
