// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablinks {
    padding: 15px;
    border-top-left-radius: 15px;    
    border-top-right-radius: 15px;    
    background-color: #666666;
    color: white;
    display: inline-block;
    min-width: 150px;    
    text-align: center;
}
.tablinks:hover {
    cursor: pointer;
}
.tablinks.active {
        background-color: white;
        color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/app/meetings/meetings-container/meetings-container.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;IAC7B,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;QACQ,uBAAuB;QACvB,cAAc;AACtB","sourcesContent":[".tablinks {\n    padding: 15px;\n    border-top-left-radius: 15px;    \n    border-top-right-radius: 15px;    \n    background-color: #666666;\n    color: white;\n    display: inline-block;\n    min-width: 150px;    \n    text-align: center;\n}\n.tablinks:hover {\n    cursor: pointer;\n}\n.tablinks.active {\n        background-color: white;\n        color: #666666;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
