import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { CrewService } from "app/five-why/services/crew.service";
import { AuthService } from "app/main/access/services/auth.service";
import { NotificationService } from "app/main/services/notification.service";
import { SelectPagination } from "app/logbooks/models/select_pagination.model";

@Component({
  selector: "app-crew-list",
  templateUrl: "./crew-list.component.html",
  styleUrls: ["./crew-list.component.css"],
})
export class CrewListComponent implements OnInit {
  pageIndex: any;
  maxPage: number;
  searchTimer: any;
  codLocation: any;
  cod_user: number;
  totalPages: any[];
  crewList: any = [];
  privilegeCrew: any;
  searchTerm: string;
  showError: boolean;
  str10perpage: string;
  str50perpage: string;
  errorMessage: string;
  str100perpage: string;
  resultsPerPage: any[];
  isLoading: any = false;
  selectedRecords: Object[];
  resultsPerPageSelected: any;
  createRecord: boolean = false;
  resultsPerPageSelectOption: any[];

  constructor(
    private _crewService: CrewService,
    private _authService: AuthService,
    private _notify: NotificationService,
    private _translateService: TranslateService
  ) {
    this.searchTerm = "";
    this.searchTimer = null;
    this.privilegeCrew = this._authService.getUserPrivilege("ADMIN_LIST_CREW");
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str10perpage = "10 " + translate;
      });
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str50perpage = "50 " + translate;
      });
    this._translateService
      .get("GENERAL.PAGINATION_SELECT")
      .subscribe((translate) => {
        this.str100perpage = "100 " + translate;
      });

    this.pageIndex = 1;
    this.totalPages = [];
    this.resultsPerPage = [
      new SelectPagination(10, this.str10perpage),
      new SelectPagination(50, this.str50perpage),
      new SelectPagination(100, this.str100perpage),
    ];
    this.resultsPerPageSelectOption = [this.resultsPerPage[0]];
    this.resultsPerPageSelected = this.resultsPerPage[0].qty;
    this.selectedRecords = [];
    this.cod_user = this._authService.getAuthenticatedUser().id;

  }

  ngOnInit() {
    this.search(true);
    this.privilegeCrew = this.checkPrivilege("ADMIN_LIST_CREW");
  }

  editCrew(cod_location, reload) {
    if (cod_location == -1) cod_location = 0;

    this.createRecord = !reload;
    this.codLocation = cod_location;
    if (reload) {
      this.search(true);
    }
  }

  search(reset: boolean) {
    if (
      this.searchTerm.length < environment().minCharStartSearch &&
      this.searchTerm.length != 0
    ) {
      return;
    }
    this.isLoading = true;
    clearTimeout(this.searchTimer);
    let $this = this;
    this.searchTimer = setTimeout(function () {
      if (reset) {
        $this.pageIndex = 1;
      }
      $this.searchCrew();
    }, environment().debounceTime);
  }

  searchCrew() {
    let tmpPage = this.pageIndex;
    if (tmpPage > 0) {
      tmpPage = tmpPage - 1;
    }
    this.isLoading = true;
    const objParam = {
      term: this.searchTerm,
      limit: this.resultsPerPageSelected,
      offset: tmpPage * this.resultsPerPageSelected,
      cod_user: this.cod_user,
      dsc_page_privilege: "ADMIN_LIST_CREW",
    };

    this._crewService.listCrew(objParam).subscribe(
      (data) => {
        this.crewList = data.content.results;
        if (data.content.length > 0) {
          this.maxPage = Math.ceil(
            data.content.results[0].full_count / this.resultsPerPageSelected
          );
          this.totalPages = this.pagination(this.pageIndex, this.maxPage);
        }

        this.isLoading = false;
      },
      (err) => {
        let e = JSON.parse(err._body);
        this._showErrorMessage(e.content);
        this._authService.errCheck(err);
      }
    );
  }

  selectionChanged($event) {
    this.resultsPerPageSelected = parseInt($event.selectedOptions[0].qty);
    this.changePage(1);
  }

  changePage(page) {
    this.pageIndex = page;
    this.search(false);
  }

  pagination(currentPage, nrOfPages) {
    let delta = 2,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    if (nrOfPages <= 1) {
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < nrOfPages && i > 1) {
        range.push(i);
      }
    }
    range.push(nrOfPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  closeErrorMessage() {
    this.showError = false;
    this.errorMessage = "";
  }

  private _showErrorMessage(err) {
    this._translateService.get(err).subscribe((translate) => {
      this._notify.error(translate);
    });
  }

  checkPrivilege(privilege) {
    return this._authService.getUserPrivilege(privilege);
  }

}
