import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ChangeDetectorRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {SocketService} from '../../main/services/socket.service';
import {Router} from '@angular/router';
import {MeetingsService} from '../services/meetings.service';
import {ActionsService} from '../services/actions.service';
import {AuthService} from '../../main/access/services/auth.service';
import {environment} from '../../../environments/environment';
import {UploadService} from '../../main/services/upload.service';
import {TranslateService} from '@ngx-translate/core';
import {AreaService} from  '../../logbooks/services/area.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-meetings-actions-details',
    templateUrl: './meetings-actions-details.component.html',
    styleUrls: ['./meetings-actions-details.component.css']
})
export class MeetingsActionsDetailsComponent implements OnChanges, OnInit {

    obj_action_form: any;
    cod_action_status_tempo: any;
    obj_message_form: any;
    obj_user: any;
    arr_messages: any;
    arr_users_status: any;
    arr_action_status: any;
    arr_meeting_indicators: any;
    arr_users_meetings: any;
    arr_areas: any = [];
    isLoading: boolean;
    areasSelected: any[];
    subAreaSelected: any[];
    equipmentsSelected: any[];
    indicatorsSelected: any[];
    ownerSelected: any;
    historySubMenu: boolean;
    error_message_upload: string;
    bol_error_upload: boolean = false;
    arrMultiselectFields: Object[];
    cod_old_action: number;
    cod_current_action: number;
    isLoadingComments: boolean;
    showingAnswersForm: boolean;
    str_meeting_tmp:any;
    obj_ta_meetings: any;
    bol_loading: boolean = false;
    status_allow_edit: boolean = false;
    hideInput: any;
    privilegeFiveWhy: any;

    obj_connections = new Subscription();
    boolActionSubscribed: boolean;
    privilege:string;

    timeToHideMessage: number;
    private timeToHideMessageConstant: number;

    @Input() detailsParam: object;
    @Input() hidden: boolean;
    @Input() isParticipant: boolean;
    @Input('date') dateSelected: string;
    @Input() cod_meeting;
    @Output() hiddenChange = new EventEmitter<boolean>();
    @Output() onChangeStatus: EventEmitter<any> = new EventEmitter();
    @Input() canEdit = 1;
    @Input() canEditMessages: boolean;


    constructor(
        private _socketService: SocketService,
        private _uploadService: UploadService,
        public meetingsService: MeetingsService,
        public actionsService: ActionsService,
        public _translateService: TranslateService,
        public _logbookAreaService: AreaService,
        private router: Router,
        private _authService: AuthService,
        private cdr: ChangeDetectorRef) {
            
        this.privilege="MEETING_SELECT_AREA";
        this.obj_action_form = {};
        this.obj_user = {};
        this.obj_user = _authService.getAuthenticatedUser();
        this.arr_messages = [];
        this.arr_users_status = [];
        this.arr_action_status = [];
        this.arr_meeting_indicators = [];
        this.obj_ta_meetings = {};
        this.arr_users_meetings = [];
        this.obj_message_form = {str_text: '', cod_user: '', cod_action: ''};
        this.hidden = true;
        this.historySubMenu = false;
        this.arrMultiselectFields = [];
        this.cod_old_action = 0;
        this.cod_current_action = 0;
        this.isLoadingComments = true;
        this.showingAnswersForm = false;

        this.timeToHideMessage = 0;
        this.timeToHideMessageConstant = 3000;

        this.str_meeting_tmp='';
        
    }

    ngOnInit() {
        this.privilegeFiveWhy = this.checkPrivilege('5WHY_MENU_VISUALIZE');
        this.indicatorsSelected = [];
        this.ownerSelected = [];
        this.boolActionSubscribed = false;

        this._translateService.onLangChange.subscribe(() => {
            if(this.obj_action_form.cod_actions){
                this.arr_meeting_indicators = [];
                this.openAction(this.obj_action_form.cod_actions);
            }
        });

        //Get translation for columns typeahead component and initialize it
        this._translateService.get('MEETING.MEETING_NAME').subscribe(translate => {
            this.obj_ta_meetings = {
            type: 'dynamic',
            placeHolder: translate,
            valueProperty: 'cod_meetings',
            searchProperty: 'str_name',
            hasImage: false,
            onSelect: (obj_selected: any) => {
    
                if(obj_selected){
                    this.enableDisableField('meeting', false, null);
                    this.obj_action_form.arr_meetings.push(obj_selected);
                    let $event ={
                        selectedOptions: this.obj_action_form.arr_meetings
                    };
                    this.saveMeetingDetails($event, 'meeting');
                    this.enableDisableField('meeting', true, null);
                }
            },
            asynchDataCall: (value: string, cb: any) => {
                this.searchMeetings(cb, value);
            },
            onBlur: (item: any) => {
                this.str_meeting_tmp='';
            }
            };
        });
    }

    ngOnChanges(changes: SimpleChanges) {        
        const detailsParam: SimpleChange = changes.detailsParam;
        if (detailsParam?.currentValue?.cod_action) {
            //LEAVE THE OLD ACTION
            this._socketService.unsubscribeAction(this.cod_old_action);
            this.cod_current_action = detailsParam.currentValue.cod_action;
            this.openAction(detailsParam.currentValue.cod_action);
            this.actionSubscribe(detailsParam.currentValue.cod_action);
            this.cod_old_action = detailsParam.currentValue.cod_action;
            this.showingAnswersForm = false;
        }
        this.cdr.detectChanges();
    }
    
    searchMeetings(cb, value){
        this.meetingsService.getMeetingsByNameList(value, this.obj_user.id, 'NOT_PRIVILEGE', 'ACTIVE', 0, 10000).subscribe({
            next: res => {
                if (res.type != 'error') {
                    cb(this.meetingsService.getArrayContent(res));
                }
            }, 
            error: err => { 
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });
    }

    delMeetingLink(obj_meeting) {
        let obj_meeting_link = {
            cod_user: this.obj_user.id,
            cod_action: this.obj_action_form.cod_actions,
            cod_meeting: obj_meeting.cod_meetings
        };

        this.actionsService.deleteMeetingActionLink(obj_meeting_link).subscribe({
            next: res => {
                this.openAction(this.obj_action_form.cod_actions);
            },
            error: err => {
                this.openAction(this.obj_action_form.cod_actions);
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });
    }

    checkStatusNaoInciada() {
        return this.isNotStarted();
    }

    checkStatusEmAndamento() {
        return this.isInProgress() || this.isNotStarted();
    }

    checkStatusRequestToClose() {
        return this.isRequetedToClose() || this.isInProgress() || this.isNotStarted();
    }

    checkStatusCancelada() {
        let is_administrator_meeting = this.obj_action_form.bool_is_owner;
        return ((is_administrator_meeting && !this.isClosed() && !this.isReproved()) || this.isCanceled());
    }

    reopenIsAllowed() {
        // COMPARE DATES - ONLY ALLOW 30 DAYS FROM CANCELLING
        if(this.detailsParam){ 
            const now = moment(new Date()); // todays date
            const end = moment(this.detailsParam['dat_alt']); // cancelling date
    
            const duration = moment.duration(now.diff(end));
            const days = duration.asDays();
            
            let isResponsable = this.obj_action_form.bool_is_owner || this.isUserResponsable();
            return isResponsable && days <= 30 && (this.isCanceled() || this.isClosed());
        }
        return false;
    }

    checkStatusClose() {
        let is_administrator_meeting = this.obj_action_form.bool_is_owner;
        return (is_administrator_meeting && (this.isRequetedToClose() || this.isClosed() || this.isInProgress() || this.isNotStarted() )) || this.isClosed(); 
    }

    checkStatusReprovada() {
        let is_administrator_meeting = this.obj_action_form.bool_is_owner;
        return (is_administrator_meeting && (this.isRequetedToClose() || this.isReproved())) || this.isReproved();
    }

    
    changeStatusReopen() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 9;        
        this.changeStatus();
    }

    changeStatusNaoInciada() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 1;
        this.changeStatus();
    }

    changeStatusEmAndamento() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 2;
        this.changeStatus();
    }

    changeStatusRequestToClose() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 3;
        this.changeStatus();
    }

    changeStatusCancelada() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 4;
        this.changeStatus();
    }

    changeStatusClose() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 7;
        this.changeStatus();
    }

    changeStatusReprovada() {
        this.cod_action_status_tempo = this.obj_action_form.cod_actions_status;
        this.obj_action_form.cod_actions_status = 9;
        this.changeStatus();
    }

    changeStatus() {
        const $this = this;

        let cod_user = $this.obj_user.id;
        let params = {
            cod_action: this.obj_action_form.cod_actions,
            cod_actions_status: this.obj_action_form.cod_actions_status,
            cod_user: cod_user,
            dat_due: this.obj_action_form.dat_due,
            html_id: `action-${this.obj_action_form.cod_actions}`
        };

        this.isLoading = true;

        this.actionsService.postChangeStatusActions(params).subscribe({
            next: res => {
                if (res.type != 'error') {
                    this._socketService.saveAction({cod_action: params.cod_action});
                    this._socketService.refreshMeetingActions({arr_meetings: res.content.results.meetings_sp_in_meeting_change_status_actions});

                    this.onChangeStatus.emit(params);
                    this.detailsParam['dat_alt'] = new Date();
                }
                this.openAction(this.obj_action_form.cod_actions);
            },
            error: err => {
                this.openAction(this.obj_action_form.cod_actions);
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });

    }


    saveMeetingDetails($event, fieldId) {
        this.enableDisableField(fieldId, false, null);
        this.bol_error_upload = false;

        let cod_indicators = [];
        if (this.indicatorsSelected.length > 0) {
            this.indicatorsSelected.map(obj => {
                if (obj.cod_indicator !== 0 && obj.cod_indicator !== "0") {
                    cod_indicators.push(obj.cod_indicator)
                }
            })
        }
        let cod_user_owner = 0;
        if (typeof this.ownerSelected !== 'undefined' && this.ownerSelected.length > 0) {
            cod_user_owner = this.ownerSelected[0].cod_user;
        }

        let cod_area = 0;
        if (this.areasSelected.length > 0) {
            cod_area = this.areasSelected[0].cod_areas;
        }

        let cod_subarea = 0;
        if (typeof this.subAreaSelected !== 'undefined' && this.subAreaSelected.length > 0) {
            cod_subarea = this.subAreaSelected[0].cod_subareas;
        }

        let cod_subareas_equipments = 0;
        if (typeof this.equipmentsSelected !== 'undefined' && this.equipmentsSelected.length > 0) {
            cod_subareas_equipments = this.equipmentsSelected[0].cod_subareas_equipments;
        }

        let $this = this;
        let cod_user = $this.obj_user.id;

        let date = '';
        if (typeof this.obj_action_form.dat_due.getMonth !== 'function') {
            date = new Date(this.obj_action_form.dat_due + 'T00:00:00').getFullYear() + '-' + ('0' + (new Date(this.obj_action_form.dat_due + 'T00:00:00').getMonth() + 1)).slice(-2) + '-' + ('0' + (new Date(this.obj_action_form.dat_due + 'T00:00:00').getDate())).slice(-2);
        } else {
            date = this.obj_action_form.dat_due.getFullYear() + '-' + ('0' + (this.obj_action_form.dat_due.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.obj_action_form.dat_due.getDate())).slice(-2);
        }

        let params = {
            str_description: this.obj_action_form.str_description,
            str_sources: this.obj_action_form.str_sources,
            cod_action: this.obj_action_form.cod_actions,
            dat_due: date,
            arr_cod_indicators: cod_indicators,
            cod_area: cod_area,
            cod_subarea: cod_subarea,
            cod_subarea_equipment: cod_subareas_equipments,
            cod_user: cod_user,
            cod_user_owner: cod_user_owner,
            str_fundamental_cause: this.obj_action_form.str_fundamental_cause,
            arr_meetings: this.obj_action_form.arr_meetings
        };

        this.actionsService.postActionsDetails(params).subscribe({
            next: res => {
                if (res.type != 'error') {
                    this._socketService.saveAction({cod_action: params.cod_action});
                    this.onChangeStatus.emit(params);
                    this.enableDisableField(fieldId, true, null);
                }
                this.openAction(this.obj_action_form.cod_actions);
            }, 
            error: err => {
                this.openAction(this.obj_action_form.cod_actions);
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });

    }


    saveColumns(column, answer, type) {

        let p_str_description_answer = null;
        let p_cod_description_answer = null;

        if (type == 1) {
            p_cod_description_answer = answer;
        } else {
            p_str_description_answer = answer;
        }

        let $this = this;
        let cod_user = $this.obj_user.id;
        let params = {
            cod_action: this.obj_action_form.cod_actions,
            cod_column: column,
            str_description_answer: p_str_description_answer,
            cod_description_answer: p_cod_description_answer,
            cod_user: cod_user
        };

        this.actionsService.postActionsColumns(params).subscribe({
            next: res => {
                if (res.type != 'error') {
                    this._socketService.saveAction({cod_action: params.cod_action});
                }
                this.openAction(this.obj_action_form.cod_actions);
            },
            error:  err => {
                this.openAction(this.obj_action_form.cod_actions);
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });


    }

    hide() {
        this.hidden = true;
        this.hiddenChange.emit(this.hidden);
    }

    selectOwner($event) {
        this.enableDisableField('owner', false, null);
        this.ownerSelected = $event.selectedOptions;
        this.saveMeetingDetails($event, 'owner');
        this.enableDisableField('owner', true, null);
    }

    selectIndicator($event) {
        this.enableDisableField('indicator', false, null);
        this.indicatorsSelected = $event.selectedOptions;
        this.saveMeetingDetails($event, 'indicator');
        this.enableDisableField('indicator', true, null);
    }

    selectArea($event) {
        this.enableDisableField('list_area', false, null);
        this.areasSelected = $event.selectedOptions;
        this.saveMeetingDetails($event, 'list_area');
        this.enableDisableField('list_area', true, null);
    }
    

    selectColumns($event, column) {
        this.enableDisableField(column, false, null);
        let answer_value = $event.selectedOptions[0].cod_columns_options;
        this.saveColumns(column, answer_value, 1);
        this.enableDisableField(column, true, null);
    }

    inputColumns(index, $event, fieldId) {
        this.enableDisableField(fieldId, false, null);
        let answer_value = this.obj_action_form.arr_columns[index].arr_answer_value[0].cod_columns_options;
        let column = this.obj_action_form.arr_columns[index].cod_columns;
        this.saveColumns(column, answer_value, 2);
        this.enableDisableField(fieldId, true, function () {
            document.getElementById('column_id_' + fieldId).focus();
            return false;
        });
    }

    actionSubscribe(cod_action) {
        this.isLoading = true;
        
        this._socketService.subscribeAction(cod_action);

        if (!this.boolActionSubscribed) {
            this.boolActionSubscribed = true;
            this.obj_connections.add(
                this._socketService.subscribeToActionInfo().subscribe({
                    next: data => {
                        let dataSocket: any;
                        dataSocket = data;
                        //If not in click moment of action, if come from socket, call function
                        if (dataSocket.obj_action.cod_action == this.cod_current_action) {
                            this.openAction(dataSocket.obj_action.cod_action);
                        }
                    },
                    error: err => {
                        this._authService.errCheck(err);
                        console.log(err);
                    }
                })
            );
        }
    }

    openAction(cod_action) {
        if(cod_action){
            this.isLoadingComments = true;
            this.actionsService.getActionsDetails(cod_action, this._authService.getAuthenticatedUser().id, environment().uploadGetFilesApiUrl + '/').subscribe({
                next: result => {
                    this.arrMultiselectFields = [];
                    if (result.code == 888 && result.content.code == 888) {
    
                        const $this = this;
    
                        $this.obj_action_form = {};
                        let dataContent: any;
    
    
                        dataContent = result.content.results;
                        dataContent.dat_due = new Date(dataContent.dat_due);
    
                        if (Array.isArray(dataContent.arr_comments)) {
                            $this.arr_messages = dataContent.arr_comments;
                        } else {
                            $this.arr_messages = [dataContent.arr_comments];
                        }
                        this.isLoadingComments = false;
    
    
                        if (Array.isArray(dataContent.arr_users_status)) {
                            $this.arr_users_status = dataContent.arr_users_status;
                        } else {
                            $this.arr_users_status = [dataContent.arr_users_status];
                        }
    
                        $this.obj_action_form = dataContent;
    
    
                        if (!Array.isArray($this.obj_action_form.arr_columns)) {
                            $this.obj_action_form.arr_columns = [$this.obj_action_form.arr_columns];
                        }
    
                        this.status_allow_edit = !($this.obj_action_form.cod_actions_status == 7 || $this.obj_action_form.cod_actions_status == 3 || $this.obj_action_form.cod_actions_status == 4);
    
                        if (!Array.isArray(dataContent.arr_indicators)) {
                            dataContent.arr_indicators = [dataContent.arr_indicators];
                        }
    
                        if (dataContent.arr_indicators && dataContent.arr_indicators.length > 0) {
                            const arr_meetings_indicators_tmp = dataContent.arr_indicators.concat(this.arr_meeting_indicators)
                            this.arr_meeting_indicators = _.uniqBy(arr_meetings_indicators_tmp, 'cod_indicator')
                            
                            this.indicatorsSelected = dataContent.arr_indicators;
                        } else {
                            this.indicatorsSelected = [];
                        }
    
                        if (dataContent.cod_user_owner) {
                            this.ownerSelected = [{cod_user: dataContent.cod_user_owner}];
                        } else {
                            this.ownerSelected = [];
                        }
    
                        if (dataContent.cod_area) {
                            this.areasSelected = [{cod_areas: dataContent.cod_area}];
                        } else {
                            this.areasSelected = [];
                        }
    
                        if (dataContent.cod_subarea) {
                            this.subAreaSelected = [{cod_subareas: dataContent.cod_subarea}];
                        } else {
                            this.subAreaSelected = [];
                        }
    
                        if (dataContent.cod_subarea_equipment) {
                            this.equipmentsSelected = [{cod_subareas_equipments: dataContent.cod_subarea_equipment}];
                        } else {
                            this.equipmentsSelected = [];
                        }
    
                        if (dataContent.arr_users_meetings) {
                            this.arr_users_meetings = dataContent.arr_users_meetings;
                        } else {
                            this.arr_users_meetings = [];
                        }
    
                        //resetamos o array para que delete caso seja removido algum indicador
                        // $this.arr_meeting_indicators = [];
                        // busca os indicators conforme codigo da meeting
                        $this.meetingsService.getMeetingIndicatorsByAction(cod_action, this._authService.getAuthenticatedUser().id).subscribe({
                            next: dataMeetingIndicators => {
                                if (dataContent.cod_actions == this.cod_current_action) {
                                    const results = dataMeetingIndicators.content.results;
    
    
                                    //THE CONCAT IS NECESSARY WHEN THE INDICATOR IS NOT IN THE MEETING (COMES FROM FORM)
                                    if (Array.isArray(results)) {
                                        $this.arr_meeting_indicators = $this.arr_meeting_indicators.concat(results);
                                    } else {
                                        $this.arr_meeting_indicators = $this.arr_meeting_indicators.concat([results]);
                                    }
    
                                    // REMOVING DUPLICATED INDICATORS IN ARRAY
                                    $this.arr_meeting_indicators = $this.arr_meeting_indicators.filter((s1, pos, arr) => arr.findIndex((s2) => s2.cod_indicator === s1.cod_indicator) === pos);
                                }
                                this.isLoading = false;
                            }, 
                            error: err => {
                                this.isLoading = false;
                                console.log(err);
                                this._authService.errCheck(err);
                            }
                        });                                        
                        this.loadAreas(dataContent.cod_meetings);
                        this.isLoadingComments = false;
    
                        this.scrollCommentListToBottom();
    
                    }
    
                },
                error: err => {
                    this._authService.errCheck(err);
                    console.log(err);
                }
            });
        } 
    }

    private scrollCommentListToBottom() {
        window.setTimeout(function () {
            let objDiv = document.getElementById('chat-messages');
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }, 200);
    }

    fileChangeEventAction(e) {

        const $this = this;
        $this.obj_message_form.str_text = '';
        let params = {
            'event': e,
            'allowedExtensions': ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']
        };

        this._uploadService.uploadFileBase64(params).subscribe({
            next: response => {
                if (response.type == 'success') {
                    const $this = this;
                    $this.obj_message_form.str_name_image = response.content.str_name;
                    this.sendMessage();
                } else {
                    this.bol_error_upload = true;
                    this.timeToHideMessage = this.timeToHideMessageConstant;
                    this.error_message_upload = response.content;
                    this.isLoading = false;
                }
            },
            error: err => {
                let e = JSON.parse(err._body);
                this.isLoading = false;
                this._authService.errCheck(err);
            }
        });
    }

    sendMessage() {
        this.bol_loading = true;
        const $this = this;
        let params = {cod_user: '', cod_action: '', str_text: '', str_name_image: '', cod_uuid: null};

        $this.obj_message_form.cod_user = $this.obj_user.id;
        $this.obj_message_form.cod_action = $this.obj_action_form.cod_actions;

        params.cod_user = $this.obj_message_form.cod_user;
        params.cod_action = $this.obj_message_form.cod_action;
        params.str_text = $this.obj_message_form.str_text;
        params.str_name_image = $this.obj_message_form.str_name_image;
        params.cod_uuid = null;

        if ($this.obj_message_form.str_text != '' || $this.obj_message_form.str_name_image != '') {

            this.actionsService.postMeetingActionMessage(params).subscribe({
                next: res => {
                    if (res.type != 'error') {
                        this._socketService.saveAction({cod_action: params.cod_action});
                        $this.obj_message_form.str_text = params.str_text = '';
                        $this.obj_message_form.str_name_image = params.str_name_image = '';
                    }
                    this.bol_loading = false;
                    this.openAction(this.obj_action_form.cod_actions);
                }, 
                error:err => {
                    this.openAction(this.obj_action_form.cod_actions);
                    $this.obj_message_form.str_text = params.str_text = '';
                    $this.obj_message_form.str_name_image = params.str_name_image = '';
                    this.bol_loading = false;
                    let e = JSON.parse(err._body);
                    console.log(e.content);
                    this._authService.errCheck(err);
                }
            });
        }
    }

    downloadFile(fileUrl, fileName) {

        this._uploadService.downloadFile(fileUrl).subscribe({
            next: res => {
                let blob = new Blob([res]);

                let a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            },
            error: err => {
                this._authService.errCheck(err);
                console.log(err);
            }
        });
    }

    getFileName(strFilePath: string) {
        let arr_temp = strFilePath.split('/');
        return arr_temp[arr_temp.length - 1];
    }

    getFileType(strFilePath: string) {
        let arr_temp = strFilePath.split('.');
        return arr_temp[arr_temp.length - 1];
    }

    toggleHistorySubMenu() {
        this.historySubMenu = !this.historySubMenu;
    }


    closeHistorySubMenu(event) {
        if (event.srcElement.id != 'history-menu-icon') {
            this.historySubMenu = false;
        }
    }

    ngOnDestroy() {
        this._socketService.unsubscribeAction(this.obj_action_form.cod_actions);
    }

    private enableDisableField(fieldId, isEnabled, cb) {
        if (isEnabled) {
            let $this = this;
            window.setTimeout(function () {
                $this.arrMultiselectFields[fieldId] = isEnabled;
                if (cb) {
                    cb();
                }
            }, 100);
        }
        else {
            let $this = this;
            $this.arrMultiselectFields[fieldId] = isEnabled;
        }
    }

    isEnableField(fieldId) {
        return (typeof this.arrMultiselectFields[fieldId] == typeof undefined || this.arrMultiselectFields[fieldId]);
    }

    deleteMessage(objMessage) {

        let params = {
            'file_name': objMessage.str_name_image
        };
        
        let objMessageForm = {
            cod_actions_comments: objMessage.cod_actions_comments,
            cod_user: this.obj_user.id,
            cod_action: this.obj_action_form.cod_actions
        };
        this.arr_messages = [];
        this.isLoadingComments = true;

        this.actionsService.deleteMeetingActionMessage(objMessageForm).subscribe({
            next: res => {
                if (res.type != 'error') {
                    this._uploadService.deleteFile(params).subscribe({
                        error: err => {
                            console.log(err);
                            this._authService.errCheck(err);
                        }
                    });

                    this._socketService.saveAction({cod_action: objMessageForm.cod_action});
                }
                this.openAction(this.obj_action_form.cod_actions);
            }, 
            error: err => {
                this.openAction(this.obj_action_form.cod_actions);
                let e = JSON.parse(err._body);
                console.log(e.content);
                this._authService.errCheck(err);
            }
        });
    }

    setShowAnswersForm($event) {
        this.showingAnswersForm = $event;
    }

    refreshMessages(codAction) {
        this.openAction(codAction);
    }

    convertDate(date) {
        if (!date || typeof date.getMonth !== 'function') {
            date = new Date(`${date}T00:00:00`);
        }

        //ajust to year like 200 or 12231
        let year = (''+date.getFullYear()).padStart(6,'0');

        return (year + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2));
    }

    changeDateModel(event) {
        this.obj_action_form.dat_due = event;
    }

    loadAreas(cod_meeting){
        let objParam = {
            'cod_meeting': cod_meeting,
            'cod_user': this._authService.getAuthenticatedUser().id,
        };
        
        this._logbookAreaService.getAreaByMeetingLocation(objParam).subscribe({
            next: res => {
                this.arr_areas = res.content.lenght == 1 ? [res.content.results] : res.content.results;
                this.isLoading = false;
            }, 
            error: err => {
                this.isLoading = false;
                console.log(err);
                this._authService.errCheck(err);
            }
        });
    }

    private isNotStarted() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 1;
    }

    private isInProgress() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 2;
    }

    private isRequetedToClose() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 3;
    }

    private isCanceled() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 4;
    }

    private isClosed() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 7;
    }

    private isReproved() {
        let status = this.obj_action_form.cod_actions_status;
        return status == 9;
    }

    private isUserResponsable() {
        let cod_user = this.obj_user.id;
        let cod_user_owner = 0
        if (typeof this.ownerSelected !== 'undefined' && this.ownerSelected.length > 0) {
            cod_user_owner = this.ownerSelected[0].cod_user;
        }
        return cod_user == cod_user_owner
    }

    hideMessage() {
        this.bol_error_upload = false;
        this.timeToHideMessage = 0;
    }
   
  checkPrivilege(privilege) {
    if (this._authService.getUserPrivilege(privilege)) {
      return true;
    }
    return false;
  }

  navigateToActionOrigin(origin) {
    let urlToGo = '';
    switch(origin) {
        case 'fivewhy': {
            urlToGo = `/problem-terminator/details/${this.obj_action_form.cod_fivewhys}`;
            break;
        }
        case 'forms': {
            const isCollab = !!this.obj_action_form.cod_logbook_routines_periodicity_users_collaborative;
                                 
            urlToGo = isCollab 
                ? `my-forms/form-collab/${this.obj_action_form.cod_logbook_routines_periodicity_users_collaborative}` 
                : `my-forms/form/${this.obj_action_form.cod_logbook_routines_periodicity_user}`;
            break;
        }
    }

    this.router.navigate([]).then(result => {  window.open(urlToGo, '_blank'); });
  }
}
