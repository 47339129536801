import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Translation } from 'app/components/translations-input/models/translation.model';

@Component({
  selector: 'forms-dynamic-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.css']
})

export class FormsDynamicEditQuestionComponent implements OnChanges, OnInit {

  @Input() cod_form_question: number = 0;
  @Input() str_text: string = '';
  @Input() int_type: number = 0;
  @Input() bol_creates_action: boolean = false;
  @Input() bol_optional: boolean = false;
  @Input() arr_choices = [];
  @Input() arr_questions = [];
  @Input() int_order: number = null;

  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onEditTranslations = new EventEmitter();
  @Output() onEditChoiceTranslations = new EventEmitter();
  @Output() onNewYesNoQuestion = new EventEmitter();
  @Output() onUpdateChoices = new EventEmitter();

  choiceId: number = 0;
  choiceText: string = '';
  warningMessageQuestion: string;
  messageCountChars: boolean = false;
  showBlockingPopUp: boolean = false;
  editQuestionBlockType = null;
  str_yes: string = '';
  str_no: string = '';

  isLoading = false;
  isEditing = false;
  constructor(private _translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges):void {
    this.isEditing = (this.cod_form_question !== 0);
  }

  ngOnInit(){

    this._translateService.get('DYNAMICFORMS.SHOW.YES').subscribe(translate => {
      this.str_yes = translate;
    });
  
    this._translateService.get('DYNAMICFORMS.SHOW.NO').subscribe(translate => {
      this.str_no = translate;
    });
  }

  createYesOrNo() {
    this.arr_choices.length = 0;

    this.arr_choices.push({
      cod_form_question_choice: null,
      str_text: this.str_yes,
      bol_creates_action: false,
      int_points: 0
    }
    ,{
      cod_form_question_choice: null,
      str_text: this.str_no,
      bol_creates_action: false,
      int_points: 0
    });

    this.onNewYesNoQuestion.emit(
      [
        new Translation('Jawohl', 'Si', 'Oui', 'Ja', 'Sim', 'да', 'Так','是','네','Có'),
        new Translation('Nein', 'No', 'Non', 'Nee', 'Não', 'Нет', 'Немає','不','아니','Không')
      ],
    );
  }

  validateQuestion() {
    const invalid = (type) => {
        this.warningMessageQuestion = type;
        return false;
    }
  
    if(!this.str_text) {
        return invalid('NAME_QUESTION');
    }

    if(!this.int_type) {
        return invalid('TYPE_QUESTION');
    } else {
        switch (this.int_type) {     
            case 2: // yes/no
            case 3:
            case 4:
                if (this.arr_choices.length == 0) {
                  return invalid('CHOICES');
                }
            break;      
        }
          
    }

    return true;
}
closeBlockPopUp(){
  this.showBlockingPopUp = false
}
save() {
    if(!this.validateQuestion()) {
      return;
    };
    if (this.int_type) {
      if (this.int_order && this.arr_questions) {
        if (this.int_type != 8 && this.arr_questions.find(x => x.int_type == 8) && this.arr_questions.find(x => x.int_type == 8).int_order == this.int_order && this.arr_questions.find(x => x.int_type == 9)) {
          this.editQuestionBlockType = 1
          this.showBlockingPopUp = true
          return
        }
        if (this.int_type == 9 && !this.arr_questions.find(x => x.int_type == 8)) {
          this.editQuestionBlockType = 2
          this.showBlockingPopUp = true
          return
        }
      } else {
        if (this.int_type == 17 && (!this.arr_questions?.find(x => x.int_type == 10))) {
          this.editQuestionBlockType = 1
          this.showBlockingPopUp = true
          return
        }
        if (this.int_type == 10 && (!this.arr_questions?.find(x => x.int_type == 9))) {
          this.editQuestionBlockType = 2
          this.showBlockingPopUp = true
          return
        }
        if (this.int_type == 9 && (!this.arr_questions?.find(x => x.int_type == 8))) {
          this.editQuestionBlockType = 3
          this.showBlockingPopUp = true
          return
        }
      }
    }

    this.onSave.emit({
      cod_form_question: this.cod_form_question,
      str_text: this.str_text,
      int_type: this.int_type,
      int_order: this.int_order,
      bol_creates_action: this.bol_creates_action,
      bol_optional: this.bol_optional,
      arr_choices: this.arr_choices,
    });
  }

  cancel() {
    this.onCancel.emit();
  }

  delete() {
    this.onDelete.emit({
      cod_form_question: this.cod_form_question,
    });
  }

  newChoice() {
    this.arr_choices.push({
      cod_form_question_choice: null,
      str_text: '',
      str_text_de: '',
      str_text_es: '',
      str_text_fr: '',
      str_text_nl: '',
      str_text_pt: '',
      str_text_ru: '',
      str_text_ua: '',
      str_text_zh: '',
      str_text_ko: '',
      str_text_vi: '',
      bol_creates_action: false,
      int_points: 0
    });
  }

  removeChoice(selectedIndex: number) {
    this.arr_choices = this.arr_choices.filter((i, index) => {
      return (index !== selectedIndex);
    });
    this.onUpdateChoices.emit({
      arr_choices: this.arr_choices,
    });
  }

  countChars() {
    if(this.str_text.length >= 255) {
        this.messageCountChars = true;
    }
    setTimeout(() => {
      this.messageCountChars = false;
    }, 2000);
  }

  editQuestionTranslations(): void {
    this.onEditTranslations.emit(this.int_order);
  }

  editChoiceTranslations(index: number): void {
    this.onEditChoiceTranslations.emit({ int_question_order: this.int_order, choice: index });
  }

  validateMinNumber(event, indexQuestion) {
    let { value } = event.target 
    if(value == '' || value < 0) {
      this.arr_choices[indexQuestion].int_points = 0;
    }
  }

}
