import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pillars-safety',
  templateUrl: './pillars-safety.component.html',
  styleUrls: ['./pillars-safety.component.css']
})
export class PillarsSafetyComponent implements OnInit {

  constructor() { 

  }

  ngOnInit() {
  }

}
