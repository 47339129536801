import {Injectable} from '@angular/core';
import {UserImageMainService} from './user-image-main.service';
import {Observable} from 'rxjs';

@Injectable()
export class UserImageService {

    constructor(private _mainService: UserImageMainService) {
    }

    getUserImage(param): Observable<any> {
        let path = '/uploads-exists/' + param.file_name;
        return this._mainService.get(path);
    }

    getUserImageFromLegacy(param): Observable<any> {
        let path = '/get-image-from-legacy/' + param.cod_user;
        return this._mainService.getPreferences(path);
    }

    getArrayContent(data: any) {
        return this._mainService.getArrayContent(data);
    }
}
