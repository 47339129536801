import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {Observable} from 'rxjs';

@Injectable()
export class MeetingsIndicatorsService {

  constructor(private _mainService: MainService) { }

  /**
   * Display list of all logbook areas.
   * @param param: JSON object filled with search "term" or "id"
   */
  getMeetingsIndicators(params): Observable<any> {// A_M, muitos
    const path = '/meetings-indicators/' + params.str_term + '/' + params.cod_user + '/' + params.dsc_page_privilege;
    return this._mainService.get(path);
  }

  getMeetingsIndicatorsAllLang(params): Observable<any> {
    const path = '/meetings-indicators-all-lang/' + params.str_term + '/' + params.cod_user + '/' + params.dsc_page_privilege;
    return this._mainService.get(path);
  }

  postNewMeetingsIndicator(params): Observable<any> {// A_M, só admin, ok
    const path = '/meetings-indicators';
    return this._mainService.post(path, params);
  }

  updateMeetingsIndicator(params): Observable<any> {// A_M, só admin, ok
    const path = '/meetings-indicators';
    return this._mainService.put(path, params);
  }

  deleteMeetingsIndicator(params): Observable<any> {// A_M, só admin, ok
    const path = '/meetings-indicators/' + params.cod_indicators + '/' + params.cod_usu_del;
    return this._mainService.delete(path);
  }

  getPeriodicities(): Observable<any> {
    const path = '/periodicities';
    return this._mainService.get(path);
  }

  getDownloadPDF(path) {// A_M, só admin, ??
    return this._mainService.getDownloadPDF(path);
  }

  getUnits(str_term, cod_user): Observable<any> {// A_M, só admin, ??
      const path = '/units?str_term=' + str_term + '&cod_user='+ cod_user;
      return this._mainService.get(path);
  }

  getIndicatorByMeetingLocation(params): Observable<any> {// A_M, muitos
    const path = '/get-indicator-by-meetings-location';
    return this._mainService.post(path, params);
  }

  getIndicatorByZone(params): Observable<any> {// A_M, muitos
    const path = '/get-indicator-by-zone';
    return this._mainService.post(path, params);
  }

  // getExternals(): Observable<any> {// A_M, só admin
  //     const path = '/externals/';
  //     return this._mainService.get(path);
  //   }

  // getExternalsByIndicators(cod_indicators, cod_user): Observable<any> {
  //     const params = `?&cod_indicators=${cod_indicators.join('&cod_indicators=')}&cod_user=${cod_user}`;
  //     const path = `/externals/cod-indicators/${params}`;
  //     return this._mainService.get(path);
  // }

  getArrayContent(data: any) {
      return (data.content.length >= 0 && !Array.isArray(data.content.results)) ? [data.content.results] : data.content.results;
  }
}
