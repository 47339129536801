import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskComponent),
    multi: true
};

@Component({
    selector: 'component-input-mask',
    templateUrl: './mask.component.html',
    styleUrls: ['./mask.component.css'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MaskComponent implements ControlValueAccessor, OnInit {
    @Input('id') componentId: string;
    @Input('mask') inputMask: string;
    @Input('placeholder') placeholder: string;
    @Input() classes: string;

    @Output()
    selectionChanged: EventEmitter<any> = new EventEmitter<any>();

    //The internal data model
    innerValue: any;

    //get accessor
    get model(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    @Input('model')
    set model(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    constructor(private _translateService: TranslateService) {

    }

    //Placeholders for the callbacks which are later provided
    //by the Control Value Accessor
    onTouchedCallback: () => void = noop;
    onChangeCallback: (_: any) => void = noop;

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    keypress(event) {
        this.mask(this.innerValue, this.inputMask, event);
    }

    mask(value, mask, evt) {
        try {

            // If user pressed DEL or BACK SPACE, clean the value
            try {
                let e = (evt.which) ? evt.which : evt.keyCode;
                if (e == 46 || e == 8) {
                    value = "";
                    return;
                }
            } catch (e1) {
                console.log("e1", e1)
            }

            let literalPattern = /[0\*]/;
            //let literalPattern = /[A-Z]/gi;
            let numberPattern = /[0-9]/;
            let newValue = "";

            for (let vId = 0, mId = 0; mId < mask.length;) {
                if (mId >= value.length)
                    break;

                // Number expected but got a different value, store only the valid portion
                if (mask[mId] == '0' && value[vId].match(numberPattern) == null) {
                    break;
                }

                // Found a literal
                while (mask[mId].match(literalPattern) == null) {
                    if (value[vId] == mask[mId])
                        break;

                    newValue += mask[mId++];
                }
                newValue += value[vId++];
                mId++;
            }
            this.innerValue = newValue;
        } catch (e) {
            console.log("e ", e);
        }
    }

    ngOnInit(): void {
        //Get translation for component
        if (this.placeholder) {
            this._translateService.get(this.placeholder).subscribe(translate => {
                this.placeholder = translate;
            });
        }
    }

}
